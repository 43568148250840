/* eslint-disable no-console */
import React from 'react';
import { useEffect, useState } from 'react';
import { Field, Form as FinalForm, FormSpy } from 'react-final-form';
import { bool, func } from 'prop-types';
import css from './SectionHero.module.css';
import classNames from 'classnames';
import {
    Button,
    FieldSelect,
    LocationAutocompleteInput,
    BookingDateRangeFilter,
} from '../../components';
import config from '../../config';
import IconDate from '../IconDate/IconDate';
const getfilterConfig = id =>
    config.custom.filters.find(_f => _f.id === id)?.config;

const identity = v => v;

function submitDisabled(values,displayDates) {
    if (values.pub_maxMTOW && values.location && values.make && displayDates) {
        return false
    } else {
        return true
    }
}
const QueryForm = props => {
    const { mounted, setDates, dates } = props;
    const displayDates = dates?.dates ? dates?.dates.split(",") : null
    return (
        <FinalForm
            {...props}
            onSubmit={e => {
                props.onSubmit(e);
            }}
            render={fieldRenderProps => {
                const { handleSubmit, onChange, values } = fieldRenderProps;
                const makePlaceholder = 'Aircraft OEM';
                const aircraftPlaceholder = 'Aircraft type';
                const aircraftConfig = getfilterConfig('airCraft');
                const makeOptions = aircraftConfig.parsePrimaryOptions(
                    aircraftConfig.options
                );
                const aircraftOptions = values.make
                    ? aircraftConfig.parseSecondaryOptions(
                        values.make,
                        aircraftConfig.options
                    )
                    : [];

                return (
                    <form
                        id="queryForm"
                        name="queryForm"
                        className={classNames(css.queryForm, {
                            [css.heroButtonFEDelay]: mounted,
                        })}
                        onSubmit={e => {
                            e.preventDefault();
                            handleSubmit(e);
                        }}>
                        <FormSpy onChange={onChange} />

                        <div className={css.inputContainer}>
                            <Field
                                className={css.queryFormAirport}
                                name="location"
                                format={identity}
                                component={LocationAutocompleteInput}
                                placeholder="Airport code"
                            // onChange={handleChange}
                            />
                        </div>

                        <div className={css.inputContainer}>
                            <FieldSelect
                                id="make"
                                name="make"
                                className={css.queryFormMake}>
                                <option value="">{makePlaceholder}</option>

                                {React.Children.toArray(
                                    makeOptions.map(_m => (
                                        <option value={_m.key}>
                                            {_m.label}
                                        </option>
                                    ))
                                )}
                            </FieldSelect>
                        </div>

                        <div className={css.inputContainer}>
                            <FieldSelect
                                className={css.queryFormMTOW}
                                id="pub_maxMTOW"
                                name="pub_maxMTOW">
                                <option value="">{aircraftPlaceholder}</option>
                                {React.Children.toArray(
                                    aircraftOptions.map(_m => (
                                        <option
                                            value={
                                                _m.key +
                                                ',' +
                                                aircraftConfig.max
                                            }>
                                            {_m.label}
                                        </option>
                                    ))
                                )}
                            </FieldSelect>
                        </div>
                        <div
                            className={classNames(
                                css.inputContainer,
                                css.inputDates
                            )}>
                            <BookingDateRangeFilter
                                label={
                                    <span className={css.findDates}>
                                        {displayDates ? `${displayDates[0]} to ${displayDates[1]}` : "Find dates"} <IconDate />
                                    </span>
                                }
                                className={css.queryFormDates}
                                queryParamNames={['dates']}
                                showAsPopup
                                onSubmit={setDates}
                                key={`QueryForm.Dates`}
                                id={'queryFormDates'}
                                idPrefix="queryFormDates"
                                contentPlacementOffset={0}
                            />
                        </div>
                        <Button
                            disabled={submitDisabled(values, displayDates)}
                            type="submit"
                            className={classNames(css.heroButton, {
                                [css.heroButtonFEDelay]: mounted,
                            })}>
                            Submit
                        </Button>
                    </form>

                );

            }}

        />
    );
};


QueryForm.propTypes = {
    onChange: func.isRequired,
    onSubmit: func.isRequired,
    mounted: bool,
};

export default QueryForm;
