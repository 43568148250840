/**
 *  TopbarMobileMenu prints the menu content for authenticated user or
 * shows login actions for those who are not authenticated.
 */
import React from 'react';
import { useState } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from '../../util/reactIntl';
import classNames from 'classnames';
import { ACCOUNT_SETTINGS_PAGES } from '../../routeConfiguration';
import { propTypes } from '../../util/types';
import { ensureCurrentUser } from '../../util/data';
import {
    AvatarLarge,
    InlineTextButton,
    NamedLink,
    NotificationBadge,
} from '../../components';

import css from './TopbarMobileMenu.module.css';
import { SupportedCurrencies } from '../../data/supportedCurrencies';

const TopbarMobileMenu = props => {
    const {
        isAuthenticated,
        currentPage,
        currentUserHasListings,
        currentUser,
        notificationCount,
        onLogout,
        saveSelectedCurrency,
        getSelectedCurrency,
    } = props;

    function getSymbole(code) {
        const currency = SupportedCurrencies.find(c => c.code == code);
        return currency?.symbole;
    }
    const [selectCurrencyOpen, setSelectCurrencyOpen] = useState(false);
    const currentCurrency = getSelectedCurrency();

    const user = ensureCurrentUser(currentUser);

    if (!isAuthenticated) {
        const signup = (
            <NamedLink name="SignupPage" className={css.signupLink}>
                <FormattedMessage id="TopbarMobileMenu.signupLink" />
            </NamedLink>
        );

        const login = (
            <NamedLink name="LoginPage" className={css.loginLink}>
                <FormattedMessage id="TopbarMobileMenu.loginLink" />
            </NamedLink>
        );

        const signupOrLogin = (
            <span className={css.authenticationLinks}>
                <FormattedMessage
                    id="TopbarMobileMenu.signupOrLogin"
                    values={{ signup, login }}
                />
            </span>
        );
        return (
            <div className={css.root}>
                <div className={css.content}>
                    <div className={css.authenticationGreeting}>
                        <FormattedMessage
                            id="TopbarMobileMenu.unauthorizedGreeting"
                            values={{ lineBreak: <br />, signupOrLogin }}
                        />
                    </div>
                </div>
                <div className={css.footer}>
                    <NamedLink
                        className={css.createNewListingLink}
                        name="NewListingPage">
                        <FormattedMessage id="TopbarMobileMenu.newListingLink" />
                    </NamedLink>
                </div>
            </div>
        );
    }

    const notificationCountBadge =
        notificationCount > 0 ? (
            <NotificationBadge
                className={css.notificationBadge}
                count={notificationCount}
            />
        ) : null;

    const displayName = user.attributes.profile.firstName;
    const currentPageClass = page => {
        const isAccountSettingsPage =
            page === 'AccountSettingsPage' &&
            ACCOUNT_SETTINGS_PAGES.includes(currentPage);
        return currentPage === page || isAccountSettingsPage
            ? css.currentPage
            : null;
    };

    return (
        <div className={css.root}>
            <AvatarLarge className={css.avatar} user={currentUser} />
            <div className={css.content}>
                <span className={css.greeting}>
                    <FormattedMessage
                        id="TopbarMobileMenu.greeting"
                        values={{ displayName }}
                    />
                </span>
                <InlineTextButton
                    rootClassName={css.logoutButton}
                    onClick={onLogout}>
                    <FormattedMessage id="TopbarMobileMenu.logoutLink" />
                </InlineTextButton>
                <NamedLink
                    className={classNames(
                        css.inbox,
                        currentPageClass('InboxPage')
                    )}
                    name="InboxPage"
                    params={{
                        tab: currentUserHasListings ? 'sales' : 'orders',
                    }}>
                    <FormattedMessage id="TopbarMobileMenu.inboxLink" />
                    {notificationCountBadge}
                </NamedLink>
                <NamedLink
                    className={classNames(
                        css.navigationLink,
                        currentPageClass('ManageListingsPage')
                    )}
                    name="ManageListingsPage">
                    <FormattedMessage id="TopbarMobileMenu.yourListingsLink" />
                </NamedLink>
                <NamedLink
                    className={classNames(
                        css.navigationLink,
                        currentPageClass('ProfileSettingsPage')
                    )}
                    name="ProfileSettingsPage">
                    <FormattedMessage id="TopbarMobileMenu.profileSettingsLink" />
                </NamedLink>
                <NamedLink
                    className={classNames(
                        css.navigationLink,
                        currentPageClass('AccountSettingsPage')
                    )}
                    name="AccountSettingsPage">
                    <FormattedMessage id="TopbarMobileMenu.accountSettingsLink" />
                </NamedLink>
                <div>
                    <div
                        onClick={() => {
                            setSelectCurrencyOpen(!selectCurrencyOpen);
                        }}
                        className={classNames(css.chooseCurrency)}>
                        <span className={css.menuItemBorder} />
                        <FormattedMessage id="EditListingPricingForm.currencyPlaceholder" />
                        <span className={css.getSymbole}>
                            {'- ' + getSymbole(currentCurrency)}
                        </span>
                    </div>
                    {selectCurrencyOpen &&
                        SupportedCurrencies.map(r => {
                            return (
                                <div
                                    key={r.code}
                                    onClick={() => {
                                        saveSelectedCurrency(r.code);
                                        setSelectCurrencyOpen(false);
                                    }}
                                    className={
                                        currentCurrency == r.code
                                            ? css.selected
                                            : css.selectCurrency
                                    }>
                                    <span className={css.symbole}>
                                        {r.symbole}
                                    </span>
                                    <span className={css.name}>{r.name}</span>
                                </div>
                            );
                        })}
                </div>
            </div>
            <div className={css.footer}>
                <NamedLink
                    className={css.createNewListingLink}
                    name="NewListingPage">
                    <FormattedMessage id="TopbarMobileMenu.newListingLink" />
                </NamedLink>
            </div>
        </div>
    );
};

TopbarMobileMenu.defaultProps = {
    currentUser: null,
    notificationCount: 0,
    currentPage: null,
};

const { bool, func, number, string } = PropTypes;

TopbarMobileMenu.propTypes = {
    isAuthenticated: bool.isRequired,
    currentUserHasListings: bool.isRequired,
    currentUser: propTypes.currentUser,
    currentPage: string,
    notificationCount: number,
    onLogout: func.isRequired,
};

export default TopbarMobileMenu;
