import unionWith from 'lodash/unionWith';
import { storableError } from '../../util/errors';
import { addMarketplaceEntities } from '../../ducks/marketplaceData.duck';
import { convertUnitToSubUnit, unitDivisor } from '../../util/currency';
import { formatDateStringToUTC, getExclusiveEndDate } from '../../util/dates';
import { parse } from '../../util/urlHelpers';
import config from '../../config';

// Pagination page size might need to be dynamic on responsive page layouts
// Current design has max 3 columns 12 is divisible by 2 and 3
// So, there's enough cards to fill all columns on full pagination pages
const RESULT_PAGE_SIZE = 24;

// ================ Action types ================ //

export const SEARCH_LISTINGS_REQUEST = 'app/SearchPage/SEARCH_LISTINGS_REQUEST';
export const SEARCH_LISTINGS_SUCCESS = 'app/SearchPage/SEARCH_LISTINGS_SUCCESS';
export const SEARCH_LISTINGS_ERROR = 'app/SearchPage/SEARCH_LISTINGS_ERROR';

export const SEARCH_MAP_LISTINGS_REQUEST =
    'app/SearchPage/SEARCH_MAP_LISTINGS_REQUEST';
export const SEARCH_MAP_LISTINGS_SUCCESS =
    'app/SearchPage/SEARCH_MAP_LISTINGS_SUCCESS';
export const SEARCH_MAP_LISTINGS_ERROR =
    'app/SearchPage/SEARCH_MAP_LISTINGS_ERROR';

export const SEARCH_MAP_SET_ACTIVE_LISTING =
    'app/SearchPage/SEARCH_MAP_SET_ACTIVE_LISTING';

export const CURRENCY_RATE_REQUEST = 'app/SearchPage/CURRENCY_RATE_REQUEST';
export const CURRENCY_RATE_SUCCESS = 'app/SearchPage/CURRENCY_RATE_SUCCESS';
export const CURRENCY_RATE_ERROR = 'app/SearchPage/CURRENCY_RATE_ERROR';

// ================ Reducer ================ //

const initialState = {
    pagination: null,
    searchParams: null,
    searchInProgress: false,
    searchListingsError: null,
    currentPageResultIds: [],
    searchMapListingIds: [],
    searchMapListingsError: null,
    currencyRate: null,
    currentUserCurrency: null,
};

const resultIds = data => data.data.map(l => l.id);

const listingPageReducer = (state = initialState, action = {}) => {
    const { type, payload } = action;
    switch (type) {
        case SEARCH_LISTINGS_REQUEST:
            return {
                ...state,
                searchParams: payload.searchParams,
                searchInProgress: true,
                searchMapListingIds: [],
                searchListingsError: null,
            };
        case SEARCH_LISTINGS_SUCCESS:
            return {
                ...state,
                currentPageResultIds: resultIds(payload.data),
                pagination: payload.data.meta,
                searchInProgress: false,
            };
        case SEARCH_LISTINGS_ERROR:
            // eslint-disable-next-line no-console
            console.error(payload);
            return {
                ...state,
                searchInProgress: false,
                searchListingsError: payload,
            };

        case SEARCH_MAP_LISTINGS_REQUEST:
            return {
                ...state,
                searchMapListingsError: null,
            };
        case SEARCH_MAP_LISTINGS_SUCCESS: {
            const searchMapListingIds = unionWith(
                state.searchMapListingIds,
                resultIds(payload.data),
                (id1, id2) => id1.uuid === id2.uuid
            );
            return {
                ...state,
                searchMapListingIds,
            };
        }
        case SEARCH_MAP_LISTINGS_ERROR:
            // eslint-disable-next-line no-console
            console.error(payload);
            return { ...state, searchMapListingsError: payload };

        case SEARCH_MAP_SET_ACTIVE_LISTING:
            return {
                ...state,
                activeListingId: payload,
            };

        case CURRENCY_RATE_REQUEST:
            return {
                ...state,
                currencyRate: null,
                currentUserCurrency: null,
            };
        case CURRENCY_RATE_SUCCESS: {
            return {
                ...state,
                currencyRate: payload.rate,
                currentUserCurrency: payload.currency,
            };
        }
        case CURRENCY_RATE_ERROR:
            // eslint-disable-next-line no-console
            console.error('api error');
            return { ...state, currencyRate: null, currentUserCurrency: null };

        default:
            return state;
    }
};

export default listingPageReducer;

// ================ Action creators ================ //

export const searchListingsRequest = searchParams => ({
    type: SEARCH_LISTINGS_REQUEST,
    payload: { searchParams },
});

export const searchListingsSuccess = response => ({
    type: SEARCH_LISTINGS_SUCCESS,
    payload: { data: response.data },
});

export const searchListingsError = e => ({
    type: SEARCH_LISTINGS_ERROR,
    error: true,
    payload: e,
});

export const searchMapListingsRequest = () => ({
    type: SEARCH_MAP_LISTINGS_REQUEST,
});

export const searchMapListingsSuccess = response => ({
    type: SEARCH_MAP_LISTINGS_SUCCESS,
    payload: { data: response.data },
});

export const searchMapListingsError = e => ({
    type: SEARCH_MAP_LISTINGS_ERROR,
    error: true,
    payload: e,
});

export const currencyRateRequest = () => ({
    type: CURRENCY_RATE_REQUEST,
});

export const currencyRateSuccess = response => ({
    type: CURRENCY_RATE_SUCCESS,
    payload: response,
});

export const currencyRateError = () => ({
    type: CURRENCY_RATE_ERROR,
    error: true,
});

export const searchListings = searchParams => (dispatch, getState, sdk) => {
    dispatch(searchListingsRequest(searchParams));

    const priceSearchParams = priceParam => {
        const inSubunits = value =>
            convertUnitToSubUnit(
                value,
                unitDivisor(config.currencyConfig.currency)
            );
        const values = priceParam ? priceParam.split(',') : [];
        return priceParam && values.length === 2
            ? {
                  price: [
                      inSubunits(values[0]),
                      Number(inSubunits(values[1])) + 1,
                  ].join(','),
              }
            : {};
    };

    const datesSearchParams = datesParam => {
        const values = datesParam ? datesParam.split(',') : [];
        const hasValues = datesParam && values.length === 2;
        const startDate = hasValues ? values[0] : null;
        const isNightlyBooking = config.bookingUnitType === 'line-item/night';
        const endDate =
            hasValues && isNightlyBooking
                ? values[1]
                : hasValues
                ? getExclusiveEndDate(values[1])
                : null;

        return hasValues
            ? {
                  start: formatDateStringToUTC(startDate),
                  end: formatDateStringToUTC(endDate),
                  // Availability can be full or partial. Default value is full.
                  availability: 'full',
              }
            : {};
    };

    const { perPage, price, dates, ...rest } = searchParams;

    const currentRate = getState().SearchPage.currencyRate;
    var newPrice = null;
    if (price && currentRate) {
        const arr = price.split(',');
        const correctPriceArr = arr.map(item => {
            return Math.round(Number(item) / currentRate)
        });
        const correctPriceStr = correctPriceArr.join(',');
        newPrice = correctPriceStr;
    }

    const priceMaybe = priceSearchParams(newPrice ? newPrice : price);
    const datesMaybe = datesSearchParams(dates);

    let params = {
        ...rest,
        ...priceMaybe,
        ...datesMaybe,
        per_page: perPage,
    };

    // if price and mtow exist, priority [pub_weightRange10] field
    if (params.price && params.pub_maxMTOW) {
        const { price, pub_maxMTOW, ...rest } = params;
        const weightRangeMaybe = {};
        const weightRange = pub_maxMTOW.split(',')[0];
        weightRangeMaybe[`pub_weightRange${weightRange}`] = price;

        params = { ...rest, ...weightRangeMaybe };
    } else if (!params?.price && params?.pub_maxMTOW) {
        const { pub_maxMTOW, ...rest } = params;
        const mtow = pub_maxMTOW.split(',');
        params = { ...rest, pub_maxMTOW: mtow[0] + ',' + mtow[1] };
    }

    return sdk.listings
        .query(params)
        .then(response => {
            dispatch(addMarketplaceEntities(response));
            dispatch(searchListingsSuccess(response));
            return response;
        })
        .catch(e => {
            dispatch(searchListingsError(storableError(e)));
            throw e;
        });
};

export const setActiveListing = listingId => ({
    type: SEARCH_MAP_SET_ACTIVE_LISTING,
    payload: listingId,
});

export const searchMapListings = searchParams => (dispatch, getState, sdk) => {
    dispatch(searchMapListingsRequest(searchParams));

    const { perPage, ...rest } = searchParams;
    const params = {
        ...rest,
        per_page: perPage,
    };

    return sdk.listings
        .query(params)
        .then(response => {
            dispatch(addMarketplaceEntities(response));
            dispatch(searchMapListingsSuccess(response));
            return response;
        })
        .catch(e => {
            dispatch(searchMapListingsError(storableError(e)));
            throw e;
        });
};

export const setCurrencyRate = (userCurrency) => (dispatch, getState, sdk) => {
    return fetch('/api/currencies')
    .then((response) => response.json())
    .then((data) => {
        if (userCurrency.toUpperCase() == "CHF") {
            dispatch(currencyRateSuccess({rate: 1, currency: userCurrency}))
        } else if (data.rates && data.rates[userCurrency]) {
            dispatch(currencyRateSuccess({rate: data.rates[userCurrency], currency: userCurrency}))
        } else {
            dispatch(currencyRateError())
        }
    }); 
};

export const loadData = (params, search) => {
    const queryParams = parse(search, {
        latlng: ['origin'],
        latlngBounds: ['bounds'],
    });
    const { page = 1, address, origin, ...rest } = queryParams;
    const originMaybe = config.sortSearchByDistance && origin ? { origin } : {};
    return searchListings({
        ...rest,
        ...originMaybe,
        page,
        perPage: RESULT_PAGE_SIZE,
        include: ['author', 'images'],
        'fields.listing': [
            'title',
            'geolocation',
            'price',
            'publicData.pricing',
            'publicData.location',
            'publicData.specifications',
            'publicData.maxMTOW',
        ],
        'fields.user': [
            'profile.displayName',
            'profile.abbreviatedName',
            'profile.publicData.companyName',
        ],
        'fields.image': [
            'variants.landscape-crop',
            'variants.landscape-crop2x',
        ],
        'limit.images': 1,
    });
};
