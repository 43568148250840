import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { FormattedMessage } from '../../util/reactIntl';
import { LISTING_STATE_DRAFT } from '../../util/types';
import { ensureOwnListing } from '../../util/data';
import { ListingLink } from '../../components';
import { EditListingDocumentationForm } from '../../forms';
import axios from 'axios';
import css from './EditListingDocumentationPanel.module.css';

const EditListingDocumentationPanel = props => {
    const {
        className,
        rootClassName,
        listing,
        disabled,
        ready,
        onSubmit,
        onImageUpload,
        images,
        onChange,
        submitButtonText,
        panelUpdated,
        updateInProgress,
        errors,
    } = props;

    const classes = classNames(rootClassName || css.root, className);
    const currentListing = ensureOwnListing(listing);
    const { publicData } = currentListing.attributes;

    const isPublished =
        currentListing.id &&
        currentListing.attributes.state !== LISTING_STATE_DRAFT;
    const panelTitle = isPublished ? (
        <FormattedMessage
            id="EditListingDocumentationPanel.title"
            values={{ listingTitle: <ListingLink listing={listing} /> }}
        />
    ) : (
        <FormattedMessage id="EditListingDocumentationPanel.createListingTitle" />
    );

    function removeFileFromS3 (key) {
        const data = new FormData();
        data.append('key', key);
        return axios.post(`/api/removeFile`, {key: key}, {
            headers: {
                'Content-Type': 'application/json;charset=UTF-8',
                "Access-Control-Allow-Origin": "*",
            }
        })
            .then(response => {
                console.log('File Removed From S3, Status: ', response.status);
            })
            .catch(err => {
                console.error('err', err);
            })
    };

    const previousDocKeys = [
        publicData?.documents?.facilityPlan?.key,
        publicData?.documents?.insuranceCertificate?.key,
        publicData?.documents?.operatingPolicies?.key,
        publicData?.documents?.businessPermit?.key,
        publicData?.documents?.otherDocument?.key,
    ];

    return (
        <div className={classes}>
            <h1 className={css.title}>{panelTitle}</h1>
            <p className={css.subTitle}>
                <FormattedMessage id="EditListingDocumentationPanel.createListingSubTitle" />
            </p>
            <EditListingDocumentationForm
                className={css.form}
                publicData={publicData}
                initialValues={{ facilityPlan: publicData?.documents?.facilityPlan, insuranceCertificate: publicData?.documents?.insuranceCertificate, operatingPolicies: publicData?.documents?.operatingPolicies, businessPermit: publicData?.documents?.businessPermit, otherDocument: publicData?.documents?.otherDocument }}
                onSubmit={values => {
                    const { facilityPlan, insuranceCertificate, operatingPolicies, businessPermit, otherDocument } = values;

                    const correctFacilityPlan = facilityPlan || publicData?.documents?.facilityPlan;
                    const correctInsuranceCertificate = insuranceCertificate || publicData?.documents?.insuranceCertificate;
                    const correctOperatingPolicies = operatingPolicies || publicData?.documents?.operatingPolicies;
                    const correctBusinessPermit = businessPermit || publicData?.documents?.businessPermit;
                    const correctOtherDocument = otherDocument || publicData?.documents?.otherDocument;

                    const currentDocKeys = [
                        correctFacilityPlan?.key,
                        correctInsuranceCertificate?.key,
                        correctOperatingPolicies?.key,
                        correctBusinessPermit?.key,
                        correctOtherDocument?.key,
                    ];
                    previousDocKeys.map(key => {
                        if (!currentDocKeys.includes(key)) {
                            removeFileFromS3 (key);
                        };
                    });

                    const updateValues = {
                        publicData: {
                            documents: {
                                facilityPlan: correctFacilityPlan,
                                insuranceCertificate: correctInsuranceCertificate,
                                operatingPolicies: correctOperatingPolicies,
                                businessPermit: correctBusinessPermit,
                                otherDocument: correctOtherDocument,
                            }
                        },
                    };
                    onSubmit(updateValues);
                }}
                onImageUpload={onImageUpload}
                images={images}
                onChange={onChange}
                disabled={disabled}
                ready={ready}
                saveActionMsg={submitButtonText}
                updated={panelUpdated}
                updateInProgress={updateInProgress}
                fetchErrors={errors}
            />
        </div>
    );
};

const { func, object, string, bool } = PropTypes;

EditListingDocumentationPanel.defaultProps = {
    className: null,
    rootClassName: null,
    listing: null,
};

EditListingDocumentationPanel.propTypes = {
    className: string,
    rootClassName: string,

    // We cannot use propTypes.listing since the listing might be a draft.
    listing: object,

    disabled: bool.isRequired,
    ready: bool.isRequired,
    onSubmit: func.isRequired,
    onChange: func.isRequired,
    submitButtonText: string.isRequired,
    panelUpdated: bool.isRequired,
    updateInProgress: bool.isRequired,
    errors: object.isRequired,
};

export default EditListingDocumentationPanel;
