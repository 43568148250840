import { AIRPORT_LIST, renderAirportCodeWithLocation } from './data/airports';
import { types as sdkTypes } from './util/sdkLoader';

const { LatLng, LatLngBounds } = sdkTypes;

// An array of locations to show in the LocationAutocompleteInput when
// the input is in focus but the user hasn't typed in any search yet.
//
// Each item in the array should be an object with a unique `id` (String) and a
// `predictionPlace` (util.types.place) properties.
const defaultLocations = AIRPORT_LIST.slice(0, 5).map(_a => ({
    id: 'default-' + _a.icaoCode,
    predictionPlace: {
        address: renderAirportCodeWithLocation(_a),
        bounds: new LatLngBounds(
            new LatLng(
                Number(_a.latitude.replace(',', '.')),
                Number(_a.longitude.replace(',', '.'))
            ),
            new LatLng(
                Number(_a.latitude.replace(',', '.')) - 0.5,
                Number(_a.longitude.replace(',', '.')) - 0.5
            )
        ),
    },
}));
export default defaultLocations;
