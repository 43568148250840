import React, { useState } from 'react';
import { bool, func, string } from 'prop-types';
import { compose } from 'redux';
import { Form as FinalForm, FormSpy } from 'react-final-form';
import { intlShape, injectIntl, FormattedMessage } from '../../util/reactIntl';
import classNames from 'classnames';
import * as validators from '../../util/validators';
import { Button, Form, Modal, FieldTextInput, FieldSelect } from '../../components';
import { AIRCRAFT_LIST, AIRCRAFT_MAKES } from '../../data/aircrafts';
import css from './AircraftsForm.module.css';

export const AircraftsForm = props => {
    const { initialValues } = props;
    const propsAircrafts = initialValues.aircrafts;

    const [addAircraftsModalOpen, setAddAircraftsModalOpen] = useState(false);
    const [aircraftEdit, setAircraftEdit] = useState();
    const [aircrafts, setAircrafts] = useState(propsAircrafts);

    return <FinalForm
        {...props}
        render={formRenderProps => {
            const {
                className,
                ready,
                handleSubmit,
                intl,
                pristine,
                saveActionMsg,
                updated,
                updateInProgress,
                onManageDisableScrolling,
                values,
                form,
                onSendData
            } = formRenderProps;


            const makeOptionsLabelMessage = intl.formatMessage({
                id: 'AircraftsForm.makeOptionsLabel',
            });

            const makeOptionsPlaceholder = intl.formatMessage({
                id: 'AircraftsForm.makeOptionsPlaceholder',
            });

            const makeOptionsRequired = validators.required(
                intl.formatMessage({
                    id: 'AircraftsForm.makeOptionsRequired',
                })
            );

            const typeOfAircraftLabelMessage = intl.formatMessage({
                id: 'AircraftsForm.typeOfAircraftLabel',
            });

            const typeOfAircraftPlaceholder = intl.formatMessage({
                id: 'AircraftsForm.typeOfAircraftPlaceholder',
            });

            const typeOfAircraftRequired = validators.required(
                intl.formatMessage({
                    id: 'AircraftsForm.typeOfAircraftRequired',
                })
            );

            const aircraftRegNoLabelMessage = intl.formatMessage({
                id: 'AircraftsForm.aircraftRegNoLabel',
            });

            const aircraftRegNoPlaceholder = intl.formatMessage({
                id: 'AircraftsForm.aircraftRegNoPlaceholder',
            });

            const aircraftRegNoRequired = validators.required(
                intl.formatMessage({
                    id: 'AircraftsForm.aircraftRegNoRequired',
                })
            );

            const mtowLabelMessage = intl.formatMessage({
                id: 'AircraftsForm.mtowLabel',
            });

            const mtowPlaceholder = intl.formatMessage({
                id: 'AircraftsForm.mtowPlaceholder',
            });

            const mtowRequired = validators.required(
                intl.formatMessage({
                    id: 'AircraftsForm.mtowRequired',
                })
            );

            const widthLabelMessage = intl.formatMessage({
                id: 'AircraftsForm.widthLabel',
            });

            const widthPlaceholder = intl.formatMessage({
                id: 'AircraftsForm.widthPlaceholder',
            });

            const widthRequired = validators.required(
                intl.formatMessage({
                    id: 'AircraftsForm.widthRequired',
                })
            );

            const heightLabelMessage = intl.formatMessage({
                id: 'AircraftsForm.heightLabel',
            });

            const heightPlaceholder = intl.formatMessage({
                id: 'AircraftsForm.heightPlaceholder',
            });

            const heightRequired = validators.required(
                intl.formatMessage({
                    id: 'AircraftsForm.heightRequired',
                })
            );

            const wingspanLabelMessage = intl.formatMessage({
                id: 'AircraftsForm.wingspanLabel',
            });

            const wingspanPlaceholder = intl.formatMessage({
                id: 'AircraftsForm.wingspanPlaceholder',
            });

            const wingspanRequired = validators.required(
                intl.formatMessage({
                    id: 'AircraftsForm.wingspanRequired',
                })
            );

            const classes = classNames(css.root, className);
            const submitReady = (updated && pristine) || ready;
            const submitInProgress = updateInProgress;

            const submitDisabled = aircrafts.length < 1 || submitInProgress;
            const addAircraftDisabled = !values.aircraftRegNo || !values.height || !values.makeOptions || !values.mtow || !values.typeOfAircraft || !values.width || !values.wingspan;
            
            const makesArray = AIRCRAFT_MAKES.map(m => { return m.label });
            const uniqMakesArray = [...new Set(makesArray)];

            function addAircraft () {
                setAddAircraftsModalOpen(false);
                if (aircraftEdit) {
                    const newAircrafts = aircrafts.filter(item => item.aircraftRegNo !== aircraftEdit.aircraftRegNo);
                    setAircrafts([...newAircrafts, {aircraftRegNo: values.aircraftRegNo,
                        height: Number(values.height),
                        makeOptions: values.makeOptions,
                        mtow: Number(values.mtow),
                        typeOfAircraft: values.typeOfAircraft,
                        width: Number(values.width),
                        wingspan: Number(values.wingspan)}]);
                } else {
                    const sameRegNo = aircrafts.find(item => item.aircraftRegNo == values.aircraftRegNo);
                    if (sameRegNo) {return alert(`An Aircraft with (${values.aircraftRegNo}) reg no already exists`)}
                    setAircrafts([...aircrafts, {aircraftRegNo: values.aircraftRegNo,
                        height: Number(values.height),
                        makeOptions: values.makeOptions,
                        mtow: Number(values.mtow),
                        typeOfAircraft: values.typeOfAircraft,
                        width: Number(values.width),
                        wingspan: Number(values.wingspan)}
                    ]);
                }
                form.reset();
                setAircraftEdit(null);
            };

            function editAircraft (aircraft) {
                form.change('aircraftRegNo', aircraft.aircraftRegNo);
                form.change('height', aircraft.height);
                form.change('makeOptions', aircraft.makeOptions);
                form.change('mtow', aircraft.mtow);
                form.change('typeOfAircraft', aircraft.typeOfAircraft);
                form.change('width', aircraft.width);
                form.change('wingspan', aircraft.wingspan);

                setAircraftEdit(aircraft);
                setAddAircraftsModalOpen(true);
            };

            function removeAircraft (aircraft) {
                const newAircrafts = aircrafts.filter(item => item.aircraftRegNo !== aircraft.aircraftRegNo);
                setAircrafts(newAircrafts);
            };

            function findAircraftByModel (model) {
                const result = AIRCRAFT_LIST.find(item => item.model == model);
                return result
            };
            
            function convertMessyStringToFloat (str) {
                return parseFloat(str.toString().replace(',', '.'))
            }

            var currentModel = '';

            return (<>
                <Form onSubmit={handleSubmit} className={classes}>
                    <div className={css.oneAircraftTip}>
                        <FormattedMessage id="AircraftsForm.headerTip" />
                    </div>

                    <FormSpy
                        subscription={{ values: true }}
                        onChange={values => {
                            const model = values.values.typeOfAircraft;
                            if (values.values.makeOptions && model && model !== currentModel) {
                                currentModel = model;
                                const aircraft = findAircraftByModel(model);
                                form.batch(() => {
                                    form.change('height', convertMessyStringToFloat(aircraft.height));
                                    form.change('mtow', convertMessyStringToFloat(aircraft.mtowT));
                                    form.change('width', convertMessyStringToFloat(aircraft.lengthM));
                                    form.change('wingspan', convertMessyStringToFloat(aircraft.wingSpanM));
                                })
                            }
                        }}
                    />

                    <div className={css.rangesContainer}>
                        {submitInProgress ? <p>
                            <FormattedMessage id="EditListingPricingForm.loading" />
                        </p> : aircrafts.sort(
                            function (a, b) { return a.mtow - b.mtow }
                        ).map(aircraft => {
                            return <p key={aircraft.aircraftRegNo}>
                                <div className={css.showAircraftTitle}>
                                    {aircraft.makeOptions} {aircraft.typeOfAircraft} ({aircraft.aircraftRegNo})
                                </div>
                                <div className={css.showAircraftInfo}>
                                    Length: {aircraft.width}m² (W) x {aircraft.height}m² (H) | Wingspan : {aircraft.wingspan}m | MTOW: {aircraft.mtow}ton
                                </div>
                                <div>
                                    <span className={css.priceAction} onClick={() => {editAircraft(aircraft)}}><FormattedMessage id="EditListingPricingForm.edit" /></span>
                                    <span className={css.priceAction} onClick={() => {removeAircraft(aircraft)}}><FormattedMessage id="EditListingPricingForm.remove" /></span>
                                </div>
                            </p>
                        })}
                    </div>
                    <div
                        className={css.addRangeDiv}
                        onClick={() => {
                            setAddAircraftsModalOpen(true);
                            form.reset();
                        }}
                    >
                        <FormattedMessage id="AircraftsForm.addAircraftDiv" />
                    </div>

                    <Button
                        className={css.submitButton}
                        type="button"
                        inProgress={submitInProgress}
                        disabled={submitDisabled}
                        ready={submitReady}
                        onClick={() => {onSendData(aircrafts)}}>
                        {saveActionMsg}
                    </Button>
                </Form>
                <Modal
                    id="addAircraftsModal"
                    isOpen={addAircraftsModalOpen}
                    onClose={() => {
                        setAddAircraftsModalOpen(false)
                    }}
                    usePortal
                    onManageDisableScrolling={onManageDisableScrolling} 
                    closeButtonMessage={'Cancel'}>

                    {addAircraftsModalOpen && <div>  
                        <FieldSelect
                            id="makeOptions"
                            name="makeOptions"
                            label={makeOptionsLabelMessage}
                            className={css.priceInput}
                            validate={makeOptionsRequired}
                        >
                            <option value={''}>{makeOptionsPlaceholder}</option>
                            {uniqMakesArray.map((m, i) => {
                                return <option key={i} value={m}>{m}</option>
                            })}
                        </FieldSelect>

                        {values.makeOptions && <FieldSelect
                            id="typeOfAircraft"
                            name="typeOfAircraft"
                            label={typeOfAircraftLabelMessage}
                            className={css.priceInput}
                            validate={typeOfAircraftRequired}
                        >
                            <option value={''}>{typeOfAircraftPlaceholder}</option>
                            {AIRCRAFT_MAKES.map((m, i) => {
                                if (m.label !== values.makeOptions) {return null}
                                return <option key={i} value={m.key}>{m.key}</option>
                            })}
                        </FieldSelect>}
                        
                        <FieldTextInput
                            id="aircraftRegNo"
                            name="aircraftRegNo"
                            type='text'
                            placeholder={aircraftRegNoPlaceholder}
                            label={aircraftRegNoLabelMessage}
                            className={css.priceInput}
                            validate={aircraftRegNoRequired}
                        />

                        <FieldTextInput
                            id="mtow"
                            name="mtow"
                            type='number'
                            placeholder={mtowPlaceholder}
                            label={mtowLabelMessage}
                            className={css.priceInput}
                            validate={mtowRequired}
                        />

                        <div className={css.rangeInputsWrapper}>
                            <FieldTextInput
                                id="width"
                                name="width"
                                type='number'
                                placeholder={widthPlaceholder}
                                label={widthLabelMessage}
                                className={css.rangeInput}
                                validate={widthRequired}
                            />

                            <FieldTextInput
                                id="height"
                                name="height"
                                type='number'
                                placeholder={heightPlaceholder}
                                label={heightLabelMessage}
                                className={css.rangeInput}
                                validate={heightRequired}
                            />
                        </div>

                        <FieldTextInput
                            id="wingspan"
                            name="wingspan"
                            type='number'
                            placeholder={wingspanPlaceholder}
                            label={wingspanLabelMessage}
                            className={css.priceInput}
                            validate={wingspanRequired}
                        />

                        <div className={css.savePriceButtonHolder}>
                            <Button
                                className={css.submitButton}
                                type="button"
                                onClick={() => {addAircraft()}}
                                disabled={addAircraftDisabled}
                            >
                                <FormattedMessage id="AircraftsForm.saveAircraft" />
                            </Button>
                        </div>
                    </div>}
                </Modal>
                </>
            );
        }}
    />
};

AircraftsForm.defaultProps = { };

AircraftsForm.propTypes = {
    intl: intlShape.isRequired,
    onSubmit: func.isRequired,
    saveActionMsg: string.isRequired,
    disabled: bool.isRequired,
    ready: bool.isRequired,
    updated: bool.isRequired,
    updateInProgress: bool.isRequired,
};

export default compose(injectIntl)(AircraftsForm);
