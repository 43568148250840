import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from '../../util/reactIntl';
import classNames from 'classnames';

import { Button, ClientCarousel } from '../../components';
import cli1 from './images/cli1.png';
import cli2 from './images/cli2.png';
import css from './SectionClientTestimonials.module.css';

const SectionClientTestimonials = props => {
    const { rootClassName, className } = props;

    const clients = [
        {image: cli1, name: 'Customer name 1', text: '“Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. “'},
        {image: cli2, name: 'Customer name 2', text: 'lorem lorem lorem'}
    ]

    const classes = classNames(rootClassName || css.root, className);
    return (
        <div className={classes}>
            <div className={css.title}>
                <FormattedMessage id="SectionClientTestimonials.titleLine" />
            </div>

            <div className={css.steps}>
                <ClientCarousel
                    clients={clients}
                />
            </div>
        </div>
    );
};

SectionClientTestimonials.defaultProps = { rootClassName: null, className: null };

const { string } = PropTypes;

SectionClientTestimonials.propTypes = {
    rootClassName: string,
    className: string,
};

export default SectionClientTestimonials;
