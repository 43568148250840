import React, {useState, useEffect} from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { FormattedMessage } from '../../util/reactIntl';
import { LISTING_STATE_DRAFT } from '../../util/types';
import { ListingLink } from '../../components';
import { EditListingPricingForm } from '../../forms';
import { ensureOwnListing } from '../../util/data';
import { types as sdkTypes } from '../../util/sdkLoader';
import config from '../../config';

import css from './EditListingPricingPanel.module.css';

const { Money } = sdkTypes;

const EditListingPricingPanel = props => {
    const {
        className,
        rootClassName,
        listing,
        disabled,
        ready,
        onSubmit,
        onChange,
        submitButtonText,
        panelUpdated,
        updateInProgress,
        errors,
        onManageDisableScrolling,
    } = props;

    const classes = classNames(rootClassName || css.root, className);
    const currentListing = ensureOwnListing(listing);
    const { price, publicData } = currentListing.attributes;
    const [ranges, setRanges] = useState([]);
    const [vatSelected, setVatSelected] = useState(publicData.percentVAT ? true : false);

    useEffect(() => {
        if (!publicData.pricing) {return};
      setRanges(publicData.pricing.map(r => {
        r.newPrice = new Money(
            r.price,
            config.currency
        );
        return r;
    }))
    }, [publicData])

    const isPublished =
        currentListing.id &&
        currentListing.attributes.state !== LISTING_STATE_DRAFT;
    const panelTitle = isPublished ? (
        <FormattedMessage
            id="EditListingPricingPanel.title"
            values={{ listingTitle: <ListingLink listing={listing} /> }}
        />
    ) : (
        <FormattedMessage id="EditListingPricingPanel.createListingTitle" />
    );

    function getLowestPriceRange () {
        const sortedRanges = ranges.sort(function (a, b) {
            return a.price - b.price;
        });
        return sortedRanges[0];
    };

    function getUnitPriceFromRange (number) {
        const range = ranges.find(item => item.rangeFrom <= number && item.rangeTo >= number);
        const price = range?.price;
        return price
    };

    const fixedRanges = [];
    for (let i = 0; i < 201; i++) {
        fixedRanges.push(i)
    };

    const priceCurrencyValid =
        price instanceof Money ? price.currency === config.currency : true;
    const form = priceCurrencyValid ? (
        <EditListingPricingForm
            className={css.form}
            initialValues={{ price, percentVAT: publicData.percentVAT }}
            onSubmit={values => {
                const { percentVAT } = values;
                const getPrice = getLowestPriceRange();
                const actualPrice = new Money(getPrice.price.amount ? getPrice.price.amount : getPrice.price, config.currency);
                const correctRanges = ranges.map(r => {
                    r.currency = r.newPrice ? r.newPrice.currency : r.price.currency;
                    r.price = r.newPrice ? r.newPrice.amount : r.price.amount;
                    r.newPrice = null;
                    return r
                });

                const detailedRanges = fixedRanges.reduce((a, v) => ({ ...a, [`weightRange${v}`]: getUnitPriceFromRange(v)}), {});
                detailedRanges.pricing = correctRanges;
                detailedRanges.percentVAT = percentVAT && vatSelected ? percentVAT : 0;

                const updatedValues = {
                    publicData: detailedRanges,
                    price: actualPrice,
                };
                onSubmit(updatedValues);
            }}
            onChange={onChange}
            saveActionMsg={submitButtonText}
            disabled={disabled}
            ready={ready}
            updated={panelUpdated}
            updateInProgress={updateInProgress}
            fetchErrors={errors}
            onManageDisableScrolling={onManageDisableScrolling}
            ranges={ranges}
            setRanges={setRanges}
            publicData={publicData}
            fixedRanges={fixedRanges}
            vatSelected={vatSelected}
            setVatSelected={setVatSelected}
        />
    ) : (
        <div className={css.priceCurrencyInvalid}>
            <FormattedMessage id="EditListingPricingPanel.listingPriceCurrencyInvalid" />
        </div>
    );

    return (
        <div className={classes}>
            <h1 className={css.title}>{panelTitle}</h1>
            <p className={css.subTitle}>
                <FormattedMessage id="EditListingPricingPanel.createListingSubTitle" />
            </p>
            {form}
        </div>
    );
};

const { func, object, string, bool } = PropTypes;

EditListingPricingPanel.defaultProps = {
    className: null,
    rootClassName: null,
    listing: null,
};

EditListingPricingPanel.propTypes = {
    className: string,
    rootClassName: string,

    // We cannot use propTypes.listing since the listing might be a draft.
    listing: object,

    disabled: bool.isRequired,
    ready: bool.isRequired,
    onSubmit: func.isRequired,
    onChange: func.isRequired,
    submitButtonText: string.isRequired,
    panelUpdated: bool.isRequired,
    updateInProgress: bool.isRequired,
    errors: object.isRequired,
};

export default EditListingPricingPanel;
