import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

const IconRuler = props => {
    const { className, rootClassName } = props;
    const classes = classNames(rootClassName, className);

    return (
        <svg
            className={classes}
            width="14"
            height="14"
            viewBox="0 0 14 14"
            fill="none"
            xmlns="http://www.w3.org/2000/svg">
            <path
                d="M0.237183 0.23584V13.6512H13.6604V8.62045H5.2709V0.23584H0.237183ZM1.35579 1.35379H4.1523V2.47174H2.47439V3.58969H4.1523V4.70763H2.47439V5.82558H4.1523V6.94353H2.47439V8.06148H4.1523V8.94801L1.35579 11.7429V1.35379ZM4.94315 9.7384H5.83021V11.4153H6.94881V9.7384H8.06742V11.4153H9.18602V9.7384H10.3046V11.4153H11.4232V9.7384H12.5418V12.5333H2.14664L4.94315 9.7384Z"
                fill="#808080"
            />
        </svg>
    );
};

const { string } = PropTypes;

IconRuler.defaultProps = {
    className: null,
    rootClassName: null,
};

IconRuler.propTypes = {
    className: string,
    rootClassName: string,
};

export default IconRuler;
