import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from '../../util/reactIntl';
import classNames from 'classnames';

import { NamedLink, Button } from '../../components';
import step1 from './images/step1.svg';
import step2 from './images/step2.svg';
import step3 from './images/step3.svg';
import step4 from './images/step4.svg';
import step5 from './images/step5.svg';
import css from './SectionHeroFeatures.module.css';

const SectionHeroFeatures = props => {
    const { rootClassName, className } = props;

    const classes = classNames(rootClassName || css.root, className);
    return (
        <div className={classes}>
            <div className={css.title}>
                <FormattedMessage id="SectionHeroFeatures.titleLine1" />
                <span style={{color: '#30B3CA'}}><FormattedMessage id="SectionHeroFeatures.titleLine2" /></span>
                <FormattedMessage id="SectionHeroFeatures.titleLine3" />
            </div>

            <div className={css.steps}>
                <div className={css.step}>
                    <div className={css.imageContainer}>
                        <img src={step1}></img>
                    </div>
                    <h2 className={css.stepTitle}>
                        <FormattedMessage id="SectionHeroFeatures.part1Title" />
                    </h2>
                </div>

                <div className={css.step}>
                    <div className={css.imageContainer}>
                        <img src={step2}></img>
                    </div>
                    <h2 className={css.stepTitle}>
                        <FormattedMessage id="SectionHeroFeatures.part2Title" />
                    </h2>
                </div>

                <div className={css.step}>
                    <div className={css.imageContainer}>
                        <img src={step3}></img>
                    </div>
                    <h2 className={css.stepTitle}>
                        <FormattedMessage id="SectionHeroFeatures.part3Title" />
                    </h2>
                </div>
                <div className={css.step}>
                    <div className={css.imageContainer}>
                        <img src={step4}></img>
                    </div>
                    <h2 className={css.stepTitle}>
                        <FormattedMessage id="SectionHeroFeatures.part4Title" />
                    </h2>
                </div>
                <div className={css.step}>
                    <div className={css.imageContainer}>
                        <img src={step5}></img>
                    </div>
                    <h2 className={css.stepTitle}>
                        <FormattedMessage id="SectionHeroFeatures.part5Title" />
                    </h2>
                </div>
            </div>

            <div className={css.aboutPageLink}>
                <Button className={css.aboutPageButton}>
                    <NamedLink name="AboutPage">
                        <FormattedMessage id="SectionHeroFeatures.findMoreLink" />
                    </NamedLink>
                </Button>
            </div>
        </div>
    );
};

SectionHeroFeatures.defaultProps = { rootClassName: null, className: null };

const { string } = PropTypes;

SectionHeroFeatures.propTypes = {
    rootClassName: string,
    className: string,
};

export default SectionHeroFeatures;
