import React, { useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import ReactImageGallery from 'react-image-gallery';

import { propTypes } from '../../util/types';
import { injectIntl, intlShape } from '../../util/reactIntl';
import { IconArrowHead } from '../../components';

import './image-gallery.css';

import css from './ClientCarousel.module.css';

const IMAGE_GALLERY_OPTIONS = {
    showPlayButton: false,
    disableThumbnailScroll: true,
    showThumbnails: false,
    showFullscreenButton: false,
    slideDuration: 400,
};

const ListingImageGallery = props => {
    const [currentIndex, setIndex] = useState(0);
    const { intl, rootClassName, className, clients } = props;

    const items = clients.map((cli, i) => {
        return {
            original: '',
            alt: intl.formatMessage(
                { id: 'ImageCarousel.imageAltText' },
                { index: i + 1, count: clients.length }
            ),
            image: cli.image,
            name: cli.name,
            text: cli.text,

        };
    });
    const renderItem = item => {
        return (
            <div className={css.step}>
                <div className={css.imageContainer}>
                    <img
                        src={item.image}
                        alt={item.alt}
                    ></img>
                </div>
                <h2 className={css.stepTitle}>
                    {item.name}
                </h2>
                <p className={css.textContainer}>{item.text}</p>
            </div>
        );
    };

    const renderLeftNav = (onClick, disabled) => {
        return (
            <button
                className={css.navLeft}
                disabled={disabled}
                onClick={onClick}>
                <div className={css.navArrowWrapper}>
                    <IconArrowHead
                        direction="left"
                        size="big"
                        className={css.arrowHead}
                    />
                </div>
            </button>
        );
    };
    const renderRightNav = (onClick, disabled) => {
        return (
            <button
                className={css.navRight}
                disabled={disabled}
                onClick={onClick}>
                <div className={css.navArrowWrapper}>
                    <IconArrowHead
                        direction="right"
                        size="big"
                        className={css.arrowHead}
                    />
                </div>
            </button>
        );
    };

    // If no image is given, rendere empty image.
    if (items.length === 0) {
        const classes = classNames(rootClassName || css.noImage, className);
        return (
            <img
                className={classes}
                image={null}
                alt=""
            ></img>
        );
    }

    const handleSlide = currentIndex => {
        setIndex(currentIndex);
    };
    const naturalIndex = index => index + 1;

    const imageIndex =
        items.length > 0 ? (
            <span className={css.imageIndex}>
                {naturalIndex(currentIndex)}/{items.length}
            </span>
        ) : null;

    const classes = classNames(rootClassName || css.root, className);

    return (
        <>
            <ReactImageGallery
                additionalClass={classes}
                items={items}
                renderItem={renderItem}
                renderLeftNav={renderLeftNav}
                renderRightNav={renderRightNav}
                onSlide={handleSlide}
                {...IMAGE_GALLERY_OPTIONS}
            />
            {imageIndex}
        </>
    );
};

ListingImageGallery.defaultProps = {
    rootClassName: null,
    className: null,
};

const { string, arrayOf } = PropTypes;

ListingImageGallery.propTypes = {
    rootClassName: string,
    className: string,
    clients: arrayOf(propTypes.image).isRequired,
    imageVariants: arrayOf(string).isRequired,

    // from injectIntl
    intl: intlShape.isRequired,
};

export default injectIntl(ListingImageGallery);
