import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import css from './IconSocialMediaTwitter.module.css';

const IconSocialMediaTwitter = props => {
    const { rootClassName, className } = props;
    const classes = classNames(rootClassName || css.root, className);
    return (
        <svg
            className={classes}
            width="16"
            height="16"
            viewBox="0 0 16 16"
            xmlns="http://www.w3.org/2000/svg">
            <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M6.02854 5.68504H8.79702V7.06406C9.19582 6.27094 10.2185 5.55832 11.7548 5.55832C14.7 5.55832 15.3992 7.13712 15.3992 10.0338V15.3986H12.4175V10.6935C12.4175 9.04389 12.0187 8.11361 11.0035 8.11361C9.59536 8.11361 9.01021 9.1162 9.01021 10.6928V15.3986H6.02854V5.68504ZM0.915732 15.2718H3.8974V5.55832H0.915732V15.2718ZM4.32452 2.39105C4.32463 2.64097 4.27507 2.88842 4.17871 3.11901C4.08235 3.34961 3.94112 3.55875 3.76322 3.73429C3.40274 4.09255 2.9148 4.29309 2.40657 4.29186C1.89923 4.29152 1.41241 4.09149 1.0514 3.73503C0.874146 3.5589 0.733393 3.34952 0.637203 3.1189C0.541012 2.88827 0.491276 2.64093 0.490845 2.39105C0.490845 1.8864 0.692107 1.40337 1.05214 1.04706C1.41284 0.690124 1.89986 0.490011 2.40731 0.490235C2.91569 0.490235 3.40319 0.690752 3.76322 1.04706C4.12251 1.40337 4.32452 1.8864 4.32452 2.39105Z"
            />
        </svg>
    );
};

IconSocialMediaTwitter.defaultProps = { rootClassName: null, className: null };

const { string } = PropTypes;

IconSocialMediaTwitter.propTypes = { rootClassName: string, className: string };

export default IconSocialMediaTwitter;
