import React from 'react';
import { bool, func, shape, string } from 'prop-types';
import classNames from 'classnames';
import { Form as FinalForm } from 'react-final-form';
import arrayMutators from 'final-form-arrays';
import { FormattedMessage } from '../../util/reactIntl';
import { findOptionsForSelectFilter } from '../../util/search';
import { required, composeValidators } from '../../util/validators';
import { propTypes } from '../../util/types';
import config from '../../config';
import { Button, FieldTextInput, FieldSelect, Form } from '../../components';
import css from './EditListingFeaturesForm.module.css';
import FieldRangeSlider from '../../components/FieldRangeSlider/FieldRangeSlider';
import { AIRCRAFT_MAKES } from '../../data/aircrafts';

const EditListingFeaturesFormComponent = props => (
    <FinalForm
        {...props}
        mutators={{ ...arrayMutators }}
        render={formRenderProps => {
            const {
                disabled,
                ready,
                rootClassName,
                className,
                name,
                handleSubmit,
                pristine,
                saveActionMsg,
                updated,
                updateInProgress,
                fetchErrors,
                filterConfig,
                values,
            } = formRenderProps;

            const classes = classNames(rootClassName || css.root, className);
            const submitReady = (updated && pristine) || ready;
            const submitInProgress = updateInProgress;
            const submitDisabled = disabled || submitInProgress;

            const { updateListingError, showListingsError } = fetchErrors || {};
            const errorMessage = updateListingError ? (
                <p className={css.error}>
                    <FormattedMessage id="EditListingFeaturesForm.updateFailed" />
                </p>
            ) : null;

            const errorMessageShowListing = showListingsError ? (
                <p className={css.error}>
                    <FormattedMessage id="EditListingFeaturesForm.showListingFailed" />
                </p>
            ) : null;

            const typeOfAccommodationLabel = <FormattedMessage id="EditListingFeaturesForm.typeOfAccommodation" />;
            const typeOfAccommodationRequired = <FormattedMessage id="EditListingFeaturesForm.typeOfAccommodationRequired" />;

            const numberOfAvailableSpaceLabel = <FormattedMessage id="EditListingFeaturesForm.numberOfAvailableSpace" />;
            const numberOfAvailableSpaceRequired = <FormattedMessage id="EditListingFeaturesForm.numberOfAvailableSpaceRequired" />;

            const hangarPurposeLabel = <FormattedMessage id="EditListingFeaturesForm.hangarPurpose" />;
            const hangarPurposeRequired = <FormattedMessage id="EditListingFeaturesForm.hangarPurposeRequired" />;

            const buildingYearLabel = <FormattedMessage id="EditListingFeaturesForm.buildingYear" />;
            const buildingYearRequired = <FormattedMessage id="EditListingFeaturesForm.buildingYearRequired" />;

            const buildingLengthLabel = <FormattedMessage id="EditListingFeaturesForm.buildingLength" />;
            const buildingLengthRequired = <FormattedMessage id="EditListingFeaturesForm.buildingLengthRequired" />;

            const buildingWidthLabel = <FormattedMessage id="EditListingFeaturesForm.buildingWidth" />;
            const buildingWidthRequired = <FormattedMessage id="EditListingFeaturesForm.buildingWidthRequired" />;

            const buildingHeightLabel = <FormattedMessage id="EditListingFeaturesForm.buildingHeight" />;
            const buildingHeightRequired = <FormattedMessage id="EditListingFeaturesForm.buildingHeightRequired" />;

            const maxMTOWLabel = <FormattedMessage id="EditListingFeaturesForm.maxMTOW" />;

            const thingsToKnowLabel = <FormattedMessage id="EditListingFeaturesForm.thingsToKnow" />;
            const thingsToKnowRequired = <FormattedMessage id="EditListingFeaturesForm.thingsToKnowRequired" />;

            const restrictionsLabel = <FormattedMessage id="EditListingFeaturesForm.restrictions" />;
            const restrictionsRequired = <FormattedMessage id="EditListingFeaturesForm.restrictionsRequired" />;

            const makeOptionsLabelMessage = <FormattedMessage id="AircraftsForm.makeOptionsLabel" />;
            const makeOptionsPlaceholder = 'Choose Make ...';
            const makeOptionsLabelRequired = <FormattedMessage id="AircraftsForm.makeOptionsRequired" />;

            const typeOfAircraftLabelMessage = <FormattedMessage id="AircraftsForm.typeOfAircraftLabel" />;
            const typeOfAircraftPlaceholder = 'Choose type ...';
            const typeOfAircraftLabelRequired = <FormattedMessage id="AircraftsForm.typeOfAircraftRequired" />;

            const makesArray = AIRCRAFT_MAKES.map(m => { return m.label });
            const uniqMakesArray = [...new Set(makesArray)];

            return (
                <Form className={classes} onSubmit={handleSubmit}>
                    {errorMessage}
                    {errorMessageShowListing}

                    <FieldTextInput
                        id="typeOfAccommodation"
                        name="typeOfAccommodation"
                        className={css.features}
                        type="text"
                        label={typeOfAccommodationLabel}
                        placeholder={'Hangar'}
                        validate={composeValidators(
                            required(typeOfAccommodationRequired)
                        )}
                        autoFocus
                    />

                    <FieldTextInput
                        id="numberOfAvailableSpace"
                        name="numberOfAvailableSpace"
                        className={css.features}
                        type="number"
                        label={numberOfAvailableSpaceLabel}
                        placeholder={'available space'}
                        validate={composeValidators(
                            required(numberOfAvailableSpaceRequired)
                        )}
                    />

                    <div className={css.duelInputContainer}>
                        <FieldSelect
                            id={'hangarPurpose'}
                            name={'hangarPurpose'}
                            className={css.halfInput}
                            label={hangarPurposeLabel}
                            validate={composeValidators(
                                required(hangarPurposeRequired)
                            )}>
                            <option disabled value="">
                                {'parking, maintenance...'}
                            </option>
                            <option key={'parking'} value={'Parking'}>
                                {'Parking'}
                            </option>
                            <option key={'maintenance'} value={'Maintenance'}>
                                {'Maintenance'}
                            </option>
                            <option key={'parking and maintenance'} value={'Parking and Maintenance'}>
                                {'Parking and Maintenance'}
                            </option>
                        </FieldSelect>

                        <FieldTextInput
                            id="buildingYear"
                            name="buildingYear"
                            className={css.halfInput}
                            type="number"
                            label={buildingYearLabel}
                            placeholder={'yyyy'}
                            validate={composeValidators(
                                required(buildingYearRequired)
                            )}
                        />
                    </div>

                    <FieldTextInput
                        id="buildingLength"
                        name="buildingLength"
                        className={css.features}
                        type="number"
                        label={buildingLengthLabel}
                        placeholder={'in squaremeter'}
                        validate={composeValidators(
                            required(buildingLengthRequired)
                        )}
                    />

                    <div className={css.duelInputContainer}>
                        <FieldTextInput
                            id="buildingWidth"
                            name="buildingWidth"
                            className={css.halfInput}
                            type="number"
                            label={buildingWidthLabel}
                            placeholder={'in squaremeter'}
                            validate={composeValidators(
                                required(buildingWidthRequired)
                            )}
                        />

                        <FieldTextInput
                            id="buildingHeight"
                            name="buildingHeight"
                            className={css.halfInput}
                            type="number"
                            label={buildingHeightLabel}
                            placeholder={'in squaremeter'}
                            validate={composeValidators(
                                required(buildingHeightRequired)
                            )}
                        />
                    </div>

                    <div className={css.duelInputContainer}>
                        <div className={css.halfInput}>
                            <FieldRangeSlider
                                id="maxMTOW"
                                name="maxMTOW"
                                label={maxMTOWLabel}
                                min={0}
                                max={200}
                                step={1}
                                handles={[0]}
                            />
                            <span style={{fontSize: '13px'}}>
                                Selected Max MTOW: <span style={{color: '#30B3CA'}}>
                                    {values.maxMTOW}
                                </span> ton.
                            </span>
                        </div>
                    </div>

                    <h3 className={css.additionalInfo}>
                        <FormattedMessage id="EditListingFeaturesForm.maxFitAircraft" />
                    </h3>

                    <div className={css.duelInputContainer}>
                        <FieldSelect
                            id="makeOptions"
                            name="makeOptions"
                            label={makeOptionsLabelMessage}
                            className={css.halfInput}
                            validate={composeValidators(
                                required(makeOptionsLabelRequired)
                            )}
                        >
                            <option value={''}>{makeOptionsPlaceholder}</option>
                            {uniqMakesArray.map((m, i) => {
                                return <option key={i} value={m}>{m}</option>
                            })}
                        </FieldSelect>

                        <FieldSelect
                            id="typeOfAircraft"
                            name="typeOfAircraft"
                            label={typeOfAircraftLabelMessage}
                            className={css.halfInput}
                            validate={composeValidators(
                                required(typeOfAircraftLabelRequired)
                            )}
                        >
                            <option value={''}>{typeOfAircraftPlaceholder}</option>
                            {AIRCRAFT_MAKES.map((m, i) => {
                                if (m.label !== values.makeOptions) {return null}
                                return <option key={i} value={m.key}>{m.key}</option>
                            })}
                        </FieldSelect>
                    </div>

                    <h3 className={css.additionalInfo}>
                        <FormattedMessage id="EditListingFeaturesForm.additionalInfo" />
                    </h3>

                    <FieldTextInput
                        id="thingsToKnow"
                        name="thingsToKnow"
                        className={css.features}
                        type="text"
                        label={thingsToKnowLabel}
                        placeholder={'any additional information'}
                        validate={composeValidators(
                            required(thingsToKnowRequired)
                        )}
                    />

                    <FieldTextInput
                        id="restrictions"
                        name="restrictions"
                        className={css.features}
                        type="text"
                        label={restrictionsLabel}
                        placeholder={'type hangar restrictions'}
                        validate={composeValidators(
                            required(restrictionsRequired)
                        )}
                    />

                    <Button
                        className={css.submitButton}
                        type="submit"
                        inProgress={submitInProgress}
                        disabled={submitDisabled}
                        ready={submitReady}>
                        {saveActionMsg}
                    </Button>
                </Form>
            );
        }}
    />
);

EditListingFeaturesFormComponent.defaultProps = {
    rootClassName: null,
    className: null,
    fetchErrors: null,
    filterConfig: config.custom.filters,
};

EditListingFeaturesFormComponent.propTypes = {
    rootClassName: string,
    className: string,
    name: string.isRequired,
    onSubmit: func.isRequired,
    saveActionMsg: string.isRequired,
    disabled: bool.isRequired,
    ready: bool.isRequired,
    updated: bool.isRequired,
    updateInProgress: bool.isRequired,
    fetchErrors: shape({
        showListingsError: propTypes.error,
        updateListingError: propTypes.error,
    }),
    filterConfig: propTypes.filterConfig,
};

const EditListingFeaturesForm = EditListingFeaturesFormComponent;

export default EditListingFeaturesForm;
