import React, { useState } from 'react';
import { bool, func, shape, string, array } from 'prop-types';
import { compose } from 'redux';
import { Form as FinalForm, Field } from 'react-final-form';
import { intlShape, injectIntl, FormattedMessage } from '../../util/reactIntl';
import classNames from 'classnames';
import { propTypes } from '../../util/types';
import config from '../../config';
import { Form, Button } from '../../components';
import axios from 'axios';
import css from './EditListingDocumentationForm.module.css';

if (typeof window !== 'undefined') {
    window.Buffer = window.Buffer || require('buffer').Buffer;
}

export const EditListingDocumentationFormComponent = props => {
    const [loading, setLoading] = useState(false);

    const documents = [
        { name: 'facilityPlan', label: 'Facility plan / blueprint *' },
        { name: 'insuranceCertificate', label: 'Insurance certificate *' },
        { name: 'operatingPolicies', label: 'Operating policies' },
        { name: 'businessPermit', label: 'Business permit' },
        { name: 'otherDocument', label: 'Other document' },
    ];

    function onImageUploadHandler(file, action, form) {
        setLoading(true);
        if (file) {
            const data = new FormData();
            data.append('name', file.name);
            data.append('file', file);
            axios.post(`/api/uploadFile`, data, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            })
                .then(response => {
                    form.change(action, {
                        name: file.name,
                        location: response.data.Location,
                        key: response.data.Key,
                    });
                    setLoading(false);
                })
                .catch(err => {
                    console.error('err', err);
                    setLoading(false);
                })
        }
    }

    return (
        <FinalForm
            {...props}
            render={formRenderProps => {
                const {
                    className,
                    disabled,
                    ready,
                    handleSubmit,
                    invalid,
                    pristine,
                    saveActionMsg,
                    updated,
                    updateInProgress,
                    fetchErrors,
                    form,
                    values,
                } = formRenderProps;

                const { updateListingError, showListingsError } =
                    fetchErrors || {};
                const errorMessage = updateListingError ? (
                    <p className={css.error}>
                        <FormattedMessage id="EditListingPoliciesForm.updateFailed" />
                    </p>
                ) : null;
                const errorMessageShowListing = showListingsError ? (
                    <p className={css.error}>
                        <FormattedMessage id="EditListingPoliciesForm.showListingFailed" />
                    </p>
                ) : null;

                const classes = classNames(css.root, className);
                const submitReady = (updated && pristine) || ready;
                const submitInProgress = updateInProgress;
                const submitDisabled =
                    invalid ||
                    disabled ||
                    submitInProgress ||
                    documentsNotComplete();

                function documentsNotComplete() {
                    if (
                        values &&
                        values.insuranceCertificate &&
                        values.facilityPlan
                    ) {
                        return false;
                    }
                    return true;
                }

                return (
                    <>
                        {loading ? (
                            <h2>
                                <FormattedMessage id="EditListingPricingForm.loading" />
                            </h2>
                        ) : (
                            <Form className={classes} onSubmit={handleSubmit}>
                                {errorMessage}
                                {errorMessageShowListing}

                                {documents.map(doc => {
                                    return (
                                        <Field
                                            id={doc.name}
                                            name={doc.name}
                                            form={null}
                                            label={doc.label}
                                            type="file"
                                            disabled={false}>
                                            {fieldprops => {
                                                const {
                                                    accept,
                                                    input,
                                                    label,
                                                    disabled: fieldDisabled,
                                                } = fieldprops;
                                                const { name, type } = input;
                                                const onChange = e => {
                                                    const file =
                                                        e.target.files[0];
                                                    form.change(doc.name, file);
                                                    form.blur(doc.name);
                                                    onImageUploadHandler(
                                                        file,
                                                        doc.name,
                                                        form
                                                    );
                                                };
                                                const inputProps = {
                                                    accept: 'image/*, application/pdf',
                                                    id: name,
                                                    name,
                                                    onChange,
                                                    type,
                                                };
                                                return (
                                                    <div
                                                        className={
                                                            css.addImageWrapper
                                                        }>
                                                        <div
                                                            className={
                                                                css.aspectRatioWrapper
                                                            }>
                                                            <label
                                                                htmlFor={name}
                                                                className={
                                                                    css.addImage
                                                                }>
                                                                {label}
                                                            </label>
                                                            {values[
                                                                doc.name
                                                            ] ? (
                                                                <div
                                                                    className={
                                                                        css.fileNameWrapper
                                                                    }>
                                                                    <div>
                                                                        {values[doc.name].name?.substring(0, 24)}
                                                                    </div>
                                                                    <div
                                                                        className={
                                                                            css.deleteFile
                                                                        }
                                                                        onClick={() => {
                                                                            form.change(
                                                                                doc.name,
                                                                                null
                                                                            );
                                                                            form.blur(
                                                                                doc.name
                                                                            );
                                                                        }}>
                                                                        x
                                                                    </div>
                                                                </div>
                                                            ) : fieldDisabled ? null : (
                                                                <input
                                                                    {...inputProps}
                                                                    className={
                                                                        css.addImageInput
                                                                    }
                                                                />
                                                            )}
                                                        </div>
                                                    </div>
                                                );
                                            }}
                                        </Field>
                                    );
                                })}
                                <p><FormattedMessage id="EditListingPricingForm.supportedFiles" /></p>
                                <Button
                                    className={css.submitButton}
                                    type="submit"
                                    inProgress={submitInProgress}
                                    disabled={submitDisabled}
                                    ready={submitReady}>
                                    {saveActionMsg}
                                </Button>
                            </Form>
                        )}
                    </>
                );
            }}
        />
    );
};

EditListingDocumentationFormComponent.defaultProps = {
    selectedPlace: null,
    updateError: null,
    filterConfig: config.custom.filters,
};

EditListingDocumentationFormComponent.propTypes = {
    intl: intlShape.isRequired,
    onSubmit: func.isRequired,
    saveActionMsg: string.isRequired,
    selectedPlace: propTypes.place,
    disabled: bool.isRequired,
    ready: bool.isRequired,
    updated: bool.isRequired,
    updateInProgress: bool.isRequired,
    fetchErrors: shape({
        showListingsError: propTypes.error,
        updateListingError: propTypes.error,
    }),
    filterConfig: propTypes.filterConfig,
    onImageUpload: func.isRequired,
    images: array,
};

export default compose(injectIntl)(EditListingDocumentationFormComponent);
