import React, { Component } from 'react';
import { string, bool, arrayOf, array, func } from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { Form as FinalForm, FormSpy } from 'react-final-form';
import classNames from 'classnames';
import moment from 'moment';
import config from '../../config';
import { FormattedMessage, intlShape, injectIntl } from '../../util/reactIntl';
import {
    required,
    bookingDatesRequired,
    composeValidators,
} from '../../util/validators';
import * as validators from '../../util/validators';
import { START_DATE, END_DATE } from '../../util/dates';
import { propTypes } from '../../util/types';
import {
    Form,
    IconSpinner,
    PrimaryButton,
    FieldDateRangeInput,
    FieldSelect,
    FieldTextInput,
    FieldRadioButton,
    Tooltip,
} from '../../components';
import EstimatedBreakdownMaybe from './EstimatedBreakdownMaybe';
import arrayMutators from 'final-form-arrays';
import { AIRCRAFT_LIST, AIRCRAFT_MAKES, AIRCRAFT_MTOWS } from '../../data/aircrafts';
import { updateProfile } from '../../containers/ProfileSettingsPage/ProfileSettingsPage.duck';
import css from './BookingDatesForm.module.css';

const identity = v => v;

export class BookingDatesFormComponent extends Component {
    constructor(props) {
        super(props);
        this.state = { focusedInput: null, AcData: null, currentModel: null, largeMtowError: null };
        this.handleFormSubmit = this.handleFormSubmit.bind(this);
        this.onFocusedInputChange = this.onFocusedInputChange.bind(this);
        this.handleOnChange = this.handleOnChange.bind(this);
        this.shouldHideAircraft = this.shouldHideAircraft.bind(this);
    }

    shouldHideAircraft (aircraftWeight) {
        const ranges = this.props.listing.attributes.publicData.pricing;
        const result = ranges.find(
            item =>
                item.rangeFrom <= aircraftWeight &&
                item.rangeTo >= aircraftWeight
        );
        if (!result) {
            return true;
        } else {
            return false;
        }
    }

    onFocusedInputChange(focusedInput) {
        this.setState({ focusedInput });
    }

    handleFormSubmit(e) {
        const { startDate, endDate } = e.bookingDates || {};
        if (!startDate) {
            e.preventDefault();
            this.setState({ focusedInput: START_DATE });
        } else if (!endDate) {
            e.preventDefault();
            this.setState({ focusedInput: END_DATE });
        } else if (!e.bookingStartTime || !e.bookingEndTime) {
            return null;
        } else if (!e.aircraft && !e.typeOfAircraft) {
            console.log('No Aircraft Added To Booking');
        } else {
            const theAircraft = this.state.AcData;
            if (e.addNewAircraft && theAircraft) {
                const userAircrafts = this.props.currentUser?.attributes.profile.publicData?.aircrafts || [];
                userAircrafts.push(theAircraft);
                this.props.onUpdateProfile({publicData: {aircrafts: userAircrafts}});
            }
            e.aircraft = theAircraft;
            this.props.onSubmit(e);
        }
    }

    handleOnChange(formValues, currentUser, form) {
        const model = formValues.values.typeOfAircraft;
        if (formValues.values.makeOptions && model && model !== this.state.currentModel) {
            const aircraft = AIRCRAFT_MTOWS.find(item => item.key == model);
            if (this.shouldHideAircraft(aircraft.label)) {
                form.change('mtow', null);
                form.change('typeOfAircraft', null);
                form.change('makeOptions', null);
                this.setState({largeMtowError: true});
            } else {
                this.setState({currentModel: model, largeMtowError: null});
                form.batch(() => {
                    form.change('mtow', parseFloat(aircraft.label.toString().replace(',', '.')));
                })
            }
        }

        if (formValues.values.addNewAircraft) {form.change('aircraft', null)}
        else {
            form.change('makeOptions', null);
            form.change('typeOfAircraft', null);
            form.change('aircraftRegNo', null);
            form.change('mtow', null);
        };

        const { startDate, endDate } =
            formValues.values && formValues.values.bookingDates
                ? formValues.values.bookingDates
                : {};
        const { bookingStartTime, bookingEndTime } = formValues.values
            ? formValues.values
            : {};

        const draftAircraft = formValues.values.typeOfAircraft ? AIRCRAFT_LIST.find(a => a.model == formValues.values.typeOfAircraft) : {};
        const modifiedAircraft = {
            "aircraftRegNo": formValues.values.aircraftRegNo,
            "height": parseFloat(draftAircraft.height?.toString().replace(',', '.')),
            "makeOptions": draftAircraft.make,
            "mtow": parseFloat(draftAircraft.toString().mtowT),
            "typeOfAircraft": draftAircraft.model,
            "width": parseFloat(draftAircraft.lengthM?.toString().replace(',', '.')),
            "wingspan": parseFloat(draftAircraft.wingSpanM?.toString().replace(',', '.'))
        };

        const theAircraft = formValues.values.aircraft ?
            currentUser?.attributes.profile.publicData?.aircrafts?.find(
                a => a.aircraftRegNo == formValues.values.aircraft
            ) : modifiedAircraft;
        this.setState({ AcData: theAircraft });

        const aircraftWeight = theAircraft.mtow || formValues.values.mtow;
        const listingId = this.props.listingId;
        const isOwnListing = this.props.isOwnListing;

        if (
            startDate &&
            endDate &&
            aircraftWeight &&
            bookingStartTime &&
            bookingEndTime &&
            !this.props.fetchLineItemsInProgress
        ) {
            const formatedStartDate = new Date(
                startDate.setHours(bookingStartTime)
            );
            const formatedEndDate = new Date(endDate.setHours(bookingEndTime));
            this.props.onFetchTransactionLineItems({
                bookingData: {
                    startDate: formatedStartDate,
                    endDate: formatedEndDate,
                    aircraftWeight: aircraftWeight,
                },
                listingId,
                isOwnListing,
            });
        }
    }

    render() {
        const {
            rootClassName,
            className,
            onUpdateProfile,
            price: unitPrice,
            ...rest
        } = this.props;
        const classes = classNames(rootClassName || css.root, className);

        if (!unitPrice) {
            return (
                <div className={classes}>
                    <p className={css.error}>
                        <FormattedMessage id="BookingDatesForm.listingPriceMissing" />
                    </p>
                </div>
            );
        }
        if (unitPrice.currency !== config.currency) {
            return (
                <div className={classes}>
                    <p className={css.error}>
                        <FormattedMessage id="BookingDatesForm.listingCurrencyInvalid" />
                    </p>
                </div>
            );
        }

        return (
            <FinalForm
                {...rest}
                mutators={{ ...arrayMutators }}
                unitPrice={unitPrice}
                onSubmit={this.handleFormSubmit}
                render={fieldRenderProps => {
                    const {
                        endDatePlaceholder,
                        startDatePlaceholder,
                        formId,
                        handleSubmit,
                        intl,
                        isOwnListing,
                        listing,
                        submitButtonWrapperClassName,
                        unitType,
                        values,
                        timeSlots,
                        fetchTimeSlotsError,
                        lineItems,
                        fetchLineItemsInProgress,
                        fetchLineItemsError,
                        monthlyTimeSlots,
                        timeZone,
                        currentUser,
                        form,
                        listingId,
                        onFetchTimeSlots = () => {},
                        pristine,
                    } = fieldRenderProps;
                    const { startDate, endDate } =
                        values && values.bookingDates
                            ? values.bookingDates
                            : {};

                    const bookingStartLabel = intl.formatMessage({
                        id: 'BookingDatesForm.bookingStartTitle',
                    });
                    const bookingEndLabel = intl.formatMessage({
                        id: 'BookingDatesForm.bookingEndTitle',
                    });

                    const requiredMessage = intl.formatMessage({
                        id: 'BookingDatesForm.requiredDate',
                    });
                    const startDateErrorMessage = intl.formatMessage({
                        id: 'FieldDateRangeInput.invalidStartDate',
                    });
                    const endDateErrorMessage = intl.formatMessage({
                        id: 'FieldDateRangeInput.invalidEndDate',
                    });
                    const timeSlotsError = fetchTimeSlotsError ? (
                        <p className={css.sideBarError}>
                            <FormattedMessage id="BookingDatesForm.timeSlotsError" />
                        </p>
                    ) : null;

                    const largeMtowError = this.state.largeMtowError ? (
                        <p className={css.sideBarError}>
                            <FormattedMessage id="BookingDatesForm.largeMtowError" />
                        </p>
                    ) : null;

                    const addNewAircraftLabel = intl.formatMessage({
                        id: 'BookingDatesForm.addNewAircraftLabel',
                    });

                    const selectAircraftLabel = intl.formatMessage({
                        id: 'BookingDatesForm.selectAircraftTitle',
                    });
                    const makeOptionsLabelMessage = intl.formatMessage({
                        id: 'AircraftsForm.makeOptionsLabel',
                    });
                    const makeOptionsPlaceholder = intl.formatMessage({
                        id: 'AircraftsForm.makeOptionsPlaceholder',
                    });
                    const makeOptionsRequired = validators.required(
                        intl.formatMessage({
                            id: 'AircraftsForm.makeOptionsRequired',
                        })
                    );
        
                    const typeOfAircraftLabelMessage = intl.formatMessage({
                        id: 'AircraftsForm.typeOfAircraftLabel',
                    });
                    const typeOfAircraftPlaceholder = intl.formatMessage({
                        id: 'AircraftsForm.typeOfAircraftPlaceholder',
                    });
                    const typeOfAircraftRequired = validators.required(
                        intl.formatMessage({
                            id: 'AircraftsForm.typeOfAircraftRequired',
                        })
                    );
        
                    const aircraftRegNoLabelMessage = intl.formatMessage({
                        id: 'AircraftsForm.aircraftRegNoLabel',
                    });
                    const aircraftRegNoPlaceholder = intl.formatMessage({
                        id: 'AircraftsForm.aircraftRegNoPlaceholder',
                    });
                    const aircraftRegNoRequired = validators.required(
                        intl.formatMessage({
                            id: 'AircraftsForm.aircraftRegNoRequired',
                        })
                    );
        
                    const mtowLabelMessage = intl.formatMessage({
                        id: 'AircraftsForm.mtowLabel',
                    });
                    const mtowPlaceholder = intl.formatMessage({
                        id: 'AircraftsForm.mtowPlaceholder',
                    });
                    const mtowRequired = validators.required(
                        intl.formatMessage({
                            id: 'AircraftsForm.mtowRequired',
                        })
                    );

                    const bookingData =
                        startDate && endDate
                            ? {
                                  unitType,
                                  startDate,
                                  endDate,
                              }
                            : null;

                    const showEstimatedBreakdown =
                        bookingData &&
                        lineItems &&
                        !fetchLineItemsInProgress &&
                        !fetchLineItemsError &&
                        (values.typeOfAircraft || values.aircraft);

                    const bookingInfoMaybe = showEstimatedBreakdown ? (
                        <div className={css.priceBreakdownContainer}>
                            <h3 className={css.priceBreakdownTitle}>
                                <FormattedMessage id="BookingDatesForm.priceBreakdownTitle" />
                            </h3>
                            <EstimatedBreakdownMaybe
                                bookingData={bookingData}
                                lineItems={lineItems}
                            />
                        </div>
                    ) : null;

                    const aircrafts = currentUser?.attributes.profile.publicData?.aircrafts || [];
                    const aircraftsOptions = aircrafts.sort(function (a, b) {
                        return a.mtow - b.mtow;
                      }).map(a => {
                        return {
                            key: a.aircraftRegNo,
                            label: `${a.makeOptions} aircraft | ${a.aircraftRegNo} | ${a.mtow}ton`,
                            mtow: a.mtow,
                        };
                    });

                    const selectAircraft = (
                        !values.addNewAircraft && 
                        <div className={css.selectAircraftContainer}>
                            <FieldSelect
                                name="aircraft"
                                id="aircraft"
                                label={selectAircraftLabel}
                                validate={composeValidators(
                                    required('Aircraft is required')
                                )}>
                                <option value=''>{'Select aircraft'}</option>
                                {aircraftsOptions.map(a => {
                                    return (
                                        <option
                                            key={a.key}
                                            id={a.key}
                                            disabled={this.shouldHideAircraft(a.mtow)}
                                            value={a.key}>
                                            {a.label}
                                        </option>
                                    );
                                })}
                            </FieldSelect>
                            <Tooltip intl={intl} messageId={'BookingDatesForm.selectAircraftTooltipText'} />
                        </div>
                    );

                    const makesArray = AIRCRAFT_MAKES.map(m => { return m.label });
                    const uniqMakesArray = [...new Set(makesArray)];

                    const addNewAircraft = (
                        <div className={css.selectAircraftContainer}>
                            {values.addNewAircraft && <>
                                <FieldSelect
                                    id="makeOptions"
                                    name="makeOptions"
                                    className={css.newAircraftInput}
                                    label={makeOptionsLabelMessage}
                                    validate={makeOptionsRequired}
                                >
                                    <option value={''}>{makeOptionsPlaceholder}</option>
                                    {uniqMakesArray.map((m, i) => {
                                        return <option key={i} value={m}>{m}</option>
                                    })}
                                </FieldSelect>
                                <div />
                                <FieldSelect
                                    id="typeOfAircraft"
                                    name="typeOfAircraft"
                                    className={css.newAircraftInput}
                                    label={typeOfAircraftLabelMessage}
                                    validate={typeOfAircraftRequired}
                                >
                                    <option value={''}>{typeOfAircraftPlaceholder}</option>
                                    {AIRCRAFT_MAKES.map((m, i) => {
                                        if (m.label !== values.makeOptions) {return null}
                                        return <option key={i} value={m.key}>{m.key}</option>
                                    })}
                                </FieldSelect>
                                
                                <FieldTextInput
                                    id="aircraftRegNo"
                                    name="aircraftRegNo"
                                    className={css.newAircraftInput}
                                    type='text'
                                    placeholder={aircraftRegNoPlaceholder}
                                    label={aircraftRegNoLabelMessage}
                                    validate={aircraftRegNoRequired}
                                />

                                <FieldTextInput
                                    id="mtow"
                                    name="mtow"
                                    readonly="readonly"
                                    className={css.newAircraftInput}
                                    type='number'
                                    placeholder={mtowPlaceholder}
                                    label={mtowLabelMessage}
                                    validate={mtowRequired}
                                />
                                {largeMtowError}
                            </>}
                        </div>
                    );

                    const loadingSpinnerMaybe = fetchLineItemsInProgress ? (
                        <IconSpinner className={css.spinner} />
                    ) : null;

                    const bookingInfoErrorMaybe = fetchLineItemsError ? (
                        <span className={css.sideBarError}>
                            <FormattedMessage id="BookingDatesForm.fetchLineItemsError" />
                        </span>
                    ) : null;

                    const dateFormatOptions = {
                        weekday: 'short',
                        month: 'short',
                        day: 'numeric',
                    };

                    const now = moment();
                    const today = now.startOf('day').toDate();
                    const tomorrow = now
                        .startOf('day')
                        .add(1, 'days')
                        .toDate();
                    const startDatePlaceholderText =
                        startDatePlaceholder ||
                        intl.formatDate(today, dateFormatOptions);
                    const endDatePlaceholderText =
                        endDatePlaceholder ||
                        intl.formatDate(tomorrow, dateFormatOptions);
                    const submitButtonClasses = classNames(
                        submitButtonWrapperClassName || css.submitButtonWrapper
                    );

                    const endTimeOptionsAllowed = values.bookingDates?.startDate && values.bookingDates?.endDate && values.bookingStartTime;

                    const hours = [];
                    for (let i = 0; i < 24; i++) {
                        hours.push({
                            id: i,
                            formatedTime: `${('0' + i).slice(-2)}:00`,
                        });
                    }

                    return (
                        <Form
                            onSubmit={handleSubmit}
                            className={classes}
                            enforcePagePreloadFor="CheckoutPage">
                            {timeSlotsError}
                            <FormSpy
                                subscription={{ values: true }}
                                onChange={values => {
                                    this.handleOnChange(values, currentUser, form);
                                }}
                            />
                            <FieldDateRangeInput
                                className={css.bookingDates}
                                name="bookingDates"
                                unitType={unitType}
                                startDateId={`${formId}.bookingStartDate`}
                                startDateLabel={bookingStartLabel}
                                startDatePlaceholderText={
                                    startDatePlaceholderText
                                }
                                endDateId={`${formId}.bookingEndDate`}
                                endDateLabel={bookingEndLabel}
                                endDatePlaceholderText={endDatePlaceholderText}
                                focusedInput={this.state.focusedInput}
                                onFocusedInputChange={this.onFocusedInputChange}
                                format={identity}
                                timeSlots={timeSlots}
                                useMobileMargins
                                validate={composeValidators(
                                    required(requiredMessage),
                                    bookingDatesRequired(
                                        startDateErrorMessage,
                                        endDateErrorMessage
                                    )
                                )}
                                disabled={fetchLineItemsInProgress}
                            />

                            {monthlyTimeSlots && timeZone ? (
                                <div className={css.timeRangeContainer}>
                                    <FieldSelect
                                        name="bookingStartTime"
                                        id="bookingStartTime"
                                        validate={composeValidators(
                                            required(
                                                'Start time is required'
                                            )
                                        )}
                                        label={'Start time'}>
                                        <option>{'select'}</option>
                                        {hours.map(h => {
                                            return (
                                                <option
                                                    key={h.id}
                                                    value={h.id}>
                                                    {h.formatedTime}
                                                </option>
                                            );
                                        })}
                                    </FieldSelect>
                                    
                                    <FieldSelect
                                        name="bookingEndTime"
                                        id="bookingEndTime"
                                        validate={composeValidators(
                                            required('End time is required')
                                        )}
                                        label={'End time'}>
                                        <option>{'select'}</option>
                                        {hours.map(h => {
                                            if ( endTimeOptionsAllowed &&
                                                values.bookingDates.startDate.getDate() ==
                                                    values.bookingDates.endDate.getDate() &&
                                                values.bookingDates.startDate.getMonth() ==
                                                    values.bookingDates.endDate.getMonth() &&
                                                values.bookingStartTime >=
                                                    h.id
                                            ) {
                                                return null;
                                            } else {
                                                return (
                                                    <option
                                                        key={h.id}
                                                        value={h.id}>
                                                        {h.formatedTime}
                                                    </option>
                                                );
                                            }
                                        })}
                                    </FieldSelect>
                                </div>
                            ) : null}
                            <FieldRadioButton
                                id="selectAircraft"
                                name="addNewAircraft"
                                label={'Select aircraft'}
                                value={''}
                                showAsRequired={false}
                            />
                            <FieldRadioButton
                                id="addNewAircraft"
                                name="addNewAircraft"
                                label={addNewAircraftLabel}
                                value={'true'}
                                showAsRequired={false}
                            />
                            <br />
                            {selectAircraft}
                            {addNewAircraft}
                            {bookingInfoMaybe}
                            {loadingSpinnerMaybe}
                            {bookingInfoErrorMaybe}

                            <p className={css.smallPrint}>
                                <FormattedMessage
                                    id={
                                        isOwnListing
                                            ? 'BookingDatesForm.ownListing'
                                            : 'BookingDatesForm.youWontBeChargedInfo'
                                    }
                                />
                            </p>
                            <div className={submitButtonClasses}>
                                <PrimaryButton type="submit">
                                    <FormattedMessage id="BookingDatesForm.requestToBook" />
                                </PrimaryButton>
                            </div>
                        </Form>
                    );
                }}
            />
        );
    }
}

BookingDatesFormComponent.defaultProps = {
    rootClassName: null,
    className: null,
    submitButtonWrapperClassName: null,
    price: null,
    isOwnListing: false,
    startDatePlaceholder: null,
    endDatePlaceholder: null,
    timeSlots: null,
    lineItems: null,
    fetchLineItemsError: null,
};

BookingDatesFormComponent.propTypes = {
    rootClassName: string,
    className: string,
    submitButtonWrapperClassName: string,

    unitType: propTypes.bookingUnitType.isRequired,
    price: propTypes.money,
    isOwnListing: bool,
    timeSlots: arrayOf(propTypes.timeSlot),

    onFetchTransactionLineItems: func.isRequired,
    lineItems: array,
    fetchLineItemsInProgress: bool.isRequired,
    fetchLineItemsError: propTypes.error,
    onUpdateProfile: func.isRequired,

    // from injectIntl
    intl: intlShape.isRequired,

    // for tests
    startDatePlaceholder: string,
    endDatePlaceholder: string,
};

const mapStateToProps = state => {
    return {}
};
const mapDispatchToProps = dispatch => ({
    onUpdateProfile: data => dispatch(updateProfile(data)),
});

const BookingDatesForm = compose(connect(mapStateToProps, mapDispatchToProps),injectIntl)(BookingDatesFormComponent);
BookingDatesForm.displayName = 'BookingDatesForm';

export default BookingDatesForm;
