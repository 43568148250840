export const AIRPORT_LIST = [
    {
        name: 'Goroka Airport',
        city: 'Goroka',
        country: 'Papua New Guinea',
        iataCode: 'GKA',
        icaoCode: 'AYGA',
        latitude: '-6,081689835',
        longitude: '145,3919983',
        altitude: '5282',
        timezone: '10',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Madang Airport',
        city: 'Madang',
        country: 'Papua New Guinea',
        iataCode: 'MAG',
        icaoCode: 'AYMD',
        latitude: '-5,207079887',
        longitude: '145,7890015',
        altitude: '20',
        timezone: '10',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Mount Hagen Kagamuga Airport',
        city: 'Mount Hagen',
        country: 'Papua New Guinea',
        iataCode: 'HGU',
        icaoCode: 'AYMH',
        latitude: '-5,826789856',
        longitude: '144,2960052',
        altitude: '5388',
        timezone: '10',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Nadzab Airport',
        city: 'Nadzab',
        country: 'Papua New Guinea',
        iataCode: 'LAE',
        icaoCode: 'AYNZ',
        latitude: '-6,569803',
        longitude: '146,725977',
        altitude: '239',
        timezone: '10',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Port Moresby Jacksons International Airport',
        city: 'Port Moresby',
        country: 'Papua New Guinea',
        iataCode: 'POM',
        icaoCode: 'AYPY',
        latitude: '-9,443380356',
        longitude: '147,2200012',
        altitude: '146',
        timezone: '10',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Wewak International Airport',
        city: 'Wewak',
        country: 'Papua New Guinea',
        iataCode: 'WWK',
        icaoCode: 'AYWK',
        latitude: '-3,583830118',
        longitude: '143,6690063',
        altitude: '19',
        timezone: '10',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Narsarsuaq Airport',
        city: 'Narssarssuaq',
        country: 'Greenland',
        iataCode: 'UAK',
        icaoCode: 'BGBW',
        latitude: '61,16049957',
        longitude: '-45,42599869',
        altitude: '112',
        timezone: '-3',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Godthaab / Nuuk Airport',
        city: 'Godthaab',
        country: 'Greenland',
        iataCode: 'GOH',
        icaoCode: 'BGGH',
        latitude: '64,19090271',
        longitude: '-51,67810059',
        altitude: '283',
        timezone: '-3',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Kangerlussuaq Airport',
        city: 'Sondrestrom',
        country: 'Greenland',
        iataCode: 'SFJ',
        icaoCode: 'BGSF',
        latitude: '67,0122219',
        longitude: '-50,71160316',
        altitude: '165',
        timezone: '-3',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Thule Air Base',
        city: 'Thule',
        country: 'Greenland',
        iataCode: 'THU',
        icaoCode: 'BGTL',
        latitude: '76,53119659',
        longitude: '-68,70320129',
        altitude: '251',
        timezone: '-4',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Akureyri Airport',
        city: 'Akureyri',
        country: 'Iceland',
        iataCode: 'AEY',
        icaoCode: 'BIAR',
        latitude: '65,66000366',
        longitude: '-18,0727005',
        altitude: '6',
        timezone: '0',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Egilsstaðir Airport',
        city: 'Egilsstadir',
        country: 'Iceland',
        iataCode: 'EGS',
        icaoCode: 'BIEG',
        latitude: '65,28330231',
        longitude: '-14,40139961',
        altitude: '76',
        timezone: '0',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Hornafjörður Airport',
        city: 'Hofn',
        country: 'Iceland',
        iataCode: 'HFN',
        icaoCode: 'BIHN',
        latitude: '64,295601',
        longitude: '-15,2272',
        altitude: '24',
        timezone: '0',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Húsavík Airport',
        city: 'Husavik',
        country: 'Iceland',
        iataCode: 'HZK',
        icaoCode: 'BIHU',
        latitude: '65,952301',
        longitude: '-17,426001',
        altitude: '48',
        timezone: '0',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Ísafjörður Airport',
        city: 'Isafjordur',
        country: 'Iceland',
        iataCode: 'IFJ',
        icaoCode: 'BIIS',
        latitude: '66,05809784',
        longitude: '-23,13529968',
        altitude: '8',
        timezone: '0',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Keflavik International Airport',
        city: 'Keflavik',
        country: 'Iceland',
        iataCode: 'KEF',
        icaoCode: 'BIKF',
        latitude: '63,98500061',
        longitude: '-22,60560036',
        altitude: '171',
        timezone: '0',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Patreksfjörður Airport',
        city: 'Patreksfjordur',
        country: 'Iceland',
        iataCode: 'PFJ',
        icaoCode: 'BIPA',
        latitude: '65,555801',
        longitude: '-23,965',
        altitude: '11',
        timezone: '0',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Reykjavik Airport',
        city: 'Reykjavik',
        country: 'Iceland',
        iataCode: 'RKV',
        icaoCode: 'BIRK',
        latitude: '64,12999725',
        longitude: '-21,94059944',
        altitude: '48',
        timezone: '0',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Siglufjörður Airport',
        city: 'Siglufjordur',
        country: 'Iceland',
        iataCode: 'SIJ',
        icaoCode: 'BISI',
        latitude: '66,133301',
        longitude: '-18,9167',
        altitude: '10',
        timezone: '0',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Vestmannaeyjar Airport',
        city: 'Vestmannaeyjar',
        country: 'Iceland',
        iataCode: 'VEY',
        icaoCode: 'BIVM',
        latitude: '63,42430115',
        longitude: '-20,27890015',
        altitude: '326',
        timezone: '0',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Sault Ste Marie Airport',
        city: 'Sault Sainte Marie',
        country: 'Canada',
        iataCode: 'YAM',
        icaoCode: 'CYAM',
        latitude: '46,48500061',
        longitude: '-84,50939941',
        altitude: '630',
        timezone: '-5',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Winnipeg / St. Andrews Airport',
        city: 'Winnipeg',
        country: 'Canada',
        iataCode: 'YAV',
        icaoCode: 'CYAV',
        latitude: '50,0564003',
        longitude: '-97,03250122',
        altitude: '760',
        timezone: '-6',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Halifax / CFB Shearwater Heliport',
        city: 'Halifax',
        country: 'Canada',
        iataCode: 'YAW',
        icaoCode: 'CYAW',
        latitude: '44,63970184',
        longitude: '-63,49940109',
        altitude: '144',
        timezone: '-4',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'St. Anthony Airport',
        city: 'St. Anthony',
        country: 'Canada',
        iataCode: 'YAY',
        icaoCode: 'CYAY',
        latitude: '51,39189911',
        longitude: '-56,08309937',
        altitude: '108',
        timezone: '-3,5',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Tofino / Long Beach Airport',
        city: 'Tofino',
        country: 'Canada',
        iataCode: 'YAZ',
        icaoCode: 'CYAZ',
        latitude: '49,079833',
        longitude: '-125,775583',
        altitude: '80',
        timezone: '-8',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Kugaaruk Airport',
        city: 'Pelly Bay',
        country: 'Canada',
        iataCode: 'YBB',
        icaoCode: 'CYBB',
        latitude: '68,534401',
        longitude: '-89,808098',
        altitude: '56',
        timezone: '-7',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Baie Comeau Airport',
        city: 'Baie Comeau',
        country: 'Canada',
        iataCode: 'YBC',
        icaoCode: 'CYBC',
        latitude: '49,13249969',
        longitude: '-68,20439911',
        altitude: '71',
        timezone: '-5',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'CFB Bagotville',
        city: 'Bagotville',
        country: 'Canada',
        iataCode: 'YBG',
        icaoCode: 'CYBG',
        latitude: '48,33060074',
        longitude: '-70,99639893',
        altitude: '522',
        timezone: '-5',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Baker Lake Airport',
        city: 'Baker Lake',
        country: 'Canada',
        iataCode: 'YBK',
        icaoCode: 'CYBK',
        latitude: '64,29889679',
        longitude: '-96,07779694',
        altitude: '59',
        timezone: '-6',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Campbell River Airport',
        city: 'Campbell River',
        country: 'Canada',
        iataCode: 'YBL',
        icaoCode: 'CYBL',
        latitude: '49,95080185',
        longitude: '-125,2710037',
        altitude: '346',
        timezone: '-8',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Brandon Municipal Airport',
        city: 'Brandon',
        country: 'Canada',
        iataCode: 'YBR',
        icaoCode: 'CYBR',
        latitude: '49,91',
        longitude: '-99,951897',
        altitude: '1343',
        timezone: '-6',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Cambridge Bay Airport',
        city: 'Cambridge Bay',
        country: 'Canada',
        iataCode: 'YCB',
        icaoCode: 'CYCB',
        latitude: '69,10810089',
        longitude: '-105,1380005',
        altitude: '90',
        timezone: '-7',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Nanaimo Airport',
        city: 'Nanaimo',
        country: 'Canada',
        iataCode: 'YCD',
        icaoCode: 'CYCD',
        latitude: '49,05497022',
        longitude: '-123,8698626',
        altitude: '92',
        timezone: '-8',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Castlegar/West Kootenay Regional Airport',
        city: 'Castlegar',
        country: 'Canada',
        iataCode: 'YCG',
        icaoCode: 'CYCG',
        latitude: '49,29639816',
        longitude: '-117,6320038',
        altitude: '1624',
        timezone: '-8',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Miramichi Airport',
        city: 'Chatham',
        country: 'Canada',
        iataCode: 'YCH',
        icaoCode: 'CYCH',
        latitude: '47,007801',
        longitude: '-65,449203',
        altitude: '108',
        timezone: '-4',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Charlo Airport',
        city: 'Charlo',
        country: 'Canada',
        iataCode: 'YCL',
        icaoCode: 'CYCL',
        latitude: '47,990799',
        longitude: '-66,330299',
        altitude: '132',
        timezone: '-4',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Kugluktuk Airport',
        city: 'Coppermine',
        country: 'Canada',
        iataCode: 'YCO',
        icaoCode: 'CYCO',
        latitude: '67,8167038',
        longitude: '-115,1439972',
        altitude: '74',
        timezone: '-7',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Coronation Airport',
        city: 'Coronation',
        country: 'Canada',
        iataCode: 'YCT',
        icaoCode: 'CYCT',
        latitude: '52,07500076',
        longitude: '-111,4449997',
        altitude: '2595',
        timezone: '-7',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Chilliwack Airport',
        city: 'Chilliwack',
        country: 'Canada',
        iataCode: 'YCW',
        icaoCode: 'CYCW',
        latitude: '49,15280151',
        longitude: '-121,939003',
        altitude: '32',
        timezone: '-8',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Clyde River Airport',
        city: 'Clyde River',
        country: 'Canada',
        iataCode: 'YCY',
        icaoCode: 'CYCY',
        latitude: '70,48609924',
        longitude: '-68,51670074',
        altitude: '87',
        timezone: '-5',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Coral Harbour Airport',
        city: 'Coral Harbour',
        country: 'Canada',
        iataCode: 'YZS',
        icaoCode: 'CYZS',
        latitude: '64,19329834',
        longitude: '-83,35939789',
        altitude: '210',
        timezone: '-5',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Dawson City Airport',
        city: 'Dawson',
        country: 'Canada',
        iataCode: 'YDA',
        icaoCode: 'CYDA',
        latitude: '64,04309845',
        longitude: '-139,128006',
        altitude: '1215',
        timezone: '-8',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Burwash Airport',
        city: 'Burwash',
        country: 'Canada',
        iataCode: 'YDB',
        icaoCode: 'CYDB',
        latitude: '61,37110138',
        longitude: '-139,0410004',
        altitude: '2647',
        timezone: '-8',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Princeton Airport',
        city: 'Princeton',
        country: 'Canada',
        iataCode: 'YDC',
        icaoCode: 'CYDC',
        latitude: '49,4681015',
        longitude: '-120,5110016',
        altitude: '2298',
        timezone: '-8',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Deer Lake Airport',
        city: 'Deer Lake',
        country: 'Canada',
        iataCode: 'YDF',
        icaoCode: 'CYDF',
        latitude: '49,21080017',
        longitude: '-57,39139938',
        altitude: '72',
        timezone: '-3,5',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Dease Lake Airport',
        city: 'Dease Lake',
        country: 'Canada',
        iataCode: 'YDL',
        icaoCode: 'CYDL',
        latitude: '58,42219925',
        longitude: '-130,0319977',
        altitude: '2600',
        timezone: '-8',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Dauphin Barker Airport',
        city: 'Dauphin',
        country: 'Canada',
        iataCode: 'YDN',
        icaoCode: 'CYDN',
        latitude: '51,10079956',
        longitude: '-100,052002',
        altitude: '999',
        timezone: '-6',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Dawson Creek Airport',
        city: 'Dawson Creek',
        country: 'Canada',
        iataCode: 'YDQ',
        icaoCode: 'CYDQ',
        latitude: '55,74229813',
        longitude: '-120,1829987',
        altitude: '2148',
        timezone: '-7',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Edmonton International Airport',
        city: 'Edmonton',
        country: 'Canada',
        iataCode: 'YEG',
        icaoCode: 'CYEG',
        latitude: '53,30970001',
        longitude: '-113,5800018',
        altitude: '2373',
        timezone: '-7',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Arviat Airport',
        city: 'Eskimo Point',
        country: 'Canada',
        iataCode: 'YEK',
        icaoCode: 'CYEK',
        latitude: '61,09420013',
        longitude: '-94,07080078',
        altitude: '32',
        timezone: '-6',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Estevan Airport',
        city: 'Estevan',
        country: 'Canada',
        iataCode: 'YEN',
        icaoCode: 'CYEN',
        latitude: '49,21030045',
        longitude: '-102,9660034',
        altitude: '1905',
        timezone: '-6',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Edson Airport',
        city: 'Edson',
        country: 'Canada',
        iataCode: 'YET',
        icaoCode: 'CYET',
        latitude: '53,57889938',
        longitude: '-116,4649963',
        altitude: '3043',
        timezone: '-7',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Eureka Airport',
        city: 'Eureka',
        country: 'Canada',
        iataCode: 'YEU',
        icaoCode: 'CYEU',
        latitude: '79,99469757',
        longitude: '-85,81420136',
        altitude: '256',
        timezone: '-6',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Inuvik Mike Zubko Airport',
        city: 'Inuvik',
        country: 'Canada',
        iataCode: 'YEV',
        icaoCode: 'CYEV',
        latitude: '68,30419922',
        longitude: '-133,4830017',
        altitude: '224',
        timezone: '-7',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Iqaluit Airport',
        city: 'Iqaluit',
        country: 'Canada',
        iataCode: 'YFB',
        icaoCode: 'CYFB',
        latitude: '63,75640106',
        longitude: '-68,55580139',
        altitude: '110',
        timezone: '-5',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Fredericton Airport',
        city: 'Fredericton',
        country: 'Canada',
        iataCode: 'YFC',
        icaoCode: 'CYFC',
        latitude: '45,8689003',
        longitude: '-66,53720093',
        altitude: '68',
        timezone: '-4',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Forestville Airport',
        city: 'Forestville',
        country: 'Canada',
        iataCode: 'N',
        icaoCode: 'CYFE',
        latitude: '48,74610138',
        longitude: '-69,09719849',
        altitude: '293',
        timezone: '-5',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Flin Flon Airport',
        city: 'Flin Flon',
        country: 'Canada',
        iataCode: 'YFO',
        icaoCode: 'CYFO',
        latitude: '54,67810059',
        longitude: '-101,6819992',
        altitude: '997',
        timezone: '-6',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Fort Resolution Airport',
        city: 'Fort Resolution',
        country: 'Canada',
        iataCode: 'YFR',
        icaoCode: 'CYFR',
        latitude: '61,18080139',
        longitude: '-113,6900024',
        altitude: '526',
        timezone: '-7',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Fort Simpson Airport',
        city: 'Fort Simpson',
        country: 'Canada',
        iataCode: 'YFS',
        icaoCode: 'CYFS',
        latitude: '61,7602005',
        longitude: '-121,2369995',
        altitude: '555',
        timezone: '-7',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Kingston Norman Rogers Airport',
        city: 'Kingston',
        country: 'Canada',
        iataCode: 'YGK',
        icaoCode: 'CYGK',
        latitude: '44,22529984',
        longitude: '-76,59690094',
        altitude: '305',
        timezone: '-5',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'La Grande Rivière Airport',
        city: 'La Grande Riviere',
        country: 'Canada',
        iataCode: 'YGL',
        icaoCode: 'CYGL',
        latitude: '53,62530136',
        longitude: '-77,70420074',
        altitude: '639',
        timezone: '-5',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Gaspé (Michel-Pouliot) Airport',
        city: 'Gaspe',
        country: 'Canada',
        iataCode: 'YGP',
        icaoCode: 'CYGP',
        latitude: '48,77529907',
        longitude: '-64,47859955',
        altitude: '112',
        timezone: '-5',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Geraldton Greenstone Regional Airport',
        city: 'Geraldton',
        country: 'Canada',
        iataCode: 'YGQ',
        icaoCode: 'CYGQ',
        latitude: '49,77830124',
        longitude: '-86,93939972',
        altitude: '1144',
        timezone: '-5',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Îles-de-la-Madeleine Airport',
        city: 'Iles De La Madeleine',
        country: 'Canada',
        iataCode: 'YGR',
        icaoCode: 'CYGR',
        latitude: '47,42470169',
        longitude: '-61,77809906',
        altitude: '35',
        timezone: '-5',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Hudson Bay Airport',
        city: 'Hudson Bay',
        country: 'Canada',
        iataCode: 'YHB',
        icaoCode: 'CYHB',
        latitude: '52,81669998',
        longitude: '-102,310997',
        altitude: '1175',
        timezone: '-6',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Dryden Regional Airport',
        city: 'Dryden',
        country: 'Canada',
        iataCode: 'YHD',
        icaoCode: 'CYHD',
        latitude: '49,83169937',
        longitude: '-92,74420166',
        altitude: '1354',
        timezone: '-6',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Ulukhaktok Holman Airport',
        city: 'Holman Island',
        country: 'Canada',
        iataCode: 'YHI',
        icaoCode: 'CYHI',
        latitude: '70,76280212',
        longitude: '-117,8059998',
        altitude: '117',
        timezone: '-7',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Gjoa Haven Airport',
        city: 'Gjoa Haven',
        country: 'Canada',
        iataCode: 'YHK',
        icaoCode: 'CYHK',
        latitude: '68,63559723',
        longitude: '-95,84970093',
        altitude: '152',
        timezone: '-7',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'John C. Munro Hamilton International Airport',
        city: 'Hamilton',
        country: 'Canada',
        iataCode: 'YHM',
        icaoCode: 'CYHM',
        latitude: '43,17359924',
        longitude: '-79,93499756',
        altitude: '780',
        timezone: '-5',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Montréal / Saint-Hubert Airport',
        city: 'Montreal',
        country: 'Canada',
        iataCode: 'YHU',
        icaoCode: 'CYHU',
        latitude: '45,51750183',
        longitude: '-73,41690063',
        altitude: '90',
        timezone: '-5',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Hay River / Merlyn Carter Airport',
        city: 'Hay River',
        country: 'Canada',
        iataCode: 'YHY',
        icaoCode: 'CYHY',
        latitude: '60,83969879',
        longitude: '-115,7829971',
        altitude: '541',
        timezone: '-7',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Halifax / Stanfield International Airport',
        city: 'Halifax',
        country: 'Canada',
        iataCode: 'YHZ',
        icaoCode: 'CYHZ',
        latitude: '44,88079834',
        longitude: '-63,50859833',
        altitude: '477',
        timezone: '-4',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Atikokan Municipal Airport',
        city: 'Atikokan',
        country: 'Canada',
        iataCode: 'YIB',
        icaoCode: 'CYIB',
        latitude: '48,77389908',
        longitude: '-91,63860321',
        altitude: '1408',
        timezone: '-5',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Pond Inlet Airport',
        city: 'Pond Inlet',
        country: 'Canada',
        iataCode: 'YIO',
        icaoCode: 'CYIO',
        latitude: '72,6832962',
        longitude: '-77,96669769',
        altitude: '181',
        timezone: '-5',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'St Jean Airport',
        city: 'St. Jean',
        country: 'Canada',
        iataCode: 'YJN',
        icaoCode: 'CYJN',
        latitude: '45,29439926',
        longitude: '-73,28109741',
        altitude: '136',
        timezone: '-5',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Stephenville Airport',
        city: 'Stephenville',
        country: 'Canada',
        iataCode: 'YJT',
        icaoCode: 'CYJT',
        latitude: '48,5442009',
        longitude: '-58,54999924',
        altitude: '84',
        timezone: '-3,5',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Kamloops Airport',
        city: 'Kamloops',
        country: 'Canada',
        iataCode: 'YKA',
        icaoCode: 'CYKA',
        latitude: '50,70220184',
        longitude: '-120,4440002',
        altitude: '1133',
        timezone: '-8',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Waterloo Airport',
        city: 'Waterloo',
        country: 'Canada',
        iataCode: 'YKF',
        icaoCode: 'CYKF',
        latitude: '43,46080017',
        longitude: '-80,37860107',
        altitude: '1055',
        timezone: '-5',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Schefferville Airport',
        city: 'Schefferville',
        country: 'Canada',
        iataCode: 'YKL',
        icaoCode: 'CYKL',
        latitude: '54,80530167',
        longitude: '-66,80529785',
        altitude: '1709',
        timezone: '-5',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Kindersley Airport',
        city: 'Kindersley',
        country: 'Canada',
        iataCode: 'YKY',
        icaoCode: 'CYKY',
        latitude: '51,51750183',
        longitude: '-109,1809998',
        altitude: '2277',
        timezone: '-6',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Buttonville Municipal Airport',
        city: 'Toronto',
        country: 'Canada',
        iataCode: 'YKZ',
        icaoCode: 'CYKZ',
        latitude: '43,86220169',
        longitude: '-79,37000275',
        altitude: '650',
        timezone: '-5',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Chapleau Airport',
        city: 'Chapleau',
        country: 'Canada',
        iataCode: 'YLD',
        icaoCode: 'CYLD',
        latitude: '47,81999969',
        longitude: '-83,34670258',
        altitude: '1470',
        timezone: '-5',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Meadow Lake Airport',
        city: 'Meadow Lake',
        country: 'Canada',
        iataCode: 'YLJ',
        icaoCode: 'CYLJ',
        latitude: '54,12530136',
        longitude: '-108,5230026',
        altitude: '1576',
        timezone: '-6',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Lloydminster Airport',
        city: 'Lloydminster',
        country: 'Canada',
        iataCode: 'YLL',
        icaoCode: 'CYLL',
        latitude: '53,30920029',
        longitude: '-110,072998',
        altitude: '2193',
        timezone: '-7',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Alert Airport',
        city: 'Alert',
        country: 'Canada',
        iataCode: 'YLT',
        icaoCode: 'CYLT',
        latitude: '82,51779938',
        longitude: '-62,2806015',
        altitude: '100',
        timezone: '-5',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Kelowna International Airport',
        city: 'Kelowna',
        country: 'Canada',
        iataCode: 'YLW',
        icaoCode: 'CYLW',
        latitude: '49,95610046',
        longitude: '-119,3779984',
        altitude: '1421',
        timezone: '-8',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Mayo Airport',
        city: 'Mayo',
        country: 'Canada',
        iataCode: 'YMA',
        icaoCode: 'CYMA',
        latitude: '63,61640167',
        longitude: '-135,8679962',
        altitude: '1653',
        timezone: '-8',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Moose Jaw Air Vice Marshal C. M. McEwen Airport',
        city: 'Moose Jaw',
        country: 'Canada',
        iataCode: 'YMJ',
        icaoCode: 'CYMJ',
        latitude: '50,33029938',
        longitude: '-105,5589981',
        altitude: '1892',
        timezone: '-6',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Fort McMurray Airport',
        city: 'Fort Mcmurray',
        country: 'Canada',
        iataCode: 'YMM',
        icaoCode: 'CYMM',
        latitude: '56,65330124',
        longitude: '-111,2220001',
        altitude: '1211',
        timezone: '-7',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Moosonee Airport',
        city: 'Moosonee',
        country: 'Canada',
        iataCode: 'YMO',
        icaoCode: 'CYMO',
        latitude: '51,29109955',
        longitude: '-80,60780334',
        altitude: '30',
        timezone: '-5',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Maniwaki Airport',
        city: 'Maniwaki',
        country: 'Canada',
        iataCode: 'YMW',
        icaoCode: 'CYMW',
        latitude: '46,27280045',
        longitude: '-75,99060059',
        altitude: '656',
        timezone: '-5',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Montreal International (Mirabel) Airport',
        city: 'Montreal',
        country: 'Canada',
        iataCode: 'YMX',
        icaoCode: 'CYMX',
        latitude: '45,67950058',
        longitude: '-74,03869629',
        altitude: '270',
        timezone: '-5',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Natashquan Airport',
        city: 'Natashquan',
        country: 'Canada',
        iataCode: 'YNA',
        icaoCode: 'CYNA',
        latitude: '50,18999863',
        longitude: '-61,78919983',
        altitude: '39',
        timezone: '-5',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Ottawa / Gatineau Airport',
        city: 'Gatineau',
        country: 'Canada',
        iataCode: 'YND',
        icaoCode: 'CYND',
        latitude: '45,52170181',
        longitude: '-75,56359863',
        altitude: '211',
        timezone: '-5',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Matagami Airport',
        city: 'Matagami',
        country: 'Canada',
        iataCode: 'YNM',
        icaoCode: 'CYNM',
        latitude: '49,76169968',
        longitude: '-77,80280304',
        altitude: '918',
        timezone: '-5',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Old Crow Airport',
        city: 'Old Crow',
        country: 'Canada',
        iataCode: 'YOC',
        icaoCode: 'CYOC',
        latitude: '67,57060242',
        longitude: '-139,8390045',
        altitude: '824',
        timezone: '-8',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'CFB Cold Lake',
        city: 'Cold Lake',
        country: 'Canada',
        iataCode: 'YOD',
        icaoCode: 'CYOD',
        latitude: '54,40499878',
        longitude: '-110,2789993',
        altitude: '1775',
        timezone: '-7',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'High Level Airport',
        city: 'High Level',
        country: 'Canada',
        iataCode: 'YOJ',
        icaoCode: 'CYOJ',
        latitude: '58,62139893',
        longitude: '-117,1650009',
        altitude: '1110',
        timezone: '-7',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Ottawa Macdonald-Cartier International Airport',
        city: 'Ottawa',
        country: 'Canada',
        iataCode: 'YOW',
        icaoCode: 'CYOW',
        latitude: '45,32249832',
        longitude: '-75,66919708',
        altitude: '374',
        timezone: '-5',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Prince Albert Glass Field',
        city: 'Prince Albert',
        country: 'Canada',
        iataCode: 'YPA',
        icaoCode: 'CYPA',
        latitude: '53,21419907',
        longitude: '-105,6729965',
        altitude: '1405',
        timezone: '-6',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Peace River Airport',
        city: 'Peace River',
        country: 'Canada',
        iataCode: 'YPE',
        icaoCode: 'CYPE',
        latitude: '56,22689819',
        longitude: '-117,4469986',
        altitude: '1873',
        timezone: '-7',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Southport Airport',
        city: 'Portage-la-prairie',
        country: 'Canada',
        iataCode: 'YPG',
        icaoCode: 'CYPG',
        latitude: '49,903099',
        longitude: '-98,273817',
        altitude: '885',
        timezone: '-6',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Pitt Meadows Airport',
        city: 'Pitt Meadows',
        country: 'Canada',
        iataCode: 'N',
        icaoCode: 'CYPK',
        latitude: '49,21609879',
        longitude: '-122,7099991',
        altitude: '11',
        timezone: '-8',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Pickle Lake Airport',
        city: 'Pickle Lake',
        country: 'Canada',
        iataCode: 'YPL',
        icaoCode: 'CYPL',
        latitude: '51,44639969',
        longitude: '-90,21420288',
        altitude: '1267',
        timezone: '-5',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Port Menier Airport',
        city: 'Port Menier',
        country: 'Canada',
        iataCode: 'YPN',
        icaoCode: 'CYPN',
        latitude: '49,83639908',
        longitude: '-64,28859711',
        altitude: '167',
        timezone: '-5',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Peterborough Airport',
        city: 'Peterborough',
        country: 'Canada',
        iataCode: 'YPQ',
        icaoCode: 'CYPQ',
        latitude: '44,22999954',
        longitude: '-78,36329651',
        altitude: '628',
        timezone: '-5',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Prince Rupert Airport',
        city: 'Prince Pupert',
        country: 'Canada',
        iataCode: 'YPR',
        icaoCode: 'CYPR',
        latitude: '54,28609848',
        longitude: '-130,4450073',
        altitude: '116',
        timezone: '-8',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Fort Chipewyan Airport',
        city: 'Fort Chipewyan',
        country: 'Canada',
        iataCode: 'YPY',
        icaoCode: 'CYPY',
        latitude: '58,76720047',
        longitude: '-111,1169968',
        altitude: '761',
        timezone: '-7',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Muskoka Airport',
        city: 'Muskoka',
        country: 'Canada',
        iataCode: 'YQA',
        icaoCode: 'CYQA',
        latitude: '44,97470093',
        longitude: '-79,30329895',
        altitude: '925',
        timezone: '-5',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Quebec Jean Lesage International Airport',
        city: 'Quebec',
        country: 'Canada',
        iataCode: 'YQB',
        icaoCode: 'CYQB',
        latitude: '46,7911',
        longitude: '-71,393303',
        altitude: '244',
        timezone: '-5',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Red Deer Regional Airport',
        city: 'Red Deer Industrial',
        country: 'Canada',
        iataCode: 'YQF',
        icaoCode: 'CYQF',
        latitude: '52,18220139',
        longitude: '-113,8939972',
        altitude: '2968',
        timezone: '-7',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Windsor Airport',
        city: 'Windsor',
        country: 'Canada',
        iataCode: 'YQG',
        icaoCode: 'CYQG',
        latitude: '42,27560043',
        longitude: '-82,95559692',
        altitude: '622',
        timezone: '-5',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Watson Lake Airport',
        city: 'Watson Lake',
        country: 'Canada',
        iataCode: 'YQH',
        icaoCode: 'CYQH',
        latitude: '60,11640167',
        longitude: '-128,8220062',
        altitude: '2255',
        timezone: '-8',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Kenora Airport',
        city: 'Kenora',
        country: 'Canada',
        iataCode: 'YQK',
        icaoCode: 'CYQK',
        latitude: '49,78829956',
        longitude: '-94,36309814',
        altitude: '1332',
        timezone: '-6',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Lethbridge County Airport',
        city: 'Lethbridge',
        country: 'Canada',
        iataCode: 'YQL',
        icaoCode: 'CYQL',
        latitude: '49,63029861',
        longitude: '-112,8000031',
        altitude: '3048',
        timezone: '-7',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Greater Moncton International Airport',
        city: 'Moncton',
        country: 'Canada',
        iataCode: 'YQM',
        icaoCode: 'CYQM',
        latitude: '46,11220169',
        longitude: '-64,6785965',
        altitude: '232',
        timezone: '-4',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Comox Airport',
        city: 'Comox',
        country: 'Canada',
        iataCode: 'YQQ',
        icaoCode: 'CYQQ',
        latitude: '49,71080017',
        longitude: '-124,887001',
        altitude: '84',
        timezone: '-8',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Regina International Airport',
        city: 'Regina',
        country: 'Canada',
        iataCode: 'YQR',
        icaoCode: 'CYQR',
        latitude: '50,43190002',
        longitude: '-104,6660004',
        altitude: '1894',
        timezone: '-6',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Thunder Bay Airport',
        city: 'Thunder Bay',
        country: 'Canada',
        iataCode: 'YQT',
        icaoCode: 'CYQT',
        latitude: '48,37189865',
        longitude: '-89,32389832',
        altitude: '653',
        timezone: '-5',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Grande Prairie Airport',
        city: 'Grande Prairie',
        country: 'Canada',
        iataCode: 'YQU',
        icaoCode: 'CYQU',
        latitude: '55,17969894',
        longitude: '-118,8850021',
        altitude: '2195',
        timezone: '-7',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Yorkton Municipal Airport',
        city: 'Yorkton',
        country: 'Canada',
        iataCode: 'YQV',
        icaoCode: 'CYQV',
        latitude: '51,26470184',
        longitude: '-102,461998',
        altitude: '1635',
        timezone: '-6',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'North Battleford Airport',
        city: 'North Battleford',
        country: 'Canada',
        iataCode: 'YQW',
        icaoCode: 'CYQW',
        latitude: '52,76919937',
        longitude: '-108,2440033',
        altitude: '1799',
        timezone: '-6',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Gander International Airport',
        city: 'Gander',
        country: 'Canada',
        iataCode: 'YQX',
        icaoCode: 'CYQX',
        latitude: '48,93690109',
        longitude: '-54,56809998',
        altitude: '496',
        timezone: '-3,5',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Sydney / J.A. Douglas McCurdy Airport',
        city: 'Sydney',
        country: 'Canada',
        iataCode: 'YQY',
        icaoCode: 'CYQY',
        latitude: '46,16139984',
        longitude: '-60,04779816',
        altitude: '203',
        timezone: '-4',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Quesnel Airport',
        city: 'Quesnel',
        country: 'Canada',
        iataCode: 'YQZ',
        icaoCode: 'CYQZ',
        latitude: '53,02610016',
        longitude: '-122,5100021',
        altitude: '1789',
        timezone: '-8',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Resolute Bay Airport',
        city: 'Resolute',
        country: 'Canada',
        iataCode: 'YRB',
        icaoCode: 'CYRB',
        latitude: '74,71690369',
        longitude: '-94,9693985',
        altitude: '215',
        timezone: '-6',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Rivière-du-Loup Airport',
        city: 'Riviere Du Loup',
        country: 'Canada',
        iataCode: 'YRI',
        icaoCode: 'CYRI',
        latitude: '47,76440048',
        longitude: '-69,58470154',
        altitude: '427',
        timezone: '-5',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Roberval Airport',
        city: 'Roberval',
        country: 'Canada',
        iataCode: 'YRJ',
        icaoCode: 'CYRJ',
        latitude: '48,52000046',
        longitude: '-72,26560211',
        altitude: '586',
        timezone: '-5',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Rocky Mountain House Airport',
        city: 'Rocky Mountain House',
        country: 'Canada',
        iataCode: 'YRM',
        icaoCode: 'CYRM',
        latitude: '52,42969894',
        longitude: '-114,9039993',
        altitude: '3244',
        timezone: '-7',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Rankin Inlet Airport',
        city: 'Rankin Inlet',
        country: 'Canada',
        iataCode: 'YRT',
        icaoCode: 'CYRT',
        latitude: '62,81140137',
        longitude: '-92,11579895',
        altitude: '94',
        timezone: '-6',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Sudbury Airport',
        city: 'Sudbury',
        country: 'Canada',
        iataCode: 'YSB',
        icaoCode: 'CYSB',
        latitude: '46,625',
        longitude: '-80,79889679',
        altitude: '1141',
        timezone: '-5',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Sherbrooke Airport',
        city: 'Sherbrooke',
        country: 'Canada',
        iataCode: 'YSC',
        icaoCode: 'CYSC',
        latitude: '45,43859863',
        longitude: '-71,69139862',
        altitude: '792',
        timezone: '-5',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Saint John Airport',
        city: 'St. John',
        country: 'Canada',
        iataCode: 'YSJ',
        icaoCode: 'CYSJ',
        latitude: '45,31610107',
        longitude: '-65,89029694',
        altitude: '357',
        timezone: '-4',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Fort Smith Airport',
        city: 'Fort Smith',
        country: 'Canada',
        iataCode: 'YSM',
        icaoCode: 'CYSM',
        latitude: '60,02030182',
        longitude: '-111,961998',
        altitude: '671',
        timezone: '-7',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Nanisivik Airport',
        city: 'Nanisivik',
        country: 'Canada',
        iataCode: 'YSR',
        icaoCode: 'CYSR',
        latitude: '72,982201',
        longitude: '-84,613602',
        altitude: '2106',
        timezone: '-5',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Summerside Airport',
        city: 'Summerside',
        country: 'Canada',
        iataCode: 'YSU',
        icaoCode: 'CYSU',
        latitude: '46,44060135',
        longitude: '-63,83359909',
        altitude: '56',
        timezone: '-4',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Sachs Harbour (David Nasogaluak Jr. Saaryuaq) Airport',
        city: 'Sachs Harbour',
        country: 'Canada',
        iataCode: 'YSY',
        icaoCode: 'CYSY',
        latitude: '71,99389648',
        longitude: '-125,2429962',
        altitude: '282',
        timezone: '-7',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Cape Dorset Airport',
        city: 'Cape Dorset',
        country: 'Canada',
        iataCode: 'YTE',
        icaoCode: 'CYTE',
        latitude: '64,23000336',
        longitude: '-76,52670288',
        altitude: '164',
        timezone: '-5',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Thompson Airport',
        city: 'Thompson',
        country: 'Canada',
        iataCode: 'YTH',
        icaoCode: 'CYTH',
        latitude: '55,80110168',
        longitude: '-97,86419678',
        altitude: '729',
        timezone: '-6',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'CFB Trenton',
        city: 'Trenton',
        country: 'Canada',
        iataCode: 'YTR',
        icaoCode: 'CYTR',
        latitude: '44,1189003',
        longitude: '-77,52809906',
        altitude: '283',
        timezone: '-5',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Timmins/Victor M. Power',
        city: 'Timmins',
        country: 'Canada',
        iataCode: 'YTS',
        icaoCode: 'CYTS',
        latitude: '48,56969833',
        longitude: '-81,37670136',
        altitude: '967',
        timezone: '-5',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Billy Bishop Toronto City Centre Airport',
        city: 'Toronto',
        country: 'Canada',
        iataCode: 'YTZ',
        icaoCode: 'CYTZ',
        latitude: '43,627499',
        longitude: '-79,396202',
        altitude: '252',
        timezone: '-5',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Tuktoyaktuk Airport',
        city: 'Tuktoyaktuk',
        country: 'Canada',
        iataCode: 'YUB',
        icaoCode: 'CYUB',
        latitude: '69,4332962',
        longitude: '-133,026001',
        altitude: '15',
        timezone: '-7',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Montreal / Pierre Elliott Trudeau International Airport',
        city: 'Montreal',
        country: 'Canada',
        iataCode: 'YUL',
        icaoCode: 'CYUL',
        latitude: '45,47060013',
        longitude: '-73,74079895',
        altitude: '118',
        timezone: '-5',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Repulse Bay Airport',
        city: 'Repulse Bay',
        country: 'Canada',
        iataCode: 'YUT',
        icaoCode: 'CYUT',
        latitude: '66,52140045',
        longitude: '-86,22470093',
        altitude: '80',
        timezone: '-6',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Hall Beach Airport',
        city: 'Hall Beach',
        country: 'Canada',
        iataCode: 'YUX',
        icaoCode: 'CYUX',
        latitude: '68,77610016',
        longitude: '-81,2425',
        altitude: '30',
        timezone: '-5',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Rouyn Noranda Airport',
        city: 'Rouyn',
        country: 'Canada',
        iataCode: 'YUY',
        icaoCode: 'CYUY',
        latitude: '48,20610046',
        longitude: '-78,83560181',
        altitude: '988',
        timezone: '-5',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'La Ronge Airport',
        city: 'La Ronge',
        country: 'Canada',
        iataCode: 'YVC',
        icaoCode: 'CYVC',
        latitude: '55,15140152',
        longitude: '-105,262001',
        altitude: '1242',
        timezone: '-6',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Vermilion Airport',
        city: 'Vermillion',
        country: 'Canada',
        iataCode: 'YVG',
        icaoCode: 'CYVG',
        latitude: '53,35580063',
        longitude: '-110,8239975',
        altitude: '2025',
        timezone: '-7',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Qikiqtarjuaq Airport',
        city: 'Broughton Island',
        country: 'Canada',
        iataCode: 'YVM',
        icaoCode: 'CYVM',
        latitude: '67,54579926',
        longitude: '-64,03140259',
        altitude: '21',
        timezone: '-5',
        dst: 'A',
        type: 'airport',
    },
    {
        name: "Val-d'Or Airport",
        city: "Val D'or",
        country: 'Canada',
        iataCode: 'YVO',
        icaoCode: 'CYVO',
        latitude: '48,05329895',
        longitude: '-77,78279877',
        altitude: '1107',
        timezone: '-5',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Kuujjuaq Airport',
        city: 'Quujjuaq',
        country: 'Canada',
        iataCode: 'YVP',
        icaoCode: 'CYVP',
        latitude: '58,09609985',
        longitude: '-68,42690277',
        altitude: '129',
        timezone: '-5',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Norman Wells Airport',
        city: 'Norman Wells',
        country: 'Canada',
        iataCode: 'YVQ',
        icaoCode: 'CYVQ',
        latitude: '65,28160095',
        longitude: '-126,7979965',
        altitude: '238',
        timezone: '-7',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Vancouver International Airport',
        city: 'Vancouver',
        country: 'Canada',
        iataCode: 'YVR',
        icaoCode: 'CYVR',
        latitude: '49,19390106',
        longitude: '-123,1839981',
        altitude: '14',
        timezone: '-8',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Buffalo Narrows Airport',
        city: 'Buffalo Narrows',
        country: 'Canada',
        iataCode: 'YVT',
        icaoCode: 'CYVT',
        latitude: '55,84189987',
        longitude: '-108,4179993',
        altitude: '1423',
        timezone: '-6',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Wiarton Airport',
        city: 'Wiarton',
        country: 'Canada',
        iataCode: 'YVV',
        icaoCode: 'CYVV',
        latitude: '44,7458',
        longitude: '-81,107201',
        altitude: '729',
        timezone: '-5',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Petawawa Airport',
        city: 'Petawawa',
        country: 'Canada',
        iataCode: 'YWA',
        icaoCode: 'CYWA',
        latitude: '45,95220184',
        longitude: '-77,31919861',
        altitude: '427',
        timezone: '-5',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Winnipeg / James Armstrong Richardson International Airport',
        city: 'Winnipeg',
        country: 'Canada',
        iataCode: 'YWG',
        icaoCode: 'CYWG',
        latitude: '49,90999985',
        longitude: '-97,23989868',
        altitude: '783',
        timezone: '-6',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Wabush Airport',
        city: 'Wabush',
        country: 'Canada',
        iataCode: 'YWK',
        icaoCode: 'CYWK',
        latitude: '52,9219017',
        longitude: '-66,86440277',
        altitude: '1808',
        timezone: '-4',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Williams Lake Airport',
        city: 'Williams Lake',
        country: 'Canada',
        iataCode: 'YWL',
        icaoCode: 'CYWL',
        latitude: '52,18310165',
        longitude: '-122,0540009',
        altitude: '3085',
        timezone: '-8',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Wrigley Airport',
        city: 'Wrigley',
        country: 'Canada',
        iataCode: 'YWY',
        icaoCode: 'CYWY',
        latitude: '63,20940018',
        longitude: '-123,4369965',
        altitude: '489',
        timezone: '-7',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Cranbrook/Canadian Rockies International Airport',
        city: 'Cranbrook',
        country: 'Canada',
        iataCode: 'YXC',
        icaoCode: 'CYXC',
        latitude: '49,6108017',
        longitude: '-115,7819977',
        altitude: '3082',
        timezone: '-7',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Edmonton City Centre (Blatchford Field) Airport',
        city: 'Edmonton',
        country: 'Canada',
        iataCode: 'YXD',
        icaoCode: 'CYXD',
        latitude: '53,57249832',
        longitude: '-113,5210037',
        altitude: '2202',
        timezone: '-7',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Saskatoon John G. Diefenbaker International Airport',
        city: 'Saskatoon',
        country: 'Canada',
        iataCode: 'YXE',
        icaoCode: 'CYXE',
        latitude: '52,17079926',
        longitude: '-106,6999969',
        altitude: '1653',
        timezone: '-6',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Medicine Hat Airport',
        city: 'Medicine Hat',
        country: 'Canada',
        iataCode: 'YXH',
        icaoCode: 'CYXH',
        latitude: '50,01890182',
        longitude: '-110,7210007',
        altitude: '2352',
        timezone: '-7',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Fort St John Airport',
        city: 'Fort Saint John',
        country: 'Canada',
        iataCode: 'YXJ',
        icaoCode: 'CYXJ',
        latitude: '56,23809814',
        longitude: '-120,7399979',
        altitude: '2280',
        timezone: '-7',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Sioux Lookout Airport',
        city: 'Sioux Lookout',
        country: 'Canada',
        iataCode: 'YXL',
        icaoCode: 'CYXL',
        latitude: '50,11389923',
        longitude: '-91,90529633',
        altitude: '1258',
        timezone: '-6',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Pangnirtung Airport',
        city: 'Pangnirtung',
        country: 'Canada',
        iataCode: 'YXP',
        icaoCode: 'CYXP',
        latitude: '66,14499664',
        longitude: '-65,71360016',
        altitude: '75',
        timezone: '-5',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Earlton (Timiskaming Regional) Airport',
        city: 'Earlton',
        country: 'Canada',
        iataCode: 'YXR',
        icaoCode: 'CYXR',
        latitude: '47,69740065',
        longitude: '-79,84734535',
        altitude: '800',
        timezone: '-5',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Prince George Airport',
        city: 'Prince George',
        country: 'Canada',
        iataCode: 'YXS',
        icaoCode: 'CYXS',
        latitude: '53,88940048',
        longitude: '-122,6790009',
        altitude: '2267',
        timezone: '-8',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Northwest Regional Airport Terrace-Kitimat',
        city: 'Terrace',
        country: 'Canada',
        iataCode: 'YXT',
        icaoCode: 'CYXT',
        latitude: '54,468498',
        longitude: '-128,576009',
        altitude: '713',
        timezone: '-8',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'London Airport',
        city: 'London',
        country: 'Canada',
        iataCode: 'YXU',
        icaoCode: 'CYXU',
        latitude: '43,035599',
        longitude: '-81,1539',
        altitude: '912',
        timezone: '-5',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Abbotsford Airport',
        city: 'Abbotsford',
        country: 'Canada',
        iataCode: 'YXX',
        icaoCode: 'CYXX',
        latitude: '49,02529907',
        longitude: '-122,3610001',
        altitude: '195',
        timezone: '-8',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Whitehorse / Erik Nielsen International Airport',
        city: 'Whitehorse',
        country: 'Canada',
        iataCode: 'YXY',
        icaoCode: 'CYXY',
        latitude: '60,70959854',
        longitude: '-135,0670013',
        altitude: '2317',
        timezone: '-8',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'North Bay Airport',
        city: 'North Bay',
        country: 'Canada',
        iataCode: 'YYB',
        icaoCode: 'CYYB',
        latitude: '46,36360168',
        longitude: '-79,42279816',
        altitude: '1215',
        timezone: '-5',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Calgary International Airport',
        city: 'Calgary',
        country: 'Canada',
        iataCode: 'YYC',
        icaoCode: 'CYYC',
        latitude: '51,11389923',
        longitude: '-114,0199966',
        altitude: '3557',
        timezone: '-7',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Smithers Airport',
        city: 'Smithers',
        country: 'Canada',
        iataCode: 'YYD',
        icaoCode: 'CYYD',
        latitude: '54,8246994',
        longitude: '-127,1829987',
        altitude: '1712',
        timezone: '-8',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Fort Nelson Airport',
        city: 'Fort Nelson',
        country: 'Canada',
        iataCode: 'YYE',
        icaoCode: 'CYYE',
        latitude: '58,83639908',
        longitude: '-122,5970001',
        altitude: '1253',
        timezone: '-8',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Penticton Airport',
        city: 'Penticton',
        country: 'Canada',
        iataCode: 'YYF',
        icaoCode: 'CYYF',
        latitude: '49,46310043',
        longitude: '-119,6019974',
        altitude: '1129',
        timezone: '-8',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Charlottetown Airport',
        city: 'Charlottetown',
        country: 'Canada',
        iataCode: 'YYG',
        icaoCode: 'CYYG',
        latitude: '46,29000092',
        longitude: '-63,12110138',
        altitude: '160',
        timezone: '-4',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Taloyoak Airport',
        city: 'Spence Bay',
        country: 'Canada',
        iataCode: 'YYH',
        icaoCode: 'CYYH',
        latitude: '69,54669952',
        longitude: '-93,5766983',
        altitude: '92',
        timezone: '-7',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Victoria International Airport',
        city: 'Victoria',
        country: 'Canada',
        iataCode: 'YYJ',
        icaoCode: 'CYYJ',
        latitude: '48,64690018',
        longitude: '-123,4260025',
        altitude: '63',
        timezone: '-8',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Lynn Lake Airport',
        city: 'Lynn Lake',
        country: 'Canada',
        iataCode: 'YYL',
        icaoCode: 'CYYL',
        latitude: '56,86389923',
        longitude: '-101,0759964',
        altitude: '1170',
        timezone: '-6',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Swift Current Airport',
        city: 'Swift Current',
        country: 'Canada',
        iataCode: 'YYN',
        icaoCode: 'CYYN',
        latitude: '50,29190063',
        longitude: '-107,6910019',
        altitude: '2680',
        timezone: '-6',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Churchill Airport',
        city: 'Churchill',
        country: 'Canada',
        iataCode: 'YYQ',
        icaoCode: 'CYYQ',
        latitude: '58,73920059',
        longitude: '-94,06500244',
        altitude: '94',
        timezone: '-6',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Goose Bay Airport',
        city: 'Goose Bay',
        country: 'Canada',
        iataCode: 'YYR',
        icaoCode: 'CYYR',
        latitude: '53,31919861',
        longitude: '-60,42580032',
        altitude: '160',
        timezone: '-4',
        dst: 'A',
        type: 'airport',
    },
    {
        name: "St. John's International Airport",
        city: "St. John's",
        country: 'Canada',
        iataCode: 'YYT',
        icaoCode: 'CYYT',
        latitude: '47,61859894',
        longitude: '-52,75189972',
        altitude: '461',
        timezone: '-3,5',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Kapuskasing Airport',
        city: 'Kapuskasing',
        country: 'Canada',
        iataCode: 'YYU',
        icaoCode: 'CYYU',
        latitude: '49,41389847',
        longitude: '-82,46749878',
        altitude: '743',
        timezone: '-5',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Armstrong Airport',
        city: 'Armstrong',
        country: 'Canada',
        iataCode: 'YYW',
        icaoCode: 'CYYW',
        latitude: '50,29029846',
        longitude: '-88,90969849',
        altitude: '1058',
        timezone: '-5',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Mont Joli Airport',
        city: 'Mont Joli',
        country: 'Canada',
        iataCode: 'YYY',
        icaoCode: 'CYYY',
        latitude: '48,60860062',
        longitude: '-68,20809937',
        altitude: '172',
        timezone: '-5',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Lester B. Pearson International Airport',
        city: 'Toronto',
        country: 'Canada',
        iataCode: 'YYZ',
        icaoCode: 'CYYZ',
        latitude: '43,67720032',
        longitude: '-79,63059998',
        altitude: '569',
        timezone: '-5',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Downsview Airport',
        city: 'Toronto',
        country: 'Canada',
        iataCode: 'YZD',
        icaoCode: 'CYZD',
        latitude: '43,74250031',
        longitude: '-79,46559906',
        altitude: '652',
        timezone: '-5',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Gore Bay Manitoulin Airport',
        city: 'Gore Bay',
        country: 'Canada',
        iataCode: 'YZE',
        icaoCode: 'CYZE',
        latitude: '45,88529968',
        longitude: '-82,56780243',
        altitude: '635',
        timezone: '-5',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Yellowknife Airport',
        city: 'Yellowknife',
        country: 'Canada',
        iataCode: 'YZF',
        icaoCode: 'CYZF',
        latitude: '62,46279907',
        longitude: '-114,4400024',
        altitude: '675',
        timezone: '-7',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Slave Lake Airport',
        city: 'Slave Lake',
        country: 'Canada',
        iataCode: 'YZH',
        icaoCode: 'CYZH',
        latitude: '55,29309845',
        longitude: '-114,7770004',
        altitude: '1912',
        timezone: '-7',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Sandspit Airport',
        city: 'Sandspit',
        country: 'Canada',
        iataCode: 'YZP',
        icaoCode: 'CYZP',
        latitude: '53,25429916',
        longitude: '-131,8139954',
        altitude: '21',
        timezone: '-8',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Chris Hadfield Airport',
        city: 'Sarnia',
        country: 'Canada',
        iataCode: 'YZR',
        icaoCode: 'CYZR',
        latitude: '42,99940109',
        longitude: '-82,30889893',
        altitude: '594',
        timezone: '-5',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Port Hardy Airport',
        city: 'Port Hardy',
        country: 'Canada',
        iataCode: 'YZT',
        icaoCode: 'CYZT',
        latitude: '50,68059921',
        longitude: '-127,3669968',
        altitude: '71',
        timezone: '-8',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Whitecourt Airport',
        city: 'Whitecourt',
        country: 'Canada',
        iataCode: 'YZU',
        icaoCode: 'CYZU',
        latitude: '54,14390182',
        longitude: '-115,7870026',
        altitude: '2567',
        timezone: '-7',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Sept-Îles Airport',
        city: 'Sept-iles',
        country: 'Canada',
        iataCode: 'YZV',
        icaoCode: 'CYZV',
        latitude: '50,22330093',
        longitude: '-66,26560211',
        altitude: '180',
        timezone: '-5',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Teslin Airport',
        city: 'Teslin',
        country: 'Canada',
        iataCode: 'YZW',
        icaoCode: 'CYZW',
        latitude: '60,17279816',
        longitude: '-132,7429962',
        altitude: '2313',
        timezone: '-8',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'CFB Greenwood',
        city: 'Greenwood',
        country: 'Canada',
        iataCode: 'YZX',
        icaoCode: 'CYZX',
        latitude: '44,9844017',
        longitude: '-64,91690063',
        altitude: '92',
        timezone: '-4',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Faro Airport',
        city: 'Faro',
        country: 'Canada',
        iataCode: 'ZFA',
        icaoCode: 'CZFA',
        latitude: '62,20750046',
        longitude: '-133,3760071',
        altitude: '2351',
        timezone: '-8',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Fort Mcpherson Airport',
        city: 'Fort Mcpherson',
        country: 'Canada',
        iataCode: 'ZFM',
        icaoCode: 'CZFM',
        latitude: '67,40750122',
        longitude: '-134,8609924',
        altitude: '116',
        timezone: '-7',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Blida Airport',
        city: 'Blida',
        country: 'Algeria',
        iataCode: 'N',
        icaoCode: 'DAAB',
        latitude: '36,50360107',
        longitude: '2,814169884',
        altitude: '535',
        timezone: '1',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Bou Saada Airport',
        city: 'Bou Saada',
        country: 'Algeria',
        iataCode: 'N',
        icaoCode: 'DAAD',
        latitude: '35,33250046',
        longitude: '4,206389904',
        altitude: '1506',
        timezone: '1',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Soummam Airport',
        city: 'Bejaja',
        country: 'Algeria',
        iataCode: 'BJA',
        icaoCode: 'DAAE',
        latitude: '36,7120018',
        longitude: '5,069920063',
        altitude: '20',
        timezone: '1',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Houari Boumediene Airport',
        city: 'Algier',
        country: 'Algeria',
        iataCode: 'ALG',
        icaoCode: 'DAAG',
        latitude: '36,69100189',
        longitude: '3,215409994',
        altitude: '82',
        timezone: '1',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Djanet Inedbirene Airport',
        city: 'Djanet',
        country: 'Algeria',
        iataCode: 'DJG',
        icaoCode: 'DAAJ',
        latitude: '24,2928009',
        longitude: '9,452440262',
        altitude: '3176',
        timezone: '1',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Boufarik Airport',
        city: 'Boufarik',
        country: 'Algeria',
        iataCode: 'QFD',
        icaoCode: 'DAAK',
        latitude: '36,545799',
        longitude: '2,87611',
        altitude: '335',
        timezone: '1',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Reggane Airport',
        city: 'Reggan',
        country: 'Algeria',
        iataCode: 'N',
        icaoCode: 'DAAN',
        latitude: '26,71010017',
        longitude: '0,285647005',
        altitude: '955',
        timezone: '1',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Illizi Takhamalt Airport',
        city: 'Illizi',
        country: 'Algeria',
        iataCode: 'VVZ',
        icaoCode: 'DAAP',
        latitude: '26,7234993',
        longitude: '8,622650146',
        altitude: '1778',
        timezone: '1',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Ain Oussera Airport',
        city: 'Ain Oussera',
        country: 'Algeria',
        iataCode: 'N',
        icaoCode: 'DAAQ',
        latitude: '35,52539825',
        longitude: '2,878710032',
        altitude: '2132',
        timezone: '1',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Aguenar – Hadj Bey Akhamok Airport',
        city: 'Tamanrasset',
        country: 'Algeria',
        iataCode: 'TMR',
        icaoCode: 'DAAT',
        latitude: '22,81150055',
        longitude: '5,451079845',
        altitude: '4518',
        timezone: '1',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Jijel Ferhat Abbas Airport',
        city: 'Jijel',
        country: 'Algeria',
        iataCode: 'GJL',
        icaoCode: 'DAAV',
        latitude: '36,79510117',
        longitude: '5,87361002',
        altitude: '36',
        timezone: '1',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Mecheria Airport',
        city: 'Mecheria',
        country: 'Algeria',
        iataCode: 'N',
        icaoCode: 'DAAY',
        latitude: '33,53590012',
        longitude: '-0,242353007',
        altitude: '3855',
        timezone: '1',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Relizane Airport',
        city: 'Relizane',
        country: 'Algeria',
        iataCode: 'N',
        icaoCode: 'DAAZ',
        latitude: '35,75220108',
        longitude: '0,626272023',
        altitude: '282',
        timezone: '1',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Annaba Airport',
        city: 'Annaba',
        country: 'Algeria',
        iataCode: 'AAE',
        icaoCode: 'DABB',
        latitude: '36,82220078',
        longitude: '7,809169769',
        altitude: '16',
        timezone: '1',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Mohamed Boudiaf International Airport',
        city: 'Constantine',
        country: 'Algeria',
        iataCode: 'CZL',
        icaoCode: 'DABC',
        latitude: '36,27600098',
        longitude: '6,620389938',
        altitude: '2265',
        timezone: '1',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Cheikh Larbi Tébessi Airport',
        city: 'Tebessa',
        country: 'Algeria',
        iataCode: 'TEE',
        icaoCode: 'DABS',
        latitude: '35,43159866',
        longitude: '8,12071991',
        altitude: '2661',
        timezone: '1',
        dst: 'N',
        type: 'airport',
    },
    {
        name: "Hassi R'Mel Airport",
        city: 'Tilrempt',
        country: 'Algeria',
        iataCode: 'HRM',
        icaoCode: 'DAFH',
        latitude: '32,93040085',
        longitude: '3,311539888',
        altitude: '2540',
        timezone: '1',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Bou Chekif Airport',
        city: 'Tiaret',
        country: 'Algeria',
        iataCode: 'TID',
        icaoCode: 'DAOB',
        latitude: '35,34109879',
        longitude: '1,463150024',
        altitude: '3245',
        timezone: '1',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Bou Sfer Airport',
        city: 'Bou Sfer',
        country: 'Algeria',
        iataCode: 'N',
        icaoCode: 'DAOE',
        latitude: '35,73540115',
        longitude: '-0,805388987',
        altitude: '187',
        timezone: '1',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Tindouf Airport',
        city: 'Tindouf',
        country: 'Algeria',
        iataCode: 'TIN',
        icaoCode: 'DAOF',
        latitude: '27,7003994',
        longitude: '-8,167099953',
        altitude: '1453',
        timezone: '1',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Ech Cheliff Airport',
        city: 'Ech-cheliff',
        country: 'Algeria',
        iataCode: 'QAS',
        icaoCode: 'DAOI',
        latitude: '36,21269989',
        longitude: '1,331769943',
        altitude: '463',
        timezone: '1',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Tafaraoui Airport',
        city: 'Oran',
        country: 'Algeria',
        iataCode: 'TAF',
        icaoCode: 'DAOL',
        latitude: '35,54240036',
        longitude: '-0,532278001',
        altitude: '367',
        timezone: '1',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Zenata – Messali El Hadj Airport',
        city: 'Tlemcen',
        country: 'Algeria',
        iataCode: 'TLM',
        icaoCode: 'DAON',
        latitude: '35,01670074',
        longitude: '-1,450000048',
        altitude: '814',
        timezone: '1',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Es Senia Airport',
        city: 'Oran',
        country: 'Algeria',
        iataCode: 'ORN',
        icaoCode: 'DAOO',
        latitude: '35,62390137',
        longitude: '-0,621182978',
        altitude: '295',
        timezone: '1',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Sidi Bel Abbes Airport',
        city: 'Sidi Bel Abbes',
        country: 'Algeria',
        iataCode: 'N',
        icaoCode: 'DAOS',
        latitude: '35,17179871',
        longitude: '-0,593275011',
        altitude: '1614',
        timezone: '1',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Ghriss Airport',
        city: 'Ghriss',
        country: 'Algeria',
        iataCode: 'MUW',
        icaoCode: 'DAOV',
        latitude: '35,20769882',
        longitude: '0,147141993',
        altitude: '1686',
        timezone: '1',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Touat Cheikh Sidi Mohamed Belkebir Airport',
        city: 'Adrar',
        country: 'Algeria',
        iataCode: 'AZR',
        icaoCode: 'DAUA',
        latitude: '27,83760071',
        longitude: '-0,186414003',
        altitude: '919',
        timezone: '1',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Biskra Airport',
        city: 'Biskra',
        country: 'Algeria',
        iataCode: 'BSK',
        icaoCode: 'DAUB',
        latitude: '34,79330063',
        longitude: '5,738230228',
        altitude: '289',
        timezone: '1',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'El Golea Airport',
        city: 'El Golea',
        country: 'Algeria',
        iataCode: 'ELG',
        icaoCode: 'DAUE',
        latitude: '30,57130051',
        longitude: '2,859590054',
        altitude: '1306',
        timezone: '1',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Noumérat - Moufdi Zakaria Airport',
        city: 'Ghardaia',
        country: 'Algeria',
        iataCode: 'GHA',
        icaoCode: 'DAUG',
        latitude: '32,38410187',
        longitude: '3,79411006',
        altitude: '1512',
        timezone: '1',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Oued Irara Airport',
        city: 'Hassi Messaoud',
        country: 'Algeria',
        iataCode: 'HME',
        icaoCode: 'DAUH',
        latitude: '31,67300034',
        longitude: '6,140439987',
        altitude: '463',
        timezone: '1',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'In Salah Airport',
        city: 'In Salah',
        country: 'Algeria',
        iataCode: 'INZ',
        icaoCode: 'DAUI',
        latitude: '27,25099945',
        longitude: '2,512020111',
        altitude: '896',
        timezone: '1',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Touggourt Sidi Madhi Airport',
        city: 'Touggourt',
        country: 'Algeria',
        iataCode: 'TGR',
        icaoCode: 'DAUK',
        latitude: '33,06779861',
        longitude: '6,088669777',
        altitude: '279',
        timezone: '1',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Laghouat Airport',
        city: 'Laghouat',
        country: 'Algeria',
        iataCode: 'LOO',
        icaoCode: 'DAUL',
        latitude: '33,76440048',
        longitude: '2,928339958',
        altitude: '2510',
        timezone: '1',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Timimoun Airport',
        city: 'Timimoun',
        country: 'Algeria',
        iataCode: 'TMX',
        icaoCode: 'DAUT',
        latitude: '29,2371006',
        longitude: '0,276033014',
        altitude: '1027',
        timezone: '1',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Ain el Beida Airport',
        city: 'Ouargla',
        country: 'Algeria',
        iataCode: 'OGX',
        icaoCode: 'DAUU',
        latitude: '31,91720009',
        longitude: '5,412779808',
        altitude: '492',
        timezone: '1',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'In Aménas Airport',
        city: 'Zarzaitine',
        country: 'Algeria',
        iataCode: 'IAM',
        icaoCode: 'DAUZ',
        latitude: '28,05150032',
        longitude: '9,642910004',
        altitude: '1847',
        timezone: '1',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Cadjehoun Airport',
        city: 'Cotonou',
        country: 'Benin',
        iataCode: 'COO',
        icaoCode: 'DBBB',
        latitude: '6,357230186',
        longitude: '2,384350061',
        altitude: '19',
        timezone: '1',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Ouagadougou Airport',
        city: 'Ouagadougou',
        country: 'Burkina Faso',
        iataCode: 'OUA',
        icaoCode: 'DFFD',
        latitude: '12,35319996',
        longitude: '-1,512420058',
        altitude: '1037',
        timezone: '0',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Bobo Dioulasso Airport',
        city: 'Bobo-dioulasso',
        country: 'Burkina Faso',
        iataCode: 'BOY',
        icaoCode: 'DFOO',
        latitude: '11,16009998',
        longitude: '-4,33096981',
        altitude: '1511',
        timezone: '0',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Kotoka International Airport',
        city: 'Accra',
        country: 'Ghana',
        iataCode: 'ACC',
        icaoCode: 'DGAA',
        latitude: '5,6051898',
        longitude: '-0,166786',
        altitude: '205',
        timezone: '0',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Tamale Airport',
        city: 'Tamale',
        country: 'Ghana',
        iataCode: 'TML',
        icaoCode: 'DGLE',
        latitude: '9,557189941',
        longitude: '-0,863214016',
        altitude: '553',
        timezone: '0',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Wa Airport',
        city: 'Wa',
        country: 'Ghana',
        iataCode: 'N',
        icaoCode: 'DGLW',
        latitude: '10,08269978',
        longitude: '-2,507689953',
        altitude: '1060',
        timezone: '0',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Sunyani Airport',
        city: 'Sunyani',
        country: 'Ghana',
        iataCode: 'NYI',
        icaoCode: 'DGSN',
        latitude: '7,361830235',
        longitude: '-2,328759909',
        altitude: '1014',
        timezone: '0',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Takoradi Airport',
        city: 'Takoradi',
        country: 'Ghana',
        iataCode: 'TKD',
        icaoCode: 'DGTK',
        latitude: '4,89605999',
        longitude: '-1,774760008',
        altitude: '21',
        timezone: '0',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Port Bouet Airport',
        city: 'Abidjan',
        country: "Cote d'Ivoire",
        iataCode: 'ABJ',
        icaoCode: 'DIAP',
        latitude: '5,261390209',
        longitude: '-3,926290035',
        altitude: '21',
        timezone: '0',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Bouaké Airport',
        city: 'Bouake',
        country: "Cote d'Ivoire",
        iataCode: 'BYK',
        icaoCode: 'DIBK',
        latitude: '7,738800049',
        longitude: '-5,07366991',
        altitude: '1230',
        timezone: '0',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Daloa Airport',
        city: 'Daloa',
        country: "Cote d'Ivoire",
        iataCode: 'DJO',
        icaoCode: 'DIDL',
        latitude: '6,792809963',
        longitude: '-6,473189831',
        altitude: '823',
        timezone: '0',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Korhogo Airport',
        city: 'Korhogo',
        country: "Cote d'Ivoire",
        iataCode: 'HGO',
        icaoCode: 'DIKO',
        latitude: '9,387180328',
        longitude: '-5,556660175',
        altitude: '1214',
        timezone: '0',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Man Airport',
        city: 'Man',
        country: "Cote d'Ivoire",
        iataCode: 'MJC',
        icaoCode: 'DIMN',
        latitude: '7,272069931',
        longitude: '-7,587359905',
        altitude: '1089',
        timezone: '0',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'San Pedro Airport',
        city: 'San Pedro',
        country: "Cote d'Ivoire",
        iataCode: 'SPY',
        icaoCode: 'DISP',
        latitude: '4,746719837',
        longitude: '-6,660820007',
        altitude: '26',
        timezone: '0',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Yamoussoukro Airport',
        city: 'Yamoussoukro',
        country: "Cote d'Ivoire",
        iataCode: 'ASK',
        icaoCode: 'DIYO',
        latitude: '6,903170109',
        longitude: '-5,365580082',
        altitude: '699',
        timezone: '0',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Nnamdi Azikiwe International Airport',
        city: 'Abuja',
        country: 'Nigeria',
        iataCode: 'ABV',
        icaoCode: 'DNAA',
        latitude: '9,006790161',
        longitude: '7,263169765',
        altitude: '1123',
        timezone: '1',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Akure Airport',
        city: 'Akure',
        country: 'Nigeria',
        iataCode: 'AKR',
        icaoCode: 'DNAK',
        latitude: '7,246739864',
        longitude: '5,301010132',
        altitude: '1100',
        timezone: '1',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Benin Airport',
        city: 'Benin',
        country: 'Nigeria',
        iataCode: 'BNI',
        icaoCode: 'DNBE',
        latitude: '6,316979885',
        longitude: '5,599500179',
        altitude: '258',
        timezone: '1',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Margaret Ekpo International Airport',
        city: 'Calabar',
        country: 'Nigeria',
        iataCode: 'CBQ',
        icaoCode: 'DNCA',
        latitude: '4,976019859',
        longitude: '8,347200394',
        altitude: '210',
        timezone: '1',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Akanu Ibiam International Airport',
        city: 'Enugu',
        country: 'Nigeria',
        iataCode: 'ENU',
        icaoCode: 'DNEN',
        latitude: '6,474269867',
        longitude: '7,56196022',
        altitude: '466',
        timezone: '1',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Gusau Airport',
        city: 'Gusau',
        country: 'Nigeria',
        iataCode: 'QUS',
        icaoCode: 'DNGU',
        latitude: '12,17169952',
        longitude: '6,696109772',
        altitude: '1520',
        timezone: '1',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Ibadan Airport',
        city: 'Ibadan',
        country: 'Nigeria',
        iataCode: 'IBA',
        icaoCode: 'DNIB',
        latitude: '7,362460136',
        longitude: '3,978329897',
        altitude: '725',
        timezone: '1',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Ilorin International Airport',
        city: 'Ilorin',
        country: 'Nigeria',
        iataCode: 'ILR',
        icaoCode: 'DNIL',
        latitude: '8,440210342',
        longitude: '4,493919849',
        altitude: '1126',
        timezone: '1',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Yakubu Gowon Airport',
        city: 'Jos',
        country: 'Nigeria',
        iataCode: 'JOS',
        icaoCode: 'DNJO',
        latitude: '9,639829636',
        longitude: '8,869050026',
        altitude: '4232',
        timezone: '1',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Kaduna Airport',
        city: 'Kaduna',
        country: 'Nigeria',
        iataCode: 'KAD',
        icaoCode: 'DNKA',
        latitude: '10,6960001',
        longitude: '7,320109844',
        altitude: '2073',
        timezone: '1',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Mallam Aminu International Airport',
        city: 'Kano',
        country: 'Nigeria',
        iataCode: 'KAN',
        icaoCode: 'DNKN',
        latitude: '12,04759979',
        longitude: '8,524620056',
        altitude: '1562',
        timezone: '1',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Maiduguri International Airport',
        city: 'Maiduguri',
        country: 'Nigeria',
        iataCode: 'MIU',
        icaoCode: 'DNMA',
        latitude: '11,85529995',
        longitude: '13,08090019',
        altitude: '1099',
        timezone: '1',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Makurdi Airport',
        city: 'Makurdi',
        country: 'Nigeria',
        iataCode: 'MDI',
        icaoCode: 'DNMK',
        latitude: '7,703879833',
        longitude: '8,613940239',
        altitude: '371',
        timezone: '1',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Murtala Muhammed International Airport',
        city: 'Lagos',
        country: 'Nigeria',
        iataCode: 'LOS',
        icaoCode: 'DNMM',
        latitude: '6,577370167',
        longitude: '3,321160078',
        altitude: '135',
        timezone: '1',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Minna Airport',
        city: 'Minna',
        country: 'Nigeria',
        iataCode: 'MXJ',
        icaoCode: 'DNMN',
        latitude: '9,652170181',
        longitude: '6,462259769',
        altitude: '834',
        timezone: '1',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Port Harcourt International Airport',
        city: 'Port Hartcourt',
        country: 'Nigeria',
        iataCode: 'PHC',
        icaoCode: 'DNPO',
        latitude: '5,015490055',
        longitude: '6,949590206',
        altitude: '87',
        timezone: '1',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Sadiq Abubakar III International Airport',
        city: 'Sokoto',
        country: 'Nigeria',
        iataCode: 'SKO',
        icaoCode: 'DNSO',
        latitude: '12,91629982',
        longitude: '5,207190037',
        altitude: '1010',
        timezone: '1',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Yola Airport',
        city: 'Yola',
        country: 'Nigeria',
        iataCode: 'YOL',
        icaoCode: 'DNYO',
        latitude: '9,25755024',
        longitude: '12,43039989',
        altitude: '599',
        timezone: '1',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Zaria Airport',
        city: 'Zaria',
        country: 'Nigeria',
        iataCode: 'ZAR',
        icaoCode: 'DNZA',
        latitude: '11,13020039',
        longitude: '7,685810089',
        altitude: '2170',
        timezone: '1',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Maradi Airport',
        city: 'Maradi',
        country: 'Niger',
        iataCode: 'MFQ',
        icaoCode: 'DRRM',
        latitude: '13,50249958',
        longitude: '7,126749992',
        altitude: '1240',
        timezone: '1',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Diori Hamani International Airport',
        city: 'Niamey',
        country: 'Niger',
        iataCode: 'NIM',
        icaoCode: 'DRRN',
        latitude: '13,48149967',
        longitude: '2,183609962',
        altitude: '732',
        timezone: '1',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Tahoua Airport',
        city: 'Tahoua',
        country: 'Niger',
        iataCode: 'THZ',
        icaoCode: 'DRRT',
        latitude: '14,8757',
        longitude: '5,265359879',
        altitude: '1266',
        timezone: '1',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Mano Dayak International Airport',
        city: 'Agadez',
        country: 'Niger',
        iataCode: 'AJY',
        icaoCode: 'DRZA',
        latitude: '16,9659996',
        longitude: '8,000109673',
        altitude: '1657',
        timezone: '1',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Dirkou Airport',
        city: 'Dirkou',
        country: 'Niger',
        iataCode: 'N',
        icaoCode: 'DRZD',
        latitude: '18,96870041',
        longitude: '12,86870003',
        altitude: '1273',
        timezone: '1',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Diffa Airport',
        city: 'Diffa',
        country: 'Niger',
        iataCode: 'N',
        icaoCode: 'DRZF',
        latitude: '13,37290001',
        longitude: '12,6267004',
        altitude: '994',
        timezone: '1',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Zinder Airport',
        city: 'Zinder',
        country: 'Niger',
        iataCode: 'ZND',
        icaoCode: 'DRZR',
        latitude: '13,77900028',
        longitude: '8,98375988',
        altitude: '1516',
        timezone: '1',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Monastir Habib Bourguiba International Airport',
        city: 'Monastir',
        country: 'Tunisia',
        iataCode: 'MIR',
        icaoCode: 'DTMB',
        latitude: '35,7580986',
        longitude: '10,75469971',
        altitude: '9',
        timezone: '1',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Tunis Carthage International Airport',
        city: 'Tunis',
        country: 'Tunisia',
        iataCode: 'TUN',
        icaoCode: 'DTTA',
        latitude: '36,85100174',
        longitude: '10,22719955',
        altitude: '22',
        timezone: '1',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Sidi Ahmed Air Base',
        city: 'Bizerte',
        country: 'Tunisia',
        iataCode: 'N',
        icaoCode: 'DTTB',
        latitude: '37,24539948',
        longitude: '9,791449547',
        altitude: '20',
        timezone: '1',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Remada Air Base',
        city: 'Remada',
        country: 'Tunisia',
        iataCode: 'N',
        icaoCode: 'DTTD',
        latitude: '32,30619812',
        longitude: '10,38210011',
        altitude: '1004',
        timezone: '1',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Gafsa Ksar International Airport',
        city: 'Gafsa',
        country: 'Tunisia',
        iataCode: 'GAF',
        icaoCode: 'DTTF',
        latitude: '34,42200089',
        longitude: '8,822500229',
        altitude: '1060',
        timezone: '1',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Gabès Matmata International Airport',
        city: 'Gabes',
        country: 'Tunisia',
        iataCode: 'GAE',
        icaoCode: 'DTTG',
        latitude: '33,87689972',
        longitude: '10,10330009',
        altitude: '26',
        timezone: '1',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Borj El Amri Airport',
        city: 'Bordj El Amri',
        country: 'Tunisia',
        iataCode: 'N',
        icaoCode: 'DTTI',
        latitude: '36,72129822',
        longitude: '9,943149567',
        altitude: '110',
        timezone: '1',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Djerba Zarzis International Airport',
        city: 'Djerba',
        country: 'Tunisia',
        iataCode: 'DJE',
        icaoCode: 'DTTJ',
        latitude: '33,875',
        longitude: '10,7755003',
        altitude: '19',
        timezone: '1',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'El Borma Airport',
        city: 'El Borma',
        country: 'Tunisia',
        iataCode: 'EBM',
        icaoCode: 'DTTR',
        latitude: '31,70429993',
        longitude: '9,254619598',
        altitude: '827',
        timezone: '1',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Sfax Thyna International Airport',
        city: 'Sfax',
        country: 'Tunisia',
        iataCode: 'SFA',
        icaoCode: 'DTTX',
        latitude: '34,7179985',
        longitude: '10,69099998',
        altitude: '85',
        timezone: '1',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Tozeur Nefta International Airport',
        city: 'Tozeur',
        country: 'Tunisia',
        iataCode: 'TOE',
        icaoCode: 'DTTZ',
        latitude: '33,93970108',
        longitude: '8,110560417',
        altitude: '287',
        timezone: '1',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Niamtougou International Airport',
        city: 'Niatougou',
        country: 'Togo',
        iataCode: 'LRL',
        icaoCode: 'DXNG',
        latitude: '9,76733017',
        longitude: '1,091249943',
        altitude: '1515',
        timezone: '0',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Lomé-Tokoin Airport',
        city: 'Lome',
        country: 'Togo',
        iataCode: 'LFW',
        icaoCode: 'DXXX',
        latitude: '6,165609837',
        longitude: '1,254510045',
        altitude: '72',
        timezone: '0',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Antwerp International Airport (Deurne)',
        city: 'Antwerp',
        country: 'Belgium',
        iataCode: 'ANR',
        icaoCode: 'EBAW',
        latitude: '51,18939972',
        longitude: '4,460279942',
        altitude: '39',
        timezone: '1',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Beauvechain Air Base',
        city: 'Beauvechain',
        country: 'Belgium',
        iataCode: 'N',
        icaoCode: 'EBBE',
        latitude: '50,75859833',
        longitude: '4,768330097',
        altitude: '370',
        timezone: '1',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Kleine Brogel Air Base',
        city: 'Kleine Brogel',
        country: 'Belgium',
        iataCode: 'N',
        icaoCode: 'EBBL',
        latitude: '51,168301',
        longitude: '5,47',
        altitude: '200',
        timezone: '1',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Brussels Airport',
        city: 'Brussels',
        country: 'Belgium',
        iataCode: 'BRU',
        icaoCode: 'EBBR',
        latitude: '50,90140152',
        longitude: '4,48443985',
        altitude: '184',
        timezone: '1',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Jehonville Air Base',
        city: 'Bertrix',
        country: 'Belgium',
        iataCode: 'N',
        icaoCode: 'EBBX',
        latitude: '49,89170074',
        longitude: '5,223889828',
        altitude: '1514',
        timezone: '1',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Brussels South Charleroi Airport',
        city: 'Charleroi',
        country: 'Belgium',
        iataCode: 'CRL',
        icaoCode: 'EBCI',
        latitude: '50,45920181',
        longitude: '4,453820229',
        altitude: '614',
        timezone: '1',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Chièvres Air Base',
        city: 'Chievres',
        country: 'Belgium',
        iataCode: 'N',
        icaoCode: 'EBCV',
        latitude: '50,57580185',
        longitude: '3,83100009',
        altitude: '194',
        timezone: '1',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Koksijde Air Base',
        city: 'Koksijde',
        country: 'Belgium',
        iataCode: 'N',
        icaoCode: 'EBFN',
        latitude: '51,09030151',
        longitude: '2,652780056',
        altitude: '20',
        timezone: '1',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Florennes Air Base',
        city: 'Florennes',
        country: 'Belgium',
        iataCode: 'N',
        icaoCode: 'EBFS',
        latitude: '50,24330139',
        longitude: '4,645830154',
        altitude: '935',
        timezone: '1',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Wevelgem Airport',
        city: 'Kortrijk-vevelgem',
        country: 'Belgium',
        iataCode: 'QKT',
        icaoCode: 'EBKT',
        latitude: '50,81719971',
        longitude: '3,20472002',
        altitude: '64',
        timezone: '1',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Liège Airport',
        city: 'Liege',
        country: 'Belgium',
        iataCode: 'LGG',
        icaoCode: 'EBLG',
        latitude: '50,63740158',
        longitude: '5,443220139',
        altitude: '659',
        timezone: '1',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Ostend-Bruges International Airport',
        city: 'Ostend',
        country: 'Belgium',
        iataCode: 'OST',
        icaoCode: 'EBOS',
        latitude: '51,19889832',
        longitude: '2,862220049',
        altitude: '13',
        timezone: '1',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Zutendaal Air Base',
        city: 'Zutendaal',
        country: 'Belgium',
        iataCode: 'N',
        icaoCode: 'EBSL',
        latitude: '50,94749832',
        longitude: '5,590559959',
        altitude: '312',
        timezone: '1',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Limburg Regional Airport',
        city: 'Sint-truiden',
        country: 'Belgium',
        iataCode: 'N',
        icaoCode: 'EBST',
        latitude: '50,79190063',
        longitude: '5,20167017',
        altitude: '246',
        timezone: '1',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Saint Hubert Air Base',
        city: 'St.-hubert',
        country: 'Belgium',
        iataCode: 'N',
        icaoCode: 'EBSU',
        latitude: '50,03440094',
        longitude: '5,440810204',
        altitude: '1930',
        timezone: '1',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Ursel Air Base',
        city: 'Ursel',
        country: 'Belgium',
        iataCode: 'N',
        icaoCode: 'EBUL',
        latitude: '51,14419937',
        longitude: '3,47555995',
        altitude: '95',
        timezone: '1',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Weelde Air Base',
        city: 'Weelde',
        country: 'Belgium',
        iataCode: 'N',
        icaoCode: 'EBWE',
        latitude: '51,39479828',
        longitude: '4,960189819',
        altitude: '105',
        timezone: '1',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Oostmalle Air Base',
        city: 'Zoersel',
        country: 'Belgium',
        iataCode: 'N',
        icaoCode: 'EBZR',
        latitude: '51,264702',
        longitude: '4,75333',
        altitude: '53',
        timezone: '1',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Bautzen Airport',
        city: 'Bautzen',
        country: 'Germany',
        iataCode: 'N',
        icaoCode: 'EDAB',
        latitude: '51,19361115',
        longitude: '14,51972198',
        altitude: '568',
        timezone: '1',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Altenburg-Nobitz Airport',
        city: 'Altenburg',
        country: 'Germany',
        iataCode: 'AOC',
        icaoCode: 'EDAC',
        latitude: '50,98194504',
        longitude: '12,50638866',
        altitude: '640',
        timezone: '1',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Dessau Airport',
        city: 'Dessau',
        country: 'Germany',
        iataCode: 'N',
        icaoCode: 'EDAD',
        latitude: '51,83169354',
        longitude: '12,19096184',
        altitude: '187',
        timezone: '1',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Eisenhüttenstadt Airport',
        city: 'Eisenhuettenstadt',
        country: 'Germany',
        iataCode: 'N',
        icaoCode: 'EDAE',
        latitude: '52,195856',
        longitude: '14,58753',
        altitude: '144',
        timezone: '1',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Großenhain Airport',
        city: 'Suhl',
        country: 'Germany',
        iataCode: 'N',
        icaoCode: 'EDAK',
        latitude: '51,30805588',
        longitude: '13,5555563',
        altitude: '417',
        timezone: '1',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Merseburg Airport',
        city: 'Muehlhausen',
        country: 'Germany',
        iataCode: 'N',
        icaoCode: 'EDAM',
        latitude: '51,3630556',
        longitude: '11,9408333',
        altitude: '341',
        timezone: '1',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Halle-Oppin Airport',
        city: 'Halle',
        country: 'Germany',
        iataCode: 'N',
        icaoCode: 'EDAQ',
        latitude: '51,552223',
        longitude: '12,053889',
        altitude: '348',
        timezone: '1',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Riesa-Göhlis Airport',
        city: 'Riesa',
        country: 'Germany',
        iataCode: 'N',
        icaoCode: 'EDAU',
        latitude: '51,29360962',
        longitude: '13,35611057',
        altitude: '322',
        timezone: '1',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Rechlin-Lärz Airport',
        city: 'Rechlin-laerz',
        country: 'Germany',
        iataCode: 'N',
        icaoCode: 'EDAX',
        latitude: '53,30638886',
        longitude: '12,75222206',
        altitude: '220',
        timezone: '1',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Strausberg Airport',
        city: 'Strausberg',
        country: 'Germany',
        iataCode: 'N',
        icaoCode: 'EDAY',
        latitude: '52,58055496',
        longitude: '13,91666698',
        altitude: '262',
        timezone: '1',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Schönhagen Airport',
        city: 'Schoenhagen',
        country: 'Germany',
        iataCode: 'N',
        icaoCode: 'EDAZ',
        latitude: '52,20360947',
        longitude: '13,15638924',
        altitude: '131',
        timezone: '1',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Barth Airport',
        city: 'Barth',
        country: 'Germany',
        iataCode: 'BBH',
        icaoCode: 'EDBH',
        latitude: '54,338253',
        longitude: '12,710515',
        altitude: '23',
        timezone: '1',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Jena-Schöngleina Airport',
        city: 'Jena',
        country: 'Germany',
        iataCode: 'N',
        icaoCode: 'EDBJ',
        latitude: '50,91527939',
        longitude: '11,71444416',
        altitude: '1247',
        timezone: '1',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Kyritz Airport',
        city: 'Kyritz',
        country: 'Germany',
        iataCode: 'N',
        icaoCode: 'EDBK',
        latitude: '52,91888809',
        longitude: '12,42527771',
        altitude: '131',
        timezone: '1',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Magdeburg City" Airport"',
        city: 'Magdeburg',
        country: 'Germany',
        iataCode: 'N',
        icaoCode: 'EDBM',
        latitude: '52,073612',
        longitude: '11,626389',
        altitude: '259',
        timezone: '1',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Rothenburg/Görlitz Airport',
        city: 'Rothenburg/ol',
        country: 'Germany',
        iataCode: 'N',
        icaoCode: 'EDBR',
        latitude: '51,36333466',
        longitude: '14,94999981',
        altitude: '518',
        timezone: '1',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Anklam Airport',
        city: 'Anklam',
        country: 'Germany',
        iataCode: 'N',
        icaoCode: 'EDCA',
        latitude: '53,83277893',
        longitude: '13,66861057',
        altitude: '16',
        timezone: '1',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Cottbus-Drewitz Airport',
        city: 'Cottbus',
        country: 'Germany',
        iataCode: 'N',
        icaoCode: 'EDCD',
        latitude: '51,889442',
        longitude: '14,531944',
        altitude: '272',
        timezone: '1',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Kamenz Airport',
        city: 'Kamenz',
        country: 'Germany',
        iataCode: 'N',
        icaoCode: 'EDCM',
        latitude: '51,29694366',
        longitude: '14,12749958',
        altitude: '495',
        timezone: '1',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Berlin-Schönefeld International Airport',
        city: 'Berlin',
        country: 'Germany',
        iataCode: 'SXF',
        icaoCode: 'EDDB',
        latitude: '52,38000107',
        longitude: '13,52250004',
        altitude: '157',
        timezone: '1',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Dresden Airport',
        city: 'Dresden',
        country: 'Germany',
        iataCode: 'DRS',
        icaoCode: 'EDDC',
        latitude: '51,13280106',
        longitude: '13,76720047',
        altitude: '755',
        timezone: '1',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Erfurt Airport',
        city: 'Erfurt',
        country: 'Germany',
        iataCode: 'ERF',
        icaoCode: 'EDDE',
        latitude: '50,97980118',
        longitude: '10,95810032',
        altitude: '1036',
        timezone: '1',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Frankfurt am Main International Airport',
        city: 'Frankfurt',
        country: 'Germany',
        iataCode: 'FRA',
        icaoCode: 'EDDF',
        latitude: '50,0333333',
        longitude: '8,5705556',
        altitude: '364',
        timezone: '1',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Münster Osnabrück Airport',
        city: 'Munster',
        country: 'Germany',
        iataCode: 'FMO',
        icaoCode: 'EDDG',
        latitude: '52,13460159',
        longitude: '7,684830189',
        altitude: '160',
        timezone: '1',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Hamburg Airport',
        city: 'Hamburg',
        country: 'Germany',
        iataCode: 'HAM',
        icaoCode: 'EDDH',
        latitude: '53,63040161',
        longitude: '9,988229752',
        altitude: '53',
        timezone: '1',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Berlin-Tempelhof International Airport',
        city: 'Berlin',
        country: 'Germany',
        iataCode: 'THF',
        icaoCode: 'EDDI',
        latitude: '52,47299957',
        longitude: '13,40390015',
        altitude: '167',
        timezone: '1',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Cologne Bonn Airport',
        city: 'Cologne',
        country: 'Germany',
        iataCode: 'CGN',
        icaoCode: 'EDDK',
        latitude: '50,86589813',
        longitude: '7,142739773',
        altitude: '302',
        timezone: '1',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Düsseldorf International Airport',
        city: 'Duesseldorf',
        country: 'Germany',
        iataCode: 'DUS',
        icaoCode: 'EDDL',
        latitude: '51,28950119',
        longitude: '6,7667799',
        altitude: '147',
        timezone: '1',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Munich International Airport',
        city: 'Munich',
        country: 'Germany',
        iataCode: 'MUC',
        icaoCode: 'EDDM',
        latitude: '48,35380173',
        longitude: '11,78610039',
        altitude: '1487',
        timezone: '1',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Nuremberg Airport',
        city: 'Nuernberg',
        country: 'Germany',
        iataCode: 'NUE',
        icaoCode: 'EDDN',
        latitude: '49,498699',
        longitude: '11,0780556',
        altitude: '1046',
        timezone: '1',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Leipzig Halle Airport',
        city: 'Leipzig',
        country: 'Germany',
        iataCode: 'LEJ',
        icaoCode: 'EDDP',
        latitude: '51,4238889',
        longitude: '12,2363889',
        altitude: '465',
        timezone: '1',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Saarbrücken Airport',
        city: 'Saarbruecken',
        country: 'Germany',
        iataCode: 'SCN',
        icaoCode: 'EDDR',
        latitude: '49,21459961',
        longitude: '7,109509945',
        altitude: '1058',
        timezone: '1',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Stuttgart Airport',
        city: 'Stuttgart',
        country: 'Germany',
        iataCode: 'STR',
        icaoCode: 'EDDS',
        latitude: '48,68989944',
        longitude: '9,221960068',
        altitude: '1276',
        timezone: '1',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Berlin-Tegel International Airport',
        city: 'Berlin',
        country: 'Germany',
        iataCode: 'TXL',
        icaoCode: 'EDDT',
        latitude: '52,55970001',
        longitude: '13,2876997',
        altitude: '122',
        timezone: '1',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Hannover Airport',
        city: 'Hannover',
        country: 'Germany',
        iataCode: 'HAJ',
        icaoCode: 'EDDV',
        latitude: '52,46110153',
        longitude: '9,685079575',
        altitude: '183',
        timezone: '1',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Bremen Airport',
        city: 'Bremen',
        country: 'Germany',
        iataCode: 'BRE',
        icaoCode: 'EDDW',
        latitude: '53,04750061',
        longitude: '8,786669731',
        altitude: '14',
        timezone: '1',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Frankfurt-Egelsbach Airport',
        city: 'Egelsbach',
        country: 'Germany',
        iataCode: 'QEF',
        icaoCode: 'EDFE',
        latitude: '49,95999908',
        longitude: '8,645833015',
        altitude: '384',
        timezone: '1',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Frankfurt-Hahn Airport',
        city: 'Hahn',
        country: 'Germany',
        iataCode: 'HHN',
        icaoCode: 'EDFH',
        latitude: '49,94869995',
        longitude: '7,26388979',
        altitude: '1649',
        timezone: '1',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Mannheim-City Airport',
        city: 'Mannheim',
        country: 'Germany',
        iataCode: 'MHG',
        icaoCode: 'EDFM',
        latitude: '49,47305679',
        longitude: '8,514166832',
        altitude: '308',
        timezone: '1',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Allendorf/Eder Airport',
        city: 'Allendorf',
        country: 'Germany',
        iataCode: 'N',
        icaoCode: 'EDFQ',
        latitude: '51,03499985',
        longitude: '8,680832863',
        altitude: '1158',
        timezone: '1',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Worms Airport',
        city: 'Worms',
        country: 'Germany',
        iataCode: 'N',
        icaoCode: 'EDFV',
        latitude: '49,606945',
        longitude: '8,368333',
        altitude: '295',
        timezone: '1',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Mainz-Finthen Airport',
        city: 'Mainz',
        country: 'Germany',
        iataCode: 'N',
        icaoCode: 'EDFZ',
        latitude: '49,967499',
        longitude: '8,147222',
        altitude: '525',
        timezone: '1',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Eisenach-Kindel Airport',
        city: 'Eisenach',
        country: 'Germany',
        iataCode: 'N',
        icaoCode: 'EDGE',
        latitude: '50,991604',
        longitude: '10,47973',
        altitude: '1112',
        timezone: '1',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Siegerland Airport',
        city: 'Siegerland',
        country: 'Germany',
        iataCode: 'N',
        icaoCode: 'EDGS',
        latitude: '50,70769882',
        longitude: '8,082969666',
        altitude: '1966',
        timezone: '1',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Hamburg-Finkenwerder Airport',
        city: 'Hamburg',
        country: 'Germany',
        iataCode: 'XFW',
        icaoCode: 'EDHI',
        latitude: '53,53527832',
        longitude: '9,83555603',
        altitude: '23',
        timezone: '1',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Kiel-Holtenau Airport',
        city: 'Kiel',
        country: 'Germany',
        iataCode: 'KEL',
        icaoCode: 'EDHK',
        latitude: '54,37944412',
        longitude: '10,14527798',
        altitude: '102',
        timezone: '1',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Lübeck Blankensee Airport',
        city: 'Luebeck',
        country: 'Germany',
        iataCode: 'LBC',
        icaoCode: 'EDHL',
        latitude: '53,80540085',
        longitude: '10,71920013',
        altitude: '53',
        timezone: '1',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Flugplatz Dahlemer Binz',
        city: 'Dahlemer Binz',
        country: 'Germany',
        iataCode: 'N',
        icaoCode: 'EDKV',
        latitude: '50,40555573',
        longitude: '6,528889179',
        altitude: '1896',
        timezone: '1',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Meinerzhagen Airport',
        city: 'Meinerzhagen',
        country: 'Germany',
        iataCode: 'N',
        icaoCode: 'EDKZ',
        latitude: '51,09999847',
        longitude: '7,599999905',
        altitude: '1549',
        timezone: '1',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Arnsberg-Menden Airport',
        city: 'Arnsberg',
        country: 'Germany',
        iataCode: 'ZCA',
        icaoCode: 'EDLA',
        latitude: '51,48389053',
        longitude: '7,898333073',
        altitude: '794',
        timezone: '1',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Essen Mulheim Airport',
        city: 'Essen',
        country: 'Germany',
        iataCode: 'ESS',
        icaoCode: 'EDLE',
        latitude: '51,40230179',
        longitude: '6,937329769',
        altitude: '424',
        timezone: '1',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Bielefeld Airport',
        city: 'Bielefeld',
        country: 'Germany',
        iataCode: 'N',
        icaoCode: 'EDLI',
        latitude: '51,96472168',
        longitude: '8,544444084',
        altitude: '433',
        timezone: '1',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Mönchengladbach Airport',
        city: 'Moenchengladbach',
        country: 'Germany',
        iataCode: 'MGL',
        icaoCode: 'EDLN',
        latitude: '51,23027802',
        longitude: '6,504444122',
        altitude: '125',
        timezone: '1',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Paderborn Lippstadt Airport',
        city: 'Paderborn',
        country: 'Germany',
        iataCode: 'PAD',
        icaoCode: 'EDLP',
        latitude: '51,61410141',
        longitude: '8,616319656',
        altitude: '699',
        timezone: '1',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Stadtlohn-Vreden Airport',
        city: 'Stadtlohn',
        country: 'Germany',
        iataCode: 'N',
        icaoCode: 'EDLS',
        latitude: '51,99583435',
        longitude: '6,840556145',
        altitude: '157',
        timezone: '1',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Dortmund Airport',
        city: 'Dortmund',
        country: 'Germany',
        iataCode: 'DTM',
        icaoCode: 'EDLW',
        latitude: '51,5182991',
        longitude: '7,612239838',
        altitude: '425',
        timezone: '1',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Augsburg Airport',
        city: 'Augsburg',
        country: 'Germany',
        iataCode: 'AGB',
        icaoCode: 'EDMA',
        latitude: '48,425278',
        longitude: '10,931667',
        altitude: '1516',
        timezone: '1',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Biberach a.d. Riß Airport',
        city: 'Biberach',
        country: 'Germany',
        iataCode: 'N',
        icaoCode: 'EDMB',
        latitude: '48,11111069',
        longitude: '9,762778282',
        altitude: '1903',
        timezone: '1',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Eggenfelden Airport',
        city: 'Eggenfelden',
        country: 'Germany',
        iataCode: 'N',
        icaoCode: 'EDME',
        latitude: '48,39611053',
        longitude: '12,72361088',
        altitude: '1342',
        timezone: '1',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Mindelheim-Mattsies Airport',
        city: 'Mindelheim',
        country: 'Germany',
        iataCode: 'N',
        icaoCode: 'EDMN',
        latitude: '48,10694504',
        longitude: '10,52499962',
        altitude: '1857',
        timezone: '1',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Oberpfaffenhofen Airport',
        city: 'Oberpfaffenhofen',
        country: 'Germany',
        iataCode: 'OBF',
        icaoCode: 'EDMO',
        latitude: '48,08140182',
        longitude: '11,28310013',
        altitude: '1947',
        timezone: '1',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Straubing Airport',
        city: 'Straubing',
        country: 'Germany',
        iataCode: 'RBM',
        icaoCode: 'EDMS',
        latitude: '48,90083313',
        longitude: '12,51666737',
        altitude: '1047',
        timezone: '1',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Vilshofen Airport',
        city: 'Vilshofen',
        country: 'Germany',
        iataCode: 'N',
        icaoCode: 'EDMV',
        latitude: '48,63499832',
        longitude: '13,19555569',
        altitude: '991',
        timezone: '1',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Leutkirch-Unterzeil Airport',
        city: 'Leutkirch',
        country: 'Germany',
        iataCode: 'N',
        icaoCode: 'EDNL',
        latitude: '47,85889053',
        longitude: '10,01416683',
        altitude: '2100',
        timezone: '1',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Friedrichshafen Airport',
        city: 'Friedrichshafen',
        country: 'Germany',
        iataCode: 'FDH',
        icaoCode: 'EDNY',
        latitude: '47,67129898',
        longitude: '9,511489868',
        altitude: '1367',
        timezone: '1',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Schwerin Parchim Airport',
        city: 'Parchim',
        country: 'Germany',
        iataCode: 'SZW',
        icaoCode: 'EDOP',
        latitude: '53,426998',
        longitude: '11,7834',
        altitude: '166',
        timezone: '1',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Stendal-Borstel Airport',
        city: 'Stendal',
        country: 'Germany',
        iataCode: 'ZSN',
        icaoCode: 'EDOV',
        latitude: '52,62888718',
        longitude: '11,81861115',
        altitude: '184',
        timezone: '1',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Aalen-Heidenheim/Elchingen Airport',
        city: 'Aalen-heidenheim',
        country: 'Germany',
        iataCode: 'N',
        icaoCode: 'EDPA',
        latitude: '48,77777863',
        longitude: '10,26472187',
        altitude: '1916',
        timezone: '1',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Bayreuth Airport',
        city: 'Bayreuth',
        country: 'Germany',
        iataCode: 'BYU',
        icaoCode: 'EDQD',
        latitude: '49,98500061',
        longitude: '11,64000034',
        altitude: '1601',
        timezone: '1',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Burg Feuerstein Airport',
        city: 'Burg Feuerstein',
        country: 'Germany',
        iataCode: 'N',
        icaoCode: 'EDQE',
        latitude: '49,79416656',
        longitude: '11,13361073',
        altitude: '1673',
        timezone: '1',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Hof-Plauen Airport',
        city: 'Hof',
        country: 'Germany',
        iataCode: 'HOQ',
        icaoCode: 'EDQM',
        latitude: '50,28861237',
        longitude: '11,85638905',
        altitude: '1959',
        timezone: '1',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Haßfurt-Schweinfurt Airport',
        city: 'Hassfurt',
        country: 'Germany',
        iataCode: 'N',
        icaoCode: 'EDQT',
        latitude: '50,01805496',
        longitude: '10,52944374',
        altitude: '719',
        timezone: '1',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Koblenz-Winningen Airport',
        city: 'Koblenz',
        country: 'Germany',
        iataCode: 'ZNV',
        icaoCode: 'EDRK',
        latitude: '50,32555771',
        longitude: '7,528611183',
        altitude: '640',
        timezone: '1',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Trier-Föhren Airport',
        city: 'Trier',
        country: 'Germany',
        iataCode: 'ZQF',
        icaoCode: 'EDRT',
        latitude: '49,86388779',
        longitude: '6,787499905',
        altitude: '666',
        timezone: '1',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Speyer Airport',
        city: 'Speyer',
        country: 'Germany',
        iataCode: 'ZQC',
        icaoCode: 'EDRY',
        latitude: '49,30472183',
        longitude: '8,451389313',
        altitude: '312',
        timezone: '1',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Zweibrücken Airport',
        city: 'Zweibruecken',
        country: 'Germany',
        iataCode: 'ZQW',
        icaoCode: 'EDRZ',
        latitude: '49,20940018',
        longitude: '7,400559902',
        altitude: '1132',
        timezone: '1',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Donaueschingen-Villingen Airport',
        city: 'Donaueschingen',
        country: 'Germany',
        iataCode: 'ZQL',
        icaoCode: 'EDTD',
        latitude: '47,97333145',
        longitude: '8,522221565',
        altitude: '2231',
        timezone: '1',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Freiburg i. Br. Airport',
        city: 'Freiburg',
        country: 'Germany',
        iataCode: 'N',
        icaoCode: 'EDTF',
        latitude: '48,02277756',
        longitude: '7,832499981',
        altitude: '801',
        timezone: '1',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Mengen-Hohentengen Airport',
        city: 'Mengen',
        country: 'Germany',
        iataCode: 'N',
        icaoCode: 'EDTM',
        latitude: '48,05389023',
        longitude: '9,372777939',
        altitude: '1818',
        timezone: '1',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Adolf Würth Airport',
        city: 'Schwaebisch Hall',
        country: 'Germany',
        iataCode: 'N',
        icaoCode: 'EDTY',
        latitude: '49,11833191',
        longitude: '9,783888817',
        altitude: '1299',
        timezone: '1',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Finsterwalde/Schacksdorf Airport',
        city: 'Soest',
        country: 'Germany',
        iataCode: 'N',
        icaoCode: 'EDUS',
        latitude: '51,607498',
        longitude: '13,743611',
        altitude: '384',
        timezone: '1',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Braunschweig Wolfsburg Airport',
        city: 'Braunschweig',
        country: 'Germany',
        iataCode: 'BWE',
        icaoCode: 'EDVE',
        latitude: '52,319199',
        longitude: '10,5561',
        altitude: '295',
        timezone: '1',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Kassel-Calden Airport',
        city: 'Kassel',
        country: 'Germany',
        iataCode: 'KSF',
        icaoCode: 'EDVK',
        latitude: '51,417273',
        longitude: '9,384967',
        altitude: '820',
        timezone: '1',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Hildesheim Airport',
        city: 'Hildesheim',
        country: 'Germany',
        iataCode: 'N',
        icaoCode: 'EDVM',
        latitude: '52,18138885',
        longitude: '9,946389198',
        altitude: '292',
        timezone: '1',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Bremerhaven Airport',
        city: 'Bremerhaven',
        country: 'Germany',
        iataCode: 'BRV',
        icaoCode: 'EDWB',
        latitude: '53,506943',
        longitude: '8,572778',
        altitude: '10',
        timezone: '1',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Emden Airport',
        city: 'Emden',
        country: 'Germany',
        iataCode: 'EME',
        icaoCode: 'EDWE',
        latitude: '53,39110947',
        longitude: '7,227499962',
        altitude: '3',
        timezone: '1',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Leer-Papenburg Airport',
        city: 'Leer',
        country: 'Germany',
        iataCode: 'N',
        icaoCode: 'EDWF',
        latitude: '53,27194214',
        longitude: '7,44166708',
        altitude: '3',
        timezone: '1',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Wilhelmshaven-Mariensiel Airport',
        city: 'Wilhelmshaven',
        country: 'Germany',
        iataCode: 'WVN',
        icaoCode: 'EDWI',
        latitude: '53,50222015',
        longitude: '8,052222252',
        altitude: '16',
        timezone: '1',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Borkum Airport',
        city: 'Borkum',
        country: 'Germany',
        iataCode: 'BMK',
        icaoCode: 'EDWR',
        latitude: '53,59638977',
        longitude: '6,709167004',
        altitude: '3',
        timezone: '1',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Norderney Airport',
        city: 'Norderney',
        country: 'Germany',
        iataCode: 'NRD',
        icaoCode: 'EDWY',
        latitude: '53,70694351',
        longitude: '7,230000019',
        altitude: '7',
        timezone: '1',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Flensburg-Schäferhaus Airport',
        city: 'Flensburg',
        country: 'Germany',
        iataCode: 'FLF',
        icaoCode: 'EDXF',
        latitude: '54,7733345',
        longitude: '9,378889084',
        altitude: '131',
        timezone: '1',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Rendsburg-Schachtholm Airport',
        city: 'Rendsburg',
        country: 'Germany',
        iataCode: 'N',
        icaoCode: 'EDXR',
        latitude: '54,22000122',
        longitude: '9,599444389',
        altitude: '23',
        timezone: '1',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Westerland Sylt Airport',
        city: 'Westerland',
        country: 'Germany',
        iataCode: 'GWT',
        icaoCode: 'EDXW',
        latitude: '54,91320038',
        longitude: '8,340470314',
        altitude: '51',
        timezone: '1',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Ämari Air Base',
        city: 'Armari Air Force Base',
        country: 'Estonia',
        iataCode: 'N',
        icaoCode: 'EEEI',
        latitude: '59,26029968',
        longitude: '24,20849991',
        altitude: '65',
        timezone: '2',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Kärdla Airport',
        city: 'Kardla',
        country: 'Estonia',
        iataCode: 'KDL',
        icaoCode: 'EEKA',
        latitude: '58,99079895',
        longitude: '22,83069992',
        altitude: '18',
        timezone: '2',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Kuressaare Airport',
        city: 'Kuressaare',
        country: 'Estonia',
        iataCode: 'URE',
        icaoCode: 'EEKE',
        latitude: '58,22990036',
        longitude: '22,5095005',
        altitude: '14',
        timezone: '2',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Pärnu Airport',
        city: 'Parnu',
        country: 'Estonia',
        iataCode: 'EPU',
        icaoCode: 'EEPU',
        latitude: '58,41899872',
        longitude: '24,4727993',
        altitude: '47',
        timezone: '2',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Lennart Meri Tallinn Airport',
        city: 'Tallinn-ulemiste International',
        country: 'Estonia',
        iataCode: 'TLL',
        icaoCode: 'EETN',
        latitude: '59,41329956',
        longitude: '24,83279991',
        altitude: '131',
        timezone: '2',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Tartu Airport',
        city: 'Tartu',
        country: 'Estonia',
        iataCode: 'TAY',
        icaoCode: 'EETU',
        latitude: '58,30749893',
        longitude: '26,69039917',
        altitude: '219',
        timezone: '2',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Enontekio Airport',
        city: 'Enontekio',
        country: 'Finland',
        iataCode: 'ENF',
        icaoCode: 'EFET',
        latitude: '68,36260223',
        longitude: '23,42429924',
        altitude: '1005',
        timezone: '2',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Eura Airport',
        city: 'Eura',
        country: 'Finland',
        iataCode: 'N',
        icaoCode: 'EFEU',
        latitude: '61,11610031',
        longitude: '22,20140076',
        altitude: '259',
        timezone: '2',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Halli Airport',
        city: 'Halli',
        country: 'Finland',
        iataCode: 'KEV',
        icaoCode: 'EFHA',
        latitude: '61,856039',
        longitude: '24,786686',
        altitude: '479',
        timezone: '2',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Helsinki Malmi Airport',
        city: 'Helsinki',
        country: 'Finland',
        iataCode: 'HEM',
        icaoCode: 'EFHF',
        latitude: '60,25460052',
        longitude: '25,0428009',
        altitude: '57',
        timezone: '2',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Helsinki Vantaa Airport',
        city: 'Helsinki',
        country: 'Finland',
        iataCode: 'HEL',
        icaoCode: 'EFHK',
        latitude: '60,31719971',
        longitude: '24,9633007',
        altitude: '179',
        timezone: '2',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Hameenkyro Airport',
        city: 'Hameenkyro',
        country: 'Finland',
        iataCode: 'N',
        icaoCode: 'EFHM',
        latitude: '61,68970108',
        longitude: '23,07369995',
        altitude: '449',
        timezone: '2',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Hanko Airport',
        city: 'Hanko',
        country: 'Finland',
        iataCode: 'N',
        icaoCode: 'EFHN',
        latitude: '59,84889984',
        longitude: '23,08359909',
        altitude: '20',
        timezone: '2',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Hyvinkää Airfield',
        city: 'Hyvinkaa',
        country: 'Finland',
        iataCode: 'HYV',
        icaoCode: 'EFHV',
        latitude: '60,65439987',
        longitude: '24,8810997',
        altitude: '430',
        timezone: '2',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Kiikala Airport',
        city: 'Kikala',
        country: 'Finland',
        iataCode: 'N',
        icaoCode: 'EFIK',
        latitude: '60,46250153',
        longitude: '23,65250015',
        altitude: '381',
        timezone: '2',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Immola Airport',
        city: 'Immola',
        country: 'Finland',
        iataCode: 'N',
        icaoCode: 'EFIM',
        latitude: '61,24919891',
        longitude: '28,90369987',
        altitude: '338',
        timezone: '2',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Kitee Airport',
        city: 'Kitee',
        country: 'Finland',
        iataCode: 'N',
        icaoCode: 'EFIT',
        latitude: '62,1661',
        longitude: '30,073601',
        altitude: '364',
        timezone: '2',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Ivalo Airport',
        city: 'Ivalo',
        country: 'Finland',
        iataCode: 'IVL',
        icaoCode: 'EFIV',
        latitude: '68,6072998',
        longitude: '27,40530014',
        altitude: '481',
        timezone: '2',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Joensuu Airport',
        city: 'Joensuu',
        country: 'Finland',
        iataCode: 'JOE',
        icaoCode: 'EFJO',
        latitude: '62,66289902',
        longitude: '29,60750008',
        altitude: '398',
        timezone: '2',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Jyvaskyla Airport',
        city: 'Jyvaskyla',
        country: 'Finland',
        iataCode: 'JYV',
        icaoCode: 'EFJY',
        latitude: '62,3995018',
        longitude: '25,67830086',
        altitude: '459',
        timezone: '2',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Kauhava Airport',
        city: 'Kauhava',
        country: 'Finland',
        iataCode: 'KAU',
        icaoCode: 'EFKA',
        latitude: '63,127102',
        longitude: '23,051399',
        altitude: '151',
        timezone: '2',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Kemi-Tornio Airport',
        city: 'Kemi',
        country: 'Finland',
        iataCode: 'KEM',
        icaoCode: 'EFKE',
        latitude: '65,77870178',
        longitude: '24,58209991',
        altitude: '61',
        timezone: '2',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Kajaani Airport',
        city: 'Kajaani',
        country: 'Finland',
        iataCode: 'KAJ',
        icaoCode: 'EFKI',
        latitude: '64,28549957',
        longitude: '27,69239998',
        altitude: '483',
        timezone: '2',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Kauhajoki Airport',
        city: 'Kauhajoki',
        country: 'Finland',
        iataCode: 'N',
        icaoCode: 'EFKJ',
        latitude: '62,46250153',
        longitude: '22,39310074',
        altitude: '407',
        timezone: '2',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Kokkola-Pietarsaari Airport',
        city: 'Kruunupyy',
        country: 'Finland',
        iataCode: 'KOK',
        icaoCode: 'EFKK',
        latitude: '63,72119904',
        longitude: '23,14310074',
        altitude: '84',
        timezone: '2',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Kemijarvi Airport',
        city: 'Kemijarvi',
        country: 'Finland',
        iataCode: 'N',
        icaoCode: 'EFKM',
        latitude: '66,712898',
        longitude: '27,156799',
        altitude: '692',
        timezone: '2',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Kuusamo Airport',
        city: 'Kuusamo',
        country: 'Finland',
        iataCode: 'KAO',
        icaoCode: 'EFKS',
        latitude: '65,98760223',
        longitude: '29,23940086',
        altitude: '866',
        timezone: '2',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Kittilä Airport',
        city: 'Kittila',
        country: 'Finland',
        iataCode: 'KTT',
        icaoCode: 'EFKT',
        latitude: '67,7009964',
        longitude: '24,84679985',
        altitude: '644',
        timezone: '2',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Kuopio Airport',
        city: 'Kuopio',
        country: 'Finland',
        iataCode: 'KUO',
        icaoCode: 'EFKU',
        latitude: '63,00709915',
        longitude: '27,79780006',
        altitude: '323',
        timezone: '2',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Lahti Vesivehmaa Airport',
        city: 'Vesivehmaa',
        country: 'Finland',
        iataCode: 'N',
        icaoCode: 'EFLA',
        latitude: '61,144199',
        longitude: '25,693501',
        altitude: '502',
        timezone: '2',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Lappeenranta Airport',
        city: 'Lappeenranta',
        country: 'Finland',
        iataCode: 'LPP',
        icaoCode: 'EFLP',
        latitude: '61,044601',
        longitude: '28,144743',
        altitude: '349',
        timezone: '2',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Mariehamn Airport',
        city: 'Mariehamn',
        country: 'Finland',
        iataCode: 'MHQ',
        icaoCode: 'EFMA',
        latitude: '60,12220001',
        longitude: '19,89819908',
        altitude: '17',
        timezone: '2',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Menkijarvi Airport',
        city: 'Menkijarvi',
        country: 'Finland',
        iataCode: 'N',
        icaoCode: 'EFME',
        latitude: '62,94670105',
        longitude: '23,51889992',
        altitude: '331',
        timezone: '2',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Mikkeli Airport',
        city: 'Mikkeli',
        country: 'Finland',
        iataCode: 'MIK',
        icaoCode: 'EFMI',
        latitude: '61,6866',
        longitude: '27,201799',
        altitude: '329',
        timezone: '2',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Nummela Airport',
        city: 'Nummela',
        country: 'Finland',
        iataCode: 'N',
        icaoCode: 'EFNU',
        latitude: '60,33390045',
        longitude: '24,29640007',
        altitude: '367',
        timezone: '2',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Oulu Airport',
        city: 'Oulu',
        country: 'Finland',
        iataCode: 'OUL',
        icaoCode: 'EFOU',
        latitude: '64,93009949',
        longitude: '25,35460091',
        altitude: '47',
        timezone: '2',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Piikajarvi Airport',
        city: 'Piikajarvi',
        country: 'Finland',
        iataCode: 'N',
        icaoCode: 'EFPI',
        latitude: '61,24560165',
        longitude: '22,19339943',
        altitude: '148',
        timezone: '2',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Pori Airport',
        city: 'Pori',
        country: 'Finland',
        iataCode: 'POR',
        icaoCode: 'EFPO',
        latitude: '61,46170044',
        longitude: '21,79999924',
        altitude: '44',
        timezone: '2',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Pudasjärvi Airport',
        city: 'Pudasjarvi',
        country: 'Finland',
        iataCode: 'N',
        icaoCode: 'EFPU',
        latitude: '65,40219879',
        longitude: '26,94689941',
        altitude: '397',
        timezone: '2',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Pyhäsalmi Airport',
        city: 'Pyhasalmi',
        country: 'Finland',
        iataCode: 'N',
        icaoCode: 'EFPY',
        latitude: '63,73189926',
        longitude: '25,92630005',
        altitude: '528',
        timezone: '2',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Raahe Pattijoki Airport',
        city: 'Pattijoki',
        country: 'Finland',
        iataCode: 'N',
        icaoCode: 'EFRH',
        latitude: '64,68810272',
        longitude: '24,69580078',
        altitude: '118',
        timezone: '2',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Rantasalmi Airport',
        city: 'Rantasalmi',
        country: 'Finland',
        iataCode: 'N',
        icaoCode: 'EFRN',
        latitude: '62,06549835',
        longitude: '28,35650063',
        altitude: '292',
        timezone: '2',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Rovaniemi Airport',
        city: 'Rovaniemi',
        country: 'Finland',
        iataCode: 'RVN',
        icaoCode: 'EFRO',
        latitude: '66,56479645',
        longitude: '25,83040047',
        altitude: '642',
        timezone: '2',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Rayskala Airport',
        city: 'Rayskala',
        country: 'Finland',
        iataCode: 'N',
        icaoCode: 'EFRY',
        latitude: '60,74470139',
        longitude: '24,10779953',
        altitude: '407',
        timezone: '2',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Savonlinna Airport',
        city: 'Savonlinna',
        country: 'Finland',
        iataCode: 'SVL',
        icaoCode: 'EFSA',
        latitude: '61,94309998',
        longitude: '28,94510078',
        altitude: '311',
        timezone: '2',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Selanpaa Airport',
        city: 'Selanpaa',
        country: 'Finland',
        iataCode: 'N',
        icaoCode: 'EFSE',
        latitude: '61,06240082',
        longitude: '26,7989006',
        altitude: '417',
        timezone: '2',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Sodankyla Airport',
        city: 'Sodankyla',
        country: 'Finland',
        iataCode: 'SOT',
        icaoCode: 'EFSO',
        latitude: '67,39499664',
        longitude: '26,61910057',
        altitude: '602',
        timezone: '2',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Tampere-Pirkkala Airport',
        city: 'Tampere',
        country: 'Finland',
        iataCode: 'TMP',
        icaoCode: 'EFTP',
        latitude: '61,41410065',
        longitude: '23,60440063',
        altitude: '390',
        timezone: '2',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Teisko Airport',
        city: 'Teisko',
        country: 'Finland',
        iataCode: 'N',
        icaoCode: 'EFTS',
        latitude: '61,7733',
        longitude: '24,027',
        altitude: '515',
        timezone: '2',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Turku Airport',
        city: 'Turku',
        country: 'Finland',
        iataCode: 'TKU',
        icaoCode: 'EFTU',
        latitude: '60,51409912',
        longitude: '22,26280022',
        altitude: '161',
        timezone: '2',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Utti Air Base',
        city: 'Utti',
        country: 'Finland',
        iataCode: 'QVY',
        icaoCode: 'EFUT',
        latitude: '60,89640045',
        longitude: '26,93840027',
        altitude: '339',
        timezone: '2',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Vaasa Airport',
        city: 'Vaasa',
        country: 'Finland',
        iataCode: 'VAA',
        icaoCode: 'EFVA',
        latitude: '63,05070114',
        longitude: '21,7621994',
        altitude: '19',
        timezone: '2',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Varkaus Airport',
        city: 'Varkaus',
        country: 'Finland',
        iataCode: 'VRK',
        icaoCode: 'EFVR',
        latitude: '62,17110062',
        longitude: '27,86860085',
        altitude: '286',
        timezone: '2',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Ylivieska Airfield',
        city: 'Ylivieska-raudaskyla',
        country: 'Finland',
        iataCode: 'N',
        icaoCode: 'EFYL',
        latitude: '64,0547222',
        longitude: '24,7252778',
        altitude: '252',
        timezone: '2',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Belfast International Airport',
        city: 'Belfast',
        country: 'United Kingdom',
        iataCode: 'BFS',
        icaoCode: 'EGAA',
        latitude: '54,65750122',
        longitude: '-6,215829849',
        altitude: '268',
        timezone: '0',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'St Angelo Airport',
        city: 'Enniskillen',
        country: 'United Kingdom',
        iataCode: 'ENK',
        icaoCode: 'EGAB',
        latitude: '54,39889908',
        longitude: '-7,651669979',
        altitude: '155',
        timezone: '0',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'George Best Belfast City Airport',
        city: 'Belfast',
        country: 'United Kingdom',
        iataCode: 'BHD',
        icaoCode: 'EGAC',
        latitude: '54,61809921',
        longitude: '-5,872499943',
        altitude: '15',
        timezone: '0',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'City of Derry Airport',
        city: 'Londonderry',
        country: 'United Kingdom',
        iataCode: 'LDY',
        icaoCode: 'EGAE',
        latitude: '55,0428009',
        longitude: '-7,161109924',
        altitude: '22',
        timezone: '0',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Birmingham International Airport',
        city: 'Birmingham',
        country: 'United Kingdom',
        iataCode: 'BHX',
        icaoCode: 'EGBB',
        latitude: '52,45389938',
        longitude: '-1,748029947',
        altitude: '327',
        timezone: '0',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Coventry Airport',
        city: 'Coventry',
        country: 'United Kingdom',
        iataCode: 'CVT',
        icaoCode: 'EGBE',
        latitude: '52,36970139',
        longitude: '-1,479719996',
        altitude: '267',
        timezone: '0',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Leicester Airport',
        city: 'Leicester',
        country: 'United Kingdom',
        iataCode: 'N',
        icaoCode: 'EGBG',
        latitude: '52,60779953',
        longitude: '-1,031939983',
        altitude: '469',
        timezone: '0',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Gloucestershire Airport',
        city: 'Golouchestershire',
        country: 'United Kingdom',
        iataCode: 'GLO',
        icaoCode: 'EGBJ',
        latitude: '51,89419937',
        longitude: '-2,167220116',
        altitude: '101',
        timezone: '0',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Wolverhampton Halfpenny Green Airport',
        city: 'Halfpenny Green',
        country: 'United Kingdom',
        iataCode: 'N',
        icaoCode: 'EGBO',
        latitude: '52,51750183',
        longitude: '-2,259439945',
        altitude: '283',
        timezone: '0',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Cotswold Airport',
        city: 'Pailton',
        country: 'United Kingdom',
        iataCode: 'N',
        icaoCode: 'EGBP',
        latitude: '51,668095',
        longitude: '-2,05694',
        altitude: '433',
        timezone: '0',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Turweston Airport',
        city: 'Turweston',
        country: 'United Kingdom',
        iataCode: 'N',
        icaoCode: 'EGBT',
        latitude: '52,04079819',
        longitude: '-1,095559955',
        altitude: '448',
        timezone: '0',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Wellesbourne Mountford Airport',
        city: 'Wellesbourne',
        country: 'United Kingdom',
        iataCode: 'N',
        icaoCode: 'EGBW',
        latitude: '52,19219971',
        longitude: '-1,614439964',
        altitude: '159',
        timezone: '0',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Manchester Airport',
        city: 'Manchester',
        country: 'United Kingdom',
        iataCode: 'MAN',
        icaoCode: 'EGCC',
        latitude: '53,35369873',
        longitude: '-2,274950027',
        altitude: '257',
        timezone: '0',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Manchester Woodford Airport',
        city: 'Woodfort',
        country: 'United Kingdom',
        iataCode: 'N',
        icaoCode: 'EGCD',
        latitude: '53,3381',
        longitude: '-2,14889',
        altitude: '295',
        timezone: '0',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Royal Marines Base Chivenor Airport',
        city: 'Chivenor',
        country: 'United Kingdom',
        iataCode: 'N',
        icaoCode: 'EGDC',
        latitude: '51,08720016',
        longitude: '-4,15034008',
        altitude: '27',
        timezone: '0',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Newquay Cornwall Airport',
        city: 'Newquai',
        country: 'United Kingdom',
        iataCode: 'NQY',
        icaoCode: 'EGHQ',
        latitude: '50,44060135',
        longitude: '-4,995409966',
        altitude: '390',
        timezone: '0',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'RAF Lyneham',
        city: 'Lyneham',
        country: 'United Kingdom',
        iataCode: 'LYE',
        icaoCode: 'EGDL',
        latitude: '51,5051',
        longitude: '-1,99343',
        altitude: '513',
        timezone: '0',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'MoD Boscombe Down Airport',
        city: 'Boscombe Down',
        country: 'United Kingdom',
        iataCode: 'N',
        icaoCode: 'EGDM',
        latitude: '51,15219879',
        longitude: '-1,747410059',
        altitude: '407',
        timezone: '0',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'RNAS Culdrose',
        city: 'Culdrose',
        country: 'United Kingdom',
        iataCode: 'N',
        icaoCode: 'EGDR',
        latitude: '50,08610153',
        longitude: '-5,255710125',
        altitude: '267',
        timezone: '0',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'MOD St. Athan',
        city: 'St. Athan',
        country: 'United Kingdom',
        iataCode: 'N',
        icaoCode: 'EGDX',
        latitude: '51,40480042',
        longitude: '-3,435750008',
        altitude: '163',
        timezone: '0',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'RNAS Yeovilton',
        city: 'Yeovilton',
        country: 'United Kingdom',
        iataCode: 'YEO',
        icaoCode: 'EGDY',
        latitude: '51,00939941',
        longitude: '-2,638819933',
        altitude: '75',
        timezone: '0',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Haverfordwest Airport',
        city: 'Haverfordwest',
        country: 'United Kingdom',
        iataCode: 'N',
        icaoCode: 'EGFE',
        latitude: '51,83309937',
        longitude: '-4,961110115',
        altitude: '159',
        timezone: '0',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Cardiff International Airport',
        city: 'Cardiff',
        country: 'United Kingdom',
        iataCode: 'CWL',
        icaoCode: 'EGFF',
        latitude: '51,39670181',
        longitude: '-3,343329906',
        altitude: '220',
        timezone: '0',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Swansea Airport',
        city: 'Swansea',
        country: 'United Kingdom',
        iataCode: 'SWS',
        icaoCode: 'EGFH',
        latitude: '51,6053009',
        longitude: '-4,067830086',
        altitude: '299',
        timezone: '0',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Bristol Airport',
        city: 'Bristol',
        country: 'United Kingdom',
        iataCode: 'BRS',
        icaoCode: 'EGGD',
        latitude: '51,382702',
        longitude: '-2,71909',
        altitude: '622',
        timezone: '0',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Liverpool John Lennon Airport',
        city: 'Liverpool',
        country: 'United Kingdom',
        iataCode: 'LPL',
        icaoCode: 'EGGP',
        latitude: '53,33359909',
        longitude: '-2,849720001',
        altitude: '80',
        timezone: '0',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'London Luton Airport',
        city: 'London',
        country: 'United Kingdom',
        iataCode: 'LTN',
        icaoCode: 'EGGW',
        latitude: '51,87469864',
        longitude: '-0,368333012',
        altitude: '526',
        timezone: '0',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Plymouth City Airport',
        city: 'Plymouth',
        country: 'United Kingdom',
        iataCode: 'PLH',
        icaoCode: 'EGHD',
        latitude: '50,422798',
        longitude: '-4,10583',
        altitude: '476',
        timezone: '0',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Bournemouth Airport',
        city: 'Bournemouth',
        country: 'United Kingdom',
        iataCode: 'BOH',
        icaoCode: 'EGHH',
        latitude: '50,77999878',
        longitude: '-1,842499971',
        altitude: '38',
        timezone: '0',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Southampton Airport',
        city: 'Southampton',
        country: 'United Kingdom',
        iataCode: 'SOU',
        icaoCode: 'EGHI',
        latitude: '50,95029831',
        longitude: '-1,35679996',
        altitude: '44',
        timezone: '0',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Lasham Airport',
        city: 'Lasham',
        country: 'United Kingdom',
        iataCode: 'QLA',
        icaoCode: 'EGHL',
        latitude: '51,18719864',
        longitude: '-1,033499956',
        altitude: '618',
        timezone: '0',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Alderney Airport',
        city: 'Alderney',
        country: 'Guernsey',
        iataCode: 'ACI',
        icaoCode: 'EGJA',
        latitude: '49,70610046',
        longitude: '-2,214720011',
        altitude: '290',
        timezone: '0',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Guernsey Airport',
        city: 'Guernsey',
        country: 'Guernsey',
        iataCode: 'GCI',
        icaoCode: 'EGJB',
        latitude: '49,43500137',
        longitude: '-2,601969957',
        altitude: '336',
        timezone: '0',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Jersey Airport',
        city: 'Jersey',
        country: 'Jersey',
        iataCode: 'JER',
        icaoCode: 'EGJJ',
        latitude: '49,207901',
        longitude: '-2,195509911',
        altitude: '277',
        timezone: '0',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Shoreham Airport',
        city: 'Shoreham By Sea',
        country: 'United Kingdom',
        iataCode: 'ESH',
        icaoCode: 'EGKA',
        latitude: '50,83560181',
        longitude: '-0,297221988',
        altitude: '7',
        timezone: '0',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'London Biggin Hill Airport',
        city: 'Biggin Hill',
        country: 'United Kingdom',
        iataCode: 'BQH',
        icaoCode: 'EGKB',
        latitude: '51,3307991',
        longitude: '0,032499999',
        altitude: '598',
        timezone: '0',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'London Gatwick Airport',
        city: 'London',
        country: 'United Kingdom',
        iataCode: 'LGW',
        icaoCode: 'EGKK',
        latitude: '51,14810181',
        longitude: '-0,190277994',
        altitude: '202',
        timezone: '0',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'London City Airport',
        city: 'London',
        country: 'United Kingdom',
        iataCode: 'LCY',
        icaoCode: 'EGLC',
        latitude: '51,505299',
        longitude: '0,055278',
        altitude: '19',
        timezone: '0',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Farnborough Airport',
        city: 'Farnborough',
        country: 'United Kingdom',
        iataCode: 'FAB',
        icaoCode: 'EGLF',
        latitude: '51,2757988',
        longitude: '-0,776332974',
        altitude: '238',
        timezone: '0',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Chalgrove Airport',
        city: 'Chalsgrove',
        country: 'United Kingdom',
        iataCode: 'N',
        icaoCode: 'EGLJ',
        latitude: '51,67610168',
        longitude: '-1,080829978',
        altitude: '240',
        timezone: '0',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Blackbushe Airport',
        city: 'Blackbushe',
        country: 'United Kingdom',
        iataCode: 'BBS',
        icaoCode: 'EGLK',
        latitude: '51,32389832',
        longitude: '-0,847500026',
        altitude: '325',
        timezone: '0',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'London Heathrow Airport',
        city: 'London',
        country: 'United Kingdom',
        iataCode: 'LHR',
        icaoCode: 'EGLL',
        latitude: '51,4706',
        longitude: '-0,461941',
        altitude: '83',
        timezone: '0',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Southend Airport',
        city: 'Southend',
        country: 'United Kingdom',
        iataCode: 'SEN',
        icaoCode: 'EGMC',
        latitude: '51,57139969',
        longitude: '0,695555985',
        altitude: '49',
        timezone: '0',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Lydd Airport',
        city: 'Lydd',
        country: 'United Kingdom',
        iataCode: 'LYX',
        icaoCode: 'EGMD',
        latitude: '50,95610046',
        longitude: '0,939167023',
        altitude: '13',
        timezone: '0',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Kent International Airport',
        city: 'Manston',
        country: 'United Kingdom',
        iataCode: 'MSE',
        icaoCode: 'EGMH',
        latitude: '51,342201',
        longitude: '1,34611',
        altitude: '178',
        timezone: '0',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Brough Airport',
        city: 'Brough',
        country: 'United Kingdom',
        iataCode: 'N',
        icaoCode: 'EGNB',
        latitude: '53,7197',
        longitude: '-0,566333',
        altitude: '12',
        timezone: '0',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Carlisle Airport',
        city: 'Carlisle',
        country: 'United Kingdom',
        iataCode: 'CAX',
        icaoCode: 'EGNC',
        latitude: '54,9375',
        longitude: '-2,809170008',
        altitude: '190',
        timezone: '0',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Retford Gamston Airport',
        city: 'Repton',
        country: 'United Kingdom',
        iataCode: 'N',
        icaoCode: 'EGNE',
        latitude: '53,2806015',
        longitude: '-0,951389015',
        altitude: '91',
        timezone: '0',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Blackpool International Airport',
        city: 'Blackpool',
        country: 'United Kingdom',
        iataCode: 'BLK',
        icaoCode: 'EGNH',
        latitude: '53,77170181',
        longitude: '-3,028609991',
        altitude: '34',
        timezone: '0',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Humberside Airport',
        city: 'Humberside',
        country: 'United Kingdom',
        iataCode: 'HUY',
        icaoCode: 'EGNJ',
        latitude: '53,57440186',
        longitude: '-0,350832999',
        altitude: '121',
        timezone: '0',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Barrow Walney Island Airport',
        city: 'Barrow Island',
        country: 'United Kingdom',
        iataCode: 'BWF',
        icaoCode: 'EGNL',
        latitude: '54,1286111',
        longitude: '-3,2675',
        altitude: '173',
        timezone: '0',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Leeds Bradford Airport',
        city: 'Leeds',
        country: 'United Kingdom',
        iataCode: 'LBA',
        icaoCode: 'EGNM',
        latitude: '53,86589813',
        longitude: '-1,660570025',
        altitude: '681',
        timezone: '0',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Warton Airport',
        city: 'Warton',
        country: 'United Kingdom',
        iataCode: 'N',
        icaoCode: 'EGNO',
        latitude: '53,74509811',
        longitude: '-2,883059978',
        altitude: '55',
        timezone: '0',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Hawarden Airport',
        city: 'Hawarden',
        country: 'United Kingdom',
        iataCode: 'CEG',
        icaoCode: 'EGNR',
        latitude: '53,17810059',
        longitude: '-2,977780104',
        altitude: '45',
        timezone: '0',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Isle of Man Airport',
        city: 'Isle Of Man',
        country: 'Isle of Man',
        iataCode: 'IOM',
        icaoCode: 'EGNS',
        latitude: '54,08330154',
        longitude: '-4,623889923',
        altitude: '52',
        timezone: '0',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Newcastle Airport',
        city: 'Newcastle',
        country: 'United Kingdom',
        iataCode: 'NCL',
        icaoCode: 'EGNT',
        latitude: '55,03749847',
        longitude: '-1,691669941',
        altitude: '266',
        timezone: '0',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Durham Tees Valley Airport',
        city: 'Teesside',
        country: 'United Kingdom',
        iataCode: 'MME',
        icaoCode: 'EGNV',
        latitude: '54,50920105',
        longitude: '-1,429409981',
        altitude: '120',
        timezone: '0',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'East Midlands Airport',
        city: 'East Midlands',
        country: 'United Kingdom',
        iataCode: 'EMA',
        icaoCode: 'EGNX',
        latitude: '52,83110046',
        longitude: '-1,328060031',
        altitude: '306',
        timezone: '0',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Llanbedr Airport',
        city: 'Llanbedr',
        country: 'United Kingdom',
        iataCode: 'N',
        icaoCode: 'EGOD',
        latitude: '52,81169891',
        longitude: '-4,123579979',
        altitude: '30',
        timezone: '0',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'RAF Ternhill',
        city: 'Ternhill',
        country: 'United Kingdom',
        iataCode: 'N',
        icaoCode: 'EGOE',
        latitude: '52,87120056',
        longitude: '-2,533560038',
        altitude: '272',
        timezone: '0',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'RAF Shawbury',
        city: 'Shawbury',
        country: 'United Kingdom',
        iataCode: 'N',
        icaoCode: 'EGOS',
        latitude: '52,7981987',
        longitude: '-2,668040037',
        altitude: '249',
        timezone: '0',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'RAF Woodvale',
        city: 'Woodvale',
        country: 'United Kingdom',
        iataCode: 'N',
        icaoCode: 'EGOW',
        latitude: '53,58160019',
        longitude: '-3,055520058',
        altitude: '37',
        timezone: '0',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Kirkwall Airport',
        city: 'Kirkwall',
        country: 'United Kingdom',
        iataCode: 'KOI',
        icaoCode: 'EGPA',
        latitude: '58,95780182',
        longitude: '-2,904999971',
        altitude: '50',
        timezone: '0',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Sumburgh Airport',
        city: 'Sumburgh',
        country: 'United Kingdom',
        iataCode: 'LSI',
        icaoCode: 'EGPB',
        latitude: '59,87889862',
        longitude: '-1,295560002',
        altitude: '20',
        timezone: '0',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Wick Airport',
        city: 'Wick',
        country: 'United Kingdom',
        iataCode: 'WIC',
        icaoCode: 'EGPC',
        latitude: '58,45890045',
        longitude: '-3,093060017',
        altitude: '126',
        timezone: '0',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Aberdeen Dyce Airport',
        city: 'Aberdeen',
        country: 'United Kingdom',
        iataCode: 'ABZ',
        icaoCode: 'EGPD',
        latitude: '57,20190048',
        longitude: '-2,197779894',
        altitude: '215',
        timezone: '0',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Inverness Airport',
        city: 'Inverness',
        country: 'United Kingdom',
        iataCode: 'INV',
        icaoCode: 'EGPE',
        latitude: '57,54249954',
        longitude: '-4,047500134',
        altitude: '31',
        timezone: '0',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Glasgow International Airport',
        city: 'Glasgow',
        country: 'United Kingdom',
        iataCode: 'GLA',
        icaoCode: 'EGPF',
        latitude: '55,87189865',
        longitude: '-4,433060169',
        altitude: '26',
        timezone: '0',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Edinburgh Airport',
        city: 'Edinburgh',
        country: 'United Kingdom',
        iataCode: 'EDI',
        icaoCode: 'EGPH',
        latitude: '55,95000076',
        longitude: '-3,372499943',
        altitude: '135',
        timezone: '0',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Islay Airport',
        city: 'Islay',
        country: 'United Kingdom',
        iataCode: 'ILY',
        icaoCode: 'EGPI',
        latitude: '55,68190002',
        longitude: '-6,256669998',
        altitude: '56',
        timezone: '0',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Glasgow Prestwick Airport',
        city: 'Prestwick',
        country: 'United Kingdom',
        iataCode: 'PIK',
        icaoCode: 'EGPK',
        latitude: '55,50939941',
        longitude: '-4,586669922',
        altitude: '65',
        timezone: '0',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Benbecula Airport',
        city: 'Benbecula',
        country: 'United Kingdom',
        iataCode: 'BEB',
        icaoCode: 'EGPL',
        latitude: '57,48109818',
        longitude: '-7,362780094',
        altitude: '19',
        timezone: '0',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Scatsta Airport',
        city: 'Scatsta',
        country: 'United Kingdom',
        iataCode: 'SCS',
        icaoCode: 'EGPM',
        latitude: '60,43280029',
        longitude: '-1,296110034',
        altitude: '81',
        timezone: '0',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Dundee Airport',
        city: 'Dundee',
        country: 'United Kingdom',
        iataCode: 'DND',
        icaoCode: 'EGPN',
        latitude: '56,45249939',
        longitude: '-3,02583003',
        altitude: '17',
        timezone: '0',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Stornoway Airport',
        city: 'Stornoway',
        country: 'United Kingdom',
        iataCode: 'SYY',
        icaoCode: 'EGPO',
        latitude: '58,21559906',
        longitude: '-6,331110001',
        altitude: '26',
        timezone: '0',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Tiree Airport',
        city: 'Tiree',
        country: 'United Kingdom',
        iataCode: 'TRE',
        icaoCode: 'EGPU',
        latitude: '56,49919891',
        longitude: '-6,869170189',
        altitude: '38',
        timezone: '0',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'RAF Leuchars',
        city: 'Leuchars',
        country: 'United Kingdom',
        iataCode: 'ADX',
        icaoCode: 'EGQL',
        latitude: '56,3728981',
        longitude: '-2,868439913',
        altitude: '38',
        timezone: '0',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'RAF Lossiemouth',
        city: 'Lossiemouth',
        country: 'United Kingdom',
        iataCode: 'LMO',
        icaoCode: 'EGQS',
        latitude: '57,7052002',
        longitude: '-3,339169979',
        altitude: '42',
        timezone: '0',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Cambridge Airport',
        city: 'Cambridge',
        country: 'United Kingdom',
        iataCode: 'CBG',
        icaoCode: 'EGSC',
        latitude: '52,20500183',
        longitude: '0,174999997',
        altitude: '47',
        timezone: '0',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Peterborough Business Airport',
        city: 'Peterborough',
        country: 'United Kingdom',
        iataCode: 'N',
        icaoCode: 'EGSF',
        latitude: '52,4681015',
        longitude: '-0,251111001',
        altitude: '26',
        timezone: '0',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Norwich International Airport',
        city: 'Norwich',
        country: 'United Kingdom',
        iataCode: 'NWI',
        icaoCode: 'EGSH',
        latitude: '52,67580032',
        longitude: '1,282780051',
        altitude: '117',
        timezone: '0',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'London Stansted Airport',
        city: 'London',
        country: 'United Kingdom',
        iataCode: 'STN',
        icaoCode: 'EGSS',
        latitude: '51,88499832',
        longitude: '0,234999999',
        altitude: '348',
        timezone: '0',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'North Weald Airport',
        city: 'North Weald',
        country: 'United Kingdom',
        iataCode: 'N',
        icaoCode: 'EGSX',
        latitude: '51,72169876',
        longitude: '0,154166996',
        altitude: '321',
        timezone: '0',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Sheffield City Heliport',
        city: 'Fowlmere',
        country: 'United Kingdom',
        iataCode: 'N',
        icaoCode: 'EGSY',
        latitude: '53,394299',
        longitude: '-1,38849',
        altitude: '231',
        timezone: '0',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Cranfield Airport',
        city: 'Cranfield',
        country: 'United Kingdom',
        iataCode: 'N',
        icaoCode: 'EGTC',
        latitude: '52,07220078',
        longitude: '-0,616666973',
        altitude: '358',
        timezone: '0',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Exeter International Airport',
        city: 'Exeter',
        country: 'United Kingdom',
        iataCode: 'EXT',
        icaoCode: 'EGTE',
        latitude: '50,7344017',
        longitude: '-3,413889885',
        altitude: '102',
        timezone: '0',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Bristol Filton Airport',
        city: 'Bristol',
        country: 'United Kingdom',
        iataCode: 'FZO',
        icaoCode: 'EGTG',
        latitude: '51,51940155',
        longitude: '-2,590830088',
        altitude: '226',
        timezone: '0',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Oxford (Kidlington) Airport',
        city: 'Oxford',
        country: 'United Kingdom',
        iataCode: 'OXF',
        icaoCode: 'EGTK',
        latitude: '51,8368988',
        longitude: '-1,320000052',
        altitude: '270',
        timezone: '0',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'RAF Benson',
        city: 'Benson',
        country: 'United Kingdom',
        iataCode: 'N',
        icaoCode: 'EGUB',
        latitude: '51,61640167',
        longitude: '-1,095829964',
        altitude: '226',
        timezone: '0',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'RAF Lakenheath',
        city: 'Lakenheath',
        country: 'United Kingdom',
        iataCode: 'N',
        icaoCode: 'EGUL',
        latitude: '52,40930176',
        longitude: '0,56099999',
        altitude: '32',
        timezone: '0',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'RAF Mildenhall',
        city: 'Mildenhall',
        country: 'United Kingdom',
        iataCode: 'MHZ',
        icaoCode: 'EGUN',
        latitude: '52,36190033',
        longitude: '0,486405998',
        altitude: '33',
        timezone: '0',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'RAF Wattisham',
        city: 'Wattisham',
        country: 'United Kingdom',
        iataCode: 'N',
        icaoCode: 'EGUW',
        latitude: '52,12730026',
        longitude: '0,956264019',
        altitude: '284',
        timezone: '0',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'RAF Wyton',
        city: 'Wyton',
        country: 'United Kingdom',
        iataCode: 'N',
        icaoCode: 'EGUY',
        latitude: '52,35720062',
        longitude: '-0,107832998',
        altitude: '135',
        timezone: '0',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'RAF Fairford',
        city: 'Fairford',
        country: 'United Kingdom',
        iataCode: 'FFD',
        icaoCode: 'EGVA',
        latitude: '51,68220139',
        longitude: '-1,790030003',
        altitude: '286',
        timezone: '0',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'RAF Brize Norton',
        city: 'Brize Norton',
        country: 'United Kingdom',
        iataCode: 'BZZ',
        icaoCode: 'EGVN',
        latitude: '51,75',
        longitude: '-1,58362',
        altitude: '288',
        timezone: '0',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'RAF Odiham',
        city: 'Odiham',
        country: 'United Kingdom',
        iataCode: 'ODH',
        icaoCode: 'EGVO',
        latitude: '51,23410034',
        longitude: '-0,942825019',
        altitude: '405',
        timezone: '0',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'DCAE Cosford Air Base',
        city: 'Cosford',
        country: 'United Kingdom',
        iataCode: 'N',
        icaoCode: 'EGWC',
        latitude: '52,639999',
        longitude: '-2,30558',
        altitude: '272',
        timezone: '0',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'RAF Northolt',
        city: 'Northolt',
        country: 'United Kingdom',
        iataCode: 'NHT',
        icaoCode: 'EGWU',
        latitude: '51,5530014',
        longitude: '-0,418166995',
        altitude: '124',
        timezone: '0',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'RAF Coningsby',
        city: 'Coningsby',
        country: 'United Kingdom',
        iataCode: 'QCY',
        icaoCode: 'EGXC',
        latitude: '53,0929985',
        longitude: '-0,166014001',
        altitude: '25',
        timezone: '0',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'RAF Dishforth',
        city: 'Dishforth',
        country: 'United Kingdom',
        iataCode: 'N',
        icaoCode: 'EGXD',
        latitude: '54,1371994',
        longitude: '-1,420250058',
        altitude: '117',
        timezone: '0',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Leeming Airport',
        city: 'Leeming',
        country: 'United Kingdom',
        iataCode: 'N',
        icaoCode: 'EGXE',
        latitude: '54,2924',
        longitude: '-1,5354',
        altitude: '132',
        timezone: '0',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Leeds East Airport',
        city: 'Church Fenton',
        country: 'United Kingdom',
        iataCode: 'N',
        icaoCode: 'EGXG',
        latitude: '53,834301',
        longitude: '-1,1955',
        altitude: '29',
        timezone: '0',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'RAF Honington',
        city: 'Honington',
        country: 'United Kingdom',
        iataCode: 'BEQ',
        icaoCode: 'EGXH',
        latitude: '52,34260178',
        longitude: '0,772939026',
        altitude: '174',
        timezone: '0',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'RAF Cottesmore',
        city: 'Cottesmore',
        country: 'United Kingdom',
        iataCode: 'N',
        icaoCode: 'EGXJ',
        latitude: '52,735699',
        longitude: '-0,648769',
        altitude: '461',
        timezone: '0',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'RAF Scampton',
        city: 'Scampton',
        country: 'United Kingdom',
        iataCode: 'N',
        icaoCode: 'EGXP',
        latitude: '53,30780029',
        longitude: '-0,550832987',
        altitude: '202',
        timezone: '0',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'RAF Wittering',
        city: 'Wittering',
        country: 'United Kingdom',
        iataCode: 'N',
        icaoCode: 'EGXT',
        latitude: '52,61259842',
        longitude: '-0,476453006',
        altitude: '273',
        timezone: '0',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'RAF Linton-On-Ouse',
        city: 'Linton-on-ouse',
        country: 'United Kingdom',
        iataCode: 'HRT',
        icaoCode: 'EGXU',
        latitude: '54,0489006',
        longitude: '-1,252750039',
        altitude: '53',
        timezone: '0',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'RAF Waddington',
        city: 'Waddington',
        country: 'United Kingdom',
        iataCode: 'WTN',
        icaoCode: 'EGXW',
        latitude: '53,16619873',
        longitude: '-0,523810983',
        altitude: '231',
        timezone: '0',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'RAF Topcliffe',
        city: 'Topcliffe',
        country: 'United Kingdom',
        iataCode: 'N',
        icaoCode: 'EGXZ',
        latitude: '54,20550156',
        longitude: '-1,382089973',
        altitude: '92',
        timezone: '0',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'RAF Cranwell',
        city: 'Cranwell',
        country: 'United Kingdom',
        iataCode: 'N',
        icaoCode: 'EGYD',
        latitude: '53,03030014',
        longitude: '-0,483242005',
        altitude: '218',
        timezone: '0',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'RAF Barkston Heath',
        city: 'Barkston Heath',
        country: 'United Kingdom',
        iataCode: 'N',
        icaoCode: 'EGYE',
        latitude: '52,96220016',
        longitude: '-0,561625004',
        altitude: '367',
        timezone: '0',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'RAF Marham',
        city: 'Marham',
        country: 'United Kingdom',
        iataCode: 'KNF',
        icaoCode: 'EGYM',
        latitude: '52,648395',
        longitude: '0,550692',
        altitude: '75',
        timezone: '0',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Mount Pleasant Airport',
        city: 'Mount Pleasant',
        country: 'Falkland Islands',
        iataCode: 'MPN',
        icaoCode: 'EGYP',
        latitude: '-51,82279968',
        longitude: '-58,44720078',
        altitude: '244',
        timezone: '-3',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Amsterdam Airport Schiphol',
        city: 'Amsterdam',
        country: 'Netherlands',
        iataCode: 'AMS',
        icaoCode: 'EHAM',
        latitude: '52,30860138',
        longitude: '4,76388979',
        altitude: '-11',
        timezone: '1',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Budel Airport',
        city: 'Weert',
        country: 'Netherlands',
        iataCode: 'N',
        icaoCode: 'EHBD',
        latitude: '51,25529861',
        longitude: '5,601389885',
        altitude: '114',
        timezone: '1',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Maastricht Aachen Airport',
        city: 'Maastricht',
        country: 'Netherlands',
        iataCode: 'MST',
        icaoCode: 'EHBK',
        latitude: '50,9117012',
        longitude: '5,770140171',
        altitude: '375',
        timezone: '1',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Deelen Air Base',
        city: 'Deelen',
        country: 'Netherlands',
        iataCode: 'N',
        icaoCode: 'EHDL',
        latitude: '52,0606',
        longitude: '5,87306',
        altitude: '158',
        timezone: '1',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Drachten Airport',
        city: 'Drachten',
        country: 'Netherlands',
        iataCode: 'N',
        icaoCode: 'EHDR',
        latitude: '53,11920166',
        longitude: '6,129720211',
        altitude: '14',
        timezone: '1',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Eindhoven Airport',
        city: 'Eindhoven',
        country: 'Netherlands',
        iataCode: 'EIN',
        icaoCode: 'EHEH',
        latitude: '51,45009995',
        longitude: '5,374529839',
        altitude: '74',
        timezone: '1',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Eelde Airport',
        city: 'Groningen',
        country: 'Netherlands',
        iataCode: 'GRQ',
        icaoCode: 'EHGG',
        latitude: '53,11970139',
        longitude: '6,579440117',
        altitude: '17',
        timezone: '1',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Gilze Rijen Air Base',
        city: 'Gilze-rijen',
        country: 'Netherlands',
        iataCode: 'N',
        icaoCode: 'EHGR',
        latitude: '51,56740189',
        longitude: '4,931829929',
        altitude: '49',
        timezone: '1',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'De Kooy Airport',
        city: 'De Kooy',
        country: 'Netherlands',
        iataCode: 'DHR',
        icaoCode: 'EHKD',
        latitude: '52,92340088',
        longitude: '4,780620098',
        altitude: '3',
        timezone: '1',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Lelystad Airport',
        city: 'Lelystad',
        country: 'Netherlands',
        iataCode: 'LEY',
        icaoCode: 'EHLE',
        latitude: '52,46030045',
        longitude: '5,527219772',
        altitude: '-13',
        timezone: '1',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Leeuwarden Air Base',
        city: 'Leeuwarden',
        country: 'Netherlands',
        iataCode: 'LWR',
        icaoCode: 'EHLW',
        latitude: '53,22859955',
        longitude: '5,760560036',
        altitude: '3',
        timezone: '1',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Rotterdam The Hague Airport',
        city: 'Rotterdam',
        country: 'Netherlands',
        iataCode: 'RTM',
        icaoCode: 'EHRD',
        latitude: '51,956902',
        longitude: '4,43722',
        altitude: '-15',
        timezone: '1',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Soesterberg Air Base',
        city: 'Soesterberg',
        country: 'Netherlands',
        iataCode: 'UTC',
        icaoCode: 'EHSB',
        latitude: '52,12730026',
        longitude: '5,276189804',
        altitude: '66',
        timezone: '1',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Twente Airfield',
        city: 'Enschede',
        country: 'Netherlands',
        iataCode: 'ENS',
        icaoCode: 'EHTW',
        latitude: '52,2758333',
        longitude: '6,8891667',
        altitude: '114',
        timezone: '1',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Valkenburg Naval Air Base',
        city: 'Valkenburg',
        country: 'Netherlands',
        iataCode: 'LID',
        icaoCode: 'EHVB',
        latitude: '52,16609955',
        longitude: '4,41794014',
        altitude: '1',
        timezone: '1',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Woensdrecht Air Base',
        city: 'Woensdrecht',
        country: 'Netherlands',
        iataCode: 'WOE',
        icaoCode: 'EHWO',
        latitude: '51,4491',
        longitude: '4,34203',
        altitude: '63',
        timezone: '1',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Cork Airport',
        city: 'Cork',
        country: 'Ireland',
        iataCode: 'ORK',
        icaoCode: 'EICK',
        latitude: '51,84130096',
        longitude: '-8,491109848',
        altitude: '502',
        timezone: '0',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Galway Airport',
        city: 'Galway',
        country: 'Ireland',
        iataCode: 'GWY',
        icaoCode: 'EICM',
        latitude: '53,30020142',
        longitude: '-8,941590309',
        altitude: '81',
        timezone: '0',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Dublin Airport',
        city: 'Dublin',
        country: 'Ireland',
        iataCode: 'DUB',
        icaoCode: 'EIDW',
        latitude: '53,42129898',
        longitude: '-6,270070076',
        altitude: '242',
        timezone: '0',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Ireland West Knock Airport',
        city: 'Connaught',
        country: 'Ireland',
        iataCode: 'NOC',
        icaoCode: 'EIKN',
        latitude: '53,91030121',
        longitude: '-8,818490028',
        altitude: '665',
        timezone: '0',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Kerry Airport',
        city: 'Kerry',
        country: 'Ireland',
        iataCode: 'KIR',
        icaoCode: 'EIKY',
        latitude: '52,18090057',
        longitude: '-9,523779869',
        altitude: '112',
        timezone: '0',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Casement Air Base',
        city: 'Casement',
        country: 'Ireland',
        iataCode: 'N',
        icaoCode: 'EIME',
        latitude: '53,30170059',
        longitude: '-6,451330185',
        altitude: '319',
        timezone: '0',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Shannon Airport',
        city: 'Shannon',
        country: 'Ireland',
        iataCode: 'SNN',
        icaoCode: 'EINN',
        latitude: '52,70199966',
        longitude: '-8,924819946',
        altitude: '46',
        timezone: '0',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Sligo Airport',
        city: 'Sligo',
        country: 'Ireland',
        iataCode: 'SXL',
        icaoCode: 'EISG',
        latitude: '54,28020096',
        longitude: '-8,599209785',
        altitude: '11',
        timezone: '0',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Waterford Airport',
        city: 'Waterford',
        country: 'Ireland',
        iataCode: 'WAT',
        icaoCode: 'EIWF',
        latitude: '52,18719864',
        longitude: '-7,086959839',
        altitude: '119',
        timezone: '0',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Aarhus Airport',
        city: 'Aarhus',
        country: 'Denmark',
        iataCode: 'AAR',
        icaoCode: 'EKAH',
        latitude: '56,29999924',
        longitude: '10,61900043',
        altitude: '82',
        timezone: '1',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Billund Airport',
        city: 'Billund',
        country: 'Denmark',
        iataCode: 'BLL',
        icaoCode: 'EKBI',
        latitude: '55,74029922',
        longitude: '9,151780128',
        altitude: '247',
        timezone: '1',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Copenhagen Kastrup Airport',
        city: 'Copenhagen',
        country: 'Denmark',
        iataCode: 'CPH',
        icaoCode: 'EKCH',
        latitude: '55,61790085',
        longitude: '12,65600014',
        altitude: '17',
        timezone: '1',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Esbjerg Airport',
        city: 'Esbjerg',
        country: 'Denmark',
        iataCode: 'EBJ',
        icaoCode: 'EKEB',
        latitude: '55,52590179',
        longitude: '8,55340004',
        altitude: '97',
        timezone: '1',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Grønholt Hillerød Airport',
        city: 'Gronholt',
        country: 'Denmark',
        iataCode: 'N',
        icaoCode: 'EKGH',
        latitude: '55,94139862',
        longitude: '12,38220024',
        altitude: '97',
        timezone: '1',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Karup Airport',
        city: 'Karup',
        country: 'Denmark',
        iataCode: 'KRP',
        icaoCode: 'EKKA',
        latitude: '56,29750061',
        longitude: '9,124629974',
        altitude: '170',
        timezone: '1',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Læsø Airport',
        city: 'Laeso',
        country: 'Denmark',
        iataCode: 'N',
        icaoCode: 'EKLS',
        latitude: '57,27719879',
        longitude: '11,00010014',
        altitude: '25',
        timezone: '1',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Lolland Falster Maribo Airport',
        city: 'Maribo',
        country: 'Denmark',
        iataCode: 'N',
        icaoCode: 'EKMB',
        latitude: '54,699299',
        longitude: '11,4401',
        altitude: '16',
        timezone: '1',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Odense Airport',
        city: 'Odense',
        country: 'Denmark',
        iataCode: 'ODE',
        icaoCode: 'EKOD',
        latitude: '55,47669983',
        longitude: '10,33090019',
        altitude: '56',
        timezone: '1',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Kruså-Padborg Airport',
        city: 'Krusa-padborg',
        country: 'Denmark',
        iataCode: 'N',
        icaoCode: 'EKPB',
        latitude: '54,87030029',
        longitude: '9,279009819',
        altitude: '88',
        timezone: '1',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Copenhagen Roskilde Airport',
        city: 'Copenhagen',
        country: 'Denmark',
        iataCode: 'RKE',
        icaoCode: 'EKRK',
        latitude: '55,58560181',
        longitude: '12,13140011',
        altitude: '146',
        timezone: '1',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Bornholm Airport',
        city: 'Ronne',
        country: 'Denmark',
        iataCode: 'RNN',
        icaoCode: 'EKRN',
        latitude: '55,06330109',
        longitude: '14,75959969',
        altitude: '52',
        timezone: '1',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Sønderborg Airport',
        city: 'Soenderborg',
        country: 'Denmark',
        iataCode: 'SGD',
        icaoCode: 'EKSB',
        latitude: '54,96440125',
        longitude: '9,791729927',
        altitude: '24',
        timezone: '1',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Skrydstrup Air Base',
        city: 'Skrydstrup',
        country: 'Denmark',
        iataCode: 'SKS',
        icaoCode: 'EKSP',
        latitude: '55,221048',
        longitude: '9,26702',
        altitude: '141',
        timezone: '1',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Skive Airport',
        city: 'Skive',
        country: 'Denmark',
        iataCode: 'N',
        icaoCode: 'EKSV',
        latitude: '56,55020142',
        longitude: '9,172980309',
        altitude: '74',
        timezone: '1',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Thisted Airport',
        city: 'Thisted',
        country: 'Denmark',
        iataCode: 'TED',
        icaoCode: 'EKTS',
        latitude: '57,06880188',
        longitude: '8,705220222',
        altitude: '23',
        timezone: '1',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Kolding Vamdrup Airport',
        city: 'Kolding',
        country: 'Denmark',
        iataCode: 'N',
        icaoCode: 'EKVD',
        latitude: '55,43629837',
        longitude: '9,330920219',
        altitude: '143',
        timezone: '1',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Vagar Airport',
        city: 'Vagar',
        country: 'Faroe Islands',
        iataCode: 'FAE',
        icaoCode: 'EKVG',
        latitude: '62,06359863',
        longitude: '-7,277219772',
        altitude: '280',
        timezone: '0',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Vesthimmerlands Flyveplads',
        city: 'Vesthimmerland',
        country: 'Denmark',
        iataCode: 'N',
        icaoCode: 'EKVH',
        latitude: '56,846901',
        longitude: '9,45861',
        altitude: '119',
        timezone: '1',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Stauning Airport',
        city: 'Stauning',
        country: 'Denmark',
        iataCode: 'STA',
        icaoCode: 'EKVJ',
        latitude: '55,99010086',
        longitude: '8,353910446',
        altitude: '17',
        timezone: '1',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Aalborg Airport',
        city: 'Aalborg',
        country: 'Denmark',
        iataCode: 'AAL',
        icaoCode: 'EKYT',
        latitude: '57,09275891',
        longitude: '9,849243164',
        altitude: '10',
        timezone: '1',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Luxembourg-Findel International Airport',
        city: 'Luxemburg',
        country: 'Luxembourg',
        iataCode: 'LUX',
        icaoCode: 'ELLX',
        latitude: '49,6233333',
        longitude: '6,2044444',
        altitude: '1234',
        timezone: '1',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Ålesund Airport',
        city: 'Alesund',
        country: 'Norway',
        iataCode: 'AES',
        icaoCode: 'ENAL',
        latitude: '62,5625',
        longitude: '6,119699955',
        altitude: '69',
        timezone: '1',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Andøya Airport',
        city: 'Andoya',
        country: 'Norway',
        iataCode: 'ANX',
        icaoCode: 'ENAN',
        latitude: '69,29250336',
        longitude: '16,14419937',
        altitude: '43',
        timezone: '1',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Alta Airport',
        city: 'Alta',
        country: 'Norway',
        iataCode: 'ALF',
        icaoCode: 'ENAT',
        latitude: '69,97609711',
        longitude: '23,37170029',
        altitude: '9',
        timezone: '1',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Bømoen Airport',
        city: 'Voss',
        country: 'Norway',
        iataCode: 'N',
        icaoCode: 'ENBM',
        latitude: '60,63890076',
        longitude: '6,50150013',
        altitude: '300',
        timezone: '1',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Brønnøysund Airport',
        city: 'Bronnoysund',
        country: 'Norway',
        iataCode: 'BNN',
        icaoCode: 'ENBN',
        latitude: '65,46109772',
        longitude: '12,21749973',
        altitude: '25',
        timezone: '1',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Bodø Airport',
        city: 'Bodo',
        country: 'Norway',
        iataCode: 'BOO',
        icaoCode: 'ENBO',
        latitude: '67,26920319',
        longitude: '14,36530018',
        altitude: '42',
        timezone: '1',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Bergen Airport Flesland',
        city: 'Bergen',
        country: 'Norway',
        iataCode: 'BGO',
        icaoCode: 'ENBR',
        latitude: '60,29339981',
        longitude: '5,218140125',
        altitude: '170',
        timezone: '1',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Båtsfjord Airport',
        city: 'Batsfjord',
        country: 'Norway',
        iataCode: 'BJF',
        icaoCode: 'ENBS',
        latitude: '70,60050201',
        longitude: '29,69140053',
        altitude: '490',
        timezone: '1',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Kristiansand Airport',
        city: 'Kristiansand',
        country: 'Norway',
        iataCode: 'KRS',
        icaoCode: 'ENCN',
        latitude: '58,20420074',
        longitude: '8,085370064',
        altitude: '57',
        timezone: '1',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Geilo Airport Dagali',
        city: 'Geilo',
        country: 'Norway',
        iataCode: 'N',
        icaoCode: 'ENDI',
        latitude: '60,41730118',
        longitude: '8,518349648',
        altitude: '2618',
        timezone: '1',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Bardufoss Airport',
        city: 'Bardufoss',
        country: 'Norway',
        iataCode: 'BDU',
        icaoCode: 'ENDU',
        latitude: '69,05580139',
        longitude: '18,54039955',
        altitude: '252',
        timezone: '1',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Harstad/Narvik Airport, Evenes',
        city: 'Harstad/Narvik',
        country: 'Norway',
        iataCode: 'EVE',
        icaoCode: 'ENEV',
        latitude: '68,49130249',
        longitude: '16,67810059',
        altitude: '84',
        timezone: '1',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Leirin Airport',
        city: 'Fagernes',
        country: 'Norway',
        iataCode: 'VDB',
        icaoCode: 'ENFG',
        latitude: '61,0155983',
        longitude: '9,288060188',
        altitude: '2697',
        timezone: '1',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Florø Airport',
        city: 'Floro',
        country: 'Norway',
        iataCode: 'FRO',
        icaoCode: 'ENFL',
        latitude: '61,58359909',
        longitude: '5,024720192',
        altitude: '37',
        timezone: '1',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Oslo Gardermoen Airport',
        city: 'Oslo',
        country: 'Norway',
        iataCode: 'OSL',
        icaoCode: 'ENGM',
        latitude: '60,19390106',
        longitude: '11,10039997',
        altitude: '681',
        timezone: '1',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Haugesund Airport',
        city: 'Haugesund',
        country: 'Norway',
        iataCode: 'HAU',
        icaoCode: 'ENHD',
        latitude: '59,34529877',
        longitude: '5,208360195',
        altitude: '86',
        timezone: '1',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Hasvik Airport',
        city: 'Hasvik',
        country: 'Norway',
        iataCode: 'HAA',
        icaoCode: 'ENHK',
        latitude: '70,48670197',
        longitude: '22,13969994',
        altitude: '21',
        timezone: '1',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Kristiansund Airport (Kvernberget)',
        city: 'Kristiansund',
        country: 'Norway',
        iataCode: 'KSU',
        icaoCode: 'ENKB',
        latitude: '63,11180115',
        longitude: '7,824520111',
        altitude: '204',
        timezone: '1',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Kjeller Airport',
        city: 'Kjeller',
        country: 'Norway',
        iataCode: 'N',
        icaoCode: 'ENKJ',
        latitude: '59,96929932',
        longitude: '11,03610039',
        altitude: '354',
        timezone: '1',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Kirkenes Airport (Høybuktmoen)',
        city: 'Kirkenes',
        country: 'Norway',
        iataCode: 'KKN',
        icaoCode: 'ENKR',
        latitude: '69,72579956',
        longitude: '29,8913002',
        altitude: '283',
        timezone: '1',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Lista Airport',
        city: 'Farsund',
        country: 'Norway',
        iataCode: 'FAN',
        icaoCode: 'ENLI',
        latitude: '58,09949875',
        longitude: '6,626049995',
        altitude: '29',
        timezone: '1',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Molde Airport',
        city: 'Molde',
        country: 'Norway',
        iataCode: 'MOL',
        icaoCode: 'ENML',
        latitude: '62,74470139',
        longitude: '7,262499809',
        altitude: '10',
        timezone: '1',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Mosjøen Airport (Kjærstad)',
        city: 'Mosjoen',
        country: 'Norway',
        iataCode: 'MJF',
        icaoCode: 'ENMS',
        latitude: '65,78399658',
        longitude: '13,21490002',
        altitude: '237',
        timezone: '1',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Banak Airport',
        city: 'Lakselv',
        country: 'Norway',
        iataCode: 'LKL',
        icaoCode: 'ENNA',
        latitude: '70,06880188',
        longitude: '24,9734993',
        altitude: '25',
        timezone: '1',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Notodden Airport',
        city: 'Notodden',
        country: 'Norway',
        iataCode: 'NTB',
        icaoCode: 'ENNO',
        latitude: '59,565701',
        longitude: '9,21222',
        altitude: '63',
        timezone: '1',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Ørland Airport',
        city: 'Orland',
        country: 'Norway',
        iataCode: 'OLA',
        icaoCode: 'ENOL',
        latitude: '63,69889832',
        longitude: '9,604000092',
        altitude: '28',
        timezone: '1',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Røros Airport',
        city: 'Roros',
        country: 'Norway',
        iataCode: 'RRS',
        icaoCode: 'ENRO',
        latitude: '62,57839966',
        longitude: '11,34230042',
        altitude: '2054',
        timezone: '1',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Moss-Rygge Airport',
        city: 'Rygge',
        country: 'Norway',
        iataCode: 'RYG',
        icaoCode: 'ENRY',
        latitude: '59,378817',
        longitude: '10,785439',
        altitude: '174',
        timezone: '1',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Svalbard Airport, Longyear',
        city: 'Svalbard',
        country: 'Norway',
        iataCode: 'LYR',
        icaoCode: 'ENSB',
        latitude: '78,24610138',
        longitude: '15,46560001',
        altitude: '88',
        timezone: '1',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Skien Airport',
        city: 'Skien',
        country: 'Norway',
        iataCode: 'SKE',
        icaoCode: 'ENSN',
        latitude: '59,18500137',
        longitude: '9,566940308',
        altitude: '463',
        timezone: '1',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Stord Airport',
        city: 'Stord',
        country: 'Norway',
        iataCode: 'SRP',
        icaoCode: 'ENSO',
        latitude: '59,79190063',
        longitude: '5,340849876',
        altitude: '160',
        timezone: '1',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Sandnessjøen Airport (Stokka)',
        city: 'Sandnessjoen',
        country: 'Norway',
        iataCode: 'SSJ',
        icaoCode: 'ENST',
        latitude: '65,95680237',
        longitude: '12,46889973',
        altitude: '56',
        timezone: '1',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Tromsø Airport',
        city: 'Tromso',
        country: 'Norway',
        iataCode: 'TOS',
        icaoCode: 'ENTC',
        latitude: '69,6832962',
        longitude: '18,91889954',
        altitude: '31',
        timezone: '1',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Sandefjord Airport, Torp',
        city: 'Sandefjord',
        country: 'Norway',
        iataCode: 'TRF',
        icaoCode: 'ENTO',
        latitude: '59,18669891',
        longitude: '10,25860024',
        altitude: '286',
        timezone: '1',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Trondheim Airport Værnes',
        city: 'Trondheim',
        country: 'Norway',
        iataCode: 'TRD',
        icaoCode: 'ENVA',
        latitude: '63,4578018',
        longitude: '10,9239998',
        altitude: '56',
        timezone: '1',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Stavanger Airport Sola',
        city: 'Stavanger',
        country: 'Norway',
        iataCode: 'SVG',
        icaoCode: 'ENZV',
        latitude: '58,87670135',
        longitude: '5,63778019',
        altitude: '29',
        timezone: '1',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Babice Airport',
        city: 'Warsaw',
        country: 'Poland',
        iataCode: 'N',
        icaoCode: 'EPBC',
        latitude: '52,26850128',
        longitude: '20,9109993',
        altitude: '352',
        timezone: '1',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Gdańsk Lech Wałęsa Airport',
        city: 'Gdansk',
        country: 'Poland',
        iataCode: 'GDN',
        icaoCode: 'EPGD',
        latitude: '54,37760162',
        longitude: '18,46619987',
        altitude: '489',
        timezone: '1',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'John Paul II International Airport Kraków-Balice Airport',
        city: 'Krakow',
        country: 'Poland',
        iataCode: 'KRK',
        icaoCode: 'EPKK',
        latitude: '50,07770157',
        longitude: '19,78479958',
        altitude: '791',
        timezone: '1',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Muchowiec Airport',
        city: 'Katowice',
        country: 'Poland',
        iataCode: 'N',
        icaoCode: 'EPKM',
        latitude: '50,23809814',
        longitude: '19,03420067',
        altitude: '909',
        timezone: '1',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Katowice International Airport',
        city: 'Katowice',
        country: 'Poland',
        iataCode: 'KTW',
        icaoCode: 'EPKT',
        latitude: '50,4743',
        longitude: '19,08',
        altitude: '995',
        timezone: '1',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Mielec Airport',
        city: 'Mielec',
        country: 'Poland',
        iataCode: 'N',
        icaoCode: 'EPML',
        latitude: '50,32229996',
        longitude: '21,46209908',
        altitude: '548',
        timezone: '1',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Poznań-Ławica Airport',
        city: 'Poznan',
        country: 'Poland',
        iataCode: 'POZ',
        icaoCode: 'EPPO',
        latitude: '52,42100143',
        longitude: '16,82629967',
        altitude: '308',
        timezone: '1',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Rzeszów-Jasionka Airport',
        city: 'Rzeszow',
        country: 'Poland',
        iataCode: 'RZE',
        icaoCode: 'EPRZ',
        latitude: '50,11000061',
        longitude: '22,0189991',
        altitude: '675',
        timezone: '1',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Szczecin-Goleniów Solidarność" Airport"',
        city: 'Szczecin',
        country: 'Poland',
        iataCode: 'SZZ',
        icaoCode: 'EPSC',
        latitude: '53,58470154',
        longitude: '14,90219975',
        altitude: '154',
        timezone: '1',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Redzikowo Air Base',
        city: 'Slupsk',
        country: 'Poland',
        iataCode: 'OSP',
        icaoCode: 'EPSK',
        latitude: '54,47890091',
        longitude: '17,10750008',
        altitude: '217',
        timezone: '1',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Swidwin Military Air Base',
        city: 'Shapaja',
        country: 'Poland',
        iataCode: 'N',
        icaoCode: 'EPSN',
        latitude: '53,79059982',
        longitude: '15,82629967',
        altitude: '385',
        timezone: '1',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Warsaw Chopin Airport',
        city: 'Warsaw',
        country: 'Poland',
        iataCode: 'WAW',
        icaoCode: 'EPWA',
        latitude: '52,16569901',
        longitude: '20,96710014',
        altitude: '362',
        timezone: '1',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Copernicus Wrocław Airport',
        city: 'Wroclaw',
        country: 'Poland',
        iataCode: 'WRO',
        icaoCode: 'EPWR',
        latitude: '51,10269928',
        longitude: '16,88579941',
        altitude: '404',
        timezone: '1',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Zielona Góra-Babimost Airport',
        city: 'Zielona Gora',
        country: 'Poland',
        iataCode: 'IEG',
        icaoCode: 'EPZG',
        latitude: '52,13850021',
        longitude: '15,7986002',
        altitude: '194',
        timezone: '1',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Malmen Air Base',
        city: 'Linkoeping',
        country: 'Sweden',
        iataCode: 'N',
        icaoCode: 'ESCF',
        latitude: '58,40230179',
        longitude: '15,52569962',
        altitude: '308',
        timezone: '1',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Bråvalla Air Base',
        city: 'Norrkoeping',
        country: 'Sweden',
        iataCode: 'N',
        icaoCode: 'ESCK',
        latitude: '58,61090088',
        longitude: '16,10359955',
        altitude: '90',
        timezone: '1',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Uppsala Airport',
        city: 'Uppsala',
        country: 'Sweden',
        iataCode: 'N',
        icaoCode: 'ESCM',
        latitude: '59,89730072',
        longitude: '17,58860016',
        altitude: '68',
        timezone: '1',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Ronneby Airport',
        city: 'Ronneby',
        country: 'Sweden',
        iataCode: 'RNB',
        icaoCode: 'ESDF',
        latitude: '56,26670074',
        longitude: '15,26500034',
        altitude: '191',
        timezone: '1',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Råda Air Base',
        city: 'Rada',
        country: 'Sweden',
        iataCode: 'N',
        icaoCode: 'ESFR',
        latitude: '58,49810028',
        longitude: '13,05319977',
        altitude: '230',
        timezone: '1',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Gothenburg-Landvetter Airport',
        city: 'Gothenborg',
        country: 'Sweden',
        iataCode: 'GOT',
        icaoCode: 'ESGG',
        latitude: '57,66279984',
        longitude: '12,27980042',
        altitude: '506',
        timezone: '1',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Jönköping Airport',
        city: 'Joenkoeping',
        country: 'Sweden',
        iataCode: 'JKG',
        icaoCode: 'ESGJ',
        latitude: '57,75759888',
        longitude: '14,06869984',
        altitude: '741',
        timezone: '1',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Falköping Airport',
        city: 'Falkoping',
        country: 'Sweden',
        iataCode: 'N',
        icaoCode: 'ESGK',
        latitude: '58,1697998',
        longitude: '13,58780003',
        altitude: '785',
        timezone: '1',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Lidköping-Hovby Airport',
        city: 'Lidkoping',
        country: 'Sweden',
        iataCode: 'LDK',
        icaoCode: 'ESGL',
        latitude: '58,46549988',
        longitude: '13,17440033',
        altitude: '200',
        timezone: '1',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Gothenburg City Airport',
        city: 'Gothenborg',
        country: 'Sweden',
        iataCode: 'GSE',
        icaoCode: 'ESGP',
        latitude: '57,77470016',
        longitude: '11,87040043',
        altitude: '59',
        timezone: '1',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Skövde Airport',
        city: 'Skovde',
        country: 'Sweden',
        iataCode: 'KVB',
        icaoCode: 'ESGR',
        latitude: '58,45640182',
        longitude: '13,97270012',
        altitude: '324',
        timezone: '1',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Trollhättan-Vänersborg Airport',
        city: 'Trollhattan',
        country: 'Sweden',
        iataCode: 'THN',
        icaoCode: 'ESGT',
        latitude: '58,31809998',
        longitude: '12,34500027',
        altitude: '137',
        timezone: '1',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Karlsborg Air Base',
        city: 'Karlsborg',
        country: 'Sweden',
        iataCode: 'N',
        icaoCode: 'ESIA',
        latitude: '58,51380157',
        longitude: '14,50710011',
        altitude: '308',
        timezone: '1',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Såtenäs Air Base',
        city: 'Satenas',
        country: 'Sweden',
        iataCode: 'N',
        icaoCode: 'ESIB',
        latitude: '58,42639923',
        longitude: '12,71440029',
        altitude: '181',
        timezone: '1',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Barkarby Airport',
        city: 'Stockholm',
        country: 'Sweden',
        iataCode: 'N',
        icaoCode: 'ESKB',
        latitude: '59,41447436',
        longitude: '17,8821373',
        altitude: '50',
        timezone: '1',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Karlskoga Airport',
        city: 'Karlskoga',
        country: 'Sweden',
        iataCode: 'KSK',
        icaoCode: 'ESKK',
        latitude: '59,34590149',
        longitude: '14,49590015',
        altitude: '400',
        timezone: '1',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Mora Airport',
        city: 'Mora',
        country: 'Sweden',
        iataCode: 'MXX',
        icaoCode: 'ESKM',
        latitude: '60,957901',
        longitude: '14,51140022',
        altitude: '634',
        timezone: '1',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Stockholm Skavsta Airport',
        city: 'Stockholm',
        country: 'Sweden',
        iataCode: 'NYO',
        icaoCode: 'ESKN',
        latitude: '58,78860092',
        longitude: '16,91220093',
        altitude: '140',
        timezone: '1',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Arvika Airport',
        city: 'Arvika',
        country: 'Sweden',
        iataCode: 'N',
        icaoCode: 'ESKV',
        latitude: '59,67589951',
        longitude: '12,63939953',
        altitude: '237',
        timezone: '1',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Emmaboda Airfield',
        city: 'Emmaboda',
        country: 'Sweden',
        iataCode: 'N',
        icaoCode: 'ESMA',
        latitude: '56,610802',
        longitude: '15,6048',
        altitude: '442',
        timezone: '1',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Feringe Airport',
        city: 'Ljungby',
        country: 'Sweden',
        iataCode: 'N',
        icaoCode: 'ESMG',
        latitude: '56,95029831',
        longitude: '13,92169952',
        altitude: '538',
        timezone: '1',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Kristianstad Airport',
        city: 'Kristianstad',
        country: 'Sweden',
        iataCode: 'KID',
        icaoCode: 'ESMK',
        latitude: '55,92169952',
        longitude: '14,08549976',
        altitude: '76',
        timezone: '1',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Landskrona Airport',
        city: 'Landskrona',
        country: 'Sweden',
        iataCode: 'JLD',
        icaoCode: 'ESML',
        latitude: '55,94599915',
        longitude: '12,86999989',
        altitude: '194',
        timezone: '1',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Oskarshamn Airport',
        city: 'Oskarshamn',
        country: 'Sweden',
        iataCode: 'OSK',
        icaoCode: 'ESMO',
        latitude: '57,3504982',
        longitude: '16,49799919',
        altitude: '96',
        timezone: '1',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Anderstorp Airport',
        city: 'Anderstorp',
        country: 'Sweden',
        iataCode: 'N',
        icaoCode: 'ESMP',
        latitude: '57,2641983',
        longitude: '13,59939957',
        altitude: '507',
        timezone: '1',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Kalmar Airport',
        city: 'Kalkmar',
        country: 'Sweden',
        iataCode: 'KLR',
        icaoCode: 'ESMQ',
        latitude: '56,6855011',
        longitude: '16,28759956',
        altitude: '17',
        timezone: '1',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Malmö Sturup Airport',
        city: 'Malmoe',
        country: 'Sweden',
        iataCode: 'MMX',
        icaoCode: 'ESMS',
        latitude: '55,53630536',
        longitude: '13,37619781',
        altitude: '236',
        timezone: '1',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Halmstad Airport',
        city: 'Halmstad',
        country: 'Sweden',
        iataCode: 'HAD',
        icaoCode: 'ESMT',
        latitude: '56,69110107',
        longitude: '12,82019997',
        altitude: '101',
        timezone: '1',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Hagshult Airport',
        city: 'Hagshult',
        country: 'Sweden',
        iataCode: 'N',
        icaoCode: 'ESMV',
        latitude: '57,29219818',
        longitude: '14,13720036',
        altitude: '556',
        timezone: '1',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Växjö Kronoberg Airport',
        city: 'Vaxjo',
        country: 'Sweden',
        iataCode: 'VXO',
        icaoCode: 'ESMX',
        latitude: '56,92910004',
        longitude: '14,72799969',
        altitude: '610',
        timezone: '1',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Hallviken Airport',
        city: 'Hallviken',
        country: 'Sweden',
        iataCode: 'N',
        icaoCode: 'ESNA',
        latitude: '63,73830032',
        longitude: '15,45829964',
        altitude: '1119',
        timezone: '1',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Hedlanda Airport',
        city: 'Hede',
        country: 'Sweden',
        iataCode: 'N',
        icaoCode: 'ESNC',
        latitude: '62,40890121',
        longitude: '13,74720001',
        altitude: '1460',
        timezone: '1',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Sveg Airport',
        city: 'Sveg',
        country: 'Sweden',
        iataCode: 'EVG',
        icaoCode: 'ESND',
        latitude: '62,04779816',
        longitude: '14,4229002',
        altitude: '1178',
        timezone: '1',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Gällivare Airport',
        city: 'Gallivare',
        country: 'Sweden',
        iataCode: 'GEV',
        icaoCode: 'ESNG',
        latitude: '67,13240051',
        longitude: '20,81459999',
        altitude: '1027',
        timezone: '1',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Hudiksvall Airport',
        city: 'Hudiksvall',
        country: 'Sweden',
        iataCode: 'HUV',
        icaoCode: 'ESNH',
        latitude: '61,76810074',
        longitude: '17,08069992',
        altitude: '95',
        timezone: '1',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Jokkmokk Airport',
        city: 'Jokkmokk',
        country: 'Sweden',
        iataCode: 'N',
        icaoCode: 'ESNJ',
        latitude: '66,49620056',
        longitude: '20,14719963',
        altitude: '904',
        timezone: '1',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Kramfors Sollefteå Airport',
        city: 'Kramfors',
        country: 'Sweden',
        iataCode: 'KRF',
        icaoCode: 'ESNK',
        latitude: '63,04859924',
        longitude: '17,76889992',
        altitude: '34',
        timezone: '1',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Lycksele Airport',
        city: 'Lycksele',
        country: 'Sweden',
        iataCode: 'LYC',
        icaoCode: 'ESNL',
        latitude: '64,5483017',
        longitude: '18,71619987',
        altitude: '705',
        timezone: '1',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Optand Airport',
        city: 'Optand',
        country: 'Sweden',
        iataCode: 'N',
        icaoCode: 'ESNM',
        latitude: '63,12860107',
        longitude: '14,80280018',
        altitude: '1236',
        timezone: '1',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Sundsvall-Härnösand Airport',
        city: 'Sundsvall',
        country: 'Sweden',
        iataCode: 'SDL',
        icaoCode: 'ESNN',
        latitude: '62,52809906',
        longitude: '17,44389915',
        altitude: '16',
        timezone: '1',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Örnsköldsvik Airport',
        city: 'Ornskoldsvik',
        country: 'Sweden',
        iataCode: 'OER',
        icaoCode: 'ESNO',
        latitude: '63,40829849',
        longitude: '18,98999977',
        altitude: '354',
        timezone: '1',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Piteå Airport',
        city: 'Pitea',
        country: 'Sweden',
        iataCode: 'N',
        icaoCode: 'ESNP',
        latitude: '65,39830017',
        longitude: '21,26079941',
        altitude: '43',
        timezone: '1',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Kiruna Airport',
        city: 'Kiruna',
        country: 'Sweden',
        iataCode: 'KRN',
        icaoCode: 'ESNQ',
        latitude: '67,8219986',
        longitude: '20,33679962',
        altitude: '1508',
        timezone: '1',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Orsa Airport',
        city: 'Orsa',
        country: 'Sweden',
        iataCode: 'N',
        icaoCode: 'ESNR',
        latitude: '61,18999863',
        longitude: '14,71259975',
        altitude: '683',
        timezone: '1',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Skellefteå Airport',
        city: 'Skelleftea',
        country: 'Sweden',
        iataCode: 'SFT',
        icaoCode: 'ESNS',
        latitude: '64,62480164',
        longitude: '21,07690048',
        altitude: '157',
        timezone: '1',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Sättna Airport',
        city: 'Sattna',
        country: 'Sweden',
        iataCode: 'N',
        icaoCode: 'ESNT',
        latitude: '62,4814',
        longitude: '17,002899',
        altitude: '886',
        timezone: '1',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Umeå Airport',
        city: 'Umea',
        country: 'Sweden',
        iataCode: 'UME',
        icaoCode: 'ESNU',
        latitude: '63,79180145',
        longitude: '20,28280067',
        altitude: '24',
        timezone: '1',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Vilhelmina Airport',
        city: 'Vilhelmina',
        country: 'Sweden',
        iataCode: 'VHM',
        icaoCode: 'ESNV',
        latitude: '64,57910156',
        longitude: '16,83359909',
        altitude: '1140',
        timezone: '1',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Arvidsjaur Airport',
        city: 'Arvidsjaur',
        country: 'Sweden',
        iataCode: 'AJR',
        icaoCode: 'ESNX',
        latitude: '65,59030151',
        longitude: '19,28190041',
        altitude: '1245',
        timezone: '1',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Örebro Airport',
        city: 'Orebro',
        country: 'Sweden',
        iataCode: 'ORB',
        icaoCode: 'ESOE',
        latitude: '59,22370148',
        longitude: '15,03800011',
        altitude: '188',
        timezone: '1',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Stockholm Västerås Airport',
        city: 'Vasteras',
        country: 'Sweden',
        iataCode: 'VST',
        icaoCode: 'ESOW',
        latitude: '59,58940125',
        longitude: '16,63360023',
        altitude: '21',
        timezone: '1',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Luleå Airport',
        city: 'Lulea',
        country: 'Sweden',
        iataCode: 'LLA',
        icaoCode: 'ESPA',
        latitude: '65,54380035',
        longitude: '22,12199974',
        altitude: '65',
        timezone: '1',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Vidsel Air Base',
        city: 'Vidsel',
        country: 'Sweden',
        iataCode: 'N',
        icaoCode: 'ESPE',
        latitude: '65,87529755',
        longitude: '20,14990044',
        altitude: '597',
        timezone: '1',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Arboga Airport',
        city: 'Arboga',
        country: 'Sweden',
        iataCode: 'N',
        icaoCode: 'ESQO',
        latitude: '59,38660049',
        longitude: '15,92409992',
        altitude: '33',
        timezone: '1',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Stockholm-Arlanda Airport',
        city: 'Stockholm',
        country: 'Sweden',
        iataCode: 'ARN',
        icaoCode: 'ESSA',
        latitude: '59,65190125',
        longitude: '17,91860008',
        altitude: '137',
        timezone: '1',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Stockholm-Bromma Airport',
        city: 'Stockholm',
        country: 'Sweden',
        iataCode: 'BMA',
        icaoCode: 'ESSB',
        latitude: '59,35440063',
        longitude: '17,94169998',
        altitude: '47',
        timezone: '1',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Borlange Airport',
        city: 'Borlange',
        country: 'Sweden',
        iataCode: 'BLE',
        icaoCode: 'ESSD',
        latitude: '60,42200089',
        longitude: '15,51519966',
        altitude: '503',
        timezone: '1',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Hultsfred Airport',
        city: 'Hultsfred',
        country: 'Sweden',
        iataCode: 'HLF',
        icaoCode: 'ESSF',
        latitude: '57,5257988',
        longitude: '15,82330036',
        altitude: '366',
        timezone: '1',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Gävle Sandviken Airport',
        city: 'Gavle',
        country: 'Sweden',
        iataCode: 'GVX',
        icaoCode: 'ESSK',
        latitude: '60,59329987',
        longitude: '16,95140076',
        altitude: '224',
        timezone: '1',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Linköping City Airport',
        city: 'Linkoeping',
        country: 'Sweden',
        iataCode: 'LPI',
        icaoCode: 'ESSL',
        latitude: '58,40620041',
        longitude: '15,68050003',
        altitude: '172',
        timezone: '1',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Norrköping Airport',
        city: 'Norrkoeping',
        country: 'Sweden',
        iataCode: 'NRK',
        icaoCode: 'ESSP',
        latitude: '58,5862999',
        longitude: '16,25060081',
        altitude: '32',
        timezone: '1',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Eskilstuna Airport',
        city: 'Eskilstuna',
        country: 'Sweden',
        iataCode: 'N',
        icaoCode: 'ESSU',
        latitude: '59,35110092',
        longitude: '16,70840073',
        altitude: '139',
        timezone: '1',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Visby Airport',
        city: 'Visby',
        country: 'Sweden',
        iataCode: 'VBY',
        icaoCode: 'ESSV',
        latitude: '57,66279984',
        longitude: '18,34620094',
        altitude: '164',
        timezone: '1',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Kalixfors Airport',
        city: 'Kalixfors',
        country: 'Sweden',
        iataCode: 'N',
        icaoCode: 'ESUK',
        latitude: '67,76480103',
        longitude: '20,25720024',
        altitude: '1549',
        timezone: '1',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Spangdahlem Air Base',
        city: 'Spangdahlem',
        country: 'Germany',
        iataCode: 'SPM',
        icaoCode: 'ETAD',
        latitude: '49,97269821',
        longitude: '6,692500114',
        altitude: '1197',
        timezone: '1',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Ramstein Air Base',
        city: 'Ramstein',
        country: 'Germany',
        iataCode: 'RMS',
        icaoCode: 'ETAR',
        latitude: '49,43690109',
        longitude: '7,600279808',
        altitude: '776',
        timezone: '1',
        dst: 'E',
        type: 'airport',
    },
    {
        name: '[Duplicate] Giebelstadt Army Air Field',
        city: 'Giebelstadt',
        country: 'Germany',
        iataCode: 'GHF',
        icaoCode: 'ETEU',
        latitude: '49,64810181',
        longitude: '9,966489792',
        altitude: '980',
        timezone: '1',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Bückeburg Air Base',
        city: 'Brueckeburg',
        country: 'Germany',
        iataCode: 'N',
        icaoCode: 'ETHB',
        latitude: '52,2785',
        longitude: '9,08217',
        altitude: '230',
        timezone: '1',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Celle Airport',
        city: 'Celle',
        country: 'Germany',
        iataCode: 'ZCN',
        icaoCode: 'ETHC',
        latitude: '52,59120178',
        longitude: '10,02210045',
        altitude: '129',
        timezone: '1',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Rheine Bentlage Air Base',
        city: 'Rheine-brentlange',
        country: 'Germany',
        iataCode: 'N',
        icaoCode: 'ETHE',
        latitude: '52,291199',
        longitude: '7,387',
        altitude: '129',
        timezone: '1',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Fritzlar Airport',
        city: 'Fritzlar',
        country: 'Germany',
        iataCode: 'N',
        icaoCode: 'ETHF',
        latitude: '51,1146',
        longitude: '9,286',
        altitude: '1345',
        timezone: '1',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Laupheim Air Base',
        city: 'Laupheim',
        country: 'Germany',
        iataCode: 'N',
        icaoCode: 'ETHL',
        latitude: '48,220299',
        longitude: '9,91002',
        altitude: '1766',
        timezone: '1',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Mendig Airport',
        city: 'Mendig',
        country: 'Germany',
        iataCode: 'N',
        icaoCode: 'ETHM',
        latitude: '50,36600113',
        longitude: '7,315330029',
        altitude: '597',
        timezone: '1',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Niederstetten Army Air Base',
        city: 'Niederstetten',
        country: 'Germany',
        iataCode: 'N',
        icaoCode: 'ETHN',
        latitude: '49,391945',
        longitude: '9,958889',
        altitude: '1339',
        timezone: '1',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Roth Airport',
        city: 'Roth',
        country: 'Germany',
        iataCode: 'N',
        icaoCode: 'ETHR',
        latitude: '49,21749878',
        longitude: '11,1001997',
        altitude: '1268',
        timezone: '1',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Fassberg Air Base',
        city: 'Fassberg',
        country: 'Germany',
        iataCode: 'N',
        icaoCode: 'ETHS',
        latitude: '52,919399',
        longitude: '10,185827',
        altitude: '245',
        timezone: '1',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Grafenwohr Army Air Field',
        city: 'Grafenwoehr',
        country: 'Germany',
        iataCode: 'N',
        icaoCode: 'ETIC',
        latitude: '49,69869995',
        longitude: '11,94019985',
        altitude: '1363',
        timezone: '1',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Hanau Army Air Field',
        city: 'Hanau',
        country: 'Germany',
        iataCode: 'ZNF',
        icaoCode: 'ETID',
        latitude: '50,169201',
        longitude: '8,96159',
        altitude: '368',
        timezone: '1',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Hohenfels Army Air Field',
        city: 'Hohenfels',
        country: 'Germany',
        iataCode: 'N',
        icaoCode: 'ETIH',
        latitude: '49,2181015',
        longitude: '11,83609962',
        altitude: '1455',
        timezone: '1',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Kitzingen Army Air Field',
        city: 'Kitzingen',
        country: 'Germany',
        iataCode: 'N',
        icaoCode: 'ETIN',
        latitude: '49,74309921',
        longitude: '10,20059967',
        altitude: '689',
        timezone: '1',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Nordholz Naval Airbase',
        city: 'Nordholz',
        country: 'Germany',
        iataCode: 'N',
        icaoCode: 'ETMN',
        latitude: '53,7677002',
        longitude: '8,658499718',
        altitude: '74',
        timezone: '1',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Diepholz Air Base',
        city: 'Diepholz',
        country: 'Germany',
        iataCode: 'N',
        icaoCode: 'ETND',
        latitude: '52,585556',
        longitude: '8,342222',
        altitude: '128',
        timezone: '1',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Geilenkirchen Air Base',
        city: 'Geilenkirchen',
        country: 'Germany',
        iataCode: 'GKE',
        icaoCode: 'ETNG',
        latitude: '50,9608',
        longitude: '6,04242',
        altitude: '296',
        timezone: '1',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Hohn Airport',
        city: 'Hohn',
        country: 'Germany',
        iataCode: 'N',
        icaoCode: 'ETNH',
        latitude: '54,31219864',
        longitude: '9,538169861',
        altitude: '39',
        timezone: '1',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Jever Air Base',
        city: 'Jever',
        country: 'Germany',
        iataCode: 'N',
        icaoCode: 'ETNJ',
        latitude: '53,533501',
        longitude: '7,88867',
        altitude: '24',
        timezone: '1',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Rostock-Laage Airport',
        city: 'Laage',
        country: 'Germany',
        iataCode: 'RLG',
        icaoCode: 'ETNL',
        latitude: '53,91820145',
        longitude: '12,27830029',
        altitude: '138',
        timezone: '1',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Nörvenich Air Base',
        city: 'Noervenich',
        country: 'Germany',
        iataCode: 'N',
        icaoCode: 'ETNN',
        latitude: '50,8312',
        longitude: '6,65817',
        altitude: '386',
        timezone: '1',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Schleswig Air Base',
        city: 'Schleswig',
        country: 'Germany',
        iataCode: 'N',
        icaoCode: 'ETNS',
        latitude: '54,459301',
        longitude: '9,51633',
        altitude: '70',
        timezone: '1',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Wittmundhafen Airport',
        city: 'Wittmundhafen',
        country: 'Germany',
        iataCode: 'N',
        icaoCode: 'ETNT',
        latitude: '53,54779816',
        longitude: '7,667329788',
        altitude: '26',
        timezone: '1',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Wunstorf Air Base',
        city: 'Wunstorf',
        country: 'Germany',
        iataCode: 'N',
        icaoCode: 'ETNW',
        latitude: '52,457298',
        longitude: '9,42717',
        altitude: '187',
        timezone: '1',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Vilseck Army Air Field',
        city: 'Vilseck',
        country: 'Germany',
        iataCode: 'N',
        icaoCode: 'ETOI',
        latitude: '49,63359833',
        longitude: '11,76720047',
        altitude: '1353',
        timezone: '1',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Coleman Army Air Field',
        city: 'Coleman',
        country: 'Germany',
        iataCode: 'N',
        icaoCode: 'ETOR',
        latitude: '49,56359863',
        longitude: '8,46339035',
        altitude: '309',
        timezone: '1',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Wiesbaden Army Airfield',
        city: 'Wiesbaden',
        country: 'Germany',
        iataCode: 'N',
        icaoCode: 'ETOU',
        latitude: '50,04980087',
        longitude: '8,325400352',
        altitude: '461',
        timezone: '1',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Landsberg Lech Air Base',
        city: 'Landsberg',
        country: 'Germany',
        iataCode: 'N',
        icaoCode: 'ETSA',
        latitude: '48,070599',
        longitude: '10,906',
        altitude: '2044',
        timezone: '1',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Büchel Air Base',
        city: 'Buechel',
        country: 'Germany',
        iataCode: 'N',
        icaoCode: 'ETSB',
        latitude: '50,17380142',
        longitude: '7,063330173',
        altitude: '1568',
        timezone: '1',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Erding Airport',
        city: 'Erding',
        country: 'Germany',
        iataCode: 'N',
        icaoCode: 'ETSE',
        latitude: '48,32229996',
        longitude: '11,94869995',
        altitude: '1515',
        timezone: '1',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Fürstenfeldbruck Airport',
        city: 'Fuerstenfeldbruck',
        country: 'Germany',
        iataCode: 'FEL',
        icaoCode: 'ETSF',
        latitude: '48,20555496',
        longitude: '11,26694393',
        altitude: '1703',
        timezone: '1',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Holzdorf Air Base',
        city: 'Holzdorf',
        country: 'Germany',
        iataCode: 'N',
        icaoCode: 'ETSH',
        latitude: '51,767799',
        longitude: '13,1677',
        altitude: '265',
        timezone: '1',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Ingolstadt Manching Airport',
        city: 'Ingolstadt',
        country: 'Germany',
        iataCode: 'IGS',
        icaoCode: 'ETSI',
        latitude: '48,71569824',
        longitude: '11,5340004',
        altitude: '1202',
        timezone: '1',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Lechfeld Airport',
        city: 'Lechfeld',
        country: 'Germany',
        iataCode: 'N',
        icaoCode: 'ETSL',
        latitude: '48,1855011',
        longitude: '10,86120033',
        altitude: '1822',
        timezone: '1',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Neuburg AFB',
        city: 'Neuburg',
        country: 'Germany',
        iataCode: 'N',
        icaoCode: 'ETSN',
        latitude: '48,710999',
        longitude: '11,2115',
        altitude: '1249',
        timezone: '1',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Gütersloh Air Base',
        city: 'Guetersloh',
        country: 'Germany',
        iataCode: 'GUT',
        icaoCode: 'ETUO',
        latitude: '51,922798',
        longitude: '8,30633',
        altitude: '236',
        timezone: '1',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Alexander Bay Airport',
        city: 'Alexander Bay',
        country: 'South Africa',
        iataCode: 'ALJ',
        icaoCode: 'FAAB',
        latitude: '-28,57500076',
        longitude: '16,5333004',
        altitude: '98',
        timezone: '2',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Aggeneys Airport',
        city: 'Aggeneys',
        country: 'South Africa',
        iataCode: 'AGZ',
        icaoCode: 'FAAG',
        latitude: '-29,28179932',
        longitude: '18,81389999',
        altitude: '2648',
        timezone: '2',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Brakpan Airport',
        city: 'Brakpan',
        country: 'South Africa',
        iataCode: 'N',
        icaoCode: 'FABB',
        latitude: '-26,23859978',
        longitude: '28,30179977',
        altitude: '5300',
        timezone: '2',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Bisho Airport',
        city: 'Bisho',
        country: 'South Africa',
        iataCode: 'BIY',
        icaoCode: 'FABE',
        latitude: '-32,89709854',
        longitude: '27,27910042',
        altitude: '1950',
        timezone: '2',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Bram Fischer International Airport',
        city: 'Bloemfontein',
        country: 'South Africa',
        iataCode: 'BFN',
        icaoCode: 'FABL',
        latitude: '-29,09269905',
        longitude: '26,30240059',
        altitude: '4458',
        timezone: '2',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Bethlehem Airport',
        city: 'Bethlehem',
        country: 'South Africa',
        iataCode: 'N',
        icaoCode: 'FABM',
        latitude: '-28,24839973',
        longitude: '28,33609962',
        altitude: '5561',
        timezone: '2',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Hendrik Potgieter Airport',
        city: 'Bothaville',
        country: 'South Africa',
        iataCode: 'N',
        icaoCode: 'FABO',
        latitude: '-27,36680031',
        longitude: '26,62919998',
        altitude: '4236',
        timezone: '2',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Cape Town International Airport',
        city: 'Cape Town',
        country: 'South Africa',
        iataCode: 'CPT',
        icaoCode: 'FACT',
        latitude: '-33,96480179',
        longitude: '18,60169983',
        altitude: '151',
        timezone: '2',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Calvinia Airport',
        city: 'Calvinia',
        country: 'South Africa',
        iataCode: 'N',
        icaoCode: 'FACV',
        latitude: '-31,50029945',
        longitude: '19,72590065',
        altitude: '3250',
        timezone: '2',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'King Shaka International Airport',
        city: 'Durban',
        country: 'South Africa',
        iataCode: 'DUR',
        icaoCode: 'FALE',
        latitude: '-29,61444444',
        longitude: '31,11972222',
        altitude: '295',
        timezone: '2',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Ben Schoeman Airport',
        city: 'East London',
        country: 'South Africa',
        iataCode: 'ELS',
        icaoCode: 'FAEL',
        latitude: '-33,03559875',
        longitude: '27,82589912',
        altitude: '435',
        timezone: '2',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Ermelo Airport',
        city: 'Ermelo',
        country: 'South Africa',
        iataCode: 'N',
        icaoCode: 'FAEO',
        latitude: '-26,49559975',
        longitude: '29,97979927',
        altitude: '5700',
        timezone: '2',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Ficksburg Sentraoes Airport',
        city: 'Ficksburg',
        country: 'South Africa',
        iataCode: 'N',
        icaoCode: 'FAFB',
        latitude: '-28,82309914',
        longitude: '27,90889931',
        altitude: '5315',
        timezone: '2',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Grand Central Airport',
        city: 'Johannesburg',
        country: 'South Africa',
        iataCode: 'GCJ',
        icaoCode: 'FAGC',
        latitude: '-25,98629951',
        longitude: '28,14010048',
        altitude: '5325',
        timezone: '2',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'George Airport',
        city: 'George',
        country: 'South Africa',
        iataCode: 'GRJ',
        icaoCode: 'FAGG',
        latitude: '-34,00559998',
        longitude: '22,37890053',
        altitude: '648',
        timezone: '2',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Graaff Reinet Airport',
        city: 'Graaff Reinet',
        country: 'South Africa',
        iataCode: 'N',
        icaoCode: 'FAGR',
        latitude: '-32,1935997',
        longitude: '24,54140091',
        altitude: '2604',
        timezone: '2',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Grahamstown Airport',
        city: 'Grahamstown',
        country: 'South Africa',
        iataCode: 'N',
        icaoCode: 'FAGT',
        latitude: '-33,28469849',
        longitude: '26,49810028',
        altitude: '2135',
        timezone: '2',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Greytown Airport',
        city: 'Greytown',
        country: 'South Africa',
        iataCode: 'N',
        icaoCode: 'FAGY',
        latitude: '-29,12199974',
        longitude: '30,58670044',
        altitude: '3531',
        timezone: '2',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Harmony Airport',
        city: 'Harmony',
        country: 'South Africa',
        iataCode: 'N',
        icaoCode: 'FAHA',
        latitude: '-28,07869911',
        longitude: '26,86120033',
        altitude: '4399',
        timezone: '2',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Harrismith Airport',
        city: 'Harrismith',
        country: 'South Africa',
        iataCode: 'N',
        icaoCode: 'FAHR',
        latitude: '-28,23509979',
        longitude: '29,10619926',
        altitude: '5585',
        timezone: '2',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Hoedspruit Air Force Base Airport',
        city: 'Hoedspruit',
        country: 'South Africa',
        iataCode: 'HDS',
        icaoCode: 'FAHS',
        latitude: '-24,36860085',
        longitude: '31,04870033',
        altitude: '1743',
        timezone: '2',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Gariep Dam Airport',
        city: 'Hendrik Verwoerddam',
        country: 'South Africa',
        iataCode: 'N',
        icaoCode: 'FAHV',
        latitude: '-30,56220055',
        longitude: '25,52829933',
        altitude: '4176',
        timezone: '2',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'OR Tambo International Airport',
        city: 'Johannesburg',
        country: 'South Africa',
        iataCode: 'JNB',
        icaoCode: 'FAJS',
        latitude: '-26,1392',
        longitude: '28,246',
        altitude: '5558',
        timezone: '2',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'P C Pelser Airport',
        city: 'Klerksdorp',
        country: 'South Africa',
        iataCode: 'N',
        icaoCode: 'FAKD',
        latitude: '-26,87109947',
        longitude: '26,71800041',
        altitude: '4444',
        timezone: '2',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Kimberley Airport',
        city: 'Kimberley',
        country: 'South Africa',
        iataCode: 'KIM',
        icaoCode: 'FAKM',
        latitude: '-28,80279922',
        longitude: '24,76519966',
        altitude: '3950',
        timezone: '2',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Krugersdorp Airport',
        city: 'Krugersdorp',
        country: 'South Africa',
        iataCode: 'N',
        icaoCode: 'FAKR',
        latitude: '-26,08082592',
        longitude: '27,72591496',
        altitude: '5499',
        timezone: '2',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Kroonstad Airport',
        city: 'Kroonstad',
        country: 'South Africa',
        iataCode: 'N',
        icaoCode: 'FAKS',
        latitude: '-27,66060066',
        longitude: '27,31579971',
        altitude: '4700',
        timezone: '2',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Johan Pienaar Airport',
        city: 'Kuruman',
        country: 'South Africa',
        iataCode: 'KMH',
        icaoCode: 'FAKU',
        latitude: '-27,45669937',
        longitude: '23,41139984',
        altitude: '4382',
        timezone: '2',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Kleinsee Airport',
        city: 'Kleinsee',
        country: 'South Africa',
        iataCode: 'KLZ',
        icaoCode: 'FAKZ',
        latitude: '-29,68840027',
        longitude: '17,09399986',
        altitude: '270',
        timezone: '2',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Lanseria Airport',
        city: 'Johannesburg',
        country: 'South Africa',
        iataCode: 'HLA',
        icaoCode: 'FALA',
        latitude: '-25,93849945',
        longitude: '27,92609978',
        altitude: '4517',
        timezone: '2',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Lichtenburg Airport',
        city: 'Lichtenburg',
        country: 'South Africa',
        iataCode: 'N',
        icaoCode: 'FALI',
        latitude: '-26,17569923',
        longitude: '26,18460083',
        altitude: '4875',
        timezone: '2',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Makhado Air Force Base Airport',
        city: 'Lambertsbaai',
        country: 'South Africa',
        iataCode: 'N',
        icaoCode: 'FALM',
        latitude: '-23,15990067',
        longitude: '29,69650078',
        altitude: '3069',
        timezone: '2',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Langebaanweg Airport',
        city: 'Langebaanweg',
        country: 'South Africa',
        iataCode: 'N',
        icaoCode: 'FALW',
        latitude: '-32,96889877',
        longitude: '18,1602993',
        altitude: '108',
        timezone: '2',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Ladysmith Airport',
        city: 'Ladysmith',
        country: 'South Africa',
        iataCode: 'LAY',
        icaoCode: 'FALY',
        latitude: '-28,58169937',
        longitude: '29,74970055',
        altitude: '3548',
        timezone: '2',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Middelburg Airport',
        city: 'Middelburg',
        country: 'South Africa',
        iataCode: 'N',
        icaoCode: 'FAMB',
        latitude: '-25,68479919',
        longitude: '29,44020081',
        altitude: '4886',
        timezone: '2',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Margate Airport',
        city: 'Margate',
        country: 'South Africa',
        iataCode: 'MGH',
        icaoCode: 'FAMG',
        latitude: '-30,85740089',
        longitude: '30,34300041',
        altitude: '495',
        timezone: '2',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Marble Hall Airport',
        city: 'Marble Hall',
        country: 'South Africa',
        iataCode: 'N',
        icaoCode: 'FAMI',
        latitude: '-24,9890995',
        longitude: '29,28310013',
        altitude: '2980',
        timezone: '2',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Majuba Power Station Airport',
        city: 'Majuba Power Station',
        country: 'South Africa',
        iataCode: 'N',
        icaoCode: 'FAMJ',
        latitude: '-27,07929993',
        longitude: '29,7784996',
        altitude: '5600',
        timezone: '2',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Riverside Airport',
        city: 'Malalane',
        country: 'South Africa',
        iataCode: 'N',
        icaoCode: 'FAMN',
        latitude: '-25,43000031',
        longitude: '31,57670021',
        altitude: '1024',
        timezone: '2',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Morningside Farm Airport',
        city: 'Musina',
        country: 'South Africa',
        iataCode: 'MEZ',
        icaoCode: 'FAMS',
        latitude: '-25,7045002',
        longitude: '26,9090004',
        altitude: '4251',
        timezone: '2',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Mkuze Airport',
        city: 'Mkuze',
        country: 'South Africa',
        iataCode: 'N',
        icaoCode: 'FAMU',
        latitude: '-27,62610054',
        longitude: '32,04430008',
        altitude: '400',
        timezone: '2',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Newcastle Airport',
        city: 'Newcastle',
        country: 'South Africa',
        iataCode: 'NCS',
        icaoCode: 'FANC',
        latitude: '-27,77059937',
        longitude: '29,9769001',
        altitude: '4074',
        timezone: '2',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Nylstroom Airfield',
        city: 'Nylstroom',
        country: 'South Africa',
        iataCode: 'N',
        icaoCode: 'FANY',
        latitude: '-24,68610001',
        longitude: '28,43490028',
        altitude: '3900',
        timezone: '2',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Overberg Airport',
        city: 'Overberg',
        country: 'South Africa',
        iataCode: 'N',
        icaoCode: 'FAOB',
        latitude: '-34,55490112',
        longitude: '20,2507',
        altitude: '52',
        timezone: '2',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Oudtshoorn Airport',
        city: 'Oudtshoorn',
        country: 'South Africa',
        iataCode: 'DUH',
        icaoCode: 'FAOH',
        latitude: '-33,60699844',
        longitude: '22,18899918',
        altitude: '1063',
        timezone: '2',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Port Elizabeth Airport',
        city: 'Port Elizabeth',
        country: 'South Africa',
        iataCode: 'PLZ',
        icaoCode: 'FAPE',
        latitude: '-33,98490143',
        longitude: '25,61730003',
        altitude: '226',
        timezone: '2',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Plettenberg Bay Airport',
        city: 'Plettenberg Bay',
        country: 'South Africa',
        iataCode: 'N',
        icaoCode: 'FAPG',
        latitude: '-34,08816017',
        longitude: '23,32872343',
        altitude: '465',
        timezone: '2',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Hendrik Van Eck Airport',
        city: 'Phalaborwa',
        country: 'South Africa',
        iataCode: 'PHW',
        icaoCode: 'FAPH',
        latitude: '-23,93720055',
        longitude: '31,15539932',
        altitude: '1432',
        timezone: '2',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Pietersburg Municipal Airport',
        city: 'Polokwane',
        country: 'South Africa',
        iataCode: 'N',
        icaoCode: 'FAPI',
        latitude: '-23,92609978',
        longitude: '29,4843998',
        altitude: '4354',
        timezone: '2',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Port St Johns Airport',
        city: 'Port Saint Johns',
        country: 'South Africa',
        iataCode: 'N',
        icaoCode: 'FAPJ',
        latitude: '-31,60589981',
        longitude: '29,51980019',
        altitude: '1227',
        timezone: '2',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Pietermaritzburg Airport',
        city: 'Pietermaritzburg',
        country: 'South Africa',
        iataCode: 'PZB',
        icaoCode: 'FAPM',
        latitude: '-29,64900017',
        longitude: '30,39870071',
        altitude: '2423',
        timezone: '2',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Pilanesberg International Airport',
        city: 'Pilanesberg',
        country: 'South Africa',
        iataCode: 'NTY',
        icaoCode: 'FAPN',
        latitude: '-25,33379936',
        longitude: '27,17340088',
        altitude: '3412',
        timezone: '2',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Polokwane International Airport',
        city: 'Potgietersrus',
        country: 'South Africa',
        iataCode: 'PTG',
        icaoCode: 'FAPP',
        latitude: '-23,845269',
        longitude: '29,458615',
        altitude: '4076',
        timezone: '2',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Potchefstroom Airport',
        city: 'Potchefstroom',
        country: 'South Africa',
        iataCode: 'N',
        icaoCode: 'FAPS',
        latitude: '-26,67099953',
        longitude: '27,08189964',
        altitude: '4520',
        timezone: '2',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Parys Airport',
        city: 'Parys',
        country: 'South Africa',
        iataCode: 'N',
        icaoCode: 'FAPY',
        latitude: '-26,88929939',
        longitude: '27,5034008',
        altitude: '4740',
        timezone: '2',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Queenstown Airport',
        city: 'Queenstown',
        country: 'South Africa',
        iataCode: 'UTW',
        icaoCode: 'FAQT',
        latitude: '-31,92020035',
        longitude: '26,88220024',
        altitude: '3637',
        timezone: '2',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Richards Bay Airport',
        city: "Richard's Bay",
        country: 'South Africa',
        iataCode: 'RCB',
        icaoCode: 'FARB',
        latitude: '-28,74099922',
        longitude: '32,09209824',
        altitude: '109',
        timezone: '2',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Rustenburg Airport',
        city: 'Rustenburg',
        country: 'South Africa',
        iataCode: 'N',
        icaoCode: 'FARG',
        latitude: '-25,64430046',
        longitude: '27,27109909',
        altitude: '3700',
        timezone: '2',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Robertson Airport',
        city: 'Robertson',
        country: 'South Africa',
        iataCode: 'ROD',
        icaoCode: 'FARS',
        latitude: '-33,81219864',
        longitude: '19,90279961',
        altitude: '640',
        timezone: '2',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Springbok Airport',
        city: 'Springbok',
        country: 'South Africa',
        iataCode: 'SBU',
        icaoCode: 'FASB',
        latitude: '-29,68930054',
        longitude: '17,93959999',
        altitude: '2690',
        timezone: '2',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Secunda Airport',
        city: 'Secunda',
        country: 'South Africa',
        iataCode: 'N',
        icaoCode: 'FASC',
        latitude: '-26,52409935',
        longitude: '29,17009926',
        altitude: '5250',
        timezone: '2',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Saldanha /Vredenburg Airport',
        city: 'Saldanha',
        country: 'South Africa',
        iataCode: 'N',
        icaoCode: 'FASD',
        latitude: '-32,96409988',
        longitude: '17,96929932',
        altitude: '50',
        timezone: '2',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Springs Airfield',
        city: 'Springs',
        country: 'South Africa',
        iataCode: 'N',
        icaoCode: 'FASI',
        latitude: '-26,24940047',
        longitude: '28,39827156',
        altitude: '5340',
        timezone: '2',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Swartkop Air Force Base',
        city: 'Swartkop',
        country: 'South Africa',
        iataCode: 'N',
        icaoCode: 'FASK',
        latitude: '-25,80970001',
        longitude: '28,16460037',
        altitude: '4780',
        timezone: '2',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Sishen Airport',
        city: 'Sishen',
        country: 'South Africa',
        iataCode: 'SIS',
        icaoCode: 'FASS',
        latitude: '-27,64859962',
        longitude: '22,9993',
        altitude: '3848',
        timezone: '2',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Hendrik Swellengrebel Airport',
        city: 'Swellendam',
        country: 'South Africa',
        iataCode: 'N',
        icaoCode: 'FASX',
        latitude: '-34,0481987',
        longitude: '20,47459984',
        altitude: '407',
        timezone: '2',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Skukuza Airport',
        city: 'Skukuza',
        country: 'South Africa',
        iataCode: 'SZK',
        icaoCode: 'FASZ',
        latitude: '-24,96089935',
        longitude: '31,58869934',
        altitude: '1020',
        timezone: '2',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Tommys Field Airport',
        city: "Tommy's Field",
        country: 'South Africa',
        iataCode: 'N',
        icaoCode: 'FATF',
        latitude: '-28,26000023',
        longitude: '22,9932003',
        altitude: '4360',
        timezone: '2',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'New Tempe Airport',
        city: 'Bloemfontein',
        country: 'South Africa',
        iataCode: 'N',
        icaoCode: 'FATP',
        latitude: '-29,03289986',
        longitude: '26,1576004',
        altitude: '4547',
        timezone: '2',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Tutuka Power Station Airport',
        city: 'Tutuka',
        country: 'South Africa',
        iataCode: 'N',
        icaoCode: 'FATT',
        latitude: '-26,77659988',
        longitude: '29,33880043',
        altitude: '5313',
        timezone: '2',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Tzaneen Airport',
        city: 'Tzaneen',
        country: 'South Africa',
        iataCode: 'LTA',
        icaoCode: 'FATZ',
        latitude: '-23,82439995',
        longitude: '30,32929993',
        altitude: '1914',
        timezone: '2',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Prince Mangosuthu Buthelezi Airport',
        city: 'Ulundi',
        country: 'South Africa',
        iataCode: 'ULD',
        icaoCode: 'FAUL',
        latitude: '-28,32060051',
        longitude: '31,41650009',
        altitude: '1720',
        timezone: '2',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Pierre Van Ryneveld Airport',
        city: 'Upington',
        country: 'South Africa',
        iataCode: 'UTN',
        icaoCode: 'FAUP',
        latitude: '-28,39909935',
        longitude: '21,2602005',
        altitude: '2782',
        timezone: '2',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'K. D. Matanzima Airport',
        city: 'Umtata',
        country: 'South Africa',
        iataCode: 'UTT',
        icaoCode: 'FAUT',
        latitude: '-31,54636318',
        longitude: '28,6733551',
        altitude: '2400',
        timezone: '2',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Vryburg Airport',
        city: 'Vryburg',
        country: 'South Africa',
        iataCode: 'VRU',
        icaoCode: 'FAVB',
        latitude: '-26,98240089',
        longitude: '24,72879982',
        altitude: '3920',
        timezone: '2',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Virginia Airport',
        city: 'Durban',
        country: 'South Africa',
        iataCode: 'VIR',
        icaoCode: 'FAVG',
        latitude: '-29,77059937',
        longitude: '31,0583992',
        altitude: '20',
        timezone: '2',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Vredendal Airport',
        city: 'Vredendal',
        country: 'South Africa',
        iataCode: 'N',
        icaoCode: 'FAVR',
        latitude: '-31,64100075',
        longitude: '18,5447998',
        altitude: '330',
        timezone: '2',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Vereeniging Airport',
        city: 'Vereeniging',
        country: 'South Africa',
        iataCode: 'N',
        icaoCode: 'FAVV',
        latitude: '-26,56640053',
        longitude: '27,96080017',
        altitude: '4846',
        timezone: '2',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Wonderboom Airport',
        city: 'Pretoria',
        country: 'South Africa',
        iataCode: 'PRY',
        icaoCode: 'FAWB',
        latitude: '-25,6539',
        longitude: '28,224199',
        altitude: '4095',
        timezone: '2',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Witbank Airport',
        city: 'Witbank',
        country: 'South Africa',
        iataCode: 'N',
        icaoCode: 'FAWI',
        latitude: '-25,83230019',
        longitude: '29,19199944',
        altitude: '5078',
        timezone: '2',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Waterkloof Air Force Base',
        city: 'Waterkloof',
        country: 'South Africa',
        iataCode: 'N',
        icaoCode: 'FAWK',
        latitude: '-25,82999992',
        longitude: '28,22249985',
        altitude: '4940',
        timezone: '2',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Welkom Airport',
        city: 'Welkom',
        country: 'South Africa',
        iataCode: 'WEL',
        icaoCode: 'FAWM',
        latitude: '-27,99682451',
        longitude: '26,66333389',
        altitude: '4421',
        timezone: '2',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Ysterplaat Air Force Base',
        city: 'Ysterplaat',
        country: 'South Africa',
        iataCode: 'N',
        icaoCode: 'FAYP',
        latitude: '-33,90019989',
        longitude: '18,49830055',
        altitude: '52',
        timezone: '2',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Zeerust Airport',
        city: 'Zeerust',
        country: 'South Africa',
        iataCode: 'N',
        icaoCode: 'FAZR',
        latitude: '-25,59900093',
        longitude: '26,04229927',
        altitude: '4258',
        timezone: '2',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Francistown Airport',
        city: 'Francistown',
        country: 'Botswana',
        iataCode: 'FRW',
        icaoCode: 'FBFT',
        latitude: '-21,1595993',
        longitude: '27,47450066',
        altitude: '3283',
        timezone: '2',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Jwaneng Airport',
        city: 'Jwaneng',
        country: 'Botswana',
        iataCode: 'JWA',
        icaoCode: 'FBJW',
        latitude: '-24,60230064',
        longitude: '24,69099998',
        altitude: '3900',
        timezone: '2',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Kasane Airport',
        city: 'Kasane',
        country: 'Botswana',
        iataCode: 'BBK',
        icaoCode: 'FBKE',
        latitude: '-17,83289909',
        longitude: '25,16239929',
        altitude: '3289',
        timezone: '2',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Maun Airport',
        city: 'Maun',
        country: 'Botswana',
        iataCode: 'MUB',
        icaoCode: 'FBMN',
        latitude: '-19,97260094',
        longitude: '23,43110085',
        altitude: '3093',
        timezone: '2',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Sir Seretse Khama International Airport',
        city: 'Gaberone',
        country: 'Botswana',
        iataCode: 'GBE',
        icaoCode: 'FBSK',
        latitude: '-24,55520058',
        longitude: '25,91819954',
        altitude: '3299',
        timezone: '2',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Selebi Phikwe Airport',
        city: 'Selebi-phikwe',
        country: 'Botswana',
        iataCode: 'PKW',
        icaoCode: 'FBSP',
        latitude: '-22,0583',
        longitude: '27,8288',
        altitude: '2925',
        timezone: '2',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Maya-Maya Airport',
        city: 'Brazzaville',
        country: 'Congo (Brazzaville)',
        iataCode: 'BZV',
        icaoCode: 'FCBB',
        latitude: '-4,251699924',
        longitude: '15,25300026',
        altitude: '1048',
        timezone: '1',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Owando Airport',
        city: 'Owando',
        country: 'Congo (Kinshasa)',
        iataCode: 'FTX',
        icaoCode: 'FCOO',
        latitude: '-0,531350017',
        longitude: '15,95009995',
        altitude: '1214',
        timezone: '1',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Ouesso Airport',
        city: 'Ouesso',
        country: 'Congo (Kinshasa)',
        iataCode: 'OUE',
        icaoCode: 'FCOU',
        latitude: '1,615990043',
        longitude: '16,03790092',
        altitude: '1158',
        timezone: '1',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Pointe Noire Airport',
        city: 'Pointe-noire',
        country: 'Congo (Brazzaville)',
        iataCode: 'PNR',
        icaoCode: 'FCPP',
        latitude: '-4,816030025',
        longitude: '11,88659954',
        altitude: '55',
        timezone: '1',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Matsapha Airport',
        city: 'Manzini',
        country: 'Swaziland',
        iataCode: 'MTS',
        icaoCode: 'FDMS',
        latitude: '-26,52899933',
        longitude: '31,30750084',
        altitude: '2075',
        timezone: '2',
        dst: 'U',
        type: 'airport',
    },
    {
        name: "Bangui M'Poko International Airport",
        city: 'Bangui',
        country: 'Central African Republic',
        iataCode: 'BGF',
        icaoCode: 'FEFF',
        latitude: '4,398479939',
        longitude: '18,51880074',
        altitude: '1208',
        timezone: '1',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Berbérati Airport',
        city: 'Berberati',
        country: 'Central African Republic',
        iataCode: 'BBT',
        icaoCode: 'FEFT',
        latitude: '4,221580029',
        longitude: '15,78639984',
        altitude: '1929',
        timezone: '1',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Bata Airport',
        city: 'Bata',
        country: 'Equatorial Guinea',
        iataCode: 'BSG',
        icaoCode: 'FGBT',
        latitude: '1,905470014',
        longitude: '9,805680275',
        altitude: '13',
        timezone: '1',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Malabo Airport',
        city: 'Malabo',
        country: 'Equatorial Guinea',
        iataCode: 'SSG',
        icaoCode: 'FGSL',
        latitude: '3,755270004',
        longitude: '8,708720207',
        altitude: '76',
        timezone: '1',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'RAF Ascension Island',
        city: 'Wide Awake',
        country: 'Saint Helena',
        iataCode: 'ASI',
        icaoCode: 'FHAW',
        latitude: '-7,969600201',
        longitude: '-14,39369965',
        altitude: '278',
        timezone: '0',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Sir Seewoosagur Ramgoolam International Airport',
        city: 'Plaisance',
        country: 'Mauritius',
        iataCode: 'MRU',
        icaoCode: 'FIMP',
        latitude: '-20,43020058',
        longitude: '57,68360138',
        altitude: '186',
        timezone: '4',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Sir Charles Gaetan Duval Airport',
        city: 'Rodriguez Island',
        country: 'Mauritius',
        iataCode: 'RRG',
        icaoCode: 'FIMR',
        latitude: '-19,75769997',
        longitude: '63,36100006',
        altitude: '95',
        timezone: '4',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Diego Garcia Naval Support Facility',
        city: 'Diego Garcia Island',
        country: 'British Indian Ocean Territory',
        iataCode: 'N',
        icaoCode: 'FJDG',
        latitude: '-7,31327',
        longitude: '72,411102',
        altitude: '9',
        timezone: '6',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Tiko Airport',
        city: 'Tiko',
        country: 'Cameroon',
        iataCode: 'TKC',
        icaoCode: 'FKKC',
        latitude: '4,089190006',
        longitude: '9,3605299',
        altitude: '151',
        timezone: '1',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Douala International Airport',
        city: 'Douala',
        country: 'Cameroon',
        iataCode: 'DLA',
        icaoCode: 'FKKD',
        latitude: '4,006080151',
        longitude: '9,719479561',
        altitude: '33',
        timezone: '1',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Salak Airport',
        city: 'Maroua',
        country: 'Cameroon',
        iataCode: 'MVR',
        icaoCode: 'FKKL',
        latitude: '10,4513998',
        longitude: '14,25739956',
        altitude: '1390',
        timezone: '1',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Foumban Nkounja Airport',
        city: 'Foumban',
        country: 'Cameroon',
        iataCode: 'FOM',
        icaoCode: 'FKKM',
        latitude: '5,636919975',
        longitude: '10,75080013',
        altitude: '3963',
        timezone: '1',
        dst: 'N',
        type: 'airport',
    },
    {
        name: "N'Gaoundéré Airport",
        city: "N'gaoundere",
        country: 'Cameroon',
        iataCode: 'NGE',
        icaoCode: 'FKKN',
        latitude: '7,357009888',
        longitude: '13,55920029',
        altitude: '3655',
        timezone: '1',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Garoua International Airport',
        city: 'Garoua',
        country: 'Cameroon',
        iataCode: 'GOU',
        icaoCode: 'FKKR',
        latitude: '9,335889816',
        longitude: '13,37010002',
        altitude: '794',
        timezone: '1',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Bafoussam Airport',
        city: 'Bafoussam',
        country: 'Cameroon',
        iataCode: 'BFX',
        icaoCode: 'FKKU',
        latitude: '5,536920071',
        longitude: '10,35459995',
        altitude: '4347',
        timezone: '1',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Bamenda Airport',
        city: 'Bamenda',
        country: 'Cameroon',
        iataCode: 'BPC',
        icaoCode: 'FKKV',
        latitude: '6,039239883',
        longitude: '10,1225996',
        altitude: '4065',
        timezone: '1',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Yaoundé Airport',
        city: 'Yaounde',
        country: 'Cameroon',
        iataCode: 'YAO',
        icaoCode: 'FKKY',
        latitude: '3,83604002',
        longitude: '11,52350044',
        altitude: '2464',
        timezone: '1',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Kasompe Airport',
        city: 'Kasompe',
        country: 'Zambia',
        iataCode: 'N',
        icaoCode: 'FLKE',
        latitude: '-12,57279968',
        longitude: '27,89389992',
        altitude: '4636',
        timezone: '2',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Livingstone Airport',
        city: 'Livingstone',
        country: 'Zambia',
        iataCode: 'LVI',
        icaoCode: 'FLLI',
        latitude: '-17,82180023',
        longitude: '25,8227005',
        altitude: '3302',
        timezone: '2',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Kenneth Kaunda International Airport Lusaka',
        city: 'Lusaka',
        country: 'Zambia',
        iataCode: 'LUN',
        icaoCode: 'FLLS',
        latitude: '-15,33080006',
        longitude: '28,45260048',
        altitude: '3779',
        timezone: '2',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Mfuwe Airport',
        city: 'Mfuwe',
        country: 'Zambia',
        iataCode: 'MFU',
        icaoCode: 'FLMF',
        latitude: '-13,25889969',
        longitude: '31,93659973',
        altitude: '1853',
        timezone: '2',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Mongu Airport',
        city: 'Mongu',
        country: 'Zambia',
        iataCode: 'N',
        icaoCode: 'FLMG',
        latitude: '-15,25450039',
        longitude: '23,16230011',
        altitude: '3488',
        timezone: '2',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Simon Mwansa Kapwepwe International Airport',
        city: 'Ndola',
        country: 'Zambia',
        iataCode: 'NLA',
        icaoCode: 'FLND',
        latitude: '-12,99810028',
        longitude: '28,66489983',
        altitude: '4167',
        timezone: '2',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Southdowns Airport',
        city: 'Southdowns',
        country: 'Zambia',
        iataCode: 'KIW',
        icaoCode: 'FLSO',
        latitude: '-12,9005003',
        longitude: '28,14990044',
        altitude: '4145',
        timezone: '2',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Prince Said Ibrahim International Airport',
        city: 'Moroni',
        country: 'Comoros',
        iataCode: 'HAH',
        icaoCode: 'FMCH',
        latitude: '-11,5337',
        longitude: '43,2719',
        altitude: '93',
        timezone: '3',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Mohéli Bandar Es Eslam Airport',
        city: 'Moheli',
        country: 'Comoros',
        iataCode: 'NWA',
        icaoCode: 'FMCI',
        latitude: '-12,29810047',
        longitude: '43,76639938',
        altitude: '46',
        timezone: '3',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Ouani Airport',
        city: 'Anjouan',
        country: 'Comoros',
        iataCode: 'AJN',
        icaoCode: 'FMCV',
        latitude: '-12,13169956',
        longitude: '44,43030167',
        altitude: '62',
        timezone: '3',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Dzaoudzi Pamandzi International Airport',
        city: 'Dzaoudzi',
        country: 'Mayotte',
        iataCode: 'DZA',
        icaoCode: 'FMCZ',
        latitude: '-12,8046999',
        longitude: '45,28110123',
        altitude: '23',
        timezone: '3',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Roland Garros Airport',
        city: 'St.-denis',
        country: 'Reunion',
        iataCode: 'RUN',
        icaoCode: 'FMEE',
        latitude: '-20,88710022',
        longitude: '55,51029968',
        altitude: '66',
        timezone: '4',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Pierrefonds Airport',
        city: 'St.-pierre',
        country: 'Reunion',
        iataCode: 'ZSE',
        icaoCode: 'FMEP',
        latitude: '-21,32089996',
        longitude: '55,42499924',
        altitude: '59',
        timezone: '4',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Ivato Airport',
        city: 'Antananarivo',
        country: 'Madagascar',
        iataCode: 'TNR',
        icaoCode: 'FMMI',
        latitude: '-18,7968998',
        longitude: '47,47880173',
        altitude: '4198',
        timezone: '3',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Miandrivazo Airport',
        city: 'Miandrivazo',
        country: 'Madagascar',
        iataCode: 'ZVA',
        icaoCode: 'FMMN',
        latitude: '-19,56279945',
        longitude: '45,45080185',
        altitude: '203',
        timezone: '3',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Sainte Marie Airport',
        city: 'Sainte Marie',
        country: 'Madagascar',
        iataCode: 'SMS',
        icaoCode: 'FMMS',
        latitude: '-17,09390068',
        longitude: '49,81579971',
        altitude: '7',
        timezone: '3',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Toamasina Airport',
        city: 'Toamasina',
        country: 'Madagascar',
        iataCode: 'TMM',
        icaoCode: 'FMMT',
        latitude: '-18,10950089',
        longitude: '49,39250183',
        altitude: '22',
        timezone: '3',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Morondava Airport',
        city: 'Morondava',
        country: 'Madagascar',
        iataCode: 'MOQ',
        icaoCode: 'FMMV',
        latitude: '-20,28470039',
        longitude: '44,31760025',
        altitude: '30',
        timezone: '3',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Arrachart Airport',
        city: 'Antsiranana',
        country: 'Madagascar',
        iataCode: 'DIE',
        icaoCode: 'FMNA',
        latitude: '-12,34939957',
        longitude: '49,29169846',
        altitude: '374',
        timezone: '3',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Mananara Nord Airport',
        city: 'Mananara',
        country: 'Madagascar',
        iataCode: 'WMR',
        icaoCode: 'FMNC',
        latitude: '-16,16390038',
        longitude: '49,7737999',
        altitude: '9',
        timezone: '3',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Andapa Airport',
        city: 'Andapa',
        country: 'Madagascar',
        iataCode: 'ZWA',
        icaoCode: 'FMND',
        latitude: '-14,65170002',
        longitude: '49,62060165',
        altitude: '1552',
        timezone: '3',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Ambilobe Airport',
        city: 'Ambilobe',
        country: 'Madagascar',
        iataCode: 'AMB',
        icaoCode: 'FMNE',
        latitude: '-13,18840027',
        longitude: '48,98799896',
        altitude: '72',
        timezone: '3',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Antsirabato Airport',
        city: 'Antalaha',
        country: 'Madagascar',
        iataCode: 'ANM',
        icaoCode: 'FMNH',
        latitude: '-14,99940014',
        longitude: '50,32020187',
        altitude: '20',
        timezone: '3',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Analalava Airport',
        city: 'Analalava',
        country: 'Madagascar',
        iataCode: 'HVA',
        icaoCode: 'FMNL',
        latitude: '-14,62969971',
        longitude: '47,76380157',
        altitude: '345',
        timezone: '3',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Amborovy Airport',
        city: 'Mahajanga',
        country: 'Madagascar',
        iataCode: 'MJN',
        icaoCode: 'FMNM',
        latitude: '-15,66684174',
        longitude: '46,35123253',
        altitude: '87',
        timezone: '3',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Fascene Airport',
        city: 'Nosy-be',
        country: 'Madagascar',
        iataCode: 'NOS',
        icaoCode: 'FMNN',
        latitude: '-13,31210041',
        longitude: '48,31480026',
        altitude: '36',
        timezone: '3',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Besalampy Airport',
        city: 'Besalampy',
        country: 'Madagascar',
        iataCode: 'BPY',
        icaoCode: 'FMNQ',
        latitude: '-16,7445303',
        longitude: '44,48248386',
        altitude: '125',
        timezone: '3',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Maroantsetra Airport',
        city: 'Maroantsetra',
        country: 'Madagascar',
        iataCode: 'WMN',
        icaoCode: 'FMNR',
        latitude: '-15,43669987',
        longitude: '49,68830109',
        altitude: '13',
        timezone: '3',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Sambava Airport',
        city: 'Sambava',
        country: 'Madagascar',
        iataCode: 'SVB',
        icaoCode: 'FMNS',
        latitude: '-14,27859974',
        longitude: '50,17470169',
        altitude: '20',
        timezone: '3',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Vohimarina Airport',
        city: 'Vohemar',
        country: 'Madagascar',
        iataCode: 'VOH',
        icaoCode: 'FMNV',
        latitude: '-13,37580013',
        longitude: '50,00279999',
        altitude: '19',
        timezone: '3',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Ambalabe Airport',
        city: 'Antsohihy',
        country: 'Madagascar',
        iataCode: 'WAI',
        icaoCode: 'FMNW',
        latitude: '-14,8987999',
        longitude: '47,9939003',
        altitude: '92',
        timezone: '3',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Ampampamena Airport',
        city: 'Ampampamena',
        country: 'Madagascar',
        iataCode: 'IVA',
        icaoCode: 'FMNZ',
        latitude: '-13,484816',
        longitude: '48,632702',
        altitude: '49',
        timezone: '3',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Tôlanaro Airport',
        city: 'Tolagnaro',
        country: 'Madagascar',
        iataCode: 'FTU',
        icaoCode: 'FMSD',
        latitude: '-25,03809929',
        longitude: '46,95610046',
        altitude: '29',
        timezone: '3',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Fianarantsoa Airport',
        city: 'Fianarantsoa',
        country: 'Madagascar',
        iataCode: 'WFI',
        icaoCode: 'FMSF',
        latitude: '-21,4416008',
        longitude: '47,11169815',
        altitude: '3658',
        timezone: '3',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Farafangana Airport',
        city: 'Farafangana',
        country: 'Madagascar',
        iataCode: 'RVA',
        icaoCode: 'FMSG',
        latitude: '-22,80529976',
        longitude: '47,8205986',
        altitude: '26',
        timezone: '3',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Manakara Airport',
        city: 'Manakara',
        country: 'Madagascar',
        iataCode: 'WVK',
        icaoCode: 'FMSK',
        latitude: '-22,11969948',
        longitude: '48,02170181',
        altitude: '33',
        timezone: '3',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Mananjary Airport',
        city: 'Mananjary',
        country: 'Madagascar',
        iataCode: 'MNJ',
        icaoCode: 'FMSM',
        latitude: '-21,20179939',
        longitude: '48,35829926',
        altitude: '20',
        timezone: '3',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Morombe Airport',
        city: 'Morombe',
        country: 'Madagascar',
        iataCode: 'MXM',
        icaoCode: 'FMSR',
        latitude: '-21,75390053',
        longitude: '43,37549973',
        altitude: '16',
        timezone: '3',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Toliara Airport',
        city: 'Toliara',
        country: 'Madagascar',
        iataCode: 'TLE',
        icaoCode: 'FMST',
        latitude: '-23,38339996',
        longitude: '43,72850037',
        altitude: '29',
        timezone: '3',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Mbanza Congo Airport',
        city: "M'banza-congo",
        country: 'Angola',
        iataCode: 'SSY',
        icaoCode: 'FNBC',
        latitude: '-6,269899845',
        longitude: '14,24699974',
        altitude: '1860',
        timezone: '1',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Benguela Airport',
        city: 'Benguela',
        country: 'Angola',
        iataCode: 'BUG',
        icaoCode: 'FNBG',
        latitude: '-12,60900021',
        longitude: '13,40369987',
        altitude: '118',
        timezone: '1',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Cabinda Airport',
        city: 'Cabinda',
        country: 'Angola',
        iataCode: 'CAB',
        icaoCode: 'FNCA',
        latitude: '-5,596990108',
        longitude: '12,18840027',
        altitude: '66',
        timezone: '1',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Nova Lisboa Airport',
        city: 'Huambo',
        country: 'Angola',
        iataCode: 'NOV',
        icaoCode: 'FNHU',
        latitude: '-12,80889988',
        longitude: '15,76049995',
        altitude: '5587',
        timezone: '1',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Kuito Airport',
        city: 'Kuito',
        country: 'Angola',
        iataCode: 'SVP',
        icaoCode: 'FNKU',
        latitude: '-12,40460014',
        longitude: '16,94739914',
        altitude: '5618',
        timezone: '1',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Lobito Airport',
        city: 'Lobito',
        country: 'Angola',
        iataCode: 'N',
        icaoCode: 'FNLB',
        latitude: '-12,37119961',
        longitude: '13,53660011',
        altitude: '10',
        timezone: '1',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Quatro de Fevereiro Airport',
        city: 'Luanda',
        country: 'Angola',
        iataCode: 'LAD',
        icaoCode: 'FNLU',
        latitude: '-8,858369827',
        longitude: '13,23120022',
        altitude: '243',
        timezone: '1',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Malanje Airport',
        city: 'Malanje',
        country: 'Angola',
        iataCode: 'MEG',
        icaoCode: 'FNMA',
        latitude: '-9,525090218',
        longitude: '16,31240082',
        altitude: '3868',
        timezone: '1',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Menongue Airport',
        city: 'Menongue',
        country: 'Angola',
        iataCode: 'SPP',
        icaoCode: 'FNME',
        latitude: '-14,6576004',
        longitude: '17,71980095',
        altitude: '4469',
        timezone: '1',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Negage Airport',
        city: 'Negage',
        country: 'Angola',
        iataCode: 'GXG',
        icaoCode: 'FNNG',
        latitude: '-7,754509926',
        longitude: '15,2876997',
        altitude: '4105',
        timezone: '1',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Porto Amboim Airport',
        city: 'Porto Amboim',
        country: 'Angola',
        iataCode: 'PBN',
        icaoCode: 'FNPA',
        latitude: '-10,72200012',
        longitude: '13,76550007',
        altitude: '16',
        timezone: '1',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Saurimo Airport',
        city: 'Saurimo',
        country: 'Angola',
        iataCode: 'VHC',
        icaoCode: 'FNSA',
        latitude: '-9,689069748',
        longitude: '20,43190002',
        altitude: '3584',
        timezone: '1',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Soyo Airport',
        city: 'Soyo',
        country: 'Angola',
        iataCode: 'SZA',
        icaoCode: 'FNSO',
        latitude: '-6,141089916',
        longitude: '12,37180042',
        altitude: '15',
        timezone: '1',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Lubango Airport',
        city: 'Lubango',
        country: 'Angola',
        iataCode: 'SDD',
        icaoCode: 'FNUB',
        latitude: '-14,92469978',
        longitude: '13,57499981',
        altitude: '5778',
        timezone: '1',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Luena Airport',
        city: 'Luena',
        country: 'Angola',
        iataCode: 'LUO',
        icaoCode: 'FNUE',
        latitude: '-11,76809978',
        longitude: '19,89769936',
        altitude: '4360',
        timezone: '1',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Uige Airport',
        city: 'Uige',
        country: 'Angola',
        iataCode: 'UGO',
        icaoCode: 'FNUG',
        latitude: '-7,603069782',
        longitude: '15,02779961',
        altitude: '2720',
        timezone: '1',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Xangongo Airport',
        city: 'Xangongo',
        country: 'Angola',
        iataCode: 'XGN',
        icaoCode: 'FNXA',
        latitude: '-16,7553997',
        longitude: '14,96529961',
        altitude: '3635',
        timezone: '1',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Oyem Airport',
        city: 'Oyem',
        country: 'Gabon',
        iataCode: 'OYE',
        icaoCode: 'FOGO',
        latitude: '1,543110013',
        longitude: '11,58139992',
        altitude: '2158',
        timezone: '1',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Okondja Airport',
        city: 'Okondja',
        country: 'Gabon',
        iataCode: 'OKN',
        icaoCode: 'FOGQ',
        latitude: '-0,665214002',
        longitude: '13,67310047',
        altitude: '1325',
        timezone: '1',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Lambarene Airport',
        city: 'Lambarene',
        country: 'Gabon',
        iataCode: 'LBQ',
        icaoCode: 'FOGR',
        latitude: '-0,704388976',
        longitude: '10,24569988',
        altitude: '82',
        timezone: '1',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Bitam Airport',
        city: 'Bitam',
        country: 'Gabon',
        iataCode: 'BMM',
        icaoCode: 'FOOB',
        latitude: '2,075639963',
        longitude: '11,4932003',
        altitude: '1969',
        timezone: '1',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Port Gentil Airport',
        city: 'Port Gentil',
        country: 'Gabon',
        iataCode: 'POG',
        icaoCode: 'FOOG',
        latitude: '-0,711739004',
        longitude: '8,754380226',
        altitude: '13',
        timezone: '1',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Omboue Hopital Airport',
        city: 'Omboue Hospial',
        country: 'Gabon',
        iataCode: 'OMB',
        icaoCode: 'FOOH',
        latitude: '-1,574730039',
        longitude: '9,26268959',
        altitude: '33',
        timezone: '1',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Makokou Airport',
        city: 'Makokou',
        country: 'Gabon',
        iataCode: 'MKU',
        icaoCode: 'FOOK',
        latitude: '0,579210997',
        longitude: '12,89089966',
        altitude: '1726',
        timezone: '1',
        dst: 'N',
        type: 'airport',
    },
    {
        name: "Libreville Leon M'ba International Airport",
        city: 'Libreville',
        country: 'Gabon',
        iataCode: 'LBV',
        icaoCode: 'FOOL',
        latitude: '0,458600014',
        longitude: '9,412280083',
        altitude: '39',
        timezone: '1',
        dst: 'N',
        type: 'airport',
    },
    {
        name: "M'Vengue El Hadj Omar Bongo Ondimba International Airport",
        city: 'Franceville',
        country: 'Gabon',
        iataCode: 'MVB',
        icaoCode: 'FOON',
        latitude: '-1,656159997',
        longitude: '13,43799973',
        altitude: '1450',
        timezone: '1',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Principe Airport',
        city: 'Principe',
        country: 'Sao Tome and Principe',
        iataCode: 'PCP',
        icaoCode: 'FPPR',
        latitude: '1,662940025',
        longitude: '7,411739826',
        altitude: '591',
        timezone: '0',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'São Tomé International Airport',
        city: 'Sao Tome',
        country: 'Sao Tome and Principe',
        iataCode: 'TMS',
        icaoCode: 'FPST',
        latitude: '0,37817499',
        longitude: '6,712150097',
        altitude: '33',
        timezone: '0',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Beira Airport',
        city: 'Beira',
        country: 'Mozambique',
        iataCode: 'BEW',
        icaoCode: 'FQBR',
        latitude: '-19,79640007',
        longitude: '34,9076004',
        altitude: '33',
        timezone: '2',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Inhambane Airport',
        city: 'Inhambane',
        country: 'Mozambique',
        iataCode: 'INH',
        icaoCode: 'FQIN',
        latitude: '-23,87639999',
        longitude: '35,40850067',
        altitude: '30',
        timezone: '2',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Lichinga Airport',
        city: 'Lichinga',
        country: 'Mozambique',
        iataCode: 'VXC',
        icaoCode: 'FQLC',
        latitude: '-13,27400017',
        longitude: '35,2663002',
        altitude: '4505',
        timezone: '2',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Lumbo Airport',
        city: 'Lumbo',
        country: 'Mozambique',
        iataCode: 'N',
        icaoCode: 'FQLU',
        latitude: '-15,03310013',
        longitude: '40,67169952',
        altitude: '33',
        timezone: '2',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Maputo Airport',
        city: 'Maputo',
        country: 'Mozambique',
        iataCode: 'MPM',
        icaoCode: 'FQMA',
        latitude: '-25,92079926',
        longitude: '32,57260132',
        altitude: '145',
        timezone: '2',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Mueda Airport',
        city: 'Mueda',
        country: 'Mozambique',
        iataCode: 'N',
        icaoCode: 'FQMD',
        latitude: '-11,6729002',
        longitude: '39,56309891',
        altitude: '2789',
        timezone: '2',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Mocímboa da Praia Airport',
        city: 'Mocimboa Da Praia',
        country: 'Mozambique',
        iataCode: 'MZB',
        icaoCode: 'FQMP',
        latitude: '-11,36180019',
        longitude: '40,35490036',
        altitude: '89',
        timezone: '2',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Marrupa Airport',
        city: 'Marrupa',
        country: 'Mozambique',
        iataCode: 'N',
        icaoCode: 'FQMR',
        latitude: '-13,22509956',
        longitude: '37,55210114',
        altitude: '2480',
        timezone: '2',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Nacala Airport',
        city: 'Nacala',
        country: 'Mozambique',
        iataCode: 'MNC',
        icaoCode: 'FQNC',
        latitude: '-14,48820019',
        longitude: '40,71220016',
        altitude: '410',
        timezone: '2',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Nampula Airport',
        city: 'Nampula',
        country: 'Mozambique',
        iataCode: 'APL',
        icaoCode: 'FQNP',
        latitude: '-15,10560036',
        longitude: '39,28179932',
        altitude: '1444',
        timezone: '2',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Pemba Airport',
        city: 'Pemba',
        country: 'Mozambique',
        iataCode: 'POL',
        icaoCode: 'FQPB',
        latitude: '-12,99176216',
        longitude: '40,52401352',
        altitude: '331',
        timezone: '2',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Quelimane Airport',
        city: 'Quelimane',
        country: 'Mozambique',
        iataCode: 'UEL',
        icaoCode: 'FQQL',
        latitude: '-17,85549927',
        longitude: '36,86909866',
        altitude: '36',
        timezone: '2',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Songo Airport',
        city: 'Songo',
        country: 'Mozambique',
        iataCode: 'N',
        icaoCode: 'FQSG',
        latitude: '-15,60270023',
        longitude: '32,77320099',
        altitude: '2904',
        timezone: '2',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Chingozi Airport',
        city: 'Tete',
        country: 'Mozambique',
        iataCode: 'TET',
        icaoCode: 'FQTT',
        latitude: '-16,10479927',
        longitude: '33,64020157',
        altitude: '525',
        timezone: '2',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Ulongwe Airport',
        city: 'Ulongwe',
        country: 'Mozambique',
        iataCode: 'N',
        icaoCode: 'FQUG',
        latitude: '-14,70460033',
        longitude: '34,35240173',
        altitude: '4265',
        timezone: '2',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Vilankulo Airport',
        city: 'Vilankulu',
        country: 'Mozambique',
        iataCode: 'VNX',
        icaoCode: 'FQVL',
        latitude: '-22,01840019',
        longitude: '35,31330109',
        altitude: '46',
        timezone: '2',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Alphonse Airport',
        city: 'Alphonse',
        country: 'Seychelles',
        iataCode: 'N',
        icaoCode: 'FSAL',
        latitude: '-7,00478',
        longitude: '52,7262',
        altitude: '10',
        timezone: '4',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Desroches Airport',
        city: 'Desroches',
        country: 'Seychelles',
        iataCode: 'DES',
        icaoCode: 'FSDR',
        latitude: '-5,6967',
        longitude: '53,6558',
        altitude: '10',
        timezone: '4',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Farquhar Airport',
        city: 'Farquhar',
        country: 'Seychelles',
        iataCode: 'N',
        icaoCode: 'FSFA',
        latitude: '-10,1096',
        longitude: '51,176102',
        altitude: '10',
        timezone: '4',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Seychelles International Airport',
        city: 'Mahe',
        country: 'Seychelles',
        iataCode: 'SEZ',
        icaoCode: 'FSIA',
        latitude: '-4,674339771',
        longitude: '55,52180099',
        altitude: '10',
        timezone: '4',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Praslin Airport',
        city: 'Praslin',
        country: 'Seychelles',
        iataCode: 'PRI',
        icaoCode: 'FSPP',
        latitude: '-4,319290161',
        longitude: '55,69139862',
        altitude: '10',
        timezone: '4',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Coetivy Airport',
        city: 'Coetivy',
        country: 'Seychelles',
        iataCode: 'N',
        icaoCode: 'FSSC',
        latitude: '-7,13457',
        longitude: '56,278198',
        altitude: '10',
        timezone: '4',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Abeche Airport',
        city: 'Abeche',
        country: 'Chad',
        iataCode: 'AEH',
        icaoCode: 'FTTC',
        latitude: '13,84700012',
        longitude: '20,84429932',
        altitude: '1788',
        timezone: '1',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Moundou Airport',
        city: 'Moundou',
        country: 'Chad',
        iataCode: 'MQQ',
        icaoCode: 'FTTD',
        latitude: '8,624409676',
        longitude: '16,07139969',
        altitude: '1407',
        timezone: '1',
        dst: 'N',
        type: 'airport',
    },
    {
        name: "N'Djamena International Airport",
        city: "N'djamena",
        country: 'Chad',
        iataCode: 'NDJ',
        icaoCode: 'FTTJ',
        latitude: '12,13370037',
        longitude: '15,0340004',
        altitude: '968',
        timezone: '1',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Faya Largeau Airport',
        city: 'Faya-largeau',
        country: 'Chad',
        iataCode: 'FYT',
        icaoCode: 'FTTY',
        latitude: '17,91710091',
        longitude: '19,11109924',
        altitude: '771',
        timezone: '1',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Joshua Mqabuko Nkomo International Airport',
        city: 'Bulawayo',
        country: 'Zimbabwe',
        iataCode: 'BUQ',
        icaoCode: 'FVBU',
        latitude: '-20,01740074',
        longitude: '28,61790085',
        altitude: '4359',
        timezone: '2',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Charles Prince Airport',
        city: 'Harare',
        country: 'Zimbabwe',
        iataCode: 'N',
        icaoCode: 'FVCP',
        latitude: '-17,75160027',
        longitude: '30,92469978',
        altitude: '4845',
        timezone: '2',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Buffalo Range Airport',
        city: 'Chiredzi',
        country: 'Zimbabwe',
        iataCode: 'BFO',
        icaoCode: 'FVCZ',
        latitude: '-21,00810051',
        longitude: '31,57859993',
        altitude: '1421',
        timezone: '2',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Victoria Falls International Airport',
        city: 'Victoria Falls',
        country: 'Zimbabwe',
        iataCode: 'VFA',
        icaoCode: 'FVFA',
        latitude: '-18,09589958',
        longitude: '25,8390007',
        altitude: '3490',
        timezone: '2',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Harare International Airport',
        city: 'Harare',
        country: 'Zimbabwe',
        iataCode: 'HRE',
        icaoCode: 'FVHA',
        latitude: '-17,93180084',
        longitude: '31,09280014',
        altitude: '4887',
        timezone: '2',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Kariba International Airport',
        city: 'Kariba',
        country: 'Zimbabwe',
        iataCode: 'KAB',
        icaoCode: 'FVKB',
        latitude: '-16,51980019',
        longitude: '28,88500023',
        altitude: '1706',
        timezone: '2',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Mutoko Airport',
        city: 'Mutoko',
        country: 'Zimbabwe',
        iataCode: 'N',
        icaoCode: 'FVMT',
        latitude: '-17,43190002',
        longitude: '32,18450165',
        altitude: '3950',
        timezone: '2',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Mutare Airport',
        city: 'Mutare',
        country: 'Zimbabwe',
        iataCode: 'N',
        icaoCode: 'FVMU',
        latitude: '-18,99749947',
        longitude: '32,62720108',
        altitude: '3410',
        timezone: '2',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Masvingo International Airport',
        city: 'Masvingo',
        country: 'Zimbabwe',
        iataCode: 'MVZ',
        icaoCode: 'FVMV',
        latitude: '-20,05529976',
        longitude: '30,85910034',
        altitude: '3595',
        timezone: '2',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Zvishavane Airport',
        city: 'Zvishavane',
        country: 'Zimbabwe',
        iataCode: 'N',
        icaoCode: 'FVSH',
        latitude: '-20,28949928',
        longitude: '30,08819962',
        altitude: '3012',
        timezone: '2',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Thornhill Air Base',
        city: 'Gwert',
        country: 'Zimbabwe',
        iataCode: 'GWE',
        icaoCode: 'FVTL',
        latitude: '-19,43639946',
        longitude: '29,86190033',
        altitude: '4680',
        timezone: '2',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Hwange National Park Airport',
        city: 'Hwange National Park',
        country: 'Zimbabwe',
        iataCode: 'WKM',
        icaoCode: 'FVWN',
        latitude: '-18,62989998',
        longitude: '27,02099991',
        altitude: '3543',
        timezone: '2',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Chileka International Airport',
        city: 'Blantyre',
        country: 'Malawi',
        iataCode: 'BLZ',
        icaoCode: 'FWCL',
        latitude: '-15,67910004',
        longitude: '34,97399902',
        altitude: '2555',
        timezone: '2',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Karonga Airport',
        city: 'Karonga',
        country: 'Malawi',
        iataCode: 'KGJ',
        icaoCode: 'FWKA',
        latitude: '-9,953570366',
        longitude: '33,89300156',
        altitude: '1765',
        timezone: '2',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Kasungu Airport',
        city: 'Kasungu',
        country: 'Malawi',
        iataCode: 'N',
        icaoCode: 'FWKG',
        latitude: '-13,0145998',
        longitude: '33,46860123',
        altitude: '3470',
        timezone: '2',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Lilongwe International Airport',
        city: 'Lilongwe',
        country: 'Malawi',
        iataCode: 'LLW',
        icaoCode: 'FWKI',
        latitude: '-13,7894001',
        longitude: '33,78099823',
        altitude: '4035',
        timezone: '2',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Mzuzu Airport',
        city: 'Mzuzu',
        country: 'Malawi',
        iataCode: 'ZZU',
        icaoCode: 'FWUU',
        latitude: '-11,44470024',
        longitude: '34,01179886',
        altitude: '4115',
        timezone: '2',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Moshoeshoe I International Airport',
        city: 'Maseru',
        country: 'Lesotho',
        iataCode: 'MSU',
        icaoCode: 'FXMM',
        latitude: '-29,46229935',
        longitude: '27,55249977',
        altitude: '5348',
        timezone: '2',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Mejametalana Airbase',
        city: 'Maseru',
        country: 'Lesotho',
        iataCode: 'N',
        icaoCode: 'FXMU',
        latitude: '-29,30410004',
        longitude: '27,50349998',
        altitude: '5105',
        timezone: '2',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Ndjili International Airport',
        city: 'Kinshasa',
        country: 'Congo (Kinshasa)',
        iataCode: 'FIH',
        icaoCode: 'FZAA',
        latitude: '-4,385749817',
        longitude: '15,44460011',
        altitude: '1027',
        timezone: '1',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Ndolo Airport',
        city: 'Kinshasa',
        country: 'Congo (Kinshasa)',
        iataCode: 'NLO',
        icaoCode: 'FZAB',
        latitude: '-4,326660156',
        longitude: '15,32750034',
        altitude: '915',
        timezone: '1',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Muanda Airport',
        city: 'Muanda',
        country: 'Congo (Kinshasa)',
        iataCode: 'MNB',
        icaoCode: 'FZAG',
        latitude: '-5,930860043',
        longitude: '12,35179996',
        altitude: '89',
        timezone: '1',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Kitona Base Airport',
        city: 'Kitona Base',
        country: 'Congo (Kinshasa)',
        iataCode: 'N',
        icaoCode: 'FZAI',
        latitude: '-5,918059826',
        longitude: '12,44769955',
        altitude: '394',
        timezone: '1',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Bandundu Airport',
        city: 'Bandoundu',
        country: 'Congo (Kinshasa)',
        iataCode: 'FDU',
        icaoCode: 'FZBO',
        latitude: '-3,311320066',
        longitude: '17,38170052',
        altitude: '1063',
        timezone: '1',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Kikwit Airport',
        city: 'Kikwit',
        country: 'Congo (Kinshasa)',
        iataCode: 'KKW',
        icaoCode: 'FZCA',
        latitude: '-5,035769939',
        longitude: '18,78560066',
        altitude: '1572',
        timezone: '1',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Mbandaka Airport',
        city: 'Mbandaka',
        country: 'Congo (Kinshasa)',
        iataCode: 'MDK',
        icaoCode: 'FZEA',
        latitude: '0,022600001',
        longitude: '18,2887001',
        altitude: '1040',
        timezone: '1',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Gbadolite Airport',
        city: 'Gbadolite',
        country: 'Congo (Kinshasa)',
        iataCode: 'BDT',
        icaoCode: 'FZFD',
        latitude: '4,253210068',
        longitude: '20,97529984',
        altitude: '1509',
        timezone: '1',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Gemena Airport',
        city: 'Gemena',
        country: 'Congo (Kinshasa)',
        iataCode: 'GMA',
        icaoCode: 'FZFK',
        latitude: '3,235369921',
        longitude: '19,77129936',
        altitude: '1378',
        timezone: '1',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Kotakoli Airport',
        city: 'Kotakoli',
        country: 'Congo (Kinshasa)',
        iataCode: 'N',
        icaoCode: 'FZFP',
        latitude: '4,15763998',
        longitude: '21,65089989',
        altitude: '1801',
        timezone: '1',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Lisala Airport',
        city: 'Lisala',
        country: 'Congo (Kinshasa)',
        iataCode: 'LIQ',
        icaoCode: 'FZGA',
        latitude: '2,170660019',
        longitude: '21,49690056',
        altitude: '1509',
        timezone: '1',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Bangoka International Airport',
        city: 'Kisangani',
        country: 'Congo (Kinshasa)',
        iataCode: 'FKI',
        icaoCode: 'FZIC',
        latitude: '0,481638998',
        longitude: '25,33799934',
        altitude: '1417',
        timezone: '2',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Matari Airport',
        city: 'Isiro',
        country: 'Congo (Kinshasa)',
        iataCode: 'IRP',
        icaoCode: 'FZJH',
        latitude: '2,827610016',
        longitude: '27,5883007',
        altitude: '2438',
        timezone: '2',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Bunia Airport',
        city: 'Bunia',
        country: 'Congo (Kinshasa)',
        iataCode: 'BUX',
        icaoCode: 'FZKA',
        latitude: '1,565719962',
        longitude: '30,2208004',
        altitude: '4045',
        timezone: '2',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Buta Zega Airport',
        city: 'Buta Zega',
        country: 'Congo (Kinshasa)',
        iataCode: 'N',
        icaoCode: 'FZKJ',
        latitude: '2,818350077',
        longitude: '24,79369926',
        altitude: '1378',
        timezone: '2',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Bukavu Kavumu Airport',
        city: 'Bukavu/kavumu',
        country: 'Congo (Kinshasa)',
        iataCode: 'BKY',
        icaoCode: 'FZMA',
        latitude: '-2,308979988',
        longitude: '28,80879974',
        altitude: '5643',
        timezone: '2',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Goma International Airport',
        city: 'Goma',
        country: 'Congo (Kinshasa)',
        iataCode: 'GOM',
        icaoCode: 'FZNA',
        latitude: '-1,670809984',
        longitude: '29,2385006',
        altitude: '5089',
        timezone: '2',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Kindu Airport',
        city: 'Kindu',
        country: 'Congo (Kinshasa)',
        iataCode: 'KND',
        icaoCode: 'FZOA',
        latitude: '-2,919179916',
        longitude: '25,91539955',
        altitude: '1630',
        timezone: '2',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Lubumbashi International Airport',
        city: 'Lubumashi',
        country: 'Congo (Kinshasa)',
        iataCode: 'FBM',
        icaoCode: 'FZQA',
        latitude: '-11,59130001',
        longitude: '27,53089905',
        altitude: '4295',
        timezone: '2',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Kolwezi Airport',
        city: 'Kolwezi',
        country: 'Congo (Kinshasa)',
        iataCode: 'KWZ',
        icaoCode: 'FZQM',
        latitude: '-10,76589966',
        longitude: '25,50569916',
        altitude: '5007',
        timezone: '2',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Kalemie Airport',
        city: 'Kalemie',
        country: 'Congo (Kinshasa)',
        iataCode: 'FMI',
        icaoCode: 'FZRF',
        latitude: '-5,875559807',
        longitude: '29,25',
        altitude: '2569',
        timezone: '2',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Kamina Base Airport',
        city: 'Kamina Base',
        country: 'Congo (Kinshasa)',
        iataCode: 'KMN',
        icaoCode: 'FZSA',
        latitude: '-8,642020226',
        longitude: '25,25289917',
        altitude: '3543',
        timezone: '2',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Kananga Airport',
        city: 'Kananga',
        country: 'Congo (Kinshasa)',
        iataCode: 'KGA',
        icaoCode: 'FZUA',
        latitude: '-5,900050163',
        longitude: '22,46920013',
        altitude: '2139',
        timezone: '2',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Mbuji Mayi Airport',
        city: 'Mbuji-mayi',
        country: 'Congo (Kinshasa)',
        iataCode: 'MJM',
        icaoCode: 'FZWA',
        latitude: '-6,121240139',
        longitude: '23,56900024',
        altitude: '2221',
        timezone: '2',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Senou Airport',
        city: 'Bamako',
        country: 'Mali',
        iataCode: 'BKO',
        icaoCode: 'GABS',
        latitude: '12,53349972',
        longitude: '-7,949940205',
        altitude: '1247',
        timezone: '0',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Gao Airport',
        city: 'Gao',
        country: 'Mali',
        iataCode: 'GAQ',
        icaoCode: 'GAGO',
        latitude: '16,24839973',
        longitude: '-0,005456',
        altitude: '870',
        timezone: '0',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Kayes Dag Dag Airport',
        city: 'Kayes',
        country: 'Mali',
        iataCode: 'KYS',
        icaoCode: 'GAKY',
        latitude: '14,48120022',
        longitude: '-11,40439987',
        altitude: '164',
        timezone: '0',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Mopti Airport',
        city: 'Mopti',
        country: 'Mali',
        iataCode: 'MZI',
        icaoCode: 'GAMB',
        latitude: '14,51280022',
        longitude: '-4,079559803',
        altitude: '906',
        timezone: '0',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Timbuktu Airport',
        city: 'Tombouctou',
        country: 'Mali',
        iataCode: 'TOM',
        icaoCode: 'GATB',
        latitude: '16,73049927',
        longitude: '-3,007580042',
        altitude: '863',
        timezone: '0',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Tessalit Airport',
        city: 'Tessalit',
        country: 'Mali',
        iataCode: 'N',
        icaoCode: 'GATS',
        latitude: '20,24300003',
        longitude: '0,977307975',
        altitude: '1621',
        timezone: '0',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Banjul International Airport',
        city: 'Banjul',
        country: 'Gambia',
        iataCode: 'BJL',
        icaoCode: 'GBYD',
        latitude: '13,3380003',
        longitude: '-16,6522007',
        altitude: '95',
        timezone: '0',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Fuerteventura Airport',
        city: 'Fuerteventura',
        country: 'Spain',
        iataCode: 'FUE',
        icaoCode: 'GCFV',
        latitude: '28,45269966',
        longitude: '-13,86380005',
        altitude: '85',
        timezone: '0',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Hierro Airport',
        city: 'Hierro',
        country: 'Spain',
        iataCode: 'VDE',
        icaoCode: 'GCHI',
        latitude: '27,81480026',
        longitude: '-17,88710022',
        altitude: '103',
        timezone: '0',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'La Palma Airport',
        city: 'Santa Cruz De La Palma',
        country: 'Spain',
        iataCode: 'SPC',
        icaoCode: 'GCLA',
        latitude: '28,62649918',
        longitude: '-17,75559998',
        altitude: '107',
        timezone: '0',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Gran Canaria Airport',
        city: 'Gran Canaria',
        country: 'Spain',
        iataCode: 'LPA',
        icaoCode: 'GCLP',
        latitude: '27,93190002',
        longitude: '-15,38659954',
        altitude: '78',
        timezone: '0',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Lanzarote Airport',
        city: 'Arrecife',
        country: 'Spain',
        iataCode: 'ACE',
        icaoCode: 'GCRR',
        latitude: '28,94549942',
        longitude: '-13,60519981',
        altitude: '46',
        timezone: '0',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Tenerife South Airport',
        city: 'Tenerife',
        country: 'Spain',
        iataCode: 'TFS',
        icaoCode: 'GCTS',
        latitude: '28,04450035',
        longitude: '-16,57250023',
        altitude: '209',
        timezone: '0',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Tenerife Norte Airport',
        city: 'Tenerife',
        country: 'Spain',
        iataCode: 'TFN',
        icaoCode: 'GCXO',
        latitude: '28,48270035',
        longitude: '-16,34149933',
        altitude: '2076',
        timezone: '0',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Melilla Airport',
        city: 'Melilla',
        country: 'Spain',
        iataCode: 'MLN',
        icaoCode: 'GEML',
        latitude: '35,27980042',
        longitude: '-2,956259966',
        altitude: '156',
        timezone: '1',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Lungi International Airport',
        city: 'Freetown',
        country: 'Sierra Leone',
        iataCode: 'FNA',
        icaoCode: 'GFLL',
        latitude: '8,616439819',
        longitude: '-13,19550037',
        altitude: '84',
        timezone: '0',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Cufar Airport',
        city: 'Cufar',
        country: 'Guinea-Bissau',
        iataCode: 'N',
        icaoCode: 'GGCF',
        latitude: '11,28789997',
        longitude: '-15,18050003',
        altitude: '65',
        timezone: '0',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Spriggs Payne Airport',
        city: 'Monrovia',
        country: 'Liberia',
        iataCode: 'MLW',
        icaoCode: 'GLMR',
        latitude: '6,289060116',
        longitude: '-10,75870037',
        altitude: '25',
        timezone: '0',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Roberts International Airport',
        city: 'Monrovia',
        country: 'Liberia',
        iataCode: 'ROB',
        icaoCode: 'GLRB',
        latitude: '6,233789921',
        longitude: '-10,36229992',
        altitude: '31',
        timezone: '0',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Al Massira Airport',
        city: 'Agadir',
        country: 'Morocco',
        iataCode: 'AGA',
        icaoCode: 'GMAD',
        latitude: '30,32500076',
        longitude: '-9,413069725',
        altitude: '250',
        timezone: '0',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Tan Tan Airport',
        city: 'Tan Tan',
        country: 'Morocco',
        iataCode: 'TTA',
        icaoCode: 'GMAT',
        latitude: '28,44820023',
        longitude: '-11,16129971',
        altitude: '653',
        timezone: '0',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Saïss Airport',
        city: 'Fes',
        country: 'Morocco',
        iataCode: 'FEZ',
        icaoCode: 'GMFF',
        latitude: '33,9272995',
        longitude: '-4,97796011',
        altitude: '1900',
        timezone: '0',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Ifrane Airport',
        city: 'Ifrane',
        country: 'Morocco',
        iataCode: 'N',
        icaoCode: 'GMFI',
        latitude: '33,50529861',
        longitude: '-5,152900219',
        altitude: '5459',
        timezone: '0',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Moulay Ali Cherif Airport',
        city: 'Er-rachidia',
        country: 'Morocco',
        iataCode: 'ERH',
        icaoCode: 'GMFK',
        latitude: '31,94750023',
        longitude: '-4,398330212',
        altitude: '3428',
        timezone: '0',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Bassatine Airport',
        city: 'Meknes',
        country: 'Morocco',
        iataCode: 'MEK',
        icaoCode: 'GMFM',
        latitude: '33,8791008',
        longitude: '-5,515120029',
        altitude: '1890',
        timezone: '0',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Angads Airport',
        city: 'Oujda',
        country: 'Morocco',
        iataCode: 'OUD',
        icaoCode: 'GMFO',
        latitude: '34,78720093',
        longitude: '-1,923990011',
        altitude: '1535',
        timezone: '0',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Ben Slimane Airport',
        city: 'Ben Slimane',
        country: 'Morocco',
        iataCode: 'N',
        icaoCode: 'GMMB',
        latitude: '33,65539932',
        longitude: '-7,221449852',
        altitude: '627',
        timezone: '0',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Rabat-Salé Airport',
        city: 'Rabat',
        country: 'Morocco',
        iataCode: 'RBA',
        icaoCode: 'GMME',
        latitude: '34,05149841',
        longitude: '-6,751520157',
        altitude: '276',
        timezone: '0',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Mohammed V International Airport',
        city: 'Casablanca',
        country: 'Morocco',
        iataCode: 'CMN',
        icaoCode: 'GMMN',
        latitude: '33,36750031',
        longitude: '-7,589970112',
        altitude: '656',
        timezone: '0',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Menara Airport',
        city: 'Marrakech',
        country: 'Morocco',
        iataCode: 'RAK',
        icaoCode: 'GMMX',
        latitude: '31,60689926',
        longitude: '-8,036299706',
        altitude: '1545',
        timezone: '0',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Kenitra Airport',
        city: 'Kentira',
        country: 'Morocco',
        iataCode: 'NNA',
        icaoCode: 'GMMY',
        latitude: '34,2989006',
        longitude: '-6,595880032',
        altitude: '16',
        timezone: '0',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Ouarzazate Airport',
        city: 'Ouarzazate',
        country: 'Morocco',
        iataCode: 'OZZ',
        icaoCode: 'GMMZ',
        latitude: '30,93910027',
        longitude: '-6,909430027',
        altitude: '3782',
        timezone: '0',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Cherif Al Idrissi Airport',
        city: 'Al Hociema',
        country: 'Morocco',
        iataCode: 'AHU',
        icaoCode: 'GMTA',
        latitude: '35,17710114',
        longitude: '-3,839519978',
        altitude: '95',
        timezone: '0',
        dst: 'N',
        type: 'airport',
    },
    {
        name: "Saniat R'mel Airport",
        city: 'Tetouan',
        country: 'Morocco',
        iataCode: 'TTU',
        icaoCode: 'GMTN',
        latitude: '35,59429932',
        longitude: '-5,320020199',
        altitude: '10',
        timezone: '0',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Ibn Batouta Airport',
        city: 'Tanger',
        country: 'Morocco',
        iataCode: 'TNG',
        icaoCode: 'GMTT',
        latitude: '35,72689819',
        longitude: '-5,916890144',
        altitude: '62',
        timezone: '0',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Ziguinchor Airport',
        city: 'Ziguinchor',
        country: 'Senegal',
        iataCode: 'ZIG',
        icaoCode: 'GOGG',
        latitude: '12,5556',
        longitude: '-16,281799',
        altitude: '75',
        timezone: '0',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Cap Skirring Airport',
        city: 'Cap Skiring',
        country: 'Senegal',
        iataCode: 'CSK',
        icaoCode: 'GOGS',
        latitude: '12,41020012',
        longitude: '-16,74609947',
        altitude: '52',
        timezone: '0',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Kaolack Airport',
        city: 'Kaolack',
        country: 'Senegal',
        iataCode: 'KLC',
        icaoCode: 'GOOK',
        latitude: '14,14690018',
        longitude: '-16,05130005',
        altitude: '26',
        timezone: '0',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Léopold Sédar Senghor International Airport',
        city: 'Dakar',
        country: 'Senegal',
        iataCode: 'DKR',
        icaoCode: 'GOOY',
        latitude: '14,73970032',
        longitude: '-17,49020004',
        altitude: '85',
        timezone: '0',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Saint Louis Airport',
        city: 'St. Louis',
        country: 'Senegal',
        iataCode: 'XLS',
        icaoCode: 'GOSS',
        latitude: '16,05080032',
        longitude: '-16,46319962',
        altitude: '9',
        timezone: '0',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Bakel Airport',
        city: 'Bakel',
        country: 'Senegal',
        iataCode: 'BXE',
        icaoCode: 'GOTB',
        latitude: '14,84729958',
        longitude: '-12,46829987',
        altitude: '98',
        timezone: '0',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Kédougou Airport',
        city: 'Kedougou',
        country: 'Senegal',
        iataCode: 'KGG',
        icaoCode: 'GOTK',
        latitude: '12,57229996',
        longitude: '-12,22029972',
        altitude: '584',
        timezone: '0',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Tambacounda Airport',
        city: 'Tambacounda',
        country: 'Senegal',
        iataCode: 'TUD',
        icaoCode: 'GOTT',
        latitude: '13,73680019',
        longitude: '-13,65310001',
        altitude: '161',
        timezone: '0',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Aioun el Atrouss Airport',
        city: 'Aioun El Atrouss',
        country: 'Mauritania',
        iataCode: 'IEO',
        icaoCode: 'GQNA',
        latitude: '16,7112999',
        longitude: '-9,637880325',
        altitude: '951',
        timezone: '0',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Tidjikja Airport',
        city: 'Tidjikja',
        country: 'Mauritania',
        iataCode: 'TIY',
        icaoCode: 'GQND',
        latitude: '18,57010078',
        longitude: '-11,42350006',
        altitude: '1363',
        timezone: '0',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Kiffa Airport',
        city: 'Kiffa',
        country: 'Mauritania',
        iataCode: 'KFA',
        icaoCode: 'GQNF',
        latitude: '16,59000015',
        longitude: '-11,40620041',
        altitude: '424',
        timezone: '0',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Néma Airport',
        city: 'Nema',
        country: 'Mauritania',
        iataCode: 'EMN',
        icaoCode: 'GQNI',
        latitude: '16,62199974',
        longitude: '-7,316599846',
        altitude: '751',
        timezone: '0',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Kaédi Airport',
        city: 'Kaedi',
        country: 'Mauritania',
        iataCode: 'KED',
        icaoCode: 'GQNK',
        latitude: '16,15950012',
        longitude: '-13,50759983',
        altitude: '66',
        timezone: '0',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Nouakchott International Airport',
        city: 'Nouakschott',
        country: 'Mauritania',
        iataCode: 'NKC',
        icaoCode: 'GQNN',
        latitude: '18,09819984',
        longitude: '-15,94849968',
        altitude: '13',
        timezone: '0',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Sélibaby Airport',
        city: 'Selibabi',
        country: 'Mauritania',
        iataCode: 'SEY',
        icaoCode: 'GQNS',
        latitude: '15,1796999',
        longitude: '-12,20730019',
        altitude: '219',
        timezone: '0',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Atar International Airport',
        city: 'Atar',
        country: 'Mauritania',
        iataCode: 'ATR',
        icaoCode: 'GQPA',
        latitude: '20,5067997',
        longitude: '-13,04319954',
        altitude: '734',
        timezone: '0',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Nouadhibou International Airport',
        city: 'Nouadhibou',
        country: 'Mauritania',
        iataCode: 'NDB',
        icaoCode: 'GQPP',
        latitude: '20,93309975',
        longitude: '-17,03000069',
        altitude: '24',
        timezone: '0',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Bir Moghrein Airport',
        city: 'Bir Moghrein',
        country: 'Mauritania',
        iataCode: 'N',
        icaoCode: 'GQPT',
        latitude: '25,23670006',
        longitude: '-11,58870029',
        altitude: '1206',
        timezone: '0',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Fria Airport',
        city: 'Fira',
        country: 'Guinea',
        iataCode: 'FIG',
        icaoCode: 'GUFA',
        latitude: '10,35060024',
        longitude: '-13,56919956',
        altitude: '499',
        timezone: '0',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Faranah Airport',
        city: 'Faranah',
        country: 'Guinea',
        iataCode: 'FAA',
        icaoCode: 'GUFH',
        latitude: '10,03549957',
        longitude: '-10,76980019',
        altitude: '1476',
        timezone: '0',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Tata Airport',
        city: 'Labe',
        country: 'Guinea',
        iataCode: 'LEK',
        icaoCode: 'GULB',
        latitude: '11,32610035',
        longitude: '-12,28680038',
        altitude: '3396',
        timezone: '0',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Amílcar Cabral International Airport',
        city: 'Amilcar Cabral',
        country: 'Cape Verde',
        iataCode: 'SID',
        icaoCode: 'GVAC',
        latitude: '16,74139977',
        longitude: '-22,94939995',
        altitude: '177',
        timezone: '-1',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Rabil Airport',
        city: 'Boa Vista',
        country: 'Cape Verde',
        iataCode: 'BVC',
        icaoCode: 'GVBA',
        latitude: '16,1364994',
        longitude: '-22,88890076',
        altitude: '69',
        timezone: '-1',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Maio Airport',
        city: 'Maio',
        country: 'Cape Verde',
        iataCode: 'MMO',
        icaoCode: 'GVMA',
        latitude: '15,1559',
        longitude: '-23,21369934',
        altitude: '36',
        timezone: '-1',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Preguiça Airport',
        city: 'Sao Nocolau Island',
        country: 'Cape Verde',
        iataCode: 'SNE',
        icaoCode: 'GVSN',
        latitude: '16,58839989',
        longitude: '-24,28470039',
        altitude: '669',
        timezone: '-1',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'São Pedro Airport',
        city: 'Sao Vicente Island',
        country: 'Cape Verde',
        iataCode: 'VXE',
        icaoCode: 'GVSV',
        latitude: '16,83320045',
        longitude: '-25,05529976',
        altitude: '66',
        timezone: '-1',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Addis Ababa Bole International Airport',
        city: 'Addis Ababa',
        country: 'Ethiopia',
        iataCode: 'ADD',
        icaoCode: 'HAAB',
        latitude: '8,977890015',
        longitude: '38,79930115',
        altitude: '7630',
        timezone: '3',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Lideta Army Airport',
        city: 'Addis Ababa',
        country: 'Ethiopia',
        iataCode: 'N',
        icaoCode: 'HAAL',
        latitude: '9,003685',
        longitude: '38,725498',
        altitude: '7749',
        timezone: '3',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Arba Minch Airport',
        city: 'Arba Minch',
        country: 'Ethiopia',
        iataCode: 'AMH',
        icaoCode: 'HAAM',
        latitude: '6,039390087',
        longitude: '37,59049988',
        altitude: '3901',
        timezone: '3',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Axum Airport',
        city: 'Axum',
        country: 'Ethiopia',
        iataCode: 'AXU',
        icaoCode: 'HAAX',
        latitude: '14,14680004',
        longitude: '38,77280045',
        altitude: '6959',
        timezone: '3',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Bahir Dar Airport',
        city: 'Bahar Dar',
        country: 'Ethiopia',
        iataCode: 'BJR',
        icaoCode: 'HABD',
        latitude: '11,60809994',
        longitude: '37,32160187',
        altitude: '5978',
        timezone: '3',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Aba Tenna Dejazmach Yilma International Airport',
        city: 'Dire Dawa',
        country: 'Ethiopia',
        iataCode: 'DIR',
        icaoCode: 'HADR',
        latitude: '9,624699593',
        longitude: '41,85419846',
        altitude: '3827',
        timezone: '3',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Gambella Airport',
        city: 'Gambella',
        country: 'Ethiopia',
        iataCode: 'GMB',
        icaoCode: 'HAGM',
        latitude: '8,128760338',
        longitude: '34,56309891',
        altitude: '1614',
        timezone: '3',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Gonder Airport',
        city: 'Gondar',
        country: 'Ethiopia',
        iataCode: 'GDQ',
        icaoCode: 'HAGN',
        latitude: '12,51990032',
        longitude: '37,43399811',
        altitude: '6449',
        timezone: '3',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Jimma Airport',
        city: 'Jimma',
        country: 'Ethiopia',
        iataCode: 'JIM',
        icaoCode: 'HAJM',
        latitude: '7,666090012',
        longitude: '36,8166008',
        altitude: '5500',
        timezone: '3',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Lalibella Airport',
        city: 'Lalibella',
        country: 'Ethiopia',
        iataCode: 'LLI',
        icaoCode: 'HALL',
        latitude: '11,97500038',
        longitude: '38,97999954',
        altitude: '6506',
        timezone: '3',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Mekele Airport',
        city: 'Makale',
        country: 'Ethiopia',
        iataCode: 'MQX',
        icaoCode: 'HAMK',
        latitude: '13,4673996',
        longitude: '39,53350067',
        altitude: '7396',
        timezone: '3',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Asosa Airport',
        city: 'Asosa',
        country: 'Ethiopia',
        iataCode: 'ASO',
        icaoCode: 'HASO',
        latitude: '10,01850033',
        longitude: '34,5862999',
        altitude: '5100',
        timezone: '3',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Bujumbura International Airport',
        city: 'Bujumbura',
        country: 'Burundi',
        iataCode: 'BJM',
        icaoCode: 'HBBA',
        latitude: '-3,324019909',
        longitude: '29,31850052',
        altitude: '2582',
        timezone: '2',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Egal International Airport',
        city: 'Hargeisa',
        country: 'Somalia',
        iataCode: 'HGA',
        icaoCode: 'HCMH',
        latitude: '9,518170357',
        longitude: '44,08879852',
        altitude: '4423',
        timezone: '3',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Berbera Airport',
        city: 'Berbera',
        country: 'Somalia',
        iataCode: 'BBO',
        icaoCode: 'HCMI',
        latitude: '10,38920021',
        longitude: '44,94110107',
        altitude: '30',
        timezone: '3',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Kisimayu Airport',
        city: 'Kismayu',
        country: 'Somalia',
        iataCode: 'KMU',
        icaoCode: 'HCMK',
        latitude: '-0,377353013',
        longitude: '42,45920181',
        altitude: '49',
        timezone: '3',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'El Nouzha Airport',
        city: 'Alexandria',
        country: 'Egypt',
        iataCode: 'ALY',
        icaoCode: 'HEAX',
        latitude: '31,18390083',
        longitude: '29,94890022',
        altitude: '-6',
        timezone: '2',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Abu Simbel Airport',
        city: 'Abu Simbel',
        country: 'Egypt',
        iataCode: 'ABS',
        icaoCode: 'HEBL',
        latitude: '22,37599945',
        longitude: '31,61170006',
        altitude: '616',
        timezone: '2',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Cairo International Airport',
        city: 'Cairo',
        country: 'Egypt',
        iataCode: 'CAI',
        icaoCode: 'HECA',
        latitude: '30,12190056',
        longitude: '31,40559959',
        altitude: '382',
        timezone: '2',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Cairo West Airport',
        city: 'Cairo',
        country: 'Egypt',
        iataCode: 'N',
        icaoCode: 'HECW',
        latitude: '30,11639977',
        longitude: '30,91539955',
        altitude: '550',
        timezone: '2',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Hurghada International Airport',
        city: 'Hurghada',
        country: 'Egypt',
        iataCode: 'HRG',
        icaoCode: 'HEGN',
        latitude: '27,17830086',
        longitude: '33,79940033',
        altitude: '52',
        timezone: '2',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'El Gora Airport',
        city: 'El Gorah',
        country: 'Egypt',
        iataCode: 'EGR',
        icaoCode: 'HEGR',
        latitude: '31,068559',
        longitude: '34,129629',
        altitude: '324',
        timezone: '2',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Luxor International Airport',
        city: 'Luxor',
        country: 'Egypt',
        iataCode: 'LXR',
        icaoCode: 'HELX',
        latitude: '25,67099953',
        longitude: '32,70660019',
        altitude: '294',
        timezone: '2',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Mersa Matruh Airport',
        city: 'Mersa-matruh',
        country: 'Egypt',
        iataCode: 'MUH',
        icaoCode: 'HEMM',
        latitude: '31,3253994',
        longitude: '27,22170067',
        altitude: '94',
        timezone: '2',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Port Said Airport',
        city: 'Port Said',
        country: 'Egypt',
        iataCode: 'PSD',
        icaoCode: 'HEPS',
        latitude: '31,27939987',
        longitude: '32,24000168',
        altitude: '8',
        timezone: '2',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'St Catherine International Airport',
        city: 'St. Catherine',
        country: 'Egypt',
        iataCode: 'SKV',
        icaoCode: 'HESC',
        latitude: '28,68530083',
        longitude: '34,0625',
        altitude: '4368',
        timezone: '2',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Aswan International Airport',
        city: 'Aswan',
        country: 'Egypt',
        iataCode: 'ASW',
        icaoCode: 'HESN',
        latitude: '23,96439934',
        longitude: '32,81999969',
        altitude: '662',
        timezone: '2',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'El Tor Airport',
        city: 'El-tor',
        country: 'Egypt',
        iataCode: 'ELT',
        icaoCode: 'HETR',
        latitude: '28,20899963',
        longitude: '33,64550018',
        altitude: '115',
        timezone: '2',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Eldoret International Airport',
        city: 'Eldoret',
        country: 'Kenya',
        iataCode: 'EDL',
        icaoCode: 'HKEL',
        latitude: '0,404457986',
        longitude: '35,23889923',
        altitude: '6941',
        timezone: '3',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Kakamega Airport',
        city: 'Kakamega',
        country: 'Kenya',
        iataCode: 'N',
        icaoCode: 'HKKG',
        latitude: '0,271342009',
        longitude: '34,78730011',
        altitude: '5020',
        timezone: '3',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Kisumu Airport',
        city: 'Kisumu',
        country: 'Kenya',
        iataCode: 'KIS',
        icaoCode: 'HKKI',
        latitude: '-0,086139001',
        longitude: '34,72890091',
        altitude: '3734',
        timezone: '3',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Kitale Airport',
        city: 'Kitale',
        country: 'Kenya',
        iataCode: 'KTL',
        icaoCode: 'HKKT',
        latitude: '0,971988976',
        longitude: '34,95859909',
        altitude: '6070',
        timezone: '3',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Lodwar Airport',
        city: 'Lodwar',
        country: 'Kenya',
        iataCode: 'LOK',
        icaoCode: 'HKLO',
        latitude: '3,121969938',
        longitude: '35,6086998',
        altitude: '1715',
        timezone: '3',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Manda Airstrip',
        city: 'Lamu',
        country: 'Kenya',
        iataCode: 'LAU',
        icaoCode: 'HKLU',
        latitude: '-2,252419949',
        longitude: '40,9131012',
        altitude: '20',
        timezone: '3',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Mombasa Moi International Airport',
        city: 'Mombasa',
        country: 'Kenya',
        iataCode: 'MBA',
        icaoCode: 'HKMO',
        latitude: '-4,034830093',
        longitude: '39,59420013',
        altitude: '200',
        timezone: '3',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Naivasha Airport',
        city: 'Naivasha',
        country: 'Kenya',
        iataCode: 'N',
        icaoCode: 'HKNV',
        latitude: '-0,787953019',
        longitude: '36,43349838',
        altitude: '6380',
        timezone: '3',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Nairobi Wilson Airport',
        city: 'Nairobi',
        country: 'Kenya',
        iataCode: 'WIL',
        icaoCode: 'HKNW',
        latitude: '-1,321720004',
        longitude: '36,81480026',
        altitude: '5536',
        timezone: '3',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Moi Air Base',
        city: 'Nairobi',
        country: 'Kenya',
        iataCode: 'N',
        icaoCode: 'HKRE',
        latitude: '-1,277269959',
        longitude: '36,86230087',
        altitude: '5336',
        timezone: '3',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Wajir Airport',
        city: 'Wajir',
        country: 'Kenya',
        iataCode: 'WJR',
        icaoCode: 'HKWJ',
        latitude: '1,733240008',
        longitude: '40,09159851',
        altitude: '770',
        timezone: '3',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Bu Attifel Airport',
        city: 'Buattifel',
        country: 'Libya',
        iataCode: 'N',
        icaoCode: 'HLFL',
        latitude: '28,79540062',
        longitude: '22,08090019',
        altitude: '161',
        timezone: '2',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Warehouse 59e Airport',
        city: 'Giallo',
        country: 'Libya',
        iataCode: 'N',
        icaoCode: 'HLGL',
        latitude: '28,63850021',
        longitude: '21,43799973',
        altitude: '325',
        timezone: '2',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Ghat Airport',
        city: 'Ghat',
        country: 'Libya',
        iataCode: 'GHT',
        icaoCode: 'HLGT',
        latitude: '25,14559937',
        longitude: '10,14260006',
        altitude: '2296',
        timezone: '2',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Kufra Airport',
        city: 'Kufra',
        country: 'Libya',
        iataCode: 'AKF',
        icaoCode: 'HLKF',
        latitude: '24,17869949',
        longitude: '23,31399918',
        altitude: '1367',
        timezone: '2',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Benina International Airport',
        city: 'Benghazi',
        country: 'Libya',
        iataCode: 'BEN',
        icaoCode: 'HLLB',
        latitude: '32,09680176',
        longitude: '20,26950073',
        altitude: '433',
        timezone: '2',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Sabha Airport',
        city: 'Sebha',
        country: 'Libya',
        iataCode: 'SEB',
        icaoCode: 'HLLS',
        latitude: '26,98699951',
        longitude: '14,47249985',
        altitude: '1427',
        timezone: '2',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Tripoli International Airport',
        city: 'Tripoli',
        country: 'Libya',
        iataCode: 'TIP',
        icaoCode: 'HLLT',
        latitude: '32,66350174',
        longitude: '13,1590004',
        altitude: '263',
        timezone: '2',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Marsa Brega Airport',
        city: 'Marsa Brega',
        country: 'Libya',
        iataCode: 'N',
        icaoCode: 'HLMB',
        latitude: '30,37809944',
        longitude: '19,57640076',
        altitude: '50',
        timezone: '2',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Ras Lanuf Oil Airport',
        city: 'Ras Lanouf V 40',
        country: 'Libya',
        iataCode: 'N',
        icaoCode: 'HLNF',
        latitude: '30,5',
        longitude: '18,5272007',
        altitude: '42',
        timezone: '2',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Hon Airport',
        city: 'Hon',
        country: 'Libya',
        iataCode: 'N',
        icaoCode: 'HLON',
        latitude: '29,11009979',
        longitude: '15,96560001',
        altitude: '919',
        timezone: '2',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Dahra Airport',
        city: 'Dahra',
        country: 'Libya',
        iataCode: 'N',
        icaoCode: 'HLRA',
        latitude: '29,47260094',
        longitude: '17,93490028',
        altitude: '1050',
        timezone: '2',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Ghadames East Airport',
        city: 'Ghadames',
        country: 'Libya',
        iataCode: 'LTD',
        icaoCode: 'HLTD',
        latitude: '30,15169907',
        longitude: '9,715310097',
        altitude: '1122',
        timezone: '2',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Zella 74 Airport',
        city: 'Zella 74',
        country: 'Libya',
        iataCode: 'N',
        icaoCode: 'HLZA',
        latitude: '28,58989906',
        longitude: '17,29389954',
        altitude: '1085',
        timezone: '2',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Gisenyi Airport',
        city: 'Gisenyi',
        country: 'Rwanda',
        iataCode: 'GYI',
        icaoCode: 'HRYG',
        latitude: '-1,67719996',
        longitude: '29,25889969',
        altitude: '5082',
        timezone: '2',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Kigali International Airport',
        city: 'Kigali',
        country: 'Rwanda',
        iataCode: 'KGL',
        icaoCode: 'HRYR',
        latitude: '-1,968629956',
        longitude: '30,13949966',
        altitude: '4859',
        timezone: '2',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Kamembe Airport',
        city: 'Kamembe',
        country: 'Rwanda',
        iataCode: 'KME',
        icaoCode: 'HRZA',
        latitude: '-2,462239981',
        longitude: '28,90789986',
        altitude: '5192',
        timezone: '2',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Dongola Airport',
        city: 'Dongola',
        country: 'Sudan',
        iataCode: 'DOG',
        icaoCode: 'HSDN',
        latitude: '19,15390015',
        longitude: '30,43009949',
        altitude: '772',
        timezone: '3',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Damazin Airport',
        city: 'Damazin',
        country: 'Sudan',
        iataCode: 'N',
        icaoCode: 'HSDZ',
        latitude: '11,78590012',
        longitude: '34,33670044',
        altitude: '1582',
        timezone: '3',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'El Fasher Airport',
        city: 'El Fasher',
        country: 'Sudan',
        iataCode: 'ELF',
        icaoCode: 'HSFS',
        latitude: '13,61489964',
        longitude: '25,32460022',
        altitude: '2393',
        timezone: '3',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Kassala Airport',
        city: 'Kassala',
        country: 'Sudan',
        iataCode: 'KSL',
        icaoCode: 'HSKA',
        latitude: '15,38749981',
        longitude: '36,3288002',
        altitude: '1671',
        timezone: '3',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Kadugli Airport',
        city: 'Kadugli',
        country: 'Sudan',
        iataCode: 'N',
        icaoCode: 'HSLI',
        latitude: '11,13799953',
        longitude: '29,7010994',
        altitude: '1848',
        timezone: '3',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'El Obeid Airport',
        city: 'El Obeid',
        country: 'Sudan',
        iataCode: 'EBD',
        icaoCode: 'HSOB',
        latitude: '13,15320015',
        longitude: '30,23270035',
        altitude: '1927',
        timezone: '3',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Juba International Airport',
        city: 'Juba',
        country: 'South Sudan',
        iataCode: 'JUB',
        icaoCode: 'HSSJ',
        latitude: '4,872010231',
        longitude: '31,60110092',
        altitude: '1513',
        timezone: '3',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Malakal Airport',
        city: 'Malakal',
        country: 'Sudan',
        iataCode: 'MAK',
        icaoCode: 'HSSM',
        latitude: '9,558970451',
        longitude: '31,6522007',
        altitude: '1291',
        timezone: '3',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Khartoum International Airport',
        city: 'Khartoum',
        country: 'Sudan',
        iataCode: 'KRT',
        icaoCode: 'HSSS',
        latitude: '15,58950043',
        longitude: '32,55319977',
        altitude: '1265',
        timezone: '3',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Arusha Airport',
        city: 'Arusha',
        country: 'Tanzania',
        iataCode: 'ARK',
        icaoCode: 'HTAR',
        latitude: '-3,367789984',
        longitude: '36,63330078',
        altitude: '4550',
        timezone: '3',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Julius Nyerere International Airport',
        city: 'Dar Es Salaam',
        country: 'Tanzania',
        iataCode: 'DAR',
        icaoCode: 'HTDA',
        latitude: '-6,878109932',
        longitude: '39,20259857',
        altitude: '182',
        timezone: '3',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Dodoma Airport',
        city: 'Dodoma',
        country: 'Tanzania',
        iataCode: 'DOD',
        icaoCode: 'HTDO',
        latitude: '-6,170440197',
        longitude: '35,75260162',
        altitude: '3673',
        timezone: '3',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Iringa Airport',
        city: 'Iringa',
        country: 'Tanzania',
        iataCode: 'IRI',
        icaoCode: 'HTIR',
        latitude: '-7,668630123',
        longitude: '35,7521019',
        altitude: '4678',
        timezone: '3',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Kilimanjaro International Airport',
        city: 'Kilimanjaro',
        country: 'Tanzania',
        iataCode: 'JRO',
        icaoCode: 'HTKJ',
        latitude: '-3,429409981',
        longitude: '37,07450104',
        altitude: '2932',
        timezone: '3',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Lake Manyara Airport',
        city: 'Lake Manyara',
        country: 'Tanzania',
        iataCode: 'LKY',
        icaoCode: 'HTLM',
        latitude: '-3,37631011',
        longitude: '35,81829834',
        altitude: '4150',
        timezone: '3',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Mtwara Airport',
        city: 'Mtwara',
        country: 'Tanzania',
        iataCode: 'MYW',
        icaoCode: 'HTMT',
        latitude: '-10,33909988',
        longitude: '40,18180084',
        altitude: '371',
        timezone: '3',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Mwanza Airport',
        city: 'Mwanza',
        country: 'Tanzania',
        iataCode: 'MWZ',
        icaoCode: 'HTMW',
        latitude: '-2,444489956',
        longitude: '32,93270111',
        altitude: '3763',
        timezone: '3',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Pemba Airport',
        city: 'Pemba',
        country: 'Tanzania',
        iataCode: 'PMA',
        icaoCode: 'HTPE',
        latitude: '-5,257259846',
        longitude: '39,81140137',
        altitude: '80',
        timezone: '3',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Tanga Airport',
        city: 'Tanga',
        country: 'Tanzania',
        iataCode: 'TGT',
        icaoCode: 'HTTG',
        latitude: '-5,09236002',
        longitude: '39,07120132',
        altitude: '129',
        timezone: '3',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Abeid Amani Karume International Airport',
        city: 'Zanzibar',
        country: 'Tanzania',
        iataCode: 'ZNZ',
        icaoCode: 'HTZA',
        latitude: '-6,222020149',
        longitude: '39,22489929',
        altitude: '54',
        timezone: '3',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Entebbe International Airport',
        city: 'Entebbe',
        country: 'Uganda',
        iataCode: 'EBB',
        icaoCode: 'HUEN',
        latitude: '0,042385999',
        longitude: '32,44350052',
        altitude: '3782',
        timezone: '3',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Soroti Airport',
        city: 'Soroti',
        country: 'Uganda',
        iataCode: 'SRT',
        icaoCode: 'HUSO',
        latitude: '1,727689981',
        longitude: '33,62279892',
        altitude: '3697',
        timezone: '3',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Tirana International Airport Mother Teresa',
        city: 'Tirana',
        country: 'Albania',
        iataCode: 'TIA',
        icaoCode: 'LATI',
        latitude: '41,41469955',
        longitude: '19,72060013',
        altitude: '126',
        timezone: '1',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Burgas Airport',
        city: 'Bourgas',
        country: 'Bulgaria',
        iataCode: 'BOJ',
        icaoCode: 'LBBG',
        latitude: '42,56959915',
        longitude: '27,51519966',
        altitude: '135',
        timezone: '2',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Gorna Oryahovitsa Airport',
        city: 'Gorna Orechovica',
        country: 'Bulgaria',
        iataCode: 'GOZ',
        icaoCode: 'LBGO',
        latitude: '43,15140152',
        longitude: '25,71290016',
        altitude: '285',
        timezone: '2',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Plovdiv International Airport',
        city: 'Plovdiv',
        country: 'Bulgaria',
        iataCode: 'PDV',
        icaoCode: 'LBPD',
        latitude: '42,067799',
        longitude: '24,8508',
        altitude: '597',
        timezone: '2',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Sofia Airport',
        city: 'Sofia',
        country: 'Bulgaria',
        iataCode: 'SOF',
        icaoCode: 'LBSF',
        latitude: '42,69669342',
        longitude: '23,41143608',
        altitude: '1742',
        timezone: '2',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Stara Zagora Airport',
        city: 'Stara Zagora',
        country: 'Bulgaria',
        iataCode: 'N',
        icaoCode: 'LBSZ',
        latitude: '42,3766667',
        longitude: '25,655',
        altitude: '558',
        timezone: '2',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Varna Airport',
        city: 'Varna',
        country: 'Bulgaria',
        iataCode: 'VAR',
        icaoCode: 'LBWN',
        latitude: '43,232101',
        longitude: '27,8251',
        altitude: '230',
        timezone: '2',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Larnaca International Airport',
        city: 'Larnaca',
        country: 'Cyprus',
        iataCode: 'LCA',
        icaoCode: 'LCLK',
        latitude: '34,87509918',
        longitude: '33,62490082',
        altitude: '8',
        timezone: '2',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Paphos International Airport',
        city: 'Paphos',
        country: 'Cyprus',
        iataCode: 'PFO',
        icaoCode: 'LCPH',
        latitude: '34,7179985',
        longitude: '32,4856987',
        altitude: '41',
        timezone: '2',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'RAF Akrotiri',
        city: 'Akrotiri',
        country: 'Cyprus',
        iataCode: 'AKT',
        icaoCode: 'LCRA',
        latitude: '34,590401',
        longitude: '32,9879',
        altitude: '76',
        timezone: '0',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Dubrovnik Airport',
        city: 'Dubrovnik',
        country: 'Croatia',
        iataCode: 'DBV',
        icaoCode: 'LDDU',
        latitude: '42,56140137',
        longitude: '18,26819992',
        altitude: '527',
        timezone: '1',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Osijek-Čepin Airfield',
        city: 'Cepin',
        country: 'Croatia',
        iataCode: 'N',
        icaoCode: 'LDOC',
        latitude: '45,5427778',
        longitude: '18,6319444',
        altitude: '299',
        timezone: '1',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Osijek Airport',
        city: 'Osijek',
        country: 'Croatia',
        iataCode: 'OSI',
        icaoCode: 'LDOS',
        latitude: '45,46269989',
        longitude: '18,81019974',
        altitude: '290',
        timezone: '1',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Pula Airport',
        city: 'Pula',
        country: 'Croatia',
        iataCode: 'PUY',
        icaoCode: 'LDPL',
        latitude: '44,89350128',
        longitude: '13,9222002',
        altitude: '274',
        timezone: '1',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Grobnicko Polje Airport',
        city: 'Grobnik',
        country: 'Croatia',
        iataCode: 'N',
        icaoCode: 'LDRG',
        latitude: '45,37950134',
        longitude: '14,50380039',
        altitude: '951',
        timezone: '1',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Rijeka Airport',
        city: 'Rijeka',
        country: 'Croatia',
        iataCode: 'RJK',
        icaoCode: 'LDRI',
        latitude: '45,21689987',
        longitude: '14,5703001',
        altitude: '278',
        timezone: '1',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Split Airport',
        city: 'Split',
        country: 'Croatia',
        iataCode: 'SPU',
        icaoCode: 'LDSP',
        latitude: '43,53889847',
        longitude: '16,29800034',
        altitude: '79',
        timezone: '1',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Varaždin Airport',
        city: 'Varazdin',
        country: 'Croatia',
        iataCode: 'N',
        icaoCode: 'LDVA',
        latitude: '46,29464722',
        longitude: '16,38293266',
        altitude: '548',
        timezone: '1',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Zagreb Airport',
        city: 'Zagreb',
        country: 'Croatia',
        iataCode: 'ZAG',
        icaoCode: 'LDZA',
        latitude: '45,74290085',
        longitude: '16,06879997',
        altitude: '353',
        timezone: '1',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Zemunik Airport',
        city: 'Zadar',
        country: 'Croatia',
        iataCode: 'ZAD',
        icaoCode: 'LDZD',
        latitude: '44,10829926',
        longitude: '15,34669971',
        altitude: '289',
        timezone: '1',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Udbina Air Base',
        city: 'Udbina',
        country: 'Croatia',
        iataCode: 'N',
        icaoCode: 'LDZU',
        latitude: '44,55759811',
        longitude: '15,77439976',
        altitude: '2462',
        timezone: '1',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Albacete-Los Llanos Airport',
        city: 'Albacete',
        country: 'Spain',
        iataCode: 'N',
        icaoCode: 'LEAB',
        latitude: '38,94850159',
        longitude: '-1,863520026',
        altitude: '2302',
        timezone: '1',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Alicante International Airport',
        city: 'Alicante',
        country: 'Spain',
        iataCode: 'ALC',
        icaoCode: 'LEAL',
        latitude: '38,28219986',
        longitude: '-0,558156013',
        altitude: '142',
        timezone: '1',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Almería International Airport',
        city: 'Almeria',
        country: 'Spain',
        iataCode: 'LEI',
        icaoCode: 'LEAM',
        latitude: '36,84389877',
        longitude: '-2,370100021',
        altitude: '70',
        timezone: '1',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Asturias Airport',
        city: 'Aviles',
        country: 'Spain',
        iataCode: 'OVD',
        icaoCode: 'LEAS',
        latitude: '43,56359863',
        longitude: '-6,034619808',
        altitude: '416',
        timezone: '1',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Córdoba Airport',
        city: 'Cordoba',
        country: 'Spain',
        iataCode: 'ODB',
        icaoCode: 'LEBA',
        latitude: '37,84199905',
        longitude: '-4,848879814',
        altitude: '297',
        timezone: '1',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Bilbao Airport',
        city: 'Bilbao',
        country: 'Spain',
        iataCode: 'BIO',
        icaoCode: 'LEBB',
        latitude: '43,30110168',
        longitude: '-2,910609961',
        altitude: '138',
        timezone: '1',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Barcelona International Airport',
        city: 'Barcelona',
        country: 'Spain',
        iataCode: 'BCN',
        icaoCode: 'LEBL',
        latitude: '41,29710007',
        longitude: '2,078459978',
        altitude: '12',
        timezone: '1',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Badajoz Airport',
        city: 'Badajoz',
        country: 'Spain',
        iataCode: 'BJZ',
        icaoCode: 'LEBZ',
        latitude: '38,8913002',
        longitude: '-6,82133007',
        altitude: '609',
        timezone: '1',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'A Coruña Airport',
        city: 'La Coruna',
        country: 'Spain',
        iataCode: 'LCG',
        icaoCode: 'LECO',
        latitude: '43,30210114',
        longitude: '-8,377260208',
        altitude: '326',
        timezone: '1',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Armilla Air Base',
        city: 'Granada',
        country: 'Spain',
        iataCode: 'N',
        icaoCode: 'LEGA',
        latitude: '37,1332016',
        longitude: '-3,63568997',
        altitude: '2297',
        timezone: '1',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Girona Airport',
        city: 'Gerona',
        country: 'Spain',
        iataCode: 'GRO',
        icaoCode: 'LEGE',
        latitude: '41,90100098',
        longitude: '2,760550022',
        altitude: '468',
        timezone: '1',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Federico Garcia Lorca Airport',
        city: 'Granada',
        country: 'Spain',
        iataCode: 'GRX',
        icaoCode: 'LEGR',
        latitude: '37,18870163',
        longitude: '-3,777359962',
        altitude: '1860',
        timezone: '1',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Getafe Air Base',
        city: 'Madrid',
        country: 'Spain',
        iataCode: 'N',
        icaoCode: 'LEGT',
        latitude: '40,29410172',
        longitude: '-3,723829985',
        altitude: '2031',
        timezone: '1',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Ibiza Airport',
        city: 'Ibiza',
        country: 'Spain',
        iataCode: 'IBZ',
        icaoCode: 'LEIB',
        latitude: '38,8728981',
        longitude: '1,37311995',
        altitude: '24',
        timezone: '1',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Jerez Airport',
        city: 'Jerez',
        country: 'Spain',
        iataCode: 'XRY',
        icaoCode: 'LEJR',
        latitude: '36,74459839',
        longitude: '-6,060110092',
        altitude: '93',
        timezone: '1',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'San Javier Airport',
        city: 'Murcia',
        country: 'Spain',
        iataCode: 'MJV',
        icaoCode: 'LELC',
        latitude: '37,77500153',
        longitude: '-0,812389016',
        altitude: '11',
        timezone: '1',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Adolfo Suárez Madrid–Barajas Airport',
        city: 'Madrid',
        country: 'Spain',
        iataCode: 'MAD',
        icaoCode: 'LEMD',
        latitude: '40,471926',
        longitude: '-3,56264',
        altitude: '1998',
        timezone: '1',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Málaga Airport',
        city: 'Malaga',
        country: 'Spain',
        iataCode: 'AGP',
        icaoCode: 'LEMG',
        latitude: '36,67490005',
        longitude: '-4,499110222',
        altitude: '53',
        timezone: '1',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Menorca Airport',
        city: 'Menorca',
        country: 'Spain',
        iataCode: 'MAH',
        icaoCode: 'LEMH',
        latitude: '39,86259842',
        longitude: '4,218649864',
        altitude: '302',
        timezone: '1',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Moron Air Base',
        city: 'Sevilla',
        country: 'Spain',
        iataCode: 'OZP',
        icaoCode: 'LEMO',
        latitude: '37,17490005',
        longitude: '-5,615940094',
        altitude: '285',
        timezone: '1',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Ocaña Airport',
        city: 'Ocana',
        country: 'Spain',
        iataCode: 'N',
        icaoCode: 'LEOC',
        latitude: '39,9375',
        longitude: '-3,503329992',
        altitude: '2405',
        timezone: '1',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Pamplona Airport',
        city: 'Pamplona',
        country: 'Spain',
        iataCode: 'PNA',
        icaoCode: 'LEPP',
        latitude: '42,77000046',
        longitude: '-1,646329999',
        altitude: '1504',
        timezone: '1',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Alcantarilla Air Base',
        city: 'Murcia',
        country: 'Spain',
        iataCode: 'N',
        icaoCode: 'LERI',
        latitude: '37,951099',
        longitude: '-1,23032',
        altitude: '250',
        timezone: '1',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Reus Air Base',
        city: 'Reus',
        country: 'Spain',
        iataCode: 'REU',
        icaoCode: 'LERS',
        latitude: '41,1473999',
        longitude: '1,167170048',
        altitude: '233',
        timezone: '1',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Rota Naval Station Airport',
        city: 'Rota',
        country: 'Spain',
        iataCode: 'N',
        icaoCode: 'LERT',
        latitude: '36,64519882',
        longitude: '-6,349460125',
        altitude: '86',
        timezone: '1',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Salamanca Airport',
        city: 'Salamanca',
        country: 'Spain',
        iataCode: 'SLM',
        icaoCode: 'LESA',
        latitude: '40,95209885',
        longitude: '-5,501989841',
        altitude: '2595',
        timezone: '1',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Son Bonet Airport',
        city: 'Son Bonet',
        country: 'Spain',
        iataCode: 'N',
        icaoCode: 'LESB',
        latitude: '39,59889984',
        longitude: '2,702780008',
        altitude: '157',
        timezone: '1',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'San Luis Airport',
        city: 'San Luis',
        country: 'Spain',
        iataCode: 'N',
        icaoCode: 'LESL',
        latitude: '39,86220169',
        longitude: '4,258329868',
        altitude: '197',
        timezone: '1',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'San Sebastian Airport',
        city: 'San Sebastian',
        country: 'Spain',
        iataCode: 'EAS',
        icaoCode: 'LESO',
        latitude: '43,35649872',
        longitude: '-1,790609956',
        altitude: '16',
        timezone: '1',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Santiago de Compostela Airport',
        city: 'Santiago',
        country: 'Spain',
        iataCode: 'SCQ',
        icaoCode: 'LEST',
        latitude: '42,89630127',
        longitude: '-8,415140152',
        altitude: '1213',
        timezone: '1',
        dst: 'E',
        type: 'airport',
    },
    {
        name: "Pirineus - la Seu d'Urgel Airport",
        city: 'Seo De Urgel',
        country: 'Spain',
        iataCode: 'LEU',
        icaoCode: 'LESU',
        latitude: '42,3386',
        longitude: '1,40917',
        altitude: '2625',
        timezone: '1',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Torrejón Airport',
        city: 'Madrid',
        country: 'Spain',
        iataCode: 'TOJ',
        icaoCode: 'LETO',
        latitude: '40,49670029',
        longitude: '-3,445869923',
        altitude: '2026',
        timezone: '1',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Valencia Airport',
        city: 'Valencia',
        country: 'Spain',
        iataCode: 'VLC',
        icaoCode: 'LEVC',
        latitude: '39,48929977',
        longitude: '-0,481624991',
        altitude: '240',
        timezone: '1',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Valladolid Airport',
        city: 'Valladolid',
        country: 'Spain',
        iataCode: 'VLL',
        icaoCode: 'LEVD',
        latitude: '41,70610046',
        longitude: '-4,851940155',
        altitude: '2776',
        timezone: '1',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Vitoria/Foronda Airport',
        city: 'Vitoria',
        country: 'Spain',
        iataCode: 'VIT',
        icaoCode: 'LEVT',
        latitude: '42,88280106',
        longitude: '-2,7244699',
        altitude: '1682',
        timezone: '1',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Vigo Airport',
        city: 'Vigo',
        country: 'Spain',
        iataCode: 'VGO',
        icaoCode: 'LEVX',
        latitude: '42,23180008',
        longitude: '-8,62677002',
        altitude: '856',
        timezone: '1',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Santander Airport',
        city: 'Santander',
        country: 'Spain',
        iataCode: 'SDR',
        icaoCode: 'LEXJ',
        latitude: '43,42710114',
        longitude: '-3,820009947',
        altitude: '16',
        timezone: '1',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Zaragoza Air Base',
        city: 'Zaragoza',
        country: 'Spain',
        iataCode: 'ZAZ',
        icaoCode: 'LEZG',
        latitude: '41,66619873',
        longitude: '-1,04155004',
        altitude: '863',
        timezone: '1',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Sevilla Airport',
        city: 'Sevilla',
        country: 'Spain',
        iataCode: 'SVQ',
        icaoCode: 'LEZL',
        latitude: '37,41799927',
        longitude: '-5,893109798',
        altitude: '112',
        timezone: '1',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Calais-Dunkerque Airport',
        city: 'Calais',
        country: 'France',
        iataCode: 'CQF',
        icaoCode: 'LFAC',
        latitude: '50,96210098',
        longitude: '1,954759955',
        altitude: '12',
        timezone: '1',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Péronne-Saint-Quentin Airport',
        city: 'Peronne',
        country: 'France',
        iataCode: 'N',
        icaoCode: 'LFAG',
        latitude: '49,8685',
        longitude: '3,02958',
        altitude: '295',
        timezone: '1',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Nangis-Les Loges Airport',
        city: 'Nangis',
        country: 'France',
        iataCode: 'N',
        icaoCode: 'LFAI',
        latitude: '48,59619904',
        longitude: '3,006789923',
        altitude: '428',
        timezone: '1',
        dst: 'E',
        type: 'airport',
    },
    {
        name: "Bagnoles-de-l'Orne-Couterne Airport",
        city: "Bagnole-de-l'orne",
        country: 'France',
        iataCode: 'N',
        icaoCode: 'LFAO',
        latitude: '48,54579926',
        longitude: '-0,38744399',
        altitude: '718',
        timezone: '1',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Albert-Bray Airport',
        city: 'Albert',
        country: 'France',
        iataCode: 'N',
        icaoCode: 'LFAQ',
        latitude: '49,9715004',
        longitude: '2,697659969',
        altitude: '364',
        timezone: '1',
        dst: 'E',
        type: 'airport',
    },
    {
        name: "Le Touquet-Côte d'Opale Airport",
        city: 'Le Tourquet',
        country: 'France',
        iataCode: 'LTQ',
        icaoCode: 'LFAT',
        latitude: '50,51739883',
        longitude: '1,620589972',
        altitude: '36',
        timezone: '1',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Valenciennes-Denain Airport',
        city: 'Valenciennes',
        country: 'France',
        iataCode: 'N',
        icaoCode: 'LFAV',
        latitude: '50,32580185',
        longitude: '3,46126008',
        altitude: '177',
        timezone: '1',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Amiens-Glisy Airport',
        city: 'Amiens',
        country: 'France',
        iataCode: 'N',
        icaoCode: 'LFAY',
        latitude: '49,8730011',
        longitude: '2,387069941',
        altitude: '208',
        timezone: '1',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Agen-La Garenne Airport',
        city: 'Agen',
        country: 'France',
        iataCode: 'AGF',
        icaoCode: 'LFBA',
        latitude: '44,17470169',
        longitude: '0,590556026',
        altitude: '204',
        timezone: '1',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Cazaux (BA 120) Air Base',
        city: 'Cazaux',
        country: 'France',
        iataCode: 'N',
        icaoCode: 'LFBC',
        latitude: '44,53329849',
        longitude: '-1,125',
        altitude: '84',
        timezone: '1',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Bordeaux-Mérignac Airport',
        city: 'Bordeaux',
        country: 'France',
        iataCode: 'BOD',
        icaoCode: 'LFBD',
        latitude: '44,82830048',
        longitude: '-0,715556026',
        altitude: '162',
        timezone: '1',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Bergerac-Roumanière Airport',
        city: 'Bergerac',
        country: 'France',
        iataCode: 'EGC',
        icaoCode: 'LFBE',
        latitude: '44,82529831',
        longitude: '0,518611014',
        altitude: '171',
        timezone: '1',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Toulouse-Francazal (BA 101) Air Base',
        city: 'Toulouse',
        country: 'France',
        iataCode: 'N',
        icaoCode: 'LFBF',
        latitude: '43,54560089',
        longitude: '1,367499948',
        altitude: '535',
        timezone: '1',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Cognac-Châteaubernard (BA 709) Air Base',
        city: 'Cognac',
        country: 'France',
        iataCode: 'CNG',
        icaoCode: 'LFBG',
        latitude: '45,65829849',
        longitude: '-0,317499995',
        altitude: '102',
        timezone: '1',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Poitiers-Biard Airport',
        city: 'Poitiers',
        country: 'France',
        iataCode: 'PIS',
        icaoCode: 'LFBI',
        latitude: '46,58769989',
        longitude: '0,306665987',
        altitude: '423',
        timezone: '1',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Montluçon-Guéret Airport',
        city: 'Montlucon-gueret',
        country: 'France',
        iataCode: 'MCU',
        icaoCode: 'LFBK',
        latitude: '46,22259903',
        longitude: '2,363960028',
        altitude: '1497',
        timezone: '1',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Limoges Airport',
        city: 'Limoges',
        country: 'France',
        iataCode: 'LIG',
        icaoCode: 'LFBL',
        latitude: '45,8628006',
        longitude: '1,179440022',
        altitude: '1300',
        timezone: '1',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Mont-de-Marsan (BA 118) Air Base',
        city: 'Mont-de-marsan',
        country: 'France',
        iataCode: 'N',
        icaoCode: 'LFBM',
        latitude: '43,911701',
        longitude: '-0,5075',
        altitude: '203',
        timezone: '1',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Niort-Souché Airport',
        city: 'Niort',
        country: 'France',
        iataCode: 'NIT',
        icaoCode: 'LFBN',
        latitude: '46,313477',
        longitude: '-0,394529',
        altitude: '203',
        timezone: '1',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Toulouse-Blagnac Airport',
        city: 'Toulouse',
        country: 'France',
        iataCode: 'TLS',
        icaoCode: 'LFBO',
        latitude: '43,629101',
        longitude: '1,36382',
        altitude: '499',
        timezone: '1',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Pau Pyrénées Airport',
        city: 'Pau',
        country: 'France',
        iataCode: 'PUF',
        icaoCode: 'LFBP',
        latitude: '43,38000107',
        longitude: '-0,41861099',
        altitude: '616',
        timezone: '1',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Muret-Lherm Airport',
        city: 'La Rochelle',
        country: 'France',
        iataCode: 'N',
        icaoCode: 'LFBR',
        latitude: '43,44889832',
        longitude: '1,263329983',
        altitude: '622',
        timezone: '1',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Tarbes-Lourdes-Pyrénées Airport',
        city: 'Tarbes',
        country: 'France',
        iataCode: 'LDE',
        icaoCode: 'LFBT',
        latitude: '43,17869949',
        longitude: '-0,006439',
        altitude: '1260',
        timezone: '1',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Angoulême-Brie-Champniers Airport',
        city: 'Angouleme',
        country: 'France',
        iataCode: 'ANG',
        icaoCode: 'LFBU',
        latitude: '45,72919846',
        longitude: '0,221456006',
        altitude: '436',
        timezone: '1',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Toul Rosières Air Base',
        city: 'Brive',
        country: 'France',
        iataCode: 'BVE',
        icaoCode: 'LFSL',
        latitude: '48,780001',
        longitude: '5,980003',
        altitude: '936',
        timezone: '1',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Périgueux-Bassillac Airport',
        city: 'Perigueux',
        country: 'France',
        iataCode: 'PGX',
        icaoCode: 'LFBX',
        latitude: '45,19810104',
        longitude: '0,81555599',
        altitude: '328',
        timezone: '1',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Biarritz-Anglet-Bayonne Airport',
        city: 'Biarritz-bayonne',
        country: 'France',
        iataCode: 'BIQ',
        icaoCode: 'LFBZ',
        latitude: '43,4683333',
        longitude: '-1,5311111',
        altitude: '245',
        timezone: '1',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Cahors-Lalbenque Airport',
        city: 'Cahors',
        country: 'France',
        iataCode: 'N',
        icaoCode: 'LFCC',
        latitude: '44,35139847',
        longitude: '1,475280046',
        altitude: '912',
        timezone: '1',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Saint-Girons-Antichan Airport',
        city: 'St.-girons',
        country: 'France',
        iataCode: 'N',
        icaoCode: 'LFCG',
        latitude: '43,00780106',
        longitude: '1,10315001',
        altitude: '1368',
        timezone: '1',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Arcachon-La Teste-de-Buch Airport',
        city: 'Arcachon',
        country: 'France',
        iataCode: 'XAC',
        icaoCode: 'LFCH',
        latitude: '44,596401',
        longitude: '-1,11083',
        altitude: '49',
        timezone: '1',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Albi-Le Séquestre Airport',
        city: 'Albi',
        country: 'France',
        iataCode: 'LBI',
        icaoCode: 'LFCI',
        latitude: '43,91389847',
        longitude: '2,113059998',
        altitude: '564',
        timezone: '1',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Castres-Mazamet Airport',
        city: 'Castres',
        country: 'France',
        iataCode: 'DCM',
        icaoCode: 'LFCK',
        latitude: '43,55630112',
        longitude: '2,28918004',
        altitude: '788',
        timezone: '1',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Toulouse-Lasbordes Airport',
        city: 'Toulouse',
        country: 'France',
        iataCode: 'N',
        icaoCode: 'LFCL',
        latitude: '43,58610153',
        longitude: '1,499169946',
        altitude: '459',
        timezone: '1',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Millau-Larzac Airport',
        city: 'Millau',
        country: 'France',
        iataCode: 'N',
        icaoCode: 'LFCM',
        latitude: '43,98929977',
        longitude: '3,183000088',
        altitude: '2606',
        timezone: '1',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Graulhet-Montdragon Airport',
        city: 'Graulhet',
        country: 'France',
        iataCode: 'N',
        icaoCode: 'LFCQ',
        latitude: '43,77109909',
        longitude: '2,010829926',
        altitude: '581',
        timezone: '1',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Rodez-Marcillac Airport',
        city: 'Rodez',
        country: 'France',
        iataCode: 'RDZ',
        icaoCode: 'LFCR',
        latitude: '44,40790176',
        longitude: '2,482670069',
        altitude: '1910',
        timezone: '1',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Ussel-Thalamy Airport',
        city: 'Ussel',
        country: 'France',
        iataCode: 'N',
        icaoCode: 'LFCU',
        latitude: '45,53469849',
        longitude: '2,423890114',
        altitude: '2428',
        timezone: '1',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Villeneuve-sur-Lot Airport',
        city: 'Villeneuve-sur-lot',
        country: 'France',
        iataCode: 'N',
        icaoCode: 'LFCW',
        latitude: '44,39690018',
        longitude: '0,758889019',
        altitude: '190',
        timezone: '1',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Royan-Médis Airport',
        city: 'Royan',
        country: 'France',
        iataCode: 'RYN',
        icaoCode: 'LFCY',
        latitude: '45,62810135',
        longitude: '-0,972500026',
        altitude: '72',
        timezone: '1',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Mimizan Airport',
        city: 'Mimizan',
        country: 'France',
        iataCode: 'N',
        icaoCode: 'LFCZ',
        latitude: '44,145928',
        longitude: '-1,16432',
        altitude: '164',
        timezone: '1',
        dst: 'E',
        type: 'airport',
    },
    {
        name: "Aire-sur-l'Adour Airport",
        city: "Aire-sur-l'adour",
        country: 'France',
        iataCode: 'N',
        icaoCode: 'LFDA',
        latitude: '43,70940018',
        longitude: '-0,245278001',
        altitude: '259',
        timezone: '1',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Montauban Airport',
        city: 'Montauban',
        country: 'France',
        iataCode: 'N',
        icaoCode: 'LFDB',
        latitude: '44,02569962',
        longitude: '1,378039956',
        altitude: '351',
        timezone: '1',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Auch-Lamothe Airport',
        city: 'Auch',
        country: 'France',
        iataCode: 'N',
        icaoCode: 'LFDH',
        latitude: '43,68780136',
        longitude: '0,601666987',
        altitude: '411',
        timezone: '1',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Libourne-Artigues-de-Lussac Airport',
        city: 'Libourne',
        country: 'France',
        iataCode: 'N',
        icaoCode: 'LFDI',
        latitude: '44,982498',
        longitude: '-0,134722',
        altitude: '157',
        timezone: '1',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Pamiers-Les Pujols Airport',
        city: 'Pamiers',
        country: 'France',
        iataCode: 'N',
        icaoCode: 'LFDJ',
        latitude: '43,09059906',
        longitude: '1,695829988',
        altitude: '1115',
        timezone: '1',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Marmande-Virazeil Airport',
        city: 'Marmande',
        country: 'France',
        iataCode: 'N',
        icaoCode: 'LFDM',
        latitude: '44,49890137',
        longitude: '0,200514004',
        altitude: '105',
        timezone: '1',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Rochefort-Saint-Agnant (BA 721) Airport',
        city: 'Rochefort',
        country: 'France',
        iataCode: 'RCO',
        icaoCode: 'LFDN',
        latitude: '45,88779831',
        longitude: '-0,983056009',
        altitude: '60',
        timezone: '1',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Pontivy Airport',
        city: 'Pontivy',
        country: 'France',
        iataCode: 'N',
        icaoCode: 'LFED',
        latitude: '48,05849838',
        longitude: '-2,921829939',
        altitude: '407',
        timezone: '1',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Aubigny-sur-Nère Airport',
        city: 'Aubigny-sur-nere',
        country: 'France',
        iataCode: 'N',
        icaoCode: 'LFEH',
        latitude: '47,480556',
        longitude: '2,394167',
        altitude: '630',
        timezone: '1',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Guiscriff Scaer Airport',
        city: 'Guiscriff-scaer',
        country: 'France',
        iataCode: 'N',
        icaoCode: 'LFES',
        latitude: '48,05250168',
        longitude: '-3,664720058',
        altitude: '574',
        timezone: '1',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Ancenis Airport',
        city: 'Ancenis',
        country: 'France',
        iataCode: 'N',
        icaoCode: 'LFFI',
        latitude: '47,40810013',
        longitude: '-1,17750001',
        altitude: '111',
        timezone: '1',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Brienne-le-Château Airport',
        city: 'Brienne-le Chateau',
        country: 'France',
        iataCode: 'N',
        icaoCode: 'LFFN',
        latitude: '48,42979813',
        longitude: '4,482220173',
        altitude: '381',
        timezone: '1',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Colmar-Houssen Airport',
        city: 'Colmar',
        country: 'France',
        iataCode: 'CMR',
        icaoCode: 'LFGA',
        latitude: '48,10990143',
        longitude: '7,35901022',
        altitude: '628',
        timezone: '1',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Beaune-Challanges Airport',
        city: 'Beaune',
        country: 'France',
        iataCode: 'N',
        icaoCode: 'LFGF',
        latitude: '47,005901',
        longitude: '4,89342',
        altitude: '656',
        timezone: '1',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Dole-Tavaux Airport',
        city: 'Dole',
        country: 'France',
        iataCode: 'DLE',
        icaoCode: 'LFGJ',
        latitude: '47,042686',
        longitude: '5,435061',
        altitude: '645',
        timezone: '1',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Joigny Airport',
        city: 'Joigny',
        country: 'France',
        iataCode: 'N',
        icaoCode: 'LFGK',
        latitude: '47,99219894',
        longitude: '3,39222002',
        altitude: '732',
        timezone: '1',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Verdun-Le Rozelier Airfield',
        city: 'Verdun',
        country: 'France',
        iataCode: 'N',
        icaoCode: 'LFGW',
        latitude: '49,122398',
        longitude: '5,46905',
        altitude: '1230',
        timezone: '1',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Aubenas-Ardèche Méridional Airport',
        city: 'Aubenas-vals-lanas',
        country: 'France',
        iataCode: 'OBS',
        icaoCode: 'LFHO',
        latitude: '44,5442009',
        longitude: '4,372189999',
        altitude: '923',
        timezone: '1',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Le Puy-Loudes Airport',
        city: 'Le Puy',
        country: 'France',
        iataCode: 'LPY',
        icaoCode: 'LFHP',
        latitude: '45,08069992',
        longitude: '3,7628901',
        altitude: '2731',
        timezone: '1',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Saint-Flour-Coltines Airport',
        city: 'St.-flour',
        country: 'France',
        iataCode: 'N',
        icaoCode: 'LFHQ',
        latitude: '45,07640076',
        longitude: '2,993609905',
        altitude: '3218',
        timezone: '1',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Bourg-Ceyzériat Airport',
        city: 'Bourg',
        country: 'France',
        iataCode: 'XBK',
        icaoCode: 'LFHS',
        latitude: '46,20090103',
        longitude: '5,292029858',
        altitude: '857',
        timezone: '1',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Villefranche-Tarare Airport',
        city: 'Vilefrance',
        country: 'France',
        iataCode: 'XVF',
        icaoCode: 'LFHV',
        latitude: '45,919983',
        longitude: '4,634931',
        altitude: '1076',
        timezone: '1',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Moulins-Montbeugny Airport',
        city: 'Moulins',
        country: 'France',
        iataCode: 'XMU',
        icaoCode: 'LFHY',
        latitude: '46,5345993',
        longitude: '3,423719883',
        altitude: '915',
        timezone: '1',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Saint-Affrique-Belmont Airport',
        city: 'St.-afrique-belmont',
        country: 'France',
        iataCode: 'N',
        icaoCode: 'LFIF',
        latitude: '43,82329941',
        longitude: '2,745280027',
        altitude: '1686',
        timezone: '1',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Cassagnes-Bégonhès Airport',
        city: 'Cassagnes-beghones',
        country: 'France',
        iataCode: 'N',
        icaoCode: 'LFIG',
        latitude: '44,17779922',
        longitude: '2,515000105',
        altitude: '2024',
        timezone: '1',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Metz-Nancy-Lorraine Airport',
        city: 'Metz',
        country: 'France',
        iataCode: 'ETZ',
        icaoCode: 'LFJL',
        latitude: '48,98210144',
        longitude: '6,251319885',
        altitude: '870',
        timezone: '1',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Bastia-Poretta Airport',
        city: 'Bastia',
        country: 'France',
        iataCode: 'BIA',
        icaoCode: 'LFKB',
        latitude: '42,55270004',
        longitude: '9,483730316',
        altitude: '26',
        timezone: '1',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Calvi-Sainte-Catherine Airport',
        city: 'Calvi',
        country: 'France',
        iataCode: 'CLY',
        icaoCode: 'LFKC',
        latitude: '42,5244444',
        longitude: '8,7930556',
        altitude: '209',
        timezone: '1',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Figari Sud-Corse Airport',
        city: 'Figari',
        country: 'France',
        iataCode: 'FSC',
        icaoCode: 'LFKF',
        latitude: '41,50059891',
        longitude: '9,097780228',
        altitude: '87',
        timezone: '1',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Ajaccio-Napoléon Bonaparte Airport',
        city: 'Ajaccio',
        country: 'France',
        iataCode: 'AJA',
        icaoCode: 'LFKJ',
        latitude: '41,92359924',
        longitude: '8,802920341',
        altitude: '18',
        timezone: '1',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Propriano Airport',
        city: 'Propriano',
        country: 'France',
        iataCode: 'N',
        icaoCode: 'LFKO',
        latitude: '41,66059875',
        longitude: '8,889749527',
        altitude: '13',
        timezone: '1',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Solenzara (BA 126) Air Base',
        city: 'Solenzara',
        country: 'France',
        iataCode: 'SOZ',
        icaoCode: 'LFKS',
        latitude: '41,92440033',
        longitude: '9,406000137',
        altitude: '28',
        timezone: '1',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Corte Airport',
        city: 'Corte',
        country: 'France',
        iataCode: 'N',
        icaoCode: 'LFKT',
        latitude: '42,29359818',
        longitude: '9,193059921',
        altitude: '1132',
        timezone: '1',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Auxerre-Branches Airport',
        city: 'Auxerre',
        country: 'France',
        iataCode: 'AUF',
        icaoCode: 'LFLA',
        latitude: '47,85020065',
        longitude: '3,49710989',
        altitude: '523',
        timezone: '1',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Chambéry-Savoie Airport',
        city: 'Chambery',
        country: 'France',
        iataCode: 'CMF',
        icaoCode: 'LFLB',
        latitude: '45,63809967',
        longitude: '5,88022995',
        altitude: '779',
        timezone: '1',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Clermont-Ferrand Auvergne Airport',
        city: 'Clermont-Ferrand',
        country: 'France',
        iataCode: 'CFE',
        icaoCode: 'LFLC',
        latitude: '45,7867012',
        longitude: '3,169169903',
        altitude: '1090',
        timezone: '1',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Bourges Airport',
        city: 'Bourges',
        country: 'France',
        iataCode: 'BOU',
        icaoCode: 'LFLD',
        latitude: '47,05810165',
        longitude: '2,370280027',
        altitude: '529',
        timezone: '1',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Chambéry-Challes-les-Eaux Airport',
        city: 'Chambery',
        country: 'France',
        iataCode: 'N',
        icaoCode: 'LFLE',
        latitude: '45,56110001',
        longitude: '5,975759983',
        altitude: '973',
        timezone: '1',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Chalon-Champforgeuil Airport',
        city: 'Chalon',
        country: 'France',
        iataCode: 'XCD',
        icaoCode: 'LFLH',
        latitude: '46,8260994',
        longitude: '4,817629814',
        altitude: '623',
        timezone: '1',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Annemasse Airport',
        city: 'Annemasse',
        country: 'France',
        iataCode: 'QNJ',
        icaoCode: 'LFLI',
        latitude: '46,19200134',
        longitude: '6,268390179',
        altitude: '1620',
        timezone: '1',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Lyon Saint-Exupéry Airport',
        city: 'Lyon',
        country: 'France',
        iataCode: 'LYS',
        icaoCode: 'LFLL',
        latitude: '45,7255556',
        longitude: '5,0811111',
        altitude: '821',
        timezone: '1',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Mâcon-Charnay Airport',
        city: 'Macon',
        country: 'France',
        iataCode: 'QNX',
        icaoCode: 'LFLM',
        latitude: '46,295101',
        longitude: '4,79577',
        altitude: '728',
        timezone: '1',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Saint-Yan Airport',
        city: 'St.-yan',
        country: 'France',
        iataCode: 'N',
        icaoCode: 'LFLN',
        latitude: '46,41249847',
        longitude: '4,013259888',
        altitude: '796',
        timezone: '1',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Roanne-Renaison Airport',
        city: 'Roanne',
        country: 'France',
        iataCode: 'RNE',
        icaoCode: 'LFLO',
        latitude: '46,05830002',
        longitude: '4,00138998',
        altitude: '1106',
        timezone: '1',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Annecy-Haute-Savoie-Mont Blanc Airport',
        city: 'Annecy',
        country: 'France',
        iataCode: 'NCY',
        icaoCode: 'LFLP',
        latitude: '45,9308333',
        longitude: '6,1063889',
        altitude: '1521',
        timezone: '1',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Grenoble-Isère Airport',
        city: 'Grenoble',
        country: 'France',
        iataCode: 'GNB',
        icaoCode: 'LFLS',
        latitude: '45,36289978',
        longitude: '5,329370022',
        altitude: '1302',
        timezone: '1',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Montluçon-Domérat Airport',
        city: 'Montlucon',
        country: 'France',
        iataCode: 'N',
        icaoCode: 'LFLT',
        latitude: '46,352501',
        longitude: '2,57049',
        altitude: '771',
        timezone: '1',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Valence-Chabeuil Airport',
        city: 'Valence',
        country: 'France',
        iataCode: 'VAF',
        icaoCode: 'LFLU',
        latitude: '44,9216',
        longitude: '4,9699',
        altitude: '525',
        timezone: '1',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Vichy-Charmeil Airport',
        city: 'Vichy',
        country: 'France',
        iataCode: 'VHY',
        icaoCode: 'LFLV',
        latitude: '46,16970062',
        longitude: '3,403739929',
        altitude: '817',
        timezone: '1',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Aurillac Airport',
        city: 'Aurillac',
        country: 'France',
        iataCode: 'AUR',
        icaoCode: 'LFLW',
        latitude: '44,89139938',
        longitude: '2,421940088',
        altitude: '2096',
        timezone: '1',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Châteauroux-Déols Marcel Dassault" Airport"',
        city: 'Chateauroux',
        country: 'France',
        iataCode: 'CHR',
        icaoCode: 'LFLX',
        latitude: '46,86027778',
        longitude: '1,7211111',
        altitude: '529',
        timezone: '1',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Lyon-Bron Airport',
        city: 'Lyon',
        country: 'France',
        iataCode: 'LYN',
        icaoCode: 'LFLY',
        latitude: '45,72719955',
        longitude: '4,944270134',
        altitude: '659',
        timezone: '1',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Aix-en-Provence (BA 114) Airport',
        city: 'Aix-les-milles',
        country: 'France',
        iataCode: 'QXB',
        icaoCode: 'LFMA',
        latitude: '43,5056',
        longitude: '5,36778',
        altitude: '367',
        timezone: '1',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Le Luc-Le Cannet Airport',
        city: 'Le Luc',
        country: 'France',
        iataCode: 'N',
        icaoCode: 'LFMC',
        latitude: '43,38470078',
        longitude: '6,387139797',
        altitude: '265',
        timezone: '1',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Cannes-Mandelieu Airport',
        city: 'Cannes',
        country: 'France',
        iataCode: 'CEQ',
        icaoCode: 'LFMD',
        latitude: '43,54199982',
        longitude: '6,953479767',
        altitude: '13',
        timezone: '1',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Saint-Étienne-Bouthéon Airport',
        city: 'St-Etienne',
        country: 'France',
        iataCode: 'EBU',
        icaoCode: 'LFMH',
        latitude: '45,54059982',
        longitude: '4,296390057',
        altitude: '1325',
        timezone: '1',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Istres Le Tubé/Istres Air Base (BA 125) Airport',
        city: 'Istres',
        country: 'France',
        iataCode: 'N',
        icaoCode: 'LFMI',
        latitude: '43,522701',
        longitude: '4,92384',
        altitude: '82',
        timezone: '1',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Carcassonne Airport',
        city: 'Carcassonne',
        country: 'France',
        iataCode: 'CCF',
        icaoCode: 'LFMK',
        latitude: '43,2159996',
        longitude: '2,306319952',
        altitude: '433',
        timezone: '1',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Marseille Provence Airport',
        city: 'Marseille',
        country: 'France',
        iataCode: 'MRS',
        icaoCode: 'LFML',
        latitude: '43,43927192',
        longitude: '5,221424103',
        altitude: '74',
        timezone: '1',
        dst: 'E',
        type: 'airport',
    },
    {
        name: "Nice-Côte d'Azur Airport",
        city: 'Nice',
        country: 'France',
        iataCode: 'NCE',
        icaoCode: 'LFMN',
        latitude: '43,65840149',
        longitude: '7,215869904',
        altitude: '12',
        timezone: '1',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Orange-Caritat (BA 115) Air Base',
        city: 'Orange',
        country: 'France',
        iataCode: 'N',
        icaoCode: 'LFMO',
        latitude: '44,14049911',
        longitude: '4,8667202',
        altitude: '197',
        timezone: '1',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Perpignan-Rivesaltes (Llabanère) Airport',
        city: 'Perpignan',
        country: 'France',
        iataCode: 'PGF',
        icaoCode: 'LFMP',
        latitude: '42,74039841',
        longitude: '2,87067008',
        altitude: '144',
        timezone: '1',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Le Castellet Airport',
        city: 'Le Castellet',
        country: 'France',
        iataCode: 'CTT',
        icaoCode: 'LFMQ',
        latitude: '43,25249863',
        longitude: '5,785190105',
        altitude: '1391',
        timezone: '1',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Alès-Deaux Airport',
        city: 'Ales',
        country: 'France',
        iataCode: 'N',
        icaoCode: 'LFMS',
        latitude: '44,069698',
        longitude: '4,14212',
        altitude: '668',
        timezone: '1',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Montpellier-Méditerranée Airport',
        city: 'Montpellier',
        country: 'France',
        iataCode: 'MPL',
        icaoCode: 'LFMT',
        latitude: '43,57619858',
        longitude: '3,963010073',
        altitude: '17',
        timezone: '1',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Béziers-Vias Airport',
        city: 'Beziers',
        country: 'France',
        iataCode: 'BZR',
        icaoCode: 'LFMU',
        latitude: '43,32350159',
        longitude: '3,353899956',
        altitude: '56',
        timezone: '1',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Avignon-Caumont Airport',
        city: 'Avignon',
        country: 'France',
        iataCode: 'AVN',
        icaoCode: 'LFMV',
        latitude: '43,90729904',
        longitude: '4,901830196',
        altitude: '124',
        timezone: '1',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Salon-de-Provence (BA 701) Air Base',
        city: 'Salon',
        country: 'France',
        iataCode: 'N',
        icaoCode: 'LFMY',
        latitude: '43,60639954',
        longitude: '5,109250069',
        altitude: '195',
        timezone: '1',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Lézignan-Corbières Airport',
        city: 'Lezignan-corbieres',
        country: 'France',
        iataCode: 'N',
        icaoCode: 'LFMZ',
        latitude: '43,17580032',
        longitude: '2,73416996',
        altitude: '207',
        timezone: '1',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Mende-Brenoux Airport',
        city: 'Mende',
        country: 'France',
        iataCode: 'MEN',
        icaoCode: 'LFNB',
        latitude: '44,5021019',
        longitude: '3,532819986',
        altitude: '3362',
        timezone: '1',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Carpentras Airport',
        city: 'Carpentras',
        country: 'France',
        iataCode: 'N',
        icaoCode: 'LFNH',
        latitude: '44,02980042',
        longitude: '5,07806015',
        altitude: '394',
        timezone: '1',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Avord (BA 702) Air Base',
        city: 'Avord',
        country: 'France',
        iataCode: 'N',
        icaoCode: 'LFOA',
        latitude: '47,053299',
        longitude: '2,6325',
        altitude: '580',
        timezone: '1',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Paris Beauvais Tillé Airport',
        city: 'Beauvais',
        country: 'France',
        iataCode: 'BVA',
        icaoCode: 'LFOB',
        latitude: '49,45439911',
        longitude: '2,112780094',
        altitude: '359',
        timezone: '1',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Châteaudun (BA 279) Air Base',
        city: 'Chateaudun',
        country: 'France',
        iataCode: 'N',
        icaoCode: 'LFOC',
        latitude: '48,058102',
        longitude: '1,37662',
        altitude: '433',
        timezone: '1',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Saumur-Saint-Florent Airport',
        city: 'Saumur',
        country: 'France',
        iataCode: 'N',
        icaoCode: 'LFOD',
        latitude: '47,256802',
        longitude: '-0,115142',
        altitude: '269',
        timezone: '1',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Évreux-Fauville (BA 105) Air Base',
        city: 'Evreux',
        country: 'France',
        iataCode: 'N',
        icaoCode: 'LFOE',
        latitude: '49,02870178',
        longitude: '1,219859958',
        altitude: '464',
        timezone: '1',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Le Havre Octeville Airport',
        city: 'Le Havre',
        country: 'France',
        iataCode: 'LEH',
        icaoCode: 'LFOH',
        latitude: '49,53390121',
        longitude: '0,088055998',
        altitude: '313',
        timezone: '1',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Abbeville',
        city: 'Abbeville',
        country: 'France',
        iataCode: 'N',
        icaoCode: 'LFOI',
        latitude: '50,143501',
        longitude: '1,831891',
        altitude: '220',
        timezone: '1',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Orléans-Bricy (BA 123) Air Base',
        city: 'Orleans',
        country: 'France',
        iataCode: 'ORE',
        icaoCode: 'LFOJ',
        latitude: '47,9878006',
        longitude: '1,760560036',
        altitude: '412',
        timezone: '1',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Châlons-Vatry Air Base',
        city: 'Chalons',
        country: 'France',
        iataCode: 'XCR',
        icaoCode: 'LFOK',
        latitude: '48,77610016',
        longitude: '4,184490204',
        altitude: '587',
        timezone: '1',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Rouen Airport',
        city: 'Rouen',
        country: 'France',
        iataCode: 'URO',
        icaoCode: 'LFOP',
        latitude: '49,38420105',
        longitude: '1,174800038',
        altitude: '512',
        timezone: '1',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Tours-Val-de-Loire Airport',
        city: 'Tours',
        country: 'France',
        iataCode: 'TUF',
        icaoCode: 'LFOT',
        latitude: '47,43220139',
        longitude: '0,727605999',
        altitude: '357',
        timezone: '1',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Cholet Le Pontreau Airport',
        city: 'Cholet',
        country: 'France',
        iataCode: 'CET',
        icaoCode: 'LFOU',
        latitude: '47,08209991',
        longitude: '-0,87706399',
        altitude: '443',
        timezone: '1',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Laval-Entrammes Airport',
        city: 'Laval',
        country: 'France',
        iataCode: 'LVA',
        icaoCode: 'LFOV',
        latitude: '48,03139877',
        longitude: '-0,742986023',
        altitude: '330',
        timezone: '1',
        dst: 'E',
        type: 'airport',
    },
    {
        name: "Orléans-Saint-Denis-de-l'Hôtel Airport",
        city: 'Orleans',
        country: 'France',
        iataCode: 'N',
        icaoCode: 'LFOZ',
        latitude: '47,8969',
        longitude: '2,16333',
        altitude: '396',
        timezone: '1',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Paris-Le Bourget Airport',
        city: 'Paris',
        country: 'France',
        iataCode: 'LBG',
        icaoCode: 'LFPB',
        latitude: '48,9693985',
        longitude: '2,441390038',
        altitude: '218',
        timezone: '1',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Creil Air Base',
        city: 'Creil',
        country: 'France',
        iataCode: 'CSF',
        icaoCode: 'LFPC',
        latitude: '49,25350189',
        longitude: '2,519140005',
        altitude: '291',
        timezone: '1',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Charles de Gaulle International Airport',
        city: 'Paris',
        country: 'France',
        iataCode: 'CDG',
        icaoCode: 'LFPG',
        latitude: '49,01279831',
        longitude: '2,549999952',
        altitude: '392',
        timezone: '1',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Coulommiers-Voisins Airport',
        city: 'Coulommiers',
        country: 'France',
        iataCode: 'N',
        icaoCode: 'LFPK',
        latitude: '48,83769989',
        longitude: '3,016119957',
        altitude: '470',
        timezone: '1',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Melun-Villaroche Air Base',
        city: 'Melun',
        country: 'France',
        iataCode: 'N',
        icaoCode: 'LFPM',
        latitude: '48,60469818',
        longitude: '2,671119928',
        altitude: '302',
        timezone: '1',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Toussus-le-Noble Airport',
        city: 'Toussous-le-noble',
        country: 'France',
        iataCode: 'TNF',
        icaoCode: 'LFPN',
        latitude: '48,75189972',
        longitude: '2,106189966',
        altitude: '538',
        timezone: '1',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Paris-Orly Airport',
        city: 'Paris',
        country: 'France',
        iataCode: 'ORY',
        icaoCode: 'LFPO',
        latitude: '48,7233333',
        longitude: '2,3794444',
        altitude: '291',
        timezone: '1',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Pontoise - Cormeilles-en-Vexin Airport',
        city: 'Pontoise',
        country: 'France',
        iataCode: 'POX',
        icaoCode: 'LFPT',
        latitude: '49,0966667',
        longitude: '2,0408333',
        altitude: '325',
        timezone: '1',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Villacoublay-Vélizy (BA 107) Air Base',
        city: 'Villacoublay',
        country: 'France',
        iataCode: 'N',
        icaoCode: 'LFPV',
        latitude: '48,7741667',
        longitude: '2,1916667',
        altitude: '584',
        timezone: '1',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Reims-Prunay Airport',
        city: 'Reims',
        country: 'France',
        iataCode: 'N',
        icaoCode: 'LFQA',
        latitude: '49,20869827',
        longitude: '4,156579971',
        altitude: '313',
        timezone: '1',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Troyes-Barberey Airport',
        city: 'Troyes',
        country: 'France',
        iataCode: 'QYR',
        icaoCode: 'LFQB',
        latitude: '48,32210159',
        longitude: '4,016699791',
        altitude: '388',
        timezone: '1',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Lunéville-Croismare Airport',
        city: 'Luneville',
        country: 'France',
        iataCode: 'N',
        icaoCode: 'LFQC',
        latitude: '48,59329987',
        longitude: '6,543459892',
        altitude: '790',
        timezone: '1',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Étain-Rouvres Air Base',
        city: 'Etain',
        country: 'France',
        iataCode: 'N',
        icaoCode: 'LFQE',
        latitude: '49,22689819',
        longitude: '5,67222023',
        altitude: '770',
        timezone: '1',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Autun-Bellevue Airport',
        city: 'Autun',
        country: 'France',
        iataCode: 'N',
        icaoCode: 'LFQF',
        latitude: '46,96730042',
        longitude: '4,260570049',
        altitude: '997',
        timezone: '1',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Nevers-Fourchambault Airport',
        city: 'Nevers',
        country: 'France',
        iataCode: 'NVS',
        icaoCode: 'LFQG',
        latitude: '47,00260162',
        longitude: '3,113329887',
        altitude: '602',
        timezone: '1',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Cambrai-Épinoy (BA 103) Air Base',
        city: 'Cambrai',
        country: 'France',
        iataCode: 'N',
        icaoCode: 'LFQI',
        latitude: '50,221802',
        longitude: '3,15424',
        altitude: '257',
        timezone: '1',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Maubeuge-Élesmes Airport',
        city: 'Maubeuge',
        country: 'France',
        iataCode: 'N',
        icaoCode: 'LFQJ',
        latitude: '50,3105011',
        longitude: '4,033120155',
        altitude: '452',
        timezone: '1',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Besançon-La Vèze Airport',
        city: 'Besancon-la-veze',
        country: 'France',
        iataCode: 'N',
        icaoCode: 'LFQM',
        latitude: '47,2066',
        longitude: '6,083681',
        altitude: '1271',
        timezone: '1',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Phalsbourg-Bourscheid Air Base',
        city: 'Phalsbourg',
        country: 'France',
        iataCode: 'N',
        icaoCode: 'LFQP',
        latitude: '48,7680556',
        longitude: '7,205',
        altitude: '1017',
        timezone: '1',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Lille-Lesquin Airport',
        city: 'Lille',
        country: 'France',
        iataCode: 'LIL',
        icaoCode: 'LFQQ',
        latitude: '50,563332',
        longitude: '3,086886',
        altitude: '157',
        timezone: '1',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Merville-Calonne Airport',
        city: 'Merville',
        country: 'France',
        iataCode: 'N',
        icaoCode: 'LFQT',
        latitude: '50,61840057',
        longitude: '2,642240047',
        altitude: '61',
        timezone: '1',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Charleville-Mézières Airport',
        city: 'Charleville',
        country: 'France',
        iataCode: 'N',
        icaoCode: 'LFQV',
        latitude: '49,78390121',
        longitude: '4,647079945',
        altitude: '492',
        timezone: '1',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Vesoul-Frotey Airport',
        city: 'Vesoul-frotey',
        country: 'France',
        iataCode: 'N',
        icaoCode: 'LFQW',
        latitude: '47,6376',
        longitude: '6,20392',
        altitude: '1249',
        timezone: '1',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Brest Bretagne Airport',
        city: 'Brest',
        country: 'France',
        iataCode: 'BES',
        icaoCode: 'LFRB',
        latitude: '48,44789886',
        longitude: '-4,418540001',
        altitude: '325',
        timezone: '1',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Cherbourg-Maupertus Airport',
        city: 'Cherbourg',
        country: 'France',
        iataCode: 'CER',
        icaoCode: 'LFRC',
        latitude: '49,65010071',
        longitude: '-1,470280051',
        altitude: '459',
        timezone: '1',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Dinard-Pleurtuit-Saint-Malo Airport',
        city: 'Dinard',
        country: 'France',
        iataCode: 'DNR',
        icaoCode: 'LFRD',
        latitude: '48,58769989',
        longitude: '-2,079960108',
        altitude: '219',
        timezone: '1',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'La Baule-Escoublac Airport',
        city: 'La Baule',
        country: 'France',
        iataCode: 'N',
        icaoCode: 'LFRE',
        latitude: '47,28939819',
        longitude: '-2,346390009',
        altitude: '105',
        timezone: '1',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Granville Airport',
        city: 'Granville',
        country: 'France',
        iataCode: 'GFR',
        icaoCode: 'LFRF',
        latitude: '48,8830986',
        longitude: '-1,564170003',
        altitude: '45',
        timezone: '1',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Deauville-Saint-Gatien Airport',
        city: 'Deauville',
        country: 'France',
        iataCode: 'DOL',
        icaoCode: 'LFRG',
        latitude: '49,36529922',
        longitude: '0,154305995',
        altitude: '479',
        timezone: '1',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Lorient South Brittany (Bretagne Sud) Airport',
        city: 'Lorient',
        country: 'France',
        iataCode: 'LRT',
        icaoCode: 'LFRH',
        latitude: '47,76060104',
        longitude: '-3,440000057',
        altitude: '160',
        timezone: '1',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'La Roche-sur-Yon Airport',
        city: 'La Roche-sur-yon',
        country: 'France',
        iataCode: 'EDM',
        icaoCode: 'LFRI',
        latitude: '46,70190048',
        longitude: '-1,378630042',
        altitude: '299',
        timezone: '1',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Landivisiau Air Base',
        city: 'Landivisiau',
        country: 'France',
        iataCode: 'N',
        icaoCode: 'LFRJ',
        latitude: '48,53030014',
        longitude: '-4,151639938',
        altitude: '348',
        timezone: '1',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Caen-Carpiquet Airport',
        city: 'Caen',
        country: 'France',
        iataCode: 'CFR',
        icaoCode: 'LFRK',
        latitude: '49,1733017',
        longitude: '-0,449999988',
        altitude: '256',
        timezone: '1',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Lanvéoc-Poulmic Air Base',
        city: 'Lanvedoc',
        country: 'France',
        iataCode: 'N',
        icaoCode: 'LFRL',
        latitude: '48,28170013',
        longitude: '-4,445020199',
        altitude: '287',
        timezone: '1',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Le Mans-Arnage Airport',
        city: 'Le Mans',
        country: 'France',
        iataCode: 'LME',
        icaoCode: 'LFRM',
        latitude: '47,94860077',
        longitude: '0,201666996',
        altitude: '194',
        timezone: '1',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Rennes-Saint-Jacques Airport',
        city: 'Rennes',
        country: 'France',
        iataCode: 'RNS',
        icaoCode: 'LFRN',
        latitude: '48,06949997',
        longitude: '-1,734789968',
        altitude: '124',
        timezone: '1',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Lannion-Côte de Granit Airport',
        city: 'Lannion',
        country: 'France',
        iataCode: 'LAI',
        icaoCode: 'LFRO',
        latitude: '48,75439835',
        longitude: '-3,471659899',
        altitude: '290',
        timezone: '1',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Quimper-Cornouaille Airport',
        city: 'Quimper',
        country: 'France',
        iataCode: 'UIP',
        icaoCode: 'LFRQ',
        latitude: '47,97499847',
        longitude: '-4,167789936',
        altitude: '297',
        timezone: '1',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Nantes Atlantique Airport',
        city: 'Nantes',
        country: 'France',
        iataCode: 'NTE',
        icaoCode: 'LFRS',
        latitude: '47,15319824',
        longitude: '-1,610730052',
        altitude: '90',
        timezone: '1',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Saint-Brieuc-Armor Airport',
        city: 'St.-brieuc Armor',
        country: 'France',
        iataCode: 'SBK',
        icaoCode: 'LFRT',
        latitude: '48,5378',
        longitude: '-2,85444',
        altitude: '453',
        timezone: '1',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Morlaix-Ploujean Airport',
        city: 'Morlaix',
        country: 'France',
        iataCode: 'MXN',
        icaoCode: 'LFRU',
        latitude: '48,60319901',
        longitude: '-3,815779924',
        altitude: '272',
        timezone: '1',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Vannes-Meucon Airport',
        city: 'Vannes',
        country: 'France',
        iataCode: 'VNE',
        icaoCode: 'LFRV',
        latitude: '47,72330093',
        longitude: '-2,71855998',
        altitude: '446',
        timezone: '1',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Saint-Nazaire-Montoir Airport',
        city: 'St.-nazaire',
        country: 'France',
        iataCode: 'SNR',
        icaoCode: 'LFRZ',
        latitude: '47,3105556',
        longitude: '-2,1566667',
        altitude: '13',
        timezone: '1',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'EuroAirport Basel-Mulhouse-Freiburg Airport',
        city: 'Mulhouse',
        country: 'France',
        iataCode: 'BSL',
        icaoCode: 'LFSB',
        latitude: '47,59',
        longitude: '7,5291667',
        altitude: '885',
        timezone: '1',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Colmar-Meyenheim Air Base',
        city: 'Colmar',
        country: 'France',
        iataCode: 'N',
        icaoCode: 'LFSC',
        latitude: '47,92200089',
        longitude: '7,399670124',
        altitude: '693',
        timezone: '1',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Dijon-Bourgogne Airport',
        city: 'Dijon',
        country: 'France',
        iataCode: 'DIJ',
        icaoCode: 'LFSD',
        latitude: '47,26890183',
        longitude: '5,090000153',
        altitude: '726',
        timezone: '1',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Metz-Frescaty (BA 128) Air Base',
        city: 'Metz',
        country: 'France',
        iataCode: 'MZM',
        icaoCode: 'LFSF',
        latitude: '49,07170105',
        longitude: '6,131669998',
        altitude: '629',
        timezone: '1',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Épinal-Mirecourt Airport',
        city: 'Epinal',
        country: 'France',
        iataCode: 'EPL',
        icaoCode: 'LFSG',
        latitude: '48,32500076',
        longitude: '6,069980145',
        altitude: '1084',
        timezone: '1',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Haguenau Airport',
        city: 'Haguenau',
        country: 'France',
        iataCode: 'N',
        icaoCode: 'LFSH',
        latitude: '48,79430008',
        longitude: '7,817609787',
        altitude: '491',
        timezone: '1',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Saint-Dizier-Robinson (BA 113) Air Base',
        city: 'St.-dizier',
        country: 'France',
        iataCode: 'N',
        icaoCode: 'LFSI',
        latitude: '48,63600159',
        longitude: '4,899419785',
        altitude: '458',
        timezone: '1',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Montbéliard-Courcelles Airfield',
        city: 'Montbeliard',
        country: 'France',
        iataCode: 'N',
        icaoCode: 'LFSM',
        latitude: '47,487',
        longitude: '6,79054',
        altitude: '1041',
        timezone: '1',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Nancy-Essey Airport',
        city: 'Nancy',
        country: 'France',
        iataCode: 'ENC',
        icaoCode: 'LFSN',
        latitude: '48,69210052',
        longitude: '6,230460167',
        altitude: '751',
        timezone: '1',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Nancy-Ochey (BA 133) Air Base',
        city: 'Nancy',
        country: 'France',
        iataCode: 'N',
        icaoCode: 'LFSO',
        latitude: '48,58309937',
        longitude: '5,954999924',
        altitude: '1106',
        timezone: '1',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Pontarlier Airport',
        city: 'Pontarlier',
        country: 'France',
        iataCode: 'N',
        icaoCode: 'LFSP',
        latitude: '46,90399933',
        longitude: '6,327370167',
        altitude: '2683',
        timezone: '1',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Reims-Champagne (BA 112) Air Base',
        city: 'Reims',
        country: 'France',
        iataCode: 'RHE',
        icaoCode: 'LFSR',
        latitude: '49,310001',
        longitude: '4,05',
        altitude: '312',
        timezone: '1',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Strasbourg Airport',
        city: 'Strasbourg',
        country: 'France',
        iataCode: 'SXB',
        icaoCode: 'LFST',
        latitude: '48,53829956',
        longitude: '7,628230095',
        altitude: '505',
        timezone: '1',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Luxeuil-Saint-Sauveur (BA 116) Air Base',
        city: 'Luxeuil',
        country: 'France',
        iataCode: 'N',
        icaoCode: 'LFSX',
        latitude: '47,7830556',
        longitude: '6,36416667',
        altitude: '913',
        timezone: '1',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Cuers-Pierrefeu Airport',
        city: 'Cuers',
        country: 'France',
        iataCode: 'N',
        icaoCode: 'LFTF',
        latitude: '43,24779892',
        longitude: '6,126699924',
        altitude: '266',
        timezone: '1',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Toulon-Hyères Airport',
        city: 'Hyeres',
        country: 'France',
        iataCode: 'TLN',
        icaoCode: 'LFTH',
        latitude: '43,09730148',
        longitude: '6,146029949',
        altitude: '7',
        timezone: '1',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Nîmes-Arles-Camargue Airport',
        city: 'Nimes',
        country: 'France',
        iataCode: 'FNI',
        icaoCode: 'LFTW',
        latitude: '43,75740051',
        longitude: '4,416349888',
        altitude: '309',
        timezone: '1',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Miquelon Airport',
        city: 'Miquelon',
        country: 'Saint Pierre and Miquelon',
        iataCode: 'MQC',
        icaoCode: 'LFVM',
        latitude: '47,09550095',
        longitude: '-56,38029861',
        altitude: '10',
        timezone: '-3',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'St Pierre Airport',
        city: 'St.-pierre',
        country: 'Saint Pierre and Miquelon',
        iataCode: 'FSP',
        icaoCode: 'LFVP',
        latitude: '46,76290131',
        longitude: '-56,17309952',
        altitude: '27',
        timezone: '-3',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Ambérieu Air Base (BA 278)',
        city: 'Amberieu',
        country: 'France',
        iataCode: 'N',
        icaoCode: 'LFXA',
        latitude: '45,987301',
        longitude: '5,32844',
        altitude: '823',
        timezone: '1',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Damblain Airport',
        city: 'Damblain',
        country: 'France',
        iataCode: 'N',
        icaoCode: 'LFYD',
        latitude: '48,0862999',
        longitude: '5,664060116',
        altitude: '1280',
        timezone: '1',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Andravida Air Base',
        city: 'Andravida',
        country: 'Greece',
        iataCode: 'PYR',
        icaoCode: 'LGAD',
        latitude: '37,9207',
        longitude: '21,292601',
        altitude: '55',
        timezone: '2',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Agrinion Air Base',
        city: 'Agrinion',
        country: 'Greece',
        iataCode: 'AGQ',
        icaoCode: 'LGAG',
        latitude: '38,602001',
        longitude: '21,3512001',
        altitude: '154',
        timezone: '2',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Dimokritos Airport',
        city: 'Alexandroupolis',
        country: 'Greece',
        iataCode: 'AXD',
        icaoCode: 'LGAL',
        latitude: '40,85589981',
        longitude: '25,95630074',
        altitude: '24',
        timezone: '2',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Alexandria Airport',
        city: 'Alexandria',
        country: 'Greece',
        iataCode: 'N',
        icaoCode: 'LGAX',
        latitude: '40,65110016',
        longitude: '22,48870087',
        altitude: '27',
        timezone: '2',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Nea Anchialos Airport',
        city: 'Nea Anghialos',
        country: 'Greece',
        iataCode: 'VOL',
        icaoCode: 'LGBL',
        latitude: '39,21960068',
        longitude: '22,79430008',
        altitude: '83',
        timezone: '2',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Elefsis Airport',
        city: 'Elefsis',
        country: 'Greece',
        iataCode: 'N',
        icaoCode: 'LGEL',
        latitude: '38,06380081',
        longitude: '23,55599976',
        altitude: '143',
        timezone: '2',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Chios Island National Airport',
        city: 'Chios',
        country: 'Greece',
        iataCode: 'JKH',
        icaoCode: 'LGHI',
        latitude: '38,34320068',
        longitude: '26,1406002',
        altitude: '15',
        timezone: '2',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Ioannina Airport',
        city: 'Ioannina',
        country: 'Greece',
        iataCode: 'IOA',
        icaoCode: 'LGIO',
        latitude: '39,69639969',
        longitude: '20,82250023',
        altitude: '1558',
        timezone: '2',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Heraklion International Nikos Kazantzakis Airport',
        city: 'Heraklion',
        country: 'Greece',
        iataCode: 'HER',
        icaoCode: 'LGIR',
        latitude: '35,33969879',
        longitude: '25,18029976',
        altitude: '115',
        timezone: '2',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Kastoria National Airport',
        city: 'Kastoria',
        country: 'Greece',
        iataCode: 'KSO',
        icaoCode: 'LGKA',
        latitude: '40,44630051',
        longitude: '21,28219986',
        altitude: '2167',
        timezone: '2',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Kithira Airport',
        city: 'Kithira',
        country: 'Greece',
        iataCode: 'KIT',
        icaoCode: 'LGKC',
        latitude: '36,27429962',
        longitude: '23,0170002',
        altitude: '1045',
        timezone: '2',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Kefallinia Airport',
        city: 'Keffallinia',
        country: 'Greece',
        iataCode: 'EFL',
        icaoCode: 'LGKF',
        latitude: '38,12009811',
        longitude: '20,50049973',
        altitude: '59',
        timezone: '2',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Kalamata Airport',
        city: 'Kalamata',
        country: 'Greece',
        iataCode: 'KLX',
        icaoCode: 'LGKL',
        latitude: '37,06829834',
        longitude: '22,02549934',
        altitude: '26',
        timezone: '2',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Amigdhaleon Airport',
        city: 'Kavala',
        country: 'Greece',
        iataCode: 'N',
        icaoCode: 'LGKM',
        latitude: '40,97280121',
        longitude: '24,34140015',
        altitude: '203',
        timezone: '2',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Kos Airport',
        city: 'Kos',
        country: 'Greece',
        iataCode: 'KGS',
        icaoCode: 'LGKO',
        latitude: '36,79330063',
        longitude: '27,0916996',
        altitude: '412',
        timezone: '2',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Karpathos Airport',
        city: 'Karpathos',
        country: 'Greece',
        iataCode: 'AOK',
        icaoCode: 'LGKP',
        latitude: '35,42139816',
        longitude: '27,14599991',
        altitude: '66',
        timezone: '2',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Ioannis Kapodistrias International Airport',
        city: 'Kerkyra/corfu',
        country: 'Greece',
        iataCode: 'CFU',
        icaoCode: 'LGKR',
        latitude: '39,60189819',
        longitude: '19,9116993',
        altitude: '6',
        timezone: '2',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Kasos Airport',
        city: 'Kasos',
        country: 'Greece',
        iataCode: 'KSJ',
        icaoCode: 'LGKS',
        latitude: '35,42139816',
        longitude: '26,90999985',
        altitude: '35',
        timezone: '2',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Alexander the Great International Airport',
        city: 'Kavala',
        country: 'Greece',
        iataCode: 'KVA',
        icaoCode: 'LGKV',
        latitude: '40,91329956',
        longitude: '24,61919975',
        altitude: '18',
        timezone: '2',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Filippos Airport',
        city: 'Kozani',
        country: 'Greece',
        iataCode: 'KZI',
        icaoCode: 'LGKZ',
        latitude: '40,28609848',
        longitude: '21,84079933',
        altitude: '2059',
        timezone: '2',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Leros Airport',
        city: 'Leros',
        country: 'Greece',
        iataCode: 'LRS',
        icaoCode: 'LGLE',
        latitude: '37,18489838',
        longitude: '26,8003006',
        altitude: '39',
        timezone: '2',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Limnos Airport',
        city: 'Limnos',
        country: 'Greece',
        iataCode: 'LXS',
        icaoCode: 'LGLM',
        latitude: '39,917099',
        longitude: '25,23629951',
        altitude: '14',
        timezone: '2',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Larisa Airport',
        city: 'Larissa',
        country: 'Greece',
        iataCode: 'LRA',
        icaoCode: 'LGLR',
        latitude: '39,650253',
        longitude: '22,4655',
        altitude: '241',
        timezone: '2',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Megara Airport',
        city: 'Megara',
        country: 'Greece',
        iataCode: 'N',
        icaoCode: 'LGMG',
        latitude: '37,98109818',
        longitude: '23,36540031',
        altitude: '12',
        timezone: '2',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Mikonos Airport',
        city: 'Mykonos',
        country: 'Greece',
        iataCode: 'JMK',
        icaoCode: 'LGMK',
        latitude: '37,43510056',
        longitude: '25,34810066',
        altitude: '405',
        timezone: '2',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Mytilene International Airport',
        city: 'Mytilini',
        country: 'Greece',
        iataCode: 'MJT',
        icaoCode: 'LGMT',
        latitude: '39,05670166',
        longitude: '26,59830093',
        altitude: '60',
        timezone: '2',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Aktion National Airport',
        city: 'Preveza',
        country: 'Greece',
        iataCode: 'PVK',
        icaoCode: 'LGPZ',
        latitude: '38,92549896',
        longitude: '20,76530075',
        altitude: '11',
        timezone: '2',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Maritsa Airport',
        city: 'Rhodos',
        country: 'Greece',
        iataCode: 'N',
        icaoCode: 'LGRD',
        latitude: '36,3830986',
        longitude: '28,10890007',
        altitude: '204',
        timezone: '2',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Diagoras Airport',
        city: 'Rhodos',
        country: 'Greece',
        iataCode: 'RHO',
        icaoCode: 'LGRP',
        latitude: '36,40539932',
        longitude: '28,08620071',
        altitude: '17',
        timezone: '2',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Araxos Airport',
        city: 'Patras',
        country: 'Greece',
        iataCode: 'GPA',
        icaoCode: 'LGRX',
        latitude: '38,1511',
        longitude: '21,4256',
        altitude: '46',
        timezone: '2',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Chania International Airport',
        city: 'Chania',
        country: 'Greece',
        iataCode: 'CHQ',
        icaoCode: 'LGSA',
        latitude: '35,53170013',
        longitude: '24,14970016',
        altitude: '490',
        timezone: '2',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Skiathos Island National Airport',
        city: 'Skiathos',
        country: 'Greece',
        iataCode: 'JSI',
        icaoCode: 'LGSK',
        latitude: '39,17710114',
        longitude: '23,50370026',
        altitude: '54',
        timezone: '2',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Samos Airport',
        city: 'Samos',
        country: 'Greece',
        iataCode: 'SMI',
        icaoCode: 'LGSM',
        latitude: '37,68999863',
        longitude: '26,9116993',
        altitude: '19',
        timezone: '2',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Sparti Airport',
        city: 'Sparti',
        country: 'Greece',
        iataCode: 'N',
        icaoCode: 'LGSP',
        latitude: '36,97389984',
        longitude: '22,52630043',
        altitude: '500',
        timezone: '2',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Santorini Airport',
        city: 'Thira',
        country: 'Greece',
        iataCode: 'JTR',
        icaoCode: 'LGSR',
        latitude: '36,39920044',
        longitude: '25,47929955',
        altitude: '127',
        timezone: '2',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Sitia Airport',
        city: 'Sitia',
        country: 'Greece',
        iataCode: 'JSH',
        icaoCode: 'LGST',
        latitude: '35,21609879',
        longitude: '26,10129929',
        altitude: '376',
        timezone: '2',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Stefanovikion Air Base',
        city: 'Stefanovikion',
        country: 'Greece',
        iataCode: 'N',
        icaoCode: 'LGSV',
        latitude: '39,48',
        longitude: '22,7672',
        altitude: '146',
        timezone: '2',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Skiros Airport',
        city: 'Skiros',
        country: 'Greece',
        iataCode: 'SKU',
        icaoCode: 'LGSY',
        latitude: '38,96760178',
        longitude: '24,48719978',
        altitude: '44',
        timezone: '2',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Tanagra Air Base',
        city: 'Tanagra',
        country: 'Greece',
        iataCode: 'N',
        icaoCode: 'LGTG',
        latitude: '38,339802',
        longitude: '23,565001',
        altitude: '485',
        timezone: '2',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Kasteli Airport',
        city: 'Kasteli',
        country: 'Greece',
        iataCode: 'N',
        icaoCode: 'LGTL',
        latitude: '35,19200134',
        longitude: '25,32699966',
        altitude: '1180',
        timezone: '2',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Tripolis Airport',
        city: 'Tripolis',
        country: 'Greece',
        iataCode: 'N',
        icaoCode: 'LGTP',
        latitude: '37,5306015',
        longitude: '22,40360069',
        altitude: '2113',
        timezone: '2',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Thessaloniki Macedonia International Airport',
        city: 'Thessaloniki',
        country: 'Greece',
        iataCode: 'SKG',
        icaoCode: 'LGTS',
        latitude: '40,5196991',
        longitude: '22,97089958',
        altitude: '22',
        timezone: '2',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Tatoi Airport',
        city: 'Dekelia',
        country: 'Greece',
        iataCode: 'N',
        icaoCode: 'LGTT',
        latitude: '38,10889816',
        longitude: '23,78380013',
        altitude: '785',
        timezone: '2',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Dionysios Solomos Airport',
        city: 'Zakynthos',
        country: 'Greece',
        iataCode: 'ZTH',
        icaoCode: 'LGZA',
        latitude: '37,75090027',
        longitude: '20,88430023',
        altitude: '15',
        timezone: '2',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Budapest Ferenc Liszt International Airport',
        city: 'Budapest',
        country: 'Hungary',
        iataCode: 'BUD',
        icaoCode: 'LHBP',
        latitude: '47,43690109',
        longitude: '19,25559998',
        altitude: '495',
        timezone: '1',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Debrecen International Airport',
        city: 'Debrecen',
        country: 'Hungary',
        iataCode: 'DEB',
        icaoCode: 'LHDC',
        latitude: '47,48889923',
        longitude: '21,61529922',
        altitude: '359',
        timezone: '1',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Kecskemét Airport',
        city: 'Kecskemet',
        country: 'Hungary',
        iataCode: 'N',
        icaoCode: 'LHKE',
        latitude: '46,91749954',
        longitude: '19,74920082',
        altitude: '376',
        timezone: '1',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Nyíregyháza Airport',
        city: 'Nyirregyhaza',
        country: 'Hungary',
        iataCode: 'N',
        icaoCode: 'LHNY',
        latitude: '47,98389816',
        longitude: '21,6923008',
        altitude: '338',
        timezone: '1',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Őcsény Airport',
        city: 'Ocseny',
        country: 'Hungary',
        iataCode: 'N',
        icaoCode: 'LHOY',
        latitude: '46,30390167',
        longitude: '18,76919937',
        altitude: '295',
        timezone: '1',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Szentkirályszabadja Airport',
        city: 'Azentkilyszabadja',
        country: 'Hungary',
        iataCode: 'N',
        icaoCode: 'LHSA',
        latitude: '47,07789993',
        longitude: '17,96839905',
        altitude: '919',
        timezone: '1',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Szolnok Airport',
        city: 'Szolnok',
        country: 'Hungary',
        iataCode: 'N',
        icaoCode: 'LHSN',
        latitude: '47,122897',
        longitude: '20,2355',
        altitude: '322',
        timezone: '1',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Amendola Air Base',
        city: 'Amendola',
        country: 'Italy',
        iataCode: 'N',
        icaoCode: 'LIBA',
        latitude: '41,541401',
        longitude: '15,7181',
        altitude: '183',
        timezone: '1',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Crotone Airport',
        city: 'Crotone',
        country: 'Italy',
        iataCode: 'CRV',
        icaoCode: 'LIBC',
        latitude: '38,9972',
        longitude: '17,0802',
        altitude: '522',
        timezone: '1',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Bari Karol Wojtyła Airport',
        city: 'Bari',
        country: 'Italy',
        iataCode: 'BRI',
        icaoCode: 'LIBD',
        latitude: '41,138901',
        longitude: '16,760599',
        altitude: '177',
        timezone: '1',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Foggia Gino Lisa" Airport"',
        city: 'Foggia',
        country: 'Italy',
        iataCode: 'FOG',
        icaoCode: 'LIBF',
        latitude: '41,432899',
        longitude: '15,535',
        altitude: '265',
        timezone: '1',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Taranto-Grottaglie Marcello Arlotta" Airport"',
        city: 'Grottaglie',
        country: 'Italy',
        iataCode: 'TAR',
        icaoCode: 'LIBG',
        latitude: '40,517502',
        longitude: '17,4032',
        altitude: '215',
        timezone: '1',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Lecce Galatina Air Base',
        city: 'Lecce',
        country: 'Italy',
        iataCode: 'LCC',
        icaoCode: 'LIBN',
        latitude: '40,239201',
        longitude: '18,133301',
        altitude: '156',
        timezone: '1',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Pescara International Airport',
        city: 'Pescara',
        country: 'Italy',
        iataCode: 'PSR',
        icaoCode: 'LIBP',
        latitude: '42,431702',
        longitude: '14,1811',
        altitude: '48',
        timezone: '1',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Brindisi – Salento Airport',
        city: 'Brindisi',
        country: 'Italy',
        iataCode: 'BDS',
        icaoCode: 'LIBR',
        latitude: '40,6576',
        longitude: '17,947001',
        altitude: '47',
        timezone: '1',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Gioia Del Colle Air Base',
        city: 'Gioia Del Colle',
        country: 'Italy',
        iataCode: 'N',
        icaoCode: 'LIBV',
        latitude: '40,767799',
        longitude: '16,9333',
        altitude: '1187',
        timezone: '1',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Lamezia Terme Airport',
        city: 'Lamezia',
        country: 'Italy',
        iataCode: 'SUF',
        icaoCode: 'LICA',
        latitude: '38,905399',
        longitude: '16,2423',
        altitude: '39',
        timezone: '1',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Catania-Fontanarossa Airport',
        city: 'Catania',
        country: 'Italy',
        iataCode: 'CTA',
        icaoCode: 'LICC',
        latitude: '37,466801',
        longitude: '15,0664',
        altitude: '39',
        timezone: '1',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Lampedusa Airport',
        city: 'Lampedusa',
        country: 'Italy',
        iataCode: 'LMP',
        icaoCode: 'LICD',
        latitude: '35,497898',
        longitude: '12,6181',
        altitude: '70',
        timezone: '1',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Pantelleria Airport',
        city: 'Pantelleria',
        country: 'Italy',
        iataCode: 'PNL',
        icaoCode: 'LICG',
        latitude: '36,816502',
        longitude: '11,9689',
        altitude: '628',
        timezone: '1',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Falcone–Borsellino Airport',
        city: 'Palermo',
        country: 'Italy',
        iataCode: 'PMO',
        icaoCode: 'LICJ',
        latitude: '38,175999',
        longitude: '13,091',
        altitude: '65',
        timezone: '1',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Palermo-Boccadifalco Airport',
        city: 'Palermo',
        country: 'Italy',
        iataCode: 'N',
        icaoCode: 'LICP',
        latitude: '38,110802',
        longitude: '13,3133',
        altitude: '345',
        timezone: '1',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Reggio Calabria Airport',
        city: 'Reggio Calabria',
        country: 'Italy',
        iataCode: 'REG',
        icaoCode: 'LICR',
        latitude: '38,071201',
        longitude: '15,6516',
        altitude: '96',
        timezone: '1',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Vincenzo Florio Airport Trapani-Birgi',
        city: 'Trapani',
        country: 'Italy',
        iataCode: 'TPS',
        icaoCode: 'LICT',
        latitude: '37,9114',
        longitude: '12,488',
        altitude: '25',
        timezone: '1',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Sigonella Navy Air Base',
        city: 'Sigonella',
        country: 'Italy',
        iataCode: 'NSY',
        icaoCode: 'LICZ',
        latitude: '37,401699',
        longitude: '14,9224',
        altitude: '79',
        timezone: '1',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Alghero-Fertilia Airport',
        city: 'Alghero',
        country: 'Italy',
        iataCode: 'AHO',
        icaoCode: 'LIEA',
        latitude: '40,632099',
        longitude: '8,29077',
        altitude: '87',
        timezone: '1',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Decimomannu Air Base',
        city: 'Decimomannu',
        country: 'Italy',
        iataCode: 'DCI',
        icaoCode: 'LIED',
        latitude: '39,354198',
        longitude: '8,97248',
        altitude: '100',
        timezone: '1',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Cagliari Elmas Airport',
        city: 'Cagliari',
        country: 'Italy',
        iataCode: 'CAG',
        icaoCode: 'LIEE',
        latitude: '39,251499',
        longitude: '9,05428',
        altitude: '13',
        timezone: '1',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Olbia Costa Smeralda Airport',
        city: 'Olbia',
        country: 'Italy',
        iataCode: 'OLB',
        icaoCode: 'LIEO',
        latitude: '40,898701',
        longitude: '9,51763',
        altitude: '37',
        timezone: '1',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Tortolì Airport',
        city: 'Tortoli',
        country: 'Italy',
        iataCode: 'TTB',
        icaoCode: 'LIET',
        latitude: '39,9188',
        longitude: '9,68298',
        altitude: '23',
        timezone: '1',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Torino-Aeritalia Airport',
        city: 'Turin',
        country: 'Italy',
        iataCode: 'N',
        icaoCode: 'LIMA',
        latitude: '45,086399',
        longitude: '7,60337',
        altitude: '943',
        timezone: '1',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Milano-Bresso Airport',
        city: 'Milano',
        country: 'Italy',
        iataCode: 'N',
        icaoCode: 'LIMB',
        latitude: '45,542198',
        longitude: '9,20333',
        altitude: '484',
        timezone: '1',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Malpensa International Airport',
        city: 'Milano',
        country: 'Italy',
        iataCode: 'MXP',
        icaoCode: 'LIMC',
        latitude: '45,6306',
        longitude: '8,72811',
        altitude: '768',
        timezone: '1',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Il Caravaggio International Airport',
        city: 'Bergamo',
        country: 'Italy',
        iataCode: 'BGY',
        icaoCode: 'LIME',
        latitude: '45,673901',
        longitude: '9,70417',
        altitude: '782',
        timezone: '1',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Turin Airport',
        city: 'Torino',
        country: 'Italy',
        iataCode: 'TRN',
        icaoCode: 'LIMF',
        latitude: '45,200802',
        longitude: '7,64963',
        altitude: '989',
        timezone: '1',
        dst: 'E',
        type: 'airport',
    },
    {
        name: "Villanova D'Albenga International Airport",
        city: 'Albenga',
        country: 'Italy',
        iataCode: 'ALL',
        icaoCode: 'LIMG',
        latitude: '44,050598',
        longitude: '8,12743',
        altitude: '148',
        timezone: '1',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Genoa Cristoforo Colombo Airport',
        city: 'Genoa',
        country: 'Italy',
        iataCode: 'GOA',
        icaoCode: 'LIMJ',
        latitude: '44,4133',
        longitude: '8,8375',
        altitude: '13',
        timezone: '1',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Milano Linate Airport',
        city: 'Milan',
        country: 'Italy',
        iataCode: 'LIN',
        icaoCode: 'LIML',
        latitude: '45,445099',
        longitude: '9,27674',
        altitude: '353',
        timezone: '1',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Cameri Air Base [MIL]',
        city: 'Cameri',
        country: 'Italy',
        iataCode: 'N',
        icaoCode: 'LIMN',
        latitude: '45,529598',
        longitude: '8,66922',
        altitude: '586',
        timezone: '1',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Parma Airport',
        city: 'Parma',
        country: 'Italy',
        iataCode: 'PMF',
        icaoCode: 'LIMP',
        latitude: '44,824501',
        longitude: '10,2964',
        altitude: '161',
        timezone: '1',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Piacenza San Damiano Air Base',
        city: 'Piacenza',
        country: 'Italy',
        iataCode: 'QPZ',
        icaoCode: 'LIMS',
        latitude: '44,913101',
        longitude: '9,723323',
        altitude: '456',
        timezone: '1',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Cuneo International Airport',
        city: 'Cuneo',
        country: 'Italy',
        iataCode: 'CUF',
        icaoCode: 'LIMZ',
        latitude: '44,547001',
        longitude: '7,62322',
        altitude: '1267',
        timezone: '1',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Aviano Air Base',
        city: 'Aviano',
        country: 'Italy',
        iataCode: 'AVB',
        icaoCode: 'LIPA',
        latitude: '46,031898',
        longitude: '12,596503',
        altitude: '410',
        timezone: '1',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Bolzano Airport',
        city: 'Bolzano',
        country: 'Italy',
        iataCode: 'BZO',
        icaoCode: 'LIPB',
        latitude: '46,460201',
        longitude: '11,3264',
        altitude: '789',
        timezone: '1',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Cervia Air Base',
        city: 'Cervia',
        country: 'Italy',
        iataCode: 'N',
        icaoCode: 'LIPC',
        latitude: '44,224201',
        longitude: '12,3072',
        altitude: '18',
        timezone: '1',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Bologna Guglielmo Marconi Airport',
        city: 'Bologna',
        country: 'Italy',
        iataCode: 'BLQ',
        icaoCode: 'LIPE',
        latitude: '44,5354',
        longitude: '11,2887',
        altitude: '123',
        timezone: '1',
        dst: 'E',
        type: 'airport',
    },
    {
        name: "Treviso-Sant'Angelo Airport",
        city: 'Treviso',
        country: 'Italy',
        iataCode: 'TSF',
        icaoCode: 'LIPH',
        latitude: '45,648399',
        longitude: '12,1944',
        altitude: '59',
        timezone: '1',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Rivolto Air Base',
        city: 'Rivolto',
        country: 'Italy',
        iataCode: 'N',
        icaoCode: 'LIPI',
        latitude: '45,978699',
        longitude: '13,0493',
        altitude: '179',
        timezone: '1',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Forlì Airport',
        city: 'Forli',
        country: 'Italy',
        iataCode: 'FRL',
        icaoCode: 'LIPK',
        latitude: '44,194801',
        longitude: '12,0701',
        altitude: '97',
        timezone: '1',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Ghedi Air Base',
        city: 'Ghedi',
        country: 'Italy',
        iataCode: 'N',
        icaoCode: 'LIPL',
        latitude: '45,432201',
        longitude: '10,2677',
        altitude: '333',
        timezone: '1',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Verona-Boscomantico Airport',
        city: 'Verona',
        country: 'Italy',
        iataCode: 'N',
        icaoCode: 'LIPN',
        latitude: '45,472',
        longitude: '10,9279',
        altitude: '286',
        timezone: '1',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Brescia Airport',
        city: 'Brescia',
        country: 'Italy',
        iataCode: 'VBS',
        icaoCode: 'LIPO',
        latitude: '45,428902',
        longitude: '10,3306',
        altitude: '355',
        timezone: '1',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Trieste–Friuli Venezia Giulia Airport',
        city: 'Ronchi De Legionari',
        country: 'Italy',
        iataCode: 'TRS',
        icaoCode: 'LIPQ',
        latitude: '45,827499',
        longitude: '13,4722',
        altitude: '39',
        timezone: '1',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Federico Fellini International Airport',
        city: 'Rimini',
        country: 'Italy',
        iataCode: 'RMI',
        icaoCode: 'LIPR',
        latitude: '44,020302',
        longitude: '12,6117',
        altitude: '40',
        timezone: '1',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Istrana Air Base',
        city: 'Treviso',
        country: 'Italy',
        iataCode: 'N',
        icaoCode: 'LIPS',
        latitude: '45,684898',
        longitude: '12,0829',
        altitude: '137',
        timezone: '1',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Vicenza Airport',
        city: 'Vicenza',
        country: 'Italy',
        iataCode: 'VIC',
        icaoCode: 'LIPT',
        latitude: '45,573399',
        longitude: '11,5295',
        altitude: '128',
        timezone: '1',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Padova Airport',
        city: 'Padova',
        country: 'Italy',
        iataCode: 'QPA',
        icaoCode: 'LIPU',
        latitude: '45,395802',
        longitude: '11,8479',
        altitude: '44',
        timezone: '1',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Verona Villafranca Airport',
        city: 'Villafranca',
        country: 'Italy',
        iataCode: 'VRN',
        icaoCode: 'LIPX',
        latitude: '45,395699',
        longitude: '10,8885',
        altitude: '239',
        timezone: '1',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Venice Marco Polo Airport',
        city: 'Venice',
        country: 'Italy',
        iataCode: 'VCE',
        icaoCode: 'LIPZ',
        latitude: '45,505299',
        longitude: '12,3519',
        altitude: '7',
        timezone: '1',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Siena-Ampugnano Airport',
        city: 'Siena',
        country: 'Italy',
        iataCode: 'SAY',
        icaoCode: 'LIQS',
        latitude: '43,256302',
        longitude: '11,255',
        altitude: '634',
        timezone: '1',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Ciampino–G. B. Pastine International Airport',
        city: 'Rome',
        country: 'Italy',
        iataCode: 'CIA',
        icaoCode: 'LIRA',
        latitude: '41,7994',
        longitude: '12,5949',
        altitude: '427',
        timezone: '1',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Pratica Di Mare Air Base',
        city: 'Pratica Di Mare',
        country: 'Italy',
        iataCode: 'N',
        icaoCode: 'LIRE',
        latitude: '41,654499',
        longitude: '12,4452',
        altitude: '41',
        timezone: '1',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Leonardo da Vinci–Fiumicino Airport',
        city: 'Rome',
        country: 'Italy',
        iataCode: 'FCO',
        icaoCode: 'LIRF',
        latitude: '41,8002778',
        longitude: '12,2388889',
        altitude: '13',
        timezone: '1',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Guidonia Air Base',
        city: 'Guidonia',
        country: 'Italy',
        iataCode: 'N',
        icaoCode: 'LIRG',
        latitude: '41,990299',
        longitude: '12,7408',
        altitude: '289',
        timezone: '1',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Marina Di Campo Airport',
        city: 'Marina Di Campo',
        country: 'Italy',
        iataCode: 'EBA',
        icaoCode: 'LIRJ',
        latitude: '42,7603',
        longitude: '10,2394',
        altitude: '31',
        timezone: '1',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Latina Air Base',
        city: 'Latina',
        country: 'Italy',
        iataCode: 'QLT',
        icaoCode: 'LIRL',
        latitude: '41,5424',
        longitude: '12,909',
        altitude: '94',
        timezone: '1',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Grazzanise Airport',
        city: 'Grazzanise',
        country: 'Italy',
        iataCode: 'N',
        icaoCode: 'LIRM',
        latitude: '41,060799',
        longitude: '14,0819',
        altitude: '29',
        timezone: '1',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Naples International Airport',
        city: 'Naples',
        country: 'Italy',
        iataCode: 'NAP',
        icaoCode: 'LIRN',
        latitude: '40,886002',
        longitude: '14,2908',
        altitude: '294',
        timezone: '1',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Pisa International Airport',
        city: 'Pisa',
        country: 'Italy',
        iataCode: 'PSA',
        icaoCode: 'LIRP',
        latitude: '43,683899',
        longitude: '10,3927',
        altitude: '6',
        timezone: '1',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Peretola Airport',
        city: 'Florence',
        country: 'Italy',
        iataCode: 'FLR',
        icaoCode: 'LIRQ',
        latitude: '43,810001',
        longitude: '11,2051',
        altitude: '142',
        timezone: '1',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Grosseto Air Base',
        city: 'Grosseto',
        country: 'Italy',
        iataCode: 'GRS',
        icaoCode: 'LIRS',
        latitude: '42,759701',
        longitude: '11,0719',
        altitude: '17',
        timezone: '1',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Urbe Airport',
        city: 'Rome',
        country: 'Italy',
        iataCode: 'N',
        icaoCode: 'LIRU',
        latitude: '41,9519',
        longitude: '12,4989',
        altitude: '55',
        timezone: '1',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Viterbo Airport',
        city: 'Viterbo',
        country: 'Italy',
        iataCode: 'N',
        icaoCode: 'LIRV',
        latitude: '42,430199',
        longitude: '12,0642',
        altitude: '992',
        timezone: '1',
        dst: 'E',
        type: 'airport',
    },
    {
        name: "Perugia San Francesco d'Assisi – Umbria International Airport",
        city: 'Perugia',
        country: 'Italy',
        iataCode: 'PEG',
        icaoCode: 'LIRZ',
        latitude: '43,095901',
        longitude: '12,5132',
        altitude: '697',
        timezone: '1',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Cerklje Airport',
        city: 'Cerklje',
        country: 'Slovenia',
        iataCode: 'N',
        icaoCode: 'LJCE',
        latitude: '45,90000153',
        longitude: '15,5302',
        altitude: '510',
        timezone: '1',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Ljubljana Jože Pučnik Airport',
        city: 'Ljubljana',
        country: 'Slovenia',
        iataCode: 'LJU',
        icaoCode: 'LJLJ',
        latitude: '46,223701',
        longitude: '14,4576',
        altitude: '1273',
        timezone: '1',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Maribor Airport',
        city: 'Maribor',
        country: 'Slovenia',
        iataCode: 'MBX',
        icaoCode: 'LJMB',
        latitude: '46,47990036',
        longitude: '15,68610001',
        altitude: '876',
        timezone: '1',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Portoroz Airport',
        city: 'Portoroz',
        country: 'Slovenia',
        iataCode: 'POW',
        icaoCode: 'LJPZ',
        latitude: '45,47340012',
        longitude: '13,61499977',
        altitude: '7',
        timezone: '1',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Slovenj Gradec Airport',
        city: 'Slovenj Gradec',
        country: 'Slovenia',
        iataCode: 'N',
        icaoCode: 'LJSG',
        latitude: '46,47200012',
        longitude: '15,11699963',
        altitude: '1645',
        timezone: '1',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'České Budějovice Airport',
        city: 'Ceske Budejovice',
        country: 'Czech Republic',
        iataCode: 'N',
        icaoCode: 'LKCS',
        latitude: '48,94639969',
        longitude: '14,42749977',
        altitude: '1417',
        timezone: '1',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Čáslav Air Base',
        city: 'Caslav',
        country: 'Czech Republic',
        iataCode: 'N',
        icaoCode: 'LKCV',
        latitude: '49,93970108',
        longitude: '15,3817997',
        altitude: '794',
        timezone: '1',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Hradec Králové Airport',
        city: 'Hradec Kralove',
        country: 'Czech Republic',
        iataCode: 'N',
        icaoCode: 'LKHK',
        latitude: '50,253201',
        longitude: '15,8452',
        altitude: '791',
        timezone: '1',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Hořovice Airport',
        city: 'Horovice',
        country: 'Czech Republic',
        iataCode: 'N',
        icaoCode: 'LKHV',
        latitude: '49,84809875',
        longitude: '13,89350033',
        altitude: '1214',
        timezone: '1',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Kbely Air Base',
        city: 'Praha',
        country: 'Czech Republic',
        iataCode: 'N',
        icaoCode: 'LKKB',
        latitude: '50,12139893',
        longitude: '14,54360008',
        altitude: '939',
        timezone: '1',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Kunovice Airport',
        city: 'Kunovice',
        country: 'Czech Republic',
        iataCode: 'N',
        icaoCode: 'LKKU',
        latitude: '49,02939987',
        longitude: '17,43969917',
        altitude: '581',
        timezone: '1',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Karlovy Vary International Airport',
        city: 'Karlovy Vary',
        country: 'Czech Republic',
        iataCode: 'KLV',
        icaoCode: 'LKKV',
        latitude: '50,20299911',
        longitude: '12,91499996',
        altitude: '1989',
        timezone: '1',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Plzeň-Líně Airport',
        city: 'Line',
        country: 'Czech Republic',
        iataCode: 'N',
        icaoCode: 'LKLN',
        latitude: '49,67520142',
        longitude: '13,27460003',
        altitude: '1188',
        timezone: '1',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Mnichovo Hradiště Airport',
        city: 'Mnichovo Hradiste',
        country: 'Czech Republic',
        iataCode: 'N',
        icaoCode: 'LKMH',
        latitude: '50,54019928',
        longitude: '15,00660038',
        altitude: '800',
        timezone: '1',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Ostrava Leos Janáček Airport',
        city: 'Ostrava',
        country: 'Czech Republic',
        iataCode: 'OSR',
        icaoCode: 'LKMT',
        latitude: '49,69630051',
        longitude: '18,11109924',
        altitude: '844',
        timezone: '1',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Náměšť Air Base',
        city: 'Namest',
        country: 'Czech Republic',
        iataCode: 'N',
        icaoCode: 'LKNA',
        latitude: '49,16579819',
        longitude: '16,12490082',
        altitude: '1548',
        timezone: '1',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Pardubice Airport',
        city: 'Pardubice',
        country: 'Czech Republic',
        iataCode: 'PED',
        icaoCode: 'LKPD',
        latitude: '50,01340103',
        longitude: '15,73859978',
        altitude: '741',
        timezone: '1',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Pribram Airport',
        city: 'Pribram',
        country: 'Czech Republic',
        iataCode: 'N',
        icaoCode: 'LKPM',
        latitude: '49,7201004',
        longitude: '14,10060024',
        altitude: '1529',
        timezone: '1',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Přerov Air Base',
        city: 'Prerov',
        country: 'Czech Republic',
        iataCode: 'PRV',
        icaoCode: 'LKPO',
        latitude: '49,42580032',
        longitude: '17,40469933',
        altitude: '676',
        timezone: '1',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Václav Havel Airport Prague',
        city: 'Prague',
        country: 'Czech Republic',
        iataCode: 'PRG',
        icaoCode: 'LKPR',
        latitude: '50,1008',
        longitude: '14,26',
        altitude: '1247',
        timezone: '1',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Brno-Tuřany Airport',
        city: 'Brno',
        country: 'Czech Republic',
        iataCode: 'BRQ',
        icaoCode: 'LKTB',
        latitude: '49,15129852',
        longitude: '16,69440079',
        altitude: '778',
        timezone: '1',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Vodochody Airport',
        city: 'Vodochody',
        country: 'Czech Republic',
        iataCode: 'N',
        icaoCode: 'LKVO',
        latitude: '50,216599',
        longitude: '14,3958',
        altitude: '919',
        timezone: '1',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Ben Gurion International Airport',
        city: 'Tel-aviv',
        country: 'Israel',
        iataCode: 'TLV',
        icaoCode: 'LLBG',
        latitude: '32,01139832',
        longitude: '34,88669968',
        altitude: '135',
        timezone: '2',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Beersheba (Teyman) Airport',
        city: 'Beer-sheba',
        country: 'Israel',
        iataCode: 'BEV',
        icaoCode: 'LLBS',
        latitude: '31,28700066',
        longitude: '34,72299957',
        altitude: '656',
        timezone: '2',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Tel Nof Air Base',
        city: 'Tel-nof',
        country: 'Israel',
        iataCode: 'N',
        icaoCode: 'LLEK',
        latitude: '31,83950043',
        longitude: '34,82180023',
        altitude: '193',
        timezone: '2',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Ein Shemer Airfield',
        city: 'Eyn-shemer',
        country: 'Israel',
        iataCode: 'N',
        icaoCode: 'LLES',
        latitude: '32,44079971',
        longitude: '35,00770187',
        altitude: '95',
        timezone: '2',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Eilat Airport',
        city: 'Elat',
        country: 'Israel',
        iataCode: 'ETH',
        icaoCode: 'LLET',
        latitude: '29,56130028',
        longitude: '34,96009827',
        altitude: '42',
        timezone: '2',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Ein Yahav Airfield',
        city: 'Eyn-yahav',
        country: 'Israel',
        iataCode: 'N',
        icaoCode: 'LLEY',
        latitude: '30,62170029',
        longitude: '35,20330048',
        altitude: '-164',
        timezone: '2',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Haifa International Airport',
        city: 'Haifa',
        country: 'Israel',
        iataCode: 'HFA',
        icaoCode: 'LLHA',
        latitude: '32,80939865',
        longitude: '35,04309845',
        altitude: '28',
        timezone: '2',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Hatzor Air Base',
        city: 'Haztor',
        country: 'Israel',
        iataCode: 'N',
        icaoCode: 'LLHS',
        latitude: '31,76250076',
        longitude: '34,72719955',
        altitude: '148',
        timezone: '2',
        dst: 'E',
        type: 'airport',
    },
    {
        name: "Ben Ya'akov Airport",
        city: 'Rosh Pina',
        country: 'Israel',
        iataCode: 'RPN',
        icaoCode: 'LLIB',
        latitude: '32,98099899',
        longitude: '35,57189941',
        altitude: '922',
        timezone: '2',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Megiddo Airport',
        city: 'Megido Airstrip',
        country: 'Israel',
        iataCode: 'N',
        icaoCode: 'LLMG',
        latitude: '32,59730148',
        longitude: '35,22880173',
        altitude: '200',
        timezone: '2',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Bar Yehuda Airfield',
        city: 'Metzada',
        country: 'Israel',
        iataCode: 'N',
        icaoCode: 'LLMZ',
        latitude: '31,32819939',
        longitude: '35,3885994',
        altitude: '-1266',
        timezone: '2',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Nevatim Air Base',
        city: 'Nevatim',
        country: 'Israel',
        iataCode: 'N',
        icaoCode: 'LLNV',
        latitude: '31,20829964',
        longitude: '35,01229858',
        altitude: '1330',
        timezone: '2',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Ovda International Airport',
        city: 'Ovda',
        country: 'Israel',
        iataCode: 'VDA',
        icaoCode: 'LLOV',
        latitude: '29,94029999',
        longitude: '34,93579865',
        altitude: '1492',
        timezone: '2',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Ramat David Air Base',
        city: 'Ramat David',
        country: 'Israel',
        iataCode: 'N',
        icaoCode: 'LLRD',
        latitude: '32,6651001',
        longitude: '35,17950058',
        altitude: '185',
        timezone: '2',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Ramon Air Base',
        city: 'Ramon',
        country: 'Israel',
        iataCode: 'N',
        icaoCode: 'LLRM',
        latitude: '30,77610016',
        longitude: '34,66669846',
        altitude: '2126',
        timezone: '2',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Sde Dov Airport',
        city: 'Tel-aviv',
        country: 'Israel',
        iataCode: 'SDV',
        icaoCode: 'LLSD',
        latitude: '32,11470032',
        longitude: '34,78219986',
        altitude: '43',
        timezone: '2',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Malta International Airport',
        city: 'Malta',
        country: 'Malta',
        iataCode: 'MLA',
        icaoCode: 'LMML',
        latitude: '35,857498',
        longitude: '14,4775',
        altitude: '300',
        timezone: '1',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Wiener Neustadt East Airport',
        city: 'Wiener Neustadt Ost',
        country: 'Austria',
        iataCode: 'N',
        icaoCode: 'LOAN',
        latitude: '47,84329987',
        longitude: '16,26009941',
        altitude: '896',
        timezone: '1',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Wels Airport',
        city: 'Wels',
        country: 'Austria',
        iataCode: 'N',
        icaoCode: 'LOLW',
        latitude: '48,18330002',
        longitude: '14,04090023',
        altitude: '1043',
        timezone: '1',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Graz Airport',
        city: 'Graz',
        country: 'Austria',
        iataCode: 'GRZ',
        icaoCode: 'LOWG',
        latitude: '46,99110031',
        longitude: '15,43959999',
        altitude: '1115',
        timezone: '1',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Innsbruck Airport',
        city: 'Innsbruck',
        country: 'Austria',
        iataCode: 'INN',
        icaoCode: 'LOWI',
        latitude: '47,260201',
        longitude: '11,344',
        altitude: '1907',
        timezone: '1',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Linz Hörsching Airport',
        city: 'Linz',
        country: 'Austria',
        iataCode: 'LNZ',
        icaoCode: 'LOWL',
        latitude: '48,2332',
        longitude: '14,1875',
        altitude: '980',
        timezone: '1',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Salzburg Airport',
        city: 'Salzburg',
        country: 'Austria',
        iataCode: 'SZG',
        icaoCode: 'LOWS',
        latitude: '47,79330063',
        longitude: '13,00430012',
        altitude: '1411',
        timezone: '1',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Vienna International Airport',
        city: 'Vienna',
        country: 'Austria',
        iataCode: 'VIE',
        icaoCode: 'LOWW',
        latitude: '48,11029816',
        longitude: '16,56970024',
        altitude: '600',
        timezone: '1',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Hinterstoisser Air Base',
        city: 'Zeltweg',
        country: 'Austria',
        iataCode: 'N',
        icaoCode: 'LOXZ',
        latitude: '47,202801',
        longitude: '14,7442',
        altitude: '2264',
        timezone: '1',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Alverca Airport',
        city: 'Alverca',
        country: 'Portugal',
        iataCode: 'N',
        icaoCode: 'LPAR',
        latitude: '38,883301',
        longitude: '-9,0301',
        altitude: '11',
        timezone: '0',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Santa Maria Airport',
        city: 'Santa Maria (island)',
        country: 'Portugal',
        iataCode: 'SMA',
        icaoCode: 'LPAZ',
        latitude: '36,97140121',
        longitude: '-25,17060089',
        altitude: '308',
        timezone: '-1',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Bragança Airport',
        city: 'Braganca',
        country: 'Portugal',
        iataCode: 'BGC',
        icaoCode: 'LPBG',
        latitude: '41,85779953',
        longitude: '-6,707129955',
        altitude: '2241',
        timezone: '0',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Beja International Airport',
        city: 'Beja (madeira)',
        country: 'Portugal',
        iataCode: 'N',
        icaoCode: 'LPBJ',
        latitude: '38,07889938',
        longitude: '-7,932400227',
        altitude: '636',
        timezone: '0',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Braga Municipal Aerodrome',
        city: 'Braga',
        country: 'Portugal',
        iataCode: 'N',
        icaoCode: 'LPBR',
        latitude: '41,58710098',
        longitude: '-8,445139885',
        altitude: '247',
        timezone: '0',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Coimbra Airport',
        city: 'Coimba',
        country: 'Portugal',
        iataCode: 'N',
        icaoCode: 'LPCO',
        latitude: '40,158758',
        longitude: '-8,470815',
        altitude: '587',
        timezone: '0',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Cascais Airport',
        city: 'Cascais',
        country: 'Portugal',
        iataCode: 'N',
        icaoCode: 'LPCS',
        latitude: '38,72499847',
        longitude: '-9,355230331',
        altitude: '325',
        timezone: '0',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Covilhã Airport',
        city: 'Covilha',
        country: 'Portugal',
        iataCode: 'N',
        icaoCode: 'LPCV',
        latitude: '40,264801',
        longitude: '-7,47996',
        altitude: '1572',
        timezone: '0',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Évora Airport',
        city: 'Evora',
        country: 'Portugal',
        iataCode: 'N',
        icaoCode: 'LPEV',
        latitude: '38,533501',
        longitude: '-7,88964',
        altitude: '807',
        timezone: '0',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Flores Airport',
        city: 'Flores',
        country: 'Portugal',
        iataCode: 'FLW',
        icaoCode: 'LPFL',
        latitude: '39,45529938',
        longitude: '-31,13139915',
        altitude: '112',
        timezone: '-1',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Faro Airport',
        city: 'Faro',
        country: 'Portugal',
        iataCode: 'FAO',
        icaoCode: 'LPFR',
        latitude: '37,01440048',
        longitude: '-7,965909958',
        altitude: '24',
        timezone: '0',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Graciosa Airport',
        city: 'Graciosa Island',
        country: 'Portugal',
        iataCode: 'GRW',
        icaoCode: 'LPGR',
        latitude: '39,09220123',
        longitude: '-28,02980042',
        altitude: '86',
        timezone: '-1',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Horta Airport',
        city: 'Horta',
        country: 'Portugal',
        iataCode: 'HOR',
        icaoCode: 'LPHR',
        latitude: '38,51990128',
        longitude: '-28,71590042',
        altitude: '118',
        timezone: '-1',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Lajes Field',
        city: 'Lajes (terceira Island)',
        country: 'Portugal',
        iataCode: 'TER',
        icaoCode: 'LPLA',
        latitude: '38,76179886',
        longitude: '-27,09079933',
        altitude: '180',
        timezone: '-1',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Monte Real Air Base',
        city: 'Monte Real',
        country: 'Portugal',
        iataCode: 'N',
        icaoCode: 'LPMR',
        latitude: '39,8283333',
        longitude: '-8,8875',
        altitude: '187',
        timezone: '0',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Montijo Airport',
        city: 'Montijo',
        country: 'Portugal',
        iataCode: 'N',
        icaoCode: 'LPMT',
        latitude: '38,70389938',
        longitude: '-9,035920143',
        altitude: '46',
        timezone: '0',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Ovar Airport',
        city: 'Ovar',
        country: 'Portugal',
        iataCode: 'N',
        icaoCode: 'LPOV',
        latitude: '40,91590118',
        longitude: '-8,6459198',
        altitude: '56',
        timezone: '0',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'João Paulo II Airport',
        city: 'Ponta Delgada',
        country: 'Portugal',
        iataCode: 'PDL',
        icaoCode: 'LPPD',
        latitude: '37,74119949',
        longitude: '-25,69790077',
        altitude: '259',
        timezone: '-1',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Pico Airport',
        city: 'Pico',
        country: 'Portugal',
        iataCode: 'PIX',
        icaoCode: 'LPPI',
        latitude: '38,5542984',
        longitude: '-28,44129944',
        altitude: '109',
        timezone: '-1',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Portimão Airport',
        city: 'Portimao',
        country: 'Portugal',
        iataCode: 'N',
        icaoCode: 'LPPM',
        latitude: '37,14929962',
        longitude: '-8,583959579',
        altitude: '5',
        timezone: '0',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Francisco de Sá Carneiro Airport',
        city: 'Porto',
        country: 'Portugal',
        iataCode: 'OPO',
        icaoCode: 'LPPR',
        latitude: '41,24810028',
        longitude: '-8,681389809',
        altitude: '228',
        timezone: '0',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Porto Santo Airport',
        city: 'Porto Santo',
        country: 'Portugal',
        iataCode: 'PXO',
        icaoCode: 'LPPS',
        latitude: '33,07339859',
        longitude: '-16,35000038',
        altitude: '341',
        timezone: '0',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Lisbon Portela Airport',
        city: 'Lisbon',
        country: 'Portugal',
        iataCode: 'LIS',
        icaoCode: 'LPPT',
        latitude: '38,78129959',
        longitude: '-9,135919571',
        altitude: '374',
        timezone: '0',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'São Jorge Airport',
        city: 'Sao Jorge Island',
        country: 'Portugal',
        iataCode: 'SJZ',
        icaoCode: 'LPSJ',
        latitude: '38,66550064',
        longitude: '-28,17580032',
        altitude: '311',
        timezone: '-1',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Sintra Airport',
        city: 'Sintra',
        country: 'Portugal',
        iataCode: 'N',
        icaoCode: 'LPST',
        latitude: '38,83110046',
        longitude: '-9,339550018',
        altitude: '440',
        timezone: '0',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Tancos Airbase',
        city: 'Tancos',
        country: 'Portugal',
        iataCode: 'N',
        icaoCode: 'LPTN',
        latitude: '39,475101',
        longitude: '-8,36458',
        altitude: '266',
        timezone: '0',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Vila Real Airport',
        city: 'Vila Real',
        country: 'Portugal',
        iataCode: 'VRL',
        icaoCode: 'LPVR',
        latitude: '41,27429962',
        longitude: '-7,720469952',
        altitude: '1805',
        timezone: '0',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Viseu Airport',
        city: 'Viseu',
        country: 'Portugal',
        iataCode: 'N',
        icaoCode: 'LPVZ',
        latitude: '40,7254982',
        longitude: '-7,888989925',
        altitude: '2060',
        timezone: '0',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Mostar International Airport',
        city: 'Mostar',
        country: 'Bosnia and Herzegovina',
        iataCode: 'OMO',
        icaoCode: 'LQMO',
        latitude: '43,28290176',
        longitude: '17,84589958',
        altitude: '156',
        timezone: '1',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Sarajevo International Airport',
        city: 'Sarajevo',
        country: 'Bosnia and Herzegovina',
        iataCode: 'SJJ',
        icaoCode: 'LQSA',
        latitude: '43,82460022',
        longitude: '18,3314991',
        altitude: '1708',
        timezone: '1',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Arad International Airport',
        city: 'Arad',
        country: 'Romania',
        iataCode: 'ARW',
        icaoCode: 'LRAR',
        latitude: '46,17660141',
        longitude: '21,26199913',
        altitude: '352',
        timezone: '2',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Bacău Airport',
        city: 'Bacau',
        country: 'Romania',
        iataCode: 'BCM',
        icaoCode: 'LRBC',
        latitude: '46,52190018',
        longitude: '26,9102993',
        altitude: '607',
        timezone: '2',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Tautii Magheraus Airport',
        city: 'Baia Mare',
        country: 'Romania',
        iataCode: 'BAY',
        icaoCode: 'LRBM',
        latitude: '47,65840149',
        longitude: '23,46999931',
        altitude: '605',
        timezone: '2',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Băneasa International Airport',
        city: 'Bucharest',
        country: 'Romania',
        iataCode: 'BBU',
        icaoCode: 'LRBS',
        latitude: '44,50320053',
        longitude: '26,10210037',
        altitude: '297',
        timezone: '2',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Mihail Kogălniceanu International Airport',
        city: 'Constanta',
        country: 'Romania',
        iataCode: 'CND',
        icaoCode: 'LRCK',
        latitude: '44,36220169',
        longitude: '28,48830032',
        altitude: '353',
        timezone: '2',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Cluj-Napoca International Airport',
        city: 'Cluj-napoca',
        country: 'Romania',
        iataCode: 'CLJ',
        icaoCode: 'LRCL',
        latitude: '46,78519821',
        longitude: '23,68619919',
        altitude: '1036',
        timezone: '2',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Caransebeş Airport',
        city: 'Caransebes',
        country: 'Romania',
        iataCode: 'CSB',
        icaoCode: 'LRCS',
        latitude: '45,41999817',
        longitude: '22,25329971',
        altitude: '866',
        timezone: '2',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Craiova Airport',
        city: 'Craiova',
        country: 'Romania',
        iataCode: 'CRA',
        icaoCode: 'LRCV',
        latitude: '44,31809998',
        longitude: '23,8885994',
        altitude: '626',
        timezone: '2',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Iaşi Airport',
        city: 'Iasi',
        country: 'Romania',
        iataCode: 'IAS',
        icaoCode: 'LRIA',
        latitude: '47,17850113',
        longitude: '27,62059975',
        altitude: '397',
        timezone: '2',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Oradea International Airport',
        city: 'Oradea',
        country: 'Romania',
        iataCode: 'OMR',
        icaoCode: 'LROD',
        latitude: '47,02529907',
        longitude: '21,90250015',
        altitude: '465',
        timezone: '2',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Henri Coandă International Airport',
        city: 'Bucharest',
        country: 'Romania',
        iataCode: 'OTP',
        icaoCode: 'LROP',
        latitude: '44,5711111',
        longitude: '26,085',
        altitude: '314',
        timezone: '2',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Sibiu International Airport',
        city: 'Sibiu',
        country: 'Romania',
        iataCode: 'SBZ',
        icaoCode: 'LRSB',
        latitude: '45,78559875',
        longitude: '24,09129906',
        altitude: '1496',
        timezone: '2',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Satu Mare Airport',
        city: 'Satu Mare',
        country: 'Romania',
        iataCode: 'SUJ',
        icaoCode: 'LRSM',
        latitude: '47,70330048',
        longitude: '22,88570023',
        altitude: '405',
        timezone: '2',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Suceava Stefan cel Mare Airport',
        city: 'Suceava',
        country: 'Romania',
        iataCode: 'SCV',
        icaoCode: 'LRSV',
        latitude: '47,6875',
        longitude: '26,35409927',
        altitude: '1375',
        timezone: '2',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Tulcea Airport',
        city: 'Tulcea',
        country: 'Romania',
        iataCode: 'TCE',
        icaoCode: 'LRTC',
        latitude: '45,0625',
        longitude: '28,71430016',
        altitude: '200',
        timezone: '2',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Transilvania Târgu Mureş International Airport',
        city: 'Tirgu Mures',
        country: 'Romania',
        iataCode: 'TGM',
        icaoCode: 'LRTM',
        latitude: '46,46770096',
        longitude: '24,41250038',
        altitude: '963',
        timezone: '2',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Timişoara Traian Vuia Airport',
        city: 'Timisoara',
        country: 'Romania',
        iataCode: 'TSR',
        icaoCode: 'LRTR',
        latitude: '45,80989838',
        longitude: '21,33790016',
        altitude: '348',
        timezone: '2',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Les Eplatures Airport',
        city: 'Les Eplatures',
        country: 'Switzerland',
        iataCode: 'N',
        icaoCode: 'LSGC',
        latitude: '47,08390045',
        longitude: '6,792840004',
        altitude: '3368',
        timezone: '1',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Geneva Cointrin International Airport',
        city: 'Geneva',
        country: 'Switzerland',
        iataCode: 'GVA',
        icaoCode: 'LSGG',
        latitude: '46,23809814',
        longitude: '6,108950138',
        altitude: '1411',
        timezone: '1',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Saanen Airport',
        city: 'Saanen',
        country: 'Switzerland',
        iataCode: 'N',
        icaoCode: 'LSGK',
        latitude: '46,48749924',
        longitude: '7,250830173',
        altitude: '3307',
        timezone: '1',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Sion Airport',
        city: 'Sion',
        country: 'Switzerland',
        iataCode: 'SIR',
        icaoCode: 'LSGS',
        latitude: '46,21960068',
        longitude: '7,326759815',
        altitude: '1585',
        timezone: '1',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Alpnach Air Base',
        city: 'Alpnach',
        country: 'Switzerland',
        iataCode: 'N',
        icaoCode: 'LSMA',
        latitude: '46,943901',
        longitude: '8,28417',
        altitude: '1460',
        timezone: '1',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Dübendorf Air Base',
        city: 'Dubendorf',
        country: 'Switzerland',
        iataCode: 'N',
        icaoCode: 'LSMD',
        latitude: '47,39860153',
        longitude: '8,648229599',
        altitude: '1470',
        timezone: '1',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Emmen Air Base',
        city: 'Emmen',
        country: 'Switzerland',
        iataCode: 'EML',
        icaoCode: 'LSME',
        latitude: '47,092444',
        longitude: '8,305184',
        altitude: '1400',
        timezone: '1',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Mollis Airport',
        city: 'Mollis',
        country: 'Switzerland',
        iataCode: 'N',
        icaoCode: 'LSMF',
        latitude: '47,07889938',
        longitude: '9,064829826',
        altitude: '1485',
        timezone: '1',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Meiringen Airport',
        city: 'Meiringen',
        country: 'Switzerland',
        iataCode: 'N',
        icaoCode: 'LSMM',
        latitude: '46,74330139',
        longitude: '8,109999657',
        altitude: '1895',
        timezone: '1',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Payerne Air Base',
        city: 'Payerne',
        country: 'Switzerland',
        iataCode: 'N',
        icaoCode: 'LSMP',
        latitude: '46,8432',
        longitude: '6,915060043',
        altitude: '1465',
        timezone: '1',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Lugano Airport',
        city: 'Lugano',
        country: 'Switzerland',
        iataCode: 'LUG',
        icaoCode: 'LSZA',
        latitude: '46,00429916',
        longitude: '8,910579681',
        altitude: '915',
        timezone: '1',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Bern Belp Airport',
        city: 'Bern',
        country: 'Switzerland',
        iataCode: 'BRN',
        icaoCode: 'LSZB',
        latitude: '46,91410065',
        longitude: '7,497149944',
        altitude: '1674',
        timezone: '1',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Grenchen Airport',
        city: 'Grenchen',
        country: 'Switzerland',
        iataCode: 'N',
        icaoCode: 'LSZG',
        latitude: '47,18159866',
        longitude: '7,417190075',
        altitude: '1411',
        timezone: '1',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Zürich Airport',
        city: 'Zurich',
        country: 'Switzerland',
        iataCode: 'ZRH',
        icaoCode: 'LSZH',
        latitude: '47,46469879',
        longitude: '8,54916954',
        altitude: '1416',
        timezone: '1',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'St Gallen Altenrhein Airport',
        city: 'Altenrhein',
        country: 'Switzerland',
        iataCode: 'ACH',
        icaoCode: 'LSZR',
        latitude: '47,48500061',
        longitude: '9,560770035',
        altitude: '1306',
        timezone: '1',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Samedan Airport',
        city: 'Samedan',
        country: 'Switzerland',
        iataCode: 'SMV',
        icaoCode: 'LSZS',
        latitude: '46,53409958',
        longitude: '9,884110451',
        altitude: '5600',
        timezone: '1',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Güvercinlik Airport',
        city: 'Ankara',
        country: 'Turkey',
        iataCode: 'N',
        icaoCode: 'LTAB',
        latitude: '39,93500137',
        longitude: '32,74079895',
        altitude: '2687',
        timezone: '2',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Esenboğa International Airport',
        city: 'Ankara',
        country: 'Turkey',
        iataCode: 'ESB',
        icaoCode: 'LTAC',
        latitude: '40,12810135',
        longitude: '32,99509811',
        altitude: '3125',
        timezone: '3',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Etimesgut Air Base',
        city: 'Ankara',
        country: 'Turkey',
        iataCode: 'ANK',
        icaoCode: 'LTAD',
        latitude: '39,94979858',
        longitude: '32,68859863',
        altitude: '2653',
        timezone: '3',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Akıncı Air Base',
        city: 'Ankara',
        country: 'Turkey',
        iataCode: 'N',
        icaoCode: 'LTAE',
        latitude: '40,07889938',
        longitude: '32,56560135',
        altitude: '2767',
        timezone: '3',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Adana Airport',
        city: 'Adana',
        country: 'Turkey',
        iataCode: 'ADA',
        icaoCode: 'LTAF',
        latitude: '36,98220062',
        longitude: '35,28039932',
        altitude: '65',
        timezone: '3',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'İncirlik Air Base',
        city: 'Adana',
        country: 'Turkey',
        iataCode: 'UAB',
        icaoCode: 'LTAG',
        latitude: '37,0021019',
        longitude: '35,42589951',
        altitude: '238',
        timezone: '3',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Afyon Airport',
        city: 'Afyon',
        country: 'Turkey',
        iataCode: 'AFY',
        icaoCode: 'LTAH',
        latitude: '38,72639847',
        longitude: '30,60110092',
        altitude: '3310',
        timezone: '3',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Antalya International Airport',
        city: 'Antalya',
        country: 'Turkey',
        iataCode: 'AYT',
        icaoCode: 'LTAI',
        latitude: '36,898701',
        longitude: '30,800501',
        altitude: '177',
        timezone: '3',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Gaziantep International Airport',
        city: 'Gaziantep',
        country: 'Turkey',
        iataCode: 'GZT',
        icaoCode: 'LTAJ',
        latitude: '36,94720078',
        longitude: '37,47869873',
        altitude: '2315',
        timezone: '3',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'İskenderun Airport',
        city: 'Iskenderun',
        country: 'Turkey',
        iataCode: 'N',
        icaoCode: 'LTAK',
        latitude: '36,57445526',
        longitude: '36,15341949',
        altitude: '25',
        timezone: '3',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Konya Airport',
        city: 'Konya',
        country: 'Turkey',
        iataCode: 'KYA',
        icaoCode: 'LTAN',
        latitude: '37,979',
        longitude: '32,561901',
        altitude: '3392',
        timezone: '3',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Malatya Tulga Airport',
        city: 'Malatya',
        country: 'Turkey',
        iataCode: 'MLX',
        icaoCode: 'LTAO',
        latitude: '38,353699',
        longitude: '38,253899',
        altitude: '3016',
        timezone: '3',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Amasya Merzifon Airport',
        city: 'Merzifon',
        country: 'Turkey',
        iataCode: 'MZH',
        icaoCode: 'LTAP',
        latitude: '40,829399',
        longitude: '35,521999',
        altitude: '1758',
        timezone: '3',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Sivas Nuri Demirağ Airport',
        city: 'Sivas',
        country: 'Turkey',
        iataCode: 'VAS',
        icaoCode: 'LTAR',
        latitude: '39,813801',
        longitude: '36,9035',
        altitude: '5239',
        timezone: '3',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Malatya Erhaç Airport',
        city: 'Malatya',
        country: 'Turkey',
        iataCode: 'N',
        icaoCode: 'LTAT',
        latitude: '38,43529892',
        longitude: '38,0909996',
        altitude: '2828',
        timezone: '3',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Kayseri Erkilet Airport',
        city: 'Kayseri',
        country: 'Turkey',
        iataCode: 'ASR',
        icaoCode: 'LTAU',
        latitude: '38,770401',
        longitude: '35,49539948',
        altitude: '3463',
        timezone: '3',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Sivrihisar Airport',
        city: 'Sivrihisar',
        country: 'Turkey',
        iataCode: 'N',
        icaoCode: 'LTAV',
        latitude: '39,45149994',
        longitude: '31,36529922',
        altitude: '3185',
        timezone: '3',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Tokat Airport',
        city: 'Tokat',
        country: 'Turkey',
        iataCode: 'N',
        icaoCode: 'LTAW',
        latitude: '40,30743027',
        longitude: '36,36740875',
        altitude: '1831',
        timezone: '3',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Çardak Airport',
        city: 'Denizli',
        country: 'Turkey',
        iataCode: 'DNZ',
        icaoCode: 'LTAY',
        latitude: '37,78559875',
        longitude: '29,70129967',
        altitude: '2795',
        timezone: '3',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Atatürk International Airport',
        city: 'Istanbul',
        country: 'Turkey',
        iataCode: 'IST',
        icaoCode: 'LTBA',
        latitude: '40,97689819',
        longitude: '28,81459999',
        altitude: '163',
        timezone: '3',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Balıkesir Merkez Airport',
        city: 'Balikesir',
        country: 'Turkey',
        iataCode: 'BZI',
        icaoCode: 'LTBF',
        latitude: '39,61930084',
        longitude: '27,9260006',
        altitude: '340',
        timezone: '3',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Bandırma Airport',
        city: 'Bandirma',
        country: 'Turkey',
        iataCode: 'BDM',
        icaoCode: 'LTBG',
        latitude: '40,31800079',
        longitude: '27,97769928',
        altitude: '170',
        timezone: '3',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Eskişehir Air Base',
        city: 'Eskisehir',
        country: 'Turkey',
        iataCode: 'ESK',
        icaoCode: 'LTBI',
        latitude: '39,78409958',
        longitude: '30,58209991',
        altitude: '2581',
        timezone: '3',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Adnan Menderes International Airport',
        city: 'Izmir',
        country: 'Turkey',
        iataCode: 'ADB',
        icaoCode: 'LTBJ',
        latitude: '38,29240036',
        longitude: '27,15699959',
        altitude: '412',
        timezone: '3',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Gaziemir Airport',
        city: 'Izmir',
        country: 'Turkey',
        iataCode: 'N',
        icaoCode: 'LTBK',
        latitude: '38,31909943',
        longitude: '27,15940094',
        altitude: '433',
        timezone: '3',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Çiğli Airport',
        city: 'Izmir',
        country: 'Turkey',
        iataCode: 'IGL',
        icaoCode: 'LTBL',
        latitude: '38,51300049',
        longitude: '27,01009941',
        altitude: '16',
        timezone: '3',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Isparta Airport',
        city: 'Isparta',
        country: 'Turkey',
        iataCode: 'N',
        icaoCode: 'LTBM',
        latitude: '37,78512192',
        longitude: '30,59001923',
        altitude: '3250',
        timezone: '3',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Kütahya Airport',
        city: 'Kutahya',
        country: 'Turkey',
        iataCode: 'N',
        icaoCode: 'LTBN',
        latitude: '39,42670059',
        longitude: '30,01689911',
        altitude: '3026',
        timezone: '3',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Yalova Airport',
        city: 'Yalova',
        country: 'Turkey',
        iataCode: 'N',
        icaoCode: 'LTBP',
        latitude: '40,68439865',
        longitude: '29,3757',
        altitude: '6',
        timezone: '3',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Cengiz Topel Airport',
        city: 'Topel',
        country: 'Turkey',
        iataCode: 'N',
        icaoCode: 'LTBQ',
        latitude: '40,73500061',
        longitude: '30,08329964',
        altitude: '182',
        timezone: '3',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Dalaman International Airport',
        city: 'Dalaman',
        country: 'Turkey',
        iataCode: 'DLM',
        icaoCode: 'LTBS',
        latitude: '36,71310043',
        longitude: '28,79249954',
        altitude: '20',
        timezone: '3',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Akhisar Airport',
        city: 'Akhisar',
        country: 'Turkey',
        iataCode: 'N',
        icaoCode: 'LTBT',
        latitude: '38,80889893',
        longitude: '27,83390045',
        altitude: '263',
        timezone: '3',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Imsık Airport',
        city: 'Bodrum',
        country: 'Turkey',
        iataCode: 'BXN',
        icaoCode: 'LTBV',
        latitude: '37,14009857',
        longitude: '27,66970062',
        altitude: '202',
        timezone: '3',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Samandıra Air Base',
        city: 'Istanbul',
        country: 'Turkey',
        iataCode: 'N',
        icaoCode: 'LTBX',
        latitude: '40,99300003',
        longitude: '29,21649933',
        altitude: '400',
        timezone: '3',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Elazığ Airport',
        city: 'Elazig',
        country: 'Turkey',
        iataCode: 'EZS',
        icaoCode: 'LTCA',
        latitude: '38,60689926',
        longitude: '39,29140091',
        altitude: '2927',
        timezone: '3',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Diyarbakir Airport',
        city: 'Diyabakir',
        country: 'Turkey',
        iataCode: 'DIY',
        icaoCode: 'LTCC',
        latitude: '37,89390183',
        longitude: '40,20100021',
        altitude: '2251',
        timezone: '3',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Erzincan Airport',
        city: 'Erzincan',
        country: 'Turkey',
        iataCode: 'ERC',
        icaoCode: 'LTCD',
        latitude: '39,71020126',
        longitude: '39,52700043',
        altitude: '3783',
        timezone: '3',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Erzurum International Airport',
        city: 'Erzurum',
        country: 'Turkey',
        iataCode: 'ERZ',
        icaoCode: 'LTCE',
        latitude: '39,95650101',
        longitude: '41,17020035',
        altitude: '5763',
        timezone: '3',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Trabzon International Airport',
        city: 'Trabzon',
        country: 'Turkey',
        iataCode: 'TZX',
        icaoCode: 'LTCG',
        latitude: '40,99509811',
        longitude: '39,78969955',
        altitude: '104',
        timezone: '3',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Van Ferit Melen Airport',
        city: 'Van',
        country: 'Turkey',
        iataCode: 'VAN',
        icaoCode: 'LTCI',
        latitude: '38,46820068',
        longitude: '43,33229828',
        altitude: '5480',
        timezone: '3',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Batman Airport',
        city: 'Batman',
        country: 'Turkey',
        iataCode: 'BAL',
        icaoCode: 'LTCJ',
        latitude: '37,92900085',
        longitude: '41,11660004',
        altitude: '1822',
        timezone: '3',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Siirt Airport',
        city: 'Siirt',
        country: 'Turkey',
        iataCode: 'N',
        icaoCode: 'LTCL',
        latitude: '37,97890091',
        longitude: '41,8404007',
        altitude: '2001',
        timezone: '3',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Kaklıç Airport',
        city: 'Izmir',
        country: 'Turkey',
        iataCode: 'N',
        icaoCode: 'LTFA',
        latitude: '38,51760101',
        longitude: '26,97739983',
        altitude: '13',
        timezone: '3',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Selçuk Efes Airport',
        city: 'Izmir',
        country: 'Turkey',
        iataCode: 'N',
        icaoCode: 'LTFB',
        latitude: '37,95069885',
        longitude: '27,32900047',
        altitude: '10',
        timezone: '3',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Balti International Airport',
        city: 'Saltsy',
        country: 'Moldova',
        iataCode: 'BZY',
        icaoCode: 'LUBL',
        latitude: '47,83810043',
        longitude: '27,78149986',
        altitude: '758',
        timezone: '2',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Chişinău International Airport',
        city: 'Chisinau',
        country: 'Moldova',
        iataCode: 'KIV',
        icaoCode: 'LUKK',
        latitude: '46,92770004',
        longitude: '28,93099976',
        altitude: '399',
        timezone: '2',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Ohrid St. Paul the Apostle Airport',
        city: 'Ohrid',
        country: 'Macedonia',
        iataCode: 'OHD',
        icaoCode: 'LWOH',
        latitude: '41,18',
        longitude: '20,7423',
        altitude: '2313',
        timezone: '1',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Skopje Alexander the Great Airport',
        city: 'Skopje',
        country: 'Macedonia',
        iataCode: 'SKP',
        icaoCode: 'LWSK',
        latitude: '41,961601',
        longitude: '21,621401',
        altitude: '781',
        timezone: '1',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Gibraltar Airport',
        city: 'Gibraltar',
        country: 'Gibraltar',
        iataCode: 'GIB',
        icaoCode: 'LXGB',
        latitude: '36,15119934',
        longitude: '-5,34965992',
        altitude: '15',
        timezone: '1',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Belgrade Nikola Tesla Airport',
        city: 'Belgrade',
        country: 'Serbia',
        iataCode: 'BEG',
        icaoCode: 'LYBE',
        latitude: '44,81840134',
        longitude: '20,3090992',
        altitude: '335',
        timezone: '1',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Nis Airport',
        city: 'Nis',
        country: 'Serbia',
        iataCode: 'INI',
        icaoCode: 'LYNI',
        latitude: '43,337299',
        longitude: '21,853701',
        altitude: '648',
        timezone: '1',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Podgorica Airport',
        city: 'Podgorica',
        country: 'Montenegro',
        iataCode: 'TGD',
        icaoCode: 'LYPG',
        latitude: '42,3594017',
        longitude: '19,25189972',
        altitude: '141',
        timezone: '1',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Priština International Airport',
        city: 'Pristina',
        country: 'Serbia',
        iataCode: 'PRN',
        icaoCode: 'BKPR',
        latitude: '42,5728',
        longitude: '21,035801',
        altitude: '1789',
        timezone: '1',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Tivat Airport',
        city: 'Tivat',
        country: 'Montenegro',
        iataCode: 'TIV',
        icaoCode: 'LYTV',
        latitude: '42,40470123',
        longitude: '18,72330093',
        altitude: '20',
        timezone: '1',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Vršac International Airport',
        city: 'Vrsac',
        country: 'Serbia',
        iataCode: 'N',
        icaoCode: 'LYVR',
        latitude: '45,1469',
        longitude: '21,3099',
        altitude: '276',
        timezone: '1',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'M. R. Štefánik Airport',
        city: 'Bratislava',
        country: 'Slovakia',
        iataCode: 'BTS',
        icaoCode: 'LZIB',
        latitude: '48,17020035',
        longitude: '17,21269989',
        altitude: '436',
        timezone: '1',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Košice Airport',
        city: 'Kosice',
        country: 'Slovakia',
        iataCode: 'KSC',
        icaoCode: 'LZKZ',
        latitude: '48,6631012',
        longitude: '21,24110031',
        altitude: '755',
        timezone: '1',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Kuchyňa Air Base',
        city: 'Malacky',
        country: 'Slovakia',
        iataCode: 'N',
        icaoCode: 'LZMC',
        latitude: '48,40200043',
        longitude: '17,11840057',
        altitude: '679',
        timezone: '1',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Piešťany Airport',
        city: 'Piestany',
        country: 'Slovakia',
        iataCode: 'PZY',
        icaoCode: 'LZPP',
        latitude: '48,62519836',
        longitude: '17,82839966',
        altitude: '545',
        timezone: '1',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Sliač Airport',
        city: 'Sliac',
        country: 'Slovakia',
        iataCode: 'SLD',
        icaoCode: 'LZSL',
        latitude: '48,63779831',
        longitude: '19,13409996',
        altitude: '1043',
        timezone: '1',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Trenčín Airport',
        city: 'Trencin',
        country: 'Slovakia',
        iataCode: 'N',
        icaoCode: 'LZTN',
        latitude: '48,86500168',
        longitude: '17,99230003',
        altitude: '676',
        timezone: '1',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Poprad-Tatry Airport',
        city: 'Poprad',
        country: 'Slovakia',
        iataCode: 'TAT',
        icaoCode: 'LZTT',
        latitude: '49,07360077',
        longitude: '20,24110031',
        altitude: '2356',
        timezone: '1',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'North Caicos Airport',
        city: 'North Caicos',
        country: 'Turks and Caicos Islands',
        iataCode: 'NCA',
        icaoCode: 'MBNC',
        latitude: '21,91749954',
        longitude: '-71,93959808',
        altitude: '10',
        timezone: '-4',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Providenciales Airport',
        city: 'Providenciales',
        country: 'Turks and Caicos Islands',
        iataCode: 'PLS',
        icaoCode: 'MBPV',
        latitude: '21,77359962',
        longitude: '-72,26589966',
        altitude: '15',
        timezone: '-4',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'South Caicos Airport',
        city: 'South Caicos',
        country: 'Turks and Caicos Islands',
        iataCode: 'XSC',
        icaoCode: 'MBSC',
        latitude: '21,51569939',
        longitude: '-71,52850342',
        altitude: '6',
        timezone: '-4',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Arroyo Barril Airport',
        city: 'Samana',
        country: 'Dominican Republic',
        iataCode: 'EPS',
        icaoCode: 'MDAB',
        latitude: '19,19860077',
        longitude: '-69,42980194',
        altitude: '57',
        timezone: '-4',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Maria Montez International Airport',
        city: 'Barahona',
        country: 'Dominican Republic',
        iataCode: 'BRX',
        icaoCode: 'MDBH',
        latitude: '18,25149918',
        longitude: '-71,12039948',
        altitude: '10',
        timezone: '-4',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Cabo Rojo Airport',
        city: 'Cabo Rojo',
        country: 'Dominican Republic',
        iataCode: 'N',
        icaoCode: 'MDCR',
        latitude: '17,92900085',
        longitude: '-71,64479828',
        altitude: '262',
        timezone: '-4',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Casa De Campo International Airport',
        city: 'La Romana',
        country: 'Dominican Republic',
        iataCode: 'LRM',
        icaoCode: 'MDLR',
        latitude: '18,45070076',
        longitude: '-68,91179657',
        altitude: '240',
        timezone: '-4',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Punta Cana International Airport',
        city: 'Punta Cana',
        country: 'Dominican Republic',
        iataCode: 'PUJ',
        icaoCode: 'MDPC',
        latitude: '18,56739998',
        longitude: '-68,36340332',
        altitude: '47',
        timezone: '-4',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Gregorio Luperon International Airport',
        city: 'Puerto Plata',
        country: 'Dominican Republic',
        iataCode: 'POP',
        icaoCode: 'MDPP',
        latitude: '19,75790024',
        longitude: '-70,56999969',
        altitude: '15',
        timezone: '-4',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Las Américas International Airport',
        city: 'Santo Domingo',
        country: 'Dominican Republic',
        iataCode: 'SDQ',
        icaoCode: 'MDSD',
        latitude: '18,42970085',
        longitude: '-69,66889954',
        altitude: '59',
        timezone: '-4',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'San Isidro Air Base',
        city: 'San Isidoro',
        country: 'Dominican Republic',
        iataCode: 'N',
        icaoCode: 'MDSI',
        latitude: '18,50370026',
        longitude: '-69,76170349',
        altitude: '111',
        timezone: '-4',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Cibao International Airport',
        city: 'Santiago',
        country: 'Dominican Republic',
        iataCode: 'STI',
        icaoCode: 'MDST',
        latitude: '19,40609932',
        longitude: '-70,60469818',
        altitude: '565',
        timezone: '-4',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Bananera Airport',
        city: 'Bananera',
        country: 'Guatemala',
        iataCode: 'N',
        icaoCode: 'MGBN',
        latitude: '15,47350025',
        longitude: '-88,83719635',
        altitude: '130',
        timezone: '-6',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Coban Airport',
        city: 'Coban',
        country: 'Guatemala',
        iataCode: 'CBV',
        icaoCode: 'MGCB',
        latitude: '15,46899986',
        longitude: '-90,40670013',
        altitude: '4339',
        timezone: '-6',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'La Aurora Airport',
        city: 'Guatemala City',
        country: 'Guatemala',
        iataCode: 'GUA',
        icaoCode: 'MGGT',
        latitude: '14,58329964',
        longitude: '-90,52749634',
        altitude: '4952',
        timezone: '-6',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Retalhuleu Airport',
        city: 'Retalhuleu',
        country: 'Guatemala',
        iataCode: 'N',
        icaoCode: 'MGRT',
        latitude: '14,52099991',
        longitude: '-91,69730377',
        altitude: '656',
        timezone: '-6',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'San José Airport',
        city: 'San Jose',
        country: 'Guatemala',
        iataCode: 'N',
        icaoCode: 'MGSJ',
        latitude: '13,93620014',
        longitude: '-90,83580017',
        altitude: '29',
        timezone: '-6',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Goloson International Airport',
        city: 'La Ceiba',
        country: 'Honduras',
        iataCode: 'LCE',
        icaoCode: 'MHLC',
        latitude: '15,7425',
        longitude: '-86,852997',
        altitude: '39',
        timezone: '-6',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Ramón Villeda Morales International Airport',
        city: 'San Pedro Sula',
        country: 'Honduras',
        iataCode: 'SAP',
        icaoCode: 'MHLM',
        latitude: '15,45259953',
        longitude: '-87,92359924',
        altitude: '91',
        timezone: '-6',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'La Laguna Airport',
        city: 'Guanaja',
        country: 'Honduras',
        iataCode: 'GJA',
        icaoCode: 'MHNJ',
        latitude: '16,4454',
        longitude: '-85,906601',
        altitude: '49',
        timezone: '-6',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Juan Manuel Galvez International Airport',
        city: 'Roatan',
        country: 'Honduras',
        iataCode: 'RTB',
        icaoCode: 'MHRO',
        latitude: '16,316799',
        longitude: '-86,523003',
        altitude: '39',
        timezone: '-6',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Tela Airport',
        city: 'Tela',
        country: 'Honduras',
        iataCode: 'TEA',
        icaoCode: 'MHTE',
        latitude: '15,7759',
        longitude: '-87,4758',
        altitude: '7',
        timezone: '-6',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Toncontín International Airport',
        city: 'Tegucigalpa',
        country: 'Honduras',
        iataCode: 'TGU',
        icaoCode: 'MHTG',
        latitude: '14,06089973',
        longitude: '-87,21720123',
        altitude: '3294',
        timezone: '-6',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Trujillo Airport',
        city: 'Trujillo',
        country: 'Honduras',
        iataCode: 'N',
        icaoCode: 'MHTJ',
        latitude: '15,9268',
        longitude: '-85,938202',
        altitude: '3',
        timezone: '-6',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Boscobel Aerodrome',
        city: 'Ocho Rios',
        country: 'Jamaica',
        iataCode: 'OCJ',
        icaoCode: 'MKBS',
        latitude: '18,4041996',
        longitude: '-76,96900177',
        altitude: '90',
        timezone: '-5',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Norman Manley International Airport',
        city: 'Kingston',
        country: 'Jamaica',
        iataCode: 'KIN',
        icaoCode: 'MKJP',
        latitude: '17,93569946',
        longitude: '-76,78749847',
        altitude: '10',
        timezone: '-5',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Sangster International Airport',
        city: 'Montego Bay',
        country: 'Jamaica',
        iataCode: 'MBJ',
        icaoCode: 'MKJS',
        latitude: '18,50370026',
        longitude: '-77,91339874',
        altitude: '4',
        timezone: '-5',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Ken Jones Airport',
        city: 'Port Antonio',
        country: 'Jamaica',
        iataCode: 'POT',
        icaoCode: 'MKKJ',
        latitude: '18,19879913',
        longitude: '-76,53450012',
        altitude: '20',
        timezone: '-5',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Tinson Pen Airport',
        city: 'Kingston',
        country: 'Jamaica',
        iataCode: 'KTP',
        icaoCode: 'MKTP',
        latitude: '17,98859978',
        longitude: '-76,82379913',
        altitude: '16',
        timezone: '-5',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'General Juan N Alvarez International Airport',
        city: 'Acapulco',
        country: 'Mexico',
        iataCode: 'ACA',
        icaoCode: 'MMAA',
        latitude: '16,75709915',
        longitude: '-99,7539978',
        altitude: '16',
        timezone: '-6',
        dst: 'S',
        type: 'airport',
    },
    {
        name: 'Del Norte International Airport',
        city: 'Monterrey',
        country: 'Mexico',
        iataCode: 'NTR',
        icaoCode: 'MMAN',
        latitude: '25,86560059',
        longitude: '-100,2369995',
        altitude: '1476',
        timezone: '-6',
        dst: 'S',
        type: 'airport',
    },
    {
        name: 'Jesús Terán Paredo International Airport',
        city: 'Aguascalientes',
        country: 'Mexico',
        iataCode: 'AGU',
        icaoCode: 'MMAS',
        latitude: '21,705601',
        longitude: '-102,318001',
        altitude: '6112',
        timezone: '-6',
        dst: 'S',
        type: 'airport',
    },
    {
        name: 'Bahías de Huatulco International Airport',
        city: 'Huatulco',
        country: 'Mexico',
        iataCode: 'HUX',
        icaoCode: 'MMBT',
        latitude: '15,77530003',
        longitude: '-96,26260376',
        altitude: '464',
        timezone: '-6',
        dst: 'S',
        type: 'airport',
    },
    {
        name: 'General Mariano Matamoros Airport',
        city: 'Cuernavaca',
        country: 'Mexico',
        iataCode: 'CVJ',
        icaoCode: 'MMCB',
        latitude: '18,83480072',
        longitude: '-99,26129913',
        altitude: '4277',
        timezone: '-6',
        dst: 'S',
        type: 'airport',
    },
    {
        name: 'Ciudad Acuña New International Airport',
        city: 'Ciudad Acuna',
        country: 'Mexico',
        iataCode: 'N',
        icaoCode: 'MMCC',
        latitude: '29,33289909',
        longitude: '-101,098999',
        altitude: '1410',
        timezone: '-6',
        dst: 'S',
        type: 'airport',
    },
    {
        name: 'Ciudad del Carmen International Airport',
        city: 'Ciudad Del Carmen',
        country: 'Mexico',
        iataCode: 'CME',
        icaoCode: 'MMCE',
        latitude: '18,65369987',
        longitude: '-91,7990036',
        altitude: '10',
        timezone: '-6',
        dst: 'S',
        type: 'airport',
    },
    {
        name: 'Nuevo Casas Grandes Airport',
        city: 'Nuevo Casas Grandes',
        country: 'Mexico',
        iataCode: 'N',
        icaoCode: 'MMCG',
        latitude: '30,3973999',
        longitude: '-107,875',
        altitude: '4850',
        timezone: '-7',
        dst: 'S',
        type: 'airport',
    },
    {
        name: 'Chilpancingo Airport',
        city: 'Chilpancingo',
        country: 'Mexico',
        iataCode: 'N',
        icaoCode: 'MMCH',
        latitude: '17,573799',
        longitude: '-99,514297',
        altitude: '4199',
        timezone: '-6',
        dst: 'S',
        type: 'airport',
    },
    {
        name: 'Bachigualato Federal International Airport',
        city: 'Culiacan',
        country: 'Mexico',
        iataCode: 'CUL',
        icaoCode: 'MMCL',
        latitude: '24,76449966',
        longitude: '-107,4749985',
        altitude: '108',
        timezone: '-7',
        dst: 'S',
        type: 'airport',
    },
    {
        name: 'Chetumal International Airport',
        city: 'Chetumal',
        country: 'Mexico',
        iataCode: 'CTM',
        icaoCode: 'MMCM',
        latitude: '18,50469971',
        longitude: '-88,32679749',
        altitude: '39',
        timezone: '-5',
        dst: 'S',
        type: 'airport',
    },
    {
        name: 'Ciudad Obregón International Airport',
        city: 'Ciudad Obregon',
        country: 'Mexico',
        iataCode: 'CEN',
        icaoCode: 'MMCN',
        latitude: '27,39259911',
        longitude: '-109,8330002',
        altitude: '243',
        timezone: '-7',
        dst: 'S',
        type: 'airport',
    },
    {
        name: 'Ingeniero Alberto Acuña Ongay International Airport',
        city: 'Campeche',
        country: 'Mexico',
        iataCode: 'CPE',
        icaoCode: 'MMCP',
        latitude: '19,81679916',
        longitude: '-90,50029755',
        altitude: '34',
        timezone: '-6',
        dst: 'S',
        type: 'airport',
    },
    {
        name: 'Abraham González International Airport',
        city: 'Ciudad Juarez',
        country: 'Mexico',
        iataCode: 'CJS',
        icaoCode: 'MMCS',
        latitude: '31,63610077',
        longitude: '-106,4290009',
        altitude: '3904',
        timezone: '-7',
        dst: 'S',
        type: 'airport',
    },
    {
        name: 'General Roberto Fierro Villalobos International Airport',
        city: 'Chihuahua',
        country: 'Mexico',
        iataCode: 'CUU',
        icaoCode: 'MMCU',
        latitude: '28,70289993',
        longitude: '-105,9649963',
        altitude: '4462',
        timezone: '-7',
        dst: 'S',
        type: 'airport',
    },
    {
        name: 'General Pedro Jose Mendez International Airport',
        city: 'Ciudad Victoria',
        country: 'Mexico',
        iataCode: 'CVM',
        icaoCode: 'MMCV',
        latitude: '23,70330048',
        longitude: '-98,95649719',
        altitude: '761',
        timezone: '-6',
        dst: 'S',
        type: 'airport',
    },
    {
        name: 'Cozumel International Airport',
        city: 'Cozumel',
        country: 'Mexico',
        iataCode: 'CZM',
        icaoCode: 'MMCZ',
        latitude: '20,5223999',
        longitude: '-86,92559814',
        altitude: '15',
        timezone: '-5',
        dst: 'S',
        type: 'airport',
    },
    {
        name: 'General Guadalupe Victoria International Airport',
        city: 'Durango',
        country: 'Mexico',
        iataCode: 'DGO',
        icaoCode: 'MMDO',
        latitude: '24,12420082',
        longitude: '-104,5279999',
        altitude: '6104',
        timezone: '-6',
        dst: 'S',
        type: 'airport',
    },
    {
        name: 'Amado Nervo National Airport',
        city: 'Tepic',
        country: 'Mexico',
        iataCode: 'TPQ',
        icaoCode: 'MMEP',
        latitude: '21,41950035',
        longitude: '-104,8430023',
        altitude: '3020',
        timezone: '-7',
        dst: 'S',
        type: 'airport',
    },
    {
        name: 'Ensenada Airport',
        city: 'Ensenada',
        country: 'Mexico',
        iataCode: 'ESE',
        icaoCode: 'MMES',
        latitude: '31,79529953',
        longitude: '-116,6029968',
        altitude: '66',
        timezone: '-8',
        dst: 'S',
        type: 'airport',
    },
    {
        name: 'Don Miguel Hidalgo Y Costilla International Airport',
        city: 'Guadalajara',
        country: 'Mexico',
        iataCode: 'GDL',
        icaoCode: 'MMGL',
        latitude: '20,52179909',
        longitude: '-103,310997',
        altitude: '5016',
        timezone: '-6',
        dst: 'S',
        type: 'airport',
    },
    {
        name: 'General José María Yáñez International Airport',
        city: 'Guaymas',
        country: 'Mexico',
        iataCode: 'GYM',
        icaoCode: 'MMGM',
        latitude: '27,96899986',
        longitude: '-110,9250031',
        altitude: '59',
        timezone: '-7',
        dst: 'S',
        type: 'airport',
    },
    {
        name: 'Tehuacan Airport',
        city: 'Tehuacan',
        country: 'Mexico',
        iataCode: 'TCN',
        icaoCode: 'MMHC',
        latitude: '18,49720001',
        longitude: '-97,41989899',
        altitude: '5509',
        timezone: '-6',
        dst: 'S',
        type: 'airport',
    },
    {
        name: 'General Ignacio P. Garcia International Airport',
        city: 'Hermosillo',
        country: 'Mexico',
        iataCode: 'HMO',
        icaoCode: 'MMHO',
        latitude: '29,09589958',
        longitude: '-111,0479965',
        altitude: '627',
        timezone: '-7',
        dst: 'S',
        type: 'airport',
    },
    {
        name: 'Licenciado Miguel de la Madrid Airport',
        city: 'Colima',
        country: 'Mexico',
        iataCode: 'CLQ',
        icaoCode: 'MMIA',
        latitude: '19,27700043',
        longitude: '-103,5770035',
        altitude: '2467',
        timezone: '-6',
        dst: 'S',
        type: 'airport',
    },
    {
        name: 'Isla Mujeres Airport',
        city: 'Isla Mujeres',
        country: 'Mexico',
        iataCode: 'ISJ',
        icaoCode: 'MMIM',
        latitude: '21,24500084',
        longitude: '-86,73999786',
        altitude: '7',
        timezone: '-5',
        dst: 'S',
        type: 'airport',
    },
    {
        name: 'Plan De Guadalupe International Airport',
        city: 'Saltillo',
        country: 'Mexico',
        iataCode: 'SLW',
        icaoCode: 'MMIO',
        latitude: '25,54949951',
        longitude: '-100,9290009',
        altitude: '4778',
        timezone: '-6',
        dst: 'S',
        type: 'airport',
    },
    {
        name: 'Ixtepec Airport',
        city: 'Iztepec',
        country: 'Mexico',
        iataCode: 'N',
        icaoCode: 'MMIT',
        latitude: '16,44930077',
        longitude: '-95,09369659',
        altitude: '164',
        timezone: '-6',
        dst: 'S',
        type: 'airport',
    },
    {
        name: 'Lázaro Cárdenas Airport',
        city: 'Lazard Cardenas',
        country: 'Mexico',
        iataCode: 'LZC',
        icaoCode: 'MMLC',
        latitude: '18,00169945',
        longitude: '-102,2210007',
        altitude: '39',
        timezone: '-6',
        dst: 'S',
        type: 'airport',
    },
    {
        name: 'Valle del Fuerte International Airport',
        city: 'Los Mochis',
        country: 'Mexico',
        iataCode: 'LMM',
        icaoCode: 'MMLM',
        latitude: '25,68519974',
        longitude: '-109,0810013',
        altitude: '16',
        timezone: '-7',
        dst: 'S',
        type: 'airport',
    },
    {
        name: 'Del Bajío International Airport',
        city: 'Del Bajio',
        country: 'Mexico',
        iataCode: 'BJX',
        icaoCode: 'MMLO',
        latitude: '20,99349976',
        longitude: '-101,4810028',
        altitude: '5956',
        timezone: '-6',
        dst: 'S',
        type: 'airport',
    },
    {
        name: 'Manuel Márquez de León International Airport',
        city: 'La Paz',
        country: 'Mexico',
        iataCode: 'LAP',
        icaoCode: 'MMLP',
        latitude: '24,0727005',
        longitude: '-110,3619995',
        altitude: '69',
        timezone: '-7',
        dst: 'S',
        type: 'airport',
    },
    {
        name: 'Loreto International Airport',
        city: 'Loreto',
        country: 'Mexico',
        iataCode: 'LTO',
        icaoCode: 'MMLT',
        latitude: '25,98920059',
        longitude: '-111,3479996',
        altitude: '34',
        timezone: '-7',
        dst: 'S',
        type: 'airport',
    },
    {
        name: 'General Servando Canales International Airport',
        city: 'Matamoros',
        country: 'Mexico',
        iataCode: 'MAM',
        icaoCode: 'MMMA',
        latitude: '25,76989937',
        longitude: '-97,52529907',
        altitude: '25',
        timezone: '-6',
        dst: 'S',
        type: 'airport',
    },
    {
        name: 'Licenciado Manuel Crescencio Rejon Int Airport',
        city: 'Merida',
        country: 'Mexico',
        iataCode: 'MID',
        icaoCode: 'MMMD',
        latitude: '20,93700027',
        longitude: '-89,65769959',
        altitude: '38',
        timezone: '-6',
        dst: 'S',
        type: 'airport',
    },
    {
        name: 'General Rodolfo Sánchez Taboada International Airport',
        city: 'Mexicali',
        country: 'Mexico',
        iataCode: 'MXL',
        icaoCode: 'MMML',
        latitude: '32,63059998',
        longitude: '-115,2419968',
        altitude: '74',
        timezone: '-8',
        dst: 'S',
        type: 'airport',
    },
    {
        name: 'General Francisco J. Mujica International Airport',
        city: 'Morelia',
        country: 'Mexico',
        iataCode: 'MLM',
        icaoCode: 'MMMM',
        latitude: '19,84989929',
        longitude: '-101,0250015',
        altitude: '6033',
        timezone: '-6',
        dst: 'S',
        type: 'airport',
    },
    {
        name: 'Minatitlán/Coatzacoalcos National Airport',
        city: 'Minatitlan',
        country: 'Mexico',
        iataCode: 'MTT',
        icaoCode: 'MMMT',
        latitude: '18,10339928',
        longitude: '-94,58070374',
        altitude: '36',
        timezone: '-6',
        dst: 'S',
        type: 'airport',
    },
    {
        name: 'Monclova International Airport',
        city: 'Monclova',
        country: 'Mexico',
        iataCode: 'LOV',
        icaoCode: 'MMMV',
        latitude: '26,95569992',
        longitude: '-101,4700012',
        altitude: '1864',
        timezone: '-6',
        dst: 'S',
        type: 'airport',
    },
    {
        name: 'Licenciado Benito Juarez International Airport',
        city: 'Mexico City',
        country: 'Mexico',
        iataCode: 'MEX',
        icaoCode: 'MMMX',
        latitude: '19,4363',
        longitude: '-99,072098',
        altitude: '7316',
        timezone: '-6',
        dst: 'S',
        type: 'airport',
    },
    {
        name: 'General Mariano Escobedo International Airport',
        city: 'Monterrey',
        country: 'Mexico',
        iataCode: 'MTY',
        icaoCode: 'MMMY',
        latitude: '25,7784996',
        longitude: '-100,1070023',
        altitude: '1278',
        timezone: '-6',
        dst: 'S',
        type: 'airport',
    },
    {
        name: 'General Rafael Buelna International Airport',
        city: 'Mazatlan',
        country: 'Mexico',
        iataCode: 'MZT',
        icaoCode: 'MMMZ',
        latitude: '23,16139984',
        longitude: '-106,2659988',
        altitude: '38',
        timezone: '-7',
        dst: 'S',
        type: 'airport',
    },
    {
        name: 'Nogales International Airport',
        city: 'Nogales',
        country: 'Mexico',
        iataCode: 'NOG',
        icaoCode: 'MMNG',
        latitude: '31,22610092',
        longitude: '-110,9759979',
        altitude: '3990',
        timezone: '-7',
        dst: 'S',
        type: 'airport',
    },
    {
        name: 'Quetzalcóatl International Airport',
        city: 'Nuevo Laredo',
        country: 'Mexico',
        iataCode: 'NLD',
        icaoCode: 'MMNL',
        latitude: '27,44389915',
        longitude: '-99,57050323',
        altitude: '484',
        timezone: '-6',
        dst: 'S',
        type: 'airport',
    },
    {
        name: 'Xoxocotlán International Airport',
        city: 'Oaxaca',
        country: 'Mexico',
        iataCode: 'OAX',
        icaoCode: 'MMOX',
        latitude: '16,99990082',
        longitude: '-96,72660065',
        altitude: '4989',
        timezone: '-6',
        dst: 'S',
        type: 'airport',
    },
    {
        name: 'El Tajín National Airport',
        city: 'Poza Rico',
        country: 'Mexico',
        iataCode: 'PAZ',
        icaoCode: 'MMPA',
        latitude: '20,60269928',
        longitude: '-97,46080017',
        altitude: '497',
        timezone: '-6',
        dst: 'S',
        type: 'airport',
    },
    {
        name: 'Hermanos Serdán International Airport',
        city: 'Puebla',
        country: 'Mexico',
        iataCode: 'PBC',
        icaoCode: 'MMPB',
        latitude: '19,15810013',
        longitude: '-98,37139893',
        altitude: '7361',
        timezone: '-6',
        dst: 'S',
        type: 'airport',
    },
    {
        name: 'Ingeniero Juan Guillermo Villasana Airport',
        city: 'Pachuca',
        country: 'Mexico',
        iataCode: 'PCA',
        icaoCode: 'MMPC',
        latitude: '20,07740021',
        longitude: '-98,78250122',
        altitude: '7600',
        timezone: '-6',
        dst: 'S',
        type: 'airport',
    },
    {
        name: 'Puerto Peñasco International Airport',
        city: 'Punta Penasco',
        country: 'Mexico',
        iataCode: 'PPE',
        icaoCode: 'MMPE',
        latitude: '31,356202',
        longitude: '-113,525677',
        altitude: '30',
        timezone: '-7',
        dst: 'S',
        type: 'airport',
    },
    {
        name: 'Piedras Negras International Airport',
        city: 'Piedras Negras',
        country: 'Mexico',
        iataCode: 'PDS',
        icaoCode: 'MMPG',
        latitude: '28,62739944',
        longitude: '-100,5350037',
        altitude: '901',
        timezone: '-6',
        dst: 'S',
        type: 'airport',
    },
    {
        name: 'Licenciado y General Ignacio Lopez Rayon Airport',
        city: 'Uruapan',
        country: 'Mexico',
        iataCode: 'UPN',
        icaoCode: 'MMPN',
        latitude: '19,39669991',
        longitude: '-102,0390015',
        altitude: '5258',
        timezone: '-6',
        dst: 'S',
        type: 'airport',
    },
    {
        name: 'Licenciado Gustavo Díaz Ordaz International Airport',
        city: 'Puerto Vallarta',
        country: 'Mexico',
        iataCode: 'PVR',
        icaoCode: 'MMPR',
        latitude: '20,68009949',
        longitude: '-105,2539978',
        altitude: '23',
        timezone: '-6',
        dst: 'S',
        type: 'airport',
    },
    {
        name: 'Puerto Escondido International Airport',
        city: 'Puerto Escondido',
        country: 'Mexico',
        iataCode: 'PXM',
        icaoCode: 'MMPS',
        latitude: '15,87689972',
        longitude: '-97,0891037',
        altitude: '294',
        timezone: '-6',
        dst: 'S',
        type: 'airport',
    },
    {
        name: 'Querétaro Intercontinental Airport',
        city: 'Queretaro',
        country: 'Mexico',
        iataCode: 'QRO',
        icaoCode: 'MMQT',
        latitude: '20,61730003',
        longitude: '-100,185997',
        altitude: '6296',
        timezone: '-6',
        dst: 'S',
        type: 'airport',
    },
    {
        name: 'General Lucio Blanco International Airport',
        city: 'Reynosa',
        country: 'Mexico',
        iataCode: 'REX',
        icaoCode: 'MMRX',
        latitude: '26,00889969',
        longitude: '-98,22850037',
        altitude: '139',
        timezone: '-6',
        dst: 'S',
        type: 'airport',
    },
    {
        name: 'Los Cabos International Airport',
        city: 'San Jose Del Cabo',
        country: 'Mexico',
        iataCode: 'SJD',
        icaoCode: 'MMSD',
        latitude: '23,15180016',
        longitude: '-109,7210007',
        altitude: '374',
        timezone: '-7',
        dst: 'S',
        type: 'airport',
    },
    {
        name: 'San Felipe International Airport',
        city: 'San Filipe',
        country: 'Mexico',
        iataCode: 'SFH',
        icaoCode: 'MMSF',
        latitude: '30,93020058',
        longitude: '-114,8089981',
        altitude: '148',
        timezone: '-8',
        dst: 'S',
        type: 'airport',
    },
    {
        name: 'Ponciano Arriaga International Airport',
        city: 'San Luis Potosi',
        country: 'Mexico',
        iataCode: 'SLP',
        icaoCode: 'MMSP',
        latitude: '22,25429916',
        longitude: '-100,9309998',
        altitude: '6035',
        timezone: '-6',
        dst: 'S',
        type: 'airport',
    },
    {
        name: 'Tlaxcala Airport',
        city: 'Tlaxcala',
        country: 'Mexico',
        iataCode: 'TXA',
        icaoCode: 'MMTA',
        latitude: '19,537992',
        longitude: '-98,173492',
        altitude: '8229',
        timezone: '-6',
        dst: 'S',
        type: 'airport',
    },
    {
        name: 'Terán Air Base',
        city: 'Tuxtla Gutierrez',
        country: 'Mexico',
        iataCode: 'N',
        icaoCode: 'MMTB',
        latitude: '16,73990059',
        longitude: '-93,1733017',
        altitude: '1909',
        timezone: '-6',
        dst: 'S',
        type: 'airport',
    },
    {
        name: 'Francisco Sarabia International Airport',
        city: 'Torreon',
        country: 'Mexico',
        iataCode: 'TRC',
        icaoCode: 'MMTC',
        latitude: '25,56830025',
        longitude: '-103,4110031',
        altitude: '3688',
        timezone: '-6',
        dst: 'S',
        type: 'airport',
    },
    {
        name: 'Angel Albino Corzo International Airport',
        city: 'Tuxtla Gutierrez',
        country: 'Mexico',
        iataCode: 'TGZ',
        icaoCode: 'MMTG',
        latitude: '16,56360054',
        longitude: '-93,02249908',
        altitude: '1499',
        timezone: '-6',
        dst: 'S',
        type: 'airport',
    },
    {
        name: 'General Abelardo L. Rodríguez International Airport',
        city: 'Tijuana',
        country: 'Mexico',
        iataCode: 'TIJ',
        icaoCode: 'MMTJ',
        latitude: '32,54109955',
        longitude: '-116,9700012',
        altitude: '489',
        timezone: '-8',
        dst: 'S',
        type: 'airport',
    },
    {
        name: 'General Francisco Javier Mina International Airport',
        city: 'Tampico',
        country: 'Mexico',
        iataCode: 'TAM',
        icaoCode: 'MMTM',
        latitude: '22,29640007',
        longitude: '-97,86589813',
        altitude: '80',
        timezone: '-6',
        dst: 'S',
        type: 'airport',
    },
    {
        name: 'Tamuin Airport',
        city: 'Tamuin',
        country: 'Mexico',
        iataCode: 'TSL',
        icaoCode: 'MMTN',
        latitude: '22,03829956',
        longitude: '-98,8065033',
        altitude: '164',
        timezone: '-6',
        dst: 'S',
        type: 'airport',
    },
    {
        name: 'Licenciado Adolfo Lopez Mateos International Airport',
        city: 'Toluca',
        country: 'Mexico',
        iataCode: 'TLC',
        icaoCode: 'MMTO',
        latitude: '19,33709908',
        longitude: '-99,56600189',
        altitude: '8466',
        timezone: '-6',
        dst: 'S',
        type: 'airport',
    },
    {
        name: 'Tapachula International Airport',
        city: 'Tapachula',
        country: 'Mexico',
        iataCode: 'TAP',
        icaoCode: 'MMTP',
        latitude: '14,79430008',
        longitude: '-92,37000275',
        altitude: '97',
        timezone: '-6',
        dst: 'S',
        type: 'airport',
    },
    {
        name: 'Cancún International Airport',
        city: 'Cancun',
        country: 'Mexico',
        iataCode: 'CUN',
        icaoCode: 'MMUN',
        latitude: '21,03650093',
        longitude: '-86,87709808',
        altitude: '22',
        timezone: '-5',
        dst: 'S',
        type: 'airport',
    },
    {
        name: 'Carlos Rovirosa Pérez International Airport',
        city: 'Villahermosa',
        country: 'Mexico',
        iataCode: 'VSA',
        icaoCode: 'MMVA',
        latitude: '17,99699974',
        longitude: '-92,81739807',
        altitude: '46',
        timezone: '-6',
        dst: 'S',
        type: 'airport',
    },
    {
        name: 'General Heriberto Jara International Airport',
        city: 'Vera Cruz',
        country: 'Mexico',
        iataCode: 'VER',
        icaoCode: 'MMVR',
        latitude: '19,14590073',
        longitude: '-96,18730164',
        altitude: '90',
        timezone: '-6',
        dst: 'S',
        type: 'airport',
    },
    {
        name: 'General Leobardo C. Ruiz International Airport',
        city: 'Zacatecas',
        country: 'Mexico',
        iataCode: 'ZCL',
        icaoCode: 'MMZC',
        latitude: '22,89710045',
        longitude: '-102,6869965',
        altitude: '7141',
        timezone: '-6',
        dst: 'S',
        type: 'airport',
    },
    {
        name: 'Ixtapa Zihuatanejo International Airport',
        city: 'Zihuatanejo',
        country: 'Mexico',
        iataCode: 'ZIH',
        icaoCode: 'MMZH',
        latitude: '17,60160065',
        longitude: '-101,4609985',
        altitude: '26',
        timezone: '-6',
        dst: 'S',
        type: 'airport',
    },
    {
        name: 'Zamora Airport',
        city: 'Zamora',
        country: 'Mexico',
        iataCode: 'ZMM',
        icaoCode: 'MMZM',
        latitude: '20,04500008',
        longitude: '-102,276001',
        altitude: '5141',
        timezone: '-6',
        dst: 'S',
        type: 'airport',
    },
    {
        name: 'Playa De Oro International Airport',
        city: 'Manzanillo',
        country: 'Mexico',
        iataCode: 'ZLO',
        icaoCode: 'MMZO',
        latitude: '19,14480019',
        longitude: '-104,5589981',
        altitude: '30',
        timezone: '-6',
        dst: 'S',
        type: 'airport',
    },
    {
        name: 'Zapopan Airport',
        city: 'Zapopan',
        country: 'Mexico',
        iataCode: 'N',
        icaoCode: 'MMZP',
        latitude: '20,747922',
        longitude: '-103,461709',
        altitude: '5333',
        timezone: '-6',
        dst: 'S',
        type: 'airport',
    },
    {
        name: 'Bluefields Airport',
        city: 'Bluefields',
        country: 'Nicaragua',
        iataCode: 'BEF',
        icaoCode: 'MNBL',
        latitude: '11,99100018',
        longitude: '-83,77410126',
        altitude: '20',
        timezone: '-6',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Los Brasiles Airport',
        city: 'Los Brasiles',
        country: 'Nicaragua',
        iataCode: 'N',
        icaoCode: 'MNBR',
        latitude: '12,18999958',
        longitude: '-86,35389709',
        altitude: '262',
        timezone: '-6',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Leon (Fanor Urroz) Airport',
        city: 'Leon',
        country: 'Nicaragua',
        iataCode: 'N',
        icaoCode: 'MNLN',
        latitude: '12,42920017',
        longitude: '-86,90280151',
        altitude: '328',
        timezone: '-6',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Augusto C. Sandino (Managua) International Airport',
        city: 'Managua',
        country: 'Nicaragua',
        iataCode: 'MGA',
        icaoCode: 'MNMG',
        latitude: '12,14150047',
        longitude: '-86,16819763',
        altitude: '194',
        timezone: '-6',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Puerto Cabezas Airport',
        city: 'Puerto Cabezas',
        country: 'Nicaragua',
        iataCode: 'PUZ',
        icaoCode: 'MNPC',
        latitude: '14,0472002',
        longitude: '-83,38670349',
        altitude: '52',
        timezone: '-6',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Bocas Del Toro International Airport',
        city: 'Bocas Del Toro',
        country: 'Panama',
        iataCode: 'BOC',
        icaoCode: 'MPBO',
        latitude: '9,340849876',
        longitude: '-82,25080109',
        altitude: '10',
        timezone: '-5',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Cap Manuel Niño International Airport',
        city: 'Changuinola',
        country: 'Panama',
        iataCode: 'CHX',
        icaoCode: 'MPCH',
        latitude: '9,458640099',
        longitude: '-82,51679993',
        altitude: '19',
        timezone: '-5',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Enrique Malek International Airport',
        city: 'David',
        country: 'Panama',
        iataCode: 'DAV',
        icaoCode: 'MPDA',
        latitude: '8,390999794',
        longitude: '-82,43499756',
        altitude: '89',
        timezone: '-5',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Panama Pacific International Airport',
        city: 'Howard',
        country: 'Panama',
        iataCode: 'BLB',
        icaoCode: 'MPHO',
        latitude: '8,91479',
        longitude: '-79,599602',
        altitude: '52',
        timezone: '-5',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Marcos A. Gelabert International Airport',
        city: 'Panama',
        country: 'Panama',
        iataCode: 'PAC',
        icaoCode: 'MPMG',
        latitude: '8,973340034',
        longitude: '-79,55560303',
        altitude: '31',
        timezone: '-5',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Ruben Cantu Airport',
        city: 'Santiago',
        country: 'Panama',
        iataCode: 'N',
        icaoCode: 'MPSA',
        latitude: '8,085599899',
        longitude: '-80,94529724',
        altitude: '272',
        timezone: '-5',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Tocumen International Airport',
        city: 'Panama City',
        country: 'Panama',
        iataCode: 'PTY',
        icaoCode: 'MPTO',
        latitude: '9,071359634',
        longitude: '-79,38349915',
        altitude: '135',
        timezone: '-5',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Buenos Aires Airport',
        city: 'Buenos Aires',
        country: 'Costa Rica',
        iataCode: 'N',
        icaoCode: 'MRBA',
        latitude: '9,163949',
        longitude: '-83,330171',
        altitude: '1214',
        timezone: '-6',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Coto 47 Airport',
        city: 'Coto 47',
        country: 'Costa Rica',
        iataCode: 'OTR',
        icaoCode: 'MRCC',
        latitude: '8,601559639',
        longitude: '-82,96859741',
        altitude: '26',
        timezone: '-6',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Chacarita Airport',
        city: 'Chacarita',
        country: 'Costa Rica',
        iataCode: 'N',
        icaoCode: 'MRCH',
        latitude: '9,981410027',
        longitude: '-84,77269745',
        altitude: '7',
        timezone: '-6',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'El Carmen de Siquirres Airport',
        city: 'El Carmen',
        country: 'Costa Rica',
        iataCode: 'N',
        icaoCode: 'MREC',
        latitude: '10,20199966',
        longitude: '-83,47219849',
        altitude: '56',
        timezone: '-6',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Finca 10 / Nuevo Palmar Sur Airport',
        city: 'Finca 10',
        country: 'Costa Rica',
        iataCode: 'N',
        icaoCode: 'MRFI',
        latitude: '8,916350365',
        longitude: '-83,50730133',
        altitude: '25',
        timezone: '-6',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Golfito Airport',
        city: 'Golfito',
        country: 'Costa Rica',
        iataCode: 'GLF',
        icaoCode: 'MRGF',
        latitude: '8,654009819',
        longitude: '-83,18219757',
        altitude: '49',
        timezone: '-6',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Guapiles Airport',
        city: 'Guapiles',
        country: 'Costa Rica',
        iataCode: 'N',
        icaoCode: 'MRGP',
        latitude: '10,21720028',
        longitude: '-83,79699707',
        altitude: '883',
        timezone: '-6',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Daniel Oduber Quiros International Airport',
        city: 'Liberia',
        country: 'Costa Rica',
        iataCode: 'LIR',
        icaoCode: 'MRLB',
        latitude: '10,59329987',
        longitude: '-85,54440308',
        altitude: '270',
        timezone: '-6',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Los Chiles Airport',
        city: 'Los Chiles',
        country: 'Costa Rica',
        iataCode: 'N',
        icaoCode: 'MRLC',
        latitude: '11,03530025',
        longitude: '-84,70610046',
        altitude: '131',
        timezone: '-6',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Limon International Airport',
        city: 'Limon',
        country: 'Costa Rica',
        iataCode: 'LIO',
        icaoCode: 'MRLM',
        latitude: '9,957960129',
        longitude: '-83,02200317',
        altitude: '7',
        timezone: '-6',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Nosara Airport',
        city: 'Nosara Beach',
        country: 'Costa Rica',
        iataCode: 'NOB',
        icaoCode: 'MRNS',
        latitude: '9,976490021',
        longitude: '-85,65299988',
        altitude: '33',
        timezone: '-6',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Juan Santamaria International Airport',
        city: 'San Jose',
        country: 'Costa Rica',
        iataCode: 'SJO',
        icaoCode: 'MROC',
        latitude: '9,993860245',
        longitude: '-84,20880127',
        altitude: '3021',
        timezone: '-6',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Pandora Airport',
        city: 'Pandora',
        country: 'Costa Rica',
        iataCode: 'N',
        icaoCode: 'MRPD',
        latitude: '9,732170105',
        longitude: '-82,98320007',
        altitude: '98',
        timezone: '-6',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Palmar Sur Airport',
        city: 'Palmar Sur',
        country: 'Costa Rica',
        iataCode: 'PMZ',
        icaoCode: 'MRPM',
        latitude: '8,951029778',
        longitude: '-83,46859741',
        altitude: '49',
        timezone: '-6',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Quepos Managua Airport',
        city: 'Quepos',
        country: 'Costa Rica',
        iataCode: 'XQP',
        icaoCode: 'MRQP',
        latitude: '9,443160057',
        longitude: '-84,12979889',
        altitude: '85',
        timezone: '-6',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Santa Clara De Guapiles Airport',
        city: 'Santa Clara',
        country: 'Costa Rica',
        iataCode: 'N',
        icaoCode: 'MRSG',
        latitude: '10,28829956',
        longitude: '-83,71350098',
        altitude: '262',
        timezone: '-6',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'San Vito De Java Airport',
        city: 'San Vito De Jaba',
        country: 'Costa Rica',
        iataCode: 'N',
        icaoCode: 'MRSV',
        latitude: '8,826109886',
        longitude: '-82,95890045',
        altitude: '3228',
        timezone: '-6',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'El Salvador International Airport',
        city: 'San Salvador',
        country: 'El Salvador',
        iataCode: 'SAL',
        icaoCode: 'MSLP',
        latitude: '13,4409',
        longitude: '-89,055702',
        altitude: '101',
        timezone: '-6',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Ilopango International Airport',
        city: 'San Salvador',
        country: 'El Salvador',
        iataCode: 'N',
        icaoCode: 'MSSS',
        latitude: '13,69950008',
        longitude: '-89,11990356',
        altitude: '2021',
        timezone: '-6',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Les Cayes Airport',
        city: 'Cayes',
        country: 'Haiti',
        iataCode: 'N',
        icaoCode: 'MTCA',
        latitude: '18,27109909',
        longitude: '-73,78829956',
        altitude: '203',
        timezone: '-5',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Cap Haitien International Airport',
        city: 'Cap Haitien',
        country: 'Haiti',
        iataCode: 'CAP',
        icaoCode: 'MTCH',
        latitude: '19,7329998',
        longitude: '-72,19470215',
        altitude: '10',
        timezone: '-5',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Jacmel Airport',
        city: 'Jacmel',
        country: 'Haiti',
        iataCode: 'N',
        icaoCode: 'MTJA',
        latitude: '18,24110031',
        longitude: '-72,51850128',
        altitude: '167',
        timezone: '-5',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Toussaint Louverture International Airport',
        city: 'Port-au-prince',
        country: 'Haiti',
        iataCode: 'PAP',
        icaoCode: 'MTPP',
        latitude: '18,57999992',
        longitude: '-72,29250336',
        altitude: '122',
        timezone: '-5',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Gustavo Rizo Airport',
        city: 'Baracoa Playa',
        country: 'Cuba',
        iataCode: 'BCA',
        icaoCode: 'MUBA',
        latitude: '20,36529922',
        longitude: '-74,5062027',
        altitude: '26',
        timezone: '-5',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Carlos Manuel de Cespedes Airport',
        city: 'Bayamo',
        country: 'Cuba',
        iataCode: 'BYM',
        icaoCode: 'MUBY',
        latitude: '20,39640045',
        longitude: '-76,62139893',
        altitude: '203',
        timezone: '-5',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Maximo Gomez Airport',
        city: 'Ciego De Avila',
        country: 'Cuba',
        iataCode: 'AVI',
        icaoCode: 'MUCA',
        latitude: '22,02709961',
        longitude: '-78,78959656',
        altitude: '335',
        timezone: '-5',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Jardines Del Rey Airport',
        city: 'Cunagua',
        country: 'Cuba',
        iataCode: 'CCC',
        icaoCode: 'MUCC',
        latitude: '22,46100044',
        longitude: '-78,32839966',
        altitude: '13',
        timezone: '-5',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Jaime Gonzalez Airport',
        city: 'Cienfuegos',
        country: 'Cuba',
        iataCode: 'CFG',
        icaoCode: 'MUCF',
        latitude: '22,14999962',
        longitude: '-80,41419983',
        altitude: '102',
        timezone: '-5',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Vilo Acuña International Airport',
        city: 'Cayo Largo del Sur',
        country: 'Cuba',
        iataCode: 'CYO',
        icaoCode: 'MUCL',
        latitude: '21,61650085',
        longitude: '-81,54599762',
        altitude: '10',
        timezone: '-5',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Ignacio Agramonte International Airport',
        city: 'Camaguey',
        country: 'Cuba',
        iataCode: 'CMW',
        icaoCode: 'MUCM',
        latitude: '21,42029953',
        longitude: '-77,84750366',
        altitude: '413',
        timezone: '-5',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Antonio Maceo International Airport',
        city: 'Santiago De Cuba',
        country: 'Cuba',
        iataCode: 'SCU',
        icaoCode: 'MUCU',
        latitude: '19,96980095',
        longitude: '-75,83540344',
        altitude: '249',
        timezone: '-5',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Florida Airport',
        city: 'Florida',
        country: 'Cuba',
        iataCode: 'N',
        icaoCode: 'MUFL',
        latitude: '21,49970055',
        longitude: '-78,20279694',
        altitude: '197',
        timezone: '-5',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Leeward Point Field',
        city: 'Guantanamo',
        country: 'Cuba',
        iataCode: 'N',
        icaoCode: 'MUGM',
        latitude: '19,90649986',
        longitude: '-75,20709991',
        altitude: '56',
        timezone: '-5',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Mariana Grajales Airport',
        city: 'Guantanamo',
        country: 'Cuba',
        iataCode: 'GAO',
        icaoCode: 'MUGT',
        latitude: '20,08530045',
        longitude: '-75,15830231',
        altitude: '56',
        timezone: '-5',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'José Martí International Airport',
        city: 'Havana',
        country: 'Cuba',
        iataCode: 'HAV',
        icaoCode: 'MUHA',
        latitude: '22,98920059',
        longitude: '-82,40910339',
        altitude: '210',
        timezone: '-5',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Frank Pais International Airport',
        city: 'Holguin',
        country: 'Cuba',
        iataCode: 'HOG',
        icaoCode: 'MUHG',
        latitude: '20,78560066',
        longitude: '-76,31510162',
        altitude: '361',
        timezone: '-5',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'La Coloma Airport',
        city: 'La Coloma',
        country: 'Cuba',
        iataCode: 'LCL',
        icaoCode: 'MULM',
        latitude: '22,33609962',
        longitude: '-83,64189911',
        altitude: '131',
        timezone: '-5',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Orestes Acosta Airport',
        city: 'Moa',
        country: 'Cuba',
        iataCode: 'MOA',
        icaoCode: 'MUMO',
        latitude: '20,65390015',
        longitude: '-74,92220306',
        altitude: '16',
        timezone: '-5',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Sierra Maestra Airport',
        city: 'Manzanillo',
        country: 'Cuba',
        iataCode: 'MZO',
        icaoCode: 'MUMZ',
        latitude: '20,28809929',
        longitude: '-77,08920288',
        altitude: '112',
        timezone: '-5',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Rafael Cabrera Airport',
        city: 'Nueva Gerona',
        country: 'Cuba',
        iataCode: 'GER',
        icaoCode: 'MUNG',
        latitude: '21,83469963',
        longitude: '-82,78379822',
        altitude: '79',
        timezone: '-5',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Playa Baracoa Airport',
        city: 'Baracoa Playa',
        country: 'Cuba',
        iataCode: 'UPB',
        icaoCode: 'MUPB',
        latitude: '23,03280067',
        longitude: '-82,57939911',
        altitude: '102',
        timezone: '-5',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Pinar Del Rio Airport',
        city: 'Pinar Del Rio Norte',
        country: 'Cuba',
        iataCode: 'N',
        icaoCode: 'MUPR',
        latitude: '22,42140007',
        longitude: '-83,67839813',
        altitude: '131',
        timezone: '-5',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'San Antonio De Los Banos Airport',
        city: 'San Antonio De Banos',
        country: 'Cuba',
        iataCode: 'N',
        icaoCode: 'MUSA',
        latitude: '22,87150002',
        longitude: '-82,50930023',
        altitude: '164',
        timezone: '-5',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Abel Santamaria Airport',
        city: 'Santa Clara',
        country: 'Cuba',
        iataCode: 'SNU',
        icaoCode: 'MUSC',
        latitude: '22,49220085',
        longitude: '-79,94360352',
        altitude: '338',
        timezone: '-5',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Joaquín de Agüero Airport',
        city: 'Santa Lucia',
        country: 'Cuba',
        iataCode: 'N',
        icaoCode: 'MUSL',
        latitude: '21,50945591',
        longitude: '-77,02059746',
        altitude: '13',
        timezone: '-5',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Siguanea Airport',
        city: 'Siguanea',
        country: 'Cuba',
        iataCode: 'N',
        icaoCode: 'MUSN',
        latitude: '21,64249992',
        longitude: '-82,95510101',
        altitude: '39',
        timezone: '-5',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Sancti Spiritus Airport',
        city: 'Sancti Spiritus',
        country: 'Cuba',
        iataCode: 'N',
        icaoCode: 'MUSS',
        latitude: '21,9704',
        longitude: '-79,442703',
        altitude: '295',
        timezone: '-5',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Juan Gualberto Gomez International Airport',
        city: 'Varadero',
        country: 'Cuba',
        iataCode: 'VRA',
        icaoCode: 'MUVR',
        latitude: '23,03440094',
        longitude: '-81,43530273',
        altitude: '210',
        timezone: '-5',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Hermanos Ameijeiras Airport',
        city: 'Las Tunas',
        country: 'Cuba',
        iataCode: 'VTU',
        icaoCode: 'MUVT',
        latitude: '20,98760033',
        longitude: '-76,93579865',
        altitude: '328',
        timezone: '-5',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Gerrard Smith International Airport',
        city: 'Cayman Brac',
        country: 'Cayman Islands',
        iataCode: 'CYB',
        icaoCode: 'MWCB',
        latitude: '19,68700027',
        longitude: '-79,88279724',
        altitude: '8',
        timezone: '-5',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Owen Roberts International Airport',
        city: 'Georgetown',
        country: 'Cayman Islands',
        iataCode: 'GCM',
        icaoCode: 'MWCR',
        latitude: '19,2928009',
        longitude: '-81,35769653',
        altitude: '8',
        timezone: '-5',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Clarence A. Bain Airport',
        city: 'Clarence Bain',
        country: 'Bahamas',
        iataCode: 'N',
        icaoCode: 'MYAB',
        latitude: '24,28770065',
        longitude: '-77,68460083',
        altitude: '19',
        timezone: '-5',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Andros Town Airport',
        city: 'Andros Town',
        country: 'Bahamas',
        iataCode: 'ASD',
        icaoCode: 'MYAF',
        latitude: '24,69790077',
        longitude: '-77,79560089',
        altitude: '5',
        timezone: '-5',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Marsh Harbour International Airport',
        city: 'Marsh Harbor',
        country: 'Bahamas',
        iataCode: 'MHH',
        icaoCode: 'MYAM',
        latitude: '26,51140022',
        longitude: '-77,08350372',
        altitude: '6',
        timezone: '-5',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'San Andros Airport',
        city: 'San Andros',
        country: 'Bahamas',
        iataCode: 'SAQ',
        icaoCode: 'MYAN',
        latitude: '25,05380058',
        longitude: '-78,0490036',
        altitude: '5',
        timezone: '-5',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Spring Point Airport',
        city: 'Spring Point',
        country: 'Bahamas',
        iataCode: 'AXP',
        icaoCode: 'MYAP',
        latitude: '22,44179916',
        longitude: '-73,97090149',
        altitude: '11',
        timezone: '-5',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Sandy Point Airport',
        city: 'Sandy Point',
        country: 'Bahamas',
        iataCode: 'N',
        icaoCode: 'MYAS',
        latitude: '26,00460052',
        longitude: '-77,39550018',
        altitude: '8',
        timezone: '-5',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Treasure Cay Airport',
        city: 'Treasure Cay',
        country: 'Bahamas',
        iataCode: 'TCB',
        icaoCode: 'MYAT',
        latitude: '26,74530029',
        longitude: '-77,39129639',
        altitude: '8',
        timezone: '-5',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Chub Cay Airport',
        city: 'Chub Cay',
        country: 'Bahamas',
        iataCode: 'CCZ',
        icaoCode: 'MYBC',
        latitude: '25,41710091',
        longitude: '-77,88089752',
        altitude: '5',
        timezone: '-5',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Great Harbour Cay Airport',
        city: 'Bullocks Harbour',
        country: 'Bahamas',
        iataCode: 'N',
        icaoCode: 'MYBG',
        latitude: '25,73830032',
        longitude: '-77,84010315',
        altitude: '18',
        timezone: '-5',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'South Bimini Airport',
        city: 'Alice Town',
        country: 'Bahamas',
        iataCode: 'BIM',
        icaoCode: 'MYBS',
        latitude: '25,69989967',
        longitude: '-79,26470184',
        altitude: '10',
        timezone: '-5',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Exuma International Airport',
        city: 'Great Exuma',
        country: 'Bahamas',
        iataCode: 'GGT',
        icaoCode: 'MYEF',
        latitude: '23,56259918',
        longitude: '-75,87799835',
        altitude: '9',
        timezone: '-5',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'George Town Airport',
        city: 'George Town',
        country: 'Bahamas',
        iataCode: 'N',
        icaoCode: 'MYEG',
        latitude: '23,4666996',
        longitude: '-75,78170013',
        altitude: '5',
        timezone: '-5',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'North Eleuthera Airport',
        city: 'North Eleuthera',
        country: 'Bahamas',
        iataCode: 'ELH',
        icaoCode: 'MYEH',
        latitude: '25,47489929',
        longitude: '-76,6835022',
        altitude: '13',
        timezone: '-5',
        dst: 'U',
        type: 'airport',
    },
    {
        name: "Governor's Harbour Airport",
        city: "Governor's Harbor",
        country: 'Bahamas',
        iataCode: 'GHB',
        icaoCode: 'MYEM',
        latitude: '25,28470039',
        longitude: '-76,33100128',
        altitude: '26',
        timezone: '-5',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Normans Cay Airport',
        city: "Norman's Cay",
        country: 'Bahamas',
        iataCode: 'N',
        icaoCode: 'MYEN',
        latitude: '24,59429932',
        longitude: '-76,82019806',
        altitude: '8',
        timezone: '-5',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Rock Sound Airport',
        city: 'Rock Sound',
        country: 'Bahamas',
        iataCode: 'RSD',
        icaoCode: 'MYER',
        latitude: '24,89507873',
        longitude: '-76,17688179',
        altitude: '10',
        timezone: '-5',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Staniel Cay Airport',
        city: 'Staniel Cay',
        country: 'Bahamas',
        iataCode: 'N',
        icaoCode: 'MYES',
        latitude: '24,16909981',
        longitude: '-76,43910217',
        altitude: '5',
        timezone: '-5',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Grand Bahama International Airport',
        city: 'Freeport',
        country: 'Bahamas',
        iataCode: 'FPO',
        icaoCode: 'MYGF',
        latitude: '26,55870056',
        longitude: '-78,69560242',
        altitude: '7',
        timezone: '-5',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Inagua Airport',
        city: 'Matthew Town',
        country: 'Bahamas',
        iataCode: 'IGA',
        icaoCode: 'MYIG',
        latitude: '20,97500038',
        longitude: '-73,66690063',
        altitude: '8',
        timezone: '-5',
        dst: 'U',
        type: 'airport',
    },
    {
        name: "Deadman's Cay Airport",
        city: "Dead Man's Cay",
        country: 'Bahamas',
        iataCode: 'LGI',
        icaoCode: 'MYLD',
        latitude: '23,17900085',
        longitude: '-75,09359741',
        altitude: '9',
        timezone: '-5',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Stella Maris Airport',
        city: 'Stella Maris',
        country: 'Bahamas',
        iataCode: 'SML',
        icaoCode: 'MYLS',
        latitude: '23,5823168',
        longitude: '-75,26862144',
        altitude: '10',
        timezone: '-5',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Mayaguana Airport',
        city: 'Mayaguana',
        country: 'Bahamas',
        iataCode: 'MYG',
        icaoCode: 'MYMM',
        latitude: '22,37949944',
        longitude: '-73,0134964',
        altitude: '11',
        timezone: '-5',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Lynden Pindling International Airport',
        city: 'Nassau',
        country: 'Bahamas',
        iataCode: 'NAS',
        icaoCode: 'MYNN',
        latitude: '25,03899956',
        longitude: '-77,46620178',
        altitude: '16',
        timezone: '-5',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Duncan Town Airport',
        city: 'Duncan Town',
        country: 'Bahamas',
        iataCode: 'N',
        icaoCode: 'MYRD',
        latitude: '22,18180084',
        longitude: '-75,72949982',
        altitude: '6',
        timezone: '-5',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Rum Cay Airport',
        city: 'Port Nelson',
        country: 'Bahamas',
        iataCode: 'N',
        icaoCode: 'MYRP',
        latitude: '23,68440056',
        longitude: '-74,8361969',
        altitude: '15',
        timezone: '-5',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'San Salvador Airport',
        city: 'Cockburn Town',
        country: 'Bahamas',
        iataCode: 'ZSA',
        icaoCode: 'MYSM',
        latitude: '24,06329918',
        longitude: '-74,52400208',
        altitude: '24',
        timezone: '-5',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Philip S. W. Goldson International Airport',
        city: 'Belize City',
        country: 'Belize',
        iataCode: 'BZE',
        icaoCode: 'MZBZ',
        latitude: '17,53910065',
        longitude: '-88,30819702',
        altitude: '15',
        timezone: '-6',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Aitutaki Airport',
        city: 'Aitutaki',
        country: 'Cook Islands',
        iataCode: 'AIT',
        icaoCode: 'NCAI',
        latitude: '-18,83090019',
        longitude: '-159,7640076',
        altitude: '14',
        timezone: '-10',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Rarotonga International Airport',
        city: 'Avarua',
        country: 'Cook Islands',
        iataCode: 'RAR',
        icaoCode: 'NCRG',
        latitude: '-21,20269966',
        longitude: '-159,8059998',
        altitude: '19',
        timezone: '-10',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Nadi International Airport',
        city: 'Nandi',
        country: 'Fiji',
        iataCode: 'NAN',
        icaoCode: 'NFFN',
        latitude: '-17,7553997',
        longitude: '177,4429932',
        altitude: '59',
        timezone: '12',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Nausori International Airport',
        city: 'Nausori',
        country: 'Fiji',
        iataCode: 'SUV',
        icaoCode: 'NFNA',
        latitude: '-18,04330063',
        longitude: '178,5590057',
        altitude: '17',
        timezone: '12',
        dst: 'U',
        type: 'airport',
    },
    {
        name: "Fua'amotu International Airport",
        city: 'Tongatapu',
        country: 'Tonga',
        iataCode: 'TBU',
        icaoCode: 'NFTF',
        latitude: '-21,24119949',
        longitude: '-175,1499939',
        altitude: '126',
        timezone: '13',
        dst: 'U',
        type: 'airport',
    },
    {
        name: "Vava'u International Airport",
        city: "Vava'u",
        country: 'Tonga',
        iataCode: 'VAV',
        icaoCode: 'NFTV',
        latitude: '-18,58530045',
        longitude: '-173,9620056',
        altitude: '236',
        timezone: '13',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Bonriki International Airport',
        city: 'Tarawa',
        country: 'Kiribati',
        iataCode: 'TRW',
        icaoCode: 'NGTA',
        latitude: '1,381639957',
        longitude: '173,1470032',
        altitude: '9',
        timezone: '12',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Tabiteuea North Airport',
        city: 'Tabiteuea North',
        country: 'Kiribati',
        iataCode: 'TBF',
        icaoCode: 'NGTE',
        latitude: '-1,224470019',
        longitude: '174,776001',
        altitude: '7',
        timezone: '12',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Hihifo Airport',
        city: 'Wallis',
        country: 'Wallis and Futuna',
        iataCode: 'WLS',
        icaoCode: 'NLWW',
        latitude: '-13,23830032',
        longitude: '-176,1990051',
        altitude: '79',
        timezone: '12',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Faleolo International Airport',
        city: 'Faleolo',
        country: 'Samoa',
        iataCode: 'APW',
        icaoCode: 'NSFA',
        latitude: '-13,82999992',
        longitude: '-172,0079956',
        altitude: '58',
        timezone: '13',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Pago Pago International Airport',
        city: 'Pago Pago',
        country: 'American Samoa',
        iataCode: 'PPG',
        icaoCode: 'NSTU',
        latitude: '-14,33100033',
        longitude: '-170,7100067',
        altitude: '32',
        timezone: '-11',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Rurutu Airport',
        city: 'Rurutu',
        country: 'French Polynesia',
        iataCode: 'RUR',
        icaoCode: 'NTAR',
        latitude: '-22,4340992',
        longitude: '-151,3609924',
        altitude: '18',
        timezone: '-10',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Tubuai Airport',
        city: 'Tubuai',
        country: 'French Polynesia',
        iataCode: 'TUB',
        icaoCode: 'NTAT',
        latitude: '-23,36540031',
        longitude: '-149,5240021',
        altitude: '7',
        timezone: '-10',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Anaa Airport',
        city: 'Anaa',
        country: 'French Polynesia',
        iataCode: 'AAA',
        icaoCode: 'NTGA',
        latitude: '-17,3526001',
        longitude: '-145,5099945',
        altitude: '10',
        timezone: '-10',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Fangatau Airport',
        city: 'Fangatau',
        country: 'French Polynesia',
        iataCode: 'N',
        icaoCode: 'NTGB',
        latitude: '-15,81989956',
        longitude: '-140,8869934',
        altitude: '9',
        timezone: '-10',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Tikehau Airport',
        city: 'Tikehau',
        country: 'French Polynesia',
        iataCode: 'TIH',
        icaoCode: 'NTGC',
        latitude: '-15,1196003',
        longitude: '-148,2310028',
        altitude: '6',
        timezone: '-10',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Reao Airport',
        city: 'Reao',
        country: 'French Polynesia',
        iataCode: 'REA',
        icaoCode: 'NTGE',
        latitude: '-18,46590042',
        longitude: '-136,4400024',
        altitude: '12',
        timezone: '-10',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Fakarava Airport',
        city: 'Fakarava',
        country: 'French Polynesia',
        iataCode: 'FAV',
        icaoCode: 'NTGF',
        latitude: '-16,05410004',
        longitude: '-145,6569977',
        altitude: '13',
        timezone: '-10',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Manihi Airport',
        city: 'Manihi',
        country: 'French Polynesia',
        iataCode: 'XMH',
        icaoCode: 'NTGI',
        latitude: '-14,4368',
        longitude: '-146,0700073',
        altitude: '14',
        timezone: '-10',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Totegegie Airport',
        city: 'Totegegie',
        country: 'French Polynesia',
        iataCode: 'GMR',
        icaoCode: 'NTGJ',
        latitude: '-23,07990074',
        longitude: '-134,8899994',
        altitude: '7',
        timezone: '-9',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Kaukura Airport',
        city: 'Kaukura Atoll',
        country: 'French Polynesia',
        iataCode: 'KKR',
        icaoCode: 'NTGK',
        latitude: '-15,66329956',
        longitude: '-146,8849945',
        altitude: '11',
        timezone: '-10',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Makemo Airport',
        city: 'Makemo',
        country: 'French Polynesia',
        iataCode: 'MKP',
        icaoCode: 'NTGM',
        latitude: '-16,58390045',
        longitude: '-143,6580048',
        altitude: '3',
        timezone: '-10',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Puka Puka Airport',
        city: 'Puka Puka',
        country: 'French Polynesia',
        iataCode: 'PKP',
        icaoCode: 'NTGP',
        latitude: '-14,80949974',
        longitude: '-138,8130035',
        altitude: '5',
        timezone: '-10',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Takapoto Airport',
        city: 'Takapoto',
        country: 'French Polynesia',
        iataCode: 'TKP',
        icaoCode: 'NTGT',
        latitude: '-14,70950031',
        longitude: '-145,2460022',
        altitude: '12',
        timezone: '-10',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Arutua Airport',
        city: 'Arutua',
        country: 'French Polynesia',
        iataCode: 'AXR',
        icaoCode: 'NTGU',
        latitude: '-15,2482996',
        longitude: '-146,6170044',
        altitude: '9',
        timezone: '-10',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Mataiva Airport',
        city: 'Mataiva',
        country: 'French Polynesia',
        iataCode: 'MVT',
        icaoCode: 'NTGV',
        latitude: '-14,86810017',
        longitude: '-148,7169952',
        altitude: '11',
        timezone: '-10',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Takaroa Airport',
        city: 'Takaroa',
        country: 'French Polynesia',
        iataCode: 'TKX',
        icaoCode: 'NTKR',
        latitude: '-14,45580006',
        longitude: '-145,0249939',
        altitude: '13',
        timezone: '-10',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Nuku Hiva Airport',
        city: 'Nuku Hiva',
        country: 'French Polynesia',
        iataCode: 'NHV',
        icaoCode: 'NTMD',
        latitude: '-8,795599937',
        longitude: '-140,2290039',
        altitude: '220',
        timezone: '-9,5',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Bora Bora Airport',
        city: 'Bora Bora',
        country: 'French Polynesia',
        iataCode: 'BOB',
        icaoCode: 'NTTB',
        latitude: '-16,44440079',
        longitude: '-151,7510071',
        altitude: '10',
        timezone: '-10',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Rangiroa Airport',
        city: 'Rangiroa',
        country: 'French Polynesia',
        iataCode: 'RGI',
        icaoCode: 'NTTG',
        latitude: '-14,95429993',
        longitude: '-147,6609955',
        altitude: '10',
        timezone: '-10',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Huahine-Fare Airport',
        city: 'Huahine Island',
        country: 'French Polynesia',
        iataCode: 'HUH',
        icaoCode: 'NTTH',
        latitude: '-16,68720055',
        longitude: '-151,0220032',
        altitude: '7',
        timezone: '-10',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Moorea Airport',
        city: 'Moorea',
        country: 'French Polynesia',
        iataCode: 'MOZ',
        icaoCode: 'NTTM',
        latitude: '-17,48999977',
        longitude: '-149,7619934',
        altitude: '9',
        timezone: '-10',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Hao Airport',
        city: 'Hao Island',
        country: 'French Polynesia',
        iataCode: 'HOI',
        icaoCode: 'NTTO',
        latitude: '-18,07480049',
        longitude: '-140,9459991',
        altitude: '10',
        timezone: '-10',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Maupiti Airport',
        city: 'Maupiti',
        country: 'French Polynesia',
        iataCode: 'MAU',
        icaoCode: 'NTTP',
        latitude: '-16,42650032',
        longitude: '-152,2440033',
        altitude: '15',
        timezone: '-10',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Raiatea Airport',
        city: 'Raiatea Island',
        country: 'French Polynesia',
        iataCode: 'RFP',
        icaoCode: 'NTTR',
        latitude: '-16,7229',
        longitude: '-151,466003',
        altitude: '3',
        timezone: '-10',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Bauerfield International Airport',
        city: 'Port-vila',
        country: 'Vanuatu',
        iataCode: 'VLI',
        icaoCode: 'NVVV',
        latitude: '-17,69930077',
        longitude: '168,3200073',
        altitude: '70',
        timezone: '11',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Koné Airport',
        city: 'Kone',
        country: 'New Caledonia',
        iataCode: 'KNQ',
        icaoCode: 'NWWD',
        latitude: '-21,05430031',
        longitude: '164,8370056',
        altitude: '23',
        timezone: '11',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Koumac Airport',
        city: 'Koumac',
        country: 'New Caledonia',
        iataCode: 'KOC',
        icaoCode: 'NWWK',
        latitude: '-20,54630089',
        longitude: '164,2559967',
        altitude: '42',
        timezone: '11',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Lifou Airport',
        city: 'Lifou',
        country: 'New Caledonia',
        iataCode: 'LIF',
        icaoCode: 'NWWL',
        latitude: '-20,77479935',
        longitude: '167,2400055',
        altitude: '92',
        timezone: '11',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Nouméa Magenta Airport',
        city: 'Noumea',
        country: 'New Caledonia',
        iataCode: 'GEA',
        icaoCode: 'NWWM',
        latitude: '-22,25830078',
        longitude: '166,4730072',
        altitude: '10',
        timezone: '11',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Maré Airport',
        city: 'Mare',
        country: 'New Caledonia',
        iataCode: 'MEE',
        icaoCode: 'NWWR',
        latitude: '-21,4817009',
        longitude: '168,0379944',
        altitude: '141',
        timezone: '11',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Touho Airport',
        city: 'Touho',
        country: 'New Caledonia',
        iataCode: 'TOU',
        icaoCode: 'NWWU',
        latitude: '-20,79000092',
        longitude: '165,2590027',
        altitude: '10',
        timezone: '11',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Ouvéa Airport',
        city: 'Ouvea',
        country: 'New Caledonia',
        iataCode: 'UVE',
        icaoCode: 'NWWV',
        latitude: '-20,6406002',
        longitude: '166,572998',
        altitude: '23',
        timezone: '11',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'La Tontouta International Airport',
        city: 'Noumea',
        country: 'New Caledonia',
        iataCode: 'NOU',
        icaoCode: 'NWWW',
        latitude: '-22,01460075',
        longitude: '166,2129974',
        altitude: '52',
        timezone: '11',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Auckland International Airport',
        city: 'Auckland',
        country: 'New Zealand',
        iataCode: 'AKL',
        icaoCode: 'NZAA',
        latitude: '-37,0080986',
        longitude: '174,7920074',
        altitude: '23',
        timezone: '12',
        dst: 'Z',
        type: 'airport',
    },
    {
        name: 'Taupo Airport',
        city: 'Taupo',
        country: 'New Zealand',
        iataCode: 'TUO',
        icaoCode: 'NZAP',
        latitude: '-38,73970032',
        longitude: '176,0839996',
        altitude: '1335',
        timezone: '12',
        dst: 'Z',
        type: 'airport',
    },
    {
        name: 'Ardmore Airport',
        city: 'Ardmore',
        country: 'New Zealand',
        iataCode: 'AMZ',
        icaoCode: 'NZAR',
        latitude: '-37,02970123',
        longitude: '174,9730072',
        altitude: '111',
        timezone: '12',
        dst: 'Z',
        type: 'airport',
    },
    {
        name: 'Christchurch International Airport',
        city: 'Christchurch',
        country: 'New Zealand',
        iataCode: 'CHC',
        icaoCode: 'NZCH',
        latitude: '-43,48939896',
        longitude: '172,5319977',
        altitude: '123',
        timezone: '12',
        dst: 'Z',
        type: 'airport',
    },
    {
        name: 'Chatham Islands-Tuuta Airport',
        city: 'Chatham Island',
        country: 'New Zealand',
        iataCode: 'CHT',
        icaoCode: 'NZCI',
        latitude: '-43,81000137',
        longitude: '-176,4570007',
        altitude: '43',
        timezone: '12,75',
        dst: 'Z',
        type: 'airport',
    },
    {
        name: 'Dunedin Airport',
        city: 'Dunedin',
        country: 'New Zealand',
        iataCode: 'DUD',
        icaoCode: 'NZDN',
        latitude: '-45,92810059',
        longitude: '170,197998',
        altitude: '4',
        timezone: '12',
        dst: 'Z',
        type: 'airport',
    },
    {
        name: 'Gisborne Airport',
        city: 'Gisborne',
        country: 'New Zealand',
        iataCode: 'GIS',
        icaoCode: 'NZGS',
        latitude: '-38,66329956',
        longitude: '177,9779968',
        altitude: '15',
        timezone: '12',
        dst: 'Z',
        type: 'airport',
    },
    {
        name: 'Glentanner Airport',
        city: 'Glentanner',
        country: 'New Zealand',
        iataCode: 'N',
        icaoCode: 'NZGT',
        latitude: '-43,90670013',
        longitude: '170,128006',
        altitude: '1824',
        timezone: '12',
        dst: 'Z',
        type: 'airport',
    },
    {
        name: 'Hokitika Airfield',
        city: 'Hokitika',
        country: 'New Zealand',
        iataCode: 'HKK',
        icaoCode: 'NZHK',
        latitude: '-42,71360016',
        longitude: '170,9850006',
        altitude: '146',
        timezone: '12',
        dst: 'Z',
        type: 'airport',
    },
    {
        name: 'Hamilton International Airport',
        city: 'Hamilton',
        country: 'New Zealand',
        iataCode: 'HLZ',
        icaoCode: 'NZHN',
        latitude: '-37,86669922',
        longitude: '175,3320007',
        altitude: '172',
        timezone: '12',
        dst: 'Z',
        type: 'airport',
    },
    {
        name: 'Hastings Aerodrome',
        city: 'Hastings',
        country: 'New Zealand',
        iataCode: 'N',
        icaoCode: 'NZHS',
        latitude: '-39,646702',
        longitude: '176,766998',
        altitude: '72',
        timezone: '12',
        dst: 'Z',
        type: 'airport',
    },
    {
        name: 'Kerikeri Airport',
        city: 'Kerikeri',
        country: 'New Zealand',
        iataCode: 'KKE',
        icaoCode: 'NZKK',
        latitude: '-35,26279831',
        longitude: '173,9120026',
        altitude: '492',
        timezone: '12',
        dst: 'Z',
        type: 'airport',
    },
    {
        name: 'Kaitaia Airport',
        city: 'Kaitaia',
        country: 'New Zealand',
        iataCode: 'KAT',
        icaoCode: 'NZKT',
        latitude: '-35,06999969',
        longitude: '173,2850037',
        altitude: '270',
        timezone: '12',
        dst: 'Z',
        type: 'airport',
    },
    {
        name: 'Alexandra Airport',
        city: 'Alexandra',
        country: 'New Zealand',
        iataCode: 'ALR',
        icaoCode: 'NZLX',
        latitude: '-45,21170044',
        longitude: '169,3730011',
        altitude: '752',
        timezone: '12',
        dst: 'Z',
        type: 'airport',
    },
    {
        name: 'Mount Cook Airport',
        city: 'Mount Cook',
        country: 'New Zealand',
        iataCode: 'MON',
        icaoCode: 'NZMC',
        latitude: '-43,76499939',
        longitude: '170,1329956',
        altitude: '2153',
        timezone: '12',
        dst: 'Z',
        type: 'airport',
    },
    {
        name: 'Manapouri Airport',
        city: 'Manapouri',
        country: 'New Zealand',
        iataCode: 'TEU',
        icaoCode: 'NZMO',
        latitude: '-45,53310013',
        longitude: '167,6499939',
        altitude: '687',
        timezone: '12',
        dst: 'Z',
        type: 'airport',
    },
    {
        name: 'Hood Airport',
        city: 'Masterton',
        country: 'New Zealand',
        iataCode: 'MRO',
        icaoCode: 'NZMS',
        latitude: '-40,97330093',
        longitude: '175,6340027',
        altitude: '364',
        timezone: '12',
        dst: 'Z',
        type: 'airport',
    },
    {
        name: 'New Plymouth Airport',
        city: 'New Plymouth',
        country: 'New Zealand',
        iataCode: 'NPL',
        icaoCode: 'NZNP',
        latitude: '-39,00859833',
        longitude: '174,1790009',
        altitude: '97',
        timezone: '12',
        dst: 'Z',
        type: 'airport',
    },
    {
        name: 'Nelson Airport',
        city: 'Nelson',
        country: 'New Zealand',
        iataCode: 'NSN',
        icaoCode: 'NZNS',
        latitude: '-41,2983017',
        longitude: '173,220993',
        altitude: '17',
        timezone: '12',
        dst: 'Z',
        type: 'airport',
    },
    {
        name: 'Invercargill Airport',
        city: 'Invercargill',
        country: 'New Zealand',
        iataCode: 'IVC',
        icaoCode: 'NZNV',
        latitude: '-46,41239929',
        longitude: '168,3130035',
        altitude: '5',
        timezone: '12',
        dst: 'Z',
        type: 'airport',
    },
    {
        name: 'RNZAF Base Ohakea',
        city: 'Ohakea',
        country: 'New Zealand',
        iataCode: 'N',
        icaoCode: 'NZOH',
        latitude: '-40,20600128',
        longitude: '175,3880005',
        altitude: '164',
        timezone: '12',
        dst: 'Z',
        type: 'airport',
    },
    {
        name: 'Oamaru Airport',
        city: 'Oamaru',
        country: 'New Zealand',
        iataCode: 'OAM',
        icaoCode: 'NZOU',
        latitude: '-44,97000122',
        longitude: '171,0820007',
        altitude: '99',
        timezone: '12',
        dst: 'Z',
        type: 'airport',
    },
    {
        name: 'Palmerston North Airport',
        city: 'Palmerston North',
        country: 'New Zealand',
        iataCode: 'PMR',
        icaoCode: 'NZPM',
        latitude: '-40,3205986',
        longitude: '175,6170044',
        altitude: '151',
        timezone: '12',
        dst: 'Z',
        type: 'airport',
    },
    {
        name: 'Paraparaumu Airport',
        city: 'Paraparaumu',
        country: 'New Zealand',
        iataCode: 'PPQ',
        icaoCode: 'NZPP',
        latitude: '-40,90470123',
        longitude: '174,9889984',
        altitude: '22',
        timezone: '12',
        dst: 'Z',
        type: 'airport',
    },
    {
        name: 'Queenstown International Airport',
        city: 'Queenstown International',
        country: 'New Zealand',
        iataCode: 'ZQN',
        icaoCode: 'NZQN',
        latitude: '-45,02109909',
        longitude: '168,7389984',
        altitude: '1171',
        timezone: '12',
        dst: 'Z',
        type: 'airport',
    },
    {
        name: 'Rotorua Regional Airport',
        city: 'Rotorua',
        country: 'New Zealand',
        iataCode: 'ROT',
        icaoCode: 'NZRO',
        latitude: '-38,10919952',
        longitude: '176,3170013',
        altitude: '935',
        timezone: '12',
        dst: 'Z',
        type: 'airport',
    },
    {
        name: 'Waiouru Airport',
        city: 'Waiouru',
        country: 'New Zealand',
        iataCode: 'N',
        icaoCode: 'NZRU',
        latitude: '-39,44639969',
        longitude: '175,6580048',
        altitude: '2686',
        timezone: '12',
        dst: 'Z',
        type: 'airport',
    },
    {
        name: 'South Pole Station Airport',
        city: "Stephen's Island",
        country: 'Antarctica',
        iataCode: 'N',
        icaoCode: 'NZSP',
        latitude: '-90',
        longitude: '0',
        altitude: '9300',
        timezone: '12',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Tauranga Airport',
        city: 'Tauranga',
        country: 'New Zealand',
        iataCode: 'TRG',
        icaoCode: 'NZTG',
        latitude: '-37,6719017',
        longitude: '176,1959991',
        altitude: '13',
        timezone: '12',
        dst: 'Z',
        type: 'airport',
    },
    {
        name: 'Timaru Airport',
        city: 'Timaru',
        country: 'New Zealand',
        iataCode: 'TIU',
        icaoCode: 'NZTU',
        latitude: '-44,30279922',
        longitude: '171,2250061',
        altitude: '89',
        timezone: '12',
        dst: 'Z',
        type: 'airport',
    },
    {
        name: 'Pukaki Airport',
        city: 'Pukaki',
        country: 'New Zealand',
        iataCode: 'N',
        icaoCode: 'NZUK',
        latitude: '-44,23500061',
        longitude: '170,1179962',
        altitude: '1575',
        timezone: '12',
        dst: 'Z',
        type: 'airport',
    },
    {
        name: 'Woodbourne Airport',
        city: 'Woodbourne',
        country: 'New Zealand',
        iataCode: 'BHE',
        icaoCode: 'NZWB',
        latitude: '-41,5182991',
        longitude: '173,8699951',
        altitude: '109',
        timezone: '12',
        dst: 'Z',
        type: 'airport',
    },
    {
        name: 'Williams Field',
        city: 'Weydon',
        country: 'Antarctica',
        iataCode: 'N',
        icaoCode: 'NZWD',
        latitude: '-77,86740112',
        longitude: '167,0570068',
        altitude: '68',
        timezone: '12',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Wanaka Airport',
        city: 'Wanaka',
        country: 'New Zealand',
        iataCode: 'WKA',
        icaoCode: 'NZWF',
        latitude: '-44,72219849',
        longitude: '169,2460022',
        altitude: '1142',
        timezone: '12',
        dst: 'Z',
        type: 'airport',
    },
    {
        name: 'Wigram Airport',
        city: 'Wigram',
        country: 'New Zealand',
        iataCode: 'N',
        icaoCode: 'NZWG',
        latitude: '-43,55110168',
        longitude: '172,5529938',
        altitude: '74',
        timezone: '12',
        dst: 'Z',
        type: 'airport',
    },
    {
        name: 'Whakatane Airport',
        city: 'Whakatane',
        country: 'New Zealand',
        iataCode: 'WHK',
        icaoCode: 'NZWK',
        latitude: '-37,92060089',
        longitude: '176,9140015',
        altitude: '20',
        timezone: '12',
        dst: 'Z',
        type: 'airport',
    },
    {
        name: 'Wellington International Airport',
        city: 'Wellington',
        country: 'New Zealand',
        iataCode: 'WLG',
        icaoCode: 'NZWN',
        latitude: '-41,32720184',
        longitude: '174,8049927',
        altitude: '41',
        timezone: '12',
        dst: 'Z',
        type: 'airport',
    },
    {
        name: 'Wairoa Airport',
        city: 'Wairoa',
        country: 'New Zealand',
        iataCode: 'N',
        icaoCode: 'NZWO',
        latitude: '-39,00690079',
        longitude: '177,4069977',
        altitude: '42',
        timezone: '12',
        dst: 'Z',
        type: 'airport',
    },
    {
        name: 'RNZAF Base Auckland-Whenuapai',
        city: 'Whenuapai',
        country: 'New Zealand',
        iataCode: 'N',
        icaoCode: 'NZWP',
        latitude: '-36,78779984',
        longitude: '174,6300049',
        altitude: '100',
        timezone: '12',
        dst: 'Z',
        type: 'airport',
    },
    {
        name: 'Whangarei Airport',
        city: 'Whangarei',
        country: 'New Zealand',
        iataCode: 'WRE',
        icaoCode: 'NZWR',
        latitude: '-35,7682991',
        longitude: '174,3650055',
        altitude: '133',
        timezone: '12',
        dst: 'Z',
        type: 'airport',
    },
    {
        name: 'Westport Airport',
        city: 'Westport',
        country: 'New Zealand',
        iataCode: 'WSZ',
        icaoCode: 'NZWS',
        latitude: '-41,73809814',
        longitude: '171,5809937',
        altitude: '13',
        timezone: '12',
        dst: 'Z',
        type: 'airport',
    },
    {
        name: 'Wanganui Airport',
        city: 'Wanganui',
        country: 'New Zealand',
        iataCode: 'WAG',
        icaoCode: 'NZWU',
        latitude: '-39,96220016',
        longitude: '175,0249939',
        altitude: '27',
        timezone: '12',
        dst: 'Z',
        type: 'airport',
    },
    {
        name: 'Herat Airport',
        city: 'Herat',
        country: 'Afghanistan',
        iataCode: 'HEA',
        icaoCode: 'OAHR',
        latitude: '34,20999908',
        longitude: '62,22829819',
        altitude: '3206',
        timezone: '4,5',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Jalalabad Airport',
        city: 'Jalalabad',
        country: 'Afghanistan',
        iataCode: 'JAA',
        icaoCode: 'OAJL',
        latitude: '34,39979935',
        longitude: '70,49859619',
        altitude: '1814',
        timezone: '4,5',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Kabul International Airport',
        city: 'Kabul',
        country: 'Afghanistan',
        iataCode: 'KBL',
        icaoCode: 'OAKB',
        latitude: '34,5658989',
        longitude: '69,21230316',
        altitude: '5877',
        timezone: '4,5',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Kandahar Airport',
        city: 'Kandahar',
        country: 'Afghanistan',
        iataCode: 'KDH',
        icaoCode: 'OAKN',
        latitude: '31,50580025',
        longitude: '65,84780121',
        altitude: '3337',
        timezone: '4,5',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Maimana Airport',
        city: 'Maimama',
        country: 'Afghanistan',
        iataCode: 'MMZ',
        icaoCode: 'OAMN',
        latitude: '35,93080139',
        longitude: '64,7609024',
        altitude: '2743',
        timezone: '4,5',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Mazar I Sharif Airport',
        city: 'Mazar-i-sharif',
        country: 'Afghanistan',
        iataCode: 'MZR',
        icaoCode: 'OAMS',
        latitude: '36,70690155',
        longitude: '67,20970154',
        altitude: '1284',
        timezone: '4,5',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Shindand Airport',
        city: 'Shindand',
        country: 'Afghanistan',
        iataCode: 'N',
        icaoCode: 'OASD',
        latitude: '33,3913002',
        longitude: '62,26100159',
        altitude: '3773',
        timezone: '4,5',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Sheberghan Airport',
        city: 'Sheberghan',
        country: 'Afghanistan',
        iataCode: 'N',
        icaoCode: 'OASG',
        latitude: '36,75080109',
        longitude: '65,91320038',
        altitude: '1053',
        timezone: '4,5',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Konduz Airport',
        city: 'Kunduz',
        country: 'Afghanistan',
        iataCode: 'UND',
        icaoCode: 'OAUZ',
        latitude: '36,6651001',
        longitude: '68,91079712',
        altitude: '1457',
        timezone: '4,5',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Bahrain International Airport',
        city: 'Bahrain',
        country: 'Bahrain',
        iataCode: 'BAH',
        icaoCode: 'OBBI',
        latitude: '26,27079964',
        longitude: '50,63359833',
        altitude: '6',
        timezone: '3',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Sheik Isa Air Base',
        city: 'Bahrain',
        country: 'Bahrain',
        iataCode: 'N',
        icaoCode: 'OBBS',
        latitude: '25,91839981',
        longitude: '50,59059906',
        altitude: '136',
        timezone: '3',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Abha Regional Airport',
        city: 'Abha',
        country: 'Saudi Arabia',
        iataCode: 'AHB',
        icaoCode: 'OEAB',
        latitude: '18,24040031',
        longitude: '42,65660095',
        altitude: '6858',
        timezone: '3',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Al Ahsa Airport',
        city: 'Al-ahsa',
        country: 'Saudi Arabia',
        iataCode: 'HOF',
        icaoCode: 'OEAH',
        latitude: '25,2852993',
        longitude: '49,48519897',
        altitude: '588',
        timezone: '3',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Al Baha Airport',
        city: 'El-baha',
        country: 'Saudi Arabia',
        iataCode: 'ABT',
        icaoCode: 'OEBA',
        latitude: '20,29610062',
        longitude: '41,63430023',
        altitude: '5486',
        timezone: '3',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Bisha Airport',
        city: 'Bisha',
        country: 'Saudi Arabia',
        iataCode: 'BHH',
        icaoCode: 'OEBH',
        latitude: '19,9843998',
        longitude: '42,6208992',
        altitude: '3887',
        timezone: '3',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Abqaiq Airport',
        city: 'Abqaiq',
        country: 'Saudi Arabia',
        iataCode: 'N',
        icaoCode: 'OEBQ',
        latitude: '25,91130066',
        longitude: '49,59120178',
        altitude: '229',
        timezone: '3',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'King Fahd International Airport',
        city: 'Dammam',
        country: 'Saudi Arabia',
        iataCode: 'DMM',
        icaoCode: 'OEDF',
        latitude: '26,47120094',
        longitude: '49,79790115',
        altitude: '72',
        timezone: '3',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'King Abdulaziz Air Base',
        city: 'Dhahran',
        country: 'Saudi Arabia',
        iataCode: 'DHA',
        icaoCode: 'OEDR',
        latitude: '26,26539993',
        longitude: '50,15200043',
        altitude: '84',
        timezone: '3',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Jizan Regional Airport',
        city: 'Gizan',
        country: 'Saudi Arabia',
        iataCode: 'GIZ',
        icaoCode: 'OEGN',
        latitude: '16,90110016',
        longitude: '42,58580017',
        altitude: '20',
        timezone: '3',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Gassim Airport',
        city: 'Gassim',
        country: 'Saudi Arabia',
        iataCode: 'ELQ',
        icaoCode: 'OEGS',
        latitude: '26,30279922',
        longitude: '43,7743988',
        altitude: '2126',
        timezone: '3',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Gurayat Domestic Airport',
        city: 'Guriat',
        country: 'Saudi Arabia',
        iataCode: 'URY',
        icaoCode: 'OEGT',
        latitude: '31,412413',
        longitude: '37,278898',
        altitude: '1672',
        timezone: '3',
        dst: 'U',
        type: 'airport',
    },
    {
        name: "Ha'il Airport",
        city: 'Hail',
        country: 'Saudi Arabia',
        iataCode: 'HAS',
        icaoCode: 'OEHL',
        latitude: '27,437901',
        longitude: '41,686298',
        altitude: '3331',
        timezone: '3',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Jubail Airport',
        city: 'Jubail',
        country: 'Saudi Arabia',
        iataCode: 'N',
        icaoCode: 'OEJB',
        latitude: '27,03899956',
        longitude: '49,40510178',
        altitude: '26',
        timezone: '3',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'King Faisal Naval Base',
        city: 'Jeddah',
        country: 'Saudi Arabia',
        iataCode: 'N',
        icaoCode: 'OEJF',
        latitude: '21,34810066',
        longitude: '39,17300034',
        altitude: '7',
        timezone: '3',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'King Abdulaziz International Airport',
        city: 'Jeddah',
        country: 'Saudi Arabia',
        iataCode: 'JED',
        icaoCode: 'OEJN',
        latitude: '21,6796',
        longitude: '39,156502',
        altitude: '48',
        timezone: '3',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'King Khaled Military City Airport',
        city: 'King Khalid Mil.city',
        country: 'Saudi Arabia',
        iataCode: 'HBT',
        icaoCode: 'OEKK',
        latitude: '27,9009',
        longitude: '45,528198',
        altitude: '1352',
        timezone: '3',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Prince Mohammad Bin Abdulaziz Airport',
        city: 'Madinah',
        country: 'Saudi Arabia',
        iataCode: 'MED',
        icaoCode: 'OEMA',
        latitude: '24,55340004',
        longitude: '39,70510101',
        altitude: '2151',
        timezone: '3',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Nejran Airport',
        city: 'Nejran',
        country: 'Saudi Arabia',
        iataCode: 'EAM',
        icaoCode: 'OENG',
        latitude: '17,6114006',
        longitude: '44,4192009',
        altitude: '3982',
        timezone: '3',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Al Qaisumah/Hafr Al Batin Airport',
        city: 'Hafr Al-batin',
        country: 'Saudi Arabia',
        iataCode: 'AQI',
        icaoCode: 'OEPA',
        latitude: '28,335199',
        longitude: '46,125099',
        altitude: '1174',
        timezone: '3',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Pump Station 3 Airport',
        city: 'Petroline 3',
        country: 'Saudi Arabia',
        iataCode: 'N',
        icaoCode: 'OEPC',
        latitude: '25,17449951',
        longitude: '47,48839951',
        altitude: '1740',
        timezone: '3',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Pump Station 6 Airport',
        city: 'Petroline 6',
        country: 'Saudi Arabia',
        iataCode: 'N',
        icaoCode: 'OEPF',
        latitude: '24,71030045',
        longitude: '44,96450043',
        altitude: '2530',
        timezone: '3',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Pump Station 10 Airport',
        city: 'Petroline 10',
        country: 'Saudi Arabia',
        iataCode: 'N',
        icaoCode: 'OEPJ',
        latitude: '24,1072998',
        longitude: '41,0359993',
        altitude: '2840',
        timezone: '3',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Rabigh Airport',
        city: 'Rabigh',
        country: 'Saudi Arabia',
        iataCode: 'N',
        icaoCode: 'OERB',
        latitude: '22,70260048',
        longitude: '39,06980133',
        altitude: '22',
        timezone: '3',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Rafha Domestic Airport',
        city: 'Rafha',
        country: 'Saudi Arabia',
        iataCode: 'RAH',
        icaoCode: 'OERF',
        latitude: '29,62639999',
        longitude: '43,49060059',
        altitude: '1474',
        timezone: '3',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'King Khaled International Airport',
        city: 'Riyadh',
        country: 'Saudi Arabia',
        iataCode: 'RUH',
        icaoCode: 'OERK',
        latitude: '24,95759964',
        longitude: '46,69879913',
        altitude: '2049',
        timezone: '3',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Ras Mishab Airport',
        city: 'Rash Mishab',
        country: 'Saudi Arabia',
        iataCode: 'N',
        icaoCode: 'OERM',
        latitude: '28,07959938',
        longitude: '48,61100006',
        altitude: '13',
        timezone: '3',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Arar Domestic Airport',
        city: 'Arar',
        country: 'Saudi Arabia',
        iataCode: 'RAE',
        icaoCode: 'OERR',
        latitude: '30,90660095',
        longitude: '41,13819885',
        altitude: '1813',
        timezone: '3',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Ras Tanura Airport',
        city: 'Ras Tanura',
        country: 'Saudi Arabia',
        iataCode: 'N',
        icaoCode: 'OERT',
        latitude: '26,72310066',
        longitude: '50,03079987',
        altitude: '6',
        timezone: '3',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Sharurah Airport',
        city: 'Sharurah',
        country: 'Saudi Arabia',
        iataCode: 'SHW',
        icaoCode: 'OESH',
        latitude: '17,46689987',
        longitude: '47,12139893',
        altitude: '2363',
        timezone: '3',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Sulayel Airport',
        city: 'Sulayel',
        country: 'Saudi Arabia',
        iataCode: 'SLF',
        icaoCode: 'OESL',
        latitude: '20,4647007',
        longitude: '45,61959839',
        altitude: '2021',
        timezone: '3',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Tabuk Airport',
        city: 'Tabuk',
        country: 'Saudi Arabia',
        iataCode: 'TUU',
        icaoCode: 'OETB',
        latitude: '28,3654',
        longitude: '36,6189',
        altitude: '2551',
        timezone: '3',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Ta’if Regional Airport',
        city: 'Taif',
        country: 'Saudi Arabia',
        iataCode: 'TIF',
        icaoCode: 'OETF',
        latitude: '21,483001',
        longitude: '40,543442',
        altitude: '4848',
        timezone: '3',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Thumamah Airport',
        city: 'Thumamah',
        country: 'Saudi Arabia',
        iataCode: 'N',
        icaoCode: 'OETH',
        latitude: '25,21299934',
        longitude: '46,64099884',
        altitude: '1870',
        timezone: '3',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Ras Tanajib Airport',
        city: 'Ras Tanajib',
        country: 'Saudi Arabia',
        iataCode: 'N',
        icaoCode: 'OETN',
        latitude: '27,86779976',
        longitude: '48,76910019',
        altitude: '30',
        timezone: '3',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Turaif Domestic Airport',
        city: 'Turaif',
        country: 'Saudi Arabia',
        iataCode: 'TUI',
        icaoCode: 'OETR',
        latitude: '31,692188',
        longitude: '38,731544',
        altitude: '2803',
        timezone: '3',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Al Wajh Domestic Airport',
        city: 'Wejh',
        country: 'Saudi Arabia',
        iataCode: 'EJH',
        icaoCode: 'OEWJ',
        latitude: '26,19860077',
        longitude: '36,47639847',
        altitude: '66',
        timezone: '3',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Prince Abdulmohsin Bin Abdulaziz Airport',
        city: 'Yenbo',
        country: 'Saudi Arabia',
        iataCode: 'YNB',
        icaoCode: 'OEYN',
        latitude: '24,144199',
        longitude: '38,0634',
        altitude: '26',
        timezone: '3',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Abadan Airport',
        city: 'Abadan',
        country: 'Iran',
        iataCode: 'ABD',
        icaoCode: 'OIAA',
        latitude: '30,37109947',
        longitude: '48,22829819',
        altitude: '10',
        timezone: '3,5',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Dezful Airport',
        city: 'Dezful',
        country: 'Iran',
        iataCode: 'N',
        icaoCode: 'OIAD',
        latitude: '32,434399',
        longitude: '48,397598',
        altitude: '474',
        timezone: '3,5',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Aghajari Airport',
        city: 'Aghajari',
        country: 'Iran',
        iataCode: 'N',
        icaoCode: 'OIAG',
        latitude: '30,7444',
        longitude: '49,6772',
        altitude: '88',
        timezone: '3,5',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Gachsaran Airport',
        city: 'Gachsaran',
        country: 'Iran',
        iataCode: 'N',
        icaoCode: 'OIAH',
        latitude: '30,33760071',
        longitude: '50,82799912',
        altitude: '2414',
        timezone: '3,5',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Shahid Asyaee Airport',
        city: 'Masjed Soleiman',
        country: 'Iran',
        iataCode: 'QMJ',
        icaoCode: 'OIAI',
        latitude: '32,00239944',
        longitude: '49,270401',
        altitude: '1206',
        timezone: '3,5',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Omidiyeh Airport',
        city: 'Omidyeh',
        country: 'Iran',
        iataCode: 'N',
        icaoCode: 'OIAJ',
        latitude: '30,83519936',
        longitude: '49,53490067',
        altitude: '85',
        timezone: '3,5',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Mahshahr Airport',
        city: 'Bandar Mahshahr',
        country: 'Iran',
        iataCode: 'MRX',
        icaoCode: 'OIAM',
        latitude: '30,55620003',
        longitude: '49,15190125',
        altitude: '8',
        timezone: '3,5',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Ahwaz Airport',
        city: 'Ahwaz',
        country: 'Iran',
        iataCode: 'AWZ',
        icaoCode: 'OIAW',
        latitude: '31,33740044',
        longitude: '48,76200104',
        altitude: '66',
        timezone: '3,5',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Abumusa Island Airport',
        city: 'Abumusa I.',
        country: 'Iran',
        iataCode: 'N',
        icaoCode: 'OIBA',
        latitude: '25,8757',
        longitude: '55,03300095',
        altitude: '23',
        timezone: '3,5',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Bushehr Airport',
        city: 'Bushehr',
        country: 'Iran',
        iataCode: 'BUZ',
        icaoCode: 'OIBB',
        latitude: '28,94479942',
        longitude: '50,83459854',
        altitude: '68',
        timezone: '3,5',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Bastak Airport',
        city: 'Bastak',
        country: 'Iran',
        iataCode: 'N',
        icaoCode: 'OIBH',
        latitude: '27,21269989',
        longitude: '54,3185997',
        altitude: '1350',
        timezone: '3,5',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Asaloyeh Airport',
        city: 'Golbandi',
        country: 'Iran',
        iataCode: 'N',
        icaoCode: 'OIBI',
        latitude: '27,4814',
        longitude: '52,615501',
        altitude: '15',
        timezone: '3,5',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Kish International Airport',
        city: 'Kish Island',
        country: 'Iran',
        iataCode: 'KIH',
        icaoCode: 'OIBK',
        latitude: '26,52619934',
        longitude: '53,98020172',
        altitude: '101',
        timezone: '3,5',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Bandar Lengeh Airport',
        city: 'Bandar Lengeh',
        country: 'Iran',
        iataCode: 'BDH',
        icaoCode: 'OIBL',
        latitude: '26,53199959',
        longitude: '54,82479858',
        altitude: '67',
        timezone: '3,5',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Khark Island Airport',
        city: 'Khark Island',
        country: 'Iran',
        iataCode: 'N',
        icaoCode: 'OIBQ',
        latitude: '29,26029968',
        longitude: '50,32389832',
        altitude: '17',
        timezone: '3,5',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Sirri Island Airport',
        city: 'Siri Island',
        country: 'Iran',
        iataCode: 'N',
        icaoCode: 'OIBS',
        latitude: '25,90889931',
        longitude: '54,53939819',
        altitude: '43',
        timezone: '3,5',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Lavan Island Airport',
        city: 'Lavan Island',
        country: 'Iran',
        iataCode: 'N',
        icaoCode: 'OIBV',
        latitude: '26,81030083',
        longitude: '53,35630035',
        altitude: '76',
        timezone: '3,5',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Shahid Ashrafi Esfahani Airport',
        city: 'Bakhtaran',
        country: 'Iran',
        iataCode: 'KSH',
        icaoCode: 'OICC',
        latitude: '34,34590149',
        longitude: '47,15810013',
        altitude: '4307',
        timezone: '3,5',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Sanandaj Airport',
        city: 'Sanandaj',
        country: 'Iran',
        iataCode: 'SDG',
        icaoCode: 'OICS',
        latitude: '35,2458992',
        longitude: '47,00920105',
        altitude: '4522',
        timezone: '3,5',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Hesa Airport',
        city: 'Daran',
        country: 'Iran',
        iataCode: 'N',
        icaoCode: 'OIFE',
        latitude: '32,92890167',
        longitude: '51,56110001',
        altitude: '5256',
        timezone: '3,5',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Shahid Vatan Pour Air Base',
        city: 'Esfahan',
        country: 'Iran',
        iataCode: 'N',
        icaoCode: 'OIFH',
        latitude: '32,56700134',
        longitude: '51,6916008',
        altitude: '5310',
        timezone: '3,5',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Kashan Airport',
        city: 'Kashan',
        country: 'Iran',
        iataCode: 'N',
        icaoCode: 'OIFK',
        latitude: '33,89530182',
        longitude: '51,57699966',
        altitude: '3465',
        timezone: '3,5',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Esfahan Shahid Beheshti International Airport',
        city: 'Esfahan',
        country: 'Iran',
        iataCode: 'IFN',
        icaoCode: 'OIFM',
        latitude: '32,75080109',
        longitude: '51,86130142',
        altitude: '5059',
        timezone: '3,5',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Badr Air Base',
        city: 'Sepah',
        country: 'Iran',
        iataCode: 'N',
        icaoCode: 'OIFP',
        latitude: '32,62110138',
        longitude: '51,6969986',
        altitude: '5242',
        timezone: '3,5',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Sardar-e-Jangal Airport',
        city: 'Rasht',
        country: 'Iran',
        iataCode: 'RAS',
        icaoCode: 'OIGG',
        latitude: '37,323333',
        longitude: '49,617778',
        altitude: '-40',
        timezone: '3,5',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Arak Airport',
        city: 'Arak',
        country: 'Iran',
        iataCode: 'N',
        icaoCode: 'OIHR',
        latitude: '34,13809967',
        longitude: '49,84730148',
        altitude: '5440',
        timezone: '3,5',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Ghazvin Azadi Airport',
        city: 'Abe-ali',
        country: 'Iran',
        iataCode: 'N',
        icaoCode: 'OIIA',
        latitude: '35,95209885',
        longitude: '50,45080185',
        altitude: '3769',
        timezone: '3,5',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Kushke Nosrat Airport',
        city: 'Kushke Nosrat',
        country: 'Iran',
        iataCode: 'N',
        icaoCode: 'OIIC',
        latitude: '34,98400116',
        longitude: '50,80619812',
        altitude: '3008',
        timezone: '3,5',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Doshan Tappeh Air Base',
        city: 'Teheran',
        country: 'Iran',
        iataCode: 'N',
        icaoCode: 'OIID',
        latitude: '35,70299911',
        longitude: '51,47510147',
        altitude: '4046',
        timezone: '3,5',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Ghale Morghi Airport',
        city: 'Teheran',
        country: 'Iran',
        iataCode: 'N',
        icaoCode: 'OIIG',
        latitude: '35,64479828',
        longitude: '51,38069916',
        altitude: '3627',
        timezone: '3,5',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Mehrabad International Airport',
        city: 'Teheran',
        country: 'Iran',
        iataCode: 'THR',
        icaoCode: 'OIII',
        latitude: '35,68920135',
        longitude: '51,31340027',
        altitude: '3962',
        timezone: '3,5',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Qazvin Airport',
        city: 'Ghazvin',
        country: 'Iran',
        iataCode: 'N',
        icaoCode: 'OIIK',
        latitude: '36,240101',
        longitude: '50,0471',
        altitude: '4184',
        timezone: '3,5',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Naja Airport',
        city: 'Khoram Dareh',
        country: 'Iran',
        iataCode: 'N',
        icaoCode: 'OIIM',
        latitude: '35,77629852',
        longitude: '50,88100052',
        altitude: '4040',
        timezone: '3,5',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Bandar Abbas International Airport',
        city: 'Bandar Abbas',
        country: 'Iran',
        iataCode: 'BND',
        icaoCode: 'OIKB',
        latitude: '27,21829987',
        longitude: '56,37779999',
        altitude: '22',
        timezone: '3,5',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Jiroft Airport',
        city: 'Jiroft',
        country: 'Iran',
        iataCode: 'N',
        icaoCode: 'OIKJ',
        latitude: '28,7269001',
        longitude: '57,67029953',
        altitude: '2663',
        timezone: '3,5',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Kerman Airport',
        city: 'Kerman',
        country: 'Iran',
        iataCode: 'KER',
        icaoCode: 'OIKK',
        latitude: '30,27440071',
        longitude: '56,9510994',
        altitude: '5741',
        timezone: '3,5',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Havadarya Airport',
        city: 'Bandar Abbas',
        country: 'Iran',
        iataCode: 'N',
        icaoCode: 'OIKP',
        latitude: '27,1583004',
        longitude: '56,17250061',
        altitude: '19',
        timezone: '3,5',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Dayrestan Airport',
        city: 'Gheshm I.',
        country: 'Iran',
        iataCode: 'N',
        icaoCode: 'OIKQ',
        latitude: '26,75460052',
        longitude: '55,90240097',
        altitude: '45',
        timezone: '3,5',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Sirjan Airport',
        city: 'Sirjan',
        country: 'Iran',
        iataCode: 'N',
        icaoCode: 'OIKY',
        latitude: '29,55089951',
        longitude: '55,67269897',
        altitude: '5846',
        timezone: '3,5',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Birjand Airport',
        city: 'Birjand',
        country: 'Iran',
        iataCode: 'XBJ',
        icaoCode: 'OIMB',
        latitude: '32,89810181',
        longitude: '59,26610184',
        altitude: '4952',
        timezone: '3,5',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Sarakhs Airport',
        city: 'Sarakhs',
        country: 'Iran',
        iataCode: 'N',
        icaoCode: 'OIMC',
        latitude: '36,50120163',
        longitude: '61,06489944',
        altitude: '945',
        timezone: '3,5',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Shahroud Airport',
        city: 'Emam Shahr',
        country: 'Iran',
        iataCode: 'N',
        icaoCode: 'OIMJ',
        latitude: '36,4253006',
        longitude: '55,10419846',
        altitude: '4215',
        timezone: '3,5',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Tabas Airport',
        city: 'Tabas',
        country: 'Iran',
        iataCode: 'N',
        icaoCode: 'OIMT',
        latitude: '33,6678009',
        longitude: '56,8927002',
        altitude: '2312',
        timezone: '3,5',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Kalaleh Airport',
        city: 'Kalaleh',
        country: 'Iran',
        iataCode: 'N',
        icaoCode: 'OINE',
        latitude: '37,38330078',
        longitude: '55,45199966',
        altitude: '425',
        timezone: '3,5',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Ramsar Airport',
        city: 'Ramsar',
        country: 'Iran',
        iataCode: 'RZR',
        icaoCode: 'OINR',
        latitude: '36,90990067',
        longitude: '50,67959976',
        altitude: '-70',
        timezone: '3,5',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Fasa Airport',
        city: 'Fasa',
        country: 'Iran',
        iataCode: 'N',
        icaoCode: 'OISF',
        latitude: '28,89179993',
        longitude: '53,72330093',
        altitude: '4261',
        timezone: '3,5',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Jahrom Airport',
        city: 'Jahrom',
        country: 'Iran',
        iataCode: 'N',
        icaoCode: 'OISJ',
        latitude: '28,58670044',
        longitude: '53,57910156',
        altitude: '3358',
        timezone: '3,5',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Lamerd Airport',
        city: 'Lamerd',
        country: 'Iran',
        iataCode: 'N',
        icaoCode: 'OISR',
        latitude: '27,37269974',
        longitude: '53,18880081',
        altitude: '1337',
        timezone: '3,5',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Shiraz Shahid Dastghaib International Airport',
        city: 'Shiraz',
        country: 'Iran',
        iataCode: 'SYZ',
        icaoCode: 'OISS',
        latitude: '29,53919983',
        longitude: '52,58980179',
        altitude: '4920',
        timezone: '3,5',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Khoy Airport',
        city: 'Khoy',
        country: 'Iran',
        iataCode: 'N',
        icaoCode: 'OITK',
        latitude: '38,42750168',
        longitude: '44,97359848',
        altitude: '3981',
        timezone: '3,5',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Tabriz International Airport',
        city: 'Tabriz',
        country: 'Iran',
        iataCode: 'TBZ',
        icaoCode: 'OITT',
        latitude: '38,13389969',
        longitude: '46,23500061',
        altitude: '4459',
        timezone: '3,5',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Zanjan Airport',
        city: 'Zanjan',
        country: 'Iran',
        iataCode: 'N',
        icaoCode: 'OITZ',
        latitude: '36,77370071',
        longitude: '48,3594017',
        altitude: '5382',
        timezone: '3,5',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Shahid Sadooghi Airport',
        city: 'Yazd',
        country: 'Iran',
        iataCode: 'AZD',
        icaoCode: 'OIYY',
        latitude: '31,9048996',
        longitude: '54,2765007',
        altitude: '4054',
        timezone: '3,5',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Zabol Airport',
        city: 'Zabol',
        country: 'Iran',
        iataCode: 'ACZ',
        icaoCode: 'OIZB',
        latitude: '31,09830093',
        longitude: '61,54389954',
        altitude: '1628',
        timezone: '3,5',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Konarak Airport',
        city: 'Chah Bahar',
        country: 'Iran',
        iataCode: 'ZBR',
        icaoCode: 'OIZC',
        latitude: '25,44330025',
        longitude: '60,38209915',
        altitude: '43',
        timezone: '3,5',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Zahedan International Airport',
        city: 'Zahedan',
        country: 'Iran',
        iataCode: 'ZAH',
        icaoCode: 'OIZH',
        latitude: '29,47570038',
        longitude: '60,90620041',
        altitude: '4564',
        timezone: '3,5',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Iran Shahr Airport',
        city: 'Iran Shahr',
        country: 'Iran',
        iataCode: 'N',
        icaoCode: 'OIZI',
        latitude: '27,23609924',
        longitude: '60,72000122',
        altitude: '2040',
        timezone: '3,5',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Saravan Airport',
        city: 'Saravan',
        country: 'Iran',
        iataCode: 'N',
        icaoCode: 'OIZS',
        latitude: '27,41930008',
        longitude: '62,31579971',
        altitude: '3930',
        timezone: '3,5',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Queen Alia International Airport',
        city: 'Amman',
        country: 'Jordan',
        iataCode: 'AMM',
        icaoCode: 'OJAI',
        latitude: '31,72260094',
        longitude: '35,99319839',
        altitude: '2395',
        timezone: '2',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Amman-Marka International Airport',
        city: 'Amman',
        country: 'Jordan',
        iataCode: 'ADJ',
        icaoCode: 'OJAM',
        latitude: '31,97270012',
        longitude: '35,99160004',
        altitude: '2555',
        timezone: '2',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Aqaba King Hussein International Airport',
        city: 'Aqaba',
        country: 'Jordan',
        iataCode: 'AQJ',
        icaoCode: 'OJAQ',
        latitude: '29,61160088',
        longitude: '35,01810074',
        altitude: '175',
        timezone: '2',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Prince Hassan Air Base',
        city: 'Hotel Four',
        country: 'Jordan',
        iataCode: 'N',
        icaoCode: 'OJHF',
        latitude: '32,160702',
        longitude: '37,149399',
        altitude: '2220',
        timezone: '2',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Jerusalem Airport',
        city: 'Jerusalem',
        country: 'West Bank',
        iataCode: 'N',
        icaoCode: 'OJJR',
        latitude: '31,86470032',
        longitude: '35,21920013',
        altitude: '2485',
        timezone: '2',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'King Hussein Air College',
        city: 'Mafraq',
        country: 'Jordan',
        iataCode: 'OMF',
        icaoCode: 'OJMF',
        latitude: '32,3564',
        longitude: '36,259201',
        altitude: '2240',
        timezone: '2',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Kuwait International Airport',
        city: 'Kuwait',
        country: 'Kuwait',
        iataCode: 'KWI',
        icaoCode: 'OKBK',
        latitude: '29,22660065',
        longitude: '47,96889877',
        altitude: '206',
        timezone: '3',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Beirut Rafic Hariri International Airport',
        city: 'Beirut',
        country: 'Lebanon',
        iataCode: 'BEY',
        icaoCode: 'OLBA',
        latitude: '33,82089996',
        longitude: '35,48839951',
        altitude: '87',
        timezone: '2',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Rene Mouawad Air Base',
        city: 'Kleiat',
        country: 'Lebanon',
        iataCode: 'N',
        icaoCode: 'OLKA',
        latitude: '34,58929825',
        longitude: '36,01129913',
        altitude: '75',
        timezone: '2',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Abu Dhabi International Airport',
        city: 'Abu Dhabi',
        country: 'United Arab Emirates',
        iataCode: 'AUH',
        icaoCode: 'OMAA',
        latitude: '24,43300056',
        longitude: '54,65110016',
        altitude: '88',
        timezone: '4',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Bateen Airport',
        city: 'Abu Dhabi',
        country: 'United Arab Emirates',
        iataCode: 'AZI',
        icaoCode: 'OMAD',
        latitude: '24,42830086',
        longitude: '54,45809937',
        altitude: '16',
        timezone: '4',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Al Hamra Aux Airport',
        city: 'Al Hamra',
        country: 'United Arab Emirates',
        iataCode: 'N',
        icaoCode: 'OMAH',
        latitude: '24,0739994',
        longitude: '52,46360016',
        altitude: '50',
        timezone: '4',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Jebel Dhana Airport',
        city: 'Jebel Dhana',
        country: 'United Arab Emirates',
        iataCode: 'N',
        icaoCode: 'OMAJ',
        latitude: '24,18740082',
        longitude: '52,61399841',
        altitude: '43',
        timezone: '4',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Al Dhafra Air Base',
        city: 'Abu Dhabi',
        country: 'United Arab Emirates',
        iataCode: 'N',
        icaoCode: 'OMAM',
        latitude: '24,24819946',
        longitude: '54,54769897',
        altitude: '77',
        timezone: '4',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Arzanah Airport',
        city: 'Arzana',
        country: 'United Arab Emirates',
        iataCode: 'N',
        icaoCode: 'OMAR',
        latitude: '24,78050041',
        longitude: '52,55989838',
        altitude: '15',
        timezone: '4',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Das Island Airport',
        city: 'Das Island',
        country: 'United Arab Emirates',
        iataCode: 'N',
        icaoCode: 'OMAS',
        latitude: '25,14620018',
        longitude: '52,87369919',
        altitude: '12',
        timezone: '4',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Zirku Airport',
        city: 'Zirku',
        country: 'United Arab Emirates',
        iataCode: 'N',
        icaoCode: 'OMAZ',
        latitude: '24,86249161',
        longitude: '53,07714081',
        altitude: '14',
        timezone: '4',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Dubai International Airport',
        city: 'Dubai',
        country: 'United Arab Emirates',
        iataCode: 'DXB',
        icaoCode: 'OMDB',
        latitude: '25,25279999',
        longitude: '55,36439896',
        altitude: '62',
        timezone: '4',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Fujairah International Airport',
        city: 'Fujeirah',
        country: 'United Arab Emirates',
        iataCode: 'FJR',
        icaoCode: 'OMFJ',
        latitude: '25,11219978',
        longitude: '56,32400131',
        altitude: '152',
        timezone: '4',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Ras Al Khaimah International Airport',
        city: 'Ras Al Khaimah',
        country: 'United Arab Emirates',
        iataCode: 'RKT',
        icaoCode: 'OMRK',
        latitude: '25,6135006',
        longitude: '55,93880081',
        altitude: '102',
        timezone: '4',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Sharjah International Airport',
        city: 'Sharjah',
        country: 'United Arab Emirates',
        iataCode: 'SHJ',
        icaoCode: 'OMSJ',
        latitude: '25,32859993',
        longitude: '55,51720047',
        altitude: '111',
        timezone: '4',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Khasab Air Base',
        city: 'Khasab',
        country: 'Oman',
        iataCode: 'KHS',
        icaoCode: 'OOKB',
        latitude: '26,17099953',
        longitude: '56,24060059',
        altitude: '100',
        timezone: '4',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Masirah Air Base',
        city: 'Masirah',
        country: 'Oman',
        iataCode: 'MSH',
        icaoCode: 'OOMA',
        latitude: '20,67539978',
        longitude: '58,89049911',
        altitude: '64',
        timezone: '4',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Muscat International Airport',
        city: 'Muscat',
        country: 'Oman',
        iataCode: 'MCT',
        icaoCode: 'OOMS',
        latitude: '23,59329987',
        longitude: '58,28440094',
        altitude: '48',
        timezone: '4',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Salalah Airport',
        city: 'Salalah',
        country: 'Oman',
        iataCode: 'SLL',
        icaoCode: 'OOSA',
        latitude: '17,0387001',
        longitude: '54,09130096',
        altitude: '73',
        timezone: '4',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Thumrait Air Base',
        city: 'Thumrait',
        country: 'Oman',
        iataCode: 'TTH',
        icaoCode: 'OOTH',
        latitude: '17,66600037',
        longitude: '54,02460098',
        altitude: '1570',
        timezone: '4',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Bhagatanwala Airport',
        city: 'Bhagtanwala',
        country: 'Pakistan',
        iataCode: 'N',
        icaoCode: 'OPBG',
        latitude: '32,05609894',
        longitude: '72,9484024',
        altitude: '600',
        timezone: '5',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Faisalabad International Airport',
        city: 'Faisalabad',
        country: 'Pakistan',
        iataCode: 'LYP',
        icaoCode: 'OPFA',
        latitude: '31,36499977',
        longitude: '72,99479675',
        altitude: '591',
        timezone: '5',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Gwadar International Airport',
        city: 'Gwadar',
        country: 'Pakistan',
        iataCode: 'GWD',
        icaoCode: 'OPGD',
        latitude: '25,23329926',
        longitude: '62,32949829',
        altitude: '36',
        timezone: '5',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Gilgit Airport',
        city: 'Gilgit',
        country: 'Pakistan',
        iataCode: 'GIL',
        icaoCode: 'OPGT',
        latitude: '35,91880035',
        longitude: '74,33360291',
        altitude: '4796',
        timezone: '5',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Shahbaz Air Base',
        city: 'Jacobsbad',
        country: 'Pakistan',
        iataCode: 'N',
        icaoCode: 'OPJA',
        latitude: '28,28420067',
        longitude: '68,4496994',
        altitude: '185',
        timezone: '5',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Jinnah International Airport',
        city: 'Karachi',
        country: 'Pakistan',
        iataCode: 'KHI',
        icaoCode: 'OPKC',
        latitude: '24,9065',
        longitude: '67,160797',
        altitude: '100',
        timezone: '5',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Alama Iqbal International Airport',
        city: 'Lahore',
        country: 'Pakistan',
        iataCode: 'LHE',
        icaoCode: 'OPLA',
        latitude: '31,52160072',
        longitude: '74,4036026',
        altitude: '712',
        timezone: '5',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Walton Airport',
        city: 'Lahore',
        country: 'Pakistan',
        iataCode: 'N',
        icaoCode: 'OPLH',
        latitude: '31,49480057',
        longitude: '74,34619904',
        altitude: '679',
        timezone: '5',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Mangla Airport',
        city: 'Mangla',
        country: 'Pakistan',
        iataCode: 'N',
        icaoCode: 'OPMA',
        latitude: '33,05009842',
        longitude: '73,63839722',
        altitude: '902',
        timezone: '5',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Muzaffarabad Airport',
        city: 'Muzaffarabad',
        country: 'Pakistan',
        iataCode: 'MFG',
        icaoCode: 'OPMF',
        latitude: '34,3390007',
        longitude: '73,50859833',
        altitude: '2691',
        timezone: '5',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Mianwali Air Base',
        city: 'Mianwali',
        country: 'Pakistan',
        iataCode: 'N',
        icaoCode: 'OPMI',
        latitude: '32,56309891',
        longitude: '71,5707016',
        altitude: '690',
        timezone: '5',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Moenjodaro Airport',
        city: 'Moenjodaro',
        country: 'Pakistan',
        iataCode: 'MJD',
        icaoCode: 'OPMJ',
        latitude: '27,33519936',
        longitude: '68,14309692',
        altitude: '154',
        timezone: '5',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Masroor Air Base',
        city: 'Karachi',
        country: 'Pakistan',
        iataCode: 'N',
        icaoCode: 'OPMR',
        latitude: '24,89360046',
        longitude: '66,938797',
        altitude: '35',
        timezone: '5',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Multan International Airport',
        city: 'Multan',
        country: 'Pakistan',
        iataCode: 'MUX',
        icaoCode: 'OPMT',
        latitude: '30,20319939',
        longitude: '71,4190979',
        altitude: '403',
        timezone: '5',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Shaheed Benazirabad Airport',
        city: 'Nawabshah',
        country: 'Pakistan',
        iataCode: 'WNS',
        icaoCode: 'OPNH',
        latitude: '26,2194',
        longitude: '68,390099',
        altitude: '95',
        timezone: '5',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Okara Cantonment Airstrip',
        city: 'Okara',
        country: 'Pakistan',
        iataCode: 'N',
        icaoCode: 'OPOK',
        latitude: '30,74099922',
        longitude: '73,35769653',
        altitude: '568',
        timezone: '5',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Panjgur Airport',
        city: 'Panjgur',
        country: 'Pakistan',
        iataCode: 'PJG',
        icaoCode: 'OPPG',
        latitude: '26,9545002',
        longitude: '64,13249969',
        altitude: '3289',
        timezone: '5',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Pasni Airport',
        city: 'Pasni',
        country: 'Pakistan',
        iataCode: 'PSI',
        icaoCode: 'OPPI',
        latitude: '25,29050064',
        longitude: '63,3451004',
        altitude: '33',
        timezone: '5',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Peshawar International Airport',
        city: 'Peshawar',
        country: 'Pakistan',
        iataCode: 'PEW',
        icaoCode: 'OPPS',
        latitude: '33,9939003',
        longitude: '71,51460266',
        altitude: '1158',
        timezone: '5',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Qasim Airport',
        city: 'Qasim',
        country: 'Pakistan',
        iataCode: 'N',
        icaoCode: 'OPQS',
        latitude: '33,56019974',
        longitude: '73,03320313',
        altitude: '1581',
        timezone: '5',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Quetta International Airport',
        city: 'Quetta',
        country: 'Pakistan',
        iataCode: 'UET',
        icaoCode: 'OPQT',
        latitude: '30,25139999',
        longitude: '66,93779755',
        altitude: '5267',
        timezone: '5',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Shaikh Zaid Airport',
        city: 'Rahim Yar Khan',
        country: 'Pakistan',
        iataCode: 'RYK',
        icaoCode: 'OPRK',
        latitude: '28,38389969',
        longitude: '70,27960205',
        altitude: '271',
        timezone: '5',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Benazir Bhutto International Airport',
        city: 'Islamabad',
        country: 'Pakistan',
        iataCode: 'ISB',
        icaoCode: 'OPRN',
        latitude: '33,61669922',
        longitude: '73,09919739',
        altitude: '1668',
        timezone: '5',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Risalpur Air Base',
        city: 'Risalpur',
        country: 'Pakistan',
        iataCode: 'N',
        icaoCode: 'OPRS',
        latitude: '34,08110046',
        longitude: '71,97260284',
        altitude: '1050',
        timezone: '5',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Rawalakot Airport',
        city: 'Rawala Kot',
        country: 'Pakistan',
        iataCode: 'RAZ',
        icaoCode: 'OPRT',
        latitude: '33,84970093',
        longitude: '73,79810333',
        altitude: '5479',
        timezone: '5',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Sukkur Airport',
        city: 'Sukkur',
        country: 'Pakistan',
        iataCode: 'SKZ',
        icaoCode: 'OPSK',
        latitude: '27,72200012',
        longitude: '68,79170227',
        altitude: '196',
        timezone: '5',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Saidu Sharif Airport',
        city: 'Saidu Sharif',
        country: 'Pakistan',
        iataCode: 'SDT',
        icaoCode: 'OPSS',
        latitude: '34,81359863',
        longitude: '72,35279846',
        altitude: '3183',
        timezone: '5',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Sui Airport',
        city: 'Sui',
        country: 'Pakistan',
        iataCode: 'SUL',
        icaoCode: 'OPSU',
        latitude: '28,64509964',
        longitude: '69,17690277',
        altitude: '763',
        timezone: '5',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Talhar Airport',
        city: 'Talhar',
        country: 'Pakistan',
        iataCode: 'BDN',
        icaoCode: 'OPTH',
        latitude: '24,84149933',
        longitude: '68,83840179',
        altitude: '28',
        timezone: '5',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Wana Airport',
        city: 'Wana',
        country: 'Pakistan',
        iataCode: 'N',
        icaoCode: 'OPWN',
        latitude: '32,30469894',
        longitude: '69,57039642',
        altitude: '4550',
        timezone: '5',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Zhob Airport',
        city: 'Zhob',
        country: 'Pakistan',
        iataCode: 'PZH',
        icaoCode: 'OPZB',
        latitude: '31,35840034',
        longitude: '69,46360016',
        altitude: '4728',
        timezone: '5',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Basrah International Airport',
        city: 'Basrah',
        country: 'Iraq',
        iataCode: 'BSR',
        icaoCode: 'ORMM',
        latitude: '30,54910088',
        longitude: '47,66210175',
        altitude: '11',
        timezone: '3',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Aleppo International Airport',
        city: 'Aleppo',
        country: 'Syria',
        iataCode: 'ALP',
        icaoCode: 'OSAP',
        latitude: '36,18069839',
        longitude: '37,22439957',
        altitude: '1276',
        timezone: '2',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Damascus International Airport',
        city: 'Damascus',
        country: 'Syria',
        iataCode: 'DAM',
        icaoCode: 'OSDI',
        latitude: '33,41149902',
        longitude: '36,5155983',
        altitude: '2020',
        timezone: '2',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Deir ez-Zor Airport',
        city: 'Deire Zor',
        country: 'Syria',
        iataCode: 'DEZ',
        icaoCode: 'OSDZ',
        latitude: '35,28540039',
        longitude: '40,17599869',
        altitude: '700',
        timezone: '2',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Bassel Al-Assad International Airport',
        city: 'Latakia',
        country: 'Syria',
        iataCode: 'LTK',
        icaoCode: 'OSLK',
        latitude: '35,40110016',
        longitude: '35,94869995',
        altitude: '157',
        timezone: '2',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Palmyra Airport',
        city: 'Palmyra',
        country: 'Syria',
        iataCode: 'PMS',
        icaoCode: 'OSPR',
        latitude: '34,5574',
        longitude: '38,316898',
        altitude: '1322',
        timezone: '2',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Doha International Airport',
        city: 'Doha',
        country: 'Qatar',
        iataCode: 'N',
        icaoCode: 'OTBD',
        latitude: '25,26110077',
        longitude: '51,56510162',
        altitude: '35',
        timezone: '3',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Canton Island Airport',
        city: 'Canton Island',
        country: 'Kiribati',
        iataCode: 'CIS',
        icaoCode: 'PCIS',
        latitude: '-2,76812005',
        longitude: '-171,7100067',
        altitude: '9',
        timezone: '13',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Rota International Airport',
        city: 'Rota',
        country: 'Northern Mariana Islands',
        iataCode: 'ROP',
        icaoCode: 'PGRO',
        latitude: '14,17430019',
        longitude: '145,2429962',
        altitude: '607',
        timezone: '10',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Saipan International Airport',
        city: 'Saipan',
        country: 'Northern Mariana Islands',
        iataCode: 'SPN',
        icaoCode: 'PGSN',
        latitude: '15,11900043',
        longitude: '145,7290039',
        altitude: '215',
        timezone: '10',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Andersen Air Force Base',
        city: 'Andersen',
        country: 'Guam',
        iataCode: 'UAM',
        icaoCode: 'PGUA',
        latitude: '13,584',
        longitude: '144,929993',
        altitude: '627',
        timezone: '10',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Antonio B. Won Pat International Airport',
        city: 'Agana',
        country: 'Guam',
        iataCode: 'GUM',
        icaoCode: 'PGUM',
        latitude: '13,48340034',
        longitude: '144,7960052',
        altitude: '298',
        timezone: '10',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Tinian International Airport',
        city: 'West Tinian',
        country: 'Northern Mariana Islands',
        iataCode: 'TIQ',
        icaoCode: 'PGWT',
        latitude: '14,99919987',
        longitude: '145,6190033',
        altitude: '271',
        timezone: '10',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Marshall Islands International Airport',
        city: 'Majuro',
        country: 'Marshall Islands',
        iataCode: 'MAJ',
        icaoCode: 'PKMJ',
        latitude: '7,064760208',
        longitude: '171,2720032',
        altitude: '6',
        timezone: '12',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Dyess Army Air Field',
        city: 'Kwajalein',
        country: 'Marshall Islands',
        iataCode: 'N',
        icaoCode: 'PKRO',
        latitude: '9,396889687',
        longitude: '167,470993',
        altitude: '9',
        timezone: '12',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Bucholz Army Air Field',
        city: 'Kwajalein',
        country: 'Marshall Islands',
        iataCode: 'KWA',
        icaoCode: 'PKWA',
        latitude: '8,72012043',
        longitude: '167,7319946',
        altitude: '9',
        timezone: '12',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Cassidy International Airport',
        city: 'Kiritimati',
        country: 'Kiribati',
        iataCode: 'CXI',
        icaoCode: 'PLCH',
        latitude: '1,98616004',
        longitude: '-157,3500061',
        altitude: '5',
        timezone: '-12',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Henderson Field',
        city: 'Midway',
        country: 'Midway Islands',
        iataCode: 'MDY',
        icaoCode: 'PMDY',
        latitude: '28,20170021',
        longitude: '-177,3809967',
        altitude: '13',
        timezone: '-11',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Chuuk International Airport',
        city: 'Chuuk',
        country: 'Micronesia',
        iataCode: 'TKK',
        icaoCode: 'PTKK',
        latitude: '7,461870193',
        longitude: '151,8430023',
        altitude: '11',
        timezone: '10',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Pohnpei International Airport',
        city: 'Pohnpei',
        country: 'Micronesia',
        iataCode: 'PNI',
        icaoCode: 'PTPN',
        latitude: '6,985099792',
        longitude: '158,2089996',
        altitude: '10',
        timezone: '11',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Babelthuap Airport',
        city: 'Babelthuap',
        country: 'Palau',
        iataCode: 'ROR',
        icaoCode: 'PTRO',
        latitude: '7,367650032',
        longitude: '134,5440063',
        altitude: '176',
        timezone: '9',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Kosrae International Airport',
        city: 'Kosrae',
        country: 'Micronesia',
        iataCode: 'KSA',
        icaoCode: 'PTSA',
        latitude: '5,35698',
        longitude: '162,957993',
        altitude: '11',
        timezone: '11',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Yap International Airport',
        city: 'Yap',
        country: 'Micronesia',
        iataCode: 'YAP',
        icaoCode: 'PTYA',
        latitude: '9,49891',
        longitude: '138,082993',
        altitude: '91',
        timezone: '10',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Kinmen Airport',
        city: 'Kinmen',
        country: 'Taiwan',
        iataCode: 'KNH',
        icaoCode: 'RCBS',
        latitude: '24,42790031',
        longitude: '118,3590012',
        altitude: '93',
        timezone: '8',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Pingtung South Airport',
        city: 'Pingtung',
        country: 'Taiwan',
        iataCode: 'N',
        icaoCode: 'RCDC',
        latitude: '22,67239952',
        longitude: '120,461998',
        altitude: '78',
        timezone: '8',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Longtan Air Base',
        city: 'Longtang',
        country: 'Taiwan',
        iataCode: 'N',
        icaoCode: 'RCDI',
        latitude: '24,85510063',
        longitude: '121,237999',
        altitude: '790',
        timezone: '8',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Taitung Airport',
        city: 'Fengnin',
        country: 'Taiwan',
        iataCode: 'TTT',
        icaoCode: 'RCFN',
        latitude: '22,75499916',
        longitude: '121,1019974',
        altitude: '143',
        timezone: '8',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Lyudao Airport',
        city: 'Green Island',
        country: 'Taiwan',
        iataCode: 'GNI',
        icaoCode: 'RCGI',
        latitude: '22,6739006',
        longitude: '121,4660034',
        altitude: '28',
        timezone: '8',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Kaohsiung International Airport',
        city: 'Kaohsiung',
        country: 'Taiwan',
        iataCode: 'KHH',
        icaoCode: 'RCKH',
        latitude: '22,57710075',
        longitude: '120,3499985',
        altitude: '31',
        timezone: '8',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Chiayi Airport',
        city: 'Chiayi',
        country: 'Taiwan',
        iataCode: 'CYI',
        icaoCode: 'RCKU',
        latitude: '23,46179962',
        longitude: '120,3929977',
        altitude: '85',
        timezone: '8',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Lanyu Airport',
        city: 'Lanyu',
        country: 'Taiwan',
        iataCode: 'KYD',
        icaoCode: 'RCLY',
        latitude: '22,02700043',
        longitude: '121,5350037',
        altitude: '44',
        timezone: '8',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Taichung Ching Chuang Kang Airport',
        city: 'Taichung',
        country: 'Taiwan',
        iataCode: 'RMQ',
        icaoCode: 'RCMQ',
        latitude: '24,26469994',
        longitude: '120,6210022',
        altitude: '663',
        timezone: '8',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Tainan Airport',
        city: 'Tainan',
        country: 'Taiwan',
        iataCode: 'TNN',
        icaoCode: 'RCNN',
        latitude: '22,9503994',
        longitude: '120,2060013',
        altitude: '63',
        timezone: '8',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Hsinchu Air Base',
        city: 'Hsinchu',
        country: 'Taiwan',
        iataCode: 'N',
        icaoCode: 'RCPO',
        latitude: '24,81800079',
        longitude: '120,939003',
        altitude: '26',
        timezone: '8',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Makung Airport',
        city: 'Makung',
        country: 'Taiwan',
        iataCode: 'MZG',
        icaoCode: 'RCQC',
        latitude: '23,56870079',
        longitude: '119,6279984',
        altitude: '103',
        timezone: '8',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Chihhang Air Base',
        city: 'Taitung',
        country: 'Taiwan',
        iataCode: 'N',
        icaoCode: 'RCQS',
        latitude: '22,79310036',
        longitude: '121,1819992',
        altitude: '121',
        timezone: '8',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Pingtung North Airport',
        city: 'Pingtung',
        country: 'Taiwan',
        iataCode: 'PIF',
        icaoCode: 'RCSQ',
        latitude: '22,70019913',
        longitude: '120,4820023',
        altitude: '97',
        timezone: '8',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Taipei Songshan Airport',
        city: 'Taipei',
        country: 'Taiwan',
        iataCode: 'TSA',
        icaoCode: 'RCSS',
        latitude: '25,06940079',
        longitude: '121,552002',
        altitude: '18',
        timezone: '8',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Taiwan Taoyuan International Airport',
        city: 'Taipei',
        country: 'Taiwan',
        iataCode: 'TPE',
        icaoCode: 'RCTP',
        latitude: '25,0777',
        longitude: '121,233002',
        altitude: '106',
        timezone: '8',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Wang-an Airport',
        city: 'Wang An',
        country: 'Taiwan',
        iataCode: 'WOT',
        icaoCode: 'RCWA',
        latitude: '23,36737251',
        longitude: '119,5027771',
        altitude: '115',
        timezone: '8',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Hualien Airport',
        city: 'Hualien',
        country: 'Taiwan',
        iataCode: 'HUN',
        icaoCode: 'RCYU',
        latitude: '24,0230999',
        longitude: '121,6179962',
        altitude: '52',
        timezone: '8',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Narita International Airport',
        city: 'Tokyo',
        country: 'Japan',
        iataCode: 'NRT',
        icaoCode: 'RJAA',
        latitude: '35,76470184',
        longitude: '140,3860016',
        altitude: '141',
        timezone: '9',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Matsumoto Airport',
        city: 'Matsumoto',
        country: 'Japan',
        iataCode: 'MMJ',
        icaoCode: 'RJAF',
        latitude: '36,16680145',
        longitude: '137,9230042',
        altitude: '2182',
        timezone: '9',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Hyakuri Airport',
        city: 'Ibaraki',
        country: 'Japan',
        iataCode: 'IBR',
        icaoCode: 'RJAH',
        latitude: '36,18109894',
        longitude: '140,4149933',
        altitude: '105',
        timezone: '9',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Minami Torishima Airport',
        city: 'Minami Tori Shima',
        country: 'Japan',
        iataCode: 'N',
        icaoCode: 'RJAM',
        latitude: '24,28969955',
        longitude: '153,9790039',
        altitude: '22',
        timezone: '9',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Iwo Jima Airport',
        city: 'Iwojima',
        country: 'Japan',
        iataCode: 'IWO',
        icaoCode: 'RJAW',
        latitude: '24,7840004',
        longitude: '141,322998',
        altitude: '384',
        timezone: '9',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Nanki Shirahama Airport',
        city: 'Nanki-shirahama',
        country: 'Japan',
        iataCode: 'SHM',
        icaoCode: 'RJBD',
        latitude: '33,66220093',
        longitude: '135,3639984',
        altitude: '298',
        timezone: '9',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Kohnan Airport',
        city: 'Kohnan',
        country: 'Japan',
        iataCode: 'N',
        icaoCode: 'RJBK',
        latitude: '34,59080124',
        longitude: '133,9329987',
        altitude: '3',
        timezone: '9',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Tokachi-Obihiro Airport',
        city: 'Obihiro',
        country: 'Japan',
        iataCode: 'OBO',
        icaoCode: 'RJCB',
        latitude: '42,73329926',
        longitude: '143,2169952',
        altitude: '505',
        timezone: '9',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'New Chitose Airport',
        city: 'Sapporo',
        country: 'Japan',
        iataCode: 'CTS',
        icaoCode: 'RJCC',
        latitude: '42,77519989',
        longitude: '141,6920013',
        altitude: '82',
        timezone: '9',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Hakodate Airport',
        city: 'Hakodate',
        country: 'Japan',
        iataCode: 'HKD',
        icaoCode: 'RJCH',
        latitude: '41,77000046',
        longitude: '140,8220062',
        altitude: '151',
        timezone: '9',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Chitose Air Base',
        city: 'Chitose',
        country: 'Japan',
        iataCode: 'SPK',
        icaoCode: 'RJCJ',
        latitude: '42,79449844',
        longitude: '141,6660004',
        altitude: '87',
        timezone: '9',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Memanbetsu Airport',
        city: 'Memanbetsu',
        country: 'Japan',
        iataCode: 'MMB',
        icaoCode: 'RJCM',
        latitude: '43,88059998',
        longitude: '144,1640015',
        altitude: '135',
        timezone: '9',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Nakashibetsu Airport',
        city: 'Nakashibetsu',
        country: 'Japan',
        iataCode: 'SHB',
        icaoCode: 'RJCN',
        latitude: '43,57749939',
        longitude: '144,9600067',
        altitude: '234',
        timezone: '9',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Tokachi Airport',
        city: 'Tokachi',
        country: 'Japan',
        iataCode: 'N',
        icaoCode: 'RJCT',
        latitude: '42,89049911',
        longitude: '143,1580048',
        altitude: '281',
        timezone: '9',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Wakkanai Airport',
        city: 'Wakkanai',
        country: 'Japan',
        iataCode: 'WKJ',
        icaoCode: 'RJCW',
        latitude: '45,40420151',
        longitude: '141,8009949',
        altitude: '30',
        timezone: '9',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Iki Airport',
        city: 'Iki',
        country: 'Japan',
        iataCode: 'IKI',
        icaoCode: 'RJDB',
        latitude: '33,74900055',
        longitude: '129,7850037',
        altitude: '41',
        timezone: '9',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Yamaguchi Ube Airport',
        city: 'Yamaguchi',
        country: 'Japan',
        iataCode: 'UBJ',
        icaoCode: 'RJDC',
        latitude: '33,93000031',
        longitude: '131,279007',
        altitude: '23',
        timezone: '9',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Tsushima Airport',
        city: 'Tsushima',
        country: 'Japan',
        iataCode: 'TSJ',
        icaoCode: 'RJDT',
        latitude: '34,28490067',
        longitude: '129,3309937',
        altitude: '213',
        timezone: '9',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Monbetsu Airport',
        city: 'Monbetsu',
        country: 'Japan',
        iataCode: 'MBE',
        icaoCode: 'RJEB',
        latitude: '44,30390167',
        longitude: '143,404007',
        altitude: '80',
        timezone: '9',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Asahikawa Airport',
        city: 'Asahikawa',
        country: 'Japan',
        iataCode: 'AKJ',
        icaoCode: 'RJEC',
        latitude: '43,67079926',
        longitude: '142,4470062',
        altitude: '721',
        timezone: '9',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Okushiri Airport',
        city: 'Okushiri',
        country: 'Japan',
        iataCode: 'OIR',
        icaoCode: 'RJEO',
        latitude: '42,07170105',
        longitude: '139,4329987',
        altitude: '161',
        timezone: '9',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Rishiri Airport',
        city: 'Rishiri Island',
        country: 'Japan',
        iataCode: 'RIS',
        icaoCode: 'RJER',
        latitude: '45,24200058',
        longitude: '141,1860046',
        altitude: '112',
        timezone: '9',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Ashiya Airport',
        city: 'Ashiya',
        country: 'Japan',
        iataCode: 'N',
        icaoCode: 'RJFA',
        latitude: '33,8830986',
        longitude: '130,6529999',
        altitude: '98',
        timezone: '9',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Yakushima Airport',
        city: 'Yakushima',
        country: 'Japan',
        iataCode: 'KUM',
        icaoCode: 'RJFC',
        latitude: '30,38559914',
        longitude: '130,6589966',
        altitude: '124',
        timezone: '9',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Fukue Airport',
        city: 'Fukue',
        country: 'Japan',
        iataCode: 'FUJ',
        icaoCode: 'RJFE',
        latitude: '32,66630173',
        longitude: '128,8329926',
        altitude: '273',
        timezone: '9',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Fukuoka Airport',
        city: 'Fukuoka',
        country: 'Japan',
        iataCode: 'FUK',
        icaoCode: 'RJFF',
        latitude: '33,58589935',
        longitude: '130,451004',
        altitude: '32',
        timezone: '9',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'New Tanegashima Airport',
        city: 'Tanegashima',
        country: 'Japan',
        iataCode: 'TNE',
        icaoCode: 'RJFG',
        latitude: '30,60510063',
        longitude: '130,9909973',
        altitude: '768',
        timezone: '9',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Kagoshima Airport',
        city: 'Kagoshima',
        country: 'Japan',
        iataCode: 'KOJ',
        icaoCode: 'RJFK',
        latitude: '31,80340004',
        longitude: '130,7189941',
        altitude: '906',
        timezone: '9',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Miyazaki Airport',
        city: 'Miyazaki',
        country: 'Japan',
        iataCode: 'KMI',
        icaoCode: 'RJFM',
        latitude: '31,87719917',
        longitude: '131,4490051',
        altitude: '20',
        timezone: '9',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Nyutabaru Airport',
        city: 'Nyutabaru',
        country: 'Japan',
        iataCode: 'N',
        icaoCode: 'RJFN',
        latitude: '32,08359909',
        longitude: '131,451004',
        altitude: '259',
        timezone: '9',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Oita Airport',
        city: 'Oita',
        country: 'Japan',
        iataCode: 'OIT',
        icaoCode: 'RJFO',
        latitude: '33,47940063',
        longitude: '131,7369995',
        altitude: '19',
        timezone: '9',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Kitakyūshū Airport',
        city: 'Kitakyushu',
        country: 'Japan',
        iataCode: 'KKJ',
        icaoCode: 'RJFR',
        latitude: '33,84590149',
        longitude: '131,0350037',
        altitude: '21',
        timezone: '9',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Kumamoto Airport',
        city: 'Kumamoto',
        country: 'Japan',
        iataCode: 'KMJ',
        icaoCode: 'RJFT',
        latitude: '32,83729935',
        longitude: '130,8549957',
        altitude: '642',
        timezone: '9',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Nagasaki Airport',
        city: 'Nagasaki',
        country: 'Japan',
        iataCode: 'NGS',
        icaoCode: 'RJFU',
        latitude: '32,91690063',
        longitude: '129,9140015',
        altitude: '15',
        timezone: '9',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Kanoya Airport',
        city: 'Kanoya',
        country: 'Japan',
        iataCode: 'N',
        icaoCode: 'RJFY',
        latitude: '31,36759949',
        longitude: '130,8450012',
        altitude: '214',
        timezone: '9',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Tsuiki Air Field',
        city: 'Tsuiki',
        country: 'Japan',
        iataCode: 'N',
        icaoCode: 'RJFZ',
        latitude: '33,68500137',
        longitude: '131,0399933',
        altitude: '55',
        timezone: '9',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Amami Airport',
        city: 'Amami',
        country: 'Japan',
        iataCode: 'ASJ',
        icaoCode: 'RJKA',
        latitude: '28,43059921',
        longitude: '129,7129974',
        altitude: '27',
        timezone: '9',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Okierabu Airport',
        city: 'Okierabu',
        country: 'Japan',
        iataCode: 'N',
        icaoCode: 'RJKB',
        latitude: '27,42550087',
        longitude: '128,701004',
        altitude: '101',
        timezone: '9',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Tokunoshima Airport',
        city: 'Tokunoshima',
        country: 'Japan',
        iataCode: 'TKN',
        icaoCode: 'RJKN',
        latitude: '27,83639908',
        longitude: '128,8809967',
        altitude: '17',
        timezone: '9',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Fukui Airport',
        city: 'Fukui',
        country: 'Japan',
        iataCode: 'N',
        icaoCode: 'RJNF',
        latitude: '36,14279938',
        longitude: '136,223999',
        altitude: '19',
        timezone: '9',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Gifu Airport',
        city: 'Gifu',
        country: 'Japan',
        iataCode: 'N',
        icaoCode: 'RJNG',
        latitude: '35,39410019',
        longitude: '136,8699951',
        altitude: '128',
        timezone: '9',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Hamamatsu Airport',
        city: 'Hamamatsu',
        country: 'Japan',
        iataCode: 'N',
        icaoCode: 'RJNH',
        latitude: '34,75019836',
        longitude: '137,7030029',
        altitude: '150',
        timezone: '9',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Komatsu Airport',
        city: 'Kanazawa',
        country: 'Japan',
        iataCode: 'KMQ',
        icaoCode: 'RJNK',
        latitude: '36,39459991',
        longitude: '136,4069977',
        altitude: '36',
        timezone: '9',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Oki Airport',
        city: 'Oki Island',
        country: 'Japan',
        iataCode: 'OKI',
        icaoCode: 'RJNO',
        latitude: '36,18109894',
        longitude: '133,3249969',
        altitude: '311',
        timezone: '9',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Toyama Airport',
        city: 'Toyama',
        country: 'Japan',
        iataCode: 'TOY',
        icaoCode: 'RJNT',
        latitude: '36,64830017',
        longitude: '137,1880035',
        altitude: '95',
        timezone: '9',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Shizuhama Airport',
        city: 'Yaizu',
        country: 'Japan',
        iataCode: 'N',
        icaoCode: 'RJNY',
        latitude: '34,81280136',
        longitude: '138,2980042',
        altitude: '23',
        timezone: '9',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Hiroshima Airport',
        city: 'Hiroshima',
        country: 'Japan',
        iataCode: 'HIJ',
        icaoCode: 'RJOA',
        latitude: '34,43610001',
        longitude: '132,9190063',
        altitude: '1088',
        timezone: '9',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Okayama Airport',
        city: 'Okayama',
        country: 'Japan',
        iataCode: 'OKJ',
        icaoCode: 'RJOB',
        latitude: '34,75690079',
        longitude: '133,8549957',
        altitude: '806',
        timezone: '9',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Izumo Airport',
        city: 'Izumo',
        country: 'Japan',
        iataCode: 'IZO',
        icaoCode: 'RJOC',
        latitude: '35,41360092',
        longitude: '132,8899994',
        altitude: '15',
        timezone: '9',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Hofu Airport',
        city: 'Hofu',
        country: 'Japan',
        iataCode: 'N',
        icaoCode: 'RJOF',
        latitude: '34,03469849',
        longitude: '131,548996',
        altitude: '7',
        timezone: '9',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Miho Yonago Airport',
        city: 'Miho',
        country: 'Japan',
        iataCode: 'YGJ',
        icaoCode: 'RJOH',
        latitude: '35,49219894',
        longitude: '133,2359924',
        altitude: '20',
        timezone: '9',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Kōchi Ryōma Airport',
        city: 'Kochi',
        country: 'Japan',
        iataCode: 'KCZ',
        icaoCode: 'RJOK',
        latitude: '33,54610062',
        longitude: '133,6690063',
        altitude: '42',
        timezone: '9',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Matsuyama Airport',
        city: 'Matsuyama',
        country: 'Japan',
        iataCode: 'MYJ',
        icaoCode: 'RJOM',
        latitude: '33,82720184',
        longitude: '132,6999969',
        altitude: '25',
        timezone: '9',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Osaka International Airport',
        city: 'Osaka',
        country: 'Japan',
        iataCode: 'ITM',
        icaoCode: 'RJOO',
        latitude: '34,78549957',
        longitude: '135,4380035',
        altitude: '50',
        timezone: '9',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Tottori Airport',
        city: 'Tottori',
        country: 'Japan',
        iataCode: 'TTJ',
        icaoCode: 'RJOR',
        latitude: '35,53010178',
        longitude: '134,1670074',
        altitude: '65',
        timezone: '9',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Tokushima Airport',
        city: 'Tokushima',
        country: 'Japan',
        iataCode: 'TKS',
        icaoCode: 'RJOS',
        latitude: '34,13280106',
        longitude: '134,6069946',
        altitude: '26',
        timezone: '9',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Takamatsu Airport',
        city: 'Takamatsu',
        country: 'Japan',
        iataCode: 'TAK',
        icaoCode: 'RJOT',
        latitude: '34,21419907',
        longitude: '134,0160065',
        altitude: '607',
        timezone: '9',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Yao Airport',
        city: 'Osaka',
        country: 'Japan',
        iataCode: 'N',
        icaoCode: 'RJOY',
        latitude: '34,59629822',
        longitude: '135,6029968',
        altitude: '39',
        timezone: '9',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Ozuki Airport',
        city: 'Ozuki',
        country: 'Japan',
        iataCode: 'N',
        icaoCode: 'RJOZ',
        latitude: '34,04529953',
        longitude: '131,052002',
        altitude: '13',
        timezone: '9',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Aomori Airport',
        city: 'Aomori',
        country: 'Japan',
        iataCode: 'AOJ',
        icaoCode: 'RJSA',
        latitude: '40,73469925',
        longitude: '140,6909943',
        altitude: '664',
        timezone: '9',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Yamagata Airport',
        city: 'Yamagata',
        country: 'Japan',
        iataCode: 'GAJ',
        icaoCode: 'RJSC',
        latitude: '38,41189957',
        longitude: '140,3710022',
        altitude: '353',
        timezone: '9',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Sado Airport',
        city: 'Sado',
        country: 'Japan',
        iataCode: 'SDS',
        icaoCode: 'RJSD',
        latitude: '38,06019974',
        longitude: '138,4140015',
        altitude: '88',
        timezone: '9',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Hachinohe Airport',
        city: 'Hachinoe',
        country: 'Japan',
        iataCode: 'N',
        icaoCode: 'RJSH',
        latitude: '40,5564003',
        longitude: '141,4660034',
        altitude: '152',
        timezone: '9',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Hanamaki Airport',
        city: 'Hanamaki',
        country: 'Japan',
        iataCode: 'HNA',
        icaoCode: 'RJSI',
        latitude: '39,42860031',
        longitude: '141,1349945',
        altitude: '297',
        timezone: '9',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Akita Airport',
        city: 'Akita',
        country: 'Japan',
        iataCode: 'AXT',
        icaoCode: 'RJSK',
        latitude: '39,61560059',
        longitude: '140,2189941',
        altitude: '313',
        timezone: '9',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Misawa Air Base',
        city: 'Misawa',
        country: 'Japan',
        iataCode: 'MSJ',
        icaoCode: 'RJSM',
        latitude: '40,70320129',
        longitude: '141,3679962',
        altitude: '119',
        timezone: '9',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Sendai Airport',
        city: 'Sendai',
        country: 'Japan',
        iataCode: 'SDJ',
        icaoCode: 'RJSS',
        latitude: '38,13970184',
        longitude: '140,9170074',
        altitude: '15',
        timezone: '9',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Matsushima Air Base',
        city: 'Matsushima',
        country: 'Japan',
        iataCode: 'N',
        icaoCode: 'RJST',
        latitude: '38,4048996',
        longitude: '141,2200012',
        altitude: '7',
        timezone: '9',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Atsugi Naval Air Facility',
        city: 'Atsugi',
        country: 'Japan',
        iataCode: 'N',
        icaoCode: 'RJTA',
        latitude: '35,45460129',
        longitude: '139,4499969',
        altitude: '205',
        timezone: '9',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Tateyama Airport',
        city: 'Tateyama',
        country: 'Japan',
        iataCode: 'N',
        icaoCode: 'RJTE',
        latitude: '34,987099',
        longitude: '139,828995',
        altitude: '10',
        timezone: '9',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Hachijojima Airport',
        city: 'Hachijojima',
        country: 'Japan',
        iataCode: 'HAC',
        icaoCode: 'RJTH',
        latitude: '33,11500168',
        longitude: '139,7859955',
        altitude: '303',
        timezone: '9',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Iruma Air Base',
        city: 'Iruma',
        country: 'Japan',
        iataCode: 'N',
        icaoCode: 'RJTJ',
        latitude: '35,84189987',
        longitude: '139,4109955',
        altitude: '295',
        timezone: '9',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Kisarazu Airport',
        city: 'Kisarazu',
        country: 'Japan',
        iataCode: 'N',
        icaoCode: 'RJTK',
        latitude: '35,39830017',
        longitude: '139,9100037',
        altitude: '10',
        timezone: '9',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Shimofusa Airport',
        city: 'Shimofusa',
        country: 'Japan',
        iataCode: 'N',
        icaoCode: 'RJTL',
        latitude: '35,7989006',
        longitude: '140,0110016',
        altitude: '98',
        timezone: '9',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Oshima Airport',
        city: 'Oshima',
        country: 'Japan',
        iataCode: 'OIM',
        icaoCode: 'RJTO',
        latitude: '34,7820015',
        longitude: '139,3600006',
        altitude: '130',
        timezone: '9',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Kastner Army Heliport',
        city: 'Zama',
        country: 'Japan',
        iataCode: 'N',
        icaoCode: 'RJTR',
        latitude: '35,51380157',
        longitude: '139,3939972',
        altitude: '360',
        timezone: '9',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Tokyo Haneda International Airport',
        city: 'Tokyo',
        country: 'Japan',
        iataCode: 'HND',
        icaoCode: 'RJTT',
        latitude: '35,552299',
        longitude: '139,779999',
        altitude: '35',
        timezone: '9',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Yokota Air Base',
        city: 'Yokota',
        country: 'Japan',
        iataCode: 'OKO',
        icaoCode: 'RJTY',
        latitude: '35,74850082',
        longitude: '139,3480072',
        altitude: '463',
        timezone: '9',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Gwangju Airport',
        city: 'Kwangju',
        country: 'South Korea',
        iataCode: 'KWJ',
        icaoCode: 'RKJJ',
        latitude: '35,12639999',
        longitude: '126,8089981',
        altitude: '39',
        timezone: '9',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Jeon Ju Airport',
        city: 'Jhunju',
        country: 'South Korea',
        iataCode: 'CHN',
        icaoCode: 'RKJU',
        latitude: '35,8783989',
        longitude: '127,1200027',
        altitude: '96',
        timezone: '9',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Yeosu Airport',
        city: 'Yeosu',
        country: 'South Korea',
        iataCode: 'RSU',
        icaoCode: 'RKJY',
        latitude: '34,84230042',
        longitude: '127,6169968',
        altitude: '53',
        timezone: '9',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Sokcho Airport',
        city: "Sokch'o",
        country: 'South Korea',
        iataCode: 'SHO',
        icaoCode: 'RKND',
        latitude: '38,14260101',
        longitude: '128,598999',
        altitude: '92',
        timezone: '9',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Gangneung Airport',
        city: 'Kangnung',
        country: 'South Korea',
        iataCode: 'KAG',
        icaoCode: 'RKNN',
        latitude: '37,75360107',
        longitude: '128,9440002',
        altitude: '35',
        timezone: '9',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Jeju International Airport',
        city: 'Cheju',
        country: 'South Korea',
        iataCode: 'CJU',
        icaoCode: 'RKPC',
        latitude: '33,51129913',
        longitude: '126,4929962',
        altitude: '118',
        timezone: '9',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Jinhae Airport',
        city: 'Chinhae',
        country: 'South Korea',
        iataCode: 'N',
        icaoCode: 'RKPE',
        latitude: '35,14120102',
        longitude: '128,6959991',
        altitude: '8',
        timezone: '9',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Gimhae International Airport',
        city: 'Busan',
        country: 'South Korea',
        iataCode: 'PUS',
        icaoCode: 'RKPK',
        latitude: '35,17950058',
        longitude: '128,9380035',
        altitude: '6',
        timezone: '9',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Ulsan Airport',
        city: 'Ulsan',
        country: 'South Korea',
        iataCode: 'USN',
        icaoCode: 'RKPU',
        latitude: '35,59349823',
        longitude: '129,352005',
        altitude: '45',
        timezone: '9',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'A 511 Airport',
        city: 'Pyongtaek',
        country: 'South Korea',
        iataCode: 'N',
        icaoCode: 'RKSG',
        latitude: '36,96220016',
        longitude: '127,0309982',
        altitude: '51',
        timezone: '9',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Seoul Air Base',
        city: 'Seoul East',
        country: 'South Korea',
        iataCode: 'SSN',
        icaoCode: 'RKSM',
        latitude: '37,44580078',
        longitude: '127,1139984',
        altitude: '92',
        timezone: '9',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Osan Air Base',
        city: 'Osan',
        country: 'South Korea',
        iataCode: 'OSN',
        icaoCode: 'RKSO',
        latitude: '37,090599',
        longitude: '127,029999',
        altitude: '38',
        timezone: '9',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Gimpo International Airport',
        city: 'Seoul',
        country: 'South Korea',
        iataCode: 'GMP',
        icaoCode: 'RKSS',
        latitude: '37,55830002',
        longitude: '126,7910004',
        altitude: '58',
        timezone: '9',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Suwon Airport',
        city: 'Suwon',
        country: 'South Korea',
        iataCode: 'SWU',
        icaoCode: 'RKSW',
        latitude: '37,23939896',
        longitude: '127,0070038',
        altitude: '88',
        timezone: '9',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Pohang Airport',
        city: 'Pohang',
        country: 'South Korea',
        iataCode: 'KPO',
        icaoCode: 'RKTH',
        latitude: '35,98789978',
        longitude: '129,4199982',
        altitude: '70',
        timezone: '9',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Daegu Airport',
        city: 'Taegu',
        country: 'South Korea',
        iataCode: 'TAE',
        icaoCode: 'RKTN',
        latitude: '35,89410019',
        longitude: '128,6589966',
        altitude: '116',
        timezone: '9',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Yecheon Airport',
        city: 'Yechon',
        country: 'South Korea',
        iataCode: 'YEC',
        icaoCode: 'RKTY',
        latitude: '36,63190079',
        longitude: '128,3549957',
        altitude: '354',
        timezone: '9',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Naha Airport',
        city: 'Okinawa',
        country: 'Japan',
        iataCode: 'OKA',
        icaoCode: 'ROAH',
        latitude: '26,19580078',
        longitude: '127,6460037',
        altitude: '12',
        timezone: '9',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Ie Shima Auxiliary Air Base',
        city: 'Iejima',
        country: 'Japan',
        iataCode: 'N',
        icaoCode: 'RODE',
        latitude: '26,7257576',
        longitude: '127,7649002',
        altitude: '184',
        timezone: '9',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Kadena Air Base',
        city: 'Kadena',
        country: 'Japan',
        iataCode: 'DNA',
        icaoCode: 'RODN',
        latitude: '26,3556',
        longitude: '127,767998',
        altitude: '143',
        timezone: '9',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Ishigaki Airport',
        city: 'Ishigaki',
        country: 'Japan',
        iataCode: 'ISG',
        icaoCode: 'ROIG',
        latitude: '24,34449959',
        longitude: '124,1869965',
        altitude: '93',
        timezone: '9',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Kumejima Airport',
        city: 'Kumejima',
        country: 'Japan',
        iataCode: 'UEO',
        icaoCode: 'ROKJ',
        latitude: '26,3635006',
        longitude: '126,7139969',
        altitude: '23',
        timezone: '9',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Minami-Daito Airport',
        city: 'Minami Daito',
        country: 'Japan',
        iataCode: 'MMD',
        icaoCode: 'ROMD',
        latitude: '25,8465004',
        longitude: '131,2630005',
        altitude: '167',
        timezone: '9',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Miyako Airport',
        city: 'Miyako',
        country: 'Japan',
        iataCode: 'MMY',
        icaoCode: 'ROMY',
        latitude: '24,78280067',
        longitude: '125,2949982',
        altitude: '150',
        timezone: '9',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Kitadaito Airport',
        city: 'Kitadaito',
        country: 'Japan',
        iataCode: 'KTD',
        icaoCode: 'RORK',
        latitude: '25,94470024',
        longitude: '131,3269959',
        altitude: '80',
        timezone: '9',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Shimojishima Airport',
        city: 'Shimojishima',
        country: 'Japan',
        iataCode: 'SHI',
        icaoCode: 'RORS',
        latitude: '24,82670021',
        longitude: '125,1449966',
        altitude: '54',
        timezone: '9',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Tarama Airport',
        city: 'Tarama',
        country: 'Japan',
        iataCode: 'N',
        icaoCode: 'RORT',
        latitude: '24,65390015',
        longitude: '124,6750031',
        altitude: '36',
        timezone: '9',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Yoron Airport',
        city: 'Yoron',
        country: 'Japan',
        iataCode: 'RNJ',
        icaoCode: 'RORY',
        latitude: '27,04400063',
        longitude: '128,4019928',
        altitude: '52',
        timezone: '9',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Yonaguni Airport',
        city: 'Yonaguni Jima',
        country: 'Japan',
        iataCode: 'OGN',
        icaoCode: 'ROYN',
        latitude: '24,46689987',
        longitude: '122,9779968',
        altitude: '70',
        timezone: '9',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Ninoy Aquino International Airport',
        city: 'Manila',
        country: 'Philippines',
        iataCode: 'MNL',
        icaoCode: 'RPLL',
        latitude: '14,5086',
        longitude: '121,019997',
        altitude: '75',
        timezone: '8',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Awang Airport',
        city: 'Cotabato',
        country: 'Philippines',
        iataCode: 'CBO',
        icaoCode: 'RPMC',
        latitude: '7,165239811',
        longitude: '124,2099991',
        altitude: '189',
        timezone: '8',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Cagayan De Oro Airport',
        city: 'Ladag',
        country: 'Philippines',
        iataCode: 'CGY',
        icaoCode: 'RPML',
        latitude: '8,41562',
        longitude: '124,611',
        altitude: '601',
        timezone: '8',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Pagadian Airport',
        city: 'Pagadian',
        country: 'Philippines',
        iataCode: 'PAG',
        icaoCode: 'RPMP',
        latitude: '7,830731448',
        longitude: '123,4611797',
        altitude: '5',
        timezone: '8',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'General Santos International Airport',
        city: 'Romblon',
        country: 'Philippines',
        iataCode: 'GES',
        icaoCode: 'RPMR',
        latitude: '6,058000088',
        longitude: '125,0960007',
        altitude: '505',
        timezone: '8',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Zamboanga International Airport',
        city: 'Zamboanga',
        country: 'Philippines',
        iataCode: 'ZAM',
        icaoCode: 'RPMZ',
        latitude: '6,922420025',
        longitude: '122,0599976',
        altitude: '33',
        timezone: '8',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Loakan Airport',
        city: 'Baguio',
        country: 'Philippines',
        iataCode: 'BAG',
        icaoCode: 'RPUB',
        latitude: '16,37509918',
        longitude: '120,6200027',
        altitude: '4251',
        timezone: '8',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Daet Airport',
        city: 'Daet',
        country: 'Philippines',
        iataCode: 'N',
        icaoCode: 'RPUD',
        latitude: '14,12919998',
        longitude: '122,9800034',
        altitude: '10',
        timezone: '8',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Basa Air Base',
        city: 'Floridablanca',
        country: 'Philippines',
        iataCode: 'N',
        icaoCode: 'RPUF',
        latitude: '14,98649979',
        longitude: '120,4929962',
        altitude: '151',
        timezone: '8',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Lingayen Airport',
        city: 'Lingayen',
        country: 'Philippines',
        iataCode: 'N',
        icaoCode: 'RPUG',
        latitude: '16,03479958',
        longitude: '120,2409973',
        altitude: '7',
        timezone: '8',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'San Jose Airport',
        city: 'San Jose',
        country: 'Philippines',
        iataCode: 'SJI',
        icaoCode: 'RPUH',
        latitude: '12,36149979',
        longitude: '121,0469971',
        altitude: '14',
        timezone: '8',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Fernando Air Base',
        city: 'Lipa',
        country: 'Philippines',
        iataCode: 'N',
        icaoCode: 'RPUL',
        latitude: '13,95499992',
        longitude: '121,125',
        altitude: '1220',
        timezone: '8',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Mamburao Airport',
        city: 'Mamburao',
        country: 'Philippines',
        iataCode: 'N',
        icaoCode: 'RPUM',
        latitude: '13,20810032',
        longitude: '120,6050034',
        altitude: '13',
        timezone: '8',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Vigan Airport',
        city: 'Vigan',
        country: 'Philippines',
        iataCode: 'N',
        icaoCode: 'RPUQ',
        latitude: '17,55529976',
        longitude: '120,3560028',
        altitude: '16',
        timezone: '8',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Dr.Juan C. Angara Airport',
        city: 'Baler',
        country: 'Philippines',
        iataCode: 'N',
        icaoCode: 'RPUR',
        latitude: '15,72980022',
        longitude: '121,5',
        altitude: '108',
        timezone: '8',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Bagabag Airport',
        city: 'Bagabag',
        country: 'Philippines',
        iataCode: 'N',
        icaoCode: 'RPUZ',
        latitude: '16,61919975',
        longitude: '121,2519989',
        altitude: '820',
        timezone: '8',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Daniel Z. Romualdez Airport',
        city: 'Tacloban',
        country: 'Philippines',
        iataCode: 'TAC',
        icaoCode: 'RPVA',
        latitude: '11,2276001',
        longitude: '125,0279999',
        altitude: '10',
        timezone: '8',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Bacolod-Silay City International Airport',
        city: 'Bacolod',
        country: 'Philippines',
        iataCode: 'BCD',
        icaoCode: 'RPVB',
        latitude: '10,7764',
        longitude: '123,014999',
        altitude: '82',
        timezone: '8',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Sibulan Airport',
        city: 'Dumaguete',
        country: 'Philippines',
        iataCode: 'DGT',
        icaoCode: 'RPVD',
        latitude: '9,333709717',
        longitude: '123,3000031',
        altitude: '15',
        timezone: '8',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Godofredo P. Ramos Airport',
        city: 'Caticlan',
        country: 'Philippines',
        iataCode: 'MPH',
        icaoCode: 'RPVE',
        latitude: '11,9245',
        longitude: '121,954002',
        altitude: '7',
        timezone: '8',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Guiuan Airport',
        city: 'Guiuan',
        country: 'Philippines',
        iataCode: 'N',
        icaoCode: 'RPVG',
        latitude: '11,03549957',
        longitude: '125,7419968',
        altitude: '7',
        timezone: '8',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Iloilo International Airport',
        city: 'Iloilo',
        country: 'Philippines',
        iataCode: 'ILO',
        icaoCode: 'RPVI',
        latitude: '10,833017',
        longitude: '122,493358',
        altitude: '27',
        timezone: '8',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Kalibo International Airport',
        city: 'Kalibo',
        country: 'Philippines',
        iataCode: 'KLO',
        icaoCode: 'RPVK',
        latitude: '11,67940044',
        longitude: '122,3759995',
        altitude: '14',
        timezone: '8',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Puerto Princesa Airport',
        city: 'Puerto Princesa',
        country: 'Philippines',
        iataCode: 'PPS',
        icaoCode: 'RPVP',
        latitude: '9,742119789',
        longitude: '118,7590027',
        altitude: '71',
        timezone: '8',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Evelio Javier Airport',
        city: 'San Jose',
        country: 'Philippines',
        iataCode: 'N',
        icaoCode: 'RPVS',
        latitude: '10,76599979',
        longitude: '121,9329987',
        altitude: '23',
        timezone: '8',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Comodoro Pierrestegui Airport',
        city: 'Concordia',
        country: 'Argentina',
        iataCode: 'COC',
        icaoCode: 'SAAC',
        latitude: '-31,2969',
        longitude: '-57,9966',
        altitude: '112',
        timezone: '-3',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Gualeguaychu Airport',
        city: 'Gualeguaychu',
        country: 'Argentina',
        iataCode: 'GHU',
        icaoCode: 'SAAG',
        latitude: '-33,0103',
        longitude: '-58,6131',
        altitude: '75',
        timezone: '-3',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Junin Airport',
        city: 'Junin',
        country: 'Argentina',
        iataCode: 'N',
        icaoCode: 'SAAJ',
        latitude: '-34,5459',
        longitude: '-60,9306',
        altitude: '262',
        timezone: '-3',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'General Urquiza Airport',
        city: 'Parana',
        country: 'Argentina',
        iataCode: 'PRA',
        icaoCode: 'SAAP',
        latitude: '-31,7948',
        longitude: '-60,4804',
        altitude: '242',
        timezone: '-3',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Islas Malvinas Airport',
        city: 'Rosario',
        country: 'Argentina',
        iataCode: 'ROS',
        icaoCode: 'SAAR',
        latitude: '-32,9036',
        longitude: '-60,785',
        altitude: '85',
        timezone: '-3',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Sauce Viejo Airport',
        city: 'Santa Fe',
        country: 'Argentina',
        iataCode: 'SFN',
        icaoCode: 'SAAV',
        latitude: '-31,7117',
        longitude: '-60,8117',
        altitude: '55',
        timezone: '-3',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Jorge Newbery Airpark',
        city: 'Buenos Aires',
        country: 'Argentina',
        iataCode: 'AEP',
        icaoCode: 'SABE',
        latitude: '-34,5592',
        longitude: '-58,4156',
        altitude: '18',
        timezone: '-3',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Ingeniero Ambrosio Taravella Airport',
        city: 'Cordoba',
        country: 'Argentina',
        iataCode: 'COR',
        icaoCode: 'SACO',
        latitude: '-31,32360077',
        longitude: '-64,20800018',
        altitude: '1604',
        timezone: '-3',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Chamical Airport',
        city: 'Gobernador Gordillo',
        country: 'Argentina',
        iataCode: 'N',
        icaoCode: 'SACT',
        latitude: '-30,34530067',
        longitude: '-66,29360198',
        altitude: '1502',
        timezone: '-3',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'San Fernando Airport',
        city: 'San Fernando',
        country: 'Argentina',
        iataCode: 'N',
        icaoCode: 'SADF',
        latitude: '-34,4532',
        longitude: '-58,5896',
        altitude: '10',
        timezone: '-3',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Mariano Moreno Airport',
        city: 'Jose C. Paz',
        country: 'Argentina',
        iataCode: 'N',
        icaoCode: 'SADJ',
        latitude: '-34,5606',
        longitude: '-58,7896',
        altitude: '105',
        timezone: '-3',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'La Plata Airport',
        city: 'La Plata',
        country: 'Argentina',
        iataCode: 'LPG',
        icaoCode: 'SADL',
        latitude: '-34,9722',
        longitude: '-57,8947',
        altitude: '72',
        timezone: '-3',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Moron Airport',
        city: 'Moron',
        country: 'Argentina',
        iataCode: 'N',
        icaoCode: 'SADM',
        latitude: '-34,6763',
        longitude: '-58,6428',
        altitude: '95',
        timezone: '-3',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'El Palomar Airport',
        city: 'El Palomar',
        country: 'Argentina',
        iataCode: 'N',
        icaoCode: 'SADP',
        latitude: '-34,6099',
        longitude: '-58,6126',
        altitude: '59',
        timezone: '-3',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Chos Malal Airport',
        city: 'Chosmadal',
        country: 'Argentina',
        iataCode: 'N',
        icaoCode: 'SAHC',
        latitude: '-37,44469833',
        longitude: '-70,22250366',
        altitude: '2788',
        timezone: '-3',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Dr. Arturo H. Illia Airport',
        city: 'Fuerte Gral Roca',
        country: 'Argentina',
        iataCode: 'N',
        icaoCode: 'SAHR',
        latitude: '-39,0007019',
        longitude: '-67,62049866',
        altitude: '852',
        timezone: '-3',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'El Plumerillo Airport',
        city: 'Mendoza',
        country: 'Argentina',
        iataCode: 'MDZ',
        icaoCode: 'SAME',
        latitude: '-32,83169937',
        longitude: '-68,79290009',
        altitude: '2310',
        timezone: '-3',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Comodoro D.R. Salomón Airport',
        city: 'Malargue',
        country: 'Argentina',
        iataCode: 'LGS',
        icaoCode: 'SAMM',
        latitude: '-35,49359894',
        longitude: '-69,57430267',
        altitude: '4685',
        timezone: '-3',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Suboficial Ay Santiago Germano Airport',
        city: 'San Rafael',
        country: 'Argentina',
        iataCode: 'AFA',
        icaoCode: 'SAMR',
        latitude: '-34,588299',
        longitude: '-68,4039',
        altitude: '2470',
        timezone: '-3',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Catamarca Airport',
        city: 'Catamarca',
        country: 'Argentina',
        iataCode: 'CTC',
        icaoCode: 'SANC',
        latitude: '-28,59560013',
        longitude: '-65,75170136',
        altitude: '1522',
        timezone: '-3',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Vicecomodoro Angel D. La Paz Aragonés Airport',
        city: 'Santiago Del Estero',
        country: 'Argentina',
        iataCode: 'SDE',
        icaoCode: 'SANE',
        latitude: '-27,76555634',
        longitude: '-64,30999756',
        altitude: '656',
        timezone: '-3',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Tinogasta Airport',
        city: 'Tinogasta',
        country: 'Argentina',
        iataCode: 'N',
        icaoCode: 'SANI',
        latitude: '-28,03779984',
        longitude: '-67,58029938',
        altitude: '3968',
        timezone: '-3',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Capitan V A Almonacid Airport',
        city: 'La Rioja',
        country: 'Argentina',
        iataCode: 'IRJ',
        icaoCode: 'SANL',
        latitude: '-29,38159943',
        longitude: '-66,79579926',
        altitude: '1437',
        timezone: '-3',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Chilecito Airport',
        city: 'Chilecito',
        country: 'Argentina',
        iataCode: 'N',
        icaoCode: 'SANO',
        latitude: '-29,22389984',
        longitude: '-67,43890381',
        altitude: '3099',
        timezone: '-3',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Teniente Benjamin Matienzo Airport',
        city: 'Tucuman',
        country: 'Argentina',
        iataCode: 'TUC',
        icaoCode: 'SANT',
        latitude: '-26,8409',
        longitude: '-65,104897',
        altitude: '1493',
        timezone: '-3',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Domingo Faustino Sarmiento Airport',
        city: 'San Julian',
        country: 'Argentina',
        iataCode: 'UAQ',
        icaoCode: 'SANU',
        latitude: '-31,571501',
        longitude: '-68,418198',
        altitude: '1958',
        timezone: '-3',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Area De Material Airport',
        city: 'Rio Cuarto',
        country: 'Argentina',
        iataCode: 'RCU',
        icaoCode: 'SAOC',
        latitude: '-33,08509827',
        longitude: '-64,26129913',
        altitude: '1380',
        timezone: '-3',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Villa Dolores Airport',
        city: 'Villa Dolores',
        country: 'Argentina',
        iataCode: 'VDR',
        icaoCode: 'SAOD',
        latitude: '-31,94519997',
        longitude: '-65,14630127',
        altitude: '1847',
        timezone: '-3',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'La Quiaca Airport',
        city: 'Laboulaye',
        country: 'Argentina',
        iataCode: 'N',
        icaoCode: 'SAOL',
        latitude: '-22,15060043',
        longitude: '-65,57749939',
        altitude: '11414',
        timezone: '-3',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Marcos Juarez Airport',
        city: 'Marcos Juarez',
        country: 'Argentina',
        iataCode: 'N',
        icaoCode: 'SAOM',
        latitude: '-32,6836',
        longitude: '-62,1578',
        altitude: '360',
        timezone: '-3',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Villa Reynolds Airport',
        city: 'Villa Reynolds',
        country: 'Argentina',
        iataCode: 'N',
        icaoCode: 'SAOR',
        latitude: '-33,72990036',
        longitude: '-65,38739777',
        altitude: '1591',
        timezone: '-3',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Brigadier Mayor D Cesar Raul Ojeda Airport',
        city: 'San Luis',
        country: 'Argentina',
        iataCode: 'LUQ',
        icaoCode: 'SAOU',
        latitude: '-33,27320099',
        longitude: '-66,35639954',
        altitude: '2328',
        timezone: '-3',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Corrientes Airport',
        city: 'Corrientes',
        country: 'Argentina',
        iataCode: 'CNQ',
        icaoCode: 'SARC',
        latitude: '-27,4455',
        longitude: '-58,7619',
        altitude: '202',
        timezone: '-3',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Resistencia International Airport',
        city: 'Resistencia',
        country: 'Argentina',
        iataCode: 'RES',
        icaoCode: 'SARE',
        latitude: '-27,45',
        longitude: '-59,0561',
        altitude: '173',
        timezone: '-3',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Formosa Airport',
        city: 'Formosa',
        country: 'Argentina',
        iataCode: 'FMA',
        icaoCode: 'SARF',
        latitude: '-26,2127',
        longitude: '-58,2281',
        altitude: '193',
        timezone: '-3',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Cataratas Del Iguazú International Airport',
        city: 'Iguazu Falls',
        country: 'Argentina',
        iataCode: 'IGR',
        icaoCode: 'SARI',
        latitude: '-25,73730087',
        longitude: '-54,47340012',
        altitude: '916',
        timezone: '-3',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Paso De Los Libres Airport',
        city: 'Paso De Los Libres',
        country: 'Argentina',
        iataCode: 'AOL',
        icaoCode: 'SARL',
        latitude: '-29,6894',
        longitude: '-57,1521',
        altitude: '230',
        timezone: '-3',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Monte Caseros Airport',
        city: 'Monte Caseros',
        country: 'Argentina',
        iataCode: 'N',
        icaoCode: 'SARM',
        latitude: '-30,2719',
        longitude: '-57,6402',
        altitude: '170',
        timezone: '-3',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Libertador Gral D Jose De San Martin Airport',
        city: 'Posadas',
        country: 'Argentina',
        iataCode: 'PSS',
        icaoCode: 'SARP',
        latitude: '-27,3858',
        longitude: '-55,9707',
        altitude: '430',
        timezone: '-3',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Martin Miguel De Guemes International Airport',
        city: 'Salta',
        country: 'Argentina',
        iataCode: 'SLA',
        icaoCode: 'SASA',
        latitude: '-24,8560009',
        longitude: '-65,48619843',
        altitude: '4088',
        timezone: '-3',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Gobernador Horacio Guzman International Airport',
        city: 'Jujuy',
        country: 'Argentina',
        iataCode: 'JUJ',
        icaoCode: 'SASJ',
        latitude: '-24,39279938',
        longitude: '-65,09780121',
        altitude: '3019',
        timezone: '-3',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Orán Airport',
        city: 'Oran',
        country: 'Argentina',
        iataCode: 'ORA',
        icaoCode: 'SASO',
        latitude: '-23,15279961',
        longitude: '-64,32920074',
        altitude: '1171',
        timezone: '-3',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Laboulaye Airport',
        city: 'La Quiaca',
        country: 'Argentina',
        iataCode: 'N',
        icaoCode: 'SASQ',
        latitude: '-34,13539886',
        longitude: '-63,36230087',
        altitude: '449',
        timezone: '-3',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'El Dorado Airport',
        city: 'El Dorado',
        country: 'Argentina',
        iataCode: 'N',
        icaoCode: 'SATD',
        latitude: '-26,39749908',
        longitude: '-54,5746994',
        altitude: '685',
        timezone: '-3',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Goya Airport',
        city: 'Goya',
        country: 'Argentina',
        iataCode: 'N',
        icaoCode: 'SATG',
        latitude: '-29,1058',
        longitude: '-59,2189',
        altitude: '128',
        timezone: '-3',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Oberá Airport',
        city: 'Obera',
        country: 'Argentina',
        iataCode: 'N',
        icaoCode: 'SATO',
        latitude: '-27,51819992',
        longitude: '-55,12419891',
        altitude: '1125',
        timezone: '-3',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Reconquista Airport',
        city: 'Reconquista',
        country: 'Argentina',
        iataCode: 'N',
        icaoCode: 'SATR',
        latitude: '-29,2103',
        longitude: '-59,68',
        altitude: '160',
        timezone: '-3',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Curuzu Cuatia Airport',
        city: 'Curuzu Cuatia',
        country: 'Argentina',
        iataCode: 'N',
        icaoCode: 'SATU',
        latitude: '-29,7706',
        longitude: '-57,9789',
        altitude: '229',
        timezone: '-3',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'El Bolson Airport',
        city: 'El Bolson',
        country: 'Argentina',
        iataCode: 'EHL',
        icaoCode: 'SAVB',
        latitude: '-41,94319916',
        longitude: '-71,53230286',
        altitude: '1141',
        timezone: '-3',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'General E. Mosconi Airport',
        city: 'Comodoro Rivadavia',
        country: 'Argentina',
        iataCode: 'CRD',
        icaoCode: 'SAVC',
        latitude: '-45,7853',
        longitude: '-67,4655',
        altitude: '189',
        timezone: '-3',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Brigadier Antonio Parodi Airport',
        city: 'Esquel',
        country: 'Argentina',
        iataCode: 'EQS',
        icaoCode: 'SAVE',
        latitude: '-42,90800095',
        longitude: '-71,13950348',
        altitude: '2621',
        timezone: '-3',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Almirante Marco Andres Zar Airport',
        city: 'Trelew',
        country: 'Argentina',
        iataCode: 'REL',
        icaoCode: 'SAVT',
        latitude: '-43,2105',
        longitude: '-65,2703',
        altitude: '141',
        timezone: '-3',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Gobernador Castello Airport',
        city: 'Viedma',
        country: 'Argentina',
        iataCode: 'VDM',
        icaoCode: 'SAVV',
        latitude: '-40,8692',
        longitude: '-63,0004',
        altitude: '20',
        timezone: '-3',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'El Tehuelche Airport',
        city: 'Puerto Madryn',
        country: 'Argentina',
        iataCode: 'PMY',
        icaoCode: 'SAVY',
        latitude: '-42,7592',
        longitude: '-65,1027',
        altitude: '427',
        timezone: '-3',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Marambio Base',
        city: 'Marambio Base',
        country: 'Antarctica',
        iataCode: 'N',
        icaoCode: 'SAWB',
        latitude: '-64,23829651',
        longitude: '-56,63079834',
        altitude: '760',
        timezone: '12',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Puerto Deseado Airport',
        city: 'Puerto Deseado',
        country: 'Argentina',
        iataCode: 'PUD',
        icaoCode: 'SAWD',
        latitude: '-47,7353',
        longitude: '-65,9041',
        altitude: '268',
        timezone: '-3',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Hermes Quijada International Airport',
        city: 'Rio Grande',
        country: 'Argentina',
        iataCode: 'RGA',
        icaoCode: 'SAWE',
        latitude: '-53,7777',
        longitude: '-67,7494',
        altitude: '65',
        timezone: '-3',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Piloto Civil N. Fernández Airport',
        city: 'Rio Gallegos',
        country: 'Argentina',
        iataCode: 'RGL',
        icaoCode: 'SAWG',
        latitude: '-51,6089',
        longitude: '-69,3126',
        altitude: '61',
        timezone: '-3',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Malvinas Argentinas Airport',
        city: 'Ushuaia',
        country: 'Argentina',
        iataCode: 'USH',
        icaoCode: 'SAWH',
        latitude: '-54,8433',
        longitude: '-68,2958',
        altitude: '102',
        timezone: '-3',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Capitan D Daniel Vazquez Airport',
        city: 'San Julian',
        country: 'Argentina',
        iataCode: 'ULA',
        icaoCode: 'SAWJ',
        latitude: '-49,3068',
        longitude: '-67,8026',
        altitude: '203',
        timezone: '-3',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Perito Moreno Airport',
        city: 'Perito Moreno',
        country: 'Argentina',
        iataCode: 'PMQ',
        icaoCode: 'SAWP',
        latitude: '-46,53789902',
        longitude: '-70,97869873',
        altitude: '1410',
        timezone: '-3',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Santa Cruz Airport',
        city: 'Santa Cruz',
        country: 'Argentina',
        iataCode: 'RZA',
        icaoCode: 'SAWU',
        latitude: '-50,0165',
        longitude: '-68,5792',
        altitude: '364',
        timezone: '-3',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Comandante Espora Airport',
        city: 'Bahia Blanca',
        country: 'Argentina',
        iataCode: 'BHI',
        icaoCode: 'SAZB',
        latitude: '-38,725',
        longitude: '-62,1693',
        altitude: '246',
        timezone: '-3',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Brigadier D.H.E. Ruiz Airport',
        city: 'Colonel Suarez',
        country: 'Argentina',
        iataCode: 'N',
        icaoCode: 'SAZC',
        latitude: '-37,446098',
        longitude: '-61,889301',
        altitude: '767',
        timezone: '-3',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Olavarria Airport',
        city: 'Olavarria',
        country: 'Argentina',
        iataCode: 'N',
        icaoCode: 'SAZF',
        latitude: '-36,88999939',
        longitude: '-60,21659851',
        altitude: '551',
        timezone: '-3',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'General Pico Airport',
        city: 'General Pico',
        country: 'Argentina',
        iataCode: 'N',
        icaoCode: 'SAZG',
        latitude: '-35,69620132',
        longitude: '-63,75830078',
        altitude: '459',
        timezone: '-3',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Tres Arroyos Airport',
        city: 'Tres Arroyos',
        country: 'Argentina',
        iataCode: 'N',
        icaoCode: 'SAZH',
        latitude: '-38,3869',
        longitude: '-60,3297',
        altitude: '400',
        timezone: '-3',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Bolivar Airport',
        city: 'Bolivar',
        country: 'Argentina',
        iataCode: 'N',
        icaoCode: 'SAZI',
        latitude: '-36,1866',
        longitude: '-61,0764',
        altitude: '308',
        timezone: '-3',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Ástor Piazzola International Airport',
        city: 'Mar Del Plata',
        country: 'Argentina',
        iataCode: 'MDQ',
        icaoCode: 'SAZM',
        latitude: '-37,9342',
        longitude: '-57,5733',
        altitude: '72',
        timezone: '-3',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Presidente Peron Airport',
        city: 'Neuquen',
        country: 'Argentina',
        iataCode: 'NQN',
        icaoCode: 'SAZN',
        latitude: '-38,94900131',
        longitude: '-68,15570068',
        altitude: '895',
        timezone: '-3',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Comodoro Pedro Zanni Airport',
        city: 'Pehuajo',
        country: 'Argentina',
        iataCode: 'N',
        icaoCode: 'SAZP',
        latitude: '-35,8446',
        longitude: '-61,8576',
        altitude: '278',
        timezone: '-3',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Santa Rosa Airport',
        city: 'Santa Rosa',
        country: 'Argentina',
        iataCode: 'RSA',
        icaoCode: 'SAZR',
        latitude: '-36,588299',
        longitude: '-64,275703',
        altitude: '630',
        timezone: '-3',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'San Carlos De Bariloche Airport',
        city: 'San Carlos De Bariloche',
        country: 'Argentina',
        iataCode: 'BRC',
        icaoCode: 'SAZS',
        latitude: '-41,15119934',
        longitude: '-71,15750122',
        altitude: '2774',
        timezone: '-3',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Héroes De Malvinas Airport',
        city: 'Tandil',
        country: 'Argentina',
        iataCode: 'TDL',
        icaoCode: 'SAZT',
        latitude: '-37,23740005',
        longitude: '-59,22790146',
        altitude: '574',
        timezone: '-3',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Villa Gesell Airport',
        city: 'Villa Gesell',
        country: 'Argentina',
        iataCode: 'VLG',
        icaoCode: 'SAZV',
        latitude: '-37,2354',
        longitude: '-57,0292',
        altitude: '32',
        timezone: '-3',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Cutral-Co Airport',
        city: 'Cutralco',
        country: 'Argentina',
        iataCode: 'N',
        icaoCode: 'SAZW',
        latitude: '-38,93970108',
        longitude: '-69,26460266',
        altitude: '2132',
        timezone: '-3',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Aviador C. Campos Airport',
        city: 'San Martin Des Andes',
        country: 'Argentina',
        iataCode: 'CPC',
        icaoCode: 'SAZY',
        latitude: '-40,07540131',
        longitude: '-71,13729858',
        altitude: '2569',
        timezone: '-3',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Conceição do Araguaia Airport',
        city: 'Conceicao Do Araguaia',
        country: 'Brazil',
        iataCode: 'CDJ',
        icaoCode: 'SBAA',
        latitude: '-8,348349571',
        longitude: '-49,30149841',
        altitude: '653',
        timezone: '-3',
        dst: 'S',
        type: 'airport',
    },
    {
        name: 'Campo Délio Jardim de Mattos Airport',
        city: 'Rio De Janeiro',
        country: 'Brazil',
        iataCode: 'N',
        icaoCode: 'SBAF',
        latitude: '-22,875099',
        longitude: '-43,384701',
        altitude: '110',
        timezone: '-3',
        dst: 'S',
        type: 'airport',
    },
    {
        name: 'Amapá Airport',
        city: 'Amapa',
        country: 'Brazil',
        iataCode: 'N',
        icaoCode: 'SBAM',
        latitude: '2,07751',
        longitude: '-50,8582',
        altitude: '45',
        timezone: '-3',
        dst: 'S',
        type: 'airport',
    },
    {
        name: 'Araraquara Airport',
        city: 'Araracuara',
        country: 'Brazil',
        iataCode: 'AQA',
        icaoCode: 'SBAQ',
        latitude: '-21,81200027',
        longitude: '-48,13299942',
        altitude: '2334',
        timezone: '-3',
        dst: 'S',
        type: 'airport',
    },
    {
        name: 'Santa Maria Airport',
        city: 'Aracaju',
        country: 'Brazil',
        iataCode: 'AJU',
        icaoCode: 'SBAR',
        latitude: '-10,98400021',
        longitude: '-37,07030106',
        altitude: '23',
        timezone: '-3',
        dst: 'S',
        type: 'airport',
    },
    {
        name: 'Piloto Osvaldo Marques Dias Airport',
        city: 'Alta Floresta',
        country: 'Brazil',
        iataCode: 'AFL',
        icaoCode: 'SBAT',
        latitude: '-9,866389275',
        longitude: '-56,10499954',
        altitude: '948',
        timezone: '-4',
        dst: 'S',
        type: 'airport',
    },
    {
        name: 'Araçatuba Airport',
        city: 'Aracatuba',
        country: 'Brazil',
        iataCode: 'ARU',
        icaoCode: 'SBAU',
        latitude: '-21,1413002',
        longitude: '-50,42470169',
        altitude: '1361',
        timezone: '-3',
        dst: 'S',
        type: 'airport',
    },
    {
        name: 'Val de Cans/Júlio Cezar Ribeiro International Airport',
        city: 'Belem',
        country: 'Brazil',
        iataCode: 'BEL',
        icaoCode: 'SBBE',
        latitude: '-1,37925005',
        longitude: '-48,47629929',
        altitude: '54',
        timezone: '-3',
        dst: 'S',
        type: 'airport',
    },
    {
        name: 'Comandante Gustavo Kraemer Airport',
        city: 'Bage',
        country: 'Brazil',
        iataCode: 'BGX',
        icaoCode: 'SBBG',
        latitude: '-31,39049911',
        longitude: '-54,11220169',
        altitude: '600',
        timezone: '-3',
        dst: 'S',
        type: 'airport',
    },
    {
        name: 'Pampulha - Carlos Drummond de Andrade Airport',
        city: 'Belo Horizonte',
        country: 'Brazil',
        iataCode: 'PLU',
        icaoCode: 'SBBH',
        latitude: '-19,8512001',
        longitude: '-43,95059967',
        altitude: '2589',
        timezone: '-3',
        dst: 'S',
        type: 'airport',
    },
    {
        name: 'Bacacheri Airport',
        city: 'Curitiba',
        country: 'Brazil',
        iataCode: 'BFH',
        icaoCode: 'SBBI',
        latitude: '-25,40509987',
        longitude: '-49,23199844',
        altitude: '3057',
        timezone: '-3',
        dst: 'S',
        type: 'airport',
    },
    {
        name: 'Major Brigadeiro Doorgal Borges Airport',
        city: 'Barbacena',
        country: 'Brazil',
        iataCode: 'N',
        icaoCode: 'SBBQ',
        latitude: '-21,26720047',
        longitude: '-43,76110077',
        altitude: '3657',
        timezone: '-3',
        dst: 'S',
        type: 'airport',
    },
    {
        name: 'Presidente Juscelino Kubistschek International Airport',
        city: 'Brasilia',
        country: 'Brazil',
        iataCode: 'BSB',
        icaoCode: 'SBBR',
        latitude: '-15,86916733',
        longitude: '-47,92083359',
        altitude: '3497',
        timezone: '-3',
        dst: 'S',
        type: 'airport',
    },
    {
        name: 'Bauru Airport',
        city: 'Bauru',
        country: 'Brazil',
        iataCode: 'BAU',
        icaoCode: 'SBBU',
        latitude: '-22,34499931',
        longitude: '-49,05379868',
        altitude: '2025',
        timezone: '-3',
        dst: 'S',
        type: 'airport',
    },
    {
        name: 'Atlas Brasil Cantanhede Airport',
        city: 'Boa Vista',
        country: 'Brazil',
        iataCode: 'BVB',
        icaoCode: 'SBBV',
        latitude: '2,841388941',
        longitude: '-60,6922226',
        altitude: '276',
        timezone: '-4',
        dst: 'S',
        type: 'airport',
    },
    {
        name: 'Barra do Garças Airport',
        city: 'Barra Do Garcas',
        country: 'Brazil',
        iataCode: 'N',
        icaoCode: 'SBBW',
        latitude: '-15,86130047',
        longitude: '-52,38890076',
        altitude: '1147',
        timezone: '-4',
        dst: 'S',
        type: 'airport',
    },
    {
        name: 'Cascavel Airport',
        city: 'Cascavel',
        country: 'Brazil',
        iataCode: 'CAC',
        icaoCode: 'SBCA',
        latitude: '-25,00029945',
        longitude: '-53,50080109',
        altitude: '2473',
        timezone: '-3',
        dst: 'S',
        type: 'airport',
    },
    {
        name: 'Cachimbo Airport',
        city: 'Itaituba',
        country: 'Brazil',
        iataCode: 'N',
        icaoCode: 'SBCC',
        latitude: '-9,333939552',
        longitude: '-54,9654007',
        altitude: '1762',
        timezone: '-3',
        dst: 'S',
        type: 'airport',
    },
    {
        name: 'Tancredo Neves International Airport',
        city: 'Belo Horizonte',
        country: 'Brazil',
        iataCode: 'CNF',
        icaoCode: 'SBCF',
        latitude: '-19,62444305',
        longitude: '-43,9719429',
        altitude: '2715',
        timezone: '-3',
        dst: 'S',
        type: 'airport',
    },
    {
        name: 'Campo Grande Airport',
        city: 'Campo Grande',
        country: 'Brazil',
        iataCode: 'CGR',
        icaoCode: 'SBCG',
        latitude: '-20,46870041',
        longitude: '-54,67250061',
        altitude: '1833',
        timezone: '-4',
        dst: 'S',
        type: 'airport',
    },
    {
        name: 'Serafin Enoss Bertaso Airport',
        city: 'Chapeco',
        country: 'Brazil',
        iataCode: 'XAP',
        icaoCode: 'SBCH',
        latitude: '-27,13419914',
        longitude: '-52,65660095',
        altitude: '2146',
        timezone: '-3',
        dst: 'S',
        type: 'airport',
    },
    {
        name: 'Brig. Lysias Augusto Rodrigues Airport',
        city: 'Carolina',
        country: 'Brazil',
        iataCode: 'CLN',
        icaoCode: 'SBCI',
        latitude: '-7,320439816',
        longitude: '-47,45869827',
        altitude: '565',
        timezone: '-3',
        dst: 'S',
        type: 'airport',
    },
    {
        name: 'Diomício Freitas Airport',
        city: 'Criciuma',
        country: 'Brazil',
        iataCode: 'CCM',
        icaoCode: 'SBCM',
        latitude: '-28,72444344',
        longitude: '-49,42139053',
        altitude: '93',
        timezone: '-3',
        dst: 'S',
        type: 'airport',
    },
    {
        name: 'Canoas Air Force Base',
        city: 'Porto Alegre',
        country: 'Brazil',
        iataCode: 'N',
        icaoCode: 'SBCO',
        latitude: '-29,945928',
        longitude: '-51,144413',
        altitude: '26',
        timezone: '-3',
        dst: 'S',
        type: 'airport',
    },
    {
        name: 'Bartolomeu Lisandro Airport',
        city: 'Campos',
        country: 'Brazil',
        iataCode: 'CAW',
        icaoCode: 'SBCP',
        latitude: '-21,69829941',
        longitude: '-41,30170059',
        altitude: '57',
        timezone: '-3',
        dst: 'S',
        type: 'airport',
    },
    {
        name: 'Corumbá International Airport',
        city: 'Corumba',
        country: 'Brazil',
        iataCode: 'CMG',
        icaoCode: 'SBCR',
        latitude: '-19,01194382',
        longitude: '-57,67139053',
        altitude: '461',
        timezone: '-4',
        dst: 'S',
        type: 'airport',
    },
    {
        name: 'Afonso Pena Airport',
        city: 'Curitiba',
        country: 'Brazil',
        iataCode: 'CWB',
        icaoCode: 'SBCT',
        latitude: '-25,5284996',
        longitude: '-49,17580032',
        altitude: '2988',
        timezone: '-3',
        dst: 'S',
        type: 'airport',
    },
    {
        name: 'Caravelas Airport',
        city: 'Caravelas',
        country: 'Brazil',
        iataCode: 'CRQ',
        icaoCode: 'SBCV',
        latitude: '-17,65229988',
        longitude: '-39,25310135',
        altitude: '36',
        timezone: '-3',
        dst: 'S',
        type: 'airport',
    },
    {
        name: 'Hugo Cantergiani Regional Airport',
        city: 'Caxias Do Sul',
        country: 'Brazil',
        iataCode: 'CXJ',
        icaoCode: 'SBCX',
        latitude: '-29,19709969',
        longitude: '-51,1875',
        altitude: '2472',
        timezone: '-3',
        dst: 'S',
        type: 'airport',
    },
    {
        name: 'Marechal Rondon Airport',
        city: 'Cuiaba',
        country: 'Brazil',
        iataCode: 'CGB',
        icaoCode: 'SBCY',
        latitude: '-15,65289974',
        longitude: '-56,11669922',
        altitude: '617',
        timezone: '-4',
        dst: 'S',
        type: 'airport',
    },
    {
        name: 'Cruzeiro do Sul Airport',
        city: 'Cruzeiro do Sul',
        country: 'Brazil',
        iataCode: 'CZS',
        icaoCode: 'SBCZ',
        latitude: '-7,599909782',
        longitude: '-72,76950073',
        altitude: '637',
        timezone: '-5',
        dst: 'S',
        type: 'airport',
    },
    {
        name: 'Presidente Prudente Airport',
        city: 'President Prudente',
        country: 'Brazil',
        iataCode: 'PPB',
        icaoCode: 'SBDN',
        latitude: '-22,17510033',
        longitude: '-51,42459869',
        altitude: '1477',
        timezone: '-3',
        dst: 'S',
        type: 'airport',
    },
    {
        name: 'Eduardo Gomes International Airport',
        city: 'Manaus',
        country: 'Brazil',
        iataCode: 'MAO',
        icaoCode: 'SBEG',
        latitude: '-3,038609982',
        longitude: '-60,04970169',
        altitude: '264',
        timezone: '-4',
        dst: 'S',
        type: 'airport',
    },
    {
        name: 'Jacareacanga Airport',
        city: 'Jacare-acanga',
        country: 'Brazil',
        iataCode: 'N',
        icaoCode: 'SBEK',
        latitude: '-6,233160019',
        longitude: '-57,77690125',
        altitude: '323',
        timezone: '-3',
        dst: 'S',
        type: 'airport',
    },
    {
        name: 'São Pedro da Aldeia Airport',
        city: 'Sao Pedro Da Aldeia',
        country: 'Brazil',
        iataCode: 'N',
        icaoCode: 'SBES',
        latitude: '-22,81290054',
        longitude: '-42,09260178',
        altitude: '61',
        timezone: '-3',
        dst: 'S',
        type: 'airport',
    },
    {
        name: 'Cataratas International Airport',
        city: 'Foz Do Iguacu',
        country: 'Brazil',
        iataCode: 'IGU',
        icaoCode: 'SBFI',
        latitude: '-25,60027885',
        longitude: '-54,48500061',
        altitude: '786',
        timezone: '-3',
        dst: 'S',
        type: 'airport',
    },
    {
        name: 'Hercílio Luz International Airport',
        city: 'Florianopolis',
        country: 'Brazil',
        iataCode: 'FLN',
        icaoCode: 'SBFL',
        latitude: '-27,67027855',
        longitude: '-48,55250168',
        altitude: '16',
        timezone: '-3',
        dst: 'S',
        type: 'airport',
    },
    {
        name: 'Fernando de Noronha Airport',
        city: 'Fernando Do Noronha',
        country: 'Brazil',
        iataCode: 'FEN',
        icaoCode: 'SBFN',
        latitude: '-3,854929924',
        longitude: '-32,4233017',
        altitude: '193',
        timezone: '-3',
        dst: 'S',
        type: 'airport',
    },
    {
        name: 'Furnas Airport',
        city: 'Alpinopolis',
        country: 'Brazil',
        iataCode: 'N',
        icaoCode: 'SBFU',
        latitude: '-20,70280075',
        longitude: '-46,33530045',
        altitude: '2413',
        timezone: '-3',
        dst: 'S',
        type: 'airport',
    },
    {
        name: 'Pinto Martins International Airport',
        city: 'Fortaleza',
        country: 'Brazil',
        iataCode: 'FOR',
        icaoCode: 'SBFZ',
        latitude: '-3,776279926',
        longitude: '-38,5326004',
        altitude: '82',
        timezone: '-3',
        dst: 'S',
        type: 'airport',
    },
    {
        name: 'Rio Galeão – Tom Jobim International Airport',
        city: 'Rio De Janeiro',
        country: 'Brazil',
        iataCode: 'GIG',
        icaoCode: 'SBGL',
        latitude: '-22,80999947',
        longitude: '-43,25055695',
        altitude: '28',
        timezone: '-3',
        dst: 'S',
        type: 'airport',
    },
    {
        name: 'Guajará-Mirim Airport',
        city: 'Guajara-mirim',
        country: 'Brazil',
        iataCode: 'N',
        icaoCode: 'SBGM',
        latitude: '-10,78639984',
        longitude: '-65,28479767',
        altitude: '478',
        timezone: '-4',
        dst: 'S',
        type: 'airport',
    },
    {
        name: 'Santa Genoveva Airport',
        city: 'Goiania',
        country: 'Brazil',
        iataCode: 'GYN',
        icaoCode: 'SBGO',
        latitude: '-16,63199997',
        longitude: '-49,22069931',
        altitude: '2450',
        timezone: '-3',
        dst: 'S',
        type: 'airport',
    },
    {
        name: 'EMBRAER - Unidade Gavião Peixoto Airport',
        city: 'Macae',
        country: 'Brazil',
        iataCode: 'N',
        icaoCode: 'SBGP',
        latitude: '-21,77370071',
        longitude: '-48,40510178',
        altitude: '1998',
        timezone: '-3',
        dst: 'S',
        type: 'airport',
    },
    {
        name:
            'Guarulhos - Governador André Franco Montoro International Airport',
        city: 'Sao Paulo',
        country: 'Brazil',
        iataCode: 'GRU',
        icaoCode: 'SBGR',
        latitude: '-23,43555641',
        longitude: '-46,47305679',
        altitude: '2459',
        timezone: '-3',
        dst: 'S',
        type: 'airport',
    },
    {
        name: 'Guaratinguetá Airport',
        city: 'Guaratingueta',
        country: 'Brazil',
        iataCode: 'N',
        icaoCode: 'SBGW',
        latitude: '-22,79159927',
        longitude: '-45,20479965',
        altitude: '1761',
        timezone: '-3',
        dst: 'S',
        type: 'airport',
    },
    {
        name: 'Altamira Airport',
        city: 'Altamira',
        country: 'Brazil',
        iataCode: 'ATM',
        icaoCode: 'SBHT',
        latitude: '-3,253910065',
        longitude: '-52,25400162',
        altitude: '369',
        timezone: '-3',
        dst: 'S',
        type: 'airport',
    },
    {
        name: 'Itacoatiara Airport',
        city: 'Itaituba',
        country: 'Brazil',
        iataCode: 'N',
        icaoCode: 'SBIC',
        latitude: '-3,12725997',
        longitude: '-58,48120117',
        altitude: '142',
        timezone: '-4',
        dst: 'S',
        type: 'airport',
    },
    {
        name: 'Itaituba Airport',
        city: 'Itaituba',
        country: 'Brazil',
        iataCode: 'ITB',
        icaoCode: 'SBIH',
        latitude: '-4,242340088',
        longitude: '-56,0007019',
        altitude: '110',
        timezone: '-3',
        dst: 'S',
        type: 'airport',
    },
    {
        name: 'Bahia - Jorge Amado Airport',
        city: 'Ilheus',
        country: 'Brazil',
        iataCode: 'IOS',
        icaoCode: 'SBIL',
        latitude: '-14,81599998',
        longitude: '-39,03319931',
        altitude: '15',
        timezone: '-3',
        dst: 'S',
        type: 'airport',
    },
    {
        name: 'Usiminas Airport',
        city: 'Ipatinga',
        country: 'Brazil',
        iataCode: 'IPN',
        icaoCode: 'SBIP',
        latitude: '-19,47069931',
        longitude: '-42,48759842',
        altitude: '784',
        timezone: '-3',
        dst: 'S',
        type: 'airport',
    },
    {
        name: 'Francisco Vilela do Amaral Airport',
        city: 'Itumbiara',
        country: 'Brazil',
        iataCode: 'N',
        icaoCode: 'SBIT',
        latitude: '-18,44470024',
        longitude: '-49,21340179',
        altitude: '1630',
        timezone: '-3',
        dst: 'S',
        type: 'airport',
    },
    {
        name: 'Prefeito Renato Moreira Airport',
        city: 'Imperatriz',
        country: 'Brazil',
        iataCode: 'IMP',
        icaoCode: 'SBIZ',
        latitude: '-5,531290054',
        longitude: '-47,45999908',
        altitude: '432',
        timezone: '-3',
        dst: 'S',
        type: 'airport',
    },
    {
        name: 'Belém/Brigadeiro Protásio de Oliveira Airport',
        city: 'Belem',
        country: 'Brazil',
        iataCode: 'N',
        icaoCode: 'SBJC',
        latitude: '-1,414160013',
        longitude: '-48,46070099',
        altitude: '52',
        timezone: '-3',
        dst: 'S',
        type: 'airport',
    },
    {
        name: 'Francisco de Assis Airport',
        city: 'Juiz De Fora',
        country: 'Brazil',
        iataCode: 'JDF',
        icaoCode: 'SBJF',
        latitude: '-21,79150009',
        longitude: '-43,38679886',
        altitude: '2989',
        timezone: '-3',
        dst: 'S',
        type: 'airport',
    },
    {
        name: 'Presidente Castro Pinto International Airport',
        city: 'Joao Pessoa',
        country: 'Brazil',
        iataCode: 'JPA',
        icaoCode: 'SBJP',
        latitude: '-7,145833015',
        longitude: '-34,94861221',
        altitude: '217',
        timezone: '-3',
        dst: 'S',
        type: 'airport',
    },
    {
        name: 'Lauro Carneiro de Loyola Airport',
        city: 'Joinville',
        country: 'Brazil',
        iataCode: 'JOI',
        icaoCode: 'SBJV',
        latitude: '-26,22450066',
        longitude: '-48,79740143',
        altitude: '15',
        timezone: '-3',
        dst: 'S',
        type: 'airport',
    },
    {
        name: 'Presidente João Suassuna Airport',
        city: 'Campina Grande',
        country: 'Brazil',
        iataCode: 'CPV',
        icaoCode: 'SBKG',
        latitude: '-7,269919872',
        longitude: '-35,89640045',
        altitude: '1646',
        timezone: '-3',
        dst: 'S',
        type: 'airport',
    },
    {
        name: 'Viracopos International Airport',
        city: 'Campinas',
        country: 'Brazil',
        iataCode: 'VCP',
        icaoCode: 'SBKP',
        latitude: '-23,00740051',
        longitude: '-47,1344986',
        altitude: '2170',
        timezone: '-3',
        dst: 'S',
        type: 'airport',
    },
    {
        name: 'Lages Airport',
        city: 'Lajes',
        country: 'Brazil',
        iataCode: 'N',
        icaoCode: 'SBLJ',
        latitude: '-27,78210068',
        longitude: '-50,28150177',
        altitude: '3065',
        timezone: '-3',
        dst: 'S',
        type: 'airport',
    },
    {
        name: 'Lins Airport',
        city: 'Lins',
        country: 'Brazil',
        iataCode: 'LIP',
        icaoCode: 'SBLN',
        latitude: '-21,66399956',
        longitude: '-49,73049927',
        altitude: '1559',
        timezone: '-3',
        dst: 'S',
        type: 'airport',
    },
    {
        name: 'Governador José Richa Airport',
        city: 'Londrina',
        country: 'Brazil',
        iataCode: 'LDB',
        icaoCode: 'SBLO',
        latitude: '-23,33359909',
        longitude: '-51,13010025',
        altitude: '1867',
        timezone: '-3',
        dst: 'S',
        type: 'airport',
    },
    {
        name: 'Bom Jesus da Lapa Airport',
        city: 'Bom Jesus Da Lapa',
        country: 'Brazil',
        iataCode: 'LAZ',
        icaoCode: 'SBLP',
        latitude: '-13,26210022',
        longitude: '-43,40810013',
        altitude: '1454',
        timezone: '-3',
        dst: 'S',
        type: 'airport',
    },
    {
        name: 'Lagoa Santa Airport',
        city: 'Lagoa Santa',
        country: 'Brazil',
        iataCode: 'N',
        icaoCode: 'SBLS',
        latitude: '-19,66160011',
        longitude: '-43,89640045',
        altitude: '2795',
        timezone: '-3',
        dst: 'S',
        type: 'airport',
    },
    {
        name: 'João Correa da Rocha Airport',
        city: 'Maraba',
        country: 'Brazil',
        iataCode: 'MAB',
        icaoCode: 'SBMA',
        latitude: '-5,368589878',
        longitude: '-49,13800049',
        altitude: '357',
        timezone: '-3',
        dst: 'S',
        type: 'airport',
    },
    {
        name: 'Monte Dourado Airport',
        city: 'Almeirim',
        country: 'Brazil',
        iataCode: 'N',
        icaoCode: 'SBMD',
        latitude: '-0,889838994',
        longitude: '-52,60219955',
        altitude: '677',
        timezone: '-3',
        dst: 'S',
        type: 'airport',
    },
    {
        name: 'Regional de Maringá - Sílvio Nane Junior Airport',
        city: 'Maringa',
        country: 'Brazil',
        iataCode: 'MGF',
        icaoCode: 'SBMG',
        latitude: '-23,4794445',
        longitude: '-52,01222229',
        altitude: '1788',
        timezone: '-3',
        dst: 'S',
        type: 'airport',
    },
    {
        name: 'Mário Ribeiro Airport',
        city: 'Montes Claros',
        country: 'Brazil',
        iataCode: 'MOC',
        icaoCode: 'SBMK',
        latitude: '-16,70689964',
        longitude: '-43,81890106',
        altitude: '2191',
        timezone: '-3',
        dst: 'S',
        type: 'airport',
    },
    {
        name: 'Ponta Pelada Airport',
        city: 'Manaus',
        country: 'Brazil',
        iataCode: 'PLL',
        icaoCode: 'SBMN',
        latitude: '-3,146039963',
        longitude: '-59,98630142',
        altitude: '267',
        timezone: '-4',
        dst: 'S',
        type: 'airport',
    },
    {
        name: 'Zumbi dos Palmares Airport',
        city: 'Maceio',
        country: 'Brazil',
        iataCode: 'MCZ',
        icaoCode: 'SBMO',
        latitude: '-9,510809898',
        longitude: '-35,79169846',
        altitude: '387',
        timezone: '-3',
        dst: 'S',
        type: 'airport',
    },
    {
        name: 'Alberto Alcolumbre Airport',
        city: 'Macapa',
        country: 'Brazil',
        iataCode: 'MCP',
        icaoCode: 'SBMQ',
        latitude: '0,050664',
        longitude: '-51,07220078',
        altitude: '56',
        timezone: '-3',
        dst: 'S',
        type: 'airport',
    },
    {
        name: 'Dix-Sept Rosado Airport',
        city: 'Mocord',
        country: 'Brazil',
        iataCode: 'MVF',
        icaoCode: 'SBMS',
        latitude: '-5,201920032',
        longitude: '-37,36429977',
        altitude: '76',
        timezone: '-3',
        dst: 'S',
        type: 'airport',
    },
    {
        name: 'Campo de Marte Airport',
        city: 'Sao Paulo',
        country: 'Brazil',
        iataCode: 'N',
        icaoCode: 'SBMT',
        latitude: '-23,50909996',
        longitude: '-46,63779831',
        altitude: '2368',
        timezone: '-3',
        dst: 'S',
        type: 'airport',
    },
    {
        name: 'Manicoré Airport',
        city: 'Manicore',
        country: 'Brazil',
        iataCode: 'MNX',
        icaoCode: 'SBMY',
        latitude: '-5,81137991',
        longitude: '-61,27830124',
        altitude: '174',
        timezone: '-4',
        dst: 'S',
        type: 'airport',
    },
    {
        name: 'Ministro Victor Konder International Airport',
        city: 'Navegantes',
        country: 'Brazil',
        iataCode: 'NVT',
        icaoCode: 'SBNF',
        latitude: '-26,87999916',
        longitude: '-48,65140152',
        altitude: '18',
        timezone: '-3',
        dst: 'S',
        type: 'airport',
    },
    {
        name: 'Santo Ângelo Airport',
        city: 'Santo Angelo',
        country: 'Brazil',
        iataCode: 'GEL',
        icaoCode: 'SBNM',
        latitude: '-28,28170013',
        longitude: '-54,16910172',
        altitude: '1056',
        timezone: '-3',
        dst: 'S',
        type: 'airport',
    },
    {
        name: 'Governador Aluízio Alves International Airport',
        city: 'Natal',
        country: 'Brazil',
        iataCode: 'NAT',
        icaoCode: 'SBSG',
        latitude: '-5,768056',
        longitude: '-35,376111',
        altitude: '272',
        timezone: '-3',
        dst: 'S',
        type: 'airport',
    },
    {
        name: 'Oiapoque Airport',
        city: 'Oioiapoque',
        country: 'Brazil',
        iataCode: 'N',
        icaoCode: 'SBOI',
        latitude: '3,855489969',
        longitude: '-51,7969017',
        altitude: '63',
        timezone: '-3',
        dst: 'S',
        type: 'airport',
    },
    {
        name: 'Salgado Filho Airport',
        city: 'Porto Alegre',
        country: 'Brazil',
        iataCode: 'POA',
        icaoCode: 'SBPA',
        latitude: '-29,99440002',
        longitude: '-51,17139816',
        altitude: '11',
        timezone: '-3',
        dst: 'S',
        type: 'airport',
    },
    {
        name: 'Prefeito Doutor João Silva Filho Airport',
        city: 'Parnaiba',
        country: 'Brazil',
        iataCode: 'N',
        icaoCode: 'SBPB',
        latitude: '-2,893749952',
        longitude: '-41,73199844',
        altitude: '16',
        timezone: '-3',
        dst: 'S',
        type: 'airport',
    },
    {
        name: 'Poços de Caldas - Embaixador Walther Moreira Salles Airport',
        city: 'Pocos De Caldas',
        country: 'Brazil',
        iataCode: 'POO',
        icaoCode: 'SBPC',
        latitude: '-21,84300041',
        longitude: '-46,56790161',
        altitude: '4135',
        timezone: '-3',
        dst: 'S',
        type: 'airport',
    },
    {
        name: 'Lauro Kurtz Airport',
        city: 'Passo Fundo',
        country: 'Brazil',
        iataCode: 'PFB',
        icaoCode: 'SBPF',
        latitude: '-28,24399948',
        longitude: '-52,32659912',
        altitude: '2376',
        timezone: '-3',
        dst: 'S',
        type: 'airport',
    },
    {
        name: 'João Simões Lopes Neto International Airport',
        city: 'Pelotas',
        country: 'Brazil',
        iataCode: 'PET',
        icaoCode: 'SBPK',
        latitude: '-31,71839905',
        longitude: '-52,32770157',
        altitude: '59',
        timezone: '-3',
        dst: 'S',
        type: 'airport',
    },
    {
        name: 'Senador Nilo Coelho Airport',
        city: 'Petrolina',
        country: 'Brazil',
        iataCode: 'PNZ',
        icaoCode: 'SBPL',
        latitude: '-9,362409592',
        longitude: '-40,56909943',
        altitude: '1263',
        timezone: '-3',
        dst: 'S',
        type: 'airport',
    },
    {
        name: 'Porto Nacional Airport',
        city: 'Porto Nacional',
        country: 'Brazil',
        iataCode: 'PNB',
        icaoCode: 'SBPN',
        latitude: '-10,71940041',
        longitude: '-48,39970016',
        altitude: '870',
        timezone: '-3',
        dst: 'S',
        type: 'airport',
    },
    {
        name: 'Ponta Porã Airport',
        city: 'Ponta Pora',
        country: 'Brazil',
        iataCode: 'PMG',
        icaoCode: 'SBPP',
        latitude: '-22,5496006',
        longitude: '-55,70259857',
        altitude: '2156',
        timezone: '-4',
        dst: 'S',
        type: 'airport',
    },
    {
        name: 'Governador Jorge Teixeira de Oliveira Airport',
        city: 'Porto Velho',
        country: 'Brazil',
        iataCode: 'PVH',
        icaoCode: 'SBPV',
        latitude: '-8,709289551',
        longitude: '-63,90230179',
        altitude: '290',
        timezone: '-4',
        dst: 'S',
        type: 'airport',
    },
    {
        name: 'Plácido de Castro Airport',
        city: 'Rio Branco',
        country: 'Brazil',
        iataCode: 'RBR',
        icaoCode: 'SBRB',
        latitude: '-9,868888855',
        longitude: '-67,89805603',
        altitude: '633',
        timezone: '-5',
        dst: 'S',
        type: 'airport',
    },
    {
        name: 'Guararapes - Gilberto Freyre International Airport',
        city: 'Recife',
        country: 'Brazil',
        iataCode: 'REC',
        icaoCode: 'SBRF',
        latitude: '-8,126489639',
        longitude: '-34,92359924',
        altitude: '33',
        timezone: '-3',
        dst: 'S',
        type: 'airport',
    },
    {
        name: 'Santos Dumont Airport',
        city: 'Rio De Janeiro',
        country: 'Brazil',
        iataCode: 'SDU',
        icaoCode: 'SBRJ',
        latitude: '-22,91049957',
        longitude: '-43,1631012',
        altitude: '11',
        timezone: '-3',
        dst: 'S',
        type: 'airport',
    },
    {
        name: 'Leite Lopes Airport',
        city: 'Ribeirao Preto',
        country: 'Brazil',
        iataCode: 'RAO',
        icaoCode: 'SBRP',
        latitude: '-21,13638878',
        longitude: '-47,77666855',
        altitude: '1806',
        timezone: '-3',
        dst: 'S',
        type: 'airport',
    },
    {
        name: 'Santa Cruz Airport',
        city: 'Rio De Janeiro',
        country: 'Brazil',
        iataCode: 'STU',
        icaoCode: 'SBSC',
        latitude: '-22,93239975',
        longitude: '-43,71910095',
        altitude: '10',
        timezone: '-3',
        dst: 'S',
        type: 'airport',
    },
    {
        name: 'Professor Urbano Ernesto Stumpf Airport',
        city: 'Sao Jose Dos Campos',
        country: 'Brazil',
        iataCode: 'SJK',
        icaoCode: 'SBSJ',
        latitude: '-23,22920036',
        longitude: '-45,86149979',
        altitude: '2120',
        timezone: '-3',
        dst: 'S',
        type: 'airport',
    },
    {
        name: 'Marechal Cunha Machado International Airport',
        city: 'Sao Luis',
        country: 'Brazil',
        iataCode: 'SLZ',
        icaoCode: 'SBSL',
        latitude: '-2,58536005',
        longitude: '-44,23410034',
        altitude: '178',
        timezone: '-3',
        dst: 'S',
        type: 'airport',
    },
    {
        name: 'Congonhas Airport',
        city: 'Sao Paulo',
        country: 'Brazil',
        iataCode: 'CGH',
        icaoCode: 'SBSP',
        latitude: '-23,62611008',
        longitude: '-46,65638733',
        altitude: '2631',
        timezone: '-3',
        dst: 'S',
        type: 'airport',
    },
    {
        name: 'Prof. Eribelto Manoel Reino State Airport',
        city: 'Sao Jose Do Rio Preto',
        country: 'Brazil',
        iataCode: 'SJP',
        icaoCode: 'SBSR',
        latitude: '-20,8166008',
        longitude: '-49,40650177',
        altitude: '1784',
        timezone: '-3',
        dst: 'S',
        type: 'airport',
    },
    {
        name: 'Base Aérea de Santos Airport',
        city: 'Santos',
        country: 'Brazil',
        iataCode: 'SSZ',
        icaoCode: 'SBST',
        latitude: '-23,92805672',
        longitude: '-46,29972076',
        altitude: '10',
        timezone: '-3',
        dst: 'S',
        type: 'airport',
    },
    {
        name: 'Deputado Luiz Eduardo Magalhães International Airport',
        city: 'Salvador',
        country: 'Brazil',
        iataCode: 'SSA',
        icaoCode: 'SBSV',
        latitude: '-12,9086113',
        longitude: '-38,32249832',
        altitude: '64',
        timezone: '-3',
        dst: 'S',
        type: 'airport',
    },
    {
        name: 'Trombetas Airport',
        city: 'Oriximina',
        country: 'Brazil',
        iataCode: 'TMT',
        icaoCode: 'SBTB',
        latitude: '-1,489599943',
        longitude: '-56,39680099',
        altitude: '287',
        timezone: '-3',
        dst: 'S',
        type: 'airport',
    },
    {
        name: 'Senador Petrônio Portela Airport',
        city: 'Teresina',
        country: 'Brazil',
        iataCode: 'THE',
        icaoCode: 'SBTE',
        latitude: '-5,059939861',
        longitude: '-42,82350159',
        altitude: '219',
        timezone: '-3',
        dst: 'S',
        type: 'airport',
    },
    {
        name: 'Tefé Airport',
        city: 'Tefe',
        country: 'Brazil',
        iataCode: 'TFF',
        icaoCode: 'SBTF',
        latitude: '-3,382940054',
        longitude: '-64,72409821',
        altitude: '188',
        timezone: '-4',
        dst: 'S',
        type: 'airport',
    },
    {
        name: 'Tarauacá Airport',
        city: 'Tarauaca',
        country: 'Brazil',
        iataCode: 'N',
        icaoCode: 'SBTK',
        latitude: '-8,155260086',
        longitude: '-70,78330231',
        altitude: '646',
        timezone: '-5',
        dst: 'S',
        type: 'airport',
    },
    {
        name: 'Telêmaco Borba Airport',
        city: 'Telemaco Borba',
        country: 'Brazil',
        iataCode: 'N',
        icaoCode: 'SBTL',
        latitude: '-24,31780052',
        longitude: '-50,65159988',
        altitude: '2610',
        timezone: '-3',
        dst: 'S',
        type: 'airport',
    },
    {
        name: 'Tiriós Airport',
        city: 'Obidos Tirios',
        country: 'Brazil',
        iataCode: 'N',
        icaoCode: 'SBTS',
        latitude: '2,223469973',
        longitude: '-55,94609833',
        altitude: '1127',
        timezone: '-3',
        dst: 'S',
        type: 'airport',
    },
    {
        name: 'Tabatinga Airport',
        city: 'Tabatinga',
        country: 'Brazil',
        iataCode: 'TBT',
        icaoCode: 'SBTT',
        latitude: '-4,255670071',
        longitude: '-69,93579865',
        altitude: '279',
        timezone: '-4',
        dst: 'S',
        type: 'airport',
    },
    {
        name: 'Tucuruí Airport',
        city: 'Tucurui',
        country: 'Brazil',
        iataCode: 'TUR',
        icaoCode: 'SBTU',
        latitude: '-3,786010027',
        longitude: '-49,72029877',
        altitude: '830',
        timezone: '-3',
        dst: 'S',
        type: 'airport',
    },
    {
        name: 'São Gabriel da Cachoeira Airport',
        city: 'Sao Gabriel',
        country: 'Brazil',
        iataCode: 'SJL',
        icaoCode: 'SBUA',
        latitude: '-0,14835',
        longitude: '-66,9855',
        altitude: '251',
        timezone: '-4',
        dst: 'S',
        type: 'airport',
    },
    {
        name: 'Paulo Afonso Airport',
        city: 'Paulo Alfonso',
        country: 'Brazil',
        iataCode: 'PAV',
        icaoCode: 'SBUF',
        latitude: '-9,40087986',
        longitude: '-38,25059891',
        altitude: '883',
        timezone: '-3',
        dst: 'S',
        type: 'airport',
    },
    {
        name: 'Rubem Berta Airport',
        city: 'Uruguaiana',
        country: 'Brazil',
        iataCode: 'URG',
        icaoCode: 'SBUG',
        latitude: '-29,78219986',
        longitude: '-57,03820038',
        altitude: '256',
        timezone: '-3',
        dst: 'S',
        type: 'airport',
    },
    {
        name: 'Ten. Cel. Aviador César Bombonato Airport',
        city: 'Uberlandia',
        country: 'Brazil',
        iataCode: 'UDI',
        icaoCode: 'SBUL',
        latitude: '-18,88361168',
        longitude: '-48,22527695',
        altitude: '3094',
        timezone: '-3',
        dst: 'S',
        type: 'airport',
    },
    {
        name: 'Mário de Almeida Franco Airport',
        city: 'Uberaba',
        country: 'Brazil',
        iataCode: 'UBA',
        icaoCode: 'SBUR',
        latitude: '-19,76472282',
        longitude: '-47,96611023',
        altitude: '2655',
        timezone: '-3',
        dst: 'S',
        type: 'airport',
    },
    {
        name: 'Major Brigadeiro Trompowsky Airport',
        city: 'Varginha',
        country: 'Brazil',
        iataCode: 'VAG',
        icaoCode: 'SBVG',
        latitude: '-21,59009933',
        longitude: '-45,47330093',
        altitude: '3025',
        timezone: '-3',
        dst: 'S',
        type: 'airport',
    },
    {
        name: 'Brigadeiro Camarão Airport',
        city: 'Vilhena',
        country: 'Brazil',
        iataCode: 'BVH',
        icaoCode: 'SBVH',
        latitude: '-12,69439983',
        longitude: '-60,09830093',
        altitude: '2018',
        timezone: '-4',
        dst: 'S',
        type: 'airport',
    },
    {
        name: 'Eurico de Aguiar Salles Airport',
        city: 'Vitoria',
        country: 'Brazil',
        iataCode: 'VIX',
        icaoCode: 'SBVT',
        latitude: '-20,25805664',
        longitude: '-40,2863884',
        altitude: '11',
        timezone: '-3',
        dst: 'S',
        type: 'airport',
    },
    {
        name: 'Iauaretê Airport',
        city: 'Iauarete',
        country: 'Brazil',
        iataCode: 'N',
        icaoCode: 'SBYA',
        latitude: '0,607500017',
        longitude: '-69,18579865',
        altitude: '345',
        timezone: '-4',
        dst: 'S',
        type: 'airport',
    },
    {
        name: 'Campo Fontenelle Airport',
        city: 'Piracununga',
        country: 'Brazil',
        iataCode: 'QPS',
        icaoCode: 'SBYS',
        latitude: '-21,98460007',
        longitude: '-47,33480072',
        altitude: '1968',
        timezone: '-3',
        dst: 'S',
        type: 'airport',
    },
    {
        name: 'Chacalluta Airport',
        city: 'Arica',
        country: 'Chile',
        iataCode: 'ARI',
        icaoCode: 'SCAR',
        latitude: '-18,3484993',
        longitude: '-70,33869934',
        altitude: '167',
        timezone: '-4',
        dst: 'S',
        type: 'airport',
    },
    {
        name: 'Balmaceda Airport',
        city: 'Balmaceda',
        country: 'Chile',
        iataCode: 'BBA',
        icaoCode: 'SCBA',
        latitude: '-45,91609955',
        longitude: '-71,6894989',
        altitude: '1722',
        timezone: '-4',
        dst: 'S',
        type: 'airport',
    },
    {
        name: 'El Bosque Airport',
        city: 'Santiago',
        country: 'Chile',
        iataCode: 'N',
        icaoCode: 'SCBQ',
        latitude: '-33,5617981',
        longitude: '-70,68840027',
        altitude: '1844',
        timezone: '-4',
        dst: 'S',
        type: 'airport',
    },
    {
        name: 'Chile Chico Airport',
        city: 'Chile Chico',
        country: 'Chile',
        iataCode: 'CCH',
        icaoCode: 'SCCC',
        latitude: '-46,58330154',
        longitude: '-71,68740082',
        altitude: '1070',
        timezone: '-4',
        dst: 'S',
        type: 'airport',
    },
    {
        name: 'El Loa Airport',
        city: 'Calama',
        country: 'Chile',
        iataCode: 'CJC',
        icaoCode: 'SCCF',
        latitude: '-22,49819946',
        longitude: '-68,9036026',
        altitude: '7543',
        timezone: '-4',
        dst: 'S',
        type: 'airport',
    },
    {
        name: 'Gral. Bernardo O´Higgins Airport',
        city: 'Chillan',
        country: 'Chile',
        iataCode: 'N',
        icaoCode: 'SCCH',
        latitude: '-36,58250046',
        longitude: '-72,03140259',
        altitude: '495',
        timezone: '-4',
        dst: 'S',
        type: 'airport',
    },
    {
        name: 'Pdte. carlos Ibañez del Campo Airport',
        city: 'Punta Arenas',
        country: 'Chile',
        iataCode: 'PUQ',
        icaoCode: 'SCCI',
        latitude: '-53,00260162',
        longitude: '-70,854599',
        altitude: '139',
        timezone: '-4',
        dst: 'S',
        type: 'airport',
    },
    {
        name: 'Teniente Vidal Airport',
        city: 'Coyhaique',
        country: 'Chile',
        iataCode: 'GXQ',
        icaoCode: 'SCCY',
        latitude: '-45,59420013',
        longitude: '-72,10610199',
        altitude: '1020',
        timezone: '-4',
        dst: 'S',
        type: 'airport',
    },
    {
        name: 'Diego Aracena Airport',
        city: 'Iquique',
        country: 'Chile',
        iataCode: 'IQQ',
        icaoCode: 'SCDA',
        latitude: '-20,53520012',
        longitude: '-70,1812973',
        altitude: '155',
        timezone: '-4',
        dst: 'S',
        type: 'airport',
    },
    {
        name: 'Comodoro Arturo Merino Benítez International Airport',
        city: 'Santiago',
        country: 'Chile',
        iataCode: 'SCL',
        icaoCode: 'SCEL',
        latitude: '-33,39300156',
        longitude: '-70,78579712',
        altitude: '1555',
        timezone: '-4',
        dst: 'S',
        type: 'airport',
    },
    {
        name: 'Cerro Moreno Airport',
        city: 'Antofagasta',
        country: 'Chile',
        iataCode: 'ANF',
        icaoCode: 'SCFA',
        latitude: '-23,44449997',
        longitude: '-70,44509888',
        altitude: '455',
        timezone: '-4',
        dst: 'S',
        type: 'airport',
    },
    {
        name: 'Capitan Fuentes Martinez Airport Airport',
        city: 'Porvenir',
        country: 'Chile',
        iataCode: 'WPR',
        icaoCode: 'SCFM',
        latitude: '-53,25370026',
        longitude: '-70,31919861',
        altitude: '104',
        timezone: '-4',
        dst: 'S',
        type: 'airport',
    },
    {
        name: 'Futaleufú Airport',
        city: 'Futaleufu',
        country: 'Chile',
        iataCode: 'N',
        icaoCode: 'SCFT',
        latitude: '-43,18920135',
        longitude: '-71,85109711',
        altitude: '1148',
        timezone: '-4',
        dst: 'S',
        type: 'airport',
    },
    {
        name: 'María Dolores Airport',
        city: 'Los Angeles',
        country: 'Chile',
        iataCode: 'LSQ',
        icaoCode: 'SCGE',
        latitude: '-37,40169907',
        longitude: '-72,42539978',
        altitude: '374',
        timezone: '-4',
        dst: 'S',
        type: 'airport',
    },
    {
        name: 'Guardiamarina Zañartu Airport',
        city: 'Puerto Williams',
        country: 'Chile',
        iataCode: 'WPU',
        icaoCode: 'SCGZ',
        latitude: '-54,93109894',
        longitude: '-67,626297',
        altitude: '88',
        timezone: '-4',
        dst: 'S',
        type: 'airport',
    },
    {
        name: 'Carriel Sur Airport',
        city: 'Concepcion',
        country: 'Chile',
        iataCode: 'CCP',
        icaoCode: 'SCIE',
        latitude: '-36,77270126',
        longitude: '-73,06310272',
        altitude: '26',
        timezone: '-4',
        dst: 'S',
        type: 'airport',
    },
    {
        name: 'Mataveri Airport',
        city: 'Easter Island',
        country: 'Chile',
        iataCode: 'IPC',
        icaoCode: 'SCIP',
        latitude: '-27,16480064',
        longitude: '-109,4219971',
        altitude: '227',
        timezone: '-6',
        dst: 'S',
        type: 'airport',
    },
    {
        name: 'Cañal Bajo Carlos - Hott Siebert Airport',
        city: 'Osorno',
        country: 'Chile',
        iataCode: 'ZOS',
        icaoCode: 'SCJO',
        latitude: '-40,61119843',
        longitude: '-73,06099701',
        altitude: '187',
        timezone: '-4',
        dst: 'S',
        type: 'airport',
    },
    {
        name: 'Vallenar Airport',
        city: 'Vallenar',
        country: 'Chile',
        iataCode: 'N',
        icaoCode: 'SCLL',
        latitude: '-28,59639931',
        longitude: '-70,7559967',
        altitude: '1725',
        timezone: '-4',
        dst: 'S',
        type: 'airport',
    },
    {
        name: 'De La Independencia Airport',
        city: 'Rancagua',
        country: 'Chile',
        iataCode: 'N',
        icaoCode: 'SCRG',
        latitude: '-34,17369843',
        longitude: '-70,77570343',
        altitude: '1446',
        timezone: '-4',
        dst: 'S',
        type: 'airport',
    },
    {
        name: 'Teniente Rodolfo Marsh Martin Base',
        city: 'Isla Rey Jorge',
        country: 'Antarctica',
        iataCode: 'N',
        icaoCode: 'SCRM',
        latitude: '-62,19079971',
        longitude: '-58,98669815',
        altitude: '147',
        timezone: '12',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'La Florida Airport',
        city: 'La Serena',
        country: 'Chile',
        iataCode: 'LSC',
        icaoCode: 'SCSE',
        latitude: '-29,91620064',
        longitude: '-71,19950104',
        altitude: '481',
        timezone: '-4',
        dst: 'S',
        type: 'airport',
    },
    {
        name: 'Eulogio Sánchez Airport',
        city: 'Santiago',
        country: 'Chile',
        iataCode: 'N',
        icaoCode: 'SCTB',
        latitude: '-33,45629883',
        longitude: '-70,54669952',
        altitude: '2129',
        timezone: '-4',
        dst: 'S',
        type: 'airport',
    },
    {
        name: 'Maquehue Airport',
        city: 'Temuco',
        country: 'Chile',
        iataCode: 'ZCO',
        icaoCode: 'SCTC',
        latitude: '-38,76679993',
        longitude: '-72,63710022',
        altitude: '304',
        timezone: '-4',
        dst: 'S',
        type: 'airport',
    },
    {
        name: 'El Tepual Airport',
        city: 'Puerto Montt',
        country: 'Chile',
        iataCode: 'PMC',
        icaoCode: 'SCTE',
        latitude: '-41,43889999',
        longitude: '-73,09400177',
        altitude: '294',
        timezone: '-4',
        dst: 'S',
        type: 'airport',
    },
    {
        name: 'Chaitén Airport',
        city: 'Chaiten',
        country: 'Chile',
        iataCode: 'WCH',
        icaoCode: 'SCTN',
        latitude: '-42,93280029',
        longitude: '-72,69909668',
        altitude: '13',
        timezone: '-4',
        dst: 'S',
        type: 'airport',
    },
    {
        name: 'Pichoy Airport',
        city: 'Valdivia',
        country: 'Chile',
        iataCode: 'ZAL',
        icaoCode: 'SCVD',
        latitude: '-39,65000153',
        longitude: '-73,08609772',
        altitude: '59',
        timezone: '-4',
        dst: 'S',
        type: 'airport',
    },
    {
        name: 'Chachoán Airport',
        city: 'Ambato',
        country: 'Ecuador',
        iataCode: 'ATF',
        icaoCode: 'SEAM',
        latitude: '-1,212069988',
        longitude: '-78,57460022',
        altitude: '8502',
        timezone: '-5',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Hacienda Clementina Airport',
        city: 'Clementia',
        country: 'Ecuador',
        iataCode: 'N',
        icaoCode: 'SECM',
        latitude: '-1,706269979',
        longitude: '-79,37889862',
        altitude: '328',
        timezone: '-5',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Francisco De Orellana Airport',
        city: 'Coca',
        country: 'Ecuador',
        iataCode: 'OCC',
        icaoCode: 'SECO',
        latitude: '-0,462886006',
        longitude: '-76,98680115',
        altitude: '834',
        timezone: '-5',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Mariscal Lamar Airport',
        city: 'Cuenca',
        country: 'Ecuador',
        iataCode: 'CUE',
        icaoCode: 'SECU',
        latitude: '-2,8894701',
        longitude: '-78,98439789',
        altitude: '8306',
        timezone: '-5',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Seymour Airport',
        city: 'Galapagos',
        country: 'Ecuador',
        iataCode: 'GPS',
        icaoCode: 'SEGS',
        latitude: '-0,453758001',
        longitude: '-90,26589966',
        altitude: '207',
        timezone: '-6',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'José Joaquín de Olmedo International Airport',
        city: 'Guayaquil',
        country: 'Ecuador',
        iataCode: 'GYE',
        icaoCode: 'SEGU',
        latitude: '-2,15741992',
        longitude: '-79,88359833',
        altitude: '19',
        timezone: '-5',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Gualaquiza Airport',
        city: 'Gualaquiza',
        country: 'Ecuador',
        iataCode: 'N',
        icaoCode: 'SEGZ',
        latitude: '-3,423209906',
        longitude: '-78,56700134',
        altitude: '2640',
        timezone: '-5',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Atahualpa Airport',
        city: 'Ibarra',
        country: 'Ecuador',
        iataCode: 'N',
        icaoCode: 'SEIB',
        latitude: '0,33841899',
        longitude: '-78,13639832',
        altitude: '7304',
        timezone: '-5',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Km 192 Airport',
        city: 'Km-192',
        country: 'Ecuador',
        iataCode: 'N',
        icaoCode: 'SEKK',
        latitude: '0,184202999',
        longitude: '-79,39199829',
        altitude: '1247',
        timezone: '-5',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Hacienda La Julia Airport',
        city: 'La Julia',
        country: 'Ecuador',
        iataCode: 'N',
        icaoCode: 'SELJ',
        latitude: '-1,704380035',
        longitude: '-79,5522995',
        altitude: '50',
        timezone: '-5',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Cotopaxi International Airport',
        city: 'Latacunga',
        country: 'Ecuador',
        iataCode: 'LTX',
        icaoCode: 'SELT',
        latitude: '-0,906832993',
        longitude: '-78,61579895',
        altitude: '9205',
        timezone: '-5',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Jose Maria Velasco Ibarra Airport',
        city: 'Macara',
        country: 'Ecuador',
        iataCode: 'N',
        icaoCode: 'SEMA',
        latitude: '-4,378230095',
        longitude: '-79,94100189',
        altitude: '1508',
        timezone: '-5',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Coronel E Carvajal Airport',
        city: 'Macas',
        country: 'Ecuador',
        iataCode: 'XMS',
        icaoCode: 'SEMC',
        latitude: '-2,299170017',
        longitude: '-78,1207962',
        altitude: '3452',
        timezone: '-5',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'General Manuel Serrano Airport',
        city: 'Machala',
        country: 'Ecuador',
        iataCode: 'MCH',
        icaoCode: 'SEMH',
        latitude: '-3,2689',
        longitude: '-79,961601',
        altitude: '11',
        timezone: '-5',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'El Carmen Airport',
        city: 'Montalvo',
        country: 'Ecuador',
        iataCode: 'N',
        icaoCode: 'SEMO',
        latitude: '-2,067009926',
        longitude: '-76,97570038',
        altitude: '960',
        timezone: '-5',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Eloy Alfaro International Airport',
        city: 'Manta',
        country: 'Ecuador',
        iataCode: 'MEC',
        icaoCode: 'SEMT',
        latitude: '-0,946078002',
        longitude: '-80,67880249',
        altitude: '48',
        timezone: '-5',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Maragrosa Airport',
        city: 'Maragrosa',
        country: 'Ecuador',
        iataCode: 'N',
        icaoCode: 'SEMX',
        latitude: '-2,851099968',
        longitude: '-79,8035965',
        altitude: '18',
        timezone: '-5',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Amable Calle Gutierrez Airport',
        city: 'Pasaje',
        country: 'Ecuador',
        iataCode: 'N',
        icaoCode: 'SEPS',
        latitude: '-3,319669962',
        longitude: '-79,76920319',
        altitude: '22',
        timezone: '-5',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Reales Tamarindos Airport',
        city: 'Portoviejo',
        country: 'Ecuador',
        iataCode: 'PVO',
        icaoCode: 'SEPV',
        latitude: '-1,041650057',
        longitude: '-80,47219849',
        altitude: '130',
        timezone: '-5',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Quevedo Airport',
        city: 'Quevedo',
        country: 'Ecuador',
        iataCode: 'N',
        icaoCode: 'SEQE',
        latitude: '-0,989400029',
        longitude: '-79,46510315',
        altitude: '350',
        timezone: '-5',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Mariscal Sucre International Airport',
        city: 'Quito',
        country: 'Ecuador',
        iataCode: 'UIO',
        icaoCode: 'SEQM',
        latitude: '-0,129166667',
        longitude: '-78,3575',
        altitude: '7841',
        timezone: '-5',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Chimborazo Airport',
        city: 'Riobamba',
        country: 'Ecuador',
        iataCode: 'N',
        icaoCode: 'SERB',
        latitude: '-1,653429985',
        longitude: '-78,65609741',
        altitude: '9151',
        timezone: '-5',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Santa Rosa International Airport',
        city: 'Santa Rosa',
        country: 'Ecuador',
        iataCode: 'ETR',
        icaoCode: 'SERO',
        latitude: '-3,441986',
        longitude: '-79,996957',
        altitude: '20',
        timezone: '-5',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'General Ulpiano Paez Airport',
        city: 'Salinas',
        country: 'Ecuador',
        iataCode: 'SNC',
        icaoCode: 'SESA',
        latitude: '-2,20499',
        longitude: '-80,988899',
        altitude: '18',
        timezone: '-5',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Santo Domingo de Los Colorados Airport',
        city: 'Santo Domingo',
        country: 'Ecuador',
        iataCode: 'N',
        icaoCode: 'SESD',
        latitude: '-0,248221993',
        longitude: '-79,21440125',
        altitude: '1714',
        timezone: '-5',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Taura Airport',
        city: 'Taura',
        country: 'Ecuador',
        iataCode: 'N',
        icaoCode: 'SETA',
        latitude: '-2,261039972',
        longitude: '-79,68019867',
        altitude: '56',
        timezone: '-5',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Mayor Galo Torres Airport',
        city: 'Tena',
        country: 'Ecuador',
        iataCode: 'N',
        icaoCode: 'SETE',
        latitude: '-0,986766994',
        longitude: '-77,81950378',
        altitude: '1708',
        timezone: '-5',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Tarapoa Airport',
        city: 'Tarapoa',
        country: 'Ecuador',
        iataCode: 'TPC',
        icaoCode: 'SETR',
        latitude: '-0,122956',
        longitude: '-76,33779907',
        altitude: '814',
        timezone: '-5',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Teniente Coronel Luis a Mantilla Airport',
        city: 'Tulcan',
        country: 'Ecuador',
        iataCode: 'TUA',
        icaoCode: 'SETU',
        latitude: '0,809505999',
        longitude: '-77,70809937',
        altitude: '9649',
        timezone: '-5',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Silvio Pettirossi International Airport',
        city: 'Asuncion',
        country: 'Paraguay',
        iataCode: 'ASU',
        icaoCode: 'SGAS',
        latitude: '-25,23999977',
        longitude: '-57,52000046',
        altitude: '292',
        timezone: '-4',
        dst: 'S',
        type: 'airport',
    },
    {
        name: 'Juan De Ayolas Airport',
        city: 'Ayolas',
        country: 'Paraguay',
        iataCode: 'N',
        icaoCode: 'SGAY',
        latitude: '-27,370554',
        longitude: '-56,854064',
        altitude: '223',
        timezone: '-4',
        dst: 'S',
        type: 'airport',
    },
    {
        name: 'Teniente Col Carmelo Peralta Airport',
        city: 'Conception',
        country: 'Paraguay',
        iataCode: 'CIO',
        icaoCode: 'SGCO',
        latitude: '-23,442363',
        longitude: '-57,427253',
        altitude: '253',
        timezone: '-4',
        dst: 'S',
        type: 'airport',
    },
    {
        name: 'Itaipú Airport',
        city: 'Itaipu',
        country: 'Paraguay',
        iataCode: 'N',
        icaoCode: 'SGIB',
        latitude: '-25,407519',
        longitude: '-54,619377',
        altitude: '762',
        timezone: '-4',
        dst: 'S',
        type: 'airport',
    },
    {
        name: 'Dr. Luis Maria Argaña International Airport',
        city: 'Mariscal Estigarribia',
        country: 'Paraguay',
        iataCode: 'N',
        icaoCode: 'SGME',
        latitude: '-22,04999924',
        longitude: '-60,61999893',
        altitude: '553',
        timezone: '-4',
        dst: 'S',
        type: 'airport',
    },
    {
        name: 'Carlos Miguel Gimenez Airport',
        city: 'Pilar',
        country: 'Paraguay',
        iataCode: 'N',
        icaoCode: 'SGPI',
        latitude: '-26,881224',
        longitude: '-58,318026',
        altitude: '249',
        timezone: '-4',
        dst: 'S',
        type: 'airport',
    },
    {
        name: 'El Eden Airport',
        city: 'Armenia',
        country: 'Colombia',
        iataCode: 'AXM',
        icaoCode: 'SKAR',
        latitude: '4,45278',
        longitude: '-75,7664',
        altitude: '3990',
        timezone: '-5',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Tres De Mayo Airport',
        city: 'Puerto Asis',
        country: 'Colombia',
        iataCode: 'PUU',
        icaoCode: 'SKAS',
        latitude: '0,505228',
        longitude: '-76,5008',
        altitude: '815',
        timezone: '-5',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Las Flores Airport',
        city: 'El Banco',
        country: 'Colombia',
        iataCode: 'N',
        icaoCode: 'SKBC',
        latitude: '9,04554',
        longitude: '-73,9749',
        altitude: '111',
        timezone: '-5',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Palonegro Airport',
        city: 'Bucaramanga',
        country: 'Colombia',
        iataCode: 'BGA',
        icaoCode: 'SKBG',
        latitude: '7,1265',
        longitude: '-73,1848',
        altitude: '3897',
        timezone: '-5',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'El Dorado International Airport',
        city: 'Bogota',
        country: 'Colombia',
        iataCode: 'BOG',
        icaoCode: 'SKBO',
        latitude: '4,70159',
        longitude: '-74,1469',
        altitude: '8361',
        timezone: '-5',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Ernesto Cortissoz International Airport',
        city: 'Barranquilla',
        country: 'Colombia',
        iataCode: 'BAQ',
        icaoCode: 'SKBQ',
        latitude: '10,8896',
        longitude: '-74,7808',
        altitude: '98',
        timezone: '-5',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'José Celestino Mutis Airport',
        city: 'Bahia Solano',
        country: 'Colombia',
        iataCode: 'BSC',
        icaoCode: 'SKBS',
        latitude: '6,20292',
        longitude: '-77,3947',
        altitude: '80',
        timezone: '-5',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Gerardo Tobar López Airport',
        city: 'Buenaventura',
        country: 'Colombia',
        iataCode: 'BUN',
        icaoCode: 'SKBU',
        latitude: '3,81963',
        longitude: '-76,9898',
        altitude: '48',
        timezone: '-5',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Camilo Daza International Airport',
        city: 'Cucuta',
        country: 'Colombia',
        iataCode: 'CUC',
        icaoCode: 'SKCC',
        latitude: '7,92757',
        longitude: '-72,5115',
        altitude: '1096',
        timezone: '-5',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Rafael Nuñez International Airport',
        city: 'Cartagena',
        country: 'Colombia',
        iataCode: 'CTG',
        icaoCode: 'SKCG',
        latitude: '10,4424',
        longitude: '-75,513',
        altitude: '4',
        timezone: '-5',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Alfonso Bonilla Aragon International Airport',
        city: 'Cali',
        country: 'Colombia',
        iataCode: 'CLO',
        icaoCode: 'SKCL',
        latitude: '3,54322',
        longitude: '-76,3816',
        altitude: '3162',
        timezone: '-5',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'La Florida Airport',
        city: 'Tumaco',
        country: 'Colombia',
        iataCode: 'TCO',
        icaoCode: 'SKCO',
        latitude: '1,81442',
        longitude: '-78,7492',
        altitude: '8',
        timezone: '-5',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Las Brujas Airport',
        city: 'Corozal',
        country: 'Colombia',
        iataCode: 'CZU',
        icaoCode: 'SKCZ',
        latitude: '9,33274',
        longitude: '-75,2856',
        altitude: '528',
        timezone: '-5',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Yariguíes Airport',
        city: 'Barrancabermeja',
        country: 'Colombia',
        iataCode: 'EJA',
        icaoCode: 'SKEJ',
        latitude: '7,02433',
        longitude: '-73,8068',
        altitude: '412',
        timezone: '-5',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Gustavo Artunduaga Paredes Airport',
        city: 'Florencia',
        country: 'Colombia',
        iataCode: 'FLA',
        icaoCode: 'SKFL',
        latitude: '1,58919',
        longitude: '-75,5644',
        altitude: '803',
        timezone: '-5',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Santiago Vila Airport',
        city: 'Girardot',
        country: 'Colombia',
        iataCode: 'N',
        icaoCode: 'SKGI',
        latitude: '4,27624',
        longitude: '-74,7967',
        altitude: '900',
        timezone: '-5',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Juan Casiano Airport',
        city: 'Guapi',
        country: 'Colombia',
        iataCode: 'GPI',
        icaoCode: 'SKGP',
        latitude: '2,57013',
        longitude: '-77,8986',
        altitude: '164',
        timezone: '-5',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Guaymaral Airport',
        city: 'Guaymaral',
        country: 'Colombia',
        iataCode: 'N',
        icaoCode: 'SKGY',
        latitude: '4,81233',
        longitude: '-74,0649',
        altitude: '8390',
        timezone: '-5',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Perales Airport',
        city: 'Ibague',
        country: 'Colombia',
        iataCode: 'IBE',
        icaoCode: 'SKIB',
        latitude: '4,42161',
        longitude: '-75,1333',
        altitude: '2999',
        timezone: '-5',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'San Luis Airport',
        city: 'Ipiales',
        country: 'Colombia',
        iataCode: 'IPI',
        icaoCode: 'SKIP',
        latitude: '0,861925',
        longitude: '-77,6718',
        altitude: '9765',
        timezone: '-5',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Antonio Roldan Betancourt Airport',
        city: 'Carepa',
        country: 'Colombia',
        iataCode: 'APO',
        icaoCode: 'SKLC',
        latitude: '7,81196',
        longitude: '-76,7164',
        altitude: '46',
        timezone: '-5',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Jorge Isaac Airport',
        city: 'La Mina',
        country: 'Colombia',
        iataCode: 'N',
        icaoCode: 'SKLM',
        latitude: '11,2325',
        longitude: '-72,4901',
        altitude: '281',
        timezone: '-5',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Alfredo Vásquez Cobo International Airport',
        city: 'Leticia',
        country: 'Colombia',
        iataCode: 'LET',
        icaoCode: 'SKLT',
        latitude: '-4,19355',
        longitude: '-69,9432',
        altitude: '277',
        timezone: '-5',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Enrique Olaya Herrera Airport',
        city: 'Medellin',
        country: 'Colombia',
        iataCode: 'EOH',
        icaoCode: 'SKMD',
        latitude: '6,220549',
        longitude: '-75,590582',
        altitude: '4949',
        timezone: '-5',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Baracoa Airport',
        city: 'Magangue',
        country: 'Colombia',
        iataCode: 'MGN',
        icaoCode: 'SKMG',
        latitude: '9,28474',
        longitude: '-74,8461',
        altitude: '178',
        timezone: '-5',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Los Garzones Airport',
        city: 'Monteria',
        country: 'Colombia',
        iataCode: 'MTR',
        icaoCode: 'SKMR',
        latitude: '8,82374',
        longitude: '-75,8258',
        altitude: '36',
        timezone: '-5',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Fabio Alberto Leon Bentley Airport',
        city: 'Mitu',
        country: 'Colombia',
        iataCode: 'MVP',
        icaoCode: 'SKMU',
        latitude: '1,25366',
        longitude: '-70,2339',
        altitude: '680',
        timezone: '-5',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'La Nubia Airport',
        city: 'Manizales',
        country: 'Colombia',
        iataCode: 'MZL',
        icaoCode: 'SKMZ',
        latitude: '5,0296',
        longitude: '-75,4647',
        altitude: '6871',
        timezone: '-5',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Benito Salas Airport',
        city: 'Neiva',
        country: 'Colombia',
        iataCode: 'NVA',
        icaoCode: 'SKNV',
        latitude: '2,95015',
        longitude: '-75,294',
        altitude: '1464',
        timezone: '-5',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Aguas Claras Airport',
        city: 'Ocana',
        country: 'Colombia',
        iataCode: 'OCV',
        icaoCode: 'SKOC',
        latitude: '8,31506',
        longitude: '-73,3583',
        altitude: '3850',
        timezone: '-5',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Otu Airport',
        city: 'Otu',
        country: 'Colombia',
        iataCode: 'OTU',
        icaoCode: 'SKOT',
        latitude: '7,01037',
        longitude: '-74,7155',
        altitude: '2060',
        timezone: '-5',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Puerto Bolívar Airport',
        city: 'Puerto Bolivar',
        country: 'Colombia',
        iataCode: 'N',
        icaoCode: 'SKPB',
        latitude: '12,2215',
        longitude: '-71,9848',
        altitude: '90',
        timezone: '-5',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'German Olano Airport',
        city: 'Puerto Carreno',
        country: 'Colombia',
        iataCode: 'PCR',
        icaoCode: 'SKPC',
        latitude: '6,18472',
        longitude: '-67,4932',
        altitude: '177',
        timezone: '-5',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Matecaña International Airport',
        city: 'Pereira',
        country: 'Colombia',
        iataCode: 'PEI',
        icaoCode: 'SKPE',
        latitude: '4,81267',
        longitude: '-75,7395',
        altitude: '4416',
        timezone: '-5',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Pitalito Airport',
        city: 'Pitalito',
        country: 'Colombia',
        iataCode: 'N',
        icaoCode: 'SKPI',
        latitude: '1,85777',
        longitude: '-76,0857',
        altitude: '4212',
        timezone: '-5',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Guillermo León Valencia Airport',
        city: 'Popayan',
        country: 'Colombia',
        iataCode: 'PPN',
        icaoCode: 'SKPP',
        latitude: '2,4544',
        longitude: '-76,6093',
        altitude: '5687',
        timezone: '-5',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Antonio Narino Airport',
        city: 'Pasto',
        country: 'Colombia',
        iataCode: 'PSO',
        icaoCode: 'SKPS',
        latitude: '1,39625',
        longitude: '-77,2915',
        altitude: '5951',
        timezone: '-5',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'El Embrujo Airport',
        city: 'Providencia',
        country: 'Colombia',
        iataCode: 'PVA',
        icaoCode: 'SKPV',
        latitude: '13,3569',
        longitude: '-81,3583',
        altitude: '10',
        timezone: '-5',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Mariquita Airport',
        city: 'Mariquita',
        country: 'Colombia',
        iataCode: 'N',
        icaoCode: 'SKQU',
        latitude: '5,21256',
        longitude: '-74,8836',
        altitude: '1531',
        timezone: '-5',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Jose Maria Córdova International Airport',
        city: 'Rio Negro',
        country: 'Colombia',
        iataCode: 'MDE',
        icaoCode: 'SKRG',
        latitude: '6,16454',
        longitude: '-75,4231',
        altitude: '6955',
        timezone: '-5',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Almirante Padilla Airport',
        city: 'Rio Hacha',
        country: 'Colombia',
        iataCode: 'RCH',
        icaoCode: 'SKRH',
        latitude: '11,5262',
        longitude: '-72,926',
        altitude: '43',
        timezone: '-5',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Jorge E. Gonzalez Torres Airport',
        city: 'San Jose Del Guaviare',
        country: 'Colombia',
        iataCode: 'SJE',
        icaoCode: 'SKSJ',
        latitude: '2,57969',
        longitude: '-72,6394',
        altitude: '605',
        timezone: '-5',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Simón Bolívar International Airport',
        city: 'Santa Marta',
        country: 'Colombia',
        iataCode: 'SMR',
        icaoCode: 'SKSM',
        latitude: '11,1196',
        longitude: '-74,2306',
        altitude: '22',
        timezone: '-5',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Gustavo Rojas Pinilla International Airport',
        city: 'San Andres Island',
        country: 'Colombia',
        iataCode: 'ADZ',
        icaoCode: 'SKSP',
        latitude: '12,5836',
        longitude: '-81,7112',
        altitude: '19',
        timezone: '-5',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Eduardo Falla Solano Airport',
        city: 'San Vincente De Caguan',
        country: 'Colombia',
        iataCode: 'SVI',
        icaoCode: 'SKSV',
        latitude: '2,15217',
        longitude: '-74,7663',
        altitude: '920',
        timezone: '-5',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Gustavo Vargas Airport',
        city: 'Tame',
        country: 'Colombia',
        iataCode: 'TME',
        icaoCode: 'SKTM',
        latitude: '6,45108',
        longitude: '-71,7603',
        altitude: '1050',
        timezone: '-5',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Santiago Perez Airport',
        city: 'Arauca',
        country: 'Colombia',
        iataCode: 'AUC',
        icaoCode: 'SKUC',
        latitude: '7,06888',
        longitude: '-70,7369',
        altitude: '420',
        timezone: '-5',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'El Caraño Airport',
        city: 'Quibdo',
        country: 'Colombia',
        iataCode: 'UIB',
        icaoCode: 'SKUI',
        latitude: '5,69076',
        longitude: '-76,6412',
        altitude: '204',
        timezone: '-5',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Heriberto Gíl Martínez Airport',
        city: 'Tulua',
        country: 'Colombia',
        iataCode: 'ULQ',
        icaoCode: 'SKUL',
        latitude: '4,08836',
        longitude: '-76,2351',
        altitude: '3132',
        timezone: '-5',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Alfonso López Pumarejo Airport',
        city: 'Valledupar',
        country: 'Colombia',
        iataCode: 'VUP',
        icaoCode: 'SKVP',
        latitude: '10,435',
        longitude: '-73,2495',
        altitude: '483',
        timezone: '-5',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Vanguardia Airport',
        city: 'Villavicencio',
        country: 'Colombia',
        iataCode: 'VVC',
        icaoCode: 'SKVV',
        latitude: '4,16787',
        longitude: '-73,6138',
        altitude: '1394',
        timezone: '-5',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Bermejo Airport',
        city: 'Bermejo',
        country: 'Bolivia',
        iataCode: 'BJO',
        icaoCode: 'SLBJ',
        latitude: '-22,77330017',
        longitude: '-64,31289673',
        altitude: '1249',
        timezone: '-4',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Jorge Wilsterman International Airport',
        city: 'Cochabamba',
        country: 'Bolivia',
        iataCode: 'CBB',
        icaoCode: 'SLCB',
        latitude: '-17,42110062',
        longitude: '-66,17710114',
        altitude: '8360',
        timezone: '-4',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Chimore Airport',
        city: 'Chapacura',
        country: 'Bolivia',
        iataCode: 'N',
        icaoCode: 'SLCH',
        latitude: '-16,99001884',
        longitude: '-65,14150238',
        altitude: '875',
        timezone: '-4',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Capitán Aníbal Arab Airport',
        city: 'Cobija',
        country: 'Bolivia',
        iataCode: 'CIJ',
        icaoCode: 'SLCO',
        latitude: '-11,04039955',
        longitude: '-68,78299713',
        altitude: '889',
        timezone: '-4',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'El Alto International Airport',
        city: 'La Paz',
        country: 'Bolivia',
        iataCode: 'LPB',
        icaoCode: 'SLLP',
        latitude: '-16,51329994',
        longitude: '-68,19229889',
        altitude: '13355',
        timezone: '-4',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Juan Mendoza Airport',
        city: 'Oruro',
        country: 'Bolivia',
        iataCode: 'N',
        icaoCode: 'SLOR',
        latitude: '-17,96260071',
        longitude: '-67,07620239',
        altitude: '12152',
        timezone: '-4',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Capitan Nicolas Rojas Airport',
        city: 'Potosi',
        country: 'Bolivia',
        iataCode: 'POI',
        icaoCode: 'SLPO',
        latitude: '-19,54310036',
        longitude: '-65,72370148',
        altitude: '12913',
        timezone: '-4',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Capitán Av. Salvador Ogaya G. airport',
        city: 'Puerto Suarez',
        country: 'Bolivia',
        iataCode: 'PSZ',
        icaoCode: 'SLPS',
        latitude: '-18,97529984',
        longitude: '-57,8205986',
        altitude: '505',
        timezone: '-4',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Santa Ana Del Yacuma Airport',
        city: 'Santa Ana',
        country: 'Bolivia',
        iataCode: 'N',
        icaoCode: 'SLSA',
        latitude: '-13,76220036',
        longitude: '-65,43520355',
        altitude: '472',
        timezone: '-4',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Juana Azurduy De Padilla Airport',
        city: 'Sucre',
        country: 'Bolivia',
        iataCode: 'SRE',
        icaoCode: 'SLSU',
        latitude: '-19,00709915',
        longitude: '-65,28869629',
        altitude: '9540',
        timezone: '-4',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Capitan Oriel Lea Plaza Airport',
        city: 'Tarija',
        country: 'Bolivia',
        iataCode: 'TJA',
        icaoCode: 'SLTJ',
        latitude: '-21,5557003',
        longitude: '-64,70130157',
        altitude: '6079',
        timezone: '-4',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Teniente Av. Jorge Henrich Arauz Airport',
        city: 'Trinidad',
        country: 'Bolivia',
        iataCode: 'TDD',
        icaoCode: 'SLTR',
        latitude: '-14,81869984',
        longitude: '-64,91799927',
        altitude: '509',
        timezone: '-4',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Teniente Coronel Rafael Pabón Airport',
        city: 'Villa Montes',
        country: 'Bolivia',
        iataCode: 'N',
        icaoCode: 'SLVM',
        latitude: '-21,25519943',
        longitude: '-63,4056015',
        altitude: '1305',
        timezone: '-4',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Viru Viru International Airport',
        city: 'Santa Cruz',
        country: 'Bolivia',
        iataCode: 'VVI',
        icaoCode: 'SLVR',
        latitude: '-17,64480019',
        longitude: '-63,13539886',
        altitude: '1224',
        timezone: '-4',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Yacuiba Airport',
        city: 'Yacuiba',
        country: 'Bolivia',
        iataCode: 'BYC',
        icaoCode: 'SLYA',
        latitude: '-21,96089935',
        longitude: '-63,65169907',
        altitude: '2112',
        timezone: '-4',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Johan Adolf Pengel International Airport',
        city: 'Zandery',
        country: 'Suriname',
        iataCode: 'PBM',
        icaoCode: 'SMJP',
        latitude: '5,452829838',
        longitude: '-55,18780136',
        altitude: '59',
        timezone: '-3',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Cayenne-Rochambeau Airport',
        city: 'Cayenne',
        country: 'French Guiana',
        iataCode: 'CAY',
        icaoCode: 'SOCA',
        latitude: '4,819809914',
        longitude: '-52,36040115',
        altitude: '26',
        timezone: '-3',
        dst: 'U',
        type: 'airport',
    },
    {
        name: "Saint-Georges-de-l'Oyapock Airport",
        city: 'St.-georges Oyapock',
        country: 'French Guiana',
        iataCode: 'N',
        icaoCode: 'SOOG',
        latitude: '3,897599936',
        longitude: '-51,80410004',
        altitude: '46',
        timezone: '-3',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Huancabamba Airport',
        city: 'Huancabamba',
        country: 'Peru',
        iataCode: 'N',
        icaoCode: 'SPAB',
        latitude: '-5,256770134',
        longitude: '-79,44290161',
        altitude: '6312',
        timezone: '-5',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Alferez FAP Alfredo Vladimir Sara Bauer Airport',
        city: 'Andoas',
        country: 'Peru',
        iataCode: 'N',
        icaoCode: 'SPAS',
        latitude: '-2,796129942',
        longitude: '-76,46659851',
        altitude: '728',
        timezone: '-5',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Teniente General Gerardo Pérez Pinedo Airport',
        city: 'Atalaya',
        country: 'Peru',
        iataCode: 'N',
        icaoCode: 'SPAY',
        latitude: '-10,72910023',
        longitude: '-73,76650238',
        altitude: '751',
        timezone: '-5',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Iberia Airport',
        city: 'Iberia',
        country: 'Peru',
        iataCode: 'N',
        icaoCode: 'SPBR',
        latitude: '-11,41160011',
        longitude: '-69,48870087',
        altitude: '750',
        timezone: '-5',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Cap FAP David Abenzur Rengifo International Airport',
        city: 'Pucallpa',
        country: 'Peru',
        iataCode: 'PCL',
        icaoCode: 'SPCL',
        latitude: '-8,377940178',
        longitude: '-74,57430267',
        altitude: '513',
        timezone: '-5',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Teniente FAP Jaime A De Montreuil Morales Airport',
        city: 'Chimbote',
        country: 'Peru',
        iataCode: 'CHM',
        icaoCode: 'SPEO',
        latitude: '-9,149609566',
        longitude: '-78,52380371',
        altitude: '69',
        timezone: '-5',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Puerto Esperanza Airport',
        city: 'Puerto Esperanza',
        country: 'Peru',
        iataCode: 'N',
        icaoCode: 'SPEP',
        latitude: '-9,768130302',
        longitude: '-70,70649719',
        altitude: '725',
        timezone: '-5',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Cesar Torke Podesta Airport',
        city: 'Moquegua',
        country: 'Peru',
        iataCode: 'N',
        icaoCode: 'SPEQ',
        latitude: '-17,17900085',
        longitude: '-70,93080139',
        altitude: '4480',
        timezone: '-5',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Capitan FAP Jose A Quinones Gonzales International Airport',
        city: 'Chiclayo',
        country: 'Peru',
        iataCode: 'CIX',
        icaoCode: 'SPHI',
        latitude: '-6,787479877',
        longitude: '-79,82810211',
        altitude: '97',
        timezone: '-5',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Coronel FAP Alfredo Mendivil Duarte Airport',
        city: 'Ayacucho',
        country: 'Peru',
        iataCode: 'AYP',
        icaoCode: 'SPHO',
        latitude: '-13,15480042',
        longitude: '-74,20439911',
        altitude: '8917',
        timezone: '-5',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Andahuaylas Airport',
        city: 'Andahuaylas',
        country: 'Peru',
        iataCode: 'ANS',
        icaoCode: 'SPHY',
        latitude: '-13,70639992',
        longitude: '-73,35040283',
        altitude: '11300',
        timezone: '-5',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Comandante FAP German Arias Graziani Airport',
        city: 'Anta',
        country: 'Peru',
        iataCode: 'ATA',
        icaoCode: 'SPHZ',
        latitude: '-9,347439766',
        longitude: '-77,5983963',
        altitude: '9097',
        timezone: '-5',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Jorge Chávez International Airport',
        city: 'Lima',
        country: 'Peru',
        iataCode: 'LIM',
        icaoCode: 'SPIM',
        latitude: '-12,0219',
        longitude: '-77,114304',
        altitude: '113',
        timezone: '-5',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Juanjui Airport',
        city: 'Juanjui',
        country: 'Peru',
        iataCode: 'JJI',
        icaoCode: 'SPJI',
        latitude: '-7,169099808',
        longitude: '-76,72859955',
        altitude: '1148',
        timezone: '-5',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Francisco Carle Airport',
        city: 'Jauja',
        country: 'Peru',
        iataCode: 'N',
        icaoCode: 'SPJJ',
        latitude: '-11,78310013',
        longitude: '-75,4733963',
        altitude: '11034',
        timezone: '-5',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Inca Manco Capac International Airport',
        city: 'Juliaca',
        country: 'Peru',
        iataCode: 'JUL',
        icaoCode: 'SPJL',
        latitude: '-15,46710014',
        longitude: '-70,15820313',
        altitude: '12552',
        timezone: '-5',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Ilo Airport',
        city: 'Ilo',
        country: 'Peru',
        iataCode: 'N',
        icaoCode: 'SPLO',
        latitude: '-17,69499969',
        longitude: '-71,34400177',
        altitude: '72',
        timezone: '-5',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Las Palmas Air Base',
        city: 'Las Palmas',
        country: 'Peru',
        iataCode: 'N',
        icaoCode: 'SPLP',
        latitude: '-12,16069984',
        longitude: '-76,99890137',
        altitude: '250',
        timezone: '-5',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Capitan FAP Pedro Canga Rodriguez Airport',
        city: 'Tumbes',
        country: 'Peru',
        iataCode: 'TBP',
        icaoCode: 'SPME',
        latitude: '-3,55253005',
        longitude: '-80,38140106',
        altitude: '115',
        timezone: '-5',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Moises Benzaquen Rengifo Airport',
        city: 'Yurimaguas',
        country: 'Peru',
        iataCode: 'YMS',
        icaoCode: 'SPMS',
        latitude: '-5,893770218',
        longitude: '-76,11820221',
        altitude: '587',
        timezone: '-5',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Collique Airport',
        city: 'Collique',
        country: 'Peru',
        iataCode: 'N',
        icaoCode: 'SPOL',
        latitude: '-11,92870045',
        longitude: '-77,06109619',
        altitude: '410',
        timezone: '-5',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Chachapoyas Airport',
        city: 'Chachapoyas',
        country: 'Peru',
        iataCode: 'CHH',
        icaoCode: 'SPPY',
        latitude: '-6,201809883',
        longitude: '-77,85610199',
        altitude: '8333',
        timezone: '-5',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Coronel FAP Francisco Secada Vignetta International Airport',
        city: 'Iquitos',
        country: 'Peru',
        iataCode: 'IQT',
        icaoCode: 'SPQT',
        latitude: '-3,784739971',
        longitude: '-73,30879974',
        altitude: '306',
        timezone: '-5',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Rodríguez Ballón International Airport',
        city: 'Arequipa',
        country: 'Peru',
        iataCode: 'AQP',
        icaoCode: 'SPQU',
        latitude: '-16,34110069',
        longitude: '-71,58309937',
        altitude: '8405',
        timezone: '-5',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Capitán FAP Leonardo Alvariño Herr Airport',
        city: 'San Ramon',
        country: 'Peru',
        iataCode: 'N',
        icaoCode: 'SPRM',
        latitude: '-11,12860012',
        longitude: '-75,35050201',
        altitude: '2600',
        timezone: '-5',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Capitan FAP Carlos Martinez De Pinillos International Airport',
        city: 'Trujillo',
        country: 'Peru',
        iataCode: 'TRU',
        icaoCode: 'SPRU',
        latitude: '-8,081410408',
        longitude: '-79,1088028',
        altitude: '106',
        timezone: '-5',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Capitán FAP Renán Elías Olivera International Airport',
        city: 'Pisco',
        country: 'Peru',
        iataCode: 'PIO',
        icaoCode: 'SPSO',
        latitude: '-13,74489975',
        longitude: '-76,22029877',
        altitude: '39',
        timezone: '-5',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Cadete FAP Guillermo Del Castillo Paredes Airport',
        city: 'Tarapoto',
        country: 'Peru',
        iataCode: 'TPP',
        icaoCode: 'SPST',
        latitude: '-6,508739948',
        longitude: '-76,37319946',
        altitude: '869',
        timezone: '-5',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Coronel FAP Carlos Ciriani Santa Rosa International Airport',
        city: 'Tacna',
        country: 'Peru',
        iataCode: 'TCQ',
        icaoCode: 'SPTN',
        latitude: '-18,05330086',
        longitude: '-70,27580261',
        altitude: '1538',
        timezone: '-5',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Padre Aldamiz International Airport',
        city: 'Puerto Maldonado',
        country: 'Peru',
        iataCode: 'PEM',
        icaoCode: 'SPTU',
        latitude: '-12,61359978',
        longitude: '-69,22859955',
        altitude: '659',
        timezone: '-5',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Capitán FAP Guillermo Concha Iberico International Airport',
        city: 'Piura',
        country: 'Peru',
        iataCode: 'PIU',
        icaoCode: 'SPUR',
        latitude: '-5,205749989',
        longitude: '-80,61640167',
        altitude: '120',
        timezone: '-5',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Capitan Montes Airport',
        city: 'Talara',
        country: 'Peru',
        iataCode: 'TYL',
        icaoCode: 'SPYL',
        latitude: '-4,576640129',
        longitude: '-81,25409698',
        altitude: '282',
        timezone: '-5',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Alejandro Velasco Astete International Airport',
        city: 'Cuzco',
        country: 'Peru',
        iataCode: 'CUZ',
        icaoCode: 'SPZO',
        latitude: '-13,53569984',
        longitude: '-71,938797',
        altitude: '10860',
        timezone: '-5',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Angel S Adami Airport',
        city: 'Montevideo',
        country: 'Uruguay',
        iataCode: 'N',
        icaoCode: 'SUAA',
        latitude: '-34,78919983',
        longitude: '-56,26470184',
        altitude: '174',
        timezone: '-3',
        dst: 'S',
        type: 'airport',
    },
    {
        name: 'Santa Bernardina International Airport',
        city: 'Durazno',
        country: 'Uruguay',
        iataCode: 'N',
        icaoCode: 'SUDU',
        latitude: '-33,35889816',
        longitude: '-56,49919891',
        altitude: '305',
        timezone: '-3',
        dst: 'S',
        type: 'airport',
    },
    {
        name: 'Carrasco International /General C L Berisso Airport',
        city: 'Montevideo',
        country: 'Uruguay',
        iataCode: 'MVD',
        icaoCode: 'SUMU',
        latitude: '-34,83840179',
        longitude: '-56,03079987',
        altitude: '105',
        timezone: '-3',
        dst: 'S',
        type: 'airport',
    },
    {
        name: 'Nueva Hesperides International Airport',
        city: 'Salto',
        country: 'Uruguay',
        iataCode: 'STY',
        icaoCode: 'SUSO',
        latitude: '-31,43849945',
        longitude: '-57,98529816',
        altitude: '187',
        timezone: '-3',
        dst: 'S',
        type: 'airport',
    },
    {
        name: 'Oswaldo Guevara Mujica Airport',
        city: 'Acarigua',
        country: 'Venezuela',
        iataCode: 'AGV',
        icaoCode: 'SVAC',
        latitude: '9,553375244',
        longitude: '-69,23786926',
        altitude: '640',
        timezone: '-4',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Anaco Airport',
        city: 'Anaco',
        country: 'Venezuela',
        iataCode: 'AAO',
        icaoCode: 'SVAN',
        latitude: '9,430225372',
        longitude: '-64,47072601',
        altitude: '721',
        timezone: '-4',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'San Fernando de Atabapo Airport',
        city: 'San Fernando Deatabapo',
        country: 'Venezuela',
        iataCode: 'N',
        icaoCode: 'SVAT',
        latitude: '4,051040173',
        longitude: '-67,6996994',
        altitude: '301',
        timezone: '-4',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'General Jose Antonio Anzoategui International Airport',
        city: 'Barcelona',
        country: 'Venezuela',
        iataCode: 'BLA',
        icaoCode: 'SVBC',
        latitude: '10,10709953',
        longitude: '-64,68920135',
        altitude: '26',
        timezone: '-4',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Barinas Airport',
        city: 'Barinas',
        country: 'Venezuela',
        iataCode: 'BNS',
        icaoCode: 'SVBI',
        latitude: '8,619569778',
        longitude: '-70,22080231',
        altitude: '666',
        timezone: '-4',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'El Libertador Airbase',
        city: 'Maracaibo',
        country: 'Venezuela',
        iataCode: 'N',
        icaoCode: 'SVBL',
        latitude: '10,18337536',
        longitude: '-67,55731964',
        altitude: '1450',
        timezone: '-4',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Barquisimeto International Airport',
        city: 'Barquisimeto',
        country: 'Venezuela',
        iataCode: 'BRM',
        icaoCode: 'SVBM',
        latitude: '10,04274654',
        longitude: '-69,35861969',
        altitude: '2042',
        timezone: '-4',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Aeropuerto General Tomas de Heres". Ciudad Bolivar"',
        city: 'Ciudad Bolivar',
        country: 'Venezuela',
        iataCode: 'CBL',
        icaoCode: 'SVCB',
        latitude: '8,122160912',
        longitude: '-63,53695679',
        altitude: '197',
        timezone: '-4',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Caicara del Orinoco Airport',
        city: 'Caicara De Orinoco',
        country: 'Venezuela',
        iataCode: 'N',
        icaoCode: 'SVCD',
        latitude: '7,625510216',
        longitude: '-66,16280365',
        altitude: '141',
        timezone: '-4',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'San Carlos Airport',
        city: 'San Carlos',
        country: 'Venezuela',
        iataCode: 'N',
        icaoCode: 'SVCJ',
        latitude: '9,647720337',
        longitude: '-68,5746994',
        altitude: '512',
        timezone: '-4',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Calabozo Airport',
        city: 'Calabozo',
        country: 'Venezuela',
        iataCode: 'N',
        icaoCode: 'SVCL',
        latitude: '8,924655914',
        longitude: '-67,41709137',
        altitude: '328',
        timezone: '-4',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Canaima Airport',
        city: 'Canaima',
        country: 'Venezuela',
        iataCode: 'CAJ',
        icaoCode: 'SVCN',
        latitude: '6,231988907',
        longitude: '-62,85443115',
        altitude: '1450',
        timezone: '-4',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Carora Airport',
        city: 'Carora',
        country: 'Venezuela',
        iataCode: 'N',
        icaoCode: 'SVCO',
        latitude: '10,17560291',
        longitude: '-70,06521606',
        altitude: '1437',
        timezone: '-4',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'General Francisco Bermúdez Airport',
        city: 'Carupano',
        country: 'Venezuela',
        iataCode: 'CUP',
        icaoCode: 'SVCP',
        latitude: '10,66001415',
        longitude: '-63,2616806',
        altitude: '33',
        timezone: '-4',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'José Leonardo Chirinos Airport',
        city: 'Coro',
        country: 'Venezuela',
        iataCode: 'CZE',
        icaoCode: 'SVCR',
        latitude: '11,4149437',
        longitude: '-69,68090057',
        altitude: '52',
        timezone: '-4',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Oscar Machado Zuluaga Airport',
        city: 'Caracas',
        country: 'Venezuela',
        iataCode: 'N',
        icaoCode: 'SVCS',
        latitude: '10,28611088',
        longitude: '-66,8161087',
        altitude: '2145',
        timezone: '-4',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Cumaná (Antonio José de Sucre) Airport',
        city: 'Cumana',
        country: 'Venezuela',
        iataCode: 'CUM',
        icaoCode: 'SVCU',
        latitude: '10,45033264',
        longitude: '-64,13047028',
        altitude: '14',
        timezone: '-4',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Capitán Manuel Ríos Airbase',
        city: 'Carrizal',
        country: 'Venezuela',
        iataCode: 'N',
        icaoCode: 'SVCZ',
        latitude: '9,372650146',
        longitude: '-66,92279816',
        altitude: '595',
        timezone: '-4',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'El Dorado Airport',
        city: 'El Dorado',
        country: 'Venezuela',
        iataCode: 'N',
        icaoCode: 'SVED',
        latitude: '6,733333111',
        longitude: '-61,58333206',
        altitude: '318',
        timezone: '-4',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Elorza Airport',
        city: 'Elorza',
        country: 'Venezuela',
        iataCode: 'N',
        icaoCode: 'SVEZ',
        latitude: '7,083333015',
        longitude: '-69,53333282',
        altitude: '295',
        timezone: '-4',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Guasdalito Airport',
        city: 'Guasdualito',
        country: 'Venezuela',
        iataCode: 'N',
        icaoCode: 'SVGD',
        latitude: '7,233333111',
        longitude: '-70,80000305',
        altitude: '426',
        timezone: '-4',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Guiria Airport',
        city: 'Guiria',
        country: 'Venezuela',
        iataCode: 'GUI',
        icaoCode: 'SVGI',
        latitude: '10,57407761',
        longitude: '-62,31266785',
        altitude: '42',
        timezone: '-4',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Guanare Airport',
        city: 'Guanare',
        country: 'Venezuela',
        iataCode: 'GUQ',
        icaoCode: 'SVGU',
        latitude: '9,02694416',
        longitude: '-69,75514984',
        altitude: '606',
        timezone: '-4',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Higuerote Airport',
        city: 'Higuerote',
        country: 'Venezuela',
        iataCode: 'N',
        icaoCode: 'SVHG',
        latitude: '10,462474',
        longitude: '-66,092779',
        altitude: '10',
        timezone: '-4',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Andrés Miguel Salazar Marcano Airport',
        city: 'Isla De Coche',
        country: 'Venezuela',
        iataCode: 'N',
        icaoCode: 'SVIE',
        latitude: '10,794432',
        longitude: '-63,98159',
        altitude: '10',
        timezone: '-4',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Josefa Camejo International Airport',
        city: 'Paraguana',
        country: 'Venezuela',
        iataCode: 'LSP',
        icaoCode: 'SVJC',
        latitude: '11,78077507',
        longitude: '-70,15149689',
        altitude: '75',
        timezone: '-4',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'San Juan de Los Morros Airport',
        city: 'San Juan De Los Morros',
        country: 'Venezuela',
        iataCode: 'N',
        icaoCode: 'SVJM',
        latitude: '9,906952858',
        longitude: '-67,37963867',
        altitude: '1404',
        timezone: '-4',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'La Fria Airport',
        city: 'La Fria',
        country: 'Venezuela',
        iataCode: 'LFR',
        icaoCode: 'SVLF',
        latitude: '8,239167213',
        longitude: '-72,27102661',
        altitude: '305',
        timezone: '-4',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'La Orchila Airport',
        city: 'La Orchila',
        country: 'Venezuela',
        iataCode: 'N',
        icaoCode: 'SVLO',
        latitude: '11,80720043',
        longitude: '-66,17960358',
        altitude: '5',
        timezone: '-4',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'La Chinita International Airport',
        city: 'Maracaibo',
        country: 'Venezuela',
        iataCode: 'MAR',
        icaoCode: 'SVMC',
        latitude: '10,55820847',
        longitude: '-71,7278595',
        altitude: '239',
        timezone: '-4',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Alberto Carnevalli Airport',
        city: 'Merida',
        country: 'Venezuela',
        iataCode: 'MRD',
        icaoCode: 'SVMD',
        latitude: '8,58207798',
        longitude: '-71,16104126',
        altitude: '5007',
        timezone: '-4',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Del Caribe Santiago Mariño International Airport',
        city: 'Porlamar',
        country: 'Venezuela',
        iataCode: 'PMV',
        icaoCode: 'SVMG',
        latitude: '10,91260338',
        longitude: '-63,96659851',
        altitude: '74',
        timezone: '-4',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Simón Bolívar International Airport',
        city: 'Caracas',
        country: 'Venezuela',
        iataCode: 'CCS',
        icaoCode: 'SVMI',
        latitude: '10,60311699',
        longitude: '-66,99058533',
        altitude: '235',
        timezone: '-4',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Maturín Airport',
        city: 'Maturin',
        country: 'Venezuela',
        iataCode: 'MUN',
        icaoCode: 'SVMT',
        latitude: '9,754529953',
        longitude: '-63,1473999',
        altitude: '224',
        timezone: '-4',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Cacique Aramare Airport',
        city: 'Puerto Ayacucho',
        country: 'Venezuela',
        iataCode: 'PYH',
        icaoCode: 'SVPA',
        latitude: '5,619989872',
        longitude: '-67,60610199',
        altitude: '245',
        timezone: '-4',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'General Bartolome Salom International Airport',
        city: 'Puerto Cabello',
        country: 'Venezuela',
        iataCode: 'PBL',
        icaoCode: 'SVPC',
        latitude: '10,48050022',
        longitude: '-68,07299805',
        altitude: '32',
        timezone: '-4',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Paramillo Airport',
        city: 'San Cristobal',
        country: 'Venezuela',
        iataCode: 'N',
        icaoCode: 'SVPM',
        latitude: '7,801320076',
        longitude: '-72,20290375',
        altitude: '3314',
        timezone: '-4',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'General Manuel Carlos Piar International Airport',
        city: 'Guayana',
        country: 'Venezuela',
        iataCode: 'PZO',
        icaoCode: 'SVPR',
        latitude: '8,28853035',
        longitude: '-62,76039886',
        altitude: '472',
        timezone: '-4',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Palmarito Airport',
        city: 'Palmarito',
        country: 'Venezuela',
        iataCode: 'N',
        icaoCode: 'SVPT',
        latitude: '7,566669941',
        longitude: '-70,1832962',
        altitude: '347',
        timezone: '-4',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'San Antonio Del Tachira Airport',
        city: 'San Antonio',
        country: 'Venezuela',
        iataCode: 'SVZ',
        icaoCode: 'SVSA',
        latitude: '7,840829849',
        longitude: '-72,43969727',
        altitude: '1312',
        timezone: '-4',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Santa Bárbara de Barinas Airport',
        city: 'Santa Barbara',
        country: 'Venezuela',
        iataCode: 'N',
        icaoCode: 'SVSB',
        latitude: '7,803514004',
        longitude: '-71,16571808',
        altitude: '590',
        timezone: '-4',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Santa Elena de Uairen Airport',
        city: 'Santa Ana De Uairen',
        country: 'Venezuela',
        iataCode: 'SNV',
        icaoCode: 'SVSE',
        latitude: '4,554999828',
        longitude: '-61,15000153',
        altitude: '2938',
        timezone: '-4',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Mayor Buenaventura Vivas International Airport',
        city: 'Santo Domingo',
        country: 'Venezuela',
        iataCode: 'STD',
        icaoCode: 'SVSO',
        latitude: '7,565380096',
        longitude: '-72,03510284',
        altitude: '1083',
        timezone: '-4',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Sub Teniente Nestor Arias Airport',
        city: 'San Felipe',
        country: 'Venezuela',
        iataCode: 'N',
        icaoCode: 'SVSP',
        latitude: '10,27869987',
        longitude: '-68,75520325',
        altitude: '761',
        timezone: '-4',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'San Fernando De Apure Airport',
        city: 'San Fernando De Apure',
        country: 'Venezuela',
        iataCode: 'SFD',
        icaoCode: 'SVSR',
        latitude: '7,883319855',
        longitude: '-67,44400024',
        altitude: '154',
        timezone: '-4',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'San Tomé Airport',
        city: 'San Tome',
        country: 'Venezuela',
        iataCode: 'SOM',
        icaoCode: 'SVST',
        latitude: '8,945146561',
        longitude: '-64,1510849',
        altitude: '861',
        timezone: '-4',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Santa Bárbara del Zulia Airport',
        city: 'Santa Barbara',
        country: 'Venezuela',
        iataCode: 'STB',
        icaoCode: 'SVSZ',
        latitude: '8,974550247',
        longitude: '-71,94325256',
        altitude: '32',
        timezone: '-4',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Tucupita Airport',
        city: 'Tucupita',
        country: 'Venezuela',
        iataCode: 'TUV',
        icaoCode: 'SVTC',
        latitude: '9,088994026',
        longitude: '-62,09417343',
        altitude: '16',
        timezone: '-4',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Tumeremo Airport',
        city: 'Tumeremo',
        country: 'Venezuela',
        iataCode: 'N',
        icaoCode: 'SVTM',
        latitude: '7,24938',
        longitude: '-61,52893',
        altitude: '345',
        timezone: '-4',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Arturo Michelena International Airport',
        city: 'Valencia',
        country: 'Venezuela',
        iataCode: 'VLN',
        icaoCode: 'SVVA',
        latitude: '10,14973259',
        longitude: '-67,92839813',
        altitude: '1411',
        timezone: '-4',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Dr. Antonio Nicolás Briceño Airport',
        city: 'Valera',
        country: 'Venezuela',
        iataCode: 'VLV',
        icaoCode: 'SVVL',
        latitude: '9,340477943',
        longitude: '-70,58406067',
        altitude: '2060',
        timezone: '-4',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Valle de La Pascua Airport',
        city: 'Valle De La Pascua',
        country: 'Venezuela',
        iataCode: 'VDP',
        icaoCode: 'SVVP',
        latitude: '9,222027779',
        longitude: '-65,99358368',
        altitude: '410',
        timezone: '-4',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Linden Airport',
        city: 'Linden',
        country: 'Guyana',
        iataCode: 'N',
        icaoCode: 'SYLD',
        latitude: '5,965919971',
        longitude: '-58,27030182',
        altitude: '180',
        timezone: '-4',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Lethem Airport',
        city: 'Lethem',
        country: 'Guyana',
        iataCode: 'LTM',
        icaoCode: 'SYLT',
        latitude: '3,372760057',
        longitude: '-59,78939819',
        altitude: '351',
        timezone: '-4',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'V.C. Bird International Airport',
        city: 'Antigua',
        country: 'Antigua and Barbuda',
        iataCode: 'ANU',
        icaoCode: 'TAPA',
        latitude: '17,1367',
        longitude: '-61,792702',
        altitude: '62',
        timezone: '-4',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Sir Grantley Adams International Airport',
        city: 'Bridgetown',
        country: 'Barbados',
        iataCode: 'BGI',
        icaoCode: 'TBPB',
        latitude: '13,07460022',
        longitude: '-59,49250031',
        altitude: '169',
        timezone: '-4',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Canefield Airport',
        city: 'Canefield',
        country: 'Dominica',
        iataCode: 'DCF',
        icaoCode: 'TDCF',
        latitude: '15,33670044',
        longitude: '-61,39220047',
        altitude: '13',
        timezone: '-4',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Melville Hall Airport',
        city: 'Dominica',
        country: 'Dominica',
        iataCode: 'DOM',
        icaoCode: 'TDPD',
        latitude: '15,54699993',
        longitude: '-61,29999924',
        altitude: '73',
        timezone: '-4',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Martinique Aimé Césaire International Airport',
        city: 'Fort-de-france',
        country: 'Martinique',
        iataCode: 'FDF',
        icaoCode: 'TFFF',
        latitude: '14,5909996',
        longitude: '-61,00320053',
        altitude: '16',
        timezone: '-4',
        dst: 'U',
        type: 'airport',
    },
    {
        name: "L'Espérance Airport",
        city: 'St. Martin',
        country: 'Guadeloupe',
        iataCode: 'SFG',
        icaoCode: 'TFFG',
        latitude: '18,09989929',
        longitude: '-63,04719925',
        altitude: '7',
        timezone: '-4',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Pointe-à-Pitre Le Raizet',
        city: 'Pointe-a-pitre',
        country: 'Guadeloupe',
        iataCode: 'PTP',
        icaoCode: 'TFFR',
        latitude: '16,26530075',
        longitude: '-61,53179932',
        altitude: '36',
        timezone: '-4',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Point Salines International Airport',
        city: 'Point Salines',
        country: 'Grenada',
        iataCode: 'GND',
        icaoCode: 'TGPY',
        latitude: '12,00419998',
        longitude: '-61,78620148',
        altitude: '41',
        timezone: '-4',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Cyril E. King Airport',
        city: 'St. Thomas',
        country: 'Virgin Islands',
        iataCode: 'STT',
        icaoCode: 'TIST',
        latitude: '18,33729935',
        longitude: '-64,9733963',
        altitude: '23',
        timezone: '-4',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Henry E Rohlsen Airport',
        city: 'St. Croix Island',
        country: 'Virgin Islands',
        iataCode: 'STX',
        icaoCode: 'TISX',
        latitude: '17,70190048',
        longitude: '-64,79859924',
        altitude: '74',
        timezone: '-4',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Rafael Hernandez Airport',
        city: 'Aguadilla',
        country: 'Puerto Rico',
        iataCode: 'BQN',
        icaoCode: 'TJBQ',
        latitude: '18,49489975',
        longitude: '-67,12940216',
        altitude: '237',
        timezone: '-4',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Diego Jimenez Torres Airport',
        city: 'Fajardo',
        country: 'Puerto Rico',
        iataCode: 'FAJ',
        icaoCode: 'TJFA',
        latitude: '18,30890083',
        longitude: '-65,66190338',
        altitude: '64',
        timezone: '-4',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Fernando Luis Ribas Dominicci Airport',
        city: 'San Juan',
        country: 'Puerto Rico',
        iataCode: 'SIG',
        icaoCode: 'TJIG',
        latitude: '18,45680046',
        longitude: '-66,09809875',
        altitude: '10',
        timezone: '-4',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Eugenio Maria De Hostos Airport',
        city: 'Mayaguez',
        country: 'Puerto Rico',
        iataCode: 'MAZ',
        icaoCode: 'TJMZ',
        latitude: '18,25569916',
        longitude: '-67,14849854',
        altitude: '28',
        timezone: '-4',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Mercedita Airport',
        city: 'Ponce',
        country: 'Puerto Rico',
        iataCode: 'PSE',
        icaoCode: 'TJPS',
        latitude: '18,00830078',
        longitude: '-66,56300354',
        altitude: '29',
        timezone: '-4',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Luis Munoz Marin International Airport',
        city: 'San Juan',
        country: 'Puerto Rico',
        iataCode: 'SJU',
        icaoCode: 'TJSJ',
        latitude: '18,43939972',
        longitude: '-66,00180054',
        altitude: '9',
        timezone: '-4',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Robert L. Bradshaw International Airport',
        city: 'Basse Terre',
        country: 'Saint Kitts and Nevis',
        iataCode: 'SKB',
        icaoCode: 'TKPK',
        latitude: '17,31119919',
        longitude: '-62,71870041',
        altitude: '170',
        timezone: '-4',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'George F. L. Charles Airport',
        city: 'Castries',
        country: 'Saint Lucia',
        iataCode: 'SLU',
        icaoCode: 'TLPC',
        latitude: '14,0202',
        longitude: '-60,992901',
        altitude: '22',
        timezone: '-4',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Hewanorra International Airport',
        city: 'Hewandorra',
        country: 'Saint Lucia',
        iataCode: 'UVF',
        icaoCode: 'TLPL',
        latitude: '13,7332',
        longitude: '-60,952599',
        altitude: '14',
        timezone: '-4',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Queen Beatrix International Airport',
        city: 'Oranjestad',
        country: 'Aruba',
        iataCode: 'AUA',
        icaoCode: 'TNCA',
        latitude: '12,50139999',
        longitude: '-70,01519775',
        altitude: '60',
        timezone: '-4',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Flamingo International Airport',
        city: 'Kralendijk',
        country: 'Netherlands Antilles',
        iataCode: 'BON',
        icaoCode: 'TNCB',
        latitude: '12,13099957',
        longitude: '-68,26850128',
        altitude: '20',
        timezone: '-4',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Hato International Airport',
        city: 'Willemstad',
        country: 'Netherlands Antilles',
        iataCode: 'CUR',
        icaoCode: 'TNCC',
        latitude: '12,1889',
        longitude: '-68,959801',
        altitude: '29',
        timezone: '-4',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'F. D. Roosevelt Airport',
        city: 'Oranjestad',
        country: 'Netherlands Antilles',
        iataCode: 'EUX',
        icaoCode: 'TNCE',
        latitude: '17,49650002',
        longitude: '-62,97940063',
        altitude: '129',
        timezone: '-4',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Princess Juliana International Airport',
        city: 'Philipsburg',
        country: 'Netherlands Antilles',
        iataCode: 'SXM',
        icaoCode: 'TNCM',
        latitude: '18,04100037',
        longitude: '-63,10889816',
        altitude: '13',
        timezone: '-4',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Wallblake Airport',
        city: 'The Valley',
        country: 'Anguilla',
        iataCode: 'AXA',
        icaoCode: 'TQPF',
        latitude: '18,20479965',
        longitude: '-63,05509949',
        altitude: '127',
        timezone: '-4',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Tobago-Crown Point Airport',
        city: 'Scarborough',
        country: 'Trinidad and Tobago',
        iataCode: 'TAB',
        icaoCode: 'TTCP',
        latitude: '11,14970016',
        longitude: '-60,8321991',
        altitude: '38',
        timezone: '-4',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Piarco International Airport',
        city: 'Port-of-spain',
        country: 'Trinidad and Tobago',
        iataCode: 'POS',
        icaoCode: 'TTPP',
        latitude: '10,59539986',
        longitude: '-61,33720016',
        altitude: '58',
        timezone: '-4',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Terrance B. Lettsome International Airport',
        city: 'Tortola',
        country: 'British Virgin Islands',
        iataCode: 'EIS',
        icaoCode: 'TUPJ',
        latitude: '18,44479942',
        longitude: '-64,54299927',
        altitude: '15',
        timezone: '-4',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Canouan Airport',
        city: 'Canouan Island',
        country: 'Saint Vincent and the Grenadines',
        iataCode: 'CIW',
        icaoCode: 'TVSC',
        latitude: '12,69900036',
        longitude: '-61,3423996',
        altitude: '11',
        timezone: '-4',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Mustique Airport',
        city: 'Mustique',
        country: 'Saint Vincent and the Grenadines',
        iataCode: 'MQS',
        icaoCode: 'TVSM',
        latitude: '12,88790035',
        longitude: '-61,18019867',
        altitude: '8',
        timezone: '-4',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'E. T. Joshua Airport',
        city: 'Kingstown',
        country: 'Saint Vincent and the Grenadines',
        iataCode: 'SVD',
        icaoCode: 'TVSV',
        latitude: '13,14430046',
        longitude: '-61,21089935',
        altitude: '66',
        timezone: '-4',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Almaty Airport',
        city: 'Alma-ata',
        country: 'Kazakhstan',
        iataCode: 'ALA',
        icaoCode: 'UAAA',
        latitude: '43,35210037',
        longitude: '77,04049683',
        altitude: '2234',
        timezone: '6',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Balkhash Airport',
        city: 'Balkhash',
        country: 'Kazakhstan',
        iataCode: 'BXH',
        icaoCode: 'UAAH',
        latitude: '46,8932991',
        longitude: '75,00499725',
        altitude: '1446',
        timezone: '6',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Astana International Airport',
        city: 'Tselinograd',
        country: 'Kazakhstan',
        iataCode: 'TSE',
        icaoCode: 'UACC',
        latitude: '51,02220154',
        longitude: '71,46690369',
        altitude: '1165',
        timezone: '6',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Taraz Airport',
        city: 'Dzhambul',
        country: 'Kazakhstan',
        iataCode: 'DMB',
        icaoCode: 'UADD',
        latitude: '42,85359955',
        longitude: '71,3035965',
        altitude: '2184',
        timezone: '6',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Manas International Airport',
        city: 'Bishkek',
        country: 'Kyrgyzstan',
        iataCode: 'FRU',
        icaoCode: 'UAFM',
        latitude: '43,06129837',
        longitude: '74,4776001',
        altitude: '2058',
        timezone: '6',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Osh Airport',
        city: 'Osh',
        country: 'Kyrgyzstan',
        iataCode: 'OSS',
        icaoCode: 'UAFO',
        latitude: '40,60900116',
        longitude: '72,79329681',
        altitude: '2927',
        timezone: '6',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Shymkent Airport',
        city: 'Chimkent',
        country: 'Kazakhstan',
        iataCode: 'CIT',
        icaoCode: 'UAII',
        latitude: '42,36420059',
        longitude: '69,47889709',
        altitude: '1385',
        timezone: '6',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Uralsk Airport',
        city: 'Uralsk',
        country: 'Kazakhstan',
        iataCode: 'URA',
        icaoCode: 'UARR',
        latitude: '51,1507988',
        longitude: '51,54309845',
        altitude: '125',
        timezone: '5',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Pavlodar Airport',
        city: 'Pavlodar',
        country: 'Kazakhstan',
        iataCode: 'PWQ',
        icaoCode: 'UASP',
        latitude: '52,19499969',
        longitude: '77,07389832',
        altitude: '410',
        timezone: '6',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Semipalatinsk Airport',
        city: 'Semiplatinsk',
        country: 'Kazakhstan',
        iataCode: 'PLX',
        icaoCode: 'UASS',
        latitude: '50,35129929',
        longitude: '80,23439789',
        altitude: '761',
        timezone: '6',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Aktobe Airport',
        city: 'Aktyubinsk',
        country: 'Kazakhstan',
        iataCode: 'AKX',
        icaoCode: 'UATT',
        latitude: '50,24580002',
        longitude: '57,20669937',
        altitude: '738',
        timezone: '5',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Heydar Aliyev International Airport',
        city: 'Baku',
        country: 'Azerbaijan',
        iataCode: 'GYD',
        icaoCode: 'UBBB',
        latitude: '40,46749878',
        longitude: '50,04669952',
        altitude: '10',
        timezone: '4',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Yakutsk Airport',
        city: 'Yakutsk',
        country: 'Russia',
        iataCode: 'YKS',
        icaoCode: 'UEEE',
        latitude: '62,09329987',
        longitude: '129,7709961',
        altitude: '325',
        timezone: '9',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Mirny Airport',
        city: 'Mirnyj',
        country: 'Russia',
        iataCode: 'MJZ',
        icaoCode: 'UERR',
        latitude: '62,53469849',
        longitude: '114,0390015',
        altitude: '1156',
        timezone: '9',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Ignatyevo Airport',
        city: 'Blagoveschensk',
        country: 'Russia',
        iataCode: 'BQS',
        icaoCode: 'UHBB',
        latitude: '50,42539978',
        longitude: '127,4120026',
        altitude: '638',
        timezone: '9',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Khabarovsk-Novy Airport',
        city: 'Khabarovsk',
        country: 'Russia',
        iataCode: 'KHV',
        icaoCode: 'UHHH',
        latitude: '48,52799988',
        longitude: '135,1880035',
        altitude: '244',
        timezone: '10',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Provideniya Bay Airport',
        city: 'Provideniya Bay',
        country: 'Russia',
        iataCode: 'PVS',
        icaoCode: 'UHMD',
        latitude: '64,37809753',
        longitude: '-173,2429962',
        altitude: '72',
        timezone: '12',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Sokol Airport',
        city: 'Magadan',
        country: 'Russia',
        iataCode: 'GDX',
        icaoCode: 'UHMM',
        latitude: '59,9109993',
        longitude: '150,7200012',
        altitude: '574',
        timezone: '11',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Pevek Airport',
        city: 'Pevek',
        country: 'Russia',
        iataCode: 'PWE',
        icaoCode: 'UHMP',
        latitude: '69,78330231',
        longitude: '170,5970001',
        altitude: '11',
        timezone: '12',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Yelizovo Airport',
        city: 'Petropavlovsk',
        country: 'Russia',
        iataCode: 'PKC',
        icaoCode: 'UHPP',
        latitude: '53,16790009',
        longitude: '158,4539948',
        altitude: '131',
        timezone: '12',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Yuzhno-Sakhalinsk Airport',
        city: 'Yuzhno-sakhalinsk',
        country: 'Russia',
        iataCode: 'UUS',
        icaoCode: 'UHSS',
        latitude: '46,88869858',
        longitude: '142,7180023',
        altitude: '59',
        timezone: '11',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Vladivostok International Airport',
        city: 'Vladivostok',
        country: 'Russia',
        iataCode: 'VVO',
        icaoCode: 'UHWW',
        latitude: '43,39899826',
        longitude: '132,147995',
        altitude: '46',
        timezone: '10',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Chita-Kadala Airport',
        city: 'Chita',
        country: 'Russia',
        iataCode: 'HTA',
        icaoCode: 'UIAA',
        latitude: '52,026299',
        longitude: '113,306',
        altitude: '2272',
        timezone: '9',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Bratsk Airport',
        city: 'Bratsk',
        country: 'Russia',
        iataCode: 'BTK',
        icaoCode: 'UIBB',
        latitude: '56,37060165',
        longitude: '101,697998',
        altitude: '1610',
        timezone: '8',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Irkutsk Airport',
        city: 'Irkutsk',
        country: 'Russia',
        iataCode: 'IKT',
        icaoCode: 'UIII',
        latitude: '52,26800156',
        longitude: '104,3889999',
        altitude: '1675',
        timezone: '8',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Ulan-Ude Airport (Mukhino)',
        city: 'Ulan-ude',
        country: 'Russia',
        iataCode: 'UUD',
        icaoCode: 'UIUU',
        latitude: '51,80780029',
        longitude: '107,4380035',
        altitude: '1690',
        timezone: '8',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Boryspil International Airport',
        city: 'Kiev',
        country: 'Ukraine',
        iataCode: 'KBP',
        icaoCode: 'UKBB',
        latitude: '50,34500122',
        longitude: '30,8946991',
        altitude: '427',
        timezone: '2',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Donetsk International Airport',
        city: 'Donetsk',
        country: 'Ukraine',
        iataCode: 'DOK',
        icaoCode: 'UKCC',
        latitude: '48,07360077',
        longitude: '37,73970032',
        altitude: '791',
        timezone: '2',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Dnipropetrovsk International Airport',
        city: 'Dnepropetrovsk',
        country: 'Ukraine',
        iataCode: 'DNK',
        icaoCode: 'UKDD',
        latitude: '48,35720062',
        longitude: '35,1006012',
        altitude: '481',
        timezone: '2',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Simferopol International Airport',
        city: 'Simferopol',
        country: 'Ukraine',
        iataCode: 'SIP',
        icaoCode: 'UKFF',
        latitude: '45,05220032',
        longitude: '33,97510147',
        altitude: '639',
        timezone: '3',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Kiev Zhuliany International Airport',
        city: 'Kiev',
        country: 'Ukraine',
        iataCode: 'IEV',
        icaoCode: 'UKKK',
        latitude: '50,40194',
        longitude: '30,45194',
        altitude: '587',
        timezone: '2',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Lviv International Airport',
        city: 'Lvov',
        country: 'Ukraine',
        iataCode: 'LWO',
        icaoCode: 'UKLL',
        latitude: '49,8125',
        longitude: '23,95610046',
        altitude: '1071',
        timezone: '2',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Odessa International Airport',
        city: 'Odessa',
        country: 'Ukraine',
        iataCode: 'ODS',
        icaoCode: 'UKOO',
        latitude: '46,42679977',
        longitude: '30,67650032',
        altitude: '172',
        timezone: '2',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Pulkovo Airport',
        city: 'St. Petersburg',
        country: 'Russia',
        iataCode: 'LED',
        icaoCode: 'ULLI',
        latitude: '59,8003006',
        longitude: '30,26250076',
        altitude: '78',
        timezone: '3',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Murmansk Airport',
        city: 'Murmansk',
        country: 'Russia',
        iataCode: 'MMK',
        icaoCode: 'ULMM',
        latitude: '68,78170013',
        longitude: '32,75080109',
        altitude: '266',
        timezone: '3',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Gomel Airport',
        city: 'Gomel',
        country: 'Belarus',
        iataCode: 'GME',
        icaoCode: 'UMGG',
        latitude: '52,52700043',
        longitude: '31,01670074',
        altitude: '472',
        timezone: '3',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Vitebsk Vostochny Airport',
        city: 'Vitebsk',
        country: 'Belarus',
        iataCode: 'VTB',
        icaoCode: 'UMII',
        latitude: '55,12649918',
        longitude: '30,34959984',
        altitude: '682',
        timezone: '3',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Khrabrovo Airport',
        city: 'Kaliningrad',
        country: 'Russia',
        iataCode: 'KGD',
        icaoCode: 'UMKK',
        latitude: '54,88999939',
        longitude: '20,59259987',
        altitude: '42',
        timezone: '2',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Minsk 1 Airport',
        city: 'Minsk',
        country: 'Belarus',
        iataCode: 'MHP',
        icaoCode: 'UMMM',
        latitude: '53,86449814',
        longitude: '27,53969955',
        altitude: '748',
        timezone: '3',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Minsk National Airport',
        city: 'Minsk 2',
        country: 'Belarus',
        iataCode: 'MSQ',
        icaoCode: 'UMMS',
        latitude: '53,88249969',
        longitude: '28,03070068',
        altitude: '670',
        timezone: '3',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Abakan Airport',
        city: 'Abakan',
        country: 'Russia',
        iataCode: 'ABA',
        icaoCode: 'UNAA',
        latitude: '53,74000168',
        longitude: '91,38500214',
        altitude: '831',
        timezone: '7',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Barnaul Airport',
        city: 'Barnaul',
        country: 'Russia',
        iataCode: 'BAX',
        icaoCode: 'UNBB',
        latitude: '53,36380005',
        longitude: '83,53849792',
        altitude: '837',
        timezone: '7',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Kemerovo Airport',
        city: 'Kemorovo',
        country: 'Russia',
        iataCode: 'KEJ',
        icaoCode: 'UNEE',
        latitude: '55,27009964',
        longitude: '86,10720062',
        altitude: '863',
        timezone: '7',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Omsk Central Airport',
        city: 'Omsk',
        country: 'Russia',
        iataCode: 'OMS',
        icaoCode: 'UNOO',
        latitude: '54,96699905',
        longitude: '73,3105011',
        altitude: '311',
        timezone: '6',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Krasnodar Pashkovsky International Airport',
        city: 'Krasnodar',
        country: 'Russia',
        iataCode: 'KRR',
        icaoCode: 'URKK',
        latitude: '45,03469849',
        longitude: '39,17050171',
        altitude: '118',
        timezone: '3',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Uytash Airport',
        city: 'Makhachkala',
        country: 'Russia',
        iataCode: 'MCX',
        icaoCode: 'URML',
        latitude: '42,81679916',
        longitude: '47,65230179',
        altitude: '12',
        timezone: '3',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Mineralnyye Vody Airport',
        city: 'Mineralnye Vody',
        country: 'Russia',
        iataCode: 'MRV',
        icaoCode: 'URMM',
        latitude: '44,22510147',
        longitude: '43,08190155',
        altitude: '1054',
        timezone: '3',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Stavropol Shpakovskoye Airport',
        city: 'Stavropol',
        country: 'Russia',
        iataCode: 'STW',
        icaoCode: 'URMT',
        latitude: '45,10919952',
        longitude: '42,1128006',
        altitude: '1486',
        timezone: '3',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Rostov-on-Don Airport',
        city: 'Rostov',
        country: 'Russia',
        iataCode: 'ROV',
        icaoCode: 'URRR',
        latitude: '47,2582016',
        longitude: '39,81809998',
        altitude: '280',
        timezone: '3',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Sochi International Airport',
        city: 'Sochi',
        country: 'Russia',
        iataCode: 'AER',
        icaoCode: 'URSS',
        latitude: '43,44990158',
        longitude: '39,95660019',
        altitude: '89',
        timezone: '3',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Astrakhan Airport',
        city: 'Astrakhan',
        country: 'Russia',
        iataCode: 'ASF',
        icaoCode: 'URWA',
        latitude: '46,28329849',
        longitude: '48,00630188',
        altitude: '-65',
        timezone: '4',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Volgograd International Airport',
        city: 'Volgograd',
        country: 'Russia',
        iataCode: 'VOG',
        icaoCode: 'URWW',
        latitude: '48,78250122',
        longitude: '44,34550095',
        altitude: '482',
        timezone: '3',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Chelyabinsk Balandino Airport',
        city: 'Chelyabinsk',
        country: 'Russia',
        iataCode: 'CEK',
        icaoCode: 'USCC',
        latitude: '55,305801',
        longitude: '61,5033',
        altitude: '769',
        timezone: '5',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Magnitogorsk International Airport',
        city: 'Magnetiogorsk',
        country: 'Russia',
        iataCode: 'MQF',
        icaoCode: 'USCM',
        latitude: '53,39310074',
        longitude: '58,75569916',
        altitude: '1430',
        timezone: '5',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Nizhnevartovsk Airport',
        city: 'Nizhnevartovsk',
        country: 'Russia',
        iataCode: 'NJC',
        icaoCode: 'USNN',
        latitude: '60,94929886',
        longitude: '76,4835968',
        altitude: '177',
        timezone: '5',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Bolshoye Savino Airport',
        city: 'Perm',
        country: 'Russia',
        iataCode: 'PEE',
        icaoCode: 'USPP',
        latitude: '57,91450119',
        longitude: '56,02119827',
        altitude: '404',
        timezone: '5',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Surgut Airport',
        city: 'Surgut',
        country: 'Russia',
        iataCode: 'SGC',
        icaoCode: 'USRR',
        latitude: '61,34370041',
        longitude: '73,40180206',
        altitude: '200',
        timezone: '5',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Koltsovo Airport',
        city: 'Yekaterinburg',
        country: 'Russia',
        iataCode: 'SVX',
        icaoCode: 'USSS',
        latitude: '56,74309921',
        longitude: '60,80270004',
        altitude: '764',
        timezone: '5',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Ashgabat Airport',
        city: 'Ashkhabad',
        country: 'Turkmenistan',
        iataCode: 'ASB',
        icaoCode: 'UTAA',
        latitude: '37,98680115',
        longitude: '58,36100006',
        altitude: '692',
        timezone: '5',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Turkmenbashi Airport',
        city: 'Krasnovodsk',
        country: 'Turkmenistan',
        iataCode: 'KRW',
        icaoCode: 'UTAK',
        latitude: '40,063301',
        longitude: '53,007198',
        altitude: '279',
        timezone: '5',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Turkmenabat Airport',
        city: 'Chardzhou',
        country: 'Turkmenistan',
        iataCode: 'CRZ',
        icaoCode: 'UTAV',
        latitude: '39,08330154',
        longitude: '63,61330032',
        altitude: '630',
        timezone: '5',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Dushanbe Airport',
        city: 'Dushanbe',
        country: 'Tajikistan',
        iataCode: 'DYU',
        icaoCode: 'UTDD',
        latitude: '38,54330063',
        longitude: '68,82499695',
        altitude: '2575',
        timezone: '5',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Bukhara Airport',
        city: 'Bukhara',
        country: 'Uzbekistan',
        iataCode: 'BHK',
        icaoCode: 'UTSB',
        latitude: '39,77500153',
        longitude: '64,48329926',
        altitude: '751',
        timezone: '5',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Samarkand Airport',
        city: 'Samarkand',
        country: 'Uzbekistan',
        iataCode: 'SKD',
        icaoCode: 'UTSS',
        latitude: '39,70050049',
        longitude: '66,9838028',
        altitude: '2224',
        timezone: '5',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Tashkent International Airport',
        city: 'Tashkent',
        country: 'Uzbekistan',
        iataCode: 'TAS',
        icaoCode: 'UTTT',
        latitude: '41,25790024',
        longitude: '69,28119659',
        altitude: '1417',
        timezone: '5',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Bryansk Airport',
        city: 'Bryansk',
        country: 'Russia',
        iataCode: 'BZK',
        icaoCode: 'UUBP',
        latitude: '53,21419907',
        longitude: '34,17639923',
        altitude: '663',
        timezone: '3',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Sheremetyevo International Airport',
        city: 'Moscow',
        country: 'Russia',
        iataCode: 'SVO',
        icaoCode: 'UUEE',
        latitude: '55,972599',
        longitude: '37,4146',
        altitude: '622',
        timezone: '3',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Migalovo Air Base',
        city: 'Tver',
        country: 'Russia',
        iataCode: 'KLD',
        icaoCode: 'UUEM',
        latitude: '56,8246994',
        longitude: '35,75770187',
        altitude: '469',
        timezone: '3',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Voronezh International Airport',
        city: 'Voronezh',
        country: 'Russia',
        iataCode: 'VOZ',
        icaoCode: 'UUOO',
        latitude: '51,81420135',
        longitude: '39,229599',
        altitude: '514',
        timezone: '3',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Vnukovo International Airport',
        city: 'Moscow',
        country: 'Russia',
        iataCode: 'VKO',
        icaoCode: 'UUWW',
        latitude: '55,59149933',
        longitude: '37,26150131',
        altitude: '685',
        timezone: '3',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Syktyvkar Airport',
        city: 'Syktyvkar',
        country: 'Russia',
        iataCode: 'SCW',
        icaoCode: 'UUYY',
        latitude: '61,64699936',
        longitude: '50,8451004',
        altitude: '342',
        timezone: '3',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Kazan International Airport',
        city: 'Kazan',
        country: 'Russia',
        iataCode: 'KZN',
        icaoCode: 'UWKD',
        latitude: '55,60620117',
        longitude: '49,27870178',
        altitude: '411',
        timezone: '3',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Orenburg Central Airport',
        city: 'Orenburg',
        country: 'Russia',
        iataCode: 'REN',
        icaoCode: 'UWOO',
        latitude: '51,79579926',
        longitude: '55,45669937',
        altitude: '387',
        timezone: '5',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Ufa International Airport',
        city: 'Ufa',
        country: 'Russia',
        iataCode: 'UFA',
        icaoCode: 'UWUU',
        latitude: '54,55749893',
        longitude: '55,87440109',
        altitude: '449',
        timezone: '5',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Kurumoch International Airport',
        city: 'Samara',
        country: 'Russia',
        iataCode: 'KUF',
        icaoCode: 'UWWW',
        latitude: '53,50490189',
        longitude: '50,16429901',
        altitude: '477',
        timezone: '4',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Sardar Vallabhbhai Patel International Airport',
        city: 'Ahmedabad',
        country: 'India',
        iataCode: 'AMD',
        icaoCode: 'VAAH',
        latitude: '23,07719994',
        longitude: '72,63469696',
        altitude: '189',
        timezone: '5,5',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Akola Airport',
        city: 'Akola',
        country: 'India',
        iataCode: 'AKD',
        icaoCode: 'VAAK',
        latitude: '20,6989994',
        longitude: '77,05860138',
        altitude: '999',
        timezone: '5,5',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Aurangabad Airport',
        city: 'Aurangabad',
        country: 'India',
        iataCode: 'IXU',
        icaoCode: 'VAAU',
        latitude: '19,86269951',
        longitude: '75,39810181',
        altitude: '1911',
        timezone: '5,5',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Chhatrapati Shivaji International Airport',
        city: 'Mumbai',
        country: 'India',
        iataCode: 'BOM',
        icaoCode: 'VABB',
        latitude: '19,08869934',
        longitude: '72,86789703',
        altitude: '39',
        timezone: '5,5',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Bilaspur Airport',
        city: 'Bilaspur',
        country: 'India',
        iataCode: 'PAB',
        icaoCode: 'VABI',
        latitude: '21,98839951',
        longitude: '82,11100006',
        altitude: '899',
        timezone: '5,5',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Bhuj Airport',
        city: 'Bhuj',
        country: 'India',
        iataCode: 'BHJ',
        icaoCode: 'VABJ',
        latitude: '23,28779984',
        longitude: '69,67019653',
        altitude: '268',
        timezone: '5,5',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Belgaum Airport',
        city: 'Belgaum',
        country: 'India',
        iataCode: 'IXG',
        icaoCode: 'VABM',
        latitude: '15,85929966',
        longitude: '74,61830139',
        altitude: '2487',
        timezone: '5,5',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Vadodara Airport',
        city: 'Baroda',
        country: 'India',
        iataCode: 'BDQ',
        icaoCode: 'VABO',
        latitude: '22,33620071',
        longitude: '73,2263031',
        altitude: '129',
        timezone: '5,5',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Raja Bhoj International Airport',
        city: 'Bhopal',
        country: 'India',
        iataCode: 'BHO',
        icaoCode: 'VABP',
        latitude: '23,28750038',
        longitude: '77,33740234',
        altitude: '1711',
        timezone: '5,5',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Bhavnagar Airport',
        city: 'Bhaunagar',
        country: 'India',
        iataCode: 'BHU',
        icaoCode: 'VABV',
        latitude: '21,75219917',
        longitude: '72,18520355',
        altitude: '44',
        timezone: '5,5',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Daman Airport',
        city: 'Daman',
        country: 'India',
        iataCode: 'NMB',
        icaoCode: 'VADN',
        latitude: '20,43440056',
        longitude: '72,84320068',
        altitude: '33',
        timezone: '5,5',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Deesa Airport',
        city: 'Deesa',
        country: 'India',
        iataCode: 'N',
        icaoCode: 'VADS',
        latitude: '24,26790047',
        longitude: '72,20439911',
        altitude: '485',
        timezone: '5,5',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Guna Airport',
        city: 'Guna',
        country: 'India',
        iataCode: 'N',
        icaoCode: 'VAGN',
        latitude: '24,65469933',
        longitude: '77,34729767',
        altitude: '1600',
        timezone: '5,5',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Dabolim Airport',
        city: 'Goa',
        country: 'India',
        iataCode: 'GOI',
        icaoCode: 'VAGO',
        latitude: '15,38080025',
        longitude: '73,83139801',
        altitude: '150',
        timezone: '5,5',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Devi Ahilyabai Holkar Airport',
        city: 'Indore',
        country: 'India',
        iataCode: 'IDR',
        icaoCode: 'VAID',
        latitude: '22,72179985',
        longitude: '75,80110168',
        altitude: '1850',
        timezone: '5,5',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Jabalpur Airport',
        city: 'Jabalpur',
        country: 'India',
        iataCode: 'JLR',
        icaoCode: 'VAJB',
        latitude: '23,17779922',
        longitude: '80,05200195',
        altitude: '1624',
        timezone: '5,5',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Jamnagar Airport',
        city: 'Jamnagar',
        country: 'India',
        iataCode: 'JGA',
        icaoCode: 'VAJM',
        latitude: '22,46549988',
        longitude: '70,01260376',
        altitude: '69',
        timezone: '5,5',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Kandla Airport',
        city: 'Kandla',
        country: 'India',
        iataCode: 'IXY',
        icaoCode: 'VAKE',
        latitude: '23,11269951',
        longitude: '70,10030365',
        altitude: '96',
        timezone: '5,5',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Khajuraho Airport',
        city: 'Khajuraho',
        country: 'India',
        iataCode: 'HJR',
        icaoCode: 'VAKJ',
        latitude: '24,81719971',
        longitude: '79,91860199',
        altitude: '728',
        timezone: '5,5',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Kolhapur Airport',
        city: 'Kolhapur',
        country: 'India',
        iataCode: 'KLH',
        icaoCode: 'VAKP',
        latitude: '16,66469955',
        longitude: '74,28939819',
        altitude: '1996',
        timezone: '5,5',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Keshod Airport',
        city: 'Keshod',
        country: 'India',
        iataCode: 'IXK',
        icaoCode: 'VAKS',
        latitude: '21,31710052',
        longitude: '70,270401',
        altitude: '167',
        timezone: '5,5',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Dr. Babasaheb Ambedkar International Airport',
        city: 'Nagpur',
        country: 'India',
        iataCode: 'NAG',
        icaoCode: 'VANP',
        latitude: '21,09219933',
        longitude: '79,04720306',
        altitude: '1033',
        timezone: '5,5',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Gandhinagar Airport',
        city: 'Nasik Road',
        country: 'India',
        iataCode: 'ISK',
        icaoCode: 'VANR',
        latitude: '19,96369934',
        longitude: '73,80760193',
        altitude: '1959',
        timezone: '5,5',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Pune Airport',
        city: 'Pune',
        country: 'India',
        iataCode: 'PNQ',
        icaoCode: 'VAPO',
        latitude: '18,58209991',
        longitude: '73,91970062',
        altitude: '1942',
        timezone: '5,5',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Porbandar Airport',
        city: 'Porbandar',
        country: 'India',
        iataCode: 'PBD',
        icaoCode: 'VAPR',
        latitude: '21,64870071',
        longitude: '69,65720367',
        altitude: '23',
        timezone: '5,5',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Rajkot Airport',
        city: 'Rajkot',
        country: 'India',
        iataCode: 'RAJ',
        icaoCode: 'VARK',
        latitude: '22,30920029',
        longitude: '70,77950287',
        altitude: '441',
        timezone: '5,5',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Raipur Airport',
        city: 'Raipur',
        country: 'India',
        iataCode: 'RPR',
        icaoCode: 'VARP',
        latitude: '21,18040085',
        longitude: '81,73880005',
        altitude: '1041',
        timezone: '5,5',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Solapur Airport',
        city: 'Sholapur',
        country: 'India',
        iataCode: 'SSE',
        icaoCode: 'VASL',
        latitude: '17,62800026',
        longitude: '75,93479919',
        altitude: '1584',
        timezone: '5,5',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Surat Airport',
        city: 'Surat',
        country: 'India',
        iataCode: 'STV',
        icaoCode: 'VASU',
        latitude: '21,1140995',
        longitude: '72,7417984',
        altitude: '16',
        timezone: '5,5',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Maharana Pratap Airport',
        city: 'Udaipur',
        country: 'India',
        iataCode: 'UDR',
        icaoCode: 'VAUD',
        latitude: '24,61770058',
        longitude: '73,89610291',
        altitude: '1684',
        timezone: '5,5',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Bandaranaike International Colombo Airport',
        city: 'Colombo',
        country: 'Sri Lanka',
        iataCode: 'CMB',
        icaoCode: 'VCBI',
        latitude: '7,180759907',
        longitude: '79,88410187',
        altitude: '30',
        timezone: '5,5',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Anuradhapura Air Force Base',
        city: 'Anuradhapura',
        country: 'Sri Lanka',
        iataCode: 'ACJ',
        icaoCode: 'VCCA',
        latitude: '8,30148983',
        longitude: '80,42790222',
        altitude: '324',
        timezone: '5,5',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Batticaloa Airport',
        city: 'Batticaloa',
        country: 'Sri Lanka',
        iataCode: 'N',
        icaoCode: 'VCCB',
        latitude: '7,70576',
        longitude: '81,678802',
        altitude: '20',
        timezone: '5,5',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Colombo Ratmalana Airport',
        city: 'Colombo',
        country: 'Sri Lanka',
        iataCode: 'RML',
        icaoCode: 'VCCC',
        latitude: '6,821990013',
        longitude: '79,88619995',
        altitude: '22',
        timezone: '5,5',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Ampara Airport',
        city: 'Galoya',
        country: 'Sri Lanka',
        iataCode: 'GOY',
        icaoCode: 'VCCG',
        latitude: '7,33776',
        longitude: '81,62594',
        altitude: '150',
        timezone: '5,5',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Kankesanturai Airport',
        city: 'Jaffna',
        country: 'Sri Lanka',
        iataCode: 'JAF',
        icaoCode: 'VCCJ',
        latitude: '9,792329788',
        longitude: '80,07009888',
        altitude: '33',
        timezone: '5,5',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'China Bay Airport',
        city: 'Trinciomalee',
        country: 'Sri Lanka',
        iataCode: 'TRR',
        icaoCode: 'VCCT',
        latitude: '8,538510323',
        longitude: '81,18190002',
        altitude: '6',
        timezone: '5,5',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Kampong Chhnang Airport',
        city: 'Kompong Chnang',
        country: 'Cambodia',
        iataCode: 'N',
        icaoCode: 'VDKH',
        latitude: '12,25520039',
        longitude: '104,564003',
        altitude: '56',
        timezone: '7',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Phnom Penh International Airport',
        city: 'Phnom-penh',
        country: 'Cambodia',
        iataCode: 'PNH',
        icaoCode: 'VDPP',
        latitude: '11,54660034',
        longitude: '104,8440018',
        altitude: '40',
        timezone: '7',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Siem Reap International Airport',
        city: 'Siem-reap',
        country: 'Cambodia',
        iataCode: 'REP',
        icaoCode: 'VDSR',
        latitude: '13,41069984',
        longitude: '103,8130035',
        altitude: '60',
        timezone: '7',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Stung Treng Airport',
        city: 'Stung Treng',
        country: 'Cambodia',
        iataCode: 'TNX',
        icaoCode: 'VDST',
        latitude: '13,53190041',
        longitude: '106,0149994',
        altitude: '203',
        timezone: '7',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Along Airport',
        city: 'Along',
        country: 'India',
        iataCode: 'N',
        icaoCode: 'VEAN',
        latitude: '28,1753006',
        longitude: '94,80200195',
        altitude: '900',
        timezone: '5,5',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Agartala Airport',
        city: 'Agartala',
        country: 'India',
        iataCode: 'IXA',
        icaoCode: 'VEAT',
        latitude: '23,88699913',
        longitude: '91,24040222',
        altitude: '46',
        timezone: '5,5',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Lengpui Airport',
        city: 'Aizwal',
        country: 'India',
        iataCode: 'AJL',
        icaoCode: 'VELP',
        latitude: '23,84059906',
        longitude: '92,61969757',
        altitude: '1398',
        timezone: '5,5',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Bagdogra Airport',
        city: 'Baghdogra',
        country: 'India',
        iataCode: 'IXB',
        icaoCode: 'VEBD',
        latitude: '26,68120003',
        longitude: '88,32859802',
        altitude: '412',
        timezone: '5,5',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Bokaro Airport',
        city: 'Bokaro',
        country: 'India',
        iataCode: 'N',
        icaoCode: 'VEBK',
        latitude: '23,64349937',
        longitude: '86,14890289',
        altitude: '715',
        timezone: '5,5',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Biju Patnaik Airport',
        city: 'Bhubaneswar',
        country: 'India',
        iataCode: 'BBI',
        icaoCode: 'VEBS',
        latitude: '20,24440002',
        longitude: '85,81780243',
        altitude: '138',
        timezone: '5,5',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Netaji Subhash Chandra Bose International Airport',
        city: 'Kolkata',
        country: 'India',
        iataCode: 'CCU',
        icaoCode: 'VECC',
        latitude: '22,65469933',
        longitude: '88,44670105',
        altitude: '16',
        timezone: '5,5',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Cooch Behar Airport',
        city: 'Cooch-behar',
        country: 'India',
        iataCode: 'COH',
        icaoCode: 'VECO',
        latitude: '26,33049965',
        longitude: '89,46720123',
        altitude: '138',
        timezone: '5,5',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Dhanbad Airport',
        city: 'Dhanbad',
        country: 'India',
        iataCode: 'DBD',
        icaoCode: 'VEDB',
        latitude: '23,83399963',
        longitude: '86,4253006',
        altitude: '847',
        timezone: '5,5',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Gaya Airport',
        city: 'Gaya',
        country: 'India',
        iataCode: 'GAY',
        icaoCode: 'VEGY',
        latitude: '24,74430084',
        longitude: '84,95120239',
        altitude: '380',
        timezone: '5,5',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Hirakud Airport',
        city: 'Hirakud',
        country: 'India',
        iataCode: 'N',
        icaoCode: 'VEHK',
        latitude: '21,5802002',
        longitude: '84,00569916',
        altitude: '658',
        timezone: '5,5',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Imphal Airport',
        city: 'Imphal',
        country: 'India',
        iataCode: 'IMF',
        icaoCode: 'VEIM',
        latitude: '24,76000023',
        longitude: '93,896698',
        altitude: '2540',
        timezone: '5,5',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Jharsuguda Airport',
        city: 'Jharsuguda',
        country: 'India',
        iataCode: 'N',
        icaoCode: 'VEJH',
        latitude: '21,91349983',
        longitude: '84,05039978',
        altitude: '751',
        timezone: '5,5',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Jamshedpur Airport',
        city: 'Jamshedpur',
        country: 'India',
        iataCode: 'IXW',
        icaoCode: 'VEJS',
        latitude: '22,8132',
        longitude: '86,16880035',
        altitude: '475',
        timezone: '5,5',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Jorhat Airport',
        city: 'Jorhat',
        country: 'India',
        iataCode: 'JRH',
        icaoCode: 'VEJT',
        latitude: '26,73150063',
        longitude: '94,17549896',
        altitude: '311',
        timezone: '5,5',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Kailashahar Airport',
        city: 'Kailashahar',
        country: 'India',
        iataCode: 'IXH',
        icaoCode: 'VEKR',
        latitude: '24,30820084',
        longitude: '92,00720215',
        altitude: '79',
        timezone: '5,5',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Silchar Airport',
        city: 'Silchar',
        country: 'India',
        iataCode: 'IXS',
        icaoCode: 'VEKU',
        latitude: '24,91290092',
        longitude: '92,97869873',
        altitude: '352',
        timezone: '5,5',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'North Lakhimpur Airport',
        city: 'Lilabari',
        country: 'India',
        iataCode: 'IXI',
        icaoCode: 'VELR',
        latitude: '27,2954998',
        longitude: '94,09760284',
        altitude: '330',
        timezone: '5,5',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Dibrugarh Airport',
        city: 'Mohanbari',
        country: 'India',
        iataCode: 'DIB',
        icaoCode: 'VEMN',
        latitude: '27,48390007',
        longitude: '95,01689911',
        altitude: '362',
        timezone: '5,5',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Muzaffarpur Airport',
        city: 'Mazuffarpur',
        country: 'India',
        iataCode: 'N',
        icaoCode: 'VEMZ',
        latitude: '26,11910057',
        longitude: '85,31369781',
        altitude: '174',
        timezone: '5,5',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Nawapara Airport',
        city: 'Nawapara',
        country: 'India',
        iataCode: 'N',
        icaoCode: 'VENP',
        latitude: '20,87000084',
        longitude: '82,51959991',
        altitude: '1058',
        timezone: '5,5',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Lok Nayak Jayaprakash Airport',
        city: 'Patina',
        country: 'India',
        iataCode: 'PAT',
        icaoCode: 'VEPT',
        latitude: '25,59129906',
        longitude: '85,08799744',
        altitude: '170',
        timezone: '5,5',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Purnea Airport',
        city: 'Purnea',
        country: 'India',
        iataCode: 'N',
        icaoCode: 'VEPU',
        latitude: '25,75959969',
        longitude: '87,41000366',
        altitude: '129',
        timezone: '5,5',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Birsa Munda Airport',
        city: 'Ranchi',
        country: 'India',
        iataCode: 'IXR',
        icaoCode: 'VERC',
        latitude: '23,31430054',
        longitude: '85,32170105',
        altitude: '2148',
        timezone: '5,5',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Rourkela Airport',
        city: 'Rourkela',
        country: 'India',
        iataCode: 'RRK',
        icaoCode: 'VERK',
        latitude: '22,25670052',
        longitude: '84,81459808',
        altitude: '659',
        timezone: '5,5',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Utkela Airport',
        city: 'Utkela',
        country: 'India',
        iataCode: 'N',
        icaoCode: 'VEUK',
        latitude: '20,09740067',
        longitude: '83,18379974',
        altitude: '680',
        timezone: '5,5',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Vishakhapatnam Airport',
        city: 'Vishakhapatnam',
        country: 'India',
        iataCode: 'VTZ',
        icaoCode: 'VEVZ',
        latitude: '17,72120094',
        longitude: '83,22450256',
        altitude: '15',
        timezone: '5,5',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Zero Airport',
        city: 'Zero',
        country: 'India',
        iataCode: 'N',
        icaoCode: 'VEZO',
        latitude: '27,5883007',
        longitude: '93,82810211',
        altitude: '5403',
        timezone: '5,5',
        dst: 'N',
        type: 'airport',
    },
    {
        name: "Cox's Bazar Airport",
        city: "Cox's Bazar",
        country: 'Bangladesh',
        iataCode: 'CXB',
        icaoCode: 'VGCB',
        latitude: '21,45219994',
        longitude: '91,96389771',
        altitude: '12',
        timezone: '6',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Shah Amanat International Airport',
        city: 'Chittagong',
        country: 'Bangladesh',
        iataCode: 'CGP',
        icaoCode: 'VGEG',
        latitude: '22,24959946',
        longitude: '91,81330109',
        altitude: '12',
        timezone: '6',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Ishurdi Airport',
        city: 'Ishurdi',
        country: 'Bangladesh',
        iataCode: 'IRD',
        icaoCode: 'VGIS',
        latitude: '24,15250015',
        longitude: '89,04940033',
        altitude: '45',
        timezone: '6',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Jessore Airport',
        city: 'Jessore',
        country: 'Bangladesh',
        iataCode: 'JSR',
        icaoCode: 'VGJR',
        latitude: '23,18379974',
        longitude: '89,16079712',
        altitude: '20',
        timezone: '6',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Shah Mokhdum Airport',
        city: 'Rajshahi',
        country: 'Bangladesh',
        iataCode: 'RJH',
        icaoCode: 'VGRJ',
        latitude: '24,43720055',
        longitude: '88,61650085',
        altitude: '64',
        timezone: '6',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Saidpur Airport',
        city: 'Saidpur',
        country: 'Bangladesh',
        iataCode: 'SPD',
        icaoCode: 'VGSD',
        latitude: '25,75919914',
        longitude: '88,9088974',
        altitude: '125',
        timezone: '6',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Osmany International Airport',
        city: 'Sylhet Osmani',
        country: 'Bangladesh',
        iataCode: 'ZYL',
        icaoCode: 'VGSY',
        latitude: '24,96319962',
        longitude: '91,8667984',
        altitude: '50',
        timezone: '6',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Tejgaon Airport',
        city: 'Dhaka',
        country: 'Bangladesh',
        iataCode: 'N',
        icaoCode: 'VGTJ',
        latitude: '23,77879906',
        longitude: '90,38269806',
        altitude: '24',
        timezone: '6',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Dhaka / Hazrat Shahjalal International Airport',
        city: 'Dhaka',
        country: 'Bangladesh',
        iataCode: 'DAC',
        icaoCode: 'VGZR',
        latitude: '23,843347',
        longitude: '90,397783',
        altitude: '30',
        timezone: '6',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Chek Lap Kok International Airport',
        city: 'Hong Kong',
        country: 'Hong Kong',
        iataCode: 'HKG',
        icaoCode: 'VHHH',
        latitude: '22,30890083',
        longitude: '113,9150009',
        altitude: '28',
        timezone: '8',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Shek Kong Air Base',
        city: 'Sek Kong',
        country: 'Hong Kong',
        iataCode: 'N',
        icaoCode: 'VHSK',
        latitude: '22,43659973',
        longitude: '114,0800018',
        altitude: '50',
        timezone: '8',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Agra Airport',
        city: 'Agra',
        country: 'India',
        iataCode: 'AGR',
        icaoCode: 'VIAG',
        latitude: '27,15579987',
        longitude: '77,96089935',
        altitude: '551',
        timezone: '5,5',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Allahabad Airport',
        city: 'Allahabad',
        country: 'India',
        iataCode: 'IXD',
        icaoCode: 'VIAL',
        latitude: '25,44009972',
        longitude: '81,73390198',
        altitude: '322',
        timezone: '5,5',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Sri Guru Ram Dass Jee International Airport',
        city: 'Amritsar',
        country: 'India',
        iataCode: 'ATQ',
        icaoCode: 'VIAR',
        latitude: '31,70960045',
        longitude: '74,79730225',
        altitude: '756',
        timezone: '5,5',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Nal Airport',
        city: 'Bikaner',
        country: 'India',
        iataCode: 'N',
        icaoCode: 'VIBK',
        latitude: '28,07060051',
        longitude: '73,2071991',
        altitude: '750',
        timezone: '5,5',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Lal Bahadur Shastri Airport',
        city: 'Varanasi',
        country: 'India',
        iataCode: 'VNS',
        icaoCode: 'VIBN',
        latitude: '25,45240021',
        longitude: '82,85929871',
        altitude: '266',
        timezone: '5,5',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Kullu Manali Airport',
        city: 'Kulu',
        country: 'India',
        iataCode: 'KUU',
        icaoCode: 'VIBR',
        latitude: '31,87669945',
        longitude: '77,15440369',
        altitude: '3573',
        timezone: '5,5',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Bhiwani Airport',
        city: 'Bhiwani',
        country: 'India',
        iataCode: 'N',
        icaoCode: 'VIBW',
        latitude: '28,83699989',
        longitude: '76,17910004',
        altitude: '720',
        timezone: '5,5',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Chandigarh Airport',
        city: 'Chandigarh',
        country: 'India',
        iataCode: 'IXC',
        icaoCode: 'VICG',
        latitude: '30,67350006',
        longitude: '76,78849792',
        altitude: '1012',
        timezone: '5,5',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Safdarjung Airport',
        city: 'Delhi',
        country: 'India',
        iataCode: 'N',
        icaoCode: 'VIDD',
        latitude: '28,58449936',
        longitude: '77,20580292',
        altitude: '705',
        timezone: '5,5',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Dehradun Airport',
        city: 'Dehra Dun',
        country: 'India',
        iataCode: 'DED',
        icaoCode: 'VIDN',
        latitude: '30,18969917',
        longitude: '78,18029785',
        altitude: '1831',
        timezone: '5,5',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Indira Gandhi International Airport',
        city: 'Delhi',
        country: 'India',
        iataCode: 'DEL',
        icaoCode: 'VIDP',
        latitude: '28,56649971',
        longitude: '77,10310364',
        altitude: '777',
        timezone: '5,5',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Gwalior Airport',
        city: 'Gwalior',
        country: 'India',
        iataCode: 'GWL',
        icaoCode: 'VIGR',
        latitude: '26,29330063',
        longitude: '78,22779846',
        altitude: '617',
        timezone: '5,5',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Hissar Airport',
        city: 'Hissar',
        country: 'India',
        iataCode: 'N',
        icaoCode: 'VIHR',
        latitude: '29,17939949',
        longitude: '75,75530243',
        altitude: '700',
        timezone: '5,5',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Jhansi Airport',
        city: 'Jhansi',
        country: 'India',
        iataCode: 'N',
        icaoCode: 'VIJN',
        latitude: '25,49119949',
        longitude: '78,55840302',
        altitude: '801',
        timezone: '5,5',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Jodhpur Airport',
        city: 'Jodhpur',
        country: 'India',
        iataCode: 'JDH',
        icaoCode: 'VIJO',
        latitude: '26,25110054',
        longitude: '73,04889679',
        altitude: '717',
        timezone: '5,5',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Jaipur International Airport',
        city: 'Jaipur',
        country: 'India',
        iataCode: 'JAI',
        icaoCode: 'VIJP',
        latitude: '26,82419968',
        longitude: '75,81220245',
        altitude: '1263',
        timezone: '5,5',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Jaisalmer Airport',
        city: 'Jaisalmer',
        country: 'India',
        iataCode: 'JSA',
        icaoCode: 'VIJR',
        latitude: '26,88870049',
        longitude: '70,86499786',
        altitude: '751',
        timezone: '5,5',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Jammu Airport',
        city: 'Jammu',
        country: 'India',
        iataCode: 'IXJ',
        icaoCode: 'VIJU',
        latitude: '32,68909836',
        longitude: '74,83740234',
        altitude: '1029',
        timezone: '5,5',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Kanpur Airport',
        city: 'Kanpur',
        country: 'India',
        iataCode: 'KNU',
        icaoCode: 'VIKA',
        latitude: '26,44140053',
        longitude: '80,36489868',
        altitude: '411',
        timezone: '5,5',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Kota Airport',
        city: 'Kota',
        country: 'India',
        iataCode: 'KTU',
        icaoCode: 'VIKO',
        latitude: '25,16020012',
        longitude: '75,84559631',
        altitude: '896',
        timezone: '5,5',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Ludhiana Airport',
        city: 'Ludhiaha',
        country: 'India',
        iataCode: 'LUH',
        icaoCode: 'VILD',
        latitude: '30,85470009',
        longitude: '75,95259857',
        altitude: '834',
        timezone: '5,5',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Leh Kushok Bakula Rimpochee Airport',
        city: 'Leh',
        country: 'India',
        iataCode: 'IXL',
        icaoCode: 'VILH',
        latitude: '34,13589859',
        longitude: '77,54650116',
        altitude: '10682',
        timezone: '5,5',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Chaudhary Charan Singh International Airport',
        city: 'Lucknow',
        country: 'India',
        iataCode: 'LKO',
        icaoCode: 'VILK',
        latitude: '26,76059914',
        longitude: '80,88929749',
        altitude: '410',
        timezone: '5,5',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Patiala Airport',
        city: 'Patiala',
        country: 'India',
        iataCode: 'N',
        icaoCode: 'VIPL',
        latitude: '30,31480026',
        longitude: '76,36450195',
        altitude: '820',
        timezone: '5,5',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Pantnagar Airport',
        city: 'Nainital',
        country: 'India',
        iataCode: 'PGH',
        icaoCode: 'VIPT',
        latitude: '29,03339958',
        longitude: '79,47370148',
        altitude: '769',
        timezone: '5,5',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Fursatganj Airport',
        city: 'Raibarelli',
        country: 'India',
        iataCode: 'N',
        icaoCode: 'VIRB',
        latitude: '26,24850082',
        longitude: '81,38050079',
        altitude: '360',
        timezone: '5,5',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Sheikh ul Alam Airport',
        city: 'Srinagar',
        country: 'India',
        iataCode: 'SXR',
        icaoCode: 'VISR',
        latitude: '33,98709869',
        longitude: '74,77420044',
        altitude: '5429',
        timezone: '5,5',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Satna Airport',
        city: 'Satna',
        country: 'India',
        iataCode: 'TNI',
        icaoCode: 'VIST',
        latitude: '24,56229973',
        longitude: '80,85489655',
        altitude: '1060',
        timezone: '5,5',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Luang Phabang International Airport',
        city: 'Luang Prabang',
        country: 'Laos',
        iataCode: 'LPQ',
        icaoCode: 'VLLB',
        latitude: '19,89730072',
        longitude: '102,1610031',
        altitude: '955',
        timezone: '7',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Pakse International Airport',
        city: 'Pakse',
        country: 'Laos',
        iataCode: 'PKZ',
        icaoCode: 'VLPS',
        latitude: '15,13210011',
        longitude: '105,7809982',
        altitude: '351',
        timezone: '7',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Phonesavanh Airport',
        city: 'Phong Savanh',
        country: 'Laos',
        iataCode: 'N',
        icaoCode: 'VLPV',
        latitude: '19,45490074',
        longitude: '103,2180023',
        altitude: '3628',
        timezone: '7',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Savannakhet Airport',
        city: 'Savannakhet',
        country: 'Laos',
        iataCode: 'ZVK',
        icaoCode: 'VLSK',
        latitude: '16,55660057',
        longitude: '104,7600021',
        altitude: '509',
        timezone: '7',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Sam Neua Airport',
        city: 'Sam Neua',
        country: 'Laos',
        iataCode: 'N',
        icaoCode: 'VLSN',
        latitude: '20,41839981',
        longitude: '104,0670013',
        altitude: '3281',
        timezone: '7',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Wattay International Airport',
        city: 'Vientiane',
        country: 'Laos',
        iataCode: 'VTE',
        icaoCode: 'VLVT',
        latitude: '17,98830032',
        longitude: '102,5630035',
        altitude: '564',
        timezone: '7',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Macau International Airport',
        city: 'Macau',
        country: 'Macau',
        iataCode: 'MFM',
        icaoCode: 'VMMC',
        latitude: '22,14959908',
        longitude: '113,5920029',
        altitude: '20',
        timezone: '8',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Gautam Buddha Airport',
        city: 'Bhairawa',
        country: 'Nepal',
        iataCode: 'BWA',
        icaoCode: 'VNBW',
        latitude: '27,505685',
        longitude: '83,416293',
        altitude: '358',
        timezone: '5,75',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Janakpur Airport',
        city: 'Janakpur',
        country: 'Nepal',
        iataCode: 'N',
        icaoCode: 'VNJP',
        latitude: '26,70879936',
        longitude: '85,92240143',
        altitude: '256',
        timezone: '5,75',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Tribhuvan International Airport',
        city: 'Kathmandu',
        country: 'Nepal',
        iataCode: 'KTM',
        icaoCode: 'VNKT',
        latitude: '27,69659996',
        longitude: '85,35910034',
        altitude: '4390',
        timezone: '5,75',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Pokhara Airport',
        city: 'Pokhara',
        country: 'Nepal',
        iataCode: 'PKR',
        icaoCode: 'VNPK',
        latitude: '28,20089912',
        longitude: '83,98210144',
        altitude: '2712',
        timezone: '5,75',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Simara Airport',
        city: 'Simara',
        country: 'Nepal',
        iataCode: 'SIF',
        icaoCode: 'VNSI',
        latitude: '27,15950012',
        longitude: '84,98010254',
        altitude: '450',
        timezone: '5,75',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Biratnagar Airport',
        city: 'Biratnagar',
        country: 'Nepal',
        iataCode: 'BIR',
        icaoCode: 'VNVT',
        latitude: '26,48150063',
        longitude: '87,26399994',
        altitude: '236',
        timezone: '5,75',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Agatti Airport',
        city: 'Agatti Island',
        country: 'India',
        iataCode: 'AGX',
        icaoCode: 'VOAT',
        latitude: '10,82369995',
        longitude: '72,1760025',
        altitude: '14',
        timezone: '5,5',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Kempegowda International Airport',
        city: 'Bangalore',
        country: 'India',
        iataCode: 'BLR',
        icaoCode: 'VOBL',
        latitude: '13,1979',
        longitude: '77,706299',
        altitude: '3000',
        timezone: '5,5',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Bellary Airport',
        city: 'Bellary',
        country: 'India',
        iataCode: 'BEP',
        icaoCode: 'VOBI',
        latitude: '15,16279984',
        longitude: '76,88279724',
        altitude: '30',
        timezone: '5,5',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Vijayawada Airport',
        city: 'Vijayawada',
        country: 'India',
        iataCode: 'VGA',
        icaoCode: 'VOBZ',
        latitude: '16,53039932',
        longitude: '80,79679871',
        altitude: '82',
        timezone: '5,5',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Coimbatore International Airport',
        city: 'Coimbatore',
        country: 'India',
        iataCode: 'CJB',
        icaoCode: 'VOCB',
        latitude: '11,02999973',
        longitude: '77,04340363',
        altitude: '1324',
        timezone: '5,5',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Cochin International Airport',
        city: 'Kochi',
        country: 'India',
        iataCode: 'COK',
        icaoCode: 'VOCI',
        latitude: '10,15200043',
        longitude: '76,40190125',
        altitude: '30',
        timezone: '5,5',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Calicut International Airport',
        city: 'Calicut',
        country: 'India',
        iataCode: 'CCJ',
        icaoCode: 'VOCL',
        latitude: '11,13679981',
        longitude: '75,95529938',
        altitude: '342',
        timezone: '5,5',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Cuddapah Airport',
        city: 'Cuddapah',
        country: 'India',
        iataCode: 'CDP',
        icaoCode: 'VOCP',
        latitude: '14,51000023',
        longitude: '78,77279663',
        altitude: '430',
        timezone: '5,5',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Dundigul Air Force Academy',
        city: 'Dundigul',
        country: 'India',
        iataCode: 'N',
        icaoCode: 'VODG',
        latitude: '17,62719917',
        longitude: '78,40339661',
        altitude: '2013',
        timezone: '5,5',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Begumpet Airport',
        city: 'Hyderabad',
        country: 'India',
        iataCode: 'BPM',
        icaoCode: 'VOHY',
        latitude: '17,4531002',
        longitude: '78,46759796',
        altitude: '1742',
        timezone: '5,5',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Madurai Airport',
        city: 'Madurai',
        country: 'India',
        iataCode: 'IXM',
        icaoCode: 'VOMD',
        latitude: '9,83450985',
        longitude: '78,09339905',
        altitude: '459',
        timezone: '5,5',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Mangalore International Airport',
        city: 'Mangalore',
        country: 'India',
        iataCode: 'IXE',
        icaoCode: 'VOML',
        latitude: '12,9612999',
        longitude: '74,89009857',
        altitude: '337',
        timezone: '5,5',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Chennai International Airport',
        city: 'Madras',
        country: 'India',
        iataCode: 'MAA',
        icaoCode: 'VOMM',
        latitude: '12,99000549',
        longitude: '80,16929626',
        altitude: '52',
        timezone: '5,5',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Nagarjuna Sagar Airport',
        city: 'Nagarjunsagar',
        country: 'India',
        iataCode: 'N',
        icaoCode: 'VONS',
        latitude: '16,54269981',
        longitude: '79,3187027',
        altitude: '658',
        timezone: '5,5',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Vir Savarkar International Airport',
        city: 'Port Blair',
        country: 'India',
        iataCode: 'IXZ',
        icaoCode: 'VOPB',
        latitude: '11,64120007',
        longitude: '92,72969818',
        altitude: '14',
        timezone: '5,5',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Pondicherry Airport',
        city: 'Pendicherry',
        country: 'India',
        iataCode: 'PNY',
        icaoCode: 'VOPC',
        latitude: '11,96870041',
        longitude: '79,81009674',
        altitude: '118',
        timezone: '5,5',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Rajahmundry Airport',
        city: 'Rajahmundry',
        country: 'India',
        iataCode: 'RJA',
        icaoCode: 'VORY',
        latitude: '17,11039925',
        longitude: '81,81819916',
        altitude: '151',
        timezone: '5,5',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Salem Airport',
        city: 'Salem',
        country: 'India',
        iataCode: 'N',
        icaoCode: 'VOSM',
        latitude: '11,7833004',
        longitude: '78,06559753',
        altitude: '1008',
        timezone: '5,5',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Tanjore Air Force Base',
        city: 'Tanjore',
        country: 'India',
        iataCode: 'N',
        icaoCode: 'VOTJ',
        latitude: '10,72239971',
        longitude: '79,10160065',
        altitude: '253',
        timezone: '5,5',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Tirupati Airport',
        city: 'Tirupeti',
        country: 'India',
        iataCode: 'TIR',
        icaoCode: 'VOTP',
        latitude: '13,63249969',
        longitude: '79,54329681',
        altitude: '350',
        timezone: '5,5',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Tiruchirapally Civil Airport Airport',
        city: 'Tiruchirappalli',
        country: 'India',
        iataCode: 'TRZ',
        icaoCode: 'VOTR',
        latitude: '10,76539993',
        longitude: '78,70970154',
        altitude: '288',
        timezone: '5,5',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Trivandrum International Airport',
        city: 'Trivandrum',
        country: 'India',
        iataCode: 'TRV',
        icaoCode: 'VOTV',
        latitude: '8,48211956',
        longitude: '76,92009735',
        altitude: '15',
        timezone: '5,5',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Paro Airport',
        city: 'Thimphu',
        country: 'Bhutan',
        iataCode: 'PBH',
        icaoCode: 'VQPR',
        latitude: '27,40320015',
        longitude: '89,42459869',
        altitude: '7332',
        timezone: '6',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Malé International Airport',
        city: 'Male',
        country: 'Maldives',
        iataCode: 'MLE',
        icaoCode: 'VRMM',
        latitude: '4,191830158',
        longitude: '73,52909851',
        altitude: '6',
        timezone: '5',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Don Mueang International Airport',
        city: 'Bangkok',
        country: 'Thailand',
        iataCode: 'DMK',
        icaoCode: 'VTBD',
        latitude: '13,91259956',
        longitude: '100,6070023',
        altitude: '9',
        timezone: '7',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Kamphaeng Saen Airport',
        city: 'Nakhon Pathom',
        country: 'Thailand',
        iataCode: 'N',
        icaoCode: 'VTBK',
        latitude: '14,10200024',
        longitude: '99,91719818',
        altitude: '30',
        timezone: '7',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Khok Kathiam Airport',
        city: 'Lop Buri',
        country: 'Thailand',
        iataCode: 'N',
        icaoCode: 'VTBL',
        latitude: '14,87460041',
        longitude: '100,663002',
        altitude: '123',
        timezone: '7',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'U-Tapao International Airport',
        city: 'Pattaya',
        country: 'Thailand',
        iataCode: 'UTP',
        icaoCode: 'VTBU',
        latitude: '12,67990017',
        longitude: '101,0049973',
        altitude: '42',
        timezone: '7',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Watthana Nakhon Airport',
        city: 'Prachin Buri',
        country: 'Thailand',
        iataCode: 'N',
        icaoCode: 'VTBW',
        latitude: '13,76879978',
        longitude: '102,3150024',
        altitude: '200',
        timezone: '7',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Lampang Airport',
        city: 'Lampang',
        country: 'Thailand',
        iataCode: 'LPT',
        icaoCode: 'VTCL',
        latitude: '18,27090073',
        longitude: '99,5042038',
        altitude: '811',
        timezone: '7',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Phrae Airport',
        city: 'Phrae',
        country: 'Thailand',
        iataCode: 'PRH',
        icaoCode: 'VTCP',
        latitude: '18,13220024',
        longitude: '100,1650009',
        altitude: '538',
        timezone: '7',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Hua Hin Airport',
        city: 'Prachuap Khiri Khan',
        country: 'Thailand',
        iataCode: 'HHQ',
        icaoCode: 'VTPH',
        latitude: '12,63619995',
        longitude: '99,95149994',
        altitude: '62',
        timezone: '7',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Takhli Airport',
        city: 'Nakhon Sawan',
        country: 'Thailand',
        iataCode: 'N',
        icaoCode: 'VTPI',
        latitude: '15,27729988',
        longitude: '100,2959976',
        altitude: '107',
        timezone: '7',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Sak Long Airport',
        city: 'Phetchabun',
        country: 'Thailand',
        iataCode: 'N',
        icaoCode: 'VTPL',
        latitude: '16,82430077',
        longitude: '101,2509995',
        altitude: '500',
        timezone: '7',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Nakhon Sawan Airport',
        city: 'Nakhon Sawan',
        country: 'Thailand',
        iataCode: 'N',
        icaoCode: 'VTPN',
        latitude: '15,67300034',
        longitude: '100,137001',
        altitude: '113',
        timezone: '7',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Phitsanulok Airport',
        city: 'Phitsanulok',
        country: 'Thailand',
        iataCode: 'PHS',
        icaoCode: 'VTPP',
        latitude: '16,78289986',
        longitude: '100,2789993',
        altitude: '154',
        timezone: '7',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Khunan Phumipol Airport',
        city: 'Tak',
        country: 'Thailand',
        iataCode: 'N',
        icaoCode: 'VTPY',
        latitude: '17,23419952',
        longitude: '99,05789948',
        altitude: '492',
        timezone: '7',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Khoun Khan Airport',
        city: 'Satun',
        country: 'Thailand',
        iataCode: 'N',
        icaoCode: 'VTSA',
        latitude: '6,661399841',
        longitude: '100,0800018',
        altitude: '18',
        timezone: '7',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Narathiwat Airport',
        city: 'Narathiwat',
        country: 'Thailand',
        iataCode: 'NAW',
        icaoCode: 'VTSC',
        latitude: '6,519919872',
        longitude: '101,7429962',
        altitude: '16',
        timezone: '7',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Krabi Airport',
        city: 'Krabi',
        country: 'Thailand',
        iataCode: 'KBV',
        icaoCode: 'VTSG',
        latitude: '8,09912014',
        longitude: '98,98619843',
        altitude: '82',
        timezone: '7',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Songkhla Airport',
        city: 'Songkhla',
        country: 'Thailand',
        iataCode: 'N',
        icaoCode: 'VTSH',
        latitude: '7,186560154',
        longitude: '100,6080017',
        altitude: '12',
        timezone: '7',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Pattani Airport',
        city: 'Pattani',
        country: 'Thailand',
        iataCode: 'PAN',
        icaoCode: 'VTSK',
        latitude: '6,785459995',
        longitude: '101,1539993',
        altitude: '8',
        timezone: '7',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Samui Airport',
        city: 'Ko Samui',
        country: 'Thailand',
        iataCode: 'USM',
        icaoCode: 'VTSM',
        latitude: '9,547789574',
        longitude: '100,0619965',
        altitude: '64',
        timezone: '7',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Cha Eian Airport',
        city: 'Nakhon Si Thammarat',
        country: 'Thailand',
        iataCode: 'N',
        icaoCode: 'VTSN',
        latitude: '8,471150398',
        longitude: '99,95559692',
        altitude: '44',
        timezone: '7',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Phuket International Airport',
        city: 'Phuket',
        country: 'Thailand',
        iataCode: 'HKT',
        icaoCode: 'VTSP',
        latitude: '8,113200188',
        longitude: '98,31690216',
        altitude: '82',
        timezone: '7',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Ranong Airport',
        city: 'Ranong',
        country: 'Thailand',
        iataCode: 'UNN',
        icaoCode: 'VTSR',
        latitude: '9,777620316',
        longitude: '98,58550262',
        altitude: '57',
        timezone: '7',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Hat Yai International Airport',
        city: 'Hat Yai',
        country: 'Thailand',
        iataCode: 'HDY',
        icaoCode: 'VTSS',
        latitude: '6,933209896',
        longitude: '100,3929977',
        altitude: '90',
        timezone: '7',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Trang Airport',
        city: 'Trang',
        country: 'Thailand',
        iataCode: 'TST',
        icaoCode: 'VTST',
        latitude: '7,508739948',
        longitude: '99,61660004',
        altitude: '67',
        timezone: '7',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Udon Thani Airport',
        city: 'Udon Thani',
        country: 'Thailand',
        iataCode: 'UTH',
        icaoCode: 'VTUD',
        latitude: '17,38640022',
        longitude: '102,788002',
        altitude: '579',
        timezone: '7',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Sakon Nakhon Airport',
        city: 'Sakon Nakhon',
        country: 'Thailand',
        iataCode: 'SNO',
        icaoCode: 'VTUI',
        latitude: '17,19510078',
        longitude: '104,1190033',
        altitude: '529',
        timezone: '7',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Surin Airport',
        city: 'Surin',
        country: 'Thailand',
        iataCode: 'N',
        icaoCode: 'VTUJ',
        latitude: '14,86830044',
        longitude: '103,4980011',
        altitude: '478',
        timezone: '7',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Loei Airport',
        city: 'Loei',
        country: 'Thailand',
        iataCode: 'LOE',
        icaoCode: 'VTUL',
        latitude: '17,43910027',
        longitude: '101,7220001',
        altitude: '860',
        timezone: '7',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Khorat Airport',
        city: 'Nakhon Ratchasima',
        country: 'Thailand',
        iataCode: 'N',
        icaoCode: 'VTUN',
        latitude: '14,93449974',
        longitude: '102,0790024',
        altitude: '729',
        timezone: '7',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Rob Muang Airport',
        city: 'Roi Et',
        country: 'Thailand',
        iataCode: 'N',
        icaoCode: 'VTUR',
        latitude: '16,07040024',
        longitude: '103,6460037',
        altitude: '459',
        timezone: '7',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Da Nang International Airport',
        city: 'Danang',
        country: 'Vietnam',
        iataCode: 'DAD',
        icaoCode: 'VVDN',
        latitude: '16,04389954',
        longitude: '108,1989975',
        altitude: '33',
        timezone: '7',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Gia Lam Air Base',
        city: 'Hanoi',
        country: 'Vietnam',
        iataCode: 'N',
        icaoCode: 'VVGL',
        latitude: '21,04050064',
        longitude: '105,8860016',
        altitude: '50',
        timezone: '7',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Kep Air Base',
        city: 'Kep',
        country: 'Vietnam',
        iataCode: 'N',
        icaoCode: 'VVKP',
        latitude: '21,39459991',
        longitude: '106,2610016',
        altitude: '55',
        timezone: '7',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Noi Bai International Airport',
        city: 'Hanoi',
        country: 'Vietnam',
        iataCode: 'HAN',
        icaoCode: 'VVNB',
        latitude: '21,22120094',
        longitude: '105,8069992',
        altitude: '39',
        timezone: '7',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Nha Trang Air Base',
        city: 'Nhatrang',
        country: 'Vietnam',
        iataCode: 'NHA',
        icaoCode: 'VVNT',
        latitude: '12,2275',
        longitude: '109,192001',
        altitude: '20',
        timezone: '7',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Phu Bai Airport',
        city: 'Hue',
        country: 'Vietnam',
        iataCode: 'HUI',
        icaoCode: 'VVPB',
        latitude: '16,4015007',
        longitude: '107,7030029',
        altitude: '48',
        timezone: '7',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Phu Quoc International Airport',
        city: 'Phuquoc',
        country: 'Vietnam',
        iataCode: 'PQC',
        icaoCode: 'VVPQ',
        latitude: '10,1698',
        longitude: '103,9931',
        altitude: '37',
        timezone: '7',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Tan Son Nhat International Airport',
        city: 'Ho Chi Minh City',
        country: 'Vietnam',
        iataCode: 'SGN',
        icaoCode: 'VVTS',
        latitude: '10,81879997',
        longitude: '106,6520004',
        altitude: '33',
        timezone: '7',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Ann Airport',
        city: 'Ann',
        country: 'Burma',
        iataCode: 'N',
        icaoCode: 'VYAN',
        latitude: '19,76919937',
        longitude: '94,02610016',
        altitude: '74',
        timezone: '6,5',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Anisakan Airport',
        city: 'Anisakan',
        country: 'Burma',
        iataCode: 'N',
        icaoCode: 'VYAS',
        latitude: '21,95540047',
        longitude: '96,40609741',
        altitude: '3000',
        timezone: '6,5',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Bagan Airport',
        city: 'Bagan',
        country: 'Burma',
        iataCode: 'NYU',
        icaoCode: 'VYBG',
        latitude: '21,17880058',
        longitude: '94,93019867',
        altitude: '312',
        timezone: '6,5',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Coco Island Airport',
        city: 'Coco Island',
        country: 'Burma',
        iataCode: 'N',
        icaoCode: 'VYCI',
        latitude: '14,14150047',
        longitude: '93,36849976',
        altitude: '20',
        timezone: '6',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Heho Airport',
        city: 'Heho',
        country: 'Burma',
        iataCode: 'HEH',
        icaoCode: 'VYHH',
        latitude: '20,74699974',
        longitude: '96,79199982',
        altitude: '3858',
        timezone: '6,5',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Hommalinn Airport',
        city: 'Hommalin',
        country: 'Burma',
        iataCode: 'N',
        icaoCode: 'VYHL',
        latitude: '24,89959908',
        longitude: '94,91400146',
        altitude: '534',
        timezone: '6,5',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Kengtung Airport',
        city: 'Kengtung',
        country: 'Burma',
        iataCode: 'KET',
        icaoCode: 'VYKG',
        latitude: '21,3015995',
        longitude: '99,63600159',
        altitude: '2798',
        timezone: '6,5',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Kyaukpyu Airport',
        city: 'Kyaukpyu',
        country: 'Burma',
        iataCode: 'KYP',
        icaoCode: 'VYKP',
        latitude: '19,42639923',
        longitude: '93,53479767',
        altitude: '20',
        timezone: '6,5',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Lashio Airport',
        city: 'Lashio',
        country: 'Burma',
        iataCode: 'LSH',
        icaoCode: 'VYLS',
        latitude: '22,97789955',
        longitude: '97,75219727',
        altitude: '2450',
        timezone: '6,5',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Lanywa Airport',
        city: 'Lanywa',
        country: 'Burma',
        iataCode: 'N',
        icaoCode: 'VYLY',
        latitude: '20,94039917',
        longitude: '94,82260132',
        altitude: '175',
        timezone: '6,5',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Mandalay International Airport',
        city: 'Mandalay',
        country: 'Burma',
        iataCode: 'MDL',
        icaoCode: 'VYMD',
        latitude: '21,70219994',
        longitude: '95,97789764',
        altitude: '300',
        timezone: '6,5',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Myeik Airport',
        city: 'Myeik',
        country: 'Burma',
        iataCode: 'MGZ',
        icaoCode: 'VYME',
        latitude: '12,43980026',
        longitude: '98,62149811',
        altitude: '75',
        timezone: '6,5',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Myitkyina Airport',
        city: 'Myitkyina',
        country: 'Burma',
        iataCode: 'MYT',
        icaoCode: 'VYMK',
        latitude: '25,38360023',
        longitude: '97,35189819',
        altitude: '475',
        timezone: '6,5',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Momeik Airport',
        city: 'Momeik',
        country: 'Burma',
        iataCode: 'N',
        icaoCode: 'VYMO',
        latitude: '23,09250069',
        longitude: '96,64530182',
        altitude: '600',
        timezone: '6,5',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Mong Hsat Airport',
        city: 'Mong Hsat',
        country: 'Burma',
        iataCode: 'MOG',
        icaoCode: 'VYMS',
        latitude: '20,51679993',
        longitude: '99,25679779',
        altitude: '1875',
        timezone: '6,5',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Nampong Air Base',
        city: 'Nampong',
        country: 'Burma',
        iataCode: 'N',
        icaoCode: 'VYNP',
        latitude: '25,35440063',
        longitude: '97,29519653',
        altitude: '459',
        timezone: '6,5',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Namsang Airport',
        city: 'Namsang',
        country: 'Burma',
        iataCode: 'N',
        icaoCode: 'VYNS',
        latitude: '20,89049911',
        longitude: '97,73590088',
        altitude: '3100',
        timezone: '6,5',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Hpa-N Airport',
        city: 'Hpa-an',
        country: 'Burma',
        iataCode: 'N',
        icaoCode: 'VYPA',
        latitude: '16,89369965',
        longitude: '97,67459869',
        altitude: '150',
        timezone: '6,5',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Putao Airport',
        city: 'Putao',
        country: 'Burma',
        iataCode: 'PBU',
        icaoCode: 'VYPT',
        latitude: '27,32990074',
        longitude: '97,42630005',
        altitude: '1500',
        timezone: '6,5',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Pyay Airport',
        city: 'Pyay',
        country: 'Burma',
        iataCode: 'N',
        icaoCode: 'VYPY',
        latitude: '18,82449913',
        longitude: '95,26599884',
        altitude: '120',
        timezone: '6,5',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Shante Air Base',
        city: 'Shante',
        country: 'Burma',
        iataCode: 'N',
        icaoCode: 'VYST',
        latitude: '20,94169998',
        longitude: '95,91449738',
        altitude: '630',
        timezone: '6,5',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Sittwe Airport',
        city: 'Sittwe',
        country: 'Burma',
        iataCode: 'AKY',
        icaoCode: 'VYSW',
        latitude: '20,13269997',
        longitude: '92,87259674',
        altitude: '27',
        timezone: '6,5',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Thandwe Airport',
        city: 'Thandwe',
        country: 'Burma',
        iataCode: 'SNW',
        icaoCode: 'VYTD',
        latitude: '18,46069908',
        longitude: '94,30010223',
        altitude: '20',
        timezone: '6,5',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Tachileik Airport',
        city: 'Tachilek',
        country: 'Burma',
        iataCode: 'THL',
        icaoCode: 'VYTL',
        latitude: '20,48380089',
        longitude: '99,93540192',
        altitude: '1280',
        timezone: '6,5',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Taungoo Airport',
        city: 'Taungoo',
        country: 'Burma',
        iataCode: 'N',
        icaoCode: 'VYTO',
        latitude: '19,03129959',
        longitude: '96,40119934',
        altitude: '160',
        timezone: '6,5',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Yangon International Airport',
        city: 'Yangon',
        country: 'Burma',
        iataCode: 'RGN',
        icaoCode: 'VYYY',
        latitude: '16,90730095',
        longitude: '96,1332016',
        altitude: '109',
        timezone: '6,5',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Hasanuddin International Airport',
        city: 'Ujung Pandang',
        country: 'Indonesia',
        iataCode: 'UPG',
        icaoCode: 'WAAA',
        latitude: '-5,061629772',
        longitude: '119,5540009',
        altitude: '47',
        timezone: '8',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Frans Kaisiepo Airport',
        city: 'Biak',
        country: 'Indonesia',
        iataCode: 'BIK',
        icaoCode: 'WABB',
        latitude: '-1,190019965',
        longitude: '136,1080017',
        altitude: '46',
        timezone: '9',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Nabire Airport',
        city: 'Nabire',
        country: 'Indonesia',
        iataCode: 'NBX',
        icaoCode: 'WABI',
        latitude: '-3,368180037',
        longitude: '135,4960022',
        altitude: '20',
        timezone: '9',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Moses Kilangin Airport',
        city: 'Timika',
        country: 'Indonesia',
        iataCode: 'TIM',
        icaoCode: 'WABP',
        latitude: '-4,528279781',
        longitude: '136,8869934',
        altitude: '103',
        timezone: '9',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Sentani Airport',
        city: 'Jayapura',
        country: 'Indonesia',
        iataCode: 'DJJ',
        icaoCode: 'WAJJ',
        latitude: '-2,576950073',
        longitude: '140,5160065',
        altitude: '289',
        timezone: '9',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Wamena Airport',
        city: 'Wamena',
        country: 'Indonesia',
        iataCode: 'WMX',
        icaoCode: 'WAJW',
        latitude: '-4,102509975',
        longitude: '138,9570007',
        altitude: '5085',
        timezone: '9',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Mopah Airport',
        city: 'Merauke',
        country: 'Indonesia',
        iataCode: 'MKQ',
        icaoCode: 'WAKK',
        latitude: '-8,520290375',
        longitude: '140,4179993',
        altitude: '10',
        timezone: '9',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Jalaluddin Airport',
        city: 'Gorontalo',
        country: 'Indonesia',
        iataCode: 'GTO',
        icaoCode: 'WAMG',
        latitude: '0,637118995',
        longitude: '122,8499985',
        altitude: '105',
        timezone: '8',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Mutiara Airport',
        city: 'Palu',
        country: 'Indonesia',
        iataCode: 'PLW',
        icaoCode: 'WAML',
        latitude: '-0,918542027',
        longitude: '119,9100037',
        altitude: '284',
        timezone: '8',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Sam Ratulangi Airport',
        city: 'Manado',
        country: 'Indonesia',
        iataCode: 'MDC',
        icaoCode: 'WAMM',
        latitude: '1,54926002',
        longitude: '124,9260025',
        altitude: '264',
        timezone: '8',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Kasiguncu Airport',
        city: 'Poso',
        country: 'Indonesia',
        iataCode: 'PSJ',
        icaoCode: 'WAMP',
        latitude: '-1,416749954',
        longitude: '120,6579971',
        altitude: '174',
        timezone: '8',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Pitu Airport',
        city: 'Morotai Island',
        country: 'Indonesia',
        iataCode: 'OTI',
        icaoCode: 'WAMR',
        latitude: '2,04598999',
        longitude: '128,3249969',
        altitude: '49',
        timezone: '9',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Sultan Khairun Babullah Airport',
        city: 'Ternate',
        country: 'Indonesia',
        iataCode: 'TTE',
        icaoCode: 'WAMT',
        latitude: '0,831413984',
        longitude: '127,3809967',
        altitude: '49',
        timezone: '9',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Bubung Airport',
        city: 'Luwuk',
        country: 'Indonesia',
        iataCode: 'LUW',
        icaoCode: 'WAMW',
        latitude: '-1,038920045',
        longitude: '122,7720032',
        altitude: '56',
        timezone: '8',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Pattimura Airport, Ambon',
        city: 'Ambon',
        country: 'Indonesia',
        iataCode: 'AMQ',
        icaoCode: 'WAPP',
        latitude: '-3,710259914',
        longitude: '128,0890045',
        altitude: '33',
        timezone: '9',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Fakfak Airport',
        city: 'Fak Fak',
        country: 'Indonesia',
        iataCode: 'FKQ',
        icaoCode: 'WASF',
        latitude: '-2,920190096',
        longitude: '132,2669983',
        altitude: '462',
        timezone: '9',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Kaimana Airport',
        city: 'Kaimana',
        country: 'Indonesia',
        iataCode: 'KNG',
        icaoCode: 'WASK',
        latitude: '-3,644520044',
        longitude: '133,6959991',
        altitude: '19',
        timezone: '9',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Babo Airport',
        city: 'Babo',
        country: 'Indonesia',
        iataCode: 'BXB',
        icaoCode: 'WASO',
        latitude: '-2,532239914',
        longitude: '133,4389954',
        altitude: '10',
        timezone: '9',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Rendani Airport',
        city: 'Manokwari',
        country: 'Indonesia',
        iataCode: 'MKW',
        icaoCode: 'WASR',
        latitude: '-0,891833007',
        longitude: '134,048996',
        altitude: '23',
        timezone: '9',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Dominique Edward Osok Airport',
        city: 'Sorong',
        country: 'Indonesia',
        iataCode: 'SOQ',
        icaoCode: 'WAXX',
        latitude: '-0,894',
        longitude: '131,287',
        altitude: '10',
        timezone: '9',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Bintulu Airport',
        city: 'Bintulu',
        country: 'Malaysia',
        iataCode: 'BTU',
        icaoCode: 'WBGB',
        latitude: '3,123850107',
        longitude: '113,0199966',
        altitude: '74',
        timezone: '8',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Kuching International Airport',
        city: 'Kuching',
        country: 'Malaysia',
        iataCode: 'KCH',
        icaoCode: 'WBGG',
        latitude: '1,484699965',
        longitude: '110,3470001',
        altitude: '89',
        timezone: '8',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Limbang Airport',
        city: 'Limbang',
        country: 'Malaysia',
        iataCode: 'LMN',
        icaoCode: 'WBGJ',
        latitude: '4,808300018',
        longitude: '115,0100021',
        altitude: '14',
        timezone: '8',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Marudi Airport',
        city: 'Marudi',
        country: 'Malaysia',
        iataCode: 'MUR',
        icaoCode: 'WBGM',
        latitude: '4,178979874',
        longitude: '114,3290024',
        altitude: '103',
        timezone: '8',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Miri Airport',
        city: 'Miri',
        country: 'Malaysia',
        iataCode: 'MYY',
        icaoCode: 'WBGR',
        latitude: '4,32201004',
        longitude: '113,9869995',
        altitude: '59',
        timezone: '8',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Sibu Airport',
        city: 'Sibu',
        country: 'Malaysia',
        iataCode: 'SBW',
        icaoCode: 'WBGS',
        latitude: '2,261600018',
        longitude: '111,9850006',
        altitude: '122',
        timezone: '8',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Lahad Datu Airport',
        city: 'Lahad Datu',
        country: 'Malaysia',
        iataCode: 'LDU',
        icaoCode: 'WBKD',
        latitude: '5,032249928',
        longitude: '118,3239975',
        altitude: '45',
        timezone: '8',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Kota Kinabalu International Airport',
        city: 'Kota Kinabalu',
        country: 'Malaysia',
        iataCode: 'BKI',
        icaoCode: 'WBKK',
        latitude: '5,937210083',
        longitude: '116,0510025',
        altitude: '10',
        timezone: '8',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Labuan Airport',
        city: 'Labuan',
        country: 'Malaysia',
        iataCode: 'LBU',
        icaoCode: 'WBKL',
        latitude: '5,300680161',
        longitude: '115,25',
        altitude: '101',
        timezone: '8',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Tawau Airport',
        city: 'Tawau',
        country: 'Malaysia',
        iataCode: 'TWU',
        icaoCode: 'WBKW',
        latitude: '4,320159912',
        longitude: '118,1279984',
        altitude: '57',
        timezone: '8',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Brunei International Airport',
        city: 'Bandar Seri Begawan',
        country: 'Brunei',
        iataCode: 'BWN',
        icaoCode: 'WBSB',
        latitude: '4,944200039',
        longitude: '114,9280014',
        altitude: '73',
        timezone: '8',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Sultan Syarif Kasim Ii (Simpang Tiga) Airport',
        city: 'Pekanbaru',
        country: 'Indonesia',
        iataCode: 'PKU',
        icaoCode: 'WIBB',
        latitude: '0,460786015',
        longitude: '101,4449997',
        altitude: '102',
        timezone: '7',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Pinang Kampai Airport',
        city: 'Dumai',
        country: 'Indonesia',
        iataCode: 'DUM',
        icaoCode: 'WIBD',
        latitude: '1,60919',
        longitude: '101,433998',
        altitude: '55',
        timezone: '7',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Soekarno-Hatta International Airport',
        city: 'Jakarta',
        country: 'Indonesia',
        iataCode: 'CGK',
        icaoCode: 'WIII',
        latitude: '-6,12556982',
        longitude: '106,6559982',
        altitude: '34',
        timezone: '7',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Binaka Airport',
        city: 'Gunung Sitoli',
        country: 'Indonesia',
        iataCode: 'GNS',
        icaoCode: 'WIMB',
        latitude: '1,166380048',
        longitude: '97,70469666',
        altitude: '20',
        timezone: '7',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Aek Godang Airport',
        city: 'Padang Sidempuan',
        country: 'Indonesia',
        iataCode: 'N',
        icaoCode: 'WIME',
        latitude: '1,4001',
        longitude: '99,430496',
        altitude: '922',
        timezone: '7',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Minangkabau Airport',
        city: 'Padang',
        country: 'Indonesia',
        iataCode: 'N',
        icaoCode: 'WIPT',
        latitude: '-0,786916971',
        longitude: '100,2809982',
        altitude: '18',
        timezone: '7',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Soewondo Air Force Base',
        city: 'Medan',
        country: 'Indonesia',
        iataCode: 'MES',
        icaoCode: 'WIMK',
        latitude: '3,559167',
        longitude: '98,671111',
        altitude: '114',
        timezone: '7',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Dr Ferdinand Lumban Tobing Airport',
        city: 'Sibolga',
        country: 'Indonesia',
        iataCode: 'N',
        icaoCode: 'WIMS',
        latitude: '1,555940032',
        longitude: '98,88890076',
        altitude: '43',
        timezone: '7',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Nanga Pinoh Airport',
        city: 'Nangapinoh',
        country: 'Indonesia',
        iataCode: 'N',
        icaoCode: 'WIOG',
        latitude: '-0,348868996',
        longitude: '111,7480011',
        altitude: '123',
        timezone: '7',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Ketapang(Rahadi Usman) Airport',
        city: 'Ketapang',
        country: 'Indonesia',
        iataCode: 'KTG',
        icaoCode: 'WIOK',
        latitude: '-1,816640019',
        longitude: '109,9629974',
        altitude: '46',
        timezone: '7',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Supadio Airport',
        city: 'Pontianak',
        country: 'Indonesia',
        iataCode: 'PNK',
        icaoCode: 'WIOO',
        latitude: '-0,150711',
        longitude: '109,4039993',
        altitude: '10',
        timezone: '7',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Sultan Thaha Airport',
        city: 'Jambi',
        country: 'Indonesia',
        iataCode: 'DJB',
        icaoCode: 'WIPA',
        latitude: '-1,638020039',
        longitude: '103,6439972',
        altitude: '82',
        timezone: '7',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Fatmawati Soekarno Airport',
        city: 'Bengkulu',
        country: 'Indonesia',
        iataCode: 'BKS',
        icaoCode: 'WIPL',
        latitude: '-3,8637',
        longitude: '102,338997',
        altitude: '50',
        timezone: '7',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Sultan Mahmud Badaruddin II Airport',
        city: 'Palembang',
        country: 'Indonesia',
        iataCode: 'PLM',
        icaoCode: 'WIPP',
        latitude: '-2,898250103',
        longitude: '104,6999969',
        altitude: '49',
        timezone: '7',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Japura Airport',
        city: 'Rengat',
        country: 'Indonesia',
        iataCode: 'RGT',
        icaoCode: 'WIPR',
        latitude: '-0,352807999',
        longitude: '102,3349991',
        altitude: '62',
        timezone: '7',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Lhok Sukon Airport',
        city: 'Lhok Sukon',
        country: 'Indonesia',
        iataCode: 'N',
        icaoCode: 'WITL',
        latitude: '5,069509983',
        longitude: '97,25920105',
        altitude: '28',
        timezone: '7',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Sultan Iskandar Muda International Airport',
        city: 'Banda Aceh',
        country: 'Indonesia',
        iataCode: 'BTJ',
        icaoCode: 'WITT',
        latitude: '5,522872024',
        longitude: '95,42063713',
        altitude: '65',
        timezone: '7',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Kluang Airport',
        city: 'Kluang',
        country: 'Malaysia',
        iataCode: 'N',
        icaoCode: 'WMAP',
        latitude: '2,041389942',
        longitude: '103,3069992',
        altitude: '150',
        timezone: '8',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Sultan Abdul Halim Airport',
        city: 'Alor Setar',
        country: 'Malaysia',
        iataCode: 'AOR',
        icaoCode: 'WMKA',
        latitude: '6,189670086',
        longitude: '100,3980026',
        altitude: '15',
        timezone: '8',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Butterworth Airport',
        city: 'Butterworth',
        country: 'Malaysia',
        iataCode: 'N',
        icaoCode: 'WMKB',
        latitude: '5,465919971',
        longitude: '100,3909988',
        altitude: '11',
        timezone: '8',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Sultan Ismail Petra Airport',
        city: 'Kota Bahru',
        country: 'Malaysia',
        iataCode: 'KBR',
        icaoCode: 'WMKC',
        latitude: '6,16685009',
        longitude: '102,2929993',
        altitude: '16',
        timezone: '8',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Kuantan Airport',
        city: 'Kuantan',
        country: 'Malaysia',
        iataCode: 'KUA',
        icaoCode: 'WMKD',
        latitude: '3,77538991',
        longitude: '103,2089996',
        altitude: '58',
        timezone: '8',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Kerteh Airport',
        city: 'Kerteh',
        country: 'Malaysia',
        iataCode: 'KTE',
        icaoCode: 'WMKE',
        latitude: '4,537220001',
        longitude: '103,427002',
        altitude: '18',
        timezone: '8',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Simpang Airport',
        city: 'Simpang',
        country: 'Malaysia',
        iataCode: 'N',
        icaoCode: 'WMKF',
        latitude: '3,11225009',
        longitude: '101,7030029',
        altitude: '111',
        timezone: '8',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Sultan Azlan Shah Airport',
        city: 'Ipoh',
        country: 'Malaysia',
        iataCode: 'IPH',
        icaoCode: 'WMKI',
        latitude: '4,567969799',
        longitude: '101,0920029',
        altitude: '130',
        timezone: '8',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Senai International Airport',
        city: 'Johor Bahru',
        country: 'Malaysia',
        iataCode: 'JHB',
        icaoCode: 'WMKJ',
        latitude: '1,641309977',
        longitude: '103,6699982',
        altitude: '135',
        timezone: '8',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Kuala Lumpur International Airport',
        city: 'Kuala Lumpur',
        country: 'Malaysia',
        iataCode: 'KUL',
        icaoCode: 'WMKK',
        latitude: '2,745579958',
        longitude: '101,7099991',
        altitude: '69',
        timezone: '8',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Langkawi International Airport',
        city: 'Langkawi',
        country: 'Malaysia',
        iataCode: 'LGK',
        icaoCode: 'WMKL',
        latitude: '6,329730034',
        longitude: '99,72869873',
        altitude: '29',
        timezone: '8',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Malacca Airport',
        city: 'Malacca',
        country: 'Malaysia',
        iataCode: 'MKZ',
        icaoCode: 'WMKM',
        latitude: '2,263360024',
        longitude: '102,2519989',
        altitude: '35',
        timezone: '8',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Sultan Mahmud Airport',
        city: 'Kuala Terengganu',
        country: 'Malaysia',
        iataCode: 'TGG',
        icaoCode: 'WMKN',
        latitude: '5,382639885',
        longitude: '103,1029968',
        altitude: '21',
        timezone: '8',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Penang International Airport',
        city: 'Penang',
        country: 'Malaysia',
        iataCode: 'PEN',
        icaoCode: 'WMKP',
        latitude: '5,297140121',
        longitude: '100,2770004',
        altitude: '11',
        timezone: '8',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Suai Airport',
        city: 'Suai',
        country: 'East Timor',
        iataCode: 'N',
        icaoCode: 'WPDB',
        latitude: '-9,303310394',
        longitude: '125,2870026',
        altitude: '96',
        timezone: '9',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Presidente Nicolau Lobato International Airport',
        city: 'Dili',
        country: 'East Timor',
        iataCode: 'DIL',
        icaoCode: 'WPDL',
        latitude: '-8,54640007',
        longitude: '125,526001',
        altitude: '154',
        timezone: '9',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Cakung Airport',
        city: 'Baucau',
        country: 'East Timor',
        iataCode: 'N',
        icaoCode: 'WPEC',
        latitude: '-8,489029884',
        longitude: '126,401001',
        altitude: '1771',
        timezone: '9',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Sembawang Air Base',
        city: 'Sembawang',
        country: 'Singapore',
        iataCode: 'N',
        icaoCode: 'WSAG',
        latitude: '1,425259948',
        longitude: '103,8130035',
        altitude: '86',
        timezone: '8',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Paya Lebar Air Base',
        city: 'Paya Lebar',
        country: 'Singapore',
        iataCode: 'QPG',
        icaoCode: 'WSAP',
        latitude: '1,360419989',
        longitude: '103,9100037',
        altitude: '65',
        timezone: '8',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Tengah Air Base',
        city: 'Tengah',
        country: 'Singapore',
        iataCode: 'N',
        icaoCode: 'WSAT',
        latitude: '1,38725996',
        longitude: '103,7089996',
        altitude: '50',
        timezone: '8',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Seletar Airport',
        city: 'Singapore',
        country: 'Singapore',
        iataCode: 'XSP',
        icaoCode: 'WSSL',
        latitude: '1,416949987',
        longitude: '103,8679962',
        altitude: '36',
        timezone: '8',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Singapore Changi Airport',
        city: 'Singapore',
        country: 'Singapore',
        iataCode: 'SIN',
        icaoCode: 'WSSS',
        latitude: '1,35019',
        longitude: '103,994003',
        altitude: '22',
        timezone: '8',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Brisbane Archerfield Airport',
        city: 'Brisbane',
        country: 'Australia',
        iataCode: 'N',
        icaoCode: 'YBAF',
        latitude: '-27,57029915',
        longitude: '153,0079956',
        altitude: '63',
        timezone: '10',
        dst: 'O',
        type: 'airport',
    },
    {
        name: 'Northern Peninsula Airport',
        city: 'Amberley',
        country: 'Australia',
        iataCode: 'ABM',
        icaoCode: 'YBAM',
        latitude: '-10,9508',
        longitude: '142,459',
        altitude: '34',
        timezone: '10',
        dst: 'O',
        type: 'airport',
    },
    {
        name: 'Alice Springs Airport',
        city: 'Alice Springs',
        country: 'Australia',
        iataCode: 'ASP',
        icaoCode: 'YBAS',
        latitude: '-23,80669975',
        longitude: '133,9019928',
        altitude: '1789',
        timezone: '9,5',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Brisbane International Airport',
        city: 'Brisbane',
        country: 'Australia',
        iataCode: 'BNE',
        icaoCode: 'YBBN',
        latitude: '-27,38419914',
        longitude: '153,1170044',
        altitude: '13',
        timezone: '10',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Gold Coast Airport',
        city: 'Coolangatta',
        country: 'Australia',
        iataCode: 'OOL',
        icaoCode: 'YBCG',
        latitude: '-28,1644001',
        longitude: '153,5050049',
        altitude: '21',
        timezone: '10',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Cairns International Airport',
        city: 'Cairns',
        country: 'Australia',
        iataCode: 'CNS',
        icaoCode: 'YBCS',
        latitude: '-16,88579941',
        longitude: '145,7550049',
        altitude: '10',
        timezone: '10',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Charleville Airport',
        city: 'Charlieville',
        country: 'Australia',
        iataCode: 'CTL',
        icaoCode: 'YBCV',
        latitude: '-26,41329956',
        longitude: '146,2619934',
        altitude: '1003',
        timezone: '10',
        dst: 'O',
        type: 'airport',
    },
    {
        name: 'Mount Isa Airport',
        city: 'Mount Isa',
        country: 'Australia',
        iataCode: 'ISA',
        icaoCode: 'YBMA',
        latitude: '-20,66390038',
        longitude: '139,4889984',
        altitude: '1121',
        timezone: '10',
        dst: 'O',
        type: 'airport',
    },
    {
        name: 'Sunshine Coast Airport',
        city: 'Maroochydore',
        country: 'Australia',
        iataCode: 'MCY',
        icaoCode: 'YBMC',
        latitude: '-26,60330009',
        longitude: '153,0910034',
        altitude: '15',
        timezone: '10',
        dst: 'O',
        type: 'airport',
    },
    {
        name: 'Mackay Airport',
        city: 'Mackay',
        country: 'Australia',
        iataCode: 'MKY',
        icaoCode: 'YBMK',
        latitude: '-21,17169952',
        longitude: '149,1799927',
        altitude: '19',
        timezone: '10',
        dst: 'O',
        type: 'airport',
    },
    {
        name: 'Proserpine Whitsunday Coast Airport',
        city: 'Prosserpine',
        country: 'Australia',
        iataCode: 'PPP',
        icaoCode: 'YBPN',
        latitude: '-20,49500084',
        longitude: '148,552002',
        altitude: '82',
        timezone: '10',
        dst: 'O',
        type: 'airport',
    },
    {
        name: 'Rockhampton Airport',
        city: 'Rockhampton',
        country: 'Australia',
        iataCode: 'ROK',
        icaoCode: 'YBRK',
        latitude: '-23,38190079',
        longitude: '150,4750061',
        altitude: '34',
        timezone: '10',
        dst: 'O',
        type: 'airport',
    },
    {
        name: 'Townsville Airport',
        city: 'Townsville',
        country: 'Australia',
        iataCode: 'TSV',
        icaoCode: 'YBTL',
        latitude: '-19,25250053',
        longitude: '146,7649994',
        altitude: '18',
        timezone: '10',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Weipa Airport',
        city: 'Weipa',
        country: 'Australia',
        iataCode: 'WEI',
        icaoCode: 'YBWP',
        latitude: '-12,67860031',
        longitude: '141,9250031',
        altitude: '63',
        timezone: '10',
        dst: 'O',
        type: 'airport',
    },
    {
        name: 'Avalon Airport',
        city: 'Avalon',
        country: 'Australia',
        iataCode: 'AVV',
        icaoCode: 'YMAV',
        latitude: '-38,03939819',
        longitude: '144,4689941',
        altitude: '35',
        timezone: '10',
        dst: 'O',
        type: 'airport',
    },
    {
        name: 'Albury Airport',
        city: 'Albury',
        country: 'Australia',
        iataCode: 'ABX',
        icaoCode: 'YMAY',
        latitude: '-36,06779861',
        longitude: '146,9579926',
        altitude: '539',
        timezone: '10',
        dst: 'O',
        type: 'airport',
    },
    {
        name: 'Melbourne Essendon Airport',
        city: 'Melbourne',
        country: 'Australia',
        iataCode: 'MEB',
        icaoCode: 'YMEN',
        latitude: '-37,72809982',
        longitude: '144,9019928',
        altitude: '282',
        timezone: '10',
        dst: 'O',
        type: 'airport',
    },
    {
        name: 'RAAF Base East Sale',
        city: 'East Sale',
        country: 'Australia',
        iataCode: 'N',
        icaoCode: 'YMES',
        latitude: '-38,09889984',
        longitude: '147,1490021',
        altitude: '23',
        timezone: '10',
        dst: 'O',
        type: 'airport',
    },
    {
        name: 'Hobart International Airport',
        city: 'Hobart',
        country: 'Australia',
        iataCode: 'HBA',
        icaoCode: 'YMHB',
        latitude: '-42,83610153',
        longitude: '147,5099945',
        altitude: '13',
        timezone: '10',
        dst: 'O',
        type: 'airport',
    },
    {
        name: 'Launceston Airport',
        city: 'Launceston',
        country: 'Australia',
        iataCode: 'LST',
        icaoCode: 'YMLT',
        latitude: '-41,54529953',
        longitude: '147,2140045',
        altitude: '562',
        timezone: '10',
        dst: 'O',
        type: 'airport',
    },
    {
        name: 'Melbourne Moorabbin Airport',
        city: 'Melbourne',
        country: 'Australia',
        iataCode: 'MBW',
        icaoCode: 'YMMB',
        latitude: '-37,97579956',
        longitude: '145,102005',
        altitude: '50',
        timezone: '10',
        dst: 'O',
        type: 'airport',
    },
    {
        name: 'Melbourne International Airport',
        city: 'Melbourne',
        country: 'Australia',
        iataCode: 'MEL',
        icaoCode: 'YMML',
        latitude: '-37,6733017',
        longitude: '144,8430023',
        altitude: '434',
        timezone: '10',
        dst: 'O',
        type: 'airport',
    },
    {
        name: 'RAAF Williams, Point Cook Base',
        city: 'Point Cook',
        country: 'Australia',
        iataCode: 'N',
        icaoCode: 'YMPC',
        latitude: '-37,93220139',
        longitude: '144,753006',
        altitude: '14',
        timezone: '10',
        dst: 'O',
        type: 'airport',
    },
    {
        name: 'Adelaide International Airport',
        city: 'Adelaide',
        country: 'Australia',
        iataCode: 'ADL',
        icaoCode: 'YPAD',
        latitude: '-34,94499969',
        longitude: '138,5310059',
        altitude: '20',
        timezone: '9,5',
        dst: 'O',
        type: 'airport',
    },
    {
        name: 'RAAF Base Edinburgh',
        city: 'Edinburgh',
        country: 'Australia',
        iataCode: 'N',
        icaoCode: 'YPED',
        latitude: '-34,70249939',
        longitude: '138,6210022',
        altitude: '67',
        timezone: '9,5',
        dst: 'O',
        type: 'airport',
    },
    {
        name: 'Perth Jandakot Airport',
        city: 'Perth',
        country: 'Australia',
        iataCode: 'JAD',
        icaoCode: 'YPJT',
        latitude: '-32,09749985',
        longitude: '115,8809967',
        altitude: '99',
        timezone: '8',
        dst: 'O',
        type: 'airport',
    },
    {
        name: 'Karratha Airport',
        city: 'Karratha',
        country: 'Australia',
        iataCode: 'KTA',
        icaoCode: 'YPKA',
        latitude: '-20,71220016',
        longitude: '116,7730026',
        altitude: '29',
        timezone: '8',
        dst: 'O',
        type: 'airport',
    },
    {
        name: 'Kalgoorlie Boulder Airport',
        city: 'Kalgoorlie',
        country: 'Australia',
        iataCode: 'KGI',
        icaoCode: 'YPKG',
        latitude: '-30,7894001',
        longitude: '121,461998',
        altitude: '1203',
        timezone: '8',
        dst: 'O',
        type: 'airport',
    },
    {
        name: 'Kununurra Airport',
        city: 'Kununurra',
        country: 'Australia',
        iataCode: 'KNX',
        icaoCode: 'YPKU',
        latitude: '-15,77810001',
        longitude: '128,7079926',
        altitude: '145',
        timezone: '8',
        dst: 'O',
        type: 'airport',
    },
    {
        name: 'Learmonth Airport',
        city: 'Learmonth',
        country: 'Australia',
        iataCode: 'LEA',
        icaoCode: 'YPLM',
        latitude: '-22,23559952',
        longitude: '114,0889969',
        altitude: '19',
        timezone: '8',
        dst: 'O',
        type: 'airport',
    },
    {
        name: 'Port Hedland International Airport',
        city: 'Port Hedland',
        country: 'Australia',
        iataCode: 'PHE',
        icaoCode: 'YPPD',
        latitude: '-20,37779999',
        longitude: '118,6259995',
        altitude: '33',
        timezone: '8',
        dst: 'O',
        type: 'airport',
    },
    {
        name: 'Adelaide Parafield Airport',
        city: 'Adelaide',
        country: 'Australia',
        iataCode: 'N',
        icaoCode: 'YPPF',
        latitude: '-34,79330063',
        longitude: '138,6329956',
        altitude: '57',
        timezone: '9,5',
        dst: 'O',
        type: 'airport',
    },
    {
        name: 'Perth International Airport',
        city: 'Perth',
        country: 'Australia',
        iataCode: 'PER',
        icaoCode: 'YPPH',
        latitude: '-31,94029999',
        longitude: '115,9670029',
        altitude: '67',
        timezone: '8',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Woomera Airfield',
        city: 'Woomera',
        country: 'Australia',
        iataCode: 'UMR',
        icaoCode: 'YPWR',
        latitude: '-31,14419937',
        longitude: '136,8170013',
        altitude: '548',
        timezone: '9,5',
        dst: 'O',
        type: 'airport',
    },
    {
        name: 'Christmas Island Airport',
        city: 'Christmas Island',
        country: 'Christmas Island',
        iataCode: 'XCH',
        icaoCode: 'YPXM',
        latitude: '-10,45059967',
        longitude: '105,6900024',
        altitude: '916',
        timezone: '7',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Sydney Bankstown Airport',
        city: 'Sydney',
        country: 'Australia',
        iataCode: 'BWU',
        icaoCode: 'YSBK',
        latitude: '-33,92440033',
        longitude: '150,9880066',
        altitude: '29',
        timezone: '10',
        dst: 'O',
        type: 'airport',
    },
    {
        name: 'Canberra International Airport',
        city: 'Canberra',
        country: 'Australia',
        iataCode: 'CBR',
        icaoCode: 'YSCB',
        latitude: '-35,30690002',
        longitude: '149,1950073',
        altitude: '1886',
        timezone: '10',
        dst: 'O',
        type: 'airport',
    },
    {
        name: 'Coffs Harbour Airport',
        city: "Coff's Harbour",
        country: 'Australia',
        iataCode: 'CFS',
        icaoCode: 'YSCH',
        latitude: '-30,32060051',
        longitude: '153,1159973',
        altitude: '18',
        timezone: '10',
        dst: 'O',
        type: 'airport',
    },
    {
        name: 'Camden Airport',
        city: 'Camden',
        country: 'Australia',
        iataCode: 'CDU',
        icaoCode: 'YSCN',
        latitude: '-34,04029846',
        longitude: '150,6869965',
        altitude: '230',
        timezone: '10',
        dst: 'O',
        type: 'airport',
    },
    {
        name: 'Dubbo City Regional Airport',
        city: 'Dubbo',
        country: 'Australia',
        iataCode: 'DBO',
        icaoCode: 'YSDU',
        latitude: '-32,21670151',
        longitude: '148,5749969',
        altitude: '935',
        timezone: '10',
        dst: 'O',
        type: 'airport',
    },
    {
        name: 'Norfolk Island International Airport',
        city: 'Norfolk Island',
        country: 'Norfolk Island',
        iataCode: 'NLK',
        icaoCode: 'YSNF',
        latitude: '-29,04159927',
        longitude: '167,9389954',
        altitude: '371',
        timezone: '11',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'RAAF Base Richmond',
        city: 'Richmond',
        country: 'Australia',
        iataCode: 'N',
        icaoCode: 'YSRI',
        latitude: '-33,600601',
        longitude: '150,781006',
        altitude: '67',
        timezone: '10',
        dst: 'O',
        type: 'airport',
    },
    {
        name: 'Sydney Kingsford Smith International Airport',
        city: 'Sydney',
        country: 'Australia',
        iataCode: 'SYD',
        icaoCode: 'YSSY',
        latitude: '-33,94609833',
        longitude: '151,177002',
        altitude: '21',
        timezone: '10',
        dst: 'O',
        type: 'airport',
    },
    {
        name: 'Tamworth Airport',
        city: 'Tamworth',
        country: 'Australia',
        iataCode: 'TMW',
        icaoCode: 'YSTW',
        latitude: '-31,08390045',
        longitude: '150,8470001',
        altitude: '1334',
        timezone: '10',
        dst: 'O',
        type: 'airport',
    },
    {
        name: 'Wagga Wagga City Airport',
        city: 'Wagga Wagga',
        country: 'Australia',
        iataCode: 'WGA',
        icaoCode: 'YSWG',
        latitude: '-35,16529846',
        longitude: '147,4660034',
        altitude: '724',
        timezone: '10',
        dst: 'O',
        type: 'airport',
    },
    {
        name: 'Beijing Capital International Airport',
        city: 'Beijing',
        country: 'China',
        iataCode: 'PEK',
        icaoCode: 'ZBAA',
        latitude: '40,08010101',
        longitude: '116,5849991',
        altitude: '116',
        timezone: '8',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Dongshan Airport',
        city: 'Hailar',
        country: 'China',
        iataCode: 'HLD',
        icaoCode: 'ZBLA',
        latitude: '49,20500183',
        longitude: '119,8249969',
        altitude: '2169',
        timezone: '8',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Tianjin Binhai International Airport',
        city: 'Tianjin',
        country: 'China',
        iataCode: 'TSN',
        icaoCode: 'ZBTJ',
        latitude: '39,12440109',
        longitude: '117,3460007',
        altitude: '10',
        timezone: '8',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Taiyuan Wusu Airport',
        city: 'Taiyuan',
        country: 'China',
        iataCode: 'TYN',
        icaoCode: 'ZBYN',
        latitude: '37,74689865',
        longitude: '112,6279984',
        altitude: '2575',
        timezone: '8',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Guangzhou Baiyun International Airport',
        city: 'Guangzhou',
        country: 'China',
        iataCode: 'CAN',
        icaoCode: 'ZGGG',
        latitude: '23,39240074',
        longitude: '113,2990036',
        altitude: '50',
        timezone: '8',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Changsha Huanghua International Airport',
        city: 'Changcha',
        country: 'China',
        iataCode: 'CSX',
        icaoCode: 'ZGHA',
        latitude: '28,18919945',
        longitude: '113,2200012',
        altitude: '217',
        timezone: '8',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Guilin Liangjiang International Airport',
        city: 'Guilin',
        country: 'China',
        iataCode: 'KWL',
        icaoCode: 'ZGKL',
        latitude: '25,21809959',
        longitude: '110,0390015',
        altitude: '570',
        timezone: '8',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Nanning Wuxu Airport',
        city: 'Nanning',
        country: 'China',
        iataCode: 'NNG',
        icaoCode: 'ZGNN',
        latitude: '22,60829926',
        longitude: '108,1719971',
        altitude: '421',
        timezone: '8',
        dst: 'U',
        type: 'airport',
    },
    {
        name: "Shenzhen Bao'an International Airport",
        city: 'Shenzhen',
        country: 'China',
        iataCode: 'SZX',
        icaoCode: 'ZGSZ',
        latitude: '22,63929939',
        longitude: '113,810997',
        altitude: '13',
        timezone: '8',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Zhengzhou Xinzheng International Airport',
        city: 'Zhengzhou',
        country: 'China',
        iataCode: 'CGO',
        icaoCode: 'ZHCC',
        latitude: '34,5196991',
        longitude: '113,8410034',
        altitude: '495',
        timezone: '8',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Wuhan Tianhe International Airport',
        city: 'Wuhan',
        country: 'China',
        iataCode: 'WUH',
        icaoCode: 'ZHHH',
        latitude: '30,78380013',
        longitude: '114,2080002',
        altitude: '113',
        timezone: '8',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Pyongyang Sunan International Airport',
        city: 'Pyongyang',
        country: 'North Korea',
        iataCode: 'FNJ',
        icaoCode: 'ZKPY',
        latitude: '39,224098',
        longitude: '125,669998',
        altitude: '117',
        timezone: '8,5',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Lanzhou Zhongchuan Airport',
        city: 'Lanzhou',
        country: 'China',
        iataCode: 'LHW',
        icaoCode: 'ZLLL',
        latitude: '36,51520157',
        longitude: '103,6200027',
        altitude: '6388',
        timezone: '8',
        dst: 'U',
        type: 'airport',
    },
    {
        name: "Xi'an Xianyang International Airport",
        city: "Xi'an",
        country: 'China',
        iataCode: 'XIY',
        icaoCode: 'ZLXY',
        latitude: '34,44710159',
        longitude: '108,7519989',
        altitude: '1572',
        timezone: '8',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Chinggis Khaan International Airport',
        city: 'Ulan Bator',
        country: 'Mongolia',
        iataCode: 'ULN',
        icaoCode: 'ZMUB',
        latitude: '47,8431015',
        longitude: '106,7669983',
        altitude: '4364',
        timezone: '8',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Xishuangbanna Gasa Airport',
        city: 'Jinghonggasa',
        country: 'China',
        iataCode: 'JHG',
        icaoCode: 'ZPJH',
        latitude: '21,97389984',
        longitude: '100,7600021',
        altitude: '1815',
        timezone: '8',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Kunming Changshui International Airport',
        city: 'Kunming',
        country: 'China',
        iataCode: 'KMG',
        icaoCode: 'ZPPP',
        latitude: '25,1019444',
        longitude: '102,9291667',
        altitude: '6903',
        timezone: '8',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Xiamen Gaoqi International Airport',
        city: 'Xiamen',
        country: 'China',
        iataCode: 'XMN',
        icaoCode: 'ZSAM',
        latitude: '24,54400063',
        longitude: '118,1279984',
        altitude: '59',
        timezone: '8',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Nanchang Changbei International Airport',
        city: 'Nanchang',
        country: 'China',
        iataCode: 'KHN',
        icaoCode: 'ZSCN',
        latitude: '28,86499977',
        longitude: '115,9000015',
        altitude: '143',
        timezone: '8',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Fuzhou Changle International Airport',
        city: 'Fuzhou',
        country: 'China',
        iataCode: 'FOC',
        icaoCode: 'ZSFZ',
        latitude: '25,93510056',
        longitude: '119,663002',
        altitude: '46',
        timezone: '8',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Hangzhou Xiaoshan International Airport',
        city: 'Hangzhou',
        country: 'China',
        iataCode: 'HGH',
        icaoCode: 'ZSHC',
        latitude: '30,22949982',
        longitude: '120,4339981',
        altitude: '23',
        timezone: '8',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Ningbo Lishe International Airport',
        city: 'Ninbo',
        country: 'China',
        iataCode: 'NGB',
        icaoCode: 'ZSNB',
        latitude: '29,82670021',
        longitude: '121,461998',
        altitude: '13',
        timezone: '8',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Nanjing Lukou Airport',
        city: 'Nanjing',
        country: 'China',
        iataCode: 'NKG',
        icaoCode: 'ZSNJ',
        latitude: '31,74200058',
        longitude: '118,8619995',
        altitude: '49',
        timezone: '8',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Hefei Luogang International Airport',
        city: 'Hefei',
        country: 'China',
        iataCode: 'HFE',
        icaoCode: 'ZSOF',
        latitude: '31,78000069',
        longitude: '117,2979965',
        altitude: '108',
        timezone: '8',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Liuting Airport',
        city: 'Qingdao',
        country: 'China',
        iataCode: 'TAO',
        icaoCode: 'ZSQD',
        latitude: '36,26610184',
        longitude: '120,3740005',
        altitude: '33',
        timezone: '8',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Shanghai Hongqiao International Airport',
        city: 'Shanghai',
        country: 'China',
        iataCode: 'SHA',
        icaoCode: 'ZSSS',
        latitude: '31,19790077',
        longitude: '121,3359985',
        altitude: '10',
        timezone: '8',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Yantai Laishan Airport',
        city: 'Yantai',
        country: 'China',
        iataCode: 'YNT',
        icaoCode: 'ZSYT',
        latitude: '37,40169907',
        longitude: '121,3720016',
        altitude: '59',
        timezone: '8',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Chongqing Jiangbei International Airport',
        city: 'Chongqing',
        country: 'China',
        iataCode: 'CKG',
        icaoCode: 'ZUCK',
        latitude: '29,71920013',
        longitude: '106,6419983',
        altitude: '1365',
        timezone: '8',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Longdongbao Airport',
        city: 'Guiyang',
        country: 'China',
        iataCode: 'KWE',
        icaoCode: 'ZUGY',
        latitude: '26,53849983',
        longitude: '106,8010025',
        altitude: '3736',
        timezone: '8',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Chengdu Shuangliu International Airport',
        city: 'Chengdu',
        country: 'China',
        iataCode: 'CTU',
        icaoCode: 'ZUUU',
        latitude: '30,57850075',
        longitude: '103,9469986',
        altitude: '1625',
        timezone: '8',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Xichang Qingshan Airport',
        city: 'Xichang',
        country: 'China',
        iataCode: 'XIC',
        icaoCode: 'ZUXC',
        latitude: '27,9890995',
        longitude: '102,1839981',
        altitude: '5112',
        timezone: '8',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Kashgar Airport',
        city: 'Kashi',
        country: 'China',
        iataCode: 'KHG',
        icaoCode: 'ZWSH',
        latitude: '39,54290009',
        longitude: '76,01999664',
        altitude: '4529',
        timezone: '8',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Hotan Airport',
        city: 'Hotan',
        country: 'China',
        iataCode: 'HTN',
        icaoCode: 'ZWTN',
        latitude: '37,03850174',
        longitude: '79,86489868',
        altitude: '4672',
        timezone: '8',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Ürümqi Diwopu International Airport',
        city: 'Urumqi',
        country: 'China',
        iataCode: 'URC',
        icaoCode: 'ZWWW',
        latitude: '43,90710068',
        longitude: '87,47419739',
        altitude: '2125',
        timezone: '8',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Taiping Airport',
        city: 'Harbin',
        country: 'China',
        iataCode: 'HRB',
        icaoCode: 'ZYHB',
        latitude: '45,62340164',
        longitude: '126,25',
        altitude: '457',
        timezone: '8',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Mudanjiang Hailang International Airport',
        city: 'Mudanjiang',
        country: 'China',
        iataCode: 'MDG',
        icaoCode: 'ZYMD',
        latitude: '44,52410126',
        longitude: '129,5690002',
        altitude: '883',
        timezone: '8',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Zhoushuizi Airport',
        city: 'Dalian',
        country: 'China',
        iataCode: 'DLC',
        icaoCode: 'ZYTL',
        latitude: '38,96569824',
        longitude: '121,5390015',
        altitude: '107',
        timezone: '8',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Shanghai Pudong International Airport',
        city: 'Shanghai',
        country: 'China',
        iataCode: 'PVG',
        icaoCode: 'ZSPD',
        latitude: '31,14340019',
        longitude: '121,8050003',
        altitude: '13',
        timezone: '8',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Pulau Tioman Airport',
        city: 'Tioman',
        country: 'Malaysia',
        iataCode: 'TOD',
        icaoCode: 'WMBT',
        latitude: '2,818180084',
        longitude: '104,1600037',
        altitude: '15',
        timezone: '8',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Sultan Abdul Aziz Shah International Airport',
        city: 'Kuala Lumpur',
        country: 'Malaysia',
        iataCode: 'SZB',
        icaoCode: 'WMSA',
        latitude: '3,130579948',
        longitude: '101,5490036',
        altitude: '90',
        timezone: '8',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Noto Airport',
        city: 'Wajima',
        country: 'Japan',
        iataCode: 'NTQ',
        icaoCode: 'RJNW',
        latitude: '37,29309845',
        longitude: '136,9620056',
        altitude: '718',
        timezone: '9',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Borg El Arab International Airport',
        city: 'Alexandria',
        country: 'Egypt',
        iataCode: 'HBE',
        icaoCode: 'HEBA',
        latitude: '30,91769981',
        longitude: '29,69639969',
        altitude: '177',
        timezone: '2',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Barter Island LRRS Airport',
        city: 'Barter Island',
        country: 'United States',
        iataCode: 'BTI',
        icaoCode: 'PABA',
        latitude: '70,13400269',
        longitude: '-143,5820007',
        altitude: '2',
        timezone: '-9',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Cape Lisburne LRRS Airport',
        city: 'Cape Lisburne',
        country: 'United States',
        iataCode: 'LUR',
        icaoCode: 'PALU',
        latitude: '68,87509918',
        longitude: '-166,1100006',
        altitude: '16',
        timezone: '-9',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Point Lay LRRS Airport',
        city: 'Point Lay',
        country: 'United States',
        iataCode: 'PIZ',
        icaoCode: 'PPIZ',
        latitude: '69,73290253',
        longitude: '-163,0050049',
        altitude: '22',
        timezone: '-9',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Hilo International Airport',
        city: 'Hilo',
        country: 'United States',
        iataCode: 'ITO',
        icaoCode: 'PHTO',
        latitude: '19,72139931',
        longitude: '-155,0480042',
        altitude: '38',
        timezone: '-10',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Orlando Executive Airport',
        city: 'Orlando',
        country: 'United States',
        iataCode: 'ORL',
        icaoCode: 'KORL',
        latitude: '28,5454998',
        longitude: '-81,332901',
        altitude: '113',
        timezone: '-5',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Bettles Airport',
        city: 'Bettles',
        country: 'United States',
        iataCode: 'BTT',
        icaoCode: 'PABT',
        latitude: '66,91390228',
        longitude: '-151,529007',
        altitude: '647',
        timezone: '-9',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Clear Airport',
        city: 'Clear Mews',
        country: 'United States',
        iataCode: 'Z84',
        icaoCode: 'PACL',
        latitude: '64,301201',
        longitude: '-149,119995',
        altitude: '552',
        timezone: '-9',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Indian Mountain LRRS Airport',
        city: 'Indian Mountains',
        country: 'United States',
        iataCode: 'UTO',
        icaoCode: 'PAIM',
        latitude: '65,99279785',
        longitude: '-153,7039948',
        altitude: '1273',
        timezone: '-9',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Fort Yukon Airport',
        city: 'Fort Yukon',
        country: 'United States',
        iataCode: 'FYU',
        icaoCode: 'PFYU',
        latitude: '66,57150269',
        longitude: '-145,25',
        altitude: '433',
        timezone: '-9',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Sparrevohn LRRS Airport',
        city: 'Sparrevohn',
        country: 'United States',
        iataCode: 'SVW',
        icaoCode: 'PASV',
        latitude: '61,09740067',
        longitude: '-155,5740051',
        altitude: '1585',
        timezone: '-9',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Bryant Army Heliport',
        city: 'Fort Richardson',
        country: 'United States',
        iataCode: 'FRN',
        icaoCode: 'PAFR',
        latitude: '61,26639938',
        longitude: '-149,6529999',
        altitude: '378',
        timezone: '-9',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Tatalina LRRS Airport',
        city: 'Tatalina',
        country: 'United States',
        iataCode: 'TLJ',
        icaoCode: 'PATL',
        latitude: '62,89440155',
        longitude: '-155,977005',
        altitude: '964',
        timezone: '-9',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Cape Romanzof LRRS Airport',
        city: 'Cape Romanzof',
        country: 'United States',
        iataCode: 'CZF',
        icaoCode: 'PACZ',
        latitude: '61,78030014',
        longitude: '-166,0390015',
        altitude: '464',
        timezone: '-9',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Laurence G Hanscom Field',
        city: 'Bedford',
        country: 'United States',
        iataCode: 'BED',
        icaoCode: 'KBED',
        latitude: '42,47000122',
        longitude: '-71,28900146',
        altitude: '133',
        timezone: '-5',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'St Paul Island Airport',
        city: 'St. Paul Island',
        country: 'United States',
        iataCode: 'SNP',
        icaoCode: 'PASN',
        latitude: '57,16730118',
        longitude: '-170,2200012',
        altitude: '63',
        timezone: '-9',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Cape Newenham LRRS Airport',
        city: 'Cape Newenham',
        country: 'United States',
        iataCode: 'EHM',
        icaoCode: 'PAEH',
        latitude: '58,64640045',
        longitude: '-162,0630035',
        altitude: '541',
        timezone: '-9',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'St George Airport',
        city: 'Point Barrow',
        country: 'United States',
        iataCode: 'STG',
        icaoCode: 'PAPB',
        latitude: '56,57830048',
        longitude: '-169,6620026',
        altitude: '125',
        timezone: '-9',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Iliamna Airport',
        city: 'Iliamna',
        country: 'United States',
        iataCode: 'ILI',
        icaoCode: 'PAIL',
        latitude: '59,75439835',
        longitude: '-154,9109955',
        altitude: '192',
        timezone: '-9',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Platinum Airport',
        city: 'Port Moller',
        country: 'United States',
        iataCode: 'PTU',
        icaoCode: 'PAPM',
        latitude: '59,01139832',
        longitude: '-161,8200073',
        altitude: '15',
        timezone: '-9',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Big Mountain Airport',
        city: 'Big Mountain',
        country: 'United States',
        iataCode: 'BMX',
        icaoCode: 'PABM',
        latitude: '59,36119843',
        longitude: '-155,2590027',
        altitude: '663',
        timezone: '-9',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Oscoda Wurtsmith Airport',
        city: 'Oscoda',
        country: 'United States',
        iataCode: 'OSC',
        icaoCode: 'KOSC',
        latitude: '44,45159912',
        longitude: '-83,39409637',
        altitude: '633',
        timezone: '-5',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Marina Municipal Airport',
        city: 'Fort Ord',
        country: 'United States',
        iataCode: 'OAR',
        icaoCode: 'KOAR',
        latitude: '36,68190002',
        longitude: '-121,762001',
        altitude: '137',
        timezone: '-8',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Sacramento Mather Airport',
        city: 'Sacramento',
        country: 'United States',
        iataCode: 'MHR',
        icaoCode: 'KMHR',
        latitude: '38,55390167',
        longitude: '-121,2979965',
        altitude: '98',
        timezone: '-8',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Bicycle Lake Army Air Field',
        city: 'Fort Irwin',
        country: 'United States',
        iataCode: 'BYS',
        icaoCode: 'KBYS',
        latitude: '35,2804985',
        longitude: '-116,6299973',
        altitude: '2350',
        timezone: '-8',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Twentynine Palms (Self) Airport',
        city: 'Twenty Nine Palms',
        country: 'United States',
        iataCode: 'NXP',
        icaoCode: 'KNXP',
        latitude: '34,2961998',
        longitude: '-116,1620026',
        altitude: '2051',
        timezone: '-8',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Fort Smith Regional Airport',
        city: 'Fort Smith',
        country: 'United States',
        iataCode: 'FSM',
        icaoCode: 'KFSM',
        latitude: '35,33660126',
        longitude: '-94,36740112',
        altitude: '469',
        timezone: '-6',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Merrill Field',
        city: 'Anchorage',
        country: 'United States',
        iataCode: 'MRI',
        icaoCode: 'PAMR',
        latitude: '61,21350098',
        longitude: '-149,8439941',
        altitude: '137',
        timezone: '-9',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Grants-Milan Municipal Airport',
        city: 'Grants',
        country: 'United States',
        iataCode: 'GNT',
        icaoCode: 'KGNT',
        latitude: '35,16730118',
        longitude: '-107,9020004',
        altitude: '6537',
        timezone: '-7',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Ponca City Regional Airport',
        city: 'Ponca City',
        country: 'United States',
        iataCode: 'PNC',
        icaoCode: 'KPNC',
        latitude: '36,73199844',
        longitude: '-97,09980011',
        altitude: '1008',
        timezone: '-6',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Hunter Army Air Field',
        city: 'Hunter Aaf',
        country: 'United States',
        iataCode: 'SVN',
        icaoCode: 'KSVN',
        latitude: '32,00999832',
        longitude: '-81,14569855',
        altitude: '41',
        timezone: '-5',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Grand Forks International Airport',
        city: 'Grand Forks',
        country: 'United States',
        iataCode: 'GFK',
        icaoCode: 'KGFK',
        latitude: '47,949299',
        longitude: '-97,176102',
        altitude: '845',
        timezone: '-6',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Grider Field',
        city: 'Pine Bluff',
        country: 'United States',
        iataCode: 'PBF',
        icaoCode: 'KPBF',
        latitude: '34,17309952',
        longitude: '-91,93560028',
        altitude: '206',
        timezone: '-6',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Whiting Field Naval Air Station - North',
        city: 'Milton',
        country: 'United States',
        iataCode: 'NSE',
        icaoCode: 'KNSE',
        latitude: '30,7241993',
        longitude: '-87,02189636',
        altitude: '199',
        timezone: '-6',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Hana Airport',
        city: 'Hana',
        country: 'United States',
        iataCode: 'HNM',
        icaoCode: 'PHHN',
        latitude: '20,79560089',
        longitude: '-156,0140076',
        altitude: '78',
        timezone: '-10',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Ernest A. Love Field',
        city: 'Prescott',
        country: 'United States',
        iataCode: 'PRC',
        icaoCode: 'KPRC',
        latitude: '34,65449905',
        longitude: '-112,4199982',
        altitude: '5045',
        timezone: '-7',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Trenton Mercer Airport',
        city: 'Trenton',
        country: 'United States',
        iataCode: 'TTN',
        icaoCode: 'KTTN',
        latitude: '40,27669907',
        longitude: '-74,81349945',
        altitude: '213',
        timezone: '-5',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'General Edward Lawrence Logan International Airport',
        city: 'Boston',
        country: 'United States',
        iataCode: 'BOS',
        icaoCode: 'KBOS',
        latitude: '42,36429977',
        longitude: '-71,00520325',
        altitude: '20',
        timezone: '-5',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Travis Air Force Base',
        city: 'Fairfield',
        country: 'United States',
        iataCode: 'SUU',
        icaoCode: 'KSUU',
        latitude: '38,26269913',
        longitude: '-121,927002',
        altitude: '62',
        timezone: '-8',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Griffiss International Airport',
        city: 'Rome',
        country: 'United States',
        iataCode: 'RME',
        icaoCode: 'KRME',
        latitude: '43,23379898',
        longitude: '-75,40699768',
        altitude: '504',
        timezone: '-5',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Wendover Airport',
        city: 'Wendover',
        country: 'United States',
        iataCode: 'ENV',
        icaoCode: 'KENV',
        latitude: '40,71870041',
        longitude: '-114,0309982',
        altitude: '4237',
        timezone: '-7',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Mobile Downtown Airport',
        city: 'Mobile',
        country: 'United States',
        iataCode: 'BFM',
        icaoCode: 'KBFM',
        latitude: '30,62680054',
        longitude: '-88,06809998',
        altitude: '26',
        timezone: '-6',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Metropolitan Oakland International Airport',
        city: 'Oakland',
        country: 'United States',
        iataCode: 'OAK',
        icaoCode: 'KOAK',
        latitude: '37,72129822',
        longitude: '-122,2210007',
        altitude: '9',
        timezone: '-8',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Eppley Airfield',
        city: 'Omaha',
        country: 'United States',
        iataCode: 'OMA',
        icaoCode: 'KOMA',
        latitude: '41,30319977',
        longitude: '-95,89409637',
        altitude: '984',
        timezone: '-6',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Port Angeles Cgas Airport',
        city: 'Port Angeles',
        country: 'United States',
        iataCode: 'NOW',
        icaoCode: 'KNOW',
        latitude: '48,14149857',
        longitude: '-123,4140015',
        altitude: '13',
        timezone: '-8',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Kahului Airport',
        city: 'Kahului',
        country: 'United States',
        iataCode: 'OGG',
        icaoCode: 'PHOG',
        latitude: '20,89859962',
        longitude: '-156,4299927',
        altitude: '54',
        timezone: '-10',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Wichita Mid Continent Airport',
        city: 'Wichita',
        country: 'United States',
        iataCode: 'ICT',
        icaoCode: 'KICT',
        latitude: '37,64989853',
        longitude: '-97,43309784',
        altitude: '1333',
        timezone: '-6',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Kansas City International Airport',
        city: 'Kansas City',
        country: 'United States',
        iataCode: 'MCI',
        icaoCode: 'KMCI',
        latitude: '39,2976',
        longitude: '-94,713898',
        altitude: '1026',
        timezone: '-6',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Dane County Regional Truax Field',
        city: 'Madison',
        country: 'United States',
        iataCode: 'MSN',
        icaoCode: 'KMSN',
        latitude: '43,13990021',
        longitude: '-89,33750153',
        altitude: '887',
        timezone: '-6',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Dillingham Airport',
        city: 'Dillingham',
        country: 'United States',
        iataCode: 'DLG',
        icaoCode: 'PADL',
        latitude: '59,04470062',
        longitude: '-158,5050049',
        altitude: '81',
        timezone: '-9',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Boone County Airport',
        city: 'Harrison',
        country: 'United States',
        iataCode: 'HRO',
        icaoCode: 'KHRO',
        latitude: '36,26150131',
        longitude: '-93,15470123',
        altitude: '1365',
        timezone: '-6',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Phoenix Sky Harbor International Airport',
        city: 'Phoenix',
        country: 'United States',
        iataCode: 'PHX',
        icaoCode: 'KPHX',
        latitude: '33,43429947',
        longitude: '-112,012001',
        altitude: '1135',
        timezone: '-7',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Bangor International Airport',
        city: 'Bangor',
        country: 'United States',
        iataCode: 'BGR',
        icaoCode: 'KBGR',
        latitude: '44,80739975',
        longitude: '-68,82810211',
        altitude: '192',
        timezone: '-5',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Fort Lauderdale Executive Airport',
        city: 'Fort Lauderdale',
        country: 'United States',
        iataCode: 'FXE',
        icaoCode: 'KFXE',
        latitude: '26,19729996',
        longitude: '-80,17070007',
        altitude: '13',
        timezone: '-5',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'East Texas Regional Airport',
        city: 'Longview',
        country: 'United States',
        iataCode: 'GGG',
        icaoCode: 'KGGG',
        latitude: '32,38399887',
        longitude: '-94,71150208',
        altitude: '365',
        timezone: '-6',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Anderson Regional Airport',
        city: 'Andersen',
        country: 'United States',
        iataCode: 'AND',
        icaoCode: 'KAND',
        latitude: '34,49459839',
        longitude: '-82,70939636',
        altitude: '782',
        timezone: '-5',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Spokane International Airport',
        city: 'Spokane',
        country: 'United States',
        iataCode: 'GEG',
        icaoCode: 'KGEG',
        latitude: '47,61989975',
        longitude: '-117,5339966',
        altitude: '2376',
        timezone: '-8',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'North Perry Airport',
        city: 'Hollywood',
        country: 'United States',
        iataCode: 'HWO',
        icaoCode: 'KHWO',
        latitude: '26,00119972',
        longitude: '-80,24069977',
        altitude: '8',
        timezone: '-5',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'San Francisco International Airport',
        city: 'San Francisco',
        country: 'United States',
        iataCode: 'SFO',
        icaoCode: 'KSFO',
        latitude: '37,61899948',
        longitude: '-122,375',
        altitude: '13',
        timezone: '-8',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Cut Bank International Airport',
        city: 'Cutbank',
        country: 'United States',
        iataCode: 'CTB',
        icaoCode: 'KCTB',
        latitude: '48,60839844',
        longitude: '-112,3759995',
        altitude: '3854',
        timezone: '-7',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Acadiana Regional Airport',
        city: 'Louisiana',
        country: 'United States',
        iataCode: 'ARA',
        icaoCode: 'KARA',
        latitude: '30,0378',
        longitude: '-91,883904',
        altitude: '24',
        timezone: '-6',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Gainesville Regional Airport',
        city: 'Gainesville',
        country: 'United States',
        iataCode: 'GNV',
        icaoCode: 'KGNV',
        latitude: '29,69009972',
        longitude: '-82,27179718',
        altitude: '152',
        timezone: '-5',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Memphis International Airport',
        city: 'Memphis',
        country: 'United States',
        iataCode: 'MEM',
        icaoCode: 'KMEM',
        latitude: '35,04240036',
        longitude: '-89,97669983',
        altitude: '341',
        timezone: '-6',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Bisbee Douglas International Airport',
        city: 'Douglas',
        country: 'United States',
        iataCode: 'DUG',
        icaoCode: 'KDUG',
        latitude: '31,46899986',
        longitude: '-109,6039963',
        altitude: '4154',
        timezone: '-7',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Allen Army Airfield',
        city: 'Delta Junction',
        country: 'United States',
        iataCode: 'BIG',
        icaoCode: 'PABI',
        latitude: '63,99449921',
        longitude: '-145,7220001',
        altitude: '1291',
        timezone: '-9',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'TSTC Waco Airport',
        city: 'Waco',
        country: 'United States',
        iataCode: 'CNW',
        icaoCode: 'KCNW',
        latitude: '31,63780022',
        longitude: '-97,07409668',
        altitude: '470',
        timezone: '-6',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Annette Island Airport',
        city: 'Annette Island',
        country: 'United States',
        iataCode: 'ANN',
        icaoCode: 'PANT',
        latitude: '55,04240036',
        longitude: '-131,5720062',
        altitude: '119',
        timezone: '-9',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Caribou Municipal Airport',
        city: 'Caribou',
        country: 'United States',
        iataCode: 'CAR',
        icaoCode: 'KCAR',
        latitude: '46,87149811',
        longitude: '-68,01789856',
        altitude: '626',
        timezone: '-5',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Little Rock Air Force Base',
        city: 'Jacksonville',
        country: 'United States',
        iataCode: 'LRF',
        icaoCode: 'KLRF',
        latitude: '34,91690063',
        longitude: '-92,14969635',
        altitude: '311',
        timezone: '-6',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Redstone Army Air Field',
        city: 'Redstone',
        country: 'United States',
        iataCode: 'HUA',
        icaoCode: 'KHUA',
        latitude: '34,67869949',
        longitude: '-86,68479919',
        altitude: '684',
        timezone: '-6',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Pope Field',
        city: 'Fort Bragg',
        country: 'United States',
        iataCode: 'POB',
        icaoCode: 'KPOB',
        latitude: '35,17089844',
        longitude: '-79,01450348',
        altitude: '217',
        timezone: '-5',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Dalhart Municipal Airport',
        city: 'Dalhart',
        country: 'United States',
        iataCode: 'DHT',
        icaoCode: 'KDHT',
        latitude: '36,02259827',
        longitude: '-102,5469971',
        altitude: '3991',
        timezone: '-6',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Laughlin Air Force Base',
        city: 'Del Rio',
        country: 'United States',
        iataCode: 'DLF',
        icaoCode: 'KDLF',
        latitude: '29,35950089',
        longitude: '-100,7779999',
        altitude: '1082',
        timezone: '-6',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Los Angeles International Airport',
        city: 'Los Angeles',
        country: 'United States',
        iataCode: 'LAX',
        icaoCode: 'KLAX',
        latitude: '33,94250107',
        longitude: '-118,4079971',
        altitude: '125',
        timezone: '-8',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Anniston Metropolitan Airport',
        city: 'Anniston',
        country: 'United States',
        iataCode: 'ANB',
        icaoCode: 'KANB',
        latitude: '33,58819962',
        longitude: '-85,85810089',
        altitude: '612',
        timezone: '-6',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Cleveland Hopkins International Airport',
        city: 'Cleveland',
        country: 'United States',
        iataCode: 'CLE',
        icaoCode: 'KCLE',
        latitude: '41,4117012',
        longitude: '-81,84980011',
        altitude: '791',
        timezone: '-5',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Dover Air Force Base',
        city: 'Dover',
        country: 'United States',
        iataCode: 'DOV',
        icaoCode: 'KDOV',
        latitude: '39,12950134',
        longitude: '-75,46600342',
        altitude: '24',
        timezone: '-5',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Cincinnati Northern Kentucky International Airport',
        city: 'Cincinnati',
        country: 'United States',
        iataCode: 'CVG',
        icaoCode: 'KCVG',
        latitude: '39,04880142',
        longitude: '-84,6678009',
        altitude: '896',
        timezone: '-5',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Tipton Airport',
        city: 'Fort Meade',
        country: 'United States',
        iataCode: 'FME',
        icaoCode: 'KFME',
        latitude: '39,08539963',
        longitude: '-76,75939941',
        altitude: '150',
        timezone: '-5',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'China Lake Naws (Armitage Field) Airport',
        city: 'China Lake',
        country: 'United States',
        iataCode: 'NID',
        icaoCode: 'KNID',
        latitude: '35,6853981',
        longitude: '-117,6920013',
        altitude: '2283',
        timezone: '-8',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Huron Regional Airport',
        city: 'Huron',
        country: 'United States',
        iataCode: 'HON',
        icaoCode: 'KHON',
        latitude: '44,3852005',
        longitude: '-98,22850037',
        altitude: '1289',
        timezone: '-6',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Juneau International Airport',
        city: 'Juneau',
        country: 'United States',
        iataCode: 'JNU',
        icaoCode: 'PAJN',
        latitude: '58,35499954',
        longitude: '-134,576004',
        altitude: '21',
        timezone: '-9',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Lafayette Regional Airport',
        city: 'Lafayette',
        country: 'United States',
        iataCode: 'LFT',
        icaoCode: 'KLFT',
        latitude: '30,20529938',
        longitude: '-91,98760223',
        altitude: '42',
        timezone: '-6',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Newark Liberty International Airport',
        city: 'Newark',
        country: 'United States',
        iataCode: 'EWR',
        icaoCode: 'KEWR',
        latitude: '40,69250107',
        longitude: '-74,16870117',
        altitude: '18',
        timezone: '-5',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Boise Air Terminal/Gowen field',
        city: 'Boise',
        country: 'United States',
        iataCode: 'BOI',
        icaoCode: 'KBOI',
        latitude: '43,56439972',
        longitude: '-116,2229996',
        altitude: '2871',
        timezone: '-7',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Creech Air Force Base',
        city: 'Indian Springs',
        country: 'United States',
        iataCode: 'INS',
        icaoCode: 'KINS',
        latitude: '36,58720016',
        longitude: '-115,6729965',
        altitude: '3133',
        timezone: '-8',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Garden City Regional Airport',
        city: 'Garden City',
        country: 'United States',
        iataCode: 'GCK',
        icaoCode: 'KGCK',
        latitude: '37,92750168',
        longitude: '-100,723999',
        altitude: '2891',
        timezone: '-6',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Minot International Airport',
        city: 'Minot',
        country: 'United States',
        iataCode: 'MOT',
        icaoCode: 'KMOT',
        latitude: '48,25939941',
        longitude: '-101,2799988',
        altitude: '1716',
        timezone: '-6',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Wheeler Army Airfield',
        city: 'Wahiawa',
        country: 'United States',
        iataCode: 'HHI',
        icaoCode: 'PHHI',
        latitude: '21,48349953',
        longitude: '-158,0399933',
        altitude: '837',
        timezone: '-10',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Maxwell Air Force Base',
        city: 'Montgomery',
        country: 'United States',
        iataCode: 'MXF',
        icaoCode: 'KMXF',
        latitude: '32,38290024',
        longitude: '-86,36579895',
        altitude: '171',
        timezone: '-6',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Robinson Army Air Field',
        city: 'Robinson',
        country: 'United States',
        iataCode: 'N',
        icaoCode: 'KRBM',
        latitude: '34,85010147',
        longitude: '-92,30020142',
        altitude: '587',
        timezone: '-6',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Dallas Love Field',
        city: 'Dallas',
        country: 'United States',
        iataCode: 'DAL',
        icaoCode: 'KDAL',
        latitude: '32,8470993',
        longitude: '-96,85179901',
        altitude: '487',
        timezone: '-6',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Butts AAF (Fort Carson) Air Field',
        city: 'Fort Carson',
        country: 'United States',
        iataCode: 'FCS',
        icaoCode: 'KFCS',
        latitude: '38,67839813',
        longitude: '-104,7570038',
        altitude: '5838',
        timezone: '-7',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Helena Regional Airport',
        city: 'Helena',
        country: 'United States',
        iataCode: 'HLN',
        icaoCode: 'KHLN',
        latitude: '46,60680008',
        longitude: '-111,9830017',
        altitude: '3877',
        timezone: '-7',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Miramar Marine Corps Air Station - Mitscher Field',
        city: 'Miramar',
        country: 'United States',
        iataCode: 'NKX',
        icaoCode: 'KNKX',
        latitude: '32,86840057',
        longitude: '-117,1429977',
        altitude: '477',
        timezone: '-8',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Luke Air Force Base',
        city: 'Phoenix',
        country: 'United States',
        iataCode: 'LUF',
        icaoCode: 'KLUF',
        latitude: '33,53499985',
        longitude: '-112,3830032',
        altitude: '1085',
        timezone: '-7',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Hurlburt Field',
        city: 'Mary Esther',
        country: 'United States',
        iataCode: 'N',
        icaoCode: 'KHRT',
        latitude: '30,42779922',
        longitude: '-86,68930054',
        altitude: '38',
        timezone: '-6',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Jack Northrop Field Hawthorne Municipal Airport',
        city: 'Hawthorne',
        country: 'United States',
        iataCode: 'HHR',
        icaoCode: 'KHHR',
        latitude: '33,922798',
        longitude: '-118,334999',
        altitude: '66',
        timezone: '-8',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Houlton International Airport',
        city: 'Houlton',
        country: 'United States',
        iataCode: 'HUL',
        icaoCode: 'KHUL',
        latitude: '46,12310028',
        longitude: '-67,792099',
        altitude: '489',
        timezone: '-5',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Vance Air Force Base',
        city: 'Enid',
        country: 'United States',
        iataCode: 'END',
        icaoCode: 'KEND',
        latitude: '36,33919907',
        longitude: '-97,91649628',
        altitude: '1307',
        timezone: '-6',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Point Mugu Naval Air Station (Naval Base Ventura Co)',
        city: 'Point Mugu',
        country: 'United States',
        iataCode: 'NTD',
        icaoCode: 'KNTD',
        latitude: '34,12030029',
        longitude: '-119,1210022',
        altitude: '13',
        timezone: '-8',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Edwards Air Force Base',
        city: 'Edwards Afb',
        country: 'United States',
        iataCode: 'EDW',
        icaoCode: 'KEDW',
        latitude: '34,905399',
        longitude: '-117,884003',
        altitude: '2312',
        timezone: '-8',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Lake Charles Regional Airport',
        city: 'Lake Charles',
        country: 'United States',
        iataCode: 'LCH',
        icaoCode: 'KLCH',
        latitude: '30,12610054',
        longitude: '-93,22329712',
        altitude: '15',
        timezone: '-6',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Kona International At Keahole Airport',
        city: 'Kona',
        country: 'United States',
        iataCode: 'KOA',
        icaoCode: 'PHKO',
        latitude: '19,73880005',
        longitude: '-156,0460052',
        altitude: '47',
        timezone: '-10',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Myrtle Beach International Airport',
        city: 'Myrtle Beach',
        country: 'United States',
        iataCode: 'MYR',
        icaoCode: 'KMYR',
        latitude: '33,67969894',
        longitude: '-78,92829895',
        altitude: '25',
        timezone: '-5',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Lemoore Naval Air Station (Reeves Field) Airport',
        city: 'Lemoore',
        country: 'United States',
        iataCode: 'NLC',
        icaoCode: 'KNLC',
        latitude: '36,33300018',
        longitude: '-119,9520035',
        altitude: '232',
        timezone: '-8',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Nantucket Memorial Airport',
        city: 'Nantucket',
        country: 'United States',
        iataCode: 'ACK',
        icaoCode: 'KACK',
        latitude: '41,25310135',
        longitude: '-70,06020355',
        altitude: '47',
        timezone: '-5',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Felker Army Air Field',
        city: 'Fort Eustis',
        country: 'United States',
        iataCode: 'FAF',
        icaoCode: 'KFAF',
        latitude: '37,13249969',
        longitude: '-76,6088028',
        altitude: '12',
        timezone: '-5',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Campbell AAF (Fort Campbell) Air Field',
        city: 'Hopkinsville',
        country: 'United States',
        iataCode: 'HOP',
        icaoCode: 'KHOP',
        latitude: '36,66859818',
        longitude: '-87,49620056',
        altitude: '573',
        timezone: '-6',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Ronald Reagan Washington National Airport',
        city: 'Washington',
        country: 'United States',
        iataCode: 'DCA',
        icaoCode: 'KDCA',
        latitude: '38,8521',
        longitude: '-77,037697',
        altitude: '15',
        timezone: '-5',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Patuxent River Naval Air Station/Trapnell Field Aiport',
        city: 'Patuxent River',
        country: 'United States',
        iataCode: 'NHK',
        icaoCode: 'KNHK',
        latitude: '38,2859993',
        longitude: '-76,41179657',
        altitude: '39',
        timezone: '-5',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Palacios Municipal Airport',
        city: 'Palacios',
        country: 'United States',
        iataCode: 'PSX',
        icaoCode: 'KPSX',
        latitude: '28,72750092',
        longitude: '-96,25099945',
        altitude: '14',
        timezone: '-6',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Arkansas International Airport',
        city: 'Blytheville',
        country: 'United States',
        iataCode: 'BYH',
        icaoCode: 'KBYH',
        latitude: '35,96429825',
        longitude: '-89,94400024',
        altitude: '254',
        timezone: '-6',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Atlantic City International Airport',
        city: 'Atlantic City',
        country: 'United States',
        iataCode: 'ACY',
        icaoCode: 'KACY',
        latitude: '39,45759964',
        longitude: '-74,57720184',
        altitude: '75',
        timezone: '-5',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Tinker Air Force Base',
        city: 'Oklahoma City',
        country: 'United States',
        iataCode: 'TIK',
        icaoCode: 'KTIK',
        latitude: '35,41469955',
        longitude: '-97,38659668',
        altitude: '1291',
        timezone: '-6',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Pueblo Memorial Airport',
        city: 'Pueblo',
        country: 'United States',
        iataCode: 'PUB',
        icaoCode: 'KPUB',
        latitude: '38,28910065',
        longitude: '-104,4970016',
        altitude: '4726',
        timezone: '-7',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Northern Maine Regional Airport at Presque Isle',
        city: 'Presque Isle',
        country: 'United States',
        iataCode: 'PQI',
        icaoCode: 'KPQI',
        latitude: '46,68899918',
        longitude: '-68,0447998',
        altitude: '534',
        timezone: '-5',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Gray Army Air Field',
        city: 'Fort Lewis',
        country: 'United States',
        iataCode: 'GRF',
        icaoCode: 'KGRF',
        latitude: '47,07920074',
        longitude: '-122,5810013',
        altitude: '300',
        timezone: '-8',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Kodiak Airport',
        city: 'Kodiak',
        country: 'United States',
        iataCode: 'ADQ',
        icaoCode: 'PADQ',
        latitude: '57,75',
        longitude: '-152,4940033',
        altitude: '78',
        timezone: '-9',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Upolu Airport',
        city: 'Opolu',
        country: 'United States',
        iataCode: 'UPP',
        icaoCode: 'PHUP',
        latitude: '20,26530075',
        longitude: '-155,8600006',
        altitude: '96',
        timezone: '-10',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Fort Lauderdale Hollywood International Airport',
        city: 'Fort Lauderdale',
        country: 'United States',
        iataCode: 'FLL',
        icaoCode: 'KFLL',
        latitude: '26,07259941',
        longitude: '-80,15270233',
        altitude: '9',
        timezone: '-5',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Davis Field',
        city: 'Muskogee',
        country: 'United States',
        iataCode: 'MKO',
        icaoCode: 'KMKO',
        latitude: '35,65650177',
        longitude: '-95,36669922',
        altitude: '611',
        timezone: '-6',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Falls International Airport',
        city: 'International Falls',
        country: 'United States',
        iataCode: 'INL',
        icaoCode: 'KINL',
        latitude: '48,56620026',
        longitude: '-93,40309906',
        altitude: '1185',
        timezone: '-6',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Salt Lake City International Airport',
        city: 'Salt Lake City',
        country: 'United States',
        iataCode: 'SLC',
        icaoCode: 'KSLC',
        latitude: '40,78839874',
        longitude: '-111,9779968',
        altitude: '4227',
        timezone: '-7',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Childress Municipal Airport',
        city: 'Childress',
        country: 'United States',
        iataCode: 'CDS',
        icaoCode: 'KCDS',
        latitude: '34,43379974',
        longitude: '-100,288002',
        altitude: '1954',
        timezone: '-6',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Keesler Air Force Base',
        city: 'Biloxi',
        country: 'United States',
        iataCode: 'BIX',
        icaoCode: 'KBIX',
        latitude: '30,41040039',
        longitude: '-88,92440033',
        altitude: '33',
        timezone: '-6',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Lawson Army Air Field (Fort Benning)',
        city: 'Fort Benning',
        country: 'United States',
        iataCode: 'LSF',
        icaoCode: 'KLSF',
        latitude: '32,33729935',
        longitude: '-84,99130249',
        altitude: '232',
        timezone: '-5',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Marshall Army Air Field',
        city: 'Fort Riley',
        country: 'United States',
        iataCode: 'FRI',
        icaoCode: 'KFRI',
        latitude: '39,05530167',
        longitude: '-96,76450348',
        altitude: '1065',
        timezone: '-6',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Harrisburg International Airport',
        city: 'Harrisburg',
        country: 'United States',
        iataCode: 'MDT',
        icaoCode: 'KMDT',
        latitude: '40,19350052',
        longitude: '-76,76339722',
        altitude: '310',
        timezone: '-5',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Lincoln Airport',
        city: 'Lincoln',
        country: 'United States',
        iataCode: 'LNK',
        icaoCode: 'KLNK',
        latitude: '40,85100174',
        longitude: '-96,75920105',
        altitude: '1219',
        timezone: '-6',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Capital City Airport',
        city: 'Lansing',
        country: 'United States',
        iataCode: 'LAN',
        icaoCode: 'KLAN',
        latitude: '42,77870178',
        longitude: '-84,58740234',
        altitude: '861',
        timezone: '-5',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Waimea Kohala Airport',
        city: 'Kamuela',
        country: 'United States',
        iataCode: 'MUE',
        icaoCode: 'PHMU',
        latitude: '20,00130081',
        longitude: '-155,6679993',
        altitude: '2671',
        timezone: '-10',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Massena International Richards Field',
        city: 'Massena',
        country: 'United States',
        iataCode: 'MSS',
        icaoCode: 'KMSS',
        latitude: '44,93579865',
        longitude: '-74,84559631',
        altitude: '215',
        timezone: '-5',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Hickory Regional Airport',
        city: 'Hickory',
        country: 'United States',
        iataCode: 'HKY',
        icaoCode: 'KHKY',
        latitude: '35,74110031',
        longitude: '-81,38950348',
        altitude: '1190',
        timezone: '-5',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Albert Whitted Airport',
        city: 'St. Petersburg',
        country: 'United States',
        iataCode: 'SPG',
        icaoCode: 'KSPG',
        latitude: '27,76510048',
        longitude: '-82,6269989',
        altitude: '7',
        timezone: '-5',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Page Field',
        city: 'Fort Myers',
        country: 'United States',
        iataCode: 'FMY',
        icaoCode: 'KFMY',
        latitude: '26,58659935',
        longitude: '-81,86329651',
        altitude: '17',
        timezone: '-5',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'George Bush Intercontinental Houston Airport',
        city: 'Houston',
        country: 'United States',
        iataCode: 'IAH',
        icaoCode: 'KIAH',
        latitude: '29,9843998',
        longitude: '-95,34140015',
        altitude: '97',
        timezone: '-6',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Millinocket Municipal Airport',
        city: 'Millinocket',
        country: 'United States',
        iataCode: 'MLT',
        icaoCode: 'KMLT',
        latitude: '45,64780045',
        longitude: '-68,68560028',
        altitude: '408',
        timezone: '-5',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Andrews Air Force Base',
        city: 'Camp Springs',
        country: 'United States',
        iataCode: 'ADW',
        icaoCode: 'KADW',
        latitude: '38,81079865',
        longitude: '-76,86699677',
        altitude: '280',
        timezone: '-5',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Smith Reynolds Airport',
        city: 'Winston-salem',
        country: 'United States',
        iataCode: 'INT',
        icaoCode: 'KINT',
        latitude: '36,13370132',
        longitude: '-80,22200012',
        altitude: '969',
        timezone: '-5',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Southern California Logistics Airport',
        city: 'Victorville',
        country: 'United States',
        iataCode: 'VCV',
        icaoCode: 'KVCV',
        latitude: '34,59749985',
        longitude: '-117,3830032',
        altitude: '2885',
        timezone: '-8',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Bob Sikes Airport',
        city: 'Crestview',
        country: 'United States',
        iataCode: 'CEW',
        icaoCode: 'KCEW',
        latitude: '30,77879906',
        longitude: '-86,52210236',
        altitude: '213',
        timezone: '-6',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Wheeler Sack Army Air Field',
        city: 'Fort Drum',
        country: 'United States',
        iataCode: 'GTB',
        icaoCode: 'KGTB',
        latitude: '44,05559921',
        longitude: '-75,71949768',
        altitude: '688',
        timezone: '-5',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'St Clair County International Airport',
        city: 'Port Huron',
        country: 'United States',
        iataCode: 'PHN',
        icaoCode: 'KPHN',
        latitude: '42,9109993',
        longitude: '-82,52890015',
        altitude: '650',
        timezone: '-5',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Meadows Field',
        city: 'Bakersfield',
        country: 'United States',
        iataCode: 'BFL',
        icaoCode: 'KBFL',
        latitude: '35,43360138',
        longitude: '-119,0569992',
        altitude: '510',
        timezone: '-8',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'El Paso International Airport',
        city: 'El Paso',
        country: 'United States',
        iataCode: 'ELP',
        icaoCode: 'KELP',
        latitude: '31,80719948',
        longitude: '-106,3779984',
        altitude: '3959',
        timezone: '-7',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Valley International Airport',
        city: 'Harlingen',
        country: 'United States',
        iataCode: 'HRL',
        icaoCode: 'KHRL',
        latitude: '26,22850037',
        longitude: '-97,65440369',
        altitude: '36',
        timezone: '-6',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Columbia Metropolitan Airport',
        city: 'Columbia',
        country: 'United States',
        iataCode: 'CAE',
        icaoCode: 'KCAE',
        latitude: '33,93880081',
        longitude: '-81,11949921',
        altitude: '236',
        timezone: '-5',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Davis Monthan Air Force Base',
        city: 'Tucson',
        country: 'United States',
        iataCode: 'DMA',
        icaoCode: 'KDMA',
        latitude: '32,16650009',
        longitude: '-110,8830032',
        altitude: '2704',
        timezone: '-7',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Pensacola Naval Air Station/Forrest Sherman Field',
        city: 'Pensacola',
        country: 'United States',
        iataCode: 'NPA',
        icaoCode: 'KNPA',
        latitude: '30,35269928',
        longitude: '-87,31860352',
        altitude: '28',
        timezone: '-6',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Pensacola Regional Airport',
        city: 'Pensacola',
        country: 'United States',
        iataCode: 'PNS',
        icaoCode: 'KPNS',
        latitude: '30,47340012',
        longitude: '-87,18659973',
        altitude: '121',
        timezone: '-6',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Grand Forks Air Force Base',
        city: 'Red River',
        country: 'United States',
        iataCode: 'RDR',
        icaoCode: 'KRDR',
        latitude: '47,96110153',
        longitude: '-97,40119934',
        altitude: '913',
        timezone: '-6',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'William P Hobby Airport',
        city: 'Houston',
        country: 'United States',
        iataCode: 'HOU',
        icaoCode: 'KHOU',
        latitude: '29,64539909',
        longitude: '-95,27890015',
        altitude: '46',
        timezone: '-6',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Buckley Air Force Base',
        city: 'Buckley',
        country: 'United States',
        iataCode: 'BKF',
        icaoCode: 'KBKF',
        latitude: '39,7016983',
        longitude: '-104,7519989',
        altitude: '5662',
        timezone: '-7',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Northway Airport',
        city: 'Northway',
        country: 'United States',
        iataCode: 'ORT',
        icaoCode: 'PAOR',
        latitude: '62,9612999',
        longitude: '-141,9290009',
        altitude: '1715',
        timezone: '-9',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Palmer Municipal Airport',
        city: 'Palmer',
        country: 'United States',
        iataCode: 'PAQ',
        icaoCode: 'PAAQ',
        latitude: '61,59489822',
        longitude: '-149,0890045',
        altitude: '242',
        timezone: '-9',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Pittsburgh International Airport',
        city: 'Pittsburgh',
        country: 'United States',
        iataCode: 'PIT',
        icaoCode: 'KPIT',
        latitude: '40,49150085',
        longitude: '-80,23290253',
        altitude: '1203',
        timezone: '-5',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Wiley Post Will Rogers Memorial Airport',
        city: 'Barrow',
        country: 'United States',
        iataCode: 'BRW',
        icaoCode: 'PABR',
        latitude: '71,285402',
        longitude: '-156,766008',
        altitude: '44',
        timezone: '-9',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Ellington Airport',
        city: 'Houston',
        country: 'United States',
        iataCode: 'EFD',
        icaoCode: 'KEFD',
        latitude: '29,6072998',
        longitude: '-95,15879822',
        altitude: '32',
        timezone: '-6',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Whidbey Island Naval Air Station /Ault Field/ Airport',
        city: 'Whidbey Island',
        country: 'United States',
        iataCode: 'NUW',
        icaoCode: 'KNUW',
        latitude: '48,35179901',
        longitude: '-122,6559982',
        altitude: '47',
        timezone: '-8',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Alice International Airport',
        city: 'Alice',
        country: 'United States',
        iataCode: 'ALI',
        icaoCode: 'KALI',
        latitude: '27,74090004',
        longitude: '-98,02690125',
        altitude: '178',
        timezone: '-6',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Moody Air Force Base',
        city: 'Valdosta',
        country: 'United States',
        iataCode: 'VAD',
        icaoCode: 'KVAD',
        latitude: '30,96780014',
        longitude: '-83,19300079',
        altitude: '233',
        timezone: '-5',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Miami International Airport',
        city: 'Miami',
        country: 'United States',
        iataCode: 'MIA',
        icaoCode: 'KMIA',
        latitude: '25,79319954',
        longitude: '-80,29060364',
        altitude: '8',
        timezone: '-5',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Seattle Tacoma International Airport',
        city: 'Seattle',
        country: 'United States',
        iataCode: 'SEA',
        icaoCode: 'KSEA',
        latitude: '47,44900131',
        longitude: '-122,3089981',
        altitude: '433',
        timezone: '-8',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Lovell Field',
        city: 'Chattanooga',
        country: 'United States',
        iataCode: 'CHA',
        icaoCode: 'KCHA',
        latitude: '35,03530121',
        longitude: '-85,20379639',
        altitude: '683',
        timezone: '-5',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Igor I Sikorsky Memorial Airport',
        city: 'Stratford',
        country: 'United States',
        iataCode: 'BDR',
        icaoCode: 'KBDR',
        latitude: '41,16350174',
        longitude: '-73,12619781',
        altitude: '9',
        timezone: '-5',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Jackson-Medgar Wiley Evers International Airport',
        city: 'Jackson',
        country: 'United States',
        iataCode: 'JAN',
        icaoCode: 'KJAN',
        latitude: '32,31119919',
        longitude: '-90,07589722',
        altitude: '346',
        timezone: '-6',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Scholes International At Galveston Airport',
        city: 'Galveston',
        country: 'United States',
        iataCode: 'GLS',
        icaoCode: 'KGLS',
        latitude: '29,26530075',
        longitude: '-94,86039734',
        altitude: '6',
        timezone: '-6',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Long Beach /Daugherty Field/ Airport',
        city: 'Long Beach',
        country: 'United States',
        iataCode: 'LGB',
        icaoCode: 'KLGB',
        latitude: '33,81769943',
        longitude: '-118,1520004',
        altitude: '60',
        timezone: '-8',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Dillingham Airfield',
        city: 'Dillingham',
        country: 'United States',
        iataCode: 'HDH',
        icaoCode: 'PHDH',
        latitude: '21,5795002',
        longitude: '-158,1970062',
        altitude: '14',
        timezone: '-10',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Williamsport Regional Airport',
        city: 'Williamsport',
        country: 'United States',
        iataCode: 'IPT',
        icaoCode: 'KIPT',
        latitude: '41,2417984',
        longitude: '-76,9210968',
        altitude: '529',
        timezone: '-5',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Indianapolis International Airport',
        city: 'Indianapolis',
        country: 'United States',
        iataCode: 'IND',
        icaoCode: 'KIND',
        latitude: '39,7173',
        longitude: '-86,294403',
        altitude: '797',
        timezone: '-5',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Whiteman Air Force Base',
        city: 'Knobnoster',
        country: 'United States',
        iataCode: 'SZL',
        icaoCode: 'KSZL',
        latitude: '38,7303009',
        longitude: '-93,54789734',
        altitude: '870',
        timezone: '-6',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Akron Fulton International Airport',
        city: 'Akron',
        country: 'United States',
        iataCode: 'AKC',
        icaoCode: 'KAKR',
        latitude: '41,03749847',
        longitude: '-81,46690369',
        altitude: '1067',
        timezone: '-5',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Greenwood–Leflore Airport',
        city: 'Greenwood',
        country: 'United States',
        iataCode: 'GWO',
        icaoCode: 'KGWO',
        latitude: '33,49430084',
        longitude: '-90,08470154',
        altitude: '162',
        timezone: '-6',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Westchester County Airport',
        city: 'White Plains',
        country: 'United States',
        iataCode: 'HPN',
        icaoCode: 'KHPN',
        latitude: '41,06700134',
        longitude: '-73,70760345',
        altitude: '439',
        timezone: '-5',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Francis S Gabreski Airport',
        city: 'West Hampton Beach',
        country: 'United States',
        iataCode: 'FOK',
        icaoCode: 'KFOK',
        latitude: '40,84370041',
        longitude: '-72,63179779',
        altitude: '67',
        timezone: '-5',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Jonesboro Municipal Airport',
        city: 'Jonesboro',
        country: 'United States',
        iataCode: 'JBR',
        icaoCode: 'KJBR',
        latitude: '35,83169937',
        longitude: '-90,64640045',
        altitude: '262',
        timezone: '-6',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Tonopah Test Range Airport',
        city: 'Tonopah',
        country: 'United States',
        iataCode: 'N',
        icaoCode: 'KTNX',
        latitude: '37,79880142',
        longitude: '-116,7809982',
        altitude: '5549',
        timezone: '-8',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Palm Beach County Park Airport',
        city: 'West Palm Beach',
        country: 'United States',
        iataCode: 'LNA',
        icaoCode: 'KLNA',
        latitude: '26,59300041',
        longitude: '-80,08509827',
        altitude: '14',
        timezone: '-5',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'North Island Naval Air Station-Halsey Field',
        city: 'San Diego',
        country: 'United States',
        iataCode: 'NZY',
        icaoCode: 'KNZY',
        latitude: '32,69919968',
        longitude: '-117,2149963',
        altitude: '26',
        timezone: '-8',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Biggs Army Air Field (Fort Bliss)',
        city: 'El Paso',
        country: 'United States',
        iataCode: 'BIF',
        icaoCode: 'KBIF',
        latitude: '31,84950066',
        longitude: '-106,3799973',
        altitude: '3946',
        timezone: '-7',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Yuma MCAS/Yuma International Airport',
        city: 'Yuma',
        country: 'United States',
        iataCode: 'YUM',
        icaoCode: 'KNYL',
        latitude: '32,65660095',
        longitude: '-114,6060028',
        altitude: '213',
        timezone: '-7',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Cavern City Air Terminal',
        city: 'Carlsbad',
        country: 'United States',
        iataCode: 'CNM',
        icaoCode: 'KCNM',
        latitude: '32,33750153',
        longitude: '-104,2630005',
        altitude: '3295',
        timezone: '-7',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Duluth International Airport',
        city: 'Duluth',
        country: 'United States',
        iataCode: 'DLH',
        icaoCode: 'KDLH',
        latitude: '46,84209824',
        longitude: '-92,19360352',
        altitude: '1428',
        timezone: '-6',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Bethel Airport',
        city: 'Bethel',
        country: 'United States',
        iataCode: 'BET',
        icaoCode: 'PABE',
        latitude: '60,77980042',
        longitude: '-161,8379974',
        altitude: '126',
        timezone: '-9',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Bowman Field',
        city: 'Louisville',
        country: 'United States',
        iataCode: 'LOU',
        icaoCode: 'KLOU',
        latitude: '38,22800064',
        longitude: '-85,66369629',
        altitude: '546',
        timezone: '-5',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Sierra Vista Municipal Libby Army Air Field',
        city: 'Fort Huachuca',
        country: 'United States',
        iataCode: 'FHU',
        icaoCode: 'KFHU',
        latitude: '31,58849907',
        longitude: '-110,3440018',
        altitude: '4719',
        timezone: '-7',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Lihue Airport',
        city: 'Lihue',
        country: 'United States',
        iataCode: 'LIH',
        icaoCode: 'PHLI',
        latitude: '21,97599983',
        longitude: '-159,3390045',
        altitude: '153',
        timezone: '-10',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Terre Haute International Hulman Field',
        city: 'Terre Haute',
        country: 'United States',
        iataCode: 'HUF',
        icaoCode: 'KHUF',
        latitude: '39,45149994',
        longitude: '-87,30760193',
        altitude: '589',
        timezone: '-5',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Havre City County Airport',
        city: 'Havre',
        country: 'United States',
        iataCode: 'HVR',
        icaoCode: 'KHVR',
        latitude: '48,54299927',
        longitude: '-109,762001',
        altitude: '2591',
        timezone: '-7',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Grant County International Airport',
        city: 'Grant County Airport',
        country: 'United States',
        iataCode: 'MWH',
        icaoCode: 'KMWH',
        latitude: '47,20769882',
        longitude: '-119,3199997',
        altitude: '1189',
        timezone: '-8',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Edward F Knapp State Airport',
        city: 'Montpelier',
        country: 'United States',
        iataCode: 'MPV',
        icaoCode: 'KMPV',
        latitude: '44,20349884',
        longitude: '-72,56230164',
        altitude: '1166',
        timezone: '-5',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'San Nicolas Island Nolf Airport',
        city: 'San Nicolas Island',
        country: 'United States',
        iataCode: 'N',
        icaoCode: 'KNSI',
        latitude: '33,2397995',
        longitude: '-119,4580002',
        altitude: '506',
        timezone: '-8',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Richmond International Airport',
        city: 'Richmond',
        country: 'United States',
        iataCode: 'RIC',
        icaoCode: 'KRIC',
        latitude: '37,50519943',
        longitude: '-77,31970215',
        altitude: '167',
        timezone: '-5',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Shreveport Regional Airport',
        city: 'Shreveport',
        country: 'United States',
        iataCode: 'SHV',
        icaoCode: 'KSHV',
        latitude: '32,44660187',
        longitude: '-93,82559967',
        altitude: '258',
        timezone: '-6',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Merle K (Mudhole) Smith Airport',
        city: 'Cordova',
        country: 'United States',
        iataCode: 'CDV',
        icaoCode: 'PACV',
        latitude: '60,4917984',
        longitude: '-145,4779968',
        altitude: '54',
        timezone: '-9',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Norfolk International Airport',
        city: 'Norfolk',
        country: 'United States',
        iataCode: 'ORF',
        icaoCode: 'KORF',
        latitude: '36,89459991',
        longitude: '-76,20120239',
        altitude: '26',
        timezone: '-5',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Southeast Texas Regional Airport',
        city: 'Beaumont',
        country: 'United States',
        iataCode: 'BPT',
        icaoCode: 'KBPT',
        latitude: '29,95079994',
        longitude: '-94,02069855',
        altitude: '15',
        timezone: '-6',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Savannah Hilton Head International Airport',
        city: 'Savannah',
        country: 'United States',
        iataCode: 'SAV',
        icaoCode: 'KSAV',
        latitude: '32,12760162',
        longitude: '-81,20210266',
        altitude: '50',
        timezone: '-5',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Hill Air Force Base',
        city: 'Ogden',
        country: 'United States',
        iataCode: 'HIF',
        icaoCode: 'KHIF',
        latitude: '41,12403',
        longitude: '-111,973086',
        altitude: '4789',
        timezone: '-7',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Nome Airport',
        city: 'Nome',
        country: 'United States',
        iataCode: 'OME',
        icaoCode: 'PAOM',
        latitude: '64,5121994',
        longitude: '-165,4450073',
        altitude: '37',
        timezone: '-9',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Scappoose Industrial Airpark',
        city: 'San Luis',
        country: 'United States',
        iataCode: 'N',
        icaoCode: 'KSPB',
        latitude: '45,77099991',
        longitude: '-122,8619995',
        altitude: '58',
        timezone: '-8',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'St Petersburg Clearwater International Airport',
        city: 'St. Petersburg',
        country: 'United States',
        iataCode: 'PIE',
        icaoCode: 'KPIE',
        latitude: '27,91020012',
        longitude: '-82,68740082',
        altitude: '11',
        timezone: '-5',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Menominee Marinette Twin County Airport',
        city: 'Macon',
        country: 'United States',
        iataCode: 'MNM',
        icaoCode: 'KMNM',
        latitude: '45,12670135',
        longitude: '-87,63839722',
        altitude: '625',
        timezone: '-6',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Lone Star Executive Airport',
        city: 'Conroe',
        country: 'United States',
        iataCode: 'CXO',
        icaoCode: 'KCXO',
        latitude: '30,35180092',
        longitude: '-95,41449738',
        altitude: '245',
        timezone: '-6',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Deadhorse Airport',
        city: 'Deadhorse',
        country: 'United States',
        iataCode: 'SCC',
        icaoCode: 'PASC',
        latitude: '70,19470215',
        longitude: '-148,4649963',
        altitude: '65',
        timezone: '-9',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'San Antonio International Airport',
        city: 'San Antonio',
        country: 'United States',
        iataCode: 'SAT',
        icaoCode: 'KSAT',
        latitude: '29,53370094',
        longitude: '-98,46980286',
        altitude: '809',
        timezone: '-6',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Greater Rochester International Airport',
        city: 'Rochester',
        country: 'United States',
        iataCode: 'ROC',
        icaoCode: 'KROC',
        latitude: '43,1189003',
        longitude: '-77,67240143',
        altitude: '559',
        timezone: '-5',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Patrick Air Force Base',
        city: 'Coco Beach',
        country: 'United States',
        iataCode: 'COF',
        icaoCode: 'KCOF',
        latitude: '28,23489952',
        longitude: '-80,61009979',
        altitude: '8',
        timezone: '-5',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Teterboro Airport',
        city: 'Teterboro',
        country: 'United States',
        iataCode: 'TEB',
        icaoCode: 'KTEB',
        latitude: '40,85010147',
        longitude: '-74,06079865',
        altitude: '9',
        timezone: '-5',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Ellsworth Air Force Base',
        city: 'Rapid City',
        country: 'United States',
        iataCode: 'RCA',
        icaoCode: 'KRCA',
        latitude: '44,14500046',
        longitude: '-103,1039963',
        altitude: '3276',
        timezone: '-7',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Raleigh Durham International Airport',
        city: 'Raleigh-durham',
        country: 'United States',
        iataCode: 'RDU',
        icaoCode: 'KRDU',
        latitude: '35,87760162',
        longitude: '-78,78749847',
        altitude: '435',
        timezone: '-5',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'James M Cox Dayton International Airport',
        city: 'Dayton',
        country: 'United States',
        iataCode: 'DAY',
        icaoCode: 'KDAY',
        latitude: '39,90240097',
        longitude: '-84,2193985',
        altitude: '1009',
        timezone: '-5',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Kenai Municipal Airport',
        city: 'Kenai',
        country: 'United States',
        iataCode: 'ENA',
        icaoCode: 'PAEN',
        latitude: '60,57310104',
        longitude: '-151,2449951',
        altitude: '99',
        timezone: '-9',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Mc Alester Regional Airport',
        city: 'Mcalester',
        country: 'United States',
        iataCode: 'MLC',
        icaoCode: 'KMLC',
        latitude: '34,88240051',
        longitude: '-95,78350067',
        altitude: '770',
        timezone: '-6',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Niagara Falls International Airport',
        city: 'Niagara Falls',
        country: 'United States',
        iataCode: 'IAG',
        icaoCode: 'KIAG',
        latitude: '43,1072998',
        longitude: '-78,94619751',
        altitude: '589',
        timezone: '-5',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Coulter Field',
        city: 'Bryan',
        country: 'United States',
        iataCode: 'CFD',
        icaoCode: 'KCFD',
        latitude: '30,71570015',
        longitude: '-96,33139801',
        altitude: '367',
        timezone: '-6',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Wright Aaf (Fort Stewart)/Midcoast Regional Airport',
        city: 'Wright',
        country: 'United States',
        iataCode: 'N',
        icaoCode: 'KLHW',
        latitude: '31,88909912',
        longitude: '-81,56230164',
        altitude: '45',
        timezone: '-5',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Newport News Williamsburg International Airport',
        city: 'Newport News',
        country: 'United States',
        iataCode: 'PHF',
        icaoCode: 'KPHF',
        latitude: '37,13190079',
        longitude: '-76,49299622',
        altitude: '42',
        timezone: '-5',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Esler Regional Airport',
        city: 'Alexandria',
        country: 'United States',
        iataCode: 'ESF',
        icaoCode: 'KESF',
        latitude: '31,39489937',
        longitude: '-92,29579926',
        altitude: '112',
        timezone: '-6',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Altus Air Force Base',
        city: 'Altus',
        country: 'United States',
        iataCode: 'LTS',
        icaoCode: 'KLTS',
        latitude: '34,667099',
        longitude: '-99,26670074',
        altitude: '1382',
        timezone: '-6',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Tucson International Airport',
        city: 'Tucson',
        country: 'United States',
        iataCode: 'TUS',
        icaoCode: 'KTUS',
        latitude: '32,11610031',
        longitude: '-110,9410019',
        altitude: '2643',
        timezone: '-7',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Minot Air Force Base',
        city: 'Minot',
        country: 'United States',
        iataCode: 'MIB',
        icaoCode: 'KMIB',
        latitude: '48,41559982',
        longitude: '-101,3580017',
        altitude: '1667',
        timezone: '-6',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Beale Air Force Base',
        city: 'Marysville',
        country: 'United States',
        iataCode: 'BAB',
        icaoCode: 'KBAB',
        latitude: '39,13610077',
        longitude: '-121,4369965',
        altitude: '113',
        timezone: '-8',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Greater Kankakee Airport',
        city: 'Kankakee',
        country: 'United States',
        iataCode: 'IKK',
        icaoCode: 'KIKK',
        latitude: '41,07139969',
        longitude: '-87,84629822',
        altitude: '630',
        timezone: '-6',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Seymour Johnson Air Force Base',
        city: 'Goldsboro',
        country: 'United States',
        iataCode: 'GSB',
        icaoCode: 'KGSB',
        latitude: '35,33940125',
        longitude: '-77,96060181',
        altitude: '109',
        timezone: '-5',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Theodore Francis Green State Airport',
        city: 'Providence',
        country: 'United States',
        iataCode: 'PVD',
        icaoCode: 'KPVD',
        latitude: '41,73260117',
        longitude: '-71,42040253',
        altitude: '55',
        timezone: '-5',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Salisbury Ocean City Wicomico Regional Airport',
        city: 'Salisbury',
        country: 'United States',
        iataCode: 'SBY',
        icaoCode: 'KSBY',
        latitude: '38,34049988',
        longitude: '-75,51029968',
        altitude: '52',
        timezone: '-5',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Rancho Murieta Airport',
        city: 'Rancho Murieta',
        country: 'United States',
        iataCode: 'RIU',
        icaoCode: 'KRIU',
        latitude: '38,48680115',
        longitude: '-121,1029968',
        altitude: '141',
        timezone: '-8',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Bob Hope Airport',
        city: 'Burbank',
        country: 'United States',
        iataCode: 'BUR',
        icaoCode: 'KBUR',
        latitude: '34,20069885',
        longitude: '-118,3590012',
        altitude: '778',
        timezone: '-8',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Detroit Metropolitan Wayne County Airport',
        city: 'Detroit',
        country: 'United States',
        iataCode: 'DTW',
        icaoCode: 'KDTW',
        latitude: '42,21239853',
        longitude: '-83,35340118',
        altitude: '645',
        timezone: '-5',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Tampa International Airport',
        city: 'Tampa',
        country: 'United States',
        iataCode: 'TPA',
        icaoCode: 'KTPA',
        latitude: '27,97550011',
        longitude: '-82,53320313',
        altitude: '26',
        timezone: '-5',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Pembina Municipal Airport',
        city: 'Pembina',
        country: 'United States',
        iataCode: 'PMB',
        icaoCode: 'KPMB',
        latitude: '48,94250107',
        longitude: '-97,24079895',
        altitude: '795',
        timezone: '-6',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Polk Army Air Field',
        city: 'Fort Polk',
        country: 'United States',
        iataCode: 'POE',
        icaoCode: 'KPOE',
        latitude: '31,0447998',
        longitude: '-93,1917038',
        altitude: '330',
        timezone: '-6',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Eielson Air Force Base',
        city: 'Fairbanks',
        country: 'United States',
        iataCode: 'EIL',
        icaoCode: 'PAEI',
        latitude: '64,66570282',
        longitude: '-147,102005',
        altitude: '547',
        timezone: '-9',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Range Regional Airport',
        city: 'Hibbing',
        country: 'United States',
        iataCode: 'HIB',
        icaoCode: 'KHIB',
        latitude: '47,38660049',
        longitude: '-92,83899689',
        altitude: '1354',
        timezone: '-6',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Angelina County Airport',
        city: 'Lufkin',
        country: 'United States',
        iataCode: 'LFK',
        icaoCode: 'KLFK',
        latitude: '31,23399925',
        longitude: '-94,75',
        altitude: '296',
        timezone: '-6',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Midland International Airport',
        city: 'Midland',
        country: 'United States',
        iataCode: 'MAF',
        icaoCode: 'KMAF',
        latitude: '31,94249916',
        longitude: '-102,2020035',
        altitude: '2871',
        timezone: '-6',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Austin Straubel International Airport',
        city: 'Green Bay',
        country: 'United States',
        iataCode: 'GRB',
        icaoCode: 'KGRB',
        latitude: '44,48509979',
        longitude: '-88,12960052',
        altitude: '695',
        timezone: '-6',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Ardmore Municipal Airport',
        city: 'Ardmore',
        country: 'United States',
        iataCode: 'ADM',
        icaoCode: 'KADM',
        latitude: '34,30301',
        longitude: '-97,0196342',
        altitude: '777',
        timezone: '-6',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Mc Guire Air Force Base',
        city: 'Wrightstown',
        country: 'United States',
        iataCode: 'WRI',
        icaoCode: 'KWRI',
        latitude: '40,0155983',
        longitude: '-74,59169769',
        altitude: '131',
        timezone: '-5',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Cherry Point MCAS /Cunningham Field/',
        city: 'Cherry Point',
        country: 'United States',
        iataCode: 'N',
        icaoCode: 'KNKT',
        latitude: '34,90090179',
        longitude: '-76,88069916',
        altitude: '29',
        timezone: '-5',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Emanuel County Airport',
        city: 'Santa Barbara',
        country: 'United States',
        iataCode: 'SBO',
        icaoCode: 'KSBO',
        latitude: '32,60910034',
        longitude: '-82,36990356',
        altitude: '327',
        timezone: '-5',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Augusta Regional At Bush Field',
        city: 'Bush Field',
        country: 'United States',
        iataCode: 'AGS',
        icaoCode: 'KAGS',
        latitude: '33,36989975',
        longitude: '-81,96450043',
        altitude: '144',
        timezone: '-5',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Sloulin Field International Airport',
        city: 'Williston',
        country: 'United States',
        iataCode: 'ISN',
        icaoCode: 'KISN',
        latitude: '48,17789841',
        longitude: '-103,6419983',
        altitude: '1982',
        timezone: '-6',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Bill & Hillary Clinton National Airport/Adams Field',
        city: 'Little Rock',
        country: 'United States',
        iataCode: 'LIT',
        icaoCode: 'KLIT',
        latitude: '34,72940063',
        longitude: '-92,22429657',
        altitude: '262',
        timezone: '-6',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Stewart International Airport',
        city: 'Newburgh',
        country: 'United States',
        iataCode: 'SWF',
        icaoCode: 'KSWF',
        latitude: '41,5041008',
        longitude: '-74,10479736',
        altitude: '491',
        timezone: '-5',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Baudette International Airport',
        city: 'Baudette',
        country: 'United States',
        iataCode: 'BDE',
        icaoCode: 'KBDE',
        latitude: '48,72840118',
        longitude: '-94,61219788',
        altitude: '1086',
        timezone: '-6',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Sacramento Executive Airport',
        city: 'Sacramento',
        country: 'United States',
        iataCode: 'SAC',
        icaoCode: 'KSAC',
        latitude: '38,51250076',
        longitude: '-121,4929962',
        altitude: '24',
        timezone: '-8',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Homer Airport',
        city: 'Homer',
        country: 'United States',
        iataCode: 'HOM',
        icaoCode: 'PAHO',
        latitude: '59,64559937',
        longitude: '-151,477005',
        altitude: '84',
        timezone: '-9',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Waynesville-St. Robert Regional Forney field',
        city: 'Fort Leonardwood',
        country: 'United States',
        iataCode: 'TBN',
        icaoCode: 'KTBN',
        latitude: '37,74160004',
        longitude: '-92,14070129',
        altitude: '1159',
        timezone: '-6',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Dobbins Air Reserve Base',
        city: 'Marietta',
        country: 'United States',
        iataCode: 'MGE',
        icaoCode: 'KMGE',
        latitude: '33,91540146',
        longitude: '-84,51629639',
        altitude: '1068',
        timezone: '-5',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Fairchild Air Force Base',
        city: 'Spokane',
        country: 'United States',
        iataCode: 'SKA',
        icaoCode: 'KSKA',
        latitude: '47,61510086',
        longitude: '-117,6559982',
        altitude: '2461',
        timezone: '-8',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Roscommon County - Blodgett Memorial Airport',
        city: 'Houghton Lake',
        country: 'United States',
        iataCode: 'HTL',
        icaoCode: 'KHTL',
        latitude: '44,359798',
        longitude: '-84,671095',
        altitude: '1150',
        timezone: '-5',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Tyndall Air Force Base',
        city: 'Panama City',
        country: 'United States',
        iataCode: 'PAM',
        icaoCode: 'KPAM',
        latitude: '30,06959915',
        longitude: '-85,57540131',
        altitude: '17',
        timezone: '-6',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Dallas Fort Worth International Airport',
        city: 'Dallas-Fort Worth',
        country: 'United States',
        iataCode: 'DFW',
        icaoCode: 'KDFW',
        latitude: '32,89680099',
        longitude: '-97,03800201',
        altitude: '607',
        timezone: '-6',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Melbourne International Airport',
        city: 'Melbourne',
        country: 'United States',
        iataCode: 'MLB',
        icaoCode: 'KMLB',
        latitude: '28,10280037',
        longitude: '-80,64530182',
        altitude: '33',
        timezone: '-5',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'McChord Air Force Base',
        city: 'Tacoma',
        country: 'United States',
        iataCode: 'TCM',
        icaoCode: 'KTCM',
        latitude: '47,13769913',
        longitude: '-122,4759979',
        altitude: '322',
        timezone: '-8',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Austin Bergstrom International Airport',
        city: 'Austin',
        country: 'United States',
        iataCode: 'AUS',
        icaoCode: 'KAUS',
        latitude: '30,19449997',
        longitude: '-97,66989899',
        altitude: '542',
        timezone: '-6',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Rickenbacker International Airport',
        city: 'Columbus',
        country: 'United States',
        iataCode: 'LCK',
        icaoCode: 'KLCK',
        latitude: '39,813801',
        longitude: '-82,927803',
        altitude: '744',
        timezone: '-5',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Sawyer International Airport',
        city: 'Gwinn',
        country: 'United States',
        iataCode: 'MQT',
        icaoCode: 'KSAW',
        latitude: '46,35359955',
        longitude: '-87,395401',
        altitude: '1221',
        timezone: '-5',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'McGhee Tyson Airport',
        city: 'Knoxville',
        country: 'United States',
        iataCode: 'TYS',
        icaoCode: 'KTYS',
        latitude: '35,81100082',
        longitude: '-83,9940033',
        altitude: '981',
        timezone: '-5',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Hood Army Air Field',
        city: 'Fort Hood',
        country: 'United States',
        iataCode: 'HLR',
        icaoCode: 'KHLR',
        latitude: '31,13870049',
        longitude: '-97,71450043',
        altitude: '924',
        timezone: '-6',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Lambert St Louis International Airport',
        city: 'St. Louis',
        country: 'United States',
        iataCode: 'STL',
        icaoCode: 'KSTL',
        latitude: '38,74869919',
        longitude: '-90,37000275',
        altitude: '618',
        timezone: '-6',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Millville Municipal Airport',
        city: 'Millville',
        country: 'United States',
        iataCode: 'MIV',
        icaoCode: 'KMIV',
        latitude: '39,36780167',
        longitude: '-75,07219696',
        altitude: '85',
        timezone: '-5',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Sheppard Air Force Base-Wichita Falls Municipal Airport',
        city: 'Wichita Falls',
        country: 'United States',
        iataCode: 'SPS',
        icaoCode: 'KSPS',
        latitude: '33,98880005',
        longitude: '-98,49189758',
        altitude: '1019',
        timezone: '-6',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Cincinnati Municipal Airport Lunken Field',
        city: 'Cincinnati',
        country: 'United States',
        iataCode: 'LUK',
        icaoCode: 'KLUK',
        latitude: '39,10329819',
        longitude: '-84,41860199',
        altitude: '483',
        timezone: '-5',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Hartsfield Jackson Atlanta International Airport',
        city: 'Atlanta',
        country: 'United States',
        iataCode: 'ATL',
        icaoCode: 'KATL',
        latitude: '33,63669968',
        longitude: '-84,42810059',
        altitude: '1026',
        timezone: '-5',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Castle Airport',
        city: 'Merced',
        country: 'United States',
        iataCode: 'MER',
        icaoCode: 'KMER',
        latitude: '37,38050079',
        longitude: '-120,5680008',
        altitude: '191',
        timezone: '-8',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Mc Clellan Airfield',
        city: 'Sacramento',
        country: 'United States',
        iataCode: 'MCC',
        icaoCode: 'KMCC',
        latitude: '38,66759872',
        longitude: '-121,401001',
        altitude: '77',
        timezone: '-8',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Gerald R. Ford International Airport',
        city: 'Grand Rapids',
        country: 'United States',
        iataCode: 'GRR',
        icaoCode: 'KGRR',
        latitude: '42,88079834',
        longitude: '-85,52279663',
        altitude: '794',
        timezone: '-5',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Winkler County Airport',
        city: 'Wink',
        country: 'United States',
        iataCode: 'INK',
        icaoCode: 'KINK',
        latitude: '31,77960014',
        longitude: '-103,2009964',
        altitude: '2822',
        timezone: '-6',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Fresno Yosemite International Airport',
        city: 'Fresno',
        country: 'United States',
        iataCode: 'FAT',
        icaoCode: 'KFAT',
        latitude: '36,77619934',
        longitude: '-119,7180023',
        altitude: '336',
        timezone: '-8',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Vero Beach Municipal Airport',
        city: 'Vero Beach',
        country: 'United States',
        iataCode: 'VRB',
        icaoCode: 'KVRB',
        latitude: '27,65559959',
        longitude: '-80,41790009',
        altitude: '24',
        timezone: '-5',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Imperial County Airport',
        city: 'Imperial',
        country: 'United States',
        iataCode: 'IPL',
        icaoCode: 'KIPL',
        latitude: '32,83420181',
        longitude: '-115,5790024',
        altitude: '-54',
        timezone: '-8',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Nashville International Airport',
        city: 'Nashville',
        country: 'United States',
        iataCode: 'BNA',
        icaoCode: 'KBNA',
        latitude: '36,12450027',
        longitude: '-86,67819977',
        altitude: '599',
        timezone: '-6',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Laredo International Airport',
        city: 'Laredo',
        country: 'United States',
        iataCode: 'LRD',
        icaoCode: 'KLRD',
        latitude: '27,54380035',
        longitude: '-99,46160126',
        altitude: '508',
        timezone: '-6',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Elmendorf Air Force Base',
        city: 'Anchorage',
        country: 'United States',
        iataCode: 'EDF',
        icaoCode: 'PAED',
        latitude: '61,25099945',
        longitude: '-149,8070068',
        altitude: '212',
        timezone: '-9',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Ralph Wien Memorial Airport',
        city: 'Kotzebue',
        country: 'United States',
        iataCode: 'OTZ',
        icaoCode: 'PAOT',
        latitude: '66,88469696',
        longitude: '-162,598999',
        altitude: '14',
        timezone: '-9',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Altoona Blair County Airport',
        city: 'Altoona',
        country: 'United States',
        iataCode: 'AOO',
        icaoCode: 'KAOO',
        latitude: '40,29639816',
        longitude: '-78,31999969',
        altitude: '1503',
        timezone: '-5',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Dyess Air Force Base',
        city: 'Abilene',
        country: 'United States',
        iataCode: 'DYS',
        icaoCode: 'KDYS',
        latitude: '32,42079926',
        longitude: '-99,854599',
        altitude: '1789',
        timezone: '-6',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'South Arkansas Regional At Goodwin Field',
        city: 'El Dorado',
        country: 'United States',
        iataCode: 'ELD',
        icaoCode: 'KELD',
        latitude: '33,22100067',
        longitude: '-92,81330109',
        altitude: '277',
        timezone: '-6',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'La Guardia Airport',
        city: 'New York',
        country: 'United States',
        iataCode: 'LGA',
        icaoCode: 'KLGA',
        latitude: '40,77719879',
        longitude: '-73,87259674',
        altitude: '21',
        timezone: '-5',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Tallahassee Regional Airport',
        city: 'Tallahassee',
        country: 'United States',
        iataCode: 'TLH',
        icaoCode: 'KTLH',
        latitude: '30,39649963',
        longitude: '-84,35030365',
        altitude: '81',
        timezone: '-5',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Dupage Airport',
        city: 'West Chicago',
        country: 'United States',
        iataCode: 'DPA',
        icaoCode: 'KDPA',
        latitude: '41,90779877',
        longitude: '-88,24859619',
        altitude: '759',
        timezone: '-6',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Waco Regional Airport',
        city: 'Waco',
        country: 'United States',
        iataCode: 'ACT',
        icaoCode: 'KACT',
        latitude: '31,61129951',
        longitude: '-97,23049927',
        altitude: '516',
        timezone: '-6',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Augusta State Airport',
        city: 'Augusta',
        country: 'United States',
        iataCode: 'AUG',
        icaoCode: 'KAUG',
        latitude: '44,3205986',
        longitude: '-69,79730225',
        altitude: '352',
        timezone: '-5',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Hillsboro Municipal Airport',
        city: 'Hillsboro',
        country: 'United States',
        iataCode: 'INJ',
        icaoCode: 'KINJ',
        latitude: '32,08349991',
        longitude: '-97,09719849',
        altitude: '686',
        timezone: '-6',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Mc Kellar Sipes Regional Airport',
        city: 'Jackson',
        country: 'United States',
        iataCode: 'MKL',
        icaoCode: 'KMKL',
        latitude: '35,59989929',
        longitude: '-88,91560364',
        altitude: '434',
        timezone: '-6',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Molokai Airport',
        city: 'Molokai',
        country: 'United States',
        iataCode: 'MKK',
        icaoCode: 'PHMK',
        latitude: '21,1529007',
        longitude: '-157,095993',
        altitude: '454',
        timezone: '-10',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Godman Army Air Field',
        city: 'Fort Knox',
        country: 'United States',
        iataCode: 'FTK',
        icaoCode: 'KFTK',
        latitude: '37,90710068',
        longitude: '-85,9720993',
        altitude: '756',
        timezone: '-5',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'New River MCAS /H/ /Mccutcheon Fld/ Airport',
        city: 'Jacksonville',
        country: 'United States',
        iataCode: 'N',
        icaoCode: 'KNCA',
        latitude: '34,70840073',
        longitude: '-77,43969727',
        altitude: '26',
        timezone: '-5',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'San Angelo Regional Mathis Field',
        city: 'San Angelo',
        country: 'United States',
        iataCode: 'SJT',
        icaoCode: 'KSJT',
        latitude: '31,35770035',
        longitude: '-100,4960022',
        altitude: '1919',
        timezone: '-6',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Calexico International Airport',
        city: 'Calexico',
        country: 'United States',
        iataCode: 'CXL',
        icaoCode: 'KCXL',
        latitude: '32,66949844',
        longitude: '-115,5130005',
        altitude: '4',
        timezone: '-8',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Chico Municipal Airport',
        city: 'Chico',
        country: 'United States',
        iataCode: 'CIC',
        icaoCode: 'KCIC',
        latitude: '39,79539871',
        longitude: '-121,8580017',
        altitude: '240',
        timezone: '-8',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Burlington International Airport',
        city: 'Burlington',
        country: 'United States',
        iataCode: 'BTV',
        icaoCode: 'KBTV',
        latitude: '44,47190094',
        longitude: '-73,15329742',
        altitude: '335',
        timezone: '-5',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Jacksonville International Airport',
        city: 'Jacksonville',
        country: 'United States',
        iataCode: 'JAX',
        icaoCode: 'KJAX',
        latitude: '30,49410057',
        longitude: '-81,68789673',
        altitude: '30',
        timezone: '-5',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Durango La Plata County Airport',
        city: 'Durango',
        country: 'United States',
        iataCode: 'DRO',
        icaoCode: 'KDRO',
        latitude: '37,1515007',
        longitude: '-107,7539978',
        altitude: '6685',
        timezone: '-7',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Washington Dulles International Airport',
        city: 'Washington',
        country: 'United States',
        iataCode: 'IAD',
        icaoCode: 'KIAD',
        latitude: '38,94449997',
        longitude: '-77,45580292',
        altitude: '312',
        timezone: '-5',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Easterwood Field',
        city: 'College Station',
        country: 'United States',
        iataCode: 'CLL',
        icaoCode: 'KCLL',
        latitude: '30,58860016',
        longitude: '-96,36380005',
        altitude: '320',
        timezone: '-6',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Felts Field',
        city: 'Spokane',
        country: 'United States',
        iataCode: 'SFF',
        icaoCode: 'KSFF',
        latitude: '47,68280029',
        longitude: '-117,322998',
        altitude: '1953',
        timezone: '-8',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'General Mitchell International Airport',
        city: 'Milwaukee',
        country: 'United States',
        iataCode: 'MKE',
        icaoCode: 'KMKE',
        latitude: '42,94720078',
        longitude: '-87,89659882',
        altitude: '723',
        timezone: '-6',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Abilene Regional Airport',
        city: 'Abilene',
        country: 'United States',
        iataCode: 'ABI',
        icaoCode: 'KABI',
        latitude: '32,41130066',
        longitude: '-99,68190002',
        altitude: '1791',
        timezone: '-6',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Columbia Regional Airport',
        city: 'Columbia',
        country: 'United States',
        iataCode: 'COU',
        icaoCode: 'KCOU',
        latitude: '38,81809998',
        longitude: '-92,21959686',
        altitude: '889',
        timezone: '-6',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Portland International Airport',
        city: 'Portland',
        country: 'United States',
        iataCode: 'PDX',
        icaoCode: 'KPDX',
        latitude: '45,58869934',
        longitude: '-122,5979996',
        altitude: '31',
        timezone: '-8',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Dade Collier Training and Transition Airport',
        city: 'Miami',
        country: 'United States',
        iataCode: 'TNT',
        icaoCode: 'KTNT',
        latitude: '25,86179924',
        longitude: '-80,89700317',
        altitude: '13',
        timezone: '-5',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Palm Beach International Airport',
        city: 'West Palm Beach',
        country: 'United States',
        iataCode: 'PBI',
        icaoCode: 'KPBI',
        latitude: '26,68320084',
        longitude: '-80,09559631',
        altitude: '19',
        timezone: '-5',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Fort Worth Meacham International Airport',
        city: 'Fort Worth',
        country: 'United States',
        iataCode: 'FTW',
        icaoCode: 'KFTW',
        latitude: '32,81980133',
        longitude: '-97,36239624',
        altitude: '710',
        timezone: '-6',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Ogdensburg International Airport',
        city: 'Ogdensburg',
        country: 'United States',
        iataCode: 'OGS',
        icaoCode: 'KOGS',
        latitude: '44,68190002',
        longitude: '-75,46549988',
        altitude: '297',
        timezone: '-5',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Boeing Field King County International Airport',
        city: 'Seattle',
        country: 'United States',
        iataCode: 'BFI',
        icaoCode: 'KBFI',
        latitude: '47,52999878',
        longitude: '-122,302002',
        altitude: '21',
        timezone: '-8',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Lackland Air Force Base',
        city: 'San Antonio',
        country: 'United States',
        iataCode: 'SKF',
        icaoCode: 'KSKF',
        latitude: '29,38419914',
        longitude: '-98,58110046',
        altitude: '691',
        timezone: '-6',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Honolulu International Airport',
        city: 'Honolulu',
        country: 'United States',
        iataCode: 'HNL',
        icaoCode: 'PHNL',
        latitude: '21,31870079',
        longitude: '-157,9219971',
        altitude: '13',
        timezone: '-10',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Des Moines International Airport',
        city: 'Des Moines',
        country: 'United States',
        iataCode: 'DSM',
        icaoCode: 'KDSM',
        latitude: '41,5340004',
        longitude: '-93,6631012',
        altitude: '958',
        timezone: '-6',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Coastal Carolina Regional Airport',
        city: 'New Bern',
        country: 'United States',
        iataCode: 'EWN',
        icaoCode: 'KEWN',
        latitude: '35,07300186',
        longitude: '-77,04290009',
        altitude: '18',
        timezone: '-5',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'San Diego International Airport',
        city: 'San Diego',
        country: 'United States',
        iataCode: 'SAN',
        icaoCode: 'KSAN',
        latitude: '32,73360062',
        longitude: '-117,1900024',
        altitude: '17',
        timezone: '-8',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Monroe Regional Airport',
        city: 'Monroe',
        country: 'United States',
        iataCode: 'MLU',
        icaoCode: 'KMLU',
        latitude: '32,51089859',
        longitude: '-92,03769684',
        altitude: '79',
        timezone: '-6',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Shaw Air Force Base',
        city: 'Sumter',
        country: 'United States',
        iataCode: 'SSC',
        icaoCode: 'KSSC',
        latitude: '33,97269821',
        longitude: '-80,47059631',
        altitude: '241',
        timezone: '-5',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Ontario International Airport',
        city: 'Ontario',
        country: 'United States',
        iataCode: 'ONT',
        icaoCode: 'KONT',
        latitude: '34,05599976',
        longitude: '-117,6009979',
        altitude: '944',
        timezone: '-8',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Majors Airport',
        city: 'Greenvile',
        country: 'United States',
        iataCode: 'GVT',
        icaoCode: 'KGVT',
        latitude: '33,06779861',
        longitude: '-96,06529999',
        altitude: '535',
        timezone: '-6',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Roswell International Air Center Airport',
        city: 'Roswell',
        country: 'United States',
        iataCode: 'ROW',
        icaoCode: 'KROW',
        latitude: '33,30160141',
        longitude: '-104,5309982',
        altitude: '3671',
        timezone: '-7',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Coleman A. Young Municipal Airport',
        city: 'Detroit',
        country: 'United States',
        iataCode: 'DET',
        icaoCode: 'KDET',
        latitude: '42,40919876',
        longitude: '-83,00990295',
        altitude: '626',
        timezone: '-5',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Brownsville South Padre Island International Airport',
        city: 'Brownsville',
        country: 'United States',
        iataCode: 'BRO',
        icaoCode: 'KBRO',
        latitude: '25,90679932',
        longitude: '-97,42590332',
        altitude: '22',
        timezone: '-6',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Dothan Regional Airport',
        city: 'Dothan',
        country: 'United States',
        iataCode: 'DHN',
        icaoCode: 'KDHN',
        latitude: '31,32130051',
        longitude: '-85,44960022',
        altitude: '401',
        timezone: '-6',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Cape May County Airport',
        city: 'Wildwood',
        country: 'United States',
        iataCode: 'WWD',
        icaoCode: 'KWWD',
        latitude: '39,00849915',
        longitude: '-74,90830231',
        altitude: '23',
        timezone: '-5',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Selfridge Angb Airport',
        city: 'Mount Clemens',
        country: 'United States',
        iataCode: 'MTC',
        icaoCode: 'KMTC',
        latitude: '42,60829926',
        longitude: '-82,83550262',
        altitude: '580',
        timezone: '-5',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Four Corners Regional Airport',
        city: 'Farmington',
        country: 'United States',
        iataCode: 'FMN',
        icaoCode: 'KFMN',
        latitude: '36,74119949',
        longitude: '-108,2300034',
        altitude: '5506',
        timezone: '-7',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Corpus Christi International Airport',
        city: 'Corpus Christi',
        country: 'United States',
        iataCode: 'CRP',
        icaoCode: 'KCRP',
        latitude: '27,77039909',
        longitude: '-97,50119781',
        altitude: '44',
        timezone: '-6',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Syracuse Hancock International Airport',
        city: 'Syracuse',
        country: 'United States',
        iataCode: 'SYR',
        icaoCode: 'KSYR',
        latitude: '43,11119843',
        longitude: '-76,10630035',
        altitude: '421',
        timezone: '-5',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Naval Air Station Key West/Boca Chica Field',
        city: 'Key West',
        country: 'United States',
        iataCode: 'NQX',
        icaoCode: 'KNQX',
        latitude: '24,57579994',
        longitude: '-81,68890381',
        altitude: '6',
        timezone: '-5',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Chicago Midway International Airport',
        city: 'Chicago',
        country: 'United States',
        iataCode: 'MDW',
        icaoCode: 'KMDW',
        latitude: '41,7859993',
        longitude: '-87,75240326',
        altitude: '620',
        timezone: '-6',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Norman Y. Mineta San Jose International Airport',
        city: 'San Jose',
        country: 'United States',
        iataCode: 'SJC',
        icaoCode: 'KSJC',
        latitude: '37,36259842',
        longitude: '-121,9290009',
        altitude: '62',
        timezone: '-8',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Lea County Regional Airport',
        city: 'Hobbs',
        country: 'United States',
        iataCode: 'HOB',
        icaoCode: 'KHOB',
        latitude: '32,6875',
        longitude: '-103,2170029',
        altitude: '3661',
        timezone: '-7',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Northeast Philadelphia Airport',
        city: 'Philadelphia',
        country: 'United States',
        iataCode: 'PNE',
        icaoCode: 'KPNE',
        latitude: '40,08190155',
        longitude: '-75,01059723',
        altitude: '120',
        timezone: '-5',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Denver International Airport',
        city: 'Denver',
        country: 'United States',
        iataCode: 'DEN',
        icaoCode: 'KDEN',
        latitude: '39,86169815',
        longitude: '-104,6729965',
        altitude: '5431',
        timezone: '-7',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Philadelphia International Airport',
        city: 'Philadelphia',
        country: 'United States',
        iataCode: 'PHL',
        icaoCode: 'KPHL',
        latitude: '39,87189865',
        longitude: '-75,2410965',
        altitude: '36',
        timezone: '-5',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Sioux Gateway Col. Bud Day Field',
        city: 'Sioux City',
        country: 'United States',
        iataCode: 'SUX',
        icaoCode: 'KSUX',
        latitude: '42,40259933',
        longitude: '-96,38439941',
        altitude: '1098',
        timezone: '-6',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Middle Georgia Regional Airport',
        city: 'Macon',
        country: 'United States',
        iataCode: 'MCN',
        icaoCode: 'KMCN',
        latitude: '32,69279861',
        longitude: '-83,64920044',
        altitude: '354',
        timezone: '-5',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Truth Or Consequences Municipal Airport',
        city: 'Truth Or Consequences',
        country: 'United States',
        iataCode: 'TCS',
        icaoCode: 'KTCS',
        latitude: '33,23690033',
        longitude: '-107,2720032',
        altitude: '4853',
        timezone: '-7',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Palmdale Regional/USAF Plant 42 Airport',
        city: 'Palmdale',
        country: 'United States',
        iataCode: 'PMD',
        icaoCode: 'KPMD',
        latitude: '34,62939835',
        longitude: '-118,0849991',
        altitude: '2543',
        timezone: '-8',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Randolph Air Force Base',
        city: 'San Antonio',
        country: 'United States',
        iataCode: 'RND',
        icaoCode: 'KRND',
        latitude: '29,52969933',
        longitude: '-98,27890015',
        altitude: '761',
        timezone: '-6',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'El Centro Naf Airport',
        city: 'El Centro',
        country: 'United States',
        iataCode: 'NJK',
        icaoCode: 'KNJK',
        latitude: '32,82920074',
        longitude: '-115,6719971',
        altitude: '-42',
        timezone: '-8',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Port Columbus International Airport',
        city: 'Columbus',
        country: 'United States',
        iataCode: 'CMH',
        icaoCode: 'KCMH',
        latitude: '39,9980011',
        longitude: '-82,89189911',
        altitude: '815',
        timezone: '-5',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Drake Field',
        city: 'Fayetteville',
        country: 'United States',
        iataCode: 'FYV',
        icaoCode: 'KFYV',
        latitude: '36,00510025',
        longitude: '-94,17009735',
        altitude: '1251',
        timezone: '-6',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Henry Post Army Air Field (Fort Sill)',
        city: 'Fort Sill',
        country: 'United States',
        iataCode: 'FSI',
        icaoCode: 'KFSI',
        latitude: '34,64979935',
        longitude: '-98,40219879',
        altitude: '1189',
        timezone: '-6',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Princeton Municipal Airport',
        city: 'Princeton',
        country: 'United States',
        iataCode: 'PNM',
        icaoCode: 'KPNM',
        latitude: '45,55989838',
        longitude: '-93,60820007',
        altitude: '980',
        timezone: '-6',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Wright-Patterson Air Force Base',
        city: 'Dayton',
        country: 'United States',
        iataCode: 'FFO',
        icaoCode: 'KFFO',
        latitude: '39,8260994',
        longitude: '-84,0483017',
        altitude: '823',
        timezone: '-5',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Edward G. Pitka Sr Airport',
        city: 'Galena',
        country: 'United States',
        iataCode: 'GAL',
        icaoCode: 'PAGA',
        latitude: '64,73619843',
        longitude: '-156,9369965',
        altitude: '153',
        timezone: '-9',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Chandler Municipal Airport',
        city: 'Chandler',
        country: 'United States',
        iataCode: 'N',
        icaoCode: 'KCHD',
        latitude: '33,2691',
        longitude: '-111,810997',
        altitude: '1243',
        timezone: '-7',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Mineral Wells Airport',
        city: 'Mineral Wells',
        country: 'United States',
        iataCode: 'MWL',
        icaoCode: 'KMWL',
        latitude: '32,78160095',
        longitude: '-98,06020355',
        altitude: '974',
        timezone: '-6',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Mc Connell Air Force Base',
        city: 'Wichita',
        country: 'United States',
        iataCode: 'IAB',
        icaoCode: 'KIAB',
        latitude: '37,62189865',
        longitude: '-97,26820374',
        altitude: '1371',
        timezone: '-6',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'New Orleans NAS JRB/Alvin Callender Field',
        city: 'New Orleans',
        country: 'United States',
        iataCode: 'NBG',
        icaoCode: 'KNBG',
        latitude: '29,82530022',
        longitude: '-90,03500366',
        altitude: '2',
        timezone: '-6',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Beaufort County Airport',
        city: 'Beaufort',
        country: 'United States',
        iataCode: 'BFT',
        icaoCode: 'KARW',
        latitude: '32,41220093',
        longitude: '-80,63439941',
        altitude: '10',
        timezone: '-5',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Texarkana Regional Webb Field',
        city: 'Texarkana',
        country: 'United States',
        iataCode: 'TXK',
        icaoCode: 'KTXK',
        latitude: '33,45370102',
        longitude: '-93,99099731',
        altitude: '390',
        timezone: '-6',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Plattsburgh International Airport',
        city: 'Plattsburgh',
        country: 'United States',
        iataCode: 'PBG',
        icaoCode: 'KPBG',
        latitude: '44,65090179',
        longitude: '-73,4681015',
        altitude: '234',
        timezone: '-5',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Phillips Army Air Field',
        city: 'Aberdeen',
        country: 'United States',
        iataCode: 'APG',
        icaoCode: 'KAPG',
        latitude: '39,466202',
        longitude: '-76,1688',
        altitude: '57',
        timezone: '-5',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Tucumcari Municipal Airport',
        city: 'Tucumcari',
        country: 'United States',
        iataCode: 'TCC',
        icaoCode: 'KTCC',
        latitude: '35,18280029',
        longitude: '-103,6029968',
        altitude: '4065',
        timezone: '-7',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Ted Stevens Anchorage International Airport',
        city: 'Anchorage',
        country: 'United States',
        iataCode: 'ANC',
        icaoCode: 'PANC',
        latitude: '61,17440033',
        longitude: '-149,9960022',
        altitude: '152',
        timezone: '-9',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Robert Gray  Army Air Field Airport',
        city: 'Killeen',
        country: 'United States',
        iataCode: 'GRK',
        icaoCode: 'KGRK',
        latitude: '31,06719971',
        longitude: '-97,8289032',
        altitude: '1015',
        timezone: '-6',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Black Rock Airport',
        city: 'Zuni Pueblo',
        country: 'United States',
        iataCode: 'ZUN',
        icaoCode: 'KZUN',
        latitude: '35,08319855',
        longitude: '-108,7919998',
        altitude: '6454',
        timezone: '-7',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Bellingham International Airport',
        city: 'Bellingham',
        country: 'United States',
        iataCode: 'BLI',
        icaoCode: 'KBLI',
        latitude: '48,7928009',
        longitude: '-122,538002',
        altitude: '170',
        timezone: '-8',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Millington Regional Jetport Airport',
        city: 'Millington',
        country: 'United States',
        iataCode: 'NQA',
        icaoCode: 'KNQA',
        latitude: '35,3567009',
        longitude: '-89,87030029',
        altitude: '320',
        timezone: '-6',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Elkins-Randolph Co-Jennings Randolph Field',
        city: 'Elkins',
        country: 'United States',
        iataCode: 'EKN',
        icaoCode: 'KEKN',
        latitude: '38,88940048',
        longitude: '-79,85710144',
        altitude: '1987',
        timezone: '-5',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Hartford Brainard Airport',
        city: 'Hartford',
        country: 'United States',
        iataCode: 'HFD',
        icaoCode: 'KHFD',
        latitude: '41,73669815',
        longitude: '-72,6493988',
        altitude: '18',
        timezone: '-5',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'North Central State Airport',
        city: 'Smithfield',
        country: 'United States',
        iataCode: 'SFZ',
        icaoCode: 'KSFZ',
        latitude: '41,92079926',
        longitude: '-71,49140167',
        altitude: '441',
        timezone: '-5',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Mobile Regional Airport',
        city: 'Mobile',
        country: 'United States',
        iataCode: 'MOB',
        icaoCode: 'KMOB',
        latitude: '30,69120026',
        longitude: '-88,24279785',
        altitude: '219',
        timezone: '-6',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Moffett Federal Airfield',
        city: 'Mountain View',
        country: 'United States',
        iataCode: 'NUQ',
        icaoCode: 'KNUQ',
        latitude: '37,41609955',
        longitude: '-122,0490036',
        altitude: '32',
        timezone: '-8',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Santa Fe Municipal Airport',
        city: 'Santa Fe',
        country: 'United States',
        iataCode: 'SAF',
        icaoCode: 'KSAF',
        latitude: '35,61709976',
        longitude: '-106,0889969',
        altitude: '6348',
        timezone: '-7',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Barking Sands Airport',
        city: 'Barking Sands',
        country: 'United States',
        iataCode: 'BKH',
        icaoCode: 'PHBK',
        latitude: '22,02280045',
        longitude: '-159,7850037',
        altitude: '23',
        timezone: '-10',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Beauregard Regional Airport',
        city: 'Deridder',
        country: 'United States',
        iataCode: 'DRI',
        icaoCode: 'KDRI',
        latitude: '30,83169937',
        longitude: '-93,33989716',
        altitude: '202',
        timezone: '-6',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Bradshaw Army Airfield',
        city: 'Bradshaw Field',
        country: 'United States',
        iataCode: 'BSF',
        icaoCode: 'PHSF',
        latitude: '19,76009941',
        longitude: '-155,5540009',
        altitude: '6190',
        timezone: '-10',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Nogales International Airport',
        city: 'Nogales',
        country: 'United States',
        iataCode: 'OLS',
        icaoCode: 'KOLS',
        latitude: '31,41769981',
        longitude: '-110,8479996',
        altitude: '3955',
        timezone: '-7',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Mac Dill Air Force Base',
        city: 'Tampa',
        country: 'United States',
        iataCode: 'MCF',
        icaoCode: 'KMCF',
        latitude: '27,84930038',
        longitude: '-82,52120209',
        altitude: '14',
        timezone: '-5',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Scott AFB/Midamerica Airport',
        city: 'Belleville',
        country: 'United States',
        iataCode: 'BLV',
        icaoCode: 'KBLV',
        latitude: '38,5452',
        longitude: '-89,835197',
        altitude: '459',
        timezone: '-6',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Opa-locka Executive Airport',
        city: 'Miami',
        country: 'United States',
        iataCode: 'OPF',
        icaoCode: 'KOPF',
        latitude: '25,90699959',
        longitude: '-80,27839661',
        altitude: '8',
        timezone: '-5',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Del Rio International Airport',
        city: 'Del Rio',
        country: 'United States',
        iataCode: 'DRT',
        icaoCode: 'KDRT',
        latitude: '29,37420082',
        longitude: '-100,927002',
        altitude: '1002',
        timezone: '-6',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Southwest Florida International Airport',
        city: 'Fort Myers',
        country: 'United States',
        iataCode: 'RSW',
        icaoCode: 'KRSW',
        latitude: '26,53619957',
        longitude: '-81,75520325',
        altitude: '30',
        timezone: '-5',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'King Salmon Airport',
        city: 'King Salmon',
        country: 'United States',
        iataCode: 'AKN',
        icaoCode: 'PAKN',
        latitude: '58,67679977',
        longitude: '-156,6490021',
        altitude: '73',
        timezone: '-9',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Muir Army Air Field (Fort Indiantown Gap) Airport',
        city: 'Muir',
        country: 'United States',
        iataCode: 'MUI',
        icaoCode: 'KMUI',
        latitude: '40,43479919',
        longitude: '-76,56939697',
        altitude: '488',
        timezone: '-5',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Kapalua Airport',
        city: 'Lahania-kapalua',
        country: 'United States',
        iataCode: 'JHM',
        icaoCode: 'PHJH',
        latitude: '20,96290016',
        longitude: '-156,6730042',
        altitude: '256',
        timezone: '-10',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'John F Kennedy International Airport',
        city: 'New York',
        country: 'United States',
        iataCode: 'JFK',
        icaoCode: 'KJFK',
        latitude: '40,63980103',
        longitude: '-73,77890015',
        altitude: '13',
        timezone: '-5',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Homestead ARB Airport',
        city: 'Homestead',
        country: 'United States',
        iataCode: 'HST',
        icaoCode: 'KHST',
        latitude: '25,48859978',
        longitude: '-80,38359833',
        altitude: '5',
        timezone: '-5',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Riverside Municipal Airport',
        city: 'Riverside',
        country: 'United States',
        iataCode: 'RAL',
        icaoCode: 'KRAL',
        latitude: '33,95190048',
        longitude: '-117,4449997',
        altitude: '819',
        timezone: '-8',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Sherman Army Air Field',
        city: 'Fort Leavenworth',
        country: 'United States',
        iataCode: 'FLV',
        icaoCode: 'KFLV',
        latitude: '39,36830139',
        longitude: '-94,91470337',
        altitude: '772',
        timezone: '-6',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Wallops Flight Facility Airport',
        city: 'Wallops Island',
        country: 'United States',
        iataCode: 'WAL',
        icaoCode: 'KWAL',
        latitude: '37,94020081',
        longitude: '-75,46640015',
        altitude: '40',
        timezone: '-5',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Holloman Air Force Base',
        city: 'Alamogordo',
        country: 'United States',
        iataCode: 'HMN',
        icaoCode: 'KHMN',
        latitude: '32,85250092',
        longitude: '-106,1070023',
        altitude: '4093',
        timezone: '-7',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Willow Grove Naval Air Station/Joint Reserve Base',
        city: 'Willow Grove',
        country: 'United States',
        iataCode: 'NXX',
        icaoCode: 'KNXX',
        latitude: '40,19979858',
        longitude: '-75,14820099',
        altitude: '358',
        timezone: '-5',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Cheyenne Regional Jerry Olson Field',
        city: 'Cheyenne',
        country: 'United States',
        iataCode: 'CYS',
        icaoCode: 'KCYS',
        latitude: '41,15570068',
        longitude: '-104,8119965',
        altitude: '6159',
        timezone: '-7',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Stockton Metropolitan Airport',
        city: 'Stockton',
        country: 'United States',
        iataCode: 'SCK',
        icaoCode: 'KSCK',
        latitude: '37,89419937',
        longitude: '-121,237999',
        altitude: '33',
        timezone: '-8',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Charleston Air Force Base-International Airport',
        city: 'Charleston',
        country: 'United States',
        iataCode: 'CHS',
        icaoCode: 'KCHS',
        latitude: '32,89860153',
        longitude: '-80,04049683',
        altitude: '46',
        timezone: '-5',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Reno Tahoe International Airport',
        city: 'Reno',
        country: 'United States',
        iataCode: 'RNO',
        icaoCode: 'KRNO',
        latitude: '39,49909973',
        longitude: '-119,7679977',
        altitude: '4415',
        timezone: '-8',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Ketchikan International Airport',
        city: 'Ketchikan',
        country: 'United States',
        iataCode: 'KTN',
        icaoCode: 'PAKT',
        latitude: '55,35559845',
        longitude: '-131,7140045',
        altitude: '89',
        timezone: '-9',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Willow Run Airport',
        city: 'Detroit',
        country: 'United States',
        iataCode: 'YIP',
        icaoCode: 'KYIP',
        latitude: '42,23789978',
        longitude: '-83,53040314',
        altitude: '716',
        timezone: '-5',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Vandenberg Air Force Base',
        city: 'Lompoc',
        country: 'United States',
        iataCode: 'VBG',
        icaoCode: 'KVBG',
        latitude: '34,73730087',
        longitude: '-120,5839996',
        altitude: '369',
        timezone: '-8',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Birmingham-Shuttlesworth International Airport',
        city: 'Birmingham',
        country: 'United States',
        iataCode: 'BHM',
        icaoCode: 'KBHM',
        latitude: '33,56290054',
        longitude: '-86,75350189',
        altitude: '650',
        timezone: '-6',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Lakehurst Maxfield Field Airport',
        city: 'Lakehurst',
        country: 'United States',
        iataCode: 'NEL',
        icaoCode: 'KNEL',
        latitude: '40,03329849',
        longitude: '-74,353302',
        altitude: '101',
        timezone: '-5',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Nellis Air Force Base',
        city: 'Las Vegas',
        country: 'United States',
        iataCode: 'LSV',
        icaoCode: 'KLSV',
        latitude: '36,23619843',
        longitude: '-115,0339966',
        altitude: '1870',
        timezone: '-8',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'March ARB Airport',
        city: 'Riverside',
        country: 'United States',
        iataCode: 'RIV',
        icaoCode: 'KRIV',
        latitude: '33,88069916',
        longitude: '-117,2590027',
        altitude: '1536',
        timezone: '-8',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Modesto City Co-Harry Sham Field',
        city: 'Modesto',
        country: 'United States',
        iataCode: 'MOD',
        icaoCode: 'KMOD',
        latitude: '37,62580109',
        longitude: '-120,9540024',
        altitude: '97',
        timezone: '-8',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Sacramento International Airport',
        city: 'Sacramento',
        country: 'United States',
        iataCode: 'SMF',
        icaoCode: 'KSMF',
        latitude: '38,69540024',
        longitude: '-121,5910034',
        altitude: '27',
        timezone: '-8',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Waukegan National Airport',
        city: 'Chicago',
        country: 'United States',
        iataCode: 'UGN',
        icaoCode: 'KUGN',
        latitude: '42,42219925',
        longitude: '-87,86789703',
        altitude: '727',
        timezone: '-6',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'City of Colorado Springs Municipal Airport',
        city: 'Colorado Springs',
        country: 'United States',
        iataCode: 'COS',
        icaoCode: 'KCOS',
        latitude: '38,80580139',
        longitude: '-104,7009964',
        altitude: '6187',
        timezone: '-7',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Buffalo Niagara International Airport',
        city: 'Buffalo',
        country: 'United States',
        iataCode: 'BUF',
        icaoCode: 'KBUF',
        latitude: '42,94049835',
        longitude: '-78,73220062',
        altitude: '728',
        timezone: '-5',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Griffing Sandusky Airport',
        city: 'Sandusky',
        country: 'United States',
        iataCode: 'SKY',
        icaoCode: 'KSKY',
        latitude: '41,4333992',
        longitude: '-82,65229797',
        altitude: '580',
        timezone: '-5',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Snohomish County (Paine Field) Airport',
        city: 'Everett',
        country: 'United States',
        iataCode: 'PAE',
        icaoCode: 'KPAE',
        latitude: '47,90629959',
        longitude: '-122,2819977',
        altitude: '606',
        timezone: '-8',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Mountain Home Air Force Base',
        city: 'Mountain Home',
        country: 'United States',
        iataCode: 'MUO',
        icaoCode: 'KMUO',
        latitude: '43,043598',
        longitude: '-115,872002',
        altitude: '2996',
        timezone: '-7',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Cedar City Regional Airport',
        city: 'Cedar City',
        country: 'United States',
        iataCode: 'CDC',
        icaoCode: 'KCDC',
        latitude: '37,70100021',
        longitude: '-113,098999',
        altitude: '5622',
        timezone: '-7',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Bradley International Airport',
        city: 'Windsor Locks',
        country: 'United States',
        iataCode: 'BDL',
        icaoCode: 'KBDL',
        latitude: '41,93889999',
        longitude: '-72,68319702',
        altitude: '173',
        timezone: '-5',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Mc Allen Miller International Airport',
        city: 'Mcallen',
        country: 'United States',
        iataCode: 'MFE',
        icaoCode: 'KMFE',
        latitude: '26,17580032',
        longitude: '-98,23860168',
        altitude: '107',
        timezone: '-6',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Norfolk Ns (Chambers Fld) Airport',
        city: 'Norfolk',
        country: 'United States',
        iataCode: 'NGU',
        icaoCode: 'KNGU',
        latitude: '36,93759918',
        longitude: '-76,28929901',
        altitude: '17',
        timezone: '-5',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Westover ARB/Metropolitan Airport',
        city: 'Chicopee Falls',
        country: 'United States',
        iataCode: 'CEF',
        icaoCode: 'KCEF',
        latitude: '42,19400024',
        longitude: '-72,53479767',
        altitude: '241',
        timezone: '-5',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Lubbock Preston Smith International Airport',
        city: 'Lubbock',
        country: 'United States',
        iataCode: 'LBB',
        icaoCode: 'KLBB',
        latitude: '33,66360092',
        longitude: '-101,822998',
        altitude: '3282',
        timezone: '-6',
        dst: 'A',
        type: 'airport',
    },
    {
        name: "Chicago O'Hare International Airport",
        city: 'Chicago',
        country: 'United States',
        iataCode: 'ORD',
        icaoCode: 'KORD',
        latitude: '41,97859955',
        longitude: '-87,90480042',
        altitude: '672',
        timezone: '-6',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Boca Raton Airport',
        city: 'Boca Raton',
        country: 'United States',
        iataCode: 'BCT',
        icaoCode: 'KBCT',
        latitude: '26,37849998',
        longitude: '-80,10769653',
        altitude: '13',
        timezone: '-5',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Fairbanks International Airport',
        city: 'Fairbanks',
        country: 'United States',
        iataCode: 'FAI',
        icaoCode: 'PAFA',
        latitude: '64,81510162',
        longitude: '-147,8560028',
        altitude: '439',
        timezone: '-9',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Quantico MCAF /Turner field',
        city: 'Quantico',
        country: 'United States',
        iataCode: 'NYG',
        icaoCode: 'KNYG',
        latitude: '38,50170135',
        longitude: '-77,30529785',
        altitude: '10',
        timezone: '-5',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Cannon Air Force Base',
        city: 'Clovis',
        country: 'United States',
        iataCode: 'CVS',
        icaoCode: 'KCVS',
        latitude: '34,38280106',
        longitude: '-103,3219986',
        altitude: '4295',
        timezone: '-7',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Kaneohe Bay MCAS (Marion E. Carl Field) Airport',
        city: 'Kaneohe Bay',
        country: 'United States',
        iataCode: 'NGF',
        icaoCode: 'PHNG',
        latitude: '21,45050049',
        longitude: '-157,7680054',
        altitude: '24',
        timezone: '-10',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Offutt Air Force Base',
        city: 'Omaha',
        country: 'United States',
        iataCode: 'OFF',
        icaoCode: 'KOFF',
        latitude: '41,11830139',
        longitude: '-95,91249847',
        altitude: '1052',
        timezone: '-6',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Gulkana Airport',
        city: 'Gulkana',
        country: 'United States',
        iataCode: 'GKN',
        icaoCode: 'PAGK',
        latitude: '62,1548996',
        longitude: '-145,4570007',
        altitude: '1586',
        timezone: '-9',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Watertown International Airport',
        city: 'Watertown',
        country: 'United States',
        iataCode: 'ART',
        icaoCode: 'KART',
        latitude: '43,9919014',
        longitude: '-76,021698',
        altitude: '325',
        timezone: '-5',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Palm Springs International Airport',
        city: 'Palm Springs',
        country: 'United States',
        iataCode: 'PSP',
        icaoCode: 'KPSP',
        latitude: '33,82970047',
        longitude: '-116,5070038',
        altitude: '477',
        timezone: '-8',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Rick Husband Amarillo International Airport',
        city: 'Amarillo',
        country: 'United States',
        iataCode: 'AMA',
        icaoCode: 'KAMA',
        latitude: '35,2193985',
        longitude: '-101,7060013',
        altitude: '3607',
        timezone: '-6',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Fort Dodge Regional Airport',
        city: 'Fort Dodge',
        country: 'United States',
        iataCode: 'FOD',
        icaoCode: 'KFOD',
        latitude: '42,55149841',
        longitude: '-94,19259644',
        altitude: '1156',
        timezone: '-6',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Barksdale Air Force Base',
        city: 'Shreveport',
        country: 'United States',
        iataCode: 'BAD',
        icaoCode: 'KBAD',
        latitude: '32,50180054',
        longitude: '-93,66269684',
        altitude: '166',
        timezone: '-6',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Topeka Regional Airport - Forbes Field',
        city: 'Topeka',
        country: 'United States',
        iataCode: 'FOE',
        icaoCode: 'KFOE',
        latitude: '38,95090103',
        longitude: '-95,66359711',
        altitude: '1078',
        timezone: '-6',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Cotulla-La Salle County Airport',
        city: 'Cotulla',
        country: 'United States',
        iataCode: 'COT',
        icaoCode: 'KCOT',
        latitude: '28,45669937',
        longitude: '-99,22029877',
        altitude: '474',
        timezone: '-6',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Wilmington International Airport',
        city: 'Wilmington',
        country: 'United States',
        iataCode: 'ILM',
        icaoCode: 'KILM',
        latitude: '34,27059937',
        longitude: '-77,90260315',
        altitude: '32',
        timezone: '-5',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Baton Rouge Metropolitan, Ryan Field',
        city: 'Baton Rouge',
        country: 'United States',
        iataCode: 'BTR',
        icaoCode: 'KBTR',
        latitude: '30,53319931',
        longitude: '-91,14959717',
        altitude: '70',
        timezone: '-6',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Tyler Pounds Regional Airport',
        city: 'Tyler',
        country: 'United States',
        iataCode: 'TYR',
        icaoCode: 'KTYR',
        latitude: '32,35409927',
        longitude: '-95,40239716',
        altitude: '544',
        timezone: '-6',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Baltimore/Washington International Thurgood Marshall Airport',
        city: 'Baltimore',
        country: 'United States',
        iataCode: 'BWI',
        icaoCode: 'KBWI',
        latitude: '39,17539978',
        longitude: '-76,66829681',
        altitude: '146',
        timezone: '-5',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Hobart Regional Airport',
        city: 'Hobart',
        country: 'United States',
        iataCode: 'HBR',
        icaoCode: 'KHBR',
        latitude: '34,991317',
        longitude: '-99,051313',
        altitude: '1563',
        timezone: '-6',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Lanai Airport',
        city: 'Lanai',
        country: 'United States',
        iataCode: 'LNY',
        icaoCode: 'PHNY',
        latitude: '20,78560066',
        longitude: '-156,951004',
        altitude: '1308',
        timezone: '-10',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Alexandria International Airport',
        city: 'Alexandria',
        country: 'United States',
        iataCode: 'AEX',
        icaoCode: 'KAEX',
        latitude: '31,32740021',
        longitude: '-92,54979706',
        altitude: '89',
        timezone: '-6',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Condron Army Air Field',
        city: 'White Sands',
        country: 'United States',
        iataCode: 'WSD',
        icaoCode: 'KWSD',
        latitude: '32,34149933',
        longitude: '-106,4029999',
        altitude: '3934',
        timezone: '-7',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Cold Bay Airport',
        city: 'Cold Bay',
        country: 'United States',
        iataCode: 'CDB',
        icaoCode: 'PACD',
        latitude: '55,20610046',
        longitude: '-162,7250061',
        altitude: '96',
        timezone: '-9',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Tulsa International Airport',
        city: 'Tulsa',
        country: 'United States',
        iataCode: 'TUL',
        icaoCode: 'KTUL',
        latitude: '36,19839859',
        longitude: '-95,88809967',
        altitude: '677',
        timezone: '-6',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Sitka Rocky Gutierrez Airport',
        city: 'Sitka',
        country: 'United States',
        iataCode: 'SIT',
        icaoCode: 'PASI',
        latitude: '57,04710007',
        longitude: '-135,3619995',
        altitude: '21',
        timezone: '-9',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Long Island Mac Arthur Airport',
        city: 'Islip',
        country: 'United States',
        iataCode: 'ISP',
        icaoCode: 'KISP',
        latitude: '40,79520035',
        longitude: '-73,10019684',
        altitude: '99',
        timezone: '-5',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Minneapolis-St Paul International/Wold-Chamberlain Airport',
        city: 'Minneapolis',
        country: 'United States',
        iataCode: 'MSP',
        icaoCode: 'KMSP',
        latitude: '44,88199997',
        longitude: '-93,22180176',
        altitude: '841',
        timezone: '-6',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'New Castle Airport',
        city: 'Wilmington',
        country: 'United States',
        iataCode: 'ILG',
        icaoCode: 'KILG',
        latitude: '39,67869949',
        longitude: '-75,60649872',
        altitude: '80',
        timezone: '-5',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Unalaska Airport',
        city: 'Unalaska',
        country: 'United States',
        iataCode: 'DUT',
        icaoCode: 'PADU',
        latitude: '53,90010071',
        longitude: '-166,5440063',
        altitude: '22',
        timezone: '-9',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Louis Armstrong New Orleans International Airport',
        city: 'New Orleans',
        country: 'United States',
        iataCode: 'MSY',
        icaoCode: 'KMSY',
        latitude: '29,99340057',
        longitude: '-90,25800323',
        altitude: '4',
        timezone: '-6',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Portland International Jetport Airport',
        city: 'Portland',
        country: 'United States',
        iataCode: 'PWM',
        icaoCode: 'KPWM',
        latitude: '43,64619827',
        longitude: '-70,30930328',
        altitude: '76',
        timezone: '-5',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Will Rogers World Airport',
        city: 'Oklahoma City',
        country: 'United States',
        iataCode: 'OKC',
        icaoCode: 'KOKC',
        latitude: '35,39310074',
        longitude: '-97,60070038',
        altitude: '1295',
        timezone: '-6',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Albany International Airport',
        city: 'Albany',
        country: 'United States',
        iataCode: 'ALB',
        icaoCode: 'KALB',
        latitude: '42,74829865',
        longitude: '-73,80169678',
        altitude: '285',
        timezone: '-5',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Valdez Pioneer Field',
        city: 'Valdez',
        country: 'United States',
        iataCode: 'VDZ',
        icaoCode: 'PAVD',
        latitude: '61,13389969',
        longitude: '-146,2480011',
        altitude: '121',
        timezone: '-9',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Langley Air Force Base',
        city: 'Hampton',
        country: 'United States',
        iataCode: 'LFI',
        icaoCode: 'KLFI',
        latitude: '37,082901',
        longitude: '-76,36049652',
        altitude: '11',
        timezone: '-5',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'John Wayne Airport-Orange County Airport',
        city: 'Santa Ana',
        country: 'United States',
        iataCode: 'SNA',
        icaoCode: 'KSNA',
        latitude: '33,67570114',
        longitude: '-117,8679962',
        altitude: '56',
        timezone: '-8',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Columbus Air Force Base',
        city: 'Colombus',
        country: 'United States',
        iataCode: 'CBM',
        icaoCode: 'KCBM',
        latitude: '33,64379883',
        longitude: '-88,44380188',
        altitude: '219',
        timezone: '-6',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Kendall-Tamiami Executive Airport',
        city: 'Kendall-tamiami',
        country: 'United States',
        iataCode: 'TMB',
        icaoCode: 'KTMB',
        latitude: '25,64789963',
        longitude: '-80,43280029',
        altitude: '8',
        timezone: '-5',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Oceana NAS',
        city: 'Oceana',
        country: 'United States',
        iataCode: 'NTU',
        icaoCode: 'KNTU',
        latitude: '36,8207016',
        longitude: '-76,03350067',
        altitude: '23',
        timezone: '-5',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Grissom Air Reserve Base',
        city: 'Peru',
        country: 'United States',
        iataCode: 'GUS',
        icaoCode: 'KGUS',
        latitude: '40,64810181',
        longitude: '-86,15209961',
        altitude: '812',
        timezone: '-5',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Casper-Natrona County International Airport',
        city: 'Casper',
        country: 'United States',
        iataCode: 'CPR',
        icaoCode: 'KCPR',
        latitude: '42,90800095',
        longitude: '-106,4639969',
        altitude: '5350',
        timezone: '-7',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Destin-Ft Walton Beach Airport',
        city: 'Valparaiso',
        country: 'United States',
        iataCode: 'VPS',
        icaoCode: 'KVPS',
        latitude: '30,4832',
        longitude: '-86,525398',
        altitude: '87',
        timezone: '-6',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Craig Field',
        city: 'Selma',
        country: 'United States',
        iataCode: 'SEM',
        icaoCode: 'KSEM',
        latitude: '32,34389877',
        longitude: '-86,9878006',
        altitude: '166',
        timezone: '-6',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Key West International Airport',
        city: 'Key West',
        country: 'United States',
        iataCode: 'EYW',
        icaoCode: 'KEYW',
        latitude: '24,55610085',
        longitude: '-81,75959778',
        altitude: '3',
        timezone: '-5',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Charlotte Douglas International Airport',
        city: 'Charlotte',
        country: 'United States',
        iataCode: 'CLT',
        icaoCode: 'KCLT',
        latitude: '35,2140007',
        longitude: '-80,94309998',
        altitude: '748',
        timezone: '-5',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'McCarran International Airport',
        city: 'Las Vegas',
        country: 'United States',
        iataCode: 'LAS',
        icaoCode: 'KLAS',
        latitude: '36,08010101',
        longitude: '-115,1520004',
        altitude: '2181',
        timezone: '-8',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Orlando International Airport',
        city: 'Orlando',
        country: 'United States',
        iataCode: 'MCO',
        icaoCode: 'KMCO',
        latitude: '28,42939949',
        longitude: '-81,30899811',
        altitude: '96',
        timezone: '-5',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Florence Regional Airport',
        city: 'Florence',
        country: 'United States',
        iataCode: 'FLO',
        icaoCode: 'KFLO',
        latitude: '34,1853981',
        longitude: '-79,72389984',
        altitude: '146',
        timezone: '-5',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Great Falls International Airport',
        city: 'Great Falls',
        country: 'United States',
        iataCode: 'GTF',
        icaoCode: 'KGTF',
        latitude: '47,48199844',
        longitude: '-111,3710022',
        altitude: '3680',
        timezone: '-7',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Youngstown Warren Regional Airport',
        city: 'Youngstown',
        country: 'United States',
        iataCode: 'YNG',
        icaoCode: 'KYNG',
        latitude: '41,26070023',
        longitude: '-80,67910004',
        altitude: '1192',
        timezone: '-5',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Ladd AAF Airfield',
        city: 'Fort Wainwright',
        country: 'United States',
        iataCode: 'FBK',
        icaoCode: 'PAFB',
        latitude: '64,83750153',
        longitude: '-147,6139984',
        altitude: '454',
        timezone: '-9',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Mc Minnville Municipal Airport',
        city: 'Mackminnville',
        country: 'United States',
        iataCode: 'MMV',
        icaoCode: 'KMMV',
        latitude: '45,19440079',
        longitude: '-123,1360016',
        altitude: '163',
        timezone: '-8',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Robins Air Force Base',
        city: 'Macon',
        country: 'United States',
        iataCode: 'WRB',
        icaoCode: 'KWRB',
        latitude: '32,64009857',
        longitude: '-83,59190369',
        altitude: '294',
        timezone: '-5',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Suvarnabhumi Airport',
        city: 'Bangkok',
        country: 'Thailand',
        iataCode: 'BKK',
        icaoCode: 'VTBS',
        latitude: '13,68109989',
        longitude: '100,7470016',
        altitude: '5',
        timezone: '7',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Naha Airport',
        city: 'Naha',
        country: 'Indonesia',
        iataCode: 'NAH',
        icaoCode: 'WAMH',
        latitude: '3,683209896',
        longitude: '125,5279999',
        altitude: '16',
        timezone: '8',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Andi Jemma Airport',
        city: 'Masamba',
        country: 'Indonesia',
        iataCode: 'N',
        icaoCode: 'WAWM',
        latitude: '-2,558039904',
        longitude: '120,3239975',
        altitude: '164',
        timezone: '8',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Soroako Airport',
        city: 'Soroako',
        country: 'Indonesia',
        iataCode: 'N',
        icaoCode: 'WAWS',
        latitude: '-2,531199932',
        longitude: '121,3580017',
        altitude: '1388',
        timezone: '8',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Pongtiku Airport',
        city: 'Makale',
        country: 'Indonesia',
        iataCode: 'TTR',
        icaoCode: 'WAWT',
        latitude: '-3,044739962',
        longitude: '119,8219986',
        altitude: '2884',
        timezone: '8',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Wolter Monginsidi Airport',
        city: 'Kendari',
        country: 'Indonesia',
        iataCode: 'KDI',
        icaoCode: 'WAWW',
        latitude: '-4,081610203',
        longitude: '122,4179993',
        altitude: '538',
        timezone: '8',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Maimun Saleh Airport',
        city: 'Sabang',
        country: 'Indonesia',
        iataCode: 'SBG',
        icaoCode: 'WITB',
        latitude: '5,874129772',
        longitude: '95,33969879',
        altitude: '393',
        timezone: '7',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Cibeureum Airport',
        city: 'Tasikmalaya',
        country: 'Indonesia',
        iataCode: 'N',
        icaoCode: 'WICM',
        latitude: '-7,346600056',
        longitude: '108,2460022',
        altitude: '1148',
        timezone: '7',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Iswahyudi Airport',
        city: 'Madiun',
        country: 'Indonesia',
        iataCode: 'N',
        icaoCode: 'WARI',
        latitude: '-7,615769863',
        longitude: '111,4339981',
        altitude: '361',
        timezone: '7',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Abdul Rachman Saleh Airport',
        city: 'Malang',
        country: 'Indonesia',
        iataCode: 'MLG',
        icaoCode: 'WARA',
        latitude: '-7,926559925',
        longitude: '112,7149963',
        altitude: '1726',
        timezone: '7',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Budiarto Airport',
        city: 'Tangerang',
        country: 'Indonesia',
        iataCode: 'N',
        icaoCode: 'WICB',
        latitude: '-6,293169975',
        longitude: '106,5699997',
        altitude: '151',
        timezone: '7',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Husein Sastranegara International Airport',
        city: 'Bandung',
        country: 'Indonesia',
        iataCode: 'BDO',
        icaoCode: 'WICC',
        latitude: '-6,900629997',
        longitude: '107,5759964',
        altitude: '2436',
        timezone: '7',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Penggung Airport',
        city: 'Cirebon',
        country: 'Indonesia',
        iataCode: 'CBN',
        icaoCode: 'WICD',
        latitude: '-6,756140232',
        longitude: '108,5400009',
        altitude: '89',
        timezone: '7',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Adi Sutjipto International Airport',
        city: 'Yogyakarta',
        country: 'Indonesia',
        iataCode: 'JOG',
        icaoCode: 'WARJ',
        latitude: '-7,788179874',
        longitude: '110,4319992',
        altitude: '350',
        timezone: '7',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Tunggul Wulung Airport',
        city: 'Cilacap',
        country: 'Indonesia',
        iataCode: 'CXP',
        icaoCode: 'WIHL',
        latitude: '-7,645060062',
        longitude: '109,0339966',
        altitude: '69',
        timezone: '7',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Pondok Cabe Air Base',
        city: 'Jakarta',
        country: 'Indonesia',
        iataCode: 'PCB',
        icaoCode: 'WIHP',
        latitude: '-6,336959839',
        longitude: '106,7649994',
        altitude: '200',
        timezone: '7',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Achmad Yani Airport',
        city: 'Semarang',
        country: 'Indonesia',
        iataCode: 'SRG',
        icaoCode: 'WARS',
        latitude: '-6,97273',
        longitude: '110,375',
        altitude: '10',
        timezone: '7',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Hang Nadim International Airport',
        city: 'Batam',
        country: 'Indonesia',
        iataCode: 'BTH',
        icaoCode: 'WIDD',
        latitude: '1,121029973',
        longitude: '104,1190033',
        altitude: '126',
        timezone: '7',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Buluh Tumbang (H A S Hanandjoeddin) Airport',
        city: 'Tanjung Pandan',
        country: 'Indonesia',
        iataCode: 'TJQ',
        icaoCode: 'WIOD',
        latitude: '-2,74571991',
        longitude: '107,7549973',
        altitude: '164',
        timezone: '7',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Pangkal Pinang (Depati Amir) Airport',
        city: 'Pangkal Pinang',
        country: 'Indonesia',
        iataCode: 'PGK',
        icaoCode: 'WIPK',
        latitude: '-2,162199974',
        longitude: '106,1389999',
        altitude: '109',
        timezone: '7',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Raja Haji Fisabilillah International Airport',
        city: 'Tanjung Pinang',
        country: 'Indonesia',
        iataCode: 'TNJ',
        icaoCode: 'WIDN',
        latitude: '0,922683001',
        longitude: '104,5319977',
        altitude: '52',
        timezone: '7',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Dabo Airport',
        city: 'Singkep',
        country: 'Indonesia',
        iataCode: 'SIQ',
        icaoCode: 'WIDS',
        latitude: '-0,479189008',
        longitude: '104,5790024',
        altitude: '95',
        timezone: '7',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Syamsudin Noor Airport',
        city: 'Banjarmasin',
        country: 'Indonesia',
        iataCode: 'BDJ',
        icaoCode: 'WAOO',
        latitude: '-3,442359924',
        longitude: '114,7630005',
        altitude: '66',
        timezone: '8',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Batu Licin Airport',
        city: 'Batu Licin',
        country: 'Indonesia',
        iataCode: 'N',
        icaoCode: 'WAOC',
        latitude: '-3,412410021',
        longitude: '115,9950027',
        altitude: '3',
        timezone: '8',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Iskandar Airport',
        city: 'Pangkalan Bun',
        country: 'Indonesia',
        iataCode: 'PKN',
        icaoCode: 'WAOI',
        latitude: '-2,705199957',
        longitude: '111,6729965',
        altitude: '75',
        timezone: '7',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Tjilik Riwut Airport',
        city: 'Palangkaraya',
        country: 'Indonesia',
        iataCode: 'PKY',
        icaoCode: 'WAOP',
        latitude: '-2,225130081',
        longitude: '113,9430008',
        altitude: '82',
        timezone: '7',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Maumere(Wai Oti) Airport',
        city: 'Maumere',
        country: 'Indonesia',
        iataCode: 'MOF',
        icaoCode: 'WATC',
        latitude: '-8,640649796',
        longitude: '122,2369995',
        altitude: '115',
        timezone: '8',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Ende (H Hasan Aroeboesman) Airport',
        city: 'Ende',
        country: 'Indonesia',
        iataCode: 'ENE',
        icaoCode: 'WATE',
        latitude: '-8,849289894',
        longitude: '121,6610031',
        altitude: '49',
        timezone: '8',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Frans Sales Lega Airport',
        city: 'Ruteng',
        country: 'Indonesia',
        iataCode: 'RTG',
        icaoCode: 'WATG',
        latitude: '-8,597009659',
        longitude: '120,4769974',
        altitude: '3510',
        timezone: '8',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'El Tari Airport',
        city: 'Kupang',
        country: 'Indonesia',
        iataCode: 'KOE',
        icaoCode: 'WATT',
        latitude: '-10,17160034',
        longitude: '123,6709976',
        altitude: '335',
        timezone: '8',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Komodo (Mutiara II) Airport',
        city: 'Labuhan Bajo',
        country: 'Indonesia',
        iataCode: 'LBJ',
        icaoCode: 'WATO',
        latitude: '-8,486660004',
        longitude: '119,8889999',
        altitude: '66',
        timezone: '8',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Sultan Aji Muhamad Sulaiman Airport',
        city: 'Balikpapan',
        country: 'Indonesia',
        iataCode: 'BPN',
        icaoCode: 'WALL',
        latitude: '-1,268270016',
        longitude: '116,8939972',
        altitude: '12',
        timezone: '8',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Juwata Airport',
        city: 'Taraken',
        country: 'Indonesia',
        iataCode: 'TRK',
        icaoCode: 'WALR',
        latitude: '3,326667',
        longitude: '117,569444',
        altitude: '23',
        timezone: '8',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Temindung Airport',
        city: 'Samarinda',
        country: 'Indonesia',
        iataCode: 'SRI',
        icaoCode: 'WALS',
        latitude: '-0,484530985',
        longitude: '117,1569977',
        altitude: '33',
        timezone: '8',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Tanjung Santan Airport',
        city: 'Tanjung Santan',
        country: 'Indonesia',
        iataCode: 'N',
        icaoCode: 'WALT',
        latitude: '-0,092973001',
        longitude: '117,4530029',
        altitude: '121',
        timezone: '8',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Selaparang Airport',
        city: 'Mataram',
        country: 'Indonesia',
        iataCode: 'AMI',
        icaoCode: 'WADA',
        latitude: '-8,560709953',
        longitude: '116,0950012',
        altitude: '52',
        timezone: '8',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Muhammad Salahuddin Airport',
        city: 'Bima',
        country: 'Indonesia',
        iataCode: 'BMU',
        icaoCode: 'WADB',
        latitude: '-8,539649963',
        longitude: '118,6869965',
        altitude: '3',
        timezone: '8',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Waingapu Airport',
        city: 'Waingapu',
        country: 'Indonesia',
        iataCode: 'WGP',
        icaoCode: 'WADW',
        latitude: '-9,669219971',
        longitude: '120,302002',
        altitude: '33',
        timezone: '8',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Juanda International Airport',
        city: 'Surabaya',
        country: 'Indonesia',
        iataCode: 'SUB',
        icaoCode: 'WARR',
        latitude: '-7,379829884',
        longitude: '112,7870026',
        altitude: '9',
        timezone: '7',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Adi Sumarmo Wiryokusumo Airport',
        city: 'Solo City',
        country: 'Indonesia',
        iataCode: 'SOC',
        icaoCode: 'WARQ',
        latitude: '-7,516089916',
        longitude: '110,7570038',
        altitude: '421',
        timezone: '7',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Incheon International Airport',
        city: 'Seoul',
        country: 'South Korea',
        iataCode: 'ICN',
        icaoCode: 'RKSI',
        latitude: '37,46910095',
        longitude: '126,4509964',
        altitude: '23',
        timezone: '9',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Chiang Mai International Airport',
        city: 'Chiang Mai',
        country: 'Thailand',
        iataCode: 'CNX',
        icaoCode: 'VTCC',
        latitude: '18,76679993',
        longitude: '98,96260071',
        altitude: '1036',
        timezone: '7',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Chiang Rai International Airport',
        city: 'Chiang Rai',
        country: 'Thailand',
        iataCode: 'CEI',
        icaoCode: 'VTCT',
        latitude: '19,95229912',
        longitude: '99,88289642',
        altitude: '1280',
        timezone: '7',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Nakhon Si Thammarat Airport',
        city: 'Nakhon Si Thammarat',
        country: 'Thailand',
        iataCode: 'NST',
        icaoCode: 'VTSF',
        latitude: '8,539620399',
        longitude: '99,94470215',
        altitude: '13',
        timezone: '7',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Nakhon Ratchasima Airport',
        city: 'Nakhon Ratchasima',
        country: 'Thailand',
        iataCode: 'NAK',
        icaoCode: 'VTUQ',
        latitude: '14,94950008',
        longitude: '102,3130035',
        altitude: '765',
        timezone: '7',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Nakhon Phanom Airport',
        city: 'Nakhon Phanom',
        country: 'Thailand',
        iataCode: 'KOP',
        icaoCode: 'VTUW',
        latitude: '17,38380051',
        longitude: '104,6429977',
        altitude: '587',
        timezone: '7',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Ubon Ratchathani Airport',
        city: 'Ubon Ratchathani',
        country: 'Thailand',
        iataCode: 'UBP',
        icaoCode: 'VTUU',
        latitude: '15,25129986',
        longitude: '104,8700027',
        altitude: '406',
        timezone: '7',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Khon Kaen Airport',
        city: 'Khon Kaen',
        country: 'Thailand',
        iataCode: 'KKC',
        icaoCode: 'VTUK',
        latitude: '16,46660042',
        longitude: '102,7839966',
        altitude: '670',
        timezone: '7',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Sukhothai Airport',
        city: 'Sukhothai',
        country: 'Thailand',
        iataCode: 'THS',
        icaoCode: 'VTPO',
        latitude: '17,23800087',
        longitude: '99,81819916',
        altitude: '179',
        timezone: '7',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Ngurah Rai (Bali) International Airport',
        city: 'Denpasar',
        country: 'Indonesia',
        iataCode: 'DPS',
        icaoCode: 'WADD',
        latitude: '-8,748169899',
        longitude: '115,1669998',
        altitude: '14',
        timezone: '8',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Eleftherios Venizelos International Airport',
        city: 'Athens',
        country: 'Greece',
        iataCode: 'ATH',
        icaoCode: 'LGAV',
        latitude: '37,93640137',
        longitude: '23,94449997',
        altitude: '308',
        timezone: '2',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Chubu Centrair International Airport',
        city: 'Nagoya',
        country: 'Japan',
        iataCode: 'NGO',
        icaoCode: 'RJGG',
        latitude: '34,85839844',
        longitude: '136,8049927',
        altitude: '15',
        timezone: '9',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Kobe Airport',
        city: 'Kobe',
        country: 'Japan',
        iataCode: 'UKB',
        icaoCode: 'RJBE',
        latitude: '34,63280106',
        longitude: '135,223999',
        altitude: '22',
        timezone: '9',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Pullman Moscow Regional Airport',
        city: 'Pullman',
        country: 'United States',
        iataCode: 'PUW',
        icaoCode: 'KPUW',
        latitude: '46,7439',
        longitude: '-117,110001',
        altitude: '2556',
        timezone: '-8',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Lewiston Nez Perce County Airport',
        city: 'Lewiston',
        country: 'United States',
        iataCode: 'LWS',
        icaoCode: 'KLWS',
        latitude: '46,37450027',
        longitude: '-117,0149994',
        altitude: '1442',
        timezone: '-8',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Elmira Corning Regional Airport',
        city: 'Elmira',
        country: 'United States',
        iataCode: 'ELM',
        icaoCode: 'KELM',
        latitude: '42,15990067',
        longitude: '-76,89160156',
        altitude: '954',
        timezone: '-5',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Ithaca Tompkins Regional Airport',
        city: 'Ithaca',
        country: 'United States',
        iataCode: 'ITH',
        icaoCode: 'KITH',
        latitude: '42,49100113',
        longitude: '-76,45839691',
        altitude: '1099',
        timezone: '-5',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Monterey Peninsula Airport',
        city: 'Monterey',
        country: 'United States',
        iataCode: 'MRY',
        icaoCode: 'KMRY',
        latitude: '36,5870018',
        longitude: '-121,8430023',
        altitude: '257',
        timezone: '-8',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Santa Barbara Municipal Airport',
        city: 'Santa Barbara',
        country: 'United States',
        iataCode: 'SBA',
        icaoCode: 'KSBA',
        latitude: '34,42620087',
        longitude: '-119,8399963',
        altitude: '13',
        timezone: '-8',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Daytona Beach International Airport',
        city: 'Daytona Beach',
        country: 'United States',
        iataCode: 'DAB',
        icaoCode: 'KDAB',
        latitude: '29,179899',
        longitude: '-81,058098',
        altitude: '34',
        timezone: '-5',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Liepāja International Airport',
        city: 'Liepaja',
        country: 'Latvia',
        iataCode: 'LPX',
        icaoCode: 'EVLA',
        latitude: '56,51750183',
        longitude: '21,09690094',
        altitude: '16',
        timezone: '2',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Riga International Airport',
        city: 'Riga',
        country: 'Latvia',
        iataCode: 'RIX',
        icaoCode: 'EVRA',
        latitude: '56,92359924',
        longitude: '23,97109985',
        altitude: '36',
        timezone: '2',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Šiauliai International Airport',
        city: 'Siauliai',
        country: 'Lithuania',
        iataCode: 'SQQ',
        icaoCode: 'EYSA',
        latitude: '55,89390182',
        longitude: '23,39500046',
        altitude: '443',
        timezone: '2',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Barysiai Airport',
        city: 'Barysiai',
        country: 'Lithuania',
        iataCode: 'HLJ',
        icaoCode: 'EYSB',
        latitude: '56,0705986',
        longitude: '23,55809975',
        altitude: '270',
        timezone: '2',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Kaunas International Airport',
        city: 'Kaunas',
        country: 'Lithuania',
        iataCode: 'KUN',
        icaoCode: 'EYKA',
        latitude: '54,96390152',
        longitude: '24,08480072',
        altitude: '256',
        timezone: '2',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'S. Darius and S. Girėnas Airport',
        city: 'Kaunas',
        country: 'Lithuania',
        iataCode: 'N',
        icaoCode: 'EYKS',
        latitude: '54,87979889',
        longitude: '23,88150024',
        altitude: '246',
        timezone: '2',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Palanga International Airport',
        city: 'Palanga',
        country: 'Lithuania',
        iataCode: 'PLQ',
        icaoCode: 'EYPA',
        latitude: '55,97320175',
        longitude: '21,09390068',
        altitude: '33',
        timezone: '2',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Vilnius International Airport',
        city: 'Vilnius',
        country: 'Lithuania',
        iataCode: 'VNO',
        icaoCode: 'EYVI',
        latitude: '54,63410187',
        longitude: '25,28580093',
        altitude: '646',
        timezone: '2',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Panevėžys Air Base',
        city: 'Panevezys',
        country: 'Lithuania',
        iataCode: 'PNV',
        icaoCode: 'EYPP',
        latitude: '55,72940063',
        longitude: '24,46080017',
        altitude: '197',
        timezone: '2',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Erebuni Airport',
        city: 'Yerevan',
        country: 'Armenia',
        iataCode: 'N',
        icaoCode: 'UDYE',
        latitude: '40,12210083',
        longitude: '44,46500015',
        altitude: '2948',
        timezone: '4',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Stepanavan Airport',
        city: 'Stepanavan',
        country: 'Armenia',
        iataCode: 'N',
        icaoCode: 'UDLS',
        latitude: '41,04850006',
        longitude: '44,33720016',
        altitude: '4836',
        timezone: '4',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Zvartnots International Airport',
        city: 'Yerevan',
        country: 'Armenia',
        iataCode: 'EVN',
        icaoCode: 'UDYZ',
        latitude: '40,14730072',
        longitude: '44,39590073',
        altitude: '2838',
        timezone: '4',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Gyumri Shirak Airport',
        city: 'Gyumri',
        country: 'Armenia',
        iataCode: 'LWN',
        icaoCode: 'UDSG',
        latitude: '40,75040054',
        longitude: '43,85929871',
        altitude: '5000',
        timezone: '4',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Assab International Airport',
        city: 'Assab',
        country: 'Eritrea',
        iataCode: 'ASA',
        icaoCode: 'HHSB',
        latitude: '13,07180023',
        longitude: '42,64500046',
        altitude: '46',
        timezone: '3',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Asmara International Airport',
        city: 'Asmara',
        country: 'Eritrea',
        iataCode: 'ASM',
        icaoCode: 'HHAS',
        latitude: '15,29189968',
        longitude: '38,91070175',
        altitude: '7661',
        timezone: '3',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Massawa International Airport',
        city: 'Massawa',
        country: 'Eritrea',
        iataCode: 'MSW',
        icaoCode: 'HHMS',
        latitude: '15,67000008',
        longitude: '39,37009811',
        altitude: '194',
        timezone: '3',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Yasser Arafat International Airport',
        city: 'Gaza',
        country: 'Palestine',
        iataCode: 'GZA',
        icaoCode: 'LVGZ',
        latitude: '31,24640083',
        longitude: '34,27610016',
        altitude: '320',
        timezone: '2',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Batumi International Airport',
        city: 'Batumi',
        country: 'Georgia',
        iataCode: 'BUS',
        icaoCode: 'UGSB',
        latitude: '41,61029816',
        longitude: '41,59970093',
        altitude: '105',
        timezone: '4',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Kopitnari Airport',
        city: 'Kutaisi',
        country: 'Georgia',
        iataCode: 'KUT',
        icaoCode: 'UGKO',
        latitude: '42,17670059',
        longitude: '42,48260117',
        altitude: '223',
        timezone: '4',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Tbilisi International Airport',
        city: 'Tbilisi',
        country: 'Georgia',
        iataCode: 'TBS',
        icaoCode: 'UGTB',
        latitude: '41,6692009',
        longitude: '44,95470047',
        altitude: '1624',
        timezone: '4',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Mukalla International Airport',
        city: 'Mukalla',
        country: 'Yemen',
        iataCode: 'RIY',
        icaoCode: 'OYRN',
        latitude: '14,66259956',
        longitude: '49,375',
        altitude: '54',
        timezone: '3',
        dst: 'U',
        type: 'airport',
    },
    {
        name: "Ta'izz International Airport",
        city: 'Taiz',
        country: 'Yemen',
        iataCode: 'TAI',
        icaoCode: 'OYTZ',
        latitude: '13,68599987',
        longitude: '44,13909912',
        altitude: '4838',
        timezone: '3',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Hodeidah International Airport',
        city: 'Hodeidah',
        country: 'Yemen',
        iataCode: 'HOD',
        icaoCode: 'OYHD',
        latitude: '14,75300026',
        longitude: '42,97629929',
        altitude: '41',
        timezone: '3',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Aden International Airport',
        city: 'Aden',
        country: 'Yemen',
        iataCode: 'ADE',
        icaoCode: 'OYAA',
        latitude: '12,8295002',
        longitude: '45,02880096',
        altitude: '7',
        timezone: '3',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Ataq Airport',
        city: 'Ataq',
        country: 'Yemen',
        iataCode: 'AXK',
        icaoCode: 'OYAT',
        latitude: '14,55130005',
        longitude: '46,82619858',
        altitude: '3735',
        timezone: '3',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Al Ghaidah International Airport',
        city: 'Al Ghaidah Intl',
        country: 'Yemen',
        iataCode: 'AAY',
        icaoCode: 'OYGD',
        latitude: '16,19169998',
        longitude: '52,17499924',
        altitude: '134',
        timezone: '3',
        dst: 'U',
        type: 'airport',
    },
    {
        name: "Sana'a International Airport",
        city: 'Sanaa',
        country: 'Yemen',
        iataCode: 'SAH',
        icaoCode: 'OYSN',
        latitude: '15,47630024',
        longitude: '44,21969986',
        altitude: '7216',
        timezone: '3',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Beihan Airport',
        city: 'Beihan',
        country: 'Yemen',
        iataCode: 'BHN',
        icaoCode: 'OYBN',
        latitude: '14,78199959',
        longitude: '45,7201004',
        altitude: '3800',
        timezone: '3',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Socotra International Airport',
        city: 'Socotra',
        country: 'Yemen',
        iataCode: 'SCT',
        icaoCode: 'OYSQ',
        latitude: '12,63070011',
        longitude: '53,90579987',
        altitude: '146',
        timezone: '3',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Al Badie Airport',
        city: 'Al Badie',
        country: 'Yemen',
        iataCode: 'N',
        icaoCode: 'OYBA',
        latitude: '18,71929932',
        longitude: '50,8368988',
        altitude: '908',
        timezone: '3',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Memmingen Allgau Airport',
        city: 'Memmingen',
        country: 'Germany',
        iataCode: 'FMM',
        icaoCode: 'EDJA',
        latitude: '47,98880005',
        longitude: '10,23950005',
        altitude: '2077',
        timezone: '1',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Nevşehir Kapadokya Airport',
        city: 'Nevsehir',
        country: 'Turkey',
        iataCode: 'NAV',
        icaoCode: 'LTAZ',
        latitude: '38,7719',
        longitude: '34,5345',
        altitude: '3100',
        timezone: '3',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Ministro Pistarini International Airport',
        city: 'Buenos Aires',
        country: 'Argentina',
        iataCode: 'EZE',
        icaoCode: 'SAEZ',
        latitude: '-34,8222',
        longitude: '-58,5358',
        altitude: '67',
        timezone: '-3',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Erbil International Airport',
        city: 'Erbil',
        country: 'Iraq',
        iataCode: 'EBL',
        icaoCode: 'ORER',
        latitude: '36,23759842',
        longitude: '43,96319962',
        altitude: '1341',
        timezone: '3',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Emerald Airport',
        city: 'Emerald',
        country: 'Australia',
        iataCode: 'EMD',
        icaoCode: 'YEML',
        latitude: '-23,56749916',
        longitude: '148,1790009',
        altitude: '624',
        timezone: '10',
        dst: 'O',
        type: 'airport',
    },
    {
        name: 'Athen Helenikon Airport',
        city: 'Athens',
        country: 'Greece',
        iataCode: 'HEW',
        icaoCode: 'LGAT',
        latitude: '37,893299',
        longitude: '23,726101',
        altitude: '69',
        timezone: '2',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Kansai International Airport',
        city: 'Osaka',
        country: 'Japan',
        iataCode: 'KIX',
        icaoCode: 'RJBB',
        latitude: '34,4272995',
        longitude: '135,2440033',
        altitude: '26',
        timezone: '9',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Tagbilaran Airport',
        city: 'Tagbilaran',
        country: 'Philippines',
        iataCode: 'TAG',
        icaoCode: 'RPVT',
        latitude: '9,664079666',
        longitude: '123,8529968',
        altitude: '38',
        timezone: '8',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Ilulissat Airport',
        city: 'Ilulissat',
        country: 'Greenland',
        iataCode: 'JAV',
        icaoCode: 'BGJN',
        latitude: '69,24320221',
        longitude: '-51,05709839',
        altitude: '95',
        timezone: '-3',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Qasigiannguit Heliport',
        city: 'Qasigiannguit',
        country: 'Greenland',
        iataCode: 'JCH',
        icaoCode: 'BGCH',
        latitude: '68,82281555',
        longitude: '-51,17344737',
        altitude: '70',
        timezone: '-3',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Aasiaat Airport',
        city: 'Aasiaat',
        country: 'Greenland',
        iataCode: 'JEG',
        icaoCode: 'BGAA',
        latitude: '68,72180176',
        longitude: '-52,78469849',
        altitude: '74',
        timezone: '-3',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Palma De Mallorca Airport',
        city: 'Palma de Mallorca',
        country: 'Spain',
        iataCode: 'PMI',
        icaoCode: 'LEPA',
        latitude: '39,55170059',
        longitude: '2,738810062',
        altitude: '27',
        timezone: '1',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Darwin International Airport',
        city: 'Darwin',
        country: 'Australia',
        iataCode: 'DRW',
        icaoCode: 'YPDN',
        latitude: '-12,41469955',
        longitude: '130,8769989',
        altitude: '103',
        timezone: '9,5',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Surat Thani Airport',
        city: 'Surat Thani',
        country: 'Thailand',
        iataCode: 'URT',
        icaoCode: 'VTSB',
        latitude: '9,132599831',
        longitude: '99,13559723',
        altitude: '20',
        timezone: '7',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Talkeetna Airport',
        city: 'Talkeetna',
        country: 'United States',
        iataCode: 'TKA',
        icaoCode: 'PATK',
        latitude: '62,32049942',
        longitude: '-150,0939941',
        altitude: '358',
        timezone: '-9',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Xewkija Heliport',
        city: 'Gozo',
        country: 'Malta',
        iataCode: 'GZM',
        icaoCode: 'LMMG',
        latitude: '36,027199',
        longitude: '14,2728',
        altitude: '0',
        timezone: '1',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Tweed New Haven Airport',
        city: 'New Haven',
        country: 'United States',
        iataCode: 'HVN',
        icaoCode: 'KHVN',
        latitude: '41,26369858',
        longitude: '-72,88680267',
        altitude: '12',
        timezone: '-5',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Asheville Regional Airport',
        city: 'Asheville',
        country: 'United States',
        iataCode: 'AVL',
        icaoCode: 'KAVL',
        latitude: '35,43619919',
        longitude: '-82,54180145',
        altitude: '2165',
        timezone: '-5',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Piedmont Triad International Airport',
        city: 'Greensboro',
        country: 'United States',
        iataCode: 'GSO',
        icaoCode: 'KGSO',
        latitude: '36,09780121',
        longitude: '-79,93730164',
        altitude: '925',
        timezone: '-5',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Joe Foss Field Airport',
        city: 'Sioux Falls',
        country: 'United States',
        iataCode: 'FSD',
        icaoCode: 'KFSD',
        latitude: '43,58200073',
        longitude: '-96,74189758',
        altitude: '1429',
        timezone: '-6',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Ayers Rock Connellan Airport',
        city: 'Uluru',
        country: 'Australia',
        iataCode: 'AYQ',
        icaoCode: 'YAYE',
        latitude: '-25,1861',
        longitude: '130,975998',
        altitude: '1626',
        timezone: '9,5',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Manchester Airport',
        city: 'Manchester NH',
        country: 'United States',
        iataCode: 'MHT',
        icaoCode: 'KMHT',
        latitude: '42,93259811',
        longitude: '-71,43569946',
        altitude: '266',
        timezone: '-5',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Naples Municipal Airport',
        city: 'Naples',
        country: 'United States',
        iataCode: 'APF',
        icaoCode: 'KAPF',
        latitude: '26,15259933',
        longitude: '-81,77529907',
        altitude: '8',
        timezone: '-5',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'LTS Pulau Redang Airport',
        city: 'Redang',
        country: 'Malaysia',
        iataCode: 'RDN',
        icaoCode: 'WMPR',
        latitude: '5,76527977',
        longitude: '103,0070038',
        altitude: '36',
        timezone: '8',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Louisville International Standiford Field',
        city: 'Louisville',
        country: 'United States',
        iataCode: 'SDF',
        icaoCode: 'KSDF',
        latitude: '38,1744',
        longitude: '-85,736',
        altitude: '501',
        timezone: '-5',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Charlottesville Albemarle Airport',
        city: 'Charlottesville VA',
        country: 'United States',
        iataCode: 'CHO',
        icaoCode: 'KCHO',
        latitude: '38,1385994',
        longitude: '-78,45290375',
        altitude: '639',
        timezone: '-5',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Roanoke–Blacksburg Regional Airport',
        city: 'Roanoke VA',
        country: 'United States',
        iataCode: 'ROA',
        icaoCode: 'KROA',
        latitude: '37,32550049',
        longitude: '-79,97540283',
        altitude: '1175',
        timezone: '-5',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Blue Grass Airport',
        city: 'Lexington KY',
        country: 'United States',
        iataCode: 'LEX',
        icaoCode: 'KLEX',
        latitude: '38,03649902',
        longitude: '-84,60590363',
        altitude: '979',
        timezone: '-5',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Evansville Regional Airport',
        city: 'Evansville',
        country: 'United States',
        iataCode: 'EVV',
        icaoCode: 'KEVV',
        latitude: '38,03699875',
        longitude: '-87,53240204',
        altitude: '418',
        timezone: '-6',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Albuquerque International Sunport Airport',
        city: 'Albuquerque',
        country: 'United States',
        iataCode: 'ABQ',
        icaoCode: 'KABQ',
        latitude: '35,04019928',
        longitude: '-106,6090012',
        altitude: '5355',
        timezone: '-7',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Gallatin Field',
        city: 'Bozeman',
        country: 'United States',
        iataCode: 'BZN',
        icaoCode: 'KBZN',
        latitude: '45,77750015',
        longitude: '-111,1529999',
        altitude: '4473',
        timezone: '-7',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Billings Logan International Airport',
        city: 'Billings',
        country: 'United States',
        iataCode: 'BIL',
        icaoCode: 'KBIL',
        latitude: '45,80770111',
        longitude: '-108,5429993',
        altitude: '3652',
        timezone: '-7',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Bert Mooney Airport',
        city: 'Butte',
        country: 'United States',
        iataCode: 'BTM',
        icaoCode: 'KBTM',
        latitude: '45,95479965',
        longitude: '-112,4970016',
        altitude: '5550',
        timezone: '-7',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Cherry Capital Airport',
        city: 'Traverse City',
        country: 'United States',
        iataCode: 'TVC',
        icaoCode: 'KTVC',
        latitude: '44,74140167',
        longitude: '-85,5821991',
        altitude: '624',
        timezone: '-5',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Mundo Maya International Airport',
        city: 'Flores',
        country: 'Guatemala',
        iataCode: 'FRS',
        icaoCode: 'MGTK',
        latitude: '16,91379929',
        longitude: '-89,86640167',
        altitude: '427',
        timezone: '-6',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Hancock County-Bar Harbor Airport',
        city: 'Bar Harbor',
        country: 'United States',
        iataCode: 'BHB',
        icaoCode: 'KBHB',
        latitude: '44,45000076',
        longitude: '-68,3615036',
        altitude: '83',
        timezone: '-5',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Knox County Regional Airport',
        city: 'Rockland',
        country: 'United States',
        iataCode: 'RKD',
        icaoCode: 'KRKD',
        latitude: '44,06010056',
        longitude: '-69,09919739',
        altitude: '56',
        timezone: '-5',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Jackson Hole Airport',
        city: 'Jacksn Hole',
        country: 'United States',
        iataCode: 'JAC',
        icaoCode: 'KJAC',
        latitude: '43,6072998',
        longitude: '-110,737999',
        altitude: '6451',
        timezone: '-7',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Chicago Rockford International Airport',
        city: 'Rockford',
        country: 'United States',
        iataCode: 'RFD',
        icaoCode: 'KRFD',
        latitude: '42,19540024',
        longitude: '-89,09719849',
        altitude: '742',
        timezone: '-6',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Domodedovo International Airport',
        city: 'Moscow',
        country: 'Russia',
        iataCode: 'DME',
        icaoCode: 'UUDD',
        latitude: '55,40879822',
        longitude: '37,90629959',
        altitude: '588',
        timezone: '3',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Sanya Phoenix International Airport',
        city: 'Sanya',
        country: 'China',
        iataCode: 'SYX',
        icaoCode: 'ZJSY',
        latitude: '18,30290031',
        longitude: '109,4120026',
        altitude: '92',
        timezone: '8',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Milford Sound Airport',
        city: 'Milford Sound',
        country: 'New Zealand',
        iataCode: 'MFN',
        icaoCode: 'NZMF',
        latitude: '-44,6733017',
        longitude: '167,9230042',
        altitude: '10',
        timezone: '12',
        dst: 'Z',
        type: 'airport',
    },
    {
        name: 'Lijiang Airport',
        city: 'Lijiang',
        country: 'China',
        iataCode: 'LJG',
        icaoCode: 'ZPLJ',
        latitude: '26,68000031',
        longitude: '100,2460022',
        altitude: '0',
        timezone: '8',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Greenville Spartanburg International Airport',
        city: 'Greenville',
        country: 'United States',
        iataCode: 'GSP',
        icaoCode: 'KGSP',
        latitude: '34,89569855',
        longitude: '-82,21890259',
        altitude: '964',
        timezone: '-5',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Central Illinois Regional Airport at Bloomington-Normal',
        city: 'Bloomington',
        country: 'United States',
        iataCode: 'BMI',
        icaoCode: 'KBMI',
        latitude: '40,47710037',
        longitude: '-88,91590118',
        altitude: '871',
        timezone: '-6',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Gulfport Biloxi International Airport',
        city: 'Gulfport',
        country: 'United States',
        iataCode: 'GPT',
        icaoCode: 'KGPT',
        latitude: '30,40730095',
        longitude: '-89,07009888',
        altitude: '28',
        timezone: '-6',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Kalamazoo Battle Creek International Airport',
        city: 'Kalamazoo',
        country: 'United States',
        iataCode: 'AZO',
        icaoCode: 'KAZO',
        latitude: '42,23490143',
        longitude: '-85,55210114',
        altitude: '874',
        timezone: '-5',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Toledo Express Airport',
        city: 'Toledo',
        country: 'United States',
        iataCode: 'TOL',
        icaoCode: 'KTOL',
        latitude: '41,58679962',
        longitude: '-83,80780029',
        altitude: '683',
        timezone: '-5',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Fort Wayne International Airport',
        city: 'Fort Wayne',
        country: 'United States',
        iataCode: 'FWA',
        icaoCode: 'KFWA',
        latitude: '40,97850037',
        longitude: '-85,19509888',
        altitude: '814',
        timezone: '-5',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Decatur Airport',
        city: 'Decatur',
        country: 'United States',
        iataCode: 'DEC',
        icaoCode: 'KDEC',
        latitude: '39,83459854',
        longitude: '-88,86569977',
        altitude: '682',
        timezone: '-6',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'The Eastern Iowa Airport',
        city: 'Cedar Rapids',
        country: 'United States',
        iataCode: 'CID',
        icaoCode: 'KCID',
        latitude: '41,88470078',
        longitude: '-91,71080017',
        altitude: '869',
        timezone: '-6',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'La Crosse Municipal Airport',
        city: 'La Crosse',
        country: 'United States',
        iataCode: 'LSE',
        icaoCode: 'KLSE',
        latitude: '43,879002',
        longitude: '-91,256699',
        altitude: '655',
        timezone: '-6',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Central Wisconsin Airport',
        city: 'Wassau',
        country: 'United States',
        iataCode: 'CWA',
        icaoCode: 'KCWA',
        latitude: '44,77759933',
        longitude: '-89,66680145',
        altitude: '1277',
        timezone: '-6',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'General Wayne A. Downing Peoria International Airport',
        city: 'Peoria',
        country: 'United States',
        iataCode: 'PIA',
        icaoCode: 'KPIA',
        latitude: '40,66419983',
        longitude: '-89,69329834',
        altitude: '660',
        timezone: '-6',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Appleton International Airport',
        city: 'Appleton',
        country: 'United States',
        iataCode: 'ATW',
        icaoCode: 'KATW',
        latitude: '44,2580986',
        longitude: '-88,51909637',
        altitude: '918',
        timezone: '-6',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Rochester International Airport',
        city: 'Rochester',
        country: 'United States',
        iataCode: 'RST',
        icaoCode: 'KRST',
        latitude: '43,90829849',
        longitude: '-92,5',
        altitude: '1317',
        timezone: '-6',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'University of Illinois Willard Airport',
        city: 'Champaign',
        country: 'United States',
        iataCode: 'CMI',
        icaoCode: 'KCMI',
        latitude: '40,03919983',
        longitude: '-88,27809906',
        altitude: '755',
        timezone: '-6',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Manhattan Regional Airport',
        city: 'Manhattan',
        country: 'United States',
        iataCode: 'MHK',
        icaoCode: 'KMHK',
        latitude: '39,14099884',
        longitude: '-96,67079926',
        altitude: '1057',
        timezone: '-6',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Kingscote Airport',
        city: 'Kingscote',
        country: 'Australia',
        iataCode: 'KGC',
        icaoCode: 'YKSC',
        latitude: '-35,71390152',
        longitude: '137,5209961',
        altitude: '24',
        timezone: '9,5',
        dst: 'O',
        type: 'airport',
    },
    {
        name: 'Hervey Bay Airport',
        city: 'Hervey Bay',
        country: 'Australia',
        iataCode: 'HVB',
        icaoCode: 'YHBA',
        latitude: '-25,31889915',
        longitude: '152,8800049',
        altitude: '60',
        timezone: '10',
        dst: 'O',
        type: 'airport',
    },
    {
        name: 'Dali Airport',
        city: 'Dali',
        country: 'China',
        iataCode: 'DLU',
        icaoCode: 'ZPDL',
        latitude: '25,649401',
        longitude: '100,319',
        altitude: '7050',
        timezone: '8',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Mulu Airport',
        city: 'Mulu',
        country: 'Malaysia',
        iataCode: 'MZV',
        icaoCode: 'WBMU',
        latitude: '4,04832983',
        longitude: '114,8050003',
        altitude: '80',
        timezone: '8',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Sharm El Sheikh International Airport',
        city: 'Sharm El Sheikh',
        country: 'Egypt',
        iataCode: 'SSH',
        icaoCode: 'HESH',
        latitude: '27,97730064',
        longitude: '34,39500046',
        altitude: '143',
        timezone: '2',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Venango Regional Airport',
        city: 'Franklin',
        country: 'United States',
        iataCode: 'FKL',
        icaoCode: 'KFKL',
        latitude: '41,37789917',
        longitude: '-79,86039734',
        altitude: '1540',
        timezone: '-5',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Jomo Kenyatta International Airport',
        city: 'Nairobi',
        country: 'Kenya',
        iataCode: 'NBO',
        icaoCode: 'HKJK',
        latitude: '-1,319239974',
        longitude: '36,92779922',
        altitude: '5330',
        timezone: '3',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Seronera Airport',
        city: 'Seronera',
        country: 'Tanzania',
        iataCode: 'SEU',
        icaoCode: 'HTSN',
        latitude: '-2,458060026',
        longitude: '34,82249832',
        altitude: '5080',
        timezone: '3',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'El Calafate Airport',
        city: 'El Calafate',
        country: 'Argentina',
        iataCode: 'FTE',
        icaoCode: 'SAWC',
        latitude: '-50,2803',
        longitude: '-72,053101',
        altitude: '669',
        timezone: '-3',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Armidale Airport',
        city: 'Armidale',
        country: 'Australia',
        iataCode: 'ARM',
        icaoCode: 'YARM',
        latitude: '-30,52809906',
        longitude: '151,6170044',
        altitude: '3556',
        timezone: '10',
        dst: 'O',
        type: 'airport',
    },
    {
        name: 'Grand Junction Regional Airport',
        city: 'Grand Junction',
        country: 'United States',
        iataCode: 'GJT',
        icaoCode: 'KGJT',
        latitude: '39,12239838',
        longitude: '-108,5270004',
        altitude: '4858',
        timezone: '-7',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'St George Municipal Airport',
        city: 'Saint George',
        country: 'United States',
        iataCode: 'SGU',
        icaoCode: 'KSGU',
        latitude: '37,03638889',
        longitude: '-113,5103056',
        altitude: '2941',
        timezone: '-7',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'David Wayne Hooks Memorial Airport',
        city: 'Houston',
        country: 'United States',
        iataCode: 'DWH',
        icaoCode: 'KDWH',
        latitude: '30,0618',
        longitude: '-95,55280304',
        altitude: '152',
        timezone: '-6',
        dst: 'A',
        type: 'airport',
    },
    {
        name: "Port O'Connor Private Airport",
        city: "Port O\\'Connor",
        country: 'United States',
        iataCode: 'S46',
        icaoCode: 'XS46',
        latitude: '28,42970085',
        longitude: '-96,44439697',
        altitude: '8',
        timezone: '-6',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Sarasota Bradenton International Airport',
        city: 'Sarasota',
        country: 'United States',
        iataCode: 'SRQ',
        icaoCode: 'KSRQ',
        latitude: '27,39539909',
        longitude: '-82,55439758',
        altitude: '30',
        timezone: '-5',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'L.F. Wade International International Airport',
        city: 'Bermuda',
        country: 'Bermuda',
        iataCode: 'BDA',
        icaoCode: 'TXKF',
        latitude: '32,36399841',
        longitude: '-64,67870331',
        altitude: '12',
        timezone: '-4',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Van Nuys Airport',
        city: 'Van Nuys',
        country: 'United States',
        iataCode: 'VNY',
        icaoCode: 'KVNY',
        latitude: '34,20980072',
        longitude: '-118,4899979',
        altitude: '802',
        timezone: '-8',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Quad City International Airport',
        city: 'Moline',
        country: 'United States',
        iataCode: 'MLI',
        icaoCode: 'KMLI',
        latitude: '41,44850159',
        longitude: '-90,50749969',
        altitude: '590',
        timezone: '-6',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Panama City-Bay Co International Airport',
        city: 'Panama City',
        country: 'United States',
        iataCode: 'PFN',
        icaoCode: 'KPFN',
        latitude: '30,212099',
        longitude: '-85,6828',
        altitude: '20',
        timezone: '-6',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Honiara International Airport',
        city: 'Honiara',
        country: 'Solomon Islands',
        iataCode: 'HIR',
        icaoCode: 'AGGH',
        latitude: '-9,42800045',
        longitude: '160,0549927',
        altitude: '28',
        timezone: '11',
        dst: 'U',
        type: 'airport',
    },
    {
        name: "Faa'a International Airport",
        city: 'Papeete',
        country: 'French Polynesia',
        iataCode: 'PPT',
        icaoCode: 'NTAA',
        latitude: '-17,55369949',
        longitude: '-149,6069946',
        altitude: '5',
        timezone: '-10',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Nauru International Airport',
        city: 'Nauru',
        country: 'Nauru',
        iataCode: 'INU',
        icaoCode: 'ANYN',
        latitude: '-0,547458',
        longitude: '166,919006',
        altitude: '22',
        timezone: '12',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Funafuti International Airport',
        city: 'Funafuti',
        country: 'Tuvalu',
        iataCode: 'FUN',
        icaoCode: 'NGFU',
        latitude: '-8,525',
        longitude: '179,195999',
        altitude: '9',
        timezone: '12',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Tolmachevo Airport',
        city: 'Novosibirsk',
        country: 'Russia',
        iataCode: 'OVB',
        icaoCode: 'UNNT',
        latitude: '55,01259995',
        longitude: '82,65070343',
        altitude: '365',
        timezone: '7',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Samsø Airport',
        city: 'Samsoe',
        country: 'Denmark',
        iataCode: 'N',
        icaoCode: 'EKSS',
        latitude: '55,88949966',
        longitude: '10,61369991',
        altitude: '1',
        timezone: '1',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Xieng Khouang Airport',
        city: 'Phon Savan',
        country: 'Laos',
        iataCode: 'XKH',
        icaoCode: 'VLXK',
        latitude: '19,45000076',
        longitude: '103,1579971',
        altitude: '3445',
        timezone: '7',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Bismarck Municipal Airport',
        city: 'Bismarck',
        country: 'United States',
        iataCode: 'BIS',
        icaoCode: 'KBIS',
        latitude: '46,77270126',
        longitude: '-100,7460022',
        altitude: '1661',
        timezone: '-6',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Telluride Regional Airport',
        city: 'Telluride',
        country: 'United States',
        iataCode: 'TEX',
        icaoCode: 'KTEX',
        latitude: '37,9538002',
        longitude: '-107,9079971',
        altitude: '9070',
        timezone: '-7',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Yinchuan Airport',
        city: 'Yinchuan',
        country: 'China',
        iataCode: 'INC',
        icaoCode: 'ZLIC',
        latitude: '38,481899',
        longitude: '106,009003',
        altitude: '0',
        timezone: '8',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Mae Hong Son Airport',
        city: 'Mae Hong Son',
        country: 'Thailand',
        iataCode: 'HGN',
        icaoCode: 'VTCH',
        latitude: '19,30130005',
        longitude: '97,97579956',
        altitude: '929',
        timezone: '7',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Rapid City Regional Airport',
        city: 'Rapid City',
        country: 'United States',
        iataCode: 'RAP',
        icaoCode: 'KRAP',
        latitude: '44,04529953',
        longitude: '-103,0569992',
        altitude: '3204',
        timezone: '-7',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Mc Clellan-Palomar Airport',
        city: 'Carlsbad',
        country: 'United States',
        iataCode: 'CLD',
        icaoCode: 'KCRQ',
        latitude: '33,12829971',
        longitude: '-117,2799988',
        altitude: '331',
        timezone: '-8',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Bishop International Airport',
        city: 'Flint',
        country: 'United States',
        iataCode: 'FNT',
        icaoCode: 'KFNT',
        latitude: '42,9654007',
        longitude: '-83,74359894',
        altitude: '782',
        timezone: '-5',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Francisco Bangoy International Airport',
        city: 'Davao',
        country: 'Philippines',
        iataCode: 'DVO',
        icaoCode: 'RPMD',
        latitude: '7,125520229',
        longitude: '125,6460037',
        altitude: '96',
        timezone: '8',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Madeira Airport',
        city: 'Funchal',
        country: 'Portugal',
        iataCode: 'FNC',
        icaoCode: 'LPMA',
        latitude: '32,69789886',
        longitude: '-16,77449989',
        altitude: '192',
        timezone: '0',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Maestro Wilson Fonseca Airport',
        city: 'Santarem',
        country: 'Brazil',
        iataCode: 'STM',
        icaoCode: 'SBSN',
        latitude: '-2,424721956',
        longitude: '-54,78583145',
        altitude: '198',
        timezone: '-3',
        dst: 'S',
        type: 'airport',
    },
    {
        name: 'Sihanoukville International Airport',
        city: 'Sihanoukville',
        country: 'Cambodia',
        iataCode: 'KOS',
        icaoCode: 'VDSV',
        latitude: '10,57970047',
        longitude: '103,637001',
        altitude: '33',
        timezone: '7',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Ekati Airport',
        city: 'Ekati',
        country: 'Canada',
        iataCode: 'YOA',
        icaoCode: 'CYOA',
        latitude: '64,69889832',
        longitude: '-110,6149979',
        altitude: '1536',
        timezone: '-7',
        dst: 'A',
        type: 'airport',
    },
    {
        name: "Hawke's Bay Airport",
        city: 'NAPIER',
        country: 'New Zealand',
        iataCode: 'NPE',
        icaoCode: 'NZNR',
        latitude: '-39,465801',
        longitude: '176,869995',
        altitude: '6',
        timezone: '12',
        dst: 'Z',
        type: 'airport',
    },
    {
        name: 'Levuka Airfield',
        city: 'Levuka',
        country: 'Fiji',
        iataCode: 'LEV',
        icaoCode: 'NFNB',
        latitude: '-17,71109962',
        longitude: '178,7590027',
        altitude: '11',
        timezone: '12',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Lhasa Gonggar Airport',
        city: 'Lhasa',
        country: 'China',
        iataCode: 'LXA',
        icaoCode: 'ZULS',
        latitude: '29,29780006',
        longitude: '90,91190338',
        altitude: '11713',
        timezone: '8',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Redding Municipal Airport',
        city: 'Redding',
        country: 'United States',
        iataCode: 'RDD',
        icaoCode: 'KRDD',
        latitude: '40,50899887',
        longitude: '-122,2929993',
        altitude: '505',
        timezone: '-8',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Mahlon Sweet Field',
        city: 'Eugene',
        country: 'United States',
        iataCode: 'EUG',
        icaoCode: 'KEUG',
        latitude: '44,12459946',
        longitude: '-123,211998',
        altitude: '374',
        timezone: '-8',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Idaho Falls Regional Airport',
        city: 'Idaho Falls',
        country: 'United States',
        iataCode: 'IDA',
        icaoCode: 'KIDA',
        latitude: '43,51459885',
        longitude: '-112,0709991',
        altitude: '4744',
        timezone: '-7',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Rogue Valley International Medford Airport',
        city: 'Medford',
        country: 'United States',
        iataCode: 'MFR',
        icaoCode: 'KMFR',
        latitude: '42,37419891',
        longitude: '-122,8730011',
        altitude: '1335',
        timezone: '-8',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Kaikoura Airport',
        city: 'Kaikoura',
        country: 'New Zealand',
        iataCode: 'KBZ',
        icaoCode: 'NZKI',
        latitude: '-42,42499924',
        longitude: '173,6049957',
        altitude: '20',
        timezone: '12',
        dst: 'Z',
        type: 'airport',
    },
    {
        name: 'Roberts Field',
        city: 'Redmond-Bend',
        country: 'United States',
        iataCode: 'RDM',
        icaoCode: 'KRDM',
        latitude: '44,2541008',
        longitude: '-121,1500015',
        altitude: '3080',
        timezone: '-8',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Picton Aerodrome',
        city: 'Picton',
        country: 'New Zealand',
        iataCode: 'PCN',
        icaoCode: 'NZPN',
        latitude: '-41,34609985',
        longitude: '173,9559937',
        altitude: '161',
        timezone: '12',
        dst: 'Z',
        type: 'airport',
    },
    {
        name: 'Hosea Kutako International Airport',
        city: 'Windhoek',
        country: 'Namibia',
        iataCode: 'WDH',
        icaoCode: 'FYWH',
        latitude: '-22,4799',
        longitude: '17,4709',
        altitude: '5640',
        timezone: '1',
        dst: 'S',
        type: 'airport',
    },
    {
        name: 'Victoria Harbour Seaplane Base',
        city: 'Victoria',
        country: 'Canada',
        iataCode: 'YWH',
        icaoCode: 'CYWH',
        latitude: '48,42498589',
        longitude: '-123,3888674',
        altitude: '0',
        timezone: '-8',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Coal Harbour Seaplane Base',
        city: 'Vancouver',
        country: 'Canada',
        iataCode: 'N',
        icaoCode: 'CAQ3',
        latitude: '50,59999847',
        longitude: '-127,5830002',
        altitude: '0',
        timezone: '-8',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Yaoqiang Airport',
        city: 'Jinan',
        country: 'China',
        iataCode: 'TNA',
        icaoCode: 'ZSJN',
        latitude: '36,85720062',
        longitude: '117,2160034',
        altitude: '76',
        timezone: '8',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Changzhou Benniu Airport',
        city: 'Changzhou',
        country: 'China',
        iataCode: 'CZX',
        icaoCode: 'ZSCG',
        latitude: '31,919701',
        longitude: '119,778999',
        altitude: '0',
        timezone: '8',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Yibin Caiba Airport',
        city: 'Yibin',
        country: 'China',
        iataCode: 'YBP',
        icaoCode: 'ZUYB',
        latitude: '28,80055556',
        longitude: '104,545',
        altitude: '924',
        timezone: '8',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Roshchino International Airport',
        city: 'Tyumen',
        country: 'Russia',
        iataCode: 'TJM',
        icaoCode: 'USTR',
        latitude: '57,1896019',
        longitude: '65,32430267',
        altitude: '378',
        timezone: '5',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Akron Canton Regional Airport',
        city: 'Akron',
        country: 'United States',
        iataCode: 'CAK',
        icaoCode: 'KCAK',
        latitude: '40,91609955',
        longitude: '-81,44219971',
        altitude: '1228',
        timezone: '-5',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Huntsville International Carl T Jones Field',
        city: 'Huntsville',
        country: 'United States',
        iataCode: 'HSV',
        icaoCode: 'KHSV',
        latitude: '34,6371994',
        longitude: '-86,77510071',
        altitude: '629',
        timezone: '-6',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Mid Ohio Valley Regional Airport',
        city: 'PARKERSBURG',
        country: 'United States',
        iataCode: 'PKB',
        icaoCode: 'KPKB',
        latitude: '39,3451004',
        longitude: '-81,43920135',
        altitude: '858',
        timezone: '-5',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Montgomery Regional (Dannelly Field) Airport',
        city: 'MONTGOMERY',
        country: 'United States',
        iataCode: 'MGM',
        icaoCode: 'KMGM',
        latitude: '32,30059814',
        longitude: '-86,39399719',
        altitude: '221',
        timezone: '-6',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Tri Cities Regional Tn Va Airport',
        city: 'BRISTOL',
        country: 'United States',
        iataCode: 'TRI',
        icaoCode: 'KTRI',
        latitude: '36,47520065',
        longitude: '-82,40740204',
        altitude: '1519',
        timezone: '-5',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Barkley Regional Airport',
        city: 'PADUCAH',
        country: 'United States',
        iataCode: 'PAH',
        icaoCode: 'KPAH',
        latitude: '37,06079865',
        longitude: '-88,77380371',
        altitude: '410',
        timezone: '-6',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Djibouti-Ambouli Airport',
        city: 'Djibouti',
        country: 'Djibouti',
        iataCode: 'JIB',
        icaoCode: 'HDAM',
        latitude: '11,54730034',
        longitude: '43,15950012',
        altitude: '49',
        timezone: '3',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Haikou Meilan International Airport',
        city: 'Haikou',
        country: 'China',
        iataCode: 'HAK',
        icaoCode: 'ZJHK',
        latitude: '19,93490028',
        longitude: '110,4589996',
        altitude: '75',
        timezone: '8',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Mafia Island Airport',
        city: 'Mafia Island',
        country: 'Tanzania',
        iataCode: 'MFA',
        icaoCode: 'HTMA',
        latitude: '-7,917478',
        longitude: '39,668502',
        altitude: '60',
        timezone: '3',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Page Municipal Airport',
        city: 'Page',
        country: 'United States',
        iataCode: 'PGA',
        icaoCode: 'KPGA',
        latitude: '36,92610168',
        longitude: '-111,447998',
        altitude: '4316',
        timezone: '-7',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Utila Airport',
        city: 'Utila',
        country: 'Honduras',
        iataCode: 'UII',
        icaoCode: 'MHUT',
        latitude: '16,1131',
        longitude: '-86,880302',
        altitude: '29',
        timezone: '-6',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Glacier Park International Airport',
        city: 'Kalispell',
        country: 'United States',
        iataCode: 'FCA',
        icaoCode: 'KGPI',
        latitude: '48,3105011',
        longitude: '-114,2559967',
        altitude: '2977',
        timezone: '-7',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'MBS International Airport',
        city: 'Saginaw',
        country: 'United States',
        iataCode: 'MBS',
        icaoCode: 'KMBS',
        latitude: '43,53290176',
        longitude: '-84,07959747',
        altitude: '668',
        timezone: '-5',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Greater Binghamton/Edwin A Link field',
        city: 'Binghamton',
        country: 'United States',
        iataCode: 'BGM',
        icaoCode: 'KBGM',
        latitude: '42,20869827',
        longitude: '-75,97979736',
        altitude: '1636',
        timezone: '-5',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Baghdad International Airport',
        city: 'Baghdad',
        country: 'Iraq',
        iataCode: 'BGW',
        icaoCode: 'ORBI',
        latitude: '33,26250076',
        longitude: '44,23460007',
        altitude: '114',
        timezone: '3',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Nan Airport',
        city: 'Nan',
        country: 'Thailand',
        iataCode: 'NNT',
        icaoCode: 'VTCN',
        latitude: '18,80789948',
        longitude: '100,7829971',
        altitude: '685',
        timezone: '7',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Roi Et Airport',
        city: 'Roi Et',
        country: 'Thailand',
        iataCode: 'ROI',
        icaoCode: 'VTUV',
        latitude: '16,11680031',
        longitude: '103,7740021',
        altitude: '451',
        timezone: '7',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Buri Ram Airport',
        city: 'Buri Ram',
        country: 'Thailand',
        iataCode: 'BFV',
        icaoCode: 'VTUO',
        latitude: '15,22949982',
        longitude: '103,2529984',
        altitude: '590',
        timezone: '7',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Trat Airport',
        city: 'Trat',
        country: 'Thailand',
        iataCode: 'TDX',
        icaoCode: 'VTBO',
        latitude: '12,27460003',
        longitude: '102,3190002',
        altitude: '105',
        timezone: '7',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Blythe Airport',
        city: 'Blythe',
        country: 'United States',
        iataCode: 'BLH',
        icaoCode: 'KBLH',
        latitude: '33,61920166',
        longitude: '-114,7170029',
        altitude: '399',
        timezone: '-8',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Al Asad Air Base',
        city: 'Al Asad',
        country: 'Iraq',
        iataCode: 'N',
        icaoCode: 'ORAA',
        latitude: '33,78559875',
        longitude: '42,44120026',
        altitude: '618',
        timezone: '3',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Al Taqaddum Air Base',
        city: 'Al Taqaddum',
        country: 'Iraq',
        iataCode: 'N',
        icaoCode: 'ORAT',
        latitude: '33,33810043',
        longitude: '43,5970993',
        altitude: '275',
        timezone: '3',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Joint Base Balad',
        city: 'Al Bakr',
        country: 'Iraq',
        iataCode: 'N',
        icaoCode: 'ORBD',
        latitude: '33,94020081',
        longitude: '44,36159897',
        altitude: '161',
        timezone: '3',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Clark International Airport',
        city: 'Angeles City',
        country: 'Philippines',
        iataCode: 'CRK',
        icaoCode: 'RPLC',
        latitude: '15,18599987',
        longitude: '120,5599976',
        altitude: '484',
        timezone: '8',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Sandakan Airport',
        city: 'Sandakan',
        country: 'Malaysia',
        iataCode: 'SDK',
        icaoCode: 'WBKS',
        latitude: '5,900899887',
        longitude: '118,0589981',
        altitude: '46',
        timezone: '8',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Luang Namtha Airport',
        city: 'Luang Namtha',
        country: 'Laos',
        iataCode: 'LXG',
        icaoCode: 'VLLN',
        latitude: '20,96699905',
        longitude: '101,4000015',
        altitude: '1968',
        timezone: '7',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Oudomsay Airport',
        city: 'Muang Xay',
        country: 'Laos',
        iataCode: 'ODY',
        icaoCode: 'VLOS',
        latitude: '20,6826992',
        longitude: '101,9940033',
        altitude: '1804',
        timezone: '7',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Taoxian Airport',
        city: 'Shenyang',
        country: 'China',
        iataCode: 'SHE',
        icaoCode: 'ZYTX',
        latitude: '41,63980103',
        longitude: '123,4830017',
        altitude: '198',
        timezone: '8',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'John A. Osborne Airport',
        city: 'Geralds',
        country: 'Montserrat',
        iataCode: 'MNI',
        icaoCode: 'TRPG',
        latitude: '16,79140091',
        longitude: '-62,19329834',
        altitude: '550',
        timezone: '-4',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Petersburg James A Johnson Airport',
        city: 'Petersburg',
        country: 'United States',
        iataCode: 'PSG',
        icaoCode: 'PAPG',
        latitude: '56,80170059',
        longitude: '-132,9450073',
        altitude: '111',
        timezone: '-9',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Luoyang Airport',
        city: 'Luoyang',
        country: 'China',
        iataCode: 'LYA',
        icaoCode: 'ZHLY',
        latitude: '34,74110031',
        longitude: '112,3880005',
        altitude: '840',
        timezone: '8',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Xuzhou Guanyin Airport',
        city: 'Xuzhou',
        country: 'China',
        iataCode: 'XUZ',
        icaoCode: 'ZSXZ',
        latitude: '34,059056',
        longitude: '117,555278',
        altitude: '0',
        timezone: '8',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Magway Airport',
        city: 'Magwe',
        country: 'Burma',
        iataCode: 'MWQ',
        icaoCode: 'VYMW',
        latitude: '20,16559982',
        longitude: '94,94139862',
        altitude: '279',
        timezone: '6,5',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Kanti Airport',
        city: 'Khamti',
        country: 'Burma',
        iataCode: 'KHM',
        icaoCode: 'VYKI',
        latitude: '25,98830032',
        longitude: '95,67440033',
        altitude: '6000',
        timezone: '6,5',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Lien Khuong Airport',
        city: 'Dalat',
        country: 'Vietnam',
        iataCode: 'DLI',
        icaoCode: 'VVDL',
        latitude: '11,75',
        longitude: '108,3669968',
        altitude: '3156',
        timezone: '7',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Rach Gia Airport',
        city: 'Rach Gia',
        country: 'Vietnam',
        iataCode: 'VKG',
        icaoCode: 'VVRG',
        latitude: '9,958029972',
        longitude: '105,1323795',
        altitude: '7',
        timezone: '7',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Cà Mau Airport',
        city: 'Ca Mau',
        country: 'Vietnam',
        iataCode: 'CAH',
        icaoCode: 'VVCM',
        latitude: '9,177667',
        longitude: '105,177778',
        altitude: '6',
        timezone: '7',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Chu Lai International Airport',
        city: 'Chu Lai',
        country: 'Vietnam',
        iataCode: 'VCL',
        icaoCode: 'VVCA',
        latitude: '15,40330029',
        longitude: '108,7060013',
        altitude: '10',
        timezone: '7',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Dong Tac Airport',
        city: 'Tuy Hoa',
        country: 'Vietnam',
        iataCode: 'TBB',
        icaoCode: 'VVTH',
        latitude: '13,04959965',
        longitude: '109,3339996',
        altitude: '20',
        timezone: '7',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Mae Hong Son Airport',
        city: 'Pai',
        country: 'Thailand',
        iataCode: 'PYY',
        icaoCode: 'VTCI',
        latitude: '19,37199974',
        longitude: '98,43699646',
        altitude: '1271',
        timezone: '7',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Bol Airport',
        city: 'Brac',
        country: 'Croatia',
        iataCode: 'BWK',
        icaoCode: 'LDSB',
        latitude: '43,28570175',
        longitude: '16,67970085',
        altitude: '1776',
        timezone: '1',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Yaoundé Nsimalen International Airport',
        city: 'Yaounde',
        country: 'Cameroon',
        iataCode: 'NSI',
        icaoCode: 'FKYS',
        latitude: '3,722559929',
        longitude: '11,5532999',
        altitude: '2278',
        timezone: '1',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Conakry International Airport',
        city: 'Conakry',
        country: 'Guinea',
        iataCode: 'CKY',
        icaoCode: 'GUCY',
        latitude: '9,57689',
        longitude: '-13,612',
        altitude: '72',
        timezone: '0',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Aachen-Merzbrück Airport',
        city: 'Aachen',
        country: 'Germany',
        iataCode: 'AAH',
        icaoCode: 'EDKA',
        latitude: '50,82305527',
        longitude: '6,186388969',
        altitude: '623',
        timezone: '1',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Karlsruhe Baden-Baden Airport',
        city: 'Karlsruhe/Baden-Baden',
        country: 'Germany',
        iataCode: 'FKB',
        icaoCode: 'EDSB',
        latitude: '48,77939987',
        longitude: '8,080499649',
        altitude: '408',
        timezone: '1',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Orlando Sanford International Airport',
        city: 'Sanford',
        country: 'United States',
        iataCode: 'SFB',
        icaoCode: 'KSFB',
        latitude: '28,77759933',
        longitude: '-81,23750305',
        altitude: '55',
        timezone: '-5',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'John Murtha Johnstown Cambria County Airport',
        city: 'Johnstown',
        country: 'United States',
        iataCode: 'JST',
        icaoCode: 'KJST',
        latitude: '40,31610107',
        longitude: '-78,83390045',
        altitude: '2284',
        timezone: '-5',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Lukla Airport',
        city: 'Lukla',
        country: 'Nepal',
        iataCode: 'LUA',
        icaoCode: 'VNLK',
        latitude: '27,68689919',
        longitude: '86,72969818',
        altitude: '9380',
        timezone: '5,75',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Bhojpur Airport',
        city: 'Bhojpur',
        country: 'Nepal',
        iataCode: 'BHP',
        icaoCode: 'VNBJ',
        latitude: '27,1473999',
        longitude: '87,05079651',
        altitude: '4000',
        timezone: '5,75',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Lamidanda Airport',
        city: 'Lamidanda',
        country: 'Nepal',
        iataCode: 'LDN',
        icaoCode: 'VNLD',
        latitude: '27,25309944',
        longitude: '86,66999817',
        altitude: '4100',
        timezone: '5,75',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Jomsom Airport',
        city: 'Jomsom',
        country: 'Nepal',
        iataCode: 'JMO',
        icaoCode: 'VNJS',
        latitude: '28,780426',
        longitude: '83,723',
        altitude: '9000',
        timezone: '5,75',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Manang Airport',
        city: 'Manang',
        country: 'Nepal',
        iataCode: 'NGX',
        icaoCode: 'VNMA',
        latitude: '28,64139938',
        longitude: '84,08920288',
        altitude: '11001',
        timezone: '5,75',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Phaplu Airport',
        city: 'Phaplu',
        country: 'Nepal',
        iataCode: 'PPL',
        icaoCode: 'VNPL',
        latitude: '27,51778688',
        longitude: '86,58445358',
        altitude: '7918',
        timezone: '5,75',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Rumjatar Airport',
        city: 'Rumjatar',
        country: 'Nepal',
        iataCode: 'RUM',
        icaoCode: 'VNRT',
        latitude: '27,30349922',
        longitude: '86,55039978',
        altitude: '4500',
        timezone: '5,75',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Tulsipur Airport',
        city: 'Dang',
        country: 'Nepal',
        iataCode: 'DNP',
        icaoCode: 'VNDG',
        latitude: '28,11109924',
        longitude: '82,29419708',
        altitude: '2100',
        timezone: '5,75',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Rukumkot Airport',
        city: 'Rukumkot',
        country: 'Nepal',
        iataCode: 'RUK',
        icaoCode: 'VNRK',
        latitude: '28,62700081',
        longitude: '82,19499969',
        altitude: '2500',
        timezone: '5,75',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Jumla Airport',
        city: 'Jumla',
        country: 'Nepal',
        iataCode: 'JUM',
        icaoCode: 'VNJL',
        latitude: '29,27420044',
        longitude: '82,19329834',
        altitude: '7700',
        timezone: '5,75',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Taplejung Airport',
        city: 'Taplejung',
        country: 'Nepal',
        iataCode: 'TPJ',
        icaoCode: 'VNTJ',
        latitude: '27,3509',
        longitude: '87,69525',
        altitude: '7990',
        timezone: '5,75',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Tumling Tar Airport',
        city: 'Tumling Tar',
        country: 'Nepal',
        iataCode: 'TMI',
        icaoCode: 'VNTR',
        latitude: '27,31500053',
        longitude: '87,19329834',
        altitude: '1700',
        timezone: '5,75',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Surkhet Airport',
        city: 'Surkhet',
        country: 'Nepal',
        iataCode: 'SKH',
        icaoCode: 'VNSK',
        latitude: '28,58600044',
        longitude: '81,63600159',
        altitude: '2400',
        timezone: '5,75',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Simikot Airport',
        city: 'Simikot',
        country: 'Nepal',
        iataCode: 'IMK',
        icaoCode: 'VNST',
        latitude: '29,97109985',
        longitude: '81,81890106',
        altitude: '9246',
        timezone: '5,75',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Dolpa Airport',
        city: 'Dolpa',
        country: 'Nepal',
        iataCode: 'DOP',
        icaoCode: 'VNDP',
        latitude: '28,98570061',
        longitude: '82,81909943',
        altitude: '8200',
        timezone: '5,75',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Bajhang Airport',
        city: 'Bajhang',
        country: 'Nepal',
        iataCode: 'BJH',
        icaoCode: 'VNBG',
        latitude: '29,53899956',
        longitude: '81,18540192',
        altitude: '4100',
        timezone: '5,75',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Dhangarhi Airport',
        city: 'Dhangarhi',
        country: 'Nepal',
        iataCode: 'DHI',
        icaoCode: 'VNDH',
        latitude: '28,75329971',
        longitude: '80,58190155',
        altitude: '690',
        timezone: '5,75',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Muan International Airport',
        city: 'Muan',
        country: 'South Korea',
        iataCode: 'MWX',
        icaoCode: 'RKJB',
        latitude: '34,991406',
        longitude: '126,382814',
        altitude: '35',
        timezone: '9',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Astypalaia Airport',
        city: 'Astypalaia',
        country: 'Greece',
        iataCode: 'JTY',
        icaoCode: 'LGPL',
        latitude: '36,57989883',
        longitude: '26,37579918',
        altitude: '165',
        timezone: '2',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Ikaria Airport',
        city: 'Ikaria',
        country: 'Greece',
        iataCode: 'JIK',
        icaoCode: 'LGIK',
        latitude: '37,68270111',
        longitude: '26,3470993',
        altitude: '79',
        timezone: '2',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Kalymnos Airport',
        city: 'Kalymnos',
        country: 'Greece',
        iataCode: 'JKL',
        icaoCode: 'LGKY',
        latitude: '36,9632988',
        longitude: '26,94059944',
        altitude: '771',
        timezone: '2',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Milos Airport',
        city: 'Milos',
        country: 'Greece',
        iataCode: 'MLO',
        icaoCode: 'LGML',
        latitude: '36,69689941',
        longitude: '24,4769001',
        altitude: '10',
        timezone: '2',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Naxos Airport',
        city: 'Cyclades Islands',
        country: 'Greece',
        iataCode: 'JNX',
        icaoCode: 'LGNX',
        latitude: '37,08110046',
        longitude: '25,36809921',
        altitude: '10',
        timezone: '2',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Paros Airport',
        city: 'Paros',
        country: 'Greece',
        iataCode: 'PAS',
        icaoCode: 'LGPA',
        latitude: '37,020495',
        longitude: '25,113195',
        altitude: '131',
        timezone: '2',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Kastelorizo Airport',
        city: 'Kastelorizo',
        country: 'Greece',
        iataCode: 'KZS',
        icaoCode: 'LGKJ',
        latitude: '36,14170074',
        longitude: '29,57640076',
        altitude: '489',
        timezone: '2',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Marsa Alam International Airport',
        city: 'Marsa Alam',
        country: 'Egypt',
        iataCode: 'RMF',
        icaoCode: 'HEMA',
        latitude: '25,5571003',
        longitude: '34,58369827',
        altitude: '251',
        timezone: '2',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Weeze Airport',
        city: 'Weeze',
        country: 'Germany',
        iataCode: 'NRN',
        icaoCode: 'EDLV',
        latitude: '51,60240173',
        longitude: '6,142169952',
        altitude: '106',
        timezone: '1',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Francisco B. Reyes Airport',
        city: 'Busuanga',
        country: 'Philippines',
        iataCode: 'USU',
        icaoCode: 'RPVV',
        latitude: '12,12150002',
        longitude: '120,0999985',
        altitude: '148',
        timezone: '8',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Bancasi Airport',
        city: 'Butuan',
        country: 'Philippines',
        iataCode: 'BXU',
        icaoCode: 'RPME',
        latitude: '8,9515',
        longitude: '125,4788',
        altitude: '141',
        timezone: '8',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Dipolog Airport',
        city: 'Dipolog',
        country: 'Philippines',
        iataCode: 'DPL',
        icaoCode: 'RPMG',
        latitude: '8,601983499',
        longitude: '123,3418751',
        altitude: '12',
        timezone: '8',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Laoag International Airport',
        city: 'Laoag',
        country: 'Philippines',
        iataCode: 'LAO',
        icaoCode: 'RPLI',
        latitude: '18,17810059',
        longitude: '120,5319977',
        altitude: '25',
        timezone: '8',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Legazpi City International Airport',
        city: 'Legazpi',
        country: 'Philippines',
        iataCode: 'LGP',
        icaoCode: 'RPLP',
        latitude: '13,1575',
        longitude: '123,735',
        altitude: '66',
        timezone: '8',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Labo Airport',
        city: 'Ozamis',
        country: 'Philippines',
        iataCode: 'OZC',
        icaoCode: 'RPMO',
        latitude: '8,178509712',
        longitude: '123,8420029',
        altitude: '75',
        timezone: '8',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Mactan Cebu International Airport',
        city: 'Cebu',
        country: 'Philippines',
        iataCode: 'CEB',
        icaoCode: 'RPVM',
        latitude: '10,30749989',
        longitude: '123,9789963',
        altitude: '31',
        timezone: '8',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Norden-Norddeich Airport',
        city: 'Norden',
        country: 'Germany',
        iataCode: 'NOE',
        icaoCode: 'EDWS',
        latitude: '53,63305664',
        longitude: '7,190278053',
        altitude: '3',
        timezone: '1',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Juist Airport',
        city: 'Juist',
        country: 'Germany',
        iataCode: 'JUI',
        icaoCode: 'EDWJ',
        latitude: '53,68111038',
        longitude: '7,055832863',
        altitude: '7',
        timezone: '1',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Porto Seguro Airport',
        city: 'Porto Seguro',
        country: 'Brazil',
        iataCode: 'BPS',
        icaoCode: 'SBPS',
        latitude: '-16,43860054',
        longitude: '-39,08089828',
        altitude: '168',
        timezone: '-3',
        dst: 'S',
        type: 'airport',
    },
    {
        name: 'Iguatu Airport',
        city: 'Iguatu',
        country: 'Brazil',
        iataCode: 'N',
        icaoCode: 'SNIG',
        latitude: '-6,34664011',
        longitude: '-39,29380035',
        altitude: '699',
        timezone: '-3',
        dst: 'S',
        type: 'airport',
    },
    {
        name: 'Brigadeiro Lysias Rodrigues Airport',
        city: 'Palmas',
        country: 'Brazil',
        iataCode: 'PMW',
        icaoCode: 'SBPJ',
        latitude: '-10,29150009',
        longitude: '-48,35699844',
        altitude: '774',
        timezone: '-3',
        dst: 'S',
        type: 'airport',
    },
    {
        name: 'Nelson Ribeiro Guimarães Airport',
        city: 'Caldas Novas',
        country: 'Brazil',
        iataCode: 'CLV',
        icaoCode: 'SBCN',
        latitude: '-17,72529984',
        longitude: '-48,60749817',
        altitude: '2247',
        timezone: '-3',
        dst: 'S',
        type: 'airport',
    },
    {
        name: 'Missoula International Airport',
        city: 'Missoula',
        country: 'United States',
        iataCode: 'MSO',
        icaoCode: 'KMSO',
        latitude: '46,91630173',
        longitude: '-114,0910034',
        altitude: '3206',
        timezone: '-7',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Blackall Airport',
        city: 'Blackall',
        country: 'Australia',
        iataCode: 'BKQ',
        icaoCode: 'YBCK',
        latitude: '-24,42779922',
        longitude: '145,4290009',
        altitude: '928',
        timezone: '10',
        dst: 'O',
        type: 'airport',
    },
    {
        name: 'Bundaberg Airport',
        city: 'Bundaberg',
        country: 'Australia',
        iataCode: 'BDB',
        icaoCode: 'YBUD',
        latitude: '-24,90390015',
        longitude: '152,3190002',
        altitude: '107',
        timezone: '10',
        dst: 'O',
        type: 'airport',
    },
    {
        name: 'Grand Canyon National Park Airport',
        city: 'Grand Canyon',
        country: 'United States',
        iataCode: 'GCN',
        icaoCode: 'KGCN',
        latitude: '35,95240021',
        longitude: '-112,1470032',
        altitude: '6609',
        timezone: '-7',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Sugar Land Regional Airport',
        city: 'Sugar Land',
        country: 'United States',
        iataCode: 'SGR',
        icaoCode: 'KSGR',
        latitude: '29,62229919',
        longitude: '-95,65650177',
        altitude: '82',
        timezone: '-6',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Hayman Island Heliport',
        city: 'Hayman Island',
        country: 'Australia',
        iataCode: 'HIS',
        icaoCode: 'YHYN',
        latitude: '-20,0599',
        longitude: '148,8834',
        altitude: '8',
        timezone: '10',
        dst: 'O',
        type: 'airport',
    },
    {
        name: 'Centennial Airport',
        city: 'Denver',
        country: 'United States',
        iataCode: 'APA',
        icaoCode: 'KAPA',
        latitude: '39,57009888',
        longitude: '-104,848999',
        altitude: '5885',
        timezone: '-7',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Clovis Municipal Airport',
        city: 'Clovis',
        country: 'United States',
        iataCode: 'CVN',
        icaoCode: 'KCVN',
        latitude: '34,42509842',
        longitude: '-103,0790024',
        altitude: '4216',
        timezone: '-7',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Fort Stockton Pecos County Airport',
        city: 'Fort Stockton',
        country: 'United States',
        iataCode: 'FST',
        icaoCode: 'KFST',
        latitude: '30,91570091',
        longitude: '-102,9160004',
        altitude: '3011',
        timezone: '-6',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Las Vegas Municipal Airport',
        city: 'Las Vegas',
        country: 'United States',
        iataCode: 'LVS',
        icaoCode: 'KLVS',
        latitude: '35,65420151',
        longitude: '-105,1419983',
        altitude: '6877',
        timezone: '-7',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'West Houston Airport',
        city: 'Houston',
        country: 'United States',
        iataCode: 'IWS',
        icaoCode: 'KIWS',
        latitude: '29,81819916',
        longitude: '-95,67259979',
        altitude: '111',
        timezone: '-6',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'La Junta Municipal Airport',
        city: 'La Junta',
        country: 'United States',
        iataCode: 'LHX',
        icaoCode: 'KLHX',
        latitude: '38,04970169',
        longitude: '-103,5090027',
        altitude: '4229',
        timezone: '-7',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Las Cruces International Airport',
        city: 'Las Cruces',
        country: 'United States',
        iataCode: 'LRU',
        icaoCode: 'KLRU',
        latitude: '32,28939819',
        longitude: '-106,9219971',
        altitude: '4456',
        timezone: '-7',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Stephens County Airport',
        city: 'Breckenridge',
        country: 'United States',
        iataCode: 'BKD',
        icaoCode: 'KBKD',
        latitude: '32,71900177',
        longitude: '-98,89099884',
        altitude: '1284',
        timezone: '-6',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Draughon Miller Central Texas Regional Airport',
        city: 'Temple',
        country: 'United States',
        iataCode: 'TPL',
        icaoCode: 'KTPL',
        latitude: '31,15250015',
        longitude: '-97,40779877',
        altitude: '682',
        timezone: '-6',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Ozona Municipal Airport',
        city: 'Ozona',
        country: 'United States',
        iataCode: 'OZA',
        icaoCode: 'KOZA',
        latitude: '30,73530006',
        longitude: '-101,2030029',
        altitude: '2381',
        timezone: '-6',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Kaadedhdhoo Airport',
        city: 'Kaadedhdhoo',
        country: 'Maldives',
        iataCode: 'KDM',
        icaoCode: 'VRMT',
        latitude: '0,488130987',
        longitude: '72,99690247',
        altitude: '2',
        timezone: '5',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Aklavik/Freddie Carmichael Airport',
        city: 'Aklavik',
        country: 'Canada',
        iataCode: 'LAK',
        icaoCode: 'CYKD',
        latitude: '68,223297',
        longitude: '-135,00599',
        altitude: '23',
        timezone: '-7',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Déline Airport',
        city: 'Deline',
        country: 'Canada',
        iataCode: 'YWJ',
        icaoCode: 'CYWJ',
        latitude: '65,21109772',
        longitude: '-123,435997',
        altitude: '703',
        timezone: '-7',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Tulita Airport',
        city: 'Tulita',
        country: 'Canada',
        iataCode: 'ZFN',
        icaoCode: 'CZFN',
        latitude: '64,90969849',
        longitude: '-125,572998',
        altitude: '332',
        timezone: '-7',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Fort Good Hope Airport',
        city: 'Fort Good Hope',
        country: 'Canada',
        iataCode: 'YGH',
        icaoCode: 'CYGH',
        latitude: '66,24079895',
        longitude: '-128,651001',
        altitude: '268',
        timezone: '-7',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Tanna Airport',
        city: 'Tanna',
        country: 'Vanuatu',
        iataCode: 'TAH',
        icaoCode: 'NVVW',
        latitude: '-19,45509911',
        longitude: '169,223999',
        altitude: '19',
        timezone: '11',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Paulatuk (Nora Aliqatchialuk Ruben) Airport',
        city: 'Paulatuk',
        country: 'Canada',
        iataCode: 'YPC',
        icaoCode: 'CYPC',
        latitude: '69,36083812',
        longitude: '-124,07547',
        altitude: '15',
        timezone: '-7',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'El Trompillo Airport',
        city: 'Santa Cruz',
        country: 'Bolivia',
        iataCode: 'SRZ',
        icaoCode: 'SLET',
        latitude: '-17,81159973',
        longitude: '-63,17150116',
        altitude: '1371',
        timezone: '-4',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Juancho E. Yrausquin Airport',
        city: 'Saba',
        country: 'Netherlands Antilles',
        iataCode: 'SAB',
        icaoCode: 'TNCS',
        latitude: '17,64500046',
        longitude: '-63,22000122',
        altitude: '60',
        timezone: '-4',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Eagle County Regional Airport',
        city: 'Vail',
        country: 'United States',
        iataCode: 'EGE',
        icaoCode: 'KEGE',
        latitude: '39,64260101',
        longitude: '-106,9179993',
        altitude: '6548',
        timezone: '-7',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Stokmarknes Skagen Airport',
        city: 'Stokmarknes',
        country: 'Norway',
        iataCode: 'SKN',
        icaoCode: 'ENSK',
        latitude: '68,5788269',
        longitude: '15,03341675',
        altitude: '11',
        timezone: '1',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Cuyahoga County Airport',
        city: 'Richmond Heights',
        country: 'United States',
        iataCode: 'CGF',
        icaoCode: 'KCGF',
        latitude: '41,56510162',
        longitude: '-81,48639679',
        altitude: '879',
        timezone: '-5',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Mansfield Lahm Regional Airport',
        city: 'Mansfield',
        country: 'United States',
        iataCode: 'MFD',
        icaoCode: 'KMFD',
        latitude: '40,82139969',
        longitude: '-82,51660156',
        altitude: '1297',
        timezone: '-5',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Columbus Metropolitan Airport',
        city: 'Columbus',
        country: 'United States',
        iataCode: 'CSG',
        icaoCode: 'KCSG',
        latitude: '32,5163002',
        longitude: '-84,93890381',
        altitude: '397',
        timezone: '-5',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Lawton Fort Sill Regional Airport',
        city: 'Lawton',
        country: 'United States',
        iataCode: 'LAW',
        icaoCode: 'KLAW',
        latitude: '34,56769943',
        longitude: '-98,41660309',
        altitude: '1110',
        timezone: '-6',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Fort Collins Loveland Municipal Airport',
        city: 'Fort Collins',
        country: 'United States',
        iataCode: 'FNL',
        icaoCode: 'KFNL',
        latitude: '40,4518013',
        longitude: '-105,0110016',
        altitude: '5016',
        timezone: '-7',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Flagstaff Pulliam Airport',
        city: 'Flagstaff',
        country: 'United States',
        iataCode: 'FLG',
        icaoCode: 'KFLG',
        latitude: '35,13850021',
        longitude: '-111,6709976',
        altitude: '7014',
        timezone: '-7',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Lake Tahoe Airport',
        city: 'South Lake Tahoe',
        country: 'United States',
        iataCode: 'TVL',
        icaoCode: 'KTVL',
        latitude: '38,89390182',
        longitude: '-119,9950027',
        altitude: '6264',
        timezone: '-8',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Joslin Field Magic Valley Regional Airport',
        city: 'Twin Falls',
        country: 'United States',
        iataCode: 'TWF',
        icaoCode: 'KTWF',
        latitude: '42,48180008',
        longitude: '-114,487999',
        altitude: '4154',
        timezone: '-7',
        dst: 'A',
        type: 'airport',
    },
    {
        name: "Martha's Vineyard Airport",
        city: 'Vineyard Haven MA',
        country: 'United States',
        iataCode: 'MVY',
        icaoCode: 'KMVY',
        latitude: '41,39310074',
        longitude: '-70,61430359',
        altitude: '67',
        timezone: '-5',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Concord Municipal Airport',
        city: 'Concord NH',
        country: 'United States',
        iataCode: 'CON',
        icaoCode: 'KCON',
        latitude: '43,20270157',
        longitude: '-71,50229645',
        altitude: '342',
        timezone: '-5',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Groton New London Airport',
        city: 'Groton CT',
        country: 'United States',
        iataCode: 'GON',
        icaoCode: 'KGON',
        latitude: '41,33010101',
        longitude: '-72,04509735',
        altitude: '9',
        timezone: '-5',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'St Cloud Regional Airport',
        city: 'Saint Cloud',
        country: 'United States',
        iataCode: 'STC',
        icaoCode: 'KSTC',
        latitude: '45,54660034',
        longitude: '-94,05989838',
        altitude: '1031',
        timezone: '-6',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Qinhuangdao Beidaihe Airport',
        city: 'Bagan',
        country: 'Burma',
        iataCode: 'BPE',
        icaoCode: 'ZBDH',
        latitude: '39,666389',
        longitude: '119,058889',
        altitude: '46',
        timezone: '8',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Golden Triangle Regional Airport',
        city: 'Columbus Mississippi',
        country: 'United States',
        iataCode: 'GTR',
        icaoCode: 'KGTR',
        latitude: '33,45029831',
        longitude: '-88,59140015',
        altitude: '264',
        timezone: '-6',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Nizhny Novgorod Strigino International Airport',
        city: 'Nizhniy Novgorod',
        country: 'Russia',
        iataCode: 'GOJ',
        icaoCode: 'UWGG',
        latitude: '56,23009872',
        longitude: '43,7840004',
        altitude: '256',
        timezone: '3',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Bowerman Airport',
        city: 'Hoquiam',
        country: 'United States',
        iataCode: 'HQM',
        icaoCode: 'KHQM',
        latitude: '46,97119904',
        longitude: '-123,9369965',
        altitude: '18',
        timezone: '-8',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Erie International Tom Ridge Field',
        city: 'Erie',
        country: 'United States',
        iataCode: 'ERI',
        icaoCode: 'KERI',
        latitude: '42,08312701',
        longitude: '-80,17386675',
        altitude: '732',
        timezone: '-5',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Barnstable Municipal Boardman Polando Field',
        city: 'Barnstable',
        country: 'United States',
        iataCode: 'HYA',
        icaoCode: 'KHYA',
        latitude: '41,66930008',
        longitude: '-70,28040314',
        altitude: '54',
        timezone: '-5',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Sedona Airport',
        city: 'Sedona',
        country: 'United States',
        iataCode: 'SDX',
        icaoCode: 'KSEZ',
        latitude: '34,84859848',
        longitude: '-111,788002',
        altitude: '4830',
        timezone: '-7',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Morgantown Municipal Walter L. Bill Hart Field',
        city: 'Morgantown',
        country: 'United States',
        iataCode: 'MGW',
        icaoCode: 'KMGW',
        latitude: '39,64289856',
        longitude: '-79,91629791',
        altitude: '1248',
        timezone: '-5',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Yeager Airport',
        city: 'Charleston',
        country: 'United States',
        iataCode: 'CRW',
        icaoCode: 'KCRW',
        latitude: '38,37310028',
        longitude: '-81,59320068',
        altitude: '981',
        timezone: '-5',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Wilkes Barre Scranton International Airport',
        city: 'Scranton',
        country: 'United States',
        iataCode: 'AVP',
        icaoCode: 'KAVP',
        latitude: '41,33850098',
        longitude: '-75,7233963',
        altitude: '962',
        timezone: '-5',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Bemidji Regional Airport',
        city: 'Bemidji',
        country: 'United States',
        iataCode: 'BJI',
        icaoCode: 'KBJI',
        latitude: '47,50939941',
        longitude: '-94,93370056',
        altitude: '1391',
        timezone: '-6',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Thangool Airport',
        city: 'Biloela',
        country: 'Australia',
        iataCode: 'THG',
        icaoCode: 'YTNG',
        latitude: '-24,4939003',
        longitude: '150,576004',
        altitude: '644',
        timezone: '10',
        dst: 'O',
        type: 'airport',
    },
    {
        name: "Fagali'i Airport",
        city: 'Apia',
        country: 'Samoa',
        iataCode: 'FGI',
        icaoCode: 'NSFI',
        latitude: '-13,84869957',
        longitude: '-171,7400055',
        altitude: '131',
        timezone: '13',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Ballina Byron Gateway Airport',
        city: 'Ballina Byron Bay',
        country: 'Australia',
        iataCode: 'BNK',
        icaoCode: 'YBNA',
        latitude: '-28,83390045',
        longitude: '153,5619965',
        altitude: '7',
        timezone: '10',
        dst: 'O',
        type: 'airport',
    },
    {
        name: 'Hector International Airport',
        city: 'Fargo',
        country: 'United States',
        iataCode: 'FAR',
        icaoCode: 'KFAR',
        latitude: '46,92070007',
        longitude: '-96,81580353',
        altitude: '902',
        timezone: '-6',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Charles B. Wheeler Downtown Airport',
        city: 'Kansas City',
        country: 'United States',
        iataCode: 'MKC',
        icaoCode: 'KMKC',
        latitude: '39,12319946',
        longitude: '-94,59279633',
        altitude: '759',
        timezone: '-6',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Ratanakiri Airport',
        city: 'Ratanakiri',
        country: 'Cambodia',
        iataCode: 'RBE',
        icaoCode: 'VDRK',
        latitude: '13,72999954',
        longitude: '106,9869995',
        altitude: '0',
        timezone: '7',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Gillette Campbell County Airport',
        city: 'Gillette',
        country: 'United States',
        iataCode: 'GCC',
        icaoCode: 'KGCC',
        latitude: '44,34889984',
        longitude: '-105,5390015',
        altitude: '4365',
        timezone: '-7',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Bogashevo Airport',
        city: 'Tomsk',
        country: 'Russia',
        iataCode: 'TOF',
        icaoCode: 'UNTT',
        latitude: '56,38029861',
        longitude: '85,20829773',
        altitude: '597',
        timezone: '7',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Phetchabun Airport',
        city: 'Phetchabun',
        country: 'Thailand',
        iataCode: 'PHY',
        icaoCode: 'VTPB',
        latitude: '16,6760006',
        longitude: '101,1949997',
        altitude: '450',
        timezone: '7',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Chumphon Airport',
        city: 'Chumphon',
        country: 'Thailand',
        iataCode: 'CJM',
        icaoCode: 'VTSE',
        latitude: '10,71119976',
        longitude: '99,36170197',
        altitude: '18',
        timezone: '7',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Jiuzhai Huanglong Airport',
        city: 'Jiuzhaigou',
        country: 'China',
        iataCode: 'JZH',
        icaoCode: 'ZUJZ',
        latitude: '32,85333333',
        longitude: '103,6822222',
        altitude: '11327',
        timezone: '8',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Jieyang Chaoshan International Airport',
        city: 'Shantou',
        country: 'China',
        iataCode: 'SWA',
        icaoCode: 'ZGOW',
        latitude: '23,552',
        longitude: '116,5033',
        altitude: '0',
        timezone: '8',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Enghien Moisselles Airport',
        city: 'Enghien-moisselles',
        country: 'France',
        iataCode: 'N',
        icaoCode: 'LFFE',
        latitude: '49,04639816',
        longitude: '2,353060007',
        altitude: '335',
        timezone: '1',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Cheddi Jagan International Airport',
        city: 'Georgetown',
        country: 'Guyana',
        iataCode: 'GEO',
        icaoCode: 'SYCJ',
        latitude: '6,498549938',
        longitude: '-58,2541008',
        altitude: '95',
        timezone: '-4',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Guarani International Airport',
        city: 'Ciudad del Este',
        country: 'Paraguay',
        iataCode: 'AGT',
        icaoCode: 'SGES',
        latitude: '-25,454516',
        longitude: '-54,842682',
        altitude: '846',
        timezone: '-4',
        dst: 'S',
        type: 'airport',
    },
    {
        name: 'Ogle Airport',
        city: 'Georgetown',
        country: 'Guyana',
        iataCode: 'OGL',
        icaoCode: 'SYGO',
        latitude: '6,806280136',
        longitude: '-58,10589981',
        altitude: '10',
        timezone: '-4',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Kaieteur International Airport',
        city: 'Kaieteur',
        country: 'Guyana',
        iataCode: 'KAI',
        icaoCode: 'PKSA',
        latitude: '5,172754765',
        longitude: '-59,49148178',
        altitude: '1520',
        timezone: '-4',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Dunhuang Airport',
        city: 'Dunhuang',
        country: 'China',
        iataCode: 'DNH',
        icaoCode: 'ZLDH',
        latitude: '40,16109848',
        longitude: '94,80919647',
        altitude: '0',
        timezone: '8',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Ancona Falconara Airport',
        city: 'Ancona',
        country: 'Italy',
        iataCode: 'AOI',
        icaoCode: 'LIPY',
        latitude: '43,616299',
        longitude: '13,3623',
        altitude: '49',
        timezone: '1',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Chamonate Airport',
        city: 'Copiapo',
        country: 'Chile',
        iataCode: 'CPO',
        icaoCode: 'SCHA',
        latitude: '-27,2968998',
        longitude: '-70,4131012',
        altitude: '984',
        timezone: '-4',
        dst: 'S',
        type: 'airport',
    },
    {
        name: 'Taba International Airport',
        city: 'Taba',
        country: 'Egypt',
        iataCode: 'TCP',
        icaoCode: 'HETB',
        latitude: '29,58779907',
        longitude: '34,77809906',
        altitude: '2415',
        timezone: '2',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Edward Bodden Airfield',
        city: 'Little Cayman',
        country: 'Cayman Islands',
        iataCode: 'LYB',
        icaoCode: 'MWCL',
        latitude: '19,66699982',
        longitude: '-80,09999847',
        altitude: '3',
        timezone: '-5',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Milas Bodrum International Airport',
        city: 'Bodrum',
        country: 'Turkey',
        iataCode: 'BJV',
        icaoCode: 'LTFE',
        latitude: '37,25059891',
        longitude: '27,66430092',
        altitude: '21',
        timezone: '3',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Tabarka 7 Novembre Airport',
        city: 'Tabarka',
        country: 'Tunisia',
        iataCode: 'TBJ',
        icaoCode: 'DTKA',
        latitude: '36,97999954',
        longitude: '8,876939774',
        altitude: '230',
        timezone: '1',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Sabiha Gökçen International Airport',
        city: 'Istanbul',
        country: 'Turkey',
        iataCode: 'SAW',
        icaoCode: 'LTFJ',
        latitude: '40,89860153',
        longitude: '29,30920029',
        altitude: '312',
        timezone: '3',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'University Park Airport',
        city: 'State College Pennsylvania',
        country: 'United States',
        iataCode: 'SCE',
        icaoCode: 'KUNV',
        latitude: '40,84930038',
        longitude: '-77,84870148',
        altitude: '1239',
        timezone: '-5',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Broome International Airport',
        city: 'Broome',
        country: 'Australia',
        iataCode: 'BME',
        icaoCode: 'YBRM',
        latitude: '-17,94470024',
        longitude: '122,2320023',
        altitude: '56',
        timezone: '8',
        dst: 'O',
        type: 'airport',
    },
    {
        name: 'Newcastle Airport',
        city: 'Newcastle',
        country: 'Australia',
        iataCode: 'NTL',
        icaoCode: 'YWLM',
        latitude: '-32,79499817',
        longitude: '151,8339996',
        altitude: '31',
        timezone: '10',
        dst: 'O',
        type: 'airport',
    },
    {
        name: 'Bakki Airport',
        city: 'Bakki',
        country: 'Iceland',
        iataCode: 'N',
        icaoCode: 'BIBA',
        latitude: '63,55609894',
        longitude: '-20,13750076',
        altitude: '45',
        timezone: '0',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Klagenfurt Airport',
        city: 'Klagenfurt',
        country: 'Austria',
        iataCode: 'KLU',
        icaoCode: 'LOWK',
        latitude: '46,642502',
        longitude: '14,3377',
        altitude: '1472',
        timezone: '1',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Hammerfest Airport',
        city: 'Hammerfest',
        country: 'Norway',
        iataCode: 'HFT',
        icaoCode: 'ENHF',
        latitude: '70,67970276',
        longitude: '23,66860008',
        altitude: '266',
        timezone: '1',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Valan Airport',
        city: 'Honningsvag',
        country: 'Norway',
        iataCode: 'HVG',
        icaoCode: 'ENHV',
        latitude: '71,00969696',
        longitude: '25,98360062',
        altitude: '44',
        timezone: '1',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Mehamn Airport',
        city: 'Mehamn',
        country: 'Norway',
        iataCode: 'MEH',
        icaoCode: 'ENMH',
        latitude: '71,02970123',
        longitude: '27,82670021',
        altitude: '39',
        timezone: '1',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Vadsø Airport',
        city: 'Vadsø',
        country: 'Norway',
        iataCode: 'VDS',
        icaoCode: 'ENVD',
        latitude: '70,06529999',
        longitude: '29,84469986',
        altitude: '127',
        timezone: '1',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Imam Khomeini International Airport',
        city: 'Tehran',
        country: 'Iran',
        iataCode: 'IKA',
        icaoCode: 'OIIE',
        latitude: '35,41609955',
        longitude: '51,15219879',
        altitude: '3305',
        timezone: '3,5',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Mashhad International Airport',
        city: 'Mashhad',
        country: 'Iran',
        iataCode: 'MHD',
        icaoCode: 'OIMM',
        latitude: '36,23519897',
        longitude: '59,64099884',
        altitude: '3263',
        timezone: '3,5',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Ust-Ilimsk Airport',
        city: 'Ust Ilimsk',
        country: 'Russia',
        iataCode: 'UIK',
        icaoCode: 'UIBS',
        latitude: '58,13610077',
        longitude: '102,5650024',
        altitude: '1339',
        timezone: '8',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Key Field',
        city: 'Meridian',
        country: 'United States',
        iataCode: 'MEI',
        icaoCode: 'KMEI',
        latitude: '32,33259964',
        longitude: '-88,75189972',
        altitude: '297',
        timezone: '-6',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Abraham Lincoln Capital Airport',
        city: 'Springfield',
        country: 'United States',
        iataCode: 'SPI',
        icaoCode: 'KSPI',
        latitude: '39,84410095',
        longitude: '-89,67790222',
        altitude: '598',
        timezone: '-6',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Cortez Municipal Airport',
        city: 'Cortez',
        country: 'United States',
        iataCode: 'CEZ',
        icaoCode: 'KCEZ',
        latitude: '37,3030014',
        longitude: '-108,6279984',
        altitude: '5918',
        timezone: '-7',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Yampa Valley Airport',
        city: 'Hayden',
        country: 'United States',
        iataCode: 'HDN',
        icaoCode: 'KHDN',
        latitude: '40,48120117',
        longitude: '-107,2180023',
        altitude: '6606',
        timezone: '-7',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Gallup Municipal Airport',
        city: 'Gallup',
        country: 'United States',
        iataCode: 'GUP',
        icaoCode: 'KGUP',
        latitude: '35,51110077',
        longitude: '-108,7890015',
        altitude: '6472',
        timezone: '-7',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Liberal Mid-America Regional Airport',
        city: 'Liberal',
        country: 'United States',
        iataCode: 'LBL',
        icaoCode: 'KLBL',
        latitude: '37,0442009',
        longitude: '-100,9599991',
        altitude: '2885',
        timezone: '-6',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Lamar Municipal Airport',
        city: 'Lamar',
        country: 'United States',
        iataCode: 'LAA',
        icaoCode: 'KLAA',
        latitude: '38,06969833',
        longitude: '-102,6880035',
        altitude: '3706',
        timezone: '-7',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Renner Field-Goodland Municipal Airport',
        city: 'Goodland',
        country: 'United States',
        iataCode: 'GLD',
        icaoCode: 'KGLD',
        latitude: '39,37060165',
        longitude: '-101,6989975',
        altitude: '3656',
        timezone: '-7',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Yellowstone Regional Airport',
        city: 'Cody',
        country: 'United States',
        iataCode: 'COD',
        icaoCode: 'KCOD',
        latitude: '44,52019882',
        longitude: '-109,0240021',
        altitude: '5102',
        timezone: '-7',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Ørsta-Volda Airport, Hovden',
        city: 'Orsta-Volda',
        country: 'Norway',
        iataCode: 'HOV',
        icaoCode: 'ENOV',
        latitude: '62,18000031',
        longitude: '6,074100018',
        altitude: '243',
        timezone: '1',
        dst: 'E',
        type: 'airport',
    },
    {
        name: "St. Mary's Airport",
        city: "ST MARY\\'S",
        country: 'United Kingdom',
        iataCode: 'ISC',
        icaoCode: 'EGHE',
        latitude: '49,91329956',
        longitude: '-6,291669846',
        altitude: '116',
        timezone: '0',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Springfield Branson National Airport',
        city: 'Springfield',
        country: 'United States',
        iataCode: 'SGF',
        icaoCode: 'KSGF',
        latitude: '37,24570084',
        longitude: '-93,38860321',
        altitude: '1268',
        timezone: '-6',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Narvik Framnes Airport',
        city: 'Narvik',
        country: 'Norway',
        iataCode: 'NVK',
        icaoCode: 'ENNK',
        latitude: '68,43689728',
        longitude: '17,38669968',
        altitude: '95',
        timezone: '1',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Berlevåg Airport',
        city: 'Berlevag',
        country: 'Norway',
        iataCode: 'BVG',
        icaoCode: 'ENBV',
        latitude: '70,87139893',
        longitude: '29,03420067',
        altitude: '42',
        timezone: '1',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Oslo, Fornebu Airport',
        city: 'Oslo',
        country: 'Norway',
        iataCode: 'FBU',
        icaoCode: 'ENFB',
        latitude: '59,89580154',
        longitude: '10,6171999',
        altitude: '0',
        timezone: '1',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Norilsk-Alykel Airport',
        city: 'Norilsk',
        country: 'Russia',
        iataCode: 'NSK',
        icaoCode: 'UOOO',
        latitude: '69,31109619',
        longitude: '87,3321991',
        altitude: '574',
        timezone: '7',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Anapa Vityazevo Airport',
        city: 'Anapa',
        country: 'Russia',
        iataCode: 'AAQ',
        icaoCode: 'URKA',
        latitude: '45,0021019',
        longitude: '37,34730148',
        altitude: '174',
        timezone: '3',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Joplin Regional Airport',
        city: 'Joplin',
        country: 'United States',
        iataCode: 'JLN',
        icaoCode: 'KJLN',
        latitude: '37,15179825',
        longitude: '-94,49829865',
        altitude: '981',
        timezone: '-6',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Lehigh Valley International Airport',
        city: 'Allentown',
        country: 'United States',
        iataCode: 'ABE',
        icaoCode: 'KABE',
        latitude: '40,65209961',
        longitude: '-75,44080353',
        altitude: '393',
        timezone: '-5',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Northwest Arkansas Regional Airport',
        city: 'Bentonville',
        country: 'United States',
        iataCode: 'XNA',
        icaoCode: 'KXNA',
        latitude: '36,281898',
        longitude: '-94,306801',
        altitude: '1287',
        timezone: '-6',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Atyrau Airport',
        city: 'Atyrau',
        country: 'Kazakhstan',
        iataCode: 'GUW',
        icaoCode: 'UATG',
        latitude: '47,12189865',
        longitude: '51,82139969',
        altitude: '-72',
        timezone: '5',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Kzyl-Orda Southwest Airport',
        city: 'Kzyl-Orda',
        country: 'Kazakhstan',
        iataCode: 'KZO',
        icaoCode: 'UAOO',
        latitude: '44,70690155',
        longitude: '65,59249878',
        altitude: '433',
        timezone: '6',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'South Bend Regional Airport',
        city: 'South Bend',
        country: 'United States',
        iataCode: 'SBN',
        icaoCode: 'KSBN',
        latitude: '41,70869827',
        longitude: '-86,31729889',
        altitude: '799',
        timezone: '-5',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Bykovo Airport',
        city: 'Moscow',
        country: 'Russia',
        iataCode: 'BKA',
        icaoCode: 'UUBB',
        latitude: '55,61719894',
        longitude: '38,06000137',
        altitude: '427',
        timezone: '3',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Talagi Airport',
        city: 'Arkhangelsk',
        country: 'Russia',
        iataCode: 'ARH',
        icaoCode: 'ULAA',
        latitude: '64,60030365',
        longitude: '40,71670151',
        altitude: '62',
        timezone: '3',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Saratov Central Airport',
        city: 'Saratov',
        country: 'Russia',
        iataCode: 'RTW',
        icaoCode: 'UWSS',
        latitude: '51,56499863',
        longitude: '46,04669952',
        altitude: '499',
        timezone: '3',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Novy Urengoy Airport',
        city: 'Novy Urengoy',
        country: 'Russia',
        iataCode: 'NUX',
        icaoCode: 'USMU',
        latitude: '66,06939697',
        longitude: '76,52030182',
        altitude: '210',
        timezone: '5',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Noyabrsk Airport',
        city: 'Noyabrsk',
        country: 'Russia',
        iataCode: 'NOJ',
        icaoCode: 'USRO',
        latitude: '63,18330002',
        longitude: '75,26999664',
        altitude: '446',
        timezone: '5',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Aktau Airport',
        city: 'Aktau',
        country: 'Kazakhstan',
        iataCode: 'SCO',
        icaoCode: 'UATE',
        latitude: '43,86009979',
        longitude: '51,09199905',
        altitude: '73',
        timezone: '5',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Ukhta Airport',
        city: 'Ukhta',
        country: 'Russia',
        iataCode: 'UCT',
        icaoCode: 'UUYH',
        latitude: '63,56689835',
        longitude: '53,80469894',
        altitude: '482',
        timezone: '3',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Usinsk Airport',
        city: 'Usinsk',
        country: 'Russia',
        iataCode: 'USK',
        icaoCode: 'UUYS',
        latitude: '66,00469971',
        longitude: '57,36719894',
        altitude: '262',
        timezone: '3',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Pechora Airport',
        city: 'Pechora',
        country: 'Russia',
        iataCode: 'PEX',
        icaoCode: 'UUYP',
        latitude: '65,12110138',
        longitude: '57,13079834',
        altitude: '98',
        timezone: '3',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Naryan Mar Airport',
        city: 'Naryan-Mar',
        country: 'Russia',
        iataCode: 'NNM',
        icaoCode: 'ULAM',
        latitude: '67,63999939',
        longitude: '53,12189865',
        altitude: '36',
        timezone: '3',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Pskov Airport',
        city: 'Pskov',
        country: 'Russia',
        iataCode: 'PKV',
        icaoCode: 'ULOO',
        latitude: '57,78390121',
        longitude: '28,39559937',
        altitude: '154',
        timezone: '3',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Kogalym International Airport',
        city: 'Kogalym',
        country: 'Russia',
        iataCode: 'KGP',
        icaoCode: 'USRK',
        latitude: '62,19039917',
        longitude: '74,53379822',
        altitude: '220',
        timezone: '5',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Yemelyanovo Airport',
        city: 'Krasnoyarsk',
        country: 'Russia',
        iataCode: 'KJA',
        icaoCode: 'UNKL',
        latitude: '56,17290115',
        longitude: '92,49330139',
        altitude: '942',
        timezone: '7',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Sary-Arka Airport',
        city: 'Karaganda',
        country: 'Kazakhstan',
        iataCode: 'KGF',
        icaoCode: 'UAKK',
        latitude: '49,67079926',
        longitude: '73,33439636',
        altitude: '1765',
        timezone: '6',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Novosibirsk North Airport',
        city: 'Novosibirsk',
        country: 'Russia',
        iataCode: 'N',
        icaoCode: 'UNCC',
        latitude: '55,09170151',
        longitude: '82,90670013',
        altitude: '558',
        timezone: '7',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Uray Airport',
        city: 'Uraj',
        country: 'Russia',
        iataCode: 'URJ',
        icaoCode: 'USHU',
        latitude: '60,10329819',
        longitude: '64,8266983',
        altitude: '190',
        timezone: '5',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Ivanovo South Airport',
        city: 'Ivanovo',
        country: 'Russia',
        iataCode: 'IWA',
        icaoCode: 'UUBI',
        latitude: '56,93939972',
        longitude: '40,94079971',
        altitude: '410',
        timezone: '3',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Longjia Airport',
        city: 'Changchun',
        country: 'China',
        iataCode: 'CGQ',
        icaoCode: 'ZYCC',
        latitude: '43,99620056',
        longitude: '125,6849976',
        altitude: '706',
        timezone: '8',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Niigata Airport',
        city: 'Niigata',
        country: 'Japan',
        iataCode: 'KIJ',
        icaoCode: 'RJSN',
        latitude: '37,95589828',
        longitude: '139,1210022',
        altitude: '29',
        timezone: '9',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Johnston Atoll Airport',
        city: 'Johnston Island',
        country: 'Johnston Atoll',
        iataCode: 'JON',
        icaoCode: 'PJON',
        latitude: '16,72859955',
        longitude: '-169,5339966',
        altitude: '7',
        timezone: '-10',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Smith Field',
        city: 'Fort Wayne IN',
        country: 'United States',
        iataCode: 'SMD',
        icaoCode: 'KSMD',
        latitude: '41,14339828',
        longitude: '-85,15280151',
        altitude: '835',
        timezone: '-5',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Arcata Airport',
        city: 'Arcata CA',
        country: 'United States',
        iataCode: 'ACV',
        icaoCode: 'KACV',
        latitude: '40,97809982',
        longitude: '-124,1090012',
        altitude: '221',
        timezone: '-8',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Albert J Ellis Airport',
        city: 'Jacksonville NC',
        country: 'United States',
        iataCode: 'OAJ',
        icaoCode: 'KOAJ',
        latitude: '34,82920074',
        longitude: '-77,61209869',
        altitude: '94',
        timezone: '-5',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Tuscaloosa Regional Airport',
        city: 'Tuscaloosa AL',
        country: 'United States',
        iataCode: 'TCL',
        icaoCode: 'KTCL',
        latitude: '33,22060013',
        longitude: '-87,61139679',
        altitude: '170',
        timezone: '-6',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Dubuque Regional Airport',
        city: 'Dubuque IA',
        country: 'United States',
        iataCode: 'DBQ',
        icaoCode: 'KDBQ',
        latitude: '42,40200043',
        longitude: '-90,70950317',
        altitude: '1077',
        timezone: '-6',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Shun Tak Heliport',
        city: 'Hong Kong',
        country: 'Hong Kong',
        iataCode: 'N',
        icaoCode: 'VHST',
        latitude: '22,28937149',
        longitude: '114,152153',
        altitude: '107',
        timezone: '8',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Uru Harbour Airport',
        city: 'Atoifi',
        country: 'Solomon Islands',
        iataCode: 'ATD',
        icaoCode: 'AGAT',
        latitude: '-8,87333',
        longitude: '161,011002',
        altitude: '0',
        timezone: '11',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Auki Airport',
        city: 'Auki',
        country: 'Solomon Islands',
        iataCode: 'AKS',
        icaoCode: 'AGGA',
        latitude: '-8,702569962',
        longitude: '160,6820068',
        altitude: '5',
        timezone: '11',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Ballalae Airport',
        city: 'Ballalae',
        country: 'Solomon Islands',
        iataCode: 'BAS',
        icaoCode: 'AGGE',
        latitude: '-6,990745',
        longitude: '155,886656',
        altitude: '5',
        timezone: '11',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Fera/Maringe Airport',
        city: 'Fera Island',
        country: 'Solomon Islands',
        iataCode: 'FRE',
        icaoCode: 'AGGF',
        latitude: '-8,1075',
        longitude: '159,576996',
        altitude: '0',
        timezone: '11',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Babanakira Airport',
        city: 'Mbambanakira',
        country: 'Solomon Islands',
        iataCode: 'MBU',
        icaoCode: 'AGGI',
        latitude: '-9,74750042',
        longitude: '159,8390045',
        altitude: '0',
        timezone: '11',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Ngorangora Airport',
        city: 'Kirakira',
        country: 'Solomon Islands',
        iataCode: 'IRA',
        icaoCode: 'AGGK',
        latitude: '-10,44970036',
        longitude: '161,897995',
        altitude: '54',
        timezone: '11',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Santa Cruz/Graciosa Bay/Luova Airport',
        city: 'Santa Cruz/Graciosa Bay/Luova',
        country: 'Solomon Islands',
        iataCode: 'SCZ',
        icaoCode: 'AGGL',
        latitude: '-10,72029972',
        longitude: '165,7949982',
        altitude: '18',
        timezone: '11',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Munda Airport',
        city: 'Munda',
        country: 'Solomon Islands',
        iataCode: 'MUA',
        icaoCode: 'AGGM',
        latitude: '-8,327969551',
        longitude: '157,2630005',
        altitude: '10',
        timezone: '11',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Nusatupe Airport',
        city: 'Gizo',
        country: 'Solomon Islands',
        iataCode: 'GZO',
        icaoCode: 'AGGN',
        latitude: '-8,097780228',
        longitude: '156,8639984',
        altitude: '13',
        timezone: '11',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Mono Airport',
        city: 'Stirling Island',
        country: 'Solomon Islands',
        iataCode: 'MNY',
        icaoCode: 'AGGO',
        latitude: '-7,416940212',
        longitude: '155,5650024',
        altitude: '0',
        timezone: '11',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Rennell/Tingoa Airport',
        city: 'Rennell Island',
        country: 'Solomon Islands',
        iataCode: 'RNL',
        icaoCode: 'AGGR',
        latitude: '-11,53390026',
        longitude: '160,0630035',
        altitude: '0',
        timezone: '11',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Marau Airport',
        city: 'Marau',
        country: 'Solomon Islands',
        iataCode: 'RUS',
        icaoCode: 'AGGU',
        latitude: '-9,86166954',
        longitude: '160,8249969',
        altitude: '0',
        timezone: '11',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Suavanao Airport',
        city: 'Suavanao',
        country: 'Solomon Islands',
        iataCode: 'VAO',
        icaoCode: 'AGGV',
        latitude: '-7,585559845',
        longitude: '158,7310028',
        altitude: '0',
        timezone: '11',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Kaghau Airport',
        city: 'Kagau Island',
        country: 'Solomon Islands',
        iataCode: 'KGE',
        icaoCode: 'AGKG',
        latitude: '-7,3305',
        longitude: '157,585',
        altitude: '30',
        timezone: '11',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Ramata Airport',
        city: 'Ramata',
        country: 'Solomon Islands',
        iataCode: 'RBV',
        icaoCode: 'AGRM',
        latitude: '-8,168060303',
        longitude: '157,6430054',
        altitude: '0',
        timezone: '11',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Buka Airport',
        city: 'Buka Island',
        country: 'Papua New Guinea',
        iataCode: 'BUA',
        icaoCode: 'AYBK',
        latitude: '-5,422319889',
        longitude: '154,6730042',
        altitude: '11',
        timezone: '10',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Chimbu Airport',
        city: 'Kundiawa',
        country: 'Papua New Guinea',
        iataCode: 'CMU',
        icaoCode: 'AYCH',
        latitude: '-6,024290085',
        longitude: '144,970993',
        altitude: '4974',
        timezone: '10',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Daru Airport',
        city: 'Daru',
        country: 'Papua New Guinea',
        iataCode: 'DAU',
        icaoCode: 'AYDU',
        latitude: '-9,086759567',
        longitude: '143,2079926',
        altitude: '20',
        timezone: '10',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Gurney Airport',
        city: 'Gurney',
        country: 'Papua New Guinea',
        iataCode: 'GUR',
        icaoCode: 'AYGN',
        latitude: '-10,3114996',
        longitude: '150,3339996',
        altitude: '88',
        timezone: '10',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Girua Airport',
        city: 'Girua',
        country: 'Papua New Guinea',
        iataCode: 'PNP',
        icaoCode: 'AYGR',
        latitude: '-8,80453968',
        longitude: '148,3090057',
        altitude: '311',
        timezone: '10',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Kimbe Airport',
        city: 'Hoskins',
        country: 'Papua New Guinea',
        iataCode: 'HKN',
        icaoCode: 'AYHK',
        latitude: '-5,462170124',
        longitude: '150,4049988',
        altitude: '66',
        timezone: '10',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Kiunga Airport',
        city: 'Kiunga',
        country: 'Papua New Guinea',
        iataCode: 'UNG',
        icaoCode: 'AYKI',
        latitude: '-6,125710011',
        longitude: '141,2819977',
        altitude: '88',
        timezone: '10',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Kikori Airport',
        city: 'Kikori',
        country: 'Papua New Guinea',
        iataCode: 'KRI',
        icaoCode: 'AYKK',
        latitude: '-7,424379826',
        longitude: '144,2500763',
        altitude: '50',
        timezone: '10',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Kerema Airport',
        city: 'Kerema',
        country: 'Papua New Guinea',
        iataCode: 'KMA',
        icaoCode: 'AYKM',
        latitude: '-7,963610172',
        longitude: '145,7709961',
        altitude: '10',
        timezone: '10',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Kavieng Airport',
        city: 'Kavieng',
        country: 'Papua New Guinea',
        iataCode: 'KVG',
        icaoCode: 'AYKV',
        latitude: '-2,579400063',
        longitude: '150,8079987',
        altitude: '7',
        timezone: '10',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Mendi Airport',
        city: 'Mendi',
        country: 'Papua New Guinea',
        iataCode: 'MDU',
        icaoCode: 'AYMN',
        latitude: '-6,14774',
        longitude: '143,656998',
        altitude: '5680',
        timezone: '10',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Momote Airport',
        city: 'Momote',
        country: 'Papua New Guinea',
        iataCode: 'MAS',
        icaoCode: 'AYMO',
        latitude: '-2,06189',
        longitude: '147,423996',
        altitude: '12',
        timezone: '10',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Moro Airport',
        city: 'Moro',
        country: 'Papua New Guinea',
        iataCode: 'MXH',
        icaoCode: 'AYMR',
        latitude: '-6,363329887',
        longitude: '143,2380066',
        altitude: '2740',
        timezone: '10',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Misima Island Airport',
        city: 'Misima Island',
        country: 'Papua New Guinea',
        iataCode: 'MIS',
        icaoCode: 'AYMS',
        latitude: '-10,6892004',
        longitude: '152,8379974',
        altitude: '26',
        timezone: '10',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Tari Airport',
        city: 'Tari',
        country: 'Papua New Guinea',
        iataCode: 'TIZ',
        icaoCode: 'AYTA',
        latitude: '-5,84499979',
        longitude: '142,947998',
        altitude: '5500',
        timezone: '10',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Tabubil Airport',
        city: 'Tabubil',
        country: 'Papua New Guinea',
        iataCode: 'TBG',
        icaoCode: 'AYTB',
        latitude: '-5,278610229',
        longitude: '141,2259979',
        altitude: '1570',
        timezone: '10',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Tokua Airport',
        city: 'Tokua',
        country: 'Papua New Guinea',
        iataCode: 'RAB',
        icaoCode: 'AYTK',
        latitude: '-4,340459824',
        longitude: '152,3800049',
        altitude: '32',
        timezone: '10',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Vanimo Airport',
        city: 'Vanimo',
        country: 'Papua New Guinea',
        iataCode: 'VAI',
        icaoCode: 'AYVN',
        latitude: '-2,6926',
        longitude: '141,3028',
        altitude: '10',
        timezone: '10',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Wapenamanda Airport',
        city: 'Wapenamanda',
        country: 'Papua New Guinea',
        iataCode: 'WBM',
        icaoCode: 'AYWD',
        latitude: '-5,643300056',
        longitude: '143,8950043',
        altitude: '5889',
        timezone: '10',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Alluitsup Paa Heliport',
        city: 'Alluitsup Paa',
        country: 'Greenland',
        iataCode: 'LLU',
        icaoCode: 'BGAP',
        latitude: '60,46445',
        longitude: '-45,56917',
        altitude: '54',
        timezone: '-3',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Neerlerit Inaat Airport',
        city: 'Neerlerit Inaat',
        country: 'Greenland',
        iataCode: 'CNP',
        icaoCode: 'BGCO',
        latitude: '70,74310303',
        longitude: '-22,65049934',
        altitude: '45',
        timezone: '-1',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Paamiut Heliport',
        city: 'Paamiut',
        country: 'Greenland',
        iataCode: 'JFR',
        icaoCode: 'BGFH',
        latitude: '61,99219894',
        longitude: '-49,66249847',
        altitude: '63',
        timezone: '-3',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Qeqertarsuaq Heliport',
        city: 'Qeqertarsuaq Airport',
        country: 'Greenland',
        iataCode: 'JGO',
        icaoCode: 'BGGN',
        latitude: '69,25118199',
        longitude: '-53,51487637',
        altitude: '9',
        timezone: '-3',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Qaqortoq Heliport',
        city: 'Qaqortoq',
        country: 'Greenland',
        iataCode: 'JJU',
        icaoCode: 'BGJH',
        latitude: '60,71568416',
        longitude: '-46,02991864',
        altitude: '53',
        timezone: '-3',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Maniitsoq Airport',
        city: 'Maniitsoq',
        country: 'Greenland',
        iataCode: 'JSU',
        icaoCode: 'BGMQ',
        latitude: '65,41249847',
        longitude: '-52,93939972',
        altitude: '91',
        timezone: '-3',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Nanortalik Heliport',
        city: 'Nanortalik',
        country: 'Greenland',
        iataCode: 'JNN',
        icaoCode: 'BGNN',
        latitude: '60,14188398',
        longitude: '-45,23297668',
        altitude: '17',
        timezone: '-3',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Narsaq Heliport',
        city: 'Narsaq',
        country: 'Greenland',
        iataCode: 'JNS',
        icaoCode: 'BGNS',
        latitude: '60,91728273',
        longitude: '-46,05992317',
        altitude: '83',
        timezone: '-3',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Qaanaaq Airport',
        city: 'Qaanaaq',
        country: 'Greenland',
        iataCode: 'NAQ',
        icaoCode: 'BGQQ',
        latitude: '77,48860168',
        longitude: '-69,38870239',
        altitude: '51',
        timezone: '-4',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Sisimiut Airport',
        city: 'Sisimiut',
        country: 'Greenland',
        iataCode: 'JHS',
        icaoCode: 'BGSS',
        latitude: '66,95130157',
        longitude: '-53,72930145',
        altitude: '33',
        timezone: '-3',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Upernavik Airport',
        city: 'Upernavik',
        country: 'Greenland',
        iataCode: 'JUV',
        icaoCode: 'BGUK',
        latitude: '72,79019928',
        longitude: '-56,13059998',
        altitude: '414',
        timezone: '-3',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Qaarsut Airport',
        city: 'Uummannaq',
        country: 'Greenland',
        iataCode: 'JQA',
        icaoCode: 'BGUQ',
        latitude: '70,73419952',
        longitude: '-52,69620132',
        altitude: '289',
        timezone: '-3',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Grímsey Airport',
        city: 'Grímsey',
        country: 'Iceland',
        iataCode: 'GRY',
        icaoCode: 'BIGR',
        latitude: '66,5458',
        longitude: '-18,0173',
        altitude: '66',
        timezone: '0',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Thorshofn Airport',
        city: 'Thorshofn',
        country: 'Iceland',
        iataCode: 'THO',
        icaoCode: 'BITN',
        latitude: '66,21849823',
        longitude: '-15,3355999',
        altitude: '65',
        timezone: '0',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Vopnafjörður Airport',
        city: 'Vopnafjörður',
        country: 'Iceland',
        iataCode: 'VPN',
        icaoCode: 'BIVO',
        latitude: '65,72059631',
        longitude: '-14,85060024',
        altitude: '16',
        timezone: '0',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Whistler/Green Lake Water Aerodrome',
        city: 'Whistler',
        country: 'Canada',
        iataCode: 'YWS',
        icaoCode: 'CAE5',
        latitude: '50,14360046',
        longitude: '-122,9489975',
        altitude: '2100',
        timezone: '-8',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Anahim Lake Airport',
        city: 'Anahim Lake',
        country: 'Canada',
        iataCode: 'YAA',
        icaoCode: 'CAJ4',
        latitude: '52,45249939',
        longitude: '-125,3030014',
        altitude: '3635',
        timezone: '-8',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Williams Harbour Airport',
        city: 'Williams Harbour',
        country: 'Canada',
        iataCode: 'YWM',
        icaoCode: 'CCA6',
        latitude: '52,56689835',
        longitude: '-55,78469849',
        altitude: '70',
        timezone: '-3,5',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'St. Lewis (Fox Harbour) Airport',
        city: 'St. Lewis',
        country: 'Canada',
        iataCode: 'YFX',
        icaoCode: 'CCK4',
        latitude: '52,37279892',
        longitude: '-55,6739006',
        altitude: '74',
        timezone: '-3,5',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Port Hope Simpson Airport',
        city: 'Port Hope Simpson',
        country: 'Canada',
        iataCode: 'YHA',
        icaoCode: 'CCP4',
        latitude: '52,52809906',
        longitude: '-56,28609848',
        altitude: '347',
        timezone: '-3,5',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Rigolet Airport',
        city: 'Rigolet',
        country: 'Canada',
        iataCode: 'YRG',
        icaoCode: 'CCZ2',
        latitude: '54,17969894',
        longitude: '-58,45750046',
        altitude: '180',
        timezone: '-4',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Colville Lake Airport',
        city: 'Colville Lake',
        country: 'Canada',
        iataCode: 'YCK',
        icaoCode: 'CEB3',
        latitude: '67,0392',
        longitude: '-126,08',
        altitude: '850',
        timezone: '-7',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Whatì Airport',
        city: 'Whatì',
        country: 'Canada',
        iataCode: 'YLE',
        icaoCode: 'CEM3',
        latitude: '63,13169861',
        longitude: '-117,2460022',
        altitude: '882',
        timezone: '-7',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Summer Beaver Airport',
        city: 'Summer Beaver',
        country: 'Canada',
        iataCode: 'SUR',
        icaoCode: 'CJV7',
        latitude: '52,70859909',
        longitude: '-88,54190063',
        altitude: '832',
        timezone: '-5',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Wapekeka Airport',
        city: 'Angling Lake',
        country: 'Canada',
        iataCode: 'YAX',
        icaoCode: 'CKB6',
        latitude: '53,8492012',
        longitude: '-89,57939911',
        altitude: '712',
        timezone: '-6',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Wunnumin Lake Airport',
        city: 'Wunnumin Lake',
        country: 'Canada',
        iataCode: 'WNN',
        icaoCode: 'CKL3',
        latitude: '52,89390182',
        longitude: '-89,28919983',
        altitude: '819',
        timezone: '-5',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'North Spirit Lake Airport',
        city: 'North Spirit Lake',
        country: 'Canada',
        iataCode: 'YNO',
        icaoCode: 'CKQ3',
        latitude: '52,49000168',
        longitude: '-92,97109985',
        altitude: '1082',
        timezone: '-6',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Bearskin Lake Airport',
        city: 'Bearskin Lake',
        country: 'Canada',
        iataCode: 'XBE',
        icaoCode: 'CNE3',
        latitude: '53,96559906',
        longitude: '-91,02719879',
        altitude: '800',
        timezone: '-6',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Kingfisher Lake Airport',
        city: 'Kingfisher Lake',
        country: 'Canada',
        iataCode: 'KIF',
        icaoCode: 'CNM5',
        latitude: '53,01250076',
        longitude: '-89,8553009',
        altitude: '866',
        timezone: '-5',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Ogoki Post Airport',
        city: 'Ogoki Post',
        country: 'Canada',
        iataCode: 'YOG',
        icaoCode: 'CNT3',
        latitude: '51,65859985',
        longitude: '-85,90170288',
        altitude: '594',
        timezone: '-5',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Poplar Hill Airport',
        city: 'Poplar Hill',
        country: 'Canada',
        iataCode: 'YHP',
        icaoCode: 'CPV7',
        latitude: '52,11330032',
        longitude: '-94,25559998',
        altitude: '1095',
        timezone: '-6',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Chisasibi Airport',
        city: 'Chisasibi',
        country: 'Canada',
        iataCode: 'YKU',
        icaoCode: 'CSU2',
        latitude: '53,80559921',
        longitude: '-78,91690063',
        altitude: '43',
        timezone: '-5',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Tête-à-la-Baleine Airport',
        city: 'Tête-à-la-Baleine',
        country: 'Canada',
        iataCode: 'ZTB',
        icaoCode: 'CTB6',
        latitude: '50,67440033',
        longitude: '-59,38359833',
        altitude: '107',
        timezone: '-4',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'La Tabatière Airport',
        city: 'La Tabatière',
        country: 'Canada',
        iataCode: 'ZLT',
        icaoCode: 'CTU5',
        latitude: '50,8307991',
        longitude: '-58,9756012',
        altitude: '102',
        timezone: '-4',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Cat Lake Airport',
        city: 'Cat Lake',
        country: 'Canada',
        iataCode: 'YAC',
        icaoCode: 'CYAC',
        latitude: '51,72719955',
        longitude: '-91,82440186',
        altitude: '1344',
        timezone: '-6',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Fort Frances Municipal Airport',
        city: 'Fort Frances',
        country: 'Canada',
        iataCode: 'YAG',
        icaoCode: 'CYAG',
        latitude: '48,65420151',
        longitude: '-93,43969727',
        altitude: '1125',
        timezone: '-6',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Kasabonika Airport',
        city: 'Kasabonika',
        country: 'Canada',
        iataCode: 'XKS',
        icaoCode: 'CYAQ',
        latitude: '53,52470016',
        longitude: '-88,64279938',
        altitude: '672',
        timezone: '-5',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Kangirsuk Airport',
        city: 'Kangirsuk',
        country: 'Canada',
        iataCode: 'YKG',
        icaoCode: 'CYAS',
        latitude: '60,02719879',
        longitude: '-69,99919891',
        altitude: '403',
        timezone: '-5',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Attawapiskat Airport',
        city: 'Attawapiskat',
        country: 'Canada',
        iataCode: 'YAT',
        icaoCode: 'CYAT',
        latitude: '52,92750168',
        longitude: '-82,43190002',
        altitude: '31',
        timezone: '-5',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Lac Du Bonnet Airport',
        city: 'Lac Du Bonnet',
        country: 'Canada',
        iataCode: 'N',
        icaoCode: 'CYAX',
        latitude: '50,29439926',
        longitude: '-96,01000214',
        altitude: '850',
        timezone: '-6',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Uranium City Airport',
        city: 'Uranium City',
        country: 'Canada',
        iataCode: 'YBE',
        icaoCode: 'CYBE',
        latitude: '59,56140137',
        longitude: '-108,4810028',
        altitude: '1044',
        timezone: '-6',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Lourdes de Blanc Sablon Airport',
        city: 'Lourdes-De-Blanc-Sablon',
        country: 'Canada',
        iataCode: 'YBX',
        icaoCode: 'CYBX',
        latitude: '51,4435997',
        longitude: '-57,18529892',
        altitude: '121',
        timezone: '-4',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Cartwright Airport',
        city: 'Cartwright',
        country: 'Canada',
        iataCode: 'YRF',
        icaoCode: 'CYCA',
        latitude: '53,68280029',
        longitude: '-57,04190063',
        altitude: '40',
        timezone: '-4',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Chesterfield Inlet Airport',
        city: 'Chesterfield Inlet',
        country: 'Canada',
        iataCode: 'YCS',
        icaoCode: 'CYCS',
        latitude: '63,34690094',
        longitude: '-90,73110199',
        altitude: '32',
        timezone: '-6',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Nain Airport',
        city: 'Nain',
        country: 'Canada',
        iataCode: 'YDP',
        icaoCode: 'CYDP',
        latitude: '56,54919815',
        longitude: '-61,68030167',
        altitude: '22',
        timezone: '-4',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Fort Severn Airport',
        city: 'Fort Severn',
        country: 'Canada',
        iataCode: 'YER',
        icaoCode: 'CYER',
        latitude: '56,01890182',
        longitude: '-87,67610168',
        altitude: '48',
        timezone: '-5',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Fort Albany Airport',
        city: 'Fort Albany',
        country: 'Canada',
        iataCode: 'YFA',
        icaoCode: 'CYFA',
        latitude: '52,20140076',
        longitude: '-81,69689941',
        altitude: '48',
        timezone: '-5',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Fort Hope Airport',
        city: 'Fort Hope',
        country: 'Canada',
        iataCode: 'YFH',
        icaoCode: 'CYFH',
        latitude: '51,56190109',
        longitude: '-87,90779877',
        altitude: '899',
        timezone: '-5',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Makkovik Airport',
        city: 'Makkovik',
        country: 'Canada',
        iataCode: 'YMN',
        icaoCode: 'CYFT',
        latitude: '55,07690048',
        longitude: '-59,18640137',
        altitude: '234',
        timezone: '-4',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Texada Gillies Bay Airport',
        city: 'Texada',
        country: 'Canada',
        iataCode: 'YGB',
        icaoCode: 'CYGB',
        latitude: '49,69419861',
        longitude: '-124,5179977',
        altitude: '326',
        timezone: '-8',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Gods Lake Narrows Airport',
        city: 'Gods Lake Narrows',
        country: 'Canada',
        iataCode: 'YGO',
        icaoCode: 'CYGO',
        latitude: '54,55889893',
        longitude: '-94,49140167',
        altitude: '617',
        timezone: '-6',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Igloolik Airport',
        city: 'Igloolik',
        country: 'Canada',
        iataCode: 'YGT',
        icaoCode: 'CYGT',
        latitude: '69,36470032',
        longitude: '-81,81610107',
        altitude: '174',
        timezone: '-5',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Kuujjuarapik Airport',
        city: 'Kuujjuarapik',
        country: 'Canada',
        iataCode: 'YGW',
        icaoCode: 'CYGW',
        latitude: '55,2818985',
        longitude: '-77,76529694',
        altitude: '34',
        timezone: '-5',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Gillam Airport',
        city: 'Gillam',
        country: 'Canada',
        iataCode: 'YGX',
        icaoCode: 'CYGX',
        latitude: '56,35749817',
        longitude: '-94,71060181',
        altitude: '476',
        timezone: '-6',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Grise Fiord Airport',
        city: 'Grise Fiord',
        country: 'Canada',
        iataCode: 'YGZ',
        icaoCode: 'CYGZ',
        latitude: '76,42610168',
        longitude: '-82,90920258',
        altitude: '146',
        timezone: '-5',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Quaqtaq Airport',
        city: 'Quaqtaq',
        country: 'Canada',
        iataCode: 'YQC',
        icaoCode: 'CYHA',
        latitude: '61,04639816',
        longitude: '-69,61779785',
        altitude: '103',
        timezone: '-5',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Vancouver Harbour Water Aerodrome',
        city: 'Vancouver',
        country: 'Canada',
        iataCode: 'CXH',
        icaoCode: 'CYHC',
        latitude: '49,29439926',
        longitude: '-123,1110001',
        altitude: '0',
        timezone: '-8',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Nemiscau Airport',
        city: 'Nemiscau',
        country: 'Canada',
        iataCode: 'YNS',
        icaoCode: 'CYHH',
        latitude: '51,69110107',
        longitude: '-76,13559723',
        altitude: '802',
        timezone: '-5',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Hopedale Airport',
        city: 'Hopedale',
        country: 'Canada',
        iataCode: 'YHO',
        icaoCode: 'CYHO',
        latitude: '55,44829941',
        longitude: '-60,22859955',
        altitude: '39',
        timezone: '-4',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Chevery Airport',
        city: 'Chevery',
        country: 'Canada',
        iataCode: 'YHR',
        icaoCode: 'CYHR',
        latitude: '50,46889877',
        longitude: '-59,63669968',
        altitude: '39',
        timezone: '-4',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Ivujivik Airport',
        city: 'Ivujivik',
        country: 'Canada',
        iataCode: 'YIK',
        icaoCode: 'CYIK',
        latitude: '62,41730118',
        longitude: '-77,9253006',
        altitude: '126',
        timezone: '-5',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Island Lake Airport',
        city: 'Island Lake',
        country: 'Canada',
        iataCode: 'YIV',
        icaoCode: 'CYIV',
        latitude: '53,85720062',
        longitude: '-94,6536026',
        altitude: '770',
        timezone: '-6',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Akulivik Airport',
        city: 'Akulivik',
        country: 'Canada',
        iataCode: 'AKV',
        icaoCode: 'CYKO',
        latitude: '60,8185997',
        longitude: '-78,14859772',
        altitude: '75',
        timezone: '-5',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Waskaganish Airport',
        city: 'Waskaganish',
        country: 'Canada',
        iataCode: 'YKQ',
        icaoCode: 'CYKQ',
        latitude: '51,47330093',
        longitude: '-78,75830078',
        altitude: '80',
        timezone: '-5',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Aupaluk Airport',
        city: 'Aupaluk',
        country: 'Canada',
        iataCode: 'YPJ',
        icaoCode: 'CYLA',
        latitude: '59,29669952',
        longitude: '-69,59970093',
        altitude: '119',
        timezone: '-5',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Kimmirut Airport',
        city: 'Kimmirut',
        country: 'Canada',
        iataCode: 'YLC',
        icaoCode: 'CYLC',
        latitude: '62,84999847',
        longitude: '-69,88330078',
        altitude: '175',
        timezone: '-5',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Lansdowne House Airport',
        city: 'Lansdowne House',
        country: 'Canada',
        iataCode: 'YLH',
        icaoCode: 'CYLH',
        latitude: '52,1955986',
        longitude: '-87,93419647',
        altitude: '834',
        timezone: '-5',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'St Georges Airport',
        city: "Lutselk'e",
        country: 'Canada',
        iataCode: 'YSG',
        icaoCode: 'CYSG',
        latitude: '46,09640121',
        longitude: '-70,71469879',
        altitude: '893',
        timezone: '-5',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Kangiqsualujjuaq (Georges River) Airport',
        city: 'Kangiqsualujjuaq',
        country: 'Canada',
        iataCode: 'XGR',
        icaoCode: 'CYLU',
        latitude: '58,71139908',
        longitude: '-65,99279785',
        altitude: '215',
        timezone: '-5',
        dst: 'A',
        type: 'airport',
    },
    {
        name: "Mary's Harbour Airport",
        city: "Mary's Harbour",
        country: 'Canada',
        iataCode: 'YMH',
        icaoCode: 'CYMH',
        latitude: '52,30279922',
        longitude: '-55,84719849',
        altitude: '38',
        timezone: '-3,5',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Chapais Airport',
        city: 'Chibougamau',
        country: 'Canada',
        iataCode: 'YMT',
        icaoCode: 'CYMT',
        latitude: '49,77190018',
        longitude: '-74,52809906',
        altitude: '1270',
        timezone: '-5',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Umiujaq Airport',
        city: 'Umiujaq',
        country: 'Canada',
        iataCode: 'YUD',
        icaoCode: 'CYMU',
        latitude: '56,53609848',
        longitude: '-76,51830292',
        altitude: '250',
        timezone: '-5',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Wemindji Airport',
        city: 'Wemindji',
        country: 'Canada',
        iataCode: 'YNC',
        icaoCode: 'CYNC',
        latitude: '53,01060104',
        longitude: '-78,83110046',
        altitude: '66',
        timezone: '-5',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Norway House Airport',
        city: 'Norway House',
        country: 'Canada',
        iataCode: 'YNE',
        icaoCode: 'CYNE',
        latitude: '53,95830154',
        longitude: '-97,84420013',
        altitude: '734',
        timezone: '-6',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Points North Landing Airport',
        city: 'Points North Landing',
        country: 'Canada',
        iataCode: 'YNL',
        icaoCode: 'CYNL',
        latitude: '58,27669907',
        longitude: '-104,0820007',
        altitude: '1605',
        timezone: '-6',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Oxford House Airport',
        city: 'Oxford House',
        country: 'Canada',
        iataCode: 'YOH',
        icaoCode: 'CYOH',
        latitude: '54,93330002',
        longitude: '-95,27890015',
        altitude: '663',
        timezone: '-6',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Inukjuak Airport',
        city: 'Inukjuak',
        country: 'Canada',
        iataCode: 'YPH',
        icaoCode: 'CYPH',
        latitude: '58,47190094',
        longitude: '-78,07689667',
        altitude: '83',
        timezone: '-5',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Pikangikum Airport',
        city: 'Pikangikum',
        country: 'Canada',
        iataCode: 'YPM',
        icaoCode: 'CYPM',
        latitude: '51,81969833',
        longitude: '-93,97329712',
        altitude: '1114',
        timezone: '-6',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Peawanuck Airport',
        city: 'Peawanuck',
        country: 'Canada',
        iataCode: 'YPO',
        icaoCode: 'CYPO',
        latitude: '54,98809814',
        longitude: '-85,44329834',
        altitude: '173',
        timezone: '-5',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Powell River Airport',
        city: 'Powell River',
        country: 'Canada',
        iataCode: 'YPW',
        icaoCode: 'CYPW',
        latitude: '49,83420181',
        longitude: '-124,5',
        altitude: '425',
        timezone: '-8',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'The Pas Airport',
        city: 'The Pas',
        country: 'Canada',
        iataCode: 'YQD',
        icaoCode: 'CYQD',
        latitude: '53,97140121',
        longitude: '-101,0910034',
        altitude: '887',
        timezone: '-6',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Nakina Airport',
        city: 'Nakina',
        country: 'Canada',
        iataCode: 'YQN',
        icaoCode: 'CYQN',
        latitude: '50,18280029',
        longitude: '-86,6964035',
        altitude: '1057',
        timezone: '-5',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Rae Lakes Airport',
        city: 'Gamètì',
        country: 'Canada',
        iataCode: 'YRA',
        icaoCode: 'CYRA',
        latitude: '64,1160965',
        longitude: '-117,3099976',
        altitude: '723',
        timezone: '-7',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Red Lake Airport',
        city: 'Red Lake',
        country: 'Canada',
        iataCode: 'YRL',
        icaoCode: 'CYRL',
        latitude: '51,06689835',
        longitude: '-93,79309845',
        altitude: '1265',
        timezone: '-6',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Stony Rapids Airport',
        city: 'Stony Rapids',
        country: 'Canada',
        iataCode: 'YSF',
        icaoCode: 'CYSF',
        latitude: '59,25030136',
        longitude: '-105,8410034',
        altitude: '805',
        timezone: '-6',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Sanikiluaq Airport',
        city: 'Sanikiluaq',
        country: 'Canada',
        iataCode: 'YSK',
        icaoCode: 'CYSK',
        latitude: '56,53779984',
        longitude: '-79,24669647',
        altitude: '104',
        timezone: '-5',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'St. Theresa Point Airport',
        city: 'St. Theresa Point',
        country: 'Canada',
        iataCode: 'YST',
        icaoCode: 'CYST',
        latitude: '53,84560013',
        longitude: '-94,85189819',
        altitude: '773',
        timezone: '-6',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Big Trout Lake Airport',
        city: 'Big Trout Lake',
        country: 'Canada',
        iataCode: 'YTL',
        icaoCode: 'CYTL',
        latitude: '53,81779861',
        longitude: '-89,89689636',
        altitude: '729',
        timezone: '-6',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Deer Lake Airport',
        city: 'Deer Lake',
        country: 'Canada',
        iataCode: 'YVZ',
        icaoCode: 'CYVZ',
        latitude: '52,65579987',
        longitude: '-94,06140137',
        altitude: '1092',
        timezone: '-6',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Webequie Airport',
        city: 'Webequie',
        country: 'Canada',
        iataCode: 'YWP',
        icaoCode: 'CYWP',
        latitude: '52,9593934',
        longitude: '-87,37486839',
        altitude: '685',
        timezone: '-5',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Whale Cove Airport',
        city: 'Whale Cove',
        country: 'Canada',
        iataCode: 'YXN',
        icaoCode: 'CYXN',
        latitude: '62,24000168',
        longitude: '-92,59809875',
        altitude: '40',
        timezone: '-6',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Salluit Airport',
        city: 'Salluit',
        country: 'Canada',
        iataCode: 'YZG',
        icaoCode: 'CYZG',
        latitude: '62,1794014',
        longitude: '-75,66719818',
        altitude: '743',
        timezone: '-5',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'York Landing Airport',
        city: 'York Landing',
        country: 'Canada',
        iataCode: 'ZAC',
        icaoCode: 'CZAC',
        latitude: '56,08940125',
        longitude: '-96,08920288',
        altitude: '621',
        timezone: '-6',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Ilford Airport',
        city: 'Ilford',
        country: 'Canada',
        iataCode: 'ILF',
        icaoCode: 'CZBD',
        latitude: '56,06140137',
        longitude: '-95,61389923',
        altitude: '642',
        timezone: '-6',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Bathurst Airport',
        city: 'Bathurst',
        country: 'Canada',
        iataCode: 'ZBF',
        icaoCode: 'CZBF',
        latitude: '47,62969971',
        longitude: '-65,73889923',
        altitude: '193',
        timezone: '-4',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Eastmain River Airport',
        city: 'Eastmain River',
        country: 'Canada',
        iataCode: 'ZEM',
        icaoCode: 'CZEM',
        latitude: '52,22639847',
        longitude: '-78,52249908',
        altitude: '24',
        timezone: '-5',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Fond-Du-Lac Airport',
        city: 'Fond-Du-Lac',
        country: 'Canada',
        iataCode: 'ZFD',
        icaoCode: 'CZFD',
        latitude: '59,33440018',
        longitude: '-107,1819992',
        altitude: '814',
        timezone: '-6',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Gods River Airport',
        city: 'Gods River',
        country: 'Canada',
        iataCode: 'ZGI',
        icaoCode: 'CZGI',
        latitude: '54,83969879',
        longitude: '-94,07859802',
        altitude: '627',
        timezone: '-6',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Swan River Airport',
        city: 'Swan River',
        country: 'Canada',
        iataCode: 'ZJN',
        icaoCode: 'CZJN',
        latitude: '52,12060165',
        longitude: '-101,2360001',
        altitude: '1100',
        timezone: '-6',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Kashechewan Airport',
        city: 'Kashechewan',
        country: 'Canada',
        iataCode: 'ZKE',
        icaoCode: 'CZKE',
        latitude: '52,28250122',
        longitude: '-81,67780304',
        altitude: '35',
        timezone: '-5',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Muskrat Dam Airport',
        city: 'Muskrat Dam',
        country: 'Canada',
        iataCode: 'MSA',
        icaoCode: 'CZMD',
        latitude: '53,44139862',
        longitude: '-91,76280212',
        altitude: '911',
        timezone: '-6',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Masset Airport',
        city: 'Masset',
        country: 'Canada',
        iataCode: 'ZMT',
        icaoCode: 'CZMT',
        latitude: '54,02750015',
        longitude: '-132,125',
        altitude: '25',
        timezone: '-8',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Sachigo Lake Airport',
        city: 'Sachigo Lake',
        country: 'Canada',
        iataCode: 'ZPB',
        icaoCode: 'CZPB',
        latitude: '53,89110184',
        longitude: '-92,1964035',
        altitude: '876',
        timezone: '-6',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Round Lake (Weagamow Lake) Airport',
        city: 'Round Lake',
        country: 'Canada',
        iataCode: 'ZRJ',
        icaoCode: 'CZRJ',
        latitude: '52,9435997',
        longitude: '-91,31279755',
        altitude: '974',
        timezone: '-6',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Sandy Lake Airport',
        city: 'Sandy Lake',
        country: 'Canada',
        iataCode: 'ZSJ',
        icaoCode: 'CZSJ',
        latitude: '53,06420135',
        longitude: '-93,3443985',
        altitude: '951',
        timezone: '-6',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Shamattawa Airport',
        city: 'Shamattawa',
        country: 'Canada',
        iataCode: 'ZTM',
        icaoCode: 'CZTM',
        latitude: '55,86560059',
        longitude: '-92,08139801',
        altitude: '289',
        timezone: '-6',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Churchill Falls Airport',
        city: 'Churchill Falls',
        country: 'Canada',
        iataCode: 'ZUM',
        icaoCode: 'CZUM',
        latitude: '53,56190109',
        longitude: '-64,10639954',
        altitude: '1442',
        timezone: '-4',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Wollaston Lake Airport',
        city: 'Wollaston Lake',
        country: 'Canada',
        iataCode: 'ZWL',
        icaoCode: 'CZWL',
        latitude: '58,10689926',
        longitude: '-103,1719971',
        altitude: '1360',
        timezone: '-6',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Batna Airport',
        city: 'Batna',
        country: 'Algeria',
        iataCode: 'BLJ',
        icaoCode: 'DABT',
        latitude: '35,7521019',
        longitude: '6,308589935',
        altitude: '2697',
        timezone: '1',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Béchar Boudghene Ben Ali Lotfi Airport',
        city: 'Béchar',
        country: 'Algeria',
        iataCode: 'CBH',
        icaoCode: 'DAOR',
        latitude: '31,64570045',
        longitude: '-2,269860029',
        altitude: '2661',
        timezone: '1',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Bordj Badji Mokhtar Airport',
        city: 'Bordj Badji Mokhtar',
        country: 'Algeria',
        iataCode: 'BMW',
        icaoCode: 'DATM',
        latitude: '21,375',
        longitude: '0,923888981',
        altitude: '1303',
        timezone: '1',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Guemar Airport',
        city: 'Guemar',
        country: 'Algeria',
        iataCode: 'ELU',
        icaoCode: 'DAUO',
        latitude: '33,51139832',
        longitude: '6,776790142',
        altitude: '203',
        timezone: '1',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Kumasi Airport',
        city: 'Kumasi',
        country: 'Ghana',
        iataCode: 'KMS',
        icaoCode: 'DGSI',
        latitude: '6,714560032',
        longitude: '-1,590819955',
        altitude: '942',
        timezone: '0',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Heringsdorf Airport',
        city: 'Heringsdorf',
        country: 'Germany',
        iataCode: 'HDF',
        icaoCode: 'EDAH',
        latitude: '53,87870026',
        longitude: '14,15229988',
        altitude: '93',
        timezone: '1',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Heide-Büsum Airport',
        city: 'Büsum',
        country: 'Germany',
        iataCode: 'HEI',
        icaoCode: 'EDXB',
        latitude: '54,15333176',
        longitude: '8,901666641',
        altitude: '7',
        timezone: '1',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Helgoland-Düne Airport',
        city: 'Helgoland',
        country: 'Germany',
        iataCode: 'HGL',
        icaoCode: 'EDXH',
        latitude: '54,18527985',
        longitude: '7,915832996',
        altitude: '8',
        timezone: '1',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Seinäjoki Airport',
        city: 'Seinäjoki / Ilmajoki',
        country: 'Finland',
        iataCode: 'SJY',
        icaoCode: 'EFSI',
        latitude: '62,69210052',
        longitude: '22,83230019',
        altitude: '302',
        timezone: '2',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Nottingham Airport',
        city: 'Nottingham',
        country: 'United Kingdom',
        iataCode: 'NQT',
        icaoCode: 'EGBN',
        latitude: '52,91999817',
        longitude: '-1,079169989',
        altitude: '138',
        timezone: '0',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Robin Hood Doncaster Sheffield Airport',
        city: 'Doncaster, Sheffield',
        country: 'United Kingdom',
        iataCode: 'DSA',
        icaoCode: 'EGCN',
        latitude: '53,48053781',
        longitude: '-1,010656357',
        altitude: '55',
        timezone: '0',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Campbeltown Airport',
        city: 'Campbeltown',
        country: 'United Kingdom',
        iataCode: 'CAL',
        icaoCode: 'EGEC',
        latitude: '55,43719864',
        longitude: '-5,686389923',
        altitude: '42',
        timezone: '0',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Eday Airport',
        city: 'Eday',
        country: 'United Kingdom',
        iataCode: 'EOI',
        icaoCode: 'EGED',
        latitude: '59,19060135',
        longitude: '-2,772219896',
        altitude: '10',
        timezone: '0',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Fair Isle Airport',
        city: 'Fair Isle',
        country: 'United Kingdom',
        iataCode: 'FIE',
        icaoCode: 'EGEF',
        latitude: '59,53580093',
        longitude: '-1,628059983',
        altitude: '223',
        timezone: '0',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'North Ronaldsay Airport',
        city: 'North Ronaldsay',
        country: 'United Kingdom',
        iataCode: 'NRL',
        icaoCode: 'EGEN',
        latitude: '59,36750031',
        longitude: '-2,434439898',
        altitude: '40',
        timezone: '0',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Papa Westray Airport',
        city: 'Papa Westray',
        country: 'United Kingdom',
        iataCode: 'PPW',
        icaoCode: 'EGEP',
        latitude: '59,35169983',
        longitude: '-2,900279999',
        altitude: '91',
        timezone: '0',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Stronsay Airport',
        city: 'Stronsay',
        country: 'United Kingdom',
        iataCode: 'SOY',
        icaoCode: 'EGER',
        latitude: '59,15530014',
        longitude: '-2,641390085',
        altitude: '39',
        timezone: '0',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Sanday Airport',
        city: 'Sanday',
        country: 'United Kingdom',
        iataCode: 'NDY',
        icaoCode: 'EGES',
        latitude: '59,25030136',
        longitude: '-2,576669931',
        altitude: '68',
        timezone: '0',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Lerwick / Tingwall Airport',
        city: 'Lerwick',
        country: 'United Kingdom',
        iataCode: 'LWK',
        icaoCode: 'EGET',
        latitude: '60,19219971',
        longitude: '-1,243610024',
        altitude: '43',
        timezone: '0',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Westray Airport',
        city: 'Westray',
        country: 'United Kingdom',
        iataCode: 'WRY',
        icaoCode: 'EGEW',
        latitude: '59,35029984',
        longitude: '-2,950000048',
        altitude: '29',
        timezone: '0',
        dst: 'E',
        type: 'airport',
    },
    {
        name: "Land's End Airport",
        city: "Land's End",
        country: 'United Kingdom',
        iataCode: 'LEQ',
        icaoCode: 'EGHC',
        latitude: '50,10279846',
        longitude: '-5,670559883',
        altitude: '401',
        timezone: '0',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Penzance Heliport',
        city: 'Penzance',
        country: 'United Kingdom',
        iataCode: 'PZE',
        icaoCode: 'EGHK',
        latitude: '50,128101',
        longitude: '-5,51845',
        altitude: '14',
        timezone: '0',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Anglesey Airport',
        city: 'Angelsey',
        country: 'United Kingdom',
        iataCode: 'VLY',
        icaoCode: 'EGOV',
        latitude: '53,24810028',
        longitude: '-4,535339832',
        altitude: '37',
        timezone: '0',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Barra Airport',
        city: 'Barra',
        country: 'United Kingdom',
        iataCode: 'BRR',
        icaoCode: 'EGPR',
        latitude: '57,02280045',
        longitude: '-7,443059921',
        altitude: '5',
        timezone: '0',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Donegal Airport',
        city: 'Dongloe',
        country: 'Ireland',
        iataCode: 'CFN',
        icaoCode: 'EIDL',
        latitude: '55,0442009',
        longitude: '-8,340999603',
        altitude: '30',
        timezone: '0',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Weston Airport',
        city: 'Leixlip',
        country: 'Ireland',
        iataCode: 'N',
        icaoCode: 'EIWT',
        latitude: '53,35219955',
        longitude: '-6,48611021',
        altitude: '150',
        timezone: '0',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Sindal Airport',
        city: 'Sindal',
        country: 'Denmark',
        iataCode: 'CNL',
        icaoCode: 'EKSN',
        latitude: '57,50350189',
        longitude: '10,22939968',
        altitude: '92',
        timezone: '1',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Leknes Airport',
        city: 'Leknes',
        country: 'Norway',
        iataCode: 'LKN',
        icaoCode: 'ENLK',
        latitude: '68,15249634',
        longitude: '13,6093998',
        altitude: '78',
        timezone: '1',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Namsos Høknesøra Airport',
        city: 'Namsos',
        country: 'Norway',
        iataCode: 'OSY',
        icaoCode: 'ENNM',
        latitude: '64,47219849',
        longitude: '11,57859993',
        altitude: '7',
        timezone: '1',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Mo i Rana Airport, Røssvoll',
        city: 'Mo i Rana',
        country: 'Norway',
        iataCode: 'MQN',
        icaoCode: 'ENRA',
        latitude: '66,36389923',
        longitude: '14,30140018',
        altitude: '229',
        timezone: '1',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Rørvik Airport, Ryum',
        city: 'Rørvik',
        country: 'Norway',
        iataCode: 'RVK',
        icaoCode: 'ENRM',
        latitude: '64,83830261',
        longitude: '11,14610004',
        altitude: '14',
        timezone: '1',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Røst Airport',
        city: 'Røst',
        country: 'Norway',
        iataCode: 'RET',
        icaoCode: 'ENRS',
        latitude: '67,52780151',
        longitude: '12,10330009',
        altitude: '7',
        timezone: '1',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Sandane Airport (Anda)',
        city: 'Sandane',
        country: 'Norway',
        iataCode: 'SDN',
        icaoCode: 'ENSD',
        latitude: '61,83000183',
        longitude: '6,105830193',
        altitude: '196',
        timezone: '1',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Sogndal Airport',
        city: 'Sogndal',
        country: 'Norway',
        iataCode: 'SOG',
        icaoCode: 'ENSG',
        latitude: '61,156101',
        longitude: '7,13778',
        altitude: '1633',
        timezone: '1',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Svolvær Helle Airport',
        city: 'Svolvær',
        country: 'Norway',
        iataCode: 'SVJ',
        icaoCode: 'ENSH',
        latitude: '68,24330139',
        longitude: '14,66919994',
        altitude: '27',
        timezone: '1',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Sørkjosen Airport',
        city: 'Sorkjosen',
        country: 'Norway',
        iataCode: 'SOJ',
        icaoCode: 'ENSR',
        latitude: '69,78679657',
        longitude: '20,95940018',
        altitude: '16',
        timezone: '1',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Vardø Airport, Svartnes',
        city: 'Vardø',
        country: 'Norway',
        iataCode: 'VAW',
        icaoCode: 'ENSS',
        latitude: '70,35540009',
        longitude: '31,04490089',
        altitude: '42',
        timezone: '1',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Værøy Heliport',
        city: 'Værøy',
        country: 'Norway',
        iataCode: 'VRY',
        icaoCode: 'ENVR',
        latitude: '67,654555',
        longitude: '12,727257',
        altitude: '12',
        timezone: '1',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Bydgoszcz Ignacy Jan Paderewski Airport',
        city: 'Bydgoszcz',
        country: 'Poland',
        iataCode: 'BZG',
        icaoCode: 'EPBY',
        latitude: '53,09680176',
        longitude: '17,97769928',
        altitude: '235',
        timezone: '1',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Łódź Władysław Reymont Airport',
        city: 'Lodz',
        country: 'Poland',
        iataCode: 'LCJ',
        icaoCode: 'EPLL',
        latitude: '51,72190094',
        longitude: '19,3980999',
        altitude: '604',
        timezone: '1',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Åre Östersund Airport',
        city: 'Östersund',
        country: 'Sweden',
        iataCode: 'OSD',
        icaoCode: 'ESNZ',
        latitude: '63,19440079',
        longitude: '14,50030041',
        altitude: '1233',
        timezone: '1',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Hagfors Airport',
        city: 'Hagfors',
        country: 'Sweden',
        iataCode: 'HFS',
        icaoCode: 'ESOH',
        latitude: '60,02009964',
        longitude: '13,57890034',
        altitude: '474',
        timezone: '1',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Karlstad Airport',
        city: 'Karlstad',
        country: 'Sweden',
        iataCode: 'KSD',
        icaoCode: 'ESOK',
        latitude: '59,44469833',
        longitude: '13,33740044',
        altitude: '352',
        timezone: '1',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Torsby Airport',
        city: 'Torsby',
        country: 'Sweden',
        iataCode: 'TYF',
        icaoCode: 'ESST',
        latitude: '60,1576004',
        longitude: '12,99129963',
        altitude: '393',
        timezone: '1',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Ängelholm-Helsingborg Airport',
        city: 'Ängelholm',
        country: 'Sweden',
        iataCode: 'AGH',
        icaoCode: 'ESTA',
        latitude: '56,29610062',
        longitude: '12,84710026',
        altitude: '68',
        timezone: '1',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Storuman Airport',
        city: 'Mohed',
        country: 'Sweden',
        iataCode: 'SQO',
        icaoCode: 'ESUD',
        latitude: '64,96089935',
        longitude: '17,69659996',
        altitude: '915',
        timezone: '1',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Hemavan Airport',
        city: 'Hemavan',
        country: 'Sweden',
        iataCode: 'HMV',
        icaoCode: 'ESUT',
        latitude: '65,80609894',
        longitude: '15,08279991',
        altitude: '1503',
        timezone: '1',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Ventspils International Airport',
        city: 'Ventspils',
        country: 'Latvia',
        iataCode: 'VTS',
        icaoCode: 'EVVA',
        latitude: '57,35779953',
        longitude: '21,5442009',
        altitude: '19',
        timezone: '2',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Rand Airport',
        city: 'Johannesburg',
        country: 'South Africa',
        iataCode: 'QRA',
        icaoCode: 'FAGM',
        latitude: '-26,24250031',
        longitude: '28,15119934',
        altitude: '5483',
        timezone: '2',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Kruger Mpumalanga International Airport',
        city: 'Mpumalanga',
        country: 'South Africa',
        iataCode: 'MQP',
        icaoCode: 'FAKN',
        latitude: '-25,38319969',
        longitude: '31,10560036',
        altitude: '2829',
        timezone: '2',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Malamala Airport',
        city: 'Malamala',
        country: 'South Africa',
        iataCode: 'AAM',
        icaoCode: 'FAMD',
        latitude: '-24,81809998',
        longitude: '31,54459953',
        altitude: '1124',
        timezone: '2',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Mmabatho International Airport',
        city: 'Mafeking',
        country: 'South Africa',
        iataCode: 'MBD',
        icaoCode: 'FAMM',
        latitude: '-25,79840088',
        longitude: '25,54800034',
        altitude: '4181',
        timezone: '2',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Ghanzi Airport',
        city: 'Ghanzi',
        country: 'Botswana',
        iataCode: 'GNZ',
        icaoCode: 'FBGZ',
        latitude: '-21,69249916',
        longitude: '21,65810013',
        altitude: '3730',
        timezone: '2',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Orapa Airport',
        city: 'Orapa',
        country: 'Botswana',
        iataCode: 'ORP',
        icaoCode: 'FBOR',
        latitude: '-21,26670074',
        longitude: '25,31669998',
        altitude: '3100',
        timezone: '2',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Shakawe Airport',
        city: 'Shakawe',
        country: 'Botswana',
        iataCode: 'SWX',
        icaoCode: 'FBSW',
        latitude: '-18,37389946',
        longitude: '21,83259964',
        altitude: '3379',
        timezone: '2',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Limpopo Valley Airport',
        city: 'Tuli Lodge',
        country: 'Botswana',
        iataCode: 'TLD',
        icaoCode: 'FBTL',
        latitude: '-22,18919945',
        longitude: '29,12689972',
        altitude: '1772',
        timezone: '2',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Ngot Nzoungou Airport',
        city: 'Loubomo',
        country: 'Congo (Brazzaville)',
        iataCode: 'DIS',
        icaoCode: 'FCPL',
        latitude: '-4,20635',
        longitude: '12,6599',
        altitude: '1079',
        timezone: '1',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Chipata Airport',
        city: 'Chipata',
        country: 'Zambia',
        iataCode: 'CIP',
        icaoCode: 'FLCP',
        latitude: '-13,55830002',
        longitude: '32,58720016',
        altitude: '3360',
        timezone: '2',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Los Alamitos Army Air Field',
        city: 'Solwesi',
        country: 'Zambia',
        iataCode: 'SLI',
        icaoCode: 'KSLI',
        latitude: '33,79000092',
        longitude: '-118,052002',
        altitude: '32',
        timezone: '-8',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Iconi Airport',
        city: 'Moroni',
        country: 'Comoros',
        iataCode: 'YVA',
        icaoCode: 'FMCN',
        latitude: '-11,71080017',
        longitude: '43,2439003',
        altitude: '33',
        timezone: '3',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Antsalova Airport',
        city: 'Antsalova',
        country: 'Madagascar',
        iataCode: 'WAQ',
        icaoCode: 'FMMG',
        latitude: '-18,70127324',
        longitude: '44,61492062',
        altitude: '551',
        timezone: '3',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Ankavandra Airport',
        city: 'Ankavandra',
        country: 'Madagascar',
        iataCode: 'JVA',
        icaoCode: 'FMMK',
        latitude: '-18,80500952',
        longitude: '45,2734673',
        altitude: '427',
        timezone: '3',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Belo sur Tsiribihina Airport',
        city: 'Belo sur Tsiribihina',
        country: 'Madagascar',
        iataCode: 'BMD',
        icaoCode: 'FMML',
        latitude: '-19,68670082',
        longitude: '44,54190063',
        altitude: '154',
        timezone: '3',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Maintirano Airport',
        city: 'Maintirano',
        country: 'Madagascar',
        iataCode: 'MXT',
        icaoCode: 'FMMO',
        latitude: '-18,04999924',
        longitude: '44,03300095',
        altitude: '95',
        timezone: '3',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Morafenobe Airport',
        city: 'Morafenobe',
        country: 'Madagascar',
        iataCode: 'TVA',
        icaoCode: 'FMMR',
        latitude: '-17,85008346',
        longitude: '44,92046714',
        altitude: '748',
        timezone: '3',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Tambohorano Airport',
        city: 'Tambohorano',
        country: 'Madagascar',
        iataCode: 'WTA',
        icaoCode: 'FMMU',
        latitude: '-17,47610092',
        longitude: '43,97280121',
        altitude: '23',
        timezone: '3',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Tsiroanomandidy Airport',
        city: 'Tsiroanomandidy',
        country: 'Madagascar',
        iataCode: 'WTS',
        icaoCode: 'FMMX',
        latitude: '-18,75967656',
        longitude: '46,05406523',
        altitude: '2776',
        timezone: '3',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Ambatondrazaka Airport',
        city: 'Ambatondrazaka',
        country: 'Madagascar',
        iataCode: 'WAM',
        icaoCode: 'FMMZ',
        latitude: '-17,79537761',
        longitude: '48,44258308',
        altitude: '2513',
        timezone: '3',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Port Bergé Airport',
        city: 'Port Bergé',
        country: 'Madagascar',
        iataCode: 'WPB',
        icaoCode: 'FMNG',
        latitude: '-15,58428647',
        longitude: '47,62358665',
        altitude: '213',
        timezone: '3',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Ambanja Airport',
        city: 'Ambanja',
        country: 'Madagascar',
        iataCode: 'N',
        icaoCode: 'FMNJ',
        latitude: '-13,645705',
        longitude: '48,459427',
        altitude: '36',
        timezone: '3',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Soalala Airport',
        city: 'Soalala',
        country: 'Madagascar',
        iataCode: 'DWB',
        icaoCode: 'FMNO',
        latitude: '-16,10169042',
        longitude: '45,35883665',
        altitude: '141',
        timezone: '3',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Mampikony Airport',
        city: 'Mampikony',
        country: 'Madagascar',
        iataCode: 'WMP',
        icaoCode: 'FMNP',
        latitude: '-16,07226934',
        longitude: '47,64416456',
        altitude: '0',
        timezone: '3',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Nasa Shuttle Landing Facility Airport',
        city: 'Tsaratanana',
        country: 'Madagascar',
        iataCode: 'TTS',
        icaoCode: 'KTTS',
        latitude: '28,61499977',
        longitude: '-80,69450378',
        altitude: '10',
        timezone: '-5',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Mandritsara Airport',
        city: 'Mandritsara',
        country: 'Madagascar',
        iataCode: 'WMA',
        icaoCode: 'FMNX',
        latitude: '-15,83304941',
        longitude: '48,83328438',
        altitude: '1007',
        timezone: '3',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Manja Airport',
        city: 'Manja',
        country: 'Madagascar',
        iataCode: 'MJA',
        icaoCode: 'FMSJ',
        latitude: '-21,42610525',
        longitude: '44,31650877',
        altitude: '787',
        timezone: '3',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Catumbela Airport',
        city: 'Catumbela',
        country: 'Angola',
        iataCode: 'CBT',
        icaoCode: 'FNCT',
        latitude: '-12,4792',
        longitude: '13,4869',
        altitude: '23',
        timezone: '1',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Dundo Airport',
        city: 'Dundo',
        country: 'Angola',
        iataCode: 'DUE',
        icaoCode: 'FNDU',
        latitude: '-7,400889874',
        longitude: '20,81850052',
        altitude: '2451',
        timezone: '1',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Ngjiva Pereira Airport',
        city: 'Ondjiva',
        country: 'Angola',
        iataCode: 'VPE',
        icaoCode: 'FNGI',
        latitude: '-17,0435009',
        longitude: '15,68379974',
        altitude: '3566',
        timezone: '1',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Namibe Airport',
        city: 'Mocamedes',
        country: 'Angola',
        iataCode: 'MSZ',
        icaoCode: 'FNMO',
        latitude: '-15,26119995',
        longitude: '12,14680004',
        altitude: '210',
        timezone: '1',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Koulamoutou Mabimbi Airport',
        city: 'Koulamoutou',
        country: 'Gabon',
        iataCode: 'KOU',
        icaoCode: 'FOGK',
        latitude: '-1,184610009',
        longitude: '12,44130039',
        altitude: '1070',
        timezone: '1',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Mouilla Ville Airport',
        city: 'Mouila',
        country: 'Gabon',
        iataCode: 'MJL',
        icaoCode: 'FOGM',
        latitude: '-1,84513998',
        longitude: '11,05669975',
        altitude: '295',
        timezone: '1',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Tchibanga Airport',
        city: 'Tchibanga',
        country: 'Gabon',
        iataCode: 'TCH',
        icaoCode: 'FOOT',
        latitude: '-2,849999905',
        longitude: '11,0170002',
        altitude: '269',
        timezone: '1',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Chimoio Airport',
        city: 'Chimoio',
        country: 'Mozambique',
        iataCode: 'VPY',
        icaoCode: 'FQCH',
        latitude: '-19,15130043',
        longitude: '33,42900085',
        altitude: '2287',
        timezone: '2',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Sarh Airport',
        city: 'Sarh',
        country: 'Chad',
        iataCode: 'SRH',
        icaoCode: 'FTTA',
        latitude: '9,144439697',
        longitude: '18,37439919',
        altitude: '1198',
        timezone: '1',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Club Makokola Airport',
        city: 'Club Makokola',
        country: 'Malawi',
        iataCode: 'CMK',
        icaoCode: 'FWCM',
        latitude: '-14,30690002',
        longitude: '35,13249969',
        altitude: '1587',
        timezone: '2',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Luderitz Airport',
        city: 'Luderitz',
        country: 'Namibia',
        iataCode: 'LUD',
        icaoCode: 'FYLZ',
        latitude: '-26,68740082',
        longitude: '15,24289989',
        altitude: '457',
        timezone: '1',
        dst: 'S',
        type: 'airport',
    },
    {
        name: 'Ondangwa Airport',
        city: 'Ondangwa',
        country: 'Namibia',
        iataCode: 'OND',
        icaoCode: 'FYOA',
        latitude: '-17,878201',
        longitude: '15,9526',
        altitude: '3599',
        timezone: '1',
        dst: 'S',
        type: 'airport',
    },
    {
        name: 'Oranjemund Airport',
        city: 'Oranjemund',
        country: 'Namibia',
        iataCode: 'OMD',
        icaoCode: 'FYOG',
        latitude: '-28,58469963',
        longitude: '16,44669914',
        altitude: '14',
        timezone: '1',
        dst: 'S',
        type: 'airport',
    },
    {
        name: 'Swakopmund Airport',
        city: 'Swakopmund',
        country: 'Namibia',
        iataCode: 'SWP',
        icaoCode: 'FYSM',
        latitude: '-22,66189957',
        longitude: '14,56809998',
        altitude: '207',
        timezone: '1',
        dst: 'S',
        type: 'airport',
    },
    {
        name: 'Eros Airport',
        city: 'Windhoek',
        country: 'Namibia',
        iataCode: 'ERS',
        icaoCode: 'FYWE',
        latitude: '-22,61219978',
        longitude: '17,08040047',
        altitude: '5575',
        timezone: '1',
        dst: 'S',
        type: 'airport',
    },
    {
        name: 'Boma Airport',
        city: 'Boma',
        country: 'Congo (Kinshasa)',
        iataCode: 'BOA',
        icaoCode: 'FZAJ',
        latitude: '-5,854000092',
        longitude: '13,06400013',
        altitude: '26',
        timezone: '1',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Tshimpi Airport',
        city: 'Matadi',
        country: 'Congo (Kinshasa)',
        iataCode: 'MAT',
        icaoCode: 'FZAM',
        latitude: '-5,799610138',
        longitude: '13,44040012',
        altitude: '1115',
        timezone: '1',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Inongo Airport',
        city: 'Inongo',
        country: 'Congo (Kinshasa)',
        iataCode: 'INO',
        icaoCode: 'FZBA',
        latitude: '-1,947219968',
        longitude: '18,28580093',
        altitude: '1040',
        timezone: '1',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Nioki Airport',
        city: 'Nioki',
        country: 'Congo (Kinshasa)',
        iataCode: 'NIO',
        icaoCode: 'FZBI',
        latitude: '-2,717499971',
        longitude: '17,68470001',
        altitude: '1043',
        timezone: '1',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Basango Mboliasa Airport',
        city: 'Kiri',
        country: 'Congo (Kinshasa)',
        iataCode: 'KRZ',
        icaoCode: 'FZBT',
        latitude: '-1,434999943',
        longitude: '19,02400017',
        altitude: '1013',
        timezone: '1',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Basankusu Airport',
        city: 'Basankusu',
        country: 'Congo (Kinshasa)',
        iataCode: 'BSU',
        icaoCode: 'FZEN',
        latitude: '1,224720001',
        longitude: '19,78890038',
        altitude: '1217',
        timezone: '1',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Tshikapa Airport',
        city: 'Tshikapa',
        country: 'Congo (Kinshasa)',
        iataCode: 'TSH',
        icaoCode: 'FZUK',
        latitude: '-6,438330173',
        longitude: '20,79470062',
        altitude: '1595',
        timezone: '2',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Lodja Airport',
        city: 'Lodja',
        country: 'Congo (Kinshasa)',
        iataCode: 'LJA',
        icaoCode: 'FZVA',
        latitude: '-3,417000055',
        longitude: '23,45000076',
        altitude: '1647',
        timezone: '2',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Ilebo Airport',
        city: 'Ilebo',
        country: 'Congo (Kinshasa)',
        iataCode: 'PFR',
        icaoCode: 'FZVS',
        latitude: '-4,329919',
        longitude: '20,590124',
        altitude: '1450',
        timezone: '2',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'La Gomera Airport',
        city: 'La Gomera',
        country: 'Spain',
        iataCode: 'GMZ',
        icaoCode: 'GCGM',
        latitude: '28,02960014',
        longitude: '-17,21459961',
        altitude: '716',
        timezone: '0',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Sherbro International Airport',
        city: 'Bonthe',
        country: 'Sierra Leone',
        iataCode: 'BTE',
        icaoCode: 'GFBN',
        latitude: '7,532420158',
        longitude: '-12,51889992',
        altitude: '14',
        timezone: '0',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Bo Airport',
        city: 'Bo',
        country: 'Sierra Leone',
        iataCode: 'KBS',
        icaoCode: 'GFBO',
        latitude: '7,944399834',
        longitude: '-11,76099968',
        altitude: '328',
        timezone: '0',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Kenema Airport',
        city: 'Kenema',
        country: 'Sierra Leone',
        iataCode: 'KEN',
        icaoCode: 'GFKE',
        latitude: '7,891290188',
        longitude: '-11,17660046',
        altitude: '485',
        timezone: '0',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Osvaldo Vieira International Airport',
        city: 'Bissau',
        country: 'Guinea-Bissau',
        iataCode: 'OXB',
        icaoCode: 'GGOV',
        latitude: '11,89480019',
        longitude: '-15,65369987',
        altitude: '129',
        timezone: '0',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Smara Airport',
        city: 'Smara',
        country: 'Western Sahara',
        iataCode: 'SMW',
        icaoCode: 'GMMA',
        latitude: '26,7318',
        longitude: '-11,6847',
        altitude: '350',
        timezone: '0',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Dakhla Airport',
        city: 'Dakhla',
        country: 'Western Sahara',
        iataCode: 'VIL',
        icaoCode: 'GMMH',
        latitude: '23,7183',
        longitude: '-15,932',
        altitude: '36',
        timezone: '0',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Mogador Airport',
        city: 'Essadouira',
        country: 'Morocco',
        iataCode: 'ESU',
        icaoCode: 'GMMI',
        latitude: '31,39749908',
        longitude: '-9,681670189',
        altitude: '384',
        timezone: '0',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Hassan I Airport',
        city: 'El Aaiún',
        country: 'Western Sahara',
        iataCode: 'EUN',
        icaoCode: 'GMML',
        latitude: '27,151699',
        longitude: '-13,2192',
        altitude: '207',
        timezone: '0',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Nador International Airport',
        city: 'El Aroui',
        country: 'Morocco',
        iataCode: 'NDR',
        icaoCode: 'GMMW',
        latitude: '34,98880005',
        longitude: '-3,028209925',
        altitude: '574',
        timezone: '0',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Praia International Airport',
        city: 'Praia, Santiago Island',
        country: 'Cape Verde',
        iataCode: 'RAI',
        icaoCode: 'GVNP',
        latitude: '14,92450047',
        longitude: '-23,49349976',
        altitude: '230',
        timezone: '-1',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'São Filipe Airport',
        city: 'Sao Filipe, Fogo Island',
        country: 'Cape Verde',
        iataCode: 'SFL',
        icaoCode: 'GVSF',
        latitude: '14,88500023',
        longitude: '-24,47999954',
        altitude: '617',
        timezone: '-1',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Baco Airport',
        city: 'Baco',
        country: 'Ethiopia',
        iataCode: 'BCO',
        icaoCode: 'HABC',
        latitude: '5,78287',
        longitude: '36,562',
        altitude: '4580',
        timezone: '3',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Beica Airport',
        city: 'Beica',
        country: 'Ethiopia',
        iataCode: 'BEI',
        icaoCode: 'HABE',
        latitude: '9,386389732',
        longitude: '34,52190018',
        altitude: '5410',
        timezone: '3',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Combolcha Airport',
        city: 'Dessie',
        country: 'Ethiopia',
        iataCode: 'DSE',
        icaoCode: 'HADC',
        latitude: '11,08250046',
        longitude: '39,71139908',
        altitude: '6117',
        timezone: '3',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Dembidollo Airport',
        city: 'Dembidollo',
        country: 'Ethiopia',
        iataCode: 'DEM',
        icaoCode: 'HADD',
        latitude: '8,553999901',
        longitude: '34,85800171',
        altitude: '5200',
        timezone: '3',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Gode Airport',
        city: 'Gode',
        country: 'Ethiopia',
        iataCode: 'GDE',
        icaoCode: 'HAGO',
        latitude: '5,935130119',
        longitude: '43,57860184',
        altitude: '834',
        timezone: '3',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Gore Airport',
        city: 'Gore',
        country: 'Ethiopia',
        iataCode: 'GOR',
        icaoCode: 'HAGR',
        latitude: '8,1614',
        longitude: '35,5529',
        altitude: '6580',
        timezone: '3',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Kabri Dehar Airport',
        city: 'Kabri Dehar',
        country: 'Ethiopia',
        iataCode: 'ABK',
        icaoCode: 'HAKD',
        latitude: '6,734000206',
        longitude: '44,25299835',
        altitude: '1800',
        timezone: '3',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Mizan Teferi Airport',
        city: 'Mizan Teferi',
        country: 'Ethiopia',
        iataCode: 'MTF',
        icaoCode: 'HAMT',
        latitude: '6,9571',
        longitude: '35,5547',
        altitude: '4396',
        timezone: '3',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Tippi Airport',
        city: 'Tippi',
        country: 'Ethiopia',
        iataCode: 'TIE',
        icaoCode: 'HATP',
        latitude: '7,2024',
        longitude: '35,415',
        altitude: '1100',
        timezone: '3',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Alula Airport',
        city: 'Alula',
        country: 'Somalia',
        iataCode: 'ALU',
        icaoCode: 'HCMA',
        latitude: '11,9582',
        longitude: '50,748',
        altitude: '6',
        timezone: '3',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Bosaso Airport',
        city: 'Bosaso',
        country: 'Somalia',
        iataCode: 'BSA',
        icaoCode: 'HCMF',
        latitude: '11,27530003',
        longitude: '49,1493988',
        altitude: '3',
        timezone: '3',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Aden Adde International Airport',
        city: 'Mogadishu',
        country: 'Somalia',
        iataCode: 'MGQ',
        icaoCode: 'HCMM',
        latitude: '2,01444006',
        longitude: '45,30469894',
        altitude: '29',
        timezone: '3',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Galcaio Airport',
        city: 'Galcaio',
        country: 'Somalia',
        iataCode: 'GLK',
        icaoCode: 'HCMR',
        latitude: '6,780829906',
        longitude: '47,45470047',
        altitude: '975',
        timezone: '3',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Burao Airport',
        city: 'Burao',
        country: 'Somalia',
        iataCode: 'BUO',
        icaoCode: 'HCMV',
        latitude: '9,5275',
        longitude: '45,5549',
        altitude: '3400',
        timezone: '3',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'El Arish International Airport',
        city: 'El Arish',
        country: 'Egypt',
        iataCode: 'AAC',
        icaoCode: 'HEAR',
        latitude: '31,07329941',
        longitude: '33,83580017',
        altitude: '121',
        timezone: '2',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Assiut International Airport',
        city: 'Asyut',
        country: 'Egypt',
        iataCode: 'ATZ',
        icaoCode: 'HEAT',
        latitude: '27,04649925',
        longitude: '31,01199913',
        altitude: '772',
        timezone: '2',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Amboseli Airport',
        city: 'Amboseli National Park',
        country: 'Kenya',
        iataCode: 'ASV',
        icaoCode: 'HKAM',
        latitude: '-2,645050049',
        longitude: '37,25310135',
        altitude: '3755',
        timezone: '3',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Lokichoggio Airport',
        city: 'Lokichoggio',
        country: 'Kenya',
        iataCode: 'LKG',
        icaoCode: 'HKLK',
        latitude: '4,204120159',
        longitude: '34,34820175',
        altitude: '2074',
        timezone: '3',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Malindi Airport',
        city: 'Malindi',
        country: 'Kenya',
        iataCode: 'MYD',
        icaoCode: 'HKML',
        latitude: '-3,229310036',
        longitude: '40,10169983',
        altitude: '80',
        timezone: '3',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Nanyuki Airport',
        city: 'Nanyuki',
        country: 'Kenya',
        iataCode: 'NYK',
        icaoCode: 'HKNY',
        latitude: '-0,062398899',
        longitude: '37,041008',
        altitude: '6250',
        timezone: '3',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Gardabya Airport',
        city: 'Sirt',
        country: 'Libya',
        iataCode: 'SRX',
        icaoCode: 'HLGD',
        latitude: '31,06349945',
        longitude: '16,59499931',
        altitude: '267',
        timezone: '2',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Gamal Abdel Nasser Airport',
        city: 'Tobruk',
        country: 'Libya',
        iataCode: 'TOB',
        icaoCode: 'HLGN',
        latitude: '31,861',
        longitude: '23,907',
        altitude: '519',
        timezone: '2',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Mitiga Airport',
        city: 'Tripoli',
        country: 'Libya',
        iataCode: 'MJI',
        icaoCode: 'HLLM',
        latitude: '32,89410019',
        longitude: '13,27600002',
        altitude: '36',
        timezone: '2',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'La Abraq Airport',
        city: "Al Bayda'",
        country: 'Libya',
        iataCode: 'LAQ',
        icaoCode: 'HLLQ',
        latitude: '32,7887001',
        longitude: '21,96430016',
        altitude: '2157',
        timezone: '2',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Atbara Airport',
        city: 'Atbara',
        country: 'Sudan',
        iataCode: 'ATB',
        icaoCode: 'HSAT',
        latitude: '17,71034431',
        longitude: '34,05701828',
        altitude: '1181',
        timezone: '3',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Nyala Airport',
        city: 'Nyala',
        country: 'Sudan',
        iataCode: 'UYL',
        icaoCode: 'HSNN',
        latitude: '12,05350018',
        longitude: '24,95619965',
        altitude: '2106',
        timezone: '3',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Port Sudan New International Airport',
        city: 'Port Sudan',
        country: 'Sudan',
        iataCode: 'PZU',
        icaoCode: 'HSPN',
        latitude: '19,43359947',
        longitude: '37,23410034',
        altitude: '135',
        timezone: '3',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Bukoba Airport',
        city: 'Bukoba',
        country: 'Tanzania',
        iataCode: 'BKZ',
        icaoCode: 'HTBU',
        latitude: '-1,332',
        longitude: '31,8212',
        altitude: '3745',
        timezone: '3',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Kigoma Airport',
        city: 'Kigoma',
        country: 'Tanzania',
        iataCode: 'TKQ',
        icaoCode: 'HTKA',
        latitude: '-4,8862',
        longitude: '29,6709',
        altitude: '2700',
        timezone: '3',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Kikwetu Airport',
        city: 'Lindi',
        country: 'Tanzania',
        iataCode: 'LDI',
        icaoCode: 'HTLI',
        latitude: '-9,851110458',
        longitude: '39,75780106',
        altitude: '100',
        timezone: '3',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Musoma Airport',
        city: 'Musoma',
        country: 'Tanzania',
        iataCode: 'MUZ',
        icaoCode: 'HTMU',
        latitude: '-1,503',
        longitude: '33,8021',
        altitude: '3806',
        timezone: '3',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Shinyanga Airport',
        city: 'Shinyanga',
        country: 'Tanzania',
        iataCode: 'SHY',
        icaoCode: 'HTSY',
        latitude: '-3,6093',
        longitude: '33,5035',
        altitude: '3800',
        timezone: '3',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Tabora Airport',
        city: 'Tabora',
        country: 'Tanzania',
        iataCode: 'TBO',
        icaoCode: 'HTTB',
        latitude: '-5,07638979',
        longitude: '32,83330154',
        altitude: '3868',
        timezone: '3',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Arua Airport',
        city: 'Arua',
        country: 'Uganda',
        iataCode: 'RUA',
        icaoCode: 'HUAR',
        latitude: '3,049999952',
        longitude: '30,91699982',
        altitude: '3951',
        timezone: '3',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Gulu Airport',
        city: 'Gulu',
        country: 'Uganda',
        iataCode: 'ULU',
        icaoCode: 'HUGU',
        latitude: '2,805560112',
        longitude: '32,27180099',
        altitude: '3510',
        timezone: '3',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Diu Airport',
        city: 'Diu',
        country: 'India',
        iataCode: 'DIU',
        icaoCode: 'VA1P',
        latitude: '20,71310043',
        longitude: '70,9210968',
        altitude: '31',
        timezone: '5,5',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Aberdeen Regional Airport',
        city: 'Aberdeen',
        country: 'United States',
        iataCode: 'ABR',
        icaoCode: 'KABR',
        latitude: '45,44910049',
        longitude: '-98,42179871',
        altitude: '1302',
        timezone: '-6',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Southwest Georgia Regional Airport',
        city: 'Albany',
        country: 'United States',
        iataCode: 'ABY',
        icaoCode: 'KABY',
        latitude: '31,53549957',
        longitude: '-84,19450378',
        altitude: '197',
        timezone: '-5',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Athens Ben Epps Airport',
        city: 'Athens',
        country: 'United States',
        iataCode: 'AHN',
        icaoCode: 'KAHN',
        latitude: '33,94860077',
        longitude: '-83,32630157',
        altitude: '808',
        timezone: '-5',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Alamogordo White Sands Regional Airport',
        city: 'Alamogordo',
        country: 'United States',
        iataCode: 'ALM',
        icaoCode: 'KALM',
        latitude: '32,83990097',
        longitude: '-105,9909973',
        altitude: '4200',
        timezone: '-7',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Waterloo Regional Airport',
        city: 'Waterloo',
        country: 'United States',
        iataCode: 'ALO',
        icaoCode: 'KALO',
        latitude: '42,55709839',
        longitude: '-92,40029907',
        altitude: '873',
        timezone: '-6',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Walla Walla Regional Airport',
        city: 'Walla Walla',
        country: 'United States',
        iataCode: 'ALW',
        icaoCode: 'KALW',
        latitude: '46,09489822',
        longitude: '-118,288002',
        altitude: '1194',
        timezone: '-8',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Alpena County Regional Airport',
        city: 'Alpena',
        country: 'United States',
        iataCode: 'APN',
        icaoCode: 'KAPN',
        latitude: '45,0780983',
        longitude: '-83,56030273',
        altitude: '690',
        timezone: '-5',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Watertown Regional Airport',
        city: 'Watertown',
        country: 'United States',
        iataCode: 'ATY',
        icaoCode: 'KATY',
        latitude: '44,91400146',
        longitude: '-97,15470123',
        altitude: '1749',
        timezone: '-6',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Bradford Regional Airport',
        city: 'Bradford',
        country: 'United States',
        iataCode: 'BFD',
        icaoCode: 'KBFD',
        latitude: '41,80310059',
        longitude: '-78,64009857',
        altitude: '2143',
        timezone: '-5',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Western Neb. Rgnl/William B. Heilig Airport',
        city: 'Scottsbluff',
        country: 'United States',
        iataCode: 'BFF',
        icaoCode: 'KBFF',
        latitude: '41,87400055',
        longitude: '-103,5960007',
        altitude: '3967',
        timezone: '-7',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Raleigh County Memorial Airport',
        city: 'Beckley',
        country: 'United States',
        iataCode: 'BKW',
        icaoCode: 'KBKW',
        latitude: '37,78730011',
        longitude: '-81,12419891',
        altitude: '2504',
        timezone: '-5',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Brunswick Golden Isles Airport',
        city: 'Brunswick',
        country: 'United States',
        iataCode: 'BQK',
        icaoCode: 'KBQK',
        latitude: '31,25880051',
        longitude: '-81,46649933',
        altitude: '26',
        timezone: '-5',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Southeast Iowa Regional Airport',
        city: 'Burlington',
        country: 'United States',
        iataCode: 'BRL',
        icaoCode: 'KBRL',
        latitude: '40,78319931',
        longitude: '-91,12550354',
        altitude: '698',
        timezone: '-6',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Jack Mc Namara Field Airport',
        city: 'Crescent City',
        country: 'United States',
        iataCode: 'CEC',
        icaoCode: 'KCEC',
        latitude: '41,78020096',
        longitude: '-124,2369995',
        altitude: '61',
        timezone: '-8',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Cape Girardeau Regional Airport',
        city: 'Cape Girardeau',
        country: 'United States',
        iataCode: 'CGI',
        icaoCode: 'KCGI',
        latitude: '37,22529984',
        longitude: '-89,57080078',
        altitude: '342',
        timezone: '-6',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Chippewa County International Airport',
        city: 'Sault Ste Marie',
        country: 'United States',
        iataCode: 'CIU',
        icaoCode: 'KCIU',
        latitude: '46,25080109',
        longitude: '-84,47239685',
        altitude: '800',
        timezone: '-5',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'North Central West Virginia Airport',
        city: 'Clarksburg',
        country: 'United States',
        iataCode: 'CKB',
        icaoCode: 'KCKB',
        latitude: '39,29660034',
        longitude: '-80,22810364',
        altitude: '1217',
        timezone: '-5',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'William R Fairchild International Airport',
        city: 'Port Angeles',
        country: 'United States',
        iataCode: 'CLM',
        icaoCode: 'KCLM',
        latitude: '48,12020111',
        longitude: '-123,5',
        altitude: '291',
        timezone: '-8',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Houghton County Memorial Airport',
        city: 'Hancock',
        country: 'United States',
        iataCode: 'CMX',
        icaoCode: 'KCMX',
        latitude: '47,16839981',
        longitude: '-88,4890976',
        altitude: '1095',
        timezone: '-5',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Dodge City Regional Airport',
        city: 'Dodge City',
        country: 'United States',
        iataCode: 'DDC',
        icaoCode: 'KDDC',
        latitude: '37,76340103',
        longitude: '-99,96559906',
        altitude: '2594',
        timezone: '-6',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'DuBois Regional Airport',
        city: 'Du Bois',
        country: 'United States',
        iataCode: 'DUJ',
        icaoCode: 'KDUJ',
        latitude: '41,17829895',
        longitude: '-78,8986969',
        altitude: '1817',
        timezone: '-5',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Chippewa Valley Regional Airport',
        city: 'Eau Claire',
        country: 'United States',
        iataCode: 'EAU',
        icaoCode: 'KEAU',
        latitude: '44,86579895',
        longitude: '-91,48429871',
        altitude: '913',
        timezone: '-6',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Elko Regional Airport',
        city: 'Elko',
        country: 'United States',
        iataCode: 'EKO',
        icaoCode: 'KEKO',
        latitude: '40,82490158',
        longitude: '-115,7919998',
        altitude: '5140',
        timezone: '-8',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'New Bedford Regional Airport',
        city: 'New Bedford',
        country: 'United States',
        iataCode: 'EWB',
        icaoCode: 'KEWB',
        latitude: '41,67610168',
        longitude: '-70,95690155',
        altitude: '80',
        timezone: '-5',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Fayetteville Regional Grannis Field',
        city: 'Fayetteville',
        country: 'United States',
        iataCode: 'FAY',
        icaoCode: 'KFAY',
        latitude: '34,99119949',
        longitude: '-78,88030243',
        altitude: '189',
        timezone: '-5',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Wokal Field Glasgow International Airport',
        city: 'Glasgow',
        country: 'United States',
        iataCode: 'GGW',
        icaoCode: 'KGGW',
        latitude: '48,21250153',
        longitude: '-106,6149979',
        altitude: '2296',
        timezone: '-7',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Central Nebraska Regional Airport',
        city: 'Grand Island',
        country: 'United States',
        iataCode: 'GRI',
        icaoCode: 'KGRI',
        latitude: '40,96749878',
        longitude: '-98,30960083',
        altitude: '1847',
        timezone: '-6',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Memorial Field',
        city: 'Hot Springs',
        country: 'United States',
        iataCode: 'HOT',
        icaoCode: 'KHOT',
        latitude: '34,47800064',
        longitude: '-93,09619904',
        altitude: '540',
        timezone: '-6',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Tri-State/Milton J. Ferguson Field',
        city: 'Huntington',
        country: 'United States',
        iataCode: 'HTS',
        icaoCode: 'KHTS',
        latitude: '38,36669922',
        longitude: '-82,55799866',
        altitude: '828',
        timezone: '-5',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Kili Airport',
        city: 'Kili Island',
        country: 'Marshall Islands',
        iataCode: 'KIO',
        icaoCode: 'Q51',
        latitude: '5,644515038',
        longitude: '169,1195068',
        altitude: '5',
        timezone: '12',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Kirksville Regional Airport',
        city: 'Kirksville',
        country: 'United States',
        iataCode: 'IRK',
        icaoCode: 'KIRK',
        latitude: '40,09349823',
        longitude: '-92,54489899',
        altitude: '966',
        timezone: '-6',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Jamestown Regional Airport',
        city: 'Jamestown',
        country: 'United States',
        iataCode: 'JMS',
        icaoCode: 'KJMS',
        latitude: '46,92969894',
        longitude: '-98,67819977',
        altitude: '1500',
        timezone: '-6',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Laramie Regional Airport',
        city: 'Laramie',
        country: 'United States',
        iataCode: 'LAR',
        icaoCode: 'KLAR',
        latitude: '41,31209946',
        longitude: '-105,6750031',
        altitude: '7284',
        timezone: '-7',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Arnold Palmer Regional Airport',
        city: 'Latrobe',
        country: 'United States',
        iataCode: 'LBE',
        icaoCode: 'KLBE',
        latitude: '40,27590179',
        longitude: '-79,40480042',
        altitude: '1199',
        timezone: '-5',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'North Platte Regional Airport Lee Bird Field',
        city: 'North Platte',
        country: 'United States',
        iataCode: 'LBF',
        icaoCode: 'KLBF',
        latitude: '41,12620163',
        longitude: '-100,6839981',
        altitude: '2777',
        timezone: '-6',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Lebanon Municipal Airport',
        city: 'Lebanon',
        country: 'United States',
        iataCode: 'LEB',
        icaoCode: 'KLEB',
        latitude: '43,62609863',
        longitude: '-72,30419922',
        altitude: '603',
        timezone: '-5',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Klamath Falls Airport',
        city: 'Klamath Falls',
        country: 'United States',
        iataCode: 'LMT',
        icaoCode: 'KLMT',
        latitude: '42,15610123',
        longitude: '-121,7330017',
        altitude: '4095',
        timezone: '-8',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Lancaster Airport',
        city: 'Lancaster',
        country: 'United States',
        iataCode: 'LNS',
        icaoCode: 'KLNS',
        latitude: '40,12170029',
        longitude: '-76,2960968',
        altitude: '403',
        timezone: '-5',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Lewistown Municipal Airport',
        city: 'Lewistown',
        country: 'United States',
        iataCode: 'LWT',
        icaoCode: 'KLWT',
        latitude: '47,04930115',
        longitude: '-109,4670029',
        altitude: '4170',
        timezone: '-7',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Lynchburg Regional Preston Glenn Field',
        city: 'Lynchburg',
        country: 'United States',
        iataCode: 'LYH',
        icaoCode: 'KLYH',
        latitude: '37,3266983',
        longitude: '-79,20040131',
        altitude: '938',
        timezone: '-5',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Muskegon County Airport',
        city: 'Muskegon',
        country: 'United States',
        iataCode: 'MKG',
        icaoCode: 'KMKG',
        latitude: '43,16949844',
        longitude: '-86,23819733',
        altitude: '629',
        timezone: '-5',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Frank Wiley Field',
        city: 'Miles City',
        country: 'United States',
        iataCode: 'MLS',
        icaoCode: 'KMLS',
        latitude: '46,4280014',
        longitude: '-105,8860016',
        altitude: '2630',
        timezone: '-7',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Northwest Alabama Regional Airport',
        city: 'Muscle Shoals',
        country: 'United States',
        iataCode: 'MSL',
        icaoCode: 'KMSL',
        latitude: '34,74530029',
        longitude: '-87,61019897',
        altitude: '551',
        timezone: '-6',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Southwest Oregon Regional Airport',
        city: 'North Bend',
        country: 'United States',
        iataCode: 'OTH',
        icaoCode: 'KOTH',
        latitude: '43,417099',
        longitude: '-124,2460022',
        altitude: '17',
        timezone: '-8',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Owensboro Daviess County Airport',
        city: 'Owensboro',
        country: 'United States',
        iataCode: 'OWB',
        icaoCode: 'KOWB',
        latitude: '37,74010086',
        longitude: '-87,16680145',
        altitude: '407',
        timezone: '-6',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Hattiesburg Laurel Regional Airport',
        city: 'Hattiesburg/Laurel',
        country: 'United States',
        iataCode: 'PIB',
        icaoCode: 'KPIB',
        latitude: '31,46710014',
        longitude: '-89,33709717',
        altitude: '298',
        timezone: '-6',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Pocatello Regional Airport',
        city: 'Pocatello',
        country: 'United States',
        iataCode: 'PIH',
        icaoCode: 'KPIH',
        latitude: '42,90980148',
        longitude: '-112,5960007',
        altitude: '4452',
        timezone: '-7',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Pierre Regional Airport',
        city: 'Pierre',
        country: 'United States',
        iataCode: 'PIR',
        icaoCode: 'KPIR',
        latitude: '44,38270187',
        longitude: '-100,2860031',
        altitude: '1744',
        timezone: '-6',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Pellston Regional Airport of Emmet County Airport',
        city: 'Pellston',
        country: 'United States',
        iataCode: 'PLN',
        icaoCode: 'KPLN',
        latitude: '45,57089996',
        longitude: '-84,79669952',
        altitude: '721',
        timezone: '-5',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Portsmouth International at Pease Airport',
        city: 'Portsmouth',
        country: 'United States',
        iataCode: 'PSM',
        icaoCode: 'KPSM',
        latitude: '43,07789993',
        longitude: '-70,82330322',
        altitude: '100',
        timezone: '-5',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Reading Regional Carl A Spaatz Field',
        city: 'Reading',
        country: 'United States',
        iataCode: 'RDG',
        icaoCode: 'KRDG',
        latitude: '40,37850189',
        longitude: '-75,96520233',
        altitude: '344',
        timezone: '-5',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Rhinelander Oneida County Airport',
        city: 'Rhinelander',
        country: 'United States',
        iataCode: 'RHI',
        icaoCode: 'KRHI',
        latitude: '45,63119888',
        longitude: '-89,46749878',
        altitude: '1624',
        timezone: '-6',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Rock Springs Sweetwater County Airport',
        city: 'Rock Springs',
        country: 'United States',
        iataCode: 'RKS',
        icaoCode: 'KRKS',
        latitude: '41,59420013',
        longitude: '-109,0650024',
        altitude: '6764',
        timezone: '-7',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Rutland - Southern Vermont Regional Airport',
        city: 'Rutland',
        country: 'United States',
        iataCode: 'RUT',
        icaoCode: 'KRUT',
        latitude: '43,52939987',
        longitude: '-72,94960022',
        altitude: '787',
        timezone: '-5',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'San Luis County Regional Airport',
        city: 'San Luis Obispo',
        country: 'United States',
        iataCode: 'SBP',
        icaoCode: 'KSBP',
        latitude: '35,23680115',
        longitude: '-120,6419983',
        altitude: '212',
        timezone: '-8',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Sheridan County Airport',
        city: 'Sheridan',
        country: 'United States',
        iataCode: 'SHR',
        icaoCode: 'KSHR',
        latitude: '44,76919937',
        longitude: '-106,9800034',
        altitude: '4021',
        timezone: '-7',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Adirondack Regional Airport',
        city: 'Saranac Lake',
        country: 'United States',
        iataCode: 'SLK',
        icaoCode: 'KSLK',
        latitude: '44,38529968',
        longitude: '-74,20619965',
        altitude: '1663',
        timezone: '-5',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Salina Municipal Airport',
        city: 'Salina',
        country: 'United States',
        iataCode: 'SLN',
        icaoCode: 'KSLN',
        latitude: '38,79100037',
        longitude: '-97,65219879',
        altitude: '1288',
        timezone: '-6',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Santa Maria Pub/Capt G Allan Hancock Field',
        city: 'Santa Maria',
        country: 'United States',
        iataCode: 'SMX',
        icaoCode: 'KSMX',
        latitude: '34,89889908',
        longitude: '-120,4570007',
        altitude: '261',
        timezone: '-8',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Tupelo Regional Airport',
        city: 'Tupelo',
        country: 'United States',
        iataCode: 'TUP',
        icaoCode: 'KTUP',
        latitude: '34,26810074',
        longitude: '-88,76989746',
        altitude: '346',
        timezone: '-6',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Quincy Regional Baldwin Field',
        city: 'Quincy',
        country: 'United States',
        iataCode: 'UIN',
        icaoCode: 'KUIN',
        latitude: '39,94269943',
        longitude: '-91,19460297',
        altitude: '768',
        timezone: '-6',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Victoria Regional Airport',
        city: 'Victoria',
        country: 'United States',
        iataCode: 'VCT',
        icaoCode: 'KVCT',
        latitude: '28,8526001',
        longitude: '-96,91850281',
        altitude: '115',
        timezone: '-6',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Valdosta Regional Airport',
        city: 'Valdosta',
        country: 'United States',
        iataCode: 'VLD',
        icaoCode: 'KVLD',
        latitude: '30,78249931',
        longitude: '-83,27670288',
        altitude: '203',
        timezone: '-5',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Worland Municipal Airport',
        city: 'Worland',
        country: 'United States',
        iataCode: 'WRL',
        icaoCode: 'KWRL',
        latitude: '43,96569824',
        longitude: '-107,9509964',
        altitude: '4227',
        timezone: '-7',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Yakima Air Terminal McAllister Field',
        city: 'Yakima',
        country: 'United States',
        iataCode: 'YKM',
        icaoCode: 'KYKM',
        latitude: '46,56819916',
        longitude: '-120,5439987',
        altitude: '1099',
        timezone: '-8',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Ercan International Airport',
        city: 'Nicosia',
        country: 'Cyprus',
        iataCode: 'ECN',
        icaoCode: 'LCEN',
        latitude: '35,15470123',
        longitude: '33,49610138',
        altitude: '404',
        timezone: '2',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Logroño-Agoncillo Airport',
        city: 'Logroño-Agoncillo',
        country: 'Spain',
        iataCode: 'RJL',
        icaoCode: 'LELO',
        latitude: '42,46095349',
        longitude: '-2,322235107',
        altitude: '1161',
        timezone: '1',
        dst: 'E',
        type: 'airport',
    },
    {
        name: "Île d'Yeu Airport",
        city: "Île d'Yeu",
        country: 'France',
        iataCode: 'IDY',
        icaoCode: 'LFEY',
        latitude: '46,71860123',
        longitude: '-2,391109943',
        altitude: '79',
        timezone: '1',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Angers-Loire Airport',
        city: 'Angers/Marcé',
        country: 'France',
        iataCode: 'ANE',
        icaoCode: 'LFJR',
        latitude: '47,56029892',
        longitude: '-0,312222004',
        altitude: '194',
        timezone: '1',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'La Môle Airport',
        city: 'La Môle',
        country: 'France',
        iataCode: 'LTT',
        icaoCode: 'LFTZ',
        latitude: '43,20539856',
        longitude: '6,481999874',
        altitude: '59',
        timezone: '1',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Syros Airport',
        city: 'Syros Island',
        country: 'Greece',
        iataCode: 'JSY',
        icaoCode: 'LGSO',
        latitude: '37,42279816',
        longitude: '24,95089912',
        altitude: '236',
        timezone: '2',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Pécs-Pogány Airport',
        city: 'Pécs-Pogány',
        country: 'Hungary',
        iataCode: 'PEV',
        icaoCode: 'LHPP',
        latitude: '45,990898',
        longitude: '18,240996',
        altitude: '1000',
        timezone: '1',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Győr-Pér International Airport',
        city: 'Győr',
        country: 'Hungary',
        iataCode: 'QGY',
        icaoCode: 'LHPR',
        latitude: '47,624401',
        longitude: '17,813601',
        altitude: '424',
        timezone: '1',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Sármellék International Airport',
        city: 'Sármellék',
        country: 'Hungary',
        iataCode: 'SOB',
        icaoCode: 'LHSM',
        latitude: '46,686391',
        longitude: '17,159084',
        altitude: '408',
        timezone: '1',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Aosta Airport',
        city: 'Aosta',
        country: 'Italy',
        iataCode: 'AOT',
        icaoCode: 'LIMW',
        latitude: '45,738499',
        longitude: '7,36872',
        altitude: '1791',
        timezone: '1',
        dst: 'E',
        type: 'airport',
    },
    {
        name: "Salerno Costa d'Amalfi Airport",
        city: 'Salerno',
        country: 'Italy',
        iataCode: 'QSR',
        icaoCode: 'LIRI',
        latitude: '40,620399',
        longitude: '14,9113',
        altitude: '119',
        timezone: '1',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Corvo Airport',
        city: 'Corvo',
        country: 'Portugal',
        iataCode: 'CVU',
        icaoCode: 'LPCR',
        latitude: '39,67150116',
        longitude: '-31,11359978',
        altitude: '0',
        timezone: '-1',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Banja Luka International Airport',
        city: 'Banja Luka',
        country: 'Bosnia and Herzegovina',
        iataCode: 'BNX',
        icaoCode: 'LQBK',
        latitude: '44,94139862',
        longitude: '17,29750061',
        altitude: '400',
        timezone: '1',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Uşak Airport',
        city: 'Usak',
        country: 'Turkey',
        iataCode: 'USQ',
        icaoCode: 'LTBO',
        latitude: '38,68149948',
        longitude: '29,47170067',
        altitude: '2897',
        timezone: '3',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Kars Airport',
        city: 'Kars',
        country: 'Turkey',
        iataCode: 'KSY',
        icaoCode: 'LTCF',
        latitude: '40,56219864',
        longitude: '43,11500168',
        altitude: '5889',
        timezone: '3',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Şanlıurfa Airport',
        city: 'Sanliurfa',
        country: 'Turkey',
        iataCode: 'SFQ',
        icaoCode: 'LTCH',
        latitude: '37,09429932',
        longitude: '38,8470993',
        altitude: '1483',
        timezone: '3',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Kahramanmaraş Airport',
        city: 'Kahramanmaras',
        country: 'Turkey',
        iataCode: 'KCM',
        icaoCode: 'LTCN',
        latitude: '37,53882599',
        longitude: '36,95352173',
        altitude: '1723',
        timezone: '3',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Ağrı Airport',
        city: 'Agri',
        country: 'Turkey',
        iataCode: 'AJI',
        icaoCode: 'LTCO',
        latitude: '39,65454102',
        longitude: '43,02597809',
        altitude: '5462',
        timezone: '3',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Adıyaman Airport',
        city: 'Adiyaman',
        country: 'Turkey',
        iataCode: 'ADF',
        icaoCode: 'LTCP',
        latitude: '37,73139954',
        longitude: '38,46889877',
        altitude: '2216',
        timezone: '3',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Süleyman Demirel International Airport',
        city: 'Isparta',
        country: 'Turkey',
        iataCode: 'ISE',
        icaoCode: 'LTFC',
        latitude: '37,85540009',
        longitude: '30,36840057',
        altitude: '2835',
        timezone: '3',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Balıkesir Körfez Airport',
        city: 'Balikesir Korfez',
        country: 'Turkey',
        iataCode: 'EDO',
        icaoCode: 'LTFD',
        latitude: '39,55459976',
        longitude: '27,01379967',
        altitude: '50',
        timezone: '3',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Samsun Çarşamba Airport',
        city: 'Samsun',
        country: 'Turkey',
        iataCode: 'SZF',
        icaoCode: 'LTFH',
        latitude: '41,254501',
        longitude: '36,567101',
        altitude: '18',
        timezone: '3',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Žilina Airport',
        city: 'Žilina',
        country: 'Slovakia',
        iataCode: 'ILZ',
        icaoCode: 'LZZI',
        latitude: '49,23149872',
        longitude: '18,6135006',
        altitude: '1020',
        timezone: '1',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'JAGS McCartney International Airport',
        city: 'Cockburn Town',
        country: 'Turks and Caicos Islands',
        iataCode: 'GDT',
        icaoCode: 'MBGT',
        latitude: '21,44449997',
        longitude: '-71,14230347',
        altitude: '13',
        timezone: '-4',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Middle Caicos Airport',
        city: 'Middle Caicos',
        country: 'Turks and Caicos Islands',
        iataCode: 'MDS',
        icaoCode: 'MBMC',
        latitude: '21,82602',
        longitude: '-71,8025',
        altitude: '9',
        timezone: '-4',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Salt Cay Airport',
        city: 'Salt Cay',
        country: 'Turks and Caicos Islands',
        iataCode: 'SLX',
        icaoCode: 'MBSY',
        latitude: '21,33300018',
        longitude: '-71,19999695',
        altitude: '3',
        timezone: '-4',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Samaná El Catey International Airport',
        city: 'Samana',
        country: 'Dominican Republic',
        iataCode: 'AZS',
        icaoCode: 'MDCY',
        latitude: '19,2670002',
        longitude: '-69,74199677',
        altitude: '30',
        timezone: '-4',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'La Isabela International Airport',
        city: 'La Isabela',
        country: 'Dominican Republic',
        iataCode: 'JBQ',
        icaoCode: 'MDJB',
        latitude: '18,57250023',
        longitude: '-69,98560333',
        altitude: '98',
        timezone: '-4',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Puerto Barrios Airport',
        city: 'Puerto Barrios',
        country: 'Guatemala',
        iataCode: 'PBR',
        icaoCode: 'MGPB',
        latitude: '15,73089981',
        longitude: '-88,58380127',
        altitude: '33',
        timezone: '-6',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Quezaltenango Airport',
        city: 'Quezaltenango',
        country: 'Guatemala',
        iataCode: 'AAZ',
        icaoCode: 'MGQZ',
        latitude: '14,86559963',
        longitude: '-91,5019989',
        altitude: '7779',
        timezone: '-6',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Utirik Airport',
        city: 'Utirik Island',
        country: 'Marshall Islands',
        iataCode: 'UTK',
        icaoCode: '03N',
        latitude: '11,222',
        longitude: '169,852005',
        altitude: '4',
        timezone: '12',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Ahuas Airport',
        city: 'Ahuas',
        country: 'Honduras',
        iataCode: 'AHS',
        icaoCode: 'MHAH',
        latitude: '15,4722',
        longitude: '-84,352203',
        altitude: '249',
        timezone: '-6',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Puerto Lempira Airport',
        city: 'Puerto Lempira',
        country: 'Honduras',
        iataCode: 'PEU',
        icaoCode: 'MHPL',
        latitude: '15,2622',
        longitude: '-83,781197',
        altitude: '33',
        timezone: '-6',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Mili Island Airport',
        city: 'Mili Island',
        country: 'Marshall Islands',
        iataCode: 'MIJ',
        icaoCode: 'MLIP',
        latitude: '6,083330154',
        longitude: '171,7330017',
        altitude: '4',
        timezone: '12',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Captain Rogelio Castillo National Airport',
        city: 'Celaya',
        country: 'Mexico',
        iataCode: 'CYW',
        icaoCode: 'MMCY',
        latitude: '20,546',
        longitude: '-100,887001',
        altitude: '5709',
        timezone: '-6',
        dst: 'S',
        type: 'airport',
    },
    {
        name: 'Ciudad Constitución Airport',
        city: 'Ciudad Constitución',
        country: 'Mexico',
        iataCode: 'CUA',
        icaoCode: 'MMDA',
        latitude: '25,05380058',
        longitude: '-111,6149979',
        altitude: '213',
        timezone: '-7',
        dst: 'S',
        type: 'airport',
    },
    {
        name: 'Guerrero Negro Airport',
        city: 'Guerrero Negro',
        country: 'Mexico',
        iataCode: 'GUB',
        icaoCode: 'MMGR',
        latitude: '28,02610016',
        longitude: '-114,0240021',
        altitude: '59',
        timezone: '-8',
        dst: 'S',
        type: 'airport',
    },
    {
        name: 'El Lencero Airport',
        city: 'Jalapa',
        country: 'Mexico',
        iataCode: 'JAL',
        icaoCode: 'MMJA',
        latitude: '19,47509956',
        longitude: '-96,79750061',
        altitude: '3127',
        timezone: '-6',
        dst: 'S',
        type: 'airport',
    },
    {
        name: 'Alonso Valderrama Airport',
        city: 'Chitré',
        country: 'Panama',
        iataCode: 'CTD',
        icaoCode: 'MPCE',
        latitude: '7,987840176',
        longitude: '-80,40969849',
        altitude: '33',
        timezone: '-5',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Enrique Adolfo Jimenez Airport',
        city: 'Colón',
        country: 'Panama',
        iataCode: 'ONX',
        icaoCode: 'MPEJ',
        latitude: '9,356639862',
        longitude: '-79,86740112',
        altitude: '25',
        timezone: '-5',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Jaqué Airport',
        city: 'Jaqué',
        country: 'Panama',
        iataCode: 'JQE',
        icaoCode: 'MPJE',
        latitude: '7,517779827',
        longitude: '-78,15720367',
        altitude: '29',
        timezone: '-5',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Captain Ramon Xatruch Airport',
        city: 'La Palma',
        country: 'Panama',
        iataCode: 'PLP',
        icaoCode: 'MPLP',
        latitude: '8,406669617',
        longitude: '-78,14170074',
        altitude: '30',
        timezone: '-5',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Aerotortuguero Airport',
        city: 'Roxana',
        country: 'Costa Rica',
        iataCode: 'TTQ',
        icaoCode: 'MRAO',
        latitude: '10,42',
        longitude: '-83,6095',
        altitude: '92',
        timezone: '-6',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Barra del Colorado Airport',
        city: 'Pococi',
        country: 'Costa Rica',
        iataCode: 'BCL',
        icaoCode: 'MRBC',
        latitude: '10,76869965',
        longitude: '-83,58560181',
        altitude: '3',
        timezone: '-6',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Cabo Velas Airport',
        city: 'Nicoya',
        country: 'Costa Rica',
        iataCode: 'TNO',
        icaoCode: 'MRCV',
        latitude: '10,35569954',
        longitude: '-85,85289764',
        altitude: '33',
        timezone: '-6',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Islita Airport',
        city: 'Nandayure',
        country: 'Costa Rica',
        iataCode: 'PBP',
        icaoCode: 'MRIA',
        latitude: '9,856109619',
        longitude: '-85,3707962',
        altitude: '7',
        timezone: '-6',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Puerto Jimenez Airport',
        city: 'Puerto Jimenez',
        country: 'Costa Rica',
        iataCode: 'PJM',
        icaoCode: 'MRPJ',
        latitude: '8,533329964',
        longitude: '-83,30000305',
        altitude: '7',
        timezone: '-6',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Tobias Bolanos International Airport',
        city: 'San Jose',
        country: 'Costa Rica',
        iataCode: 'SYQ',
        icaoCode: 'MRPV',
        latitude: '9,957050323',
        longitude: '-84,13980103',
        altitude: '3287',
        timezone: '-6',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Playa Samara Airport',
        city: 'Playa Samara',
        country: 'Costa Rica',
        iataCode: 'N',
        icaoCode: 'MRSR',
        latitude: '10,25',
        longitude: '-85,41699982',
        altitude: '10',
        timezone: '-6',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Jérémie Airport',
        city: 'Jeremie',
        country: 'Haiti',
        iataCode: 'JEE',
        icaoCode: 'MTJE',
        latitude: '18,66309929',
        longitude: '-74,17030334',
        altitude: '147',
        timezone: '-5',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Port-de-Paix Airport',
        city: 'Port-de-Paix',
        country: 'Haiti',
        iataCode: 'PAX',
        icaoCode: 'MTPX',
        latitude: '19,93359947',
        longitude: '-72,84860229',
        altitude: '9',
        timezone: '-5',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Cayo Coco Airport',
        city: 'Cayo Coco',
        country: 'Cuba',
        iataCode: 'N',
        icaoCode: 'MUOC',
        latitude: '22,51320076',
        longitude: '-78,51100159',
        altitude: '6',
        timezone: '-5',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Alberto Delgado Airport',
        city: 'Trinidad',
        country: 'Cuba',
        iataCode: 'TND',
        icaoCode: 'MUTD',
        latitude: '21,78829956',
        longitude: '-79,99720001',
        altitude: '125',
        timezone: '-5',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Congo Town Airport',
        city: 'Andros',
        country: 'Bahamas',
        iataCode: 'COX',
        icaoCode: 'MYAK',
        latitude: '24,15870094',
        longitude: '-77,58979797',
        altitude: '15',
        timezone: '-5',
        dst: 'U',
        type: 'airport',
    },
    {
        name: "Arthur's Town Airport",
        city: "Arthur's Town",
        country: 'Bahamas',
        iataCode: 'ATC',
        icaoCode: 'MYCA',
        latitude: '24,62940025',
        longitude: '-75,67379761',
        altitude: '18',
        timezone: '-5',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'New Bight Airport',
        city: 'Cat Island',
        country: 'Bahamas',
        iataCode: 'CAT',
        icaoCode: 'MYCB',
        latitude: '24,31529999',
        longitude: '-75,45230103',
        altitude: '5',
        timezone: '-5',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Colonel Hill Airport',
        city: 'Colonel Hill',
        country: 'Bahamas',
        iataCode: 'CRI',
        icaoCode: 'MYCI',
        latitude: '22,74559975',
        longitude: '-74,18240356',
        altitude: '5',
        timezone: '-5',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Nassau Paradise Island Airport',
        city: 'Nassau',
        country: 'Bahamas',
        iataCode: 'PID',
        icaoCode: 'MYPI',
        latitude: '25,08300018',
        longitude: '-77,30000305',
        altitude: '0',
        timezone: '-5',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Enua Airport',
        city: 'Atiu Island',
        country: 'Cook Islands',
        iataCode: 'AIU',
        icaoCode: 'NCAT',
        latitude: '-19,96780014',
        longitude: '-158,1190033',
        altitude: '36',
        timezone: '-10',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Mangaia Island Airport',
        city: 'Mangaia Island',
        country: 'Cook Islands',
        iataCode: 'MGS',
        icaoCode: 'NCMG',
        latitude: '-21,89598656',
        longitude: '-157,906662',
        altitude: '45',
        timezone: '-10',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Manihiki Island Airport',
        city: 'Manihiki Island',
        country: 'Cook Islands',
        iataCode: 'MHX',
        icaoCode: 'NCMH',
        latitude: '-10,3767004',
        longitude: '-161,0019989',
        altitude: '0',
        timezone: '-10',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Mauke Airport',
        city: 'Mauke Island',
        country: 'Cook Islands',
        iataCode: 'MUK',
        icaoCode: 'NCMK',
        latitude: '-20,13610077',
        longitude: '-157,3450012',
        altitude: '26',
        timezone: '-10',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Mitiaro Island Airport',
        city: 'Mitiaro Island',
        country: 'Cook Islands',
        iataCode: 'MOI',
        icaoCode: 'NCMR',
        latitude: '-19,84250069',
        longitude: '-157,7030029',
        altitude: '25',
        timezone: '-10',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Tongareva Airport',
        city: 'Penrhyn Island',
        country: 'Cook Islands',
        iataCode: 'PYE',
        icaoCode: 'NCPY',
        latitude: '-9,014369965',
        longitude: '-158,0324097',
        altitude: '8',
        timezone: '-10',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Cicia Airport',
        city: 'Cicia',
        country: 'Fiji',
        iataCode: 'ICI',
        icaoCode: 'NFCI',
        latitude: '-17,74329948',
        longitude: '-179,3419952',
        altitude: '13',
        timezone: '12',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Malolo Lailai Island Airport',
        city: 'Malolo Lailai Island',
        country: 'Fiji',
        iataCode: 'PTF',
        icaoCode: 'NFFO',
        latitude: '-17,7779007',
        longitude: '177,1970062',
        altitude: '10',
        timezone: '12',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Vunisea Airport',
        city: 'Vunisea',
        country: 'Fiji',
        iataCode: 'KDV',
        icaoCode: 'NFKD',
        latitude: '-19,05809975',
        longitude: '178,1569977',
        altitude: '6',
        timezone: '12',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Mana Island Airport',
        city: 'Mana Island',
        country: 'Fiji',
        iataCode: 'MNF',
        icaoCode: 'NFMA',
        latitude: '-17,67309952',
        longitude: '177,0980072',
        altitude: '0',
        timezone: '12',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Moala Airport',
        city: 'Moala',
        country: 'Fiji',
        iataCode: 'MFJ',
        icaoCode: 'NFMO',
        latitude: '-18,56669998',
        longitude: '179,951004',
        altitude: '13',
        timezone: '12',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Ngau Airport',
        city: 'Ngau',
        country: 'Fiji',
        iataCode: 'NGI',
        icaoCode: 'NFNG',
        latitude: '-18,11560059',
        longitude: '179,3399963',
        altitude: '50',
        timezone: '12',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Lakeba Island Airport',
        city: 'Lakeba Island',
        country: 'Fiji',
        iataCode: 'LKB',
        icaoCode: 'NFNK',
        latitude: '-18,19919968',
        longitude: '-178,8170013',
        altitude: '280',
        timezone: '12',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Labasa Airport',
        city: 'Lambasa',
        country: 'Fiji',
        iataCode: 'LBS',
        icaoCode: 'NFNL',
        latitude: '-16,4666996',
        longitude: '179,3399963',
        altitude: '44',
        timezone: '12',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Matei Airport',
        city: 'Matei',
        country: 'Fiji',
        iataCode: 'TVU',
        icaoCode: 'NFNM',
        latitude: '-16,69059944',
        longitude: '-179,8769989',
        altitude: '60',
        timezone: '12',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Koro Island Airport',
        city: 'Koro Island',
        country: 'Fiji',
        iataCode: 'KXF',
        icaoCode: 'NFNO',
        latitude: '-17,3458004',
        longitude: '179,4219971',
        altitude: '358',
        timezone: '12',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Rotuma Airport',
        city: 'Rotuma',
        country: 'Fiji',
        iataCode: 'RTA',
        icaoCode: 'NFNR',
        latitude: '-12,48250008',
        longitude: '177,0709991',
        altitude: '22',
        timezone: '12',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Savusavu Airport',
        city: 'Savusavu',
        country: 'Fiji',
        iataCode: 'SVU',
        icaoCode: 'NFNS',
        latitude: '-16,80279922',
        longitude: '179,3410034',
        altitude: '17',
        timezone: '12',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Kaufana Airport',
        city: 'Eua Island',
        country: 'Tonga',
        iataCode: 'EUA',
        icaoCode: 'NFTE',
        latitude: '-21,37829971',
        longitude: '-174,9579926',
        altitude: '325',
        timezone: '13',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Lifuka Island Airport',
        city: 'Lifuka',
        country: 'Tonga',
        iataCode: 'HPA',
        icaoCode: 'NFTL',
        latitude: '-19,77700043',
        longitude: '-174,3410034',
        altitude: '31',
        timezone: '13',
        dst: 'U',
        type: 'airport',
    },
    {
        name: "Mata'aho Airport",
        city: "Angaha, Niuafo'ou Island",
        country: 'Tonga',
        iataCode: 'NFO',
        icaoCode: 'NFTO',
        latitude: '-15,57079983',
        longitude: '-175,6329956',
        altitude: '160',
        timezone: '13',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Kuini Lavenia Airport',
        city: 'Niuatoputapu',
        country: 'Tonga',
        iataCode: 'NTT',
        icaoCode: 'NFTP',
        latitude: '-15,97733765',
        longitude: '-173,7910295',
        altitude: '30',
        timezone: '13',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Vanua Balavu Airport',
        city: 'Vanua Balavu',
        country: 'Fiji',
        iataCode: 'VBV',
        icaoCode: 'NFVB',
        latitude: '-17,2689991',
        longitude: '-178,9759979',
        altitude: '76',
        timezone: '12',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Niue International Airport',
        city: 'Alofi',
        country: 'Niue',
        iataCode: 'IUE',
        icaoCode: 'NIUE',
        latitude: '-19,07903099',
        longitude: '-169,9255981',
        altitude: '209',
        timezone: '-11',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Pointe Vele Airport',
        city: 'Futuna Island',
        country: 'Wallis and Futuna',
        iataCode: 'FUT',
        icaoCode: 'NLWF',
        latitude: '-14,31140041',
        longitude: '-178,0659943',
        altitude: '20',
        timezone: '12',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Maota Airport',
        city: 'Savaii Island',
        country: 'Samoa',
        iataCode: 'MXS',
        icaoCode: 'NSMA',
        latitude: '-13,74230003',
        longitude: '-172,2579956',
        altitude: '0',
        timezone: '13',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Apataki Airport',
        city: 'Apataki',
        country: 'French Polynesia',
        iataCode: 'APK',
        icaoCode: 'NTGD',
        latitude: '-15,57359982',
        longitude: '-146,4149933',
        altitude: '8',
        timezone: '-10',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Ahe Airport',
        city: 'Ahe',
        country: 'French Polynesia',
        iataCode: 'AHE',
        icaoCode: 'NTHE',
        latitude: '-14,42809963',
        longitude: '-146,2570038',
        altitude: '11',
        timezone: '-10',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Hiva Oa-Atuona Airport',
        city: 'Hiva-oa',
        country: 'French Polynesia',
        iataCode: 'AUQ',
        icaoCode: 'NTMN',
        latitude: '-9,768790245',
        longitude: '-139,0110016',
        altitude: '1481',
        timezone: '-9,5',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Ua Pou Airport',
        city: 'Ua Pou',
        country: 'French Polynesia',
        iataCode: 'UAP',
        icaoCode: 'NTMP',
        latitude: '-9,351670265',
        longitude: '-140,0780029',
        altitude: '16',
        timezone: '-9,5',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Ua Huka Airport',
        city: 'Ua Huka',
        country: 'French Polynesia',
        iataCode: 'UAH',
        icaoCode: 'NTMU',
        latitude: '-8,936109543',
        longitude: '-139,552002',
        altitude: '160',
        timezone: '-9,5',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Mota Lava Airport',
        city: 'Ablow',
        country: 'Vanuatu',
        iataCode: 'MTV',
        icaoCode: 'NVSA',
        latitude: '-13,66600037',
        longitude: '167,7120056',
        altitude: '63',
        timezone: '11',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Sola Airport',
        city: 'Sola',
        country: 'Vanuatu',
        iataCode: 'SLH',
        icaoCode: 'NVSC',
        latitude: '-13,85169983',
        longitude: '167,5370026',
        altitude: '7',
        timezone: '11',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Torres Airstrip',
        city: 'Loh/Linua',
        country: 'Vanuatu',
        iataCode: 'TOH',
        icaoCode: 'NVSD',
        latitude: '-13,32800007',
        longitude: '166,6380005',
        altitude: '75',
        timezone: '11',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Siwo Airport',
        city: 'Sangafa',
        country: 'Vanuatu',
        iataCode: 'EAE',
        icaoCode: 'NVSE',
        latitude: '-17,09029961',
        longitude: '168,3430023',
        altitude: '7',
        timezone: '11',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Craig Cove Airport',
        city: 'Craig Cove',
        country: 'Vanuatu',
        iataCode: 'CCV',
        icaoCode: 'NVSF',
        latitude: '-16,26499939',
        longitude: '167,923996',
        altitude: '69',
        timezone: '11',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Longana Airport',
        city: 'Longana',
        country: 'Vanuatu',
        iataCode: 'LOD',
        icaoCode: 'NVSG',
        latitude: '-15,30669975',
        longitude: '167,9669952',
        altitude: '167',
        timezone: '11',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Sara Airport',
        city: 'Pentecost Island',
        country: 'Vanuatu',
        iataCode: 'SSR',
        icaoCode: 'NVSH',
        latitude: '-15,4708004',
        longitude: '168,1519928',
        altitude: '493',
        timezone: '11',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Tavie Airport',
        city: 'Paama Island',
        country: 'Vanuatu',
        iataCode: 'PBJ',
        icaoCode: 'NVSI',
        latitude: '-16,43899918',
        longitude: '168,2570038',
        altitude: '160',
        timezone: '11',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Lamap Airport',
        city: 'Lamap',
        country: 'Vanuatu',
        iataCode: 'LPM',
        icaoCode: 'NVSL',
        latitude: '-16,45400047',
        longitude: '167,822998',
        altitude: '7',
        timezone: '11',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Lamen Bay Airport',
        city: 'Lamen Bay',
        country: 'Vanuatu',
        iataCode: 'LNB',
        icaoCode: 'NVSM',
        latitude: '-16,58419991',
        longitude: '168,1589966',
        altitude: '7',
        timezone: '11',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Maewo-Naone Airport',
        city: 'Maewo Island',
        country: 'Vanuatu',
        iataCode: 'MWF',
        icaoCode: 'NVSN',
        latitude: '-15',
        longitude: '168,0829926',
        altitude: '509',
        timezone: '11',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Lonorore Airport',
        city: 'Lonorore',
        country: 'Vanuatu',
        iataCode: 'LNE',
        icaoCode: 'NVSO',
        latitude: '-15,86559963',
        longitude: '168,1719971',
        altitude: '43',
        timezone: '11',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Norsup Airport',
        city: 'Norsup',
        country: 'Vanuatu',
        iataCode: 'NUS',
        icaoCode: 'NVSP',
        latitude: '-16,07970047',
        longitude: '167,401001',
        altitude: '23',
        timezone: '11',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Gaua Island Airport',
        city: 'Gaua Island',
        country: 'Vanuatu',
        iataCode: 'ZGU',
        icaoCode: 'NVSQ',
        latitude: '-14,21809959',
        longitude: '167,5870056',
        altitude: '100',
        timezone: '11',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Redcliffe Airport',
        city: 'Redcliffe',
        country: 'Vanuatu',
        iataCode: 'RCL',
        icaoCode: 'NVSR',
        latitude: '-15,47200012',
        longitude: '167,8350067',
        altitude: '36',
        timezone: '11',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Santo Pekoa International Airport',
        city: 'Santo',
        country: 'Vanuatu',
        iataCode: 'SON',
        icaoCode: 'NVSS',
        latitude: '-15,50500011',
        longitude: '167,2200012',
        altitude: '184',
        timezone: '11',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Tongoa Airport',
        city: 'Tongoa Island',
        country: 'Vanuatu',
        iataCode: 'TGH',
        icaoCode: 'NVST',
        latitude: '-16,89109993',
        longitude: '168,5509949',
        altitude: '443',
        timezone: '11',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Uléi Airport',
        city: 'Ambryn Island',
        country: 'Vanuatu',
        iataCode: 'ULB',
        icaoCode: 'NVSU',
        latitude: '-16,3297',
        longitude: '168,3011',
        altitude: '170',
        timezone: '11',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Valesdir Airport',
        city: 'Valesdir',
        country: 'Vanuatu',
        iataCode: 'VLS',
        icaoCode: 'NVSV',
        latitude: '-16,79610062',
        longitude: '168,177002',
        altitude: '10',
        timezone: '11',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Southwest Bay Airport',
        city: 'Malekula Island',
        country: 'Vanuatu',
        iataCode: 'SWJ',
        icaoCode: 'NVSX',
        latitude: '-16,4864',
        longitude: '167,4472',
        altitude: '68',
        timezone: '11',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'North West Santo Airport',
        city: 'Olpoi',
        country: 'Vanuatu',
        iataCode: 'OLZ',
        icaoCode: 'NVSZ',
        latitude: '-14,88169956',
        longitude: '166,5579987',
        altitude: '50',
        timezone: '11',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Aneityum Airport',
        city: 'Anelghowhat',
        country: 'Vanuatu',
        iataCode: 'AUY',
        icaoCode: 'NVVA',
        latitude: '-20,24920082',
        longitude: '169,7709961',
        altitude: '7',
        timezone: '11',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Aniwa Airport',
        city: 'Aniwa',
        country: 'Vanuatu',
        iataCode: 'AWD',
        icaoCode: 'NVVB',
        latitude: '-19,2346',
        longitude: '169,6009',
        altitude: '69',
        timezone: '11',
        dst: 'U',
        type: 'airport',
    },
    {
        name: "Dillon's Bay Airport",
        city: "Dillon's Bay",
        country: 'Vanuatu',
        iataCode: 'DLY',
        icaoCode: 'NVVD',
        latitude: '-18,76939964',
        longitude: '169,0010071',
        altitude: '630',
        timezone: '11',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Futuna Airport',
        city: 'Futuna Island',
        country: 'Vanuatu',
        iataCode: 'FTA',
        icaoCode: 'NVVF',
        latitude: '-19,51639938',
        longitude: '170,2319946',
        altitude: '95',
        timezone: '11',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Ipota Airport',
        city: 'Ipota',
        country: 'Vanuatu',
        iataCode: 'IPA',
        icaoCode: 'NVVI',
        latitude: '-18,856389',
        longitude: '169,283333',
        altitude: '23',
        timezone: '11',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Tiga Airport',
        city: 'Tiga',
        country: 'New Caledonia',
        iataCode: 'TGJ',
        icaoCode: 'NWWA',
        latitude: '-21,09609985',
        longitude: '167,8040009',
        altitude: '128',
        timezone: '11',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Île Art - Waala Airport',
        city: 'Waala',
        country: 'New Caledonia',
        iataCode: 'BMY',
        icaoCode: 'NWWC',
        latitude: '-19,72060013',
        longitude: '163,6609955',
        altitude: '306',
        timezone: '11',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Île des Pins Airport',
        city: 'Île des Pins',
        country: 'New Caledonia',
        iataCode: 'ILP',
        icaoCode: 'NWWE',
        latitude: '-22,58889961',
        longitude: '167,4559937',
        altitude: '315',
        timezone: '11',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Fayzabad Airport',
        city: 'Faizabad',
        country: 'Afghanistan',
        iataCode: 'FBD',
        icaoCode: 'OAFZ',
        latitude: '37,121101',
        longitude: '70,518097',
        altitude: '3872',
        timezone: '4,5',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Dawadmi Domestic Airport',
        city: 'Dawadmi',
        country: 'Saudi Arabia',
        iataCode: 'DWD',
        icaoCode: 'OEDW',
        latitude: '24,5',
        longitude: '44,40000153',
        altitude: '3429',
        timezone: '3',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Al-Jawf Domestic Airport',
        city: 'Al-Jawf',
        country: 'Saudi Arabia',
        iataCode: 'AJF',
        icaoCode: 'OESK',
        latitude: '29,78510094',
        longitude: '40,09999847',
        altitude: '2261',
        timezone: '3',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Wadi Al Dawasir Airport',
        city: 'Wadi-al-dawasir',
        country: 'Saudi Arabia',
        iataCode: 'EWD',
        icaoCode: 'OEWD',
        latitude: '20,50429916',
        longitude: '45,19960022',
        altitude: '2062',
        timezone: '3',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Khoram Abad Airport',
        city: 'Khorram Abad',
        country: 'Iran',
        iataCode: 'KHD',
        icaoCode: 'OICK',
        latitude: '33,4353981',
        longitude: '48,28290176',
        altitude: '3782',
        timezone: '3,5',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Bam Airport',
        city: 'Bam',
        country: 'Iran',
        iataCode: 'BXR',
        icaoCode: 'OIKM',
        latitude: '29,08419991',
        longitude: '58,45000076',
        altitude: '3231',
        timezone: '3,5',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Rafsanjan Airport',
        city: 'Rafsanjan',
        country: 'Iran',
        iataCode: 'RJN',
        icaoCode: 'OIKR',
        latitude: '30,29770088',
        longitude: '56,05110168',
        altitude: '5298',
        timezone: '3,5',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Bojnord Airport',
        city: 'Bojnourd',
        country: 'Iran',
        iataCode: 'BJB',
        icaoCode: 'OIMN',
        latitude: '37,49300003',
        longitude: '57,30820084',
        altitude: '3499',
        timezone: '3,5',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Sabzevar National Airport',
        city: 'Sabzevar',
        country: 'Iran',
        iataCode: 'AFZ',
        icaoCode: 'OIMS',
        latitude: '36,16809845',
        longitude: '57,59519958',
        altitude: '3010',
        timezone: '3,5',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Noshahr Airport',
        city: 'Noshahr',
        country: 'Iran',
        iataCode: 'NSH',
        icaoCode: 'OINN',
        latitude: '36,66329956',
        longitude: '51,46469879',
        altitude: '-61',
        timezone: '3,5',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Dasht-e Naz Airport',
        city: 'Dasht-e-naz',
        country: 'Iran',
        iataCode: 'SRY',
        icaoCode: 'OINZ',
        latitude: '36,63579941',
        longitude: '53,1935997',
        altitude: '35',
        timezone: '3,5',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Lar Airport',
        city: 'Lar',
        country: 'Iran',
        iataCode: 'LRR',
        icaoCode: 'OISL',
        latitude: '27,67469978',
        longitude: '54,38330078',
        altitude: '2641',
        timezone: '3,5',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Ardabil Airport',
        city: 'Ardabil',
        country: 'Iran',
        iataCode: 'ADU',
        icaoCode: 'OITL',
        latitude: '38,32569885',
        longitude: '48,42440033',
        altitude: '4315',
        timezone: '3,5',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Urmia Airport',
        city: 'Uromiyeh',
        country: 'Iran',
        iataCode: 'OMH',
        icaoCode: 'OITR',
        latitude: '37,66809845',
        longitude: '45,06869888',
        altitude: '4343',
        timezone: '3,5',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Al Ain International Airport',
        city: 'Al Ain',
        country: 'United Arab Emirates',
        iataCode: 'AAN',
        icaoCode: 'OMAL',
        latitude: '24,26169968',
        longitude: '55,60919952',
        altitude: '869',
        timezone: '4',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Bannu Airport',
        city: 'Bannu',
        country: 'Pakistan',
        iataCode: 'BNP',
        icaoCode: 'OPBN',
        latitude: '32,97290039',
        longitude: '70,5279007',
        altitude: '1325',
        timezone: '5',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Bahawalpur Airport',
        city: 'Bahawalpur',
        country: 'Pakistan',
        iataCode: 'BHV',
        icaoCode: 'OPBW',
        latitude: '29,34810066',
        longitude: '71,71800232',
        altitude: '392',
        timezone: '5',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Chitral Airport',
        city: 'Chitral',
        country: 'Pakistan',
        iataCode: 'CJL',
        icaoCode: 'OPCH',
        latitude: '35,88660049',
        longitude: '71,80059814',
        altitude: '4920',
        timezone: '5',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Dalbandin Airport',
        city: 'Dalbandin',
        country: 'Pakistan',
        iataCode: 'DBA',
        icaoCode: 'OPDB',
        latitude: '28,87829971',
        longitude: '64,39980316',
        altitude: '2800',
        timezone: '5',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Dera Ghazi Khan Airport',
        city: 'Dera Ghazi Khan',
        country: 'Pakistan',
        iataCode: 'DEA',
        icaoCode: 'OPDG',
        latitude: '29,96100044',
        longitude: '70,48590088',
        altitude: '492',
        timezone: '5',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Dera Ismael Khan Airport',
        city: 'Dera Ismael Khan',
        country: 'Pakistan',
        iataCode: 'DSK',
        icaoCode: 'OPDI',
        latitude: '31,90940094',
        longitude: '70,89659882',
        altitude: '594',
        timezone: '5',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Jiwani Airport',
        city: 'Jiwani',
        country: 'Pakistan',
        iataCode: 'JIW',
        icaoCode: 'OPJI',
        latitude: '25,06780052',
        longitude: '61,80540085',
        altitude: '186',
        timezone: '5',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Hyderabad Airport',
        city: 'Hyderabad',
        country: 'Pakistan',
        iataCode: 'HDD',
        icaoCode: 'OPKD',
        latitude: '25,31809998',
        longitude: '68,3660965',
        altitude: '130',
        timezone: '5',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Khuzdar Airport',
        city: 'Khuzdar',
        country: 'Pakistan',
        iataCode: 'KDD',
        icaoCode: 'OPKH',
        latitude: '27,79059982',
        longitude: '66,64730072',
        altitude: '4012',
        timezone: '5',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Ormara Airport',
        city: 'Ormara Raik',
        country: 'Pakistan',
        iataCode: 'ORW',
        icaoCode: 'OPOR',
        latitude: '25,27470016',
        longitude: '64,58599854',
        altitude: '10',
        timezone: '5',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Parachinar Airport',
        city: 'Parachinar',
        country: 'Pakistan',
        iataCode: 'PAJ',
        icaoCode: 'OPPC',
        latitude: '33,90209961',
        longitude: '70,07160187',
        altitude: '5800',
        timezone: '5',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Skardu Airport',
        city: 'Skardu',
        country: 'Pakistan',
        iataCode: 'KDU',
        icaoCode: 'OPSD',
        latitude: '35,33549881',
        longitude: '75,53600311',
        altitude: '7316',
        timezone: '5',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Sehwan Sharif Airport',
        city: 'Sehwan Sharif',
        country: 'Pakistan',
        iataCode: 'SYW',
        icaoCode: 'OPSN',
        latitude: '26,47310066',
        longitude: '67,71720123',
        altitude: '121',
        timezone: '5',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Turbat International Airport',
        city: 'Turbat',
        country: 'Pakistan',
        iataCode: 'TUK',
        icaoCode: 'OPTU',
        latitude: '25,9864006',
        longitude: '63,03020096',
        altitude: '498',
        timezone: '5',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Sulaymaniyah International Airport',
        city: 'Sulaymaniyah',
        country: 'Iraq',
        iataCode: 'ISU',
        icaoCode: 'ORSU',
        latitude: '35,5617485',
        longitude: '45,31673813',
        altitude: '2494',
        timezone: '3',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Kamishly Airport',
        city: 'Kamishly',
        country: 'Syria',
        iataCode: 'KAC',
        icaoCode: 'OSKL',
        latitude: '37,02059937',
        longitude: '41,19139862',
        altitude: '1480',
        timezone: '2',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Sayun International Airport',
        city: 'Sayun Intl',
        country: 'Yemen',
        iataCode: 'GXF',
        icaoCode: 'OYSY',
        latitude: '15,96609974',
        longitude: '48,78829956',
        altitude: '2097',
        timezone: '3',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Adak Airport',
        city: 'Adak Island',
        country: 'United States',
        iataCode: 'ADK',
        icaoCode: 'PADK',
        latitude: '51,87799835',
        longitude: '-176,6459961',
        altitude: '18',
        timezone: '-10',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Gustavus Airport',
        city: 'Gustavus',
        country: 'United States',
        iataCode: 'GST',
        icaoCode: 'PAGS',
        latitude: '58,4253006',
        longitude: '-135,7070007',
        altitude: '35',
        timezone: '-9',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Skagway Airport',
        city: 'Skagway',
        country: 'United States',
        iataCode: 'SGY',
        icaoCode: 'PAGY',
        latitude: '59,46009827',
        longitude: '-135,3159943',
        altitude: '44',
        timezone: '-9',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Holy Cross Airport',
        city: 'Holy Cross',
        country: 'United States',
        iataCode: 'HCR',
        icaoCode: 'PAHC',
        latitude: '62,18830109',
        longitude: '-159,7749939',
        altitude: '70',
        timezone: '-9',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Haines Airport',
        city: 'Haines',
        country: 'United States',
        iataCode: 'HNS',
        icaoCode: 'PAHN',
        latitude: '59,24380112',
        longitude: '-135,5240021',
        altitude: '15',
        timezone: '-9',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Kalskag Airport',
        city: 'Kalskag',
        country: 'United States',
        iataCode: 'KLG',
        icaoCode: 'PALG',
        latitude: '61,53630066',
        longitude: '-160,3410034',
        altitude: '55',
        timezone: '-9',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'McGrath Airport',
        city: 'Mcgrath',
        country: 'United States',
        iataCode: 'MCG',
        icaoCode: 'PAMC',
        latitude: '62,95289993',
        longitude: '-155,6060028',
        altitude: '341',
        timezone: '-9',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Mountain Village Airport',
        city: 'Mountain Village',
        country: 'United States',
        iataCode: 'MOU',
        icaoCode: 'PAMO',
        latitude: '62,09540176',
        longitude: '-163,6820068',
        altitude: '337',
        timezone: '-9',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Aniak Airport',
        city: 'Aniak',
        country: 'United States',
        iataCode: 'ANI',
        icaoCode: 'PANI',
        latitude: '61,58160019',
        longitude: '-159,5429993',
        altitude: '88',
        timezone: '-9',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Chevak Airport',
        city: 'Chevak',
        country: 'United States',
        iataCode: 'VAK',
        icaoCode: 'PAVA',
        latitude: '61,5409',
        longitude: '-165,6005',
        altitude: '75',
        timezone: '-9',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Wrangell Airport',
        city: 'Wrangell',
        country: 'United States',
        iataCode: 'WRG',
        icaoCode: 'PAWG',
        latitude: '56,48429871',
        longitude: '-132,3699951',
        altitude: '49',
        timezone: '-9',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Kalaupapa Airport',
        city: 'Molokai',
        country: 'United States',
        iataCode: 'LUP',
        icaoCode: 'PHLU',
        latitude: '21,21100044',
        longitude: '-156,973999',
        altitude: '24',
        timezone: '-10',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Eniwetok Airport',
        city: 'Eniwetok Atoll',
        country: 'Marshall Islands',
        iataCode: 'ENT',
        icaoCode: 'PKMA',
        latitude: '11,34070015',
        longitude: '162,3280029',
        altitude: '13',
        timezone: '12',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Matsu Nangan Airport',
        city: 'Matsu Islands',
        country: 'Taiwan',
        iataCode: 'LZN',
        icaoCode: 'RCFG',
        latitude: '26,15979958',
        longitude: '119,9580002',
        altitude: '232',
        timezone: '8',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Hengchun Airport',
        city: 'Hengchun',
        country: 'Taiwan',
        iataCode: 'HCN',
        icaoCode: 'RCKW',
        latitude: '22,04109955',
        longitude: '120,7300034',
        altitude: '46',
        timezone: '8',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Matsu Beigan Airport',
        city: 'Matsu Islands',
        country: 'Taiwan',
        iataCode: 'MFK',
        icaoCode: 'RCMT',
        latitude: '26,2241993',
        longitude: '120,0029984',
        altitude: '41',
        timezone: '8',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Kushiro Airport',
        city: 'Kushiro',
        country: 'Japan',
        iataCode: 'KUH',
        icaoCode: 'RJCK',
        latitude: '43,04100037',
        longitude: '144,1929932',
        altitude: '327',
        timezone: '9',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Okadama Airport',
        city: 'Sapporo',
        country: 'Japan',
        iataCode: 'OKD',
        icaoCode: 'RJCO',
        latitude: '43,11610031',
        longitude: '141,3800049',
        altitude: '25',
        timezone: '9',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Saga Airport',
        city: 'Saga',
        country: 'Japan',
        iataCode: 'HSG',
        icaoCode: 'RJFS',
        latitude: '33,14970016',
        longitude: '130,302002',
        altitude: '6',
        timezone: '9',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Nagoya Airport',
        city: 'Nagoya',
        country: 'Japan',
        iataCode: 'NKM',
        icaoCode: 'RJNA',
        latitude: '35,25500107',
        longitude: '136,923996',
        altitude: '52',
        timezone: '9',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Iwami Airport',
        city: 'Iwami',
        country: 'Japan',
        iataCode: 'IWJ',
        icaoCode: 'RJOW',
        latitude: '34,67639923',
        longitude: '131,7899933',
        altitude: '184',
        timezone: '9',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Fukushima Airport',
        city: 'Fukushima',
        country: 'Japan',
        iataCode: 'FKS',
        icaoCode: 'RJSF',
        latitude: '37,22740173',
        longitude: '140,4309998',
        altitude: '1221',
        timezone: '9',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Odate Noshiro Airport',
        city: 'Odate Noshiro',
        country: 'Japan',
        iataCode: 'ONJ',
        icaoCode: 'RJSR',
        latitude: '40,19189835',
        longitude: '140,3710022',
        altitude: '292',
        timezone: '9',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Shonai Airport',
        city: 'Shonai',
        country: 'Japan',
        iataCode: 'SYO',
        icaoCode: 'RJSY',
        latitude: '38,81219864',
        longitude: '139,7870026',
        altitude: '86',
        timezone: '9',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Miyakejima Airport',
        city: 'Miyakejima',
        country: 'Japan',
        iataCode: 'MYE',
        icaoCode: 'RJTQ',
        latitude: '34,07360077',
        longitude: '139,5599976',
        altitude: '67',
        timezone: '9',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Kunsan Air Base',
        city: 'Kunsan',
        country: 'South Korea',
        iataCode: 'KUV',
        icaoCode: 'RKJK',
        latitude: '35,90380096',
        longitude: '126,6159973',
        altitude: '29',
        timezone: '9',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Mokpo Heliport',
        city: 'Mokpo',
        country: 'South Korea',
        iataCode: 'MPK',
        icaoCode: 'RKJM',
        latitude: '34,75889969',
        longitude: '126,3799973',
        altitude: '23',
        timezone: '9',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Wonju Airport',
        city: 'Wonju',
        country: 'South Korea',
        iataCode: 'WJU',
        icaoCode: 'RKNW',
        latitude: '37,43809891',
        longitude: '127,9599991',
        altitude: '329',
        timezone: '9',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Yangyang International Airport',
        city: 'Sokcho / Gangneung',
        country: 'South Korea',
        iataCode: 'YNY',
        icaoCode: 'RKNY',
        latitude: '38,06129837',
        longitude: '128,6690063',
        altitude: '241',
        timezone: '9',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Sacheon Air Base',
        city: 'Sacheon',
        country: 'South Korea',
        iataCode: 'HIN',
        icaoCode: 'RKPS',
        latitude: '35,08850098',
        longitude: '128,0700073',
        altitude: '25',
        timezone: '9',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Cheongju International Airport',
        city: 'Chongju',
        country: 'South Korea',
        iataCode: 'CJJ',
        icaoCode: 'RKTU',
        latitude: '36,71659851',
        longitude: '127,4990005',
        altitude: '191',
        timezone: '9',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Subic Bay International Airport',
        city: 'Olongapo City',
        country: 'Philippines',
        iataCode: 'SFS',
        icaoCode: 'RPLB',
        latitude: '14,79440022',
        longitude: '120,2710037',
        altitude: '64',
        timezone: '8',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Cuyo Airport',
        city: 'Cuyo',
        country: 'Philippines',
        iataCode: 'CYU',
        icaoCode: 'RPLO',
        latitude: '10,85809994',
        longitude: '121,0690002',
        altitude: '0',
        timezone: '8',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Rajah Buayan Air Base',
        city: 'General Santos City',
        country: 'Philippines',
        iataCode: 'N',
        icaoCode: 'RPMB',
        latitude: '6,106440067',
        longitude: '125,2350006',
        altitude: '28',
        timezone: '8',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Camiguin Airport',
        city: 'Camiguin',
        country: 'Philippines',
        iataCode: 'CGM',
        icaoCode: 'RPMH',
        latitude: '9,253520012',
        longitude: '124,7070007',
        altitude: '53',
        timezone: '8',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Jolo Airport',
        city: 'Jolo',
        country: 'Philippines',
        iataCode: 'JOL',
        icaoCode: 'RPMJ',
        latitude: '6,05366993',
        longitude: '121,0110016',
        altitude: '118',
        timezone: '8',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Sanga Sanga Airport',
        city: 'Sanga Sanga',
        country: 'Philippines',
        iataCode: 'SGS',
        icaoCode: 'RPMN',
        latitude: '5,046989918',
        longitude: '119,7429962',
        altitude: '15',
        timezone: '8',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Surigao Airport',
        city: 'Sangley Point',
        country: 'Philippines',
        iataCode: 'SUG',
        icaoCode: 'RPMS',
        latitude: '9,755838326',
        longitude: '125,4809475',
        altitude: '20',
        timezone: '8',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Tandag Airport',
        city: 'Tandag',
        country: 'Philippines',
        iataCode: 'TDG',
        icaoCode: 'RPMW',
        latitude: '9,072110176',
        longitude: '126,1709976',
        altitude: '16',
        timezone: '8',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Naga Airport',
        city: 'Naga',
        country: 'Philippines',
        iataCode: 'WNP',
        icaoCode: 'RPUN',
        latitude: '13,5848999',
        longitude: '123,2699966',
        altitude: '142',
        timezone: '8',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Basco Airport',
        city: 'Basco',
        country: 'Philippines',
        iataCode: 'BSO',
        icaoCode: 'RPUO',
        latitude: '20,45129967',
        longitude: '121,9800034',
        altitude: '291',
        timezone: '8',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'San Fernando Airport',
        city: 'San Fernando',
        country: 'Philippines',
        iataCode: 'SFE',
        icaoCode: 'RPUS',
        latitude: '16,59560013',
        longitude: '120,3030014',
        altitude: '13',
        timezone: '8',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Tuguegarao Airport',
        city: 'Tuguegarao',
        country: 'Philippines',
        iataCode: 'TUG',
        icaoCode: 'RPUT',
        latitude: '17,64336768',
        longitude: '121,7331505',
        altitude: '70',
        timezone: '8',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Virac Airport',
        city: 'Virac',
        country: 'Philippines',
        iataCode: 'VRC',
        icaoCode: 'RPUV',
        latitude: '13,5763998',
        longitude: '124,2060013',
        altitude: '121',
        timezone: '8',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Calbayog Airport',
        city: 'Calbayog City',
        country: 'Philippines',
        iataCode: 'CYP',
        icaoCode: 'RPVC',
        latitude: '12,07269955',
        longitude: '124,5449982',
        altitude: '12',
        timezone: '8',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Catarman National Airport',
        city: 'Catarman',
        country: 'Philippines',
        iataCode: 'CRM',
        icaoCode: 'RPVF',
        latitude: '12,5024004',
        longitude: '124,6360016',
        altitude: '6',
        timezone: '8',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Moises R. Espinosa Airport',
        city: 'Masbate',
        country: 'Philippines',
        iataCode: 'MBT',
        icaoCode: 'RPVJ',
        latitude: '12,36940002',
        longitude: '123,6289978',
        altitude: '26',
        timezone: '8',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Roxas Airport',
        city: 'Roxas City',
        country: 'Philippines',
        iataCode: 'RXS',
        icaoCode: 'RPVR',
        latitude: '11,59770012',
        longitude: '122,7519989',
        altitude: '10',
        timezone: '8',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'General Enrique Mosconi Airport',
        city: 'Tartagal',
        country: 'Argentina',
        iataCode: 'TTG',
        icaoCode: 'SAST',
        latitude: '-22,6196003',
        longitude: '-63,79370117',
        altitude: '1472',
        timezone: '-3',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Las Heras Airport',
        city: 'Las Heras',
        country: 'Argentina',
        iataCode: 'LHS',
        icaoCode: 'SAVH',
        latitude: '-46,53829956',
        longitude: '-68,96530151',
        altitude: '1082',
        timezone: '-3',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Antoine De St Exupery Airport',
        city: 'San Antonio Oeste',
        country: 'Argentina',
        iataCode: 'OES',
        icaoCode: 'SAVN',
        latitude: '-40,7512',
        longitude: '-65,0343',
        altitude: '85',
        timezone: '-3',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Lago Argentino Airport',
        city: 'El Calafate',
        country: 'Argentina',
        iataCode: 'ING',
        icaoCode: 'SAWA',
        latitude: '-50,336102',
        longitude: '-72,248596',
        altitude: '732',
        timezone: '-3',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Gobernador Gregores Airport',
        city: 'Gobernador Gregores',
        country: 'Argentina',
        iataCode: 'GGS',
        icaoCode: 'SAWR',
        latitude: '-48,78310013',
        longitude: '-70,15000153',
        altitude: '356',
        timezone: '-3',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Santa Teresita Airport',
        city: 'Santa Teresita',
        country: 'Argentina',
        iataCode: 'SST',
        icaoCode: 'SAZL',
        latitude: '-36,5423',
        longitude: '-56,7218',
        altitude: '9',
        timezone: '-3',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Necochea Airport',
        city: 'Necochea',
        country: 'Argentina',
        iataCode: 'NEC',
        icaoCode: 'SAZO',
        latitude: '-38,4831',
        longitude: '-58,8172',
        altitude: '72',
        timezone: '-3',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Orlando Bezerra de Menezes Airport',
        city: 'Juazeiro Do Norte',
        country: 'Brazil',
        iataCode: 'JDO',
        icaoCode: 'SBJU',
        latitude: '-7,218959808',
        longitude: '-39,27009964',
        altitude: '1392',
        timezone: '-3',
        dst: 'S',
        type: 'airport',
    },
    {
        name: 'Coronel Horácio de Mattos Airport',
        city: 'Lençóis',
        country: 'Brazil',
        iataCode: 'LEC',
        icaoCode: 'SBLE',
        latitude: '-12,4822998',
        longitude: '-41,27700043',
        altitude: '1676',
        timezone: '-3',
        dst: 'S',
        type: 'airport',
    },
    {
        name: 'Macaé Airport',
        city: 'Macaé',
        country: 'Brazil',
        iataCode: 'MEA',
        icaoCode: 'SBME',
        latitude: '-22,34300041',
        longitude: '-41,76599884',
        altitude: '8',
        timezone: '-3',
        dst: 'S',
        type: 'airport',
    },
    {
        name: 'Frank Miloye Milenkowichi–Marília State Airport',
        city: 'Marília',
        country: 'Brazil',
        iataCode: 'MII',
        icaoCode: 'SBML',
        latitude: '-22,19689941',
        longitude: '-49,92639923',
        altitude: '2122',
        timezone: '-3',
        dst: 'S',
        type: 'airport',
    },
    {
        name: 'Vitória da Conquista Airport',
        city: 'Vitória Da Conquista',
        country: 'Brazil',
        iataCode: 'VDC',
        icaoCode: 'SBQV',
        latitude: '-14,86279964',
        longitude: '-40,86309814',
        altitude: '3002',
        timezone: '-3',
        dst: 'S',
        type: 'airport',
    },
    {
        name: 'Santa Maria Airport',
        city: 'Santa Maria',
        country: 'Brazil',
        iataCode: 'RIA',
        icaoCode: 'SBSM',
        latitude: '-29,71139908',
        longitude: '-53,6882019',
        altitude: '287',
        timezone: '-3',
        dst: 'S',
        type: 'airport',
    },
    {
        name: 'Toledo Airport',
        city: 'Toledo',
        country: 'Brazil',
        iataCode: 'TOW',
        icaoCode: 'SBTD',
        latitude: '-24,68630028',
        longitude: '-53,69749832',
        altitude: '1843',
        timezone: '-3',
        dst: 'S',
        type: 'airport',
    },
    {
        name: 'Ricardo García Posada Airport',
        city: 'El Salvador',
        country: 'Chile',
        iataCode: 'ESR',
        icaoCode: 'SCES',
        latitude: '-26,31110001',
        longitude: '-69,76519775',
        altitude: '5240',
        timezone: '-4',
        dst: 'S',
        type: 'airport',
    },
    {
        name: 'Pucón Airport',
        city: 'Pucon',
        country: 'Chile',
        iataCode: 'ZPC',
        icaoCode: 'SCPC',
        latitude: '-39,2928009',
        longitude: '-71,91590118',
        altitude: '853',
        timezone: '-4',
        dst: 'S',
        type: 'airport',
    },
    {
        name: 'Sorocaba Airport',
        city: 'Sorocaba',
        country: 'Brazil',
        iataCode: 'SOD',
        icaoCode: 'SDCO',
        latitude: '-23,47800064',
        longitude: '-47,49000168',
        altitude: '2077',
        timezone: '-3',
        dst: 'S',
        type: 'airport',
    },
    {
        name: 'San Cristóbal Airport',
        city: 'San Cristóbal',
        country: 'Ecuador',
        iataCode: 'SCY',
        icaoCode: 'SEST',
        latitude: '-0,91020602',
        longitude: '-89,61740112',
        altitude: '62',
        timezone: '-6',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Camilo Ponce Enriquez Airport',
        city: 'La Toma (Catamayo)',
        country: 'Ecuador',
        iataCode: 'LOH',
        icaoCode: 'SETM',
        latitude: '-3,995889902',
        longitude: '-79,37190247',
        altitude: '4056',
        timezone: '-5',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'General Rivadeneira Airport',
        city: 'Esmeraldas',
        country: 'Ecuador',
        iataCode: 'ESM',
        icaoCode: 'SETN',
        latitude: '0,978519022',
        longitude: '-79,62660217',
        altitude: '32',
        timezone: '-5',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Port Stanley Airport',
        city: 'Stanley',
        country: 'Falkland Islands',
        iataCode: 'PSY',
        icaoCode: 'SFAL',
        latitude: '-51,68569946',
        longitude: '-57,77759933',
        altitude: '75',
        timezone: '-3',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Santa Ana Airport',
        city: 'Cartago',
        country: 'Colombia',
        iataCode: 'CRC',
        icaoCode: 'SKGO',
        latitude: '4,75818',
        longitude: '-75,9557',
        altitude: '2979',
        timezone: '-5',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'La Jagua Airport',
        city: 'Garzón',
        country: 'Colombia',
        iataCode: 'GLJ',
        icaoCode: 'SKGZ',
        latitude: '2,1464',
        longitude: '-75,6944',
        altitude: '2620',
        timezone: '-5',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Caucaya Airport',
        city: 'Puerto Leguízamo',
        country: 'Colombia',
        iataCode: 'LQM',
        icaoCode: 'SKLG',
        latitude: '-0,182278',
        longitude: '-74,7708',
        altitude: '573',
        timezone: '-5',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'La Pedrera Airport',
        city: 'La Pedrera',
        country: 'Colombia',
        iataCode: 'LPD',
        icaoCode: 'SKLP',
        latitude: '-1,32861',
        longitude: '-69,5797',
        altitude: '590',
        timezone: '-5',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Reyes Murillo Airport',
        city: 'Nuquí',
        country: 'Colombia',
        iataCode: 'NQU',
        icaoCode: 'SKNQ',
        latitude: '5,6964',
        longitude: '-77,2806',
        altitude: '12',
        timezone: '-5',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Obando Airport',
        city: 'Puerto Inírida',
        country: 'Colombia',
        iataCode: 'PDA',
        icaoCode: 'SKPD',
        latitude: '3,85353',
        longitude: '-67,9062',
        altitude: '460',
        timezone: '-5',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'El Yopal Airport',
        city: 'Yopal',
        country: 'Colombia',
        iataCode: 'EYP',
        icaoCode: 'SKYP',
        latitude: '5,31911',
        longitude: '-72,384',
        altitude: '1028',
        timezone: '-5',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Capitán de Av. Emilio Beltrán Airport',
        city: 'Guayaramerín',
        country: 'Bolivia',
        iataCode: 'GYA',
        icaoCode: 'SLGY',
        latitude: '-10,82059956',
        longitude: '-65,34559631',
        altitude: '557',
        timezone: '-4',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Puerto Rico Airport',
        city: 'Puerto Rico/Manuripi',
        country: 'Bolivia',
        iataCode: 'PUR',
        icaoCode: 'SLPR',
        latitude: '-11,10766315',
        longitude: '-67,55115509',
        altitude: '597',
        timezone: '-4',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Capitán Av. Selin Zeitun Lopez Airport',
        city: 'Riberalta',
        country: 'Bolivia',
        iataCode: 'RIB',
        icaoCode: 'SLRI',
        latitude: '-11',
        longitude: '-66',
        altitude: '462',
        timezone: '-4',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Reyes Airport',
        city: 'Reyes',
        country: 'Bolivia',
        iataCode: 'REY',
        icaoCode: 'SLRY',
        latitude: '-14,30440044',
        longitude: '-67,35340118',
        altitude: '935',
        timezone: '-4',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Capitán Av. German Quiroga G. Airport',
        city: 'San Borja',
        country: 'Bolivia',
        iataCode: 'SRJ',
        icaoCode: 'SLSB',
        latitude: '-14,85919952',
        longitude: '-66,73750305',
        altitude: '633',
        timezone: '-4',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Zorg en Hoop Airport',
        city: 'Paramaribo',
        country: 'Suriname',
        iataCode: 'ORG',
        icaoCode: 'SMZO',
        latitude: '5,811079979',
        longitude: '-55,19070053',
        altitude: '10',
        timezone: '-3',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Mucuri Airport',
        city: 'Mucuri',
        country: 'Brazil',
        iataCode: 'MVS',
        icaoCode: 'SNMU',
        latitude: '-18,0489006',
        longitude: '-39,86420059',
        altitude: '276',
        timezone: '-3',
        dst: 'S',
        type: 'airport',
    },
    {
        name: 'Caballococha Airport',
        city: 'Caballococha',
        country: 'Peru',
        iataCode: 'LHC',
        icaoCode: 'SPBC',
        latitude: '-3,916860104',
        longitude: '-70,5082016',
        altitude: '328',
        timezone: '-5',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Mayor General FAP Armando Revoredo Iglesias Airport',
        city: 'Cajamarca',
        country: 'Peru',
        iataCode: 'CJA',
        icaoCode: 'SPJR',
        latitude: '-7,139180183',
        longitude: '-78,48940277',
        altitude: '8781',
        timezone: '-5',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Alferez Fap David Figueroa Fernandini Airport',
        city: 'Huánuco',
        country: 'Peru',
        iataCode: 'HUU',
        icaoCode: 'SPNC',
        latitude: '-9,878809929',
        longitude: '-76,20480347',
        altitude: '6070',
        timezone: '-5',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Maria Reiche Neuman Airport',
        city: 'Nazca',
        country: 'Peru',
        iataCode: 'NZC',
        icaoCode: 'SPZA',
        latitude: '-14,85400009',
        longitude: '-74,96150208',
        altitude: '1860',
        timezone: '-5',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Santa Rosa Airport',
        city: 'Santa Rosa',
        country: 'Brazil',
        iataCode: 'SRA',
        icaoCode: 'SSZR',
        latitude: '-27,90670013',
        longitude: '-54,520401',
        altitude: '984',
        timezone: '-3',
        dst: 'S',
        type: 'airport',
    },
    {
        name: 'El Jagüel / Punta del Este Airport',
        city: 'Maldonado',
        country: 'Uruguay',
        iataCode: 'MDO',
        icaoCode: 'SUPE',
        latitude: '-34,91699982',
        longitude: '-54,91699982',
        altitude: '66',
        timezone: '-3',
        dst: 'S',
        type: 'airport',
    },
    {
        name: 'Escuela Mariscal Sucre Airport',
        city: 'Maracay',
        country: 'Venezuela',
        iataCode: 'MYC',
        icaoCode: 'SVBS',
        latitude: '10,24997807',
        longitude: '-67,64942169',
        altitude: '1338',
        timezone: '-4',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Juan Pablo Pérez Alfonso Airport',
        city: 'El Vigía',
        country: 'Venezuela',
        iataCode: 'VIG',
        icaoCode: 'SVVG',
        latitude: '8,624138832',
        longitude: '-71,67266846',
        altitude: '250',
        timezone: '-4',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Ji-Paraná Airport',
        city: 'Ji-Paraná',
        country: 'Brazil',
        iataCode: 'JPR',
        icaoCode: 'SWJI',
        latitude: '-10,87080002',
        longitude: '-61,8465004',
        altitude: '598',
        timezone: '-4',
        dst: 'S',
        type: 'airport',
    },
    {
        name: 'Codrington Airport',
        city: 'Codrington',
        country: 'Antigua and Barbuda',
        iataCode: 'BBQ',
        icaoCode: 'TAPH',
        latitude: '17,635799',
        longitude: '-61,828602',
        altitude: '15',
        timezone: '-4',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'La Désirade Airport',
        city: 'Grande Anse',
        country: 'Guadeloupe',
        iataCode: 'DSD',
        icaoCode: 'TFFA',
        latitude: '16,2968998',
        longitude: '-61,08440018',
        altitude: '10',
        timezone: '-4',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Baillif Airport',
        city: 'Basse Terre',
        country: 'Guadeloupe',
        iataCode: 'BBR',
        icaoCode: 'TFFB',
        latitude: '16,01329994',
        longitude: '-61,74219894',
        altitude: '59',
        timezone: '-4',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'St-François Airport',
        city: 'St-François',
        country: 'Guadeloupe',
        iataCode: 'SFC',
        icaoCode: 'TFFC',
        latitude: '16,25779915',
        longitude: '-61,26250076',
        altitude: '10',
        timezone: '-4',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Les Bases Airport',
        city: 'Grand Bourg',
        country: 'Guadeloupe',
        iataCode: 'GBJ',
        icaoCode: 'TFFM',
        latitude: '15,86870003',
        longitude: '-61,27000046',
        altitude: '16',
        timezone: '-4',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Vance W. Amory International Airport',
        city: 'Charlestown',
        country: 'Saint Kitts and Nevis',
        iataCode: 'NEV',
        icaoCode: 'TKPN',
        latitude: '17,20569992',
        longitude: '-62,58990097',
        altitude: '14',
        timezone: '-4',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Virgin Gorda Airport',
        city: 'Spanish Town',
        country: 'British Virgin Islands',
        iataCode: 'VIJ',
        icaoCode: 'TUPW',
        latitude: '18,44639969',
        longitude: '-64,42749786',
        altitude: '9',
        timezone: '-4',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'J F Mitchell Airport',
        city: 'Bequia',
        country: 'Saint Vincent and the Grenadines',
        iataCode: 'BQU',
        icaoCode: 'TVSB',
        latitude: '12,98840046',
        longitude: '-61,26200104',
        altitude: '15',
        timezone: '-4',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Union Island International Airport',
        city: 'Union Island',
        country: 'Saint Vincent and the Grenadines',
        iataCode: 'UNI',
        icaoCode: 'TVSU',
        latitude: '12,60013485',
        longitude: '-61,41194534',
        altitude: '16',
        timezone: '-4',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Kokshetau Airport',
        city: 'Kokshetau',
        country: 'Kazakhstan',
        iataCode: 'KOV',
        icaoCode: 'UACK',
        latitude: '53,32910156',
        longitude: '69,59459686',
        altitude: '900',
        timezone: '6',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Petropavlosk South Airport',
        city: 'Petropavlosk',
        country: 'Kazakhstan',
        iataCode: 'PPK',
        icaoCode: 'UACP',
        latitude: '54,77470016',
        longitude: '69,18389893',
        altitude: '453',
        timezone: '6',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Zhezkazgan Airport',
        city: 'Zhezkazgan',
        country: 'Kazakhstan',
        iataCode: 'DZN',
        icaoCode: 'UAKD',
        latitude: '47,708302',
        longitude: '67,733299',
        altitude: '1250',
        timezone: '6',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Ust-Kamennogorsk Airport',
        city: 'Ust Kamenogorsk',
        country: 'Kazakhstan',
        iataCode: 'UKK',
        icaoCode: 'UASK',
        latitude: '50,03659821',
        longitude: '82,49420166',
        altitude: '939',
        timezone: '6',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Kostanay West Airport',
        city: 'Kostanay',
        country: 'Kazakhstan',
        iataCode: 'KSN',
        icaoCode: 'UAUU',
        latitude: '53,20690155',
        longitude: '63,5503006',
        altitude: '595',
        timezone: '6',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Ganja Airport',
        city: 'Ganja',
        country: 'Azerbaijan',
        iataCode: 'KVD',
        icaoCode: 'UBBG',
        latitude: '40,73770142',
        longitude: '46,31760025',
        altitude: '1083',
        timezone: '4',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Nakhchivan Airport',
        city: 'Nakhchivan',
        country: 'Azerbaijan',
        iataCode: 'NAJ',
        icaoCode: 'UBBN',
        latitude: '39,18880081',
        longitude: '45,45840073',
        altitude: '2863',
        timezone: '4',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Chulman Airport',
        city: 'Neryungri',
        country: 'Russia',
        iataCode: 'CNN',
        icaoCode: 'UELL',
        latitude: '56,91389847',
        longitude: '124,9140015',
        altitude: '2812',
        timezone: '9',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Polyarny Airport',
        city: 'Yakutia',
        country: 'Russia',
        iataCode: 'PYJ',
        icaoCode: 'UERP',
        latitude: '66,40039825',
        longitude: '112,0299988',
        altitude: '1660',
        timezone: '9',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Chokurdakh Airport',
        city: 'Chokurdah',
        country: 'Russia',
        iataCode: 'CKH',
        icaoCode: 'UESO',
        latitude: '70,62310028',
        longitude: '147,9019928',
        altitude: '151',
        timezone: '11',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Cherskiy Airport',
        city: 'Cherskiy',
        country: 'Russia',
        iataCode: 'CYX',
        icaoCode: 'UESS',
        latitude: '68,74060059',
        longitude: '161,3379974',
        altitude: '20',
        timezone: '11',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Tiksi Airport',
        city: 'Tiksi',
        country: 'Russia',
        iataCode: 'IKS',
        icaoCode: 'UEST',
        latitude: '71,6977005',
        longitude: '128,9029999',
        altitude: '26',
        timezone: '9',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Komsomolsk-on-Amur Airport',
        city: 'Komsomolsk-on-Amur',
        country: 'Russia',
        iataCode: 'KXK',
        icaoCode: 'UHKK',
        latitude: '50,4090004',
        longitude: '136,9340057',
        altitude: '92',
        timezone: '10',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Ugolny Airport',
        city: 'Anadyr',
        country: 'Russia',
        iataCode: 'DYR',
        icaoCode: 'UHMA',
        latitude: '64,73490143',
        longitude: '177,7409973',
        altitude: '194',
        timezone: '12',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Okhotsk Airport',
        city: 'Okhotsk',
        country: 'Russia',
        iataCode: 'OHO',
        icaoCode: 'UHOO',
        latitude: '59,4100647',
        longitude: '143,0565033',
        altitude: '0',
        timezone: '10',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Ujae Atoll Airport',
        city: 'Ujae Atoll',
        country: 'Marshall Islands',
        iataCode: 'UJE',
        icaoCode: 'UJAP',
        latitude: '8,928059578',
        longitude: '165,7619934',
        altitude: '29',
        timezone: '12',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Mariupol International Airport',
        city: 'Mariupol International',
        country: 'Ukraine',
        iataCode: 'MPW',
        icaoCode: 'UKCM',
        latitude: '47,0760994',
        longitude: '37,44960022',
        altitude: '251',
        timezone: '2',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Luhansk International Airport',
        city: 'Lugansk',
        country: 'Ukraine',
        iataCode: 'VSG',
        icaoCode: 'UKCW',
        latitude: '48,41740036',
        longitude: '39,37409973',
        altitude: '636',
        timezone: '2',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Zaporizhzhia International Airport',
        city: 'Zaporozhye',
        country: 'Ukraine',
        iataCode: 'OZH',
        icaoCode: 'UKDE',
        latitude: '47,86700058',
        longitude: '35,31570053',
        altitude: '373',
        timezone: '2',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Kryvyi Rih International Airport',
        city: 'Krivoy Rog',
        country: 'Ukraine',
        iataCode: 'KWG',
        icaoCode: 'UKDR',
        latitude: '48,04330063',
        longitude: '33,20999908',
        altitude: '408',
        timezone: '2',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Kharkiv International Airport',
        city: 'Kharkov',
        country: 'Ukraine',
        iataCode: 'HRK',
        icaoCode: 'UKHH',
        latitude: '49,92480087',
        longitude: '36,29000092',
        altitude: '508',
        timezone: '2',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Ivano-Frankivsk International Airport',
        city: 'Ivano-Frankivsk',
        country: 'Ukraine',
        iataCode: 'IFO',
        icaoCode: 'UKLI',
        latitude: '48,88420105',
        longitude: '24,68610001',
        altitude: '919',
        timezone: '2',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Chernivtsi International Airport',
        city: 'Chernovtsk',
        country: 'Ukraine',
        iataCode: 'CWC',
        icaoCode: 'UKLN',
        latitude: '48,25930023',
        longitude: '25,98080063',
        altitude: '826',
        timezone: '2',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Rivne International Airport',
        city: 'Rivne',
        country: 'Ukraine',
        iataCode: 'RWN',
        icaoCode: 'UKLR',
        latitude: '50,60710144',
        longitude: '26,14159966',
        altitude: '755',
        timezone: '2',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Uzhhorod International Airport',
        city: 'Uzhgorod',
        country: 'Ukraine',
        iataCode: 'UDJ',
        icaoCode: 'UKLU',
        latitude: '48,63430023',
        longitude: '22,26339912',
        altitude: '383',
        timezone: '2',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Solovki Airport',
        city: 'Solovetsky Islands',
        country: 'Russia',
        iataCode: 'CSH',
        icaoCode: 'ULAS',
        latitude: '65,02999878',
        longitude: '35,73333359',
        altitude: '60',
        timezone: '3',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Cherepovets Airport',
        city: 'Cherepovets',
        country: 'Russia',
        iataCode: 'CEE',
        icaoCode: 'ULBC',
        latitude: '59,27360153',
        longitude: '38,01580048',
        altitude: '377',
        timezone: '3',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Amderma Airport',
        city: 'Amderma',
        country: 'Russia',
        iataCode: 'AMV',
        icaoCode: 'ULDD',
        latitude: '69,76329803',
        longitude: '61,5564003',
        altitude: '13',
        timezone: '3',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Kotlas Airport',
        city: 'Kotlas',
        country: 'Russia',
        iataCode: 'KSZ',
        icaoCode: 'ULKK',
        latitude: '61,2358017',
        longitude: '46,69749832',
        altitude: '184',
        timezone: '3',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Petrozavodsk Airport',
        city: 'Petrozavodsk',
        country: 'Russia',
        iataCode: 'PES',
        icaoCode: 'ULPB',
        latitude: '61,8852005',
        longitude: '34,15470123',
        altitude: '151',
        timezone: '3',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Hrodna Airport',
        city: 'Hrodna',
        country: 'Belarus',
        iataCode: 'GNA',
        icaoCode: 'UMMG',
        latitude: '53,60200119',
        longitude: '24,05380058',
        altitude: '443',
        timezone: '3',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Mogilev Airport',
        city: 'Mogilev',
        country: 'Belarus',
        iataCode: 'MVQ',
        icaoCode: 'UMOO',
        latitude: '53,95489883',
        longitude: '30,0951004',
        altitude: '637',
        timezone: '3',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Yeniseysk Airport',
        city: 'Yeniseysk',
        country: 'Russia',
        iataCode: 'EIE',
        icaoCode: 'UNII',
        latitude: '58,4742012',
        longitude: '92,11250305',
        altitude: '253',
        timezone: '7',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Kyzyl Airport',
        city: 'Kyzyl',
        country: 'Russia',
        iataCode: 'KYZ',
        icaoCode: 'UNKY',
        latitude: '51,66939926',
        longitude: '94,40059662',
        altitude: '2123',
        timezone: '7',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Spichenkovo Airport',
        city: 'Novokuznetsk',
        country: 'Russia',
        iataCode: 'NOZ',
        icaoCode: 'UNWW',
        latitude: '53,81140137',
        longitude: '86,87719727',
        altitude: '1024',
        timezone: '7',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Khatanga Airport',
        city: 'Khatanga',
        country: 'Russia',
        iataCode: 'HTG',
        icaoCode: 'UOHH',
        latitude: '71,97810364',
        longitude: '102,4909973',
        altitude: '95',
        timezone: '7',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Igarka Airport',
        city: 'Igarka',
        country: 'Russia',
        iataCode: 'IAA',
        icaoCode: 'UOII',
        latitude: '67,43720245',
        longitude: '86,62190247',
        altitude: '82',
        timezone: '7',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Khankala Air Base',
        city: 'Grozny',
        country: 'Russia',
        iataCode: 'GRV',
        icaoCode: 'URMG',
        latitude: '43,29809952',
        longitude: '45,78409958',
        altitude: '548',
        timezone: '3',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Nalchik Airport',
        city: 'Nalchik',
        country: 'Russia',
        iataCode: 'NAL',
        icaoCode: 'URMN',
        latitude: '43,51290131',
        longitude: '43,63660049',
        altitude: '1461',
        timezone: '3',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Beslan Airport',
        city: 'Beslan',
        country: 'Russia',
        iataCode: 'OGZ',
        icaoCode: 'URMO',
        latitude: '43,20510101',
        longitude: '44,60660172',
        altitude: '1673',
        timezone: '3',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Elista Airport',
        city: 'Elista',
        country: 'Russia',
        iataCode: 'ESL',
        icaoCode: 'URWI',
        latitude: '46,37390137',
        longitude: '44,33089828',
        altitude: '501',
        timezone: '3',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Aleknagik / New Airport',
        city: 'Aleknagik',
        country: 'United States',
        iataCode: 'WKK',
        icaoCode: '5A8',
        latitude: '59,2826004',
        longitude: '-158,6179962',
        altitude: '66',
        timezone: '-9',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Mercer County Airport',
        city: 'Bluefield',
        country: 'United States',
        iataCode: 'BLF',
        icaoCode: 'KBLF',
        latitude: '37,29579926',
        longitude: '-81,20770264',
        altitude: '2857',
        timezone: '-5',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Mid Delta Regional Airport',
        city: 'Greenville',
        country: 'United States',
        iataCode: 'GLH',
        icaoCode: 'KGLH',
        latitude: '33,48289871',
        longitude: '-90,98560333',
        altitude: '131',
        timezone: '-6',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Tri Cities Airport',
        city: 'Pasco',
        country: 'United States',
        iataCode: 'PSC',
        icaoCode: 'KPSC',
        latitude: '46,26470184',
        longitude: '-119,1190033',
        altitude: '410',
        timezone: '-8',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Akutan Seaplane Base',
        city: 'Akutan',
        country: 'United States',
        iataCode: 'KQA',
        icaoCode: 'KQA',
        latitude: '54,13377044',
        longitude: '-165,7788956',
        altitude: '0',
        timezone: '-9',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Lopez Island Airport',
        city: 'Lopez',
        country: 'United States',
        iataCode: 'LPS',
        icaoCode: 'S31',
        latitude: '48,48389816',
        longitude: '-122,9380035',
        altitude: '209',
        timezone: '-8',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Salekhard Airport',
        city: 'Salekhard',
        country: 'Russia',
        iataCode: 'SLY',
        icaoCode: 'USDD',
        latitude: '66,59079742',
        longitude: '66,61100006',
        altitude: '218',
        timezone: '5',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Khanty Mansiysk Airport',
        city: 'Khanty-Mansiysk',
        country: 'Russia',
        iataCode: 'HMA',
        icaoCode: 'USHH',
        latitude: '61,0284996',
        longitude: '69,08609772',
        altitude: '76',
        timezone: '5',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Nyagan Airport',
        city: 'Nyagan',
        country: 'Russia',
        iataCode: 'NYA',
        icaoCode: 'USHN',
        latitude: '62,11000061',
        longitude: '65,61499786',
        altitude: '361',
        timezone: '5',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Sovetskiy Airport',
        city: 'Sovetskiy',
        country: 'Russia',
        iataCode: 'OVS',
        icaoCode: 'USHS',
        latitude: '61,32662201',
        longitude: '63,60191345',
        altitude: '351',
        timezone: '5',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Izhevsk Airport',
        city: 'Izhevsk',
        country: 'Russia',
        iataCode: 'IJK',
        icaoCode: 'USII',
        latitude: '56,8280983',
        longitude: '53,45750046',
        altitude: '531',
        timezone: '4',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Pobedilovo Airport',
        city: 'Kirov',
        country: 'Russia',
        iataCode: 'KVX',
        icaoCode: 'USKK',
        latitude: '58,50329971',
        longitude: '49,34830093',
        altitude: '479',
        timezone: '3',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Nadym Airport',
        city: 'Nadym',
        country: 'Russia',
        iataCode: 'NYM',
        icaoCode: 'USMM',
        latitude: '65,48090363',
        longitude: '72,69889832',
        altitude: '49',
        timezone: '5',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Raduzhny Airport',
        city: 'Raduzhnyi',
        country: 'Russia',
        iataCode: 'RAT',
        icaoCode: 'USNR',
        latitude: '62,15859985',
        longitude: '77,3289032',
        altitude: '250',
        timezone: '5',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Nefteyugansk Airport',
        city: 'Nefteyugansk',
        country: 'Russia',
        iataCode: 'NFG',
        icaoCode: 'USRN',
        latitude: '61,10829926',
        longitude: '72,65000153',
        altitude: '115',
        timezone: '5',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Kurgan Airport',
        city: 'Kurgan',
        country: 'Russia',
        iataCode: 'KRO',
        icaoCode: 'USUU',
        latitude: '55,47529984',
        longitude: '65,41560364',
        altitude: '240',
        timezone: '5',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Khudzhand Airport',
        city: 'Khudzhand',
        country: 'Tajikistan',
        iataCode: 'LBD',
        icaoCode: 'UTDL',
        latitude: '40,2154007',
        longitude: '69,69470215',
        altitude: '1450',
        timezone: '5',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Andizhan Airport',
        city: 'Andizhan',
        country: 'Uzbekistan',
        iataCode: 'AZN',
        icaoCode: 'UTKA',
        latitude: '40,72769928',
        longitude: '72,29399872',
        altitude: '1515',
        timezone: '5',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Fergana International Airport',
        city: 'Fergana',
        country: 'Uzbekistan',
        iataCode: 'FEG',
        icaoCode: 'UTKF',
        latitude: '40,35879898',
        longitude: '71,74500275',
        altitude: '1980',
        timezone: '5',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Namangan Airport',
        city: 'Namangan',
        country: 'Uzbekistan',
        iataCode: 'NMA',
        icaoCode: 'UTKN',
        latitude: '40,98460007',
        longitude: '71,55670166',
        altitude: '1555',
        timezone: '5',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Nukus Airport',
        city: 'Nukus',
        country: 'Uzbekistan',
        iataCode: 'NCU',
        icaoCode: 'UTNN',
        latitude: '42,48839951',
        longitude: '59,62329865',
        altitude: '246',
        timezone: '5',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Urgench Airport',
        city: 'Urgench',
        country: 'Uzbekistan',
        iataCode: 'UGC',
        icaoCode: 'UTNU',
        latitude: '41,58430099',
        longitude: '60,64170074',
        altitude: '320',
        timezone: '5',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Karshi Khanabad Airport',
        city: 'Khanabad',
        country: 'Uzbekistan',
        iataCode: 'KSQ',
        icaoCode: 'UTSL',
        latitude: '38,83359909',
        longitude: '65,92150116',
        altitude: '1365',
        timezone: '5',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Termez Airport',
        city: 'Termez',
        country: 'Uzbekistan',
        iataCode: 'TMJ',
        icaoCode: 'UTST',
        latitude: '37,2867012',
        longitude: '67,30999756',
        altitude: '1027',
        timezone: '5',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Staroselye Airport',
        city: 'Rybinsk',
        country: 'Russia',
        iataCode: 'RYB',
        icaoCode: 'UUBK',
        latitude: '58,10419846',
        longitude: '38,9294014',
        altitude: '423',
        timezone: '3',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Belgorod International Airport',
        city: 'Belgorod',
        country: 'Russia',
        iataCode: 'EGO',
        icaoCode: 'UUOB',
        latitude: '50,64379883',
        longitude: '36,59009933',
        altitude: '735',
        timezone: '3',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Kursk East Airport',
        city: 'Kursk',
        country: 'Russia',
        iataCode: 'URS',
        icaoCode: 'UUOK',
        latitude: '51,75059891',
        longitude: '36,29560089',
        altitude: '686',
        timezone: '3',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Lipetsk Airport',
        city: 'Lipetsk',
        country: 'Russia',
        iataCode: 'LPK',
        icaoCode: 'UUOL',
        latitude: '52,70280075',
        longitude: '39,53779984',
        altitude: '584',
        timezone: '3',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Vorkuta Airport',
        city: 'Vorkuta',
        country: 'Russia',
        iataCode: 'VKT',
        icaoCode: 'UUYW',
        latitude: '67,48860168',
        longitude: '63,99309921',
        altitude: '604',
        timezone: '3',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Bugulma Airport',
        city: 'Bugulma',
        country: 'Russia',
        iataCode: 'UUA',
        icaoCode: 'UWKB',
        latitude: '54,63999939',
        longitude: '52,80170059',
        altitude: '991',
        timezone: '3',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Yoshkar-Ola Airport',
        city: 'Yoshkar-Ola',
        country: 'Russia',
        iataCode: 'JOK',
        icaoCode: 'UWKJ',
        latitude: '56,70059967',
        longitude: '47,90470123',
        altitude: '348',
        timezone: '3',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Cheboksary Airport',
        city: 'Cheboksary',
        country: 'Russia',
        iataCode: 'CSY',
        icaoCode: 'UWKS',
        latitude: '56,09030151',
        longitude: '47,34730148',
        altitude: '558',
        timezone: '3',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Ulyanovsk East Airport',
        city: 'Ulyanovsk',
        country: 'Russia',
        iataCode: 'ULY',
        icaoCode: 'UWLW',
        latitude: '54,40100098',
        longitude: '48,80270004',
        altitude: '252',
        timezone: '4',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Orsk Airport',
        city: 'Orsk',
        country: 'Russia',
        iataCode: 'OSW',
        icaoCode: 'UWOR',
        latitude: '51,07249832',
        longitude: '58,59560013',
        altitude: '909',
        timezone: '5',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Penza Airport',
        city: 'Penza',
        country: 'Russia',
        iataCode: 'PEZ',
        icaoCode: 'UWPP',
        latitude: '53,11059952',
        longitude: '45,02109909',
        altitude: '614',
        timezone: '3',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Saransk Airport',
        city: 'Saransk',
        country: 'Russia',
        iataCode: 'SKX',
        icaoCode: 'UWPS',
        latitude: '54,1251297',
        longitude: '45,21225739',
        altitude: '676',
        timezone: '3',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Balakovo Airport',
        city: 'Balakovo',
        country: 'Russia',
        iataCode: 'BWO',
        icaoCode: 'UWSB',
        latitude: '51,85829926',
        longitude: '47,74560165',
        altitude: '95',
        timezone: '3',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Hubli Airport',
        city: 'Hubli',
        country: 'India',
        iataCode: 'HBX',
        icaoCode: 'VAHB',
        latitude: '15,36170006',
        longitude: '75,0848999',
        altitude: '2171',
        timezone: '5,5',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Koggala Airport',
        city: 'Koggala',
        country: 'Sri Lanka',
        iataCode: 'KCT',
        icaoCode: 'VCCK',
        latitude: '5,99368',
        longitude: '80,32029724',
        altitude: '10',
        timezone: '5,5',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Weerawila Airport',
        city: 'Wirawila',
        country: 'Sri Lanka',
        iataCode: 'WRZ',
        icaoCode: 'VCCW',
        latitude: '6,254489899',
        longitude: '81,23519897',
        altitude: '50',
        timezone: '5,5',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Battambang Airport',
        city: 'Battambang',
        country: 'Cambodia',
        iataCode: 'BBM',
        icaoCode: 'VDBG',
        latitude: '13,09560013',
        longitude: '103,223999',
        altitude: '59',
        timezone: '7',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Shillong Airport',
        city: 'Shillong',
        country: 'India',
        iataCode: 'SHL',
        icaoCode: 'VEBI',
        latitude: '25,70359993',
        longitude: '91,97869873',
        altitude: '2910',
        timezone: '5,5',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Lokpriya Gopinath Bordoloi International Airport',
        city: 'Guwahati',
        country: 'India',
        iataCode: 'GAU',
        icaoCode: 'VEGT',
        latitude: '26,10610008',
        longitude: '91,58589935',
        altitude: '162',
        timezone: '5,5',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Dimapur Airport',
        city: 'Dimapur',
        country: 'India',
        iataCode: 'DMU',
        icaoCode: 'VEMR',
        latitude: '25,88389969',
        longitude: '93,77110291',
        altitude: '487',
        timezone: '5,5',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Tezpur Airport',
        city: 'Tezpur',
        country: 'India',
        iataCode: 'TEZ',
        icaoCode: 'VETZ',
        latitude: '26,70910072',
        longitude: '92,78469849',
        altitude: '240',
        timezone: '5,5',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Barisal Airport',
        city: 'Barisal',
        country: 'Bangladesh',
        iataCode: 'BZL',
        icaoCode: 'VGBR',
        latitude: '22,8010006',
        longitude: '90,30120087',
        altitude: '23',
        timezone: '6',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Ban Huoeisay Airport',
        city: 'Huay Xai',
        country: 'Laos',
        iataCode: 'OUI',
        icaoCode: 'VLHS',
        latitude: '20,25729942',
        longitude: '100,4369965',
        altitude: '1380',
        timezone: '7',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Bharatpur Airport',
        city: 'Bharatpur',
        country: 'Nepal',
        iataCode: 'BHR',
        icaoCode: 'VNBP',
        latitude: '27,67810059',
        longitude: '84,42939758',
        altitude: '600',
        timezone: '5,75',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Bhadrapur Airport',
        city: 'Chandragarhi',
        country: 'Nepal',
        iataCode: 'BDP',
        icaoCode: 'VNCG',
        latitude: '26,57080078',
        longitude: '88,07959747',
        altitude: '300',
        timezone: '5,75',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Meghauli Airport',
        city: 'Meghauli',
        country: 'Nepal',
        iataCode: 'MEY',
        icaoCode: 'VNMG',
        latitude: '27,5774',
        longitude: '84,22875',
        altitude: '600',
        timezone: '5,75',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Nepalgunj Airport',
        city: 'Nepalgunj',
        country: 'Nepal',
        iataCode: 'KEP',
        icaoCode: 'VNNG',
        latitude: '28,10359955',
        longitude: '81,66699982',
        altitude: '540',
        timezone: '5,75',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Gan International Airport',
        city: 'Gan Island',
        country: 'Maldives',
        iataCode: 'GAN',
        icaoCode: 'VRMG',
        latitude: '-0,69334197',
        longitude: '73,1556015',
        altitude: '6',
        timezone: '5',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Hanimaadhoo Airport',
        city: 'Haa Dhaalu Atoll',
        country: 'Maldives',
        iataCode: 'HAQ',
        icaoCode: 'VRMH',
        latitude: '6,744229794',
        longitude: '73,17050171',
        altitude: '4',
        timezone: '5',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Kadhdhoo Airport',
        city: 'Laamu Atoll',
        country: 'Maldives',
        iataCode: 'KDO',
        icaoCode: 'VRMK',
        latitude: '1,85916996',
        longitude: '73,52189636',
        altitude: '4',
        timezone: '5',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Mae Sot Airport',
        city: 'Tak',
        country: 'Thailand',
        iataCode: 'MAQ',
        icaoCode: 'VTPM',
        latitude: '16,69989967',
        longitude: '98,54509735',
        altitude: '690',
        timezone: '7',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Buon Ma Thuot Airport',
        city: 'Buonmethuot',
        country: 'Vietnam',
        iataCode: 'BMV',
        icaoCode: 'VVBM',
        latitude: '12,66829968',
        longitude: '108,1200027',
        altitude: '1729',
        timezone: '7',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Cat Bi International Airport',
        city: 'Haiphong',
        country: 'Vietnam',
        iataCode: 'HPH',
        icaoCode: 'VVCI',
        latitude: '20,81940079',
        longitude: '106,7249985',
        altitude: '6',
        timezone: '7',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Cam Ranh Airport',
        city: 'Nha Trang',
        country: 'Vietnam',
        iataCode: 'CXR',
        icaoCode: 'VVCR',
        latitude: '11,99820042',
        longitude: '109,2190018',
        altitude: '40',
        timezone: '7',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Co Ong Airport',
        city: 'Conson',
        country: 'Vietnam',
        iataCode: 'VCS',
        icaoCode: 'VVCS',
        latitude: '8,731829643',
        longitude: '106,6330032',
        altitude: '20',
        timezone: '7',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Can Tho International Airport',
        city: 'Can Tho',
        country: 'Vietnam',
        iataCode: 'VCA',
        icaoCode: 'VVCT',
        latitude: '10,08510017',
        longitude: '105,711998',
        altitude: '9',
        timezone: '7',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Dien Bien Phu Airport',
        city: 'Dienbienphu',
        country: 'Vietnam',
        iataCode: 'DIN',
        icaoCode: 'VVDB',
        latitude: '21,39749908',
        longitude: '103,0080032',
        altitude: '1611',
        timezone: '7',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Phu Cat Airport',
        city: 'Phucat',
        country: 'Vietnam',
        iataCode: 'UIH',
        icaoCode: 'VVPC',
        latitude: '13,95499992',
        longitude: '109,0419998',
        altitude: '80',
        timezone: '7',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Pleiku Airport',
        city: 'Pleiku',
        country: 'Vietnam',
        iataCode: 'PXU',
        icaoCode: 'VVPK',
        latitude: '14,00450039',
        longitude: '108,0169983',
        altitude: '2434',
        timezone: '7',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Vinh Airport',
        city: 'Vinh',
        country: 'Vietnam',
        iataCode: 'VII',
        icaoCode: 'VVVH',
        latitude: '18,73760033',
        longitude: '105,6709976',
        altitude: '23',
        timezone: '7',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Banmaw Airport',
        city: 'Banmaw',
        country: 'Burma',
        iataCode: 'BMO',
        icaoCode: 'VYBM',
        latitude: '24,2689991',
        longitude: '97,24620056',
        altitude: '370',
        timezone: '6,5',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Dawei Airport',
        city: 'Dawei',
        country: 'Burma',
        iataCode: 'TVY',
        icaoCode: 'VYDW',
        latitude: '14,10389996',
        longitude: '98,20359802',
        altitude: '84',
        timezone: '6,5',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Kawthoung Airport',
        city: 'Kawthoung',
        country: 'Burma',
        iataCode: 'KAW',
        icaoCode: 'VYKT',
        latitude: '10,04930019',
        longitude: '98,53800201',
        altitude: '180',
        timezone: '6,5',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Loikaw Airport',
        city: 'Loikaw',
        country: 'Burma',
        iataCode: 'LIW',
        icaoCode: 'VYLK',
        latitude: '19,69149971',
        longitude: '97,21479797',
        altitude: '2940',
        timezone: '6,5',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Mawlamyine Airport',
        city: 'Mawlamyine',
        country: 'Burma',
        iataCode: 'MNU',
        icaoCode: 'VYMM',
        latitude: '16,44470024',
        longitude: '97,66069794',
        altitude: '52',
        timezone: '6,5',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Pathein Airport',
        city: 'Pathein',
        country: 'Burma',
        iataCode: 'BSX',
        icaoCode: 'VYPN',
        latitude: '16,81520081',
        longitude: '94,7798996',
        altitude: '20',
        timezone: '6,5',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Pakhokku Airport',
        city: 'Pakhokku',
        country: 'Burma',
        iataCode: 'PKK',
        icaoCode: 'VYPU',
        latitude: '21,4043',
        longitude: '95,11125',
        altitude: '151',
        timezone: '6,5',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Sumbawa Besar Airport',
        city: 'Sumbawa Island',
        country: 'Indonesia',
        iataCode: 'SWQ',
        icaoCode: 'WADS',
        latitude: '-8,489040375',
        longitude: '117,4120026',
        altitude: '16',
        timezone: '8',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Tambolaka Airport',
        city: 'Waikabubak-Sumba Island',
        country: 'Indonesia',
        iataCode: 'TMC',
        icaoCode: 'WADT',
        latitude: '-9,409720421',
        longitude: '119,2440033',
        altitude: '161',
        timezone: '8',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Bokondini Airport',
        city: 'Bokondini-Papua Island',
        country: 'Indonesia',
        iataCode: 'BUI',
        icaoCode: 'WAJB',
        latitude: '-3,6822',
        longitude: '138,6755',
        altitude: '4550',
        timezone: '9',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Senggeh Airport',
        city: 'Senggeh-Papua Island',
        country: 'Indonesia',
        iataCode: 'SEH',
        icaoCode: 'WAJS',
        latitude: '-3,45',
        longitude: '140,779',
        altitude: '914',
        timezone: '9',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Tanjung Harapan Airport',
        city: 'Tanjung Selor-Borneo Island',
        country: 'Indonesia',
        iataCode: 'TJS',
        icaoCode: 'WALG',
        latitude: '2,835833333',
        longitude: '117,3736111',
        altitude: '10',
        timezone: '8',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Datadawai Airport',
        city: 'Datadawai-Borneo Island',
        country: 'Indonesia',
        iataCode: 'DTD',
        icaoCode: 'WALJ',
        latitude: '0,8106',
        longitude: '114,5306',
        altitude: '508',
        timezone: '8',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Barau(Kalimaru) Airport',
        city: 'Tanjung Redep-Borneo Island',
        country: 'Indonesia',
        iataCode: 'BEJ',
        icaoCode: 'WALK',
        latitude: '2,155499935',
        longitude: '117,4319992',
        altitude: '59',
        timezone: '8',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Warukin Airport',
        city: 'Tanjung-Borneo Island',
        country: 'Indonesia',
        iataCode: 'TJG',
        icaoCode: 'WAON',
        latitude: '-2,216559887',
        longitude: '115,435997',
        altitude: '197',
        timezone: '8',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Sampit(Hasan) Airport',
        city: 'Sampit-Borneo Island',
        country: 'Indonesia',
        iataCode: 'SMQ',
        icaoCode: 'WAOS',
        latitude: '-2,499190092',
        longitude: '112,9749985',
        altitude: '50',
        timezone: '7',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Dumatumbun Airport',
        city: 'Langgur-Kei Islands',
        country: 'Indonesia',
        iataCode: 'LUV',
        icaoCode: 'WAPL',
        latitude: '-5,66162014',
        longitude: '132,7310028',
        altitude: '10',
        timezone: '9',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Mali Airport',
        city: 'Alor Island',
        country: 'Indonesia',
        iataCode: 'ARD',
        icaoCode: 'WATM',
        latitude: '-8,132340431',
        longitude: '124,5970001',
        altitude: '10',
        timezone: '8',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Belaga Airport',
        city: 'Belaga',
        country: 'Malaysia',
        iataCode: 'BLG',
        icaoCode: 'WBGC',
        latitude: '2,650000095',
        longitude: '113,7669983',
        altitude: '200',
        timezone: '8',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Long Lellang Airport',
        city: 'Long Datih',
        country: 'Malaysia',
        iataCode: 'LGL',
        icaoCode: 'WBGF',
        latitude: '3,421000004',
        longitude: '115,1539993',
        altitude: '1400',
        timezone: '8',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Long Seridan Airport',
        city: 'Long Seridan',
        country: 'Malaysia',
        iataCode: 'ODN',
        icaoCode: 'WBGI',
        latitude: '3,967000008',
        longitude: '115,0500031',
        altitude: '607',
        timezone: '8',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Mukah Airport',
        city: 'Mukah',
        country: 'Malaysia',
        iataCode: 'MKM',
        icaoCode: 'WBGK',
        latitude: '2,906389952',
        longitude: '112,0800018',
        altitude: '13',
        timezone: '8',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Bakalalan Airport',
        city: 'Bakalalan',
        country: 'Malaysia',
        iataCode: 'BKM',
        icaoCode: 'WBGQ',
        latitude: '3,973999977',
        longitude: '115,6179962',
        altitude: '2900',
        timezone: '8',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Lawas Airport',
        city: 'Lawas',
        country: 'Malaysia',
        iataCode: 'LWY',
        icaoCode: 'WBGW',
        latitude: '4,849170208',
        longitude: '115,4079971',
        altitude: '5',
        timezone: '8',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Bario Airport',
        city: 'Bario',
        country: 'Malaysia',
        iataCode: 'BBN',
        icaoCode: 'WBGZ',
        latitude: '3,733890057',
        longitude: '115,4789963',
        altitude: '3350',
        timezone: '8',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Tomanggong Airport',
        city: 'Tomanggong',
        country: 'Malaysia',
        iataCode: 'TMG',
        icaoCode: 'WBKM',
        latitude: '5,400000095',
        longitude: '118,6500015',
        altitude: '26',
        timezone: '8',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Kudat Airport',
        city: 'Kudat',
        country: 'Malaysia',
        iataCode: 'KUD',
        icaoCode: 'WBKT',
        latitude: '6,922500134',
        longitude: '116,8359985',
        altitude: '10',
        timezone: '8',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Radin Inten II (Branti) Airport',
        city: 'Bandar Lampung-Sumatra Island',
        country: 'Indonesia',
        iataCode: 'TKG',
        icaoCode: 'WIAT',
        latitude: '-5,240556',
        longitude: '105,175556',
        altitude: '282',
        timezone: '7',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Halim Perdanakusuma International Airport',
        city: 'Jakarta',
        country: 'Indonesia',
        iataCode: 'HLP',
        icaoCode: 'WIHH',
        latitude: '-6,266610146',
        longitude: '106,8909988',
        altitude: '84',
        timezone: '7',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Ranai Airport',
        city: 'Ranai-Natuna Besar Island',
        country: 'Indonesia',
        iataCode: 'NTX',
        icaoCode: 'WION',
        latitude: '3,908710003',
        longitude: '108,3880005',
        altitude: '7',
        timezone: '7',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Pangsuma Airport',
        city: 'Putussibau-Borneo Island',
        country: 'Indonesia',
        iataCode: 'PSU',
        icaoCode: 'WIOP',
        latitude: '0,835578024',
        longitude: '112,9369965',
        altitude: '297',
        timezone: '7',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Sintang(Susilo) Airport',
        city: 'Sintang-Borneo Island',
        country: 'Indonesia',
        iataCode: 'SQG',
        icaoCode: 'WIOS',
        latitude: '0,063619003',
        longitude: '111,4729996',
        altitude: '98',
        timezone: '7',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Pendopo Airport',
        city: 'Talang Gudang-Sumatra Island',
        country: 'Indonesia',
        iataCode: 'PDO',
        icaoCode: 'WIPQ',
        latitude: '-3,286070108',
        longitude: '103,8799973',
        altitude: '184',
        timezone: '7',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Malikus Saleh Airport',
        city: 'Lhok Seumawe-Sumatra Island',
        country: 'Indonesia',
        iataCode: 'LSW',
        icaoCode: 'WITM',
        latitude: '5,226679802',
        longitude: '96,95030212',
        altitude: '90',
        timezone: '7',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Pulau Pangkor Airport',
        city: 'Pangkor Island',
        country: 'Malaysia',
        iataCode: 'PKG',
        icaoCode: 'WMPA',
        latitude: '4,244719982',
        longitude: '100,5530014',
        altitude: '19',
        timezone: '8',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Long Bawan Airport',
        city: 'Long Bawan-Borneo Island',
        country: 'Indonesia',
        iataCode: 'LBW',
        icaoCode: 'WRLB',
        latitude: '3,9028',
        longitude: '115,6921',
        altitude: '3165',
        timezone: '8',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Nunukan Airport',
        city: 'Nunukan-Nunukan Island',
        country: 'Indonesia',
        iataCode: 'NNX',
        icaoCode: 'WRLF',
        latitude: '4,133333333',
        longitude: '117,6666667',
        altitude: '30',
        timezone: '8',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Long Apung Airport',
        city: 'Long Apung-Borneo Island',
        country: 'Indonesia',
        iataCode: 'LPU',
        icaoCode: 'WRLP',
        latitude: '1,704486',
        longitude: '114,970297',
        altitude: '627',
        timezone: '8',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Albany Airport',
        city: 'Albany',
        country: 'Australia',
        iataCode: 'ALH',
        icaoCode: 'YABA',
        latitude: '-34,94329834',
        longitude: '117,8089981',
        altitude: '233',
        timezone: '8',
        dst: 'O',
        type: 'airport',
    },
    {
        name: 'Argyle Airport',
        city: 'Argyle',
        country: 'Australia',
        iataCode: 'GYL',
        icaoCode: 'YARG',
        latitude: '-16,6369',
        longitude: '128,451004',
        altitude: '522',
        timezone: '8',
        dst: 'O',
        type: 'airport',
    },
    {
        name: 'Aurukun Airport',
        city: 'Aurukun',
        country: 'Australia',
        iataCode: 'AUU',
        icaoCode: 'YAUR',
        latitude: '-13,354067',
        longitude: '141,72065',
        altitude: '31',
        timezone: '10',
        dst: 'O',
        type: 'airport',
    },
    {
        name: 'Barcaldine Airport',
        city: 'Barcaldine',
        country: 'Australia',
        iataCode: 'BCI',
        icaoCode: 'YBAR',
        latitude: '-23,56529999',
        longitude: '145,3070068',
        altitude: '878',
        timezone: '10',
        dst: 'O',
        type: 'airport',
    },
    {
        name: 'Badu Island Airport',
        city: 'Badu Island',
        country: 'Australia',
        iataCode: 'BDD',
        icaoCode: 'YBAU',
        latitude: '-10,14999962',
        longitude: '142,1734',
        altitude: '14',
        timezone: '10',
        dst: 'O',
        type: 'airport',
    },
    {
        name: 'Birdsville Airport',
        city: 'Birdsville',
        country: 'Australia',
        iataCode: 'BVI',
        icaoCode: 'YBDV',
        latitude: '-25,89749908',
        longitude: '139,3480072',
        altitude: '159',
        timezone: '10',
        dst: 'O',
        type: 'airport',
    },
    {
        name: 'Broken Hill Airport',
        city: 'Broken Hill',
        country: 'Australia',
        iataCode: 'BHQ',
        icaoCode: 'YBHI',
        latitude: '-32,00139999',
        longitude: '141,4720001',
        altitude: '958',
        timezone: '9,5',
        dst: 'O',
        type: 'airport',
    },
    {
        name: 'Hamilton Island Airport',
        city: 'Hamilton Island',
        country: 'Australia',
        iataCode: 'HTI',
        icaoCode: 'YBHM',
        latitude: '-20,35810089',
        longitude: '148,9519959',
        altitude: '15',
        timezone: '10',
        dst: 'O',
        type: 'airport',
    },
    {
        name: 'Bedourie Airport',
        city: 'Bedourie',
        country: 'Australia',
        iataCode: 'BEU',
        icaoCode: 'YBIE',
        latitude: '-24,34609985',
        longitude: '139,4600067',
        altitude: '300',
        timezone: '10',
        dst: 'O',
        type: 'airport',
    },
    {
        name: 'Bourke Airport',
        city: 'Bourke',
        country: 'Australia',
        iataCode: 'BRK',
        icaoCode: 'YBKE',
        latitude: '-30,03919983',
        longitude: '145,9519958',
        altitude: '352',
        timezone: '10',
        dst: 'O',
        type: 'airport',
    },
    {
        name: 'Burketown Airport',
        city: 'Burketown',
        country: 'Australia',
        iataCode: 'BUC',
        icaoCode: 'YBKT',
        latitude: '-17,74860001',
        longitude: '139,5339966',
        altitude: '21',
        timezone: '10',
        dst: 'O',
        type: 'airport',
    },
    {
        name: 'Boigu Airport',
        city: 'Boigu',
        country: 'Australia',
        iataCode: 'GIC',
        icaoCode: 'YBOI',
        latitude: '-9,232780457',
        longitude: '142,2180023',
        altitude: '23',
        timezone: '10',
        dst: 'O',
        type: 'airport',
    },
    {
        name: 'Oakey Airport',
        city: 'Oakey',
        country: 'Australia',
        iataCode: 'OKY',
        icaoCode: 'YBOK',
        latitude: '-27,41139984',
        longitude: '151,7350006',
        altitude: '1335',
        timezone: '10',
        dst: 'O',
        type: 'airport',
    },
    {
        name: 'Boulia Airport',
        city: 'Boulia',
        country: 'Australia',
        iataCode: 'BQL',
        icaoCode: 'YBOU',
        latitude: '-22,91329956',
        longitude: '139,8999939',
        altitude: '542',
        timezone: '10',
        dst: 'O',
        type: 'airport',
    },
    {
        name: 'Bathurst Airport',
        city: 'Bathurst',
        country: 'Australia',
        iataCode: 'BHS',
        icaoCode: 'YBTH',
        latitude: '-33,40940094',
        longitude: '149,6519928',
        altitude: '2435',
        timezone: '10',
        dst: 'O',
        type: 'airport',
    },
    {
        name: 'Blackwater Airport',
        city: 'Blackwater',
        country: 'Australia',
        iataCode: 'BLT',
        icaoCode: 'YBTR',
        latitude: '-23,60309982',
        longitude: '148,8070068',
        altitude: '657',
        timezone: '10',
        dst: 'O',
        type: 'airport',
    },
    {
        name: 'Carnarvon Airport',
        city: 'Carnarvon',
        country: 'Australia',
        iataCode: 'CVQ',
        icaoCode: 'YCAR',
        latitude: '-24,880211',
        longitude: '113,67174',
        altitude: '13',
        timezone: '8',
        dst: 'O',
        type: 'airport',
    },
    {
        name: 'Cobar Airport',
        city: 'Cobar',
        country: 'Australia',
        iataCode: 'CAZ',
        icaoCode: 'YCBA',
        latitude: '-31,53829956',
        longitude: '145,7940063',
        altitude: '724',
        timezone: '10',
        dst: 'O',
        type: 'airport',
    },
    {
        name: 'Coober Pedy Airport',
        city: 'Coober Pedy',
        country: 'Australia',
        iataCode: 'CPD',
        icaoCode: 'YCBP',
        latitude: '-29,04000092',
        longitude: '134,720993',
        altitude: '740',
        timezone: '9,5',
        dst: 'O',
        type: 'airport',
    },
    {
        name: 'Coconut Island Airport',
        city: 'Coconut Island',
        country: 'Australia',
        iataCode: 'CNC',
        icaoCode: 'YCCT',
        latitude: '-10,05000019',
        longitude: '143,0700073',
        altitude: '3',
        timezone: '10',
        dst: 'O',
        type: 'airport',
    },
    {
        name: 'Cloncurry Airport',
        city: 'Cloncurry',
        country: 'Australia',
        iataCode: 'CNJ',
        icaoCode: 'YCCY',
        latitude: '-20,66860008',
        longitude: '140,5039978',
        altitude: '616',
        timezone: '10',
        dst: 'O',
        type: 'airport',
    },
    {
        name: 'Ceduna Airport',
        city: 'Ceduna',
        country: 'Australia',
        iataCode: 'CED',
        icaoCode: 'YCDU',
        latitude: '-32,13059998',
        longitude: '133,7100067',
        altitude: '77',
        timezone: '9,5',
        dst: 'O',
        type: 'airport',
    },
    {
        name: 'Cooktown Airport',
        city: 'Cooktown',
        country: 'Australia',
        iataCode: 'CTN',
        icaoCode: 'YCKN',
        latitude: '-15,44470024',
        longitude: '145,1840057',
        altitude: '26',
        timezone: '10',
        dst: 'O',
        type: 'airport',
    },
    {
        name: 'Cunnamulla Airport',
        city: 'Cunnamulla',
        country: 'Australia',
        iataCode: 'CMA',
        icaoCode: 'YCMU',
        latitude: '-28,03000069',
        longitude: '145,621994',
        altitude: '630',
        timezone: '10',
        dst: 'O',
        type: 'airport',
    },
    {
        name: 'Coonamble Airport',
        city: 'Coonamble',
        country: 'Australia',
        iataCode: 'CNB',
        icaoCode: 'YCNM',
        latitude: '-30,98329926',
        longitude: '148,3760071',
        altitude: '604',
        timezone: '10',
        dst: 'O',
        type: 'airport',
    },
    {
        name: 'Coen Airport',
        city: 'Coen',
        country: 'Australia',
        iataCode: 'CUQ',
        icaoCode: 'YCOE',
        latitude: '-13,761133',
        longitude: '143,113311',
        altitude: '532',
        timezone: '10',
        dst: 'O',
        type: 'airport',
    },
    {
        name: 'Cooma Snowy Mountains Airport',
        city: 'Cooma',
        country: 'Australia',
        iataCode: 'OOM',
        icaoCode: 'YCOM',
        latitude: '-36,30059814',
        longitude: '148,973999',
        altitude: '3088',
        timezone: '10',
        dst: 'O',
        type: 'airport',
    },
    {
        name: 'Doomadgee Airport',
        city: 'Doomadgee',
        country: 'Australia',
        iataCode: 'DMD',
        icaoCode: 'YDMG',
        latitude: '-17,94029999',
        longitude: '138,8220062',
        altitude: '153',
        timezone: '10',
        dst: 'O',
        type: 'airport',
    },
    {
        name: 'Darnley Island Airport',
        city: 'Darnley Island',
        country: 'Australia',
        iataCode: 'NLF',
        icaoCode: 'YDNI',
        latitude: '-9,583330154',
        longitude: '143,7669983',
        altitude: '0',
        timezone: '10',
        dst: 'O',
        type: 'airport',
    },
    {
        name: 'Devonport Airport',
        city: 'Devonport',
        country: 'Australia',
        iataCode: 'DPO',
        icaoCode: 'YDPO',
        latitude: '-41,16970062',
        longitude: '146,4299927',
        altitude: '33',
        timezone: '10',
        dst: 'O',
        type: 'airport',
    },
    {
        name: 'Elcho Island Airport',
        city: 'Elcho Island',
        country: 'Australia',
        iataCode: 'ELC',
        icaoCode: 'YELD',
        latitude: '-12,01939964',
        longitude: '135,5709991',
        altitude: '101',
        timezone: '9,5',
        dst: 'O',
        type: 'airport',
    },
    {
        name: 'Esperance Airport',
        city: 'Esperance',
        country: 'Australia',
        iataCode: 'EPR',
        icaoCode: 'YESP',
        latitude: '-33,684399',
        longitude: '121,822998',
        altitude: '470',
        timezone: '8',
        dst: 'O',
        type: 'airport',
    },
    {
        name: 'Flinders Island Airport',
        city: 'Flinders Island',
        country: 'Australia',
        iataCode: 'FLS',
        icaoCode: 'YFLI',
        latitude: '-40,09170151',
        longitude: '147,9929962',
        altitude: '10',
        timezone: '10',
        dst: 'O',
        type: 'airport',
    },
    {
        name: 'Geraldton Airport',
        city: 'Geraldton',
        country: 'Australia',
        iataCode: 'GET',
        icaoCode: 'YGEL',
        latitude: '-28,796101',
        longitude: '114,707001',
        altitude: '121',
        timezone: '8',
        dst: 'O',
        type: 'airport',
    },
    {
        name: 'Gladstone Airport',
        city: 'Gladstone',
        country: 'Australia',
        iataCode: 'GLT',
        icaoCode: 'YGLA',
        latitude: '-23,869699',
        longitude: '151,223007',
        altitude: '64',
        timezone: '10',
        dst: 'O',
        type: 'airport',
    },
    {
        name: 'Groote Eylandt Airport',
        city: 'Groote Eylandt',
        country: 'Australia',
        iataCode: 'GTE',
        icaoCode: 'YGTE',
        latitude: '-13,97500038',
        longitude: '136,4600067',
        altitude: '53',
        timezone: '9,5',
        dst: 'O',
        type: 'airport',
    },
    {
        name: 'Griffith Airport',
        city: 'Griffith',
        country: 'Australia',
        iataCode: 'GFF',
        icaoCode: 'YGTH',
        latitude: '-34,25080109',
        longitude: '146,0670013',
        altitude: '439',
        timezone: '10',
        dst: 'O',
        type: 'airport',
    },
    {
        name: 'Horn Island Airport',
        city: 'Horn Island',
        country: 'Australia',
        iataCode: 'HID',
        icaoCode: 'YHID',
        latitude: '-10,58640003',
        longitude: '142,2899933',
        altitude: '43',
        timezone: '10',
        dst: 'O',
        type: 'airport',
    },
    {
        name: 'Hooker Creek Airport',
        city: 'Hooker Creek',
        country: 'Australia',
        iataCode: 'HOK',
        icaoCode: 'YHOO',
        latitude: '-18,33670044',
        longitude: '130,6380005',
        altitude: '320',
        timezone: '9,5',
        dst: 'O',
        type: 'airport',
    },
    {
        name: 'Mount Hotham Airport',
        city: 'Mount Hotham',
        country: 'Australia',
        iataCode: 'MHU',
        icaoCode: 'YHOT',
        latitude: '-37,04750061',
        longitude: '147,3339996',
        altitude: '4260',
        timezone: '10',
        dst: 'O',
        type: 'airport',
    },
    {
        name: 'Hughenden Airport',
        city: 'Hughenden',
        country: 'Australia',
        iataCode: 'HGD',
        icaoCode: 'YHUG',
        latitude: '-20,81500053',
        longitude: '144,2250061',
        altitude: '1043',
        timezone: '10',
        dst: 'O',
        type: 'airport',
    },
    {
        name: 'Julia Creek Airport',
        city: 'Julia Creek',
        country: 'Australia',
        iataCode: 'JCK',
        icaoCode: 'YJLC',
        latitude: '-20,66830063',
        longitude: '141,7230072',
        altitude: '404',
        timezone: '10',
        dst: 'O',
        type: 'airport',
    },
    {
        name: 'Kalbarri Airport',
        city: 'Kalbarri',
        country: 'Australia',
        iataCode: 'KAX',
        icaoCode: 'YKBR',
        latitude: '-27,692813',
        longitude: '114,259169',
        altitude: '157',
        timezone: '8',
        dst: 'O',
        type: 'airport',
    },
    {
        name: 'King Island Airport',
        city: 'King Island',
        country: 'Australia',
        iataCode: 'KNS',
        icaoCode: 'YKII',
        latitude: '-39,87749863',
        longitude: '143,878006',
        altitude: '132',
        timezone: '10',
        dst: 'O',
        type: 'airport',
    },
    {
        name: 'Kalkgurung Airport',
        city: 'Kalkgurung',
        country: 'Australia',
        iataCode: 'KFG',
        icaoCode: 'YKKG',
        latitude: '-17,43190002',
        longitude: '130,8079987',
        altitude: '646',
        timezone: '9,5',
        dst: 'O',
        type: 'airport',
    },
    {
        name: 'Karumba Airport',
        city: 'Karumba',
        country: 'Australia',
        iataCode: 'KRB',
        icaoCode: 'YKMB',
        latitude: '-17,45669937',
        longitude: '140,8300018',
        altitude: '5',
        timezone: '10',
        dst: 'O',
        type: 'airport',
    },
    {
        name: 'Kowanyama Airport',
        city: 'Kowanyama',
        country: 'Australia',
        iataCode: 'KWM',
        icaoCode: 'YKOW',
        latitude: '-15,48560047',
        longitude: '141,7510071',
        altitude: '35',
        timezone: '10',
        dst: 'O',
        type: 'airport',
    },
    {
        name: 'Kubin Airport',
        city: 'Kubin',
        country: 'Australia',
        iataCode: 'KUG',
        icaoCode: 'YKUB',
        latitude: '-10,22500038',
        longitude: '142,2180023',
        altitude: '15',
        timezone: '10',
        dst: 'O',
        type: 'airport',
    },
    {
        name: 'Leonora Airport',
        city: 'Leonora',
        country: 'Australia',
        iataCode: 'LNO',
        icaoCode: 'YLEO',
        latitude: '-28,87809944',
        longitude: '121,3150024',
        altitude: '1217',
        timezone: '8',
        dst: 'O',
        type: 'airport',
    },
    {
        name: 'Lake Evella Airport',
        city: 'Lake Evella',
        country: 'Australia',
        iataCode: 'LEL',
        icaoCode: 'YLEV',
        latitude: '-12,49890041',
        longitude: '135,8059998',
        altitude: '256',
        timezone: '9,5',
        dst: 'O',
        type: 'airport',
    },
    {
        name: 'Lord Howe Island Airport',
        city: 'Lord Howe Island',
        country: 'Australia',
        iataCode: 'LDH',
        icaoCode: 'YLHI',
        latitude: '-31,53829956',
        longitude: '159,0769959',
        altitude: '5',
        timezone: '10,5',
        dst: 'O',
        type: 'airport',
    },
    {
        name: 'Lockhart River Airport',
        city: 'Lockhart River',
        country: 'Australia',
        iataCode: 'IRG',
        icaoCode: 'YLHR',
        latitude: '-12,7869',
        longitude: '143,304993',
        altitude: '77',
        timezone: '10',
        dst: 'O',
        type: 'airport',
    },
    {
        name: 'Lismore Airport',
        city: 'Lismore',
        country: 'Australia',
        iataCode: 'LSY',
        icaoCode: 'YLIS',
        latitude: '-28,83029938',
        longitude: '153,2599945',
        altitude: '35',
        timezone: '10',
        dst: 'O',
        type: 'airport',
    },
    {
        name: 'Lightning Ridge Airport',
        city: 'Lightning Ridge',
        country: 'Australia',
        iataCode: 'LHG',
        icaoCode: 'YLRD',
        latitude: '-29,45669937',
        longitude: '147,9839935',
        altitude: '540',
        timezone: '10',
        dst: 'O',
        type: 'airport',
    },
    {
        name: 'Longreach Airport',
        city: 'Longreach',
        country: 'Australia',
        iataCode: 'LRE',
        icaoCode: 'YLRE',
        latitude: '-23,43420029',
        longitude: '144,2799988',
        altitude: '627',
        timezone: '10',
        dst: 'O',
        type: 'airport',
    },
    {
        name: 'Leinster Airport',
        city: 'Leinster',
        country: 'Australia',
        iataCode: 'LER',
        icaoCode: 'YLST',
        latitude: '-27,84329987',
        longitude: '120,7030029',
        altitude: '1631',
        timezone: '8',
        dst: 'O',
        type: 'airport',
    },
    {
        name: 'Laverton Airport',
        city: 'Laverton',
        country: 'Australia',
        iataCode: 'LVO',
        icaoCode: 'YLTN',
        latitude: '-28,61359978',
        longitude: '122,4240036',
        altitude: '1530',
        timezone: '8',
        dst: 'O',
        type: 'airport',
    },
    {
        name: 'Mabuiag Island Airport',
        city: 'Mabuiag Island',
        country: 'Australia',
        iataCode: 'UBB',
        icaoCode: 'YMAA',
        latitude: '-9,949999809',
        longitude: '142,1829987',
        altitude: '0',
        timezone: '10',
        dst: 'O',
        type: 'airport',
    },
    {
        name: 'Meekatharra Airport',
        city: 'Meekatharra',
        country: 'Australia',
        iataCode: 'MKR',
        icaoCode: 'YMEK',
        latitude: '-26,61170006',
        longitude: '118,5479965',
        altitude: '1713',
        timezone: '8',
        dst: 'O',
        type: 'airport',
    },
    {
        name: 'Merimbula Airport',
        city: 'Merimbula',
        country: 'Australia',
        iataCode: 'MIM',
        icaoCode: 'YMER',
        latitude: '-36,90859985',
        longitude: '149,901001',
        altitude: '7',
        timezone: '10',
        dst: 'O',
        type: 'airport',
    },
    {
        name: 'Milingimbi Airport',
        city: 'Milingimbi',
        country: 'Australia',
        iataCode: 'MGT',
        icaoCode: 'YMGB',
        latitude: '-12,09440041',
        longitude: '134,8939972',
        altitude: '53',
        timezone: '9,5',
        dst: 'O',
        type: 'airport',
    },
    {
        name: 'Maningrida Airport',
        city: 'Maningrida',
        country: 'Australia',
        iataCode: 'MNG',
        icaoCode: 'YMGD',
        latitude: '-12,05609989',
        longitude: '134,2339935',
        altitude: '123',
        timezone: '9,5',
        dst: 'O',
        type: 'airport',
    },
    {
        name: 'McArthur River Mine Airport',
        city: 'McArthur River Mine',
        country: 'Australia',
        iataCode: 'MCV',
        icaoCode: 'YMHU',
        latitude: '-16,44249916',
        longitude: '136,0839996',
        altitude: '131',
        timezone: '9,5',
        dst: 'O',
        type: 'airport',
    },
    {
        name: 'Mildura Airport',
        city: 'Mildura',
        country: 'Australia',
        iataCode: 'MQL',
        icaoCode: 'YMIA',
        latitude: '-34,22919846',
        longitude: '142,0859985',
        altitude: '167',
        timezone: '10',
        dst: 'O',
        type: 'airport',
    },
    {
        name: 'Mount Magnet Airport',
        city: 'Mount Magnet',
        country: 'Australia',
        iataCode: 'MMG',
        icaoCode: 'YMOG',
        latitude: '-28,11610031',
        longitude: '117,8420029',
        altitude: '1354',
        timezone: '8',
        dst: 'O',
        type: 'airport',
    },
    {
        name: 'Moree Airport',
        city: 'Moree',
        country: 'Australia',
        iataCode: 'MRZ',
        icaoCode: 'YMOR',
        latitude: '-29,49889946',
        longitude: '149,8450012',
        altitude: '701',
        timezone: '10',
        dst: 'O',
        type: 'airport',
    },
    {
        name: 'Moranbah Airport',
        city: 'Moranbah',
        country: 'Australia',
        iataCode: 'MOV',
        icaoCode: 'YMRB',
        latitude: '-22,05780029',
        longitude: '148,0769959',
        altitude: '770',
        timezone: '10',
        dst: 'O',
        type: 'airport',
    },
    {
        name: 'Moruya Airport',
        city: 'Moruya',
        country: 'Australia',
        iataCode: 'MYA',
        icaoCode: 'YMRY',
        latitude: '-35,89780045',
        longitude: '150,1439972',
        altitude: '14',
        timezone: '10',
        dst: 'O',
        type: 'airport',
    },
    {
        name: 'Mount Gambier Airport',
        city: 'Mount Gambier',
        country: 'Australia',
        iataCode: 'MGB',
        icaoCode: 'YMTG',
        latitude: '-37,74560165',
        longitude: '140,7850037',
        altitude: '212',
        timezone: '9,5',
        dst: 'O',
        type: 'airport',
    },
    {
        name: 'Mornington Island Airport',
        city: 'Mornington Island',
        country: 'Australia',
        iataCode: 'ONG',
        icaoCode: 'YMTI',
        latitude: '-16,66250038',
        longitude: '139,1779938',
        altitude: '33',
        timezone: '10',
        dst: 'O',
        type: 'airport',
    },
    {
        name: 'Murray Island Airport',
        city: 'Murray Island',
        country: 'Australia',
        iataCode: 'MYI',
        icaoCode: 'YMUI',
        latitude: '-9,916669846',
        longitude: '144,0549927',
        altitude: '300',
        timezone: '10',
        dst: 'O',
        type: 'airport',
    },
    {
        name: 'Maryborough Airport',
        city: 'Maryborough',
        country: 'Australia',
        iataCode: 'MBH',
        icaoCode: 'YMYB',
        latitude: '-25,51329994',
        longitude: '152,7149963',
        altitude: '38',
        timezone: '10',
        dst: 'O',
        type: 'airport',
    },
    {
        name: 'Narrandera Airport',
        city: 'Narrandera',
        country: 'Australia',
        iataCode: 'NRA',
        icaoCode: 'YNAR',
        latitude: '-34,70220184',
        longitude: '146,5119934',
        altitude: '474',
        timezone: '10',
        dst: 'O',
        type: 'airport',
    },
    {
        name: 'Narrabri Airport',
        city: 'Narrabri',
        country: 'Australia',
        iataCode: 'NAA',
        icaoCode: 'YNBR',
        latitude: '-30,31920052',
        longitude: '149,8269959',
        altitude: '788',
        timezone: '10',
        dst: 'O',
        type: 'airport',
    },
    {
        name: 'Normanton Airport',
        city: 'Normanton',
        country: 'Australia',
        iataCode: 'NTN',
        icaoCode: 'YNTN',
        latitude: '-17,68409',
        longitude: '141,069664',
        altitude: '73',
        timezone: '10',
        dst: 'O',
        type: 'airport',
    },
    {
        name: 'Newman Airport',
        city: 'Newman',
        country: 'Australia',
        iataCode: 'ZNE',
        icaoCode: 'YNWN',
        latitude: '-23,4178009',
        longitude: '119,8030014',
        altitude: '1724',
        timezone: '8',
        dst: 'O',
        type: 'airport',
    },
    {
        name: 'Olympic Dam Airport',
        city: 'Olympic Dam',
        country: 'Australia',
        iataCode: 'OLP',
        icaoCode: 'YOLD',
        latitude: '-30,48500061',
        longitude: '136,8769989',
        altitude: '343',
        timezone: '9,5',
        dst: 'O',
        type: 'airport',
    },
    {
        name: 'Port Augusta Airport',
        city: 'Argyle',
        country: 'Australia',
        iataCode: 'PUG',
        icaoCode: 'YPAG',
        latitude: '-32,50690079',
        longitude: '137,7169952',
        altitude: '56',
        timezone: '9,5',
        dst: 'O',
        type: 'airport',
    },
    {
        name: 'Palm Island Airport',
        city: 'Palm Island',
        country: 'Australia',
        iataCode: 'PMK',
        icaoCode: 'YPAM',
        latitude: '-18,75530052',
        longitude: '146,5809937',
        altitude: '28',
        timezone: '10',
        dst: 'O',
        type: 'airport',
    },
    {
        name: 'Paraburdoo Airport',
        city: 'Paraburdoo',
        country: 'Australia',
        iataCode: 'PBO',
        icaoCode: 'YPBO',
        latitude: '-23,17110062',
        longitude: '117,7450027',
        altitude: '1406',
        timezone: '8',
        dst: 'O',
        type: 'airport',
    },
    {
        name: 'Cocos (Keeling) Islands Airport',
        city: 'Cocos Keeling Island',
        country: 'Cocos (Keeling) Islands',
        iataCode: 'CCK',
        icaoCode: 'YPCC',
        latitude: '-12,18830013',
        longitude: '96,83390045',
        altitude: '10',
        timezone: '6,5',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Gove Airport',
        city: 'Gove',
        country: 'Australia',
        iataCode: 'GOV',
        icaoCode: 'YPGV',
        latitude: '-12,26939964',
        longitude: '136,8179932',
        altitude: '192',
        timezone: '9,5',
        dst: 'O',
        type: 'airport',
    },
    {
        name: 'Parkes Airport',
        city: 'Parkes',
        country: 'Australia',
        iataCode: 'PKE',
        icaoCode: 'YPKS',
        latitude: '-33,13140106',
        longitude: '148,2389984',
        altitude: '1069',
        timezone: '10',
        dst: 'O',
        type: 'airport',
    },
    {
        name: 'Port Lincoln Airport',
        city: 'Port Lincoln',
        country: 'Australia',
        iataCode: 'PLO',
        icaoCode: 'YPLC',
        latitude: '-34,6053009',
        longitude: '135,8800049',
        altitude: '36',
        timezone: '9,5',
        dst: 'O',
        type: 'airport',
    },
    {
        name: 'Pormpuraaw Airport',
        city: 'Pormpuraaw',
        country: 'Australia',
        iataCode: 'EDR',
        icaoCode: 'YPMP',
        latitude: '-14,896451',
        longitude: '141,60908',
        altitude: '10',
        timezone: '10',
        dst: 'O',
        type: 'airport',
    },
    {
        name: 'Port Macquarie Airport',
        city: 'Port Macquarie',
        country: 'Australia',
        iataCode: 'PQQ',
        icaoCode: 'YPMQ',
        latitude: '-31,43580055',
        longitude: '152,8630066',
        altitude: '12',
        timezone: '10',
        dst: 'O',
        type: 'airport',
    },
    {
        name: 'Portland Airport',
        city: 'Portland',
        country: 'Australia',
        iataCode: 'PTJ',
        icaoCode: 'YPOD',
        latitude: '-38,31809998',
        longitude: '141,470993',
        altitude: '265',
        timezone: '10',
        dst: 'O',
        type: 'airport',
    },
    {
        name: 'Quilpie Airport',
        city: 'Quilpie',
        country: 'Australia',
        iataCode: 'ULP',
        icaoCode: 'YQLP',
        latitude: '-26,61219978',
        longitude: '144,253006',
        altitude: '655',
        timezone: '10',
        dst: 'O',
        type: 'airport',
    },
    {
        name: 'Ramingining Airport',
        city: 'Ramingining',
        country: 'Australia',
        iataCode: 'RAM',
        icaoCode: 'YRNG',
        latitude: '-12,35639954',
        longitude: '134,897995',
        altitude: '206',
        timezone: '9,5',
        dst: 'O',
        type: 'airport',
    },
    {
        name: 'Roma Airport',
        city: 'Roma',
        country: 'Australia',
        iataCode: 'RMA',
        icaoCode: 'YROM',
        latitude: '-26,54500008',
        longitude: '148,7749939',
        altitude: '1032',
        timezone: '10',
        dst: 'O',
        type: 'airport',
    },
    {
        name: 'St George Airport',
        city: 'St George',
        country: 'Australia',
        iataCode: 'SGO',
        icaoCode: 'YSGE',
        latitude: '-28,04969978',
        longitude: '148,5950012',
        altitude: '656',
        timezone: '10',
        dst: 'O',
        type: 'airport',
    },
    {
        name: 'Shark Bay Airport',
        city: 'Shark Bay',
        country: 'Australia',
        iataCode: 'MJK',
        icaoCode: 'YSHK',
        latitude: '-25,89389992',
        longitude: '113,5770035',
        altitude: '111',
        timezone: '8',
        dst: 'O',
        type: 'airport',
    },
    {
        name: 'Saibai Island Airport',
        city: 'Saibai Island',
        country: 'Australia',
        iataCode: 'SBR',
        icaoCode: 'YSII',
        latitude: '-9,378330231',
        longitude: '142,625',
        altitude: '15',
        timezone: '10',
        dst: 'O',
        type: 'airport',
    },
    {
        name: 'Strahan Airport',
        city: 'Strahan',
        country: 'Australia',
        iataCode: 'SRN',
        icaoCode: 'YSRN',
        latitude: '-42,15499878',
        longitude: '145,2920074',
        altitude: '20',
        timezone: '10',
        dst: 'O',
        type: 'airport',
    },
    {
        name: 'Thargomindah Airport',
        city: 'Thargomindah',
        country: 'Australia',
        iataCode: 'XTG',
        icaoCode: 'YTGM',
        latitude: '-27,9864006',
        longitude: '143,8110046',
        altitude: '433',
        timezone: '10',
        dst: 'O',
        type: 'airport',
    },
    {
        name: 'Tennant Creek Airport',
        city: 'Tennant Creek',
        country: 'Australia',
        iataCode: 'TCA',
        icaoCode: 'YTNK',
        latitude: '-19,63439941',
        longitude: '134,1829987',
        altitude: '1236',
        timezone: '9,5',
        dst: 'O',
        type: 'airport',
    },
    {
        name: 'Victoria River Downs Airport',
        city: 'Victoria River Downs',
        country: 'Australia',
        iataCode: 'VCD',
        icaoCode: 'YVRD',
        latitude: '-16,4021244',
        longitude: '131,0049744',
        altitude: '89',
        timezone: '9,5',
        dst: 'O',
        type: 'airport',
    },
    {
        name: 'Warraber Island Airport',
        city: 'Sue Islet',
        country: 'Australia',
        iataCode: 'SYU',
        icaoCode: 'YWBS',
        latitude: '-10,20829964',
        longitude: '142,8249969',
        altitude: '3',
        timezone: '10',
        dst: 'O',
        type: 'airport',
    },
    {
        name: 'Windorah Airport',
        city: 'Windorah',
        country: 'Australia',
        iataCode: 'WNR',
        icaoCode: 'YWDH',
        latitude: '-25,41309929',
        longitude: '142,6670074',
        altitude: '452',
        timezone: '10',
        dst: 'O',
        type: 'airport',
    },
    {
        name: 'Whyalla Airport',
        city: 'Whyalla',
        country: 'Australia',
        iataCode: 'WYA',
        icaoCode: 'YWHA',
        latitude: '-33,05889893',
        longitude: '137,5140076',
        altitude: '41',
        timezone: '9,5',
        dst: 'O',
        type: 'airport',
    },
    {
        name: 'Wiluna Airport',
        city: 'Wiluna',
        country: 'Australia',
        iataCode: 'WUN',
        icaoCode: 'YWLU',
        latitude: '-26,62919998',
        longitude: '120,2210007',
        altitude: '1649',
        timezone: '8',
        dst: 'O',
        type: 'airport',
    },
    {
        name: 'Wollongong Airport',
        city: 'Wollongong',
        country: 'Australia',
        iataCode: 'WOL',
        icaoCode: 'YWOL',
        latitude: '-34,56110001',
        longitude: '150,7890015',
        altitude: '31',
        timezone: '10',
        dst: 'O',
        type: 'airport',
    },
    {
        name: 'Winton Airport',
        city: 'Winton',
        country: 'Australia',
        iataCode: 'WIN',
        icaoCode: 'YWTN',
        latitude: '-22,36359978',
        longitude: '143,0859985',
        altitude: '638',
        timezone: '10',
        dst: 'O',
        type: 'airport',
    },
    {
        name: 'Wynyard Airport',
        city: 'Burnie',
        country: 'Australia',
        iataCode: 'BWT',
        icaoCode: 'YWYY',
        latitude: '-40,99890137',
        longitude: '145,7310028',
        altitude: '62',
        timezone: '10',
        dst: 'O',
        type: 'airport',
    },
    {
        name: 'Yorke Island Airport',
        city: 'Yorke Island',
        country: 'Australia',
        iataCode: 'OKR',
        icaoCode: 'YYKI',
        latitude: '-9,752801',
        longitude: '143,405673',
        altitude: '10',
        timezone: '10',
        dst: 'O',
        type: 'airport',
    },
    {
        name: 'Yam Island Airport',
        city: 'Yam Island',
        country: 'Australia',
        iataCode: 'XMY',
        icaoCode: 'YYMI',
        latitude: '-9,901109695',
        longitude: '142,776001',
        altitude: '0',
        timezone: '10',
        dst: 'O',
        type: 'airport',
    },
    {
        name: 'Beijing Nanyuan Airport',
        city: 'Beijing',
        country: 'China',
        iataCode: 'NAY',
        icaoCode: 'ZBNY',
        latitude: '39,78279877',
        longitude: '116,3880005',
        altitude: '0',
        timezone: '8',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Chifeng Airport',
        city: 'Chifeng',
        country: 'China',
        iataCode: 'CIF',
        icaoCode: 'ZBCF',
        latitude: '42,23500061',
        longitude: '118,9079971',
        altitude: '0',
        timezone: '8',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Changzhi Airport',
        city: 'Changzhi',
        country: 'China',
        iataCode: 'CIH',
        icaoCode: 'ZBCZ',
        latitude: '36,247501',
        longitude: '113,125999',
        altitude: '0',
        timezone: '8',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Datong Airport',
        city: 'Datong',
        country: 'China',
        iataCode: 'DAT',
        icaoCode: 'ZBDT',
        latitude: '40,060299',
        longitude: '113,482002',
        altitude: '3442',
        timezone: '8',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Baita International Airport',
        city: 'Hohhot',
        country: 'China',
        iataCode: 'HET',
        icaoCode: 'ZBHH',
        latitude: '40,85139847',
        longitude: '111,8239975',
        altitude: '3556',
        timezone: '8',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Baotou Airport',
        city: 'Baotou',
        country: 'China',
        iataCode: 'BAV',
        icaoCode: 'ZBOW',
        latitude: '40,56000137',
        longitude: '109,9970016',
        altitude: '3321',
        timezone: '8',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Shijiazhuang Daguocun International Airport',
        city: 'Shijiazhuang',
        country: 'China',
        iataCode: 'SJW',
        icaoCode: 'ZBSJ',
        latitude: '38,28070068',
        longitude: '114,6969986',
        altitude: '233',
        timezone: '8',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Tongliao Airport',
        city: 'Tongliao',
        country: 'China',
        iataCode: 'TGO',
        icaoCode: 'ZBTL',
        latitude: '43,556702',
        longitude: '122,199997',
        altitude: '2395',
        timezone: '8',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Ulanhot Airport',
        city: 'Ulanhot',
        country: 'China',
        iataCode: 'HLH',
        icaoCode: 'ZBUL',
        latitude: '46,195333',
        longitude: '122,008333',
        altitude: '0',
        timezone: '8',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Xilinhot Airport',
        city: 'Xilinhot',
        country: 'China',
        iataCode: 'XIL',
        icaoCode: 'ZBXH',
        latitude: '43,91559982',
        longitude: '115,9639969',
        altitude: '0',
        timezone: '8',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Beihai Airport',
        city: 'Beihai',
        country: 'China',
        iataCode: 'BHY',
        icaoCode: 'ZGBH',
        latitude: '21,5394',
        longitude: '109,293999',
        altitude: '0',
        timezone: '8',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Changde Airport',
        city: 'Changde',
        country: 'China',
        iataCode: 'CGD',
        icaoCode: 'ZGCD',
        latitude: '28,91889954',
        longitude: '111,6399994',
        altitude: '128',
        timezone: '8',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Dayong Airport',
        city: 'Dayong',
        country: 'China',
        iataCode: 'DYG',
        icaoCode: 'ZGDY',
        latitude: '29,1028',
        longitude: '110,443001',
        altitude: '692',
        timezone: '8',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Meixian Airport',
        city: 'Meixian',
        country: 'China',
        iataCode: 'MXZ',
        icaoCode: 'ZGMX',
        latitude: '24,35000038',
        longitude: '116,1330032',
        altitude: '0',
        timezone: '8',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Zhuhai Jinwan Airport',
        city: 'Zhuhai',
        country: 'China',
        iataCode: 'ZUH',
        icaoCode: 'ZGSD',
        latitude: '22,006399',
        longitude: '113,375999',
        altitude: '23',
        timezone: '8',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Liuzhou Bailian Airport',
        city: 'Liuzhou',
        country: 'China',
        iataCode: 'LZH',
        icaoCode: 'ZGZH',
        latitude: '24,2075',
        longitude: '109,390999',
        altitude: '295',
        timezone: '8',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Zhanjiang Airport',
        city: 'Zhanjiang',
        country: 'China',
        iataCode: 'ZHA',
        icaoCode: 'ZGZJ',
        latitude: '21,214399',
        longitude: '110,358002',
        altitude: '125',
        timezone: '8',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Enshi Airport',
        city: 'Enshi',
        country: 'China',
        iataCode: 'ENH',
        icaoCode: 'ZHES',
        latitude: '30,32029915',
        longitude: '109,4850006',
        altitude: '1605',
        timezone: '8',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Nanyang Jiangying Airport',
        city: 'Nanyang',
        country: 'China',
        iataCode: 'NNY',
        icaoCode: 'ZHNY',
        latitude: '32,980801',
        longitude: '112,614998',
        altitude: '840',
        timezone: '8',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Xiangyang Liuji Airport',
        city: 'Xiangfan',
        country: 'China',
        iataCode: 'XFN',
        icaoCode: 'ZHXF',
        latitude: '32,1506',
        longitude: '112,291',
        altitude: '0',
        timezone: '8',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Yichang Sanxia Airport',
        city: 'Yichang',
        country: 'China',
        iataCode: 'YIH',
        icaoCode: 'ZHYC',
        latitude: '30,55655',
        longitude: '111,479988',
        altitude: '673',
        timezone: '8',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Ankang Wulipu Airport',
        city: 'Ankang',
        country: 'China',
        iataCode: 'AKA',
        icaoCode: 'ZLAK',
        latitude: '32,708099',
        longitude: '108,931',
        altitude: '860',
        timezone: '8',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Golmud Airport',
        city: 'Golmud',
        country: 'China',
        iataCode: 'GOQ',
        icaoCode: 'ZLGM',
        latitude: '36,4006',
        longitude: '94,786102',
        altitude: '9334',
        timezone: '8',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Hanzhong Chenggu Airport',
        city: 'Hanzhong',
        country: 'China',
        iataCode: 'HZG',
        icaoCode: 'ZLHZ',
        latitude: '33,063599',
        longitude: '107,008003',
        altitude: '0',
        timezone: '8',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Qingyang Airport',
        city: 'Qingyang',
        country: 'China',
        iataCode: 'IQN',
        icaoCode: 'ZLQY',
        latitude: '35,799702',
        longitude: '107,602997',
        altitude: '0',
        timezone: '8',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Xining Caojiabu Airport',
        city: 'Xining',
        country: 'China',
        iataCode: 'XNN',
        icaoCode: 'ZLXN',
        latitude: '36,5275',
        longitude: '102,042999',
        altitude: '7119',
        timezone: '8',
        dst: 'U',
        type: 'airport',
    },
    {
        name: "Yan'an Ershilipu Airport",
        city: "Yan'an",
        country: 'China',
        iataCode: 'ENY',
        icaoCode: 'ZLYA',
        latitude: '36,636902',
        longitude: '109,554001',
        altitude: '3100',
        timezone: '8',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Yulin Yuyang Airport',
        city: 'Yulin',
        country: 'China',
        iataCode: 'UYN',
        icaoCode: 'ZLYL',
        latitude: '38,35971',
        longitude: '109,590927',
        altitude: '0',
        timezone: '8',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Arvaikheer Airport',
        city: 'Arvaikheer',
        country: 'Mongolia',
        iataCode: 'AVK',
        icaoCode: 'ZMAH',
        latitude: '46,25030136',
        longitude: '102,802002',
        altitude: '5932',
        timezone: '8',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Altai Airport',
        city: 'Altai',
        country: 'Mongolia',
        iataCode: 'LTI',
        icaoCode: 'ZMAT',
        latitude: '46,37639999',
        longitude: '96,22109985',
        altitude: '7260',
        timezone: '8',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Bayankhongor Airport',
        city: 'Bayankhongor',
        country: 'Mongolia',
        iataCode: 'BYN',
        icaoCode: 'ZMBH',
        latitude: '46,16329956',
        longitude: '100,7040024',
        altitude: '6085',
        timezone: '8',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Dalanzadgad Airport',
        city: 'Dalanzadgad',
        country: 'Mongolia',
        iataCode: 'DLZ',
        icaoCode: 'ZMDZ',
        latitude: '43,59170151',
        longitude: '104,4300003',
        altitude: '4787',
        timezone: '8',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Khovd Airport',
        city: 'Khovd',
        country: 'Mongolia',
        iataCode: 'HVD',
        icaoCode: 'ZMKD',
        latitude: '47,95410156',
        longitude: '91,62819672',
        altitude: '4898',
        timezone: '7',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Mörön Airport',
        city: 'Muren',
        country: 'Mongolia',
        iataCode: 'MXV',
        icaoCode: 'ZMMN',
        latitude: '49,66329956',
        longitude: '100,098999',
        altitude: '4272',
        timezone: '8',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Diqing Airport',
        city: 'Shangri-La',
        country: 'China',
        iataCode: 'DIG',
        icaoCode: 'ZPDQ',
        latitude: '27,7936',
        longitude: '99,6772',
        altitude: '10761',
        timezone: '8',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Mangshi Airport',
        city: 'Luxi',
        country: 'China',
        iataCode: 'LUM',
        icaoCode: 'ZPLX',
        latitude: '24,4011',
        longitude: '98,5317',
        altitude: '2890',
        timezone: '8',
        dst: 'U',
        type: 'airport',
    },
    {
        name: "Pu'er Simao Airport",
        city: 'Simao',
        country: 'China',
        iataCode: 'SYM',
        icaoCode: 'ZPSM',
        latitude: '22,793301',
        longitude: '100,959',
        altitude: '0',
        timezone: '8',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Zhaotong Airport',
        city: 'Zhaotong',
        country: 'China',
        iataCode: 'ZAT',
        icaoCode: 'ZPZT',
        latitude: '27,32559967',
        longitude: '103,7549973',
        altitude: '0',
        timezone: '8',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Ganzhou Airport',
        city: 'Ganzhou',
        country: 'China',
        iataCode: 'KOW',
        icaoCode: 'ZSGZ',
        latitude: '25,853333',
        longitude: '114,778889',
        altitude: '387',
        timezone: '8',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Jingdezhen Airport',
        city: 'Jingdezhen',
        country: 'China',
        iataCode: 'JDZ',
        icaoCode: 'ZSJD',
        latitude: '29,33860016',
        longitude: '117,1760025',
        altitude: '112',
        timezone: '8',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Jiujiang Lushan Airport',
        city: 'Jiujiang',
        country: 'China',
        iataCode: 'JIU',
        icaoCode: 'ZSJJ',
        latitude: '29,476944',
        longitude: '115,801111',
        altitude: '0',
        timezone: '8',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Quzhou Airport',
        city: 'Quzhou',
        country: 'China',
        iataCode: 'JUZ',
        icaoCode: 'ZSJU',
        latitude: '28,965799',
        longitude: '118,899002',
        altitude: '0',
        timezone: '8',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Lianyungang Airport',
        city: 'Lianyungang',
        country: 'China',
        iataCode: 'LYG',
        icaoCode: 'ZSLG',
        latitude: '34,571667',
        longitude: '118,873611',
        altitude: '0',
        timezone: '8',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Huangyan Luqiao Airport',
        city: 'Huangyan',
        country: 'China',
        iataCode: 'HYN',
        icaoCode: 'ZSLQ',
        latitude: '28,56220055',
        longitude: '121,4290009',
        altitude: '0',
        timezone: '8',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Shubuling Airport',
        city: 'Linyi',
        country: 'China',
        iataCode: 'LYI',
        icaoCode: 'ZSLY',
        latitude: '35,04610062',
        longitude: '118,4120026',
        altitude: '0',
        timezone: '8',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Quanzhou Jinjiang International Airport',
        city: 'Quanzhou',
        country: 'China',
        iataCode: 'JJN',
        icaoCode: 'ZSQZ',
        latitude: '24,7964',
        longitude: '118,589996',
        altitude: '0',
        timezone: '8',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Tunxi International Airport',
        city: 'Huangshan',
        country: 'China',
        iataCode: 'TXN',
        icaoCode: 'ZSTX',
        latitude: '29,73329926',
        longitude: '118,2559967',
        altitude: '0',
        timezone: '8',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Weifang Airport',
        city: 'Weifang',
        country: 'China',
        iataCode: 'WEF',
        icaoCode: 'ZSWF',
        latitude: '36,646702',
        longitude: '119,119003',
        altitude: '0',
        timezone: '8',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Weihai Airport',
        city: 'Weihai',
        country: 'China',
        iataCode: 'WEH',
        icaoCode: 'ZSWH',
        latitude: '37,18709946',
        longitude: '122,2289963',
        altitude: '145',
        timezone: '8',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Sunan Shuofang International Airport',
        city: 'Wuxi',
        country: 'China',
        iataCode: 'WUX',
        icaoCode: 'ZSWX',
        latitude: '31,49440002',
        longitude: '120,4290009',
        altitude: '24',
        timezone: '8',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Nanping Wuyishan Airport',
        city: 'Wuyishan',
        country: 'China',
        iataCode: 'WUS',
        icaoCode: 'ZSWY',
        latitude: '27,7019',
        longitude: '118,000999',
        altitude: '614',
        timezone: '8',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Wenzhou Yongqiang Airport',
        city: 'Wenzhou',
        country: 'China',
        iataCode: 'WNZ',
        icaoCode: 'ZSWZ',
        latitude: '27,91220093',
        longitude: '120,8519974',
        altitude: '0',
        timezone: '8',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Yancheng Airport',
        city: 'Yancheng',
        country: 'China',
        iataCode: 'YNZ',
        icaoCode: 'ZSYN',
        latitude: '33,425833',
        longitude: '120,203056',
        altitude: '0',
        timezone: '8',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Yiwu Airport',
        city: 'Yiwu',
        country: 'China',
        iataCode: 'YIW',
        icaoCode: 'ZSYW',
        latitude: '29,34469986',
        longitude: '120,0319977',
        altitude: '262',
        timezone: '8',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Zhoushan Airport',
        city: 'Zhoushan',
        country: 'China',
        iataCode: 'HSN',
        icaoCode: 'ZSZS',
        latitude: '29,93420029',
        longitude: '122,3619995',
        altitude: '3',
        timezone: '8',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Qamdo Bangda Airport',
        city: 'Bangda',
        country: 'China',
        iataCode: 'BPX',
        icaoCode: 'ZUBD',
        latitude: '30,55360031',
        longitude: '97,10829926',
        altitude: '14219',
        timezone: '8',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Dachuan Airport',
        city: 'Dazhou',
        country: 'China',
        iataCode: 'DAX',
        icaoCode: 'ZUDX',
        latitude: '31,1302',
        longitude: '107,4295',
        altitude: '0',
        timezone: '8',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Guangyuan Airport',
        city: 'Guangyuan',
        country: 'China',
        iataCode: 'GYS',
        icaoCode: 'ZUGU',
        latitude: '32,39110184',
        longitude: '105,7020035',
        altitude: '0',
        timezone: '8',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Luzhou Airport',
        city: 'Luzhou',
        country: 'China',
        iataCode: 'LZO',
        icaoCode: 'ZULZ',
        latitude: '28,85219955',
        longitude: '105,3929977',
        altitude: '0',
        timezone: '8',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Mianyang Airport',
        city: 'Mianyang',
        country: 'China',
        iataCode: 'MIG',
        icaoCode: 'ZUMY',
        latitude: '31,42810059',
        longitude: '104,7409973',
        altitude: '0',
        timezone: '8',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Nanchong Airport',
        city: 'Nanchong',
        country: 'China',
        iataCode: 'NAO',
        icaoCode: 'ZUNC',
        latitude: '30,79545',
        longitude: '106,1626',
        altitude: '0',
        timezone: '8',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Nyingchi Airport',
        city: 'Nyingchi',
        country: 'China',
        iataCode: 'LZY',
        icaoCode: 'ZUNZ',
        latitude: '29,30330086',
        longitude: '94,33529663',
        altitude: '9675',
        timezone: '8',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Wanxian Airport',
        city: 'Wanxian',
        country: 'China',
        iataCode: 'WXN',
        icaoCode: 'ZUWX',
        latitude: '30,8017',
        longitude: '108,433',
        altitude: '0',
        timezone: '8',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Aksu Airport',
        city: 'Aksu',
        country: 'China',
        iataCode: 'AKU',
        icaoCode: 'ZWAK',
        latitude: '41,262501',
        longitude: '80,291702',
        altitude: '3816',
        timezone: '8',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Qiemo Airport',
        city: 'Qiemo',
        country: 'China',
        iataCode: 'IQM',
        icaoCode: 'ZWCM',
        latitude: '38,1493988',
        longitude: '85,53279877',
        altitude: '4108',
        timezone: '8',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Kuqa Airport',
        city: 'Kuqa',
        country: 'China',
        iataCode: 'KCA',
        icaoCode: 'ZWKC',
        latitude: '41,7181015',
        longitude: '82,98690033',
        altitude: '3524',
        timezone: '8',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Korla Airport',
        city: 'Korla',
        country: 'China',
        iataCode: 'KRL',
        icaoCode: 'ZWKL',
        latitude: '41,69779968',
        longitude: '86,12889862',
        altitude: '0',
        timezone: '8',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Karamay Airport',
        city: 'Karamay',
        country: 'China',
        iataCode: 'KRY',
        icaoCode: 'ZWKM',
        latitude: '45,46655',
        longitude: '84,9527',
        altitude: '0',
        timezone: '8',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Yining Airport',
        city: 'Yining',
        country: 'China',
        iataCode: 'YIN',
        icaoCode: 'ZWYN',
        latitude: '43,955799',
        longitude: '81,330299',
        altitude: '0',
        timezone: '8',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Heihe Airport',
        city: 'Heihe',
        country: 'China',
        iataCode: 'HEK',
        icaoCode: 'ZYHE',
        latitude: '50,17162094',
        longitude: '127,3088837',
        altitude: '8530',
        timezone: '8',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Jiamusi Airport',
        city: 'Jiamusi',
        country: 'China',
        iataCode: 'JMU',
        icaoCode: 'ZYJM',
        latitude: '46,84339905',
        longitude: '130,4649963',
        altitude: '262',
        timezone: '8',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Jinzhou Airport',
        city: 'Jinzhou',
        country: 'China',
        iataCode: 'JNZ',
        icaoCode: 'ZYJZ',
        latitude: '41,10139847',
        longitude: '121,0619965',
        altitude: '0',
        timezone: '8',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Qiqihar Sanjiazi Airport',
        city: 'Qiqihar',
        country: 'China',
        iataCode: 'NDG',
        icaoCode: 'ZYQQ',
        latitude: '47,23960114',
        longitude: '123,9179993',
        altitude: '477',
        timezone: '8',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Yanji Chaoyangchuan Airport',
        city: 'Yanji',
        country: 'China',
        iataCode: 'YNJ',
        icaoCode: 'ZYYJ',
        latitude: '42,88280106',
        longitude: '129,451004',
        altitude: '624',
        timezone: '8',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Waikoloa Heliport',
        city: 'Waikoloa Village',
        country: 'United States',
        iataCode: 'WKL',
        icaoCode: 'HI07',
        latitude: '19,9205',
        longitude: '-155,8607',
        altitude: '119',
        timezone: '-10',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Mount Keith Airport',
        city: 'Mount Keith',
        country: 'Australia',
        iataCode: 'WME',
        icaoCode: 'YMNE',
        latitude: '-27,28639984',
        longitude: '120,5550003',
        altitude: '1792',
        timezone: '8',
        dst: 'O',
        type: 'airport',
    },
    {
        name: 'Los Roques Airport',
        city: 'Los Roques',
        country: 'Venezuela',
        iataCode: 'LRV',
        icaoCode: 'SVRS',
        latitude: '11,94999981',
        longitude: '-66,66999817',
        altitude: '17',
        timezone: '-4',
        dst: 'S',
        type: 'airport',
    },
    {
        name: 'Inishmore Aerodrome',
        city: 'Inis Mor',
        country: 'Ireland',
        iataCode: 'IOR',
        icaoCode: 'EIIM',
        latitude: '53,1067009',
        longitude: '-9,653610229',
        altitude: '24',
        timezone: '0',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Connemara Regional Airport',
        city: 'Indreabhan',
        country: 'Ireland',
        iataCode: 'NNR',
        icaoCode: 'EICA',
        latitude: '53,2303009',
        longitude: '-9,467780113',
        altitude: '70',
        timezone: '0',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Rügen Airport',
        city: 'Ruegen',
        country: 'Germany',
        iataCode: 'GTI',
        icaoCode: 'EDCG',
        latitude: '54,3833313',
        longitude: '13,3255558',
        altitude: '69',
        timezone: '1',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Berezovo Airport',
        city: 'Berezovo',
        country: 'Russia',
        iataCode: 'NBB',
        icaoCode: 'USHB',
        latitude: '63,92100143',
        longitude: '65,03050232',
        altitude: '98',
        timezone: '5',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Szczecin-Dąbie Airport',
        city: 'Szczecin',
        country: 'Poland',
        iataCode: 'N',
        icaoCode: 'EPSD',
        latitude: '53,39210129',
        longitude: '14,63379955',
        altitude: '3',
        timezone: '1',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Worcester Regional Airport',
        city: 'Worcester',
        country: 'United States',
        iataCode: 'ORH',
        icaoCode: 'KORH',
        latitude: '42,26729965',
        longitude: '-71,8757019',
        altitude: '1009',
        timezone: '-5',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Anqing Tianzhushan Airport',
        city: 'Anqing',
        country: 'China',
        iataCode: 'AQG',
        icaoCode: 'ZSAQ',
        latitude: '30,582199',
        longitude: '117,050003',
        altitude: '0',
        timezone: '8',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Shanhaiguan Airport',
        city: 'Qinhuangdao',
        country: 'China',
        iataCode: 'SHP',
        icaoCode: 'ZBSH',
        latitude: '39,968102',
        longitude: '119,731003',
        altitude: '30',
        timezone: '8',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Yuncheng Guangong Airport',
        city: 'Yuncheng',
        country: 'China',
        iataCode: 'YCU',
        icaoCode: 'ZBYC',
        latitude: '35,116391',
        longitude: '111,0313889',
        altitude: '1242',
        timezone: '8',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Lanzhou City Airport',
        city: 'Lanzhou',
        country: 'China',
        iataCode: 'N',
        icaoCode: 'ZLAN',
        latitude: '36,033333',
        longitude: '103,86667',
        altitude: '5040',
        timezone: '8',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Jiayuguan Airport',
        city: 'Jiayuguan',
        country: 'China',
        iataCode: 'JGN',
        icaoCode: 'ZLJQ',
        latitude: '39,856899',
        longitude: '98,3414',
        altitude: '5112',
        timezone: '8',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Ordos Ejin Horo Airport',
        city: 'Dongsheng',
        country: 'China',
        iataCode: 'DSN',
        icaoCode: 'ZBDS',
        latitude: '39,49',
        longitude: '109,8613889',
        altitude: '4557',
        timezone: '8',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Bremerton National Airport',
        city: 'Bremerton',
        country: 'United States',
        iataCode: 'PWT',
        icaoCode: 'KPWT',
        latitude: '47,49020004',
        longitude: '-122,7649994',
        altitude: '444',
        timezone: '-8',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Spencer Municipal Airport',
        city: 'Spencer',
        country: 'United States',
        iataCode: 'SPW',
        icaoCode: 'KSPW',
        latitude: '43,16550064',
        longitude: '-95,20279694',
        altitude: '1339',
        timezone: '-6',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Jefferson City Memorial Airport',
        city: 'Jefferson City',
        country: 'United States',
        iataCode: 'JEF',
        icaoCode: 'KJEF',
        latitude: '38,59120178',
        longitude: '-92,15609741',
        altitude: '549',
        timezone: '-6',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Tannheim Airport',
        city: 'Tannheim',
        country: 'Germany',
        iataCode: 'N',
        icaoCode: 'EDMT',
        latitude: '48,00999832',
        longitude: '10,09861088',
        altitude: '1903',
        timezone: '1',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Unst Airport',
        city: 'Unst',
        country: 'United Kingdom',
        iataCode: 'UNT',
        icaoCode: 'EGPW',
        latitude: '60,74720001',
        longitude: '-0,853850007',
        altitude: '0',
        timezone: '0',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Berkley Structures Heliport',
        city: 'Pagerungan',
        country: 'Indonesia',
        iataCode: 'N',
        icaoCode: 'WA19',
        latitude: '47,682043',
        longitude: '-122,148456',
        altitude: '50',
        timezone: '-8',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Provincetown Municipal Airport',
        city: 'Provincetown',
        country: 'United States',
        iataCode: 'PVC',
        icaoCode: 'KPVC',
        latitude: '42,07189941',
        longitude: '-70,2213974',
        altitude: '9',
        timezone: '-5',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Anduki Airport',
        city: 'Seria',
        country: 'Brunei',
        iataCode: 'N',
        icaoCode: 'WBAK',
        latitude: '4,637000084',
        longitude: '114,3820038',
        altitude: '7',
        timezone: '8',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Gustaf III Airport',
        city: 'Gustavia',
        country: 'France',
        iataCode: 'SBH',
        icaoCode: 'TFFJ',
        latitude: '17,90439987',
        longitude: '-62,84360123',
        altitude: '49',
        timezone: '-4',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Kostroma Sokerkino Airport',
        city: 'Kostroma',
        country: 'Russia',
        iataCode: 'KMW',
        icaoCode: 'UUBA',
        latitude: '57,7969017',
        longitude: '41,01940155',
        altitude: '446',
        timezone: '3',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Sukhumi Dranda Airport',
        city: 'Sukhumi',
        country: 'Georgia',
        iataCode: 'SUI',
        icaoCode: 'UGSS',
        latitude: '42,85820007',
        longitude: '41,12810135',
        altitude: '53',
        timezone: '4',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Donskoye Airport',
        city: 'Tambow',
        country: 'Russia',
        iataCode: 'TBW',
        icaoCode: 'UUOT',
        latitude: '52,80609894',
        longitude: '41,48279953',
        altitude: '413',
        timezone: '3',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Oban Airport',
        city: 'North Connel',
        country: 'United Kingdom',
        iataCode: 'OBN',
        icaoCode: 'EGEO',
        latitude: '56,46350098',
        longitude: '-5,399670124',
        altitude: '20',
        timezone: '0',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Erechim Airport',
        city: 'Erechim',
        country: 'Brazil',
        iataCode: 'ERM',
        icaoCode: 'SSER',
        latitude: '-27,66189957',
        longitude: '-52,2682991',
        altitude: '2498',
        timezone: '-3',
        dst: 'S',
        type: 'airport',
    },
    {
        name: 'La Côte Airport',
        city: 'Prangins',
        country: 'Switzerland',
        iataCode: 'N',
        icaoCode: 'LSGP',
        latitude: '46,40639877',
        longitude: '6,258059978',
        altitude: '1352',
        timezone: '1',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Courchevel Airport',
        city: 'Courcheval',
        country: 'France',
        iataCode: 'CVF',
        icaoCode: 'LFLJ',
        latitude: '45,39670181',
        longitude: '6,634719849',
        altitude: '6588',
        timezone: '1',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Fullerton Municipal Airport',
        city: 'Fullerton',
        country: 'United States',
        iataCode: 'FUL',
        icaoCode: 'KFUL',
        latitude: '33,87200165',
        longitude: '-117,9800034',
        altitude: '96',
        timezone: '-8',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Concord Regional Airport',
        city: 'Concord',
        country: 'United States',
        iataCode: 'N',
        icaoCode: 'KJQF',
        latitude: '35,387798',
        longitude: '-80,709099',
        altitude: '705',
        timezone: '-5',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Isle of Wight / Sandown Airport',
        city: 'Isle Of Wight',
        country: 'United Kingdom',
        iataCode: 'N',
        icaoCode: 'EGHN',
        latitude: '50,653099',
        longitude: '-1,18221998',
        altitude: '55',
        timezone: '0',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Navoi Airport',
        city: 'Navoi',
        country: 'Uzbekistan',
        iataCode: 'NVI',
        icaoCode: 'UTSA',
        latitude: '40,11719894',
        longitude: '65,17079926',
        altitude: '0',
        timezone: '5',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Andernos Les Bains Airport',
        city: 'Andernos-Les-Bains',
        country: 'France',
        iataCode: 'N',
        icaoCode: 'LFCD',
        latitude: '44,7560997',
        longitude: '-1,063330054',
        altitude: '66',
        timezone: '1',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Bienenfarm Airport',
        city: 'Nauen',
        country: 'Germany',
        iataCode: 'N',
        icaoCode: 'EDOI',
        latitude: '52,66166687',
        longitude: '12,7458334',
        altitude: '131',
        timezone: '1',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Ain Arnat Airport',
        city: 'Setif',
        country: 'Algeria',
        iataCode: 'QSF',
        icaoCode: 'DAAS',
        latitude: '36,17810059',
        longitude: '5,32449007',
        altitude: '3360',
        timezone: '1',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'La Rochelle-Île de Ré Airport',
        city: 'La Rochelle',
        country: 'France',
        iataCode: 'LRH',
        icaoCode: 'LFBH',
        latitude: '46,17919922',
        longitude: '-1,195279956',
        altitude: '74',
        timezone: '1',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Friedman Memorial Airport',
        city: 'Hailey',
        country: 'United States',
        iataCode: 'SUN',
        icaoCode: 'KSUN',
        latitude: '43,50439835',
        longitude: '-114,2959976',
        altitude: '5318',
        timezone: '-7',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Yverdon-les-Bains Airport',
        city: 'Yverdon-Les-Bains',
        country: 'Switzerland',
        iataCode: 'N',
        icaoCode: 'LSGY',
        latitude: '46,76190186',
        longitude: '6,613329887',
        altitude: '1421',
        timezone: '1',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Bielsko Biala Airport',
        city: 'Bielsko-Biala',
        country: 'Poland',
        iataCode: 'N',
        icaoCode: 'EPBA',
        latitude: '49,80500031',
        longitude: '19,00189972',
        altitude: '1319',
        timezone: '1',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Mason City Municipal Airport',
        city: 'Mason City',
        country: 'United States',
        iataCode: 'MCW',
        icaoCode: 'KMCW',
        latitude: '43,15779877',
        longitude: '-93,33129883',
        altitude: '1213',
        timezone: '-6',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Phoenix-Mesa-Gateway Airport',
        city: 'Mesa',
        country: 'United States',
        iataCode: 'AZA',
        icaoCode: 'KIWA',
        latitude: '33,30780029',
        longitude: '-111,6549988',
        altitude: '1382',
        timezone: '-7',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Saúl Airport',
        city: 'Saul',
        country: 'French Guiana',
        iataCode: 'XAU',
        icaoCode: 'SOOS',
        latitude: '3,61361',
        longitude: '-53,204201',
        altitude: '656',
        timezone: '-3',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Tekapo Aerodrome',
        city: 'Lake Tekapo',
        country: 'New Zealand',
        iataCode: 'N',
        icaoCode: 'NZTL',
        latitude: '-44,00529861',
        longitude: '170,4440002',
        altitude: '2496',
        timezone: '12',
        dst: 'Z',
        type: 'airport',
    },
    {
        name: 'Niederoeblarn Airport',
        city: 'Niederoeblarn',
        country: 'Austria',
        iataCode: 'N',
        icaoCode: 'LOGO',
        latitude: '47,478298',
        longitude: '14,0083',
        altitude: '2142',
        timezone: '1',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Vöslau Airport',
        city: 'Bad Voeslau',
        country: 'Austria',
        iataCode: 'N',
        icaoCode: 'LOAV',
        latitude: '47,96500015',
        longitude: '16,26000023',
        altitude: '765',
        timezone: '1',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Uetersen/Heist Airport',
        city: 'Uetersen',
        country: 'Germany',
        iataCode: 'N',
        icaoCode: 'EDHE',
        latitude: '53,64638901',
        longitude: '9,704167366',
        altitude: '23',
        timezone: '1',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Anaktuvuk Pass Airport',
        city: 'Anaktuvuk Pass',
        country: 'United States',
        iataCode: 'AKP',
        icaoCode: 'PAKP',
        latitude: '68,13359833',
        longitude: '-151,7429962',
        altitude: '2102',
        timezone: '-9',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Anvik Airport',
        city: 'Anvik',
        country: 'United States',
        iataCode: 'ANV',
        icaoCode: 'PANV',
        latitude: '62,646702',
        longitude: '-160,190994',
        altitude: '291',
        timezone: '-9',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Atqasuk Edward Burnell Sr Memorial Airport',
        city: 'Atqasuk',
        country: 'United States',
        iataCode: 'ATK',
        icaoCode: 'PATQ',
        latitude: '70,46730042',
        longitude: '-157,4360046',
        altitude: '96',
        timezone: '-9',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Gambell Airport',
        city: 'Gambell',
        country: 'United States',
        iataCode: 'GAM',
        icaoCode: 'PAGM',
        latitude: '63,76679993',
        longitude: '-171,7330017',
        altitude: '27',
        timezone: '-9',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Hooper Bay Airport',
        city: 'Hooper Bay',
        country: 'United States',
        iataCode: 'HPB',
        icaoCode: 'PAHP',
        latitude: '61,52389908',
        longitude: '-166,1470032',
        altitude: '13',
        timezone: '-9',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Kaltag Airport',
        city: 'Kaltag',
        country: 'United States',
        iataCode: 'KAL',
        icaoCode: 'PAKV',
        latitude: '64,31909943',
        longitude: '-158,7409973',
        altitude: '181',
        timezone: '-9',
        dst: 'A',
        type: 'airport',
    },
    {
        name: "St Mary's Airport",
        city: "St Mary's",
        country: 'United States',
        iataCode: 'KSM',
        icaoCode: 'PASM',
        latitude: '62,0605011',
        longitude: '-163,302002',
        altitude: '312',
        timezone: '-9',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Kivalina Airport',
        city: 'Kivalina',
        country: 'United States',
        iataCode: 'KVL',
        icaoCode: 'PAVL',
        latitude: '67,73619843',
        longitude: '-164,5630035',
        altitude: '13',
        timezone: '-9',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Mekoryuk Airport',
        city: 'Mekoryuk',
        country: 'United States',
        iataCode: 'MYU',
        icaoCode: 'PAMY',
        latitude: '60,37139893',
        longitude: '-166,2709961',
        altitude: '48',
        timezone: '-9',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Ruby Airport',
        city: 'Ruby',
        country: 'United States',
        iataCode: 'RBY',
        icaoCode: 'PARY',
        latitude: '64,72720337',
        longitude: '-155,4700012',
        altitude: '658',
        timezone: '-9',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Shishmaref Airport',
        city: 'Shishmaref',
        country: 'United States',
        iataCode: 'SHH',
        icaoCode: 'PASH',
        latitude: '66,249604',
        longitude: '-166,089112',
        altitude: '12',
        timezone: '-9',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Savoonga Airport',
        city: 'Savoonga',
        country: 'United States',
        iataCode: 'SVA',
        icaoCode: 'PASA',
        latitude: '63,68640137',
        longitude: '-170,4929962',
        altitude: '53',
        timezone: '-9',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Noatak Airport',
        city: 'Noatak',
        country: 'United States',
        iataCode: 'WTK',
        icaoCode: 'PAWN',
        latitude: '67,56610107',
        longitude: '-162,9750061',
        altitude: '88',
        timezone: '-9',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Ormoc Airport',
        city: 'Ormoc City',
        country: 'Philippines',
        iataCode: 'OMC',
        icaoCode: 'RPVO',
        latitude: '11,05799961',
        longitude: '124,5650024',
        altitude: '83',
        timezone: '8',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Puvirnituq Airport',
        city: 'Puvirnituq',
        country: 'Canada',
        iataCode: 'YPX',
        icaoCode: 'CYPX',
        latitude: '60,05059814',
        longitude: '-77,28690338',
        altitude: '74',
        timezone: '-5',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Tasiujaq Airport',
        city: 'Tasiujaq',
        country: 'Canada',
        iataCode: 'YTQ',
        icaoCode: 'CYTQ',
        latitude: '58,6678009',
        longitude: '-69,95580292',
        altitude: '122',
        timezone: '-5',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Arctic Village Airport',
        city: 'Arctic Village',
        country: 'United States',
        iataCode: 'ARC',
        icaoCode: 'PARC',
        latitude: '68,1147',
        longitude: '-145,578995',
        altitude: '2092',
        timezone: '-9',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Sam Mbakwe International Airport',
        city: 'Imo',
        country: 'Nigeria',
        iataCode: 'QOW',
        icaoCode: 'DNIM',
        latitude: '5,427060127',
        longitude: '7,206029892',
        altitude: '373',
        timezone: '1',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Arenal Airport',
        city: 'La Fortuna/San Carlos',
        country: 'Costa Rica',
        iataCode: 'FON',
        icaoCode: 'MRAN',
        latitude: '10,47799969',
        longitude: '-84,6344986',
        altitude: '342',
        timezone: '-6',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Tambor Airport',
        city: 'Nicoya',
        country: 'Costa Rica',
        iataCode: 'TMU',
        icaoCode: 'MRTR',
        latitude: '9,738519669',
        longitude: '-85,01380157',
        altitude: '33',
        timezone: '-6',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Cauayan Airport',
        city: 'Cauayan',
        country: 'Philippines',
        iataCode: 'CYZ',
        icaoCode: 'RPUY',
        latitude: '16,92989922',
        longitude: '121,7529984',
        altitude: '200',
        timezone: '8',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Kirovsk-Apatity Airport',
        city: 'Apatity',
        country: 'Russia',
        iataCode: 'KVK',
        icaoCode: 'ULMK',
        latitude: '67,46330261',
        longitude: '33,5882988',
        altitude: '515',
        timezone: '3',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Coronel Altino Machado de Oliveira Airport',
        city: 'Governador Valadares',
        country: 'Brazil',
        iataCode: 'GVR',
        icaoCode: 'SBGV',
        latitude: '-18,89520073',
        longitude: '-41,98220062',
        altitude: '561',
        timezone: '-3',
        dst: 'S',
        type: 'airport',
    },
    {
        name: 'Pajala Airport',
        city: 'Pajala',
        country: 'Sweden',
        iataCode: 'PJA',
        icaoCode: 'ESUP',
        latitude: '67,24559784',
        longitude: '23,06889915',
        altitude: '542',
        timezone: '1',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Bella Coola Airport',
        city: 'Bella Coola',
        country: 'Canada',
        iataCode: 'QBC',
        icaoCode: 'CYBD',
        latitude: '52,387501',
        longitude: '-126,596001',
        altitude: '117',
        timezone: '-8',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Hagerstown Regional Richard A Henson Field',
        city: 'Hagerstown',
        country: 'United States',
        iataCode: 'HGR',
        icaoCode: 'KHGR',
        latitude: '39,707901',
        longitude: '-77,72949982',
        altitude: '703',
        timezone: '-5',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Araracuara Airport',
        city: 'Araracuara',
        country: 'Colombia',
        iataCode: 'ACR',
        icaoCode: 'SKAC',
        latitude: '-0,5833',
        longitude: '-72,4083',
        altitude: '1250',
        timezone: '-5',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Gorakhpur Airport',
        city: 'Gorakhpur',
        country: 'India',
        iataCode: 'GOP',
        icaoCode: 'VEGK',
        latitude: '26,73970032',
        longitude: '83,4496994',
        altitude: '259',
        timezone: '5,5',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Sand Point Airport',
        city: 'Sand Point',
        country: 'United States',
        iataCode: 'SDP',
        icaoCode: 'PASD',
        latitude: '55,31499863',
        longitude: '-160,522995',
        altitude: '21',
        timezone: '-9',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Hami Airport',
        city: 'Hami',
        country: 'China',
        iataCode: 'HMI',
        icaoCode: 'ZWHM',
        latitude: '42,84140015',
        longitude: '93,66919708',
        altitude: '2703',
        timezone: '8',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Wuzhou Changzhoudao Airport',
        city: 'Wuzhou',
        country: 'China',
        iataCode: 'WUZ',
        icaoCode: 'ZGWZ',
        latitude: '23,456699',
        longitude: '111,248001',
        altitude: '89',
        timezone: '8',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Tugdan Airport',
        city: 'Romblon',
        country: 'Philippines',
        iataCode: 'TBH',
        icaoCode: 'RPVU',
        latitude: '12,31099987',
        longitude: '122,0849991',
        altitude: '10',
        timezone: '8',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Sahand Airport',
        city: 'Maragheh',
        country: 'Iran',
        iataCode: 'ACP',
        icaoCode: 'OITM',
        latitude: '37,34799957',
        longitude: '46,12789917',
        altitude: '4396',
        timezone: '3,5',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Gorgan Airport',
        city: 'Gorgan',
        country: 'Iran',
        iataCode: 'GBT',
        icaoCode: 'OING',
        latitude: '36,90940094',
        longitude: '54,40129852',
        altitude: '-24',
        timezone: '3,5',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Ilam Airport',
        city: 'Ilam',
        country: 'Iran',
        iataCode: 'IIL',
        icaoCode: 'OICI',
        latitude: '33,58660126',
        longitude: '46,40480042',
        altitude: '4404',
        timezone: '3,5',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Parsabade Moghan Airport',
        city: 'Parsabad',
        country: 'Iran',
        iataCode: 'PFQ',
        icaoCode: 'OITP',
        latitude: '39,60359955',
        longitude: '47,88150024',
        altitude: '251',
        timezone: '3,5',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Tocache Airport',
        city: 'Tocache',
        country: 'Peru',
        iataCode: 'N',
        icaoCode: 'SPCH',
        latitude: '-8,182999611',
        longitude: '-76,51699829',
        altitude: '1500',
        timezone: '-5',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Tacheng Airport',
        city: 'Tacheng',
        country: 'China',
        iataCode: 'TCG',
        icaoCode: 'ZWTC',
        latitude: '46,67250061',
        longitude: '83,34079742',
        altitude: '0',
        timezone: '8',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Mardin Airport',
        city: 'Mardin',
        country: 'Turkey',
        iataCode: 'MQM',
        icaoCode: 'LTCR',
        latitude: '37,22330093',
        longitude: '40,63169861',
        altitude: '1729',
        timezone: '3',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Sugraly Airport',
        city: 'Zarafshan',
        country: 'Uzbekistan',
        iataCode: 'AFS',
        icaoCode: 'UTSN',
        latitude: '41,61389923',
        longitude: '64,23320007',
        altitude: '1396',
        timezone: '5',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Deering Airport',
        city: 'Deering',
        country: 'United States',
        iataCode: 'DRG',
        icaoCode: 'PADE',
        latitude: '66,06960297',
        longitude: '-162,7660065',
        altitude: '21',
        timezone: '-9',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Leon Airport',
        city: 'Leon',
        country: 'Spain',
        iataCode: 'LEN',
        icaoCode: 'LELN',
        latitude: '42,5890007',
        longitude: '-5,655560017',
        altitude: '3006',
        timezone: '1',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Burgos Airport',
        city: 'Burgos',
        country: 'Spain',
        iataCode: 'RGS',
        icaoCode: 'LEBG',
        latitude: '42,35760117',
        longitude: '-3,620759964',
        altitude: '2945',
        timezone: '1',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Sege Airport',
        city: 'Sege',
        country: 'Solomon Islands',
        iataCode: 'EGM',
        icaoCode: 'AGGS',
        latitude: '-8,578889847',
        longitude: '157,8760071',
        altitude: '0',
        timezone: '11',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Shahrekord Airport',
        city: 'Shahre Kord',
        country: 'Iran',
        iataCode: 'CQD',
        icaoCode: 'OIFS',
        latitude: '32,29719925',
        longitude: '50,84220123',
        altitude: '6723',
        timezone: '3,5',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Kangra Airport',
        city: 'Kangra',
        country: 'India',
        iataCode: 'DHM',
        icaoCode: 'VIGG',
        latitude: '32,1651001',
        longitude: '76,26339722',
        altitude: '2525',
        timezone: '5,5',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Nanded Airport',
        city: 'Nanded',
        country: 'India',
        iataCode: 'NDC',
        icaoCode: 'VAND',
        latitude: '19,18330002',
        longitude: '77,3167038',
        altitude: '1250',
        timezone: '5,5',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Shimla Airport',
        city: 'Shimla',
        country: 'India',
        iataCode: 'SLV',
        icaoCode: 'VISM',
        latitude: '31,0818',
        longitude: '77,068001',
        altitude: '5072',
        timezone: '5,5',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Igiugig Airport',
        city: 'Igiugig',
        country: 'United States',
        iataCode: 'IGG',
        icaoCode: 'PAIG',
        latitude: '59,32400131',
        longitude: '-155,9019928',
        altitude: '90',
        timezone: '-9',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'New Stuyahok Airport',
        city: 'New Stuyahok',
        country: 'United States',
        iataCode: 'KNW',
        icaoCode: 'PANW',
        latitude: '59,44990158',
        longitude: '-157,3280029',
        altitude: '364',
        timezone: '-9',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'King Cove Airport',
        city: 'King Cove',
        country: 'United States',
        iataCode: 'KVC',
        icaoCode: 'PAVC',
        latitude: '55,11629868',
        longitude: '-162,2660065',
        altitude: '155',
        timezone: '-9',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Port Heiden Airport',
        city: 'Port Heiden',
        country: 'United States',
        iataCode: 'PTH',
        icaoCode: 'PAPH',
        latitude: '56,95909882',
        longitude: '-158,6329956',
        altitude: '95',
        timezone: '-9',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Togiak Airport',
        city: 'Togiak Village',
        country: 'United States',
        iataCode: 'TOG',
        icaoCode: 'PATG',
        latitude: '59,05279922',
        longitude: '-160,3970032',
        altitude: '21',
        timezone: '-9',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Geneina Airport',
        city: 'Geneina',
        country: 'Sudan',
        iataCode: 'EGN',
        icaoCode: 'HSGN',
        latitude: '13,48169994',
        longitude: '22,46719933',
        altitude: '2650',
        timezone: '3',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Long Akah Airport',
        city: 'Long Akah',
        country: 'Malaysia',
        iataCode: 'LKH',
        icaoCode: 'WBGL',
        latitude: '3,299999952',
        longitude: '114,7829971',
        altitude: '289',
        timezone: '8',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Walaha Airport',
        city: 'Walaha',
        country: 'Vanuatu',
        iataCode: 'WLH',
        icaoCode: 'NVSW',
        latitude: '-15,4119997',
        longitude: '167,6909943',
        altitude: '151',
        timezone: '11',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Chaoyang Airport',
        city: 'Chaoyang',
        country: 'China',
        iataCode: 'CHG',
        icaoCode: 'ZYCY',
        latitude: '41,538101',
        longitude: '120,434998',
        altitude: '568',
        timezone: '8',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Samburu South Airport',
        city: 'Samburu South',
        country: 'Kenya',
        iataCode: 'UAS',
        icaoCode: 'HKSB',
        latitude: '0,530583024',
        longitude: '37,53419495',
        altitude: '3295',
        timezone: '3',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Brus Laguna Airport',
        city: 'Brus Laguna',
        country: 'Honduras',
        iataCode: 'BHG',
        icaoCode: 'MHBL',
        latitude: '15,7631',
        longitude: '-84,543602',
        altitude: '19',
        timezone: '-6',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Bonaventure Airport',
        city: 'Bonaventure',
        country: 'Canada',
        iataCode: 'YVB',
        icaoCode: 'CYVB',
        latitude: '48,07109833',
        longitude: '-65,46029663',
        altitude: '123',
        timezone: '-5',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Sialkot Airport',
        city: 'Sialkot',
        country: 'Pakistan',
        iataCode: 'SKT',
        icaoCode: 'OPST',
        latitude: '32,53555679',
        longitude: '74,3638916',
        altitude: '837',
        timezone: '5',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Capitan Corbeta CA Curbelo International Airport',
        city: 'Punta del Este',
        country: 'Uruguay',
        iataCode: 'PDP',
        icaoCode: 'SULS',
        latitude: '-34,85509872',
        longitude: '-55,09429932',
        altitude: '95',
        timezone: '-3',
        dst: 'S',
        type: 'airport',
    },
    {
        name: 'Walvis Bay Airport',
        city: 'Walvis Bay',
        country: 'Namibia',
        iataCode: 'WVB',
        icaoCode: 'FYWB',
        latitude: '-22,97990036',
        longitude: '14,64529991',
        altitude: '299',
        timezone: '1',
        dst: 'S',
        type: 'airport',
    },
    {
        name: 'Katima Mulilo Airport',
        city: 'Mpacha',
        country: 'Namibia',
        iataCode: 'MPA',
        icaoCode: 'FYKM',
        latitude: '-17,63439941',
        longitude: '24,17670059',
        altitude: '3144',
        timezone: '1',
        dst: 'S',
        type: 'airport',
    },
    {
        name: 'Anadolu Airport',
        city: 'Eskissehir',
        country: 'Turkey',
        iataCode: 'AOE',
        icaoCode: 'LTBY',
        latitude: '39,809898',
        longitude: '30,5194',
        altitude: '2588',
        timezone: '3',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Çanakkale Airport',
        city: 'Canakkale',
        country: 'Turkey',
        iataCode: 'CKZ',
        icaoCode: 'LTBH',
        latitude: '40,13769913',
        longitude: '26,42679977',
        altitude: '23',
        timezone: '3',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Muş Airport',
        city: 'Mus',
        country: 'Turkey',
        iataCode: 'MSR',
        icaoCode: 'LTCK',
        latitude: '38,74779892',
        longitude: '41,66120148',
        altitude: '4157',
        timezone: '3',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Sinop Airport',
        city: 'Sinop',
        country: 'Turkey',
        iataCode: 'SIC',
        icaoCode: 'LTCM',
        latitude: '42,01580048',
        longitude: '35,06639862',
        altitude: '20',
        timezone: '3',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Tekirdağ Çorlu Airport',
        city: 'Çorlu',
        country: 'Turkey',
        iataCode: 'TEQ',
        icaoCode: 'LTBU',
        latitude: '41,13819885',
        longitude: '27,91909981',
        altitude: '574',
        timezone: '3',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Bursa Yenişehir Airport',
        city: 'Yenisehir',
        country: 'Turkey',
        iataCode: 'YEI',
        icaoCode: 'LTBR',
        latitude: '40,25519943',
        longitude: '29,56259918',
        altitude: '764',
        timezone: '3',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Terre-de-Haut Airport',
        city: 'Les Saintes',
        country: 'Guadeloupe',
        iataCode: 'LSS',
        icaoCode: 'TFFS',
        latitude: '15,86439991',
        longitude: '-61,58060074',
        altitude: '46',
        timezone: '-4',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Kalay Airport',
        city: 'Kalemyo',
        country: 'Myanmar',
        iataCode: 'KMV',
        icaoCode: 'VYKL',
        latitude: '23,18880081',
        longitude: '94,05110168',
        altitude: '499',
        timezone: '6,5',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Vieques Airport',
        city: 'Vieques Island',
        country: 'Puerto Rico',
        iataCode: 'VQS',
        icaoCode: 'TJCG',
        latitude: '18,11580086',
        longitude: '-65,42269897',
        altitude: '19',
        timezone: '-4',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'St Augustin Airport',
        city: 'St-Augustin',
        country: 'Canada',
        iataCode: 'YIF',
        icaoCode: 'CYIF',
        latitude: '51,21170044',
        longitude: '-58,65829849',
        altitude: '20',
        timezone: '-4',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Hamadan Airport',
        city: 'Hamadan',
        country: 'Iran',
        iataCode: 'HDM',
        icaoCode: 'OIHH',
        latitude: '34,86920166',
        longitude: '48,55250168',
        altitude: '5755',
        timezone: '3,5',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Marinduque Airport',
        city: 'Gasan',
        country: 'Philippines',
        iataCode: 'MRQ',
        icaoCode: 'RPUW',
        latitude: '13,36100006',
        longitude: '121,8259964',
        altitude: '32',
        timezone: '8',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Grafton Airport',
        city: 'Grafton',
        country: 'Australia',
        iataCode: 'GFN',
        icaoCode: 'YGFN',
        latitude: '-29,75939941',
        longitude: '153,0299988',
        altitude: '110',
        timezone: '10',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Orange Airport',
        city: 'Orange',
        country: 'Australia',
        iataCode: 'OAG',
        icaoCode: 'YORG',
        latitude: '-33,38169861',
        longitude: '149,1329956',
        altitude: '3115',
        timezone: '10',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Taree Airport',
        city: 'Taree',
        country: 'Australia',
        iataCode: 'TRO',
        icaoCode: 'YTRE',
        latitude: '-31,8885994',
        longitude: '152,5140076',
        altitude: '38',
        timezone: '10',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Choibalsan Airport',
        city: 'Choibalsan',
        country: 'Mongolia',
        iataCode: 'COQ',
        icaoCode: 'ZMCD',
        latitude: '48,13570023',
        longitude: '114,6460037',
        altitude: '2457',
        timezone: '8',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Hohenems-Dornbirn Airport',
        city: 'Hohenems',
        country: 'Austria',
        iataCode: 'HOJ',
        icaoCode: 'LOIH',
        latitude: '47,38499832',
        longitude: '9,699999809',
        altitude: '1352',
        timezone: '1',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Weser-Wümme Airport',
        city: 'Hellwege',
        country: 'Germany',
        iataCode: 'N',
        icaoCode: 'EDWM',
        latitude: '53,05389023',
        longitude: '9,208610535',
        altitude: '59',
        timezone: '1',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Krems Airport',
        city: 'Krems',
        country: 'Austria',
        iataCode: 'N',
        icaoCode: 'LOAG',
        latitude: '48,446389',
        longitude: '15,634167',
        altitude: '1017',
        timezone: '1',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Delta County Airport',
        city: 'Escanaba',
        country: 'United States',
        iataCode: 'ESC',
        icaoCode: 'KESC',
        latitude: '45,72269821',
        longitude: '-87,09369659',
        altitude: '609',
        timezone: '-5',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Yakutat Airport',
        city: 'Yakutat',
        country: 'United States',
        iataCode: 'YAK',
        icaoCode: 'PAYA',
        latitude: '59,50329971',
        longitude: '-139,6600037',
        altitude: '33',
        timezone: '-9',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Goulburn Airport',
        city: 'Goulburn',
        country: 'Australia',
        iataCode: 'GUL',
        icaoCode: 'YGLB',
        latitude: '-34,81029892',
        longitude: '149,7259979',
        altitude: '2141',
        timezone: '10',
        dst: 'O',
        type: 'airport',
    },
    {
        name: 'Cessnock Airport',
        city: 'Cessnock',
        country: 'Australia',
        iataCode: 'CES',
        icaoCode: 'YCNK',
        latitude: '-32,787498',
        longitude: '151,341995',
        altitude: '211',
        timezone: '10',
        dst: 'O',
        type: 'airport',
    },
    {
        name: 'Scone Airport',
        city: 'Scone',
        country: 'Australia',
        iataCode: 'NSO',
        icaoCode: 'YSCO',
        latitude: '-32,03720093',
        longitude: '150,8320007',
        altitude: '745',
        timezone: '10',
        dst: 'O',
        type: 'airport',
    },
    {
        name: 'Mudgee Airport',
        city: 'Mudgee',
        country: 'Australia',
        iataCode: 'DGE',
        icaoCode: 'YMDG',
        latitude: '-32,5625',
        longitude: '149,6109924',
        altitude: '1545',
        timezone: '10',
        dst: 'O',
        type: 'airport',
    },
    {
        name: 'Maitland Airport',
        city: 'Maitland',
        country: 'Australia',
        iataCode: 'MTL',
        icaoCode: 'YMND',
        latitude: '-32,70330048',
        longitude: '151,4880066',
        altitude: '85',
        timezone: '10',
        dst: 'O',
        type: 'airport',
    },
    {
        name: 'Benjamin Rivera Noriega Airport',
        city: 'Culebra Island',
        country: 'Puerto Rico',
        iataCode: 'CPX',
        icaoCode: 'TJCP',
        latitude: '18,313289',
        longitude: '-65,304324',
        altitude: '49',
        timezone: '-4',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Raron Airport',
        city: 'Raron',
        country: 'Switzerland',
        iataCode: 'N',
        icaoCode: 'LSTA',
        latitude: '46,30360031',
        longitude: '7,823329926',
        altitude: '2029',
        timezone: '1',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Borovaya Airfield',
        city: 'Minsk',
        country: 'Belarus',
        iataCode: 'N',
        icaoCode: 'UMMB',
        latitude: '53,9604611',
        longitude: '27,6505961',
        altitude: '0',
        timezone: '3',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Amlikon Glider Airport',
        city: 'Amlikon',
        country: 'Switzerland',
        iataCode: 'N',
        icaoCode: 'LSPA',
        latitude: '47,57419968',
        longitude: '9,047499657',
        altitude: '1371',
        timezone: '1',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Lommis Airfield',
        city: 'Lommis',
        country: 'Switzerland',
        iataCode: 'N',
        icaoCode: 'LSZT',
        latitude: '47,5243988',
        longitude: '9,003060341',
        altitude: '1539',
        timezone: '1',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Williamson County Regional Airport',
        city: 'Marion',
        country: 'United States',
        iataCode: 'MWA',
        icaoCode: 'KMWA',
        latitude: '37,75500107',
        longitude: '-89,01110077',
        altitude: '472',
        timezone: '-6',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Burnet Municipal Kate Craddock Field',
        city: 'Bamburi',
        country: 'Kenya',
        iataCode: 'BMQ',
        icaoCode: 'KBMQ',
        latitude: '30,73889923',
        longitude: '-98,23860168',
        altitude: '1284',
        timezone: '-6',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Oceanside Municipal Airport',
        city: 'Fraser Island',
        country: 'Australia',
        iataCode: 'OKB',
        icaoCode: 'KOKB',
        latitude: '33,21730042',
        longitude: '-117,3539963',
        altitude: '28',
        timezone: '-8',
        dst: 'O',
        type: 'airport',
    },
    {
        name: 'Flugplatz Finsterwalde/Heinrichsruh',
        city: 'Finsterwalde',
        country: 'Germany',
        iataCode: 'N',
        icaoCode: 'EDAS',
        latitude: '51,634445',
        longitude: '13,675556',
        altitude: '384',
        timezone: '1',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Kirkuk Air Base',
        city: 'Kirkuk',
        country: 'Iraq',
        iataCode: 'KIK',
        icaoCode: 'ORKK',
        latitude: '35,4695015',
        longitude: '44,34889984',
        altitude: '1061',
        timezone: '3',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Al Udeid Air Base',
        city: 'Doha',
        country: 'Qatar',
        iataCode: 'IUD',
        icaoCode: 'OTBH',
        latitude: '25,11730003',
        longitude: '51,31499863',
        altitude: '130',
        timezone: '3',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Great Barrier Aerodrome',
        city: 'Claris',
        country: 'New Zealand',
        iataCode: 'GBZ',
        icaoCode: 'NZGB',
        latitude: '-36,24140167',
        longitude: '175,4720001',
        altitude: '20',
        timezone: '12',
        dst: 'Z',
        type: 'airport',
    },
    {
        name: 'Ford Airport',
        city: 'Iron Mountain',
        country: 'United States',
        iataCode: 'IMT',
        icaoCode: 'KIMT',
        latitude: '45,81840134',
        longitude: '-88,11450195',
        altitude: '1182',
        timezone: '-6',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Marquette Airport',
        city: 'Marquette',
        country: 'United States',
        iataCode: 'N',
        icaoCode: 'KMQT',
        latitude: '46,53390121',
        longitude: '-87,56140137',
        altitude: '1424',
        timezone: '-5',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Allakaket Airport',
        city: 'Allakaket',
        country: 'United States',
        iataCode: 'AET',
        icaoCode: 'PFAL',
        latitude: '66,55180359',
        longitude: '-152,621994',
        altitude: '441',
        timezone: '-9',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Dinslaken/Schwarze Heide Airport',
        city: 'Dinslaken',
        country: 'Germany',
        iataCode: 'N',
        icaoCode: 'EDLD',
        latitude: '51,616112',
        longitude: '6,865278',
        altitude: '217',
        timezone: '1',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Höxter-Holzminden Airport',
        city: 'Hoexter Holzminden',
        country: 'Germany',
        iataCode: 'N',
        icaoCode: 'EDVI',
        latitude: '51,80666733',
        longitude: '9,378333092',
        altitude: '1263',
        timezone: '1',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Niijima Airport',
        city: 'Niijima',
        country: 'Japan',
        iataCode: 'N',
        icaoCode: 'RJAN',
        latitude: '34,36940002',
        longitude: '139,2689972',
        altitude: '92',
        timezone: '9',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Michigan City Municipal Airport',
        city: 'Michigan City',
        country: 'United States',
        iataCode: 'MGC',
        icaoCode: 'KMGC',
        latitude: '41,70330048',
        longitude: '-86,82119751',
        altitude: '655',
        timezone: '-6',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Seward Airport',
        city: 'Seward',
        country: 'United States',
        iataCode: 'SWD',
        icaoCode: 'PAWD',
        latitude: '60,12689972',
        longitude: '-149,4190063',
        altitude: '22',
        timezone: '-9',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Grand Marais Cook County Airport',
        city: 'Grand Marais',
        country: 'United States',
        iataCode: 'GRM',
        icaoCode: 'KCKC',
        latitude: '47,8382988',
        longitude: '-90,38289642',
        altitude: '1799',
        timezone: '-6',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Wausau Downtown Airport',
        city: 'Wausau',
        country: 'United States',
        iataCode: 'AUW',
        icaoCode: 'KAUW',
        latitude: '44,92620087',
        longitude: '-89,62660217',
        altitude: '1201',
        timezone: '-6',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Kalundborg Airport',
        city: 'Kalundborg',
        country: 'Denmark',
        iataCode: 'N',
        icaoCode: 'EKKL',
        latitude: '55,70029831',
        longitude: '11,25',
        altitude: '1',
        timezone: '1',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Mary Airport',
        city: 'Mary',
        country: 'Turkmenistan',
        iataCode: 'MYP',
        icaoCode: 'UTAM',
        latitude: '37,6194',
        longitude: '61,896702',
        altitude: '728',
        timezone: '5',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Bungle Bungle Airport',
        city: 'Bungle Bungle',
        country: 'Australia',
        iataCode: 'N',
        icaoCode: 'YBUU',
        latitude: '-17,54529953',
        longitude: '128,3070068',
        altitude: '0',
        timezone: '8',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Sazená Airport',
        city: 'Sazena',
        country: 'Czech Republic',
        iataCode: 'LKS',
        icaoCode: 'LKSZ',
        latitude: '50,3246994',
        longitude: '14,25889969',
        altitude: '761',
        timezone: '1',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Spray View Airport',
        city: 'Spray View',
        country: 'Zimbabwe',
        iataCode: 'N',
        icaoCode: 'FVSV',
        latitude: '-17,91699982',
        longitude: '25,81699944',
        altitude: '3210',
        timezone: '2',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Reykjahlíð Airport',
        city: 'Myvatn',
        country: 'Iceland',
        iataCode: 'MVA',
        icaoCode: 'BIRL',
        latitude: '65,65579987',
        longitude: '-16,91810036',
        altitude: '1030',
        timezone: '0',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Sabadell Airport',
        city: 'Sabadell',
        country: 'Spain',
        iataCode: 'QSA',
        icaoCode: 'LELL',
        latitude: '41,52090073',
        longitude: '2,105079889',
        altitude: '485',
        timezone: '1',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Whitsunday Island Airport',
        city: 'Airlie Beach',
        country: 'Australia',
        iataCode: 'WSY',
        icaoCode: 'YWHI',
        latitude: '-20,27611',
        longitude: '148,755',
        altitude: '40',
        timezone: '10',
        dst: 'O',
        type: 'airport',
    },
    {
        name: 'Delaware County Johnson Field',
        city: 'Muncie',
        country: 'United States',
        iataCode: 'MIE',
        icaoCode: 'KMIE',
        latitude: '40,24229813',
        longitude: '-85,39589691',
        altitude: '937',
        timezone: '-5',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Purdue University Airport',
        city: 'Lafayette',
        country: 'United States',
        iataCode: 'LAF',
        icaoCode: 'KLAF',
        latitude: '40,41230011',
        longitude: '-86,93689728',
        altitude: '606',
        timezone: '-5',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Brown County Airport',
        city: 'Georgetown',
        country: 'United States',
        iataCode: 'N',
        icaoCode: 'KGEO',
        latitude: '38,88190079',
        longitude: '-83,88269806',
        altitude: '958',
        timezone: '-5',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'North Las Vegas Airport',
        city: 'Las Vegas',
        country: 'United States',
        iataCode: 'VGT',
        icaoCode: 'KVGT',
        latitude: '36,21070099',
        longitude: '-115,1940002',
        altitude: '2205',
        timezone: '-8',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Kenosha Regional Airport',
        city: 'Kenosha',
        country: 'United States',
        iataCode: 'ENW',
        icaoCode: 'KENW',
        latitude: '42,59569931',
        longitude: '-87,92780304',
        altitude: '742',
        timezone: '-6',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Montrose Regional Airport',
        city: 'Montrose CO',
        country: 'United States',
        iataCode: 'MTJ',
        icaoCode: 'KMTJ',
        latitude: '38,50979996',
        longitude: '-107,8939972',
        altitude: '5759',
        timezone: '-7',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Riverton Regional Airport',
        city: 'Riverton WY',
        country: 'United States',
        iataCode: 'RIW',
        icaoCode: 'KRIW',
        latitude: '43,06420136',
        longitude: '-108,4599991',
        altitude: '5525',
        timezone: '-7',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Eastern Oregon Regional At Pendleton Airport',
        city: 'Pendleton',
        country: 'United States',
        iataCode: 'PDT',
        icaoCode: 'KPDT',
        latitude: '45,69509888',
        longitude: '-118,8410034',
        altitude: '1497',
        timezone: '-8',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Lympne Airport',
        city: 'Lympne',
        country: 'United Kingdom',
        iataCode: 'LYM',
        icaoCode: 'EGMK',
        latitude: '51,08',
        longitude: '1,013',
        altitude: '351',
        timezone: '0',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Porto Cheli Airport',
        city: 'Porto Heli',
        country: 'Greece',
        iataCode: 'PKH',
        icaoCode: 'LGHL',
        latitude: '37,297501',
        longitude: '23,1478',
        altitude: '69',
        timezone: '2',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Zell Am See Airport',
        city: 'Zell am See',
        country: 'Austria',
        iataCode: 'N',
        icaoCode: 'LOWZ',
        latitude: '47,292222',
        longitude: '12,7875',
        altitude: '2470',
        timezone: '1',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'RAAF Base Amberley',
        city: 'Amberley',
        country: 'Australia',
        iataCode: 'N',
        icaoCode: 'YAMB',
        latitude: '-27,6406',
        longitude: '152,712006',
        altitude: '91',
        timezone: '10',
        dst: 'O',
        type: 'airport',
    },
    {
        name: 'Tindal Airport',
        city: 'Katherine',
        country: 'Australia',
        iataCode: 'KTR',
        icaoCode: 'YPTN',
        latitude: '-14,52110004',
        longitude: '132,378006',
        altitude: '443',
        timezone: '9,5',
        dst: 'O',
        type: 'airport',
    },
    {
        name: 'RAAF Williams, Laverton Base',
        city: 'Laverton',
        country: 'Australia',
        iataCode: 'N',
        icaoCode: 'YLVT',
        latitude: '-37,86360168',
        longitude: '144,7460022',
        altitude: '18',
        timezone: '10',
        dst: 'O',
        type: 'airport',
    },
    {
        name: 'Nowra Airport',
        city: 'Nowra',
        country: 'Australia',
        iataCode: 'NOA',
        icaoCode: 'YSNW',
        latitude: '-34,94889832',
        longitude: '150,5370026',
        altitude: '400',
        timezone: '10',
        dst: 'O',
        type: 'airport',
    },
    {
        name: 'Lutsk Airport',
        city: 'Lutsk',
        country: 'Ukraine',
        iataCode: 'UKC',
        icaoCode: 'UKLC',
        latitude: '50,678404',
        longitude: '25,487165',
        altitude: '774',
        timezone: '2',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Chernihiv Shestovitsa Airport',
        city: 'Chernigov',
        country: 'Ukraine',
        iataCode: 'CEJ',
        icaoCode: 'UKRR',
        latitude: '51,40219879',
        longitude: '31,1583004',
        altitude: '446',
        timezone: '2',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Ternopil International Airport',
        city: 'Ternopol',
        country: 'Ukraine',
        iataCode: 'TNL',
        icaoCode: 'UKLT',
        latitude: '49,5242',
        longitude: '25,7001',
        altitude: '1072',
        timezone: '2',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Brest Airport',
        city: 'Brest',
        country: 'Belarus',
        iataCode: 'BQT',
        icaoCode: 'UMBB',
        latitude: '52,108299',
        longitude: '23,8981',
        altitude: '468',
        timezone: '3',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Wittman Regional Airport',
        city: 'Oshkosh',
        country: 'United States',
        iataCode: 'OSH',
        icaoCode: 'KOSH',
        latitude: '43,9844017',
        longitude: '-88,55699921',
        altitude: '808',
        timezone: '-6',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Harle Airport',
        city: 'Harlesiel',
        country: 'Germany',
        iataCode: 'N',
        icaoCode: 'EDXP',
        latitude: '53,70666885',
        longitude: '7,820278168',
        altitude: '7',
        timezone: '1',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Wangerooge Airport',
        city: 'Wangerooge',
        country: 'Germany',
        iataCode: 'AGE',
        icaoCode: 'EDWG',
        latitude: '53,78277969',
        longitude: '7,913888931',
        altitude: '7',
        timezone: '1',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'RAAF Base Pearce',
        city: 'Perth',
        country: 'Australia',
        iataCode: 'N',
        icaoCode: 'YPEA',
        latitude: '-31,6678009',
        longitude: '116,0149994',
        altitude: '149',
        timezone: '8',
        dst: 'O',
        type: 'airport',
    },
    {
        name: 'Bendigo Airport',
        city: 'Bendigo',
        country: 'Australia',
        iataCode: 'N',
        icaoCode: 'YBDG',
        latitude: '-36,73939896',
        longitude: '144,3300018',
        altitude: '705',
        timezone: '10',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Pangborn Memorial Airport',
        city: 'Wenatchee',
        country: 'United States',
        iataCode: 'EAT',
        icaoCode: 'KEAT',
        latitude: '47,39889908',
        longitude: '-120,2070007',
        altitude: '1249',
        timezone: '-8',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Antonio Nery Juarbe Pol Airport',
        city: 'Arecibo',
        country: 'Puerto Rico',
        iataCode: 'ARE',
        icaoCode: 'TJAB',
        latitude: '18,45000076',
        longitude: '-66,6753006',
        altitude: '23',
        timezone: '-4',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Ringi Cove Airport',
        city: 'Ringi Cove',
        country: 'Solomon Islands',
        iataCode: 'RIN',
        icaoCode: 'AGRC',
        latitude: '-8,126390457',
        longitude: '157,1430054',
        altitude: '0',
        timezone: '11',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Kirensk Airport',
        city: 'Kirensk',
        country: 'Russia',
        iataCode: 'N',
        icaoCode: 'UIKK',
        latitude: '57,773',
        longitude: '108,064',
        altitude: '840',
        timezone: '8',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Ust-Kut Airport',
        city: 'Ust-Kut',
        country: 'Russia',
        iataCode: 'UKX',
        icaoCode: 'UITT',
        latitude: '56,8567009',
        longitude: '105,7300034',
        altitude: '2188',
        timezone: '8',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Rimatara Airport',
        city: 'Rimatara',
        country: 'French Polynesia',
        iataCode: 'RMT',
        icaoCode: 'NTAM',
        latitude: '-22,63725',
        longitude: '-152,8059',
        altitude: '60',
        timezone: '-10',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Triengen Airport',
        city: 'Triengen',
        country: 'Switzerland',
        iataCode: 'N',
        icaoCode: 'LSPN',
        latitude: '47,22669983',
        longitude: '8,07806015',
        altitude: '1594',
        timezone: '1',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Lausanne-Blécherette Airport',
        city: 'Lausanne',
        country: 'Switzerland',
        iataCode: 'QLS',
        icaoCode: 'LSGL',
        latitude: '46,54529953',
        longitude: '6,616670132',
        altitude: '2041',
        timezone: '1',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Speck-Fehraltorf Airport',
        city: 'Fehraltorf',
        country: 'Switzerland',
        iataCode: 'N',
        icaoCode: 'LSZK',
        latitude: '47,37639999',
        longitude: '8,757499695',
        altitude: '1748',
        timezone: '1',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Locarno Airport',
        city: 'Locarno',
        country: 'Switzerland',
        iataCode: 'ZJI',
        icaoCode: 'LSZL',
        latitude: '46,16080093',
        longitude: '8,878609657',
        altitude: '650',
        timezone: '1',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Neuchatel Airport',
        city: 'Neuchatel',
        country: 'Switzerland',
        iataCode: 'QNC',
        icaoCode: 'LSGN',
        latitude: '46,95750046',
        longitude: '6,864719868',
        altitude: '1427',
        timezone: '1',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Taganrog Yuzhny Airport',
        city: 'Taganrog',
        country: 'Russia',
        iataCode: 'N',
        icaoCode: 'URRT',
        latitude: '47,1983333',
        longitude: '38,8491667',
        altitude: '117',
        timezone: '3',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Gelendzhik Airport',
        city: 'Gelendzhik',
        country: 'Russia',
        iataCode: 'GDZ',
        icaoCode: 'URKG',
        latitude: '44,58209263',
        longitude: '38,01248074',
        altitude: '98',
        timezone: '3',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Zhukovsky International Airport',
        city: 'Ramenskoe',
        country: 'Russia',
        iataCode: 'N',
        icaoCode: 'UUBW',
        latitude: '55,553299',
        longitude: '38,150002',
        altitude: '377',
        timezone: '3',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Khabarovsk Airport',
        city: 'Khabarovsk',
        country: 'Russia',
        iataCode: 'N',
        icaoCode: 'UHHT',
        latitude: '48,52500153',
        longitude: '135,1529999',
        altitude: '262',
        timezone: '10',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Dzemgi Airport',
        city: 'Komsomolsk-on-Amur',
        country: 'Russia',
        iataCode: 'N',
        icaoCode: 'UHKD',
        latitude: '50,60559845',
        longitude: '137,0809937',
        altitude: '89',
        timezone: '10',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Belaya Air Base',
        city: 'Sredniiy',
        country: 'Russia',
        iataCode: 'N',
        icaoCode: 'UIIB',
        latitude: '52,91500092',
        longitude: '103,5749969',
        altitude: '1503',
        timezone: '8',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Tunoshna Airport',
        city: 'Yaroslavl',
        country: 'Russia',
        iataCode: 'IAR',
        icaoCode: 'UUDL',
        latitude: '57,56069946',
        longitude: '40,15739822',
        altitude: '287',
        timezone: '3',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Gu-Lian Airport',
        city: 'Mohe County',
        country: 'China',
        iataCode: 'OHE',
        icaoCode: 'ZYMH',
        latitude: '52,91277778',
        longitude: '122,43',
        altitude: '1836',
        timezone: '8',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Jining Qufu Airport',
        city: 'Jining',
        country: 'China',
        iataCode: 'JNG',
        icaoCode: 'ZLJN',
        latitude: '35,292778',
        longitude: '116,346667',
        altitude: '134',
        timezone: '8',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Drake Bay Airport',
        city: 'Puntarenas',
        country: 'Costa Rica',
        iataCode: 'DRK',
        icaoCode: 'MRDK',
        latitude: '8,71889019',
        longitude: '-83,64170074',
        altitude: '12',
        timezone: '-6',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Tuzla International Airport',
        city: 'Null',
        country: 'Bosnia and Herzegovina',
        iataCode: 'N',
        icaoCode: 'LQTZ',
        latitude: '44,45869827',
        longitude: '18,72480011',
        altitude: '784',
        timezone: '1',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'NAS Fort Worth JRB/Carswell Field',
        city: 'Dallas',
        country: 'United States',
        iataCode: 'N',
        icaoCode: 'KNFW',
        latitude: '32,76919937',
        longitude: '-97,4414978',
        altitude: '650',
        timezone: '-6',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Naypyidaw Airport',
        city: 'Naypyidaw',
        country: 'Burma',
        iataCode: 'NYT',
        icaoCode: 'VYEL',
        latitude: '19,62350082',
        longitude: '96,2009964',
        altitude: '302',
        timezone: '6,5',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Kyaukhtu South Airport',
        city: 'Kyauktu',
        country: 'Burma',
        iataCode: 'N',
        icaoCode: 'VYXG',
        latitude: '21,40670013',
        longitude: '94,13030243',
        altitude: '1345',
        timezone: '6,5',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Jan Mayensfield',
        city: 'Jan Mayen',
        country: 'Norway',
        iataCode: 'ZXB',
        icaoCode: 'ENJA',
        latitude: '70,96111111',
        longitude: '-8,575833333',
        altitude: '39',
        timezone: '1',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Bokpyinn Airport',
        city: 'Bokepyin',
        country: 'Burma',
        iataCode: 'N',
        icaoCode: 'VYBP',
        latitude: '11,1494',
        longitude: '98,735901',
        altitude: '100',
        timezone: '6,5',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Wuhai Airport',
        city: 'Wuhai',
        country: 'China',
        iataCode: 'WUA',
        icaoCode: 'ZBUH',
        latitude: '39,7934',
        longitude: '106,7993',
        altitude: '3650',
        timezone: '8',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Gary Chicago International Airport',
        city: 'Gary',
        country: 'United States',
        iataCode: 'GYY',
        icaoCode: 'KGYY',
        latitude: '41,61629868',
        longitude: '-87,41280365',
        altitude: '591',
        timezone: '-6',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Brainerd Lakes Regional Airport',
        city: 'Brainerd',
        country: 'United States',
        iataCode: 'BRD',
        icaoCode: 'KBRD',
        latitude: '46,39830017',
        longitude: '-94,13809967',
        altitude: '1232',
        timezone: '-6',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Greenbrier Valley Airport',
        city: 'Lewisburg',
        country: 'United States',
        iataCode: 'LWB',
        icaoCode: 'KLWB',
        latitude: '37,85829926',
        longitude: '-80,39949799',
        altitude: '2302',
        timezone: '-5',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Pitt Greenville Airport',
        city: 'Greenville',
        country: 'United States',
        iataCode: 'PGV',
        icaoCode: 'KPGV',
        latitude: '35,6352005',
        longitude: '-77,38529968',
        altitude: '26',
        timezone: '-5',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Chefornak Airport',
        city: 'Chefornak',
        country: 'United States',
        iataCode: 'CYF',
        icaoCode: 'PACK',
        latitude: '60,14920044',
        longitude: '-164,2859955',
        altitude: '40',
        timezone: '-9',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Oxnard Airport',
        city: 'Oxnard',
        country: 'United States',
        iataCode: 'OXR',
        icaoCode: 'KOXR',
        latitude: '34,20080185',
        longitude: '-119,2070007',
        altitude: '45',
        timezone: '-8',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Tongren Fenghuang Airport',
        city: 'Tongren',
        country: 'China',
        iataCode: 'TEN',
        icaoCode: 'ZUTR',
        latitude: '27,883333',
        longitude: '109,308889',
        altitude: '0',
        timezone: '8',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Naiu Airport',
        city: 'Niau',
        country: 'French Polynesia',
        iataCode: 'NIU',
        icaoCode: 'NTKN',
        latitude: '-16,1191',
        longitude: '-146,3683',
        altitude: '50',
        timezone: '-10',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Schenectady County Airport',
        city: 'Scotia NY',
        country: 'United States',
        iataCode: 'SCH',
        icaoCode: 'KSCH',
        latitude: '42,85250092',
        longitude: '-73,92890167',
        altitude: '378',
        timezone: '-5',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Begishevo Airport',
        city: 'Nizhnekamsk',
        country: 'Russia',
        iataCode: 'NBC',
        icaoCode: 'UWKE',
        latitude: '55,56470108',
        longitude: '52,09249878',
        altitude: '643',
        timezone: '3',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Volkel Air Base',
        city: 'Volkel',
        country: 'Netherlands',
        iataCode: 'N',
        icaoCode: 'EHVK',
        latitude: '51,657222',
        longitude: '5,7077778',
        altitude: '72',
        timezone: '1',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Siargao Airport',
        city: 'Siargao',
        country: 'Philippines',
        iataCode: 'IAO',
        icaoCode: 'RPNS',
        latitude: '9,859100342',
        longitude: '126,0139999',
        altitude: '10',
        timezone: '8',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Langeoog Airport',
        city: 'Langeoog',
        country: 'Germany',
        iataCode: 'LGO',
        icaoCode: 'EDWL',
        latitude: '53,74250031',
        longitude: '7,497777939',
        altitude: '7',
        timezone: '1',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Biel-Kappelen Airport',
        city: 'Biel',
        country: 'Switzerland',
        iataCode: 'N',
        icaoCode: 'LSZP',
        latitude: '47,08919907',
        longitude: '7,289999962',
        altitude: '1437',
        timezone: '1',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Nelspruit Airport',
        city: 'Nelspruit',
        country: 'South Africa',
        iataCode: 'NLP',
        icaoCode: 'FANS',
        latitude: '-25,5',
        longitude: '30,91379929',
        altitude: '2875',
        timezone: '2',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Cherkasy International Airport',
        city: 'Cherkassy',
        country: 'Ukraine',
        iataCode: 'CKC',
        icaoCode: 'UKKE',
        latitude: '49,41559982',
        longitude: '31,99530029',
        altitude: '375',
        timezone: '2',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Northeast Florida Regional Airport',
        city: 'St. Augustine Airport',
        country: 'United States',
        iataCode: 'UST',
        icaoCode: 'KSGJ',
        latitude: '29,95919991',
        longitude: '-81,33979797',
        altitude: '10',
        timezone: '-5',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Mykolaiv International Airport',
        city: 'Nikolayev',
        country: 'Ukraine',
        iataCode: 'NLV',
        icaoCode: 'UKON',
        latitude: '47,05789948',
        longitude: '31,9197998',
        altitude: '184',
        timezone: '2',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Ramechhap Airport',
        city: 'Ramechhap',
        country: 'Nepal',
        iataCode: 'RHP',
        icaoCode: 'VNRC',
        latitude: '27,3939991',
        longitude: '86,06140137',
        altitude: '1555',
        timezone: '5,75',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Charles M. Schulz Sonoma County Airport',
        city: 'Santa Rosa',
        country: 'United States',
        iataCode: 'STS',
        icaoCode: 'KSTS',
        latitude: '38,50899887',
        longitude: '-122,8130035',
        altitude: '128',
        timezone: '-8',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Kissimmee Gateway Airport',
        city: 'Kissimmee',
        country: 'United States',
        iataCode: 'ISM',
        icaoCode: 'KISM',
        latitude: '28,28980064',
        longitude: '-81,43710327',
        altitude: '82',
        timezone: '-5',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Lake City Gateway Airport',
        city: 'Lake City',
        country: 'United States',
        iataCode: 'LCQ',
        icaoCode: 'KLCQ',
        latitude: '30,18199921',
        longitude: '-82,57689667',
        altitude: '201',
        timezone: '-5',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Deland Municipal Sidney H Taylor Field',
        city: 'DeLand',
        country: 'United States',
        iataCode: 'N',
        icaoCode: 'KDED',
        latitude: '29,06699944',
        longitude: '-81,28379822',
        altitude: '79',
        timezone: '-5',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Haller Airpark',
        city: 'Green Cove Springs',
        country: 'United States',
        iataCode: 'N',
        icaoCode: '7FL4',
        latitude: '29,904057',
        longitude: '-81,68515',
        altitude: '75',
        timezone: '-5',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Palmar Airport',
        city: 'Santa Lucia',
        country: 'Peru',
        iataCode: 'N',
        icaoCode: 'SLPA',
        latitude: '-18,97166634',
        longitude: '-62,97533417',
        altitude: '1342',
        timezone: '-4',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Logan-Cache Airport',
        city: 'Logan',
        country: 'United States',
        iataCode: 'LGU',
        icaoCode: 'KLGU',
        latitude: '41,79119873',
        longitude: '-111,8519974',
        altitude: '4457',
        timezone: '-7',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Brigham City Airport',
        city: 'Brigham City',
        country: 'United States',
        iataCode: 'BMC',
        icaoCode: 'KBMC',
        latitude: '41,55239868',
        longitude: '-112,0619965',
        altitude: '4229',
        timezone: '-7',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Malad City Airport',
        city: 'Malad City',
        country: 'United States',
        iataCode: 'MLD',
        icaoCode: 'KMLD',
        latitude: '42,16659927',
        longitude: '-112,2969971',
        altitude: '4503',
        timezone: '-7',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Aspen-Pitkin Co/Sardy Field',
        city: 'Aspen',
        country: 'United States',
        iataCode: 'ASE',
        icaoCode: 'KASE',
        latitude: '39,22320175',
        longitude: '-106,8690033',
        altitude: '7820',
        timezone: '-7',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Ulyanovsk Baratayevka Airport',
        city: 'Ulyanovsk',
        country: 'Russia',
        iataCode: 'ULV',
        icaoCode: 'UWLL',
        latitude: '54,2682991',
        longitude: '48,22669983',
        altitude: '463',
        timezone: '4',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Kerrville Municipal Louis Schreiner Field',
        city: 'Kerrville',
        country: 'United States',
        iataCode: 'ERV',
        icaoCode: 'KERV',
        latitude: '29,97669983',
        longitude: '-99,08570099',
        altitude: '1617',
        timezone: '-6',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Birrfeld Airport',
        city: 'Birrfeld',
        country: 'Switzerland',
        iataCode: 'N',
        icaoCode: 'LSZF',
        latitude: '47,4435997',
        longitude: '8,233610153',
        altitude: '1300',
        timezone: '1',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Sussex County Airport',
        city: 'Georgetown',
        country: 'United States',
        iataCode: 'GED',
        icaoCode: 'KGED',
        latitude: '38,68920135',
        longitude: '-75,35890198',
        altitude: '53',
        timezone: '-5',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Prince Rupert/Seal Cove Seaplane Base',
        city: 'Prince Rupert',
        country: 'Canada',
        iataCode: 'ZSW',
        icaoCode: 'CZSW',
        latitude: '54,33330154',
        longitude: '-130,2830048',
        altitude: '0',
        timezone: '-8',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Great Bend Municipal Airport',
        city: 'Great Bend',
        country: 'United States',
        iataCode: 'GBN',
        icaoCode: 'KGBD',
        latitude: '38,34429932',
        longitude: '-98,85919952',
        altitude: '1887',
        timezone: '-6',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Hays Regional Airport',
        city: 'Hays',
        country: 'United States',
        iataCode: 'HYS',
        icaoCode: 'KHYS',
        latitude: '38,84220123',
        longitude: '-99,27320099',
        altitude: '1999',
        timezone: '-6',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Spirit of St Louis Airport',
        city: 'Null',
        country: 'United States',
        iataCode: 'SUS',
        icaoCode: 'KSUS',
        latitude: '38,66210175',
        longitude: '-90,65200043',
        altitude: '463',
        timezone: '-6',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Ely Municipal Airport',
        city: 'Ely',
        country: 'United States',
        iataCode: 'LYU',
        icaoCode: 'KELO',
        latitude: '47,82450104',
        longitude: '-91,83070374',
        altitude: '1456',
        timezone: '-6',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Grand Rapids Itasca Co-Gordon Newstrom field',
        city: 'Grand Rapids MN',
        country: 'United States',
        iataCode: 'GPZ',
        icaoCode: 'KGPZ',
        latitude: '47,21110153',
        longitude: '-93,50980377',
        altitude: '1355',
        timezone: '-6',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Thief River Falls Regional Airport',
        city: 'Thief River Falls',
        country: 'United States',
        iataCode: 'TVF',
        icaoCode: 'KTVF',
        latitude: '48,06570053',
        longitude: '-96,18499756',
        altitude: '1119',
        timezone: '-6',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Eagle River Union Airport',
        city: 'Eagle River',
        country: 'United States',
        iataCode: 'EGV',
        icaoCode: 'KEGV',
        latitude: '45,93230057',
        longitude: '-89,26830292',
        altitude: '1642',
        timezone: '-6',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Lakeland-Noble F. Lee Memorial field',
        city: 'Minocqua - Woodruff',
        country: 'United States',
        iataCode: 'ARV',
        icaoCode: 'KARV',
        latitude: '45,92789841',
        longitude: '-89,73090363',
        altitude: '1629',
        timezone: '-6',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Ankeny Regional Airport',
        city: 'Ankeny',
        country: 'United States',
        iataCode: 'IKV',
        icaoCode: 'KIKV',
        latitude: '41,69139862',
        longitude: '-93,56639862',
        altitude: '910',
        timezone: '-6',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Berens River Airport',
        city: 'Berens River',
        country: 'Canada',
        iataCode: 'YBV',
        icaoCode: 'CYBV',
        latitude: '52,358898',
        longitude: '-97,018303',
        altitude: '728',
        timezone: '-6',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Corpus Christi Naval Air Station/Truax Field',
        city: 'Corpus Christi',
        country: 'United States',
        iataCode: 'NGP',
        icaoCode: 'KNGP',
        latitude: '27,69260025',
        longitude: '-97,29109955',
        altitude: '18',
        timezone: '-6',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Catalina Airport',
        city: 'Catalina Island',
        country: 'United States',
        iataCode: 'AVX',
        icaoCode: 'KAVX',
        latitude: '33,4049',
        longitude: '-118,416',
        altitude: '1602',
        timezone: '-8',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Mojave Airport',
        city: 'Mojave',
        country: 'United States',
        iataCode: 'MHV',
        icaoCode: 'KMHV',
        latitude: '35,05939865',
        longitude: '-118,1520004',
        altitude: '2801',
        timezone: '-8',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Interlaken Air Base',
        city: 'Interlaken',
        country: 'Switzerland',
        iataCode: 'ZIN',
        icaoCode: 'LSMI',
        latitude: '46,6766014',
        longitude: '7,8790798',
        altitude: '0',
        timezone: '1',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Inisheer Aerodrome',
        city: 'Inisheer',
        country: 'Ireland',
        iataCode: 'INQ',
        icaoCode: 'EIIR',
        latitude: '53,06470108',
        longitude: '-9,510899544',
        altitude: '40',
        timezone: '0',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Strezhevoy Airport',
        city: 'Strezhevoy',
        country: 'Russia',
        iataCode: 'SWT',
        icaoCode: 'UNSS',
        latitude: '60,70940018',
        longitude: '77,66000366',
        altitude: '164',
        timezone: '7',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Hutchinson Municipal Airport',
        city: 'Hutchinson',
        country: 'United States',
        iataCode: 'HUT',
        icaoCode: 'KHUT',
        latitude: '38,06549835',
        longitude: '-97,86060333',
        altitude: '1543',
        timezone: '-6',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Bagram Air Base',
        city: 'Kabul',
        country: 'Afghanistan',
        iataCode: 'N',
        icaoCode: 'OAIX',
        latitude: '34,94609833',
        longitude: '69,26499939',
        altitude: '4895',
        timezone: '4,5',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Prince Sultan Air Base',
        city: 'Al Kharj',
        country: 'Saudi Arabia',
        iataCode: 'N',
        icaoCode: 'OEPS',
        latitude: '24,06270027',
        longitude: '47,58050156',
        altitude: '1651',
        timezone: '3',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Rosecrans Memorial Airport',
        city: 'Rosecrans',
        country: 'United States',
        iataCode: 'STJ',
        icaoCode: 'KSTJ',
        latitude: '39,77190018',
        longitude: '-94,90969849',
        altitude: '826',
        timezone: '-6',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Pápa Air Base',
        city: 'Papa',
        country: 'Hungary',
        iataCode: 'N',
        icaoCode: 'LHPA',
        latitude: '47,36360168',
        longitude: '17,50079918',
        altitude: '466',
        timezone: '1',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Whiting Field Naval Air Station South Airport',
        city: 'Cuxhaven',
        country: 'Germany',
        iataCode: 'NDZ',
        icaoCode: 'KNDZ',
        latitude: '30,70439911',
        longitude: '-87,02300262',
        altitude: '177',
        timezone: '-6',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Volk Field',
        city: 'Camp Douglas',
        country: 'United States',
        iataCode: 'VOK',
        icaoCode: 'KVOK',
        latitude: '43,93899918',
        longitude: '-90,25340271',
        altitude: '912',
        timezone: '-6',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Gunnison Crested Butte Regional Airport',
        city: 'Gunnison',
        country: 'United States',
        iataCode: 'GUC',
        icaoCode: 'KGUC',
        latitude: '38,53390121',
        longitude: '-106,9329987',
        altitude: '7680',
        timezone: '-7',
        dst: 'A',
        type: 'airport',
    },
    {
        name: "Xi'an Xiguan Airport",
        city: "Xi\\'AN",
        country: 'China',
        iataCode: 'SIA',
        icaoCode: 'ZLSN',
        latitude: '34,376701',
        longitude: '109,120003',
        altitude: '0',
        timezone: '8',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Zamperini Field',
        city: 'Torrance',
        country: 'United States',
        iataCode: 'TOA',
        icaoCode: 'KTOA',
        latitude: '33,80339813',
        longitude: '-118,3399963',
        altitude: '103',
        timezone: '-8',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Manistee Co Blacker Airport',
        city: 'Manistee',
        country: 'United States',
        iataCode: 'MBL',
        icaoCode: 'KMBL',
        latitude: '44,2723999',
        longitude: '-86,24690247',
        altitude: '621',
        timezone: '-5',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Charlotte County Airport',
        city: 'Punta Gorda',
        country: 'United States',
        iataCode: 'PGD',
        icaoCode: 'KPGD',
        latitude: '26,92020035',
        longitude: '-81,9905014',
        altitude: '26',
        timezone: '-5',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Northern Aroostook Regional Airport',
        city: 'Frenchville',
        country: 'United States',
        iataCode: 'WFK',
        icaoCode: 'KFVE',
        latitude: '47,28549957',
        longitude: '-68,31279755',
        altitude: '988',
        timezone: '-5',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Chautauqua County-Jamestown Airport',
        city: 'Jamestown',
        country: 'United States',
        iataCode: 'JHW',
        icaoCode: 'KJHW',
        latitude: '42,15340042',
        longitude: '-79,25800323',
        altitude: '1723',
        timezone: '-5',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'La Macaza / Mont-Tremblant International Inc Airport',
        city: 'Mont-Tremblant',
        country: 'Canada',
        iataCode: 'YTM',
        icaoCode: 'CYFJ',
        latitude: '46,40940094',
        longitude: '-74,77999878',
        altitude: '827',
        timezone: '-5',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Lake Cumberland Regional Airport',
        city: 'Somerset',
        country: 'United States',
        iataCode: 'SME',
        icaoCode: 'KSME',
        latitude: '37,05339813',
        longitude: '-84,61589813',
        altitude: '927',
        timezone: '-5',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Shenandoah Valley Regional Airport',
        city: 'Weyers Cave',
        country: 'United States',
        iataCode: 'SHD',
        icaoCode: 'KSHD',
        latitude: '38,26380157',
        longitude: '-78,89640045',
        altitude: '1201',
        timezone: '-5',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Devils Lake Regional Airport',
        city: 'Devils Lake',
        country: 'United States',
        iataCode: 'DVL',
        icaoCode: 'KDVL',
        latitude: '48,11420059',
        longitude: '-98,90879822',
        altitude: '1456',
        timezone: '-6',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Dickinson Theodore Roosevelt Regional Airport',
        city: 'Dickinson',
        country: 'United States',
        iataCode: 'DIK',
        icaoCode: 'KDIK',
        latitude: '46,79740143',
        longitude: '-102,802002',
        altitude: '2592',
        timezone: '-7',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Sidney Richland Municipal Airport',
        city: 'Sidney',
        country: 'United States',
        iataCode: 'SDY',
        icaoCode: 'KSDY',
        latitude: '47,70690155',
        longitude: '-104,1930008',
        altitude: '1985',
        timezone: '-7',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Chadron Municipal Airport',
        city: 'Chadron',
        country: 'United States',
        iataCode: 'CDR',
        icaoCode: 'KCDR',
        latitude: '42,83760071',
        longitude: '-103,0950012',
        altitude: '3297',
        timezone: '-7',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Alliance Municipal Airport',
        city: 'Alliance',
        country: 'United States',
        iataCode: 'AIA',
        icaoCode: 'KAIA',
        latitude: '42,05319977',
        longitude: '-102,8040009',
        altitude: '3931',
        timezone: '-7',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Mc Cook Ben Nelson Regional Airport',
        city: 'McCook',
        country: 'United States',
        iataCode: 'MCK',
        icaoCode: 'KMCK',
        latitude: '40,20629883',
        longitude: '-100,5920029',
        altitude: '2583',
        timezone: '-6',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'The Florida Keys Marathon Airport',
        city: 'Marathon',
        country: 'United States',
        iataCode: 'MTH',
        icaoCode: 'KMTH',
        latitude: '24,72610092',
        longitude: '-81,05139923',
        altitude: '5',
        timezone: '-5',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Dawson Community Airport',
        city: 'Glendive',
        country: 'United States',
        iataCode: 'GDV',
        icaoCode: 'KGDV',
        latitude: '47,13869858',
        longitude: '-104,8069992',
        altitude: '2458',
        timezone: '-7',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'L M Clayton Airport',
        city: 'Wolf Point',
        country: 'United States',
        iataCode: 'OLF',
        icaoCode: 'KOLF',
        latitude: '48,0945015',
        longitude: '-105,5749969',
        altitude: '1986',
        timezone: '-7',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Yellowstone Airport',
        city: 'West Yellowstone',
        country: 'United States',
        iataCode: 'WYS',
        icaoCode: 'KWYS',
        latitude: '44,68840027',
        longitude: '-111,1179962',
        altitude: '6649',
        timezone: '-7',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'San Luis Valley Regional Bergman Field',
        city: 'Alamosa',
        country: 'United States',
        iataCode: 'ALS',
        icaoCode: 'KALS',
        latitude: '37,434898',
        longitude: '-105,866997',
        altitude: '7539',
        timezone: '-7',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Canyonlands Field',
        city: 'Moab',
        country: 'United States',
        iataCode: 'CNY',
        icaoCode: 'KCNY',
        latitude: '38,75500107',
        longitude: '-109,7549973',
        altitude: '4557',
        timezone: '-7',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Ely Airport Yelland Field',
        city: 'Ely',
        country: 'United States',
        iataCode: 'ELY',
        icaoCode: 'KELY',
        latitude: '39,29970169',
        longitude: '-114,8420029',
        altitude: '6259',
        timezone: '-8',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Vernal Regional Airport',
        city: 'Vernal',
        country: 'United States',
        iataCode: 'VEL',
        icaoCode: 'KVEL',
        latitude: '40,4408989',
        longitude: '-109,5100021',
        altitude: '5278',
        timezone: '-7',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Sierra Blanca Regional Airport',
        city: 'Ruidoso',
        country: 'United States',
        iataCode: 'SRR',
        icaoCode: 'KSRR',
        latitude: '33,46279907',
        longitude: '-105,5350037',
        altitude: '6814',
        timezone: '-7',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Show Low Regional Airport',
        city: 'Show Low',
        country: 'United States',
        iataCode: 'SOW',
        icaoCode: 'KSOW',
        latitude: '34,26549912',
        longitude: '-110,0059967',
        altitude: '6415',
        timezone: '-7',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'McCall Municipal Airport',
        city: 'McCall',
        country: 'United States',
        iataCode: 'MYL',
        icaoCode: 'KMYL',
        latitude: '44,88970184',
        longitude: '-116,1009979',
        altitude: '5024',
        timezone: '-7',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Lemhi County Airport',
        city: 'Salmon',
        country: 'United States',
        iataCode: 'SMN',
        icaoCode: 'KSMN',
        latitude: '45,12379837',
        longitude: '-113,8809967',
        altitude: '4043',
        timezone: '-7',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Mammoth Yosemite Airport',
        city: 'Mammoth Lakes',
        country: 'United States',
        iataCode: 'MMH',
        icaoCode: 'KMMH',
        latitude: '37,62409973',
        longitude: '-118,8379974',
        altitude: '7135',
        timezone: '-8',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Friday Harbor Airport',
        city: 'Friday Harbor',
        country: 'United States',
        iataCode: 'FRD',
        icaoCode: 'KFHR',
        latitude: '48,52199936',
        longitude: '-123,0240021',
        altitude: '113',
        timezone: '-8',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Orcas Island Airport',
        city: 'Eastsound',
        country: 'United States',
        iataCode: 'ESD',
        icaoCode: 'KORS',
        latitude: '48,70819855',
        longitude: '-122,9100037',
        altitude: '31',
        timezone: '-8',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Astoria Regional Airport',
        city: 'Astoria',
        country: 'United States',
        iataCode: 'AST',
        icaoCode: 'KAST',
        latitude: '46,15800095',
        longitude: '-123,8789978',
        altitude: '15',
        timezone: '-8',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Newport Municipal Airport',
        city: 'Newport',
        country: 'United States',
        iataCode: 'ONP',
        icaoCode: 'KONP',
        latitude: '44,58039856',
        longitude: '-124,0579987',
        altitude: '160',
        timezone: '-8',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Emmonak Airport',
        city: 'Emmonak',
        country: 'United States',
        iataCode: 'EMK',
        icaoCode: 'PAEM',
        latitude: '62,78609848',
        longitude: '-164,4909973',
        altitude: '13',
        timezone: '-9',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Unalakleet Airport',
        city: 'Unalakleet',
        country: 'United States',
        iataCode: 'UNK',
        icaoCode: 'PAUN',
        latitude: '63,88840103',
        longitude: '-160,798996',
        altitude: '27',
        timezone: '-9',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Ugnu-Kuparuk Airport',
        city: 'Kuparuk',
        country: 'United States',
        iataCode: 'UUK',
        icaoCode: 'PAKU',
        latitude: '70,33080292',
        longitude: '-149,5980072',
        altitude: '67',
        timezone: '-9',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Shageluk Airport',
        city: 'Shageluk',
        country: 'United States',
        iataCode: 'SHX',
        icaoCode: 'PAHX',
        latitude: '62,69229889',
        longitude: '-159,5690002',
        altitude: '79',
        timezone: '-9',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Nuiqsut Airport',
        city: 'Nuiqsut',
        country: 'United States',
        iataCode: 'NUI',
        icaoCode: 'PAQT',
        latitude: '70,20999908',
        longitude: '-151,0059967',
        altitude: '38',
        timezone: '-9',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Eek Airport',
        city: 'Eek',
        country: 'United States',
        iataCode: 'EEK',
        icaoCode: 'PAEE',
        latitude: '60,21367264',
        longitude: '-162,0438843',
        altitude: '12',
        timezone: '-9',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Kasigluk Airport',
        city: 'Kasigluk',
        country: 'United States',
        iataCode: 'KUK',
        icaoCode: 'PFKA',
        latitude: '60,87440109',
        longitude: '-162,5240021',
        altitude: '48',
        timezone: '-9',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Kwethluk Airport',
        city: 'Kwethluk',
        country: 'United States',
        iataCode: 'KWT',
        icaoCode: 'PFKW',
        latitude: '60,79029846',
        longitude: '-161,4440002',
        altitude: '25',
        timezone: '-9',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Kwigillingok Airport',
        city: 'Kwigillingok',
        country: 'United States',
        iataCode: 'KWK',
        icaoCode: 'PAGG',
        latitude: '59,876499',
        longitude: '-163,169005',
        altitude: '18',
        timezone: '-9',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Marshall Don Hunter Sr Airport',
        city: 'Marshall',
        country: 'United States',
        iataCode: 'MLL',
        icaoCode: 'PADM',
        latitude: '61,86429977',
        longitude: '-162,026001',
        altitude: '103',
        timezone: '-9',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Russian Mission Airport',
        city: 'Russian Mission',
        country: 'United States',
        iataCode: 'RSH',
        icaoCode: 'PARS',
        latitude: '61,77888489',
        longitude: '-161,319458',
        altitude: '51',
        timezone: '-9',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Koliganek Airport',
        city: 'Koliganek',
        country: 'United States',
        iataCode: 'KGK',
        icaoCode: 'PAJZ',
        latitude: '59,72660065',
        longitude: '-157,2590027',
        altitude: '269',
        timezone: '-9',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Manokotak Airport',
        city: 'Manokotak',
        country: 'United States',
        iataCode: 'KMO',
        icaoCode: 'PAMB',
        latitude: '58,99020004',
        longitude: '-159,0500031',
        altitude: '100',
        timezone: '-9',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Chalkyitsik Airport',
        city: 'Chalkyitsik',
        country: 'United States',
        iataCode: 'CIK',
        icaoCode: 'PACI',
        latitude: '66,64499664',
        longitude: '-143,7400055',
        altitude: '544',
        timezone: '-9',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Eagle Airport',
        city: 'Eagle',
        country: 'United States',
        iataCode: 'EAA',
        icaoCode: 'PAEG',
        latitude: '64,77639771',
        longitude: '-141,151001',
        altitude: '908',
        timezone: '-9',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Hughes Airport',
        city: 'Hughes',
        country: 'United States',
        iataCode: 'HUS',
        icaoCode: 'PAHU',
        latitude: '66,04109955',
        longitude: '-154,2630005',
        altitude: '299',
        timezone: '-9',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Huslia Airport',
        city: 'Huslia',
        country: 'United States',
        iataCode: 'HSL',
        icaoCode: 'PAHL',
        latitude: '65,69789886',
        longitude: '-156,3509979',
        altitude: '220',
        timezone: '-9',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Nulato Airport',
        city: 'Nulato',
        country: 'United States',
        iataCode: 'NUL',
        icaoCode: 'PANU',
        latitude: '64,72930145',
        longitude: '-158,0740051',
        altitude: '399',
        timezone: '-9',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Venetie Airport',
        city: 'Venetie',
        country: 'United States',
        iataCode: 'VEE',
        icaoCode: 'PAVE',
        latitude: '67,00869751',
        longitude: '-146,3659973',
        altitude: '574',
        timezone: '-9',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Beaver Airport',
        city: 'Beaver',
        country: 'United States',
        iataCode: 'WBQ',
        icaoCode: 'PAWB',
        latitude: '66,36219788',
        longitude: '-147,4069977',
        altitude: '359',
        timezone: '-9',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Central Airport',
        city: 'Central',
        country: 'United States',
        iataCode: 'CEM',
        icaoCode: 'PACE',
        latitude: '65,57379913',
        longitude: '-144,7830048',
        altitude: '937',
        timezone: '-9',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Shungnak Airport',
        city: 'Shungnak',
        country: 'United States',
        iataCode: 'SHG',
        icaoCode: 'PAGH',
        latitude: '66,88809967',
        longitude: '-157,1620026',
        altitude: '197',
        timezone: '-9',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Inyokern Airport',
        city: 'Inyokern',
        country: 'United States',
        iataCode: 'IYK',
        icaoCode: 'KIYK',
        latitude: '35,65879822',
        longitude: '-117,8300018',
        altitude: '2457',
        timezone: '-8',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Visalia Municipal Airport',
        city: 'Visalia',
        country: 'United States',
        iataCode: 'VIS',
        icaoCode: 'KVIS',
        latitude: '36,31869888',
        longitude: '-119,3929977',
        altitude: '295',
        timezone: '-8',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Merced Regional Macready Field',
        city: 'Merced',
        country: 'United States',
        iataCode: 'MCE',
        icaoCode: 'KMCE',
        latitude: '37,28469849',
        longitude: '-120,5139999',
        altitude: '155',
        timezone: '-8',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Laguna de Los Patos International Airport',
        city: 'Colonia',
        country: 'Uruguay',
        iataCode: 'CYR',
        icaoCode: 'SUCA',
        latitude: '-34,45640182',
        longitude: '-57,77059937',
        altitude: '66',
        timezone: '-3',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Amarais Airport',
        city: 'Campinas',
        country: 'Brazil',
        iataCode: 'CPQ',
        icaoCode: 'SDAM',
        latitude: '-22,85919952',
        longitude: '-47,10820007',
        altitude: '2008',
        timezone: '-3',
        dst: 'S',
        type: 'airport',
    },
    {
        name: 'Phoenix Goodyear Airport',
        city: 'Goodyear',
        country: 'United States',
        iataCode: 'N',
        icaoCode: 'KGYR',
        latitude: '33,42250061',
        longitude: '-112,3759995',
        altitude: '968',
        timezone: '-7',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Toowoomba Airport',
        city: 'Toowoomba',
        country: 'Australia',
        iataCode: 'TWB',
        icaoCode: 'YTWB',
        latitude: '-27,5428009',
        longitude: '151,9160004',
        altitude: '2086',
        timezone: '10',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Ballera Airport',
        city: 'Ballera',
        country: 'Australia',
        iataCode: 'N',
        icaoCode: 'YLLE',
        latitude: '-27,405633',
        longitude: '141,809458',
        altitude: '385',
        timezone: '10',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Gatton Campus Airport',
        city: 'Gatton',
        country: 'Australia',
        iataCode: 'N',
        icaoCode: 'YGAT',
        latitude: '-27,56040607',
        longitude: '152,3404598',
        altitude: '0',
        timezone: '10',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Arkalyk North Airport',
        city: 'Arkalyk',
        country: 'Kazakhstan',
        iataCode: 'AYK',
        icaoCode: 'UAUR',
        latitude: '50,3185997',
        longitude: '66,95279694',
        altitude: '1266',
        timezone: '6',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Nabern/Teck Airport',
        city: 'Kirchheim-Teck',
        country: 'Germany',
        iataCode: 'N',
        icaoCode: 'EDTN',
        latitude: '48,61277771',
        longitude: '9,477222443',
        altitude: '1214',
        timezone: '1',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Angoon Seaplane Base',
        city: 'Angoon',
        country: 'United States',
        iataCode: 'AGN',
        icaoCode: 'PAGN',
        latitude: '57,503601',
        longitude: '-134,585007',
        altitude: '0',
        timezone: '-9',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Elfin Cove Seaplane Base',
        city: 'Elfin Cove',
        country: 'United States',
        iataCode: 'ELV',
        icaoCode: 'PAEL',
        latitude: '58,19520187',
        longitude: '-136,3470001',
        altitude: '0',
        timezone: '-9',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Funter Bay Seaplane Base',
        city: 'Funter Bay',
        country: 'United States',
        iataCode: 'FNR',
        icaoCode: 'PANR',
        latitude: '58,25439835',
        longitude: '-134,897995',
        altitude: '0',
        timezone: '-9',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Hoonah Airport',
        city: 'Hoonah',
        country: 'United States',
        iataCode: 'HNH',
        icaoCode: 'PAOH',
        latitude: '58,0961',
        longitude: '-135,410111',
        altitude: '19',
        timezone: '-9',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Kake Airport',
        city: 'Kake',
        country: 'United States',
        iataCode: 'AFE',
        icaoCode: 'PAFE',
        latitude: '56,96139908',
        longitude: '-133,9100037',
        altitude: '172',
        timezone: '-9',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Metlakatla Seaplane Base',
        city: 'Metakatla',
        country: 'United States',
        iataCode: 'MTM',
        icaoCode: 'PAMM',
        latitude: '55,13100052',
        longitude: '-131,5780029',
        altitude: '0',
        timezone: '-9',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Hydaburg Seaplane Base',
        city: 'Hydaburg',
        country: 'United States',
        iataCode: 'HYG',
        icaoCode: 'PAHY',
        latitude: '55,20629883',
        longitude: '-132,8280029',
        altitude: '0',
        timezone: '-9',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Egegik Airport',
        city: 'Egegik',
        country: 'United States',
        iataCode: 'EGX',
        icaoCode: 'PAII',
        latitude: '58,1855011',
        longitude: '-157,375',
        altitude: '92',
        timezone: '-9',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Perryville Airport',
        city: 'Perryville',
        country: 'United States',
        iataCode: 'KPV',
        icaoCode: 'PAPE',
        latitude: '55,905998',
        longitude: '-159,162993',
        altitude: '29',
        timezone: '-9',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Pilot Point Airport',
        city: 'Pilot Point',
        country: 'United States',
        iataCode: 'PIP',
        icaoCode: 'PAPN',
        latitude: '57,58039856',
        longitude: '-157,5720062',
        altitude: '57',
        timezone: '-9',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'South Naknek Nr 2 Airport',
        city: 'South Naknek',
        country: 'United States',
        iataCode: 'WSN',
        icaoCode: 'PFWS',
        latitude: '58,70339966',
        longitude: '-157,0079956',
        altitude: '162',
        timezone: '-9',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Akhiok Airport',
        city: 'Akhiok',
        country: 'United States',
        iataCode: 'AKK',
        icaoCode: 'PAKH',
        latitude: '56,93870163',
        longitude: '-154,1829987',
        altitude: '44',
        timezone: '-9',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Karluk Airport',
        city: 'Karluk',
        country: 'United States',
        iataCode: 'KYK',
        icaoCode: 'PAKY',
        latitude: '57,56710052',
        longitude: '-154,4499969',
        altitude: '137',
        timezone: '-9',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Larsen Bay Airport',
        city: 'Larsen Bay',
        country: 'United States',
        iataCode: 'KLN',
        icaoCode: 'PALB',
        latitude: '57,53509903',
        longitude: '-153,9779968',
        altitude: '87',
        timezone: '-9',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Ambler Airport',
        city: 'Ambler',
        country: 'United States',
        iataCode: 'ABL',
        icaoCode: 'PAFM',
        latitude: '67,10630035',
        longitude: '-157,8569946',
        altitude: '334',
        timezone: '-9',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Buckland Airport',
        city: 'Buckland',
        country: 'United States',
        iataCode: 'BKC',
        icaoCode: 'PABL',
        latitude: '65,9815979',
        longitude: '-161,1490021',
        altitude: '31',
        timezone: '-9',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Bob Baker Memorial Airport',
        city: 'Kiana',
        country: 'United States',
        iataCode: 'IAN',
        icaoCode: 'PAIK',
        latitude: '66,97599792',
        longitude: '-160,4369965',
        altitude: '166',
        timezone: '-9',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Kobuk Airport',
        city: 'Kobuk',
        country: 'United States',
        iataCode: 'OBU',
        icaoCode: 'PAOB',
        latitude: '66,91230011',
        longitude: '-156,8970032',
        altitude: '137',
        timezone: '-9',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Robert (Bob) Curtis Memorial Airport',
        city: 'Noorvik',
        country: 'United States',
        iataCode: 'ORV',
        icaoCode: 'PFNO',
        latitude: '66,81790161',
        longitude: '-161,0189972',
        altitude: '55',
        timezone: '-9',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Selawik Airport',
        city: 'Selawik',
        country: 'United States',
        iataCode: 'WLK',
        icaoCode: 'PASK',
        latitude: '66,60009766',
        longitude: '-159,9859924',
        altitude: '17',
        timezone: '-9',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Brevig Mission Airport',
        city: 'Brevig Mission',
        country: 'United States',
        iataCode: 'KTS',
        icaoCode: 'PFKT',
        latitude: '65,33129883',
        longitude: '-166,4660034',
        altitude: '38',
        timezone: '-9',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Elim Airport',
        city: 'Elim',
        country: 'United States',
        iataCode: 'ELI',
        icaoCode: 'PFEL',
        latitude: '64,61470032',
        longitude: '-162,2720032',
        altitude: '162',
        timezone: '-9',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Golovin Airport',
        city: 'Golovin',
        country: 'United States',
        iataCode: 'GLV',
        icaoCode: 'PAGL',
        latitude: '64,55049896',
        longitude: '-163,0070038',
        altitude: '59',
        timezone: '-9',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Teller Airport',
        city: 'Teller',
        country: 'United States',
        iataCode: 'TLA',
        icaoCode: 'PATE',
        latitude: '65,24040222',
        longitude: '-166,3390045',
        altitude: '294',
        timezone: '-9',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Wales Airport',
        city: 'Wales',
        country: 'United States',
        iataCode: 'WAA',
        icaoCode: 'PAIW',
        latitude: '65,622593',
        longitude: '-168,095',
        altitude: '22',
        timezone: '-9',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'White Mountain Airport',
        city: 'White Mountain',
        country: 'United States',
        iataCode: 'WMO',
        icaoCode: 'PAWM',
        latitude: '64,68920136',
        longitude: '-163,4129944',
        altitude: '267',
        timezone: '-9',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Koyuk Alfred Adams Airport',
        city: 'Koyuk',
        country: 'United States',
        iataCode: 'KKA',
        icaoCode: 'PAKK',
        latitude: '64,9394989',
        longitude: '-161,154007',
        altitude: '154',
        timezone: '-9',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'St Michael Airport',
        city: 'St. Michael',
        country: 'United States',
        iataCode: 'SMK',
        icaoCode: 'PAMK',
        latitude: '63,49010086',
        longitude: '-162,1100006',
        altitude: '98',
        timezone: '-9',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Shaktoolik Airport',
        city: 'Shaktoolik',
        country: 'United States',
        iataCode: 'SKK',
        icaoCode: 'PFSH',
        latitude: '64,37110138',
        longitude: '-161,223999',
        altitude: '24',
        timezone: '-9',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Tin City Long Range Radar Station Airport',
        city: 'Tin City',
        country: 'United States',
        iataCode: 'TNC',
        icaoCode: 'PATC',
        latitude: '65,56310272',
        longitude: '-167,9219971',
        altitude: '271',
        timezone: '-9',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Atka Airport',
        city: 'Atka',
        country: 'United States',
        iataCode: 'AKB',
        icaoCode: 'PAAK',
        latitude: '52,22029877',
        longitude: '-174,2059937',
        altitude: '57',
        timezone: '-10',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Yakataga Airport',
        city: 'Yakataga',
        country: 'United States',
        iataCode: 'CYT',
        icaoCode: 'PACY',
        latitude: '60,08200073',
        longitude: '-142,4929962',
        altitude: '12',
        timezone: '-9',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Alakanuk Airport',
        city: 'Alakanuk',
        country: 'United States',
        iataCode: 'AUK',
        icaoCode: 'PAUK',
        latitude: '62,68004227',
        longitude: '-164,6599274',
        altitude: '10',
        timezone: '-9',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Kipnuk Airport',
        city: 'Kipnuk',
        country: 'United States',
        iataCode: 'KPN',
        icaoCode: 'PAKI',
        latitude: '59,93299866',
        longitude: '-164,0310059',
        altitude: '11',
        timezone: '-9',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'False Pass Airport',
        city: 'False Pass',
        country: 'United States',
        iataCode: 'KFP',
        icaoCode: 'PAKF',
        latitude: '54,84740067',
        longitude: '-163,4100037',
        altitude: '20',
        timezone: '-9',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Nelson Lagoon Airport',
        city: 'Nelson Lagoon',
        country: 'United States',
        iataCode: 'NLG',
        icaoCode: 'PAOU',
        latitude: '56,00749969',
        longitude: '-161,1600037',
        altitude: '14',
        timezone: '-9',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Port Moller Airport',
        city: 'Cold Bay',
        country: 'United States',
        iataCode: 'PML',
        icaoCode: 'PAAL',
        latitude: '56,00600052',
        longitude: '-160,5610046',
        altitude: '20',
        timezone: '-9',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Klawock Airport',
        city: 'Klawock',
        country: 'United States',
        iataCode: 'KLW',
        icaoCode: 'PAKW',
        latitude: '55,57920074',
        longitude: '-133,076004',
        altitude: '80',
        timezone: '-9',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Quinhagak Airport',
        city: 'Quinhagak',
        country: 'United States',
        iataCode: 'KWN',
        icaoCode: 'PAQH',
        latitude: '59,75510025',
        longitude: '-161,8450012',
        altitude: '42',
        timezone: '-9',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Kotlik Airport',
        city: 'Kotlik',
        country: 'United States',
        iataCode: 'KOT',
        icaoCode: 'PFKO',
        latitude: '63,0306015',
        longitude: '-163,5330048',
        altitude: '15',
        timezone: '-9',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Koyukuk Airport',
        city: 'Koyukuk',
        country: 'United States',
        iataCode: 'KYU',
        icaoCode: 'PFKU',
        latitude: '64,87609863',
        longitude: '-157,727005',
        altitude: '149',
        timezone: '-9',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Scammon Bay Airport',
        city: 'Scammon Bay',
        country: 'United States',
        iataCode: 'SCM',
        icaoCode: 'PACM',
        latitude: '61,84529877',
        longitude: '-165,5709991',
        altitude: '14',
        timezone: '-9',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Nondalton Airport',
        city: 'Nondalton',
        country: 'United States',
        iataCode: 'NNL',
        icaoCode: 'PANO',
        latitude: '59,98020172',
        longitude: '-154,8390045',
        altitude: '314',
        timezone: '-9',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Kongiganak Airport',
        city: 'Kongiganak',
        country: 'United States',
        iataCode: 'KKH',
        icaoCode: 'PADY',
        latitude: '59,96080017',
        longitude: '-162,8809967',
        altitude: '30',
        timezone: '-9',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Nikolai Airport',
        city: 'Nikolai',
        country: 'United States',
        iataCode: 'NIB',
        icaoCode: 'PAFS',
        latitude: '63,01860046',
        longitude: '-154,3580017',
        altitude: '441',
        timezone: '-9',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Akiak Airport',
        city: 'Akiak',
        country: 'United States',
        iataCode: 'AKI',
        icaoCode: 'PFAK',
        latitude: '60,9029007',
        longitude: '-161,2310028',
        altitude: '30',
        timezone: '-9',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Wainwright Airport',
        city: 'Wainwright',
        country: 'United States',
        iataCode: 'AIN',
        icaoCode: 'PAWI',
        latitude: '70,63800049',
        longitude: '-159,9949951',
        altitude: '41',
        timezone: '-9',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Zapala Airport',
        city: 'ZAPALA',
        country: 'Argentina',
        iataCode: 'APZ',
        icaoCode: 'SAHZ',
        latitude: '-38,975498',
        longitude: '-70,113602',
        altitude: '3330',
        timezone: '-3',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Rincon De Los Sauces Airport',
        city: 'Rincon de los Sauces',
        country: 'Argentina',
        iataCode: 'RDS',
        icaoCode: 'SAHS',
        latitude: '-37,3905983',
        longitude: '-68,90419769',
        altitude: '1968',
        timezone: '-3',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Tte. Julio Gallardo Airport',
        city: 'Puerto Natales',
        country: 'Chile',
        iataCode: 'PNT',
        icaoCode: 'SCNT',
        latitude: '-51,67150116',
        longitude: '-72,52839661',
        altitude: '217',
        timezone: '-4',
        dst: 'S',
        type: 'airport',
    },
    {
        name: 'Sierra Grande Airport',
        city: 'Sierra Grande',
        country: 'Argentina',
        iataCode: 'SGV',
        icaoCode: 'SAVS',
        latitude: '-41,59170151',
        longitude: '-65,33940125',
        altitude: '688',
        timezone: '-3',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Cabo F.A.A. H. R. Bordón Airport',
        city: 'Ingeniero Jacobacci',
        country: 'Argentina',
        iataCode: 'IGB',
        icaoCode: 'SAVJ',
        latitude: '-41,32089996',
        longitude: '-69,57489777',
        altitude: '2925',
        timezone: '-3',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Chenega Bay Airport',
        city: 'Chenega',
        country: 'United States',
        iataCode: 'NCN',
        icaoCode: 'PFCB',
        latitude: '60,07730103',
        longitude: '-147,9920044',
        altitude: '72',
        timezone: '-9',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Tok Junction Airport',
        city: 'Tok',
        country: 'United States',
        iataCode: 'TKJ',
        icaoCode: 'PFTO',
        latitude: '63,32949829',
        longitude: '-142,9539948',
        altitude: '1639',
        timezone: '-9',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Circle City /New/ Airport',
        city: 'Circle',
        country: 'United States',
        iataCode: 'IRC',
        icaoCode: 'PACR',
        latitude: '65,830498',
        longitude: '-144,076008',
        altitude: '613',
        timezone: '-9',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Sleetmute Airport',
        city: 'Sleetmute',
        country: 'United States',
        iataCode: 'SLQ',
        icaoCode: 'PASL',
        latitude: '61,70050049',
        longitude: '-157,1660004',
        altitude: '190',
        timezone: '-9',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Healy River Airport',
        city: 'Healy',
        country: 'United States',
        iataCode: 'HKB',
        icaoCode: 'PAHV',
        latitude: '63,86619949',
        longitude: '-148,9689941',
        altitude: '1263',
        timezone: '-9',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Klawock Seaplane Base',
        city: 'Klawock',
        country: 'United States',
        iataCode: 'AQC',
        icaoCode: 'PAQC',
        latitude: '55,55469894',
        longitude: '-133,102005',
        altitude: '0',
        timezone: '-9',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Minchumina Airport',
        city: 'Lake Minchumina',
        country: 'United States',
        iataCode: 'MHM',
        icaoCode: 'PAMH',
        latitude: '63,88600159',
        longitude: '-152,302002',
        altitude: '678',
        timezone: '-9',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Manley Hot Springs Airport',
        city: 'Manley Hot Springs',
        country: 'United States',
        iataCode: 'MLY',
        icaoCode: 'PAML',
        latitude: '64,99759674',
        longitude: '-150,6439972',
        altitude: '270',
        timezone: '-9',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Natuashish Airport',
        city: 'Natuashish',
        country: 'Canada',
        iataCode: 'N',
        icaoCode: 'CNH2',
        latitude: '55,913898',
        longitude: '-61,184399',
        altitude: '30',
        timezone: '-4',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Postville Airport',
        city: 'Postville',
        country: 'Canada',
        iataCode: 'YSO',
        icaoCode: 'CCD4',
        latitude: '54,9105',
        longitude: '-59,78507',
        altitude: '193',
        timezone: '-4',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Kangiqsujuaq (Wakeham Bay) Airport',
        city: 'Kangiqsujuaq',
        country: 'Canada',
        iataCode: 'YWB',
        icaoCode: 'CYKG',
        latitude: '61,58860016',
        longitude: '-71,92939758',
        altitude: '501',
        timezone: '-5',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Alma Airport',
        city: 'Alma',
        country: 'Canada',
        iataCode: 'YTF',
        icaoCode: 'CYTF',
        latitude: '48,50889969',
        longitude: '-71,64189911',
        altitude: '445',
        timezone: '-5',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Havre St Pierre Airport',
        city: 'Havre-Saint-Pierre',
        country: 'Canada',
        iataCode: 'YGV',
        icaoCode: 'CYGV',
        latitude: '50,2818985',
        longitude: '-63,6114006',
        altitude: '124',
        timezone: '-5',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Rimouski Airport',
        city: 'Rimouski',
        country: 'Canada',
        iataCode: 'YXK',
        icaoCode: 'CYXK',
        latitude: '48,47809982',
        longitude: '-68,49690247',
        altitude: '82',
        timezone: '-5',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Tadoule Lake Airport',
        city: 'Tadoule Lake',
        country: 'Canada',
        iataCode: 'XTL',
        icaoCode: 'CYBQ',
        latitude: '58,7061',
        longitude: '-98,512199',
        altitude: '923',
        timezone: '-6',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Lac Brochet Airport',
        city: 'Lac Brochet',
        country: 'Canada',
        iataCode: 'XLB',
        icaoCode: 'CZWH',
        latitude: '58,61750031',
        longitude: '-101,4690018',
        altitude: '1211',
        timezone: '-6',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'South Indian Lake Airport',
        city: 'South Indian Lake',
        country: 'Canada',
        iataCode: 'XSI',
        icaoCode: 'CZSN',
        latitude: '56,7928009',
        longitude: '-98,90720367',
        altitude: '951',
        timezone: '-6',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Brochet Airport',
        city: 'Brochet',
        country: 'Canada',
        iataCode: 'YBT',
        icaoCode: 'CYBT',
        latitude: '57,8894',
        longitude: '-101,679001',
        altitude: '1136',
        timezone: '-6',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Little Grand Rapids Airport',
        city: 'Little Grand Rapids',
        country: 'Canada',
        iataCode: 'ZGR',
        icaoCode: 'CZGR',
        latitude: '52,04560089',
        longitude: '-95,46579742',
        altitude: '1005',
        timezone: '-6',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Cross Lake (Charlie Sinclair Memorial) Airport',
        city: 'Cross Lake',
        country: 'Canada',
        iataCode: 'YCR',
        icaoCode: 'CYCR',
        latitude: '54,61059952',
        longitude: '-97,76080322',
        altitude: '709',
        timezone: '-6',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Red Sucker Lake Airport',
        city: 'Red Sucker Lake',
        country: 'Canada',
        iataCode: 'YRS',
        icaoCode: 'CYRS',
        latitude: '54,16719818',
        longitude: '-93,55719757',
        altitude: '729',
        timezone: '-6',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Rainbow Lake Airport',
        city: 'Rainbow Lake',
        country: 'Canada',
        iataCode: 'YOP',
        icaoCode: 'CYOP',
        latitude: '58,49140167',
        longitude: '-119,4079971',
        altitude: '1759',
        timezone: '-7',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Bonnyville Airport',
        city: 'Bonnyville',
        country: 'Canada',
        iataCode: 'YBY',
        icaoCode: 'CYBF',
        latitude: '54,304199',
        longitude: '-110,744003',
        altitude: '1836',
        timezone: '-7',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Nanaimo Harbour Water Airport',
        city: 'Nanaimo',
        country: 'Canada',
        iataCode: 'ZNA',
        icaoCode: 'CAC8',
        latitude: '49,18330002',
        longitude: '-123,9499969',
        altitude: '0',
        timezone: '-8',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Ganges Seaplane Base',
        city: 'Ganges',
        country: 'Canada',
        iataCode: 'YGG',
        icaoCode: 'CAX6',
        latitude: '48,8545',
        longitude: '-123,4969',
        altitude: '0',
        timezone: '-8',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Fort St James Airport',
        city: 'Fort St. James',
        country: 'Canada',
        iataCode: 'YJM',
        icaoCode: 'CYJM',
        latitude: '54,39720154',
        longitude: '-124,2630005',
        altitude: '2364',
        timezone: '-8',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Boundary Bay Airport',
        city: 'Boundary Bay',
        country: 'Canada',
        iataCode: 'YDT',
        icaoCode: 'CZBB',
        latitude: '49,07419968',
        longitude: '-123,012001',
        altitude: '6',
        timezone: '-8',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Langley Airport',
        city: 'Langley Township',
        country: 'Canada',
        iataCode: 'N',
        icaoCode: 'CYNJ',
        latitude: '49,10079956',
        longitude: '-122,6309967',
        altitude: '34',
        timezone: '-8',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Denny Island Airport',
        city: 'Bella Bella',
        country: 'Canada',
        iataCode: 'ZEL',
        icaoCode: 'CYJQ',
        latitude: '52,139702',
        longitude: '-128,063997',
        altitude: '162',
        timezone: '-8',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Wekweètì Airport',
        city: 'Wekweeti',
        country: 'Canada',
        iataCode: 'YFJ',
        icaoCode: 'CFJ2',
        latitude: '64,190804',
        longitude: '-114,077002',
        altitude: '1208',
        timezone: '-7',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Camaguey-Campo Cuatro Milpas Airport',
        city: 'Guasave',
        country: 'Mexico',
        iataCode: 'N',
        icaoCode: 'MM52',
        latitude: '25,6522007',
        longitude: '-108,538002',
        altitude: '92',
        timezone: '-7',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Cabo San Lucas International Airport',
        city: 'Cabo San Lucas',
        country: 'Mexico',
        iataCode: 'N',
        icaoCode: 'MMSL',
        latitude: '22,9477005',
        longitude: '-109,9369965',
        altitude: '459',
        timezone: '-7',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Corn Island',
        city: 'Corn Island',
        country: 'Nicaragua',
        iataCode: 'RNI',
        icaoCode: 'MNCI',
        latitude: '12,16289997',
        longitude: '-83,063797',
        altitude: '1',
        timezone: '-6',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'San Pedro Airport',
        city: 'Bonanza',
        country: 'Nicaragua',
        iataCode: 'BZA',
        icaoCode: 'MNBZ',
        latitude: '13,94999981',
        longitude: '-84,59999847',
        altitude: '600',
        timezone: '-6',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Rosita Airport',
        city: 'Rosita',
        country: 'Nicaragua',
        iataCode: 'RFS',
        icaoCode: 'MNRT',
        latitude: '13,88969994',
        longitude: '-84,4088974',
        altitude: '193',
        timezone: '-6',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Siuna',
        city: 'Siuna',
        country: 'Nicaragua',
        iataCode: 'SIU',
        icaoCode: 'MNSI',
        latitude: '13,72722244',
        longitude: '-84,77777863',
        altitude: '606',
        timezone: '-6',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Waspam Airport',
        city: 'Waspam',
        country: 'Nicaragua',
        iataCode: 'WSP',
        icaoCode: 'MNWP',
        latitude: '14,73919964',
        longitude: '-83,9693985',
        altitude: '98',
        timezone: '-6',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'San Carlos',
        city: 'San Carlos',
        country: 'Nicaragua',
        iataCode: 'N',
        icaoCode: 'MNSC',
        latitude: '11,13339996',
        longitude: '-84,76999664',
        altitude: '91',
        timezone: '-6',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Playa Samara/Carrillo Airport',
        city: 'Carrillo',
        country: 'Costa Rica',
        iataCode: 'PLD',
        icaoCode: 'MRCR',
        latitude: '9,870510101',
        longitude: '-85,48139954',
        altitude: '50',
        timezone: '-6',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Constanza - Expedición 14 de Junio National Airport',
        city: 'Constanza',
        country: 'Dominican Republic',
        iataCode: 'COZ',
        icaoCode: 'MDCZ',
        latitude: '18,90749931',
        longitude: '-70,72190094',
        altitude: '3950',
        timezone: '-4',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Negril Airport',
        city: 'Negril',
        country: 'Jamaica',
        iataCode: 'NEG',
        icaoCode: 'MKNG',
        latitude: '18,34280014',
        longitude: '-78,33209991',
        altitude: '9',
        timezone: '-5',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'José Aponte de la Torre Airport',
        city: 'Ceiba',
        country: 'Puerto Rico',
        iataCode: 'RVR',
        icaoCode: 'TJRV',
        latitude: '18,24530029',
        longitude: '-65,6434021',
        altitude: '38',
        timezone: '-4',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Charlotte Amalie Harbor Seaplane Base',
        city: 'Charlotte Amalie',
        country: 'Virgin Islands',
        iataCode: 'SPB',
        icaoCode: 'VI22',
        latitude: '18,33860016',
        longitude: '-64,94069672',
        altitude: '0',
        timezone: '-4',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'D. Casimiro Szlapelis Airport',
        city: 'Alto Rio Senguer',
        country: 'Argentina',
        iataCode: 'ARR',
        icaoCode: 'SAVR',
        latitude: '-45,013599',
        longitude: '-70,812202',
        altitude: '2286',
        timezone: '-3',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Jose De San Martin Airport',
        city: 'Jose de San Martin',
        country: 'Argentina',
        iataCode: 'JSM',
        icaoCode: 'SAWS',
        latitude: '-44,04859924',
        longitude: '-70,45890045',
        altitude: '2407',
        timezone: '-3',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Uyuni Airport',
        city: 'Uyuni',
        country: 'Bolivia',
        iataCode: 'UYU',
        icaoCode: 'SLUY',
        latitude: '-20,44630051',
        longitude: '-66,8483963',
        altitude: '11136',
        timezone: '-4',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Abaiang Airport',
        city: 'Abaiang Atoll',
        country: 'Kiribati',
        iataCode: 'ABF',
        icaoCode: 'NGAB',
        latitude: '1,798609972',
        longitude: '173,0410004',
        altitude: '0',
        timezone: '12',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Afobakka Airstrip',
        city: 'Afobaka',
        country: 'Suriname',
        iataCode: 'N',
        icaoCode: 'SMAF',
        latitude: '4,998505',
        longitude: '-54,992033',
        altitude: '80',
        timezone: '-3',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Alalapadu Airstrip',
        city: 'Alapadu',
        country: 'Suriname',
        iataCode: 'N',
        icaoCode: 'SMDU',
        latitude: '2,5235',
        longitude: '-56,3247',
        altitude: '880',
        timezone: '-3',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Albina Airport',
        city: 'Albina',
        country: 'Suriname',
        iataCode: 'ABN',
        icaoCode: 'SMBN',
        latitude: '5,512720108',
        longitude: '-54,05009842',
        altitude: '19',
        timezone: '-3',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Drietabbetje Airport',
        city: 'Drietabbetje',
        country: 'Suriname',
        iataCode: 'DRJ',
        icaoCode: 'SMDA',
        latitude: '4,116666794',
        longitude: '-54,66667175',
        altitude: '236',
        timezone: '-3',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Kabalebo Airport',
        city: 'Kabalebo',
        country: 'Suriname',
        iataCode: 'N',
        icaoCode: 'SMKA',
        latitude: '4,406000137',
        longitude: '-57,22299957',
        altitude: '535',
        timezone: '-3',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Kayser Airport',
        city: 'Kayser',
        country: 'Suriname',
        iataCode: 'N',
        icaoCode: 'SMKE',
        latitude: '3,099999905',
        longitude: '-56,48300171',
        altitude: '849',
        timezone: '-3',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Kwamalasoemoetoe Airport Airport',
        city: 'Kwamelasemoetoe',
        country: 'Suriname',
        iataCode: 'N',
        icaoCode: 'SMSM',
        latitude: '2,333329916',
        longitude: '-56,78333282',
        altitude: '905',
        timezone: '-3',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Moengo Airstrip',
        city: 'Moengo',
        country: 'Suriname',
        iataCode: 'N',
        icaoCode: 'SMMO',
        latitude: '5,6076',
        longitude: '-54,4003',
        altitude: '49',
        timezone: '-3',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Nieuw Nickerie Airport',
        city: 'Nieuw Nickerie',
        country: 'Suriname',
        iataCode: 'ICK',
        icaoCode: 'SMNI',
        latitude: '5,955560207',
        longitude: '-57,03939819',
        altitude: '9',
        timezone: '-3',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Vincent Fayks Airport',
        city: 'Paloemeu',
        country: 'Suriname',
        iataCode: 'OEM',
        icaoCode: 'SMPA',
        latitude: '3,345279932',
        longitude: '-55,44250107',
        altitude: '714',
        timezone: '-3',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Sipaliwini Airport',
        city: 'Sipaliwini',
        country: 'Suriname',
        iataCode: 'N',
        icaoCode: 'SMSI',
        latitude: '1,966050029',
        longitude: '-56,00350189',
        altitude: '744',
        timezone: '-3',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Stoelmanseiland Airport',
        city: 'Stoelmans Eiland',
        country: 'Suriname',
        iataCode: 'SMZ',
        icaoCode: 'SMST',
        latitude: '4,349999905',
        longitude: '-54,41666794',
        altitude: '187',
        timezone: '-3',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Totness Airport',
        city: 'Totness',
        country: 'Suriname',
        iataCode: 'TOT',
        icaoCode: 'SMCO',
        latitude: '5,865829945',
        longitude: '-56,32749939',
        altitude: '10',
        timezone: '-3',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Wageningen Airstrip',
        city: 'Wageningen',
        country: 'Suriname',
        iataCode: 'AGI',
        icaoCode: 'SMWA',
        latitude: '5,766666889',
        longitude: '-56,6333313',
        altitude: '6',
        timezone: '-3',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Codela Airport',
        city: 'Guapiles',
        country: 'Costa Rica',
        iataCode: 'CSC',
        icaoCode: 'MRCA',
        latitude: '10,41399956',
        longitude: '-85,09169769',
        altitude: '328',
        timezone: '-6',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Orinduik Airport',
        city: 'Orinduik',
        country: 'Guyana',
        iataCode: 'ORJ',
        icaoCode: 'SYOR',
        latitude: '4,725269794',
        longitude: '-60,03499985',
        altitude: '1797',
        timezone: '-4',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Annai Airport',
        city: 'Annai',
        country: 'Guyana',
        iataCode: 'NAI',
        icaoCode: 'SYAN',
        latitude: '3,959439993',
        longitude: '-59,12419891',
        altitude: '301',
        timezone: '-4',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Apoteri Airport',
        city: 'Apoteri',
        country: 'Guyana',
        iataCode: 'N',
        icaoCode: 'SYAP',
        latitude: '4,013879776',
        longitude: '-58,60499954',
        altitude: '301',
        timezone: '-4',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Imbaimadai Airport',
        city: 'Imbaimadai',
        country: 'Guyana',
        iataCode: 'IMB',
        icaoCode: 'SYIB',
        latitude: '5,708109856',
        longitude: '-60,2942009',
        altitude: '1646',
        timezone: '-4',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Kamarang Airport',
        city: 'Kamarang',
        country: 'Guyana',
        iataCode: 'KAR',
        icaoCode: 'SYKM',
        latitude: '5,865340233',
        longitude: '-60,61420059',
        altitude: '1601',
        timezone: '-4',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Mabaruma Airport',
        city: 'Mabaruma',
        country: 'Guyana',
        iataCode: 'USI',
        icaoCode: 'SYMB',
        latitude: '8,199999809',
        longitude: '-59,78329849',
        altitude: '45',
        timezone: '-4',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Mahdia Airport',
        city: 'Mahdia',
        country: 'Guyana',
        iataCode: 'MHA',
        icaoCode: 'SYMD',
        latitude: '5,277490139',
        longitude: '-59,15110016',
        altitude: '300',
        timezone: '-4',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Dr Augusto Roberto Fuster International Airport',
        city: 'Pedro Juan Caballero',
        country: 'Paraguay',
        iataCode: 'PJC',
        icaoCode: 'SGPJ',
        latitude: '-22,63999939',
        longitude: '-55,83000183',
        altitude: '1873',
        timezone: '-4',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Alcides Fernández Airport',
        city: 'Acandi',
        country: 'Colombia',
        iataCode: 'ACD',
        icaoCode: 'SKAD',
        latitude: '8,51667',
        longitude: '-77,3',
        altitude: '50',
        timezone: '-5',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Los Colonizadores Airport',
        city: 'Saravena',
        country: 'Colombia',
        iataCode: 'RVE',
        icaoCode: 'SKSA',
        latitude: '6,951868',
        longitude: '-71,857179',
        altitude: '680',
        timezone: '-5',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Batagay Airport',
        city: 'Batagay',
        country: 'Russia',
        iataCode: 'N',
        icaoCode: 'UEBB',
        latitude: '67,64800262',
        longitude: '134,6950073',
        altitude: '696',
        timezone: '10',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Villa Garzón Airport',
        city: 'Villa Garzon',
        country: 'Colombia',
        iataCode: 'VGZ',
        icaoCode: 'SKVG',
        latitude: '0,978767',
        longitude: '-76,6056',
        altitude: '1248',
        timezone: '-5',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'El Bagre Airport',
        city: 'El Bagre',
        country: 'Colombia',
        iataCode: 'EBG',
        icaoCode: 'SKEB',
        latitude: '7,59647',
        longitude: '-74,8089',
        altitude: '180',
        timezone: '-5',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Juan H White Airport',
        city: 'Caucasia',
        country: 'Colombia',
        iataCode: 'CAQ',
        icaoCode: 'SKCU',
        latitude: '7,96847',
        longitude: '-75,1985',
        altitude: '174',
        timezone: '-5',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Mandinga Airport',
        city: 'Condoto',
        country: 'Colombia',
        iataCode: 'COG',
        icaoCode: 'SKCD',
        latitude: '5,08333',
        longitude: '-76,7',
        altitude: '213',
        timezone: '-5',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Golfo de Morrosquillo Airport',
        city: 'Tolu',
        country: 'Colombia',
        iataCode: 'TLU',
        icaoCode: 'SKTL',
        latitude: '9,50945',
        longitude: '-75,5854',
        altitude: '16',
        timezone: '-5',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Cabo Frio Airport',
        city: 'Cabo Frio',
        country: 'Brazil',
        iataCode: 'CFB',
        icaoCode: 'SBCB',
        latitude: '-22,92169952',
        longitude: '-42,07429886',
        altitude: '23',
        timezone: '-3',
        dst: 'S',
        type: 'airport',
    },
    {
        name: 'Presidente João Batista Figueiredo Airport',
        city: 'Sinop',
        country: 'Brazil',
        iataCode: 'OPS',
        icaoCode: 'SWSI',
        latitude: '-11,88500023',
        longitude: '-55,58610916',
        altitude: '1227',
        timezone: '-4',
        dst: 'S',
        type: 'airport',
    },
    {
        name: 'Gurupi Airport',
        city: 'Gurupi',
        country: 'Brazil',
        iataCode: 'GRP',
        icaoCode: 'SWGI',
        latitude: '-11,73960018',
        longitude: '-49,13219833',
        altitude: '1148',
        timezone: '-3',
        dst: 'S',
        type: 'airport',
    },
    {
        name: 'Santana do Araguaia Airport',
        city: 'Santana do Araguaia',
        country: 'Brazil',
        iataCode: 'CMP',
        icaoCode: 'SNKE',
        latitude: '-9,319970131',
        longitude: '-50,32849884',
        altitude: '597',
        timezone: '-3',
        dst: 'S',
        type: 'airport',
    },
    {
        name: 'Breves Airport',
        city: 'Breves',
        country: 'Brazil',
        iataCode: 'BVS',
        icaoCode: 'SNVS',
        latitude: '-1,636530042',
        longitude: '-50,4435997',
        altitude: '98',
        timezone: '-3',
        dst: 'S',
        type: 'airport',
    },
    {
        name: 'Soure Airport',
        city: 'Soure',
        country: 'Brazil',
        iataCode: 'SFK',
        icaoCode: 'SNSW',
        latitude: '-0,699431002',
        longitude: '-48,52099991',
        altitude: '43',
        timezone: '-3',
        dst: 'S',
        type: 'airport',
    },
    {
        name: 'Parintins Airport',
        city: 'Parintins',
        country: 'Brazil',
        iataCode: 'PIN',
        icaoCode: 'SWPI',
        latitude: '-2,673019886',
        longitude: '-56,77719879',
        altitude: '87',
        timezone: '-4',
        dst: 'S',
        type: 'airport',
    },
    {
        name: 'Barreiras Airport',
        city: 'Barreiras',
        country: 'Brazil',
        iataCode: 'BRA',
        icaoCode: 'SNBR',
        latitude: '-12,07890034',
        longitude: '-45,00899887',
        altitude: '2447',
        timezone: '-3',
        dst: 'S',
        type: 'airport',
    },
    {
        name: 'Santa Terezinha Airport',
        city: 'Santa Terezinha',
        country: 'Brazil',
        iataCode: 'STZ',
        icaoCode: 'SWST',
        latitude: '-10,46472168',
        longitude: '-50,51861191',
        altitude: '663',
        timezone: '-4',
        dst: 'S',
        type: 'airport',
    },
    {
        name: 'Minaçu Airport',
        city: 'Minacu',
        country: 'Brazil',
        iataCode: 'MQH',
        icaoCode: 'SBMC',
        latitude: '-13,5491',
        longitude: '-48,195301',
        altitude: '1401',
        timezone: '-3',
        dst: 'S',
        type: 'airport',
    },
    {
        name: 'Araguaína Airport',
        city: 'Araguaina',
        country: 'Brazil',
        iataCode: 'AUX',
        icaoCode: 'SWGN',
        latitude: '-7,22787',
        longitude: '-48,240501',
        altitude: '771',
        timezone: '-3',
        dst: 'S',
        type: 'airport',
    },
    {
        name: 'Novo Aripuanã Airport',
        city: 'Novo Aripuana',
        country: 'Brazil',
        iataCode: 'NVP',
        icaoCode: 'SWNA',
        latitude: '-5,118030071',
        longitude: '-60,36489868',
        altitude: '118',
        timezone: '-4',
        dst: 'S',
        type: 'airport',
    },
    {
        name: 'Fazenda Colen Airport',
        city: 'Lucas do Rio Verde',
        country: 'Brazil',
        iataCode: 'LVR',
        icaoCode: 'SWFE',
        latitude: '-13,31444359',
        longitude: '-56,11277771',
        altitude: '1345',
        timezone: '-4',
        dst: 'S',
        type: 'airport',
    },
    {
        name: 'Franca Airport',
        city: 'Franca',
        country: 'Brazil',
        iataCode: 'FRC',
        icaoCode: 'SIMK',
        latitude: '-20,59219933',
        longitude: '-47,38290024',
        altitude: '3292',
        timezone: '-3',
        dst: 'S',
        type: 'airport',
    },
    {
        name: 'Dourados Airport',
        city: 'Dourados',
        country: 'Brazil',
        iataCode: 'DOU',
        icaoCode: 'SSDO',
        latitude: '-22,2019',
        longitude: '-54,926601',
        altitude: '1503',
        timezone: '-4',
        dst: 'S',
        type: 'airport',
    },
    {
        name: 'Lábrea Airport',
        city: 'Labrea',
        country: 'Brazil',
        iataCode: 'LBR',
        icaoCode: 'SWLB',
        latitude: '-7,278969765',
        longitude: '-64,76950073',
        altitude: '190',
        timezone: '-4',
        dst: 'S',
        type: 'airport',
    },
    {
        name: 'Maestro Marinho Franco Airport',
        city: 'Rondonopolis',
        country: 'Brazil',
        iataCode: 'ROO',
        icaoCode: 'SWRD',
        latitude: '-16,586',
        longitude: '-54,7248',
        altitude: '1467',
        timezone: '-4',
        dst: 'S',
        type: 'airport',
    },
    {
        name: 'Tancredo Thomas de Faria Airport',
        city: 'Guarapuava',
        country: 'Brazil',
        iataCode: 'GPB',
        icaoCode: 'SBGU',
        latitude: '-25,38750076',
        longitude: '-51,52019882',
        altitude: '3494',
        timezone: '-3',
        dst: 'S',
        type: 'airport',
    },
    {
        name: 'Santa Terezinha Airport',
        city: 'Joacaba',
        country: 'Brazil',
        iataCode: 'JCB',
        icaoCode: 'SSJA',
        latitude: '-27,17140007',
        longitude: '-51,55329895',
        altitude: '2546',
        timezone: '-3',
        dst: 'S',
        type: 'airport',
    },
    {
        name: 'General Leite de Castro Airport',
        city: 'Rio Verde',
        country: 'Brazil',
        iataCode: 'RVD',
        icaoCode: 'SWLC',
        latitude: '-17,83472252',
        longitude: '-50,95611191',
        altitude: '2464',
        timezone: '-3',
        dst: 'S',
        type: 'airport',
    },
    {
        name: 'Romeu Zema Airport',
        city: 'Araxa',
        country: 'Brazil',
        iataCode: 'AAX',
        icaoCode: 'SBAX',
        latitude: '-19,5632',
        longitude: '-46,96039963',
        altitude: '3276',
        timezone: '-3',
        dst: 'S',
        type: 'airport',
    },
    {
        name: 'Maués Airport',
        city: 'Maues',
        country: 'Brazil',
        iataCode: 'MBZ',
        icaoCode: 'SWMW',
        latitude: '-3,37217',
        longitude: '-57,7248',
        altitude: '69',
        timezone: '-4',
        dst: 'S',
        type: 'airport',
    },
    {
        name: 'Borba Airport',
        city: 'Borba',
        country: 'Brazil',
        iataCode: 'RBB',
        icaoCode: 'SWBR',
        latitude: '-4,406340122',
        longitude: '-59,60240173',
        altitude: '293',
        timezone: '-4',
        dst: 'S',
        type: 'airport',
    },
    {
        name: 'Coari Airport',
        city: 'Coari',
        country: 'Brazil',
        iataCode: 'CIZ',
        icaoCode: 'SWKO',
        latitude: '-4,134059906',
        longitude: '-63,13259888',
        altitude: '131',
        timezone: '-4',
        dst: 'S',
        type: 'airport',
    },
    {
        name: 'Barcelos Airport',
        city: 'Barcelos',
        country: 'Brazil',
        iataCode: 'BAZ',
        icaoCode: 'SWBC',
        latitude: '-0,981292',
        longitude: '-62,919601',
        altitude: '112',
        timezone: '-4',
        dst: 'S',
        type: 'airport',
    },
    {
        name: 'Diamantino Airport',
        city: 'Diamantino',
        country: 'Brazil',
        iataCode: 'DMT',
        icaoCode: 'SWDM',
        latitude: '-14,37689972',
        longitude: '-56,40039825',
        altitude: '1476',
        timezone: '-4',
        dst: 'S',
        type: 'airport',
    },
    {
        name: 'Guanambi Airport',
        city: 'Guanambi',
        country: 'Brazil',
        iataCode: 'GNM',
        icaoCode: 'SNGI',
        latitude: '-14,20820045',
        longitude: '-42,74610138',
        altitude: '1815',
        timezone: '-3',
        dst: 'S',
        type: 'airport',
    },
    {
        name: 'Tsletsi Airport',
        city: 'Djelfa',
        country: 'Algeria',
        iataCode: 'QDJ',
        icaoCode: 'DAFI',
        latitude: '34,6657',
        longitude: '3,351',
        altitude: '3753',
        timezone: '1',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Nzagi Airport',
        city: 'Nzagi',
        country: 'Angola',
        iataCode: 'NZA',
        icaoCode: 'FNZG',
        latitude: '-7,716939926',
        longitude: '21,35820007',
        altitude: '2431',
        timezone: '1',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Lucapa Airport',
        city: 'Lucapa',
        country: 'Angola',
        iataCode: 'LBZ',
        icaoCode: 'FNLK',
        latitude: '-8,445727348',
        longitude: '20,73208618',
        altitude: '3029',
        timezone: '1',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Am Timan Airport',
        city: 'Am Timan',
        country: 'Chad',
        iataCode: 'AMC',
        icaoCode: 'FTTN',
        latitude: '11,0340004',
        longitude: '20,27400017',
        altitude: '1420',
        timezone: '1',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Shark El Oweinat International Airport',
        city: 'Sharq Al-Owainat',
        country: 'Egypt',
        iataCode: 'GSQ',
        icaoCode: 'HEOW',
        latitude: '22,58569908',
        longitude: '28,71660042',
        altitude: '859',
        timezone: '2',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Eastern WV Regional Airport/Shepherd Field',
        city: 'Martinsburg',
        country: 'United States',
        iataCode: 'MRB',
        icaoCode: 'KMRB',
        latitude: '39,40190125',
        longitude: '-77,98459625',
        altitude: '565',
        timezone: '-5',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Awassa Airport',
        city: 'Awasa',
        country: 'Ethiopia',
        iataCode: 'AWA',
        icaoCode: 'HALA',
        latitude: '7,066999912',
        longitude: '38,5',
        altitude: '5450',
        timezone: '3',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Wilwal International Airport',
        city: 'Jijiga',
        country: 'Ethiopia',
        iataCode: 'JIJ',
        icaoCode: 'HAJJ',
        latitude: '9,3325',
        longitude: '42,9121',
        altitude: '5954',
        timezone: '3',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Mekane Selam Airport',
        city: 'Mekane Selam',
        country: 'Ethiopia',
        iataCode: 'MKS',
        icaoCode: 'HAMA',
        latitude: '10,7254',
        longitude: '38,7415',
        altitude: '8405',
        timezone: '3',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Debra Marcos Airport',
        city: 'Debre Marqos',
        country: 'Ethiopia',
        iataCode: 'DBM',
        icaoCode: 'HADM',
        latitude: '10,35000038',
        longitude: '37,71699905',
        altitude: '8136',
        timezone: '3',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Debre Tabor Airport',
        city: 'Debre Tabor',
        country: 'Ethiopia',
        iataCode: 'DBT',
        icaoCode: 'HADT',
        latitude: '11,96700001',
        longitude: '38',
        altitude: '8490',
        timezone: '3',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Harar Meda Airport',
        city: 'Debre Zeyit',
        country: 'Ethiopia',
        iataCode: 'QHR',
        icaoCode: 'HAHM',
        latitude: '8,7163',
        longitude: '39,0059',
        altitude: '6201',
        timezone: '3',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Robe Airport',
        city: 'Goba',
        country: 'Ethiopia',
        iataCode: 'GOB',
        icaoCode: 'HAGB',
        latitude: '7,1160634',
        longitude: '40,0463033',
        altitude: '7892',
        timezone: '3',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Mayumba Airport',
        city: 'Mayumba',
        country: 'Gabon',
        iataCode: 'MYB',
        icaoCode: 'FOOY',
        latitude: '-3,4584198',
        longitude: '10,67407608',
        altitude: '13',
        timezone: '1',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Mara Serena Lodge Airstrip',
        city: 'Masai Mara',
        country: 'Kenya',
        iataCode: 'MRE',
        icaoCode: 'HKMS',
        latitude: '-1,406111',
        longitude: '35,008057',
        altitude: '5200',
        timezone: '3',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Meru Mulika Airport',
        city: 'Meru National Park',
        country: 'Kenya',
        iataCode: 'N',
        icaoCode: 'HKMK',
        latitude: '0,229567',
        longitude: '38,171412',
        altitude: '2230',
        timezone: '3',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Rumbek Airport',
        city: 'Rumbek',
        country: 'Sudan',
        iataCode: 'RBX',
        icaoCode: 'HSMK',
        latitude: '6,824999809',
        longitude: '29,66900063',
        altitude: '1378',
        timezone: '3',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Yei Airport',
        city: 'Yei',
        country: 'Sudan',
        iataCode: 'N',
        icaoCode: 'HSYE',
        latitude: '4,130281601',
        longitude: '30,72807312',
        altitude: '2000',
        timezone: '3',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Cape Palmas Airport',
        city: 'Greenville',
        country: 'Liberia',
        iataCode: 'CPA',
        icaoCode: 'GLCP',
        latitude: '4,379020214',
        longitude: '-7,696949959',
        altitude: '20',
        timezone: '0',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Ecuvillens Airport',
        city: 'Ecuvillens',
        country: 'Switzerland',
        iataCode: 'N',
        icaoCode: 'LSGE',
        latitude: '46,75500107',
        longitude: '7,076109886',
        altitude: '2293',
        timezone: '1',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Inhaca Airport',
        city: 'Inhaca',
        country: 'Mozambique',
        iataCode: 'N',
        icaoCode: 'FQIA',
        latitude: '-25,9971447',
        longitude: '32,92935181',
        altitude: '10',
        timezone: '2',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Ouro Sogui Airport',
        city: 'Matam',
        country: 'Senegal',
        iataCode: 'MAX',
        icaoCode: 'GOSM',
        latitude: '15,59360027',
        longitude: '-13,32279968',
        altitude: '85',
        timezone: '0',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Bird Island Airport',
        city: 'Bird Island',
        country: 'Seychelles',
        iataCode: 'BDI',
        icaoCode: 'FSSB',
        latitude: '-3,72472',
        longitude: '55,205299',
        altitude: '6',
        timezone: '4',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Wadi Halfa Airport',
        city: 'Wadi Halfa',
        country: 'Sudan',
        iataCode: 'WHF',
        icaoCode: 'HSSW',
        latitude: '21,80269814',
        longitude: '31,52157784',
        altitude: '961',
        timezone: '3',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Kidepo Airport',
        city: 'Kidepo',
        country: 'Uganda',
        iataCode: 'N',
        icaoCode: 'HUKD',
        latitude: '3,7176',
        longitude: '33,7487',
        altitude: '4033',
        timezone: '3',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Kitgum Airport',
        city: 'Kitgum',
        country: 'Uganda',
        iataCode: 'N',
        icaoCode: 'HUKT',
        latitude: '3,2817',
        longitude: '32,8882',
        altitude: '3130',
        timezone: '3',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Bugungu Airport',
        city: 'Pakuba',
        country: 'Uganda',
        iataCode: 'PAF',
        icaoCode: 'HUPA',
        latitude: '2,2',
        longitude: '31,55',
        altitude: '2472',
        timezone: '3',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Svea Airport',
        city: 'Sveagruva',
        country: 'Svalbard',
        iataCode: 'N',
        icaoCode: 'ENSA',
        latitude: '77,8969444',
        longitude: '16,725',
        altitude: '32',
        timezone: '1',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Ny-Ålesund Airport (Hamnerabben)',
        city: 'Ny-Alesund',
        country: 'Svalbard',
        iataCode: 'N',
        icaoCode: 'ENAS',
        latitude: '78,92749786',
        longitude: '11,8743',
        altitude: '50',
        timezone: '1',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Hatay Airport',
        city: 'Hatay',
        country: 'Turkey',
        iataCode: 'HTY',
        icaoCode: 'LTDA',
        latitude: '36,36277771',
        longitude: '36,28222275',
        altitude: '269',
        timezone: '3',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Kihnu Airfield',
        city: 'Kihnu',
        country: 'Estonia',
        iataCode: 'N',
        icaoCode: 'EEKU',
        latitude: '58,14830017',
        longitude: '24,00250053',
        altitude: '10',
        timezone: '2',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Ruhnu Airfield',
        city: 'Ruhnu',
        country: 'Estonia',
        iataCode: 'N',
        icaoCode: 'EERU',
        latitude: '57,78390121',
        longitude: '23,26609993',
        altitude: '10',
        timezone: '2',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Raivavae Airport',
        city: 'Raivavae',
        country: 'French Polynesia',
        iataCode: 'RVV',
        icaoCode: 'NTAV',
        latitude: '-23,8852005',
        longitude: '-147,6620026',
        altitude: '7',
        timezone: '-10',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Lleida-Alguaire Airport',
        city: 'Lleida',
        country: 'Spain',
        iataCode: 'ILD',
        icaoCode: 'LEDA',
        latitude: '41,728185',
        longitude: '0,535023',
        altitude: '1170',
        timezone: '1',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Ouessant Airport',
        city: 'Ouessant',
        country: 'France',
        iataCode: 'N',
        icaoCode: 'LFEC',
        latitude: '48,46319962',
        longitude: '-5,063580036',
        altitude: '142',
        timezone: '1',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Bildudalur Airport',
        city: 'Bildudalur',
        country: 'Iceland',
        iataCode: 'BIU',
        icaoCode: 'BIBD',
        latitude: '65,64129639',
        longitude: '-23,5461998',
        altitude: '18',
        timezone: '0',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Gjögur Airport',
        city: 'Gjogur',
        country: 'Iceland',
        iataCode: 'GJR',
        icaoCode: 'BIGJ',
        latitude: '65,99530029',
        longitude: '-21,32690048',
        altitude: '83',
        timezone: '0',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Sauðárkrókur Airport',
        city: 'Saudarkrokur',
        country: 'Iceland',
        iataCode: 'SAK',
        icaoCode: 'BIKR',
        latitude: '65,73169708',
        longitude: '-19,57279968',
        altitude: '8',
        timezone: '0',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Selfoss Airport',
        city: 'Selfoss',
        country: 'Iceland',
        iataCode: 'N',
        icaoCode: 'BISF',
        latitude: '63,92919922',
        longitude: '-21,03779984',
        altitude: '45',
        timezone: '0',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Inishmaan Aerodrome',
        city: 'Inishmaan',
        country: 'Ireland',
        iataCode: 'IIA',
        icaoCode: 'EIMN',
        latitude: '53,0929985',
        longitude: '-9,568059921',
        altitude: '15',
        timezone: '0',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Ak Bashat Airport',
        city: 'Taldykorgan',
        country: 'Kazakhstan',
        iataCode: 'TDK',
        icaoCode: 'UAAT',
        latitude: '42,88958',
        longitude: '73,602004',
        altitude: '0',
        timezone: '6',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Ulgii Mongolei Airport',
        city: 'Olgii',
        country: 'Mongolia',
        iataCode: 'ULG',
        icaoCode: 'ZMUL',
        latitude: '48,99330139',
        longitude: '89,92250061',
        altitude: '5732',
        timezone: '7',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Qurghonteppa International Airport',
        city: 'Kurgan Tyube',
        country: 'Tajikistan',
        iataCode: 'N',
        icaoCode: 'UTDT',
        latitude: '37,86640167',
        longitude: '68,86470032',
        altitude: '1473',
        timezone: '5',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Vologda Airport',
        city: 'Vologda',
        country: 'Russia',
        iataCode: 'VGD',
        icaoCode: 'ULWW',
        latitude: '59,28250122',
        longitude: '39,94440079',
        altitude: '387',
        timezone: '3',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Severo-Evensk Airport',
        city: 'Evensk',
        country: 'Russia',
        iataCode: 'N',
        icaoCode: 'UHMW',
        latitude: '61,92166519',
        longitude: '159,2299957',
        altitude: '0',
        timezone: '11',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Olenyok Airport',
        city: 'Olenyok',
        country: 'Russia',
        iataCode: 'N',
        icaoCode: 'UERO',
        latitude: '68,51499939',
        longitude: '112,4800034',
        altitude: '847',
        timezone: '9',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Saskylakh Airport',
        city: 'Saskylakh',
        country: 'Russia',
        iataCode: 'N',
        icaoCode: 'UERS',
        latitude: '71,92790222',
        longitude: '114,0800018',
        altitude: '0',
        timezone: '9',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Leshukonskoye Airport',
        city: 'Arkhangelsk',
        country: 'Russia',
        iataCode: 'LDG',
        icaoCode: 'ULAL',
        latitude: '64,89600372',
        longitude: '45,72299957',
        altitude: '220',
        timezone: '3',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Nizhneangarsk Airport',
        city: 'Nizhneangarsk',
        country: 'Russia',
        iataCode: 'N',
        icaoCode: 'UIUN',
        latitude: '55,80080032',
        longitude: '109,5950012',
        altitude: '1545',
        timezone: '8',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Vanavara Airport',
        city: 'Vanavara',
        country: 'Russia',
        iataCode: 'N',
        icaoCode: 'UNIW',
        latitude: '60,35969925',
        longitude: '102,3239975',
        altitude: '892',
        timezone: '7',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Aykhal Airport',
        city: 'Aykhal',
        country: 'Russia',
        iataCode: 'N',
        icaoCode: 'UERA',
        latitude: '65,959198',
        longitude: '111,5465012',
        altitude: '0',
        timezone: '9',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Uktus Airport',
        city: 'Yekaterinburg',
        country: 'Russia',
        iataCode: 'N',
        icaoCode: 'USSK',
        latitude: '56,7016983',
        longitude: '60,79000092',
        altitude: '643',
        timezone: '5',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Baykit Airport',
        city: 'Baykit',
        country: 'Russia',
        iataCode: 'N',
        icaoCode: 'UNIB',
        latitude: '61,67670059',
        longitude: '96,35500336',
        altitude: '853',
        timezone: '7',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Biysk Airport',
        city: 'Biysk',
        country: 'Russia',
        iataCode: 'N',
        icaoCode: 'UNBI',
        latitude: '52,47999954',
        longitude: '85,33999634',
        altitude: '620',
        timezone: '7',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Huesca/Pirineos Airport',
        city: 'Huesca',
        country: 'Spain',
        iataCode: 'HSK',
        icaoCode: 'LEHC',
        latitude: '42,0760994',
        longitude: '-0,316666991',
        altitude: '1768',
        timezone: '1',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Ciudad Real Central Airport',
        city: 'Ciudad Real',
        country: 'Spain',
        iataCode: 'CQM',
        icaoCode: 'LERL',
        latitude: '38,85638889',
        longitude: '-3,97',
        altitude: '0',
        timezone: '1',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Al Najaf International Airport',
        city: 'Najaf',
        country: 'Iraq',
        iataCode: 'NJF',
        icaoCode: 'ORNI',
        latitude: '31,989853',
        longitude: '44,404317',
        altitude: '103',
        timezone: '3',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Colonsay Airstrip',
        city: 'Colonsay',
        country: 'United Kingdom',
        iataCode: 'CSA',
        icaoCode: 'EGEY',
        latitude: '56,05749893',
        longitude: '-6,243060112',
        altitude: '44',
        timezone: '0',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Rock Hill - York County Airport',
        city: 'Rock Hill',
        country: 'United States',
        iataCode: 'RKH',
        icaoCode: 'KUZA',
        latitude: '34,9878006',
        longitude: '-81,05719757',
        altitude: '666',
        timezone: '-5',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Allegheny County Airport',
        city: 'Pittsburgh',
        country: 'United States',
        iataCode: 'AGC',
        icaoCode: 'KAGC',
        latitude: '40,35440063',
        longitude: '-79,93019867',
        altitude: '1252',
        timezone: '-5',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Cecil Airport',
        city: 'Jacksonville',
        country: 'United States',
        iataCode: 'N',
        icaoCode: 'KVQQ',
        latitude: '30,21870041',
        longitude: '-81,87670136',
        altitude: '81',
        timezone: '-5',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Fulton County Airport Brown Field',
        city: 'Atlanta',
        country: 'United States',
        iataCode: 'FTY',
        icaoCode: 'KFTY',
        latitude: '33,77909851',
        longitude: '-84,52140045',
        altitude: '841',
        timezone: '-5',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Tresco Heliport',
        city: 'Tresco',
        country: 'United Kingdom',
        iataCode: 'TSO',
        icaoCode: 'EGHT',
        latitude: '49,9455986',
        longitude: '-6,331389904',
        altitude: '20',
        timezone: '0',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Tarin Kowt Airport',
        city: 'Tarin Kowt',
        country: 'Afghanistan',
        iataCode: 'TII',
        icaoCode: 'OATN',
        latitude: '32,60419846',
        longitude: '65,86579895',
        altitude: '4429',
        timezone: '4,5',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Zaranj Airport',
        city: 'Zaranj',
        country: 'Afghanistan',
        iataCode: 'ZAJ',
        icaoCode: 'OAZJ',
        latitude: '30,972222',
        longitude: '61,865833',
        altitude: '1572',
        timezone: '4,5',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Chakcharan Airport',
        city: 'Chaghcharan',
        country: 'Afghanistan',
        iataCode: 'CCN',
        icaoCode: 'OACC',
        latitude: '34,53300095',
        longitude: '65,26699829',
        altitude: '7383',
        timezone: '4,5',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Fuyang Xiguan Airport',
        city: 'Fuyang',
        country: 'China',
        iataCode: 'FUG',
        icaoCode: 'ZSFY',
        latitude: '32,882157',
        longitude: '115,734364',
        altitude: '104',
        timezone: '8',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Longyan Guanzhishan Airport',
        city: 'Longyan',
        country: 'China',
        iataCode: 'LCX',
        icaoCode: 'ZSLD',
        latitude: '25,67469978',
        longitude: '116,7470016',
        altitude: '1225',
        timezone: '8',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Xingyi Airport',
        city: 'Xingyi',
        country: 'China',
        iataCode: 'ACX',
        icaoCode: 'ZUYI',
        latitude: '25,08638889',
        longitude: '104,9594444',
        altitude: '4150',
        timezone: '8',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Liping Airport',
        city: 'Liping',
        country: 'China',
        iataCode: 'HZH',
        icaoCode: 'ZUNP',
        latitude: '26,32217',
        longitude: '109,1499',
        altitude: '1620',
        timezone: '8',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Stepanakert Air Base',
        city: 'Stepanakert',
        country: 'Azerbaijan',
        iataCode: 'N',
        icaoCode: 'UB13',
        latitude: '39,90140152',
        longitude: '46,78699875',
        altitude: '2001',
        timezone: '4',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Ohio State University Airport',
        city: 'Columbus',
        country: 'United States',
        iataCode: 'OSU',
        icaoCode: 'KOSU',
        latitude: '40,07979965',
        longitude: '-83,07299805',
        altitude: '905',
        timezone: '-5',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Addison Airport',
        city: 'Addison',
        country: 'United States',
        iataCode: 'ADS',
        icaoCode: 'KADS',
        latitude: '32,96860123',
        longitude: '-96,83640289',
        altitude: '644',
        timezone: '-6',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Destin Executive Airport',
        city: 'Destin',
        country: 'United States',
        iataCode: 'DTS',
        icaoCode: 'KDTS',
        latitude: '30,40010071',
        longitude: '-86,47149658',
        altitude: '23',
        timezone: '-6',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Chernobayevka Airport',
        city: 'Kherson',
        country: 'Ukraine',
        iataCode: 'KHE',
        icaoCode: 'UKOH',
        latitude: '46,67580032',
        longitude: '32,50640106',
        altitude: '148',
        timezone: '2',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Ryans Creek Aerodrome',
        city: 'Stewart Island',
        country: 'New Zealand',
        iataCode: 'SZS',
        icaoCode: 'NZRC',
        latitude: '-46,89970016',
        longitude: '168,1009979',
        altitude: '62',
        timezone: '12',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Assumption Island Airport',
        city: 'Assumption Island',
        country: 'Seychelles',
        iataCode: 'N',
        icaoCode: 'FSAS',
        latitude: '-9,74222',
        longitude: '46,506802',
        altitude: '10',
        timezone: '4',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Zhijiang Airport',
        city: 'Zhijiang',
        country: 'China',
        iataCode: 'HJJ',
        icaoCode: 'ZGCJ',
        latitude: '27,44111111',
        longitude: '109,7',
        altitude: '882',
        timezone: '8',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Yarmouth Airport',
        city: 'Yarmouth',
        country: 'Canada',
        iataCode: 'YQI',
        icaoCode: 'CYQI',
        latitude: '43,82690048',
        longitude: '-66,08809662',
        altitude: '141',
        timezone: '-4',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Kinston Regional Jetport At Stallings Field',
        city: 'Kinston',
        country: 'United States',
        iataCode: 'ISO',
        icaoCode: 'KISO',
        latitude: '35,33140183',
        longitude: '-77,6088028',
        altitude: '93',
        timezone: '-5',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'First Flight Airport',
        city: 'Kill Devil Hills',
        country: 'United States',
        iataCode: 'FFA',
        icaoCode: 'KFFA',
        latitude: '36,01819992',
        longitude: '-75,6713028',
        altitude: '13',
        timezone: '-5',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Maturacá Airport',
        city: 'Maturaca',
        country: 'Brazil',
        iataCode: 'N',
        icaoCode: 'SWMK',
        latitude: '0,628269017',
        longitude: '-66,11509705',
        altitude: '354',
        timezone: '-4',
        dst: 'S',
        type: 'airport',
    },
    {
        name: 'Carajás Airport',
        city: 'Parauapebas',
        country: 'Brazil',
        iataCode: 'CKS',
        icaoCode: 'SBCJ',
        latitude: '-6,115277767',
        longitude: '-50,00138855',
        altitude: '2064',
        timezone: '-3',
        dst: 'S',
        type: 'airport',
    },
    {
        name: 'Centro de Lançamento de Alcântara Airport',
        city: 'Alcantara',
        country: 'Brazil',
        iataCode: 'N',
        icaoCode: 'SNCW',
        latitude: '-2,372999907',
        longitude: '-44,39640045',
        altitude: '148',
        timezone: '-3',
        dst: 'S',
        type: 'airport',
    },
    {
        name: 'Tarempa Airport',
        city: 'Anambas Islands',
        country: 'Indonesia',
        iataCode: 'MWK',
        icaoCode: 'WIOM',
        latitude: '3,348119974',
        longitude: '106,2580032',
        altitude: '10',
        timezone: '7',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Würzburg-Schenkenturm Airport',
        city: 'Wuerzburg',
        country: 'Germany',
        iataCode: 'N',
        icaoCode: 'EDFW',
        latitude: '49,81777954',
        longitude: '9,897500038',
        altitude: '991',
        timezone: '1',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'São Jacinto Airport',
        city: 'Aveiro',
        country: 'Portugal',
        iataCode: 'N',
        icaoCode: 'LPAV',
        latitude: '40,656741',
        longitude: '-8,741544',
        altitude: '26',
        timezone: '0',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Hopsten Air Base',
        city: 'Hopsten',
        country: 'Germany',
        iataCode: 'N',
        icaoCode: 'ETNP',
        latitude: '52,33869934',
        longitude: '7,541329861',
        altitude: '423',
        timezone: '1',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Persian Gulf International Airport',
        city: 'Khalije Fars',
        country: 'Iran',
        iataCode: 'PGU',
        icaoCode: 'OIBP',
        latitude: '27,379601',
        longitude: '52,737701',
        altitude: '27',
        timezone: '3,5',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Yasouj Airport',
        city: 'Yasuj',
        country: 'Iran',
        iataCode: 'YES',
        icaoCode: 'OISY',
        latitude: '30,70050049',
        longitude: '51,54510117',
        altitude: '5939',
        timezone: '3,5',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Mosul International Airport',
        city: 'Mosul',
        country: 'Iraq',
        iataCode: 'OSB',
        icaoCode: 'ORBM',
        latitude: '36,30580139',
        longitude: '43,1473999',
        altitude: '719',
        timezone: '3',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Tajima Airport',
        city: 'Toyooka',
        country: 'Japan',
        iataCode: 'TJH',
        icaoCode: 'RJBT',
        latitude: '35,51279831',
        longitude: '134,7870026',
        altitude: '584',
        timezone: '9',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Amakusa Airport',
        city: 'Amakusa',
        country: 'Japan',
        iataCode: 'AXJ',
        icaoCode: 'RJDA',
        latitude: '32,482498',
        longitude: '130,158997',
        altitude: '340',
        timezone: '9',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Kikai Airport',
        city: 'Kikai',
        country: 'Japan',
        iataCode: 'KKX',
        icaoCode: 'RJKI',
        latitude: '28,32130051',
        longitude: '129,9279938',
        altitude: '21',
        timezone: '9',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Aguni Airport',
        city: 'Aguni',
        country: 'Japan',
        iataCode: 'AGJ',
        icaoCode: 'RORA',
        latitude: '26,59250069',
        longitude: '127,2409973',
        altitude: '38',
        timezone: '9',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Donoi Airport',
        city: 'Uliastai',
        country: 'Mongolia',
        iataCode: 'N',
        icaoCode: 'ZMDN',
        latitude: '47,7093',
        longitude: '96,5258',
        altitude: '5800',
        timezone: '8',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Bulgan Airport',
        city: 'Bulgan',
        country: 'Mongolia',
        iataCode: 'UGA',
        icaoCode: 'ZMBN',
        latitude: '48,85499954',
        longitude: '103,4759979',
        altitude: '4311',
        timezone: '8',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Ulaangom Airport',
        city: 'Ulaangom',
        country: 'Mongolia',
        iataCode: 'ULO',
        icaoCode: 'ZMUG',
        latitude: '50,066588',
        longitude: '91,938273',
        altitude: '0',
        timezone: '7',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Borongan Airport',
        city: 'Borongan',
        country: 'Philippines',
        iataCode: 'BPR',
        icaoCode: 'RPVW',
        latitude: '11,67430019',
        longitude: '125,4789963',
        altitude: '7',
        timezone: '8',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Lubang Airport',
        city: 'Lubang',
        country: 'Philippines',
        iataCode: 'LBX',
        icaoCode: 'RPLU',
        latitude: '13,85540009',
        longitude: '120,1050034',
        altitude: '43',
        timezone: '8',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Kulob Airport',
        city: 'Kulyab',
        country: 'Tajikistan',
        iataCode: 'TJU',
        icaoCode: 'UTDK',
        latitude: '37,98809814',
        longitude: '69,80500031',
        altitude: '2293',
        timezone: '5',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Daşoguz Airport',
        city: 'Dasoguz',
        country: 'Turkmenistan',
        iataCode: 'TAZ',
        icaoCode: 'UTAT',
        latitude: '41,761101',
        longitude: '59,826698',
        altitude: '272',
        timezone: '5',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Barrow Island Airport',
        city: 'Barrow Island',
        country: 'Australia',
        iataCode: 'BWB',
        icaoCode: 'YBWX',
        latitude: '-20,86440086',
        longitude: '115,4059982',
        altitude: '26',
        timezone: '8',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Derby Airport',
        city: 'Derby',
        country: 'Australia',
        iataCode: 'DRB',
        icaoCode: 'YDBY',
        latitude: '-17,37000084',
        longitude: '123,6610031',
        altitude: '24',
        timezone: '8',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Walgett Airport',
        city: 'Walgett',
        country: 'Australia',
        iataCode: 'WGE',
        icaoCode: 'YWLG',
        latitude: '-30,03280067',
        longitude: '148,1260071',
        altitude: '439',
        timezone: '10',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Bathurst Island Airport',
        city: 'Bathurst Island',
        country: 'Australia',
        iataCode: 'BRT',
        icaoCode: 'YBTI',
        latitude: '-11,76920033',
        longitude: '130,6199951',
        altitude: '67',
        timezone: '9,5',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Dunk Island Airport',
        city: 'Dunk Island',
        country: 'Australia',
        iataCode: 'DKI',
        icaoCode: 'YDKI',
        latitude: '-17,94169998',
        longitude: '146,1399994',
        altitude: '6',
        timezone: '10',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Lizard Island Airport',
        city: 'Lizard Island',
        country: 'Australia',
        iataCode: 'LZR',
        icaoCode: 'YLZI',
        latitude: '-14,673273',
        longitude: '145,454571',
        altitude: '70',
        timezone: '10',
        dst: 'O',
        type: 'airport',
    },
    {
        name: 'Hamilton Airport',
        city: 'Hamilton',
        country: 'Australia',
        iataCode: 'HLT',
        icaoCode: 'YHML',
        latitude: '-37,64889908',
        longitude: '142,0650024',
        altitude: '803',
        timezone: '10',
        dst: 'O',
        type: 'airport',
    },
    {
        name: 'Halls Creek Airport',
        city: 'Halls Creek',
        country: 'Australia',
        iataCode: 'HCQ',
        icaoCode: 'YHLC',
        latitude: '-18,23390007',
        longitude: '127,6699982',
        altitude: '1346',
        timezone: '8',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Fitzroy Crossing Airport',
        city: 'Fitzroy Crossing',
        country: 'Australia',
        iataCode: 'FIZ',
        icaoCode: 'YFTZ',
        latitude: '-18,18190002',
        longitude: '125,5589981',
        altitude: '368',
        timezone: '8',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Ravensthorpe Airport',
        city: 'Ravensthorpe',
        country: 'Australia',
        iataCode: 'RVT',
        icaoCode: 'YNRV',
        latitude: '-33,79719925',
        longitude: '120,2080002',
        altitude: '197',
        timezone: '8',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Wilkins Runway',
        city: 'Budd Coast',
        country: 'Antarctica',
        iataCode: 'N',
        icaoCode: 'YWKS',
        latitude: '-66,690833',
        longitude: '111,523611',
        altitude: '2529',
        timezone: '12',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Provo Municipal Airport',
        city: 'Provo',
        country: 'United States',
        iataCode: 'PVU',
        icaoCode: 'KPVU',
        latitude: '40,21920013',
        longitude: '-111,7229996',
        altitude: '4497',
        timezone: '-7',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Steamboat Springs Bob Adams Field',
        city: 'Steamboat Springs',
        country: 'United States',
        iataCode: 'SBS',
        icaoCode: 'KSBS',
        latitude: '40,5163002',
        longitude: '-106,8659973',
        altitude: '6882',
        timezone: '-7',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Delta Municipal Airport',
        city: 'Delta',
        country: 'United States',
        iataCode: 'DTA',
        icaoCode: 'KDTA',
        latitude: '39,38059998',
        longitude: '-112,5080032',
        altitude: '4759',
        timezone: '-7',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Richfield Municipal Airport',
        city: 'Richfield',
        country: 'United States',
        iataCode: 'RIF',
        icaoCode: 'KRIF',
        latitude: '38,7364006',
        longitude: '-112,098999',
        altitude: '5301',
        timezone: '-7',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Carbon County Regional/Buck Davis Field',
        city: 'Price',
        country: 'United States',
        iataCode: 'PUC',
        icaoCode: 'KPUC',
        latitude: '39,61389923',
        longitude: '-110,7509995',
        altitude: '5957',
        timezone: '-7',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Los Alamos Airport',
        city: 'Los Alamos',
        country: 'United States',
        iataCode: 'LAM',
        icaoCode: 'KLAM',
        latitude: '35,87979889',
        longitude: '-106,2689972',
        altitude: '7171',
        timezone: '-7',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Lake Havasu City Airport',
        city: 'Lake Havasu City',
        country: 'United States',
        iataCode: 'HII',
        icaoCode: 'KHII',
        latitude: '34,571098',
        longitude: '-114,358002',
        altitude: '783',
        timezone: '-7',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Winslow Lindbergh Regional Airport',
        city: 'Winslow',
        country: 'United States',
        iataCode: 'INW',
        icaoCode: 'KINW',
        latitude: '35,02190018',
        longitude: '-110,7229996',
        altitude: '4941',
        timezone: '-7',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Douglas Municipal Airport',
        city: 'Douglas',
        country: 'United States',
        iataCode: 'DGL',
        icaoCode: 'KDGL',
        latitude: '31,34259987',
        longitude: '-109,5059967',
        altitude: '4173',
        timezone: '-7',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Marakei Airport',
        city: 'Marakei',
        country: 'Kiribati',
        iataCode: 'MZK',
        icaoCode: 'NGMK',
        latitude: '2,058609962',
        longitude: '173,2709961',
        altitude: '10',
        timezone: '12',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Abemama Atoll Airport',
        city: 'Abemama',
        country: 'Kiribati',
        iataCode: 'AEA',
        icaoCode: 'NGTB',
        latitude: '0,490833014',
        longitude: '173,8289948',
        altitude: '8',
        timezone: '12',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Buariki Airport',
        city: 'Buariki',
        country: 'Kiribati',
        iataCode: 'AAK',
        icaoCode: 'NGUK',
        latitude: '0,185277998',
        longitude: '173,6369934',
        altitude: '0',
        timezone: '10',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Kuria Airport',
        city: 'Kuria',
        country: 'Kiribati',
        iataCode: 'KUC',
        icaoCode: 'NGKT',
        latitude: '0,218611002',
        longitude: '173,4420013',
        altitude: '0',
        timezone: '12',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Arorae Island Airport',
        city: 'Arorae',
        country: 'Kiribati',
        iataCode: 'AIS',
        icaoCode: 'NGTR',
        latitude: '-2,616110086',
        longitude: '176,8029938',
        altitude: '0',
        timezone: '12',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Tamana Island Airport',
        city: 'Tamana',
        country: 'Kiribati',
        iataCode: 'TMN',
        icaoCode: 'NGTM',
        latitude: '-2,485830069',
        longitude: '175,9700012',
        altitude: '0',
        timezone: '12',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Beru Airport',
        city: 'Beru Island',
        country: 'Kiribati',
        iataCode: 'BEZ',
        icaoCode: 'NGBR',
        latitude: '-1,354719996',
        longitude: '176,0070038',
        altitude: '6',
        timezone: '12',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Nikunau Airport',
        city: 'Nikunau',
        country: 'Kiribati',
        iataCode: 'NIG',
        icaoCode: 'NGNU',
        latitude: '-1,314440012',
        longitude: '176,4100037',
        altitude: '6',
        timezone: '12',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Butaritari Atoll Airport',
        city: 'Butaritari',
        country: 'Kiribati',
        iataCode: 'BBG',
        icaoCode: 'NGTU',
        latitude: '3,08583',
        longitude: '172,811005',
        altitude: '5',
        timezone: '12',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Makin Island Airport',
        city: 'Makin',
        country: 'Kiribati',
        iataCode: 'MTK',
        icaoCode: 'NGMN',
        latitude: '3,374439955',
        longitude: '172,9920044',
        altitude: '0',
        timezone: '12',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Maiana Airport',
        city: 'Maiana',
        country: 'Kiribati',
        iataCode: 'MNK',
        icaoCode: 'NGMA',
        latitude: '1,003610015',
        longitude: '173,0310059',
        altitude: '8',
        timezone: '12',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Nonouti Airport',
        city: 'Nonouti',
        country: 'Kiribati',
        iataCode: 'NON',
        icaoCode: 'NGTO',
        latitude: '-0,63972199',
        longitude: '174,4279938',
        altitude: '10',
        timezone: '12',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Tabiteuea South Airport',
        city: 'Tabiteuea',
        country: 'Kiribati',
        iataCode: 'TSU',
        icaoCode: 'NGTS',
        latitude: '-1,474439979',
        longitude: '175,0639954',
        altitude: '0',
        timezone: '12',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Whitianga Airport',
        city: 'Whitianga',
        country: 'New Zealand',
        iataCode: 'WTZ',
        icaoCode: 'NZWT',
        latitude: '-36,83169937',
        longitude: '175,6790009',
        altitude: '10',
        timezone: '12',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Takaka Airport',
        city: 'Takaka',
        country: 'New Zealand',
        iataCode: 'KTF',
        icaoCode: 'NZTK',
        latitude: '-40,81330109',
        longitude: '172,7749939',
        altitude: '102',
        timezone: '12',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Afutara Aerodrome',
        city: 'Afutara',
        country: 'Solomon Islands',
        iataCode: 'AFT',
        icaoCode: 'AGAF',
        latitude: '-9,191388889',
        longitude: '160,9486111',
        altitude: '23',
        timezone: '11',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Ulawa Airport',
        city: 'Ulawa',
        country: 'Solomon Islands',
        iataCode: 'RNA',
        icaoCode: 'AGAR',
        latitude: '-9,860543583',
        longitude: '161,9795465',
        altitude: '40',
        timezone: '11',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Choiseul Bay Airport',
        city: 'Choiseul Bay',
        country: 'Solomon Islands',
        iataCode: 'CHY',
        icaoCode: 'AGGC',
        latitude: '-6,711944',
        longitude: '156,396111',
        altitude: '0',
        timezone: '11',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Santa Ana Airport',
        city: 'Santa Ana',
        country: 'Solomon Islands',
        iataCode: 'NNB',
        icaoCode: 'AGGT',
        latitude: '-10,847994',
        longitude: '162,454108',
        altitude: '3',
        timezone: '11',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Yandina Airport',
        city: 'Yandina',
        country: 'Solomon Islands',
        iataCode: 'XYA',
        icaoCode: 'AGGY',
        latitude: '-9,092816',
        longitude: '159,21841',
        altitude: '60',
        timezone: '11',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Bartow Municipal Airport',
        city: 'Bartow',
        country: 'United States',
        iataCode: 'BOW',
        icaoCode: 'KBOW',
        latitude: '27,94339943',
        longitude: '-81,78340149',
        altitude: '125',
        timezone: '-5',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Dyagilevo Air Base',
        city: 'Kostroma',
        country: 'Russia',
        iataCode: 'N',
        icaoCode: 'UUBD',
        latitude: '54,6446991',
        longitude: '39,57040024',
        altitude: '440',
        timezone: '3',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Fitiuta Airport',
        city: "Fiti\\'uta",
        country: 'American Samoa',
        iataCode: 'FTI',
        icaoCode: 'NSFQ',
        latitude: '-14,2172',
        longitude: '-169,425003',
        altitude: '110',
        timezone: '-11',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Livermore Municipal Airport',
        city: 'Livermore',
        country: 'United States',
        iataCode: 'LVK',
        icaoCode: 'KLVK',
        latitude: '37,69340134',
        longitude: '-121,8199997',
        altitude: '400',
        timezone: '-8',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Mariposa Yosemite Airport',
        city: 'Mariposa',
        country: 'United States',
        iataCode: 'RMY',
        icaoCode: 'KMPI',
        latitude: '37,51089859',
        longitude: '-120,0400009',
        altitude: '2254',
        timezone: '-8',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Grootfontein Airport',
        city: 'Grootfontein',
        country: 'Namibia',
        iataCode: 'GFY',
        icaoCode: 'FYGF',
        latitude: '-19,60219955',
        longitude: '18,12269974',
        altitude: '4636',
        timezone: '1',
        dst: 'S',
        type: 'airport',
    },
    {
        name: 'Rundu Airport',
        city: 'Rundu',
        country: 'Namibia',
        iataCode: 'NDU',
        icaoCode: 'FYRU',
        latitude: '-17,9564991',
        longitude: '19,71940041',
        altitude: '3627',
        timezone: '1',
        dst: 'S',
        type: 'airport',
    },
    {
        name: 'Tasiilaq Heliport',
        city: 'Angmagssalik',
        country: 'Greenland',
        iataCode: 'AGM',
        icaoCode: 'BGAM',
        latitude: '65,61229608',
        longitude: '-37,61833549',
        altitude: '24',
        timezone: '-3',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Jacqueline Cochran Regional Airport',
        city: 'Palm Springs',
        country: 'United States',
        iataCode: 'TRM',
        icaoCode: 'KTRM',
        latitude: '33,62670135',
        longitude: '-116,1600037',
        altitude: '-115',
        timezone: '-8',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Santa Monica Municipal Airport',
        city: 'Santa Monica',
        country: 'United States',
        iataCode: 'SMO',
        icaoCode: 'KSMO',
        latitude: '34,01580048',
        longitude: '-118,4509964',
        altitude: '177',
        timezone: '-8',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Bermuda Dunes Airport',
        city: 'Palm Springs',
        country: 'United States',
        iataCode: 'UDD',
        icaoCode: 'KUDD',
        latitude: '33,74840164',
        longitude: '-116,2750015',
        altitude: '73',
        timezone: '-8',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Scottsdale Airport',
        city: 'Scottsdale',
        country: 'United States',
        iataCode: 'ZSY',
        icaoCode: 'KSDL',
        latitude: '33,6228981',
        longitude: '-111,9110031',
        altitude: '1510',
        timezone: '-7',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Olympia Regional Airport',
        city: 'Olympia',
        country: 'United States',
        iataCode: 'OLM',
        icaoCode: 'KOLM',
        latitude: '46,9693985',
        longitude: '-122,9029999',
        altitude: '209',
        timezone: '-8',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Yolo County Davis Woodland Winters Airport',
        city: 'Davis-Woodland-Winters',
        country: 'United States',
        iataCode: 'DWA',
        icaoCode: 'KDWA',
        latitude: '38,57910156',
        longitude: '-121,8570023',
        altitude: '100',
        timezone: '-8',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Garfield County Regional Airport',
        city: 'Rifle',
        country: 'United States',
        iataCode: 'RIL',
        icaoCode: 'KRIL',
        latitude: '39,52629852',
        longitude: '-107,7269974',
        altitude: '5548',
        timezone: '-7',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Shively Field',
        city: 'SARATOGA',
        country: 'United States',
        iataCode: 'SAA',
        icaoCode: 'KSAA',
        latitude: '41,44490051',
        longitude: '-106,8239975',
        altitude: '7012',
        timezone: '-7',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'DeKalb Peachtree Airport',
        city: 'Atlanta',
        country: 'United States',
        iataCode: 'PDK',
        icaoCode: 'KPDK',
        latitude: '33,87559891',
        longitude: '-84,30200195',
        altitude: '1003',
        timezone: '-5',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Monroe County Airport',
        city: 'Bloomington',
        country: 'United States',
        iataCode: 'BMG',
        icaoCode: 'KBMG',
        latitude: '39,14599991',
        longitude: '-86,61669922',
        altitude: '846',
        timezone: '-5',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Witham Field',
        city: 'Stuart',
        country: 'United States',
        iataCode: 'SUA',
        icaoCode: 'KSUA',
        latitude: '27,18169975',
        longitude: '-80,22109985',
        altitude: '16',
        timezone: '-5',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Morristown Municipal Airport',
        city: 'Morristown',
        country: 'United States',
        iataCode: 'MMU',
        icaoCode: 'KMMU',
        latitude: '40,79940033',
        longitude: '-74,41490173',
        altitude: '187',
        timezone: '-5',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Napa County Airport',
        city: 'Napa',
        country: 'United States',
        iataCode: 'APC',
        icaoCode: 'KAPC',
        latitude: '38,2132',
        longitude: '-122,280998',
        altitude: '35',
        timezone: '-8',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Brown Field Municipal Airport',
        city: 'San Diego',
        country: 'United States',
        iataCode: 'SDM',
        icaoCode: 'KSDM',
        latitude: '32,57229996',
        longitude: '-116,9800034',
        altitude: '526',
        timezone: '-8',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Wangen-Lachen Airport',
        city: 'Wangen-Lachen',
        country: 'Switzerland',
        iataCode: 'N',
        icaoCode: 'LSPV',
        latitude: '47,20470047',
        longitude: '8,867500305',
        altitude: '1335',
        timezone: '1',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Venice Municipal Airport',
        city: 'Venice',
        country: 'United States',
        iataCode: 'VNC',
        icaoCode: 'KVNC',
        latitude: '27,07159996',
        longitude: '-82,44029999',
        altitude: '18',
        timezone: '-5',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Palm Beach County Glades Airport',
        city: 'Pahokee',
        country: 'United States',
        iataCode: 'PHK',
        icaoCode: 'KPHK',
        latitude: '26,78499985',
        longitude: '-80,69339752',
        altitude: '16',
        timezone: '-5',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Northwest Florida Beaches International Airport',
        city: 'Panama City',
        country: 'United States',
        iataCode: 'ECP',
        icaoCode: 'KECP',
        latitude: '30,357106',
        longitude: '-85,795414',
        altitude: '69',
        timezone: '-6',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'San Bernardino International Airport',
        city: 'San Bernardino',
        country: 'United States',
        iataCode: 'SBD',
        icaoCode: 'KSBD',
        latitude: '34,09540176',
        longitude: '-117,2350006',
        altitude: '1159',
        timezone: '-8',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Valença Airport',
        city: 'Valenca',
        country: 'Brazil',
        iataCode: 'VAL',
        icaoCode: 'SNVB',
        latitude: '-13,29650021',
        longitude: '-38,99240112',
        altitude: '21',
        timezone: '-3',
        dst: 'S',
        type: 'airport',
    },
    {
        name: 'Caruaru Airport',
        city: 'Caruaru',
        country: 'Brazil',
        iataCode: 'CAU',
        icaoCode: 'SNRU',
        latitude: '-8,282389641',
        longitude: '-36,01350021',
        altitude: '1891',
        timezone: '-3',
        dst: 'S',
        type: 'airport',
    },
    {
        name: 'Wake Island Airfield',
        city: 'Wake island',
        country: 'Wake Island',
        iataCode: 'AWK',
        icaoCode: 'PWAK',
        latitude: '19,28210068',
        longitude: '166,6360016',
        altitude: '14',
        timezone: '-10',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Aeroclube Airport',
        city: 'Nova Iguacu',
        country: 'Brazil',
        iataCode: 'QNV',
        icaoCode: 'SDNY',
        latitude: '-22,74530029',
        longitude: '-43,46030045',
        altitude: '164',
        timezone: '-3',
        dst: 'S',
        type: 'airport',
    },
    {
        name: 'San Carlos Airport',
        city: 'San Carlos',
        country: 'United States',
        iataCode: 'SQL',
        icaoCode: 'KSQL',
        latitude: '37,51190186',
        longitude: '-122,25',
        altitude: '5',
        timezone: '-8',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Courtelary Airport',
        city: 'Courtelary ',
        country: 'Switzerland',
        iataCode: 'N',
        icaoCode: 'LSZJ',
        latitude: '47,18360138',
        longitude: '7,090829849',
        altitude: '2247',
        timezone: '1',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Koszalin Zegrze Pomorskie Air Base',
        city: 'Koszalin',
        country: 'Poland',
        iataCode: 'OSZ',
        icaoCode: 'EPKO',
        latitude: '54,0425',
        longitude: '16,2656',
        altitude: '249',
        timezone: '1',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Camp Okavango Airport',
        city: 'Okavango Delta',
        country: 'Botswana',
        iataCode: 'N',
        icaoCode: 'FBCO',
        latitude: '-19,131115',
        longitude: '23,102505',
        altitude: '3158',
        timezone: '2',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Rocky Mount Wilson Regional Airport',
        city: 'Rocky Mount',
        country: 'United States',
        iataCode: 'RWI',
        icaoCode: 'KRWI',
        latitude: '35,85630035',
        longitude: '-77,89189911',
        altitude: '159',
        timezone: '-5',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Whittier Airport',
        city: 'Whittier',
        country: 'United States',
        iataCode: 'N',
        icaoCode: 'PAWR',
        latitude: '60,77719879',
        longitude: '-148,7220001',
        altitude: '30',
        timezone: '-9',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Soldotna Airport',
        city: 'Soldotna',
        country: 'United States',
        iataCode: 'SXQ',
        icaoCode: 'PASX',
        latitude: '60,47570038',
        longitude: '-151,0339966',
        altitude: '113',
        timezone: '-9',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Gillespie Field',
        city: 'El Cajon',
        country: 'United States',
        iataCode: 'SEE',
        icaoCode: 'KSEE',
        latitude: '32,82619858',
        longitude: '-116,9720001',
        altitude: '388',
        timezone: '-8',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'San Clemente Island Naval Auxiliary Landing Field',
        city: 'San Clemente Island',
        country: 'United States',
        iataCode: 'N',
        icaoCode: 'KNUC',
        latitude: '33,02270126',
        longitude: '-118,5879974',
        altitude: '184',
        timezone: '-8',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Phan Rang Airport',
        city: 'Phan Rang',
        country: 'Vietnam',
        iataCode: 'PHA',
        icaoCode: 'VVPR',
        latitude: '11,6335001',
        longitude: '108,9520035',
        altitude: '101',
        timezone: '7',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Na-San Airport',
        city: 'Son-La',
        country: 'Vietnam',
        iataCode: 'SQH',
        icaoCode: 'VVNS',
        latitude: '21,21699905',
        longitude: '104,0329971',
        altitude: '2133',
        timezone: '7',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Truckee Tahoe Airport',
        city: 'Truckee',
        country: 'United States',
        iataCode: 'TKF',
        icaoCode: 'KTRK',
        latitude: '39,31999969',
        longitude: '-120,1399994',
        altitude: '5900',
        timezone: '-8',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Fréjus Airport',
        city: 'Frejus',
        country: 'France',
        iataCode: 'FRJ',
        icaoCode: 'LFTU',
        latitude: '43,4175',
        longitude: '6,7357',
        altitude: '33',
        timezone: '1',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Geelong Airport',
        city: 'Geelong',
        country: 'Australia',
        iataCode: 'GEX',
        icaoCode: 'YGLG',
        latitude: '-38,22499847',
        longitude: '144,3329926',
        altitude: '43',
        timezone: '10',
        dst: 'O',
        type: 'airport',
    },
    {
        name: 'Mezen Airport',
        city: 'Mezen',
        country: 'Russia',
        iataCode: 'N',
        icaoCode: 'ULAE',
        latitude: '65,87833405',
        longitude: '44,21500015',
        altitude: '46',
        timezone: '3',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Vaskovo Airport',
        city: 'Arkhangelsk',
        country: 'Russia',
        iataCode: 'N',
        icaoCode: 'ULAH',
        latitude: '64,4417038',
        longitude: '40,42169952',
        altitude: '82',
        timezone: '3',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Cobb County-Mc Collum Field',
        city: 'Atlanta',
        country: 'United States',
        iataCode: 'RYY',
        icaoCode: 'KRYY',
        latitude: '34,01319885',
        longitude: '-84,59860229',
        altitude: '1040',
        timezone: '-5',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Brumowski  Air Base',
        city: 'Tulln',
        country: 'Austria',
        iataCode: 'N',
        icaoCode: 'LOXT',
        latitude: '48,3167',
        longitude: '16,116699',
        altitude: '574',
        timezone: '1',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Dell Flight Strip',
        city: 'Dell',
        country: 'United States',
        iataCode: '4U9',
        icaoCode: 'K4U9',
        latitude: '44,7356987',
        longitude: '-112,7200012',
        altitude: '6007',
        timezone: '-7',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Mission Field',
        city: 'Livingston-Montana',
        country: 'United States',
        iataCode: 'LVM',
        icaoCode: 'KLVM',
        latitude: '45,69940186',
        longitude: '-110,447998',
        altitude: '4660',
        timezone: '-7',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Big Timber Airport',
        city: 'Big Timber',
        country: 'United States',
        iataCode: '6S0',
        icaoCode: 'K6S0',
        latitude: '45,8064003',
        longitude: '-109,9810028',
        altitude: '4492',
        timezone: '-7',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Tulip City Airport',
        city: 'Holland',
        country: 'United States',
        iataCode: 'BIV',
        icaoCode: 'KBIV',
        latitude: '42,74290085',
        longitude: '-86,10739899',
        altitude: '698',
        timezone: '-5',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'London Heliport',
        city: 'London',
        country: 'United Kingdom',
        iataCode: 'N',
        icaoCode: 'EGLW',
        latitude: '51,46972275',
        longitude: '-0,179444',
        altitude: '18',
        timezone: '0',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Venice-Lido Airport',
        city: 'Venice',
        country: 'Italy',
        iataCode: 'N',
        icaoCode: 'LIPV',
        latitude: '45,428299',
        longitude: '12,3881',
        altitude: '13',
        timezone: '1',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Tallinn Linnahall Heliport',
        city: 'Tallinn',
        country: 'Estonia',
        iataCode: 'N',
        icaoCode: 'EECL',
        latitude: '59,44861984',
        longitude: '24,75322723',
        altitude: '23',
        timezone: '2',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Hernesaari Heliport',
        city: 'Helsinki',
        country: 'Finland',
        iataCode: 'HEN',
        icaoCode: 'EFHE',
        latitude: '60,14777756',
        longitude: '24,9244442',
        altitude: '7',
        timezone: '2',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Linkenheim Airport',
        city: 'Linkenheim',
        country: 'Germany',
        iataCode: 'N',
        icaoCode: 'EDRI',
        latitude: '49,14166641',
        longitude: '8,394721985',
        altitude: '325',
        timezone: '1',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Monument Valley Airport',
        city: 'Monument Valley',
        country: 'United States',
        iataCode: 'N',
        icaoCode: 'UT25',
        latitude: '37,01670074',
        longitude: '-110,2009964',
        altitude: '5192',
        timezone: '-7',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Hilversum Airport',
        city: 'Hilversum',
        country: 'Netherlands',
        iataCode: 'N',
        icaoCode: 'EHHV',
        latitude: '52,19189835',
        longitude: '5,146940231',
        altitude: '3',
        timezone: '1',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Texel Airport',
        city: 'Texel',
        country: 'Netherlands',
        iataCode: 'N',
        icaoCode: 'EHTX',
        latitude: '53,11529922',
        longitude: '4,833610058',
        altitude: '2',
        timezone: '1',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'La Cerdanya Airport',
        city: 'Das i Fontanals de Cerdanya',
        country: 'Spain',
        iataCode: 'N',
        icaoCode: 'LECD',
        latitude: '42,38639832',
        longitude: '1,866670012',
        altitude: '3586',
        timezone: '1',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Lakeland Linder Regional Airport',
        city: 'Lakeland',
        country: 'United States',
        iataCode: 'LAL',
        icaoCode: 'KLAL',
        latitude: '27,98889923',
        longitude: '-82,01860046',
        altitude: '142',
        timezone: '-5',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Stary Oskol Airport',
        city: 'Stary Oskol',
        country: 'Russia',
        iataCode: 'N',
        icaoCode: 'UUOS',
        latitude: '51,329216',
        longitude: '37,76884842',
        altitude: '791',
        timezone: '3',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Syangboche Airport',
        city: 'Syangboche',
        country: 'Nepal',
        iataCode: 'SYH',
        icaoCode: 'VNSB',
        latitude: '27,8112',
        longitude: '86,7124',
        altitude: '12400',
        timezone: '5,75',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Indianola Municipal Airport',
        city: 'New York',
        country: 'United States',
        iataCode: 'IDL',
        icaoCode: 'KIDL',
        latitude: '33,485699',
        longitude: '-90,678902',
        altitude: '126',
        timezone: '-6',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Cheremshanka Airport',
        city: 'Krasnoyarsk',
        country: 'Russia',
        iataCode: 'N',
        icaoCode: 'UNKM',
        latitude: '56,17699814',
        longitude: '92,54000092',
        altitude: '0',
        timezone: '7',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'French Valley Airport',
        city: 'Murrieta-Temecula',
        country: 'United States',
        iataCode: 'RBK',
        icaoCode: 'KF70',
        latitude: '33,57419968',
        longitude: '-117,1279984',
        altitude: '1350',
        timezone: '-8',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Oristano-Fenosu Airport',
        city: 'Oristano',
        country: 'Italy',
        iataCode: 'FNU',
        icaoCode: 'LIER',
        latitude: '39,895308',
        longitude: '8,642661',
        altitude: '36',
        timezone: '1',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'White Waltham Airfield',
        city: 'Maidenhead',
        country: 'United Kingdom',
        iataCode: 'N',
        icaoCode: 'EGLM',
        latitude: '51,50080109',
        longitude: '-0,774443984',
        altitude: '131',
        timezone: '0',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Mysore Airport',
        city: 'Mysore',
        country: 'India',
        iataCode: 'MYQ',
        icaoCode: 'VOMY',
        latitude: '12,30720043',
        longitude: '76,64969635',
        altitude: '2349',
        timezone: '5,5',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Carl R Keller Field',
        city: 'Port Clinton',
        country: 'United States',
        iataCode: 'PCW',
        icaoCode: 'KPCW',
        latitude: '41,5163002',
        longitude: '-82,86869812',
        altitude: '590',
        timezone: '-5',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Dayton-Wright Brothers Airport',
        city: 'Dayton',
        country: 'United States',
        iataCode: 'MGY',
        icaoCode: 'KMGY',
        latitude: '39,5890007',
        longitude: '-84,22489929',
        altitude: '957',
        timezone: '-5',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Richmond Municipal Airport',
        city: 'Richmond',
        country: 'United States',
        iataCode: 'RID',
        icaoCode: 'KRID',
        latitude: '39,75719833',
        longitude: '-84,84279633',
        altitude: '1140',
        timezone: '-5',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Findlay Airport',
        city: 'Findley',
        country: 'United States',
        iataCode: 'FDY',
        icaoCode: 'KFDY',
        latitude: '41,01350021',
        longitude: '-83,66870117',
        altitude: '813',
        timezone: '-5',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Burlington Executive',
        city: 'Burlington',
        country: 'Canada',
        iataCode: 'N',
        icaoCode: 'CZBA',
        latitude: '43,44145315',
        longitude: '-79,85010266',
        altitude: '602',
        timezone: '-5',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Penneshaw Airport',
        city: 'Penneshaw',
        country: 'Australia',
        iataCode: 'PEA',
        icaoCode: 'YPSH',
        latitude: '-35,75584629',
        longitude: '137,9628754',
        altitude: '0',
        timezone: '9,5',
        dst: 'O',
        type: 'airport',
    },
    {
        name: 'Engels heliport',
        city: 'Ebenhofen',
        country: 'Germany',
        iataCode: 'EBE',
        icaoCode: 'EBEN',
        latitude: '51,21166611',
        longitude: '4,580832958',
        altitude: '33',
        timezone: '1',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Emporia Municipal Airport',
        city: 'Kempten',
        country: 'Germany',
        iataCode: 'EMP',
        icaoCode: 'KEMP',
        latitude: '38,33209991',
        longitude: '-96,19120026',
        altitude: '1208',
        timezone: '-6',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Skå-Edeby Airport',
        city: 'Essen',
        country: 'Germany',
        iataCode: 'ESX',
        icaoCode: 'ESSE',
        latitude: '59,3451004',
        longitude: '17,7404995',
        altitude: '0',
        timezone: '1',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Wycombe Air Park',
        city: 'Wycombe',
        country: 'United Kingdom',
        iataCode: 'N',
        icaoCode: 'EGTB',
        latitude: '51,61169815',
        longitude: '-0,80833298',
        altitude: '515',
        timezone: '0',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Bembridge Airport',
        city: 'Bembridge',
        country: 'United Kingdom',
        iataCode: 'BBP',
        icaoCode: 'EGHJ',
        latitude: '50,67810059',
        longitude: '-1,109439969',
        altitude: '53',
        timezone: '0',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Waterville / Kings County Municipal Airport',
        city: 'Waterville',
        country: 'Canada',
        iataCode: 'N',
        icaoCode: 'CCW3',
        latitude: '45,05189896',
        longitude: '-64,65170288',
        altitude: '119',
        timezone: '-4',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Black Hills Airport-Clyde Ice Field',
        city: 'Spearfish-South Dakota',
        country: 'United States',
        iataCode: 'SPF',
        icaoCode: 'KSPF',
        latitude: '44,4803009',
        longitude: '-103,7829971',
        altitude: '3931',
        timezone: '-7',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Westkapelle heliport',
        city: 'Knokke',
        country: 'Belgium',
        iataCode: 'N',
        icaoCode: 'EBKW',
        latitude: '51,32222366',
        longitude: '3,293056011',
        altitude: '10',
        timezone: '1',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Redcliffe Airport',
        city: 'Rothwell',
        country: 'Australia',
        iataCode: 'N',
        icaoCode: 'YRED',
        latitude: '-27,20669937',
        longitude: '153,0679932',
        altitude: '2',
        timezone: '10',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Oksywie Military Air Base',
        city: 'Gdynia',
        country: 'Poland',
        iataCode: 'QYD',
        icaoCode: 'EPOK',
        latitude: '54,57970047',
        longitude: '18,51720047',
        altitude: '144',
        timezone: '1',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Malbork Military Air Base',
        city: 'Malbork',
        country: 'Poland',
        iataCode: 'N',
        icaoCode: 'EPMB',
        latitude: '54,02690125',
        longitude: '19,13419914',
        altitude: '16',
        timezone: '1',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Lask Military Air Base',
        city: 'Lask',
        country: 'Poland',
        iataCode: 'N',
        icaoCode: 'EPLK',
        latitude: '51,55170059',
        longitude: '19,17910004',
        altitude: '633',
        timezone: '1',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Miroslawiec Military Air Base',
        city: 'Miroslawiec',
        country: 'Poland',
        iataCode: 'N',
        icaoCode: 'EPMI',
        latitude: '53,39509964',
        longitude: '16,08279991',
        altitude: '459',
        timezone: '1',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Krzesiny Military Air Base',
        city: 'Poznan',
        country: 'Poland',
        iataCode: 'N',
        icaoCode: 'EPKS',
        latitude: '52,33169937',
        longitude: '16,96640015',
        altitude: '265',
        timezone: '1',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Olive Branch Airport',
        city: 'Olive Branch',
        country: 'United States',
        iataCode: 'OLV',
        icaoCode: 'KOLV',
        latitude: '34,97869873',
        longitude: '-89,78690338',
        altitude: '402',
        timezone: '-6',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Viña del mar Airport',
        city: 'Vina del Mar',
        country: 'Chile',
        iataCode: 'N',
        icaoCode: 'SCVM',
        latitude: '-32,9496',
        longitude: '-71,4786',
        altitude: '461',
        timezone: '-4',
        dst: 'S',
        type: 'airport',
    },
    {
        name: 'Brampton Airport',
        city: 'Brampton',
        country: 'Canada',
        iataCode: 'N',
        icaoCode: 'CNC3',
        latitude: '43,76029968',
        longitude: '-79,875',
        altitude: '935',
        timezone: '-5',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Zonguldak Airport',
        city: 'Zonguldak',
        country: 'Turkey',
        iataCode: 'ONQ',
        icaoCode: 'LTAS',
        latitude: '41,50640106',
        longitude: '32,08860016',
        altitude: '39',
        timezone: '3',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Rocky Mountain Metropolitan Airport',
        city: 'Broomfield-CO',
        country: 'United States',
        iataCode: 'BJC',
        icaoCode: 'KBJC',
        latitude: '39,90879822',
        longitude: '-105,1169968',
        altitude: '5673',
        timezone: '-7',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Salem Municipal Airport/McNary Field',
        city: 'Salem',
        country: 'United States',
        iataCode: 'SLE',
        icaoCode: 'KSLE',
        latitude: '44,90950012',
        longitude: '-123,0029984',
        altitude: '214',
        timezone: '-8',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Tunica Municipal Airport',
        city: 'Tunica',
        country: 'United States',
        iataCode: 'UTM',
        icaoCode: 'KUTA',
        latitude: '34,68099976',
        longitude: '-90,34670258',
        altitude: '194',
        timezone: '-6',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Batken Airport',
        city: 'Batken',
        country: 'Kyrgyzstan',
        iataCode: 'N',
        icaoCode: 'UA30',
        latitude: '40,042899',
        longitude: '70,83784',
        altitude: '3517',
        timezone: '6',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Kasaba Bay Airport',
        city: 'Kasaba Bay',
        country: 'Zambia',
        iataCode: 'ZKB',
        icaoCode: 'FLKY',
        latitude: '-8,524999619',
        longitude: '30,66300011',
        altitude: '2780',
        timezone: '2',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Hunt Field',
        city: 'Lindau',
        country: 'Germany',
        iataCode: 'LND',
        icaoCode: 'KLND',
        latitude: '42,81520081',
        longitude: '-108,7300034',
        altitude: '5586',
        timezone: '-7',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Hoogeveen Airport',
        city: 'Hoogeveen',
        country: 'Netherlands',
        iataCode: 'N',
        icaoCode: 'EHHO',
        latitude: '52,73080063',
        longitude: '6,516109943',
        altitude: '40',
        timezone: '1',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Teuge Airport',
        city: 'Deventer',
        country: 'Netherlands',
        iataCode: 'N',
        icaoCode: 'EHTE',
        latitude: '52,24470139',
        longitude: '6,04666996',
        altitude: '17',
        timezone: '1',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Midden-Zeeland Airport',
        city: 'Middelburg',
        country: 'Netherlands',
        iataCode: 'N',
        icaoCode: 'EHMZ',
        latitude: '51,5121994',
        longitude: '3,731110096',
        altitude: '6',
        timezone: '1',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Ameland Airport',
        city: 'Ameland',
        country: 'Netherlands',
        iataCode: 'N',
        icaoCode: 'EHAL',
        latitude: '53,4516983',
        longitude: '5,677219868',
        altitude: '11',
        timezone: '1',
        dst: 'E',
        type: 'airport',
    },
    {
        name: "Saint-Cyr-l'École Airport",
        city: 'Saint-Cyr',
        country: 'France',
        iataCode: 'N',
        icaoCode: 'LFPZ',
        latitude: '48,81140137',
        longitude: '2,074719906',
        altitude: '371',
        timezone: '1',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Lawrence J Timmerman Airport',
        city: 'Milwaukee',
        country: 'United States',
        iataCode: 'MWC',
        icaoCode: 'KMWC',
        latitude: '43,11040115',
        longitude: '-88,03440094',
        altitude: '745',
        timezone: '-6',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Southern Wisconsin Regional Airport',
        city: 'Janesville',
        country: 'United States',
        iataCode: 'JVL',
        icaoCode: 'KJVL',
        latitude: '42,62030029',
        longitude: '-89,04160309',
        altitude: '808',
        timezone: '-6',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Mantsonyane Airport',
        city: 'Mantsonyane',
        country: 'Lesotho',
        iataCode: 'N',
        icaoCode: 'FXMN',
        latitude: '-29,54610062',
        longitude: '28,27099991',
        altitude: '7100',
        timezone: '2',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Arlington Municipal Airport',
        city: 'Arlington',
        country: 'United States',
        iataCode: 'GKY',
        icaoCode: 'KGKY',
        latitude: '32,66389847',
        longitude: '-97,09429932',
        altitude: '628',
        timezone: '-6',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Gwinnett County Briscoe Field',
        city: 'Lawrenceville',
        country: 'United States',
        iataCode: 'LZU',
        icaoCode: 'KLZU',
        latitude: '33,97809982',
        longitude: '-83,96240234',
        altitude: '1061',
        timezone: '-5',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Bowling Green Warren County Regional Airport',
        city: 'Bowling Green',
        country: 'United States',
        iataCode: 'BWG',
        icaoCode: 'KBWG',
        latitude: '36,96450043',
        longitude: '-86,41970062',
        altitude: '547',
        timezone: '-6',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Richard Lloyd Jones Jr Airport',
        city: 'Tulsa',
        country: 'United States',
        iataCode: 'RVS',
        icaoCode: 'KRVS',
        latitude: '36,03960037',
        longitude: '-95,98459625',
        altitude: '638',
        timezone: '-6',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Al Minhad Air Base',
        city: 'Minhad AB',
        country: 'United Arab Emirates',
        iataCode: 'NHD',
        icaoCode: 'OMDM',
        latitude: '25,02680016',
        longitude: '55,36619949',
        altitude: '165',
        timezone: '4',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Kirovograd Airport',
        city: 'Kirovograd',
        country: 'Ukraine',
        iataCode: 'KGO',
        icaoCode: 'UKKG',
        latitude: '48,5428009',
        longitude: '32,28499985',
        altitude: '568',
        timezone: '2',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Roitzschjora Airport',
        city: 'Roitzschjora',
        country: 'Germany',
        iataCode: 'N',
        icaoCode: 'EDAW',
        latitude: '51,57777786',
        longitude: '12,49444389',
        altitude: '289',
        timezone: '1',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'El Alamein International Airport',
        city: 'Dabaa City',
        country: 'Egypt',
        iataCode: 'DBB',
        icaoCode: 'HEAL',
        latitude: '30,92449951',
        longitude: '28,46139908',
        altitude: '143',
        timezone: '2',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Bryce Canyon Airport',
        city: 'Bryce Canyon',
        country: 'United States',
        iataCode: 'BCE',
        icaoCode: 'KBCE',
        latitude: '37,70640183',
        longitude: '-112,1449966',
        altitude: '7590',
        timezone: '-7',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Burlington Alamance Regional Airport',
        city: 'Burlington',
        country: 'United States',
        iataCode: 'N',
        icaoCode: 'KBUY',
        latitude: '36,04850006',
        longitude: '-79,47489929',
        altitude: '617',
        timezone: '-5',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Chkalovskiy Airport',
        city: 'Shchyolkovo',
        country: 'Russia',
        iataCode: 'CKL',
        icaoCode: 'UUMU',
        latitude: '55,87829971',
        longitude: '38,06169891',
        altitude: '499',
        timezone: '3',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Tengchong Tuofeng Airport',
        city: 'Tengchong',
        country: 'China',
        iataCode: 'TCZ',
        icaoCode: 'ZUTC',
        latitude: '24,93805556',
        longitude: '98,48583333',
        altitude: '6250',
        timezone: '8',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Belbek Airport',
        city: 'Sevastopol',
        country: 'Ukraine',
        iataCode: 'UKS',
        icaoCode: 'UKFB',
        latitude: '44,68899918',
        longitude: '33,57099915',
        altitude: '344',
        timezone: '3',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'De Peel Air Base',
        city: 'Deurne',
        country: 'Netherlands',
        iataCode: 'N',
        icaoCode: 'EHDP',
        latitude: '51,51729965',
        longitude: '5,855720043',
        altitude: '98',
        timezone: '1',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Camp Bastion Airport',
        city: 'Camp Bastion',
        country: 'Afghanistan',
        iataCode: 'N',
        icaoCode: 'OAZI',
        latitude: '31,86380005',
        longitude: '64,22460175',
        altitude: '2943',
        timezone: '4,5',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'New Century Aircenter Airport',
        city: 'Olathe',
        country: 'United States',
        iataCode: 'JCI',
        icaoCode: 'KIXD',
        latitude: '38,83089828',
        longitude: '-94,89029694',
        altitude: '1087',
        timezone: '-6',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Easton Newnam Field',
        city: 'Easton',
        country: 'United States',
        iataCode: 'ESN',
        icaoCode: 'KESN',
        latitude: '38,80419922',
        longitude: '-76,06900024',
        altitude: '72',
        timezone: '-5',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Stafsberg Airport',
        city: 'Hamar',
        country: 'Norway',
        iataCode: 'HMR',
        icaoCode: 'ENHA',
        latitude: '60,81809998',
        longitude: '11,06799984',
        altitude: '713',
        timezone: '1',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Ringebu Airfield Frya',
        city: 'Frya',
        country: 'Norway',
        iataCode: 'N',
        icaoCode: 'ENRI',
        latitude: '61,54544067',
        longitude: '10,06158829',
        altitude: '571',
        timezone: '1',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Yuba County Airport',
        city: 'Yuba City',
        country: 'United States',
        iataCode: 'MYV',
        icaoCode: 'KMYV',
        latitude: '39,09780121',
        longitude: '-121,5699997',
        altitude: '64',
        timezone: '-8',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Phillip Island Airport',
        city: 'Phillip Island',
        country: 'Australia',
        iataCode: 'N',
        icaoCode: 'YPID',
        latitude: '-38,52330017',
        longitude: '145,3269958',
        altitude: '13',
        timezone: '10',
        dst: 'O',
        type: 'airport',
    },
    {
        name: 'Halliburton Field',
        city: 'Duncan',
        country: 'United States',
        iataCode: 'DUC',
        icaoCode: 'KDUC',
        latitude: '34,47090149',
        longitude: '-97,9598999',
        altitude: '1114',
        timezone: '-6',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Garner Field',
        city: 'Uvalde',
        country: 'United States',
        iataCode: 'UVA',
        icaoCode: 'KUVA',
        latitude: '29,2112999',
        longitude: '-99,74359894',
        altitude: '942',
        timezone: '-6',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Lewis University Airport',
        city: 'Lockport',
        country: 'United States',
        iataCode: 'LOT',
        icaoCode: 'KLOT',
        latitude: '41,6072998',
        longitude: '-88,09619904',
        altitude: '679',
        timezone: '-6',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Buchanan Field',
        city: 'Concord',
        country: 'United States',
        iataCode: 'CCR',
        icaoCode: 'KCCR',
        latitude: '37,98970032',
        longitude: '-122,0569992',
        altitude: '26',
        timezone: '-8',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Ocean Reef Club Airport',
        city: 'Ocean Reef Club Airport',
        country: 'United States',
        iataCode: 'OCA',
        icaoCode: '07FA',
        latitude: '25,3253994',
        longitude: '-80,27480316',
        altitude: '8',
        timezone: '-5',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Strasbourg Neuhof Airport',
        city: 'Strasbourg Neudorf',
        country: 'France',
        iataCode: 'N',
        icaoCode: 'LFGC',
        latitude: '48,5544014',
        longitude: '7,778059959',
        altitude: '456',
        timezone: '1',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Nannhausen Airport',
        city: 'Nannhausen',
        country: 'Germany',
        iataCode: 'N',
        icaoCode: 'EDRN',
        latitude: '49,97027969',
        longitude: '7,479166985',
        altitude: '1224',
        timezone: '1',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Yushu Batang Airport',
        city: 'Yushu',
        country: 'China',
        iataCode: 'YUS',
        icaoCode: 'ZYLS',
        latitude: '32,83638889',
        longitude: '97,03638889',
        altitude: '12816',
        timezone: '8',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Oshawa Airport',
        city: 'Oshawa',
        country: 'Canada',
        iataCode: 'YOO',
        icaoCode: 'CYOO',
        latitude: '43,92279816',
        longitude: '-78,89499664',
        altitude: '460',
        timezone: '-5',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Marl-Loemühle Airport',
        city: 'Recklinghausen',
        country: 'Germany',
        iataCode: 'N',
        icaoCode: 'EDLM',
        latitude: '51,647202',
        longitude: '7,16333',
        altitude: '240',
        timezone: '1',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Färila Air Base',
        city: 'Farila',
        country: 'Sweden',
        iataCode: 'N',
        icaoCode: 'ESNF',
        latitude: '61,89799881',
        longitude: '15,70530033',
        altitude: '0',
        timezone: '1',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Lahr Airport',
        city: 'Lahr',
        country: 'Germany',
        iataCode: 'LHA',
        icaoCode: 'EDTL',
        latitude: '48,36930084',
        longitude: '7,827720165',
        altitude: '511',
        timezone: '1',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Monywar Airport',
        city: 'Monywa',
        country: 'Burma',
        iataCode: 'N',
        icaoCode: 'VYMY',
        latitude: '22,2329998',
        longitude: '95,11699677',
        altitude: '298',
        timezone: '6,5',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Ohio University Snyder Field',
        city: 'Athens',
        country: 'United States',
        iataCode: 'N',
        icaoCode: 'KUNI',
        latitude: '39,21099854',
        longitude: '-82,23139954',
        altitude: '766',
        timezone: '-5',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Springfield-Beckley Municipal Airport',
        city: 'Springfield',
        country: 'United States',
        iataCode: 'SGH',
        icaoCode: 'KSGH',
        latitude: '39,84030151',
        longitude: '-83,84020233',
        altitude: '1051',
        timezone: '-5',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Fazenda Palmital Airport',
        city: 'South Aari Atoll',
        country: 'Maldives',
        iataCode: 'MSI',
        icaoCode: 'SIAM',
        latitude: '-20,69639969',
        longitude: '-48,28639984',
        altitude: '1709',
        timezone: '-3',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Fes Sefrou Airport',
        city: 'Fes',
        country: 'Morocco',
        iataCode: 'N',
        icaoCode: 'GMFU',
        latitude: '34,0080986',
        longitude: '-4,965559959',
        altitude: '1539',
        timezone: '0',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Herrera Airport',
        city: 'Santo Domingo',
        country: 'Dominican Republic',
        iataCode: 'HEX',
        icaoCode: 'MDHE',
        latitude: '18,46969986',
        longitude: '-69,9693985',
        altitude: '190',
        timezone: '-4',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Cooinda Airport',
        city: 'Cooinda',
        country: 'Australia',
        iataCode: 'CDA',
        icaoCode: 'YCOO',
        latitude: '-12,90330029',
        longitude: '132,5319977',
        altitude: '13',
        timezone: '9,5',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Jabiru Airport',
        city: 'Jabiru',
        country: 'Australia',
        iataCode: 'JAB',
        icaoCode: 'YJAB',
        latitude: '-12,6583004',
        longitude: '132,8930054',
        altitude: '85',
        timezone: '9,5',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Regensburg-Oberhub Airport',
        city: 'Regensburg',
        country: 'Germany',
        iataCode: 'N',
        icaoCode: 'EDNR',
        latitude: '49,14194489',
        longitude: '12,08194447',
        altitude: '1299',
        timezone: '1',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Hastings Airport',
        city: 'Freetown',
        country: 'Sierra Leone',
        iataCode: 'HGS',
        icaoCode: 'GFHA',
        latitude: '8,397130013',
        longitude: '-13,12909985',
        altitude: '60',
        timezone: '0',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Philip Billard Municipal Airport',
        city: 'Topeka',
        country: 'United States',
        iataCode: 'TOP',
        icaoCode: 'KTOP',
        latitude: '39,06869888',
        longitude: '-95,62249756',
        altitude: '881',
        timezone: '-6',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Benson Airstrip',
        city: 'Uvalde',
        country: 'United States',
        iataCode: 'N',
        icaoCode: '2XS8',
        latitude: '29,22940063',
        longitude: '-99,82389832',
        altitude: '929',
        timezone: '-6',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Rough River State Park Airport',
        city: 'Null',
        country: 'United States',
        iataCode: 'N',
        icaoCode: 'K2I3',
        latitude: '37,610022',
        longitude: '-86,507262',
        altitude: '577',
        timezone: '-6',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Smyrna Airport',
        city: 'Smyrna',
        country: 'United States',
        iataCode: 'N',
        icaoCode: 'KMQY',
        latitude: '36,00899887',
        longitude: '-86,52010345',
        altitude: '543',
        timezone: '-6',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Franklin County Airport',
        city: 'Sewanee',
        country: 'United States',
        iataCode: 'N',
        icaoCode: 'KUOS',
        latitude: '35,20510101',
        longitude: '-85,89810181',
        altitude: '1953',
        timezone: '-6',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Ngari Gunsa Airport',
        city: 'Shiquanhe',
        country: 'China',
        iataCode: 'NGQ',
        icaoCode: 'ZUAL',
        latitude: '32,1',
        longitude: '80,05305556',
        altitude: '14022',
        timezone: '8',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Cochstedt Airport',
        city: 'Cochstedt',
        country: 'Germany',
        iataCode: 'CSO',
        icaoCode: 'EDBC',
        latitude: '51,85639954',
        longitude: '11,42029953',
        altitude: '594',
        timezone: '1',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Collin County Regional At Mc Kinney Airport',
        city: 'DALLAS',
        country: 'United States',
        iataCode: 'TKI',
        icaoCode: 'KTKI',
        latitude: '33,17789841',
        longitude: '-96,59049988',
        altitude: '585',
        timezone: '-6',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Chicago Executive Airport',
        city: 'Chicago-Wheeling',
        country: 'United States',
        iataCode: 'PWK',
        icaoCode: 'KPWK',
        latitude: '42,114222',
        longitude: '-87,901494',
        altitude: '647',
        timezone: '-6',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Southwest Washington Regional Airport',
        city: 'Kelso',
        country: 'United States',
        iataCode: 'KLS',
        icaoCode: 'KKLS',
        latitude: '46,11800003',
        longitude: '-122,8980026',
        altitude: '20',
        timezone: '-8',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Benešov Airport',
        city: 'Benesov',
        country: 'Czech Republic',
        iataCode: 'N',
        icaoCode: 'LKBE',
        latitude: '49,74079895',
        longitude: '14,64470005',
        altitude: '1319',
        timezone: '1',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Bougouni Airport',
        city: 'Bougouni',
        country: 'Mali',
        iataCode: 'N',
        icaoCode: 'GABG',
        latitude: '11,44999981',
        longitude: '-7,517000198',
        altitude: '1139',
        timezone: '0',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Tureia Airport',
        city: 'Tureia',
        country: 'French Polynesia',
        iataCode: 'ZTA',
        icaoCode: 'NTGY',
        latitude: '-20,78969955',
        longitude: '-138,5700073',
        altitude: '12',
        timezone: '-10',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'McMurdo Station Ice Runway',
        city: 'Ross Island',
        country: 'Antarctica',
        iataCode: 'N',
        icaoCode: 'NZIR',
        latitude: '-77,85399628',
        longitude: '166,4689941',
        altitude: '1',
        timezone: '12',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Keekorok Airport',
        city: 'Keekorok',
        country: 'Kenya',
        iataCode: 'N',
        icaoCode: 'HKKE',
        latitude: '-1,582999945',
        longitude: '35,25',
        altitude: '5800',
        timezone: '3',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Puerto Obaldia Airport',
        city: 'Puerto Obaldia',
        country: 'Panama',
        iataCode: 'PUE',
        icaoCode: 'MPOA',
        latitude: '8,667',
        longitude: '-77,418',
        altitude: '223',
        timezone: '-5',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Kerch Airport',
        city: 'Kerch',
        country: 'Ukraine',
        iataCode: 'KHC',
        icaoCode: 'UKFK',
        latitude: '45,37250137',
        longitude: '36,40140152',
        altitude: '171',
        timezone: '3',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Ukunda Airstrip',
        city: 'Ukunda',
        country: 'Kenya',
        iataCode: 'UKA',
        icaoCode: 'HKUK',
        latitude: '-4,293330193',
        longitude: '39,57109833',
        altitude: '98',
        timezone: '3',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Wilmington Airpark',
        city: 'Wilmington',
        country: 'United States',
        iataCode: 'ILN',
        icaoCode: 'KILN',
        latitude: '39,42789841',
        longitude: '-83,792099',
        altitude: '1077',
        timezone: '-5',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Marana Regional Airport',
        city: 'Tucson',
        country: 'United States',
        iataCode: 'AVW',
        icaoCode: 'KAVQ',
        latitude: '32,4095993',
        longitude: '-111,2180023',
        altitude: '2031',
        timezone: '-7',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Casa Grande Municipal Airport',
        city: 'Casa Grande',
        country: 'United States',
        iataCode: 'CGZ',
        icaoCode: 'KCGZ',
        latitude: '32,954899',
        longitude: '-111,766998',
        altitude: '1464',
        timezone: '-7',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Mobile Airport',
        city: 'Mobile',
        country: 'United States',
        iataCode: 'N',
        icaoCode: '1AZ0',
        latitude: '33,11190033',
        longitude: '-112,2689972',
        altitude: '1261',
        timezone: '-7',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Buckeye Municipal Airport',
        city: 'Buckeye',
        country: 'United States',
        iataCode: 'BXK',
        icaoCode: 'KBXK',
        latitude: '33,42039871',
        longitude: '-112,685997',
        altitude: '1033',
        timezone: '-7',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Gila Bend Municipal Airport',
        city: 'Gila Bend',
        country: 'United States',
        iataCode: 'E63',
        icaoCode: 'KE63',
        latitude: '32,95809937',
        longitude: '-112,6780014',
        altitude: '789',
        timezone: '-7',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'McMinn County Airport',
        city: 'Athens',
        country: 'United States',
        iataCode: 'MMI',
        icaoCode: 'KMMI',
        latitude: '35,39730072',
        longitude: '-84,56259918',
        altitude: '875',
        timezone: '-5',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Sterling Municipal Airport',
        city: 'Sterling',
        country: 'United States',
        iataCode: 'STK',
        icaoCode: 'KSTK',
        latitude: '40,61529922',
        longitude: '-103,2649994',
        altitude: '4040',
        timezone: '-7',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Rawlins Municipal Airport/Harvey Field',
        city: 'Rawlins',
        country: 'United States',
        iataCode: 'RWL',
        icaoCode: 'KRWL',
        latitude: '41,80559921',
        longitude: '-107,1999969',
        altitude: '6813',
        timezone: '-7',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Mackenzie Airport',
        city: 'Mackenzie British Columbia',
        country: 'Canada',
        iataCode: 'YZY',
        icaoCode: 'CYZY',
        latitude: '55,3044014',
        longitude: '-123,1320038',
        altitude: '2264',
        timezone: '-8',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Essex County Airport',
        city: 'Caldwell',
        country: 'United States',
        iataCode: 'CDW',
        icaoCode: 'KCDW',
        latitude: '40,87519836',
        longitude: '-74,28140259',
        altitude: '173',
        timezone: '-5',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Lee C Fine Memorial Airport',
        city: 'Kaiser Lake Ozark',
        country: 'United States',
        iataCode: 'AIZ',
        icaoCode: 'KAIZ',
        latitude: '38,09600067',
        longitude: '-92,54949951',
        altitude: '869',
        timezone: '-6',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Thomasville Regional Airport',
        city: 'Thomasville',
        country: 'United States',
        iataCode: 'TVI',
        icaoCode: 'KTVI',
        latitude: '30,90159988',
        longitude: '-83,88130188',
        altitude: '264',
        timezone: '-5',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Henderson Executive Airport',
        city: 'Henderson',
        country: 'United States',
        iataCode: 'HSH',
        icaoCode: 'KHND',
        latitude: '35,97280121',
        longitude: '-115,1340027',
        altitude: '2492',
        timezone: '-8',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Gostomel Airport',
        city: 'Kiev',
        country: 'Ukraine',
        iataCode: 'GML',
        icaoCode: 'UKKM',
        latitude: '50,60350037',
        longitude: '30,19190025',
        altitude: '517',
        timezone: '2',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Henry Tift Myers Airport',
        city: 'Tifton',
        country: 'United States',
        iataCode: 'TMA',
        icaoCode: 'KTMA',
        latitude: '31,42900085',
        longitude: '-83,4885025',
        altitude: '355',
        timezone: '-5',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Landshut Airport',
        city: 'Landshut',
        country: 'Germany',
        iataCode: 'N',
        icaoCode: 'EDML',
        latitude: '48,51166534',
        longitude: '12,03333282',
        altitude: '1312',
        timezone: '1',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Itzehoe/Hungriger Wolf Airport',
        city: 'Itzehoe',
        country: 'Germany',
        iataCode: 'N',
        icaoCode: 'EDHF',
        latitude: '53,9944458',
        longitude: '9,578611374',
        altitude: '89',
        timezone: '1',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Radom Airport',
        city: 'RADOM',
        country: 'Poland',
        iataCode: 'QXR',
        icaoCode: 'EPRA',
        latitude: '51,3891983',
        longitude: '21,21330071',
        altitude: '610',
        timezone: '1',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Phoenix Deer Valley Airport',
        city: 'Phoenix ',
        country: 'United States',
        iataCode: 'DVT',
        icaoCode: 'KDVT',
        latitude: '33,68830109',
        longitude: '-112,0830002',
        altitude: '1478',
        timezone: '-7',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Calgary / Springbank Airport',
        city: 'Calgary',
        country: 'Canada',
        iataCode: 'N',
        icaoCode: 'CYBW',
        latitude: '51,10309982',
        longitude: '-114,3740005',
        altitude: '3940',
        timezone: '-7',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Golden Airport',
        city: 'Golden',
        country: 'Canada',
        iataCode: 'YGE',
        icaoCode: 'CYGE',
        latitude: '51,299196',
        longitude: '-116,982002',
        altitude: '2575',
        timezone: '-7',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Revelstoke Airport',
        city: 'Revelstoke',
        country: 'Canada',
        iataCode: 'YRV',
        icaoCode: 'CYRV',
        latitude: '50,96670151',
        longitude: '-118,1829987',
        altitude: '1459',
        timezone: '-8',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Republic Airport',
        city: 'Farmingdale',
        country: 'United States',
        iataCode: 'N',
        icaoCode: 'KFRG',
        latitude: '40,72880173',
        longitude: '-73,41339874',
        altitude: '82',
        timezone: '-5',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Allstedt Airport',
        city: 'Allstedt',
        country: 'Germany',
        iataCode: 'N',
        icaoCode: 'EDBT',
        latitude: '51,3805542',
        longitude: '11,44666672',
        altitude: '932',
        timezone: '1',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'General Freire Airport',
        city: 'Curico',
        country: 'Chile',
        iataCode: 'N',
        icaoCode: 'SCIC',
        latitude: '-34,96666718',
        longitude: '-71,21639252',
        altitude: '722',
        timezone: '-4',
        dst: 'S',
        type: 'airport',
    },
    {
        name: 'Peine-Eddesse Airport',
        city: 'Peine',
        country: 'Germany',
        iataCode: 'N',
        icaoCode: 'EDVP',
        latitude: '52,40250015',
        longitude: '10,22888947',
        altitude: '249',
        timezone: '1',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'South Texas Regional Airport at Hondo',
        city: 'Hondo',
        country: 'United States',
        iataCode: 'HDO',
        icaoCode: 'KHDO',
        latitude: '29,35950089',
        longitude: '-99,17669678',
        altitude: '930',
        timezone: '-6',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Zhongwei Shapotou Airport',
        city: 'Zhongwei',
        country: 'China',
        iataCode: 'ZHY',
        icaoCode: 'ZLZW',
        latitude: '37,573125',
        longitude: '105,154454',
        altitude: '8202',
        timezone: '8',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'McKinley National Park Airport',
        city: 'McKinley Park',
        country: 'United States',
        iataCode: 'MCL',
        icaoCode: 'PAIN',
        latitude: '63,73260117',
        longitude: '-148,9109955',
        altitude: '1720',
        timezone: '-9',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Lake Hood Seaplane Base',
        city: 'Anchorage',
        country: 'United States',
        iataCode: 'LHD',
        icaoCode: 'PALH',
        latitude: '61,18000031',
        longitude: '-149,9720001',
        altitude: '71',
        timezone: '-9',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Prospect Creek Airport',
        city: 'Prospect Creek',
        country: 'United States',
        iataCode: 'PPC',
        icaoCode: 'PAPR',
        latitude: '66,81410217',
        longitude: '-150,6439972',
        altitude: '1095',
        timezone: '-9',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Khwai River Lodge Airport',
        city: 'Khwai River',
        country: 'Botswana',
        iataCode: 'KHW',
        icaoCode: 'FBKR',
        latitude: '-19,14999962',
        longitude: '23,78300095',
        altitude: '3000',
        timezone: '2',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Spremberg-Welzow Airport',
        city: 'Welzow',
        country: 'Germany',
        iataCode: 'N',
        icaoCode: 'EDCY',
        latitude: '51,57555771',
        longitude: '14,13694382',
        altitude: '374',
        timezone: '1',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Taichung Airport',
        city: 'Taichung',
        country: 'Taiwan',
        iataCode: 'TXG',
        icaoCode: 'RCLG',
        latitude: '24,18630028',
        longitude: '120,6539993',
        altitude: '369',
        timezone: '8',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Wheeling Ohio County Airport',
        city: 'Wheeling',
        country: 'United States',
        iataCode: 'HLG',
        icaoCode: 'KHLG',
        latitude: '40,17499924',
        longitude: '-80,64630127',
        altitude: '1195',
        timezone: '-5',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Fitzgerald Municipal Airport',
        city: 'Fitzgerald',
        country: 'United States',
        iataCode: 'FZG',
        icaoCode: 'KFZG',
        latitude: '31,68370056',
        longitude: '-83,27050018',
        altitude: '365',
        timezone: '-5',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Ye Airport',
        city: 'Ye',
        country: 'Burma',
        iataCode: 'XYE',
        icaoCode: 'VYYE',
        latitude: '15,30000019',
        longitude: '97,86699677',
        altitude: '30',
        timezone: '6,5',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Isla San Felix Airport',
        city: 'Isla San Felix',
        country: 'Chile',
        iataCode: 'N',
        icaoCode: 'SCFX',
        latitude: '-26,29389954',
        longitude: '-80,09619904',
        altitude: '165',
        timezone: '-4',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Shaibah Airport',
        city: 'Shaybah',
        country: 'Saudi Arabia',
        iataCode: 'N',
        icaoCode: 'OESB',
        latitude: '22,51449966',
        longitude: '53,96409988',
        altitude: '300',
        timezone: '3',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Al Maktoum International Airport',
        city: 'Dubai',
        country: 'United Arab Emirates',
        iataCode: 'DWC',
        icaoCode: 'OMDW',
        latitude: '24,896356',
        longitude: '55,161389',
        altitude: '114',
        timezone: '4',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Aransas County Airport',
        city: 'Rockport',
        country: 'United States',
        iataCode: 'RKP',
        icaoCode: 'KRKP',
        latitude: '28,08679962',
        longitude: '-97,04460144',
        altitude: '24',
        timezone: '-6',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Megève Airport',
        city: 'Verdun',
        country: 'France',
        iataCode: 'MVV',
        icaoCode: 'LFHM',
        latitude: '45,82080078',
        longitude: '6,652219772',
        altitude: '4823',
        timezone: '1',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Méribel Altiport',
        city: 'Ajaccio',
        country: 'France',
        iataCode: 'MFX',
        icaoCode: 'LFKX',
        latitude: '45,407003',
        longitude: '6,577942',
        altitude: '5636',
        timezone: '1',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Okaukuejo Airport',
        city: 'Okaukuejo',
        country: 'Namibia',
        iataCode: 'OKF',
        icaoCode: 'FYOO',
        latitude: '-19,14920044',
        longitude: '15,91189957',
        altitude: '3911',
        timezone: '1',
        dst: 'S',
        type: 'airport',
    },
    {
        name: 'Mokuti Lodge Airport',
        city: 'Mokuti Lodge',
        country: 'Namibia',
        iataCode: 'OKU',
        icaoCode: 'FYMO',
        latitude: '-18,81279945',
        longitude: '17,05940056',
        altitude: '3665',
        timezone: '1',
        dst: 'S',
        type: 'airport',
    },
    {
        name: 'Rotenburg (Wümme) Airport',
        city: 'Rotenburg Wuemme',
        country: 'Germany',
        iataCode: 'N',
        icaoCode: 'EDXQ',
        latitude: '53,12833405',
        longitude: '9,348610878',
        altitude: '98',
        timezone: '1',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Wipperfürth-Neye Airport',
        city: 'Wipperfuerth',
        country: 'Germany',
        iataCode: 'N',
        icaoCode: 'EDKN',
        latitude: '51,1241684',
        longitude: '7,373610973',
        altitude: '863',
        timezone: '1',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Osnabrück-Atterheide Airport',
        city: 'Osnabrueck',
        country: 'Germany',
        iataCode: 'N',
        icaoCode: 'EDWO',
        latitude: '52,2863884',
        longitude: '7,969721794',
        altitude: '285',
        timezone: '1',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Flugplatz Ballenstedt',
        city: 'Ballenstedt',
        country: 'Germany',
        iataCode: 'N',
        icaoCode: 'EDCB',
        latitude: '51,745834',
        longitude: '11,229722',
        altitude: '535',
        timezone: '1',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Hartenholm Airport',
        city: 'Hasenmoor',
        country: 'Germany',
        iataCode: 'N',
        icaoCode: 'EDHM',
        latitude: '53,91500092',
        longitude: '10,03555584',
        altitude: '108',
        timezone: '1',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Ganderkesee Atlas Airfield Airport',
        city: 'Ganderkesee',
        country: 'Germany',
        iataCode: 'N',
        icaoCode: 'EDWQ',
        latitude: '53,03610992',
        longitude: '8,505556107',
        altitude: '95',
        timezone: '1',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Nienburg-Holzbalge Airport',
        city: 'Nienburg Weser',
        country: 'Germany',
        iataCode: 'N',
        icaoCode: 'EDXI',
        latitude: '52,70972061',
        longitude: '9,162500381',
        altitude: '82',
        timezone: '1',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Damme Airport',
        city: 'Damme',
        country: 'Germany',
        iataCode: 'N',
        icaoCode: 'EDWC',
        latitude: '52,48749924',
        longitude: '8,185556412',
        altitude: '151',
        timezone: '1',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Borkenberge Airport',
        city: 'Duelmen',
        country: 'Germany',
        iataCode: 'N',
        icaoCode: 'EDLB',
        latitude: '51,77999878',
        longitude: '7,288055897',
        altitude: '157',
        timezone: '1',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Obermehler-Schlotheim Airport',
        city: 'Obermehler',
        country: 'Germany',
        iataCode: 'N',
        icaoCode: 'EDCO',
        latitude: '51,26777649',
        longitude: '10,63472176',
        altitude: '909',
        timezone: '1',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Hodenhagen Airport',
        city: 'Hodenhagen',
        country: 'Germany',
        iataCode: 'N',
        icaoCode: 'EDVH',
        latitude: '52,76111221',
        longitude: '9,605555534',
        altitude: '79',
        timezone: '1',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Grube Airport',
        city: 'Grube',
        country: 'Germany',
        iataCode: 'N',
        icaoCode: 'EDHB',
        latitude: '54,2444458',
        longitude: '11,0247221',
        altitude: '7',
        timezone: '1',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Tønder Airport',
        city: 'Toender',
        country: 'Denmark',
        iataCode: 'N',
        icaoCode: 'EKTD',
        latitude: '54,92969894',
        longitude: '8,84057045',
        altitude: '1',
        timezone: '1',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Celle-Arloh Airport',
        city: 'Celle',
        country: 'Germany',
        iataCode: 'N',
        icaoCode: 'EDVC',
        latitude: '52,68722153',
        longitude: '10,11138916',
        altitude: '207',
        timezone: '1',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Uelzen Airport',
        city: 'Uelzen',
        country: 'Germany',
        iataCode: 'N',
        icaoCode: 'EDVU',
        latitude: '52,98389053',
        longitude: '10,46500015',
        altitude: '246',
        timezone: '1',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Hamm-Lippewiesen Airport',
        city: 'Hamm',
        country: 'Germany',
        iataCode: 'N',
        icaoCode: 'EDLH',
        latitude: '51,68972015',
        longitude: '7,816111088',
        altitude: '190',
        timezone: '1',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Lüsse Airport',
        city: 'Luesse',
        country: 'Germany',
        iataCode: 'N',
        icaoCode: 'EDOJ',
        latitude: '52,14110947',
        longitude: '12,66472244',
        altitude: '217',
        timezone: '1',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Porta Westfalica Airport',
        city: 'Bad Oeynhausen',
        country: 'Germany',
        iataCode: 'N',
        icaoCode: 'EDVY',
        latitude: '52,22083282',
        longitude: '8,859167099',
        altitude: '148',
        timezone: '1',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Brilon/Hochsauerlandkreis Airport',
        city: 'Brilon',
        country: 'Germany',
        iataCode: 'N',
        icaoCode: 'EDKO',
        latitude: '51,40250015',
        longitude: '8,641667366',
        altitude: '1509',
        timezone: '1',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Hameln-Pyrmont Airport',
        city: 'Bad Pyrmont',
        country: 'Germany',
        iataCode: 'N',
        icaoCode: 'EDVW',
        latitude: '51,96666718',
        longitude: '9,291666985',
        altitude: '1178',
        timezone: '1',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Nordholz-Spieka Airfield',
        city: 'Cuxhaven',
        country: 'Germany',
        iataCode: 'N',
        icaoCode: 'EDXN',
        latitude: '53,767223',
        longitude: '8,643611',
        altitude: '72',
        timezone: '1',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Köthen Airport',
        city: 'Koethen',
        country: 'Germany',
        iataCode: 'N',
        icaoCode: 'EDCK',
        latitude: '51,7211113',
        longitude: '11,95277786',
        altitude: '305',
        timezone: '1',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'St. Michaelisdonn Airport',
        city: 'Sankt Michaelisdonn',
        country: 'Germany',
        iataCode: 'N',
        icaoCode: 'EDXM',
        latitude: '53,97805786',
        longitude: '9,144721985',
        altitude: '125',
        timezone: '1',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Salzgitter-Drütte Airport',
        city: 'Salzgitter',
        country: 'Germany',
        iataCode: 'N',
        icaoCode: 'EDVS',
        latitude: '52,15444565',
        longitude: '10,42666721',
        altitude: '328',
        timezone: '1',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Karlshöfen Airport',
        city: 'Karlshoefen',
        country: 'Germany',
        iataCode: 'N',
        icaoCode: 'EDWK',
        latitude: '53,33277893',
        longitude: '9,02833271',
        altitude: '20',
        timezone: '1',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Oldenburg-Hatten Airport',
        city: 'Oldenburg',
        country: 'Germany',
        iataCode: 'N',
        icaoCode: 'EDWH',
        latitude: '53,06888962',
        longitude: '8,313611031',
        altitude: '26',
        timezone: '1',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Rinteln Airport',
        city: 'Rinteln',
        country: 'Germany',
        iataCode: 'N',
        icaoCode: 'EDVR',
        latitude: '52,17527771',
        longitude: '9,053333282',
        altitude: '180',
        timezone: '1',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Münster-Telgte Airport',
        city: 'Muenster',
        country: 'Germany',
        iataCode: 'N',
        icaoCode: 'EDLT',
        latitude: '51,94444275',
        longitude: '7,773889065',
        altitude: '177',
        timezone: '1',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'St. Peter-Ording Airport',
        city: 'Sankt Peter-Ording',
        country: 'Germany',
        iataCode: 'PSH',
        icaoCode: 'EDXO',
        latitude: '54,30888748',
        longitude: '8,686944008',
        altitude: '7',
        timezone: '1',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Lüchow-Rehbeck Airport',
        city: 'Luechow',
        country: 'Germany',
        iataCode: 'N',
        icaoCode: 'EDHC',
        latitude: '53,01610947',
        longitude: '11,14444447',
        altitude: '49',
        timezone: '1',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Klietz/Scharlibbe Airport',
        city: 'Scharlibbe',
        country: 'Germany',
        iataCode: 'N',
        icaoCode: 'EDCL',
        latitude: '52,70944214',
        longitude: '12,07333279',
        altitude: '95',
        timezone: '1',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Burg Airport',
        city: 'Burg',
        country: 'Germany',
        iataCode: 'N',
        icaoCode: 'EDBG',
        latitude: '52,2416687',
        longitude: '11,85611057',
        altitude: '174',
        timezone: '1',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Crisp County Cordele Airport',
        city: 'Cordele',
        country: 'United States',
        iataCode: 'CKF',
        icaoCode: 'KCKF',
        latitude: '31,98880005',
        longitude: '-83,77390289',
        altitude: '310',
        timezone: '-5',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Ormond Beach Municipal Airport',
        city: 'Ormond Beach',
        country: 'United States',
        iataCode: 'OMN',
        icaoCode: 'KOMN',
        latitude: '29,30060005',
        longitude: '-81,11360168',
        altitude: '29',
        timezone: '-5',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Bad Neuenahr-Ahrweiler Airport',
        city: 'Bad Neuenahr',
        country: 'Germany',
        iataCode: 'N',
        icaoCode: 'EDRA',
        latitude: '50,5577774',
        longitude: '7,136388779',
        altitude: '673',
        timezone: '1',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Bad Dürkheim Airport',
        city: 'Bad Duerkheim',
        country: 'Germany',
        iataCode: 'N',
        icaoCode: 'EDRF',
        latitude: '49,47305679',
        longitude: '8,196389198',
        altitude: '351',
        timezone: '1',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Portland Troutdale Airport',
        city: 'Troutdale',
        country: 'United States',
        iataCode: 'TTD',
        icaoCode: 'KTTD',
        latitude: '45,54940033',
        longitude: '-122,401001',
        altitude: '39',
        timezone: '-8',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Portland Hillsboro Airport',
        city: 'Hillsboro',
        country: 'United States',
        iataCode: 'HIO',
        icaoCode: 'KHIO',
        latitude: '45,540401',
        longitude: '-122,949997',
        altitude: '208',
        timezone: '-8',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'One Police Plaza Heliport',
        city: 'New York',
        country: 'United States',
        iataCode: 'N',
        icaoCode: 'NK39',
        latitude: '40,71260071',
        longitude: '-73,99960327',
        altitude: '244',
        timezone: '-5',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Leverkusen Airport',
        city: 'Leverkusen',
        country: 'Germany',
        iataCode: 'N',
        icaoCode: 'EDKL',
        latitude: '51,01527786',
        longitude: '7,005556107',
        altitude: '157',
        timezone: '1',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Wershofen/Eifel Airport',
        city: 'Wershofen',
        country: 'Germany',
        iataCode: 'N',
        icaoCode: 'EDRV',
        latitude: '50,45138931',
        longitude: '6,783332825',
        altitude: '1581',
        timezone: '1',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Khost Airport',
        city: 'Khost',
        country: 'Afghanistan',
        iataCode: 'KHT',
        icaoCode: 'OAKS',
        latitude: '33,33340073',
        longitude: '69,95200348',
        altitude: '3756',
        timezone: '4,5',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Namtu Airport',
        city: 'Naypyidaw',
        country: 'Burma',
        iataCode: 'N',
        icaoCode: 'VYNT',
        latitude: '23,08300018',
        longitude: '97,38300323',
        altitude: '2000',
        timezone: '6,5',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Bend Municipal Airport',
        city: 'Bend',
        country: 'United States',
        iataCode: 'N',
        icaoCode: 'KBDN',
        latitude: '44,09479904',
        longitude: '-121,2009964',
        altitude: '3460',
        timezone: '-8',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Christmas Valley Airport',
        city: 'Christmas Valley',
        country: 'United States',
        iataCode: 'N',
        icaoCode: 'K62S',
        latitude: '43,23649979',
        longitude: '-120,6660004',
        altitude: '4317',
        timezone: '-8',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Burns Municipal Airport',
        city: 'Burns',
        country: 'United States',
        iataCode: 'N',
        icaoCode: 'KBNO',
        latitude: '43,59189987',
        longitude: '-118,9550018',
        altitude: '4148',
        timezone: '-8',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Prineville Airport',
        city: 'Prineville',
        country: 'United States',
        iataCode: 'N',
        icaoCode: 'KS39',
        latitude: '44,28699875',
        longitude: '-120,9039993',
        altitude: '3250',
        timezone: '-8',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Red Bluff Municipal Airport',
        city: 'Red Bluff',
        country: 'United States',
        iataCode: 'N',
        icaoCode: 'KRBL',
        latitude: '40,15069962',
        longitude: '-122,2519989',
        altitude: '352',
        timezone: '-8',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Marin County Airport - Gnoss Field',
        city: 'Novato',
        country: 'United States',
        iataCode: 'N',
        icaoCode: 'KDVO',
        latitude: '38,14360046',
        longitude: '-122,5559998',
        altitude: '2',
        timezone: '-8',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Lake County Airport',
        city: 'Lakeview',
        country: 'United States',
        iataCode: 'N',
        icaoCode: 'KLKV',
        latitude: '42,16109848',
        longitude: '-120,3990021',
        altitude: '4733',
        timezone: '-8',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Tillamook Airport',
        city: 'Tillamook',
        country: 'United States',
        iataCode: 'N',
        icaoCode: 'KTMK',
        latitude: '45,41820145',
        longitude: '-123,814003',
        altitude: '36',
        timezone: '-8',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Ontario Municipal Airport',
        city: 'Ontario',
        country: 'United States',
        iataCode: 'N',
        icaoCode: 'KONO',
        latitude: '44,02050018',
        longitude: '-117,0139999',
        altitude: '2193',
        timezone: '-7',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Columbia Gorge Regional the Dalles Municipal Airport',
        city: 'The Dalles',
        country: 'United States',
        iataCode: 'N',
        icaoCode: 'KDLS',
        latitude: '45,61849976',
        longitude: '-121,1669998',
        altitude: '247',
        timezone: '-8',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Montgomery County Airpark',
        city: 'Gaithersburg',
        country: 'United States',
        iataCode: 'GAI',
        icaoCode: 'KGAI',
        latitude: '39,16830063',
        longitude: '-77,16600037',
        altitude: '539',
        timezone: '-5',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Sharana Airstrip',
        city: 'Sharona',
        country: 'Afghanistan',
        iataCode: 'AZ3',
        icaoCode: 'OASA',
        latitude: '33,12575',
        longitude: '68,838517',
        altitude: '7340',
        timezone: '4,5',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Pembroke Airport',
        city: 'Pembroke',
        country: 'Canada',
        iataCode: 'YTA',
        icaoCode: 'CYTA',
        latitude: '45,86439896',
        longitude: '-77,25170135',
        altitude: '529',
        timezone: '-5',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Tsumeb Airport',
        city: 'Tsumeb',
        country: 'Namibia',
        iataCode: 'TSB',
        icaoCode: 'FYTM',
        latitude: '-19,26189995',
        longitude: '17,73250008',
        altitude: '4353',
        timezone: '1',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Suffield Heliport',
        city: 'Suffield',
        country: 'Canada',
        iataCode: 'YSD',
        icaoCode: 'CYSD',
        latitude: '50,26670074',
        longitude: '-111,1829987',
        altitude: '2525',
        timezone: '-7',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Blumenau Airport',
        city: 'BLUMENAU',
        country: 'Brazil',
        iataCode: 'BNU',
        icaoCode: 'SSBL',
        latitude: '-26,83060074',
        longitude: '-49,09030151',
        altitude: '60',
        timezone: '-3',
        dst: 'S',
        type: 'airport',
    },
    {
        name: 'Charlevoix Municipal Airport',
        city: 'Charelvoix',
        country: 'United States',
        iataCode: 'CVX',
        icaoCode: 'KCVX',
        latitude: '45,30479813',
        longitude: '-85,27480316',
        altitude: '669',
        timezone: '-5',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Mykines Heliport',
        city: 'Mykines',
        country: 'Faroe Islands',
        iataCode: 'N',
        icaoCode: 'EKMS',
        latitude: '62,10210037',
        longitude: '-7,6459198',
        altitude: '110',
        timezone: '0',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Cornwall Regional Airport',
        city: 'Cornwall',
        country: 'Canada',
        iataCode: 'YCC',
        icaoCode: 'CYCC',
        latitude: '45,09280014',
        longitude: '-74,56330109',
        altitude: '175',
        timezone: '-5',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Seppe Airport',
        city: 'Bosschenhoofd',
        country: 'Netherlands',
        iataCode: 'N',
        icaoCode: 'EHSE',
        latitude: '51,55469894',
        longitude: '4,552499771',
        altitude: '30',
        timezone: '1',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'St Stephan Airport',
        city: 'St.Stephan',
        country: 'Switzerland',
        iataCode: 'N',
        icaoCode: 'LSTS',
        latitude: '46,49739838',
        longitude: '7,41257',
        altitude: '3304',
        timezone: '1',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Zona da Mata Regional Airport',
        city: 'Juiz de Fora',
        country: 'Brazil',
        iataCode: 'IZA',
        icaoCode: 'SDZY',
        latitude: '-21,5130558',
        longitude: '-43,17305756',
        altitude: '1348',
        timezone: '-3',
        dst: 'S',
        type: 'airport',
    },
    {
        name: 'Flagler County Airport',
        city: 'Flagler',
        country: 'United States',
        iataCode: 'XFL',
        icaoCode: 'KXFL',
        latitude: '29,4673996',
        longitude: '-81,20629883',
        altitude: '33',
        timezone: '-5',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Morrisville Stowe State Airport',
        city: 'Morrisville',
        country: 'United States',
        iataCode: 'MVL',
        icaoCode: 'KMVL',
        latitude: '44,5345993',
        longitude: '-72,61399841',
        altitude: '732',
        timezone: '-5',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Dallas Executive Airport',
        city: 'Dallas',
        country: 'United States',
        iataCode: 'RBD',
        icaoCode: 'KRBD',
        latitude: '32,68090057',
        longitude: '-96,86820221',
        altitude: '660',
        timezone: '-6',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Como (Idroscalo - Water Ad) Hidroport',
        city: 'Como',
        country: 'Italy',
        iataCode: 'N',
        icaoCode: 'LILY',
        latitude: '45,814701',
        longitude: '9,06972',
        altitude: '663',
        timezone: '1',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Westerly State Airport',
        city: 'Washington County',
        country: 'United States',
        iataCode: 'WST',
        icaoCode: 'KWST',
        latitude: '41,34960175',
        longitude: '-71,80339813',
        altitude: '81',
        timezone: '-5',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Block Island State Airport',
        city: 'Block Island',
        country: 'United States',
        iataCode: 'BID',
        icaoCode: 'KBID',
        latitude: '41,16809845',
        longitude: '-71,57779694',
        altitude: '108',
        timezone: '-5',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Nightmute Airport',
        city: 'Nightmute',
        country: 'United States',
        iataCode: 'NME',
        icaoCode: 'PAGT',
        latitude: '60,47100067',
        longitude: '-164,701004',
        altitude: '4',
        timezone: '-9',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Toksook Bay Airport',
        city: 'Toksook Bay',
        country: 'United States',
        iataCode: 'OOK',
        icaoCode: 'PAOO',
        latitude: '60,54140091',
        longitude: '-165,0870056',
        altitude: '59',
        timezone: '-9',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Ittoqqortoormiit Heliport',
        city: 'Ittoqqortoormiit',
        country: 'Greenland',
        iataCode: 'OBY',
        icaoCode: 'BGSC',
        latitude: '70,48822882',
        longitude: '-21,97167993',
        altitude: '238',
        timezone: '-1',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Vinnytsia/Gavyryshivka Airport',
        city: 'Vinnitsa',
        country: 'Ukraine',
        iataCode: 'VIN',
        icaoCode: 'UKWW',
        latitude: '49,242531',
        longitude: '28,613778',
        altitude: '961',
        timezone: '2',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Decatur County Industrial Air Park',
        city: 'Bainbridge',
        country: 'United States',
        iataCode: 'BGE',
        icaoCode: 'KBGE',
        latitude: '30,9715004',
        longitude: '-84,63739777',
        altitude: '141',
        timezone: '-5',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'La Romaine Airport',
        city: 'La Romaine',
        country: 'Canada',
        iataCode: 'N',
        icaoCode: 'CTT5',
        latitude: '50,25970078',
        longitude: '-60,6794014',
        altitude: '90',
        timezone: '-4',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Kegaska Airport',
        city: 'Kegaska',
        country: 'Canada',
        iataCode: 'ZKG',
        icaoCode: 'CTK6',
        latitude: '50,19580078',
        longitude: '-61,26580048',
        altitude: '32',
        timezone: '-4',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Black Tickle Airport',
        city: 'Black Tickle',
        country: 'Canada',
        iataCode: 'YBI',
        icaoCode: 'CCE4',
        latitude: '53,4693985',
        longitude: '-55,78499985',
        altitude: '57',
        timezone: '-4',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Silver Springs Airport',
        city: 'Silver Springs',
        country: 'United States',
        iataCode: 'SPZ',
        icaoCode: 'KSPZ',
        latitude: '39,40299988',
        longitude: '-119,2509995',
        altitude: '4269',
        timezone: '-8',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Whiteman Airport',
        city: 'Los Angeles',
        country: 'United States',
        iataCode: 'WHP',
        icaoCode: 'KWHP',
        latitude: '34,25930023',
        longitude: '-118,413002',
        altitude: '1003',
        timezone: '-8',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Madera Municipal Airport',
        city: 'Madera',
        country: 'United States',
        iataCode: 'MAE',
        icaoCode: 'KMAE',
        latitude: '36,98860168',
        longitude: '-120,1119995',
        altitude: '255',
        timezone: '-8',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Trail Airport',
        city: 'Trail',
        country: 'Canada',
        iataCode: 'YZZ',
        icaoCode: 'CAD4',
        latitude: '49,05559921',
        longitude: '-117,6090012',
        altitude: '1427',
        timezone: '-8',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Victoria Airport',
        city: 'Patricia Bay',
        country: 'Canada',
        iataCode: 'N',
        icaoCode: 'CAP5',
        latitude: '48,65389205',
        longitude: '-123,4504509',
        altitude: '0',
        timezone: '-8',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Old Arctic Bay Airport',
        city: 'Arctic Bay',
        country: 'Canada',
        iataCode: 'YAB',
        icaoCode: 'CJX7',
        latitude: '73,00589225',
        longitude: '-85,0325489',
        altitude: '100',
        timezone: '-6',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Birchwood Airport',
        city: 'Belmopan',
        country: 'Belize',
        iataCode: 'BCV',
        icaoCode: 'PABV',
        latitude: '61,41650009',
        longitude: '-149,5070038',
        altitude: '83',
        timezone: '-9',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Grand-Santi Airport',
        city: 'Grand-Santi',
        country: 'French Guiana',
        iataCode: 'N',
        icaoCode: 'SOGS',
        latitude: '4,285833',
        longitude: '-54,373056',
        altitude: '207',
        timezone: '-3',
        dst: 'S',
        type: 'airport',
    },
    {
        name: 'Maripasoula Airport',
        city: 'Maripasoula',
        country: 'French Guiana',
        iataCode: 'MPY',
        icaoCode: 'SOOA',
        latitude: '3,6575',
        longitude: '-54,037201',
        altitude: '406',
        timezone: '-3',
        dst: 'S',
        type: 'airport',
    },
    {
        name: 'Saint-Laurent-du-Maroni Airport',
        city: 'Saint-Laurent-du-Maroni',
        country: 'French Guiana',
        iataCode: 'LDX',
        icaoCode: 'SOOM',
        latitude: '5,48306',
        longitude: '-54,034401',
        altitude: '16',
        timezone: '-3',
        dst: 'S',
        type: 'airport',
    },
    {
        name: 'Kanas Airport',
        city: 'Burqin',
        country: 'China',
        iataCode: 'KJI',
        icaoCode: 'ZWKN',
        latitude: '48,2223',
        longitude: '86,9959',
        altitude: '3921',
        timezone: '8',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Capurganá Airport',
        city: 'Capurgana',
        country: 'Colombia',
        iataCode: 'CPB',
        icaoCode: 'SKCA',
        latitude: '8,63333',
        longitude: '-77,35',
        altitude: '49',
        timezone: '-5',
        dst: 'S',
        type: 'airport',
    },
    {
        name: 'Sohag International Airport',
        city: 'Sohag',
        country: 'Egypt',
        iataCode: 'HMB',
        icaoCode: 'HEMK',
        latitude: '26,342778',
        longitude: '31,742778',
        altitude: '310',
        timezone: '2',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Presidente General Don Oscar D. Gestido International Airport',
        city: 'Rivera',
        country: 'Uruguay',
        iataCode: 'RVY',
        icaoCode: 'SURV',
        latitude: '-30,97459984',
        longitude: '-55,4762001',
        altitude: '712',
        timezone: '-3',
        dst: 'S',
        type: 'airport',
    },
    {
        name: 'Patos de Minas Airport',
        city: 'Patos de Minas',
        country: 'Brazil',
        iataCode: 'POJ',
        icaoCode: 'SNPD',
        latitude: '-18,67280006',
        longitude: '-46,49119949',
        altitude: '2793',
        timezone: '-3',
        dst: 'S',
        type: 'airport',
    },
    {
        name: 'Bauru - Arealva Airport',
        city: 'Bauru',
        country: 'Brazil',
        iataCode: 'JTC',
        icaoCode: 'SJTC',
        latitude: '-22,16685914',
        longitude: '-49,05028667',
        altitude: '1949',
        timezone: '-3',
        dst: 'S',
        type: 'airport',
    },
    {
        name: 'Ourilândia do Norte Airport',
        city: 'Ourilandia do Norte',
        country: 'Brazil',
        iataCode: 'OIA',
        icaoCode: 'SDOW',
        latitude: '-6,763100147',
        longitude: '-51,04990005',
        altitude: '901',
        timezone: '-3',
        dst: 'S',
        type: 'airport',
    },
    {
        name: 'Redenção Airport',
        city: 'Redencao',
        country: 'Brazil',
        iataCode: 'RDC',
        icaoCode: 'SNDC',
        latitude: '-8,033289909',
        longitude: '-49,97990036',
        altitude: '670',
        timezone: '-3',
        dst: 'S',
        type: 'airport',
    },
    {
        name: 'São Félix do Xingu Airport',
        city: 'Sao Felix do Xingu',
        country: 'Brazil',
        iataCode: 'SXX',
        icaoCode: 'SNFX',
        latitude: '-6,6413',
        longitude: '-51,9523',
        altitude: '656',
        timezone: '-3',
        dst: 'S',
        type: 'airport',
    },
    {
        name: 'Bonito Airport',
        city: 'Bointo',
        country: 'Brazil',
        iataCode: 'BYO',
        icaoCode: 'SJDB',
        latitude: '-21,247299',
        longitude: '-56,452499',
        altitude: '1180',
        timezone: '-4',
        dst: 'S',
        type: 'airport',
    },
    {
        name: 'São Félix do Araguaia Airport',
        city: 'Sao Felix do Araguaia',
        country: 'Brazil',
        iataCode: 'SXO',
        icaoCode: 'SWFX',
        latitude: '-11,63239956',
        longitude: '-50,6896019',
        altitude: '650',
        timezone: '-4',
        dst: 'S',
        type: 'airport',
    },
    {
        name: 'Caçador Airport',
        city: 'Cacador',
        country: 'Brazil',
        iataCode: 'CFC',
        icaoCode: 'SBCD',
        latitude: '-26,78840065',
        longitude: '-50,93980026',
        altitude: '3376',
        timezone: '-3',
        dst: 'S',
        type: 'airport',
    },
    {
        name: 'Carauari Airport',
        city: 'Carauari',
        country: 'Brazil',
        iataCode: 'CAF',
        icaoCode: 'SWCA',
        latitude: '-4,871520042',
        longitude: '-66,89749908',
        altitude: '355',
        timezone: '-4',
        dst: 'S',
        type: 'airport',
    },
    {
        name: 'Urucu Airport',
        city: 'Porto Urucu',
        country: 'Brazil',
        iataCode: 'N',
        icaoCode: 'SWUY',
        latitude: '-4,884220123',
        longitude: '-65,35540009',
        altitude: '243',
        timezone: '-4',
        dst: 'S',
        type: 'airport',
    },
    {
        name: 'Eirunepé Airport',
        city: 'Eirunepe',
        country: 'Brazil',
        iataCode: 'ERN',
        icaoCode: 'SWEI',
        latitude: '-6,639530182',
        longitude: '-69,87979889',
        altitude: '412',
        timezone: '-4',
        dst: 'S',
        type: 'airport',
    },
    {
        name: 'Concórdia Airport',
        city: 'Concordia',
        country: 'Brazil',
        iataCode: 'CCI',
        icaoCode: 'SSCK',
        latitude: '-27,18059921',
        longitude: '-52,05270004',
        altitude: '2461',
        timezone: '-3',
        dst: 'S',
        type: 'airport',
    },
    {
        name: 'Francisco Beltrão Airport',
        city: 'Francisco Beltrao',
        country: 'Brazil',
        iataCode: 'FBE',
        icaoCode: 'SSFB',
        latitude: '-26,05920029',
        longitude: '-53,06349945',
        altitude: '2100',
        timezone: '-3',
        dst: 'S',
        type: 'airport',
    },
    {
        name: 'Confresa Airport',
        city: 'Confresa',
        country: 'Brazil',
        iataCode: 'CFO',
        icaoCode: 'SJHG',
        latitude: '-10,63440037',
        longitude: '-51,56359863',
        altitude: '781',
        timezone: '-4',
        dst: 'S',
        type: 'airport',
    },
    {
        name: 'Apalachicola Regional Airport',
        city: 'Apalachicola',
        country: 'United States',
        iataCode: 'AAF',
        icaoCode: 'KAAF',
        latitude: '29,72750092',
        longitude: '-85,02749634',
        altitude: '20',
        timezone: '-5',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Umuarama Airport',
        city: 'Umuarama',
        country: 'Brazil',
        iataCode: 'UMU',
        icaoCode: 'SSUM',
        latitude: '-23,79870033',
        longitude: '-53,31380081',
        altitude: '1558',
        timezone: '-3',
        dst: 'S',
        type: 'airport',
    },
    {
        name: 'Diamantina Airport',
        city: 'Diamantina',
        country: 'Brazil',
        iataCode: 'DTI',
        icaoCode: 'SNDT',
        latitude: '-18,23200035',
        longitude: '-43,65039825',
        altitude: '4446',
        timezone: '-3',
        dst: 'S',
        type: 'airport',
    },
    {
        name: 'Fonte Boa Airport',
        city: 'Fonte Boa',
        country: 'Brazil',
        iataCode: 'FBA',
        icaoCode: 'SWOB',
        latitude: '-2,53260994',
        longitude: '-66,08319855',
        altitude: '207',
        timezone: '-4',
        dst: 'S',
        type: 'airport',
    },
    {
        name: 'Senadora Eunice Micheles Airport',
        city: 'Sao Paulo de Olivenca',
        country: 'Brazil',
        iataCode: 'OLC',
        icaoCode: 'SDCG',
        latitude: '-3,467929508',
        longitude: '-68,92041206',
        altitude: '335',
        timezone: '-4',
        dst: 'S',
        type: 'airport',
    },
    {
        name: 'Humaitá Airport',
        city: 'Humaita',
        country: 'Brazil',
        iataCode: 'HUW',
        icaoCode: 'SWHT',
        latitude: '-7,532120228',
        longitude: '-63,07210159',
        altitude: '230',
        timezone: '-4',
        dst: 'S',
        type: 'airport',
    },
    {
        name: 'Tapuruquara Airport',
        city: 'Santa Isabel do Rio Negro',
        country: 'Brazil',
        iataCode: 'IRZ',
        icaoCode: 'SWTP',
        latitude: '-0,3786',
        longitude: '-64,9923',
        altitude: '223',
        timezone: '-4',
        dst: 'S',
        type: 'airport',
    },
    {
        name: 'Oriximiná Airport',
        city: 'Oriximina',
        country: 'Brazil',
        iataCode: 'ORX',
        icaoCode: 'SNOX',
        latitude: '-1,714079976',
        longitude: '-55,83620071',
        altitude: '262',
        timezone: '-3',
        dst: 'S',
        type: 'airport',
    },
    {
        name: 'Hotel Transamérica Airport',
        city: 'Una',
        country: 'Brazil',
        iataCode: 'UNA',
        icaoCode: 'SBTC',
        latitude: '-15,35519981',
        longitude: '-38,99900055',
        altitude: '20',
        timezone: '-3',
        dst: 'S',
        type: 'airport',
    },
    {
        name: 'Telfer Airport',
        city: 'Telfer',
        country: 'Australia',
        iataCode: 'TEF',
        icaoCode: 'YTEF',
        latitude: '-21,71500015',
        longitude: '122,2289963',
        altitude: '970',
        timezone: '8',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Gazipaşa Airport',
        city: 'Alanya',
        country: 'Turkey',
        iataCode: 'GZP',
        icaoCode: 'LTGP',
        latitude: '36,29921722',
        longitude: '32,30059814',
        altitude: '86',
        timezone: '3',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Douglas Municipal Airport',
        city: 'Douglas',
        country: 'United States',
        iataCode: 'DQH',
        icaoCode: 'KDQH',
        latitude: '31,47669983',
        longitude: '-82,86049652',
        altitude: '257',
        timezone: '-5',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'St Lucie County International Airport',
        city: 'Fort Pierce',
        country: 'United States',
        iataCode: 'FRP',
        icaoCode: 'KFPR',
        latitude: '27,49510002',
        longitude: '-80,36830139',
        altitude: '24',
        timezone: '-5',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Taunton Municipal King Field',
        city: 'Taunton',
        country: 'United States',
        iataCode: 'TAN',
        icaoCode: 'KTAN',
        latitude: '41,87440109',
        longitude: '-71,01660156',
        altitude: '43',
        timezone: '-5',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Plymouth Municipal Airport',
        city: 'Plymouth',
        country: 'United States',
        iataCode: 'PYM',
        icaoCode: 'KPYM',
        latitude: '41,9090004',
        longitude: '-70,72879791',
        altitude: '148',
        timezone: '-5',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Quonset State Airport',
        city: 'North Kingstown',
        country: 'United States',
        iataCode: 'OQU',
        icaoCode: 'KOQU',
        latitude: '41,5970993',
        longitude: '-71,41210175',
        altitude: '18',
        timezone: '-5',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Norwood Memorial Airport',
        city: 'Norwood',
        country: 'United States',
        iataCode: 'OWD',
        icaoCode: 'KOWD',
        latitude: '42,19049835',
        longitude: '-71,17289734',
        altitude: '49',
        timezone: '-5',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Barnes Municipal Airport',
        city: 'Westfield',
        country: 'United States',
        iataCode: 'BAF',
        icaoCode: 'KBAF',
        latitude: '42,157799',
        longitude: '-72,715599',
        altitude: '271',
        timezone: '-5',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Windham Airport',
        city: 'Willimantic',
        country: 'United States',
        iataCode: 'IJD',
        icaoCode: 'KIJD',
        latitude: '41,74399948',
        longitude: '-72,18029785',
        altitude: '247',
        timezone: '-5',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Orange County Airport',
        city: 'Montgomery',
        country: 'United States',
        iataCode: 'MGJ',
        icaoCode: 'KMGJ',
        latitude: '41,50999832',
        longitude: '-74,26460266',
        altitude: '364',
        timezone: '-5',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Capital City Airport',
        city: 'Harrisburg',
        country: 'United States',
        iataCode: 'CXY',
        icaoCode: 'KCXY',
        latitude: '40,21709824',
        longitude: '-76,85150146',
        altitude: '347',
        timezone: '-5',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Marshfield Municipal George Harlow Field',
        city: 'Marshfield',
        country: 'United States',
        iataCode: 'GHG',
        icaoCode: 'KGHG',
        latitude: '42,09830093',
        longitude: '-70,67220306',
        altitude: '11',
        timezone: '-5',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Danbury Municipal Airport',
        city: 'Danbury',
        country: 'United States',
        iataCode: 'DXR',
        icaoCode: 'KDXR',
        latitude: '41,37149811',
        longitude: '-73,48220062',
        altitude: '458',
        timezone: '-5',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Boire Field',
        city: 'Nashua',
        country: 'United States',
        iataCode: 'ASH',
        icaoCode: 'KASH',
        latitude: '42,78170013',
        longitude: '-71,51480103',
        altitude: '199',
        timezone: '-5',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Lawrence Municipal Airport',
        city: 'Lawrence',
        country: 'United States',
        iataCode: 'LWM',
        icaoCode: 'KLWM',
        latitude: '42,71720123',
        longitude: '-71,12339783',
        altitude: '148',
        timezone: '-5',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Waterbury Oxford Airport',
        city: 'Oxford',
        country: 'United States',
        iataCode: 'OXC',
        icaoCode: 'KOXC',
        latitude: '41,47859955',
        longitude: '-73,1352005',
        altitude: '726',
        timezone: '-5',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Fitchburg Municipal Airport',
        city: 'Fitchburg',
        country: 'United States',
        iataCode: 'FIT',
        icaoCode: 'KFIT',
        latitude: '42,55410004',
        longitude: '-71,75900269',
        altitude: '348',
        timezone: '-5',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Earl L. Small Jr. Field/Stockmar Airport',
        city: 'Villa Rica',
        country: 'United States',
        iataCode: 'N',
        icaoCode: '20GA',
        latitude: '33,75650024',
        longitude: '-84,88469696',
        altitude: '1110',
        timezone: '-5',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Cartersville Airport',
        city: 'Cartersville',
        country: 'United States',
        iataCode: 'VPC',
        icaoCode: 'KVPC',
        latitude: '34,12310028',
        longitude: '-84,84870148',
        altitude: '759',
        timezone: '-5',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Centre-Piedmont-Cherokee County Regional Airport',
        city: 'Centre',
        country: 'United States',
        iataCode: 'PYP',
        icaoCode: 'KPYP',
        latitude: '34,089977',
        longitude: '-85,610069',
        altitude: '596',
        timezone: '-6',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Richard B Russell Airport',
        city: 'Rome',
        country: 'United States',
        iataCode: 'RMG',
        icaoCode: 'KRMG',
        latitude: '34,3506012',
        longitude: '-85,15799713',
        altitude: '644',
        timezone: '-5',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Northeast Alabama Regional Airport',
        city: 'Gadsden',
        country: 'United States',
        iataCode: 'GAD',
        icaoCode: 'KGAD',
        latitude: '33,972599',
        longitude: '-86,088996',
        altitude: '569',
        timezone: '-6',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Knoxville Downtown Island Airport',
        city: 'Knoxville',
        country: 'United States',
        iataCode: 'DKX',
        icaoCode: 'KDKX',
        latitude: '35,96390152',
        longitude: '-83,87390137',
        altitude: '833',
        timezone: '-5',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Barrow County Airport',
        city: 'Winder',
        country: 'United States',
        iataCode: 'WDR',
        icaoCode: 'KWDR',
        latitude: '33,98289871',
        longitude: '-83,66739655',
        altitude: '943',
        timezone: '-5',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Plantation Airpark',
        city: 'Sylvania',
        country: 'United States',
        iataCode: 'JYL',
        icaoCode: 'KJYL',
        latitude: '32,64530182',
        longitude: '-81,5970993',
        altitude: '188',
        timezone: '-5',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Dalton Municipal Airport',
        city: 'Dalton',
        country: 'United States',
        iataCode: 'DNN',
        icaoCode: 'KDNN',
        latitude: '34,72290039',
        longitude: '-84,87020111',
        altitude: '709',
        timezone: '-5',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'West Georgia Regional O V Gray Field',
        city: 'Carrollton',
        country: 'United States',
        iataCode: 'CTJ',
        icaoCode: 'KCTJ',
        latitude: '33,63100052',
        longitude: '-85,15200043',
        altitude: '1161',
        timezone: '-5',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Lagrange Callaway Airport',
        city: 'LaGrange',
        country: 'United States',
        iataCode: 'LGC',
        icaoCode: 'KLGC',
        latitude: '33,00889969',
        longitude: '-85,07260132',
        altitude: '693',
        timezone: '-5',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Baldwin County Airport',
        city: 'Milledgeville',
        country: 'United States',
        iataCode: 'MLJ',
        icaoCode: 'KMLJ',
        latitude: '33,15420151',
        longitude: '-83,24069977',
        altitude: '385',
        timezone: '-5',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Harris County Airport',
        city: 'Pine Mountain',
        country: 'United States',
        iataCode: 'PIM',
        icaoCode: 'KPIM',
        latitude: '32,84069824',
        longitude: '-84,88240051',
        altitude: '902',
        timezone: '-5',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Peachtree City Falcon Field',
        city: 'Atlanta',
        country: 'United States',
        iataCode: 'FFC',
        icaoCode: 'KFFC',
        latitude: '33,3572998',
        longitude: '-84,57180023',
        altitude: '808',
        timezone: '-5',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Lee Gilmer Memorial Airport',
        city: 'Gainesville',
        country: 'United States',
        iataCode: 'GVL',
        icaoCode: 'KGVL',
        latitude: '34,27259827',
        longitude: '-83,8302002',
        altitude: '1276',
        timezone: '-5',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Harry Clever Field',
        city: 'New Philadelpha',
        country: 'United States',
        iataCode: 'PHD',
        icaoCode: 'KPHD',
        latitude: '40,47090149',
        longitude: '-81,41970062',
        altitude: '894',
        timezone: '-5',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Darlington County Jetport Airport',
        city: 'Darlington',
        country: 'United States',
        iataCode: 'UDG',
        icaoCode: 'KUDG',
        latitude: '34,44940186',
        longitude: '-79,89009857',
        altitude: '192',
        timezone: '-5',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Hilton Head Airport',
        city: 'Hilton Head Island',
        country: 'United States',
        iataCode: 'HHH',
        icaoCode: 'KHXD',
        latitude: '32,22439957',
        longitude: '-80,69750214',
        altitude: '19',
        timezone: '-5',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Daniel Field',
        city: 'Augusta',
        country: 'United States',
        iataCode: 'DNL',
        icaoCode: 'KDNL',
        latitude: '33,46649933',
        longitude: '-82,03939819',
        altitude: '423',
        timezone: '-5',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Foothills Regional Airport',
        city: 'Morganton',
        country: 'United States',
        iataCode: 'MRN',
        icaoCode: 'KMRN',
        latitude: '35,82020187',
        longitude: '-81,61139679',
        altitude: '1270',
        timezone: '-5',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Pike County-Hatcher Field',
        city: 'Pikeville',
        country: 'United States',
        iataCode: 'PBX',
        icaoCode: 'KPBX',
        latitude: '37,5617981',
        longitude: '-82,56639862',
        altitude: '1473',
        timezone: '-5',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Mallards Landing Airport',
        city: 'Locust Grove',
        country: 'United States',
        iataCode: 'N',
        icaoCode: 'GA04',
        latitude: '33,36569977',
        longitude: '-84,16519928',
        altitude: '837',
        timezone: '-5',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Toccoa Airport - R.G. Letourneau Field',
        city: 'Toccoa',
        country: 'United States',
        iataCode: 'TOC',
        icaoCode: 'KTOC',
        latitude: '34,59379959',
        longitude: '-83,29579926',
        altitude: '996',
        timezone: '-5',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Compton Abbas Aerodrome',
        city: 'Shaftesbury',
        country: 'United Kingdom',
        iataCode: 'N',
        icaoCode: 'EGHA',
        latitude: '50,96720123',
        longitude: '-2,153609991',
        altitude: '811',
        timezone: '0',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Suprunovka Airport',
        city: 'Poltava',
        country: 'Ukraine',
        iataCode: 'PLV',
        icaoCode: 'UKHP',
        latitude: '49,5685997',
        longitude: '34,39720154',
        altitude: '505',
        timezone: '2',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Aweil Airport',
        city: 'Aweil',
        country: 'Sudan',
        iataCode: 'N',
        icaoCode: 'HSAW',
        latitude: '8,767000198',
        longitude: '27,39999962',
        altitude: '1394',
        timezone: '3',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Wau Airport',
        city: 'Wau',
        country: 'Sudan',
        iataCode: 'WUU',
        icaoCode: 'HSWW',
        latitude: '7,725830078',
        longitude: '27,97500038',
        altitude: '1529',
        timezone: '3',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Humera Airport',
        city: 'Humera',
        country: 'Ethiopia',
        iataCode: 'HUE',
        icaoCode: 'HAHU',
        latitude: '14,25',
        longitude: '36,58300018',
        altitude: '1930',
        timezone: '3',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Moyale Airport',
        city: 'Moyale',
        country: 'Kenya',
        iataCode: 'OYL',
        icaoCode: 'HKMY',
        latitude: '3,46972',
        longitude: '39,101398',
        altitude: '2790',
        timezone: '3',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Zagora Airport',
        city: 'Zagora',
        country: 'Morocco',
        iataCode: 'N',
        icaoCode: 'GMAZ',
        latitude: '30,32029915',
        longitude: '-5,866670132',
        altitude: '2631',
        timezone: '0',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Yengema Airport',
        city: 'Yengema',
        country: 'Sierra Leone',
        iataCode: 'WYE',
        icaoCode: 'GFYE',
        latitude: '8,610469818',
        longitude: '-11,04539967',
        altitude: '1300',
        timezone: '0',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Gbangbatok Airport',
        city: 'Gbangbatok',
        country: 'Sierra Leone',
        iataCode: 'GBK',
        icaoCode: 'GFGK',
        latitude: '7,767000198',
        longitude: '-12,38300037',
        altitude: '75',
        timezone: '0',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Turukhansk Airport',
        city: 'Turukhansk',
        country: 'Russia',
        iataCode: 'N',
        icaoCode: 'UOTT',
        latitude: '65,79720306',
        longitude: '87,93530273',
        altitude: '128',
        timezone: '7',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Podkamennaya Tunguska Airport',
        city: 'Bor',
        country: 'Russia',
        iataCode: 'N',
        icaoCode: 'UNIP',
        latitude: '61,58969879',
        longitude: '89,9940033',
        altitude: '213',
        timezone: '7',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Fort Worth Alliance Airport',
        city: 'Fort Worth',
        country: 'United States',
        iataCode: 'AFW',
        icaoCode: 'KAFW',
        latitude: '32,98759842',
        longitude: '-97,31880188',
        altitude: '722',
        timezone: '-6',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'East Troy Municipal Airport',
        city: 'East Troy',
        country: 'United States',
        iataCode: '57C',
        icaoCode: 'K57C',
        latitude: '42,79719925',
        longitude: '-88,37259674',
        altitude: '860',
        timezone: '-6',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Kolpashevo Airport',
        city: 'Kolpashevo',
        country: 'Russia',
        iataCode: 'N',
        icaoCode: 'UNLL',
        latitude: '58,32529831',
        longitude: '82,93250275',
        altitude: '243',
        timezone: '7',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Ali Al Salem Air Base',
        city: 'Kuwait',
        country: 'Kuwait',
        iataCode: 'N',
        icaoCode: 'OKAS',
        latitude: '29,34670067',
        longitude: '47,52080154',
        altitude: '472',
        timezone: '3',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Renmark Airport',
        city: 'Renmark',
        country: 'Australia',
        iataCode: 'RMK',
        icaoCode: 'YREN',
        latitude: '-34,19639969',
        longitude: '140,673996',
        altitude: '115',
        timezone: '9,5',
        dst: 'O',
        type: 'airport',
    },
    {
        name: 'Leigh Creek Airport',
        city: 'Leigh Creek',
        country: 'Australia',
        iataCode: 'LGH',
        icaoCode: 'YLEC',
        latitude: '-30,59830093',
        longitude: '138,4259949',
        altitude: '856',
        timezone: '9,5',
        dst: 'O',
        type: 'airport',
    },
    {
        name: 'Warburton Airport',
        city: 'Warburton Community',
        country: 'Australia',
        iataCode: 'N',
        icaoCode: 'YWBR',
        latitude: '-26,12829971',
        longitude: '126,5830002',
        altitude: '1500',
        timezone: '8',
        dst: 'O',
        type: 'airport',
    },
    {
        name: 'Cunderdin Airport',
        city: 'Cunderdin',
        country: 'Australia',
        iataCode: 'N',
        icaoCode: 'YCUN',
        latitude: '-31,62220001',
        longitude: '117,2170029',
        altitude: '705',
        timezone: '8',
        dst: 'O',
        type: 'airport',
    },
    {
        name: 'Rottnest Island Airport',
        city: 'Rottnest Island',
        country: 'Australia',
        iataCode: 'RTS',
        icaoCode: 'YRTI',
        latitude: '-32,00669861',
        longitude: '115,5400009',
        altitude: '12',
        timezone: '8',
        dst: 'O',
        type: 'airport',
    },
    {
        name: 'Forrest Airport',
        city: 'Forrest',
        country: 'Australia',
        iataCode: 'N',
        icaoCode: 'YFRT',
        latitude: '-30,83810043',
        longitude: '128,1150055',
        altitude: '511',
        timezone: '8',
        dst: 'O',
        type: 'airport',
    },
    {
        name: 'Ballarat Airport',
        city: 'Ballarat',
        country: 'Australia',
        iataCode: 'N',
        icaoCode: 'YBLT',
        latitude: '-37,51169968',
        longitude: '143,7910004',
        altitude: '1433',
        timezone: '10',
        dst: 'O',
        type: 'airport',
    },
    {
        name: 'Keewaywin Airport',
        city: 'Keewaywin',
        country: 'Canada',
        iataCode: 'KEW',
        icaoCode: 'CPV8',
        latitude: '52,99110031',
        longitude: '-92,83640289',
        altitude: '988',
        timezone: '-6',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Marathon Airport',
        city: 'Marathon',
        country: 'Canada',
        iataCode: 'YSP',
        icaoCode: 'CYSP',
        latitude: '48,75529861',
        longitude: '-86,3443985',
        altitude: '1035',
        timezone: '-5',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Hearst René Fontaine Municipal Airport',
        city: 'Hearst',
        country: 'Canada',
        iataCode: 'YHF',
        icaoCode: 'CYHF',
        latitude: '49,71419907',
        longitude: '-83,68609619',
        altitude: '827',
        timezone: '-5',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Hornepayne Municipal Airport',
        city: 'Hornepayne',
        country: 'Canada',
        iataCode: 'YHN',
        icaoCode: 'CYHN',
        latitude: '49,19309998',
        longitude: '-84,7589035',
        altitude: '1099',
        timezone: '-5',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Kirkland Lake Airport',
        city: 'Kirkland Lake',
        country: 'Canada',
        iataCode: 'YKX',
        icaoCode: 'CYKX',
        latitude: '48,21030045',
        longitude: '-79,98139954',
        altitude: '1157',
        timezone: '-5',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Manitouwadge Airport',
        city: 'Manitouwadge',
        country: 'Canada',
        iataCode: 'YMG',
        icaoCode: 'CYMG',
        latitude: '49,08390045',
        longitude: '-85,86060333',
        altitude: '1198',
        timezone: '-5',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Wawa Airport',
        city: 'Wawa',
        country: 'Canada',
        iataCode: 'YXZ',
        icaoCode: 'CYXZ',
        latitude: '47,96670151',
        longitude: '-84,78669739',
        altitude: '942',
        timezone: '-5',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Manitoulin East Municipal Airport',
        city: 'Manitowaning',
        country: 'Canada',
        iataCode: 'YEM',
        icaoCode: 'CYEM',
        latitude: '45,84280014',
        longitude: '-81,85810089',
        altitude: '869',
        timezone: '-5',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Slate Falls Airport',
        city: 'Slate Falls',
        country: 'Canada',
        iataCode: 'N',
        icaoCode: 'CKD9',
        latitude: '51,13000107',
        longitude: '-91,66560364',
        altitude: '1355',
        timezone: '-6',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Collingwood Airport',
        city: 'Collingwood',
        country: 'Canada',
        iataCode: 'N',
        icaoCode: 'CNY3',
        latitude: '44,44919968',
        longitude: '-80,15830231',
        altitude: '730',
        timezone: '-5',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Brantford Municipal Airport',
        city: 'Brantford',
        country: 'Canada',
        iataCode: 'YFD',
        icaoCode: 'CYFD',
        latitude: '43,13140106',
        longitude: '-80,34249878',
        altitude: '815',
        timezone: '-5',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Lawrence Municipal Airport',
        city: 'Lawrence',
        country: 'United States',
        iataCode: 'LWC',
        icaoCode: 'KLWC',
        latitude: '39,01119995',
        longitude: '-95,21659851',
        altitude: '833',
        timezone: '-6',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Wellington Municipal Airport',
        city: 'Wellington',
        country: 'United States',
        iataCode: 'EGT',
        icaoCode: 'KEGT',
        latitude: '37,32360077',
        longitude: '-97,38829803',
        altitude: '1277',
        timezone: '-6',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Pompano Beach Airpark',
        city: 'Pompano Beach',
        country: 'United States',
        iataCode: 'PMP',
        icaoCode: 'KPMP',
        latitude: '26,24710083',
        longitude: '-80,11109924',
        altitude: '19',
        timezone: '-5',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Mallacoota Airport',
        city: 'Mallacoota',
        country: 'Australia',
        iataCode: 'XMC',
        icaoCode: 'YMCO',
        latitude: '-37,59830093',
        longitude: '149,7200012',
        altitude: '31',
        timezone: '10',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Shelby County Airport',
        city: 'Alabaster',
        country: 'United States',
        iataCode: 'EET',
        icaoCode: 'KEET',
        latitude: '33,17699814',
        longitude: '-86,78279877',
        altitude: '586',
        timezone: '-6',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Yuendumu Airport',
        city: 'Yuendumu ',
        country: 'Australia',
        iataCode: 'YUE',
        icaoCode: 'YYND',
        latitude: '-22,25419998',
        longitude: '131,7819977',
        altitude: '2205',
        timezone: '9,5',
        dst: 'O',
        type: 'airport',
    },
    {
        name: 'Sky Ranch At Carefree Airport',
        city: 'Carefree',
        country: 'United States',
        iataCode: 'N',
        icaoCode: '18AZ',
        latitude: '33,81809998',
        longitude: '-111,8980026',
        altitude: '2568',
        timezone: '-7',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Lombok International Airport',
        city: 'Praya',
        country: 'Indonesia',
        iataCode: 'LOP',
        icaoCode: 'WADL',
        latitude: '-8,757322',
        longitude: '116,276675',
        altitude: '319',
        timezone: '8',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'One Hundred Mile House Airport',
        city: 'One Hundred Mile House',
        country: 'Canada',
        iataCode: 'N',
        icaoCode: 'CAV3',
        latitude: '51,64250183',
        longitude: '-121,3069992',
        altitude: '3055',
        timezone: '-8',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'South Cariboo Region / 108 Mile Airport',
        city: '108 Mile Ranch',
        country: 'Canada',
        iataCode: 'ZML',
        icaoCode: 'CZML',
        latitude: '51,73609924',
        longitude: '-121,3330002',
        altitude: '3126',
        timezone: '-8',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Glasgow City Heliport',
        city: 'Glasgow',
        country: 'United Kingdom',
        iataCode: 'N',
        icaoCode: 'EGEG',
        latitude: '55,86138916',
        longitude: '-4,296944141',
        altitude: '0',
        timezone: '0',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Yarram Airport',
        city: 'Yarram',
        country: 'Australia',
        iataCode: 'N',
        icaoCode: 'YYRM',
        latitude: '-38,56669998',
        longitude: '146,7550049',
        altitude: '15',
        timezone: '10',
        dst: 'O',
        type: 'airport',
    },
    {
        name: 'Handan Airport',
        city: 'Handan',
        country: 'China',
        iataCode: 'HDG',
        icaoCode: 'ZBHD',
        latitude: '36,52583333',
        longitude: '114,4255556',
        altitude: '229',
        timezone: '8',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Indianapolis Metropolitan Airport',
        city: 'Indianapolis',
        country: 'United States',
        iataCode: 'UMP',
        icaoCode: 'KUMP',
        latitude: '39,93519974',
        longitude: '-86,04499817',
        altitude: '811',
        timezone: '-5',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'London-Corbin Airport/Magee Field',
        city: 'London',
        country: 'United States',
        iataCode: 'LOZ',
        icaoCode: 'KLOZ',
        latitude: '37,0821991',
        longitude: '-84,0848999',
        altitude: '1212',
        timezone: '-5',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Simmons Army Air Field',
        city: 'Fredericksburg',
        country: 'United States',
        iataCode: 'FBG',
        icaoCode: 'KFBG',
        latitude: '35,13180161',
        longitude: '-78,93669891',
        altitude: '244',
        timezone: '-5',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Modlin Airport',
        city: 'Warsaw',
        country: 'Poland',
        iataCode: 'WMI',
        icaoCode: 'EPMO',
        latitude: '52,4510994',
        longitude: '20,65180016',
        altitude: '341',
        timezone: '1',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Jixi Xingkaihu Airport',
        city: 'Jixi',
        country: 'China',
        iataCode: 'JXA',
        icaoCode: 'ZYJX',
        latitude: '45,293',
        longitude: '131,193',
        altitude: '760',
        timezone: '8',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Jeongseok Airport',
        city: 'Seogwipo',
        country: 'South Korea',
        iataCode: 'N',
        icaoCode: 'RKPD',
        latitude: '33,39960098',
        longitude: '126,711998',
        altitude: '1171',
        timezone: '9',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Gimli Industrial Park Airport',
        city: 'Gimli',
        country: 'Canada',
        iataCode: 'YGM',
        icaoCode: 'CYGM',
        latitude: '50,62810135',
        longitude: '-97,04329681',
        altitude: '753',
        timezone: '-6',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Matheson Island Airport',
        city: 'Matheson Island',
        country: 'Canada',
        iataCode: 'N',
        icaoCode: 'CJT2',
        latitude: '51,73220062',
        longitude: '-96,93440247',
        altitude: '725',
        timezone: '-6',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Tura Mountain Airport',
        city: 'Tura',
        country: 'Russia',
        iataCode: 'N',
        icaoCode: 'UNIT',
        latitude: '64,33351135',
        longitude: '100,4328918',
        altitude: '2044',
        timezone: '7',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Beloyarskiy Airport',
        city: 'Beloyarsky',
        country: 'Russia',
        iataCode: 'EYK',
        icaoCode: 'USHY',
        latitude: '63,68690109',
        longitude: '66,69860077',
        altitude: '82',
        timezone: '5',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'John H Batten Airport',
        city: 'Racine',
        country: 'United States',
        iataCode: 'RAC',
        icaoCode: 'KRAC',
        latitude: '42,76060104',
        longitude: '-87,81520081',
        altitude: '674',
        timezone: '-6',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Cesar Lim Rodriguez Airport',
        city: 'Taytay',
        country: 'Philippines',
        iataCode: 'RZP',
        icaoCode: 'RPSD',
        latitude: '10,81874',
        longitude: '119,507697',
        altitude: '80',
        timezone: '8',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Gera-Leumnitz Airport',
        city: 'Gera',
        country: 'Germany',
        iataCode: 'N',
        icaoCode: 'EDAJ',
        latitude: '50,88166809',
        longitude: '12,13583279',
        altitude: '1014',
        timezone: '1',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Kasimovo Airfield',
        city: 'Saint-Petersburg',
        country: 'Russia',
        iataCode: 'N',
        icaoCode: 'XLLN',
        latitude: '60,19829941',
        longitude: '30,33499908',
        altitude: '230',
        timezone: '3',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Terlet Glider Field',
        city: 'Arnhem',
        country: 'Netherlands',
        iataCode: 'N',
        icaoCode: 'EHTL',
        latitude: '52,057201',
        longitude: '5,92444',
        altitude: '276',
        timezone: '1',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Redlands Municipal Airport',
        city: 'Redlands',
        country: 'United States',
        iataCode: 'REI',
        icaoCode: 'KREI',
        latitude: '34,08530045',
        longitude: '-117,1460037',
        altitude: '1571',
        timezone: '-8',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Flabob Airport',
        city: 'Riverside',
        country: 'United States',
        iataCode: 'RIR',
        icaoCode: 'KRIR',
        latitude: '33,98970032',
        longitude: '-117,4110031',
        altitude: '764',
        timezone: '-8',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Tacoma Narrows Airport',
        city: 'Tacoma',
        country: 'United States',
        iataCode: 'TIW',
        icaoCode: 'KTIW',
        latitude: '47,26789856',
        longitude: '-122,5780029',
        altitude: '294',
        timezone: '-8',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Oerlinghausen Airport',
        city: 'TELGTE',
        country: 'Germany',
        iataCode: 'N',
        icaoCode: 'EDLO',
        latitude: '51,93222046',
        longitude: '8,66166687',
        altitude: '558',
        timezone: '1',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Jack Edwards Airport',
        city: 'Gulf Shores',
        country: 'United States',
        iataCode: 'JKA',
        icaoCode: 'KJKA',
        latitude: '30,29050064',
        longitude: '-87,67179871',
        altitude: '17',
        timezone: '-6',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'General Villamil Airport',
        city: 'Isabela',
        country: 'Ecuador',
        iataCode: 'N',
        icaoCode: 'SEII',
        latitude: '-0,942628026',
        longitude: '-90,95300293',
        altitude: '35',
        timezone: '-6',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Balzers Heliport',
        city: 'Balzers',
        country: 'Switzerland',
        iataCode: 'N',
        icaoCode: 'LSXB',
        latitude: '47,0681',
        longitude: '9,48111',
        altitude: '1585',
        timezone: '1',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Flugplatz Punitz',
        city: 'Punitz-Guessing',
        country: 'Austria',
        iataCode: 'N',
        icaoCode: 'LOGG',
        latitude: '47,14653',
        longitude: '16,316843',
        altitude: '950',
        timezone: '1',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Khmelnytskyi Airport',
        city: 'Khmeinitskiy',
        country: 'Ukraine',
        iataCode: 'HMJ',
        icaoCode: 'UKLH',
        latitude: '49,35969925',
        longitude: '26,9333992',
        altitude: '1150',
        timezone: '2',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Hiroshimanishi Airport',
        city: 'Hiroshima',
        country: 'Japan',
        iataCode: 'HIW',
        icaoCode: 'RJBH',
        latitude: '34,3669014',
        longitude: '132,4140015',
        altitude: '15',
        timezone: '9',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Yalata Mission Airport',
        city: 'Yalata',
        country: 'Australia',
        iataCode: 'N',
        icaoCode: 'YYTA',
        latitude: '-31,47060013',
        longitude: '131,8249969',
        altitude: '0',
        timezone: '9,5',
        dst: 'O',
        type: 'airport',
    },
    {
        name: 'Hazleton Municipal Airport',
        city: 'Hazleton',
        country: 'United States',
        iataCode: 'HZL',
        icaoCode: 'KHZL',
        latitude: '40,98680115',
        longitude: '-75,99490356',
        altitude: '1603',
        timezone: '-5',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Greater Cumberland Regional Airport',
        city: 'Cumberland',
        country: 'United States',
        iataCode: 'CBE',
        icaoCode: 'KCBE',
        latitude: '39,61539841',
        longitude: '-78,7609024',
        altitude: '775',
        timezone: '-5',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Sugar Loaf Shores Airport',
        city: 'Key West',
        country: 'United States',
        iataCode: 'N',
        icaoCode: '7FA1',
        latitude: '24,6487999',
        longitude: '-81,57980347',
        altitude: '4',
        timezone: '-5',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Wyndham Airport',
        city: 'Wyndham',
        country: 'Australia',
        iataCode: 'N',
        icaoCode: 'YWYM',
        latitude: '-15,51140022',
        longitude: '128,1529999',
        altitude: '14',
        timezone: '8',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Bob Quinn Lake Airport',
        city: 'Bob Quinn Lake',
        country: 'Canada',
        iataCode: 'YBO',
        icaoCode: 'CBW4',
        latitude: '56,96670151',
        longitude: '-130,25',
        altitude: '2000',
        timezone: '-8',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Grabtsevo Airport',
        city: 'Kaluga',
        country: 'Russia',
        iataCode: 'KLF',
        icaoCode: 'UUBC',
        latitude: '54,54999924',
        longitude: '36,3666687',
        altitude: '656',
        timezone: '3',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Tri-County Regional Airport',
        city: 'Lone Rock',
        country: 'United States',
        iataCode: 'LNR',
        icaoCode: 'KLNR',
        latitude: '43,21170044',
        longitude: '-90,18160248',
        altitude: '717',
        timezone: '-6',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Price County Airport',
        city: 'Phillips',
        country: 'United States',
        iataCode: 'N',
        icaoCode: 'KPBH',
        latitude: '45,70899963',
        longitude: '-90,40249634',
        altitude: '1497',
        timezone: '-6',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Monroe Municipal Airport',
        city: 'Monroe',
        country: 'United States',
        iataCode: 'N',
        icaoCode: 'KEFT',
        latitude: '42,61489868',
        longitude: '-89,5904007',
        altitude: '1086',
        timezone: '-6',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Joliet Regional Airport',
        city: 'Joliet',
        country: 'United States',
        iataCode: 'JOT',
        icaoCode: 'KJOT',
        latitude: '41,51779938',
        longitude: '-88,17549896',
        altitude: '582',
        timezone: '-6',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Illinois Valley Regional Airport-Walter A Duncan Field',
        city: 'Peru',
        country: 'United States',
        iataCode: 'VYS',
        icaoCode: 'KVYS',
        latitude: '41,351898',
        longitude: '-89,153099',
        altitude: '654',
        timezone: '-6',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Jackson County Reynolds Field',
        city: 'Jackson',
        country: 'United States',
        iataCode: 'JXN',
        icaoCode: 'KJXN',
        latitude: '42,25979996',
        longitude: '-84,45939636',
        altitude: '1001',
        timezone: '-5',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Fuerstenwalde Airport',
        city: 'Furstenwalde',
        country: 'Germany',
        iataCode: 'N',
        icaoCode: 'EDAL',
        latitude: '52,3905983',
        longitude: '14,09720039',
        altitude: '184',
        timezone: '1',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Eberswalde-Finow Airport',
        city: 'Eberswalde',
        country: 'Germany',
        iataCode: 'N',
        icaoCode: 'EDAV',
        latitude: '52,82722092',
        longitude: '13,69361115',
        altitude: '121',
        timezone: '1',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Joseph A. Hardy Connellsville Airport',
        city: 'Connellsville',
        country: 'United States',
        iataCode: 'N',
        icaoCode: 'KVVS',
        latitude: '39,95920181',
        longitude: '-79,65709686',
        altitude: '1267',
        timezone: '-5',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Bedford County Airport',
        city: 'Bedford',
        country: 'United States',
        iataCode: 'N',
        icaoCode: 'KHMZ',
        latitude: '40,08530045',
        longitude: '-78,5121994',
        altitude: '1162',
        timezone: '-5',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Wings Field',
        city: 'Philadelphia',
        country: 'United States',
        iataCode: 'BBX',
        icaoCode: 'KLOM',
        latitude: '40,13750076',
        longitude: '-75,26509857',
        altitude: '302',
        timezone: '-5',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Okeechobee County Airport',
        city: 'Okeechobee',
        country: 'United States',
        iataCode: 'OBE',
        icaoCode: 'KOBE',
        latitude: '27,26280022',
        longitude: '-80,84980011',
        altitude: '34',
        timezone: '-5',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Sebring Regional Airport',
        city: 'Sebring',
        country: 'United States',
        iataCode: 'SEF',
        icaoCode: 'KSEF',
        latitude: '27,45639992',
        longitude: '-81,3423996',
        altitude: '62',
        timezone: '-5',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Avon Park Executive Airport',
        city: 'Avon Park',
        country: 'United States',
        iataCode: 'AVO',
        icaoCode: 'KAVO',
        latitude: '27,59119987',
        longitude: '-81,52780151',
        altitude: '160',
        timezone: '-5',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Winter Haven Municipal Airport - Gilbert Field',
        city: 'Winter Haven',
        country: 'United States',
        iataCode: 'GIF',
        icaoCode: 'KGIF',
        latitude: '28,06290054',
        longitude: '-81,75330353',
        altitude: '145',
        timezone: '-5',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Zephyrhills Municipal Airport',
        city: 'Zephyrhills',
        country: 'United States',
        iataCode: 'ZPH',
        icaoCode: 'KZPH',
        latitude: '28,22820091',
        longitude: '-82,15589905',
        altitude: '90',
        timezone: '-5',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Ocala International Airport - Jim Taylor Field',
        city: 'Ocala',
        country: 'United States',
        iataCode: 'OCF',
        icaoCode: 'KOCF',
        latitude: '29,17259979',
        longitude: '-82,22419739',
        altitude: '90',
        timezone: '-5',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Jesup Wayne County Airport',
        city: 'Jesup',
        country: 'United States',
        iataCode: 'JES',
        icaoCode: 'KJES',
        latitude: '31,55400085',
        longitude: '-81,88249969',
        altitude: '107',
        timezone: '-5',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Madison Municipal Airport',
        city: 'Madison',
        country: 'United States',
        iataCode: '52A',
        icaoCode: 'K52A',
        latitude: '33,61209869',
        longitude: '-83,46040344',
        altitude: '694',
        timezone: '-5',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Newnan Coweta County Airport',
        city: 'Newnan',
        country: 'United States',
        iataCode: 'CCO',
        icaoCode: 'KCCO',
        latitude: '33,31159973',
        longitude: '-84,76979828',
        altitude: '970',
        timezone: '-5',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Thomson-McDuffie County Airport',
        city: 'Thomson',
        country: 'United States',
        iataCode: 'HQU',
        icaoCode: 'KHQU',
        latitude: '33,52970123',
        longitude: '-82,51650238',
        altitude: '501',
        timezone: '-5',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Aiken Municipal Airport',
        city: 'Aiken',
        country: 'United States',
        iataCode: 'AIK',
        icaoCode: 'KAIK',
        latitude: '33,6493988',
        longitude: '-81,68499756',
        altitude: '528',
        timezone: '-5',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Woodward Field',
        city: 'Camden',
        country: 'United States',
        iataCode: 'CDN',
        icaoCode: 'KCDN',
        latitude: '34,28359985',
        longitude: '-80,56490326',
        altitude: '302',
        timezone: '-5',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Lumberton Regional Airport',
        city: 'Lumberton',
        country: 'United States',
        iataCode: 'LBT',
        icaoCode: 'KLBT',
        latitude: '34,60990143',
        longitude: '-79,05940247',
        altitude: '126',
        timezone: '-5',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Moore County Airport',
        city: 'Pinehurst-Southern Pines',
        country: 'United States',
        iataCode: 'SOP',
        icaoCode: 'KSOP',
        latitude: '35,23740005',
        longitude: '-79,3911972',
        altitude: '455',
        timezone: '-5',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Richmond County Airport',
        city: 'Rockingham',
        country: 'United States',
        iataCode: 'RCZ',
        icaoCode: 'KRCZ',
        latitude: '34,8913',
        longitude: '-79,759598',
        altitude: '358',
        timezone: '-5',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Baraboo Wisconsin Dells Airport',
        city: 'Baraboo',
        country: 'United States',
        iataCode: 'DLL',
        icaoCode: 'KDLL',
        latitude: '43,52270126',
        longitude: '-89,77020264',
        altitude: '979',
        timezone: '-6',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Statesville Regional Airport',
        city: 'Statesville',
        country: 'United States',
        iataCode: 'SVH',
        icaoCode: 'KSVH',
        latitude: '35,76530075',
        longitude: '-80,9539032',
        altitude: '968',
        timezone: '-5',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Burlington Municipal Airport',
        city: 'Burlington',
        country: 'United States',
        iataCode: 'BUU',
        icaoCode: 'KBUU',
        latitude: '42,69070053',
        longitude: '-88,30460358',
        altitude: '779',
        timezone: '-6',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'William T. Piper Memorial Airport',
        city: 'Lock Haven',
        country: 'United States',
        iataCode: 'LHV',
        icaoCode: 'KLHV',
        latitude: '41,13560104',
        longitude: '-77,42230225',
        altitude: '556',
        timezone: '-5',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Zelienople Municipal Airport',
        city: 'Zelienople',
        country: 'United States',
        iataCode: 'N',
        icaoCode: 'KPJC',
        latitude: '40,80160141',
        longitude: '-80,16069794',
        altitude: '898',
        timezone: '-5',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Lorain County Regional Airport',
        city: 'Lorain-Elyria',
        country: 'United States',
        iataCode: 'LPR',
        icaoCode: 'KLPR',
        latitude: '41,34429932',
        longitude: '-82,17759705',
        altitude: '793',
        timezone: '-5',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Burke Lakefront Airport',
        city: 'Cleveland',
        country: 'United States',
        iataCode: 'BKL',
        icaoCode: 'KBKL',
        latitude: '41,51750183',
        longitude: '-81,6832962',
        altitude: '583',
        timezone: '-5',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Chautauqua County-Dunkirk Airport',
        city: 'Dunkirk',
        country: 'United States',
        iataCode: 'DKK',
        icaoCode: 'KDKK',
        latitude: '42,49330139',
        longitude: '-79,27200317',
        altitude: '693',
        timezone: '-5',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'South Jersey Regional Airport',
        city: 'Mount Holly',
        country: 'United States',
        iataCode: 'VAY',
        icaoCode: 'KVAY',
        latitude: '39,94290161',
        longitude: '-74,84570313',
        altitude: '53',
        timezone: '-5',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Linden Airport',
        city: 'Linden',
        country: 'United States',
        iataCode: 'LDJ',
        icaoCode: 'KLDJ',
        latitude: '40,61740112',
        longitude: '-74,24459839',
        altitude: '23',
        timezone: '-5',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Tri State Steuben County Airport',
        city: 'Angola',
        country: 'United States',
        iataCode: 'ANQ',
        icaoCode: 'KANQ',
        latitude: '41,639702',
        longitude: '-85,083504',
        altitude: '995',
        timezone: '-5',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Warsaw Municipal Airport',
        city: 'Warsaw',
        country: 'United States',
        iataCode: 'N',
        icaoCode: 'KASW',
        latitude: '41,27470016',
        longitude: '-85,84010315',
        altitude: '850',
        timezone: '-5',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Van Wert County Airport',
        city: 'Van Wert',
        country: 'United States',
        iataCode: 'VNW',
        icaoCode: 'KVNW',
        latitude: '40,86470032',
        longitude: '-84,60939789',
        altitude: '785',
        timezone: '-5',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Brooks Field',
        city: 'Marshall',
        country: 'United States',
        iataCode: 'N',
        icaoCode: 'KRMY',
        latitude: '42,25120163',
        longitude: '-84,95549774',
        altitude: '941',
        timezone: '-5',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Genesee County Airport',
        city: 'Batavia',
        country: 'United States',
        iataCode: 'GVQ',
        icaoCode: 'KGVQ',
        latitude: '43,03170013',
        longitude: '-78,16760254',
        altitude: '914',
        timezone: '-5',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Clearwater Air Park',
        city: 'Clearwater',
        country: 'United States',
        iataCode: 'CLW',
        icaoCode: 'KCLW',
        latitude: '27,97669983',
        longitude: '-82,75869751',
        altitude: '71',
        timezone: '-5',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Orlampa Inc Airport',
        city: 'Forties Alpha',
        country: 'United Kingdom',
        iataCode: 'N',
        icaoCode: 'FA08',
        latitude: '28,16699982',
        longitude: '-81,80809784',
        altitude: '139',
        timezone: '-5',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Chicago Meigs Airport',
        city: 'Chicago',
        country: 'United States',
        iataCode: 'CGX',
        icaoCode: 'KCGX',
        latitude: '41,85879898',
        longitude: '-87,60790253',
        altitude: '593',
        timezone: '-6',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Pickens County Airport',
        city: 'Jasper',
        country: 'United States',
        iataCode: 'JZP',
        icaoCode: 'KJZP',
        latitude: '34,45339966',
        longitude: '-84,45739746',
        altitude: '1535',
        timezone: '-5',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Saarmund Airport',
        city: 'Saarmund',
        country: 'Germany',
        iataCode: 'N',
        icaoCode: 'EDCS',
        latitude: '52,30833435',
        longitude: '13,10055637',
        altitude: '174',
        timezone: '1',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Grand Strand Airport',
        city: 'North Myrtle Beach',
        country: 'United States',
        iataCode: 'CRE',
        icaoCode: 'KCRE',
        latitude: '33,81169891',
        longitude: '-78,72389984',
        altitude: '32',
        timezone: '-5',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Lansing Municipal Airport',
        city: 'Lansing',
        country: 'United States',
        iataCode: 'IGQ',
        icaoCode: 'KIGQ',
        latitude: '41,53490067',
        longitude: '-87,52950287',
        altitude: '620',
        timezone: '-6',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Ramona Airport',
        city: 'Ramona',
        country: 'United States',
        iataCode: 'RNM',
        icaoCode: 'KRNM',
        latitude: '33,03919983',
        longitude: '-116,9150009',
        altitude: '1395',
        timezone: '-8',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Pont Sur Yonne Airfield',
        city: 'Pont Sur Yonne',
        country: 'France',
        iataCode: 'N',
        icaoCode: 'LFGO',
        latitude: '48,289172',
        longitude: '3,247912',
        altitude: '236',
        timezone: '1',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'St Florentin Cheu Airfield',
        city: 'St Florentin Cheu',
        country: 'France',
        iataCode: 'N',
        icaoCode: 'LFGP',
        latitude: '47,98019',
        longitude: '3,775583',
        altitude: '351',
        timezone: '1',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Saulieu Liernais Airfield',
        city: 'Saulieu',
        country: 'France',
        iataCode: 'N',
        icaoCode: 'LFEW',
        latitude: '47,239399',
        longitude: '4,26583',
        altitude: '1722',
        timezone: '1',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Olten Airport',
        city: 'Olten',
        country: 'Switzerland',
        iataCode: 'N',
        icaoCode: 'LSPO',
        latitude: '47,3443985',
        longitude: '7,88944006',
        altitude: '0',
        timezone: '1',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Buochs Airport',
        city: 'Buochs',
        country: 'Switzerland',
        iataCode: 'BXO',
        icaoCode: 'LSZC',
        latitude: '46,974444',
        longitude: '8,396944',
        altitude: '1475',
        timezone: '1',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Ambri Airport',
        city: 'Quinto',
        country: 'Switzerland',
        iataCode: 'N',
        icaoCode: 'LSPM',
        latitude: '46,512501',
        longitude: '8,68978',
        altitude: '3241',
        timezone: '1',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Lodrino Air Base',
        city: 'Lodrino',
        country: 'Switzerland',
        iataCode: 'N',
        icaoCode: 'LSML',
        latitude: '46,29589844',
        longitude: '8,99213028',
        altitude: '0',
        timezone: '1',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Roudnice Airport',
        city: 'Roudnice nad Lebem',
        country: 'Czech Republic',
        iataCode: 'N',
        icaoCode: 'LKRO',
        latitude: '50,41059875',
        longitude: '14,2261',
        altitude: '732',
        timezone: '1',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Usti Nad Labem Airfield',
        city: 'Usti Nad Labem',
        country: 'Czech Republic',
        iataCode: 'N',
        icaoCode: 'LKUL',
        latitude: '50,699699',
        longitude: '13,9697',
        altitude: '791',
        timezone: '1',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Mauterndorf Airport',
        city: 'Mauterndorf',
        country: 'Austria',
        iataCode: 'N',
        icaoCode: 'LOSM',
        latitude: '47,13249969',
        longitude: '13,69690037',
        altitude: '3642',
        timezone: '1',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Nötsch Im Gailtal Airport',
        city: 'Notsch Im Gailtal',
        country: 'Austria',
        iataCode: 'N',
        icaoCode: 'LOKN',
        latitude: '46,58110046',
        longitude: '13,62919998',
        altitude: '1801',
        timezone: '1',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Karlsruhe-Forchheim Airport',
        city: 'Karlsruhe',
        country: 'Germany',
        iataCode: 'N',
        icaoCode: 'EDTK',
        latitude: '48,9843',
        longitude: '8,3355',
        altitude: '381',
        timezone: '1',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Weinheim/Bergstraße Airport',
        city: 'Weinheim',
        country: 'Germany',
        iataCode: 'N',
        icaoCode: 'EDGZ',
        latitude: '49,56750107',
        longitude: '8,610555649',
        altitude: '318',
        timezone: '1',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Meaux Esbly Airport',
        city: 'Meaux',
        country: 'France',
        iataCode: 'N',
        icaoCode: 'LFPE',
        latitude: '48,92779922',
        longitude: '2,835279942',
        altitude: '217',
        timezone: '1',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Château-Thierry - Belleau Airport',
        city: 'Chateau-Thierry',
        country: 'France',
        iataCode: 'N',
        icaoCode: 'LFFH',
        latitude: '49,06719971',
        longitude: '3,356940031',
        altitude: '728',
        timezone: '1',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Branch County Memorial Airport',
        city: 'Coldwater',
        country: 'United States',
        iataCode: 'OEB',
        icaoCode: 'KOEB',
        latitude: '41,9333992',
        longitude: '-85,05259705',
        altitude: '959',
        timezone: '-5',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Wilkes Barre Wyoming Valley Airport',
        city: 'Wilkes-Barre',
        country: 'United States',
        iataCode: 'WBW',
        icaoCode: 'KWBW',
        latitude: '41,29719925',
        longitude: '-75,85119629',
        altitude: '545',
        timezone: '-5',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Willoughby Lost Nation Municipal Airport',
        city: 'Willoughby',
        country: 'United States',
        iataCode: 'LNN',
        icaoCode: 'KLNN',
        latitude: '41,68399811',
        longitude: '-81,38970184',
        altitude: '626',
        timezone: '-5',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Taoyuan Air Base',
        city: 'Taoyuan',
        country: 'Taiwan',
        iataCode: 'N',
        icaoCode: 'RCGM',
        latitude: '25,05660057',
        longitude: '121,2442627',
        altitude: '144',
        timezone: '8',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Uummannaq Heliport',
        city: 'Uummannaq',
        country: 'Greenland',
        iataCode: 'UMD',
        icaoCode: 'BGUM',
        latitude: '70,68042793',
        longitude: '-52,11163044',
        altitude: '50',
        timezone: '-3',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Bayannur Tianjitai Airport',
        city: 'Bayannur',
        country: 'China',
        iataCode: 'RLK',
        icaoCode: 'ZBYZ',
        latitude: '40,926',
        longitude: '107,7428',
        altitude: '3400',
        timezone: '8',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Capital City Airport',
        city: 'Frankfort',
        country: 'United States',
        iataCode: 'FFT',
        icaoCode: 'KFFT',
        latitude: '38,18249893',
        longitude: '-84,90470123',
        altitude: '806',
        timezone: '-5',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Auburn Lewiston Municipal Airport',
        city: 'Lewiston',
        country: 'United States',
        iataCode: 'LEW',
        icaoCode: 'KLEW',
        latitude: '44,04850006',
        longitude: '-70,28350067',
        altitude: '288',
        timezone: '-5',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Inowroclaw Military Air Base',
        city: 'Inowroclaw',
        country: 'Poland',
        iataCode: 'N',
        icaoCode: 'EPIR',
        latitude: '52,82939911',
        longitude: '18,33060074',
        altitude: '259',
        timezone: '1',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Pruszcz Gdanski Air Base',
        city: 'Pruszcz Gdansk',
        country: 'Poland',
        iataCode: 'N',
        icaoCode: 'EPPR',
        latitude: '54,248001',
        longitude: '18,6716',
        altitude: '21',
        timezone: '1',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Bloyer Field',
        city: 'Tomah',
        country: 'United States',
        iataCode: 'N',
        icaoCode: 'KY72',
        latitude: '43,97499847',
        longitude: '-90,48349762',
        altitude: '966',
        timezone: '-6',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Marco Island Airport',
        city: 'Marco Island Airport',
        country: 'United States',
        iataCode: 'MRK',
        icaoCode: 'KMKY',
        latitude: '25,99500084',
        longitude: '-81,67250061',
        altitude: '5',
        timezone: '-5',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Drummond Island Airport',
        city: 'Drummond Island',
        country: 'United States',
        iataCode: 'DRM',
        icaoCode: 'KDRM',
        latitude: '46,00930023',
        longitude: '-83,74389648',
        altitude: '668',
        timezone: '-5',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Gladwin Zettel Memorial Airport',
        city: 'Gladwin',
        country: 'United States',
        iataCode: 'GDW',
        icaoCode: 'KGDW',
        latitude: '43,97060013',
        longitude: '-84,47499847',
        altitude: '776',
        timezone: '-5',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'South Haven Area Regional Airport',
        city: 'South Haven',
        country: 'United States',
        iataCode: 'LWA',
        icaoCode: 'KLWA',
        latitude: '42,3512001',
        longitude: '-86,25569916',
        altitude: '666',
        timezone: '-5',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Marshfield Municipal Airport',
        city: 'Marshfield',
        country: 'United States',
        iataCode: 'MFI',
        icaoCode: 'KMFI',
        latitude: '44,63690186',
        longitude: '-90,18930054',
        altitude: '1277',
        timezone: '-6',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Alexander Field South Wood County Airport',
        city: 'Wisconsin Rapids',
        country: 'United States',
        iataCode: 'ISW',
        icaoCode: 'KISW',
        latitude: '44,36029816',
        longitude: '-89,83899689',
        altitude: '1021',
        timezone: '-6',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Clinton Municipal Airport',
        city: 'Clinton',
        country: 'United States',
        iataCode: 'CWI',
        icaoCode: 'KCWI',
        latitude: '41,83110046',
        longitude: '-90,32910156',
        altitude: '708',
        timezone: '-6',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Beverly Municipal Airport',
        city: 'Beverly',
        country: 'United States',
        iataCode: 'BVY',
        icaoCode: 'KBVY',
        latitude: '42,58420181',
        longitude: '-70,91649628',
        altitude: '107',
        timezone: '-5',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Nagaur Airport',
        city: 'Nagaur',
        country: 'India',
        iataCode: 'N',
        icaoCode: 'VI73',
        latitude: '27,20829964',
        longitude: '73,71140289',
        altitude: '950',
        timezone: '5,5',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Ostafyevo International Airport',
        city: 'Moscow',
        country: 'Russia',
        iataCode: 'N',
        icaoCode: 'UUMO',
        latitude: '55,51169968',
        longitude: '37,50719833',
        altitude: '568',
        timezone: '3',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Trois-Rivières Airport',
        city: 'Trois Rivieres',
        country: 'Canada',
        iataCode: 'YRQ',
        icaoCode: 'CYRQ',
        latitude: '46,35279846',
        longitude: '-72,67939758',
        altitude: '199',
        timezone: '-5',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Poplar Bluff Municipal Airport',
        city: 'Poplar Bluff',
        country: 'United States',
        iataCode: 'POF',
        icaoCode: 'KPOF',
        latitude: '36,77389908',
        longitude: '-90,32489777',
        altitude: '331',
        timezone: '-6',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Somerset Airport',
        city: 'Somerville',
        country: 'United States',
        iataCode: 'N',
        icaoCode: 'KSMQ',
        latitude: '40,62599945',
        longitude: '-74,67019653',
        altitude: '105',
        timezone: '-5',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Eastport Municipal Airport',
        city: 'Eastport',
        country: 'United States',
        iataCode: 'EPM',
        icaoCode: 'KEPM',
        latitude: '44,91009903',
        longitude: '-67,01270294',
        altitude: '45',
        timezone: '-5',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Keokuk Municipal Airport',
        city: 'Keokuk',
        country: 'United States',
        iataCode: 'EOK',
        icaoCode: 'KEOK',
        latitude: '40,4598999',
        longitude: '-91,42849731',
        altitude: '671',
        timezone: '-6',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Perth/Scone Airport',
        city: 'Perth',
        country: 'United Kingdom',
        iataCode: 'PSL',
        icaoCode: 'EGPT',
        latitude: '56,43920135',
        longitude: '-3,372220039',
        altitude: '397',
        timezone: '0',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Caernarfon Airport',
        city: 'Caernarfon',
        country: 'United Kingdom',
        iataCode: 'N',
        icaoCode: 'EGCK',
        latitude: '53,10181944',
        longitude: '-4,337613889',
        altitude: '1',
        timezone: '0',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Grefrath-Niershorst Airport',
        city: 'Grefrath',
        country: 'Germany',
        iataCode: 'N',
        icaoCode: 'EDLF',
        latitude: '51,33388901',
        longitude: '6,359444141',
        altitude: '105',
        timezone: '1',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'St Paul Downtown Holman Field',
        city: 'St. Paul',
        country: 'United States',
        iataCode: 'STP',
        icaoCode: 'KSTP',
        latitude: '44,93450165',
        longitude: '-93,05999756',
        altitude: '705',
        timezone: '-6',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Söderhamn Airport',
        city: 'Soderhamn',
        country: 'Sweden',
        iataCode: 'SOO',
        icaoCode: 'ESNY',
        latitude: '61,26150131',
        longitude: '17,09910011',
        altitude: '88',
        timezone: '1',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Newcastle Aerodrome',
        city: 'Newcastle',
        country: 'Ireland',
        iataCode: 'N',
        icaoCode: 'EINC',
        latitude: '53,07109833',
        longitude: '-6,04527998',
        altitude: '1',
        timezone: '0',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Saravane Airport',
        city: 'Saravane',
        country: 'Laos',
        iataCode: 'VNA',
        icaoCode: 'VLSV',
        latitude: '15,70943921',
        longitude: '106,4106989',
        altitude: '574',
        timezone: '7',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Segeletz Airport',
        city: 'Segeletz',
        country: 'Germany',
        iataCode: 'N',
        icaoCode: 'EDAI',
        latitude: '52,82666779',
        longitude: '12,54194355',
        altitude: '141',
        timezone: '1',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Fuentemilanos Airport',
        city: 'Segovia',
        country: 'Spain',
        iataCode: 'N',
        icaoCode: 'LEFM',
        latitude: '40,88861084',
        longitude: '-4,237500191',
        altitude: '3307',
        timezone: '1',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Akeno Airport',
        city: 'Akeno',
        country: 'Japan',
        iataCode: 'N',
        icaoCode: 'RJOE',
        latitude: '34,53329849',
        longitude: '136,6719971',
        altitude: '20',
        timezone: '9',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Ust-Kamchatsk Airport',
        city: 'Ust Kamchatsk',
        country: 'Russia',
        iataCode: 'N',
        icaoCode: 'UHPK',
        latitude: '56,23860168',
        longitude: '162,6880035',
        altitude: '200',
        timezone: '12',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Oconomowoc Airport',
        city: 'Oconomowoc',
        country: 'United States',
        iataCode: 'N',
        icaoCode: '0WI8',
        latitude: '43,13890076',
        longitude: '-88,47229767',
        altitude: '885',
        timezone: '-6',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Kozyrevsk Airport',
        city: 'Kozyrevsk',
        country: 'Russia',
        iataCode: 'N',
        icaoCode: 'UHPO',
        latitude: '56,09000015',
        longitude: '159,8766632',
        altitude: '331',
        timezone: '12',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Dikson Airport',
        city: 'Dikson',
        country: 'Russia',
        iataCode: 'DKS',
        icaoCode: 'UODD',
        latitude: '73,51780701',
        longitude: '80,37966919',
        altitude: '47',
        timezone: '7',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Beverley Airport',
        city: 'Mine Site',
        country: 'Australia',
        iataCode: 'N',
        icaoCode: 'YBEE',
        latitude: '-30,18670082',
        longitude: '139,5579987',
        altitude: '116',
        timezone: '9,5',
        dst: 'O',
        type: 'airport',
    },
    {
        name: 'Bantry Aerodrome',
        city: 'Bantry',
        country: 'Ireland',
        iataCode: 'BYT',
        icaoCode: 'EIBN',
        latitude: '51,66859818',
        longitude: '-9,48416996',
        altitude: '7',
        timezone: '0',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Aliwal North Airport',
        city: 'Aliwal North',
        country: 'South Africa',
        iataCode: 'N',
        icaoCode: 'FAAN',
        latitude: '-30,68000031',
        longitude: '26,72999954',
        altitude: '4405',
        timezone: '2',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Alkantpan Copper Airport',
        city: 'Alkantpan',
        country: 'South Africa',
        iataCode: 'N',
        icaoCode: 'FACO',
        latitude: '-29,90640068',
        longitude: '22,31669998',
        altitude: '3589',
        timezone: '2',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Alldays Airport',
        city: 'Alldays',
        country: 'South Africa',
        iataCode: 'ADY',
        icaoCode: 'FAAL',
        latitude: '-22,67900085',
        longitude: '29,05550003',
        altitude: '2600',
        timezone: '2',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Barberton Airport',
        city: 'Barberton',
        country: 'South Africa',
        iataCode: 'N',
        icaoCode: 'FABR',
        latitude: '-25,716869',
        longitude: '30,97518',
        altitude: '2250',
        timezone: '2',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Butler Co Regional Airport - Hogan Field',
        city: 'Hamilton',
        country: 'United States',
        iataCode: 'N',
        icaoCode: 'KHAO',
        latitude: '39,36380005',
        longitude: '-84,52200317',
        altitude: '633',
        timezone: '-5',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Bungoma Airport',
        city: 'Bungoma',
        country: 'Kenya',
        iataCode: 'N',
        icaoCode: 'HKBU',
        latitude: '0,576207995',
        longitude: '34,55345154',
        altitude: '4726',
        timezone: '3',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Bura East Airport',
        city: 'Bura',
        country: 'Kenya',
        iataCode: 'N',
        icaoCode: 'HKBR',
        latitude: '-1,100000024',
        longitude: '39,95000076',
        altitude: '345',
        timezone: '3',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Busia Airport',
        city: 'Busia',
        country: 'Kenya',
        iataCode: 'N',
        icaoCode: 'HKBA',
        latitude: '0,457085013',
        longitude: '34,13027954',
        altitude: '3989',
        timezone: '3',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Embu Airport',
        city: 'Embu',
        country: 'Kenya',
        iataCode: 'N',
        icaoCode: 'HKEM',
        latitude: '-0,57327',
        longitude: '37,49726',
        altitude: '4150',
        timezone: '3',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Garba Tula Airport',
        city: 'Garba Tula',
        country: 'Kenya',
        iataCode: 'N',
        icaoCode: 'HKGT',
        latitude: '0,532999992',
        longitude: '38,51699829',
        altitude: '2000',
        timezone: '3',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Garissa Airport',
        city: 'Garissa',
        country: 'Kenya',
        iataCode: 'GAS',
        icaoCode: 'HKGA',
        latitude: '-0,46350801',
        longitude: '39,64830017',
        altitude: '475',
        timezone: '3',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Hola Airport',
        city: 'Hola',
        country: 'Kenya',
        iataCode: 'HOA',
        icaoCode: 'HKHO',
        latitude: '-1,521999955',
        longitude: '40,00400162',
        altitude: '195',
        timezone: '3',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Homa Bay Airport',
        city: 'Homa Bay',
        country: 'Kenya',
        iataCode: 'N',
        icaoCode: 'HKHB',
        latitude: '-0,600000024',
        longitude: '34,46699905',
        altitude: '4280',
        timezone: '3',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Isiolo Airport',
        city: 'Isiolo',
        country: 'Kenya',
        iataCode: 'N',
        icaoCode: 'HKIS',
        latitude: '0,338170946',
        longitude: '37,59169388',
        altitude: '3495',
        timezone: '3',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Kericho Airport',
        city: 'Kericho',
        country: 'Kenya',
        iataCode: 'KEY',
        icaoCode: 'HKKR',
        latitude: '-0,416999996',
        longitude: '35,25',
        altitude: '6562',
        timezone: '3',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Kilaguni Airport',
        city: 'Kilaguni',
        country: 'Kenya',
        iataCode: 'ILU',
        icaoCode: 'HKKL',
        latitude: '-2,910609961',
        longitude: '38,06520081',
        altitude: '2750',
        timezone: '3',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Antsirabe Airport',
        city: 'Antsirabe',
        country: 'Madagascar',
        iataCode: 'ATJ',
        icaoCode: 'FMME',
        latitude: '-19,83922148',
        longitude: '47,06371307',
        altitude: '4997',
        timezone: '3',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Bekily Airport',
        city: 'Bekily',
        country: 'Madagascar',
        iataCode: 'OVA',
        icaoCode: 'FMSL',
        latitude: '-24,23569475',
        longitude: '45,30452728',
        altitude: '1270',
        timezone: '3',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Ust-Tsylma Airport',
        city: 'Ust-Tsylma',
        country: 'Russia',
        iataCode: 'N',
        icaoCode: 'UUYX',
        latitude: '65,43729401',
        longitude: '52,20033646',
        altitude: '262',
        timezone: '3',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Gorno-Altaysk Airport',
        city: 'Gorno-Altaysk',
        country: 'Russia',
        iataCode: 'RGK',
        icaoCode: 'UNBG',
        latitude: '51,96670151',
        longitude: '85,83329773',
        altitude: '965',
        timezone: '7',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Fond du Lac County Airport',
        city: 'Fond du Lac',
        country: 'United States',
        iataCode: 'FLD',
        icaoCode: 'KFLD',
        latitude: '43,77119827',
        longitude: '-88,48840332',
        altitude: '808',
        timezone: '-6',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Waupaca Municipal Airport',
        city: 'Waupaca',
        country: 'United States',
        iataCode: 'PCZ',
        icaoCode: 'KPCZ',
        latitude: '44,33330154',
        longitude: '-89,01979828',
        altitude: '840',
        timezone: '-6',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Stevens Point Municipal Airport',
        city: 'Stevens Point',
        country: 'United States',
        iataCode: 'STE',
        icaoCode: 'KSTE',
        latitude: '44,54520035',
        longitude: '-89,53029633',
        altitude: '1110',
        timezone: '-6',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Mys Shmidta Airport',
        city: 'Mys Shmidta',
        country: 'Russia',
        iataCode: 'N',
        icaoCode: 'UHMI',
        latitude: '68,86830139',
        longitude: '-179,3730011',
        altitude: '20',
        timezone: '12',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Moma Airport',
        city: 'Honuu',
        country: 'Russia',
        iataCode: 'N',
        icaoCode: 'UEMA',
        latitude: '66,45085907',
        longitude: '143,2615509',
        altitude: '656',
        timezone: '11',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Luce County Airport',
        city: 'Newberry',
        country: 'United States',
        iataCode: 'ERY',
        icaoCode: 'KERY',
        latitude: '46,31119919',
        longitude: '-85,45729828',
        altitude: '869',
        timezone: '-5',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Peenemünde Airport',
        city: 'Peenemunde',
        country: 'Germany',
        iataCode: 'PEF',
        icaoCode: 'EDCP',
        latitude: '54,15777969',
        longitude: '13,77444363',
        altitude: '7',
        timezone: '1',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Góraszka Airport',
        city: 'Goraszka',
        country: 'Poland',
        iataCode: 'N',
        icaoCode: 'EPGO',
        latitude: '52,18444061',
        longitude: '21,28111076',
        altitude: '361',
        timezone: '1',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Nusawiru Airport',
        city: 'Nusawiru',
        country: 'Indonesia',
        iataCode: 'N',
        icaoCode: 'WI1A',
        latitude: '-7,720389843',
        longitude: '108,4899979',
        altitude: '16',
        timezone: '7',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Galion Municipal Airport',
        city: 'Galion',
        country: 'United States',
        iataCode: 'GQQ',
        icaoCode: 'KGQQ',
        latitude: '40,7533989',
        longitude: '-82,72380066',
        altitude: '1224',
        timezone: '-5',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Tiputini Airport',
        city: 'Tiputini',
        country: 'Ecuador',
        iataCode: 'TPN',
        icaoCode: 'SETI',
        latitude: '-0,776111007',
        longitude: '-75,52639771',
        altitude: '997',
        timezone: '-5',
        dst: 'S',
        type: 'airport',
    },
    {
        name: 'Rio Amazonas Airport',
        city: 'Pastaza',
        country: 'Ecuador',
        iataCode: 'PTZ',
        icaoCode: 'SESM',
        latitude: '-1,505239964',
        longitude: '-78,06269836',
        altitude: '3465',
        timezone: '-5',
        dst: 'S',
        type: 'airport',
    },
    {
        name: 'Clarksville–Montgomery County Regional Airport',
        city: 'Clarksville',
        country: 'United States',
        iataCode: 'CKV',
        icaoCode: 'KCKV',
        latitude: '36,62189865',
        longitude: '-87,41500092',
        altitude: '550',
        timezone: '-6',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Lompoc Airport',
        city: 'Lompoc',
        country: 'United States',
        iataCode: 'LPC',
        icaoCode: 'KLPC',
        latitude: '34,66559982',
        longitude: '-120,4680023',
        altitude: '88',
        timezone: '-8',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Chester County G O Carlson Airport',
        city: 'Coatesville',
        country: 'United States',
        iataCode: 'CTH',
        icaoCode: 'KMQS',
        latitude: '39,97900009',
        longitude: '-75,8655014',
        altitude: '660',
        timezone: '-5',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Bost Airport',
        city: 'Lashkar Gah',
        country: 'Afghanistan',
        iataCode: 'BST',
        icaoCode: 'OABT',
        latitude: '31,55970001',
        longitude: '64,36499786',
        altitude: '2464',
        timezone: '4,5',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Lankaran International Airport',
        city: 'Lankaran',
        country: 'Azerbaijan',
        iataCode: 'LLK',
        icaoCode: 'UBBL',
        latitude: '38,74639893',
        longitude: '48,81800079',
        altitude: '30',
        timezone: '4',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Gabala International Airport',
        city: 'Qabala',
        country: 'Azerbaijan',
        iataCode: 'GBB',
        icaoCode: 'UBBQ',
        latitude: '40,826667',
        longitude: '47,7125',
        altitude: '935',
        timezone: '4',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Zaqatala International Airport',
        city: 'Zaqatala',
        country: 'Azerbaijan',
        iataCode: 'ZTU',
        icaoCode: 'UBBY',
        latitude: '41,562222',
        longitude: '46,667221',
        altitude: '1279',
        timezone: '4',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Lake Placid Airport',
        city: 'Lake Placid',
        country: 'United States',
        iataCode: 'LKP',
        icaoCode: 'KLKP',
        latitude: '44,26449966',
        longitude: '-73,9618988',
        altitude: '1747',
        timezone: '-5',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Magan Airport',
        city: 'Yakutsk',
        country: 'Russia',
        iataCode: 'N',
        icaoCode: 'UEMM',
        latitude: '62,103484',
        longitude: '129,545288',
        altitude: '577',
        timezone: '9',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Cooma Hospital Helipad',
        city: 'Yuzhno-Kurilsk',
        country: 'Russia',
        iataCode: 'DEE',
        icaoCode: 'YXCM',
        latitude: '-36,242155',
        longitude: '149,130147',
        altitude: '2656',
        timezone: '10',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Lima Allen County Airport',
        city: 'Lima',
        country: 'United States',
        iataCode: 'AOH',
        icaoCode: 'KAOH',
        latitude: '40,706902',
        longitude: '-84,026703',
        altitude: '975',
        timezone: '-5',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Sondok Airport',
        city: 'Hamhung',
        country: 'North Korea',
        iataCode: 'DSO',
        icaoCode: 'ZKSD',
        latitude: '39,745201',
        longitude: '127,473999',
        altitude: '12',
        timezone: '8,5',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Malcolm McKinnon Airport',
        city: 'Brunswick',
        country: 'United States',
        iataCode: 'SSI',
        icaoCode: 'KSSI',
        latitude: '31,15180016',
        longitude: '-81,39129639',
        altitude: '19',
        timezone: '-5',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Beaver County Airport',
        city: 'Beaver Falls',
        country: 'United States',
        iataCode: 'BFP',
        icaoCode: 'KBVI',
        latitude: '40,77249908',
        longitude: '-80,3914032',
        altitude: '1253',
        timezone: '-5',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Georgetown County Airport',
        city: 'Georgetown',
        country: 'United States',
        iataCode: 'GGE',
        icaoCode: 'KGGE',
        latitude: '33,31169891',
        longitude: '-79,31960297',
        altitude: '39',
        timezone: '-5',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Hardwick Field',
        city: 'Cleveland',
        country: 'United States',
        iataCode: 'HDI',
        icaoCode: 'KHDI',
        latitude: '35,2201004',
        longitude: '-84,83239746',
        altitude: '874',
        timezone: '-5',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Renton Municipal Airport',
        city: 'Renton',
        country: 'United States',
        iataCode: 'RNT',
        icaoCode: 'KRNT',
        latitude: '47,49309921',
        longitude: '-122,2160034',
        altitude: '32',
        timezone: '-8',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Brackett Field',
        city: 'La Verne',
        country: 'United States',
        iataCode: 'POC',
        icaoCode: 'KPOC',
        latitude: '34,09159851',
        longitude: '-117,7819977',
        altitude: '1011',
        timezone: '-8',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Cross City Airport',
        city: 'Cross City',
        country: 'United States',
        iataCode: 'CTY',
        icaoCode: 'KCTY',
        latitude: '29,63549995',
        longitude: '-83,10479736',
        altitude: '42',
        timezone: '-5',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Oconee County Regional Airport',
        city: 'Clemson',
        country: 'United States',
        iataCode: 'CEU',
        icaoCode: 'KCEU',
        latitude: '34,6719017',
        longitude: '-82,8864975',
        altitude: '892',
        timezone: '-5',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Beech Factory Airport',
        city: 'Wichita',
        country: 'United States',
        iataCode: 'BEC',
        icaoCode: 'KBEC',
        latitude: '37,69449997',
        longitude: '-97,21499634',
        altitude: '1408',
        timezone: '-6',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Tabing Airport',
        city: 'Padang',
        country: 'Indonesia',
        iataCode: 'PDG',
        icaoCode: 'WIMG',
        latitude: '-0,874988973',
        longitude: '100,3519974',
        altitude: '9',
        timezone: '7',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Tom B. David Field',
        city: 'Calhoun',
        country: 'United States',
        iataCode: 'N',
        icaoCode: 'KCZL',
        latitude: '34,45539856',
        longitude: '-84,93920135',
        altitude: '647',
        timezone: '-5',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Habersham County Airport',
        city: 'Cornelia',
        country: 'United States',
        iataCode: 'N',
        icaoCode: 'KAJR',
        latitude: '34,49990082',
        longitude: '-83,55670166',
        altitude: '1448',
        timezone: '-5',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Georgetown Municipal Airport',
        city: 'Georgetown',
        country: 'United States',
        iataCode: 'GTU',
        icaoCode: 'KGTU',
        latitude: '30,67880058',
        longitude: '-97,67939758',
        altitude: '790',
        timezone: '-6',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Old Rhinebeck Airport',
        city: 'Rhinebeck',
        country: 'United States',
        iataCode: 'N',
        icaoCode: 'NY94',
        latitude: '41,9715004',
        longitude: '-73,86289978',
        altitude: '323',
        timezone: '-5',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Duxford Airport',
        city: 'Duxford',
        country: 'United Kingdom',
        iataCode: 'QFO',
        icaoCode: 'EGSU',
        latitude: '52,09080124',
        longitude: '0,131944001',
        altitude: '125',
        timezone: '0',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Sidney Municipal-Lloyd W Carr Field',
        city: 'Sidney',
        country: 'United States',
        iataCode: 'SNY',
        icaoCode: 'KSNY',
        latitude: '41,10129929',
        longitude: '-102,9850006',
        altitude: '4313',
        timezone: '-7',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Great Keppel Is Airport',
        city: 'Great Keppel Island',
        country: 'Australia',
        iataCode: 'GKL',
        icaoCode: 'YGKL',
        latitude: '-23,18330002',
        longitude: '150,9420013',
        altitude: '21',
        timezone: '10',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Roper Bar Airport',
        city: 'Roper Bar',
        country: 'Australia',
        iataCode: 'RPB',
        icaoCode: 'YRRB',
        latitude: '-14,734814',
        longitude: '134,525485',
        altitude: '92',
        timezone: '9,5',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Mount Garnet Airport',
        city: 'Mount Garnet',
        country: 'Australia',
        iataCode: 'N',
        icaoCode: 'YMRT',
        latitude: '-17,70000076',
        longitude: '145,1499939',
        altitude: '657',
        timezone: '10',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Innisfail Airport',
        city: 'Innisfail',
        country: 'Australia',
        iataCode: 'IFL',
        icaoCode: 'YIFL',
        latitude: '-17,55940056',
        longitude: '146,0119934',
        altitude: '46',
        timezone: '10',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Kalaeloa Airport',
        city: 'Kapolei',
        country: 'United States',
        iataCode: 'N',
        icaoCode: 'PHJR',
        latitude: '21,3074',
        longitude: '-158,070009',
        altitude: '30',
        timezone: '-10',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Bamiyan Airport',
        city: 'Bamyan',
        country: 'Afghanistan',
        iataCode: 'BIN',
        icaoCode: 'OABN',
        latitude: '34,81700134',
        longitude: '67,81700134',
        altitude: '8367',
        timezone: '4,5',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Moomba Airport',
        city: 'Moomba',
        country: 'Australia',
        iataCode: 'MOO',
        icaoCode: 'YOOM',
        latitude: '-28,09939957',
        longitude: '140,1970062',
        altitude: '143',
        timezone: '9,5',
        dst: 'O',
        type: 'airport',
    },
    {
        name: 'Iosco County Airport',
        city: 'East Tawas',
        country: 'United States',
        iataCode: 'ECA',
        icaoCode: 'K6D9',
        latitude: '44,312801',
        longitude: '-83,422302',
        altitude: '606',
        timezone: '-5',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Madison County Executive Airport-Tom Sharp Jr Field',
        city: 'Huntsville',
        country: 'United States',
        iataCode: 'N',
        icaoCode: 'KMDQ',
        latitude: '34,8614006',
        longitude: '-86,55750275',
        altitude: '756',
        timezone: '-6',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Leesburg Executive Airport',
        city: 'Leesburg',
        country: 'United States',
        iataCode: 'JYO',
        icaoCode: 'KJYO',
        latitude: '39,07799911',
        longitude: '-77,55750275',
        altitude: '389',
        timezone: '-5',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Anoka County-Blaine Arpt(Janes Field) Airport',
        city: 'Anoka',
        country: 'United States',
        iataCode: 'N',
        icaoCode: 'KANE',
        latitude: '45,14500046',
        longitude: '-93,21140289',
        altitude: '912',
        timezone: '-6',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Robinson Crusoe Airport',
        city: 'San Juan Bautista',
        country: 'Chile',
        iataCode: 'N',
        icaoCode: 'SCIR',
        latitude: '-33,66500092',
        longitude: '-78,92970276',
        altitude: '433',
        timezone: '-4',
        dst: 'S',
        type: 'airport',
    },
    {
        name: 'Villa Airport',
        city: 'Maamigili',
        country: 'Maldives',
        iataCode: 'VAM',
        icaoCode: 'VRMV',
        latitude: '3,470555556',
        longitude: '72,83583333',
        altitude: '6',
        timezone: '5',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Lingling Airport',
        city: 'Yongzhou',
        country: 'China',
        iataCode: 'LLF',
        icaoCode: 'ZGLG',
        latitude: '26,338661',
        longitude: '111,610043',
        altitude: '340',
        timezone: '8',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Lošinj Island Airport',
        city: 'Mali Losinj',
        country: 'Croatia',
        iataCode: 'LSZ',
        icaoCode: 'LDLO',
        latitude: '44,56579971',
        longitude: '14,39309978',
        altitude: '151',
        timezone: '1',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Onslow Airport',
        city: 'Onslow',
        country: 'Australia',
        iataCode: 'ONS',
        icaoCode: 'YOLW',
        latitude: '-21,66830063',
        longitude: '115,112999',
        altitude: '7',
        timezone: '8',
        dst: 'O',
        type: 'airport',
    },
    {
        name: 'Theodore Airport',
        city: 'Theodore',
        country: 'Australia',
        iataCode: 'TDR',
        icaoCode: 'YTDR',
        latitude: '-24,99329948',
        longitude: '150,0930023',
        altitude: '171',
        timezone: '10',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Williamson Sodus Airport',
        city: 'Williamson',
        country: 'United States',
        iataCode: 'SDC',
        icaoCode: 'KSDC',
        latitude: '43,23469925',
        longitude: '-77,1210022',
        altitude: '424',
        timezone: '-5',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Fairoaks Airport',
        city: 'Fairoaks',
        country: 'United Kingdom',
        iataCode: 'N',
        icaoCode: 'EGTF',
        latitude: '51,34809875',
        longitude: '-0,558888972',
        altitude: '80',
        timezone: '0',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Boulder Municipal Airport',
        city: 'Boulder',
        country: 'United States',
        iataCode: 'WBU',
        icaoCode: 'KBDU',
        latitude: '40,03939819',
        longitude: '-105,2259979',
        altitude: '5288',
        timezone: '-7',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Neustadt-Glewe Airport',
        city: 'Neustadt-Glewe',
        country: 'Germany',
        iataCode: 'N',
        icaoCode: 'EDAN',
        latitude: '53,35972214',
        longitude: '11,61527824',
        altitude: '115',
        timezone: '1',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Goetsenhoven Air Base',
        city: 'GOETSENHOVEN',
        country: 'Belgium',
        iataCode: 'N',
        icaoCode: 'EBTN',
        latitude: '50,78170013',
        longitude: '4,957779884',
        altitude: '246',
        timezone: '1',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Tutow Airport',
        city: 'Wildenrath',
        country: 'Germany',
        iataCode: 'N',
        icaoCode: 'EDUW',
        latitude: '53,92194366',
        longitude: '13,21888924',
        altitude: '26',
        timezone: '1',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Bremgarten Airport',
        city: 'Bremgarten',
        country: 'Germany',
        iataCode: 'N',
        icaoCode: 'EDTG',
        latitude: '47,90277863',
        longitude: '7,617777824',
        altitude: '696',
        timezone: '1',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Bitburg Airport',
        city: 'Birburg',
        country: 'Germany',
        iataCode: 'BBJ',
        icaoCode: 'EDRB',
        latitude: '49,945278',
        longitude: '6,565',
        altitude: '1220',
        timezone: '1',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Palo Alto Airport of Santa Clara County',
        city: 'Palo Alto',
        country: 'United States',
        iataCode: 'PAO',
        icaoCode: 'KPAO',
        latitude: '37,46110153',
        longitude: '-122,1149979',
        altitude: '4',
        timezone: '-8',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Ust-Nera Airport',
        city: 'Ust-Nera',
        country: 'Russia',
        iataCode: 'N',
        icaoCode: 'UEMT',
        latitude: '64,55000305',
        longitude: '143,1150055',
        altitude: '1805',
        timezone: '10',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Letiště Vysoké Mýto',
        city: 'Vysoke Myto',
        country: 'Czech Republic',
        iataCode: 'N',
        icaoCode: 'LKVM',
        latitude: '49,926899',
        longitude: '16,185801',
        altitude: '991',
        timezone: '1',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Spa (la Sauvenière) Airport',
        city: 'Spa',
        country: 'Belgium',
        iataCode: 'N',
        icaoCode: 'EBSP',
        latitude: '50,48249817',
        longitude: '5,910299778',
        altitude: '1581',
        timezone: '1',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Falcon Field',
        city: 'Mesa',
        country: 'United States',
        iataCode: 'FFZ',
        icaoCode: 'KFFZ',
        latitude: '33,46080017',
        longitude: '-111,7279968',
        altitude: '1394',
        timezone: '-7',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Coolidge Municipal Airport',
        city: 'Cooldige',
        country: 'United States',
        iataCode: 'P08',
        icaoCode: 'KP08',
        latitude: '32,93590164',
        longitude: '-111,427002',
        altitude: '1574',
        timezone: '-7',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Cottonwood Airport',
        city: 'Cottonwood',
        country: 'United States',
        iataCode: 'P52',
        icaoCode: 'KP52',
        latitude: '34,72999954',
        longitude: '-112,0350037',
        altitude: '3550',
        timezone: '-7',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Suarlée Airport',
        city: 'Namur',
        country: 'Belgium',
        iataCode: 'N',
        icaoCode: 'EBNM',
        latitude: '50,487999',
        longitude: '4,76892',
        altitude: '594',
        timezone: '1',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Kiewit Airfield Hasselt',
        city: 'Hasselt',
        country: 'Belgium',
        iataCode: 'N',
        icaoCode: 'EBZH',
        latitude: '50,970001',
        longitude: '5,37507',
        altitude: '141',
        timezone: '1',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Ak-Chin Regional Airport',
        city: 'Phoenix',
        country: 'United States',
        iataCode: 'A39',
        icaoCode: 'KA39',
        latitude: '32,9908056',
        longitude: '-111,9185278',
        altitude: '1300',
        timezone: '-7',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Wickenburg Municipal Airport',
        city: 'Wickenburg',
        country: 'United States',
        iataCode: 'E25',
        icaoCode: 'KE25',
        latitude: '33,96889877',
        longitude: '-112,7990036',
        altitude: '2377',
        timezone: '-7',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Yangzhou Taizhou Airport',
        city: 'Yangzhou',
        country: 'China',
        iataCode: 'YTY',
        icaoCode: 'ZSYA',
        latitude: '32,5634',
        longitude: '119,7198',
        altitude: '7',
        timezone: '8',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Oakland County International Airport',
        city: 'Pontiac',
        country: 'United States',
        iataCode: 'PTK',
        icaoCode: 'KPTK',
        latitude: '42,66550064',
        longitude: '-83,42009735',
        altitude: '980',
        timezone: '-5',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Kissidougou Airport',
        city: 'Kissidougou',
        country: 'Guinea',
        iataCode: 'KSI',
        icaoCode: 'GUKU',
        latitude: '9,160559654',
        longitude: '-10,12440014',
        altitude: '1808',
        timezone: '0',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Dillant Hopkins Airport',
        city: 'Keene',
        country: 'United States',
        iataCode: 'EEN',
        icaoCode: 'KEEN',
        latitude: '42,89839935',
        longitude: '-72,27079773',
        altitude: '488',
        timezone: '-5',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Kawama Airport',
        city: 'Kawama',
        country: 'Cuba',
        iataCode: 'N',
        icaoCode: 'MUKW',
        latitude: '23,12400055',
        longitude: '-81,3015976',
        altitude: '16',
        timezone: '-5',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Kooddoo Airport',
        city: 'Kooddoo',
        country: 'Maldives',
        iataCode: 'GKK',
        icaoCode: 'VRMO',
        latitude: '0,7324',
        longitude: '73,4336',
        altitude: '29',
        timezone: '5',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Glasgow Industrial Airport',
        city: 'Glasgow',
        country: 'United States',
        iataCode: 'N',
        icaoCode: '07MT',
        latitude: '48,42110062',
        longitude: '-106,5279999',
        altitude: '2762',
        timezone: '-7',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Rochester Airport',
        city: 'Rochester',
        country: 'United Kingdom',
        iataCode: 'RCS',
        icaoCode: 'EGTO',
        latitude: '51,35189819',
        longitude: '0,503332973',
        altitude: '436',
        timezone: '0',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Termas de Río Hondo international Airport',
        city: 'Rio Hondo',
        country: 'Argentina',
        iataCode: 'RHD',
        icaoCode: 'SANR',
        latitude: '-27,4966',
        longitude: '-64,93595',
        altitude: '935',
        timezone: '-3',
        dst: 'S',
        type: 'airport',
    },
    {
        name: 'Keetmanshoop Airport',
        city: 'Keetmanshoop',
        country: 'Namibia',
        iataCode: 'KMP',
        icaoCode: 'FYKT',
        latitude: '-26,53980064',
        longitude: '18,1114006',
        altitude: '3506',
        timezone: '1',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Iowa City Municipal Airport',
        city: 'Iowa City',
        country: 'United States',
        iataCode: 'IOW',
        icaoCode: 'KIOW',
        latitude: '41,6391983',
        longitude: '-91,54650116',
        altitude: '668',
        timezone: '-6',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Turpan Jiaohe Airport',
        city: 'Turpan',
        country: 'China',
        iataCode: 'TLQ',
        icaoCode: 'ZWTP',
        latitude: '43,0308',
        longitude: '89,0987',
        altitude: '934',
        timezone: '8',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Lorenzo Airport',
        city: 'Morro de Sao Paulo',
        country: 'Brazil',
        iataCode: 'N',
        icaoCode: 'SNCL',
        latitude: '-13,38944435',
        longitude: '-38,90999985',
        altitude: '3',
        timezone: '-3',
        dst: 'S',
        type: 'airport',
    },
    {
        name: 'Windom Municipal Airport',
        city: 'Windom',
        country: 'United States',
        iataCode: 'MWM',
        icaoCode: 'KMWM',
        latitude: '43,91339874',
        longitude: '-95,10939789',
        altitude: '1410',
        timezone: '-6',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Longview Ranch Airport',
        city: 'Longview',
        country: 'United States',
        iataCode: 'N',
        icaoCode: 'OG39',
        latitude: '44,6617012',
        longitude: '-119,6520004',
        altitude: '2080',
        timezone: '-8',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Sortavala Airport',
        city: 'Sortavala',
        country: 'Russia',
        iataCode: 'N',
        icaoCode: 'ULPW',
        latitude: '61,73609924',
        longitude: '30,67359924',
        altitude: '0',
        timezone: '3',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Lee Airport',
        city: 'Annapolis',
        country: 'United States',
        iataCode: 'ANP',
        icaoCode: 'KANP',
        latitude: '38,942902',
        longitude: '-76,568398',
        altitude: '34',
        timezone: '-5',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Cuamba Airport',
        city: 'Cuamba',
        country: 'Mozambique',
        iataCode: 'FXO',
        icaoCode: 'FQCB',
        latitude: '-14,815',
        longitude: '36,529999',
        altitude: '1919',
        timezone: '2',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Bodaybo Airport',
        city: 'Bodaibo',
        country: 'Russia',
        iataCode: 'ODO',
        icaoCode: 'UIKB',
        latitude: '57,86610031',
        longitude: '114,2429962',
        altitude: '919',
        timezone: '8',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Zhytomyr Airport',
        city: 'Zhytomyr',
        country: 'Ukraine',
        iataCode: 'ZTR',
        icaoCode: 'UKKV',
        latitude: '50,270556',
        longitude: '28,738611',
        altitude: '0',
        timezone: '2',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Paluknys Airport',
        city: 'Paluknys',
        country: 'Lithuania',
        iataCode: 'N',
        icaoCode: 'EYVP',
        latitude: '54,48484802',
        longitude: '24,98737335',
        altitude: '400',
        timezone: '2',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Mattala Rajapaksa International Airport',
        city: 'Mattala',
        country: 'Sri Lanka',
        iataCode: 'HRI',
        icaoCode: 'VCRI',
        latitude: '6,284467',
        longitude: '81,124128',
        altitude: '157',
        timezone: '5,5',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Pecos Municipal Airport',
        city: 'Pecos',
        country: 'United States',
        iataCode: 'PEQ',
        icaoCode: 'KPEQ',
        latitude: '31,38240051',
        longitude: '-103,5110016',
        altitude: '2613',
        timezone: '-6',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Hattiesburg Bobby L Chain Municipal Airport',
        city: 'Hattiesburg',
        country: 'United States',
        iataCode: 'HBG',
        icaoCode: 'KHBG',
        latitude: '31,26479912',
        longitude: '-89,25279999',
        altitude: '151',
        timezone: '-6',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Botucatu - Tancredo de Almeida Neves Airport',
        city: 'Botucatu',
        country: 'Brazil',
        iataCode: 'QCJ',
        icaoCode: 'SDBK',
        latitude: '-22,93950081',
        longitude: '-48,4679985',
        altitude: '3012',
        timezone: '-3',
        dst: 'S',
        type: 'airport',
    },
    {
        name: 'Base Aérea Airport',
        city: 'Anapolis',
        country: 'Brazil',
        iataCode: 'N',
        icaoCode: 'SBAN',
        latitude: '-16,2292',
        longitude: '-48,964298',
        altitude: '3731',
        timezone: '-3',
        dst: 'S',
        type: 'airport',
    },
    {
        name: 'São Carlos Airport',
        city: 'Sao Carlos',
        country: 'Brazil',
        iataCode: 'QSC',
        icaoCode: 'SDSC',
        latitude: '-21,87540054',
        longitude: '-47,90370178',
        altitude: '2649',
        timezone: '-3',
        dst: 'S',
        type: 'airport',
    },
    {
        name: 'Chan Gurney Municipal Airport',
        city: 'Yankton',
        country: 'United States',
        iataCode: 'YKN',
        icaoCode: 'KYKN',
        latitude: '42,91669846',
        longitude: '-97,3859024',
        altitude: '1306',
        timezone: '-6',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Sir Bani Yas Airport',
        city: 'Sir Bani Yas Island',
        country: 'United Arab Emirates',
        iataCode: 'XSB',
        icaoCode: 'OMBY',
        latitude: '24,283611',
        longitude: '52,580278',
        altitude: '25',
        timezone: '4',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Bromont (Roland Desourdy) Airport',
        city: 'Bromont',
        country: 'Canada',
        iataCode: 'ZBM',
        icaoCode: 'CZBM',
        latitude: '45,29079819',
        longitude: '-72,74140167',
        altitude: '375',
        timezone: '-5',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Beccles Airport',
        city: 'Beccles',
        country: 'United Kingdom',
        iataCode: 'N',
        icaoCode: 'EGSM',
        latitude: '52,43529892',
        longitude: '1,618330002',
        altitude: '80',
        timezone: '0',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Kratie Airport',
        city: 'Kratie',
        country: 'Cambodia',
        iataCode: 'KTI',
        icaoCode: 'VDKT',
        latitude: '12,48799992',
        longitude: '106,0550003',
        altitude: '0',
        timezone: '7',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Caldera Airport',
        city: 'Caldera',
        country: 'Chile',
        iataCode: 'N',
        icaoCode: 'SCCL',
        latitude: '-27,0781002',
        longitude: '-70,79530334',
        altitude: '180',
        timezone: '-4',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'San Pedro de Atacama Airport',
        city: 'San Pedro de Atacama',
        country: 'Chile',
        iataCode: 'N',
        icaoCode: 'SCPE',
        latitude: '-22,92169952',
        longitude: '-68,15840149',
        altitude: '7960',
        timezone: '-4',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Copacabana Airport',
        city: 'Copacabana',
        country: 'Bolivia',
        iataCode: 'N',
        icaoCode: 'SLCC',
        latitude: '-16,19109917',
        longitude: '-69,09559631',
        altitude: '12591',
        timezone: '-4',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Guyuan Liupanshan Airport',
        city: 'Guyuan',
        country: 'China',
        iataCode: 'GYU',
        icaoCode: 'ZLGY',
        latitude: '36,07888889',
        longitude: '106,2169444',
        altitude: '5696',
        timezone: '8',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'RAF Brawdy',
        city: 'Brawdy',
        country: 'United Kingdom',
        iataCode: 'N',
        icaoCode: 'EGDA',
        latitude: '51,88375',
        longitude: '-5,119972',
        altitude: '0',
        timezone: '0',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Changhai Airport',
        city: 'Changhai',
        country: 'China',
        iataCode: 'CNI',
        icaoCode: 'ZYCH',
        latitude: '39,26666667',
        longitude: '122,6669444',
        altitude: '80',
        timezone: '8',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Redhill Aerodrome',
        city: 'Redhill',
        country: 'United Kingdom',
        iataCode: 'KRH',
        icaoCode: 'EGKR',
        latitude: '51,21360016',
        longitude: '-0,138611004',
        altitude: '222',
        timezone: '0',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Chinchilla Airport',
        city: 'Chinchilla',
        country: 'Australia',
        iataCode: 'CCL',
        icaoCode: 'YCCA',
        latitude: '-26,77499962',
        longitude: '150,6170044',
        altitude: '1028',
        timezone: '10',
        dst: 'O',
        type: 'airport',
    },
    {
        name: 'Hayward Executive Airport',
        city: 'Hayward',
        country: 'United States',
        iataCode: 'HWD',
        icaoCode: 'KHWD',
        latitude: '37,65919876',
        longitude: '-122,1220016',
        altitude: '52',
        timezone: '-8',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Motueka Airport',
        city: 'Motueka',
        country: 'New Zealand',
        iataCode: 'MZP',
        icaoCode: 'NZMK',
        latitude: '-41,12329865',
        longitude: '172,9889984',
        altitude: '39',
        timezone: '12',
        dst: 'Z',
        type: 'airport',
    },
    {
        name: 'Shute Harbour Airport',
        city: 'Shute Harbour',
        country: 'Australia',
        iataCode: 'JHQ',
        icaoCode: 'YSHR',
        latitude: '-20,277221',
        longitude: '148,755556',
        altitude: '12',
        timezone: '10',
        dst: 'O',
        type: 'airport',
    },
    {
        name: 'Ann Arbor Municipal Airport',
        city: 'Ann Arbor',
        country: 'United States',
        iataCode: 'ARB',
        icaoCode: 'KARB',
        latitude: '42,22299957',
        longitude: '-83,74559784',
        altitude: '839',
        timezone: '-5',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Shepparton Airport',
        city: 'Shepparton',
        country: 'Australia',
        iataCode: 'SHT',
        icaoCode: 'YSHT',
        latitude: '-36,42890167',
        longitude: '145,3930054',
        altitude: '374',
        timezone: '10',
        dst: 'O',
        type: 'airport',
    },
    {
        name: 'Temora Airport',
        city: 'Temora',
        country: 'Australia',
        iataCode: 'TEM',
        icaoCode: 'YTEM',
        latitude: '-34,42139816',
        longitude: '147,5119934',
        altitude: '921',
        timezone: '10',
        dst: 'O',
        type: 'airport',
    },
    {
        name: 'Gayndah Airport',
        city: 'Gayndah',
        country: 'Australia',
        iataCode: 'GAH',
        icaoCode: 'YGAY',
        latitude: '-25,61440086',
        longitude: '151,6190033',
        altitude: '369',
        timezone: '10',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Wilcannia Airport',
        city: 'Wilcannia',
        country: 'Australia',
        iataCode: 'WIO',
        icaoCode: 'YWCA',
        latitude: '-31,52639961',
        longitude: '143,375',
        altitude: '250',
        timezone: '10',
        dst: 'O',
        type: 'airport',
    },
    {
        name: 'Ivanhoe Airport',
        city: 'Ivanhoe',
        country: 'Australia',
        iataCode: 'N',
        icaoCode: 'YIVO',
        latitude: '-32,88330078',
        longitude: '144,3099976',
        altitude: '330',
        timezone: '10',
        dst: 'O',
        type: 'airport',
    },
    {
        name: 'Menindee Airport',
        city: 'Menindee',
        country: 'Australia',
        iataCode: 'N',
        icaoCode: 'YMED',
        latitude: '-32,36669922',
        longitude: '142,4049988',
        altitude: '0',
        timezone: '10',
        dst: 'O',
        type: 'airport',
    },
    {
        name: 'Pooncarie Airport',
        city: 'Pooncarie',
        country: 'Australia',
        iataCode: 'N',
        icaoCode: 'YPCE',
        latitude: '-33,36669922',
        longitude: '142,5879974',
        altitude: '50',
        timezone: '10',
        dst: 'O',
        type: 'airport',
    },
    {
        name: 'Tilpa Airport',
        city: 'Tilpa',
        country: 'Australia',
        iataCode: 'N',
        icaoCode: 'YTLP',
        latitude: '-30,93330002',
        longitude: '144,4170074',
        altitude: '0',
        timezone: '10',
        dst: 'O',
        type: 'airport',
    },
    {
        name: 'Reichenbach Air Base',
        city: 'Reichenbach im Kandertal',
        country: 'Switzerland',
        iataCode: 'N',
        icaoCode: 'LSGR',
        latitude: '46,61360168',
        longitude: '7,677780151',
        altitude: '2385',
        timezone: '1',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Bijie Feixiong Airport',
        city: 'Bijie',
        country: 'China',
        iataCode: 'BFJ',
        icaoCode: 'ZUBJ',
        latitude: '27,267066',
        longitude: '105,472097',
        altitude: '4751',
        timezone: '8',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Lensk Airport',
        city: 'Lensk',
        country: 'Russia',
        iataCode: 'ULK',
        icaoCode: 'UERL',
        latitude: '60,72060013',
        longitude: '114,8259964',
        altitude: '801',
        timezone: '9',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Kavalerovo Airport',
        city: 'Kavalerovo',
        country: 'Russia',
        iataCode: 'N',
        icaoCode: 'UHWK',
        latitude: '44,2726',
        longitude: '135,029',
        altitude: '730',
        timezone: '10',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Şanlıurfa GAP Airport',
        city: 'Sanliurfa',
        country: 'Turkey',
        iataCode: 'GNY',
        icaoCode: 'LTCS',
        latitude: '37,44566345',
        longitude: '38,89559174',
        altitude: '2708',
        timezone: '3',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Zafer Airport',
        city: 'Kutahya',
        country: 'Turkey',
        iataCode: 'KZR',
        icaoCode: 'LTBZ',
        latitude: '39,107377',
        longitude: '30,115724',
        altitude: '3327',
        timezone: '3',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Velikiye Luki Airport',
        city: 'Velikiye Luki',
        country: 'Russia',
        iataCode: 'VLU',
        icaoCode: 'ULOL',
        latitude: '56,3810997',
        longitude: '30,60810089',
        altitude: '328',
        timezone: '3',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Yeltsovka Airport',
        city: 'Novosibirsk',
        country: 'Russia',
        iataCode: 'N',
        icaoCode: 'UNNE',
        latitude: '55,0923996',
        longitude: '83,00450134',
        altitude: '617',
        timezone: '7',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Kodinsk Airport',
        city: 'Kodinsk',
        country: 'Russia',
        iataCode: 'N',
        icaoCode: 'UNKI',
        latitude: '58,47940063',
        longitude: '99,09390259',
        altitude: '0',
        timezone: '7',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Lake Macquarie Airport',
        city: 'Lake Macquarie',
        country: 'Australia',
        iataCode: 'BEO',
        icaoCode: 'YPEC',
        latitude: '-33,0667',
        longitude: '151,647995',
        altitude: '2',
        timezone: '10',
        dst: 'O',
        type: 'airport',
    },
    {
        name: 'Atlanta South Regional Airport/Tara Field',
        city: 'Hampton',
        country: 'United States',
        iataCode: '4A7',
        icaoCode: 'K4A7',
        latitude: '33,389099',
        longitude: '-84,332397',
        altitude: '874',
        timezone: '-5',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Brampton Island Airport',
        city: 'Brampton Island',
        country: 'Australia',
        iataCode: 'BMP',
        icaoCode: 'YBPI',
        latitude: '-20,80330086',
        longitude: '149,2700043',
        altitude: '11',
        timezone: '10',
        dst: 'O',
        type: 'airport',
    },
    {
        name: 'Endelave West Airport',
        city: 'Endelage',
        country: 'Denmark',
        iataCode: 'N',
        icaoCode: 'EKEL',
        latitude: '55,75650024',
        longitude: '10,24849987',
        altitude: '15',
        timezone: '1',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'St. Johann In Tirol Airport',
        city: 'St. Johann in Tirol',
        country: 'Austria',
        iataCode: 'N',
        icaoCode: 'LOIJ',
        latitude: '47,5201',
        longitude: '12,4497',
        altitude: '2198',
        timezone: '1',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Thalmässing-Waizenhofen Airport',
        city: 'Thalmaessing',
        country: 'Germany',
        iataCode: 'N',
        icaoCode: 'EDPW',
        latitude: '49,06416702',
        longitude: '11,20916653',
        altitude: '1893',
        timezone: '1',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Cochrane Airport',
        city: 'Cochrane',
        country: 'Canada',
        iataCode: 'YCN',
        icaoCode: 'CYCN',
        latitude: '49,10559845',
        longitude: '-81,01360321',
        altitude: '861',
        timezone: '-5',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Estadual Arthur Siqueira Airport',
        city: 'Braganca Paulista',
        country: 'Brazil',
        iataCode: 'BJP',
        icaoCode: 'SBBP',
        latitude: '-22,979162',
        longitude: '-46,537508',
        altitude: '2887',
        timezone: '-3',
        dst: 'S',
        type: 'airport',
    },
    {
        name: 'Busselton Regional Airport',
        city: 'Brusselton',
        country: 'Australia',
        iataCode: 'BQB',
        icaoCode: 'YBLN',
        latitude: '-33,68842316',
        longitude: '115,4015961',
        altitude: '55',
        timezone: '8',
        dst: 'O',
        type: 'airport',
    },
    {
        name: 'Srednekolymsk Airport',
        city: 'Srednekolymsk',
        country: 'Russia',
        iataCode: 'SEK',
        icaoCode: 'UESK',
        latitude: '67,4805',
        longitude: '153,7364',
        altitude: '60',
        timezone: '11',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Comarapa Airport',
        city: 'Salt Lake City',
        country: 'United States',
        iataCode: 'N',
        icaoCode: 'SLCR',
        latitude: '-17,91360092',
        longitude: '-64,51779938',
        altitude: '6186',
        timezone: '-4',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Inverell Airport',
        city: 'Inverell',
        country: 'Australia',
        iataCode: 'IVR',
        icaoCode: 'YIVL',
        latitude: '-29,88829994',
        longitude: '151,1439972',
        altitude: '2667',
        timezone: '10',
        dst: 'O',
        type: 'airport',
    },
    {
        name: 'Glen Innes Airport',
        city: 'Glen Innes',
        country: 'Australia',
        iataCode: 'GLI',
        icaoCode: 'YGLI',
        latitude: '-29,67499924',
        longitude: '151,6889954',
        altitude: '3433',
        timezone: '10',
        dst: 'O',
        type: 'airport',
    },
    {
        name: 'Ansbach-Petersdorf Airport',
        city: 'Ansbach',
        country: 'Germany',
        iataCode: 'N',
        icaoCode: 'EDQF',
        latitude: '49,361111',
        longitude: '10,669444',
        altitude: '1371',
        timezone: '1',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Immokalee Regional Airport',
        city: 'Immokalee ',
        country: 'United States',
        iataCode: 'IMM',
        icaoCode: 'KIMM',
        latitude: '26,43320084',
        longitude: '-81,40100098',
        altitude: '37',
        timezone: '-5',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Maranggo Airport',
        city: 'Sulawesi Tenggara',
        country: 'Indonesia',
        iataCode: 'N',
        icaoCode: 'WA44',
        latitude: '-5,764570236',
        longitude: '123,9169998',
        altitude: '169',
        timezone: '8',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Rancho San Simeon Airport',
        city: 'Cambria',
        country: 'United States',
        iataCode: 'N',
        icaoCode: '66CA',
        latitude: '35,60770035',
        longitude: '-121,1100006',
        altitude: '320',
        timezone: '-8',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Yichun Mingyueshan Airport',
        city: 'Yichun',
        country: 'China',
        iataCode: 'YIC',
        icaoCode: 'ZSYC',
        latitude: '27,8025',
        longitude: '114,3062',
        altitude: '430',
        timezone: '8',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Dinwiddie County Airport',
        city: 'Petersburg',
        country: 'United States',
        iataCode: 'PTB',
        icaoCode: 'KPTB',
        latitude: '37,18379974',
        longitude: '-77,50740051',
        altitude: '193',
        timezone: '-5',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Kasongo Airport',
        city: 'Kasongo',
        country: 'Congo (Kinshasa)',
        iataCode: 'KGN',
        icaoCode: 'FZOK',
        latitude: '-4,532999992',
        longitude: '26,61700058',
        altitude: '1785',
        timezone: '2',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'McMurdo Station Pegasus Field',
        city: 'McMurdo Station',
        country: 'Antarctica',
        iataCode: 'N',
        icaoCode: 'NZPG',
        latitude: '-77,96340179',
        longitude: '166,5249939',
        altitude: '18',
        timezone: '12',
        dst: 'Z',
        type: 'airport',
    },
    {
        name: 'Klatovy Airport',
        city: 'Klatovy',
        country: 'Czech Republic',
        iataCode: 'N',
        icaoCode: 'LKKT',
        latitude: '49,41830063',
        longitude: '13,32190037',
        altitude: '1299',
        timezone: '1',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Sheboygan County Memorial Airport',
        city: 'Sheboygan',
        country: 'United States',
        iataCode: 'SBM',
        icaoCode: 'KSBM',
        latitude: '43,76959991',
        longitude: '-87,85140228',
        altitude: '755',
        timezone: '-6',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Fortescue - Dave Forrest Aerodrome',
        city: 'Cloudbreak',
        country: 'Australia',
        iataCode: 'KFE',
        icaoCode: 'YFDF',
        latitude: '-22,290754',
        longitude: '119,437143',
        altitude: '1555',
        timezone: '8',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Kangel Danda Airport',
        city: 'Kangel Danda',
        country: 'Nepal',
        iataCode: 'N',
        icaoCode: 'VNKL',
        latitude: '27,41063331',
        longitude: '86,646595',
        altitude: '0',
        timezone: '5,75',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Bajura Airport',
        city: 'Bajura',
        country: 'Nepal',
        iataCode: 'BJU',
        icaoCode: 'VNBR',
        latitude: '29,50200081',
        longitude: '81,66899872',
        altitude: '4300',
        timezone: '5,75',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Chara Airport',
        city: 'Chara',
        country: 'Russia',
        iataCode: 'N',
        icaoCode: 'UIAR',
        latitude: '56,91333389',
        longitude: '118,2699966',
        altitude: '2201',
        timezone: '9',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Herning Airport',
        city: 'Herning',
        country: 'Denmark',
        iataCode: 'N',
        icaoCode: 'EKHG',
        latitude: '56,18470001',
        longitude: '9,044449806',
        altitude: '167',
        timezone: '1',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Oberschleißheim Airfield',
        city: 'Schleissheim',
        country: 'Germany',
        iataCode: 'N',
        icaoCode: 'EDNX',
        latitude: '48,23944473',
        longitude: '11,56138897',
        altitude: '1594',
        timezone: '1',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Pinal Airpark',
        city: 'Marana',
        country: 'United States',
        iataCode: 'MZJ',
        icaoCode: 'KMZJ',
        latitude: '32,51060104',
        longitude: '-111,3280029',
        altitude: '1893',
        timezone: '-7',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Glendale Municipal Airport',
        city: 'Glendale',
        country: 'United States',
        iataCode: 'GEU',
        icaoCode: 'KGEU',
        latitude: '33,52690125',
        longitude: '-112,2949982',
        altitude: '1071',
        timezone: '-7',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Safford Regional Airport',
        city: 'Safford',
        country: 'United States',
        iataCode: 'SAD',
        icaoCode: 'KSAD',
        latitude: '32,85480118',
        longitude: '-109,6350021',
        altitude: '3179',
        timezone: '-7',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Verden-Scharnhorst Airport',
        city: 'Verden',
        country: 'Germany',
        iataCode: 'N',
        icaoCode: 'EDWV',
        latitude: '52,96527863',
        longitude: '9,282777786',
        altitude: '144',
        timezone: '1',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Nittenau-Bruck Airport',
        city: 'Nittenau',
        country: 'Germany',
        iataCode: 'N',
        icaoCode: 'EDNM',
        latitude: '49,22249985',
        longitude: '12,2966671',
        altitude: '1161',
        timezone: '1',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Kerama Airport',
        city: 'Kerama',
        country: 'Japan',
        iataCode: 'KJP',
        icaoCode: 'ROKR',
        latitude: '26,16830063',
        longitude: '127,2929993',
        altitude: '156',
        timezone: '9',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Americana Airport',
        city: 'Americana',
        country: 'Brazil',
        iataCode: 'N',
        icaoCode: 'SDAI',
        latitude: '-22,75580025',
        longitude: '-47,26940155',
        altitude: '2085',
        timezone: '-3',
        dst: 'S',
        type: 'airport',
    },
    {
        name: 'Ekibastuz Airport',
        city: 'Ekibastuz',
        country: 'Kazakhstan',
        iataCode: 'N',
        icaoCode: 'UASB',
        latitude: '51,5909996',
        longitude: '75,21499634',
        altitude: '621',
        timezone: '6',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Adamovka',
        city: 'Adamovka',
        country: 'Russia',
        iataCode: 'N',
        icaoCode: 'UWOD',
        latitude: '51,5',
        longitude: '59,936111',
        altitude: '0',
        timezone: '5',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Kvarkeno',
        city: 'Kvarkeno',
        country: 'Russia',
        iataCode: 'N',
        icaoCode: 'UWOH',
        latitude: '52,078333',
        longitude: '59,683333',
        altitude: '0',
        timezone: '5',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Sikeston Memorial Municipal Airport',
        city: 'Sikeston',
        country: 'United States',
        iataCode: 'SIK',
        icaoCode: 'KSIK',
        latitude: '36,89889908',
        longitude: '-89,5617981',
        altitude: '315',
        timezone: '-6',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Tetiaroa Airport',
        city: 'Tetiaroa',
        country: 'French Polynesia',
        iataCode: 'TTI',
        icaoCode: 'NTTE',
        latitude: '-17,01329994',
        longitude: '-149,5870056',
        altitude: '7',
        timezone: '-10',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Floyd Bennett Memorial Airport',
        city: 'Queensbury',
        country: 'United States',
        iataCode: 'GFL',
        icaoCode: 'KGFL',
        latitude: '43,34120178',
        longitude: '-73,61029816',
        altitude: '328',
        timezone: '-5',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Saratoga County Airport',
        city: 'Ballston Spa',
        country: 'United States',
        iataCode: '5B2',
        icaoCode: 'K5B2',
        latitude: '43,05130005',
        longitude: '-73,86119843',
        altitude: '434',
        timezone: '-5',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Crystal River Airport',
        city: 'Crystal River',
        country: 'United States',
        iataCode: 'CGC',
        icaoCode: 'KCGC',
        latitude: '28,86730003',
        longitude: '-82,57129669',
        altitude: '9',
        timezone: '-5',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Martin State Airport',
        city: 'Baltimore',
        country: 'United States',
        iataCode: 'MTN',
        icaoCode: 'KMTN',
        latitude: '39,32569885',
        longitude: '-76,4138031',
        altitude: '21',
        timezone: '-5',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Lincoln Regional Karl Harder Field',
        city: 'Lincoln',
        country: 'United States',
        iataCode: 'LHM',
        icaoCode: 'KLHM',
        latitude: '38,90919876',
        longitude: '-121,3509979',
        altitude: '121',
        timezone: '-8',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Fostoria Metropolitan Airport',
        city: 'Fostoria',
        country: 'United States',
        iataCode: 'FZI',
        icaoCode: 'KFZI',
        latitude: '41,19079971',
        longitude: '-83,39450073',
        altitude: '752',
        timezone: '-5',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Eastern Slopes Regional Airport',
        city: 'Fryeburg',
        country: 'United States',
        iataCode: 'IZG',
        icaoCode: 'KIZG',
        latitude: '43,99110031',
        longitude: '-70,94789886',
        altitude: '454',
        timezone: '-5',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Coral Creek Airport',
        city: 'Placida',
        country: 'United States',
        iataCode: 'N',
        icaoCode: 'FA54',
        latitude: '26,85449982',
        longitude: '-82,25119781',
        altitude: '4',
        timezone: '-5',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Lakefront Airport',
        city: 'New Orleans',
        country: 'United States',
        iataCode: 'NEW',
        icaoCode: 'KNEW',
        latitude: '30,04240036',
        longitude: '-90,02829742',
        altitude: '8',
        timezone: '-6',
        dst: 'A',
        type: 'airport',
    },
    {
        name: "Coeur D'Alene - Pappy Boyington Field",
        city: "Coeur d'Alene",
        country: 'United States',
        iataCode: 'COE',
        icaoCode: 'KCOE',
        latitude: '47,77429962',
        longitude: '-116,8199997',
        altitude: '2320',
        timezone: '-8',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Beaumont Municipal Airport',
        city: 'Beaumont',
        country: 'United States',
        iataCode: 'BMT',
        icaoCode: 'KBMT',
        latitude: '30,07069969',
        longitude: '-94,21579742',
        altitude: '32',
        timezone: '-6',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Vermilion Regional Airport',
        city: 'Danville',
        country: 'United States',
        iataCode: 'DNV',
        icaoCode: 'KDNV',
        latitude: '40,19919968',
        longitude: '-87,59590149',
        altitude: '697',
        timezone: '-6',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Coonabarabran Airport',
        city: 'Coonabarabran',
        country: 'Australia',
        iataCode: 'COJ',
        icaoCode: 'YCBB',
        latitude: '-31,33250046',
        longitude: '149,2669983',
        altitude: '2117',
        timezone: '10',
        dst: 'O',
        type: 'airport',
    },
    {
        name: 'Space Coast Regional Airport',
        city: 'Titusville',
        country: 'United States',
        iataCode: 'TIX',
        icaoCode: 'KTIX',
        latitude: '28,51479912',
        longitude: '-80,79920197',
        altitude: '34',
        timezone: '-5',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Bumi Airport',
        city: 'Bumi Hills',
        country: 'Zimbabwe',
        iataCode: 'N',
        icaoCode: 'FVBM',
        latitude: '-16,81699944',
        longitude: '28,35000038',
        altitude: '1650',
        timezone: '2',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Warnervale Airport',
        city: 'Warnervale Airport',
        country: 'Australia',
        iataCode: 'N',
        icaoCode: 'YWVA',
        latitude: '-33,240278',
        longitude: '151,429722',
        altitude: '25',
        timezone: '10',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Bouarfa Airport',
        city: 'Bouarfa',
        country: 'Morocco',
        iataCode: 'N',
        icaoCode: 'GMFB',
        latitude: '32,51430556',
        longitude: '-1,983055556',
        altitude: '3630',
        timezone: '0',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Nyeri Airport',
        city: 'NYERI',
        country: 'Kenya',
        iataCode: 'NYE',
        icaoCode: 'HKNI',
        latitude: '-0,364414006',
        longitude: '36,97848511',
        altitude: '5830',
        timezone: '3',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Andrau Airpark',
        city: 'Houston',
        country: 'United States',
        iataCode: 'AAP',
        icaoCode: 'KAAP',
        latitude: '29,72249985',
        longitude: '-95,58830261',
        altitude: '79',
        timezone: '-6',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Flying Cloud Airport',
        city: 'Eden Prairie',
        country: 'United States',
        iataCode: 'FCM',
        icaoCode: 'KFCM',
        latitude: '44,82720184',
        longitude: '-93,45709991',
        altitude: '906',
        timezone: '-6',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Likoma Island Airport',
        city: 'Likoma Island',
        country: 'Malawi',
        iataCode: 'LIX',
        icaoCode: 'FWLK',
        latitude: '-12,08300018',
        longitude: '34,73300171',
        altitude: '1600',
        timezone: '2',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Johnson County Executive Airport',
        city: 'Olathe',
        country: 'United States',
        iataCode: 'OJC',
        icaoCode: 'KOJC',
        latitude: '38,84759903',
        longitude: '-94,73760223',
        altitude: '1096',
        timezone: '-6',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Sigiriya Air Force Base',
        city: 'Sigiriya',
        country: 'Sri Lanka',
        iataCode: 'GIU',
        icaoCode: 'VCCS',
        latitude: '7,956669807',
        longitude: '80,72850037',
        altitude: '630',
        timezone: '5,5',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Neumünster Airport',
        city: 'Neumuenster',
        country: 'Germany',
        iataCode: 'EUM',
        icaoCode: 'EDHN',
        latitude: '54,07944489',
        longitude: '9,941389084',
        altitude: '72',
        timezone: '1',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Tak Airport',
        city: 'Tak',
        country: 'Thailand',
        iataCode: 'TKT',
        icaoCode: 'VTPT',
        latitude: '16,89599991',
        longitude: '99,25330353',
        altitude: '478',
        timezone: '7',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Barrie-Orillia (Lake Simcoe Regional Airport)',
        city: 'Barrie-Orillia',
        country: 'Canada',
        iataCode: 'YLK',
        icaoCode: 'CYLS',
        latitude: '44,48529816',
        longitude: '-79,55560303',
        altitude: '972',
        timezone: '-5',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Huronia Airport',
        city: 'Midland',
        country: 'Canada',
        iataCode: 'YEE',
        icaoCode: 'CYEE',
        latitude: '44,68330002',
        longitude: '-79,92829895',
        altitude: '770',
        timezone: '-5',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Markham Airport',
        city: 'Markham',
        country: 'Canada',
        iataCode: 'NU8',
        icaoCode: 'CNU8',
        latitude: '43,93579865',
        longitude: '-79,2621994',
        altitude: '807',
        timezone: '-5',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Stanhope Municipal Airport',
        city: 'Haliburton',
        country: 'Canada',
        iataCode: 'ND4',
        icaoCode: 'CND4',
        latitude: '45,11083333',
        longitude: '-78,64',
        altitude: '1066',
        timezone: '-5',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Lindsay Airport',
        city: 'Lindsay',
        country: 'Canada',
        iataCode: 'NF4',
        icaoCode: 'CNF4',
        latitude: '44,36470032',
        longitude: '-78,7838974',
        altitude: '882',
        timezone: '-5',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Niagara District Airport',
        city: 'Saint Catherines',
        country: 'Canada',
        iataCode: 'YCM',
        icaoCode: 'CYSN',
        latitude: '43,19169998',
        longitude: '-79,17169952',
        altitude: '321',
        timezone: '-5',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Edenvale Aerodrome',
        city: 'Edenvale',
        country: 'Canada',
        iataCode: 'N',
        icaoCode: 'CNV8',
        latitude: '44,441101',
        longitude: '-79,962799',
        altitude: '718',
        timezone: '-5',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Orillia Airport',
        city: 'Orillia',
        country: 'Canada',
        iataCode: 'N',
        icaoCode: 'CNJ4',
        latitude: '44,67765578',
        longitude: '-79,3102169',
        altitude: '725',
        timezone: '-5',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Holland Landing Airpark',
        city: 'Holland Landing',
        country: 'Canada',
        iataCode: 'N',
        icaoCode: 'CLA4',
        latitude: '44,08940125',
        longitude: '-79,49500275',
        altitude: '855',
        timezone: '-5',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Parry Sound Area Municipal Airport',
        city: 'Parry Sound',
        country: 'Canada',
        iataCode: 'YPD',
        icaoCode: 'CNK4',
        latitude: '45,2575',
        longitude: '-79,829697',
        altitude: '832',
        timezone: '-5',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Hanover / Saugeen Municipal Airport',
        city: 'Hanover',
        country: 'Canada',
        iataCode: 'N',
        icaoCode: 'CYHS',
        latitude: '44,158298',
        longitude: '-81,062798',
        altitude: '939',
        timezone: '-5',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Brandywine Airport',
        city: 'West Goshen Township',
        country: 'United States',
        iataCode: 'OQN',
        icaoCode: 'KOQN',
        latitude: '39,99010086',
        longitude: '-75,58190155',
        altitude: '466',
        timezone: '-5',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Manassas Regional Airport/Harry P. Davis Field',
        city: 'Manassas',
        country: 'United States',
        iataCode: 'MNZ',
        icaoCode: 'KHEF',
        latitude: '38,72140121',
        longitude: '-77,51540375',
        altitude: '192',
        timezone: '-5',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Texas Gulf Coast Regional Airport',
        city: 'Angleton',
        country: 'United States',
        iataCode: 'N',
        icaoCode: 'KLBX',
        latitude: '29,10860062',
        longitude: '-95,46209717',
        altitude: '25',
        timezone: '-6',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Bubovice Airport',
        city: 'Bubovice',
        country: 'Czech Republic',
        iataCode: 'N',
        icaoCode: 'LKBU',
        latitude: '49,97439957',
        longitude: '14,17809963',
        altitude: '1401',
        timezone: '1',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Rakkestad Astorp Airport',
        city: 'Rakkestad',
        country: 'Norway',
        iataCode: 'N',
        icaoCode: 'ENRK',
        latitude: '59,397499',
        longitude: '11,3469',
        altitude: '400',
        timezone: '1',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Kastamonu Airport',
        city: 'Kastamonu',
        country: 'Turkey',
        iataCode: 'KFS',
        icaoCode: 'LTAL',
        latitude: '41,31420135',
        longitude: '33,79579926',
        altitude: '3520',
        timezone: '3',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Elstree Airfield',
        city: 'Elstree',
        country: 'United Kingdom',
        iataCode: 'N',
        icaoCode: 'EGTR',
        latitude: '51,65579987',
        longitude: '-0,325832993',
        altitude: '332',
        timezone: '0',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Sandtoft Airfield',
        city: 'Sandtoft',
        country: 'United Kingdom',
        iataCode: 'N',
        icaoCode: 'EGCF',
        latitude: '53,55970001',
        longitude: '-0,858332992',
        altitude: '11',
        timezone: '0',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Torit Airport',
        city: 'Torit',
        country: 'South Sudan',
        iataCode: 'N',
        icaoCode: 'HSTR',
        latitude: '4,400000095',
        longitude: '32,58300018',
        altitude: '2050',
        timezone: '3',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Shelby County Airport',
        city: 'Shelbyville',
        country: 'United States',
        iataCode: '2H0',
        icaoCode: 'K2H0',
        latitude: '39,41040039',
        longitude: '-88,84539795',
        altitude: '618',
        timezone: '-6',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Gannan Xiahe Airport',
        city: 'Xiahe city',
        country: 'China',
        iataCode: 'GXH',
        icaoCode: 'ZLXH',
        latitude: '34,8105',
        longitude: '102,6447',
        altitude: '10510',
        timezone: '8',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Vängsö Airport',
        city: 'Vangso',
        country: 'Sweden',
        iataCode: 'N',
        icaoCode: 'ESSZ',
        latitude: '59,10110092',
        longitude: '17,21109962',
        altitude: '0',
        timezone: '1',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Comiso Airport',
        city: 'Comiso',
        country: 'Italy',
        iataCode: 'CIY',
        icaoCode: 'LICB',
        latitude: '36,994601',
        longitude: '14,607182',
        altitude: '623',
        timezone: '1',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Enumclaw Airport',
        city: 'Enumclaw',
        country: 'United States',
        iataCode: 'N',
        icaoCode: 'WA77',
        latitude: '47,1957016',
        longitude: '-122,0220032',
        altitude: '738',
        timezone: '-8',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Markovo Airport',
        city: 'Markovo',
        country: 'Russia',
        iataCode: 'KVM',
        icaoCode: 'UHMO',
        latitude: '64,66699982',
        longitude: '170,4170074',
        altitude: '0',
        timezone: '12',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Seymchan Airport',
        city: 'Seymchan',
        country: 'Russia',
        iataCode: 'N',
        icaoCode: 'UHMS',
        latitude: '62,92078018',
        longitude: '152,4227753',
        altitude: '679',
        timezone: '11',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Zyryanka Airport',
        city: 'Zyryanka',
        country: 'Russia',
        iataCode: 'ZKP',
        icaoCode: 'UESU',
        latitude: '65,7485',
        longitude: '150,8889',
        altitude: '140',
        timezone: '11',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Susuman Airport',
        city: 'Susuman',
        country: 'Russia',
        iataCode: 'N',
        icaoCode: 'UHMH',
        latitude: '62,76666641',
        longitude: '148,1466675',
        altitude: '2129',
        timezone: '11',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Ust-Maya Airport',
        city: 'Ust-Maya',
        country: 'Russia',
        iataCode: 'UMS',
        icaoCode: 'UEMU',
        latitude: '60,35699844',
        longitude: '134,4349976',
        altitude: '561',
        timezone: '9',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Aldan Airport',
        city: 'Aldan',
        country: 'Russia',
        iataCode: 'ADH',
        icaoCode: 'UEEA',
        latitude: '58,60279846',
        longitude: '125,4089966',
        altitude: '2241',
        timezone: '9',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Olyokminsk Airport',
        city: 'Olekminsk',
        country: 'Russia',
        iataCode: 'N',
        icaoCode: 'UEMO',
        latitude: '60,39749908',
        longitude: '120,4710007',
        altitude: '656',
        timezone: '9',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Vitim Airport',
        city: 'Vitim',
        country: 'Russia',
        iataCode: 'N',
        icaoCode: 'UERT',
        latitude: '59,45800018',
        longitude: '112,5630035',
        altitude: '610',
        timezone: '9',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Pellworm Field',
        city: 'Pellworm',
        country: 'Germany',
        iataCode: 'N',
        icaoCode: 'EDHP',
        latitude: '54,5363884',
        longitude: '8,680000305',
        altitude: '3',
        timezone: '1',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Xinyuan Nalati Airport',
        city: 'Xinyuan',
        country: 'China',
        iataCode: 'NLT',
        icaoCode: 'ZWNL',
        latitude: '43,4318',
        longitude: '83,3786',
        altitude: '3050',
        timezone: '8',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Fontaine Airport',
        city: 'Belfort',
        country: 'France',
        iataCode: 'BOR',
        icaoCode: 'LFSQ',
        latitude: '47,6556015',
        longitude: '7,010829926',
        altitude: '1208',
        timezone: '1',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Fairfield County Airport',
        city: 'Winnsboro',
        country: 'United States',
        iataCode: 'FDW',
        icaoCode: 'KFDW',
        latitude: '34,31549835',
        longitude: '-81,1088028',
        altitude: '577',
        timezone: '-5',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Obock Airport',
        city: 'Obock',
        country: 'Djibouti',
        iataCode: 'OBC',
        icaoCode: 'HDOB',
        latitude: '11,96700001',
        longitude: '43,26699829',
        altitude: '69',
        timezone: '3',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Tadjoura Airport',
        city: 'Tadjoura',
        country: 'Djibouti',
        iataCode: 'TDJ',
        icaoCode: 'HDTJ',
        latitude: '11,78299999',
        longitude: '42,91699982',
        altitude: '246',
        timezone: '3',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Santa Cruz del Quiche Airport',
        city: 'Santa Cruz des Quiche',
        country: 'Guatemala',
        iataCode: 'AQB',
        icaoCode: 'MGQC',
        latitude: '15,01220036',
        longitude: '-91,15059662',
        altitude: '6631',
        timezone: '-6',
        dst: 'S',
        type: 'airport',
    },
    {
        name: 'Norðfjörður Airport',
        city: 'Nordfjordur',
        country: 'Iceland',
        iataCode: 'NOR',
        icaoCode: 'BINF',
        latitude: '65,13189697',
        longitude: '-13,74639988',
        altitude: '13',
        timezone: '0',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Bursa Airport',
        city: 'Bursa',
        country: 'Turkey',
        iataCode: 'BTZ',
        icaoCode: 'LTBE',
        latitude: '40,23329926',
        longitude: '29,00919914',
        altitude: '331',
        timezone: '3',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Skyhaven Airport',
        city: 'Rochester',
        country: 'United States',
        iataCode: 'DAW',
        icaoCode: 'KDAW',
        latitude: '43,28409958',
        longitude: '-70,9292984',
        altitude: '322',
        timezone: '-5',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Waris Airport',
        city: 'Waris-Papua Island',
        country: 'Indonesia',
        iataCode: 'WAR',
        icaoCode: 'WAJR',
        latitude: '-3,235',
        longitude: '140,994',
        altitude: '1500',
        timezone: '9',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Newton City-County Airport',
        city: 'Newton',
        country: 'United States',
        iataCode: 'EWK',
        icaoCode: 'KEWK',
        latitude: '38,05820084',
        longitude: '-97,27449799',
        altitude: '1533',
        timezone: '-6',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'La Ferté Alais Airfield',
        city: 'La Ferte Alais',
        country: 'France',
        iataCode: 'N',
        icaoCode: 'LFFQ',
        latitude: '48,498652',
        longitude: '2,338867',
        altitude: '453',
        timezone: '1',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Bairnsdale Airport',
        city: 'Bairnsdale',
        country: 'Australia',
        iataCode: 'BSJ',
        icaoCode: 'YBNS',
        latitude: '-37,88750076',
        longitude: '147,5679932',
        altitude: '165',
        timezone: '10',
        dst: 'O',
        type: 'airport',
    },
    {
        name: 'Taszár Air Base',
        city: 'Columbus',
        country: 'United States',
        iataCode: 'TZR',
        icaoCode: 'LHTA',
        latitude: '46,39310074',
        longitude: '17,91749954',
        altitude: '531',
        timezone: '1',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Fort Bridger Airport',
        city: 'Fort Bridger',
        country: 'United States',
        iataCode: 'FBR',
        icaoCode: 'KFBR',
        latitude: '41,39189911',
        longitude: '-110,4069977',
        altitude: '7034',
        timezone: '-7',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Prosser Airport',
        city: 'Prosser',
        country: 'United States',
        iataCode: 'S40',
        icaoCode: 'KS40',
        latitude: '46,21340179',
        longitude: '-119,7910004',
        altitude: '697',
        timezone: '-8',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Chehalis Centralia Airport',
        city: 'Chehalis',
        country: 'United States',
        iataCode: 'CLS',
        icaoCode: 'KCLS',
        latitude: '46,67699814',
        longitude: '-122,9830017',
        altitude: '176',
        timezone: '-8',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Desert Aire Airport',
        city: 'Mattawa',
        country: 'United States',
        iataCode: 'M94',
        icaoCode: 'KM94',
        latitude: '46,68740082',
        longitude: '-119,9209976',
        altitude: '586',
        timezone: '-8',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Evanston-Uinta County Airport-Burns Field',
        city: 'Evanston',
        country: 'United States',
        iataCode: 'EVW',
        icaoCode: 'KEVW',
        latitude: '41,27479935',
        longitude: '-111,0350037',
        altitude: '7143',
        timezone: '-7',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Sabetha Municipal Airport',
        city: 'Sabetha',
        country: 'United States',
        iataCode: 'K83',
        icaoCode: 'KK83',
        latitude: '39,90420151',
        longitude: '-95,77940369',
        altitude: '1330',
        timezone: '-6',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Mt Pleasant Regional-Faison field',
        city: 'Mount Pleasant',
        country: 'United States',
        iataCode: 'LRO',
        icaoCode: 'KLRO',
        latitude: '32,89780045',
        longitude: '-79,78289795',
        altitude: '12',
        timezone: '-5',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Souther Field',
        city: 'Americus',
        country: 'United States',
        iataCode: 'N',
        icaoCode: 'KACJ',
        latitude: '32,1108017',
        longitude: '-84,18890381',
        altitude: '468',
        timezone: '-5',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Weedon Field',
        city: 'Eufala',
        country: 'United States',
        iataCode: 'EUF',
        icaoCode: 'KEUF',
        latitude: '31,95129967',
        longitude: '-85,12889862',
        altitude: '285',
        timezone: '-6',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Saluda County Airport',
        city: 'Saluda',
        country: 'United States',
        iataCode: '6J4',
        icaoCode: 'K6J4',
        latitude: '33,92679977',
        longitude: '-81,79460144',
        altitude: '555',
        timezone: '-5',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Dare County Regional Airport',
        city: 'Manteo',
        country: 'United States',
        iataCode: 'MEO',
        icaoCode: 'KMQI',
        latitude: '35,91899872',
        longitude: '-75,69550323',
        altitude: '13',
        timezone: '-5',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Auburn Opelika Robert G. Pitts Airport',
        city: 'Auburn',
        country: 'United States',
        iataCode: 'AUO',
        icaoCode: 'KAUO',
        latitude: '32,61510086',
        longitude: '-85,43399811',
        altitude: '777',
        timezone: '-6',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Tri Cities Airport',
        city: 'Endicott',
        country: 'United States',
        iataCode: 'CZG',
        icaoCode: 'KCZG',
        latitude: '42,078499',
        longitude: '-76,096296',
        altitude: '833',
        timezone: '-5',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Bessemer Airport',
        city: 'Bessemer',
        country: 'United States',
        iataCode: 'EKY',
        icaoCode: 'KEKY',
        latitude: '33,31290054',
        longitude: '-86,92590332',
        altitude: '700',
        timezone: '-6',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Colorado Springs East Airport',
        city: 'Ellicott',
        country: 'United States',
        iataCode: 'A50',
        icaoCode: 'KA50',
        latitude: '38,87440109',
        longitude: '-104,4100037',
        altitude: '6145',
        timezone: '-7',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Crystal Airport',
        city: 'Crystal',
        country: 'United States',
        iataCode: 'MIC',
        icaoCode: 'KMIC',
        latitude: '45,06200027',
        longitude: '-93,35389709',
        altitude: '869',
        timezone: '-6',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Clarke County Airport',
        city: 'Quitman',
        country: 'United States',
        iataCode: '23M',
        icaoCode: 'K23M',
        latitude: '32,0848999',
        longitude: '-88,73889923',
        altitude: '320',
        timezone: '-6',
        dst: 'A',
        type: 'airport',
    },
    {
        name: "W H 'Bud' Barron Airport",
        city: 'Dublin',
        country: 'United States',
        iataCode: 'DBN',
        icaoCode: 'KDBN',
        latitude: '32,56439972',
        longitude: '-82,98529816',
        altitude: '309',
        timezone: '-5',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Pukarua Airport',
        city: 'Pukarua',
        country: 'French Polynesia',
        iataCode: 'PUK',
        icaoCode: 'NTGQ',
        latitude: '-18,29560089',
        longitude: '-137,0169983',
        altitude: '5',
        timezone: '-10',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Kabale Airport',
        city: 'Kabale',
        country: 'Uganda',
        iataCode: 'N',
        icaoCode: 'HUKB',
        latitude: '-1,226111',
        longitude: '29,96',
        altitude: '6000',
        timezone: '3',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Guatuso Airport',
        city: 'Marigot',
        country: 'France',
        iataCode: 'N',
        icaoCode: 'MRGT',
        latitude: '10,6833334',
        longitude: '-84,83333588',
        altitude: '164',
        timezone: '-6',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Central Bolívar Airport',
        city: 'Sevilla',
        country: 'Spain',
        iataCode: 'N',
        icaoCode: 'SVSJ',
        latitude: '8,916666985',
        longitude: '-71,93333435',
        altitude: '39',
        timezone: '-4',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Corvallis Municipal Airport',
        city: 'Corvallis',
        country: 'United States',
        iataCode: 'CVO',
        icaoCode: 'KCVO',
        latitude: '44,49720001',
        longitude: '-123,2900009',
        altitude: '250',
        timezone: '-8',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Tuzla Romania Airport',
        city: 'Tuzla',
        country: 'Romania',
        iataCode: 'N',
        icaoCode: 'LRTZ',
        latitude: '43,98419952',
        longitude: '28,60969925',
        altitude: '161',
        timezone: '2',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'El Almendro Airport',
        city: 'Scranton',
        country: 'United States',
        iataCode: 'N',
        icaoCode: 'SCRT',
        latitude: '-35,96055603',
        longitude: '-71,79333496',
        altitude: '486',
        timezone: '-4',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'San Agustin Airport',
        city: 'Morristown',
        country: 'United States',
        iataCode: 'N',
        icaoCode: 'MRST',
        latitude: '10,0666666',
        longitude: '-84,8833313',
        altitude: '66',
        timezone: '-6',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Samambaia Heliport',
        city: 'Saint Denis',
        country: 'Reunion',
        iataCode: 'N',
        icaoCode: 'SDNS',
        latitude: '-22,46249962',
        longitude: '-43,1305542',
        altitude: '3167',
        timezone: '-3',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Fazenda Campo Verde Airport',
        city: 'Sihanoukville',
        country: 'Cambodia',
        iataCode: 'N',
        icaoCode: 'SNKV',
        latitude: '1,042780042',
        longitude: '-50,51670074',
        altitude: '49',
        timezone: '-3',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Prominent Hill Airport',
        city: 'Prominent Hill',
        country: 'Australia',
        iataCode: 'PXH',
        icaoCode: 'YPMH',
        latitude: '-29,716',
        longitude: '135,5244',
        altitude: '745',
        timezone: '9,5',
        dst: 'O',
        type: 'airport',
    },
    {
        name: 'Cowra Airport',
        city: 'Chatsworth',
        country: 'United States',
        iataCode: 'CWT',
        icaoCode: 'YCWR',
        latitude: '-33,84469986',
        longitude: '148,6490021',
        altitude: '966',
        timezone: '10',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Coral Bay Airport',
        city: 'Coral Bay',
        country: 'Australia',
        iataCode: 'N',
        icaoCode: 'YCOY',
        latitude: '-23,12999916',
        longitude: '113,7770004',
        altitude: '5',
        timezone: '8',
        dst: 'O',
        type: 'airport',
    },
    {
        name: 'Ogden Hinckley Airport',
        city: 'Ogden',
        country: 'United States',
        iataCode: 'OGD',
        icaoCode: 'KOGD',
        latitude: '41,19589996',
        longitude: '-112,012001',
        altitude: '4473',
        timezone: '-7',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Lake Country Regional Airport',
        city: 'Clarksville',
        country: 'United Arab Emirates',
        iataCode: 'W63',
        icaoCode: 'KW63',
        latitude: '36,59579849',
        longitude: '-78,56009674',
        altitude: '421',
        timezone: '-5',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Robert S Kerr Airport',
        city: 'Poteau',
        country: 'United States',
        iataCode: 'RKR',
        icaoCode: 'KRKR',
        latitude: '35,02159882',
        longitude: '-94,62129974',
        altitude: '451',
        timezone: '-6',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Colorado Plains Regional Airport',
        city: 'Akron',
        country: 'United States',
        iataCode: 'AKO',
        icaoCode: 'KAKO',
        latitude: '40,17559814',
        longitude: '-103,2220001',
        altitude: '4714',
        timezone: '-7',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Sanderson Field',
        city: 'Shelton',
        country: 'United States',
        iataCode: 'SHN',
        icaoCode: 'KSHN',
        latitude: '47,23360062',
        longitude: '-123,1480026',
        altitude: '273',
        timezone: '-8',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Napakiak Airport',
        city: 'Napakiak',
        country: 'United States',
        iataCode: 'WNA',
        icaoCode: 'PANA',
        latitude: '60,69029999',
        longitude: '-161,9790039',
        altitude: '17',
        timezone: '-9',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Napaskiak Airport',
        city: 'Napaskiak',
        country: 'United States',
        iataCode: 'PKA',
        icaoCode: 'PAPK',
        latitude: '60,70289993',
        longitude: '-161,7779999',
        altitude: '24',
        timezone: '-9',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Tok Airport',
        city: 'Tok',
        country: 'United States',
        iataCode: 'N',
        icaoCode: 'PATJ',
        latitude: '63,303333',
        longitude: '-143,001111',
        altitude: '1670',
        timezone: '-9',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Bedwell Harbour Seaplane Base',
        city: 'Bedwell Harbour',
        country: 'Canada',
        iataCode: 'YBW',
        icaoCode: 'CAB3',
        latitude: '48,75',
        longitude: '-123,2330017',
        altitude: '0',
        timezone: '-8',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Popham Airport',
        city: 'Popham',
        country: 'United Kingdom',
        iataCode: 'N',
        icaoCode: 'EGHP',
        latitude: '51,19390106',
        longitude: '-1,234719992',
        altitude: '550',
        timezone: '0',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Causey Airport',
        city: 'Liberty',
        country: 'United States',
        iataCode: '2A5',
        icaoCode: 'K2A5',
        latitude: '35,91180038',
        longitude: '-79,61759949',
        altitude: '723',
        timezone: '-5',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Abaco I Walker C Airport',
        city: "Walker's Cay",
        country: 'Bahamas',
        iataCode: 'WKR',
        icaoCode: 'MYAW',
        latitude: '27,26670074',
        longitude: '-78,39969635',
        altitude: '10',
        timezone: '-5',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Black Point Airstrip',
        city: 'Black Point',
        country: 'Bahamas',
        iataCode: 'N',
        icaoCode: 'MYEB',
        latitude: '24,08948845',
        longitude: '-76,39791727',
        altitude: '10',
        timezone: '-5',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Bartica A Airport',
        city: 'Bartica',
        country: 'Guyana',
        iataCode: 'GFO',
        icaoCode: 'SYBT',
        latitude: '6,374770164',
        longitude: '-58,63809967',
        altitude: '3',
        timezone: '-4',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Doylestown Airport',
        city: 'Doylestown',
        country: 'United States',
        iataCode: 'DYL',
        icaoCode: 'KDYL',
        latitude: '40,33300018',
        longitude: '-75,12229919',
        altitude: '394',
        timezone: '-5',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Tingo Maria Airport',
        city: 'Tingo Maria',
        country: 'Peru',
        iataCode: 'TGI',
        icaoCode: 'SPGM',
        latitude: '-9,133000374',
        longitude: '-75,94999695',
        altitude: '2010',
        timezone: '-5',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Plínio Alarcom Airport',
        city: 'Tres Lagoas',
        country: 'Brazil',
        iataCode: 'TJL',
        icaoCode: 'SSTL',
        latitude: '-20,75419998',
        longitude: '-51,68420029',
        altitude: '1050',
        timezone: '-4',
        dst: 'S',
        type: 'airport',
    },
    {
        name: 'Cacoal Airport',
        city: 'Cacoal',
        country: 'Brazil',
        iataCode: 'OAL',
        icaoCode: 'SSKW',
        latitude: '-11,496',
        longitude: '-61,4508',
        altitude: '778',
        timezone: '-4',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Warren Field',
        city: 'Washington',
        country: 'United States',
        iataCode: 'OCW',
        icaoCode: 'KOCW',
        latitude: '35,57049942',
        longitude: '-77,04979706',
        altitude: '38',
        timezone: '-5',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Hyde County Airport',
        city: 'Engelhard',
        country: 'United States',
        iataCode: '7W6',
        icaoCode: 'K7W6',
        latitude: '35,56240082',
        longitude: '-75,9552002',
        altitude: '8',
        timezone: '-5',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Mocopulli Airport',
        city: 'Castro',
        country: 'Chile',
        iataCode: 'MHC',
        icaoCode: 'SCPQ',
        latitude: '-42,340388',
        longitude: '-73,715693',
        altitude: '528',
        timezone: '-4',
        dst: 'S',
        type: 'airport',
    },
    {
        name: 'Stillwater Regional Airport',
        city: 'Stillwater',
        country: 'United States',
        iataCode: 'N',
        icaoCode: 'KSWO',
        latitude: '36,16120148',
        longitude: '-97,08570099',
        altitude: '1000',
        timezone: '-6',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Okmulgee Regional Airport',
        city: 'Okmulgee',
        country: 'United States',
        iataCode: 'N',
        icaoCode: 'KOKM',
        latitude: '35,66809845',
        longitude: '-95,94869995',
        altitude: '720',
        timezone: '-6',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Cushing Municipal Airport',
        city: 'Cushing',
        country: 'United States',
        iataCode: 'N',
        icaoCode: 'KCUH',
        latitude: '35,94990158',
        longitude: '-96,77310181',
        altitude: '916',
        timezone: '-6',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Clinton Sherman Airport',
        city: 'Clinton',
        country: 'United States',
        iataCode: 'N',
        icaoCode: 'KCSM',
        latitude: '35,33980179',
        longitude: '-99,20050049',
        altitude: '1922',
        timezone: '-6',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Strother Field',
        city: 'Winfield',
        country: 'United States',
        iataCode: 'N',
        icaoCode: 'KWLD',
        latitude: '37,16859818',
        longitude: '-97,03759766',
        altitude: '1160',
        timezone: '-6',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Wiley Post Airport',
        city: 'Oklahoma City',
        country: 'United States',
        iataCode: 'N',
        icaoCode: 'KPWA',
        latitude: '35,53419876',
        longitude: '-97,64710236',
        altitude: '1300',
        timezone: '-6',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Shreveport Downtown Airport',
        city: 'Shreveport',
        country: 'United States',
        iataCode: 'N',
        icaoCode: 'KDTN',
        latitude: '32,54019928',
        longitude: '-93,74500275',
        altitude: '179',
        timezone: '-6',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Stephenville Clark Regional Airport',
        city: 'Stephenville',
        country: 'United States',
        iataCode: 'N',
        icaoCode: 'KSEP',
        latitude: '32,21530151',
        longitude: '-98,17769623',
        altitude: '1321',
        timezone: '-6',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Perry Municipal Airport',
        city: 'Perry',
        country: 'United States',
        iataCode: 'N',
        icaoCode: 'KF22',
        latitude: '36,38560104',
        longitude: '-97,27719879',
        altitude: '1002',
        timezone: '-6',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Hamilton Municipal Airport',
        city: 'Hamilton',
        country: 'United States',
        iataCode: 'N',
        icaoCode: 'KMNZ',
        latitude: '31,66589928',
        longitude: '-98,14859772',
        altitude: '1299',
        timezone: '-6',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Ada Municipal Airport',
        city: 'Ada',
        country: 'United States',
        iataCode: 'N',
        icaoCode: 'KADH',
        latitude: '34,8042984',
        longitude: '-96,6713028',
        altitude: '1016',
        timezone: '-6',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Mesquite Metro Airport',
        city: 'Misquite',
        country: 'United States',
        iataCode: 'N',
        icaoCode: 'KHQZ',
        latitude: '32,74700165',
        longitude: '-96,53040314',
        altitude: '447',
        timezone: '-6',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Denton Municipal Airport',
        city: 'Denton',
        country: 'United States',
        iataCode: 'N',
        icaoCode: 'KDTO',
        latitude: '33,20069885',
        longitude: '-97,19799805',
        altitude: '642',
        timezone: '-6',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Austin Executive Airport',
        city: 'Austin',
        country: 'United States',
        iataCode: 'N',
        icaoCode: 'KEDC',
        latitude: '30,3974931',
        longitude: '-97,5663935',
        altitude: '620',
        timezone: '-6',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Lago Vista Tx Rusty Allen Airport',
        city: 'Lago Vista',
        country: 'United States',
        iataCode: 'N',
        icaoCode: 'KRYW',
        latitude: '30,49860001',
        longitude: '-97,96949768',
        altitude: '1231',
        timezone: '-6',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Brenham Municipal Airport',
        city: 'Brenham',
        country: 'United States',
        iataCode: 'N',
        icaoCode: 'K11R',
        latitude: '30,21899986',
        longitude: '-96,3742981',
        altitude: '307',
        timezone: '-6',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Lakeway Airpark',
        city: 'Lakeway',
        country: 'United States',
        iataCode: 'N',
        icaoCode: 'K3R9',
        latitude: '30,35750008',
        longitude: '-97,99449921',
        altitude: '909',
        timezone: '-6',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Iraan Municipal Airport',
        city: 'Iraan',
        country: 'United States',
        iataCode: 'N',
        icaoCode: 'K2F0',
        latitude: '30,90570068',
        longitude: '-101,8919983',
        altitude: '2200',
        timezone: '-6',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Kestrel Airpark',
        city: 'San Antonio',
        country: 'United States',
        iataCode: 'N',
        icaoCode: 'K1T7',
        latitude: '29,81270027',
        longitude: '-98,4253006',
        altitude: '1250',
        timezone: '-6',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Elliot Lake Municipal Airport',
        city: 'ELLIOT LAKE',
        country: 'Canada',
        iataCode: 'YEL',
        icaoCode: 'CYEL',
        latitude: '46,35139847',
        longitude: '-82,56140137',
        altitude: '1087',
        timezone: '-5',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Wilkes County Airport',
        city: 'North Wilkesboro',
        country: 'United States',
        iataCode: 'UKF',
        icaoCode: 'KUKF',
        latitude: '36,22280121',
        longitude: '-81,09829712',
        altitude: '1301',
        timezone: '-5',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Charleston Executive Airport',
        city: 'Charleston',
        country: 'United States',
        iataCode: 'JZI',
        icaoCode: 'KJZI',
        latitude: '32,70090103',
        longitude: '-80,00289917',
        altitude: '17',
        timezone: '-5',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Danville Regional Airport',
        city: 'Danville',
        country: 'United States',
        iataCode: 'DAN',
        icaoCode: 'KDAN',
        latitude: '36,57289886',
        longitude: '-79,33609772',
        altitude: '571',
        timezone: '-5',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Brookneal/Campbell County Airport',
        city: 'Brookneal',
        country: 'United States',
        iataCode: '0V4',
        icaoCode: 'K0V4',
        latitude: '37,14170074',
        longitude: '-79,0164032',
        altitude: '596',
        timezone: '-5',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Yerbogachen Airport',
        city: 'Yerbogachen',
        country: 'Russia',
        iataCode: 'ERG',
        icaoCode: 'UIKE',
        latitude: '61,27500153',
        longitude: '108,0299988',
        altitude: '400',
        timezone: '8',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Cheraw Municipal Airport/Lynch Bellinger Field',
        city: 'Cheraw',
        country: 'United States',
        iataCode: 'CQW',
        icaoCode: 'KCQW',
        latitude: '34,71289825',
        longitude: '-79,95700073',
        altitude: '239',
        timezone: '-5',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Wauchula Municipal Airport',
        city: 'Wuchula',
        country: 'United States',
        iataCode: 'N',
        icaoCode: 'KCHN',
        latitude: '27,51490021',
        longitude: '-81,88050079',
        altitude: '106',
        timezone: '-5',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Lilydale Airport',
        city: 'Lilydale',
        country: 'Australia',
        iataCode: 'N',
        icaoCode: 'YLIL',
        latitude: '-37,69169998',
        longitude: '145,3670044',
        altitude: '76',
        timezone: '10',
        dst: 'O',
        type: 'airport',
    },
    {
        name: 'Beni Mellal Airport',
        city: 'Beni Mellal',
        country: 'Morocco',
        iataCode: 'BEM',
        icaoCode: 'GMMD',
        latitude: '32,40000153',
        longitude: '-6,333330154',
        altitude: '1670',
        timezone: '0',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Şırnak Şerafettin Elçi Airport',
        city: 'Cizre',
        country: 'Turkey',
        iataCode: 'NKT',
        icaoCode: 'LTCV',
        latitude: '37,3647',
        longitude: '42,0582',
        altitude: '2038',
        timezone: '3',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Suntar Airport',
        city: 'Suntar',
        country: 'Russia',
        iataCode: 'SUY',
        icaoCode: 'UENS',
        latitude: '62,18500137',
        longitude: '117,6350021',
        altitude: '452',
        timezone: '9',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Tazadit Airport',
        city: 'Zouerat',
        country: 'Mauritania',
        iataCode: 'OUZ',
        icaoCode: 'GQPZ',
        latitude: '22,75639915',
        longitude: '-12,48359966',
        altitude: '1129',
        timezone: '0',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Asaba International Airport',
        city: 'Asaba',
        country: 'Nigeria',
        iataCode: 'N',
        icaoCode: 'DNAS',
        latitude: '6,203333333',
        longitude: '6,658888889',
        altitude: '305',
        timezone: '1',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Akwa Ibom International Airport',
        city: 'Uyo',
        country: 'Nigeria',
        iataCode: 'QUO',
        icaoCode: 'DNAI',
        latitude: '4,8725',
        longitude: '8,093',
        altitude: '170',
        timezone: '1',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Kasama Airport',
        city: 'Kasama',
        country: 'Zambia',
        iataCode: 'KAA',
        icaoCode: 'FLKS',
        latitude: '-10,2166996',
        longitude: '31,13330078',
        altitude: '4541',
        timezone: '2',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Mbeya Airport',
        city: 'Mbeya',
        country: 'Tanzania',
        iataCode: 'MBI',
        icaoCode: 'HTMB',
        latitude: '-8,916999817',
        longitude: '33,46699905',
        altitude: '5600',
        timezone: '3',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Mpanda Airport',
        city: 'Mpanda',
        country: 'Tanzania',
        iataCode: 'N',
        icaoCode: 'HTMP',
        latitude: '-6,355374',
        longitude: '31,084116',
        altitude: '3520',
        timezone: '3',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Songea Airport',
        city: 'Songea',
        country: 'Tanzania',
        iataCode: 'SGX',
        icaoCode: 'HTSO',
        latitude: '-10,68299961',
        longitude: '35,58300018',
        altitude: '3445',
        timezone: '3',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Morogoro Airport',
        city: 'Morogoro',
        country: 'Tanzania',
        iataCode: 'N',
        icaoCode: 'HTMG',
        latitude: '-6,79722023',
        longitude: '37,65309906',
        altitude: '1676',
        timezone: '3',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Anshan Air Base',
        city: 'Anshan',
        country: 'China',
        iataCode: 'AOG',
        icaoCode: 'ZYAS',
        latitude: '41,105301',
        longitude: '122,853996',
        altitude: '0',
        timezone: '8',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Zunyi Xinzhou Airport',
        city: 'Zunyi',
        country: 'China',
        iataCode: 'ZYI',
        icaoCode: 'ZUZY',
        latitude: '27,5895',
        longitude: '107,0007',
        altitude: '2920',
        timezone: '8',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Conway Horry County Airport',
        city: 'Conway',
        country: 'United States',
        iataCode: 'HYW',
        icaoCode: 'KHYW',
        latitude: '33,82849884',
        longitude: '-79,12220001',
        altitude: '35',
        timezone: '-5',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Lindu Airport',
        city: 'Yinchun',
        country: 'China',
        iataCode: 'LDS',
        icaoCode: 'ZYLD',
        latitude: '47,75205556',
        longitude: '129,019125',
        altitude: '791',
        timezone: '8',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Anshun Huangguoshu Airport',
        city: 'Anshun',
        country: 'China',
        iataCode: 'AVA',
        icaoCode: 'ZUAS',
        latitude: '26,26055556',
        longitude: '105,8733333',
        altitude: '4812',
        timezone: '8',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Sikasso Airport',
        city: 'Sikasso',
        country: 'Mali',
        iataCode: 'KSS',
        icaoCode: 'GASK',
        latitude: '11,33300018',
        longitude: '-5,699999809',
        altitude: '1378',
        timezone: '0',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Brisbane West Wellcamp Airport',
        city: 'Toowoomba',
        country: 'Australia',
        iataCode: 'WTB',
        icaoCode: 'YBWW',
        latitude: '-27,558333',
        longitude: '151,793333',
        altitude: '1509',
        timezone: '10',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Tonghua Sanyuanpu Airport',
        city: 'Tonghua',
        country: 'China',
        iataCode: 'TNH',
        icaoCode: 'ZYTN',
        latitude: '42,25388889',
        longitude: '125,7033333',
        altitude: '1200',
        timezone: '8',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Suzhou Guangfu Airport',
        city: 'Suzhou',
        country: 'China',
        iataCode: 'SZV',
        icaoCode: 'ZSSZ',
        latitude: '31,2631',
        longitude: '120,401001',
        altitude: '0',
        timezone: '8',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'City Airport Manchester',
        city: 'Manchester',
        country: 'United Kingdom',
        iataCode: 'N',
        icaoCode: 'EGCB',
        latitude: '53,47169876',
        longitude: '-2,389719963',
        altitude: '73',
        timezone: '0',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Sleap Airport',
        city: 'Shrewsbury',
        country: 'United Kingdom',
        iataCode: 'N',
        icaoCode: 'EGCV',
        latitude: '52,83390045',
        longitude: '-2,771670103',
        altitude: '275',
        timezone: '0',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Tatenhill Airfield',
        city: 'Burton-on-Trent',
        country: 'United Kingdom',
        iataCode: 'N',
        icaoCode: 'EGBM',
        latitude: '52,81470108',
        longitude: '-1,761109948',
        altitude: '439',
        timezone: '0',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Full Sutton Airfield',
        city: 'York',
        country: 'United Kingdom',
        iataCode: 'N',
        icaoCode: 'EGNU',
        latitude: '53,98059845',
        longitude: '-0,864722013',
        altitude: '86',
        timezone: '0',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Sherburn-In-Elmet Airfield',
        city: 'Leeds',
        country: 'United Kingdom',
        iataCode: 'N',
        icaoCode: 'EGCJ',
        latitude: '53,788458',
        longitude: '-1,216877',
        altitude: '26',
        timezone: '0',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Beringin Airport',
        city: 'Muara Teweh',
        country: 'Indonesia',
        iataCode: 'N',
        icaoCode: 'WAOM',
        latitude: '-0,940325022',
        longitude: '114,8938751',
        altitude: '126',
        timezone: '7',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Mulia Airport',
        city: 'Mulia',
        country: 'Indonesia',
        iataCode: 'LII',
        icaoCode: 'WAJM',
        latitude: '-3,7018',
        longitude: '137,957',
        altitude: '6527',
        timezone: '9',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Stenkol Airport',
        city: 'Bintuni',
        country: 'Indonesia',
        iataCode: 'NTI',
        icaoCode: 'WASB',
        latitude: '-2,1033',
        longitude: '133,5164',
        altitude: '57',
        timezone: '9',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Wasior Airport',
        city: 'Wasior',
        country: 'Indonesia',
        iataCode: 'WSR',
        icaoCode: 'WASW',
        latitude: '-2,721',
        longitude: '134,5061',
        altitude: '49',
        timezone: '9',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Silangit Airport',
        city: 'Siborong-Borong',
        country: 'Indonesia',
        iataCode: 'DTB',
        icaoCode: 'WIMN',
        latitude: '2,25973',
        longitude: '98,991898',
        altitude: '4700',
        timezone: '7',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Lasikin Airport',
        city: 'Sinabang',
        country: 'Indonesia',
        iataCode: 'SSV',
        icaoCode: 'WITG',
        latitude: '2,410279989',
        longitude: '96,32559967',
        altitude: '19',
        timezone: '7',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Seunagan Airport',
        city: 'Nagan Raya',
        country: 'Indonesia',
        iataCode: 'MEQ',
        icaoCode: 'WITC',
        latitude: '4,25',
        longitude: '96,21700287',
        altitude: '10',
        timezone: '7',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Betoambari Airport',
        city: 'Bau-Bau',
        country: 'Indonesia',
        iataCode: 'BUW',
        icaoCode: 'WAWB',
        latitude: '-5,486879826',
        longitude: '122,5690002',
        altitude: '164',
        timezone: '8',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Kao Airport',
        city: 'Kao',
        country: 'Indonesia',
        iataCode: 'KAZ',
        icaoCode: 'WAMK',
        latitude: '1,185279965',
        longitude: '127,8960037',
        altitude: '27',
        timezone: '9',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Melangguane Airport',
        city: 'Melonguane',
        country: 'Indonesia',
        iataCode: 'MNA',
        icaoCode: 'WAMN',
        latitude: '4,006939888',
        longitude: '126,6729965',
        altitude: '3',
        timezone: '8',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Sanggata/Sangkimah Airport',
        city: 'Sanggata',
        country: 'Indonesia',
        iataCode: 'SGQ',
        icaoCode: 'WRLA',
        latitude: '0,3847',
        longitude: '117,543',
        altitude: '60',
        timezone: '8',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Muara Bungo Airport',
        city: 'Muara Bungo',
        country: 'Indonesia',
        iataCode: 'N',
        icaoCode: 'WIPI',
        latitude: '-1,1278',
        longitude: '102,135',
        altitude: '214',
        timezone: '7',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Selayar/Aroepala Airport',
        city: 'Selayar',
        country: 'Indonesia',
        iataCode: 'N',
        icaoCode: 'WAWH',
        latitude: '-6,1751',
        longitude: '120,4362',
        altitude: '30',
        timezone: '8',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Illaga Airport',
        city: 'Illaga',
        country: 'Indonesia',
        iataCode: 'N',
        icaoCode: 'WABL',
        latitude: '-3,97648',
        longitude: '137,6225',
        altitude: '7989',
        timezone: '9',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Oksibil Airport',
        city: 'Oksibil',
        country: 'Indonesia',
        iataCode: 'OKL',
        icaoCode: 'WAJO',
        latitude: '-4,9071',
        longitude: '140,6277',
        altitude: '4315',
        timezone: '9',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Kokonau Airport',
        city: 'Kokonau',
        country: 'Indonesia',
        iataCode: 'KOX',
        icaoCode: 'WABN',
        latitude: '-4,71075',
        longitude: '136,43515',
        altitude: '59',
        timezone: '9',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Clermont Airport',
        city: 'Clermont',
        country: 'Australia',
        iataCode: 'CMQ',
        icaoCode: 'YCMT',
        latitude: '-22,7730999',
        longitude: '147,6210022',
        altitude: '908',
        timezone: '10',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Warrnambool Airport',
        city: 'Warrnambool',
        country: 'Australia',
        iataCode: 'WMB',
        icaoCode: 'YWBL',
        latitude: '-38,29529953',
        longitude: '142,4470062',
        altitude: '242',
        timezone: '10',
        dst: 'O',
        type: 'airport',
    },
    {
        name: 'Richmond Airport',
        city: 'Richmond',
        country: 'Australia',
        iataCode: 'RCM',
        icaoCode: 'YRMD',
        latitude: '-20,70190048',
        longitude: '143,1150055',
        altitude: '676',
        timezone: '10',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'RAAF Base Curtin',
        city: 'Derby',
        country: 'Australia',
        iataCode: 'DCN',
        icaoCode: 'YCIN',
        latitude: '-17,58139992',
        longitude: '123,8280029',
        altitude: '300',
        timezone: '8',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Kualanamu International Airport',
        city: 'Medan',
        country: 'Indonesia',
        iataCode: 'KNO',
        icaoCode: 'WIMM',
        latitude: '3,642222',
        longitude: '98,885278',
        altitude: '23',
        timezone: '7',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Gratiot Community Airport',
        city: 'Kamloops',
        country: 'Canada',
        iataCode: 'N',
        icaoCode: 'KAMN',
        latitude: '43,32210159',
        longitude: '-84,68800354',
        altitude: '754',
        timezone: '-7',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Asheboro Regional Airport',
        city: 'Asheboro',
        country: 'United States',
        iataCode: 'HBI',
        icaoCode: 'KHBI',
        latitude: '35,65449905',
        longitude: '-79,8946991',
        altitude: '671',
        timezone: '-5',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Seosan Air Base',
        city: 'Seosan',
        country: 'South Korea',
        iataCode: 'N',
        icaoCode: 'RKTP',
        latitude: '36,703999',
        longitude: '126,486',
        altitude: '39',
        timezone: '9',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Henderson Field',
        city: 'Wallace',
        country: 'United States',
        iataCode: 'N',
        icaoCode: 'KACZ',
        latitude: '34,71789932',
        longitude: '-78,00360107',
        altitude: '39',
        timezone: '-5',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Emporia Greensville Regional Airport',
        city: 'Emporia',
        country: 'United States',
        iataCode: 'EMV',
        icaoCode: 'KEMV',
        latitude: '36,68690109',
        longitude: '-77,48280334',
        altitude: '127',
        timezone: '-5',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'El Monte Airport',
        city: 'El Monte',
        country: 'United States',
        iataCode: 'EMT',
        icaoCode: 'KEMT',
        latitude: '34,08610153',
        longitude: '-118,0350037',
        altitude: '296',
        timezone: '-8',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Farah Airport',
        city: 'Farah',
        country: 'Afghanistan',
        iataCode: 'FAH',
        icaoCode: 'OAFR',
        latitude: '32,36700058',
        longitude: '62,18299866',
        altitude: '3083',
        timezone: '4,3',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Pasighat Airport',
        city: 'Pasighat',
        country: 'India',
        iataCode: 'IXT',
        icaoCode: 'VEPG',
        latitude: '28,06609917',
        longitude: '95,33560181',
        altitude: '477',
        timezone: '5,3',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Kee Field',
        city: 'Pineville',
        country: 'United States',
        iataCode: 'I16',
        icaoCode: 'KI16',
        latitude: '37,60039902',
        longitude: '-81,55930328',
        altitude: '1783',
        timezone: '-5',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Kramatorsk Airport',
        city: 'Kramatorsk',
        country: 'Ukraine',
        iataCode: 'KRQ',
        icaoCode: 'UKCK',
        latitude: '48,70560074',
        longitude: '37,62889862',
        altitude: '646',
        timezone: '2',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Kautokeino Air Base',
        city: 'Kautokeino',
        country: 'Norway',
        iataCode: 'N',
        icaoCode: 'ENKA',
        latitude: '69,04029846',
        longitude: '23,0340004',
        altitude: '1165',
        timezone: '1',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Lille/Marcq-en-Baroeul Airport',
        city: 'Marcq En Baroeul',
        country: 'France',
        iataCode: 'N',
        icaoCode: 'LFQO',
        latitude: '50,68719864',
        longitude: '3,075560093',
        altitude: '69',
        timezone: '1',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Kawass Airport',
        city: 'Kamsar',
        country: 'Guinea',
        iataCode: 'N',
        icaoCode: 'GUKR',
        latitude: '10,65100002',
        longitude: '-14,53359985',
        altitude: '36',
        timezone: '1',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Stinson Municipal Airport',
        city: 'Stinson',
        country: 'United States',
        iataCode: 'SSF',
        icaoCode: 'KSSF',
        latitude: '29,33699989',
        longitude: '-98,47109985',
        altitude: '577',
        timezone: '-5',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Sallisaw Municipal Airport',
        city: 'Sallisaw',
        country: 'United States',
        iataCode: 'JSV',
        icaoCode: 'KJSV',
        latitude: '35,4382019',
        longitude: '-94,80280304',
        altitude: '527',
        timezone: '-5',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Jasper County Airport-Bell Field',
        city: 'Jasper',
        country: 'United States',
        iataCode: 'JAS',
        icaoCode: 'KJAS',
        latitude: '30,88570023',
        longitude: '-94,03489685',
        altitude: '213',
        timezone: '-5',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'El Dorado Springs Memorial Airport',
        city: 'El dorado springs',
        country: 'United States',
        iataCode: '87K',
        icaoCode: 'K87K',
        latitude: '37,8567009',
        longitude: '-93,99909973',
        altitude: '931',
        timezone: '-5',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Marfa Municipal Airport',
        city: 'Marfa',
        country: 'United States',
        iataCode: 'MRF',
        icaoCode: 'KMRF',
        latitude: '30,371099',
        longitude: '-104,017997',
        altitude: '4849',
        timezone: '-5',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Alpine Casparis Municipal Airport',
        city: 'Alpine',
        country: 'United States',
        iataCode: 'E38',
        icaoCode: 'KE38',
        latitude: '30,38419914',
        longitude: '-103,6839981',
        altitude: '4515',
        timezone: '-5',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Bubaque Airport',
        city: 'Bubaque',
        country: 'Guinea-Bissau',
        iataCode: 'BQE',
        icaoCode: 'GGBU',
        latitude: '11,29735565',
        longitude: '-15,83807945',
        altitude: '0',
        timezone: '0',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Chichen Itza International Airport',
        city: 'Chichen Itza',
        country: 'Mexico',
        iataCode: 'CZA',
        icaoCode: 'MMCT',
        latitude: '20,6413002',
        longitude: '-88,44619751',
        altitude: '102',
        timezone: '-6',
        dst: 'S',
        type: 'airport',
    },
    {
        name: 'Bunbury Airport',
        city: 'Bunbury',
        country: 'Australia',
        iataCode: 'BUY',
        icaoCode: 'YBUN',
        latitude: '-33,37829971',
        longitude: '115,677002',
        altitude: '53',
        timezone: '8',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Cable Airport',
        city: 'Upland',
        country: 'United States',
        iataCode: 'CCB',
        icaoCode: 'KCCB',
        latitude: '34,11159897',
        longitude: '-117,6880035',
        altitude: '1444',
        timezone: '-8',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Mount Sterling Montgomery County Airport',
        city: 'Mount Sterling',
        country: 'United States',
        iataCode: 'IOB',
        icaoCode: 'KIOB',
        latitude: '38,05810165',
        longitude: '-83,979599',
        altitude: '1019',
        timezone: '-5',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Elkhart Municipal Airport',
        city: 'Elkhart',
        country: 'United States',
        iataCode: 'EKI',
        icaoCode: 'KEKM',
        latitude: '41,7193985',
        longitude: '-86,00319672',
        altitude: '778',
        timezone: '-5',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Nappanee Municipal Airport',
        city: 'Nappanee ',
        country: 'United States',
        iataCode: 'C03',
        icaoCode: 'KC03',
        latitude: '41,44620132',
        longitude: '-85,93479919',
        altitude: '860',
        timezone: '-5',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Jim Hamilton L.B. Owens Airport',
        city: 'Columbia',
        country: 'United States',
        iataCode: 'CUB',
        icaoCode: 'KCUB',
        latitude: '33,97050095',
        longitude: '-80,99520111',
        altitude: '193',
        timezone: '-4',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Grove Municipal Airport',
        city: 'Grove',
        country: 'United States',
        iataCode: 'GMJ',
        icaoCode: 'KGMJ',
        latitude: '36,60680008',
        longitude: '-94,73860168',
        altitude: '831',
        timezone: '-5',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Mc Pherson Airport',
        city: 'Mc Pherson',
        country: 'United States',
        iataCode: 'MPR',
        icaoCode: 'KMPR',
        latitude: '38,35240173',
        longitude: '-97,69129944',
        altitude: '1498',
        timezone: '-5',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Donaldson Center Airport',
        city: 'Greenville',
        country: 'United States',
        iataCode: 'GYH',
        icaoCode: 'KGYH',
        latitude: '34,75830078',
        longitude: '-82,37640381',
        altitude: '955',
        timezone: '-4',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Perry Houston County Airport',
        city: 'Perry',
        country: 'United States',
        iataCode: 'PXE',
        icaoCode: 'KPXE',
        latitude: '32,51060104',
        longitude: '-83,76730347',
        altitude: '418',
        timezone: '-4',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Hartsville Regional Airport',
        city: 'Hartsville',
        country: 'United States',
        iataCode: 'HVS',
        icaoCode: 'KHVS',
        latitude: '34,40309906',
        longitude: '-80,11920166',
        altitude: '364',
        timezone: '-5',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Horace Williams Airport',
        city: 'Chapel Hill',
        country: 'United States',
        iataCode: 'IGX',
        icaoCode: 'KIGX',
        latitude: '35,935001',
        longitude: '-79,065902',
        altitude: '512',
        timezone: '-5',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'San Cristobal de las Casas Airport',
        city: 'San Cristobal de las Casas',
        country: 'Mexico',
        iataCode: 'SZT',
        icaoCode: 'MMSC',
        latitude: '16,69029999',
        longitude: '-92,53009796',
        altitude: '7707',
        timezone: '-6',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Dunnville Airport',
        city: 'Dunnville',
        country: 'Canada',
        iataCode: 'DU9',
        icaoCode: 'CDU9',
        latitude: '42,87220001',
        longitude: '-79,59580231',
        altitude: '600',
        timezone: '-5',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Brunswick County Airport',
        city: 'Oak Island',
        country: 'United States',
        iataCode: 'SUT',
        icaoCode: 'KSUT',
        latitude: '33,9292984',
        longitude: '-78,07499695',
        altitude: '24',
        timezone: '-5',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Chesterfield County Airport',
        city: 'Richmond',
        country: 'United States',
        iataCode: 'FCI',
        icaoCode: 'KFCI',
        latitude: '37,40650177',
        longitude: '-77,52500153',
        altitude: '236',
        timezone: '-4',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Atherton Airport',
        city: 'Atherton',
        country: 'Australia',
        iataCode: 'N',
        icaoCode: 'YATN',
        latitude: '-17,26169968',
        longitude: '145,5149994',
        altitude: '2450',
        timezone: '10',
        dst: 'O',
        type: 'airport',
    },
    {
        name: 'Bagdarin Airport',
        city: 'Bagdarin',
        country: 'Russia',
        iataCode: 'N',
        icaoCode: 'UIUB',
        latitude: '54,36920166',
        longitude: '113,4789963',
        altitude: '3084',
        timezone: '8',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Scarlett Martinez International Airport',
        city: 'Rio Hato',
        country: 'Panama',
        iataCode: 'N',
        icaoCode: 'MPRH',
        latitude: '8,375880241',
        longitude: '-80,12789917',
        altitude: '105',
        timezone: '-5',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Henderson Oxford Airport',
        city: 'Oxford',
        country: 'United States',
        iataCode: 'HNZ',
        icaoCode: 'KHNZ',
        latitude: '36,36159897',
        longitude: '-78,52919769',
        altitude: '526',
        timezone: '-5',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Leesburg International Airport',
        city: 'Leesburg',
        country: 'United States',
        iataCode: 'LEE',
        icaoCode: 'KLEE',
        latitude: '28,82309914',
        longitude: '-81,80870056',
        altitude: '76',
        timezone: '-5',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Semyazino Airport',
        city: 'Vladimir',
        country: 'Russia',
        iataCode: 'N',
        icaoCode: 'UUBL',
        latitude: '56,12670135',
        longitude: '40,31499863',
        altitude: '554',
        timezone: '3',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Witberg Tswalu Airport',
        city: 'Dedeben',
        country: 'South Africa',
        iataCode: 'N',
        icaoCode: 'FATW',
        latitude: '-27,20499992',
        longitude: '22,48189926',
        altitude: '3921',
        timezone: '2',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Mana Pools Airport',
        city: 'Mana Pools',
        country: 'Zimbabwe',
        iataCode: 'N',
        icaoCode: 'FVMN',
        latitude: '-15,7670002',
        longitude: '29,38299942',
        altitude: '1300',
        timezone: '2',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Pouso Alegre Airport',
        city: 'Pouso Alegre',
        country: 'Brazil',
        iataCode: 'PPY',
        icaoCode: 'SNZA',
        latitude: '-22,28919983',
        longitude: '-45,91910172',
        altitude: '2904',
        timezone: '-3',
        dst: 'S',
        type: 'airport',
    },
    {
        name: 'Brigadeiro Cabral Airport',
        city: 'Divinopolis',
        country: 'Brazil',
        iataCode: 'DIQ',
        icaoCode: 'SNDV',
        latitude: '-20,1807003',
        longitude: '-44,8708992',
        altitude: '2608',
        timezone: '-3',
        dst: 'S',
        type: 'airport',
    },
    {
        name: 'Yeysk Airport',
        city: 'Eysk',
        country: 'Russia',
        iataCode: 'N',
        icaoCode: 'URKE',
        latitude: '46,68',
        longitude: '38,21',
        altitude: '60',
        timezone: '3',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Berdyansk Airport',
        city: 'Berdyansk',
        country: 'Ukraine',
        iataCode: 'ERD',
        icaoCode: 'UKDB',
        latitude: '46,81499863',
        longitude: '36,7580986',
        altitude: '171',
        timezone: '2',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Erenhot Saiwusu International Airport',
        city: 'Erenhot',
        country: 'China',
        iataCode: 'ERL',
        icaoCode: 'ZBER',
        latitude: '43,4225',
        longitude: '112,096667',
        altitude: '3301',
        timezone: '8',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Shobdon Aerodrome',
        city: 'Shobdon',
        country: 'United Kingdom',
        iataCode: 'N',
        icaoCode: 'EGBS',
        latitude: '52,24169922',
        longitude: '-2,881109953',
        altitude: '318',
        timezone: '0',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Chino Airport',
        city: 'Chino',
        country: 'United States',
        iataCode: 'CNO',
        icaoCode: 'KCNO',
        latitude: '33,97470093',
        longitude: '-117,637001',
        altitude: '650',
        timezone: '-8',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Kamigoto Airport',
        city: 'Shin-kamigoto',
        country: 'Japan',
        iataCode: 'N',
        icaoCode: 'RJDK',
        latitude: '33,01309967',
        longitude: '129,1920013',
        altitude: '263',
        timezone: '9',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Ojika Airport',
        city: 'Odika',
        country: 'Japan',
        iataCode: 'N',
        icaoCode: 'RJDO',
        latitude: '33,19079971',
        longitude: '129,0899963',
        altitude: '30',
        timezone: '9',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Chofu Airport',
        city: 'Tokyo',
        country: 'Japan',
        iataCode: 'N',
        icaoCode: 'RJTF',
        latitude: '35,67169952',
        longitude: '139,5279999',
        altitude: '141',
        timezone: '9',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Hateruma Airport',
        city: 'Taketomi',
        country: 'Japan',
        iataCode: 'HTR',
        icaoCode: 'RORH',
        latitude: '24,05890083',
        longitude: '123,8059998',
        altitude: '43',
        timezone: '9',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Madison County Airport',
        city: 'London',
        country: 'United States',
        iataCode: 'UYF',
        icaoCode: 'KUYF',
        latitude: '39,93270111',
        longitude: '-83,46199799',
        altitude: '1082',
        timezone: '-5',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Las Brujas Airport',
        city: 'Cayo Santa Maria',
        country: 'Cuba',
        iataCode: 'BWW',
        icaoCode: 'MUBR',
        latitude: '22,62129974',
        longitude: '-79,14720154',
        altitude: '13',
        timezone: '-5',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Digby (General Hospital) Heliport',
        city: 'Tremblay-en-France',
        country: 'France',
        iataCode: 'N',
        icaoCode: 'CDG2',
        latitude: '44,61610031',
        longitude: '-65,76190186',
        altitude: '105',
        timezone: '1',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Thun Airport',
        city: 'Thun',
        country: 'Switzerland',
        iataCode: 'N',
        icaoCode: 'LSZW',
        latitude: '46,75640106',
        longitude: '7,600560188',
        altitude: '1837',
        timezone: '1',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Lavrentiya Airport',
        city: 'Lavrentiya',
        country: 'Russia',
        iataCode: 'N',
        icaoCode: 'UHML',
        latitude: '65,58000183',
        longitude: '-170,9966736',
        altitude: '30',
        timezone: '12',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Paso Robles Municipal Airport',
        city: 'Paso Robles',
        country: 'United States',
        iataCode: 'PRB',
        icaoCode: 'KPRB',
        latitude: '35,67290115',
        longitude: '-120,6269989',
        altitude: '840',
        timezone: '-8',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'N 104 Helipad',
        city: 'Ulleung',
        country: 'South Korea',
        iataCode: 'N',
        icaoCode: 'RKDU',
        latitude: '37,47916667',
        longitude: '130,8955556',
        altitude: '476',
        timezone: '9',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Kymi Airport',
        city: 'Kotka',
        country: 'Finland',
        iataCode: 'N',
        icaoCode: 'EFKY',
        latitude: '60,57139969',
        longitude: '26,89609909',
        altitude: '223',
        timezone: '2',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Half Moon Bay Airport',
        city: 'Half Moon Bay',
        country: 'United States',
        iataCode: 'HAF',
        icaoCode: 'KHAF',
        latitude: '37,51340103',
        longitude: '-122,5009995',
        altitude: '66',
        timezone: '-8',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Hechi Jinchengjiang Airport',
        city: 'Hechi',
        country: 'China',
        iataCode: 'HCJ',
        icaoCode: 'ZGHC',
        latitude: '24,805',
        longitude: '107,6997',
        altitude: '2221',
        timezone: '8',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Kadina Airport',
        city: 'Kadina',
        country: 'Australia',
        iataCode: 'N',
        icaoCode: 'YKDI',
        latitude: '-33,97669983',
        longitude: '137,6600037',
        altitude: '42',
        timezone: '9,5',
        dst: 'O',
        type: 'airport',
    },
    {
        name: 'General WM J Fox Airfield',
        city: 'Lancaster',
        country: 'United States',
        iataCode: 'WJF',
        icaoCode: 'KWJF',
        latitude: '34,74110031',
        longitude: '-118,2190018',
        altitude: '2351',
        timezone: '-8',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Coondewanna Airport',
        city: 'Coondewanna',
        country: 'Australia',
        iataCode: 'CJF',
        icaoCode: 'YCWA',
        latitude: '-22,9666996',
        longitude: '118,8130035',
        altitude: '2300',
        timezone: '8',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Guarapari Airport',
        city: 'Guarapari',
        country: 'Brazil',
        iataCode: 'GUZ',
        icaoCode: 'SNGA',
        latitude: '-20,64649963',
        longitude: '-40,4919014',
        altitude: '28',
        timezone: '-3',
        dst: 'S',
        type: 'airport',
    },
    {
        name: 'Ubatuba Airport',
        city: 'Ubatuba',
        country: 'Brazil',
        iataCode: 'UBT',
        icaoCode: 'SDUB',
        latitude: '-23,44109917',
        longitude: '-45,07559967',
        altitude: '13',
        timezone: '-3',
        dst: 'S',
        type: 'airport',
    },
    {
        name: 'Ali Air Base',
        city: 'Nasiriyah',
        country: 'Iraq',
        iataCode: 'N',
        icaoCode: 'ORTL',
        latitude: '30,93580055',
        longitude: '46,09009933',
        altitude: '20',
        timezone: '3',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Ota Air Base',
        city: 'Ota',
        country: 'Portugal',
        iataCode: 'N',
        icaoCode: 'LPOT',
        latitude: '39,087502',
        longitude: '-8,96278',
        altitude: '140',
        timezone: '1',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Borroloola Airport',
        city: 'Borroloola',
        country: 'Australia',
        iataCode: 'BOX',
        icaoCode: 'YBRL',
        latitude: '-16,07530022',
        longitude: '136,302002',
        altitude: '55',
        timezone: '9,5',
        dst: 'O',
        type: 'airport',
    },
    {
        name: 'Elsenthal Grafe Airport',
        city: 'Elsenthal',
        country: 'Germany',
        iataCode: 'N',
        icaoCode: 'EDNF',
        latitude: '48,82249832',
        longitude: '13,36750031',
        altitude: '1417',
        timezone: '1',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Newtownards Airport',
        city: 'Newtownards',
        country: 'United Kingdom',
        iataCode: 'N',
        icaoCode: 'EGAD',
        latitude: '54,58110046',
        longitude: '-5,691939831',
        altitude: '9',
        timezone: '0',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Lashenden (Headcorn) Airfield',
        city: 'Headcorn',
        country: 'United Kingdom',
        iataCode: 'N',
        icaoCode: 'EGKH',
        latitude: '51,156898',
        longitude: '0,641667',
        altitude: '72',
        timezone: '0',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Panshanger Aerodrome',
        city: 'Panshanger',
        country: 'United Kingdom',
        iataCode: 'N',
        icaoCode: 'EGLG',
        latitude: '51,80250168',
        longitude: '-0,158056006',
        altitude: '249',
        timezone: '0',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Thurrock Airfield',
        city: 'Thurrock',
        country: 'United Kingdom',
        iataCode: 'N',
        icaoCode: 'EGMT',
        latitude: '51,537505',
        longitude: '0,367634',
        altitude: '20',
        timezone: '0',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Stapleford Aerodrome',
        city: 'Stapleford',
        country: 'United Kingdom',
        iataCode: 'N',
        icaoCode: 'EGSG',
        latitude: '51,65250015',
        longitude: '0,155833006',
        altitude: '183',
        timezone: '0',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'HAL Airport',
        city: 'Bangalore',
        country: 'India',
        iataCode: 'N',
        icaoCode: 'VOBG',
        latitude: '12,94999981',
        longitude: '77,66819763',
        altitude: '2912',
        timezone: '5,5',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Chichester/Goodwood Airport',
        city: 'Goodwood',
        country: 'United Kingdom',
        iataCode: 'N',
        icaoCode: 'EGHR',
        latitude: '50,8594017',
        longitude: '-0,759167016',
        altitude: '98',
        timezone: '0',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Omarama Glider Airport',
        city: 'Omarama',
        country: 'New Zealand',
        iataCode: 'N',
        icaoCode: 'NZOA',
        latitude: '-44,48669815',
        longitude: '169,9859924',
        altitude: '1381',
        timezone: '12',
        dst: 'Z',
        type: 'airport',
    },
    {
        name: 'Haast Aerodrome',
        city: 'Haast',
        country: 'New Zealand',
        iataCode: 'N',
        icaoCode: 'NZHT',
        latitude: '-43,86529922',
        longitude: '169,0410004',
        altitude: '13',
        timezone: '12',
        dst: 'Z',
        type: 'airport',
    },
    {
        name: 'Meschede-Schüren Airport',
        city: 'Meschede',
        country: 'Germany',
        iataCode: 'N',
        icaoCode: 'EDKM',
        latitude: '51,30277634',
        longitude: '8,239167213',
        altitude: '1434',
        timezone: '1',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Morro da Urca Heliport',
        city: 'Rio de Janeiro',
        country: 'Brazil',
        iataCode: 'N',
        icaoCode: 'SDHU',
        latitude: '-22,95166779',
        longitude: '-43,16583252',
        altitude: '692',
        timezone: '-3',
        dst: 'S',
        type: 'airport',
    },
    {
        name: 'Dongji Aiport',
        city: 'Fuyuan',
        country: 'China',
        iataCode: 'FYJ',
        icaoCode: 'ZYFY',
        latitude: '48,199494',
        longitude: '134,366447',
        altitude: '0',
        timezone: '8',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Skúvoy Heliport',
        city: 'Skuvoy',
        country: 'Faroe Islands',
        iataCode: 'N',
        icaoCode: 'EKSY',
        latitude: '61,76959991',
        longitude: '-6,803410053',
        altitude: '70',
        timezone: '1',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Stóra Dímun Heliport',
        city: 'Stora Dimun',
        country: 'Faroe Islands',
        iataCode: 'N',
        icaoCode: 'EKSR',
        latitude: '61,68529892',
        longitude: '-6,758500099',
        altitude: '305',
        timezone: '1',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Frooba Heliport',
        city: 'Frooba',
        country: 'Faroe Islands',
        iataCode: 'N',
        icaoCode: 'EKFA',
        latitude: '61,54370117',
        longitude: '-6,774419785',
        altitude: '77',
        timezone: '1',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Person County Airport',
        city: 'Roxboro',
        country: 'United States',
        iataCode: 'TDF',
        icaoCode: 'KTDF',
        latitude: '36,28490067',
        longitude: '-78,98419952',
        altitude: '609',
        timezone: '-5',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Zulu Inyala Airport',
        city: 'Phinda',
        country: 'South Africa',
        iataCode: 'PZL',
        icaoCode: 'FADQ',
        latitude: '-27,84939957',
        longitude: '32,30970001',
        altitude: '160',
        timezone: '2',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Kaili Airport',
        city: 'Kaili',
        country: 'China',
        iataCode: 'KJH',
        icaoCode: 'ZUKJ',
        latitude: '26,972',
        longitude: '107,988',
        altitude: '3115',
        timezone: '8',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Shennongjia Hongping Airport',
        city: 'Shennongjia',
        country: 'China',
        iataCode: 'HPG',
        icaoCode: 'ZHSN',
        latitude: '31,626',
        longitude: '110,34',
        altitude: '8365',
        timezone: '8',
        dst: 'N',
        type: 'airport',
    },
    {
        name: "Arxan Yi'ershi Airport",
        city: 'Arxan',
        country: 'China',
        iataCode: 'YIE',
        icaoCode: 'ZBES',
        latitude: '47,3106',
        longitude: '119,9117',
        altitude: '2925',
        timezone: '8',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Hengyang Nanyue Airport',
        city: 'Hengyang',
        country: 'China',
        iataCode: 'HNY',
        icaoCode: 'ZGHY',
        latitude: '26,9053',
        longitude: '112,627998',
        altitude: '0',
        timezone: '8',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Wonsan Kalma International Airport',
        city: 'Wonsan',
        country: 'North Korea',
        iataCode: 'WOS',
        icaoCode: 'ZKWS',
        latitude: '39,166801',
        longitude: '127,486',
        altitude: '7',
        timezone: '8,5',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Mestia Queen Tamar Airport',
        city: 'Mestia',
        country: 'Georgia',
        iataCode: 'N',
        icaoCode: 'UGMS',
        latitude: '43,053597',
        longitude: '42,749012',
        altitude: '4778',
        timezone: '5',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Magas Airport',
        city: 'Magas',
        country: 'Russia',
        iataCode: 'IGT',
        icaoCode: 'URMS',
        latitude: '43,32229996',
        longitude: '45,01259995',
        altitude: '1165',
        timezone: '3',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Talladega Municipal Airport',
        city: 'Talladega',
        country: 'United States',
        iataCode: 'ASN',
        icaoCode: 'KASN',
        latitude: '33,56990051',
        longitude: '-86,05090332',
        altitude: '529',
        timezone: '-5',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Greenville Downtown Airport',
        city: 'Greenville',
        country: 'United States',
        iataCode: 'GMU',
        icaoCode: 'KGMU',
        latitude: '34,84790039',
        longitude: '-82,34999847',
        altitude: '1048',
        timezone: '-4',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Mankato Regional Airport',
        city: 'Mankato',
        country: 'United States',
        iataCode: 'MKT',
        icaoCode: 'KMKT',
        latitude: '44,22159958',
        longitude: '-93,91870117',
        altitude: '1021',
        timezone: '-6',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Captain Auguste George Airport',
        city: 'Anegada',
        country: 'British Virgin Islands',
        iataCode: 'NGD',
        icaoCode: 'TUPA',
        latitude: '18,72719955',
        longitude: '-64,32969666',
        altitude: '9',
        timezone: '-4',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Troy Municipal Airport',
        city: 'Troy',
        country: 'United States',
        iataCode: 'TOI',
        icaoCode: 'KTOI',
        latitude: '31,86039925',
        longitude: '-86,01210022',
        altitude: '398',
        timezone: '-5',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Merkel Field Sylacauga Municipal Airport',
        city: 'Sylacauga',
        country: 'United States',
        iataCode: 'SCD',
        icaoCode: 'KSCD',
        latitude: '33,17179871',
        longitude: '-86,30549622',
        altitude: '569',
        timezone: '-5',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Enterprise Municipal Airport',
        city: 'Enterprise',
        country: 'United States',
        iataCode: 'EDN',
        icaoCode: 'KEDN',
        latitude: '31,29969978',
        longitude: '-85,89990234',
        altitude: '361',
        timezone: '-5',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Ryan Field',
        city: 'Tucson',
        country: 'United States',
        iataCode: 'N',
        icaoCode: 'KRYN',
        latitude: '32,14220047',
        longitude: '-111,1750031',
        altitude: '2417',
        timezone: '-7',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Thomas C Russell Field',
        city: 'Alexander City',
        country: 'United States',
        iataCode: 'ALX',
        icaoCode: 'KALX',
        latitude: '32,91469955',
        longitude: '-85,96299744',
        altitude: '686',
        timezone: '-5',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Port Keats Airport',
        city: 'Wadeye',
        country: 'Australia',
        iataCode: 'PKT',
        icaoCode: 'YPKT',
        latitude: '-14,25',
        longitude: '129,529007',
        altitude: '91',
        timezone: '9,5',
        dst: 'O',
        type: 'airport',
    },
    {
        name: 'Garden Point Airport',
        city: 'Pirlangimpi',
        country: 'Australia',
        iataCode: 'GPN',
        icaoCode: 'YGPT',
        latitude: '-11,40250015',
        longitude: '130,4219971',
        altitude: '90',
        timezone: '9,5',
        dst: 'O',
        type: 'airport',
    },
    {
        name: 'Stade Airport',
        city: 'Stade',
        country: 'Germany',
        iataCode: 'N',
        icaoCode: 'EDHS',
        latitude: '53,56111145',
        longitude: '9,499167442',
        altitude: '62',
        timezone: '1',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Meriden Markham Municipal Airport',
        city: 'Meriden',
        country: 'United States',
        iataCode: 'N',
        icaoCode: 'KMMK',
        latitude: '41,50870132',
        longitude: '-72,82949829',
        altitude: '103',
        timezone: '-5',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Flinders Island Airport',
        city: 'Elliston',
        country: 'Australia',
        iataCode: 'N',
        icaoCode: 'YFLS',
        latitude: '-33,730989',
        longitude: '134,501',
        altitude: '0',
        timezone: '9,5',
        dst: 'O',
        type: 'airport',
    },
    {
        name: 'Luzern-Beromunster Airport',
        city: 'Lucerne',
        country: 'Switzerland',
        iataCode: 'N',
        icaoCode: 'LSZO',
        latitude: '47,18999863',
        longitude: '8,204719543',
        altitude: '2146',
        timezone: '1',
        dst: 'E',
        type: 'airport',
    },
    {
        name: "La Grand'combe Airport",
        city: "La Grand'Combe",
        country: 'France',
        iataCode: 'N',
        icaoCode: 'LFTN',
        latitude: '44,24440002',
        longitude: '4,012219906',
        altitude: '1647',
        timezone: '1',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Fort Mackay / Horizon Airport',
        city: 'Wood Buffalo',
        country: 'Canada',
        iataCode: 'HZP',
        icaoCode: 'CYNR',
        latitude: '57,38169861',
        longitude: '-111,7009964',
        altitude: '916',
        timezone: '-7',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Canmore Municipal Heliport',
        city: 'Canmore',
        country: 'Canada',
        iataCode: 'N',
        icaoCode: 'CEW9',
        latitude: '51,07780075',
        longitude: '-115,3379974',
        altitude: '4296',
        timezone: '-7',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Krasnokamensk Airport',
        city: 'Krasnokamensk',
        country: 'Russia',
        iataCode: 'N',
        icaoCode: 'UIAE',
        latitude: '50,03310776',
        longitude: '118,061142',
        altitude: '2139',
        timezone: '9',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Ridgely Airpark',
        city: 'Ridgely',
        country: 'United States',
        iataCode: 'N',
        icaoCode: 'KRJD',
        latitude: '38,9701004',
        longitude: '-75,86630249',
        altitude: '64',
        timezone: '-5',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'New Castle Henry Co. Municipal Airport',
        city: 'New Castle',
        country: 'United States',
        iataCode: 'UWL',
        icaoCode: 'KUWL',
        latitude: '39,87590027',
        longitude: '-85,32649994',
        altitude: '1088',
        timezone: '-4',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Scott City Municipal Airport',
        city: 'Scott City',
        country: 'United States',
        iataCode: 'TQK',
        icaoCode: 'KTQK',
        latitude: '38,47430038',
        longitude: '-100,8850021',
        altitude: '2963',
        timezone: '-5',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Brewster Field',
        city: 'Holdredge',
        country: 'United States',
        iataCode: 'HDE',
        icaoCode: 'KHDE',
        latitude: '40,452099',
        longitude: '-99,336502',
        altitude: '2313',
        timezone: '-5',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Pratt Regional Airport',
        city: 'Pratt',
        country: 'United States',
        iataCode: 'PTT',
        icaoCode: 'KPTT',
        latitude: '37,70159912',
        longitude: '-98,74690247',
        altitude: '1953',
        timezone: '-5',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Yubileyniy Airfield',
        city: 'Baikonur',
        country: 'Kazakhstan',
        iataCode: 'N',
        icaoCode: 'UAON',
        latitude: '46,05500031',
        longitude: '63,25',
        altitude: '328',
        timezone: '6',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Wahoo Municipal Airport',
        city: 'Wahoo',
        country: 'United States',
        iataCode: 'AHQ',
        icaoCode: 'KAHQ',
        latitude: '41,24129868',
        longitude: '-96,59400177',
        altitude: '1224',
        timezone: '-5',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Jim Kelly Field',
        city: 'Lexington',
        country: 'United States',
        iataCode: 'LXN',
        icaoCode: 'KLXN',
        latitude: '40,79100037',
        longitude: '-99,77729797',
        altitude: '2413',
        timezone: '-5',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Sublette Municipal Airport',
        city: 'Sublette',
        country: 'United States',
        iataCode: '19S',
        icaoCode: 'K19S',
        latitude: '37,49140167',
        longitude: '-100,8300018',
        altitude: '2908',
        timezone: '-5',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Council Bluffs Municipal Airport',
        city: 'Council Bluffs',
        country: 'United States',
        iataCode: 'CBF',
        icaoCode: 'KCBF',
        latitude: '41,25920105',
        longitude: '-95,76059723',
        altitude: '1253',
        timezone: '-5',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Kokomo Municipal Airport',
        city: 'Kokomo',
        country: 'United States',
        iataCode: 'OKK',
        icaoCode: 'KOKK',
        latitude: '40,52819824',
        longitude: '-86,05899811',
        altitude: '830',
        timezone: '-4',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Neodesha Municipal Airport',
        city: 'Neodesha',
        country: 'United States',
        iataCode: '2K7',
        icaoCode: 'K2K7',
        latitude: '37,4353981',
        longitude: '-95,64610291',
        altitude: '841',
        timezone: '-5',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'King Ranch Airport',
        city: 'Sutton',
        country: 'United States',
        iataCode: 'N',
        icaoCode: 'AK59',
        latitude: '61,79560089',
        longitude: '-148,3549957',
        altitude: '1350',
        timezone: '-8',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Elkhart Morton County Airport',
        city: 'Elkhart',
        country: 'United States',
        iataCode: 'EHA',
        icaoCode: 'KEHA',
        latitude: '37,000702',
        longitude: '-101,879997',
        altitude: '3622',
        timezone: '-5',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Front Range Airport',
        city: 'Denver',
        country: 'United States',
        iataCode: 'FTG',
        icaoCode: 'KFTG',
        latitude: '39,78530121',
        longitude: '-104,5429993',
        altitude: '5512',
        timezone: '-6',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Galesburg Municipal Airport',
        city: 'Galesburg',
        country: 'United States',
        iataCode: 'GBG',
        icaoCode: 'KGBG',
        latitude: '40,93799973',
        longitude: '-90,43109894',
        altitude: '764',
        timezone: '-5',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Guymon Municipal Airport',
        city: 'Guymon',
        country: 'United States',
        iataCode: 'GUY',
        icaoCode: 'KGUY',
        latitude: '36,68510056',
        longitude: '-101,5080032',
        altitude: '3123',
        timezone: '-5',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Meade Municipal Airport',
        city: 'Meade',
        country: 'United States',
        iataCode: 'MEJ',
        icaoCode: 'KMEJ',
        latitude: '37,27690125',
        longitude: '-100,3560028',
        altitude: '2529',
        timezone: '-5',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Turkey Mountain Estates Airport',
        city: 'Shell Knob',
        country: 'United States',
        iataCode: 'N',
        icaoCode: 'MO00',
        latitude: '36,59170151',
        longitude: '-93,66690063',
        altitude: '1000',
        timezone: '-5',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Ulysses Airport',
        city: 'Ulysses',
        country: 'United States',
        iataCode: 'ULS',
        icaoCode: 'KULS',
        latitude: '37,60400009',
        longitude: '-101,3740005',
        altitude: '3071',
        timezone: '-5',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Flagler Aerial Spraying Inc Airport',
        city: 'Flagler',
        country: 'United States',
        iataCode: 'N',
        icaoCode: 'CO00',
        latitude: '39,27999878',
        longitude: '-103,0670013',
        altitude: '4945',
        timezone: '-6',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Independence Municipal Airport',
        city: 'Independence',
        country: 'United States',
        iataCode: 'IDP',
        icaoCode: 'KIDP',
        latitude: '37,15840149',
        longitude: '-95,77839661',
        altitude: '825',
        timezone: '-5',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Augusta Municipal Airport',
        city: 'Augusta',
        country: 'United States',
        iataCode: '3AU',
        icaoCode: 'K3AU',
        latitude: '37,67160034',
        longitude: '-97,07790375',
        altitude: '1328',
        timezone: '-5',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Larned Pawnee County Airport',
        city: 'Larned',
        country: 'United States',
        iataCode: 'LQR',
        icaoCode: 'KLQR',
        latitude: '38,20859909',
        longitude: '-99,08599854',
        altitude: '2012',
        timezone: '-5',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Lampasas Airport',
        city: 'Lampasas',
        country: 'United States',
        iataCode: 'LZZ',
        icaoCode: 'KLZZ',
        latitude: '31,10619926',
        longitude: '-98,19589996',
        altitude: '1215',
        timezone: '-5',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Bay City Municipal Airport',
        city: 'Bay City',
        country: 'United States',
        iataCode: 'BYY',
        icaoCode: 'KBYY',
        latitude: '28,97330093',
        longitude: '-95,8635025',
        altitude: '45',
        timezone: '-5',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Cox Field',
        city: 'Paris',
        country: 'United States',
        iataCode: 'PRX',
        icaoCode: 'KPRX',
        latitude: '33,63660049',
        longitude: '-95,45079803',
        altitude: '547',
        timezone: '-5',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Coffeyville Municipal Airport',
        city: 'Coffeyville',
        country: 'United States',
        iataCode: 'CFV',
        icaoCode: 'KCFV',
        latitude: '37,09400177',
        longitude: '-95,57189941',
        altitude: '754',
        timezone: '-5',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'The Farm Airport',
        city: 'Summit',
        country: 'United States',
        iataCode: 'N',
        icaoCode: '24SC',
        latitude: '33,93579865',
        longitude: '-81,42970276',
        altitude: '580',
        timezone: '-4',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Greeley–Weld County Airport',
        city: 'Greeley',
        country: 'United States',
        iataCode: 'GXY',
        icaoCode: 'KGXY',
        latitude: '40,43740082',
        longitude: '-104,6330032',
        altitude: '4697',
        timezone: '-7',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'General Dewitt Spain Airport',
        city: 'Memphis',
        country: 'United States',
        iataCode: 'N',
        icaoCode: 'KM01',
        latitude: '35,20069885',
        longitude: '-90,05400085',
        altitude: '225',
        timezone: '-4',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Oryol Yuzhny Airport',
        city: 'Oakley',
        country: 'United States',
        iataCode: 'OEL',
        icaoCode: 'UUOR',
        latitude: '52,93470001',
        longitude: '36,00220108',
        altitude: '656',
        timezone: '-5',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Fremont Municipal Airport',
        city: 'Fremont',
        country: 'United States',
        iataCode: 'FET',
        icaoCode: 'KFET',
        latitude: '41,44910049',
        longitude: '-96,52020264',
        altitude: '1204',
        timezone: '-5',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'La Grande/Union County Airport',
        city: 'La Grande',
        country: 'United States',
        iataCode: 'LGD',
        icaoCode: 'KLGD',
        latitude: '45,29019928',
        longitude: '-118,0070038',
        altitude: '2717',
        timezone: '-7',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Szczytno-Szymany International Airport',
        city: 'Szczytno-Szymany',
        country: 'Poland',
        iataCode: 'SZY',
        icaoCode: 'EPSY',
        latitude: '53,48189926',
        longitude: '20,93770027',
        altitude: '463',
        timezone: '1',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Pocono Mountains Municipal Airport',
        city: 'Mount Pocono',
        country: 'United States',
        iataCode: 'MPO',
        icaoCode: 'KMPO',
        latitude: '41,13750076',
        longitude: '-75,37889862',
        altitude: '1915',
        timezone: '-5',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Quakertown Airport',
        city: 'Quakertown',
        country: 'United States',
        iataCode: 'UKT',
        icaoCode: 'KUKT',
        latitude: '40,43519974',
        longitude: '-75,38189697',
        altitude: '526',
        timezone: '-5',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Banff Airport',
        city: 'Banff',
        country: 'Canada',
        iataCode: 'YBA',
        icaoCode: 'CYBA',
        latitude: '51,20734047',
        longitude: '-115,5418611',
        altitude: '4583',
        timezone: '-7',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Banning Municipal Airport',
        city: 'Banning',
        country: 'United States',
        iataCode: 'BNG',
        icaoCode: 'KBNG',
        latitude: '33,92309952',
        longitude: '-116,8509979',
        altitude: '2219',
        timezone: '-8',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Gießen-Lützellinden Airport',
        city: 'Giessen',
        country: 'Germany',
        iataCode: 'N',
        icaoCode: 'EDFL',
        latitude: '50,54388809',
        longitude: '8,590277672',
        altitude: '755',
        timezone: '1',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Tököl Airport',
        city: 'Budapest',
        country: 'Hungary',
        iataCode: 'N',
        icaoCode: 'LHTL',
        latitude: '47,34529877',
        longitude: '18,98080063',
        altitude: '318',
        timezone: '1',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Karl Stefan Memorial Airport',
        city: 'Norfolk  Nebraska',
        country: 'United States',
        iataCode: 'OFK',
        icaoCode: 'KOFK',
        latitude: '41,98550034',
        longitude: '-97,43509674',
        altitude: '1573',
        timezone: '-6',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Kotido Airport',
        city: 'Kotido',
        country: 'Uganda',
        iataCode: 'N',
        icaoCode: 'HUKO',
        latitude: '2,953',
        longitude: '34,1232',
        altitude: '4020',
        timezone: '3',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Kajjansi Airfield',
        city: 'Kampala',
        country: 'Uganda',
        iataCode: 'N',
        icaoCode: 'HUKJ',
        latitude: '0,1968',
        longitude: '32,55297',
        altitude: '3743',
        timezone: '3',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Arlington Municipal Airport',
        city: 'Arlington',
        country: 'United States',
        iataCode: 'AWO',
        icaoCode: 'KAWO',
        latitude: '48,16070175',
        longitude: '-122,1589966',
        altitude: '142',
        timezone: '-8',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Paracatu Airport',
        city: 'Paracatu',
        country: 'Brazil',
        iataCode: 'N',
        icaoCode: 'SNZR',
        latitude: '-17,24259949',
        longitude: '-46,8830986',
        altitude: '2359',
        timezone: '-3',
        dst: 'S',
        type: 'airport',
    },
    {
        name: 'Conselheiro Lafaiete Airport',
        city: 'Conselheiro Lafaiete',
        country: 'Brazil',
        iataCode: 'N',
        icaoCode: 'SNKF',
        latitude: '-20,73859978',
        longitude: '-43,79740143',
        altitude: '3478',
        timezone: '-3',
        dst: 'S',
        type: 'airport',
    },
    {
        name: 'Janaúba Airport',
        city: 'Janauba',
        country: 'Brazil',
        iataCode: 'N',
        icaoCode: 'SNAP',
        latitude: '-15,73200035',
        longitude: '-43,32310104',
        altitude: '1732',
        timezone: '-3',
        dst: 'S',
        type: 'airport',
    },
    {
        name: 'Juscelino Kubitscheck Airport',
        city: 'Teofilo Otoni',
        country: 'Brazil',
        iataCode: 'TFL',
        icaoCode: 'SNTO',
        latitude: '-17,89229965',
        longitude: '-41,5135994',
        altitude: '1572',
        timezone: '-3',
        dst: 'S',
        type: 'airport',
    },
    {
        name: 'Cristiano Ferreira Varella Airport',
        city: 'Muriae',
        country: 'Brazil',
        iataCode: 'N',
        icaoCode: 'SNBM',
        latitude: '-21,12610054',
        longitude: '-42,39440155',
        altitude: '886',
        timezone: '-3',
        dst: 'S',
        type: 'airport',
    },
    {
        name: 'Peter O Knight Airport',
        city: 'Tampa',
        country: 'United States',
        iataCode: 'TPF',
        icaoCode: 'KTPF',
        latitude: '27,91559982',
        longitude: '-82,44930267',
        altitude: '8',
        timezone: '-5',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Lure Malbouhans Air Base',
        city: 'Malbouhans',
        country: 'France',
        iataCode: 'N',
        icaoCode: 'LFYL',
        latitude: '47,7047',
        longitude: '6,54583',
        altitude: '1040',
        timezone: '1',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Parati Airport',
        city: 'Paraty',
        country: 'Brazil',
        iataCode: 'N',
        icaoCode: 'SDTK',
        latitude: '-23,22439957',
        longitude: '-44,72029877',
        altitude: '10',
        timezone: '-3',
        dst: 'S',
        type: 'airport',
    },
    {
        name: 'Umberto Modiano Airport',
        city: 'Buzios',
        country: 'Brazil',
        iataCode: 'BZC',
        icaoCode: 'SBBZ',
        latitude: '-22,77099991',
        longitude: '-41,96289825',
        altitude: '10',
        timezone: '-3',
        dst: 'S',
        type: 'airport',
    },
    {
        name: 'Angra dos Reis Airport',
        city: 'Angra dos Reis',
        country: 'Brazil',
        iataCode: 'N',
        icaoCode: 'SDAG',
        latitude: '-22,97529984',
        longitude: '-44,30709839',
        altitude: '10',
        timezone: '-3',
        dst: 'S',
        type: 'airport',
    },
    {
        name: 'Itaperuna Airport',
        city: 'Itaperuna',
        country: 'Brazil',
        iataCode: 'ITP',
        icaoCode: 'SDUN',
        latitude: '-21,21929932',
        longitude: '-41,87590027',
        altitude: '410',
        timezone: '-3',
        dst: 'S',
        type: 'airport',
    },
    {
        name: 'Maricá Airport',
        city: 'Marica',
        country: 'Brazil',
        iataCode: 'N',
        icaoCode: 'SDMC',
        latitude: '-22,91950035',
        longitude: '-42,83089828',
        altitude: '13',
        timezone: '-3',
        dst: 'S',
        type: 'airport',
    },
    {
        name: 'Resende Airport',
        city: 'Resende',
        country: 'Brazil',
        iataCode: 'N',
        icaoCode: 'SDRS',
        latitude: '-22,47850037',
        longitude: '-44,4803009',
        altitude: '1320',
        timezone: '-3',
        dst: 'S',
        type: 'airport',
    },
    {
        name: 'Saquarema Airport',
        city: 'Saquarema',
        country: 'Brazil',
        iataCode: 'N',
        icaoCode: 'SDSK',
        latitude: '-22,92972183',
        longitude: '-42,50694275',
        altitude: '26',
        timezone: '-3',
        dst: 'S',
        type: 'airport',
    },
    {
        name: 'Drumheller Municipal Airport',
        city: 'Drumheller',
        country: 'Canada',
        iataCode: 'N',
        icaoCode: 'CEG4',
        latitude: '51,49639893',
        longitude: '-112,7490005',
        altitude: '2597',
        timezone: '-7',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Mwene-Ditu Airport',
        city: 'Mwene-Ditu',
        country: 'Congo (Kinshasa)',
        iataCode: 'N',
        icaoCode: 'FZWE',
        latitude: '-6,982999802',
        longitude: '23,08300018',
        altitude: '3198',
        timezone: '1',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Tunta Airport',
        city: 'Kabinda',
        country: 'Congo (Kinshasa)',
        iataCode: 'KBN',
        icaoCode: 'FZWT',
        latitude: '-6,132999897',
        longitude: '24,4829998',
        altitude: '2766',
        timezone: '1',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Ikela Airport',
        city: 'Ikela',
        country: 'Congo (Kinshasa)',
        iataCode: 'IKL',
        icaoCode: 'FZGV',
        latitude: '-1,048109',
        longitude: '23,372501',
        altitude: '1283',
        timezone: '1',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Aripuanã Airport',
        city: 'Aripuana',
        country: 'Brazil',
        iataCode: 'AIR',
        icaoCode: 'SWRP',
        latitude: '-10,250278',
        longitude: '-59,383888',
        altitude: '623',
        timezone: '-4',
        dst: 'S',
        type: 'airport',
    },
    {
        name: 'Juruena Airport',
        city: 'Juruena',
        country: 'Brazil',
        iataCode: 'JRN',
        icaoCode: 'SWJU',
        latitude: '-10,30583286',
        longitude: '-58,48944473',
        altitude: '525',
        timezone: '-4',
        dst: 'S',
        type: 'airport',
    },
    {
        name: 'Juína Airport',
        city: 'Juina',
        country: 'Brazil',
        iataCode: 'JIA',
        icaoCode: 'SWJN',
        latitude: '-11,41944408',
        longitude: '-58,70166779',
        altitude: '1083',
        timezone: '-4',
        dst: 'S',
        type: 'airport',
    },
    {
        name: 'Vila Rica Airport',
        city: 'Vila Rica',
        country: 'Brazil',
        iataCode: 'VLP',
        icaoCode: 'SWVC',
        latitude: '-9,97944355',
        longitude: '-51,14222336',
        altitude: '892',
        timezone: '-4',
        dst: 'S',
        type: 'airport',
    },
    {
        name: 'Inácio Luís do Nascimento Airport',
        city: 'Juara',
        country: 'Brazil',
        iataCode: 'JUA',
        icaoCode: 'SIZX',
        latitude: '-11,2966',
        longitude: '-57,5495',
        altitude: '870',
        timezone: '-4',
        dst: 'S',
        type: 'airport',
    },
    {
        name: 'Cáceres Airport',
        city: 'Caceres',
        country: 'Brazil',
        iataCode: 'CCX',
        icaoCode: 'SWKC',
        latitude: '-16,04360008',
        longitude: '-57,62990189',
        altitude: '492',
        timezone: '-4',
        dst: 'S',
        type: 'airport',
    },
    {
        name: 'Posto Leonardo Vilas Boas Airport',
        city: 'Chapada dos Guimaraes',
        country: 'Brazil',
        iataCode: 'N',
        icaoCode: 'SWPL',
        latitude: '-12,19833279',
        longitude: '-53,38166809',
        altitude: '1083',
        timezone: '-4',
        dst: 'S',
        type: 'airport',
    },
    {
        name: 'Tangará da Serra Airport',
        city: 'Tangara da Serra',
        country: 'Brazil',
        iataCode: 'N',
        icaoCode: 'SWTS',
        latitude: '-14,6619997',
        longitude: '-57,44350052',
        altitude: '1460',
        timezone: '-4',
        dst: 'S',
        type: 'airport',
    },
    {
        name: 'Canarana Airport',
        city: 'Canarana',
        country: 'Brazil',
        iataCode: 'N',
        icaoCode: 'SWEK',
        latitude: '-13,57444382',
        longitude: '-52,2705574',
        altitude: '1314',
        timezone: '-4',
        dst: 'S',
        type: 'airport',
    },
    {
        name: 'Vila Bela da Santíssima Trindade Airport',
        city: 'Vila Bela da Santissima Trindade ',
        country: 'Brazil',
        iataCode: 'N',
        icaoCode: 'SWVB',
        latitude: '-14,9942',
        longitude: '-59,9458',
        altitude: '660',
        timezone: '-4',
        dst: 'S',
        type: 'airport',
    },
    {
        name: 'Bumbar Airport',
        city: 'Bumba',
        country: 'Congo (Kinshasa)',
        iataCode: 'BMB',
        icaoCode: 'FZFU',
        latitude: '2,182780027',
        longitude: '22,4817009',
        altitude: '0',
        timezone: '1',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Sobral Airport',
        city: 'Sobral',
        country: 'Brazil',
        iataCode: 'QBX',
        icaoCode: 'SNOB',
        latitude: '-3,67888999',
        longitude: '-40,33679962',
        altitude: '210',
        timezone: '-3',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Arapiraca Airport',
        city: 'Arapiraca',
        country: 'Brazil',
        iataCode: 'APQ',
        icaoCode: 'SNAL',
        latitude: '-9,775360107',
        longitude: '-36,62919998',
        altitude: '886',
        timezone: '-3',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Cangapara Airport',
        city: 'Floriano',
        country: 'Brazil',
        iataCode: 'FLB',
        icaoCode: 'SNQG',
        latitude: '-6,846389771',
        longitude: '-43,07730103',
        altitude: '689',
        timezone: '-3',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Picos Airport',
        city: 'Picos',
        country: 'Brazil',
        iataCode: 'PCS',
        icaoCode: 'SNPC',
        latitude: '-7,062059879',
        longitude: '-41,52370071',
        altitude: '1050',
        timezone: '-3',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Beni Airport',
        city: 'Beni',
        country: 'Congo (Kinshasa)',
        iataCode: 'BNC',
        icaoCode: 'FZNP',
        latitude: '0,574999988',
        longitude: '29,47389984',
        altitude: '3517',
        timezone: '1',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Boende Airport',
        city: 'Boende',
        country: 'Congo (Kinshasa)',
        iataCode: 'BNB',
        icaoCode: 'FZGN',
        latitude: '-0,216999993',
        longitude: '20,85000038',
        altitude: '1168',
        timezone: '1',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Montauk Airport',
        city: 'Montauk',
        country: 'United States',
        iataCode: 'MTP',
        icaoCode: 'KMTP',
        latitude: '41,07649994',
        longitude: '-71,92079926',
        altitude: '6',
        timezone: '-4',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Porter County Municipal Airport',
        city: 'Valparaiso IN',
        country: 'United States',
        iataCode: 'VPZ',
        icaoCode: 'KVPZ',
        latitude: '41,45399857',
        longitude: '-87,00710297',
        altitude: '770',
        timezone: '-5',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Dharavandhoo Airport',
        city: 'DHARAVANDHOO',
        country: 'Maldives',
        iataCode: 'DRV',
        icaoCode: 'VRMD',
        latitude: '5,1561',
        longitude: '73,1302',
        altitude: '6',
        timezone: '5',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Saumlaki/Olilit Airport',
        city: 'Saumlaki',
        country: 'Indonesia',
        iataCode: 'SXK',
        icaoCode: 'WAPI',
        latitude: '-7,988609791',
        longitude: '131,3059998',
        altitude: '218',
        timezone: '9',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Cerro Largo International Airport',
        city: 'Melo',
        country: 'Uruguay',
        iataCode: 'MLZ',
        icaoCode: 'SUMO',
        latitude: '-32,33789825',
        longitude: '-54,21670151',
        altitude: '364',
        timezone: '-3',
        dst: 'S',
        type: 'airport',
    },
    {
        name: 'Tydeo Larre Borges Airport',
        city: 'Paysandu',
        country: 'Uruguay',
        iataCode: 'PDU',
        icaoCode: 'SUPU',
        latitude: '-32,36330032',
        longitude: '-58,06190109',
        altitude: '138',
        timezone: '-3',
        dst: 'S',
        type: 'airport',
    },
    {
        name: 'Artigas International Airport',
        city: 'Artigas ',
        country: 'Uruguay',
        iataCode: 'ATI',
        icaoCode: 'SUAG',
        latitude: '-30,40069962',
        longitude: '-56,50790024',
        altitude: '410',
        timezone: '-3',
        dst: 'S',
        type: 'airport',
    },
    {
        name: 'Murray Bridge Airport',
        city: 'Murray Bridge',
        country: 'Australia',
        iataCode: 'N',
        icaoCode: 'YMBD',
        latitude: '-35,06669998',
        longitude: '139,227005',
        altitude: '55',
        timezone: '10',
        dst: 'O',
        type: 'airport',
    },
    {
        name: 'Horsham Airport',
        city: 'Horsham',
        country: 'Australia',
        iataCode: 'HSM',
        icaoCode: 'YHSM',
        latitude: '-36,66970062',
        longitude: '142,1730042',
        altitude: '445',
        timezone: '10',
        dst: 'O',
        type: 'airport',
    },
    {
        name: 'Swan Hill Airport',
        city: 'Swan Hill',
        country: 'Australia',
        iataCode: 'SWH',
        icaoCode: 'YSWH',
        latitude: '-35,37580109',
        longitude: '143,5330048',
        altitude: '234',
        timezone: '10',
        dst: 'O',
        type: 'airport',
    },
    {
        name: 'Turtle Island Seaplane Base',
        city: 'Turtle Island',
        country: 'Fiji',
        iataCode: 'TTL',
        icaoCode: 'NFUL',
        latitude: '-16,966',
        longitude: '177,368',
        altitude: '0',
        timezone: '12',
        dst: 'O',
        type: 'airport',
    },
    {
        name: 'Dewadaru - Kemujan Island',
        city: 'Karimunjawa',
        country: 'Indonesia',
        iataCode: 'KWB',
        icaoCode: 'WARU',
        latitude: '-5,80091',
        longitude: '110,47838',
        altitude: '35',
        timezone: '7',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Kongolo Airport',
        city: 'Kongolo',
        country: 'Congo (Kinshasa)',
        iataCode: 'KOO',
        icaoCode: 'FZRQ',
        latitude: '-5,394440174',
        longitude: '26,98999977',
        altitude: '1850',
        timezone: '1',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Attopeu Airport',
        city: 'Attopeu',
        country: 'Laos',
        iataCode: 'AOU',
        icaoCode: 'VLAP',
        latitude: '14,81471536',
        longitude: '106,8216991',
        altitude: '344',
        timezone: '7',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Kahemba Airport',
        city: 'Kahemba',
        country: 'Congo (Kinshasa)',
        iataCode: 'N',
        icaoCode: 'FZCF',
        latitude: '-7,333000183',
        longitude: '19,0170002',
        altitude: '3425',
        timezone: '1',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Francisco de Miranda Airport',
        city: 'Caracas',
        country: 'Venezuela',
        iataCode: 'N/A',
        icaoCode: 'SVFM',
        latitude: '10,48503304',
        longitude: '-66,84351349',
        altitude: '2739',
        timezone: '-4',
        dst: 'S',
        type: 'airport',
    },
    {
        name: 'São Miguel do Oeste Airport',
        city: 'Sao Miguel do Oeste',
        country: 'Brazil',
        iataCode: 'SQX',
        icaoCode: 'SSOE',
        latitude: '-26,78160095',
        longitude: '-53,50350189',
        altitude: '2180',
        timezone: '-3',
        dst: 'S',
        type: 'airport',
    },
    {
        name: 'Mason County Airport',
        city: 'Ludington',
        country: 'United States',
        iataCode: 'LDM',
        icaoCode: 'KLDM',
        latitude: '43,96250153',
        longitude: '-86,40789795',
        altitude: '646',
        timezone: '-5',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Reid-Hillview Airport of Santa Clara County',
        city: 'San Jose',
        country: 'United States',
        iataCode: 'RHV',
        icaoCode: 'KRHV',
        latitude: '37,332901',
        longitude: '-121,8190002',
        altitude: '135',
        timezone: '8',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Sohar Airport',
        city: 'Sohar',
        country: 'Oman',
        iataCode: 'OHS',
        icaoCode: 'OOSH',
        latitude: '24,38604',
        longitude: '56,62541',
        altitude: '20',
        timezone: '4',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Sahiwal Airport',
        city: 'Sindh',
        country: 'Pakistan',
        iataCode: 'RZS',
        icaoCode: 'OPSW',
        latitude: '31,88944435',
        longitude: '72,39167023',
        altitude: '570',
        timezone: '5',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Thamkharka Airport',
        city: 'Thamkharka',
        country: 'Nepal',
        iataCode: 'N',
        icaoCode: 'VNTH',
        latitude: '27,04787',
        longitude: '86,858022',
        altitude: '5240',
        timezone: '5,75',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Thimarafushi Airport',
        city: 'Thimarafushi',
        country: 'Maldives',
        iataCode: 'TMF',
        icaoCode: 'VRNT',
        latitude: '2,211',
        longitude: '73,1533',
        altitude: '6',
        timezone: '5',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Ifuru Airport',
        city: 'Ifuru',
        country: 'Maldives',
        iataCode: 'IFU',
        icaoCode: 'VREI',
        latitude: '5,7083',
        longitude: '73,025',
        altitude: '20',
        timezone: '5',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Kieta Airport',
        city: 'Kieta',
        country: 'Papua New Guinea',
        iataCode: 'KIE',
        icaoCode: 'AYKT',
        latitude: '-6,305416667',
        longitude: '155,7281389',
        altitude: '20',
        timezone: '10',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Hakkari Yüksekova Airport',
        city: 'Hakkari',
        country: 'Turkey',
        iataCode: 'YKO',
        icaoCode: 'LTCW',
        latitude: '37,5497',
        longitude: '44,2381',
        altitude: '6090',
        timezone: '2',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Jelenia Góra Glider Airport',
        city: 'Jelenia Gora',
        country: 'Poland',
        iataCode: 'N',
        icaoCode: 'EPJG',
        latitude: '50,89889908',
        longitude: '15,78559971',
        altitude: '1119',
        timezone: '1',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Bathpalathang Airport',
        city: 'Jakar',
        country: 'Bhutan',
        iataCode: 'BUT',
        icaoCode: 'VQBT',
        latitude: '27,5622',
        longitude: '90,7471',
        altitude: '8485',
        timezone: '6',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Sultan Bantilan Airport',
        city: 'Toli-Toli',
        country: 'Indonesia',
        iataCode: 'TLI',
        icaoCode: 'WAMI',
        latitude: '1,123428',
        longitude: '120,793658',
        altitude: '40',
        timezone: '8',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Vuktyl Airport',
        city: 'Vuktyl',
        country: 'Russia',
        iataCode: 'N',
        icaoCode: 'UUYK',
        latitude: '63,82329941',
        longitude: '57,27999878',
        altitude: '358',
        timezone: '3',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Berezniki Airport',
        city: 'Berezniki',
        country: 'Russia',
        iataCode: 'N',
        icaoCode: 'USPT',
        latitude: '59,57954788',
        longitude: '56,85768127',
        altitude: '207',
        timezone: '6',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'San Sebastián Airport',
        city: 'San Sebastian',
        country: 'Chile',
        iataCode: 'N',
        icaoCode: 'SCSS',
        latitude: '-53,31700134',
        longitude: '-68,65000153',
        altitude: '50',
        timezone: '-4',
        dst: 'S',
        type: 'airport',
    },
    {
        name: 'Tolwin Observatory Airport',
        city: 'Tolhuin',
        country: 'Argentina',
        iataCode: 'N',
        icaoCode: 'SAWL',
        latitude: '-54,52190018',
        longitude: '-67,1989975',
        altitude: '90',
        timezone: '-3',
        dst: 'S',
        type: 'airport',
    },
    {
        name: 'Tarko-Sale Airport',
        city: 'Tarko-Sale',
        country: 'Russia',
        iataCode: 'TQL',
        icaoCode: 'USDS',
        latitude: '64,93080139',
        longitude: '77,81809998',
        altitude: '82',
        timezone: '5',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Camarillo Airport',
        city: 'Camarillo - CA',
        country: 'United States',
        iataCode: 'N',
        icaoCode: 'KCMA',
        latitude: '34,21369934',
        longitude: '-119,0940018',
        altitude: '77',
        timezone: '-8',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Leopoldsburg Airfield',
        city: 'Leopoldsburg',
        country: 'Belgium',
        iataCode: 'N',
        icaoCode: 'EBLE',
        latitude: '51,12',
        longitude: '5,3072222',
        altitude: '207',
        timezone: '1',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Omtepe Airport',
        city: 'Moyogalpa',
        country: 'Nicaragua',
        iataCode: 'N',
        icaoCode: 'MNLP',
        latitude: '11,523616',
        longitude: '-85,702995',
        altitude: '200',
        timezone: '-6',
        dst: 'S',
        type: 'airport',
    },
    {
        name: 'Alashankou Bole (Bortala) airport',
        city: 'Bole',
        country: 'China',
        iataCode: 'BPL',
        icaoCode: 'ZWAX',
        latitude: '44,895',
        longitude: '82,3',
        altitude: '1253',
        timezone: '8',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Achinsk Airport',
        city: 'Achinsk',
        country: 'Russia',
        iataCode: 'ACS',
        icaoCode: 'UNKS',
        latitude: '56,2682991',
        longitude: '90,57080078',
        altitude: '1033',
        timezone: '8',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Hinton/Jasper-Hinton Airport',
        city: 'Hinton',
        country: 'Canada',
        iataCode: 'N',
        icaoCode: 'CEC4',
        latitude: '53,31919861',
        longitude: '-117,7529984',
        altitude: '4006',
        timezone: '-7',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Watsonville Municipal Airport',
        city: 'Watsonville',
        country: 'United States',
        iataCode: 'WVI',
        icaoCode: 'KWVI',
        latitude: '36,93569946',
        longitude: '-121,7900009',
        altitude: '163',
        timezone: '-8',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Gelephu Airport',
        city: 'Gelephu',
        country: 'Bhutan',
        iataCode: 'GLU',
        icaoCode: 'VQGP',
        latitude: '26,88456',
        longitude: '90,46412',
        altitude: '980',
        timezone: '6',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Williamsburg Jamestown Airport',
        city: 'Williamsburg',
        country: 'United States',
        iataCode: 'JGG',
        icaoCode: 'KJGG',
        latitude: '37,23920059',
        longitude: '-76,7161026',
        altitude: '49',
        timezone: '-5',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Dulkaninna Airport',
        city: 'Dulkaninna',
        country: 'Australia',
        iataCode: 'DLK',
        icaoCode: 'YDLK',
        latitude: '-29,01329994',
        longitude: '138,4810028',
        altitude: '0',
        timezone: '9,5',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'William Creek Airport',
        city: 'William Creek',
        country: 'Australia',
        iataCode: 'N',
        icaoCode: 'YWMC',
        latitude: '-28,90670013',
        longitude: '136,3419952',
        altitude: '91',
        timezone: '9,5',
        dst: 'U',
        type: 'airport',
    },
    {
        name: 'Kenora Seaplane Base',
        city: 'Kenora',
        country: 'Canada',
        iataCode: 'N',
        icaoCode: 'CJM9',
        latitude: '49,767424',
        longitude: '-94,49274',
        altitude: '1060',
        timezone: '-6',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Siquijor Airport',
        city: 'Siquijor',
        country: 'Philippines',
        iataCode: 'N',
        icaoCode: 'RPVZ',
        latitude: '9,214203223',
        longitude: '123,4722519',
        altitude: '0',
        timezone: '8',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Opapimiskan Lake Airport',
        city: 'Musselwhite Mine',
        country: 'Canada',
        iataCode: 'KM8',
        icaoCode: 'CKM8',
        latitude: '52,6067009',
        longitude: '-90,37689972',
        altitude: '1023',
        timezone: '-5',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Taroom Airport',
        city: 'Taroom',
        country: 'Australia',
        iataCode: 'XTO',
        icaoCode: 'YTAM',
        latitude: '-25,80170059',
        longitude: '149,8999939',
        altitude: '240',
        timezone: '10',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Gelnhausen Airport',
        city: 'Gelnhausen',
        country: 'Germany',
        iataCode: 'N',
        icaoCode: 'EDFG',
        latitude: '50,19722366',
        longitude: '9,170000076',
        altitude: '446',
        timezone: '1',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Ærø Airport',
        city: 'Aero',
        country: 'Denmark',
        iataCode: 'N',
        icaoCode: 'EKAE',
        latitude: '54,85279846',
        longitude: '10,45639992',
        altitude: '3',
        timezone: '1',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Lauterbach Airport',
        city: 'Lauterbach-Wernges',
        country: 'Germany',
        iataCode: 'N',
        icaoCode: 'EDFT',
        latitude: '50,68333435',
        longitude: '9,410833359',
        altitude: '1211',
        timezone: '1',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Aschaffenburg Airport',
        city: 'Aschaffenburg',
        country: 'Germany',
        iataCode: 'N',
        icaoCode: 'EDFC',
        latitude: '49,93888855',
        longitude: '9,06388855',
        altitude: '410',
        timezone: '1',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Squamish Airport',
        city: 'Squamish',
        country: 'Canada',
        iataCode: 'YSE',
        icaoCode: 'CYSE',
        latitude: '49,78170013',
        longitude: '-123,1620026',
        altitude: '171',
        timezone: '-8',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'Reichelsheim Airport',
        city: 'Reichelsheim',
        country: 'Germany',
        iataCode: 'N',
        icaoCode: 'EDFB',
        latitude: '50,3358345',
        longitude: '8,878055573',
        altitude: '397',
        timezone: '1',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Marburg-Schönstadt Airport',
        city: 'Schoenstadt',
        country: 'Germany',
        iataCode: 'N',
        icaoCode: 'EDFN',
        latitude: '50,87444305',
        longitude: '8,81499958',
        altitude: '833',
        timezone: '1',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Lauenbrück Airport',
        city: 'Lauenbrueck',
        country: 'Germany',
        iataCode: 'N',
        icaoCode: 'EDHU',
        latitude: '53,20750046',
        longitude: '9,573332787',
        altitude: '98',
        timezone: '1',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Hüttenbusch Airport',
        city: 'Huettenbusch',
        country: 'Germany',
        iataCode: 'N',
        icaoCode: 'EDXU',
        latitude: '53,28666687',
        longitude: '8,947221756',
        altitude: '10',
        timezone: '1',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Vrsar Crljenka Airport',
        city: 'Vsar',
        country: 'Croatia',
        iataCode: 'N',
        icaoCode: 'LDPV',
        latitude: '45,14170074',
        longitude: '13,63059998',
        altitude: '121',
        timezone: '1',
        dst: 'E',
        type: 'airport',
    },
    {
        name: 'Union County Airport',
        city: 'Marysville',
        country: 'United States',
        iataCode: 'MRT',
        icaoCode: 'KMRT',
        latitude: '40,22470093',
        longitude: '-83,35160065',
        altitude: '1021',
        timezone: '-5',
        dst: 'A',
        type: 'airport',
    },
    {
        name: 'La Grande-4 Airport',
        city: 'La Grande-4',
        country: 'Canada',
        iataCode: 'YAH',
        icaoCode: 'CYAH',
        latitude: '53,75469971',
        longitude: '-73,6753006',
        altitude: '1005',
        timezone: 'N',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Alert Bay Airport',
        city: 'Alert Bay',
        country: 'Canada',
        iataCode: 'YAL',
        icaoCode: 'CYAL',
        latitude: '50,5821991',
        longitude: '-126,9160004',
        altitude: '240',
        timezone: 'N',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Liverpool South Shore Regional Airport',
        city: 'Liverpool',
        country: 'Canada',
        iataCode: '',
        icaoCode: 'CYAU',
        latitude: '44,2303009',
        longitude: '-64,85610199',
        altitude: '321',
        timezone: 'N',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Nipawin Airport',
        city: 'Nipawin',
        country: 'Canada',
        iataCode: 'YBU',
        icaoCode: 'CYBU',
        latitude: '53,33250046',
        longitude: '-104,0080032',
        altitude: '1220',
        timezone: 'N',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'James T. Field Memorial Aerodrome',
        city: 'Centralia',
        country: 'Canada',
        iataCode: 'YCE',
        icaoCode: 'CYCE',
        latitude: '43,285599',
        longitude: '-81,508301',
        altitude: '824',
        timezone: 'N',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Blue River Airport',
        city: 'Blue River',
        country: 'Canada',
        iataCode: 'YCP',
        icaoCode: 'CYCP',
        latitude: '52,11669922',
        longitude: '-119,2829971',
        altitude: '2240',
        timezone: 'N',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Chetwynd Airport',
        city: 'Chetwynd',
        country: 'Canada',
        iataCode: 'YCQ',
        icaoCode: 'CYCQ',
        latitude: '55,68719864',
        longitude: '-121,6269989',
        altitude: '2000',
        timezone: 'N',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Ross River Airport',
        city: 'Ross River',
        country: 'Canada',
        iataCode: 'XRR',
        icaoCode: 'CYDM',
        latitude: '61,97060013',
        longitude: '-132,4230042',
        altitude: '2314',
        timezone: 'N',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Dolbeau St Felicien Airport',
        city: 'Dolbeau-St-Félicien',
        country: 'Canada',
        iataCode: 'YDO',
        icaoCode: 'CYDO',
        latitude: '48,7784996',
        longitude: '-72,375',
        altitude: '372',
        timezone: 'N',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Amos Magny Airport',
        city: 'Amos',
        country: 'Canada',
        iataCode: 'YEY',
        icaoCode: 'CYEY',
        latitude: '48,56389999',
        longitude: '-78,24970245',
        altitude: '1068',
        timezone: 'N',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Goderich Airport',
        city: 'Goderich',
        country: 'Canada',
        iataCode: 'YGD',
        icaoCode: 'CYGD',
        latitude: '43,76689911',
        longitude: '-81,71060181',
        altitude: '712',
        timezone: 'N',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Hope Airport',
        city: 'Hope',
        country: 'Canada',
        iataCode: 'YHE',
        icaoCode: 'CYHE',
        latitude: '49,36830139',
        longitude: '-121,4980011',
        altitude: '128',
        timezone: 'N',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Haines Junction Airport',
        city: 'Haines Junction',
        country: 'Canada',
        iataCode: 'YHT',
        icaoCode: 'CYHT',
        latitude: '60,78919983',
        longitude: '-137,5460052',
        altitude: '2150',
        timezone: 'N',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Digby / Annapolis Regional Airport',
        city: 'Digby',
        country: 'Canada',
        iataCode: 'YDG',
        icaoCode: 'CYID',
        latitude: '44,54584504',
        longitude: '-65,78542471',
        altitude: '499',
        timezone: 'N',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Fort Liard Airport',
        city: 'Fort Liard',
        country: 'Canada',
        iataCode: 'YJF',
        icaoCode: 'CYJF',
        latitude: '60,2358017',
        longitude: '-123,4690018',
        altitude: '708',
        timezone: 'N',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Key Lake Airport',
        city: 'Key Lake',
        country: 'Canada',
        iataCode: 'YKJ',
        icaoCode: 'CYKJ',
        latitude: '57,2560997',
        longitude: '-105,6179962',
        altitude: '1679',
        timezone: 'N',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Leaf Rapids Airport',
        city: 'Leaf Rapids',
        country: 'Canada',
        iataCode: 'YLR',
        icaoCode: 'CYLR',
        latitude: '56,51330185',
        longitude: '-99,98529816',
        altitude: '959',
        timezone: 'N',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Matane Airport',
        city: 'Matane',
        country: 'Canada',
        iataCode: 'YME',
        icaoCode: 'CYME',
        latitude: '48,85689926',
        longitude: '-67,45330048',
        altitude: '102',
        timezone: 'N',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Charlevoix Airport',
        city: 'Charlevoix',
        country: 'Canada',
        iataCode: 'YML',
        icaoCode: 'CYML',
        latitude: '47,59749985',
        longitude: '-70,22389984',
        altitude: '977',
        timezone: 'N',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Nejanilini Lake Airport',
        city: 'Nejanilini Lake',
        country: 'Canada',
        iataCode: 'N',
        icaoCode: 'CYNN',
        latitude: '59,48749924',
        longitude: '-97,78029633',
        altitude: '1000',
        timezone: 'N',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Owen Sound / Billy Bishop Regional Airport',
        city: 'Owen Sound',
        country: 'Canada',
        iataCode: 'YOS',
        icaoCode: 'CYOS',
        latitude: '44,59030151',
        longitude: '-80,83750153',
        altitude: '1007',
        timezone: 'N',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Port Hawkesbury Airport',
        city: 'Port Hawkesbury',
        country: 'Canada',
        iataCode: 'YPS',
        icaoCode: 'CYPD',
        latitude: '45,65670013',
        longitude: '-61,36809921',
        altitude: '377',
        timezone: 'N',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'St Thomas Municipal Airport',
        city: 'St Thomas',
        country: 'Canada',
        iataCode: 'YQS',
        icaoCode: 'CYQS',
        latitude: '42,77000046',
        longitude: '-81,1108017',
        altitude: '778',
        timezone: 'N',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Ottawa / Rockcliffe Airport',
        city: 'Ottawa',
        country: 'Canada',
        iataCode: 'YRO',
        icaoCode: 'CYRO',
        latitude: '45,46030045',
        longitude: '-75,64610291',
        altitude: '188',
        timezone: 'N',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Ottawa / Carp Airport',
        city: 'Ottawa',
        country: 'Canada',
        iataCode: 'YRP',
        icaoCode: 'CYRP',
        latitude: '45,31919861',
        longitude: '-76,02220154',
        altitude: '382',
        timezone: 'N',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Smiths Falls-Montague (Russ Beach) Airport',
        city: 'Smiths Falls',
        country: 'Canada',
        iataCode: 'YSH',
        icaoCode: 'CYSH',
        latitude: '44,94580078',
        longitude: '-75,94059753',
        altitude: '416',
        timezone: 'N',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'St Leonard Airport',
        city: 'St Leonard',
        country: 'Canada',
        iataCode: 'YSL',
        icaoCode: 'CYSL',
        latitude: '47,15750122',
        longitude: '-67,83470154',
        altitude: '793',
        timezone: 'N',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Virden/R.J. (Bob) Andrew Field Regional Aerodrome',
        city: 'Virden',
        country: 'Canada',
        iataCode: 'YVD',
        icaoCode: 'CYVD',
        latitude: '49,87829971',
        longitude: '-100,9179993',
        altitude: '1454',
        timezone: 'N',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Vernon Airport',
        city: 'Vernon',
        country: 'Canada',
        iataCode: 'YVE',
        icaoCode: 'CYVK',
        latitude: '50,24810028',
        longitude: '-119,3310013',
        altitude: '1140',
        timezone: 'N',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Beaver Creek Airport',
        city: 'Beaver Creek',
        country: 'Canada',
        iataCode: 'YXQ',
        icaoCode: 'CYXQ',
        latitude: '62,41030121',
        longitude: '-140,8670044',
        altitude: '2131',
        timezone: 'N',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Salmon Arm Airport',
        city: 'Salmon Arm',
        country: 'Canada',
        iataCode: 'YSN',
        icaoCode: 'CZAM',
        latitude: '50,682802',
        longitude: '-119,228996',
        altitude: '1751',
        timezone: 'N',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Kelsey Airport',
        city: 'Kelsey',
        country: 'Canada',
        iataCode: 'KES',
        icaoCode: 'CZEE',
        latitude: '56,03749847',
        longitude: '-96,50969696',
        altitude: '600',
        timezone: 'N',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Pukatawagan Airport',
        city: 'Pukatawagan',
        country: 'Canada',
        iataCode: 'XPK',
        icaoCode: 'CZFG',
        latitude: '55,74919891',
        longitude: '-101,2659988',
        altitude: '958',
        timezone: 'N',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Grand Forks Airport',
        city: 'Grand Forks',
        country: 'Canada',
        iataCode: 'ZGF',
        icaoCode: 'CZGF',
        latitude: '49,0155983',
        longitude: '-118,4309998',
        altitude: '1720',
        timezone: 'N',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Jenpeg Airport',
        city: 'Jenpeg',
        country: 'Canada',
        iataCode: 'ZJG',
        icaoCode: 'CZJG',
        latitude: '54,51890182',
        longitude: '-98,0460968',
        altitude: '729',
        timezone: 'N',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Thicket Portage Airport',
        city: 'Thicket Portage',
        country: 'Canada',
        iataCode: 'YTD',
        icaoCode: 'CZLQ',
        latitude: '55,31890106',
        longitude: '-97,70780182',
        altitude: '678',
        timezone: 'N',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Pikwitonei Airport',
        city: 'Pikwitonei',
        country: 'Canada',
        iataCode: 'PIW',
        icaoCode: 'CZMN',
        latitude: '55,58890152',
        longitude: '-97,16419983',
        altitude: '630',
        timezone: 'N',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Poplar River Airport',
        city: 'Poplar River',
        country: 'Canada',
        iataCode: 'XPP',
        icaoCode: 'CZNG',
        latitude: '52,99652588',
        longitude: '-97,27419376',
        altitude: '728',
        timezone: 'N',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Pincher Creek Airport',
        city: 'Pincher Creek',
        country: 'Canada',
        iataCode: 'WPC',
        icaoCode: 'CZPC',
        latitude: '49,52059937',
        longitude: '-113,9970016',
        altitude: '3903',
        timezone: 'N',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Stewart Airport',
        city: 'Stewart',
        country: 'Canada',
        iataCode: 'ZST',
        icaoCode: 'CZST',
        latitude: '55,93541045',
        longitude: '-129,9824345',
        altitude: '24',
        timezone: 'N',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Ignace Municipal Airport',
        city: 'Ignace',
        country: 'Canada',
        iataCode: 'ZUC',
        icaoCode: 'CZUC',
        latitude: '49,42969894',
        longitude: '-91,71779633',
        altitude: '1435',
        timezone: 'N',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Neubrandenburg Airport',
        city: 'Neubrandenburg',
        country: 'Germany',
        iataCode: 'FNB',
        icaoCode: 'EDBN',
        latitude: '53,6022',
        longitude: '13,306',
        altitude: '228',
        timezone: 'N',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Airport Bamberg-Breitenau',
        city: 'Bamberg',
        country: 'Germany',
        iataCode: 'N',
        icaoCode: 'EDQA',
        latitude: '49,920403',
        longitude: '10,914233',
        altitude: '811',
        timezone: 'N',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Zerbst Airport',
        city: 'Zerbst/Anhalt',
        country: 'Germany',
        iataCode: 'N',
        icaoCode: 'EDUZ',
        latitude: '52,0008316',
        longitude: '12,14861107',
        altitude: '289',
        timezone: 'N',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'RAF Spadeadam',
        city: 'Spadeadam',
        country: 'United Kingdom',
        iataCode: 'N',
        icaoCode: 'EGOM',
        latitude: '55,04999924',
        longitude: '-2,549999952',
        altitude: '1066',
        timezone: 'N',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'RAF Kinloss',
        city: 'Kinloss',
        country: 'United Kingdom',
        iataCode: 'FSS',
        icaoCode: 'EGQK',
        latitude: '57,6493988',
        longitude: '-3,560640097',
        altitude: '22',
        timezone: 'N',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Biała Podlaska Airfield',
        city: 'Biała Podlaska',
        country: 'Poland',
        iataCode: 'BXP',
        icaoCode: 'EPBP',
        latitude: '52,00078',
        longitude: '23,1325278',
        altitude: '485',
        timezone: 'N',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Cewice Military Airport',
        city: 'Lębork',
        country: 'Poland',
        iataCode: 'N',
        icaoCode: 'EPCE',
        latitude: '54,41600037',
        longitude: '17,76329994',
        altitude: '495',
        timezone: 'N',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Deblin Military Air Base',
        city: 'Dęblin',
        country: 'Poland',
        iataCode: 'N',
        icaoCode: 'EPDE',
        latitude: '51,55139923',
        longitude: '21,89360046',
        altitude: '392',
        timezone: 'N',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Leczyca Military Air Base',
        city: 'Leczyca',
        country: 'Poland',
        iataCode: 'N',
        icaoCode: 'EPLY',
        latitude: '52,00469971',
        longitude: '19,14559937',
        altitude: '377',
        timezone: 'N',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Minsk Mazowiecki Military Air Base',
        city: '',
        country: 'Poland',
        iataCode: 'N',
        icaoCode: 'EPMM',
        latitude: '52,19549942',
        longitude: '21,65589905',
        altitude: '604',
        timezone: 'N',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Powidz Military Air Base',
        city: '',
        country: 'Poland',
        iataCode: 'N',
        icaoCode: 'EPPW',
        latitude: '52,37939835',
        longitude: '17,85390091',
        altitude: '371',
        timezone: 'N',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Tomaszow Mazowiecki Military Air Base',
        city: 'Tomaszów Mazowiecki',
        country: 'Poland',
        iataCode: 'N',
        icaoCode: 'EPTM',
        latitude: '51,58440018',
        longitude: '20,0977993',
        altitude: '571',
        timezone: 'N',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Björkvik Air Base',
        city: 'Björkvik',
        country: 'Sweden',
        iataCode: 'N',
        icaoCode: 'ESKX',
        latitude: '58,79079819',
        longitude: '16,57119942',
        altitude: '0',
        timezone: 'N',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Ljungbyhed Airport',
        city: 'Ljungbyhed',
        country: 'Sweden',
        iataCode: 'N',
        icaoCode: 'ESTL',
        latitude: '56,08280182',
        longitude: '13,21249962',
        altitude: '140',
        timezone: 'N',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Daugavpils Intrenational Airport',
        city: 'Daugavpils',
        country: 'Latvia',
        iataCode: 'DGP',
        icaoCode: 'EVDA',
        latitude: '55,94472122',
        longitude: '26,66500092',
        altitude: '398',
        timezone: 'N',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Jēkabpils Air Base',
        city: 'Jēkabpils',
        country: 'Latvia',
        iataCode: 'N',
        icaoCode: 'EVKA',
        latitude: '56,534698',
        longitude: '25,8925',
        altitude: '289',
        timezone: 'N',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Jūrmala Airport',
        city: 'Tukums',
        country: 'Latvia',
        iataCode: 'N',
        icaoCode: 'EVTA',
        latitude: '56,9422',
        longitude: '23,2239',
        altitude: '220',
        timezone: 'N',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Kėdainiai Air Base',
        city: 'Kėdainiai',
        country: 'Lithuania',
        iataCode: 'N',
        icaoCode: 'EYKD',
        latitude: '55,31169891',
        longitude: '23,95639992',
        altitude: '171',
        timezone: 'N',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Lime Acres Finsch Mine Airport',
        city: 'Lime Acres',
        country: 'South Africa',
        iataCode: 'LMR',
        icaoCode: 'FALC',
        latitude: '-28,36009979',
        longitude: '23,43910027',
        altitude: '4900',
        timezone: 'N',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Sua Pan Airport',
        city: 'Sowa',
        country: 'Botswana',
        iataCode: 'SXN',
        icaoCode: 'FBSN',
        latitude: '-20,5534',
        longitude: '26,115801',
        altitude: '2985',
        timezone: 'N',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Lusaka City Airport',
        city: 'Lusaka',
        country: 'Zambia',
        iataCode: 'N',
        icaoCode: 'FLLC',
        latitude: '-15,41380024',
        longitude: '28,33069992',
        altitude: '4200',
        timezone: 'N',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Sumbe Airport',
        city: 'Sumbe',
        country: 'Angola',
        iataCode: 'NDD',
        icaoCode: 'FNSU',
        latitude: '-11,16790009',
        longitude: '13,84749985',
        altitude: '36',
        timezone: 'N',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Mangochi Airport',
        city: 'Mangochi',
        country: 'Malawi',
        iataCode: 'MAI',
        icaoCode: 'FWMG',
        latitude: '-14,4829998',
        longitude: '35,26699829',
        altitude: '1580',
        timezone: 'N',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Arandis Airport',
        city: 'Arandis',
        country: 'Namibia',
        iataCode: 'ADI',
        icaoCode: 'FYAR',
        latitude: '-22,46220016',
        longitude: '14,97999954',
        altitude: '1905',
        timezone: 'N',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Mariental Airport',
        city: 'Mariental',
        country: 'Namibia',
        iataCode: 'N',
        icaoCode: 'FYML',
        latitude: '-24,60540009',
        longitude: '17,92539978',
        altitude: '3650',
        timezone: 'N',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Almaza Air Force Base',
        city: 'Cairo',
        country: 'Egypt',
        iataCode: 'N',
        icaoCode: 'HEAZ',
        latitude: '30,09180069',
        longitude: '31,36000061',
        altitude: '300',
        timezone: 'N',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Beni Suef Air Base',
        city: 'Beni Suef',
        country: 'Egypt',
        iataCode: 'N',
        icaoCode: 'HEBS',
        latitude: '29,20829964',
        longitude: '31,01659966',
        altitude: '108',
        timezone: 'N',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Jiyanklis Air Base',
        city: 'Jiyanklis',
        country: 'Egypt',
        iataCode: 'N',
        icaoCode: 'HEGS',
        latitude: '30,81979942',
        longitude: '30,19120026',
        altitude: '49',
        timezone: 'N',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Merowe New Airport',
        city: 'Merowe',
        country: 'Sudan',
        iataCode: 'MWE',
        icaoCode: 'HSMN',
        latitude: '18,44333333',
        longitude: '31,84333333',
        altitude: '897',
        timezone: 'N',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'St Louis Regional Airport',
        city: 'Alton/St Louis',
        country: 'United States',
        iataCode: 'ALN',
        icaoCode: 'KALN',
        latitude: '38,89030075',
        longitude: '-90,04599762',
        altitude: '544',
        timezone: 'N',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Chandler Field',
        city: 'Alexandria',
        country: 'United States',
        iataCode: 'AXN',
        icaoCode: 'KAXN',
        latitude: '45,86629868',
        longitude: '-95,3946991',
        altitude: '1425',
        timezone: 'N',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Columbus Municipal Airport',
        city: 'Columbus',
        country: 'United States',
        iataCode: 'CLU',
        icaoCode: 'KBAK',
        latitude: '39,26190186',
        longitude: '-85,89630127',
        altitude: '656',
        timezone: 'N',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Curtis Field',
        city: 'Brady',
        country: 'United States',
        iataCode: 'BBD',
        icaoCode: 'KBBD',
        latitude: '31,17930031',
        longitude: '-99,32389832',
        altitude: '1827',
        timezone: 'N',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Eastern Sierra Regional Airport',
        city: 'Bishop',
        country: 'United States',
        iataCode: 'BIH',
        icaoCode: 'KBIH',
        latitude: '37,37310028',
        longitude: '-118,3639984',
        altitude: '4124',
        timezone: 'N',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Baker City Municipal Airport',
        city: 'Baker City',
        country: 'United States',
        iataCode: 'BKE',
        icaoCode: 'KBKE',
        latitude: '44,83729935',
        longitude: '-117,8089981',
        altitude: '3373',
        timezone: 'N',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Miley Memorial Field',
        city: 'Big Piney',
        country: 'United States',
        iataCode: 'BPI',
        icaoCode: 'KBPI',
        latitude: '42,58509827',
        longitude: '-110,1110001',
        altitude: '6990',
        timezone: 'N',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Ozark Regional Airport',
        city: 'Mountain Home',
        country: 'United States',
        iataCode: 'WMH',
        icaoCode: 'KBPK',
        latitude: '36,3689003',
        longitude: '-92,47049713',
        altitude: '928',
        timezone: 'N',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'W K Kellogg Airport',
        city: 'Battle Creek',
        country: 'United States',
        iataCode: 'BTL',
        icaoCode: 'KBTL',
        latitude: '42,30730057',
        longitude: '-85,25150299',
        altitude: '952',
        timezone: 'N',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Burley Municipal Airport',
        city: 'Burley',
        country: 'United States',
        iataCode: 'BYI',
        icaoCode: 'KBYI',
        latitude: '42,54259872',
        longitude: '-113,7720032',
        altitude: '4150',
        timezone: 'N',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Northeast Iowa Regional Airport',
        city: 'Charles City',
        country: 'United States',
        iataCode: 'CCY',
        icaoCode: 'KCCY',
        latitude: '43,07260132',
        longitude: '-92,6108017',
        altitude: '1125',
        timezone: 'N',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Chanute Martin Johnson Airport',
        city: 'Chanute',
        country: 'United States',
        iataCode: 'CNU',
        icaoCode: 'KCNU',
        latitude: '37,66880035',
        longitude: '-95,48509979',
        altitude: '1002',
        timezone: 'N',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Jacksonville Executive at Craig Airport',
        city: 'Jacksonville',
        country: 'United States',
        iataCode: 'CRG',
        icaoCode: 'KCRG',
        latitude: '30,3362999',
        longitude: '-81,51439667',
        altitude: '41',
        timezone: 'N',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Crossville Memorial Whitson Field',
        city: 'Crossville',
        country: 'United States',
        iataCode: 'CSV',
        icaoCode: 'KCSV',
        latitude: '35,95130157',
        longitude: '-85,08499908',
        altitude: '1881',
        timezone: 'N',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Davison Army Air Field',
        city: 'Fort Belvoir',
        country: 'United States',
        iataCode: 'DAA',
        icaoCode: 'KDAA',
        latitude: '38,71500015',
        longitude: '-77,18099976',
        altitude: '73',
        timezone: 'N',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Barstow Daggett Airport',
        city: 'Daggett',
        country: 'United States',
        iataCode: 'DAG',
        icaoCode: 'KDAG',
        latitude: '34,85369873',
        longitude: '-116,7870026',
        altitude: '1930',
        timezone: 'N',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Deming Municipal Airport',
        city: 'Deming',
        country: 'United States',
        iataCode: 'DMN',
        icaoCode: 'KDMN',
        latitude: '32,26229858',
        longitude: '-107,7210007',
        altitude: '4314',
        timezone: 'N',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Desert Rock Airport',
        city: 'Mercury',
        country: 'United States',
        iataCode: 'DRA',
        icaoCode: 'KDRA',
        latitude: '36,6194',
        longitude: '-116,032997',
        altitude: '3314',
        timezone: 'N',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Needles Airport',
        city: 'Needles',
        country: 'United States',
        iataCode: 'EED',
        icaoCode: 'KEED',
        latitude: '34,7663002',
        longitude: '-114,6230011',
        altitude: '983',
        timezone: 'N',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Duke Field',
        city: 'Crestview',
        country: 'United States',
        iataCode: 'EGI',
        icaoCode: 'KEGI',
        latitude: '30,65040016',
        longitude: '-86,52290344',
        altitude: '191',
        timezone: 'N',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Murray Field',
        city: 'Eureka',
        country: 'United States',
        iataCode: 'EKA',
        icaoCode: 'KEKA',
        latitude: '40,80339813',
        longitude: '-124,112999',
        altitude: '7',
        timezone: 'N',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'San Marcos Municipal Airport',
        city: 'San Marcos',
        country: 'United States',
        iataCode: 'HYI',
        icaoCode: 'KHYI',
        latitude: '29,8927002',
        longitude: '-97,86299896',
        altitude: '597',
        timezone: 'N',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Sawyer County Airport',
        city: 'Hayward',
        country: 'United States',
        iataCode: 'HYR',
        icaoCode: 'KHYR',
        latitude: '46,02519989',
        longitude: '-91,44429779',
        altitude: '1216',
        timezone: 'N',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Kimble County Airport',
        city: 'Junction',
        country: 'United States',
        iataCode: 'JCT',
        icaoCode: 'KJCT',
        latitude: '30,51129913',
        longitude: '-99,7634964',
        altitude: '1749',
        timezone: 'N',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Monticello Municipal Ellis Field',
        city: 'Monticello',
        country: 'United States',
        iataCode: 'LLQ',
        icaoCode: 'KLLQ',
        latitude: '33,6385994',
        longitude: '-91,75099945',
        altitude: '270',
        timezone: 'N',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Derby Field',
        city: 'Lovelock',
        country: 'United States',
        iataCode: 'LOL',
        icaoCode: 'KLOL',
        latitude: '40,06639862',
        longitude: '-118,5650024',
        altitude: '3904',
        timezone: 'N',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Mobridge Municipal Airport',
        city: 'Mobridge',
        country: 'United States',
        iataCode: 'MBG',
        icaoCode: 'KMBG',
        latitude: '45,54650116',
        longitude: '-100,4079971',
        altitude: '1716',
        timezone: 'N',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Mc Comb/Pike County Airport/John E Lewis Field',
        city: 'Mc Comb',
        country: 'United States',
        iataCode: 'MCB',
        icaoCode: 'KMCB',
        latitude: '31,17849922',
        longitude: '-90,47190094',
        altitude: '413',
        timezone: 'N',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Southern Illinois Airport',
        city: 'Carbondale/Murphysboro',
        country: 'United States',
        iataCode: 'MDH',
        icaoCode: 'KMDH',
        latitude: '37,77809906',
        longitude: '-89,2519989',
        altitude: '411',
        timezone: 'N',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Mc Entire Joint National Guard Base',
        city: 'Eastover',
        country: 'United States',
        iataCode: 'MMT',
        icaoCode: 'KMMT',
        latitude: '33,92079926',
        longitude: '-80,80130005',
        altitude: '254',
        timezone: 'N',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Brunswick Executive Airport',
        city: 'Brunswick',
        country: 'United States',
        iataCode: 'NHZ',
        icaoCode: 'KNHZ',
        latitude: '43,89220047',
        longitude: '-69,93859863',
        altitude: '72',
        timezone: 'N',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Naval Station Mayport (Admiral David L. Mcdonald Field)',
        city: 'Mayport',
        country: 'United States',
        iataCode: 'NRB',
        icaoCode: 'KNRB',
        latitude: '30,39109993',
        longitude: '-81,42469788',
        altitude: '15',
        timezone: 'N',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Orangeburg Municipal Airport',
        city: 'Orangeburg',
        country: 'United States',
        iataCode: 'OGB',
        icaoCode: 'KOGB',
        latitude: '33,45679855',
        longitude: '-80,85949707',
        altitude: '195',
        timezone: 'N',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Columbus Municipal Airport',
        city: 'Columbus',
        country: 'United States',
        iataCode: 'OLU',
        icaoCode: 'KOLU',
        latitude: '41,44800186',
        longitude: '-97,34259796',
        altitude: '1447',
        timezone: 'N',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Ottumwa Regional Airport',
        city: 'Ottumwa',
        country: 'United States',
        iataCode: 'OTM',
        icaoCode: 'KOTM',
        latitude: '41,10660172',
        longitude: '-92,44789886',
        altitude: '845',
        timezone: 'N',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Cairns AAF (Fort Rucker) Air Field',
        city: 'Fort Rucker/Ozark',
        country: 'United States',
        iataCode: 'OZR',
        icaoCode: 'KOZR',
        latitude: '31,27569962',
        longitude: '-85,71340179',
        altitude: '301',
        timezone: 'N',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Ralph Wenz Field',
        city: 'Pinedale',
        country: 'United States',
        iataCode: 'PWY',
        icaoCode: 'KPNA',
        latitude: '42,79550171',
        longitude: '-109,8069992',
        altitude: '7102',
        timezone: 'N',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Dutchess County Airport',
        city: 'Poughkeepsie',
        country: 'United States',
        iataCode: 'POU',
        icaoCode: 'KPOU',
        latitude: '41,62659836',
        longitude: '-73,88420105',
        altitude: '165',
        timezone: 'N',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'New Richmond Regional Airport',
        city: 'New Richmond',
        country: 'United States',
        iataCode: 'RNH',
        icaoCode: 'KRNH',
        latitude: '45,14830017',
        longitude: '-92,5381012',
        altitude: '998',
        timezone: 'N',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Russell Municipal Airport',
        city: 'Russell',
        country: 'United States',
        iataCode: 'RSL',
        icaoCode: 'KRSL',
        latitude: '38,87210083',
        longitude: '-98,8117981',
        altitude: '1862',
        timezone: 'N',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Redwood Falls Municipal Airport',
        city: 'Redwood Falls',
        country: 'United States',
        iataCode: 'RWF',
        icaoCode: 'KRWF',
        latitude: '44,54719925',
        longitude: '-95,08229828',
        altitude: '1024',
        timezone: 'N',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Salinas Municipal Airport',
        city: 'Salinas',
        country: 'United States',
        iataCode: 'SNS',
        icaoCode: 'KSNS',
        latitude: '36,66279984',
        longitude: '-121,6060028',
        altitude: '85',
        timezone: 'N',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Sonora Municipal Airport',
        city: 'Sonora',
        country: 'United States',
        iataCode: 'SOA',
        icaoCode: 'KSOA',
        latitude: '30,58569908',
        longitude: '-100,6490021',
        altitude: '2140',
        timezone: 'N',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Saline County Regional Airport',
        city: 'Benton',
        country: 'United States',
        iataCode: 'SUZ',
        icaoCode: 'KSUZ',
        latitude: '34,59059906',
        longitude: '-92,47940063',
        altitude: '390',
        timezone: 'N',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Tonopah Airport',
        city: 'Tonopah',
        country: 'United States',
        iataCode: 'TPH',
        icaoCode: 'KTPH',
        latitude: '38,06019974',
        longitude: '-117,086998',
        altitude: '5430',
        timezone: 'N',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Aurora State Airport',
        city: 'Aurora',
        country: 'United States',
        iataCode: 'UAO',
        icaoCode: 'KUAO',
        latitude: '45,24710083',
        longitude: '-122,7699966',
        altitude: '200',
        timezone: 'N',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Ukiah Municipal Airport',
        city: 'Ukiah',
        country: 'United States',
        iataCode: 'UKI',
        icaoCode: 'KUKI',
        latitude: '39,12599945',
        longitude: '-123,2009964',
        altitude: '614',
        timezone: 'N',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'University Oxford Airport',
        city: 'Oxford',
        country: 'United States',
        iataCode: 'UOX',
        icaoCode: 'KUOX',
        latitude: '34,38430023',
        longitude: '-89,53679657',
        altitude: '452',
        timezone: 'N',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Huntsville Regional Airport',
        city: 'Huntsville',
        country: 'United States',
        iataCode: 'HTV',
        icaoCode: 'KUTS',
        latitude: '30,74690056',
        longitude: '-95,58719635',
        altitude: '363',
        timezone: 'N',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Miller Field',
        city: 'Valentine',
        country: 'United States',
        iataCode: 'VTN',
        icaoCode: 'KVTN',
        latitude: '42,85779953',
        longitude: '-100,5479965',
        altitude: '2596',
        timezone: 'N',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Winnemucca Municipal Airport',
        city: 'Winnemucca',
        country: 'United States',
        iataCode: 'WMC',
        icaoCode: 'KWMC',
        latitude: '40,89659882',
        longitude: '-117,8059998',
        altitude: '4308',
        timezone: 'N',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'West Woodward Airport',
        city: 'Woodward',
        country: 'United States',
        iataCode: 'WWR',
        icaoCode: 'KWWR',
        latitude: '36,438',
        longitude: '-99,5226667',
        altitude: '2189',
        timezone: 'N',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Cape Canaveral AFS Skid Strip',
        city: 'Cocoa Beach',
        country: 'United States',
        iataCode: 'XMR',
        icaoCode: 'KXMR',
        latitude: '28,46759987',
        longitude: '-80,56659698',
        altitude: '10',
        timezone: 'N',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Homey (Area 51) Airport',
        city: 'Groom Lake',
        country: 'United States',
        iataCode: 'N',
        icaoCode: 'KXTA',
        latitude: '37,23500061',
        longitude: '-115,810997',
        altitude: '4462',
        timezone: 'N',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Zanesville Municipal Airport',
        city: 'Zanesville',
        country: 'United States',
        iataCode: 'ZZV',
        icaoCode: 'KZZV',
        latitude: '39,94440079',
        longitude: '-81,89209747',
        altitude: '900',
        timezone: 'N',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Gjadër Air Base',
        city: 'Gjadër',
        country: 'Albania',
        iataCode: 'N',
        icaoCode: 'LAGJ',
        latitude: '41,895199',
        longitude: '19,5987',
        altitude: '23',
        timezone: 'N',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Kukës Airport',
        city: 'Kukës',
        country: 'Albania',
        iataCode: 'N',
        icaoCode: 'LAKU',
        latitude: '42,03369904',
        longitude: '20,41589928',
        altitude: '1120',
        timezone: 'N',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Kuçovë Air Base',
        city: 'Kuçovë',
        country: 'Albania',
        iataCode: 'N',
        icaoCode: 'LAKV',
        latitude: '40,77190018',
        longitude: '19,90189934',
        altitude: '135',
        timezone: 'N',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Vlorë Air Base',
        city: 'Vlorë',
        country: 'Albania',
        iataCode: 'N',
        icaoCode: 'LAVL',
        latitude: '40,47610092',
        longitude: '19,4741993',
        altitude: '3',
        timezone: 'N',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Uzundzhovo Air Base',
        city: 'Haskovo',
        country: 'Bulgaria',
        iataCode: 'N',
        icaoCode: 'LBHS',
        latitude: '41,97639847',
        longitude: '25,58979988',
        altitude: '160',
        timezone: 'N',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Gabrovnitsa Air Base',
        city: 'Gabrovnitsa',
        country: 'Bulgaria',
        iataCode: 'N',
        icaoCode: 'LBMG',
        latitude: '43,54430008',
        longitude: '23,27249908',
        altitude: '626',
        timezone: 'N',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Graf Ignatievo Air Base',
        city: 'Graf Ignatievo',
        country: 'Bulgaria',
        iataCode: 'N',
        icaoCode: 'LBPG',
        latitude: '42,29040146',
        longitude: '24,7140007',
        altitude: '190',
        timezone: 'N',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Dolna Mitropoliya Air Base',
        city: 'Dolna Mitropoliya',
        country: 'Bulgaria',
        iataCode: 'N',
        icaoCode: 'LBPL',
        latitude: '43,451401',
        longitude: '24,5028',
        altitude: '330',
        timezone: 'N',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Balchik Air Base',
        city: 'Balchik',
        country: 'Bulgaria',
        iataCode: 'N',
        icaoCode: 'LBWB',
        latitude: '43,42380142',
        longitude: '28,18129921',
        altitude: '660',
        timezone: 'N',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Cuatro Vientos Airport',
        city: 'Madrid',
        country: 'Spain',
        iataCode: 'ECV',
        icaoCode: 'LECU',
        latitude: '40,37070084',
        longitude: '-3,785140038',
        altitude: '2269',
        timezone: 'N',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Castellón-Costa Azahar Airport',
        city: 'Castellón de la Plana',
        country: 'Spain',
        iataCode: 'CDT',
        icaoCode: 'LEDS',
        latitude: '40,213889',
        longitude: '0,073333',
        altitude: '1145',
        timezone: 'N',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Teruel Airport',
        city: 'Teruel',
        country: 'Spain',
        iataCode: 'TEV',
        icaoCode: 'LETL',
        latitude: '40,403',
        longitude: '-1,2183',
        altitude: '3380',
        timezone: 'N',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Blois-Le Breuil Airport',
        city: 'Blois/Le Breuil',
        country: 'France',
        iataCode: 'N',
        icaoCode: 'LFOQ',
        latitude: '47,678502',
        longitude: '1,20884',
        altitude: '398',
        timezone: 'N',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Trento-Mattarello Airport',
        city: 'Trento (TN)',
        country: 'Italy',
        iataCode: 'TN0',
        icaoCode: 'LIDT',
        latitude: '46,0214',
        longitude: '11,1242',
        altitude: '610',
        timezone: 'N',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Alessandria Airport',
        city: 'Alessandria',
        country: 'Italy',
        iataCode: 'N',
        icaoCode: 'LILA',
        latitude: '44,925201',
        longitude: '8,62513',
        altitude: '299',
        timezone: 'N',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Biella-Cerrione Airport',
        city: 'Biella (BI)',
        country: 'Italy',
        iataCode: 'N',
        icaoCode: 'LILE',
        latitude: '45,4953',
        longitude: '8,10278',
        altitude: '920',
        timezone: 'N',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Vercelli Airport',
        city: 'Vercelli',
        country: 'Italy',
        iataCode: 'N',
        icaoCode: 'LILI',
        latitude: '45,310233',
        longitude: '8,417935',
        altitude: '417',
        timezone: 'N',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Casale Monferrato Airport',
        city: 'Casale Monferrato',
        country: 'Italy',
        iataCode: 'N',
        icaoCode: 'LILM',
        latitude: '45,111198',
        longitude: '8,45603',
        altitude: '377',
        timezone: 'N',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Varese-Venegono Airport',
        city: 'Varese',
        country: 'Italy',
        iataCode: 'N',
        icaoCode: 'LILN',
        latitude: '45,742199',
        longitude: '8,88823',
        altitude: '1050',
        timezone: 'N',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Novi Ligure Airport',
        city: 'Novi Ligure',
        country: 'Italy',
        iataCode: 'N',
        icaoCode: 'LIMR',
        latitude: '44,779999',
        longitude: '8,78639',
        altitude: '607',
        timezone: 'N',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Sarzana-Luni Air Base',
        city: 'Sarzana (SP)',
        country: 'Italy',
        iataCode: 'QLP',
        icaoCode: 'LIQW',
        latitude: '44,088001',
        longitude: '9,98795',
        altitude: '45',
        timezone: 'N',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Câmpia Turzii Air Base',
        city: 'Câmpia Turzii',
        country: 'Romania',
        iataCode: 'N',
        icaoCode: 'LRCT',
        latitude: '46,50230026',
        longitude: '23,8859005',
        altitude: '1083',
        timezone: 'N',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Mărculeşti International Airport',
        city: 'Mărculeşti',
        country: 'Moldova',
        iataCode: 'N',
        icaoCode: 'LUBM',
        latitude: '47,86270142',
        longitude: '28,21279907',
        altitude: '312',
        timezone: 'N',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Cahul International Airport',
        city: 'Cahul',
        country: 'Moldova',
        iataCode: 'N',
        icaoCode: 'LUCH',
        latitude: '45,84379959',
        longitude: '28,26370049',
        altitude: '652',
        timezone: 'N',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Tiraspol Airport',
        city: 'Tiraspol',
        country: 'Moldova',
        iataCode: 'N',
        icaoCode: 'LUTR',
        latitude: '46,86809921',
        longitude: '29,59059906',
        altitude: '141',
        timezone: 'N',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Batajnica Air Base',
        city: 'Batajnica',
        country: 'Serbia',
        iataCode: 'BJY',
        icaoCode: 'LYBT',
        latitude: '44,935299',
        longitude: '20,2575',
        altitude: '265',
        timezone: 'N',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Rubelsanto Airport',
        city: 'Rubelsanto',
        country: 'Guatemala',
        iataCode: 'RUV',
        icaoCode: 'MGRB',
        latitude: '15,99199963',
        longitude: '-90,44529724',
        altitude: '426',
        timezone: 'N',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Coronel Enrique Soto Cano Air Base',
        city: 'Comayagua',
        country: 'Honduras',
        iataCode: 'XPL',
        icaoCode: 'MHSC',
        latitude: '14,3824',
        longitude: '-87,621201',
        altitude: '2061',
        timezone: 'N',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Upala Airport',
        city: 'Upala',
        country: 'Costa Rica',
        iataCode: 'UPL',
        icaoCode: 'MRUP',
        latitude: '10,8922',
        longitude: '-85,016197',
        altitude: '184',
        timezone: 'N',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'San Nicolas De Bari Airport',
        city: 'San Nicolás',
        country: 'Cuba',
        iataCode: 'QSN',
        icaoCode: 'MUNB',
        latitude: '22,7560997',
        longitude: '-81,92089844',
        altitude: '49',
        timezone: 'N',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'San Julian Air Base',
        city: 'Pinar Del Rio',
        country: 'Cuba',
        iataCode: 'SNJ',
        icaoCode: 'MUSJ',
        latitude: '22,09530067',
        longitude: '-84,15200043',
        altitude: '98',
        timezone: 'N',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Dawadmi Domestic Airport',
        city: '',
        country: 'Saudi Arabia',
        iataCode: 'N',
        icaoCode: 'OEDM',
        latitude: '24,4499',
        longitude: '44,121201',
        altitude: '3026',
        timezone: 'N',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'King Khaled Air Base',
        city: '',
        country: 'Saudi Arabia',
        iataCode: 'KMX',
        icaoCode: 'OEKM',
        latitude: '18,29730034',
        longitude: '42,80350113',
        altitude: '6778',
        timezone: 'N',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Riyadh Air Base',
        city: 'Riyadh',
        country: 'Saudi Arabia',
        iataCode: 'XXN',
        icaoCode: 'OERY',
        latitude: '24,70980072',
        longitude: '46,72520065',
        altitude: '2082',
        timezone: 'N',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Jam Airport',
        city: 'Kangan',
        country: 'Iran',
        iataCode: 'KNR',
        icaoCode: 'OIBJ',
        latitude: '27,82049942',
        longitude: '52,35219955',
        altitude: '2173',
        timezone: 'N',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Hamadan Air Base',
        city: 'Hamadan',
        country: 'Iran',
        iataCode: 'N',
        icaoCode: 'OIHS',
        latitude: '35,21160126',
        longitude: '48,65340042',
        altitude: '5609',
        timezone: 'N',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Payam International Airport',
        city: 'Karaj',
        country: 'Iran',
        iataCode: 'PYK',
        icaoCode: 'OIIP',
        latitude: '35,77610016',
        longitude: '50,8266983',
        altitude: '4170',
        timezone: 'N',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Ahmed Al Jaber Air Base',
        city: 'Ahmed Al Jaber AB',
        country: 'Kuwait',
        iataCode: 'XIJ',
        icaoCode: 'OKAJ',
        latitude: '28,93479919',
        longitude: '47,79190063',
        altitude: '409',
        timezone: 'N',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Rayak Air Base',
        city: 'Rayak',
        country: 'Lebanon',
        iataCode: 'N',
        icaoCode: 'OLRA',
        latitude: '33,85079956',
        longitude: '35,98770142',
        altitude: '3018',
        timezone: 'N',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Mirpur Khas Air Base',
        city: 'Mirpur Khas',
        country: 'Pakistan',
        iataCode: 'MPD',
        icaoCode: 'OPMK',
        latitude: '25,68250084',
        longitude: '69,07279968',
        altitude: '60',
        timezone: 'N',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Minhas Air Base',
        city: 'Kamra',
        country: 'Pakistan',
        iataCode: 'ATG',
        icaoCode: 'OPMS',
        latitude: '33,86909866',
        longitude: '72,40090179',
        altitude: '1023',
        timezone: 'N',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Rafiqui Air Base',
        city: 'Shorkot',
        country: 'Pakistan',
        iataCode: 'N',
        icaoCode: 'OPRQ',
        latitude: '30,75810051',
        longitude: '72,28250122',
        altitude: '492',
        timezone: 'N',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Faisal Air Base',
        city: 'Karachi',
        country: 'Pakistan',
        iataCode: 'N',
        icaoCode: 'OPSF',
        latitude: '24,87420082',
        longitude: '67,11849976',
        altitude: '29',
        timezone: 'N',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Mushaf Air Base',
        city: 'Sargodha',
        country: 'Pakistan',
        iataCode: 'SGI',
        icaoCode: 'OPSR',
        latitude: '32,04859924',
        longitude: '72,66500092',
        altitude: '614',
        timezone: 'N',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Qayyarah West Airport',
        city: 'Qayyarah',
        country: 'Iraq',
        iataCode: 'RQW',
        icaoCode: 'ORQW',
        latitude: '35,76720047',
        longitude: '43,12509918',
        altitude: '749',
        timezone: 'N',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Al Sahra Army Air Field',
        city: 'Tikrit',
        country: 'Iraq',
        iataCode: 'N',
        icaoCode: 'ORSH',
        latitude: '34,6731987',
        longitude: '43,54299927',
        altitude: '451',
        timezone: 'N',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Tall Afar Army Air Field',
        city: 'Tall Afar',
        country: 'Iraq',
        iataCode: 'N',
        icaoCode: 'ORTF',
        latitude: '36,28310013',
        longitude: '42,40299988',
        altitude: '996',
        timezone: 'N',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Al Taji Army Air Field',
        city: 'Taji',
        country: 'Iraq',
        iataCode: 'N',
        icaoCode: 'ORTI',
        latitude: '33,52389908',
        longitude: '44,25669861',
        altitude: '121',
        timezone: 'N',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Ubaydah Bin Al Jarrah Airport',
        city: 'Al Cut',
        country: 'Iraq',
        iataCode: 'N',
        icaoCode: 'ORUB',
        latitude: '32,48199844',
        longitude: '45,75709915',
        altitude: '68',
        timezone: 'N',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Nenana Municipal Airport',
        city: 'Nenana',
        country: 'United States',
        iataCode: 'ENN',
        icaoCode: 'PANN',
        latitude: '64,54730225',
        longitude: '-149,0740051',
        altitude: '362',
        timezone: 'N',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Wasilla Airport',
        city: 'Wasilla',
        country: 'United States',
        iataCode: 'WWA',
        icaoCode: 'PAWS',
        latitude: '61,57170105',
        longitude: '-149,5399933',
        altitude: '354',
        timezone: 'N',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Gangshan Air Force Base',
        city: 'Gangshan',
        country: 'Taiwan',
        iataCode: 'N',
        icaoCode: 'RCAY',
        latitude: '22,78249931',
        longitude: '120,2630005',
        altitude: '34',
        timezone: 'N',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Asahikawa Airport',
        city: '',
        country: 'Japan',
        iataCode: 'N',
        icaoCode: 'RJCA',
        latitude: '43,79520035',
        longitude: '142,3630066',
        altitude: '377',
        timezone: 'N',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Utsunomiya Airport',
        city: '',
        country: 'Japan',
        iataCode: 'QUT',
        icaoCode: 'RJTU',
        latitude: '36,51449966',
        longitude: '139,8710022',
        altitude: '334',
        timezone: 'N',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Jungwon Air Base',
        city: '',
        country: 'South Korea',
        iataCode: 'N',
        icaoCode: 'RKTI',
        latitude: '37,02999878',
        longitude: '127,8850021',
        altitude: '281',
        timezone: 'N',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Ie Jima Airport',
        city: 'Ie',
        country: 'Japan',
        iataCode: 'IEJ',
        icaoCode: 'RORE',
        latitude: '26,72200012',
        longitude: '127,7850037',
        altitude: '246',
        timezone: 'N',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Allah Valley Airport',
        city: 'Surallah',
        country: 'Philippines',
        iataCode: 'AAV',
        icaoCode: 'RPMA',
        latitude: '6,366819859',
        longitude: '124,7509995',
        altitude: '659',
        timezone: 'N',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Bislig Airport',
        city: '',
        country: 'Philippines',
        iataCode: 'BPH',
        icaoCode: 'RPMF',
        latitude: '8,195949554',
        longitude: '126,3219986',
        altitude: '12',
        timezone: 'N',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Mati National Airport',
        city: '',
        country: 'Philippines',
        iataCode: 'MXI',
        icaoCode: 'RPMQ',
        latitude: '6,949369907',
        longitude: '126,2730026',
        altitude: '156',
        timezone: 'N',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Presidente Néstor Kirchner Regionsl Airport',
        city: 'Villa Maria',
        country: 'Argentina',
        iataCode: 'VMR',
        icaoCode: 'SAOV',
        latitude: '-32,3201',
        longitude: '-63,22663',
        altitude: '670',
        timezone: 'N',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Chafei Amsei Airport',
        city: 'Barretos',
        country: 'Brazil',
        iataCode: 'BAT',
        icaoCode: 'SBBT',
        latitude: '-20,58449936',
        longitude: '-48,59410095',
        altitude: '1898',
        timezone: 'N',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Base de Aviação de Taubaté Airport',
        city: 'Taubaté',
        country: 'Brazil',
        iataCode: 'QHP',
        icaoCode: 'SBTA',
        latitude: '-23,0401001',
        longitude: '-45,51599884',
        altitude: '1908',
        timezone: 'N',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Barriles Airport',
        city: 'Tocopilla',
        country: 'Chile',
        iataCode: 'TOQ',
        icaoCode: 'SCBE',
        latitude: '-22,14109993',
        longitude: '-70,06289673',
        altitude: '3475',
        timezone: 'N',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Cochrane Airport',
        city: 'Cochrane',
        country: 'Chile',
        iataCode: 'LGR',
        icaoCode: 'SCHR',
        latitude: '-47,24380112',
        longitude: '-72,58840179',
        altitude: '643',
        timezone: 'N',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Chañaral Airport',
        city: 'Chañaral',
        country: 'Chile',
        iataCode: 'CNR',
        icaoCode: 'SCRA',
        latitude: '-26,33250046',
        longitude: '-70,6072998',
        altitude: '97',
        timezone: 'N',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Panguilemo Airport',
        city: 'Talca',
        country: 'Chile',
        iataCode: 'TLX',
        icaoCode: 'SCTL',
        latitude: '-35,37779999',
        longitude: '-71,60169983',
        altitude: '371',
        timezone: 'N',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Victoria Airport',
        city: 'Victoria',
        country: 'Chile',
        iataCode: 'ZIC',
        icaoCode: 'SCTO',
        latitude: '-38,24560165',
        longitude: '-72,34860229',
        altitude: '1148',
        timezone: 'N',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Las Breas Airport',
        city: 'Taltal',
        country: 'Chile',
        iataCode: 'TTC',
        icaoCode: 'SCTT',
        latitude: '-25,56430054',
        longitude: '-70,37590027',
        altitude: '2580',
        timezone: 'N',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Gomez Nino Apiay Air Base',
        city: 'Apiay',
        country: 'Colombia',
        iataCode: 'API',
        icaoCode: 'SKAP',
        latitude: '4,07607',
        longitude: '-73,5627',
        altitude: '1207',
        timezone: 'N',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Coveñas Airport',
        city: 'Coveñas',
        country: 'Colombia',
        iataCode: 'CVE',
        icaoCode: 'SKCV',
        latitude: '9,40092',
        longitude: '-75,6913',
        altitude: '31',
        timezone: 'N',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'German Olano Air Base',
        city: 'La Dorada',
        country: 'Colombia',
        iataCode: 'PAL',
        icaoCode: 'SKPQ',
        latitude: '5,48361',
        longitude: '-74,6574',
        altitude: '566',
        timezone: 'N',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Paz De Ariporo Airport',
        city: 'Paz De Ariporo',
        country: 'Colombia',
        iataCode: 'PZA',
        icaoCode: 'SKPZ',
        latitude: '5,87615',
        longitude: '-71,8866',
        altitude: '900',
        timezone: 'N',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Tunja Airport',
        city: 'Tunja',
        country: 'Colombia',
        iataCode: 'N',
        icaoCode: 'SKTJ',
        latitude: '5,54138',
        longitude: '-73,3445',
        altitude: '8940',
        timezone: 'N',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Tres Esquinas Air Base',
        city: 'Tres Esquinas',
        country: 'Colombia',
        iataCode: 'TQS',
        icaoCode: 'SKTQ',
        latitude: '0,7459',
        longitude: '-75,234',
        altitude: '585',
        timezone: 'N',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Marandúa Air Base',
        city: 'Marandúa',
        country: 'Colombia',
        iataCode: 'MQZ',
        icaoCode: 'SKUA',
        latitude: '5,52448',
        longitude: '-68,6856',
        altitude: '285',
        timezone: 'N',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Juan Simons Vela Airport',
        city: 'Rioja',
        country: 'Peru',
        iataCode: 'RIJ',
        icaoCode: 'SPJA',
        latitude: '-6,067860126',
        longitude: '-77,16000366',
        altitude: '2707',
        timezone: 'N',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Shumba Airport',
        city: 'Jaén',
        country: 'Peru',
        iataCode: 'JAE',
        icaoCode: 'SPJE',
        latitude: '-5,59248',
        longitude: '-78,774002',
        altitude: '2477',
        timezone: 'N',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Metropolitano Airport',
        city: '',
        country: 'Venezuela',
        iataCode: 'N',
        icaoCode: 'SVMP',
        latitude: '10,13316917',
        longitude: '-66,78782654',
        altitude: '574',
        timezone: 'N',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Issyk-Kul International Airport',
        city: 'Tamchy',
        country: 'Kyrgyzstan',
        iataCode: 'IKU',
        icaoCode: 'UAFL',
        latitude: '42,58792',
        longitude: '76,713046',
        altitude: '5425',
        timezone: 'N',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Kant Air Base',
        city: 'Kant',
        country: 'Kyrgyzstan',
        iataCode: 'N',
        icaoCode: 'UAFW',
        latitude: '42,85319901',
        longitude: '74,84649658',
        altitude: '2549',
        timezone: 'N',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Ayaguz Airport',
        city: 'Ayaguz',
        country: 'Kazakhstan',
        iataCode: 'N',
        icaoCode: 'UASA',
        latitude: '47,91859818',
        longitude: '80,45279694',
        altitude: '2119',
        timezone: 'N',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Chelkar Airport',
        city: 'Chelkar',
        country: 'Kazakhstan',
        iataCode: 'N',
        icaoCode: 'UATR',
        latitude: '47,90499878',
        longitude: '59,61999893',
        altitude: '0',
        timezone: 'N',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Kyzyl-Syr Airport',
        city: 'Kyzyl-Syr',
        country: 'Russia',
        iataCode: 'N',
        icaoCode: 'UENK',
        latitude: '63,88499832',
        longitude: '122,7770004',
        altitude: '331',
        timezone: 'N',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Vilyuisk Airport',
        city: 'Vilyuisk',
        country: 'Russia',
        iataCode: 'VYI',
        icaoCode: 'UENW',
        latitude: '63,75666809',
        longitude: '121,6933365',
        altitude: '361',
        timezone: 'N',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Belaya Gora Airport',
        city: '',
        country: 'Russia',
        iataCode: 'N',
        icaoCode: 'UESG',
        latitude: '68,55660248',
        longitude: '146,2315063',
        altitude: '118',
        timezone: 'N',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Dzhermuk Airport',
        city: 'Dzhermuk',
        country: 'Armenia',
        iataCode: 'N',
        icaoCode: 'UGEJ',
        latitude: '39,82400131',
        longitude: '45,67399979',
        altitude: '0',
        timezone: 'N',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Kamenny Ruchey Naval Air Base',
        city: 'Sovetskaya Gavan',
        country: 'Russia',
        iataCode: 'N',
        icaoCode: 'UHKG',
        latitude: '49,23570251',
        longitude: '140,1931',
        altitude: '659',
        timezone: 'N',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Maygatka Airport.',
        city: 'Sovetskaya Gavan',
        country: 'Russia',
        iataCode: 'GVN',
        icaoCode: 'UHKM',
        latitude: '48,92699814',
        longitude: '140,0339966',
        altitude: '768',
        timezone: 'N',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Omsukchan Airport',
        city: 'Omsukchan',
        country: 'Russia',
        iataCode: 'N',
        icaoCode: 'UHMF',
        latitude: '62,45700073',
        longitude: '155,7449951',
        altitude: '1732',
        timezone: 'N',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Chaybukha Airport',
        city: 'Chaybukha',
        country: 'Russia',
        iataCode: 'N',
        icaoCode: 'UHMG',
        latitude: '61,83499908',
        longitude: '160,5480042',
        altitude: '207',
        timezone: 'N',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Keperveem Airport',
        city: 'Keperveem',
        country: 'Russia',
        iataCode: 'N',
        icaoCode: 'UHMK',
        latitude: '67,84500122',
        longitude: '166,1399994',
        altitude: '623',
        timezone: 'N',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Magadan-13 Airport',
        city: 'Magadan',
        country: 'Russia',
        iataCode: 'N',
        icaoCode: 'UHMT',
        latitude: '59,62329865',
        longitude: '150,9219971',
        altitude: '164',
        timezone: 'N',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Nikolayevsk-na-Amure Airport',
        city: 'Nikolayevsk-na-Amure Airport',
        country: 'Russia',
        iataCode: 'NLI',
        icaoCode: 'UHNN',
        latitude: '53,15499878',
        longitude: '140,6499939',
        altitude: '170',
        timezone: 'N',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Palana Airport',
        city: 'Palana',
        country: 'Russia',
        iataCode: 'N',
        icaoCode: 'UHPL',
        latitude: '59,08169937',
        longitude: '159,8880005',
        altitude: '112',
        timezone: 'N',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Zheleznogorsk Airport',
        city: 'Zheleznogorsk-Ilimsky',
        country: 'Russia',
        iataCode: 'N',
        icaoCode: 'UIBV',
        latitude: '56,48770142',
        longitude: '104,1060028',
        altitude: '1946',
        timezone: 'N',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Gvardeyskoe Air Base',
        city: 'Sarabuz',
        country: 'Ukraine',
        iataCode: 'N',
        icaoCode: 'UKFG',
        latitude: '45,11500168',
        longitude: '33,97700119',
        altitude: '721',
        timezone: 'N',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Saki Air Base',
        city: 'Saki',
        country: 'Ukraine',
        iataCode: 'N',
        icaoCode: 'UKFI',
        latitude: '45,0929985',
        longitude: '33,59500122',
        altitude: '36',
        timezone: 'N',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Dzhankoy Airport',
        city: 'Dzhankoy',
        country: 'Ukraine',
        iataCode: 'N',
        icaoCode: 'UKFY',
        latitude: '45,70090103',
        longitude: '34,41889954',
        altitude: '75',
        timezone: 'N',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Ozerne Air Base',
        city: 'Zhytomyr',
        country: 'Ukraine',
        iataCode: 'N',
        icaoCode: 'UKKO',
        latitude: '50,15829849',
        longitude: '28,73830032',
        altitude: '761',
        timezone: 'N',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Severomorsk-1 Naval Air Base',
        city: 'Severomorsk',
        country: 'Russia',
        iataCode: 'N',
        icaoCode: 'ULAK',
        latitude: '69,03166962',
        longitude: '33,41833115',
        altitude: '239',
        timezone: 'N',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Pushkin Airport',
        city: 'St. Petersburg',
        country: 'Russia',
        iataCode: 'N',
        icaoCode: 'ULLP',
        latitude: '59,68500137',
        longitude: '30,33830071',
        altitude: '230',
        timezone: 'N',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Siversky Air Base',
        city: 'Siversky',
        country: 'Russia',
        iataCode: 'N',
        icaoCode: 'ULLS',
        latitude: '59,3567009',
        longitude: '30,0366993',
        altitude: '341',
        timezone: 'N',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Staraya Russa Airport',
        city: 'Staraya Russa',
        country: 'Russia',
        iataCode: 'N',
        icaoCode: 'ULNR',
        latitude: '57,96139908',
        longitude: '31,38439941',
        altitude: '52',
        timezone: 'N',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Peski Airport',
        city: 'Petrozavodsk',
        country: 'Russia',
        iataCode: 'N',
        icaoCode: 'ULPP',
        latitude: '61,83194351',
        longitude: '34,29555511',
        altitude: '0',
        timezone: 'N',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Baranavichi Air Base',
        city: 'Baranavichi',
        country: 'Belarus',
        iataCode: 'N',
        icaoCode: 'UMMA',
        latitude: '53,09999847',
        longitude: '26,04999924',
        altitude: '0',
        timezone: 'N',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Babruisk Air Base',
        city: 'Babruisk',
        country: 'Belarus',
        iataCode: 'N',
        icaoCode: 'UMNB',
        latitude: '53,105',
        longitude: '29,205',
        altitude: '502',
        timezone: 'N',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Severo-Eniseysk Airport',
        city: 'Severo-Eniseysk',
        country: 'Russia',
        iataCode: 'N',
        icaoCode: 'UNIS',
        latitude: '60,37329865',
        longitude: '93,01170349',
        altitude: '1706',
        timezone: 'N',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Sharypovo Airport',
        city: 'Sharypovo',
        country: 'Russia',
        iataCode: 'N',
        icaoCode: 'UNKO',
        latitude: '55,45491409',
        longitude: '89,17388153',
        altitude: '1099',
        timezone: 'N',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Omsk Severny Airport',
        city: 'Omsk',
        country: 'Russia',
        iataCode: 'N',
        icaoCode: 'UNOS',
        latitude: '54,97499847',
        longitude: '73,55500031',
        altitude: '0',
        timezone: 'N',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Nagurskoye',
        city: 'Murmansk',
        country: 'Russia',
        iataCode: 'N',
        icaoCode: 'UODN',
        latitude: '80,803207',
        longitude: '47,663586',
        altitude: '59',
        timezone: 'N',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Svetlogorsk Airport',
        city: 'Svetlogorsk',
        country: 'Russia',
        iataCode: 'N',
        icaoCode: 'UOIG',
        latitude: '66,83999634',
        longitude: '88,40333557',
        altitude: '394',
        timezone: 'N',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Valek Airport',
        city: 'Valek',
        country: 'Russia',
        iataCode: 'N',
        icaoCode: 'UOOW',
        latitude: '69,39690399',
        longitude: '88,35368347',
        altitude: '108',
        timezone: 'N',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Khanskaya Airport',
        city: 'Maykop',
        country: 'Russia',
        iataCode: 'N',
        icaoCode: 'URKH',
        latitude: '44,68000031',
        longitude: '40,03499985',
        altitude: '600',
        timezone: 'N',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Dudinka Airport',
        city: 'Dudinka',
        country: 'Russia',
        iataCode: 'N',
        icaoCode: 'UROD',
        latitude: '69,375',
        longitude: '86,15666962',
        altitude: '82',
        timezone: 'N',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Volgodonsk Airport',
        city: '',
        country: 'Russia',
        iataCode: 'VLK',
        icaoCode: 'URRY',
        latitude: '47,68209839',
        longitude: '42,07279968',
        altitude: '276',
        timezone: 'N',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Tashkent East Airport',
        city: 'Tashkent',
        country: 'Uzbekistan',
        iataCode: 'N',
        icaoCode: 'UTTP',
        latitude: '41,31269836',
        longitude: '69,3914032',
        altitude: '1574',
        timezone: 'N',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Myachkovo Airport',
        city: 'Moscow',
        country: 'Russia',
        iataCode: 'N',
        icaoCode: 'UUBM',
        latitude: '55,56000137',
        longitude: '37,98500061',
        altitude: '410',
        timezone: 'N',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Tretyakovo Airport',
        city: 'Lukhovitsy',
        country: 'Russia',
        iataCode: 'N',
        icaoCode: 'UUMT',
        latitude: '54,90499878',
        longitude: '39,02666855',
        altitude: '515',
        timezone: 'N',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Yermolino Airport',
        city: 'Balabanovo',
        country: 'Russia',
        iataCode: 'N',
        icaoCode: 'UUWE',
        latitude: '55,22833252',
        longitude: '36,60833359',
        altitude: '640',
        timezone: 'N',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Inta Airport',
        city: 'Inta',
        country: 'Russia',
        iataCode: 'INA',
        icaoCode: 'UUYI',
        latitude: '66,05483246',
        longitude: '60,11032104',
        altitude: '184',
        timezone: 'N',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Izhma Airport',
        city: 'Izhma',
        country: 'Russia',
        iataCode: 'N',
        icaoCode: 'UUYV',
        latitude: '65,03170013',
        longitude: '53,97000122',
        altitude: '230',
        timezone: 'N',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Borisoglebskoye Airport',
        city: 'Kazan',
        country: 'Russia',
        iataCode: 'N',
        icaoCode: 'UWKG',
        latitude: '55,86669922',
        longitude: '49,13330078',
        altitude: '213',
        timezone: 'N',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Zhigansk Airport',
        city: 'Zhigansk',
        country: 'Russia',
        iataCode: 'ZIX',
        icaoCode: 'UWKV',
        latitude: '66,79650116',
        longitude: '123,3610001',
        altitude: '292',
        timezone: 'N',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Maksimovka Airport',
        city: 'Ufa',
        country: 'Russia',
        iataCode: 'N',
        icaoCode: 'UWUM',
        latitude: '54,83000183',
        longitude: '56,16830063',
        altitude: '325',
        timezone: 'N',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Buguruslan Severny Airport',
        city: 'Buguruslan',
        country: 'Russia',
        iataCode: 'N',
        icaoCode: 'UWWB',
        latitude: '53,71839905',
        longitude: '52,37189865',
        altitude: '728',
        timezone: 'N',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Bezymyanka Airfield',
        city: 'Samara',
        country: 'Russia',
        iataCode: 'N',
        icaoCode: 'UWWG',
        latitude: '53,22000122',
        longitude: '50,32500076',
        altitude: '135',
        timezone: 'N',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Ratnagiri Airport',
        city: '',
        country: 'India',
        iataCode: 'RTC',
        icaoCode: 'VARG',
        latitude: '17,0135994',
        longitude: '73,32779694',
        altitude: '305',
        timezone: 'N',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Hingurakgoda Air Force Base',
        city: 'Polonnaruwa Town',
        country: 'Sri Lanka',
        iataCode: 'HIM',
        icaoCode: 'VCCH',
        latitude: '8,04981',
        longitude: '80,9814',
        altitude: '170',
        timezone: 'N',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Kazi Nazrul Islam Airport',
        city: 'Durgapur',
        country: 'India',
        iataCode: 'RDP',
        icaoCode: 'VEDG',
        latitude: '23,6225',
        longitude: '87,243',
        altitude: '300',
        timezone: 'N',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Sri Sathya Sai Airport',
        city: 'Puttaparthi',
        country: 'India',
        iataCode: 'PUT',
        icaoCode: 'VOPN',
        latitude: '14,14929962',
        longitude: '77,79109955',
        altitude: '1558',
        timezone: 'N',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Meiktila Air Base',
        city: 'Meiktila',
        country: 'Burma',
        iataCode: 'N',
        icaoCode: 'VYML',
        latitude: '20,88640022',
        longitude: '95,89279175',
        altitude: '699',
        timezone: 'N',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Changi Air Base (East)',
        city: 'Singapore',
        country: 'Singapore',
        iataCode: 'N',
        icaoCode: 'WSAC',
        latitude: '1,344140053',
        longitude: '104,0090027',
        altitude: '22',
        timezone: 'N',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Soltsy-2 Air Base',
        city: 'Soltsy',
        country: 'Russia',
        iataCode: 'N',
        icaoCode: 'XLLL',
        latitude: '58,13954544',
        longitude: '30,33041954',
        altitude: '266',
        timezone: 'N',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Severomorsk-3 Naval Air Base',
        city: 'Severomorsk',
        country: 'Russia',
        iataCode: 'N',
        icaoCode: 'XLMV',
        latitude: '68,8666687',
        longitude: '33,71666718',
        altitude: '564',
        timezone: 'N',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Fedotovo Naval Air Base',
        city: 'Kipelovo',
        country: 'Russia',
        iataCode: 'N',
        icaoCode: 'XLWF',
        latitude: '59,18830109',
        longitude: '39,12329865',
        altitude: '574',
        timezone: 'N',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Lebyazhye Air Base',
        city: 'Kamyshin',
        country: 'Russia',
        iataCode: 'N',
        icaoCode: 'XRWL',
        latitude: '50,2016983',
        longitude: '45,20830154',
        altitude: '381',
        timezone: 'N',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Smolensk North Airport',
        city: 'Smolensk',
        country: 'Russia',
        iataCode: 'N',
        icaoCode: 'XUBS',
        latitude: '54,824',
        longitude: '32,025',
        altitude: '820',
        timezone: 'N',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Rtishchevo Air Base',
        city: 'Rtishchevo',
        country: 'Russia',
        iataCode: 'N',
        icaoCode: 'XWPR',
        latitude: '52,29731667',
        longitude: '43,7241',
        altitude: '673',
        timezone: 'N',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Alpha Airport',
        city: 'Alpha',
        country: 'Australia',
        iataCode: 'ABH',
        icaoCode: 'YAPH',
        latitude: '-23,646099',
        longitude: '146,584',
        altitude: '1255',
        timezone: 'N',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Ararat Airport',
        city: '',
        country: 'Australia',
        iataCode: 'ARY',
        icaoCode: 'YARA',
        latitude: '-37,30939865',
        longitude: '142,9889984',
        altitude: '1008',
        timezone: 'N',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Benalla Airport',
        city: '',
        country: 'Australia',
        iataCode: 'BLN',
        icaoCode: 'YBLA',
        latitude: '-36,55189896',
        longitude: '146,0070038',
        altitude: '569',
        timezone: 'N',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Balranald Airport',
        city: '',
        country: 'Australia',
        iataCode: 'BZD',
        icaoCode: 'YBRN',
        latitude: '-34,62360001',
        longitude: '143,5780029',
        altitude: '210',
        timezone: 'N',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Brewarrina Airport',
        city: '',
        country: 'Australia',
        iataCode: 'BWQ',
        icaoCode: 'YBRW',
        latitude: '-29,97389984',
        longitude: '146,8170013',
        altitude: '414',
        timezone: 'N',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Cleve Airport',
        city: '',
        country: 'Australia',
        iataCode: 'CVC',
        icaoCode: 'YCEE',
        latitude: '-33,70970154',
        longitude: '136,5050049',
        altitude: '589',
        timezone: 'N',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Corowa Airport',
        city: '',
        country: 'Australia',
        iataCode: 'CWW',
        icaoCode: 'YCOR',
        latitude: '-35,99470139',
        longitude: '146,3569946',
        altitude: '469',
        timezone: 'N',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Corryong Airport',
        city: '',
        country: 'Australia',
        iataCode: 'CYG',
        icaoCode: 'YCRG',
        latitude: '-36,18280029',
        longitude: '147,8880005',
        altitude: '963',
        timezone: 'N',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Cootamundra Airport',
        city: '',
        country: 'Australia',
        iataCode: 'CMD',
        icaoCode: 'YCTM',
        latitude: '-34,62390137',
        longitude: '148,0279999',
        altitude: '1110',
        timezone: 'N',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Dirranbandi Airport',
        city: '',
        country: 'Australia',
        iataCode: 'DRN',
        icaoCode: 'YDBI',
        latitude: '-28,5916996',
        longitude: '148,2169952',
        altitude: '567',
        timezone: 'N',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Deniliquin Airport',
        city: 'Deniliquin',
        country: 'Australia',
        iataCode: 'DNQ',
        icaoCode: 'YDLQ',
        latitude: '-35,55939865',
        longitude: '144,9459991',
        altitude: '316',
        timezone: 'N',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Dysart Airport',
        city: '',
        country: 'Australia',
        iataCode: 'DYA',
        icaoCode: 'YDYS',
        latitude: '-22,62220001',
        longitude: '148,3639984',
        altitude: '670',
        timezone: 'N',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Echuca Airport',
        city: '',
        country: 'Australia',
        iataCode: 'ECH',
        icaoCode: 'YECH',
        latitude: '-36,15719986',
        longitude: '144,7619934',
        altitude: '323',
        timezone: 'N',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Forbes Airport',
        city: 'Forbes',
        country: 'Australia',
        iataCode: 'FRB',
        icaoCode: 'YFBS',
        latitude: '-33,363602',
        longitude: '147,934998',
        altitude: '760',
        timezone: 'N',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Gunnedah Airport',
        city: '',
        country: 'Australia',
        iataCode: 'GUH',
        icaoCode: 'YGDH',
        latitude: '-30,96109962',
        longitude: '150,2510071',
        altitude: '863',
        timezone: 'N',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Hay Airport',
        city: '',
        country: 'Australia',
        iataCode: 'HXX',
        icaoCode: 'YHAY',
        latitude: '-34,53139877',
        longitude: '144,8300018',
        altitude: '305',
        timezone: 'N',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Hopetoun Airport',
        city: '',
        country: 'Australia',
        iataCode: 'HTU',
        icaoCode: 'YHPN',
        latitude: '-35,71530151',
        longitude: '142,3600006',
        altitude: '256',
        timezone: 'N',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Kerang Airport',
        city: '',
        country: 'Australia',
        iataCode: 'KRA',
        icaoCode: 'YKER',
        latitude: '-35,75139999',
        longitude: '143,9389954',
        altitude: '254',
        timezone: 'N',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Kempsey Airport',
        city: '',
        country: 'Australia',
        iataCode: 'KPS',
        icaoCode: 'YKMP',
        latitude: '-31,07439995',
        longitude: '152,7700043',
        altitude: '54',
        timezone: 'N',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Kingaroy Airport',
        city: '',
        country: 'Australia',
        iataCode: 'KGY',
        icaoCode: 'YKRY',
        latitude: '-26,5807991',
        longitude: '151,8410034',
        altitude: '1492',
        timezone: 'N',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Latrobe Valley Airport',
        city: 'Morwell',
        country: 'Australia',
        iataCode: 'TGN',
        icaoCode: 'YLTV',
        latitude: '-38,207199',
        longitude: '146,470001',
        altitude: '180',
        timezone: 'N',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Mareeba Airport',
        city: '',
        country: 'Australia',
        iataCode: 'MRG',
        icaoCode: 'YMBA',
        latitude: '-17,06920052',
        longitude: '145,4190063',
        altitude: '1560',
        timezone: 'N',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Ngukurr Airport',
        city: '',
        country: 'Australia',
        iataCode: 'RPM',
        icaoCode: 'YNGU',
        latitude: '-14,72280025',
        longitude: '134,746994',
        altitude: '45',
        timezone: 'N',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Narromine Airport',
        city: '',
        country: 'Australia',
        iataCode: 'QRM',
        icaoCode: 'YNRM',
        latitude: '-32,21469879',
        longitude: '148,2250061',
        altitude: '782',
        timezone: 'N',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Port Pirie Airport',
        city: '',
        country: 'Australia',
        iataCode: 'PPI',
        icaoCode: 'YPIR',
        latitude: '-33,23889923',
        longitude: '137,9949951',
        altitude: '40',
        timezone: 'N',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Smithton Airport',
        city: '',
        country: 'Australia',
        iataCode: 'SIO',
        icaoCode: 'YSMI',
        latitude: '-40,83499908',
        longitude: '145,0839996',
        altitude: '31',
        timezone: 'N',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Snake Bay Airport',
        city: '',
        country: 'Australia',
        iataCode: 'SNB',
        icaoCode: 'YSNB',
        latitude: '-11,42280006',
        longitude: '130,654007',
        altitude: '173',
        timezone: 'N',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Stawell Airport',
        city: '',
        country: 'Australia',
        iataCode: 'SWC',
        icaoCode: 'YSWL',
        latitude: '-37,07170105',
        longitude: '142,7409973',
        altitude: '807',
        timezone: 'N',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Tibooburra Airport',
        city: '',
        country: 'Australia',
        iataCode: 'TYB',
        icaoCode: 'YTIB',
        latitude: '-29,4510994',
        longitude: '142,0579987',
        altitude: '584',
        timezone: 'N',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Tumut Airport',
        city: '',
        country: 'Australia',
        iataCode: 'TUM',
        icaoCode: 'YTMU',
        latitude: '-35,26279831',
        longitude: '148,2409973',
        altitude: '878',
        timezone: 'N',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Wangaratta Airport',
        city: '',
        country: 'Australia',
        iataCode: 'WGT',
        icaoCode: 'YWGT',
        latitude: '-36,41579819',
        longitude: '146,3070068',
        altitude: '504',
        timezone: 'N',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Warracknabeal Airport',
        city: '',
        country: 'Australia',
        iataCode: 'WKB',
        icaoCode: 'YWKB',
        latitude: '-36,32109833',
        longitude: '142,4190063',
        altitude: '397',
        timezone: 'N',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Warren Airport',
        city: '',
        country: 'Australia',
        iataCode: 'QRR',
        icaoCode: 'YWRN',
        latitude: '-31,73329926',
        longitude: '147,8029938',
        altitude: '669',
        timezone: 'N',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'West Sale Airport',
        city: 'Sale',
        country: 'Australia',
        iataCode: 'SXE',
        icaoCode: 'YWSL',
        latitude: '-38,090827',
        longitude: '146,965335',
        altitude: '72',
        timezone: 'N',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'West Wyalong Airport',
        city: 'West Wyalong',
        country: 'Australia',
        iataCode: 'WWY',
        icaoCode: 'YWWL',
        latitude: '-33,93719864',
        longitude: '147,1909943',
        altitude: '859',
        timezone: 'N',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Young Airport',
        city: '',
        country: 'Australia',
        iataCode: 'NGA',
        icaoCode: 'YYNG',
        latitude: '-34,25559998',
        longitude: '148,2480011',
        altitude: '1267',
        timezone: 'N',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Guangzhou MR Air Base',
        city: 'Guanghua',
        country: 'China',
        iataCode: 'LHK',
        icaoCode: 'ZHGH',
        latitude: '32,38940048',
        longitude: '111,6949997',
        altitude: '0',
        timezone: 'N',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Shiyan Wudangshan Airport',
        city: 'Shiyan',
        country: 'China',
        iataCode: 'WDS',
        icaoCode: 'ZHSY',
        latitude: '32,591667',
        longitude: '110,907778',
        altitude: '0',
        timezone: 'N',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Sunchon Air Base',
        city: 'Sunchon',
        country: 'North Korea',
        iataCode: 'N',
        icaoCode: 'ZKSC',
        latitude: '39,415699',
        longitude: '125,894997',
        altitude: '141',
        timezone: 'N',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Huatugou Airport',
        city: 'Mengnai',
        country: 'China',
        iataCode: 'HTT',
        icaoCode: 'ZLHX',
        latitude: '38,201984',
        longitude: '90,841495',
        altitude: '2945',
        timezone: 'N',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Baruun Urt Airport',
        city: '',
        country: 'Mongolia',
        iataCode: 'UUN',
        icaoCode: 'ZMBU',
        latitude: '46,66030121',
        longitude: '113,2850037',
        altitude: '3205',
        timezone: 'N',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Bengbu Airport',
        city: 'Bengbu',
        country: 'China',
        iataCode: 'BFU',
        icaoCode: 'ZSBB',
        latitude: '32,84773333',
        longitude: '117,3202444',
        altitude: '100',
        timezone: 'N',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Rugao Air Base',
        city: 'Rugao',
        country: 'China',
        iataCode: 'RUG',
        icaoCode: 'ZSRG',
        latitude: '32,25788498',
        longitude: '120,5016556',
        altitude: '0',
        timezone: 'N',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Wuhu Air Base',
        city: 'Wuhu',
        country: 'China',
        iataCode: 'WHU',
        icaoCode: 'ZSWU',
        latitude: '31,3906',
        longitude: '118,408997',
        altitude: '0',
        timezone: 'N',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Shanshan Airport',
        city: 'Shanshan',
        country: 'China',
        iataCode: 'SXJ',
        icaoCode: 'ZWSS',
        latitude: '42,9117012',
        longitude: '90,24749756',
        altitude: '0',
        timezone: 'N',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Yingkou Lanqi Airport',
        city: 'Yingkou',
        country: 'China',
        iataCode: 'YKH',
        icaoCode: 'ZYYK',
        latitude: '40,542524',
        longitude: '122,3586',
        altitude: '0',
        timezone: 'N',
        dst: 'N',
        type: 'airport',
    },
    {
        name: 'Shenyang Dongta Airport',
        city: 'Shenyang',
        country: 'China',
        iataCode: 'N',
        icaoCode: 'ZYYY',
        latitude: '41,78440094',
        longitude: '123,4960022',
        altitude: '0',
        timezone: 'N',
        dst: 'N',
        type: 'airport',
    },
];

export const AIRPORT_ICAO_LIST = AIRPORT_LIST.map(({ icaoCode }) => icaoCode);

export const renderAirportCodeWithLocation = _ap =>
    _ap ? `${_ap.iataCode} | ${_ap.icaoCode} | ${_ap.city}, ${_ap.country}` : 'N/A';

export const getLocationByAirportCode = _code =>
    renderAirportCodeWithLocation(
        AIRPORT_LIST.find(({ icaoCode }) => _code === icaoCode)
    ) || 'N/A';
