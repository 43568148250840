import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

const IconLocation = props => {
    const { className, rootClassName } = props;
    const classes = classNames(rootClassName, className);

    return (
        <svg
            className={classes}
            width="14"
            height="18"
            viewBox="0 0 14 18"
            fill="none"
            xmlns="http://www.w3.org/2000/svg">
            <path
                d="M6.94499 9.69027C8.18004 9.69027 9.18124 8.68907 9.18124 7.45402C9.18124 6.21898 8.18004 5.21777 6.94499 5.21777C5.70994 5.21777 4.70874 6.21898 4.70874 7.45402C4.70874 8.68907 5.70994 9.69027 6.94499 9.69027Z"
                stroke="#808080"
                strokeWidth="1.49083"
                strokeLinecap="round"
                strokeLinejoin="round"
                fill="none"
            />
            <path
                d="M6.94502 1.49072C5.36345 1.49072 3.84665 2.119 2.72831 3.23734C1.60997 4.35568 0.981689 5.87248 0.981689 7.45406C0.981689 8.86438 1.28135 9.78721 2.09981 10.8084L6.94502 16.3991L11.7902 10.8084C12.6087 9.78721 12.9084 8.86438 12.9084 7.45406C12.9084 5.87248 12.2801 4.35568 11.1617 3.23734C10.0434 2.119 8.5266 1.49072 6.94502 1.49072V1.49072Z"
                stroke="#808080"
                strokeWidth="1.49083"
                strokeLinecap="round"
                strokeLinejoin="round"
                fill="none"
            />
        </svg>
    );
};

const { string } = PropTypes;

IconLocation.defaultProps = {
    className: null,
    rootClassName: null,
};

IconLocation.propTypes = {
    className: string,
    rootClassName: string,
};

export default IconLocation;
