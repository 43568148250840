import React, { Component, useState } from 'react';
import { array, arrayOf, func, node, number, object, string } from 'prop-types';
import classNames from 'classnames';
import { FormattedMessage, injectIntl, intlShape } from '../../util/reactIntl';
import { parseSelectFilterOptions } from '../../util/search';
import { FieldSelect } from '..';

import { FilterPopup, FilterPlain } from '..';
import css from './SelectNestedSingleFilter.module.css';

// SelectNestedSingleFilter doesn't need array mutators since it doesn't require validation.
// TODO: Live edit didn't work with FieldCheckboxGroup
//       There's a mutation problem: formstate.dirty is not reliable with it.
export const GroupOfNestedSelectFields = props => {
    const {
        id,
        className,
        name,
        options,
        primaryQueryName,
        parsePrimaryOptions,
        parseSecondaryOptions,
        intl,
    } = props;
    const [primarySelected, setPrimarySelected] = useState(null);
    const catPlaceholder = intl.formatMessage({
        id: 'AirCraftFilter.category.placeholder',
    });
    const modelPlaceholder = intl.formatMessage({
        id: 'AirCraftFilter.model.placeholder',
    });
    const infoTextId = props?.infoTextId;

    return (
        <div className={className}>
            <p className={css.insideLabel}>
                <FormattedMessage id="AirCraftFilter.label" />
            </p>
            <div className={css.infoTextContainer}>
                {infoTextId && (
                    <p className={css.infoText}>
                        <FormattedMessage id={infoTextId} />
                    </p>
                )}
            </div>

            {parsePrimaryOptions && (
                <FieldSelect
                    id={primaryQueryName}
                    name={primaryQueryName}
                    parse={value => {
                        setPrimarySelected(value);
                        return value;
                    }}>
                    <option value="">{catPlaceholder}</option>
                    {React.Children.toArray(
                        parsePrimaryOptions(options).map(({ key, label }) => (
                            <option value={key}>{label}</option>
                        ))
                    )}
                </FieldSelect>
            )}

            {parseSecondaryOptions && (
                <FieldSelect id={id + 'secondary'} name={name}>
                    <option value="">{modelPlaceholder}</option>
                    {React.Children.toArray(
                        parseSecondaryOptions(primarySelected, options).map(
                            ({ key, label }) => (
                                <option value={key}>{label}</option>
                            )
                        )
                    )}
                </FieldSelect>
            )}
        </div>
    );
};

const getQueryParamName = queryParamNames => {
    return Array.isArray(queryParamNames)
        ? queryParamNames[0]
        : queryParamNames;
};

class SelectNestedSingleFilter extends Component {
    constructor(props) {
        super(props);

        this.filter = null;
        this.filterContent = null;

        this.positionStyleForContent = this.positionStyleForContent.bind(this);
    }

    positionStyleForContent() {
        if (this.filter && this.filterContent) {
            // Render the filter content to the right from the menu
            // unless there's no space in which case it is rendered
            // to the left
            const distanceToRight =
                window.innerWidth - this.filter.getBoundingClientRect().right;
            const labelWidth = this.filter.offsetWidth;
            const contentWidth = this.filterContent.offsetWidth;
            const contentWidthBiggerThanLabel = contentWidth - labelWidth;
            const renderToRight = distanceToRight > contentWidthBiggerThanLabel;
            const contentPlacementOffset = this.props.contentPlacementOffset;

            const offset = renderToRight
                ? { left: contentPlacementOffset }
                : { right: contentPlacementOffset };
            // set a min-width if the content is narrower than the label
            const minWidth =
                contentWidth < labelWidth ? { minWidth: labelWidth } : null;

            return { ...offset, ...minWidth };
        }
        return {};
    }

    render() {
        const {
            rootClassName,
            className,
            id,
            name,
            label,
            options,
            initialValues,
            contentPlacementOffset,
            onSubmit,
            queryParamNames,
            searchMode,
            intl,
            showAsPopup,
            primaryQueryName,
            ...rest
        } = this.props;

        const classes = classNames(rootClassName || css.root, className);

        const queryParamName = getQueryParamName(queryParamNames);
        const hasInitialValues =
            !!initialValues && !!initialValues[queryParamName];

        // Parse options from param strings like "has_all:a,b,c" or "a,b,c"
        const selectedOptions = hasInitialValues
            ? parseSelectFilterOptions(initialValues[queryParamName])
            : [];

        const hasInitialPrimaryValues =
            !!initialValues && !!initialValues[primaryQueryName];
        const selectedPrimaryOptions = hasInitialPrimaryValues
            ? parseSelectFilterOptions(initialValues[primaryQueryName])
            : [];
        const labelForPopup = label;

        const labelForPlain = label;

        const contentStyle = this.positionStyleForContent();

        // pass the initial values with the name key so that
        // they can be passed to the correct field
        const namedInitialValues = {
            [name]: selectedOptions[0],
            [primaryQueryName]: selectedPrimaryOptions,
        };

        const handleSubmit = values => {
            const primaryQueryNameUsedValue = values
                ? getQueryParamName(values[primaryQueryName])
                : values;
            const usedValue = values ? values[name] : values;
            const max = this.props.max || 200; // 200 MTOW

            onSubmit({
                [primaryQueryName]: primaryQueryNameUsedValue,
                [queryParamName]: usedValue ? `${usedValue},${max}` : null,
            });
        };

        return showAsPopup ? (
            <FilterPopup
                className={classes}
                rootClassName={rootClassName}
                popupClassName={css.popupSize}
                name={name}
                label={labelForPopup}
                isSelected={hasInitialValues && hasInitialPrimaryValues}
                id={`${id}.popup`}
                showAsPopup
                contentPlacementOffset={contentPlacementOffset}
                onSubmit={handleSubmit}
                initialValues={namedInitialValues}
                keepDirtyOnReinitialize
                {...rest}>
                <GroupOfNestedSelectFields
                    className={css.fieldGroup}
                    name={name}
                    id={`${id}-checkbox-group`}
                    options={options}
                    primaryQueryName={primaryQueryName}
                    selectedOptions={selectedOptions}
                    intl={intl}
                    {...rest}
                />
            </FilterPopup>
        ) : (
            <FilterPlain
                className={className}
                rootClassName={rootClassName}
                label={labelForPlain}
                isSelected={hasInitialValues}
                id={`${id}.plain`}
                liveEdit
                contentPlacementOffset={contentStyle}
                onSubmit={handleSubmit}
                initialValues={namedInitialValues}
                {...rest}>
                <GroupOfNestedSelectFields
                    className={css.fieldGroupPlain}
                    name={name}
                    id={`${id}-checkbox-group`}
                    options={options}
                    primaryQueryName={primaryQueryName}
                    intl={intl}
                    {...rest}
                />
            </FilterPlain>
        );
    }
}

SelectNestedSingleFilter.defaultProps = {
    rootClassName: null,
    className: null,
    initialValues: null,
    contentPlacementOffset: 0,
};

SelectNestedSingleFilter.propTypes = {
    rootClassName: string,
    className: string,
    id: string.isRequired,
    name: string.isRequired,
    queryParamNames: arrayOf(string).isRequired,
    label: node.isRequired,
    onSubmit: func.isRequired,
    options: array.isRequired,
    initialValues: object,
    contentPlacementOffset: number,

    // form injectIntl
    intl: intlShape.isRequired,
};

export default injectIntl(SelectNestedSingleFilter);
