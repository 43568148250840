import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { FormattedMessage } from 'react-intl';
import css from './PrivacyPolicy.module.css';

const PrivacyPolicy = props => {
    const { rootClassName, className } = props;
    const classes = classNames(rootClassName || css.root, className);

    // prettier-ignore
    return (
    <div className={classes}>
      <p className={css.lastUpdated}><FormattedMessage id="PrivacyPolicyPage.lastUpdate" /></p>

      <p><FormattedMessage id="PrivacyPolicyPage.text" /></p>
    </div>
  );
};

PrivacyPolicy.defaultProps = {
    rootClassName: null,
    className: null,
};

const { string } = PropTypes;

PrivacyPolicy.propTypes = {
    rootClassName: string,
    className: string,
};

export default PrivacyPolicy;
