import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from '../../util/reactIntl';
import classNames from 'classnames';

import { NamedLink } from '../../components';
import step1 from './images/step1.svg';
import step2 from './images/step2.svg';
import step3 from './images/step3.svg';
import step4 from './images/step4.svg';
import css from './SectionHowItWorks.module.css';

const SectionHowItWorks = props => {
    const { rootClassName, className } = props;

    const classes = classNames(rootClassName || css.root, className);
    return (
        <div className={classes}>
            <div className={css.title}>
                <FormattedMessage id="SectionHowItWorks.titleLineTwo" />
            </div>

            <div className={css.steps}>
                <div className={css.step}>
                    <div className={css.imageContainer}>
                        <img src={step1}></img>
                    </div>
                    <h2 className={css.stepTitle}>
                        <FormattedMessage id="SectionHowItWorks.part1Title" />
                    </h2>
                    <p>
                        <FormattedMessage id="SectionHowItWorks.part1Text" />
                    </p>
                </div>

                <div className={css.step}>
                    <div className={css.imageContainer}>
                        <img src={step2}></img>
                    </div>
                    <h2 className={css.stepTitle}>
                        <FormattedMessage id="SectionHowItWorks.part2Title" />
                    </h2>
                    <p>
                        <FormattedMessage id="SectionHowItWorks.part2Text" />
                    </p>
                </div>

                <div className={css.step}>
                    <div className={css.imageContainer}>
                        <img src={step3}></img>
                    </div>
                    <h2 className={css.stepTitle}>
                        <FormattedMessage id="SectionHowItWorks.part3Title" />
                    </h2>
                    <p>
                        <FormattedMessage id="SectionHowItWorks.part3Text" />
                    </p>
                </div>
                <div className={css.step}>
                    <div className={css.imageContainer}>
                        <img src={step4}></img>
                    </div>
                    <h2 className={css.stepTitle}>
                        <FormattedMessage id="SectionHowItWorks.part4Title" />
                    </h2>
                    <p>
                        <FormattedMessage id="SectionHowItWorks.part4Text" />
                    </p>
                </div>
            </div>
        </div>
    );
};

SectionHowItWorks.defaultProps = { rootClassName: null, className: null };

const { string } = PropTypes;

SectionHowItWorks.propTypes = {
    rootClassName: string,
    className: string,
};

export default SectionHowItWorks;
