import React, { useEffect, useState } from 'react';
import { string } from 'prop-types';
import { FormattedMessage } from '../../util/reactIntl';
import classNames from 'classnames';

import css from './SectionHero.module.css';
import QueryForm from './QueryForm';
import { createResourceLocatorString } from '../../util/routes';
import routeConfiguration from '../../routeConfiguration';

const SectionHero = props => {
    const { rootClassName, className, history } = props;

    const [mounted, setMounted] = useState(false);
    const [dates, setDates] = useState(null);

    useEffect(() => {
        setMounted(true);
    }, []);

    const classes = classNames(rootClassName || css.root, className);

    const handleOnSubmitQueryForm = _values => {
        const { location, ...rest } = _values || {};
        let searchParams = { ...rest };

        if (location) {
            searchParams = {
                ...searchParams,
                bounds: location.selectedPlace.bounds,
                address: location.search,
            };
        }

        if (dates) {
            searchParams = {
                ...searchParams,
                ...dates,
            };
        }
        const routes = routeConfiguration();
        history.push(
            createResourceLocatorString('SearchPage', routes, {}, searchParams)
        );
    };

    const handleOnChangeQueryForm = e => {};

    return (
        <div className={classes}>
            <div className={css.heroContent}>
                <h1
                    className={classNames(css.heroMainTitle, {
                        [css.heroMainTitleFEDelay]: mounted,
                    })}>
                    <FormattedMessage id="SectionHero.title" />
                    <span className={css.marketplaceNameHero}>HangarHub</span>
                </h1>
                <h2
                    style={{ color: '#4A4A4A' }}
                    className={classNames(css.heroSubTitle, {
                        [css.heroSubTitleFEDelay]: mounted,
                    })}>
                    <FormattedMessage id="SectionHero.subTitle" />
                </h2>

                <QueryForm
                    mounted={mounted}
                    onSubmit={handleOnSubmitQueryForm}
                    onChange={handleOnChangeQueryForm}
                    setDates={setDates}
                    dates={dates}
                />

                {/* <NamedLink
                    name="SearchPage"
                    to={{
                        search: querySearch || '',
                    }}
                    className={classNames(css.heroButton, {
                        [css.heroButtonFEDelay]: mounted,
                    })}>
                    <FormattedMessage id="SectionHero.browseButton" />
                </NamedLink> */}
            </div>
        </div>
    );
};

SectionHero.defaultProps = { rootClassName: null, className: null };

SectionHero.propTypes = {
    rootClassName: string,
    className: string,
};

export default SectionHero;
