import React, { useState } from 'react';
import { FormattedMessage } from '../../util/reactIntl';
import classNames from 'classnames';
import { PrimaryButton, SecondaryButton, Modal, FieldDateRangeInput, FieldSelect, Form } from '../../components';
import { Form as FinalForm } from 'react-final-form';
import calendarIconBlue from '../../assets/icons/calendarBlue.png';
import calendarIconRed from '../../assets/icons/calendarRed.png';
import {
    required,
    bookingDatesRequired,
    composeValidators,
} from '../../util/validators';

import css from './TransactionPanel.module.css';
const identity = v => v;
// Functional component as a helper to build ActionButtons for
// provider when state is preauthorized
const SaleActionButtonsMaybe = props => {
    const {
        className,
        rootClassName,
        showButtons,
        currentTransaction,
        acceptInProgress,
        declineInProgress,
        acceptSaleError,
        declineSaleError,
        onAcceptSale,
        onDeclineSale,
        onProposeDates,
        onCancelDates,
        onFinalizeDatesWithNewTx,
        showCancelButton,
        showProposeDate,
        showCancelProposal,
        isProvider,
    } = props;

    const [declineRequestModalOpen, setDeclineRequestModalOpen] = useState(false);
    const [proposeDatesModalOpen, setProposeDatesModalOpen] = useState(false);
    const buttonsDisabled = acceptInProgress || declineInProgress;

    const acceptErrorMessage = acceptSaleError ? (
        <p className={css.actionError}>
            <FormattedMessage id="TransactionPanel.acceptSaleFailed" />
        </p>
    ) : null;
    const declineErrorMessage = declineSaleError ? (
        <p className={css.actionError}>
            <FormattedMessage id="TransactionPanel.declineSaleFailed" />
        </p>
    ) : null;

    const requiredMessage = 'Oops, make sure your date is correct!';
    const startDateErrorMessage = 'The start date is not valid';
    const endDateErrorMessage = 'The end date is not valid';

    const classes = classNames(rootClassName || css.actionButtons, className);

    const options = { weekday: 'short', month: 'short', day: 'numeric' };
    const start = currentTransaction.booking.attributes.start;
    const end = currentTransaction.booking.attributes.end;
    const startDate = start.toLocaleDateString('en-UK', options);
    const endDate = end.toLocaleDateString('en-UK', options);
    const startTime = start.toLocaleTimeString('en-UK', {
        hour12: true,
        hour: '2-digit',
        minute: '2-digit',
    });
    const endTime = end.toLocaleTimeString('en-UK', {
        hour12: true,
        hour: '2-digit',
        minute: '2-digit',
    });

    const declineText = showCancelButton ? (
        <FormattedMessage id="TransactionPanel.cancelButton" />
    ) : (
        <FormattedMessage id="TransactionPanel.declineButton" />
    );

    const submitProposedDatesText = <FormattedMessage id="TransactionPanel.submitProposedDatesText" />;
    const currentRoleString = isProvider ? 'provider' : 'customer';

    const calculateAndAcceptProposal = () => {
        if (window.confirm("This transaction will be cancelled and refunded, and a new one with the correct dates and amount will be created!")) {
            onFinalizeDatesWithNewTx(currentTransaction);
        };
    };

    const hours = [];
    for (let i = 0; i < 24; i++) {
        hours.push({
            id: i,
            formatedTime: `${('0' + i).slice(-2)}:00`,
        });
    }

    return showButtons || showProposeDate || showCancelProposal ? (
        <div className={classes}>
            <div className={css.actionErrors}>
                {acceptErrorMessage}
                {declineErrorMessage}
            </div>
            {showButtons ? <div className={css.actionButtonWrapper}>
                <SecondaryButton
                    className={css.declineButton}
                    inProgress={declineInProgress}
                    disabled={buttonsDisabled}
                    onClick={() => setDeclineRequestModalOpen(true)}>
                    {declineText}
                </SecondaryButton>
                {!showCancelButton && (
                    <PrimaryButton
                        className={css.acceptButton}
                        inProgress={acceptInProgress}
                        disabled={buttonsDisabled}
                        onClick={onAcceptSale}>
                        <FormattedMessage id="TransactionPanel.acceptButton" />
                    </PrimaryButton>
                )}
            </div> : null}
            {showProposeDate ? <div
                className={css.proposeDatesWrapper}
                onClick={() => setProposeDatesModalOpen(true)}
            >
                <img src={calendarIconBlue} />
                <FormattedMessage id="TransactionPanel.ProposeNewDate" />
            </div> : null}

            {showCancelProposal ? <div>
                {isProvider ? null : <div
                    className={css.proposeDatesWrapper}
                    onClick={() => calculateAndAcceptProposal()}
                    style={{ marginBottom: '20px' }}
                >
                    <img src={calendarIconBlue} />
                    <FormattedMessage id="TransactionPanel.AcceptNewDate" />
                </div>}
                <div
                    className={css.cancelDatesWrapper}
                    onClick={() => onCancelDates(
                        currentTransaction.id,
                        { start: currentTransaction.booking.attributes.start.toISOString(), end: currentTransaction.booking.attributes.end.toISOString() },
                        currentRoleString
                    )}
                >
                    <img src={calendarIconRed} />
                    {isProvider ?
                        <FormattedMessage id="TransactionPanel.CancelNewDate" />
                        : <FormattedMessage id="TransactionPanel.DeclineNewDate" />
                    }
                </div>
                <div className={css.newDatesNote}>
                    {isProvider ?
                        <FormattedMessage id="TransactionPanel.NewDatesNote" />
                        : <FormattedMessage id="TransactionPanel.NewDatesNoteCustomer" />
                    }
                </div>
            </div> : null}
            <Modal
                id="declineRequestModal"
                isOpen={declineRequestModalOpen}
                onClose={() => {
                    setDeclineRequestModalOpen(false);
                }}
                usePortal
                onManageDisableScrolling={() => null}
                closeButtonMessage={'Cancel'}>
                {declineRequestModalOpen && (
                    <div>
                        <h3>
                            {!showCancelButton ? (
                                <FormattedMessage id="TransactionPanel.confirmDeclineTitle" />
                            ) : (
                                <FormattedMessage id="TransactionPanel.confirmCancelTitle" />
                            )}
                        </h3>
                        <div className={css.dateContainer}>
                            <div>
                                From: {startDate}, {startTime}
                            </div>
                            <div>
                                To: {endDate}, {endTime}
                            </div>
                        </div>
                        <div className={css.actionDeclinecontainer}>
                            <SecondaryButton
                                className={css.declineButton}
                                inProgress={declineInProgress}
                                disabled={buttonsDisabled}
                                onClick={onDeclineSale}>
                                {declineText}
                            </SecondaryButton>
                        </div>
                    </div>
                )}
            </Modal>
            <Modal
                id="proposeDatesModal"
                isOpen={proposeDatesModalOpen}
                onClose={() => { setProposeDatesModalOpen(false); }}
                usePortal
                onManageDisableScrolling={() => null}
                closeButtonMessage={'Cancel'}>
                {proposeDatesModalOpen && (
                    <div>
                        <h3><FormattedMessage id="TransactionPanel.proposeDatesTitle" /></h3>
                        <div className={css.proposeDatesSubtitle}><FormattedMessage id="TransactionPanel.proposeDatesSubtitle" /></div>
                        <div className={css.dateContainer}>
                            <FinalForm
                                onSubmit={(e) => { e.preventDefault(); console.log(e) }}
                                render={fieldRenderProps => {
                                    const { values } = fieldRenderProps;
                                    return <Form onSubmit={(e) => {
                                        e.preventDefault();
                                        const { startDate, endDate } = values.proposeDates ? values.proposeDates : {};
                                        const { bookingStartTime, bookingEndTime } = values;
                                        const formatedStartDate = new Date(
                                            startDate.setHours(bookingStartTime)
                                        );
                                        const formatedEndDate = new Date(endDate.setHours(bookingEndTime));
                                        const dates = {
                                            start: formatedStartDate.toISOString(),
                                            end: formatedEndDate.toISOString(),
                                        };
                                        onProposeDates(currentTransaction.id, dates);
                                        setProposeDatesModalOpen(false);
                                    }}>
                                        <FieldDateRangeInput
                                            name="proposeDates"
                                            startDateId="proposeDatesStart"
                                            startDateLabel="Start date"
                                            startDatePlaceholderText="Wed, Apr 19"
                                            endDateId="proposeDatesEnd"
                                            endDateLabel="End date"
                                            endDatePlaceholderText="Thu, Apr 20"
                                            format={identity}
                                            useMobileMargins
                                            validate={composeValidators(
                                                required(requiredMessage),
                                                bookingDatesRequired(startDateErrorMessage, endDateErrorMessage)
                                            )}
                                        />
                                        <div className={css.timeRangeContainer}>
                                            <FieldSelect
                                                name="bookingStartTime"
                                                id="bookingStartTime"
                                                validate={composeValidators(
                                                    required(
                                                        'Start time is required'
                                                    )
                                                )}
                                                label={'Start time'}>
                                                <option>{'select'}</option>
                                                {hours.map(h => {
                                                    return (
                                                        <option
                                                            key={h.id}
                                                            value={h.id}>
                                                            {h.formatedTime}
                                                        </option>
                                                    );
                                                })}
                                            </FieldSelect>

                                            <FieldSelect
                                                name="bookingEndTime"
                                                id="bookingEndTime"
                                                validate={composeValidators(
                                                    required('End time is required')
                                                )}
                                                label={'End time'}>
                                                <option>{'select'}</option>
                                                {hours.map(h => {
                                                    if (
                                                        values.proposeDates?.startDate?.getDate() ==
                                                        values.proposeDates?.endDate?.getDate() &&
                                                        values.proposeDates?.startDate?.getMonth() ==
                                                        values.proposeDates?.endDate?.getMonth() &&
                                                        values.bookingStartTime >=
                                                        h.id
                                                    ) {
                                                        return null;
                                                    } else {
                                                        return (
                                                            <option
                                                                key={h.id}
                                                                value={h.id}>
                                                                {h.formatedTime}
                                                            </option>
                                                        );
                                                    }
                                                })}
                                            </FieldSelect>
                                        </div>
                                        <PrimaryButton
                                            className={css.proposeDatesSubmit}
                                            disabled={!values.proposeDates?.endDate}
                                            type='submit'
                                        >
                                            {submitProposedDatesText}
                                        </PrimaryButton>
                                    </Form>
                                }} />
                        </div>
                    </div>
                )}
            </Modal>
        </div>
    ) : null;
};

export default SaleActionButtonsMaybe;
