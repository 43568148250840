import React, { useState, useEffect } from 'react';
import { bool, func, shape, string } from 'prop-types';
import { compose } from 'redux';
import { Form as FinalForm, FormSpy } from 'react-final-form';
import { intlShape, injectIntl, FormattedMessage } from '../../util/reactIntl';
import classNames from 'classnames';
import config from '../../config';
import { LINE_ITEM_NIGHT, LINE_ITEM_DAY, propTypes } from '../../util/types';
import * as validators from '../../util/validators';
import { required, composeValidators, minValue } from '../../util/validators';
import { formatMoney } from '../../util/currency';
import { types as sdkTypes } from '../../util/sdkLoader';
import {
    Button,
    Form,
    FieldCurrencyInput,
    Modal,
    FieldTextInput,
    FieldSelect,
} from '../../components';

import { currencyConverter } from '../../util/currency';
import css from './EditListingPricingForm.module.css';
import { SupportedCurrencies } from '../../data/supportedCurrencies';

const { Money } = sdkTypes;

export const EditListingPricingFormComponent = props => {
    const [addRangeModalOpen, setAddRangeModalOpen] = useState(false);
    const [rangeEdit, setRangeEdit] = useState();

    return (
        <FinalForm
            {...props}
            render={formRenderProps => {
                const {
                    className,
                    disabled,
                    ready,
                    handleSubmit,
                    intl,
                    invalid,
                    pristine,
                    saveActionMsg,
                    updated,
                    updateInProgress,
                    fetchErrors,
                    onManageDisableScrolling,
                    ranges,
                    setRanges,
                    values,
                    form,
                    fixedRanges,
                    publicData,
                    vatSelected,
                    setVatSelected,
                } = formRenderProps;

                const unitType = config.bookingUnitType;
                const isNightly = unitType === LINE_ITEM_NIGHT;
                const isDaily = unitType === LINE_ITEM_DAY;

                const translationKey = isNightly
                    ? 'EditListingPricingForm.pricePerNight'
                    : isDaily
                        ? 'EditListingPricingForm.pricePerDay'
                        : 'EditListingPricingForm.pricePerUnit';

                const pricePerUnitMessage = intl.formatMessage({
                    id: translationKey,
                });

                const pricePlaceholderMessage = intl.formatMessage({
                    id: 'EditListingPricingForm.priceInputPlaceholder',
                });

                const priceLabelMessage = intl.formatMessage({
                    id: 'EditListingPricingForm.priceLabel',
                });

                const rangeRequiredMessage = intl.formatMessage({
                    id: 'EditListingPricingForm.rangeRequired',
                });

                const currencyLabelMessage = intl.formatMessage({
                    id: 'EditListingPricingForm.currencyLabel',
                });

                const currencyPlaceholderMessage = intl.formatMessage({
                    id: 'EditListingPricingForm.currencyPlaceholder',
                });

                const currencyRequiredMessage = intl.formatMessage({
                    id: 'EditListingPricingForm.currencyRequired',
                });

                const priceRequired = validators.required(
                    intl.formatMessage({
                        id: 'EditListingPricingForm.priceRequired',
                    })
                );
                const minPrice = new Money(
                    config.listingMinimumPriceSubUnits,
                    config.currency
                );
                const minPriceRequired = validators.moneySubUnitAmountAtLeast(
                    intl.formatMessage(
                        {
                            id: 'EditListingPricingForm.priceTooLow',
                        },
                        {
                            minPrice: formatMoney(intl, minPrice),
                        }
                    ),
                    config.listingMinimumPriceSubUnits
                );
                const priceValidators = config.listingMinimumPriceSubUnits
                    ? validators.composeValidators(
                        priceRequired,
                        minPriceRequired
                    )
                    : priceRequired;

                const vatInRange = validators.minMaxValue(
                    'Percentage should be between 1 and 100',
                    1,
                    100
                );

                const classes = classNames(css.root, className);
                const submitReady = (updated && pristine) || ready;
                const submitInProgress = updateInProgress;
                // const submitDisabled = invalid || disabled || submitInProgress;
                const { updateListingError, showListingsError } =
                    fetchErrors || {};

                const submitDisabled = ranges.length < 1 || submitInProgress;
                const addPriceDisabled =
                    !values.rangeFrom || !values.rangeTo || !values.listingCurrency || !values.listingPrice;

                function addPrice() {
                    setAddRangeModalOpen(false);
                    return getCurrency(
                        values.listingCurrency,
                        values.listingPrice
                    )
                        .then(value => {
                            const swissPrice = new Money(value * 100, 'CHF');
                            values.priceR = swissPrice;
                            if (rangeEdit) {
                                const newRanges = ranges.filter(
                                    item => item.rangeFrom !== rangeEdit.rangeFrom
                                );
                                setRanges([
                                    ...newRanges,
                                    {
                                        rangeFrom: Number(values.rangeFrom),
                                        rangeTo: Number(values.rangeTo),
                                        price: values.priceR,
                                        listingCurrency: values.listingCurrency,
                                        listingPrice: Number(values.listingPrice) * 100,
                                    },
                                ]);
                            } else {
                                setRanges([
                                    ...ranges,
                                    {
                                        rangeFrom: Number(values.rangeFrom),
                                        rangeTo: Number(values.rangeTo),
                                        price: values.priceR,
                                        listingCurrency: values.listingCurrency,
                                        listingPrice: Number(values.listingPrice) * 100,
                                    },
                                ]);
                            }

                            form.change('rangeTo', null);
                            form.change('rangeFrom', null);
                            form.change('listingCurrency', null);
                            form.change('listingPrice', null);
                            setRangeEdit(null);
                        })
                        .catch(err => console.log(err))

                }

                function editPrice(range) {
                    const priceObject = new Money(
                        range.price.amount ? range.price.amount : range.price,
                        config.currency
                    );
                    form.change('rangeTo', range.rangeTo);
                    form.change('rangeFrom', range.rangeFrom);
                    form.change('listingCurrency', range.listingCurrency);
                    form.change('listingPrice', range.listingPrice / 100);
                    form.change('priceR', priceObject);
                    setRangeEdit(range);
                    setAddRangeModalOpen(true);
                }

                function removePrice(rangeFrom) {
                    const newRanges = ranges.filter(
                        item => item.rangeFrom !== rangeFrom
                    );
                    setRanges(newRanges);
                }

                function checkRangeTakenFrom(number) {
                    const result = ranges.find(
                        item =>
                            item.rangeFrom <= number && item.rangeTo >= number
                    );
                    if (publicData.maxMTOW < number) {
                        return true;
                    }
                    if (
                        rangeEdit &&
                        (rangeEdit.rangeFrom <= number &&
                            rangeEdit.rangeTo >= number)
                    ) {
                        return false;
                    }
                    if (result) {
                        return true;
                    } else {
                        return false;
                    }
                }

                function checkRangeTakenTo(number) {
                    const result = ranges.find(
                        item =>
                            item.rangeFrom <= number && item.rangeTo >= number
                    );
                    const nextRange = getNextRange();
                    if (publicData.maxMTOW < number) {
                        return true;
                    }
                    if (nextRange) {
                        if (number >= nextRange.rangeFrom) {
                            return true;
                        }
                    }
                    if (
                        rangeEdit &&
                        (rangeEdit.rangeFrom <= number ||
                            rangeEdit.rangeTo >= number)
                    ) {
                        if (number <= values.rangeFrom) {
                            return true;
                        }
                        return false;
                    }
                    if (result) {
                        return true;
                    } else {
                        if (number < values.rangeFrom) {
                            return true;
                        }
                        return false;
                    }
                }

                function getNextRange() {
                    const number = rangeEdit
                        ? rangeEdit.rangeTo + 1
                        : values.rangeFrom;
                    const startingRanges = [];
                    ranges.map(r => {
                        startingRanges.push(r.rangeFrom);
                    });
                    const sortedRanges = ranges.sort(function (a, b) {
                        return a.rangeFrom - b.rangeFrom;
                    });
                    const nextRange = sortedRanges.find(
                        item => item.rangeFrom >= number
                    );
                    return nextRange;
                }

                function getCurrency(currency, price) {
                    return currencyConverter({
                        from: currency,
                        to: 'chf',
                        amount: price,
                    })
                        .then(result => {
                            if (currency.toUpperCase() == 'CHF') { return Number(price) };
                            return result
                        })
                        .catch(err => console.log(err))
                }

                return (
                    <>
                        <Form onSubmit={handleSubmit} className={classes}>
                            {updateListingError ? (
                                <p className={css.error}>
                                    <FormattedMessage id="EditListingPricingForm.updateFailed" />
                                </p>
                            ) : null}
                            {showListingsError ? (
                                <p className={css.error}>
                                    <FormattedMessage id="EditListingPricingForm.showListingFailed" />
                                </p>
                            ) : null}
                            <FormSpy
                                subscription={{ values: true }}
                                onChange={values => {
                                    if (values.rangeTo && checkRangeTakenTo(values.rangeTo)) {
                                        form.change('rangeTo', null);
                                    };
                                }}
                            />

                            <div className={css.rangesContainer}>
                                {submitInProgress ? (
                                    <p>
                                        <FormattedMessage id="EditListingPricingForm.loading" />
                                    </p>
                                ) : (
                                    ranges
                                        .sort(function (a, b) {
                                            return a.rangeTo - b.rangeTo;
                                        })
                                        .map(range => {
                                            return (
                                                <p
                                                    key={range.rangeFrom}
                                                    className={
                                                        css.spaceBetweenFlex
                                                    }>
                                                    <span>
                                                        ● {range.rangeFrom}t -{' '}
                                                        {range.rangeTo}t |{' '}
                                                        {range.newPrice
                                                            ? range.newPrice
                                                                .currency
                                                            : range.price
                                                                .currency}{' '}
                                                        {(range.newPrice
                                                            ? range.newPrice
                                                                .amount / 100
                                                            : range.price
                                                                .amount / 100
                                                        ).toFixed(2)}
                                                        {range.listingCurrency && ` | ${range.listingCurrency.toUpperCase()} ${Number(range.listingPrice / 100).toFixed(2)}`}
                                                    </span>
                                                    <span>
                                                        <span
                                                            className={
                                                                css.priceAction
                                                            }
                                                            onClick={() => {
                                                                editPrice(
                                                                    range
                                                                );
                                                            }}>
                                                            <FormattedMessage id="EditListingPricingForm.edit" />
                                                        </span>
                                                        <span
                                                            className={
                                                                css.priceAction
                                                            }
                                                            onClick={() => {
                                                                removePrice(
                                                                    range.rangeFrom
                                                                );
                                                            }}>
                                                            <FormattedMessage id="EditListingPricingForm.remove" />
                                                        </span>
                                                    </span>
                                                </p>
                                            );
                                        })
                                )}
                            </div>
                            <div>
                                <div className={css.vatContainer}>
                                    <input
                                        type="checkbox"
                                        name='addVAT'
                                        className={css.vatCheckbox}
                                        defaultChecked={vatSelected}
                                        onChange={e => { setVatSelected(e.target.checked) }}
                                    />
                                    <span>Add a VAT</span>
                                </div>
                                {vatSelected &&
                                    <FieldTextInput
                                        id="percentVAT"
                                        name="percentVAT"
                                        type="number"
                                        className={css.rangeInput}
                                        label={'VAT (as percentage)'}
                                        placeholder={'Enter VAT value...'}
                                        validate={vatInRange}
                                    />
                                }
                            </div>
                            <div
                                className={css.addRangeDiv}
                                onClick={() => {
                                    form.reset();
                                    setAddRangeModalOpen(true);
                                }}>
                                <FormattedMessage id="EditListingPricingForm.addPriceRange" />
                            </div>

                            <Button
                                className={css.submitButton}
                                type="submit"
                                inProgress={submitInProgress}
                                disabled={submitDisabled}
                                ready={submitReady}>
                                {saveActionMsg}
                            </Button>
                        </Form>
                        <Modal
                            id="AddRangeModal"
                            isOpen={addRangeModalOpen}
                            onClose={() => {
                                setAddRangeModalOpen(false);
                            }}
                            usePortal
                            onManageDisableScrolling={onManageDisableScrolling}
                            closeButtonMessage={'Cancel'}>
                            {addRangeModalOpen && (
                                <div>
                                    <label>
                                        <FormattedMessage id="EditListingPricingForm.weightRange" />
                                        <div className={css.rangeInputsWrapper}>
                                            <FieldSelect
                                                id="rangeFrom"
                                                name="rangeFrom"
                                                className={css.rangeInput}
                                                validate={composeValidators(
                                                    required(
                                                        rangeRequiredMessage
                                                    )
                                                )}>
                                                <option value={''}>from</option>
                                                {fixedRanges.map(r => {
                                                    if (
                                                        checkRangeTakenFrom(r)
                                                    ) {
                                                        return null;
                                                    }
                                                    return (
                                                        <option
                                                            key={r}
                                                            value={r}>
                                                            {r}
                                                        </option>
                                                    );
                                                })}
                                            </FieldSelect>
                                            <div
                                                className={
                                                    css.rangeSeprater
                                                }></div>

                                            <FieldSelect
                                                id="rangeTo"
                                                name="rangeTo"
                                                className={css.rangeInput}
                                                validate={composeValidators(
                                                    required(
                                                        rangeRequiredMessage
                                                    )
                                                )}>
                                                <option value={''}>
                                                    to
                                                </option>
                                                {fixedRanges.map(r => {
                                                    if (
                                                        checkRangeTakenTo(r)
                                                    ) {
                                                        return null;
                                                    }
                                                    return (
                                                        <option
                                                            key={r}
                                                            value={r}>
                                                            {r}
                                                        </option>
                                                    );
                                                })}
                                            </FieldSelect>

                                        </div>
                                    </label>

                                    <div
                                        style={{
                                            marginTop: '20px',
                                            marginBottom: '50px',
                                        }}
                                        className={css.rangeInputsWrapper}>
                                        <FieldSelect
                                            id="listingCurrency"
                                            name="listingCurrency"
                                            className={css.rangeInput}
                                            label={currencyLabelMessage}
                                            validate={composeValidators(
                                                required(
                                                    currencyRequiredMessage
                                                )
                                            )}>
                                            <option value={''}>
                                                {currencyPlaceholderMessage}
                                            </option>
                                            {SupportedCurrencies.map(r => {
                                                return (
                                                    <option
                                                        key={r.code}
                                                        value={r.code}>
                                                        {r.name}
                                                    </option>
                                                );
                                            })}
                                        </FieldSelect>
                                        <div
                                            className={css.rangeSeprater}></div>

                                        <FieldTextInput
                                            id="listingPrice"
                                            name="listingPrice"
                                            type="number"
                                            className={css.rangeInput}
                                            label={priceLabelMessage}
                                            placeholder={'Type your price...'}
                                            currencyConfig={
                                                config.currencyConfig
                                            }
                                            validate={priceValidators}
                                        />
                                    </div>
                                    <div></div>
                                    <div className={css.savePriceButtonHolder}>
                                        <Button
                                            className={css.submitButton}
                                            type="button"
                                            onClick={() => {
                                                addPrice();
                                            }}
                                            disabled={addPriceDisabled}>
                                            <FormattedMessage id="EditListingPricingForm.savePrice" />
                                        </Button>
                                    </div>
                                </div>
                            )}
                        </Modal>
                    </>
                );
            }}
        />
    );
};

EditListingPricingFormComponent.defaultProps = { fetchErrors: null };

EditListingPricingFormComponent.propTypes = {
    intl: intlShape.isRequired,
    onSubmit: func.isRequired,
    saveActionMsg: string.isRequired,
    disabled: bool.isRequired,
    ready: bool.isRequired,
    updated: bool.isRequired,
    updateInProgress: bool.isRequired,
    fetchErrors: shape({
        showListingsError: propTypes.error,
        updateListingError: propTypes.error,
    }),
};

export default compose(injectIntl)(EditListingPricingFormComponent);
