import React from 'react';
import PropTypes from 'prop-types';

const IconLogo = props => {
    const { className, ...rest } = props;

    return (
        <svg width="55" height="55" viewBox="0 0 192 192" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fill-rule="evenodd" clip-rule="evenodd" d="M59.4971 76.3125C82.9371 91.6108 110.291 109.933 112.517 113.604C112.943 114.309 114.083 116.223 113.393 117.424C112.608 118.79 110.869 119.163 109.577 118.381C104.981 115.433 100.385 112.485 95.7893 109.537C91.179 106.579 86.5688 103.622 81.958 100.664L68.5646 105.041L68.4739 145.362H173.464C173.449 138.642 173.434 131.922 173.418 125.201C173.403 118.481 173.388 111.761 173.373 105.041C164.785 102.234 156.195 99.4268 147.606 96.6199C138.964 93.7954 130.321 90.9709 121.679 88.1464C121.56 88.179 121.442 88.2121 121.324 88.2451C121.205 88.2782 121.087 88.3112 120.969 88.3438C120.851 88.3112 120.732 88.2782 120.614 88.2451C120.496 88.2121 120.377 88.179 120.259 88.1464C117.97 88.895 115.681 89.6431 113.392 90.3912C111.101 91.1398 108.811 91.8883 106.52 92.6374L153.9 121.738C155.259 122.598 155.636 124.348 154.857 125.637C153.991 126.919 152.252 127.294 150.954 126.511L137.433 118.056V118.06C124.246 110.552 111.084 103.012 97.9406 95.4412C85.1097 88.051 72.295 80.6298 59.4971 73.1782V76.3125Z" fill="url(#paint0_linear_1856_72922)" />
            <path d="M191.872 91.8483C168.696 84.2733 145.52 76.7003 122.343 69.1253C122.107 69.0622 121.789 69.003 121.411 69.001C121.012 68.999 120.679 69.0602 120.435 69.1253C112.109 71.8463 103.782 74.5673 95.4561 77.2883C95.1879 77.31 94.5944 77.5271 93.3028 77.8171C92.5258 77.9908 91.7508 78.1802 90.9739 78.3874C89.1183 77.8842 87.2469 77.3633 85.3618 76.8207C79.6727 75.1829 74.1809 73.4505 68.8862 71.651C78.6039 77.0476 88.3216 82.4442 98.0394 87.8388C105.823 85.2244 113.606 82.6119 121.389 79.9975C140.424 86.3905 159.461 92.7836 178.496 99.1766C178.581 114.806 178.666 130.436 178.749 146.067C183.164 146.071 187.579 146.077 191.994 146.081C191.953 128.005 191.911 109.926 191.872 91.8503V91.8483Z" fill="url(#paint1_linear_1856_72922)" />
            <path d="M50.0325 79.897C51.7106 81.0631 54.3313 83.2671 56.0114 86.8741C56.5004 87.9218 56.8337 88.9262 57.0624 89.8378C55.0096 90.5087 52.9588 91.1795 50.906 91.8504C50.8646 109.927 50.8232 128.005 50.7838 146.081C55.1989 146.077 59.6141 146.071 64.0293 146.067C64.1141 130.438 64.1989 114.808 64.2817 99.1768C67.6596 98.0422 71.0356 96.9076 74.4135 95.775C66.2871 90.483 58.1608 85.191 50.0344 79.8989L50.0325 79.897Z" fill="url(#paint2_linear_1856_72922)" />
            <path d="M191.878 91.8576C168.702 84.2826 145.525 76.7096 122.349 69.1346C122.113 69.0715 121.795 69.0123 121.416 69.0103C121.018 69.0083 120.685 69.0695 120.44 69.1346C112.115 71.8556 103.787 74.5766 95.4618 77.2976C95.1937 77.3193 94.6001 77.5363 93.3085 77.8264C92.5316 78 91.7566 78.1895 90.9796 78.3966C89.124 77.8935 87.2527 77.3726 85.3675 76.83C79.6785 75.1922 74.1866 73.4598 68.892 71.6603C78.6097 77.0569 88.3274 82.4535 98.0451 87.8481C105.828 85.2337 113.612 82.6212 121.395 80.0067C140.43 86.3998 159.467 92.7929 178.502 99.1859C183.002 114.819 187.5 130.455 192 146.088C191.959 128.012 191.917 109.934 191.878 91.8576V91.8576Z" fill="url(#paint3_linear_1856_72922)" />
            <path d="M50.0386 79.9067C51.7167 81.0729 54.3374 83.2769 56.0175 86.8838C56.5065 87.9316 56.8398 88.9359 57.0685 89.8475C55.0157 90.5184 52.9649 91.1893 50.9121 91.8602C50.8707 109.936 50.8293 128.014 50.7899 146.091C55.2898 130.457 59.7878 114.822 64.2878 99.1885C67.6657 98.0539 71.0417 96.9194 74.4196 95.7868C66.2932 90.4947 58.1669 85.2027 50.0405 79.9107L50.0386 79.9067Z" fill="url(#paint4_linear_1856_72922)" />
            <path d="M14.6083 47.5848C13.8826 47.2592 13.1175 47.1389 12.313 47.2296L24.4364 56.0674C18.6843 52.9104 12.9322 49.7572 7.18002 46.6022C0.43008 45.4893 -1.56158 45.9194 1.209 47.8887L16.9352 56.5726L40.1567 66.391C38.4273 64.8007 36.6979 63.2083 34.9686 61.6179L14.6083 47.5848Z" fill="url(#paint5_linear_1856_72922)" />
            <path d="M38.4155 63.9894L46.0429 59.4077L57.9751 60.931L45.7452 56.8111C43.5169 55.416 41.2886 54.021 39.0603 52.626C40.0818 54.4077 41.1032 56.1915 42.1247 57.9733C40.2652 58.5691 38.4056 59.167 36.5461 59.7629C35.0376 59.3998 33.529 59.0348 32.0225 58.6718C34.1541 60.4437 36.2838 62.2156 38.4155 63.9875V63.9894Z" fill="url(#paint6_linear_1856_72922)" />
            <path d="M54.1339 72.2985C49.4663 70.4082 44.7968 68.5199 40.1292 66.6296C36.002 64.9583 33.1427 63.585 28.343 61.6987C24.7895 60.3017 21.8356 59.2342 19.84 58.5317C22.5593 60.1675 25.2805 61.8032 27.9998 63.439C36.6961 66.7381 45.3923 70.0353 54.0905 73.3344C56.6619 72.5629 59.2333 71.7934 61.8048 71.0219L54.1319 72.2985H54.1339Z" fill="url(#paint7_linear_1856_72922)" />
            <path fill-rule="evenodd" clip-rule="evenodd" d="M59.4971 76.3125C82.9371 91.6108 110.291 109.933 112.517 113.604C112.943 114.309 114.083 116.223 113.393 117.424C112.608 118.79 110.869 119.163 109.577 118.381C105.027 115.463 100.477 112.544 95.9273 109.625L95.759 109.517C91.1589 106.566 86.5587 103.615 81.958 100.664L68.472 145.362H173.464C170.583 133.959 167.702 122.556 164.821 111.152C155.031 105.932 145.241 100.711 135.451 95.4906L135.419 95.4734L121.679 88.1464C121.56 88.179 121.442 88.2121 121.324 88.2451C121.205 88.2782 121.087 88.3112 120.969 88.3438C120.851 88.3112 120.732 88.2782 120.614 88.2451L120.614 88.2451L120.614 88.2451C120.496 88.212 120.377 88.179 120.259 88.1464C117.969 88.8955 115.678 89.644 113.388 90.3926C111.098 91.1407 108.809 91.8888 106.52 92.6374L153.9 121.738C155.259 122.598 155.636 124.348 154.857 125.637C153.991 126.919 152.252 127.294 150.954 126.511L137.433 118.056V118.06C124.246 110.552 111.084 103.012 97.9406 95.4412C85.1097 88.051 72.295 80.6298 59.4971 73.1782V76.3125Z" fill="url(#paint8_linear_1856_72922)" />
            <defs>
                <linearGradient id="paint0_linear_1856_72922" x1="125.39" y1="166.327" x2="103.312" y2="79.5818" gradientUnits="userSpaceOnUse">
                    <stop stop-color="#99F3FF" />
                    <stop offset="0.27" stop-color="#73DCEC" />
                    <stop offset="0.87" stop-color="#15A2BD" />
                    <stop offset="1" stop-color="#0196B3" />
                </linearGradient>
                <linearGradient id="paint1_linear_1856_72922" x1="130.344" y1="80.6032" x2="132.433" y2="145.844" gradientUnits="userSpaceOnUse">
                    <stop stop-color="#99F3FF" />
                    <stop offset="0.27" stop-color="#73DCEC" />
                    <stop offset="0.87" stop-color="#15A2BD" />
                    <stop offset="1" stop-color="#0196B3" />
                </linearGradient>
                <linearGradient id="paint2_linear_1856_72922" x1="61.5939" y1="89.5102" x2="63.3472" y2="144.289" gradientUnits="userSpaceOnUse">
                    <stop stop-color="#99F3FF" />
                    <stop offset="0.27" stop-color="#73DCEC" />
                    <stop offset="0.87" stop-color="#15A2BD" />
                    <stop offset="1" stop-color="#0196B3" />
                </linearGradient>
                <linearGradient id="paint3_linear_1856_72922" x1="193.966" y1="159.164" x2="85.1368" y2="26.8353" gradientUnits="userSpaceOnUse">
                    <stop stop-color="#99F3FF" />
                    <stop offset="0.27" stop-color="#73DCEC" />
                    <stop offset="0.87" stop-color="#15A2BD" />
                    <stop offset="1" stop-color="#0196B3" />
                </linearGradient>
                <linearGradient id="paint4_linear_1856_72922" x1="64.836" y1="171.172" x2="58.7865" y2="35.1478" gradientUnits="userSpaceOnUse">
                    <stop stop-color="#99F3FF" />
                    <stop offset="0.27" stop-color="#73DCEC" />
                    <stop offset="0.87" stop-color="#15A2BD" />
                    <stop offset="1" stop-color="#0196B3" />
                </linearGradient>
                <linearGradient id="paint5_linear_1856_72922" x1="0.00019814" y1="56.1957" x2="40.1567" y2="56.1957" gradientUnits="userSpaceOnUse">
                    <stop stop-color="#99F3FF" />
                    <stop offset="0.27" stop-color="#73DCEC" />
                    <stop offset="0.87" stop-color="#15A2BD" />
                    <stop offset="1" stop-color="#0196B3" />
                </linearGradient>
                <linearGradient id="paint6_linear_1856_72922" x1="32.0225" y1="58.3087" x2="57.9771" y2="58.3087" gradientUnits="userSpaceOnUse">
                    <stop stop-color="#99F3FF" />
                    <stop offset="0.27" stop-color="#73DCEC" />
                    <stop offset="0.87" stop-color="#15A2BD" />
                    <stop offset="1" stop-color="#0196B3" />
                </linearGradient>
                <linearGradient id="paint7_linear_1856_72922" x1="19.8419" y1="65.9331" x2="61.8067" y2="65.9331" gradientUnits="userSpaceOnUse">
                    <stop stop-color="#99F3FF" />
                    <stop offset="0.27" stop-color="#73DCEC" />
                    <stop offset="0.87" stop-color="#15A2BD" />
                    <stop offset="1" stop-color="#0196B3" />
                </linearGradient>
                <linearGradient id="paint8_linear_1856_72922" x1="207.71" y1="174.159" x2="45.7512" y2="91.7207" gradientUnits="userSpaceOnUse">
                    <stop stop-color="#99F3FF" />
                    <stop offset="0.27" stop-color="#73DCEC" />
                    <stop offset="0.87" stop-color="#15A2BD" />
                    <stop offset="1" stop-color="#0196B3" />
                </linearGradient>
            </defs>
        </svg>


    );
};

const { string } = PropTypes;

IconLogo.defaultProps = {
    className: null,
};

IconLogo.propTypes = {
    className: string,
};

export default IconLogo;
