import { string, func } from 'prop-types';
import classNames from 'classnames';
import React, { Component } from 'react';

import { createSlug } from '../../util/urlHelpers';
import { ensureListing, ensureUser } from '../../util/data';
import { formatMoney } from '../../util/currency';
import { FormattedMessage, intlShape, injectIntl } from '../../util/reactIntl';
import { lazyLoadWithDimensions } from '../../util/contextHelpers';
import { NamedLink, ResponsiveImage } from '../../components';
import { propTypes } from '../../util/types';
import { richText } from '../../util/richText';
import { types as sdkTypes } from '../../util/sdkLoader';
import config from '../../config';
import IconBuilding from '../IconBuilding/IconBuilding';
import IconGrid from '../IconGrid/IconGrid';
import IconLocation from '../IconLocation/IconLocation';
import IconRuler from '../IconRuler/IconRuler';
import IconWeight from '../IconWeight/IconWeight';

import css from './ListingCard.module.css';

const { Money } = sdkTypes;

const MIN_LENGTH_FOR_LONG_WORDS = 10;

const priceData = (price, intl, currencyRate) => {
    if (!currencyRate) {
        currencyRate = 1;
    }
    if (price && price.currency && price.amount) {
        const newPrice = price.amount * currencyRate;
        const roundedPrice = Math.round(newPrice / 100) * 100;
        price.amount = roundedPrice || 0;
        const formattedPrice = formatMoney(intl, price, 0);
        return { formattedPrice, priceTitle: formattedPrice };
    } else if (price) {
        return {
            formattedPrice: intl.formatMessage(
                { id: 'ListingCard.unsupportedPrice' },
                { currency: price.currency }
            ),
            priceTitle: intl.formatMessage(
                { id: 'ListingCard.unsupportedPriceTitle' },
                { currency: price.currency }
            ),
        };
    }
    return {};
};

class ListingImage extends Component {
    render() {
        return <ResponsiveImage {...this.props} />;
    }
}
const LazyImage = lazyLoadWithDimensions(ListingImage, {
    loadAfterInitialRendering: 3000,
});

export const ListingCardComponent = props => {
    const {
        urlQueryParams,
        className,
        rootClassName,
        intl,
        listing,
        renderSizes,
        setActiveListing,
        displayCurrency,
        currencyRate,
    } = props;
    const classes = classNames(rootClassName || css.root, className);
    const currentListing = ensureListing(listing);
    const id = currentListing.id.uuid;
    const { title = '', price, publicData } = currentListing.attributes;
    const slug = createSlug(title);
    const author = ensureUser(listing.author);
    const companyName = author.attributes.profile.publicData.companyName;
    const firstImage =
        currentListing.images && currentListing.images.length > 0
            ? currentListing.images[0]
            : null;

    const unitTranslationKey = 'ListingCard.perDay';

    if (displayCurrency && currencyRate) {
        price.currency = displayCurrency;
    }

    const { specifications, location, maxMTOW, pricing = [] } =
        publicData || {};
    const airportLocation = location?.address;

    const {
        buildingHeight,
        buildingWidth,
        hangarPurpose,
        buildingLength,
        numberOfAvailableSpace,
    } = specifications || {};

    // TODO: check when removing price, does default price change
    // DEFAULT PRICE
    const { formattedPrice: defaultFormattedPrice } = priceData(
        price,
        intl,
        currencyRate
    );

    // PRICE FROM MTOW FILTER
    const filteredMTOWValue = urlQueryParams?.pub_maxMTOW?.split(',')[0];
    const mtowRangeFromListing = pricing.find(
        _p =>
            _p.rangeFrom <= filteredMTOWValue && filteredMTOWValue <= _p.rangeTo
    );

    const formattedRangePrice =
        mtowRangeFromListing && mtowRangeFromListing.price && priceData(
            new Money(mtowRangeFromListing.price, price.currency),
            intl,
            currencyRate
        );

    // PRICE FROM PRICE FILTER
    const isPriceFiltered = urlQueryParams?.price;

    // DYNAMIC MIN TO MAX PRICE RANGE
    const maxPrice = pricing.sort((a, b) => (a.price > b.price ? -1 : 1))[0]
        ?.price;
    const minPrice = pricing.sort((a, b) => (a.price > b.price ? 1 : -1))[0]
        ?.price;
    const isMinMaxSame = maxPrice === minPrice;

    const { formattedPrice: maxFormattedPrice } = priceData(
        new Money(maxPrice, price.currency),
        intl,
        currencyRate
    );
    const { formattedPrice: minFormattedPrice } = priceData(
        new Money(minPrice, price.currency),
        intl,
        currencyRate
    );

    // assigning respective price to show by given conditions
    const formattedDynamicPriceRange = formattedRangePrice
        ? formattedRangePrice.formattedPrice
        : isPriceFiltered
            ? defaultFormattedPrice
            : isMinMaxSame
                ? maxFormattedPrice
                : `${minFormattedPrice} - ${maxFormattedPrice
                //     .slice(
                //       4,
                //       maxFormattedPrice.length
                //   )
                }`;

    const hangerDimensions = intl.formatMessage(
        {
            id: 'ListingCard.hangerDimensions',
        },
        {
            buildingLength,
            buildingWidth,
            buildingHeight,
        }
    );
    const maxMTOWValue = intl.formatMessage(
        {
            id: 'ListingCard.maxMTOW',
        },
        {
            maxMTOW,
        }
    );
    const availabelSurfaceValue = intl.formatMessage(
        {
            id: 'ListingCard.availabelSurface',
        },
        {
            numberOfAvailableSpace,
        }
    );

    return (
        <NamedLink className={classes} name="ListingPage" params={{ id, slug }}>
            <div
                className={css.threeToTwoWrapper}
                onMouseEnter={() => setActiveListing(currentListing.id)}
                onMouseLeave={() => setActiveListing(null)}>
                <div className={css.aspectWrapper}>
                    <LazyImage
                        rootClassName={css.rootForImage}
                        alt={title}
                        image={firstImage}
                        variants={['landscape-crop', 'landscape-crop2x']}
                        sizes={renderSizes}
                    />
                </div>
            </div>
            <div className={css.info}>
                <div className={css.mainInfo}>
                    <div className={css.title}>
                        {richText(title, {
                            longWordMinLength: MIN_LENGTH_FOR_LONG_WORDS,
                            longWordClass: css.longWord,
                        })}
                    </div>
                    <div className={css.authorInfo}>
                        {companyName && (
                            <FormattedMessage
                                id="ListingCard.hostedBy"
                                values={{ authorName: companyName }}
                            />
                        )}
                    </div>
                </div>
                <div className={css.details}>
                    <div className={css.detailRow}>
                        <div className={css.infoIcon}>
                            <IconLocation />
                        </div>
                        {airportLocation}
                    </div>
                    <div className={css.detailRow}>
                        <div className={css.detailRow}>
                            <div className={css.infoIcon}>
                                <IconBuilding />
                            </div>
                            {hangarPurpose}
                        </div>
                        <div className={css.detailRow}>
                            <div className={css.infoIcon}>
                                <IconGrid />
                            </div>
                            {availabelSurfaceValue}
                        </div>
                    </div>

                    <div className={css.detailRow}>
                        <div className={css.detailRow}>
                            <div className={css.infoIcon}>
                                <IconRuler />
                            </div>
                            {hangerDimensions}
                        </div>

                        <div className={css.detailRow}>
                            <div className={css.infoIcon}>
                                <IconWeight />
                            </div>
                            {maxMTOWValue}
                        </div>
                    </div>
                </div>
                <div className={css.price}>
                    <div
                        className={css.priceValue}
                        title={formattedDynamicPriceRange}>
                        {formattedDynamicPriceRange}
                        <span> *</span>
                    </div>
                    <div className={css.perUnit}>
                        <FormattedMessage id={unitTranslationKey} />
                    </div>
                </div>
            </div>
        </NamedLink>
    );
};

ListingCardComponent.defaultProps = {
    className: null,
    rootClassName: null,
    renderSizes: null,
    setActiveListing: () => null,
};

ListingCardComponent.propTypes = {
    className: string,
    rootClassName: string,
    intl: intlShape.isRequired,
    listing: propTypes.listing.isRequired,

    // Responsive image sizes hint
    renderSizes: string,

    setActiveListing: func,
};

export default injectIntl(ListingCardComponent);
