import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { propTypes } from '../../util/types';
import { injectIntl, intlShape } from '../../util/reactIntl';
import tooltipIcon from "./TooltipIcon.png"
import css from './Tooltip.module.css';

const Tooltip = props => {
    const { intl, messageId } = props;

    const tooltipMessageText = intl.formatMessage(
        { id: messageId }
    );

    return (<>
        <div className={css.tooltip}>
            <img src={tooltipIcon}></img>
            <span className={css.tooltiptext}>{tooltipMessageText}</span>
        </div>
    </>);
};

Tooltip.defaultProps = {
    tooltipMessageText: 'N/A',
};

const { string } = PropTypes;

Tooltip.propTypes = {
    tooltipMessageText: string,
    intl: intlShape.isRequired,
};

export default injectIntl(Tooltip);
