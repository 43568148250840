import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

const IconBuilding = props => {
    const { className, rootClassName } = props;
    const classes = classNames(rootClassName, className);

    return (
        <svg
            className={classes}
            width="18"
            height="16"
            viewBox="0 0 18 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg">
            <path
                d="M8.94501 0.981445L16.3992 5.82665V14.3989H1.49084V5.82665L8.94501 0.981445Z"
                fill="none"
                stroke="#808080"
                strokeWidth="1.49083"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M7.45419 14.3991V6.57227L4.47252 8.43581V14.3991M10.4359 14.3991V6.57227L13.4175 8.43581V14.3991M15.2811 14.3991H2.98169"
                fill="none"
                stroke="#808080"
                strokeWidth="1.49083"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    );
};

const { string } = PropTypes;

IconBuilding.defaultProps = {
    className: null,
    rootClassName: null,
};

IconBuilding.propTypes = {
    className: string,
    rootClassName: string,
};

export default IconBuilding;
