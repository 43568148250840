import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { FormattedMessage } from '../../util/reactIntl';

import { LISTING_STATE_DRAFT } from '../../util/types';
import { ensureListing } from '../../util/data';
import { EditListingFeaturesForm } from '../../forms';
import { ListingLink } from '../../components';

import css from './EditListingFeaturesPanel.module.css';

const FEATURES_NAME = 'specifications';

const EditListingFeaturesPanel = props => {
    const {
        rootClassName,
        className,
        listing,
        disabled,
        ready,
        onSubmit,
        onChange,
        submitButtonText,
        panelUpdated,
        updateInProgress,
        errors,
    } = props;

    const classes = classNames(rootClassName || css.root, className);
    const currentListing = ensureListing(listing);
    const { publicData } = currentListing.attributes;

    const isPublished =
        currentListing.id &&
        currentListing.attributes.state !== LISTING_STATE_DRAFT;
    const panelTitle = isPublished ? (
        <FormattedMessage
            id="EditListingFeaturesPanel.title"
            values={{ listingTitle: <ListingLink listing={listing} /> }}
        />
    ) : (
        <FormattedMessage id="EditListingFeaturesPanel.createListingTitle" />
    );

    const typeOfAccommodation = publicData && publicData.specifications && publicData.specifications.typeOfAccommodation;
    const numberOfAvailableSpace = publicData && publicData.specifications && publicData.specifications.numberOfAvailableSpace;
    const hangarPurpose = publicData && publicData.specifications && publicData.specifications.hangarPurpose;
    const buildingYear = publicData && publicData.specifications && publicData.specifications.buildingYear;
    const buildingLength = publicData && publicData.specifications && publicData.specifications.buildingLength;
    const buildingWidth = publicData && publicData.specifications && publicData.specifications.buildingWidth;
    const buildingHeight = publicData && publicData.specifications && publicData.specifications.buildingHeight;
    const maxMTOW = publicData && [publicData.maxMTOW];
    const makeOptions = publicData && publicData.specifications && publicData.specifications.makeOptions;
    const typeOfAircraft = publicData && publicData.specifications && publicData.specifications.typeOfAircraft;
    const thingsToKnow = publicData && publicData.specifications && publicData.specifications.thingsToKnow;
    const restrictions = publicData && publicData.specifications && publicData.specifications.restrictions;

    const initialValues = { typeOfAccommodation: typeOfAccommodation ? typeOfAccommodation : 'Hangar', numberOfAvailableSpace, hangarPurpose, buildingYear, buildingLength, buildingWidth, buildingHeight, maxMTOW: maxMTOW ? maxMTOW : 0, makeOptions, typeOfAircraft, thingsToKnow, restrictions };

    return (
        <div className={classes}>
            <h1 className={css.title}>{panelTitle}</h1>
            <EditListingFeaturesForm
                className={css.form}
                name={FEATURES_NAME}
                initialValues={initialValues}
                onSubmit={values => {
                    const { typeOfAccommodation = 'Hangar', numberOfAvailableSpace, hangarPurpose, buildingYear, buildingLength, buildingWidth, buildingHeight, maxMTOW, makeOptions, typeOfAircraft, thingsToKnow, restrictions } = values;

                    const updatedValues = {
                        publicData: { maxMTOW: maxMTOW[0], specifications: { typeOfAccommodation, numberOfAvailableSpace, hangarPurpose, buildingYear, buildingLength, buildingWidth, buildingHeight, makeOptions, typeOfAircraft, thingsToKnow, restrictions } },
                    };
                    onSubmit(updatedValues);
                }}
                onChange={onChange}
                saveActionMsg={submitButtonText}
                disabled={disabled}
                ready={ready}
                updated={panelUpdated}
                updateInProgress={updateInProgress}
                fetchErrors={errors}
            />
        </div>
    );
};

EditListingFeaturesPanel.defaultProps = {
    rootClassName: null,
    className: null,
    listing: null,
};

const { bool, func, object, string } = PropTypes;

EditListingFeaturesPanel.propTypes = {
    rootClassName: string,
    className: string,

    // We cannot use propTypes.listing since the listing might be a draft.
    listing: object,

    disabled: bool.isRequired,
    ready: bool.isRequired,
    onSubmit: func.isRequired,
    onChange: func.isRequired,
    submitButtonText: string.isRequired,
    panelUpdated: bool.isRequired,
    updateInProgress: bool.isRequired,
    errors: object.isRequired,
};

export default EditListingFeaturesPanel;
