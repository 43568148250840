import React from 'react';
import { FormattedMessage, intlShape } from '../../util/reactIntl';
import { formatMoney } from '../../util/currency';
import { LINE_ITEM_NIGHT, LINE_ITEM_DAY, propTypes } from '../../util/types';

import css from './BookingBreakdown.module.css';

const showDayAndHour = hours => {
    let hour = hours;
    let day = 0;

    if (hour > 24) {
        day = parseInt(hour / 24);
        hour = parseInt(hour % 24);
    } else {
        hour = parseInt(hour);

        if (hour === 24) {
            hour = 0;
            day += 1;
        }
    }

    const result = [];

    if (day) {
        result.push(`${day} ${day > 1 ? 'Days' : 'Day'}`);
    }
    if (hour) {
        result.push(`${hour} ${hour > 1 ? 'Hrs' : 'Hr'}`);
    }

    return result.join(', ');
};

const LineItemBasePriceMaybe = props => {
    const { transaction, unitType, intl } = props;
    const isNightly = unitType === LINE_ITEM_NIGHT;
    const isDaily = unitType === LINE_ITEM_DAY;
    const translationKey = isNightly
        ? 'BookingBreakdown.baseUnitNight'
        : isDaily
        ? 'BookingBreakdown.baseUnitDay'
        : 'BookingBreakdown.baseUnitQuantity';

    // Find correct line-item for given unitType prop.
    // It should be one of the following: 'line-item/night, 'line-item/day', 'line-item/units', or 'line-item/time'
    // These are defined in '../../util/types';
    const unitPurchase = transaction.attributes.lineItems.find(
        item => item.code === unitType && !item.reversal
    );

    const quantity = unitPurchase ? unitPurchase.quantity.toString() : null;
    const unitPrice = unitPurchase
        ? formatMoney(intl, unitPurchase.unitPrice)
        : null;
    const total = unitPurchase
        ? formatMoney(intl, unitPurchase.lineTotal)
        : null;
    const dayAndHour = showDayAndHour(quantity);

    return quantity && total ? (
        <div className={css.lineItem}>
            <span className={css.itemLabel}>
                <FormattedMessage
                    id={translationKey}
                    values={{ unitPrice, dayAndHour }}
                />
            </span>
            <span className={css.itemValue}>{total}</span>
        </div>
    ) : null;
};

LineItemBasePriceMaybe.propTypes = {
    transaction: propTypes.transaction.isRequired,
    unitType: propTypes.bookingUnitType.isRequired,
    intl: intlShape.isRequired,
};

export default LineItemBasePriceMaybe;
