import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { FormattedMessage, injectIntl, intlShape } from '../../util/reactIntl';
import { Form as FinalForm } from 'react-final-form';
import classNames from 'classnames';
import * as validators from '../../util/validators';
import { Form, PrimaryButton, FieldTextInput } from '../../components';

import css from './SignupForm.module.css';

const KEY_CODE_ENTER = 13;

const SignupFormComponent = props => (
    <FinalForm
        {...props}
        render={fieldRenderProps => {
            const {
                rootClassName,
                className,
                formId,
                handleSubmit,
                inProgress,
                invalid,
                intl,
                onOpenTermsOfService,
            } = fieldRenderProps;

            const [accepted, setAccepted] = useState(false);

            // email
            const emailLabel = intl.formatMessage({
                id: 'SignupForm.emailLabel',
            });
            const emailPlaceholder = intl.formatMessage({
                id: 'SignupForm.emailPlaceholder',
            });
            const emailRequiredMessage = intl.formatMessage({
                id: 'SignupForm.emailRequired',
            });
            const emailRequired = validators.required(emailRequiredMessage);
            const emailInvalidMessage = intl.formatMessage({
                id: 'SignupForm.emailInvalid',
            });
            const emailValid = validators.emailFormatValid(emailInvalidMessage);

            // companyName
            const companyNameLabel = intl.formatMessage({
                id: 'SignupForm.companyNameLabel',
            });
            const companyNamePlaceholder = intl.formatMessage({
                id: 'SignupForm.companyNamePlaceholder',
            });
            const companyNameRequiredMessage = intl.formatMessage({
                id: 'SignupForm.companyNameRequired',
            });
            const companyNameRequired = validators.required(companyNameRequiredMessage);

            // password
            const passwordLabel = intl.formatMessage({
                id: 'SignupForm.passwordLabel',
            });
            const passwordPlaceholder = intl.formatMessage({
                id: 'SignupForm.passwordPlaceholder',
            });
            const passwordRequiredMessage = intl.formatMessage({
                id: 'SignupForm.passwordRequired',
            });
            const passwordMinLengthMessage = intl.formatMessage(
                {
                    id: 'SignupForm.passwordTooShort',
                },
                {
                    minLength: validators.PASSWORD_MIN_LENGTH,
                }
            );
            const passwordMaxLengthMessage = intl.formatMessage(
                {
                    id: 'SignupForm.passwordTooLong',
                },
                {
                    maxLength: validators.PASSWORD_MAX_LENGTH,
                }
            );
            const passwordMinLength = validators.minLength(
                passwordMinLengthMessage,
                validators.PASSWORD_MIN_LENGTH
            );
            const passwordMaxLength = validators.maxLength(
                passwordMaxLengthMessage,
                validators.PASSWORD_MAX_LENGTH
            );
            const passwordRequired = validators.requiredStringNoTrim(
                passwordRequiredMessage
            );
            const passwordValidators = validators.composeValidators(
                passwordRequired,
                passwordMinLength,
                passwordMaxLength
            );

            // firstName
            const firstNameLabel = intl.formatMessage({
                id: 'SignupForm.firstNameLabel',
            });
            const firstNamePlaceholder = intl.formatMessage({
                id: 'SignupForm.firstNamePlaceholder',
            });
            const firstNameRequiredMessage = intl.formatMessage({
                id: 'SignupForm.firstNameRequired',
            });
            const firstNameRequired = validators.required(
                firstNameRequiredMessage
            );

            // lastName
            const lastNameLabel = intl.formatMessage({
                id: 'SignupForm.lastNameLabel',
            });
            const lastNamePlaceholder = intl.formatMessage({
                id: 'SignupForm.lastNamePlaceholder',
            });
            const lastNameRequiredMessage = intl.formatMessage({
                id: 'SignupForm.lastNameRequired',
            });
            const lastNameRequired = validators.required(
                lastNameRequiredMessage
            );

            const classes = classNames(rootClassName || css.root, className);
            const submitInProgress = inProgress;
            const submitDisabled = invalid || submitInProgress;

            const handleTermsKeyUp = e => {
                // Allow click action with keyboard like with normal links
                if (e.keyCode === KEY_CODE_ENTER) {
                    onOpenTermsOfService();
                }
            };
            const termsLink = (
                <span
                    className={css.termsLink}
                    onClick={() => window.open(
                        '/static/assets/pdf/TermsOfService.pdf',
                        '_blank'
                    )}
                    role="button"
                    tabIndex="0"
                    style={{color: '#30b3ca'}}
                    onKeyUp={handleTermsKeyUp}>
                    <FormattedMessage id="ConfirmSignupForm.termsLinkText" />
                </span>
            );

            const conditionsLink = (
                <span
                    className={css.termsLink}
                    onClick={() => window.open(
                        '/static/assets/pdf/PrivacyPolicy.pdf',
                        '_blank'
                    )}
                    role="button"
                    tabIndex="0"
                    style={{color: '#30b3ca'}}
                    onKeyUp={handleTermsKeyUp}>
                    <FormattedMessage id="ConfirmSignupForm.conditionsLinkText" />
                </span>
            );

            return (
                <Form className={classes} onSubmit={handleSubmit}>
                    <div>
                        <FieldTextInput
                            type="email"
                            id={formId ? `${formId}.email` : 'email'}
                            name="email"
                            autoComplete="email"
                            label={emailLabel}
                            placeholder={emailPlaceholder}
                            validate={validators.composeValidators(
                                emailRequired,
                                emailValid
                            )}
                        />
                        <div style={{ marginTop: '20px' }}>
                            <FieldTextInput
                                type="text"
                                id={formId ? `${formId}.companyName` : 'companyName'}
                                name="companyName"
                                label={companyNameLabel}
                                placeholder={companyNamePlaceholder}
                                validate={validators.composeValidators(
                                    companyNameRequired
                                )}
                            />
                        </div>
                        <div className={css.name}>
                            <FieldTextInput
                                className={css.firstNameRoot}
                                type="text"
                                id={formId ? `${formId}.fname` : 'fname'}
                                name="fname"
                                autoComplete="given-name"
                                label={firstNameLabel}
                                placeholder={firstNamePlaceholder}
                                validate={firstNameRequired}
                            />
                            <FieldTextInput
                                className={css.lastNameRoot}
                                type="text"
                                id={formId ? `${formId}.lname` : 'lname'}
                                name="lname"
                                autoComplete="family-name"
                                label={lastNameLabel}
                                placeholder={lastNamePlaceholder}
                                validate={lastNameRequired}
                            />
                        </div>
                        <FieldTextInput
                            className={css.password}
                            type="password"
                            id={formId ? `${formId}.password` : 'password'}
                            name="password"
                            autoComplete="new-password"
                            label={passwordLabel}
                            placeholder={passwordPlaceholder}
                            validate={passwordValidators}
                        />
                    </div>

                    <div className={css.bottomWrapper}>
                        <p style={{ display: 'flex', alignItems: 'center' }} className={css.bottomWrapperText}>
                            <input
                                style={{ width: '16px', marginRight: '12px' }}
                                type='checkbox'
                                value={accepted}
                                onChange={(e) => setAccepted(!accepted)}
                            ></input>
                            {/* <span className={css.termsText}>
                                <FormattedMessage
                                    id="SignupForm.termsAndConditionsAcceptText"
                                    values={{ termsLink }}
                                />
                            </span> */}
                            <span>
                                <FormattedMessage
                                    id="ConfirmSignupForm.termsAndConditionsCheckBox"
                                /> {termsLink} and {conditionsLink}
                            </span>
                        </p>
                        <PrimaryButton
                            type="submit"
                            inProgress={submitInProgress}
                            disabled={submitDisabled || !accepted}>
                            <FormattedMessage id="SignupForm.signUp" />
                        </PrimaryButton>
                    </div>
                </Form>
            );
        }}
    />
);

SignupFormComponent.defaultProps = { inProgress: false };

const { bool, func } = PropTypes;

SignupFormComponent.propTypes = {
    inProgress: bool,

    onOpenTermsOfService: func.isRequired,

    // from injectIntl
    intl: intlShape.isRequired,
};

const SignupForm = compose(injectIntl)(SignupFormComponent);
SignupForm.displayName = 'SignupForm';

export default SignupForm;
