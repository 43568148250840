/*
 * Marketplace specific configuration.
 *
 * Every filter needs to have following keys:
 * - id:     Unique id of the filter.
 * - label:  The default label of the filter.
 * - type:   String that represents one of the existing filter components:
 *           BookingDateRangeFilter, KeywordFilter, PriceFilter,
 *           SelectSingleFilter, SelectMultipleFilter.
 * - group:  Is this 'primary' or 'secondary' filter?
 *           Primary filters are visible on desktop layout by default.
 *           Secondary filters are behind "More filters" button.
 *           Read more from src/containers/SearchPage/README.md
 * - queryParamNames: Describes parameters to be used with queries
 *                    (e.g. 'price' or 'pub_amenities'). Most of these are
 *                    the same between webapp URLs and API query params.
 *                    You can't change 'dates', 'price', or 'keywords'
 *                    since those filters are fixed to a specific attribute.
 * - config: Extra configuration that the filter component needs.
 *
 * Note 1: Labels could be tied to translation file
 *         by importing FormattedMessage:
 *         <FormattedMessage id="some.translation.key.here" />
 *
 * Note 2: If you need to add new custom filter components,
 *         you need to take those into use in:
 *         src/containers/SearchPage/FilterComponent.js
 *
 * Note 3: If you just want to create more enum filters
 *         (i.e. SelectSingleFilter, SelectMultipleFilter),
 *         you can just add more configurations with those filter types
 *         and tie them with correct extended data key
 *         (i.e. pub_<key> or meta_<key>).
 */

import { AIRCRAFT_LIST } from './data/aircrafts';
import { AIRPORT_ICAO_LIST } from './data/airports';

export const filters = [
    {
        id: 'dates',
        label: 'Dates',
        type: 'BookingDateRangeFilter',
        group: 'primary',
        // Note: BookingDateRangeFilter is fixed filter,
        // you can't change "queryParamNames: ['dates'],"
        queryParamNames: ['dates'],
        config: {},
    },
    {
        id: 'airCraft',
        label: 'Aircraft Model',
        type: 'SelectNestedSingleFilter',
        infoTextId: 'AirCraftFilterForm.infoText',
        group: 'primary',
        queryParamNames: ['pub_maxMTOW', 'make'],
        config: {
            max: 200,
            options: AIRCRAFT_LIST,
            primaryQueryName: 'make',
            parsePrimaryOptions: _options =>
                [...new Set(_options.map(({ make }) => make))].map(_cat => ({
                    key: _cat,
                    label: _cat,
                })),
            parseSecondaryOptions: (_selectedKey, _options) => {
                if (!_selectedKey) {
                    return _options.map(({ mtowT, model }) => ({
                        key: mtowT,
                        label: model,
                    }));
                }

                return _options
                    .filter(_op => _op.make === _selectedKey)
                    .map(({ mtowT, model }) => ({
                        key: mtowT,
                        label: model,
                    }));
            },
        },
    },
    {
        id: 'mtowKg',
        label: 'MTOW',
        type: 'RangeFilter',
        infoTextId: 'MTOWRangeFilterForm.infoText',
        group: 'primary',
        queryParamNames: ['pub_maxMTOW'],
        labelSelectedButtonId: 'RangeFilter.labelHideMaxSelectedButton',
        labelFilterFormId: 'RangeFilterForm.label',
        config: {
            min: 0,
            max: 200,
            hideMax: true,
            step: 1,
        },
    },

    // {
    //     id: 'keyword',
    //     label: 'Keyword',
    //     type: 'KeywordFilter',
    //     group: 'primary',
    //     // Note: KeywordFilter is fixed filter,
    //     // you can't change "queryParamNames: ['keywords'],"
    //     queryParamNames: ['keywords'],
    //     // NOTE: If you are ordering search results by distance
    //     // the keyword search can't be used at the same time.
    //     // You can turn on/off ordering by distance from config.js file.
    //     config: {},
    // },
    {
        id: 'price',
        label: 'Price',
        type: 'PriceFilter',
        group: 'primary',
        infoTextId: 'PriceFilterForm.infoText',
        // Note: PriceFilter is fixed filter,
        // you can't change "queryParamNames: ['price'],"
        queryParamNames: ['price'],
        // Price filter configuration
        // Note: unlike most prices this is not handled in subunits
        config: {
            min: 0,
            max: 1000,
            step: 1,
        },
    },
    {
        id: 'terms',
        label: 'Terms',
        type: 'SelectMultipleFilter',
        group: 'secondary',
        queryParamNames: ['pub_terms'],
        config: {
            // Optional modes: 'has_all', 'has_any'
            // https://www.sharetribe.com/api-reference/marketplace.html#extended-data-filtering
            searchMode: 'has_all',

            // "key" is the option you see in Flex Console.
            // "label" is set here for this web app's UI only.
            // Note: label is not added through the translation files
            // to make filter customizations a bit easier.
            options: [
                {
                    key: 'hurrican_proof',
                    label: 'Hurrican proof',
                },
                {
                    key: 'allow_stacking',
                    label: 'Allow stacking',
                },
                {
                    key: 'maintenance_posibility',
                    label: 'Maintenance posibility',
                },
                {
                    key: 'fire_suppression',
                    label: 'Fire suppression',
                },
                {
                    key: 'airconditioned',
                    label: 'Airconditioned',
                },
                {
                    key: 'heated',
                    label: 'Heated',
                },
                {
                    key: 'towing_included',
                    label: 'Towing included',
                },
                {
                    key: 'security_available',
                    label: 'Security available?',
                },
                {
                    key: 'depart_staright_from_hangar',
                    label: 'Depart staright from hangar?',
                },
            ],
        },
    },
    {
        id: 'airportCodes',
        label: 'Airport Codes',
        type: '_',
        group: 'secondary',
        queryParamNames: ['pub_airportCodes'],
        config: {
            // Optional modes: 'has_all', 'has_any'
            // https://www.sharetribe.com/api-reference/marketplace.html#extended-data-filtering
            searchMode: 'has_all',

            // "key" is the option you see in Flex Console.
            // "label" is set here for this web app's UI only.
            // Note: label is not added through the translation files
            // to make filter customizations a bit easier.
            options: AIRPORT_ICAO_LIST.slice(0, 10).map(_c => ({
                key: _c,
                label: _c,
            })),
        },
    },
];

export const sortConfig = {
    // Enable/disable the sorting control in the SearchPage
    active: true,

    // Note: queryParamName 'sort' is fixed,
    // you can't change it since Flex API expects it to be named as 'sort'
    queryParamName: 'sort',

    // Internal key for the relevance option, see notes below.
    relevanceKey: 'relevance',

    // Keyword filter is sorting the results already by relevance.
    // If keyword filter is active, we need to disable sorting.
    conflictingFilters: ['keyword'],

    options: [
        { key: 'createdAt', label: 'Newest' },
        { key: '-createdAt', label: 'Oldest' },
        { key: '-price', label: 'Lowest price' },
        { key: 'price', label: 'Highest price' },

        // The relevance is only used for keyword search, but the
        // parameter isn't sent to the Marketplace API. The key is purely
        // for handling the internal state of the sorting dropdown.
        {
            key: 'relevance',
            label: 'Relevance',
            longLabel: 'Relevance (Keyword search)',
        },
    ],
};
