import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { propTypes } from '../../util/types';
import { FormattedMessage } from '../../util/reactIntl';
import { ListingCard, PaginationLinks } from '../../components';
import css from './SearchResultsPanel.module.css';

const SearchResultsPanel = props => {
    const {
        urlQueryParams,
        className,
        rootClassName,
        listings,
        pagination,
        search,
        setActiveListing,
        currentUser,
        currencyRate,
    } = props;

    const classes = classNames(rootClassName || css.root, className);
    const displayCurrency = currentUser?.attributes.profile.publicData.displayCurrency || (typeof window !== 'undefined' && localStorage.getItem("displayCurrency"));
    const paginationLinks =
        pagination && pagination.totalPages > 1 ? (
            <PaginationLinks
                className={css.pagination}
                pageName="SearchPage"
                pageSearchParams={search}
                pagination={pagination}
            />
        ) : null;

    // Panel width relative to the viewport
    const panelMediumWidth = 50;
    const panelLargeWidth = 62.5;
    const cardRenderSizes = [
        '(max-width: 767px) 100vw',
        `(max-width: 1023px) ${panelMediumWidth}vw`,
        `(max-width: 1920px) ${panelLargeWidth / 2}vw`,
        `${panelLargeWidth / 3}vw`,
    ].join(', ');

    return (
        <div className={classes}>
            <div className={css.listingCards}>
                <p className={css.estimatePrice}>
                    <div>* </div>
                    <FormattedMessage id="ListingPage.estimatePriceTip" />
                </p>
                {listings.map(l => (
                    <ListingCard
                        urlQueryParams={urlQueryParams}
                        className={css.listingCard}
                        key={l.id.uuid}
                        listing={l}
                        renderSizes={cardRenderSizes}
                        setActiveListing={setActiveListing}
                        displayCurrency={displayCurrency}
                        currencyRate={currencyRate}
                    />
                ))}
                {props.children}
            </div>
            {paginationLinks}
        </div>
    );
};

SearchResultsPanel.defaultProps = {
    children: null,
    className: null,
    listings: [],
    pagination: null,
    rootClassName: null,
    search: null,
};

const { array, node, object, string } = PropTypes;

SearchResultsPanel.propTypes = {
    children: node,
    className: string,
    listings: array,
    pagination: propTypes.pagination,
    rootClassName: string,
    search: object,
};

export default SearchResultsPanel;
