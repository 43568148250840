export const AIRCRAFT_LIST = [
    {
        make: 'Airbus',
        type: 'BD-500',
        model: 'A220-100',
        wingSpanM: '35,1',
        lengthM: '34,9',
        height: '11,8',
        mtowKG: 60.781,
        mtowT: 61,
        calcSurface: 919
    },
    {
        make: 'Airbus',
        type: 'BD-500',
        model: 'A220-300',
        wingSpanM: '35,1',
        lengthM: '38,7',
        height: '11,5',
        mtowKG: 67.858,
        mtowT: 68,
        calcSurface: 1.019
    },
    {
        make: 'Airbus',
        type: 'A318',
        model: 'A318-111',
        wingSpanM: '34,1',
        lengthM: '31,45',
        height: '12,51',
        mtowKG: 68,
        mtowT: 68,
        calcSurface: 804
    },
    {
        make: 'Airbus',
        type: 'A318-ACJ',
        model: 'A318-112',
        wingSpanM: '34,1',
        lengthM: '31,45',
        height: '12,51',
        mtowKG: 68,
        mtowT: 68,
        calcSurface: 804
    },
    {
        make: 'Airbus',
        type: 'A318',
        model: 'A318-121',
        wingSpanM: '34,1',
        lengthM: '31,45',
        height: '12,51',
        mtowKG: 68,
        mtowT: 68,
        calcSurface: 804
    },
    {
        make: 'Airbus',
        type: 'A318',
        model: 'A318-122',
        wingSpanM: '34,1',
        lengthM: '31,45',
        height: '12,51',
        mtowKG: 68,
        mtowT: 68,
        calcSurface: 804
    },
    {
        make: 'Airbus',
        type: 'A319',
        model: 'A319-CEO',
        wingSpanM: '34,1',
        lengthM: '33,84',
        height: '11,76',
        mtowKG: 75.5,
        mtowT: 76,
        calcSurface: 909
    },
    {
        make: 'Airbus',
        type: 'A319',
        model: 'A319-NEO',
        wingSpanM: '34,1',
        lengthM: '33,84',
        height: '11,76',
        mtowKG: 75.5,
        mtowT: 76,
        calcSurface: 909
    },
    {
        make: 'Airbus',
        type: 'A320',
        model: 'A320-CEO',
        wingSpanM: '34,1',
        lengthM: '37,57',
        height: '11,76',
        mtowKG: 78,
        mtowT: 68,
        calcSurface: 909
    },
    {
        make: 'Airbus',
        type: 'A320',
        model: 'A320-NEO',
        wingSpanM: '34,1',
        lengthM: '37,57',
        height: '11,76',
        mtowKG: 78,
        mtowT: 68,
        calcSurface: 909
    },
    {
        make: 'Airbus',
        type: 'A320',
        model: 'A320-215',
        wingSpanM: '35,8',
        lengthM: '37,57',
        height: '11,76',
        mtowKG: 75.5,
        mtowT: 76,
        calcSurface: 1.009
    },
    {
        make: 'Airbus',
        type: 'A320',
        model: 'A320-216',
        wingSpanM: '35,8',
        lengthM: '37,57',
        height: '11,76',
        mtowKG: 75.5,
        mtowT: 76,
        calcSurface: 1.009
    },
    {
        make: 'Airbus',
        type: 'A319',
        model: 'A319-111',
        wingSpanM: '35,8',
        lengthM: '33,84',
        height: '11,76',
        mtowKG: 75.5,
        mtowT: 76,
        calcSurface: 909
    },
    {
        make: 'Airbus',
        type: 'A319',
        model: 'A319-112',
        wingSpanM: '35,8',
        lengthM: '33,84',
        height: '11,76',
        mtowKG: 75.5,
        mtowT: 76,
        calcSurface: 909
    },
    {
        make: 'Airbus',
        type: 'A319',
        model: 'A319-113',
        wingSpanM: '35,8',
        lengthM: '33,84',
        height: '11,76',
        mtowKG: 75.5,
        mtowT: 76,
        calcSurface: 909
    },
    {
        make: 'Airbus',
        type: 'A319',
        model: 'A319-114',
        wingSpanM: '35,8',
        lengthM: '33,84',
        height: '11,76',
        mtowKG: 75.5,
        mtowT: 76,
        calcSurface: 909
    },
    {
        make: 'Airbus',
        type: 'A319',
        model: 'A319-115',
        wingSpanM: '35,8',
        lengthM: '33,84',
        height: '11,76',
        mtowKG: 75.5,
        mtowT: 76,
        calcSurface: 909
    },
    {
        make: 'Airbus',
        type: 'A319',
        model: 'A319-131',
        wingSpanM: '35,8',
        lengthM: '33,84',
        height: '11,76',
        mtowKG: 75.5,
        mtowT: 76,
        calcSurface: 909
    },
    {
        make: 'Airbus',
        type: 'A319',
        model: 'A319-132',
        wingSpanM: '35,8',
        lengthM: '33,84',
        height: '11,76',
        mtowKG: 75.5,
        mtowT: 76,
        calcSurface: 909
    },
    {
        make: 'Airbus',
        type: 'A319',
        model: 'A319-133',
        wingSpanM: '35,8',
        lengthM: '33,84',
        height: '11,76',
        mtowKG: 75.5,
        mtowT: 76,
        calcSurface: 909
    },
    {
        make: 'Airbus',
        type: 'A319Neo',
        model: 'A319-151N',
        wingSpanM: '35,8',
        lengthM: '33,84',
        height: '11,76',
        mtowKG: 75.5,
        mtowT: 76,
        calcSurface: 909
    },
    {
        make: 'Airbus',
        type: 'A319Neo',
        model: 'A319-171N',
        wingSpanM: '35,8',
        lengthM: '33,84',
        height: '11,76',
        mtowKG: 75.5,
        mtowT: 76,
        calcSurface: 909
    },
    {
        make: 'Airbus',
        type: 'A320',
        model: 'A320-211',
        wingSpanM: '35,8',
        lengthM: '37,57',
        height: '11,76',
        mtowKG: 77,
        mtowT: 77,
        calcSurface: 1.009
    },
    {
        make: 'Airbus',
        type: 'A320',
        model: 'A320-212',
        wingSpanM: '35,8',
        lengthM: '37,57',
        height: '11,76',
        mtowKG: 77,
        mtowT: 77,
        calcSurface: 1.009
    },
    {
        make: 'Airbus',
        type: 'A320',
        model: 'A320-231',
        wingSpanM: '35,8',
        lengthM: '37,57',
        height: '11,76',
        mtowKG: 77,
        mtowT: 77,
        calcSurface: 1.009
    },
    {
        make: 'Airbus',
        type: 'A320',
        model: 'A320-214',
        wingSpanM: '35,8',
        lengthM: '37,57',
        height: '11,76',
        mtowKG: 78,
        mtowT: 78,
        calcSurface: 1.009
    },
    {
        make: 'Airbus',
        type: 'A320',
        model: 'A320-232',
        wingSpanM: '35,8',
        lengthM: '37,57',
        height: '11,76',
        mtowKG: 78,
        mtowT: 78,
        calcSurface: 1.009
    },
    {
        make: 'Airbus',
        type: 'A320',
        model: 'A320-233',
        wingSpanM: '35,8',
        lengthM: '37,57',
        height: '11,76',
        mtowKG: 78,
        mtowT: 78,
        calcSurface: 1.009
    },
    {
        make: 'Airbus',
        type: 'A320 Neo',
        model: 'A320-271N',
        wingSpanM: '35,8',
        lengthM: '37,57',
        height: '11,76',
        mtowKG: 79,
        mtowT: 79,
        calcSurface: 1.009
    },
    {
        make: 'Airbus',
        type: 'A320 Neo',
        model: 'A320-272N',
        wingSpanM: '35,8',
        lengthM: '37,57',
        height: '11,76',
        mtowKG: 79,
        mtowT: 79,
        calcSurface: 1.009
    },
    {
        make: 'Airbus',
        type: 'A320 Neo',
        model: 'A320-273N',
        wingSpanM: '35,8',
        lengthM: '37,57',
        height: '11,76',
        mtowKG: 79,
        mtowT: 79,
        calcSurface: 1.009
    },
    {
        make: 'Airbus',
        type: 'A320 Neo',
        model: 'A320-251N',
        wingSpanM: '35,8',
        lengthM: '37,57',
        height: '11,76',
        mtowKG: 79,
        mtowT: 79,
        calcSurface: 1.009
    },
    {
        make: 'Airbus',
        type: 'A320 Neo',
        model: 'A320-252N',
        wingSpanM: '35,8',
        lengthM: '37,57',
        height: '11,76',
        mtowKG: 79,
        mtowT: 79,
        calcSurface: 1.009
    },
    {
        make: 'Airbus',
        type: 'A320 Neo',
        model: 'A320-253N',
        wingSpanM: '35,8',
        lengthM: '37,57',
        height: '11,76',
        mtowKG: 79,
        mtowT: 79,
        calcSurface: 1.009
    },
    {
        make: 'Airbus',
        type: 'A320 Neo',
        model: 'A321-111',
        wingSpanM: '35,8',
        lengthM: '44,51',
        height: '11,76',
        mtowKG: 89,
        mtowT: 89,
        calcSurface: 1.195
    },
    {
        make: 'Airbus',
        type: 'A320 Neo',
        model: 'A321-112',
        wingSpanM: '35,8',
        lengthM: '44,51',
        height: '11,76',
        mtowKG: 89,
        mtowT: 89,
        calcSurface: 1.195
    },
    {
        make: 'Airbus',
        type: 'A320 Neo',
        model: 'A321-211',
        wingSpanM: '35,8',
        lengthM: '44,51',
        height: '11,76',
        mtowKG: 93,
        mtowT: 93,
        calcSurface: 1.195
    },
    {
        make: 'Airbus',
        type: 'A320 Neo',
        model: 'A321-231',
        wingSpanM: '35,8',
        lengthM: '44,51',
        height: '11,76',
        mtowKG: 93,
        mtowT: 93,
        calcSurface: 1.195
    },
    {
        make: 'Airbus',
        type: 'A320 Neo',
        model: 'A321-212',
        wingSpanM: '35,8',
        lengthM: '44,51',
        height: '11,76',
        mtowKG: 93,
        mtowT: 93,
        calcSurface: 1.195
    },
    {
        make: 'Airbus',
        type: 'A320 Neo',
        model: 'A321-213',
        wingSpanM: '35,8',
        lengthM: '44,51',
        height: '11,76',
        mtowKG: 93,
        mtowT: 93,
        calcSurface: 1.195
    },
    {
        make: 'Airbus',
        type: 'A320 Neo',
        model: 'A321-232',
        wingSpanM: '35,8',
        lengthM: '44,51',
        height: '11,76',
        mtowKG: 93,
        mtowT: 93,
        calcSurface: 1.195
    },
    {
        make: 'Airbus',
        type: 'A320 Neo',
        model: 'A321-271N',
        wingSpanM: '35,8',
        lengthM: '44,51',
        height: '11,76',
        mtowKG: 93.5,
        mtowT: 94,
        calcSurface: 1.195
    },
    {
        make: 'Airbus',
        type: 'A320 Neo',
        model: 'A321-272N',
        wingSpanM: '35,8',
        lengthM: '44,51',
        height: '11,76',
        mtowKG: 93.5,
        mtowT: 94,
        calcSurface: 1.195
    },
    {
        make: 'Airbus',
        type: 'A320 Neo',
        model: 'A321-251N',
        wingSpanM: '35,8',
        lengthM: '44,51',
        height: '11,76',
        mtowKG: 93.5,
        mtowT: 94,
        calcSurface: 1.195
    },
    {
        make: 'Airbus',
        type: 'A320 Neo',
        model: 'A321-252N',
        wingSpanM: '35,8',
        lengthM: '44,51',
        height: '11,76',
        mtowKG: 93.5,
        mtowT: 94,
        calcSurface: 1.195
    },
    {
        make: 'Airbus',
        type: 'A321Neo',
        model: 'A321-253N',
        wingSpanM: '35,8',
        lengthM: '44,51',
        height: '11,76',
        mtowKG: 93.5,
        mtowT: 94,
        calcSurface: 1.195
    },
    {
        make: 'Airbus',
        type: 'A321Neo',
        model: 'A321-271NX',
        wingSpanM: '35,8',
        lengthM: '44,51',
        height: '11,76',
        mtowKG: 97,
        mtowT: 97,
        calcSurface: 1.195
    },
    {
        make: 'Airbus',
        type: 'A321Neo',
        model: 'A321-272NX',
        wingSpanM: '35,8',
        lengthM: '44,51',
        height: '11,76',
        mtowKG: 97,
        mtowT: 97,
        calcSurface: 1.195
    },
    {
        make: 'Airbus',
        type: 'A321Neo',
        model: 'A321-251NX',
        wingSpanM: '35,8',
        lengthM: '44,51',
        height: '11,76',
        mtowKG: 97,
        mtowT: 97,
        calcSurface: 1.195
    },
    {
        make: 'Airbus',
        type: 'A321Neo',
        model: 'A321-252NX',
        wingSpanM: '35,8',
        lengthM: '44,51',
        height: '11,76',
        mtowKG: 97,
        mtowT: 97,
        calcSurface: 1.195
    },
    {
        make: 'Airbus',
        type: 'A321Neo',
        model: 'A321-253NX',
        wingSpanM: '35,8',
        lengthM: '44,51',
        height: '11,76',
        mtowKG: 97,
        mtowT: 97,
        calcSurface: 1.195
    },
    {
        make: 'Airbus',
        type: 'A300',
        model: 'A300-200',
        wingSpanM: '44,84',
        lengthM: '53,61',
        height: '16,72',
        mtowKG: 137,
        mtowT: 137,
        calcSurface: 1.803
    },
    {
        make: 'Airbus',
        type: 'A300',
        model: 'A300-600',
        wingSpanM: '44,84',
        lengthM: '54,08',
        height: '16,66',
        mtowKG: 170.5,
        mtowT: 171,
        calcSurface: 1.819
    },
    {
        make: 'Airbus',
        type: 'A330',
        model: 'A330-700L',
        wingSpanM: '60,3',
        lengthM: '63,12',
        height: '18,95',
        mtowKG: 205,
        mtowT: 205,
        calcSurface: 2.855
    },
    {
        make: 'Airbus',
        type: 'A330',
        model: 'A330-300',
        wingSpanM: '60,3',
        lengthM: '60,3',
        height: '16,83',
        mtowKG: 215,
        mtowT: 215,
        calcSurface: 2.727
    },
    {
        make: 'Airbus',
        type: 'A330',
        model: 'A330-200',
        wingSpanM: '60,3',
        lengthM: '58,82',
        height: '17,38',
        mtowKG: 230,
        mtowT: 230,
        calcSurface: 2.66
    },
    {
        make: 'Airbus',
        type: 'A330',
        model: 'A330-800',
        wingSpanM: '64',
        lengthM: '58,82',
        height: '17,38',
        mtowKG: 238,
        mtowT: 238,
        calcSurface: 2.823
    },
    {
        make: 'Airbus',
        type: 'A330',
        model: 'A330-900',
        wingSpanM: '64',
        lengthM: '63,66',
        height: '16,79',
        mtowKG: 240,
        mtowT: 240,
        calcSurface: 3.056
    },
    {
        make: 'Airbus',
        type: 'A340',
        model: 'A340-200',
        wingSpanM: '60,3',
        lengthM: '59,39',
        height: '16,83',
        mtowKG: 260,
        mtowT: 260,
        calcSurface: 2.686
    },
    {
        make: 'Airbus',
        type: 'A340',
        model: 'A340-300',
        wingSpanM: '60,3',
        lengthM: '63,66',
        height: '16,63',
        mtowKG: 275,
        mtowT: 275,
        calcSurface: 2.879
    },
    {
        make: 'Airbus',
        type: 'A350',
        model: 'A350-900',
        wingSpanM: '64,75',
        lengthM: '66,89',
        height: '17,1',
        mtowKG: 278,
        mtowT: 278,
        calcSurface: 3.248
    },
    {
        make: 'Airbus',
        type: 'A350',
        model: 'A350-1000',
        wingSpanM: '64,75',
        lengthM: '73,88',
        height: '17,1',
        mtowKG: 308,
        mtowT: 308,
        calcSurface: 3.588
    },
    {
        make: 'Airbus',
        type: 'A340',
        model: 'A340-600',
        wingSpanM: '63,45',
        lengthM: '67,33',
        height: '17,11',
        mtowKG: 368,
        mtowT: 368,
        calcSurface: 3.204
    },
    {
        make: 'Airbus',
        type: 'A340',
        model: 'A340-500',
        wingSpanM: '63,45',
        lengthM: '74,77',
        height: '17,29',
        mtowKG: 375,
        mtowT: 375,
        calcSurface: 3.558
    },
    {
        make: 'Airbus',
        type: 'A380',
        model: 'A380-800',
        wingSpanM: '80',
        lengthM: '73',
        height: '24,1',
        mtowKG: 575,
        mtowT: 575,
        calcSurface: 4.38
    },
    {
        make: 'Beechcraft',
        type: 'King Air ',
        model: 'C90 / C90GT / C90GTi',
        wingSpanM: '13,98',
        lengthM: '10,82',
        height: '4,47',
        mtowKG: 4.377,
        mtowT: 4,
        calcSurface: 113
    },
    {
        make: 'Beechcraft',
        type: 'King Air ',
        model: '100',
        wingSpanM: '14',
        lengthM: '12,17',
        height: '4,7',
        mtowKG: 5.352,
        mtowT: 5,
        calcSurface: 128
    },
    {
        make: 'Beechcraft',
        type: 'King Air ',
        model: 'B200 / B200C /',
        wingSpanM: '16,61',
        lengthM: '13,36',
        height: '4,52',
        mtowKG: 5.67,
        mtowT: 6,
        calcSurface: 166
    },
    {
        make: 'Beechcraft',
        type: 'King Air ',
        model: ' B200GT / B200CGT',
        wingSpanM: '16,6',
        lengthM: '13,3',
        height: '4,5',
        mtowKG: 5.67,
        mtowT: 6,
        calcSurface: 166
    },
    {
        make: 'Beechcraft',
        type: 'King Air ',
        model: 'B300 / B300C',
        wingSpanM: '17,6',
        lengthM: '14,2',
        height: '4,36',
        mtowKG: 6.804,
        mtowT: 7,
        calcSurface: 187
    },
    {
        make: 'Boeing',
        type: 'B737-Classic',
        model: 'B737-100',
        wingSpanM: '28,35',
        lengthM: '28,65',
        height: '11,28',
        mtowKG: 58.105,
        mtowT: 58,
        calcSurface: 609
    },
    {
        make: 'Boeing',
        type: 'B737-Classic',
        model: 'B737-200',
        wingSpanM: '28,35',
        lengthM: '30,48',
        height: '11,28',
        mtowKG: 58.105,
        mtowT: 58,
        calcSurface: 648
    },
    {
        make: 'Boeing',
        type: 'B737-Classic',
        model: 'B737-500',
        wingSpanM: '28,88',
        lengthM: '31,01',
        height: '11,13',
        mtowKG: 61.688,
        mtowT: 62,
        calcSurface: 672
    },
    {
        make: 'Boeing',
        type: 'B737-Classic',
        model: 'B737-300',
        wingSpanM: '28,88',
        lengthM: '33,4',
        height: '11,13',
        mtowKG: 63.276,
        mtowT: 63,
        calcSurface: 723
    },
    {
        make: 'Boeing',
        type: 'B737-Next Gen',
        model: 'B737-600',
        wingSpanM: '35,79',
        lengthM: '31,2',
        height: '12,57',
        mtowKG: 65.997,
        mtowT: 66,
        calcSurface: 837
    },
    {
        make: 'Boeing',
        type: 'B737-Classic',
        model: 'B737-400',
        wingSpanM: '28,88',
        lengthM: '36,45',
        height: '11,13',
        mtowKG: 68.038,
        mtowT: 68,
        calcSurface: 790
    },
    {
        make: 'Boeing',
        type: 'B737-Classic',
        model: 'B737-700',
        wingSpanM: '35,79',
        lengthM: '32,18',
        height: '12,57',
        mtowKG: 70.08,
        mtowT: 70,
        calcSurface: 864
    },
    {
        make: 'Boeing',
        type: 'BBJ1',
        model: 'B737-700',
        wingSpanM: '35,79',
        lengthM: '32,18',
        height: '12,57',
        mtowKG: 70.08,
        mtowT: 70,
        calcSurface: 864
    },
    {
        make: 'Boeing',
        type: 'B737-Classic',
        model: 'B737-700IGW',
        wingSpanM: '35,79',
        lengthM: '32,18',
        height: '12,57',
        mtowKG: 77.564,
        mtowT: 78,
        calcSurface: 864
    },
    {
        make: 'Boeing',
        type: 'B737-Next Gen',
        model: 'B737-800',
        wingSpanM: '35,79',
        lengthM: '39,5',
        height: '12,57',
        mtowKG: 79.015,
        mtowT: 79,
        calcSurface: 1.06
    },
    {
        make: 'Boeing',
        type: 'BBJ2',
        model: 'B737-800',
        wingSpanM: '35,79',
        lengthM: '39,5',
        height: '12,57',
        mtowKG: 79.015,
        mtowT: 79,
        calcSurface: 1.06
    },
    {
        make: 'Boeing',
        type: 'B737-Next Gen',
        model: 'B737-900',
        wingSpanM: '35,79',
        lengthM: '42,1',
        height: '12,57',
        mtowKG: 79.015,
        mtowT: 79,
        calcSurface: 1.13
    },
    {
        make: 'Boeing',
        type: 'B737-Max',
        model: 'B737-8',
        wingSpanM: '35,92',
        lengthM: '39,5',
        height: '12,29',
        mtowKG: 82.19,
        mtowT: 82,
        calcSurface: 1.064
    },
    {
        make: 'Boeing',
        type: 'B737-Next Gen',
        model: 'B737-900ER',
        wingSpanM: '35,79',
        lengthM: '42,1',
        height: '12,57',
        mtowKG: 85.139,
        mtowT: 85,
        calcSurface: 1.13
    },
    {
        make: 'Boeing',
        type: 'BBJ3',
        model: 'B737-900ER',
        wingSpanM: '35,79',
        lengthM: '42,1',
        height: '12,57',
        mtowKG: 85.139,
        mtowT: 85,
        calcSurface: 1.13
    },
    {
        make: 'Boeing',
        type: 'B737-Max',
        model: 'B737-9',
        wingSpanM: '35,92',
        lengthM: '42,11',
        height: '12,29',
        mtowKG: 88.314,
        mtowT: 88,
        calcSurface: 1.134
    },
    {
        make: 'Boeing',
        type: 'B757',
        model: 'B757-200PF',
        wingSpanM: '38,02',
        lengthM: '47,32',
        height: '13,74',
        mtowKG: 115.892,
        mtowT: 116,
        calcSurface: 1.349
    },
    {
        make: 'Boeing',
        type: 'B757',
        model: 'B757-200',
        wingSpanM: '38,02',
        lengthM: '47,32',
        height: '13,74',
        mtowKG: 115.893,
        mtowT: 116,
        calcSurface: 1.349
    },
    {
        make: 'Boeing',
        type: 'B757',
        model: 'B757-300',
        wingSpanM: '38,02',
        lengthM: '54,4',
        height: '13,6',
        mtowKG: 123.83,
        mtowT: 124,
        calcSurface: 1.551
    },
    {
        make: 'Boeing',
        type: 'B767',
        model: 'B767-200',
        wingSpanM: '47,57',
        lengthM: '48,51',
        height: '15,85',
        mtowKG: 179.169,
        mtowT: 179,
        calcSurface: 1.731
    },
    {
        make: 'Boeing',
        type: 'B767',
        model: 'B767-300',
        wingSpanM: '47,57',
        lengthM: '54,94',
        height: '15,85',
        mtowKG: 186.88,
        mtowT: 187,
        calcSurface: 1.96
    },
    {
        make: 'Boeing',
        type: 'B767',
        model: 'B767-300F',
        wingSpanM: '47,57',
        lengthM: '54,94',
        height: '15,85',
        mtowKG: 186.88,
        mtowT: 187,
        calcSurface: 1.96
    },
    {
        make: 'Boeing',
        type: 'B767',
        model: 'B767-400ER',
        wingSpanM: '61,4',
        lengthM: '51,9',
        height: '16,8',
        mtowKG: 204.116,
        mtowT: 204,
        calcSurface: 2.39
    },
    {
        make: 'Boeing',
        type: 'B787',
        model: 'B787-8',
        wingSpanM: '60,12',
        lengthM: '56,71',
        height: '17',
        mtowKG: 227.93,
        mtowT: 228,
        calcSurface: 2.557
    },
    {
        make: 'Boeing',
        type: 'B777',
        model: 'B777-200',
        wingSpanM: '60,9',
        lengthM: '63,7',
        height: '18,4',
        mtowKG: 247.207,
        mtowT: 247,
        calcSurface: 2.909
    },
    {
        make: 'Boeing',
        type: 'B787',
        model: 'B787-9',
        wingSpanM: '60,12',
        lengthM: '62',
        height: '17',
        mtowKG: 254.011,
        mtowT: 254,
        calcSurface: 2.796
    },
    {
        make: 'Boeing',
        type: 'B787',
        model: 'B787-10',
        wingSpanM: '60,12',
        lengthM: '68,3',
        height: '17',
        mtowKG: 254.011,
        mtowT: 254,
        calcSurface: 3.08
    },
    {
        make: 'Boeing',
        type: 'B777',
        model: 'B777-300',
        wingSpanM: '60,9',
        lengthM: '73,8',
        height: '18,5',
        mtowKG: 299.37,
        mtowT: 299,
        calcSurface: 3.371
    },
    {
        make: 'Boeing',
        type: 'B747',
        model: 'B747SP',
        wingSpanM: '59,6',
        lengthM: '56,25',
        height: '20,2',
        mtowKG: 315.7,
        mtowT: 316,
        calcSurface: 2.514
    },
    {
        make: 'Boeing',
        type: 'B747',
        model: 'B747-100',
        wingSpanM: '59,6',
        lengthM: '70,6',
        height: '19,3',
        mtowKG: 333.4,
        mtowT: 333,
        calcSurface: 3.156
    },
    {
        make: 'Boeing',
        type: 'B777',
        model: 'B777-200LR',
        wingSpanM: '64,8',
        lengthM: '63,7',
        height: '63,7',
        mtowKG: 340.194,
        mtowT: 340,
        calcSurface: 3.096
    },
    {
        make: 'Boeing',
        type: 'B777',
        model: 'B777-300ER',
        wingSpanM: '64,8',
        lengthM: '73,8',
        height: '18,5',
        mtowKG: 340.194,
        mtowT: 340,
        calcSurface: 3.587
    },
    {
        make: 'Boeing',
        type: 'B777',
        model: 'B777F',
        wingSpanM: '64,8',
        lengthM: '63,7',
        height: '18,5',
        mtowKG: 347.814,
        mtowT: 348,
        calcSurface: 3.096
    },
    {
        make: 'Boeing',
        type: 'B747',
        model: 'B747-200',
        wingSpanM: '59,6',
        lengthM: '70,6',
        height: '19,3',
        mtowKG: 377.842,
        mtowT: 378,
        calcSurface: 3.156
    },
    {
        make: 'Boeing',
        type: 'B747',
        model: 'B747-300',
        wingSpanM: '59,6',
        lengthM: '70,6',
        height: '19,3',
        mtowKG: 377.842,
        mtowT: 378,
        calcSurface: 3.156
    },
    {
        make: 'Boeing',
        type: 'B747',
        model: 'B747-400',
        wingSpanM: '64,44',
        lengthM: '70,6',
        height: '19,4',
        mtowKG: 396.893,
        mtowT: 397,
        calcSurface: 3.412
    },
    {
        make: 'Boeing',
        type: 'B747',
        model: 'B747-400F',
        wingSpanM: '64,44',
        lengthM: '70,66',
        height: '19,33',
        mtowKG: 412.769,
        mtowT: 413,
        calcSurface: 3.415
    },
    {
        make: 'Boeing',
        type: 'B747',
        model: 'B747-8F',
        wingSpanM: '68,5',
        lengthM: '76,3',
        height: '19,4',
        mtowKG: 447.695,
        mtowT: 448,
        calcSurface: 3.92
    },
    {
        make: 'Boeing',
        type: 'B747',
        model: 'B747-8',
        wingSpanM: '68,5',
        lengthM: '76,3',
        height: '19,4',
        mtowKG: 447.696,
        mtowT: 448,
        calcSurface: 3.92
    },
    {
        make: 'Bombardier',
        type: 'Learjet M45',
        model: 'Learjet 40',
        wingSpanM: '14,58',
        lengthM: '17,18',
        height: '4,39',
        mtowKG: 9.525,
        mtowT: 10,
        calcSurface: 188
    },
    {
        make: 'Bombardier',
        type: 'Learjet M45',
        model: 'Learjet 45',
        wingSpanM: '14,58',
        lengthM: '17,77',
        height: '4,39',
        mtowKG: 9.752,
        mtowT: 10,
        calcSurface: 194
    },
    {
        make: 'Bombardier',
        type: 'Learjet M45',
        model: 'Learjet 75',
        wingSpanM: '15,56',
        lengthM: '17,77',
        height: '4,39',
        mtowKG: 9.752,
        mtowT: 10,
        calcSurface: 207
    },
    {
        make: 'Bombardier',
        type: 'Learjet M45',
        model: 'Learjet 70',
        wingSpanM: '15,56',
        lengthM: '17,18',
        height: '4,39',
        mtowKG: 9.752,
        mtowT: 10,
        calcSurface: 200
    },
    {
        make: 'Bombardier',
        type: 'Learjet M60',
        model: 'Learjet 60',
        wingSpanM: '13,4',
        lengthM: '17,8',
        height: '4,36',
        mtowKG: 10.319,
        mtowT: 10,
        calcSurface: 179
    },
    {
        make: 'Bombardier',
        type: 'BD-100-1A10',
        model: 'Challenger 300',
        wingSpanM: '19,46',
        lengthM: '20,92',
        height: '6,2',
        mtowKG: 17.463,
        mtowT: 17,
        calcSurface: 305
    },
    {
        make: 'Bombardier',
        type: 'Learjet M30',
        model: 'Learjet 35',
        wingSpanM: '12,04',
        lengthM: '14,81',
        height: '3,7338',
        mtowKG: 18.3,
        mtowT: 18,
        calcSurface: 134
    },
    {
        make: 'Bombardier',
        type: 'BD-100-1A10',
        model: 'Challenger 350',
        wingSpanM: '21',
        lengthM: '20,92',
        height: '6,1',
        mtowKG: 18.416,
        mtowT: 18,
        calcSurface: 329
    },
    {
        make: 'Bombardier',
        type: 'CL-600-1A11',
        model: 'Challenger 600',
        wingSpanM: '19,61',
        lengthM: '20,94',
        height: '6,3',
        mtowKG: 18.711,
        mtowT: 19,
        calcSurface: 308
    },
    {
        make: 'Bombardier',
        type: 'CL-600-2A12',
        model: 'Challenger 601',
        wingSpanM: '19,6',
        lengthM: '20,9',
        height: '6,3',
        mtowKG: 20.457,
        mtowT: 20,
        calcSurface: 307
    },
    {
        make: 'Bombardier',
        type: 'CL-600-2B16',
        model: 'Challgener 604',
        wingSpanM: '19,6',
        lengthM: '20,9',
        height: '6,3',
        mtowKG: 21.863,
        mtowT: 22,
        calcSurface: 307
    },
    {
        make: 'Bombardier',
        type: 'CL-600-2B16',
        model: 'Challenger 605',
        wingSpanM: '19,6',
        lengthM: '20,9',
        height: '6,3',
        mtowKG: 21.863,
        mtowT: 22,
        calcSurface: 307
    },
    {
        make: 'Bombardier',
        type: 'CL-600-2B16',
        model: 'Challenger 650',
        wingSpanM: '19,6',
        lengthM: '20,9',
        height: '6,3',
        mtowKG: 21.863,
        mtowT: 22,
        calcSurface: 307
    },
    {
        make: 'Bombardier',
        type: 'CL-600-2B19',
        model: 'Challenger 850',
        wingSpanM: '21,21',
        lengthM: '26,76',
        height: '6,22',
        mtowKG: 24.04,
        mtowT: 24,
        calcSurface: 426
    },
    {
        make: 'Bombardier',
        type: 'BD-700-1A10',
        model: 'Global Express',
        wingSpanM: '28,7',
        lengthM: '30,3',
        height: '7,8',
        mtowKG: 41957,
        mtowT: 42,
        calcSurface: 652
    },
    {
        make: 'Bombardier',
        type: 'BD-700-1A10',
        model: 'Global Express XRS',
        wingSpanM: '28,7',
        lengthM: '30,3',
        height: '7,8',
        mtowKG: 41957,
        mtowT: 42,
        calcSurface: 652
    },
    {
        make: 'Bombardier',
        type: 'BD-700-1A10',
        model: 'Global 6000',
        wingSpanM: '28,7',
        lengthM: '30,3',
        height: '7,8',
        mtowKG: 41957,
        mtowT: 42,
        calcSurface: 652
    },
    {
        make: 'Bombardier',
        type: 'BD-700-1A10',
        model: 'Global 6500',
        wingSpanM: '28,7',
        lengthM: '30,3',
        height: '7,8',
        mtowKG: 41957,
        mtowT: 42,
        calcSurface: 652
    },
    {
        make: 'Bombardier',
        type: 'BD-700-1A11',
        model: 'Global 5000',
        wingSpanM: '28,7',
        lengthM: '29,5',
        height: '7,8',
        mtowKG: 41957,
        mtowT: 42,
        calcSurface: 635
    },
    {
        make: 'Bombardier',
        type: 'BD-700-1A11',
        model: 'Global 5500',
        wingSpanM: '28,7',
        lengthM: '29,5',
        height: '7,8',
        mtowKG: 41957,
        mtowT: 42,
        calcSurface: 635
    },
    {
        make: 'Bombardier',
        type: 'BD-700-1A12',
        model: 'Global 7000',
        wingSpanM: '31,7',
        lengthM: '33,88',
        height: '8,2',
        mtowKG: 52.095,
        mtowT: 52,
        calcSurface: 805
    },
    {
        make: 'Bombardier',
        type: 'BD-700-1A12',
        model: 'Global 7500',
        wingSpanM: '31,7',
        lengthM: '33,88',
        height: '8,2',
        mtowKG: 52.095,
        mtowT: 52,
        calcSurface: 805
    },
    {
        make: 'Cessna',
        type: '510',
        model: 'Mustang',
        wingSpanM: '13,16',
        lengthM: '12,37',
        height: '4,09',
        mtowKG: 3.921,
        mtowT: 4,
        calcSurface: 122
    },
    {
        make: 'Cessna',
        type: '500',
        model: 'Citation I',
        wingSpanM: '14,35',
        lengthM: '13,35',
        height: '4,39',
        mtowKG: 5.376,
        mtowT: 5,
        calcSurface: 144
    },
    {
        make: 'Cessna',
        type: '525',
        model: 'Citation / CJ /CJ1 /CJ1+ / M2',
        wingSpanM: '15,09',
        lengthM: '14,53',
        height: '4,27',
        mtowKG: 5.67,
        mtowT: 6,
        calcSurface: 164
    },
    {
        make: 'Cessna',
        type: '550',
        model: 'Citation II / Bravo',
        wingSpanM: '15,9',
        lengthM: '14,39',
        height: '4,57',
        mtowKG: 6.713,
        mtowT: 7,
        calcSurface: 172
    },
    {
        make: 'Cessna',
        type: 'S550',
        model: 'Citation S',
        wingSpanM: '15,9',
        lengthM: '14,39',
        height: '4,57',
        mtowKG: 6.849,
        mtowT: 7,
        calcSurface: 172
    },
    {
        make: 'Cessna',
        type: '560',
        model: 'Citation V / Ultra ',
        wingSpanM: '15,9',
        lengthM: '14,9',
        height: '4,57',
        mtowKG: 7.394,
        mtowT: 7,
        calcSurface: 178
    },
    {
        make: 'Cessna',
        type: '560',
        model: 'Encore / Encore+',
        wingSpanM: '16,48',
        lengthM: '14,9',
        height: '4,62',
        mtowKG: 7.634,
        mtowT: 8,
        calcSurface: 184
    },
    {
        make: 'Cessna',
        type: '560XL',
        model: 'Excel / XLS /',
        wingSpanM: '16,97',
        lengthM: '15,8',
        height: '5,23',
        mtowKG: 9.163,
        mtowT: 9,
        calcSurface: 201
    },
    {
        make: 'Cessna',
        type: '560XL',
        model: 'XLS+',
        wingSpanM: '16,97',
        lengthM: '16,07',
        height: '5,23',
        mtowKG: 9.163,
        mtowT: 9,
        calcSurface: 205
    },
    {
        make: 'Cessna',
        type: '680',
        model: 'Sovereign',
        wingSpanM: '19,24',
        lengthM: '19,37',
        height: '6,17',
        mtowKG: 13.743,
        mtowT: 14,
        calcSurface: 280
    },
    {
        make: 'Cessna',
        type: '680',
        model: 'Sovereign +',
        wingSpanM: '22,04',
        lengthM: '19,37',
        height: '6,17',
        mtowKG: 13.959,
        mtowT: 14,
        calcSurface: 320
    },
    {
        make: 'Cessna',
        type: '750',
        model: 'Citation X',
        wingSpanM: '19,39',
        lengthM: '22,04',
        height: '5,85',
        mtowKG: 16.375,
        mtowT: 16,
        calcSurface: 321
    },
    {
        make: 'Dassault',
        type: 'F10',
        model: 'Falcon 10',
        wingSpanM: '13,08',
        lengthM: '13,86',
        height: '4,61',
        mtowKG: 8.755,
        mtowT: 9,
        calcSurface: 136
    },
    {
        make: 'Dassault',
        type: 'F20',
        model: 'Falcon 20',
        wingSpanM: '16,32',
        lengthM: '17,15',
        height: '5,32',
        mtowKG: 13,
        mtowT: 13,
        calcSurface: 210
    },
    {
        make: 'Dassault',
        type: 'F2000',
        model: 'Falcon 2000',
        wingSpanM: '19238',
        lengthM: '20228',
        height: '7058',
        mtowKG: 16.556,
        mtowT: 17,
        calcSurface: 292
    },
    {
        make: 'Dassault',
        type: 'F50',
        model: 'Falcon 50/50Ex',
        wingSpanM: '18,86',
        lengthM: '18,52',
        height: '6,98',
        mtowKG: 18.5,
        mtowT: 19,
        calcSurface: 262
    },
    {
        make: 'Dassault',
        type: 'F2000',
        model: 'Falcon 2000Dx',
        wingSpanM: '19238',
        lengthM: '20228',
        height: '7058',
        mtowKG: 18.597,
        mtowT: 19,
        calcSurface: 292
    },
    {
        make: 'Dassault',
        type: 'F2000',
        model: 'Falcon 2000Lx',
        wingSpanM: '21,38',
        lengthM: '20228',
        height: '7058',
        mtowKG: 19.142,
        mtowT: 19,
        calcSurface: 324
    },
    {
        make: 'Dassault',
        type: 'F2000',
        model: 'Falcon 2000Ex / 2000Exy',
        wingSpanM: '19238',
        lengthM: '20228',
        height: '7058',
        mtowKG: 19.414,
        mtowT: 19,
        calcSurface: 292
    },
    {
        make: 'Dassault',
        type: 'F2000',
        model: 'Falcon 2000LxS / 2000S',
        wingSpanM: '21,38',
        lengthM: '20228',
        height: '7058',
        mtowKG: 19.414,
        mtowT: 19,
        calcSurface: 324
    },
    {
        make: 'Dassault',
        type: 'F900',
        model: 'Falcon 900 / 900C',
        wingSpanM: '19,33',
        lengthM: '20,2',
        height: '7,5',
        mtowKG: 21.092,
        mtowT: 21,
        calcSurface: 293
    },
    {
        make: 'Dassault',
        type: 'F900',
        model: 'Falcon 900Ex / 900Exy /  900Dx',
        wingSpanM: '19,33',
        lengthM: '20,2',
        height: '7,5',
        mtowKG: 22.226,
        mtowT: 22,
        calcSurface: 293
    },
    {
        make: 'Dassault',
        type: 'F900',
        model: 'Falcon 900Lx',
        wingSpanM: '21,38',
        lengthM: '20,2',
        height: '7,5',
        mtowKG: 22.226,
        mtowT: 22,
        calcSurface: 324
    },
    {
        make: 'Dassault',
        type: 'F7x',
        model: 'Falcon 7x',
        wingSpanM: '26,21',
        lengthM: '23,38',
        height: '7,93',
        mtowKG: 31.751,
        mtowT: 32,
        calcSurface: 460
    },
    {
        make: 'Dassault',
        type: 'F7x',
        model: 'Falcon 8x',
        wingSpanM: '26,28',
        lengthM: '24,46',
        height: '7,93',
        mtowKG: 33.112,
        mtowT: 33,
        calcSurface: 482
    },
    {
        make: 'Dassault ',
        type: 'F200',
        model: 'Falcon 200',
        wingSpanM: '16,32',
        lengthM: '17,15',
        height: '5,32',
        mtowKG: 14.515,
        mtowT: 15,
        calcSurface: 210
    },
    {
        make: 'Embraer',
        type: 'EMB-545',
        model: 'Legacy 450',
        wingSpanM: '21,5',
        lengthM: '19,68',
        height: '6,43',
        mtowKG: 17.04,
        mtowT: 17,
        calcSurface: 317
    },
    {
        make: 'Embraer',
        type: 'EMB-550',
        model: 'Legacy 500',
        wingSpanM: '21,5',
        lengthM: '20,74',
        height: '6,44',
        mtowKG: 19.44,
        mtowT: 19,
        calcSurface: 334
    },
    {
        make: 'Embraer',
        type: 'EMB-135BJ',
        model: 'Legacy 600',
        wingSpanM: '21,17',
        lengthM: '26,33',
        height: '6,76',
        mtowKG: 24.3,
        mtowT: 24,
        calcSurface: 418
    },
    {
        make: 'Embraer',
        type: 'EMB-135BJ',
        model: 'Legacy 650',
        wingSpanM: '21,17',
        lengthM: '26,33',
        height: '6,76',
        mtowKG: 24.3,
        mtowT: 24,
        calcSurface: 418
    },
    {
        make: 'Embraer',
        type: 'ERJ 190-100ECJ',
        model: 'Linange 1000 / E190',
        wingSpanM: '28,72',
        lengthM: '36,24',
        height: '10,57',
        mtowKG: 54.5,
        mtowT: 55,
        calcSurface: 781
    },
    {
        make: 'Gulfstream',
        type: 'G100',
        model: 'G100',
        wingSpanM: '16,64',
        lengthM: '16,94',
        height: '5,54',
        mtowKG: 11.181,
        mtowT: 11,
        calcSurface: 211
    },
    {
        make: 'Gulfstream',
        type: 'G150',
        model: 'G150',
        wingSpanM: '16,94',
        lengthM: '17,3',
        height: '5,82',
        mtowKG: 11.839,
        mtowT: 12,
        calcSurface: 220
    },
    {
        make: 'Gulfstream',
        type: 'G200-Galaxy',
        model: 'G200',
        wingSpanM: '17,7',
        lengthM: '18,97',
        height: '6,52',
        mtowKG: 16.171,
        mtowT: 16,
        calcSurface: 252
    },
    {
        make: 'Gulfstream',
        type: 'G280',
        model: 'G280',
        wingSpanM: '19,21',
        lengthM: '20,39',
        height: '6,5',
        mtowKG: 17.962,
        mtowT: 18,
        calcSurface: 294
    },
    {
        make: 'Gulfstream',
        type: 'G-1159',
        model: 'GII',
        wingSpanM: '20,75',
        lengthM: '24,1',
        height: '7,46',
        mtowKG: 29.71,
        mtowT: 30,
        calcSurface: 375
    },
    {
        make: 'Gulfstream',
        type: 'G-1159B',
        model: 'GIIB',
        wingSpanM: '20,75',
        lengthM: '24,3',
        height: '7,46',
        mtowKG: 31.615,
        mtowT: 32,
        calcSurface: 378
    },
    {
        make: 'Gulfstream',
        type: 'G-1159A',
        model: 'GIII',
        wingSpanM: '23,5',
        lengthM: '25,32',
        height: '7,46',
        mtowKG: 31.615,
        mtowT: 32,
        calcSurface: 446
    },
    {
        make: 'Gulfstream',
        type: 'GIV',
        model: 'G300',
        wingSpanM: '23,5',
        lengthM: '25,32',
        height: '7,46',
        mtowKG: 32.658,
        mtowT: 33,
        calcSurface: 446
    },
    {
        make: 'Gulfstream',
        type: 'GIV',
        model: 'GIV',
        wingSpanM: '23,5',
        lengthM: '25,32',
        height: '7,46',
        mtowKG: 33.203,
        mtowT: 33,
        calcSurface: 446
    },
    {
        make: 'Gulfstream',
        type: 'GIV',
        model: 'GIV-SP',
        wingSpanM: '23,5',
        lengthM: '25,32',
        height: '7,46',
        mtowKG: 33.838,
        mtowT: 34,
        calcSurface: 446
    },
    {
        make: 'Gulfstream',
        type: 'GIV',
        model: 'G400',
        wingSpanM: '23,5',
        lengthM: '25,32',
        height: '7,46',
        mtowKG: 33.838,
        mtowT: 34,
        calcSurface: 446
    },
    {
        make: 'Gulfstream',
        type: 'GIV-X',
        model: 'G450',
        wingSpanM: '23,72',
        lengthM: '27,23',
        height: '7,67',
        mtowKG: 33.838,
        mtowT: 34,
        calcSurface: 484
    },
    {
        make: 'Gulfstream',
        type: 'GIV-X',
        model: 'G350',
        wingSpanM: '23,5',
        lengthM: '25,32',
        height: '7,46',
        mtowKG: 35.52,
        mtowT: 36,
        calcSurface: 446
    },
    {
        make: 'Gulfstream',
        type: 'GVII',
        model: 'G500',
        wingSpanM: '26,3',
        lengthM: '27,78',
        height: '7,77',
        mtowKG: 36.106,
        mtowT: 36,
        calcSurface: 548
    },
    {
        make: 'Gulfstream',
        type: 'GV-SP',
        model: 'G450SP',
        wingSpanM: '26,3',
        lengthM: '27,78',
        height: '7,64',
        mtowKG: 38.601,
        mtowT: 39,
        calcSurface: 548
    },
    {
        make: 'Gulfstream',
        type: 'GV',
        model: 'GV',
        wingSpanM: '28,5',
        lengthM: '29,4',
        height: '7,64',
        mtowKG: 41.05,
        mtowT: 41,
        calcSurface: 628
    },
    {
        make: 'Gulfstream',
        type: 'GV-SP',
        model: 'G550',
        wingSpanM: '28,5',
        lengthM: '29,4',
        height: '7,9',
        mtowKG: 41.277,
        mtowT: 41,
        calcSurface: 628
    },
    {
        make: 'Gulfstream',
        type: 'GVI',
        model: 'G650',
        wingSpanM: '30,36',
        lengthM: '30,41',
        height: '7,72',
        mtowKG: 45.177,
        mtowT: 45,
        calcSurface: 692
    },
    {
        make: 'Gulfstream',
        type: 'GVI',
        model: 'G650ER',
        wingSpanM: '30,36',
        lengthM: '30,41',
        height: '7,72',
        mtowKG: 45.55,
        mtowT: 46,
        calcSurface: 692
    },
    {
        make: 'Hawker',
        type: '400A',
        model: '400XP',
        wingSpanM: '13,2334',
        lengthM: '14,76',
        height: '4191',
        mtowKG: 7.394,
        mtowT: 7,
        calcSurface: 146
    },
    {
        make: 'Hawker',
        type: '800',
        model: '750',
        wingSpanM: '15,6464',
        lengthM: '15,57',
        height: '5,5118',
        mtowKG: 12.247,
        mtowT: 12,
        calcSurface: 183
    },
    {
        make: 'Hawker',
        type: '800',
        model: '800',
        wingSpanM: '15,6464',
        lengthM: '15,57',
        height: '5,2832',
        mtowKG: 12.7,
        mtowT: 13,
        calcSurface: 183
    },
    {
        make: 'Hawker',
        type: '800',
        model: '800XP / 800SP / 800XP2',
        wingSpanM: '16,5608',
        lengthM: '15,57',
        height: '5,5118',
        mtowKG: 12.7,
        mtowT: 13,
        calcSurface: 193
    },
    {
        make: 'Hawker',
        type: '800',
        model: '850XP',
        wingSpanM: '16,8656',
        lengthM: '15,57',
        height: '5,5118',
        mtowKG: 12.7,
        mtowT: 13,
        calcSurface: 197
    },
    {
        make: 'Hawker',
        type: '800',
        model: '900XP',
        wingSpanM: '16,8656',
        lengthM: '15,57',
        height: '5,5118',
        mtowKG: 12.7,
        mtowT: 13,
        calcSurface: 197
    },
    {
        make: 'Hawker',
        type: '1000',
        model: '1000A / 1000B',
        wingSpanM: '15,6464',
        lengthM: '16,0782',
        height: '5207',
        mtowKG: 14.061,
        mtowT: 14,
        calcSurface: 189
    },
    {
        make: 'Hawker',
        type: 'Horizon',
        model: '4000',
        wingSpanM: '18,82',
        lengthM: '21,08',
        height: '5,97',
        mtowKG: 17.917,
        mtowT: 18,
        calcSurface: 298
    },
    {
        make: 'Honda',
        type: 'HondaJet',
        model: 'HA-420',
        wingSpanM: '12,12',
        lengthM: '12,99',
        height: '4,56',
        mtowKG: 4.853,
        mtowT: 5,
        calcSurface: 118
    },
    {
        make: 'Pilatus',
        type: 'PC12',
        model: 'PC12',
        wingSpanM: '16315',
        lengthM: '14408',
        height: '4,26',
        mtowKG: 4.74,
        mtowT: 5,
        calcSurface: 176
    },
    {
        make: 'Pilatus',
        type: 'PC24',
        model: 'PC24',
        wingSpanM: '17',
        lengthM: '16,85',
        height: '5,4',
        mtowKG: 8.3,
        mtowT: 8,
        calcSurface: 215
    }
];

export const AIRCRAFT_MAKES = AIRCRAFT_LIST.map(({ model, make }) => ({
    key: model,
    label: make,
}));

export const AIRCRAFT_MODELS = AIRCRAFT_LIST.map(({ model }) => ({
    key: model,
    label: model,
}));

export const AIRCRAFT_MTOWS = AIRCRAFT_LIST.map(({ model, mtowT }) => ({
    key: model,
    label: mtowT,
}));