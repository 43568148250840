import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

const IconWeight = props => {
    const { className, rootClassName } = props;
    const classes = classNames(rootClassName, className);

    return (
        <svg
            className={classes}
            width="14"
            height="14"
            viewBox="0 0 14 14"
            fill="none"
            xmlns="http://www.w3.org/2000/svg">
            <path
                d="M6.94498 0.79541C6.02532 0.79541 5.26779 1.55294 5.26779 2.4726C5.26779 2.66939 5.30916 2.855 5.37289 3.03166H2.57758L2.48981 3.48618L0.812619 11.8721L0.795288 11.9241V13.0948H13.0947V11.9241L13.0773 11.8721L11.4001 3.48618L11.3124 3.03166H8.51706C8.58421 2.8528 8.61977 2.66363 8.62216 2.4726C8.62216 1.55294 7.86463 0.79541 6.94498 0.79541ZM6.94498 1.91354C7.25973 1.91354 7.50404 2.1584 7.50404 2.4726C7.50404 2.78735 7.25917 3.03166 6.94498 3.03166C6.63022 3.03166 6.38591 2.78679 6.38591 2.4726C6.38591 2.15785 6.63078 1.91354 6.94498 1.91354ZM3.4855 4.14979H10.4045L11.9765 11.9767H1.91341L3.4855 4.14979Z"
                fill="#808080"
            />
        </svg>
    );
};

const { string } = PropTypes;

IconWeight.defaultProps = {
    className: null,
    rootClassName: null,
};

IconWeight.propTypes = {
    className: string,
    rootClassName: string,
};

export default IconWeight;
