import React from 'react';
import { string } from 'prop-types';
import classNames from 'classnames';

const IconDate = props => {
    const { className, rootClassName } = props;
    const classes = classNames(rootClassName, className);

    return (
        <svg
            className={classes}
            width="16"
            height="16"
            viewBox="0 0 16 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg">
            <path d="M7.33398 8H11.334V12H7.33398V8Z" fill="#4A4A4A" />
            <path
                d="M12.6667 2.66732H11.3333V1.33398H10V2.66732H6V1.33398H4.66667V2.66732H3.33333C2.598 2.66732 2 3.26532 2 4.00065V13.334C2 14.0693 2.598 14.6673 3.33333 14.6673H12.6667C13.402 14.6673 14 14.0693 14 13.334V4.00065C14 3.26532 13.402 2.66732 12.6667 2.66732ZM12.6673 13.334H3.33333V5.33398H12.6667L12.6673 13.334Z"
                fill="#4A4A4A"
            />
        </svg>
    );
};

IconDate.defaultProps = {
    className: null,
    rootClassName: null,
    brand: 'default',
};

IconDate.propTypes = {
    className: string,
    rootClassName: string,
    brand: string,
};

export default IconDate;
