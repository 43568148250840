import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

const IconGrid = props => {
    const { className, rootClassName } = props;
    const classes = classNames(rootClassName, className);

    return (
        <svg
            className={classes}
            width="14"
            height="14"
            viewBox="0 0 14 14"
            fill="none"
            xmlns="http://www.w3.org/2000/svg">
            <path
                d="M12.169 0.23584H1.72866C0.908345 0.23584 0.237183 0.906609 0.237183 1.72644V12.1606C0.237183 12.9805 0.908345 13.6512 1.72866 13.6512H12.169C12.9893 13.6512 13.6604 12.9805 13.6604 12.1606V1.72644C13.6604 0.906609 12.9893 0.23584 12.169 0.23584ZM1.72866 12.1606V1.72644H6.20307V12.1606H1.72866ZM12.169 12.1606H7.69455V6.94353H12.169V12.1606ZM12.169 5.45293H7.69455V1.72644H12.169V5.45293Z"
                fill="#808080"
            />
        </svg>
    );
};

const { string } = PropTypes;

IconGrid.defaultProps = {
    className: null,
    rootClassName: null,
};

IconGrid.propTypes = {
    className: string,
    rootClassName: string,
};

export default IconGrid;
